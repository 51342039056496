import { Icon } from '@chakra-ui/react';
import { FunctionComponent as FC } from 'react';
import { MdContentCopy, MdInfo } from 'react-icons/md';
import { MClipboardCopyBox } from '~app/components/Monetize/MClipboardCopyBox';
import {
  MBox,
  MButton,
  MCenterModal,
  MFlex,
  MIcon,
  MStack,
  MText,
} from '~components/Monetize';

interface ApiKeyModalProps {
  apiKey: string;
  isOpen: boolean;
  onClose: () => void;
}

const ApiKeyModal: FC<ApiKeyModalProps> = (props: ApiKeyModalProps) => {
  const { isOpen, onClose, apiKey } = props;

  return (
    <MCenterModal
      isOpen={isOpen}
      onClose={onClose}
      modalTitle="New API Key"
      modalBodyProps={{ pt: 0 }}
      renderFooter={() => (
        <MStack
          spacing={4}
          direction="row"
          align="center"
          justify="right"
          flex={1}
        >
          <MButton variant="primary" onClick={onClose} minW="auto">
            Ok
          </MButton>
        </MStack>
      )}
    >
      <MBox mt={4}>
        <MFlex bg="tCreamsicle.base" padding="8px" borderRadius={3} mb={3}>
          <MIcon
            as={MdInfo}
            color="tOrange.tangerine"
            mr="3"
            fontSize="2xl"
            alignSelf={'center'}
          />
          <MText>
            Make sure to save your personal access token now. You will not be
            able to view this key again.
          </MText>
        </MFlex>
        <MFlex>
          <MText color="tPurple.dark" fontSize="sm" fontWeight={700}>
            API Key:{' '}
          </MText>
          <MText
            mr={2}
            color="tPurple.dark"
            fontSize="sm"
            ml="2"
            data-testid="personal-access-token"
          >
            {apiKey}
          </MText>
          <MClipboardCopyBox
            copyValue={apiKey}
            tooltipMessage="Copy"
            renderContent={() => {
              return (
                <Icon
                  verticalAlign="middle"
                  as={MdContentCopy}
                  w={4}
                  h={4}
                  color="tGray.darkPurple"
                  cursor="pointer"
                  _hover={{ color: 'tPurple.base' }}
                />
              );
            }}
          />
        </MFlex>
      </MBox>
    </MCenterModal>
  );
};

ApiKeyModal.defaultProps = {};

export { ApiKeyModal };
