import React, { FunctionComponent as FC } from 'react';
import { Box, InputProps, useMultiStyleConfig } from '@chakra-ui/react';

interface MCustomInputWrapperProps extends InputProps {}

const MCustomInputWrapper: FC<MCustomInputWrapperProps> = React.forwardRef<
  any,
  MCustomInputWrapperProps
>((props: MCustomInputWrapperProps, ref: any) => {
  const { size, variant, children, ...rest } = props;
  const styles: any = useMultiStyleConfig('Input', { variant, size });

  return (
    <Box ref={ref} __css={styles.field} {...rest}>
      {children}
    </Box>
  );
});

MCustomInputWrapper.defaultProps = {};

export default MCustomInputWrapper;
