import { z } from 'zod';

// NOTE: this used for string literal type
export const FieldMappingSystemSchema = z.enum([
  'hubspot',
  'monetizenow',
  'salesforce',
  'netsuite',
]);
export type FieldMappingSystemType = z.infer<typeof FieldMappingSystemSchema>;

// NOTE: this used for string literal type
export const FieldMappingObjectTypeSchema = z.enum([
  'companies',
  'deals',
  'contacts',
  'Account',
  'Quote',
  'Contact',
  'LegalEntity',
  'Opportunity',
  'CreditNote',
  'CreditMemo',
]);
export type FieldMappingObjectType = z.infer<
  typeof FieldMappingObjectTypeSchema
>;

export const FieldMappingViaObjectTypeSchema = z.enum([
  'LegalEntity',
  'Contact',
]);
export type FieldMappingViaObjectType = z.infer<
  typeof FieldMappingViaObjectTypeSchema
>;
export const FieldMappingBaseFieldSchema = z.object({
  fieldApiName: z.string().min(1),
  dataType: z.string(),
  displayLabel: z.string(),
});
export type FieldMappingBaseFieldType = z.infer<
  typeof FieldMappingBaseFieldSchema
>;
export const FieldMappingFieldSchema = FieldMappingBaseFieldSchema.extend({
  system: FieldMappingSystemSchema,
  objectType: FieldMappingObjectTypeSchema,
});
export type FieldMappingCreateFieldType = z.infer<
  typeof FieldMappingFieldSchema
>;

export const FieldMappingSchema = z.intersection(
  z.object({
    system: FieldMappingSystemSchema,
  }),
  z.record(
    FieldMappingObjectTypeSchema,
    z.array(FieldMappingFieldSchema.omit({ system: true, objectType: true })),
  ),
);
export type FieldMappingType = z.infer<typeof FieldMappingSchema>;

// NOTE: this used for string literal type
export const FieldMappingModeSchema = z.enum([
  'by_value',
  'by_reference_with_lookup_filter',
]);
export type FieldMappingModeType = z.infer<typeof FieldMappingModeSchema>;

export const FieldMappingViaFieldSchema = z.object({
  objectType: FieldMappingViaObjectTypeSchema,
  fieldApiName: z.string().min(1),
});
export type FieldMappingViaFieldType = z.infer<
  typeof FieldMappingViaFieldSchema
>;

export const FieldMappingCreateReqSchema = z.object({
  mode: FieldMappingModeSchema,
  source: FieldMappingBaseFieldSchema,
  target: FieldMappingBaseFieldSchema,
  via: FieldMappingViaFieldSchema.nullish(),
});
export type FieldMappingCreateReqType = z.infer<
  typeof FieldMappingCreateReqSchema
>;

export const FieldMappingCreateFormSchema = z.record(
  z.string().min(1),
  z.array(FieldMappingCreateReqSchema),
);
export type FieldMappingCreateFormType = z.infer<
  typeof FieldMappingCreateFormSchema
>;

export const FieldMappingsResFieldSchema = z.object({
  mode: FieldMappingModeSchema,
  source: FieldMappingFieldSchema,
  target: FieldMappingFieldSchema,
  via: FieldMappingViaFieldSchema.nullish(),
});
export type FieldMappingsResFieldType = z.infer<
  typeof FieldMappingsResFieldSchema
>;

export const FieldMappingsResSchema = z.object({
  fromMn: z.array(FieldMappingsResFieldSchema),
  toMn: z.array(FieldMappingsResFieldSchema),
});
export type FieldMappingsResType = z.infer<typeof FieldMappingsResSchema>;

export const FieldMappingMatchedFieldSchema = z.record(
  FieldMappingObjectTypeSchema,
  z.array(FieldMappingFieldSchema),
);

export type CrmFieldMatch = {
  crm: FieldMappingBaseFieldType[];
  monetize: FieldMappingBaseFieldType[];
};

export type AccountingFieldMatch = {
  accounting: FieldMappingBaseFieldType[];
  monetize: FieldMappingBaseFieldType[];
};

export type FieldMappingMatchedFieldType = z.infer<
  typeof FieldMappingMatchedFieldSchema
>;
export const FieldMappingBatchUpsertPayloadMappingFieldSchema = z.object({
  mode: FieldMappingModeSchema,
  source: FieldMappingFieldSchema.pick({ fieldApiName: true }),
  target: FieldMappingFieldSchema.pick({ fieldApiName: true }),
  via: FieldMappingViaFieldSchema.nullish(),
});
export type FieldMappingBatchUpsertPayloadMappingFieldType = z.infer<
  typeof FieldMappingBatchUpsertPayloadMappingFieldSchema
>;

export const FieldMappingBatchUpsertPayloadSchema = z.object({
  sourceSystem: FieldMappingSystemSchema,
  sourceEntity: FieldMappingObjectTypeSchema,
  targetSystem: FieldMappingSystemSchema,
  targetEntity: FieldMappingObjectTypeSchema,
  mappings: z.array(FieldMappingBatchUpsertPayloadMappingFieldSchema),
});
export type FieldMappingBatchUpsertPayloadType = z.infer<
  typeof FieldMappingBatchUpsertPayloadSchema
>;
