import { MBox, MFlex, MHeading } from '~app/components/Monetize';
import { ITeamResponseSchema } from '~app/types';
import { UserTeamCard } from './UserTeamCard';

export const UserTeams = ({ teams }: { teams: ITeamResponseSchema[] }) => {
  if (!teams?.length) {return null;}

  return (
    <MBox w="full">
      <MHeading size="xs" fontWeight="semibold" mb={2}>
        Teams
      </MHeading>
      <MFlex flexDir="row" flexWrap="wrap" rowGap={6} columnGap={6}>
        {teams.map((team) => (
          <UserTeamCard team={team} key={team.id} />
        ))}
      </MFlex>
    </MBox>
  );
};
