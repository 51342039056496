import { zodResolver } from '@hookform/resolvers/zod';
import { FunctionComponent as FC, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { handleApiErrorToast } from '~app/api/axios';
import { usePerformEntityAction } from '~app/api/queryUtils';
import {
  MButton,
  MCenterModal,
  MCheckbox,
  MFlex,
  MFormField,
  MStack,
  MText,
  MTooltip,
  MVStack,
} from '~app/components/Monetize';
import { DatePicker } from '~app/components/Monetize/DatePicker/DatePicker';
import { useToast } from '~app/services/toast';
import {
  IGetSubscriptionSchema,
  ISubscriptionCancelSchema,
  SubscriptionCancelSchema,
} from '~app/types';
import { toDateOnly } from '~app/utils/dates';

interface CancelSubscriptionProps {
  accountId: string;
  billGroupId: string;
  subscriptionId: string;
  isOpen: boolean;
  onClose: (redirect?: boolean) => void;
}

export const CancelSubscription: FC<CancelSubscriptionProps> = (
  props: CancelSubscriptionProps,
) => {
  const { isOpen, onClose, accountId, billGroupId, subscriptionId } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const { addToast } = useToast();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ISubscriptionCancelSchema>({
    resolver: zodResolver(SubscriptionCancelSchema),
    mode: 'onChange',
    defaultValues: {
      prorate: false,
    },
  });

  const { mutateAsync: doCancel } =
    usePerformEntityAction<IGetSubscriptionSchema>('accountSubscriptions', {
      endpointArgs: { accountId, billGroupId, isCancelOrActivate: true },
      onError: (err) => handleApiErrorToast(err),
    });

  const onSubmit = async (data: ISubscriptionCancelSchema) => {
    setLoading(true);
    try {
      await doCancel({ id: subscriptionId, action: 'cancel', data });
      addToast({
        summary: 'Subscription Canceled.',
        detail: `Subscription has successfully been canceled.`,
        severity: 'info',
      });
      onClose();
    } catch (error) {
      handleApiErrorToast(error);
    }
    setLoading(false);
  };

  return (
    <MCenterModal
      size="sm"
      isOpen={isOpen}
      onClose={() => onClose(false)}
      modalTitle="Cancel Subscription"
      renderFooter={() => (
        <MStack
          spacing={4}
          direction="row"
          align="center"
          justify="right"
          flex={1}
        >
          <MButton variant="cancel" onClick={() => onClose(false)} minW="auto">
            Close
          </MButton>
          <MButton
            variant="delete"
            isLoading={loading}
            type="submit"
            onClick={handleSubmit(onSubmit)}
            minW="auto"
          >
            Cancel
          </MButton>
        </MStack>
      )}
    >
      <MFlex justifyContent="start">
        <form onSubmit={handleSubmit(onSubmit)}>
          <MVStack gap={2} alignItems="left" mt={-3}>
            <MText color="tGray.darkPurple" noOfLines={1} fontSize="sm">
              Please select the effective date of the cancelation
            </MText>
            <MFormField error={errors.effectiveDate} label="Effective Date">
              <Controller
                name="effectiveDate"
                control={control}
                defaultValue={toDateOnly(new Date(), 'userTimezone')}
                render={({ field }) => (
                  <DatePicker {...field} isDisabled={loading} clearable />
                )}
              />
            </MFormField>
            <MFormField error={errors.prorate}>
              <Controller
                name="prorate"
                control={control}
                render={({ field: { value, ...rest } }) => (
                  <MCheckbox
                    isChecked={value as any}
                    {...rest}
                    iconColor="tWhite.base"
                    spacing="5"
                    fontWeight="normal"
                  >
                    <MTooltip
                      placement="right"
                      label={
                        <MVStack spacing="0px" alignContent="start">
                          <MText color="tWhite.base" fontSize={12}>
                            Choose this if you would like to credit
                          </MText>
                          <MText color="tWhite.base" fontSize={12}>
                            the customer for their unused period
                          </MText>
                        </MVStack>
                      }
                    >
                      Prorate & Credit
                    </MTooltip>
                  </MCheckbox>
                )}
              />
            </MFormField>
          </MVStack>
        </form>
      </MFlex>
    </MCenterModal>
  );
};
