import isArray from 'lodash/isArray';
import startCase from 'lodash/startCase';
import {
  CrmFieldMatch,
  FieldMappingBaseFieldType,
  FieldMappingBatchUpsertPayloadMappingFieldType,
  FieldMappingBatchUpsertPayloadType,
  FieldMappingCreateFieldType,
  FieldMappingCreateReqType,
  FieldMappingObjectType,
  FieldMappingsResFieldType,
  FieldMappingsResType,
  FieldMappingSystemType,
  FieldMappingType,
} from '~app/types/fieldMappingTypes';
import { CustomFieldTypeEnum } from '../types';

interface MapMatchingHubSpotFields {
  companyToAccount?: CrmFieldMatch;
  contactToContact?: CrmFieldMatch;
  dealToQuote?: CrmFieldMatch;
}

interface MapMatchingSfdcFields {
  opportunityToQuote?: CrmFieldMatch;
  contactToContact?: CrmFieldMatch;
  accountToAccount?: CrmFieldMatch;
}

export const convertCustomFieldTypeToCrmType = (
  type: CustomFieldTypeEnum,
): string => {
  let dataType = type.toLowerCase();
  if (type === CustomFieldTypeEnum.CHECKBOX) {
    dataType = 'boolean';
  } else if (
    type === CustomFieldTypeEnum.SINGLE_LINE_TEXT ||
    type === CustomFieldTypeEnum.DROPDOWN
  ) {
    dataType = 'text';
  }
  return dataType;
};

export const stringifyDisplayLabel = (displayLabel: string) => {
  const pattern = /_[0-9a-f]{8}$/;
  return pattern.test(displayLabel)
    ? displayLabel
        .replace(pattern, '')
        .split('_')
        .map((v) => startCase(v))
        .join(' ')
    : displayLabel;
};

export const mapMatchingHubSpotFields = (
  crmFields?: FieldMappingType,
  monetizeNowFields?: FieldMappingType,
): MapMatchingHubSpotFields => {
  if (!crmFields || !monetizeNowFields) {
    return {};
  }
  let response: Partial<MapMatchingHubSpotFields> = {};
  if (
    crmFields.hasOwnProperty('companies') &&
    monetizeNowFields.hasOwnProperty('Account')
  ) {
    response = {
      ...response,
      companyToAccount: {
        crm: crmFields.companies!,
        monetize: monetizeNowFields.Account!,
      },
    };
  }
  if (
    crmFields.hasOwnProperty('contacts') &&
    monetizeNowFields.hasOwnProperty('Contact')
  ) {
    response = {
      ...response,
      contactToContact: {
        crm: crmFields.contacts || [],
        monetize: monetizeNowFields.Contact || [],
      },
    };
  }
  if (
    crmFields.hasOwnProperty('deals') &&
    monetizeNowFields.hasOwnProperty('Quote')
  ) {
    response = {
      ...response,
      dealToQuote: {
        crm: crmFields.deals || [],
        monetize: monetizeNowFields.Quote || [],
      },
    };
  }
  return response;
};

export const mapMatchingSalesforceFields = (
  crmFields?: FieldMappingType,
  monetizeNowFields?: FieldMappingType,
): MapMatchingSfdcFields => {
  if (!crmFields || !monetizeNowFields) {
    return {};
  }
  let response: Partial<MapMatchingSfdcFields> = {};
  if (
    crmFields.hasOwnProperty('Opportunity') &&
    monetizeNowFields.hasOwnProperty('Quote')
  ) {
    response = {
      ...response,
      opportunityToQuote: {
        crm: crmFields.Opportunity || [],
        monetize: monetizeNowFields.Quote || [],
      },
    };
  }
  if (
    crmFields.hasOwnProperty('Contact') &&
    monetizeNowFields.hasOwnProperty('Contact')
  ) {
    response = {
      ...response,
      contactToContact: {
        crm: crmFields.Contact || [],
        monetize: monetizeNowFields.Contact || [],
      },
    };
  }
  if (
    crmFields.hasOwnProperty('Account') &&
    monetizeNowFields.hasOwnProperty('Account')
  ) {
    response = {
      ...response,
      accountToAccount: {
        crm: crmFields.Account || [],
        monetize: monetizeNowFields.Account || [],
      },
    };
  }
  return response;
};

/**
 * Transform crmFields and monetizeNowFields to a normalized format that
 * can be used in the form
 */
export const transformToDisplayLabelLookupRecord = (
  crmFields?: FieldMappingType,
  monetizeNowFields?: FieldMappingType,
) => {
  const performMapping = (
    data: FieldMappingType,
    baseData: Record<string, Record<string, string>> = {},
  ) => {
    return Object.keys(data).reduce((result, key) => {
      const fields = data[key as FieldMappingObjectType];
      if (isArray(fields)) {
        result[key] = {
          ...result[key],
          ...fields.reduce((res, field) => {
            res[field.fieldApiName] = stringifyDisplayLabel(field.displayLabel);
            return res;
          }, {} as Record<string, string>),
        };
      }
      return result;
    }, baseData);
  };

  const output: Record<string, Record<string, string>> = {};
  if (crmFields) {
    performMapping(crmFields, output);
  }
  if (monetizeNowFields) {
    performMapping(monetizeNowFields, output);
  }
  return output;
};

const getFieldWithDisplayLabel = (
  field: FieldMappingCreateFieldType,
  displayLabelLookupRecord: Record<string, Record<string, string>>,
): FieldMappingCreateFieldType => {
  let displayLabel =
    displayLabelLookupRecord[field.objectType][field.fieldApiName];
  if (!displayLabel) {
    displayLabel = field.fieldApiName;
  }
  return {
    ...field,
    displayLabel,
  };
};

export const convertToField = (
  field: FieldMappingsResFieldType,
  displayLabelLookupRecord: Record<string, Record<string, string>>,
): FieldMappingCreateReqType => {
  const source = getFieldWithDisplayLabel(
    field.source,
    displayLabelLookupRecord,
  );
  let targetFieldApiName = field.target.fieldApiName;
  if (field.mode === 'by_reference_with_lookup_filter') {
    const [firstPart, secondPart] = targetFieldApiName.split('.');
    switch (firstPart) {
      case 'legalEntityId':
        targetFieldApiName = `legalEntityId.${field.via?.fieldApiName}`;
        break;
      case 'contacts':
        targetFieldApiName = `${firstPart}.${secondPart}`;
        break;
    }
  }

  const target = getFieldWithDisplayLabel(
    {
      ...field.target,
      fieldApiName: targetFieldApiName,
    },
    displayLabelLookupRecord,
  );

  return field.mode === 'by_reference_with_lookup_filter'
    ? {
        mode: field.mode,
        source: {
          fieldApiName: source.fieldApiName,
          displayLabel: source.displayLabel,
          dataType: source.dataType,
        },
        target: {
          fieldApiName: target.fieldApiName,
          displayLabel: target.displayLabel,
          dataType: target.dataType,
        },
        via: field.via,
      }
    : {
        mode: field.mode,
        source: {
          fieldApiName: source.fieldApiName,
          displayLabel: source.displayLabel,
          dataType: source.dataType,
        },
        target: {
          fieldApiName: target.fieldApiName,
          displayLabel: target.displayLabel,
          dataType: target.dataType,
        },
      };
};

export const mapExistingFieldMapping = (
  data?: FieldMappingsResType,
  crmFields?: FieldMappingType,
  monetizeNowFields?: FieldMappingType,
) => {
  const response = {
    userDefinedCompaniesToAccount: [] as FieldMappingCreateReqType[],
    userDefinedContactsToContact: [] as FieldMappingCreateReqType[],
    userDefinedDealsToQuote: [] as FieldMappingCreateReqType[],
    userDefinedUOpportunityToQuote: [] as FieldMappingCreateReqType[],
    userDefinedUContactToContact: [] as FieldMappingCreateReqType[],
    userDefinedUAccountToAccount: [] as FieldMappingCreateReqType[],
  };
  if (!data) {
    return response;
  }

  const displayLabelLookupRecord = transformToDisplayLabelLookupRecord(
    crmFields,
    monetizeNowFields,
  );

  return data.toMn.reduce((result, field) => {
    const convertToFieldResult = convertToField(
      field,
      displayLabelLookupRecord,
    );

    if (
      field.source.objectType === 'companies' &&
      field.target.objectType === 'Account'
    ) {
      result.userDefinedCompaniesToAccount.push(convertToFieldResult);
    } else if (
      field.source.objectType === 'contacts' &&
      field.target.objectType === 'Contact'
    ) {
      result.userDefinedContactsToContact.push(convertToFieldResult);
    } else if (
      field.source.objectType === 'deals' &&
      field.target.objectType === 'Quote'
    ) {
      result.userDefinedDealsToQuote.push(convertToFieldResult);
    } else if (
      field.source.objectType === 'Opportunity' &&
      field.target.objectType === 'Quote'
    ) {
      result.userDefinedUOpportunityToQuote.push(convertToFieldResult);
    } else if (
      field.source.objectType === 'Contact' &&
      field.target.objectType === 'Contact'
    ) {
      result.userDefinedUContactToContact.push(convertToFieldResult);
    } else if (
      field.source.objectType === 'Account' &&
      field.target.objectType === 'Account'
    ) {
      result.userDefinedUAccountToAccount.push(convertToFieldResult);
    }
    return result;
  }, response);
};

export const transformCrmFields = (
  matchedFields: CrmFieldMatch,
  objectType: 'crm' | 'monetize',
): FieldMappingBaseFieldType[] => {
  return (matchedFields[objectType]?.map(
    ({ displayLabel, fieldApiName, dataType }) => ({
      displayLabel: stringifyDisplayLabel(displayLabel),
      fieldApiName,
      dataType,
    }),
  ) ?? []) satisfies FieldMappingBaseFieldType[];
};

export const transformToFieldMappingBatchUpsertPayloadType = (
  sourceSystem: FieldMappingSystemType,
  sourceEntity: FieldMappingObjectType,
  targetEntity: FieldMappingObjectType,
  payloads: FieldMappingCreateReqType[],
): FieldMappingBatchUpsertPayloadType => {
  return payloads.reduce(
    (result, field) => {
      let mappingField = {
        source: {
          fieldApiName: field.source.fieldApiName,
        },
        target: {
          fieldApiName: field.target.fieldApiName,
        },
        mode: field.mode,
      } as FieldMappingBatchUpsertPayloadMappingFieldType;

      if (field.mode === 'by_reference_with_lookup_filter') {
        const [firstPart, secondPart] = field.target.fieldApiName.split('.');
        switch (firstPart) {
          case 'legalEntityId':
            mappingField = {
              ...mappingField,
              target: {
                fieldApiName: firstPart,
              },
            };
            break;
          case 'contacts':
            mappingField = {
              ...mappingField,
              target: {
                fieldApiName: `${firstPart}.${secondPart}`,
              },
            };
            break;
        }

        if (field.via) {
          mappingField.via = {
            objectType: field.via.objectType,
            fieldApiName: field.via.fieldApiName,
          };
        }
      }

      result.mappings.push(mappingField);
      return result;
    },
    {
      sourceSystem,
      sourceEntity,
      targetSystem: 'monetizenow',
      targetEntity,
      mappings: [],
    } as FieldMappingBatchUpsertPayloadType,
  );
};
