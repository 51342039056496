import { differenceInDays } from 'date-fns/differenceInDays';
import { parseISO } from 'date-fns/parseISO';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { MBox, MDivider, MFlex, MLink } from '~app/components/Monetize';
import { ROUTES } from '~app/constants';
import { QUOTE_STATUES_TO_ALLOW_EXTEND_EXPIRATION_DATE } from '~app/constants/quotes';
import { useQuoteSettings } from '~app/hooks';
import { IQuoteRequestSchema, IQuoteRespSchema } from '~app/types';
import { getQuoteStatus } from '~app/utils';
import { getIsCustomPriceUsed } from '~app/utils/quotes';
import { QuoteDocumentsPopover } from '../quoteDocuments/QuoteDocumentsPopover';
import AddPoNumberSection from './AddPoNumberSection';
import { ExtendQuoteExpirationDate } from './ExtendQuoteExpirationDate';
import QuoteAccountSectionSegment from './QuoteAccountSectionSegment';
import QuoteCustomPricePopover from './QuoteCustomPricePopover';
import QuoteCustomRateNamePopover from './QuoteCustomRateNamePopover';
import QuoteEsignPopover from './QuoteEsignPopover';

interface QuoteAccountSectionProps {
  quote: IQuoteRespSchema;
  isReadOnly?: boolean;
  setQuote: (quote: IQuoteRespSchema | null) => void;
  fetchQuote: (quoteId: string) => void;
  updateQuote: (
    id: string,
    data: IQuoteRequestSchema,
    signal?: AbortSignal,
  ) => Promise<IQuoteRespSchema | null>;
}

const QuoteAccountSection = ({
  quote,
  isReadOnly,
  setQuote,
  fetchQuote,
  updateQuote,
}: QuoteAccountSectionProps) => {
  const { quoteSettings } = useQuoteSettings();
  const navigate = useNavigate();

  const customRateNames = useMemo(
    () =>
      quote.quoteOfferings
        .filter((offering) => offering.accountSpecificRate)
        .map(({ rateName, offeringName }) => ({ rateName, offeringName })),
    [quote.quoteOfferings],
  );

  const expiredDate = parseISO(quote.expirationDate);
  const quoteExpiresInDays = differenceInDays(expiredDate, new Date());
  const isQuoteExpiresInDaysVisible =
    quoteExpiresInDays <= 7 &&
    QUOTE_STATUES_TO_ALLOW_EXTEND_EXPIRATION_DATE.has(quote.status);

  const hasCustomPrice = getIsCustomPriceUsed(quote);

  const showAdditionalInfo =
    quote.requiresEsign ||
    hasCustomPrice ||
    customRateNames?.length ||
    isQuoteExpiresInDaysVisible;

  const handleUpdateDocuments = (
    quoteRequest: IQuoteRequestSchema,
    requestType: string,
  ) => {
    switch (requestType) {
      case 'GET':
        fetchQuote(quote.id);
        break;
      case 'PUT':
        updateQuote(quote.id, quoteRequest);
        break;
    }
  };

  const { isReview, isApproved } = getQuoteStatus(quote);
  const canEditPONumber = isReview || isApproved;
  const canShowPONumberSection =
    canEditPONumber || (!canEditPONumber && quote?.purchaseOrderNumber);

  return (
    <MBox width="full" pl="10">
      <MFlex justifyContent="space-between">
        <MFlex>
          {quote.contractId && (
            <QuoteAccountSectionSegment
              label="Contract"
              content={
                <MLink
                  fontSize="sm"
                  textDecor="none"
                  isTruncated
                  maxWidth="200px"
                  onClick={() => {
                    navigate(
                      ROUTES.getQuoteContractEditRoute(
                        quote.id,
                        quote.contractId!,
                      ),
                      { replace: true },
                    );
                  }}
                >
                  {quote.contractId}
                </MLink>
              }
              containerProps={{
                paddingLeft: !quote.accountId && !document ? '0' : '3',
              }}
            />
          )}

          {quoteSettings?.allowPONumberOnQuotes && canShowPONumberSection && (
            <QuoteAccountSectionSegment
              label="PO Number"
              content={<AddPoNumberSection quote={quote} setQuote={setQuote} />}
            />
          )}

          {showAdditionalInfo && (
            <QuoteAccountSectionSegment
              content={
                <MFlex justify="center" align="center" gap={2}>
                  {quote.requiresEsign && <QuoteEsignPopover quote={quote} />}
                  {hasCustomPrice && <QuoteCustomPricePopover />}
                  {customRateNames.length > 0 && (
                    <QuoteCustomRateNamePopover
                      customRateNames={customRateNames}
                    />
                  )}
                  {!isReadOnly && (
                    <ExtendQuoteExpirationDate
                      quote={quote}
                      setQuote={setQuote}
                      isQuoteExpiresInDaysVisible={isQuoteExpiresInDaysVisible}
                      quoteExpiresInDays={quoteExpiresInDays}
                    />
                  )}
                </MFlex>
              }
              showDivider={false}
              containerProps={{
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'flex-end',
                flexWrap: 'wrap',
                alignItems: 'center',
                mr: 2,
              }}
            />
          )}
          <QuoteDocumentsPopover
            quote={quote}
            isReadOnly={isReadOnly}
            onUpdate={handleUpdateDocuments}
          />
        </MFlex>
      </MFlex>
      <MDivider mt="2" />
    </MBox>
  );
};

export default QuoteAccountSection;
