import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';
import orderBy from 'lodash/orderBy';
import { apiGet, apiGetAllList, apiPost, isAxiosError } from '~api/axios';
import {
  AggregatedApplicationTransactionResponse,
  AggregatedTransactablesResponse,
  ApplyUnapplyRequest,
  DEFAULT_PAGER,
  GetListApiFilter,
  InvoiceStatusEnum,
  InvoiceSummaryResp,
  Maybe,
  TDataTablePager,
  TransactableSourceType,
  TransactablesResponse,
  TransactableTargetType,
} from '~types';
import { arrayToObject } from '../utils/misc';
import { invoiceServiceQueryKeys } from './invoiceService';
import { accountServiceQueryKeys } from './queryKeysService';

const creditServiceQueryKeys = {
  base: ['transactable'] as const,
  transactionApplications: (sourceId: string) =>
    [...creditServiceQueryKeys.base, sourceId] as const,
  transactionApplicationsAggregate: (sourceId: string) =>
    [
      ...creditServiceQueryKeys.transactionApplications(sourceId),
      'aggregation',
    ] as const,
};

export function useGetTransactionApplications(
  sourceType: TransactableSourceType,
  sourceId: string,
  options: Partial<
    UseQueryOptions<TransactablesResponse, unknown, TransactablesResponse>
  > = {},
) {
  return useQuery<TransactablesResponse, unknown, TransactablesResponse>(
    [...creditServiceQueryKeys.transactionApplications(sourceId)],
    {
      queryFn: () =>
        apiGet<TransactablesResponse>(
          `/applications/${sourceType}/${sourceId}`,
        ).then((res) => res.data),
      refetchOnWindowFocus: false,
      ...options,
    },
  );
}

export function useGetAggregatedTransactionApplications(
  sourceType: TransactableSourceType,
  sourceId: string,
  options: Partial<
    UseQueryOptions<
      AggregatedTransactablesResponse,
      unknown,
      AggregatedTransactablesResponse
    >
  > = {},
) {
  return useQuery<
    AggregatedTransactablesResponse,
    unknown,
    AggregatedTransactablesResponse
  >([...creditServiceQueryKeys.transactionApplicationsAggregate(sourceId)], {
    queryFn: () =>
      apiGet<AggregatedTransactablesResponse>(
        `/api/applications/aggregate/${sourceType}/${sourceId}`,
      ).then((res) => res.data),
    refetchOnWindowFocus: false,
    ...options,
  });
}

export interface AggregatedApplicationTransactionResponseWithInvoices {
  aggregateTransactable?: Maybe<AggregatedTransactablesResponse>;
  aggregatedApplicationsById: Record<
    string,
    AggregatedApplicationTransactionResponse
  >;
  /**
   * Invoices that have been applied to the source.
   */
  appliedInvoices: InvoiceSummaryResp[];
  /**
   * Unpaid invoices that have not been applied to the source.
   */
  unpaidInvoices: InvoiceSummaryResp[];
  /**
   * All invoices that are either applied or unpaid.
   */
  allInvoices: InvoiceSummaryResp[];
  /**
   * All invoices that are either applied or unpaid, mapped by id
   */
  invoicesById: Record<string, InvoiceSummaryResp>;
}

/**
 * Get all aggregated transactables for a source along with all applied and unpaid invoices for the provided account.
 *
 *
 * @param accountId
 * @param sourceType
 * @param sourceId
 * @param options
 * @returns
 */
export function useGetAggregatedTransactionApplicationsWithInvoices<
  SelectData = AggregatedApplicationTransactionResponseWithInvoices,
>(
  {
    accountId,
    sourceType,
    sourceId,
    currency,
    billGroupId,
  }: {
    accountId: string;
    sourceType: TransactableSourceType;
    sourceId: string;
    currency: string;
    billGroupId?: string;
  },
  options: Partial<
    UseQueryOptions<
      AggregatedApplicationTransactionResponseWithInvoices,
      unknown,
      SelectData
    >
  > = {},
) {
  const PAGER: TDataTablePager = {
    ...DEFAULT_PAGER,
    sortField: 'dueDate',
    sortOrder: 1,
    rows: 100,
  };
  return useQuery<
    AggregatedApplicationTransactionResponseWithInvoices,
    unknown,
    SelectData
  >([...creditServiceQueryKeys.transactionApplicationsAggregate(sourceId)], {
    queryFn: async () => {
      let aggregateTransactable: AggregatedTransactablesResponse | null = null;
      try {
        aggregateTransactable = await apiGet<AggregatedTransactablesResponse>(
          `/api/applications/aggregate/${sourceType}/${sourceId}`,
        ).then((res) => res.data);
      } catch (ex) {
        if (isAxiosError(ex) && ex.response?.status === 404) {
          // No applications found, this is ok
        } else {
          // fatal error
          throw ex;
        }
      }

      const invoiceIds = Array.from(
        new Set(
          aggregateTransactable?.aggregatedApplications
            .filter((app) => app.targetType === TransactableTargetType.invoice)
            .map((app) => app.targetId),
        ),
      );

      let appliedInvoices: InvoiceSummaryResp[] = [];

      if (invoiceIds.length > 0) {
        appliedInvoices = await apiGetAllList<InvoiceSummaryResp>(
          `/api/accounts/${accountId}/invoices`,
          {
            filters: { id: { in: invoiceIds } },
          },
        ).then((invoices) => invoices);
      }

      const appliedInvoiceIds = new Set(appliedInvoices.map(({ id }) => id));

      const filter: GetListApiFilter = {
        status: InvoiceStatusEnum.UNPAID,
        currency,
      };
      if (billGroupId) {
        filter.billGroupId = billGroupId;
      }

      const unpaidInvoices = await apiGetAllList<InvoiceSummaryResp>(
        `/api/accounts/${accountId}/invoices`,
        {
          config: PAGER,
          filters: filter,
        },
      ).then((res) => res.filter(({ id }) => !appliedInvoiceIds.has(id)));

      const allInvoices = orderBy(
        [...appliedInvoices, ...unpaidInvoices],
        ['dueDate', 'invoiceNumber'],
        ['asc', 'asc'],
      );

      return {
        aggregateTransactable,
        aggregatedApplicationsById: arrayToObject(
          aggregateTransactable?.aggregatedApplications || [],
          'targetId',
        ),
        appliedInvoices,
        unpaidInvoices,
        allInvoices,
        invoicesById: arrayToObject(
          [...appliedInvoices, ...unpaidInvoices],
          'id',
        ),
      };
    },
    refetchOnWindowFocus: false,
    ...options,
  });
}

export function useApplyPaymentsAndCreditsToInvoices(
  sourceType: TransactableSourceType,
  sourceId: string,
  accountId: string,
  options: UseMutationOptions<any, unknown, ApplyUnapplyRequest> = {},
) {
  const queryClient = useQueryClient();
  const { onSuccess, ...restOptions } = options;
  return useMutation<any, unknown, ApplyUnapplyRequest>({
    mutationFn: (payload) =>
      apiPost(`/api/applications/${sourceType}/${sourceId}`, payload),
    onSuccess: (...args) => {
      const [data, payload] = args;
      queryClient.invalidateQueries(
        creditServiceQueryKeys.transactionApplications(sourceId),
      );
      queryClient.invalidateQueries(
        creditServiceQueryKeys.transactionApplicationsAggregate(sourceId),
      );
      /**
       * Invalidate impacted invoices
       */
      payload.applications.forEach(({ invoiceId }) => {
        queryClient.invalidateQueries(
          accountServiceQueryKeys.accounts.invoiceList(accountId),
        );
        queryClient.invalidateQueries(invoiceServiceQueryKeys.invoiceList());
        queryClient.invalidateQueries(
          invoiceServiceQueryKeys.invoiceDetail(invoiceId),
        );
        queryClient.invalidateQueries(
          invoiceServiceQueryKeys.htmlTemplate(invoiceId),
        );
      });
      /**
       * Invalidate the source of the transaction to ensure data is re-fetched
       */
      switch (sourceType) {
        case TransactableSourceType.credit: {
          // This credit may have actually been from a credit note, so we need to invalidate both
          // and we don't have the credit note id here, so we invalidate every credit note in the system :fear:
          queryClient.invalidateQueries(
            accountServiceQueryKeys.creditNotes.base,
          );
          queryClient.invalidateQueries(
            accountServiceQueryKeys.credits.creditDetail(sourceId),
          );
          queryClient.invalidateQueries(
            accountServiceQueryKeys.credits.creditListByAccount(accountId),
          );
          queryClient.invalidateQueries(
            accountServiceQueryKeys.credits.htmlTemplate(accountId),
          );
          break;
        }
        case TransactableSourceType.creditNote: {
          queryClient.invalidateQueries(
            accountServiceQueryKeys.creditNotes.creditNoteById(sourceId),
          );
          // this is intentional, there is no "credit note list"
          queryClient.invalidateQueries(
            accountServiceQueryKeys.credits.creditListByAccount(accountId),
          );
          queryClient.invalidateQueries(
            accountServiceQueryKeys.creditNotes.htmlTemplate(accountId),
          );
          break;
        }
        case TransactableSourceType.payment: {
          queryClient.invalidateQueries(
            accountServiceQueryKeys.payments.paymentById(sourceId),
          );
          queryClient.invalidateQueries(
            accountServiceQueryKeys.payments.paymentByAccount(accountId),
          );
          queryClient.invalidateQueries(
            accountServiceQueryKeys.payments.htmlTemplate(accountId),
          );
          break;
        }
      }

      onSuccess && onSuccess(...args);
    },
    ...restOptions,
  });
}
