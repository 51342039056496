import { FC } from 'react';
import { MButton, MFormField } from '~app/components/Monetize';
import { IContactRespSchema } from '~app/types';

interface QuoteContactInputProps {
  contact?: IContactRespSchema | null;
  showPrimaryContactRequiredBg: boolean;
  onEditContacts: () => void;
}

const QuoteContactInput: FC<QuoteContactInputProps> = ({
  contact,
  showPrimaryContactRequiredBg,
  onEditContacts,
}: QuoteContactInputProps) => {
  return (
    <MFormField label="Primary Contact" isRequired>
      {contact ? (
        <MButton
          data-testid="new-contact-btn-change"
          variant="tertiary"
          size="sm"
          onClick={onEditContacts}
          maxW="100%"
          display="block"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          ml="-3"
        >
          {contact.fullName}
        </MButton>
      ) : (
        <MButton
          display="block"
          data-testid="new-contact-btn-add"
          variant="tertiary"
          size="sm"
          background={
            showPrimaryContactRequiredBg ? 'tOrange.bg' : 'transparent'
          }
          onClick={onEditContacts}
          ml="-3"
        >
          Set Contacts
        </MButton>
      )}
    </MFormField>
  );
};

export default QuoteContactInput;
