import { HStack } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { addMinutes } from 'date-fns/addMinutes';
import { formatISO } from 'date-fns/formatISO';
import { fromUnixTime } from 'date-fns/fromUnixTime';
import { parseISO } from 'date-fns/parseISO';
import { startOfDay } from 'date-fns/startOfDay';
import { subMinutes } from 'date-fns/subMinutes';
import isNil from 'lodash/isNil';
import { FC, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import {
  useCreateUsageRecord,
  useEditUsageRecord,
} from '~app/api/accountsService';
import { handleApiErrorToast } from '~app/api/axios';
import { DatePicker } from '~app/components/Monetize/DatePicker/DatePicker';
import { useCurrencies } from '~app/hooks/useCurrencies';
import { logger } from '~app/services/logger';
import {
  IUsageEvent,
  IUsageEventRequestSchema,
  IUsageTypeResSchema,
  UsageEventRequestSchema,
} from '~app/types';
import { adjustTimeZoneOffsetWithUtc, toDateTimeShort } from '~app/utils/dates';
import {
  MBox,
  MButton,
  MCenterModal,
  MCheckbox,
  MCustomNumberInput,
  MCustomSelect,
  MFormField,
  MInput,
  MStack,
} from '~components/Monetize';

interface ManualUsageFormProps {
  existingUsage?: IUsageEvent;
  accountId: string;
  subscriptionId: string;
  usageTypes?: IUsageTypeResSchema[] | null;
  periodStartDate: string;
  isOpen: boolean;
  onClose: (didSave?: boolean) => void;
}

export const ManualUsageForm: FC<ManualUsageFormProps> = ({
  existingUsage,
  isOpen,
  onClose,
  subscriptionId,
  usageTypes,
  accountId,
  periodStartDate,
}: ManualUsageFormProps) => {
  const [minDate] = useState(() => parseISO(periodStartDate));
  const [maxDate] = useState(() => new Date());
  const [loading, setLoading] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [hasPriceOverride, setHasPriceOverride] = useState<boolean>(
    () => !isNil(existingUsage?.ratedPrice),
  );
  const { mutateAsync: doPostSubscriptionUsage } = useCreateUsageRecord();
  const { mutateAsync: doUpdateSubscriptionUsage } = useEditUsageRecord();
  const { currencyPlaceholder } = useCurrencies();

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm<IUsageEventRequestSchema>({
    resolver: zodResolver(UsageEventRequestSchema),
    mode: 'onChange',
    defaultValues: existingUsage
      ? {
          ...existingUsage,
          timestamp: adjustTimeZoneOffsetWithUtc(
            fromUnixTime(existingUsage.timestamp),
          ),
        }
      : {
          id: '',
          subscriptionId,
          accountId,
          unitsConsumed: 1,
          timestamp: formatISO(startOfDay(new Date())),
          usageTypeId: usageTypes?.[0]?.id,
        },
  });

  useEffect(() => {
    setValue('accountId', accountId || '');
    setValue('subscriptionId', subscriptionId || '');
  }, [accountId, setValue, subscriptionId]);

  const isValid = Object.keys(errors).length === 0;

  const reset = () => {
    setValue('id', '');
    setValue('unitsConsumed', 1);
    setValue('timestamp', formatISO(startOfDay(new Date())));
    setValue('ratedPrice', null);
    setDisabled(false);
  };

  const onSubmit = async (data: IUsageEventRequestSchema) => {
    setLoading(true);
    try {
      if (existingUsage?.id) {
        await doUpdateSubscriptionUsage({
          id: existingUsage.id,
          payload: {
            unitsConsumed: data.unitsConsumed,
            // NOTE: the null value of ratedPrice is zero(0) for being a decimal input field
            // That's why i have to check this
            ratedPrice: (data.ratedPrice ?? 0) > 0 ? data.ratedPrice : null,
          },
        });
      } else {
        await doPostSubscriptionUsage([data]);
      }
    } catch (err) {
      // handle err
      handleApiErrorToast(err);
      setLoading(false);
      return;
    }
    reset();
    setLoading(false);
    onClose(true);
  };

  function onError(err: any) {
    logger.error(err, errors);
  }

  function handleGenerateId() {
    setValue('id', uuidv4().slice(0, 23));
  }

  const timestamp = watch('timestamp');

  const getTimestampHint = () => {
    const tempDate =
      timestamp instanceof Date ? timestamp : new Date(timestamp);
    const offset = tempDate.getTimezoneOffset();
    const date =
      Math.sign(offset) === -1
        ? addMinutes(tempDate, Math.abs(offset))
        : subMinutes(tempDate, offset);
    return toDateTimeShort(date);
  };

  if (!usageTypes || usageTypes.length === 0) {
    return null;
  }

  return (
    <MCenterModal
      isOpen={isOpen}
      trapFocus
      onClose={() => {
        onClose();
        reset();
      }}
      modalTitle={existingUsage ? 'Edit Usage' : 'Add Manual Usage'}
      size="sm"
      renderFooter={() => (
        <MStack
          spacing={4}
          direction="row"
          align="center"
          justify="right"
          flex={1}
        >
          <MButton
            variant="cancel"
            isDisabled={loading}
            minW="auto"
            onClick={() => onClose()}
          >
            Cancel
          </MButton>
          <MButton
            form="manual-usage-form"
            data-testid="manual-usage-form-submit"
            variant="primary"
            isLoading={loading}
            isDisabled={!isValid || loading || disabled}
            minW="auto"
            type="submit"
          >
            Save
          </MButton>
        </MStack>
      )}
    >
      <MBox>
        <form
          id="manual-usage-form"
          data-testid="manual-usage-form"
          onSubmit={handleSubmit(onSubmit, onError)}
        >
          <MStack>
            <MFormField
              error={errors?.timestamp}
              label="Usage Timestamp (UTC)"
              isRequired={!existingUsage}
              hint={`Local time: ${getTimestampHint()}`}
            >
              <Controller
                name="timestamp"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    includeTime
                    minDate={minDate}
                    maxDate={maxDate}
                    isDisabled={loading || !!existingUsage}
                    isReadOnly={!!existingUsage}
                    {...field}
                  />
                )}
              />
            </MFormField>
            <HStack spacing="4" alignItems="end">
              <MFormField
                error={errors?.id}
                label="Usage ID"
                isRequired={!existingUsage}
                tooltip={
                  existingUsage
                    ? undefined
                    : 'The Usage ID must be a unique value which will be used to identify the usage record. We can generate an ID for you if you click “Generate”.'
                }
              >
                <Controller
                  name="id"
                  control={control}
                  render={({ field: { value, ...rest } }) => (
                    <MInput
                      placeholder="Provide a unique id"
                      value={value || ''}
                      {...rest}
                      isDisabled={!!existingUsage || loading}
                      isReadOnly={!!existingUsage}
                      variant={existingUsage ? 'readonly' : undefined}
                    />
                  )}
                />
              </MFormField>
              {!existingUsage && (
                <MButton
                  variant="secondary"
                  size="sm"
                  onClick={handleGenerateId}
                >
                  Generate
                </MButton>
              )}
            </HStack>
            <HStack spacing="6" alignItems="start">
              <MFormField
                error={errors?.unitsConsumed}
                label="Unit(s)"
                isRequired
              >
                <Controller
                  name="unitsConsumed"
                  control={control}
                  render={({ field: { onChange, ...rest } }) => (
                    <MCustomNumberInput
                      inputMode="decimal"
                      placeholder="Enter Units"
                      isDisabled={loading}
                      onChange={(
                        valueAsString: string,
                        valueAsNumber: number,
                      ) => onChange(valueAsString)}
                      {...rest}
                    />
                  )}
                />
              </MFormField>

              <MFormField error={errors?.usageTypeId} label="Usage Type" ml={6}>
                <Controller
                  name="usageTypeId"
                  control={control}
                  render={({ field }) => (
                    <MCustomSelect
                      itemTitle="name"
                      itemValue="id"
                      items={usageTypes}
                      placeholder="Select"
                      isDisabled={loading}
                      {...field}
                    />
                  )}
                />
              </MFormField>
            </HStack>
            {!existingUsage && (
              <MFormField>
                <MCheckbox
                  data-testid="price-override-checkbox"
                  isChecked={hasPriceOverride}
                  onChange={() => setHasPriceOverride(!hasPriceOverride)}
                >
                  Override the price for the usage record
                </MCheckbox>
              </MFormField>
            )}
            {hasPriceOverride && (
              <HStack maxW="50%">
                <MFormField
                  mr={4}
                  error={errors?.ratedPrice}
                  label="Amount Override"
                  tooltip="If specified, this amount will be charged once for all units on this usage record.  E.g. If Amount Override is $1.00 and quantity is 10 units, the amount charged will be $1.00."
                >
                  <Controller
                    name="ratedPrice"
                    control={control}
                    render={({ field: { value, ...rest } }) => (
                      <MCustomNumberInput
                        value={value as any}
                        inputMode="decimal"
                        placeholder={currencyPlaceholder}
                        isDisabled={loading}
                        {...rest}
                      />
                    )}
                  />
                </MFormField>
              </HStack>
            )}
          </MStack>
        </form>
      </MBox>
    </MCenterModal>
  );
};
