import { useQuery } from '@tanstack/react-query';
import { apiGet } from '~app/api/axios';
import { composeGetQuery } from '~app/api/utils';
import { ApiListResponse, GetListApiConfig, IAccountHistoryResp } from '~types';

const useAccountHistory = (
  accountId: string,
  config: GetListApiConfig,
  options?: {
    enabled?: boolean;
    refetchOnWindowFocus?: boolean;
    keepPreviousData?: boolean;
  },
) => {
  const params = composeGetQuery(config);
  return useQuery(['history', accountId, params], {
    queryFn: async () => {
      const { data } = await apiGet<ApiListResponse<IAccountHistoryResp>>(
        `/api/account/${accountId}/history`,
        {
          params,
        },
      );
      return data;
    },
    ...options,
  });
};
export default useAccountHistory;
