import { useNavigate, useParams } from 'react-router-dom';
import { handleApiErrorToast } from '~app/api/axios';
import { usePerformEntityAction } from '~app/api/queryUtils';
import DataTableActions, {
  ActionType,
} from '~app/components/Monetize/DataTable/MDataTableActions';
import { ROUTES } from '~app/constants';
import { useACL } from '~app/services/acl/acl';
import { BillGroupStatusEnum, IBillGroupResp, IBillGroupRespUI } from '~types';

interface BillGroupActionsProps {
  billGroup: IBillGroupRespUI;
  source?: 'accountOverview' | 'accountBillGroupList';
}

export const BillGroupActions = ({
  billGroup,
  source = 'accountBillGroupList',
}: BillGroupActionsProps) => {
  const params = useParams();
  const navigate = useNavigate();
  const accountId = params?.accountId || '';

  const { canDo } = useACL();

  const { mutateAsync: doActivateDeactivate } =
    usePerformEntityAction<IBillGroupResp>('billGroups', {
      endpointArgs: { accountId, skipNestedEndpoint: true },
      onError: (err) => handleApiErrorToast(err),
    });

  const canAccountBillGroupUpdate = canDo([['billing', 'update']]);

  const lockedByContract = billGroup?.locked && billGroup?.activeContract;

  const actions: ActionType[] = [
    {
      title: 'Edit',
      enabled: canAccountBillGroupUpdate,
      action: () => {
        navigate(
          canAccountBillGroupUpdate
            ? `${ROUTES.getBillGroupPageRoute(
                billGroup.id!,
                'details',
              )}?editMode`
            : ROUTES.getBillGroupPageRoute(billGroup.id!, 'details'),
        );
      },
    },
    {
      title: 'Activate',
      enabled:
        billGroup.status === BillGroupStatusEnum.INACTIVE &&
        canAccountBillGroupUpdate &&
        !lockedByContract,
      action: () =>
        doActivateDeactivate({ id: billGroup?.id!, action: 'activate' }),
    },
    {
      title: 'Deactivate',
      color: 'danger',
      confirmProps: {
        title: `Are you sure you want to deactivate "${billGroup.name}"?`,
        description:
          'Once deactivated, the customer will no longer be billed for any subscriptions related to this Bill Group.',
        yesButton: 'Deactivate',
      },
      enabled:
        billGroup.status === BillGroupStatusEnum.ACTIVE &&
        canAccountBillGroupUpdate &&
        !lockedByContract,
      action: () =>
        doActivateDeactivate({ id: billGroup?.id!, action: 'deactivate' }),
    },
  ];

  return <DataTableActions actions={actions} />;
};
