import { useDisclosure, UseDisclosureReturn } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import {
  doDownloadInvoiceAsCsv,
  doPrintInvoiceToPdf,
  useSetContactOnInvoice,
} from '~app/api/accountsService';
import { handleApiErrorToast } from '~app/api/axios';
import { useSetAddressOnInvoice } from '~app/api/invoiceService';
import { useRevRecSettings } from '~app/api/revenueService';
import { ContactFormModal } from '~app/components/Contacts/ContactFormModal';
import DataTableActions, {
  ActionType,
} from '~app/components/Monetize/DataTable/MDataTableActions';
import {
  INVOICE_EMAIL_STATUSES,
  REVENUE_SCHEDULE_STATUSES,
} from '~app/constants/invoice';
import { getInvoiceDetailContractsEdit } from '~app/constants/routes';
import { useACL } from '~app/services/acl/acl';
import { useConfirmModal } from '~app/services/confirmModal';
import { logger } from '~app/services/logger';
import { useToast } from '~app/services/toast';
import { downloadBlobAsFile } from '~app/utils/download';
import { isSummaryInvoiceResponse } from '~app/utils/invoices';
import {
  IInvoiceAddressDataSchema,
  IInvoiceRespSchema,
  InvoiceAddressDataSchema,
  InvoiceStatusEnum,
  InvoiceSummaryResp,
} from '~types';
import { useGetListData, usePerformEntityAction } from '../../api/queryUtils';
import { useFlags } from '../../services/launchDarkly';
import { ITaxConnectionBaseSchema, TaxStatusEnum } from '../../types/taxTypes';
import { downloadAndSaveToPDF } from '../../utils';
import { EditContactDetailModal } from './EditContactDetailModal';
import { EmailInvoiceModal } from './EmailInvoiceModal';
import { InvoiceRevenueScheduleModal } from './InvoiceRevenueSchedule/InvoiceRevenueScheduleModal';

interface InvoiceActionProps {
  invoice: IInvoiceRespSchema | InvoiceSummaryResp;
  isReadOnly?: boolean;
  source?: 'accounts' | 'dashboard';
  refreshData: () => void;
  recalculateTax: () => Promise<IInvoiceRespSchema>;
  onCreateCreditNote: () => void;
  editContactModalState: UseDisclosureReturn;
  onOpenCreditAndRebillModal: () => void;
}

export const InvoiceActions = ({
  invoice,
  isReadOnly = false,
  source,
  editContactModalState,
  onOpenCreditAndRebillModal,
  refreshData,
  recalculateTax,
  onCreateCreditNote,
}: InvoiceActionProps) => {
  const navigate = useNavigate();
  const { addToast } = useToast();
  const { canDo } = useACL();
  const { showCreditAndRebill } = useFlags();

  const { data: recRevSettings } = useRevRecSettings();
  const { mutateAsync: doUpdateInvoiceAddress } = useSetAddressOnInvoice();
  const addressForm = useForm<IInvoiceAddressDataSchema>({
    resolver: zodResolver(InvoiceAddressDataSchema),
    mode: 'onChange',
    defaultValues: {},
  });

  const contactModalState = useDisclosure();
  const revenueScheduleModalState = useDisclosure();
  const emailInvoiceModalState = useDisclosure();
  const { showConfirmModal, setModalLoading, closeModal } = useConfirmModal();

  const { data: isTaxProviderConnected } = useGetListData<
    ITaxConnectionBaseSchema,
    boolean
  >('tax', undefined, {
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 5,
    cacheTime: 1000 * 60 * 5,
    select: (data) => {
      const taxProvider = data as unknown as ITaxConnectionBaseSchema[];
      return taxProvider?.some(({ status }) => status === TaxStatusEnum.ACTIVE);
    },
  });

  const [contact, setContact] = useState<{
    billingContactId: string;
    shippingContactId: string;
  }>();
  const [isActionFor, setIsActionFor] = useState<'BILLING' | 'SHIPPING'>(
    'BILLING',
  );
  const [isSavingContact, setIsSavingContact] = useState(false);
  const canUpdatePoNumber = canDo([
    ['sales', 'read'],
    ['billing', 'read'],
  ]);

  const { mutateAsync: doSetContactOnInvoice } = useSetContactOnInvoice();
  const { mutateAsync: finalizeInvoiceWithTaxError } =
    usePerformEntityAction<IInvoiceRespSchema>('invoices', {
      onSuccess: () => refreshData(),
    });

  if (!invoice) {
    return null;
  }

  const handleClickContinue = async ({
    billingContactId,
    shippingContactId,
    action,
    isSubmit,
  }: {
    billingContactId: string;
    shippingContactId: string;
    action?: 'BILLING' | 'SHIPPING';
    isSubmit?: boolean;
  }) => {
    if (isSubmit) {
      await doSetContactOnInvoice({
        invoiceId: invoice?.id,
        data: {
          contactId: billingContactId,
          shippingContactId,
        },
      });

      await doUpdateInvoiceAddress({
        invoiceId: invoice.id,
        data: addressForm.getValues(),
      });
      refreshData();
      editContactModalState.onClose();
    } else {
      setIsActionFor(action!);
      setContact({ billingContactId, shippingContactId });
      contactModalState.onOpen();
    }
  };

  const handleContactModalClose = async () => {
    contactModalState.onClose();
    setContact(undefined);
  };

  async function handleReCalculateTax() {
    showConfirmModal({
      title: 'Recalculate Tax',
      description:
        'Are you sure you want to Recalculate Tax? If you have already sent the invoice to your customer, the amount may change.',
      onYes: async () => {
        try {
          setModalLoading(true);
          await recalculateTax();
        } catch (error) {
          // message to user is already handled in parent component
          logger.warn('Could not Recalculate taxes');
        } finally {
          setModalLoading(false);
          closeModal();
        }
      },
      yesButton: 'Yes',
      yesBtnProps: {
        variant: 'primary',
      },
      noBtnProps: {
        variant: 'cancel',
      },
    });
  }

  async function handleFinalizeWithTaxError() {
    showConfirmModal({
      title: 'Finalize with Tax Error',
      description:
        'Are you sure you want to finalize this invoice without tax? This action cannot be undone.',
      onYes: async () => {
        try {
          setModalLoading(true);
          await finalizeInvoiceWithTaxError({
            id: invoice.id,
            action: 'finalize',
            params: { overrideTaxError: 'true' },
          });
        } catch (error) {
          handleApiErrorToast(error);
        } finally {
          setModalLoading(false);
          closeModal();
        }
      },
      yesButton: 'Yes',
      yesBtnProps: {
        variant: 'primary',
      },
      noBtnProps: {
        variant: 'cancel',
      },
    });
  }

  const actions: ActionType[] = [
    {
      title: 'View Contract',
      enabled: !!invoice.contractId,
      action: () => {
        try {
          const contractPath = getInvoiceDetailContractsEdit(
            invoice.contractId,
          );
          navigate(contractPath, { state: { source } });
        } catch (err) {
          handleApiErrorToast(err);
        }
      },
    },
    {
      title: 'Print to PDF',
      enabled: true,
      action: () =>
        downloadAndSaveToPDF(() =>
          doPrintInvoiceToPdf(invoice.id, invoice.invoiceNumber),
        ),
    },
    {
      title: 'Email Invoice',
      enabled:
        !isReadOnly &&
        !isSummaryInvoiceResponse(invoice) &&
        !!invoice.billingContact?.email &&
        INVOICE_EMAIL_STATUSES.has(invoice.status),
      action: () => emailInvoiceModalState.onOpen(),
    },
    {
      title: 'Export CSV',
      enabled: true,
      action: async () => {
        try {
          const resp = await doDownloadInvoiceAsCsv(invoice.id);
          downloadBlobAsFile(
            resp,
            `${invoice.invoiceNumber || invoice.id}.csv`,
            'text/csv;encoding:utf-8',
          );
        } catch (err) {
          handleApiErrorToast(err);
        }
      },
    },
    {
      title: 'View Revenue Schedule',
      enabled:
        recRevSettings?.enabled &&
        REVENUE_SCHEDULE_STATUSES.has(invoice.status),
      action: () => revenueScheduleModalState.onOpen(),
    },
    {
      title: 'Finalize with Tax Error',
      enabled:
        !isReadOnly &&
        !isSummaryInvoiceResponse(invoice) &&
        invoice.status === InvoiceStatusEnum.DRAFT &&
        !!invoice.taxError,
      action: handleFinalizeWithTaxError,
    },
    {
      title: 'Recalculate Tax',
      enabled:
        !isReadOnly &&
        !isSummaryInvoiceResponse(invoice) &&
        invoice.status === InvoiceStatusEnum.DRAFT &&
        isTaxProviderConnected,
      action: handleReCalculateTax,
    },
  ];

  if (invoice.amountCredited < invoice.amount && !isReadOnly) {
    actions.push({
      title: 'Create a Credit Note',
      enabled: invoice.status === InvoiceStatusEnum.UNPAID,
      action: onCreateCreditNote,
    });
  }

  actions.push({
    title: 'Credit and Rebill',
    enabled:
      !isReadOnly &&
      InvoiceStatusEnum.DRAFT !== invoice.status &&
      !(invoice as IInvoiceRespSchema).creditRebilledTargetInvoiceId &&
      showCreditAndRebill,
    action: onOpenCreditAndRebillModal,
  });

  return (
    <>
      <DataTableActions actions={actions} />

      {editContactModalState.isOpen && !isSummaryInvoiceResponse(invoice) && (
        <EditContactDetailModal
          isOpen
          invoice={invoice!}
          onClose={() => editContactModalState.onClose()}
          onContinue={handleClickContinue}
          addressForm={addressForm}
        />
      )}

      {contactModalState.isOpen && isActionFor && contact && (
        <ContactFormModal
          isOpen
          accountId={invoice?.account.id}
          contactId={
            isActionFor === 'BILLING'
              ? contact?.billingContactId
              : contact?.shippingContactId
          }
          isLoading={isSavingContact}
          onClose={() => contactModalState.onClose()}
          onUpdatedContact={handleContactModalClose}
        />
      )}

      {revenueScheduleModalState.isOpen && (
        <InvoiceRevenueScheduleModal
          isOpen
          invoice={invoice}
          onClose={() => revenueScheduleModalState.onClose()}
        />
      )}
      {emailInvoiceModalState.isOpen && !isSummaryInvoiceResponse(invoice) && (
        <EmailInvoiceModal
          isOpen
          invoice={invoice}
          onClose={emailInvoiceModalState.onClose}
          refreshData={refreshData}
        />
      )}
    </>
  );
};
