import { BoxProps } from '@chakra-ui/react';
import React, { FunctionComponent as FC } from 'react';
import { MBox } from './chakra';

interface MPageContentContainerProps extends BoxProps {
  children?: React.ReactNode;
}

const MPageContentContainer: FC<MPageContentContainerProps> = React.forwardRef<
  any,
  MPageContentContainerProps
>(({ children, ...rest }: MPageContentContainerProps, ref) => {
  const defaultProps: Partial<BoxProps> = {
    display: 'flex',
    flexDir: 'column',
    height: '100%',
    flex: 1,
    minHeight: 0,
  };

  return (
    <MBox ref={ref} {...defaultProps} {...rest}>
      {children}
    </MBox>
  );
});

export { MPageContentContainer };
