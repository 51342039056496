import { FC } from 'react';
import { MBox, MFlex, MLink, MText } from '~app/components/Monetize';
import { useQuoteContext } from '../../Quote/quoteContext';
import { IQuoteRequestSchema, QuoteAmendmentVersionEnum } from '~app/types';
import { Controller, useFormContext } from 'react-hook-form';
import { QuoteBasicInfoLine } from '~app/components/Quotes/QuoteBasicInfoLine';
import { parseISO } from 'date-fns/parseISO';
import { handleApiErrorToast } from '~app/api/axios';
import { DatePicker } from '~app/components/Monetize/DatePicker/DatePicker';
import { QuoteContractLengthPopoverV2 } from '~app/components/Quotes/QuoteContractLengthPopoverV2';
import { QuoteContractRenewalTermsActionV2 } from '~app/components/Quotes/QuoteContractRenewalTermsActionV2';
import { getQuoteType } from '~app/utils';
import { toDateShort } from '~app/utils/dates';
import { useFlags } from '~app/services/launchDarkly';
import { Link } from 'react-router-dom';
import { ROUTES } from '~app/constants';

interface QuoteBasicContractProps {}

export const QuoteBasicContract: FC<
  QuoteBasicContractProps
> = ({}: QuoteBasicContractProps) => {
  const {
    handleSubmitButtonWithoutDirtyCheck,
    isReadOnly,
    quoteData: { quote, modifiedFields, updateQuote },
    quoteSettingsData,
  } = useQuoteContext();
  const { isNew, isAmendment, isRenewal } = getQuoteType(quote);
  const { control, getValues, setValue } =
    useFormContext<IQuoteRequestSchema>();
  const { hideAutoRenewal } = useFlags();

  const handleSaveQuoteContractRenewalTerms = async (
    payload: IQuoteRequestSchema,
  ): Promise<void> => {
    try {
      await updateQuote(quote?.id!, payload);
    } catch (ex) {
      handleApiErrorToast(ex);
    }
  };
  if (!quote) {
    return null;
  }

  return (
    <MBox>
      <QuoteBasicInfoLine title="Length">
        <QuoteContractLengthPopoverV2
          isCustomContractLength={
            quoteSettingsData.quoteSettings?.customContractLength
              .allowCustomLength!
          }
          isNew={isNew}
          isAmendment={isAmendment}
          quote={quote!}
          setValue={setValue}
          handleSubmitButton={handleSubmitButtonWithoutDirtyCheck}
          isDisabled={
            (isAmendment &&
              quote?.amendmentVersion === QuoteAmendmentVersionEnum.v1) ||
            isReadOnly
          }
        />
      </QuoteBasicInfoLine>

      <QuoteBasicInfoLine title="Period">
        {quote?.contractStartDate && quote?.contractEndDate && (
          <MFlex minH={8} align="center">
            <MFlex align="center">
              {!(isAmendment || isRenewal) && (
                <Controller
                  name="contractStartDate"
                  control={control}
                  render={({ field: { onChange, ...rest } }) => (
                    <DatePicker
                      {...rest}
                      isReadOnly={isReadOnly}
                      triggerType="button"
                      btnText={toDateShort(quote?.contractStartDate || null)}
                      isLoading={modifiedFields?.has('contractStartDate')}
                      onChange={(date) => {
                        onChange(date);
                        handleSubmitButtonWithoutDirtyCheck();
                      }}
                      btnProps={{
                        m: 0,
                        'data-testid': 'contractStartDateBtn',
                        px: 3,
                      }}
                      readonlyContentProps={{ pb: 0, px: 1 }}
                      isDisabled={!quote}
                    />
                  )}
                />
              )}

              {(isRenewal || isAmendment) && (
                <MText
                  px="3"
                  fontWeight="normal"
                  fontSize="sm"
                  color="tPurple.base"
                  whiteSpace="pre-wrap"
                >
                  {toDateShort(quote?.contractStartDate || null)}
                </MText>
              )}
              <MText as="span">-</MText>
              <MText
                pl="3"
                fontWeight="normal"
                fontSize="sm"
                color="tPurple.base"
                whiteSpace="pre-wrap"
              >
                {toDateShort(quote?.contractEndDate || null)}
              </MText>
            </MFlex>
          </MFlex>
        )}
      </QuoteBasicInfoLine>

      {!hideAutoRenewal && (
        <QuoteBasicInfoLine title="End Action">
          <QuoteContractRenewalTermsActionV2
            contractRenewalTerms={quote?.contractRenewalTerms}
            defaultContractRenewalTerms={
              quoteSettingsData.quoteSettings?.contractRenewalTerms
            }
            onSubmit={async (contractRenewalTerms) => {
              try {
                await handleSaveQuoteContractRenewalTerms({
                  ...getValues(),
                  contractRenewalTerms,
                });
                setValue('contractRenewalTerms', contractRenewalTerms, {
                  shouldDirty: false,
                });
              } catch (ex) {
                handleApiErrorToast(ex);
              }
            }}
            isReadOnly={isReadOnly}
          />
        </QuoteBasicInfoLine>
      )}

      <QuoteBasicInfoLine title="ID">
        {!quote.contractId ? (
          <MText fontStyle="italic">Defined after quote processing</MText>
        ) : (
          <MLink
            fontSize="sm"
            isTruncated
            maxWidth="200px"
            to={ROUTES.getQuoteContractEditRoute(quote.id, quote.contractId!)}
            as={Link}
            replace
          >
            {quote.contractId}
          </MLink>
        )}
      </QuoteBasicInfoLine>
    </MBox>
  );
};
