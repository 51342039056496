/** Playwright global setup indirectly references this file, don't access browser APIs unless they exist */
const location: Partial<Location> = globalThis.window ? window.location : {};

const auth0CallbackUrl = `${location.origin}/auth/callback`;
const auth0ReturnUrl = `${location.origin}/auth/login`;

/**
 * process.env should not be accessed directly, instead use ENVIRONMENT
 */
// prettier-ignore
export const ENVIRONMENT = {
  HOST_TYPE: process.env.REACT_APP_HOST_TYPE,
  IS_DEV:
    process.env.REACT_APP_HOST_TYPE === 'development' ||
    process.env.REACT_APP_HOST_TYPE === 'local',
  IS_LOCAL_DEV:
    process.env.REACT_APP_IS_CI !== 'true' &&
    location?.hostname === 'localhost',
  IS_PLAYWRIGHT:
    process.env.REACT_APP_IS_CI === 'true' &&
    location?.hostname === 'localhost',
  /** 
   * True if running rules service locally
   * Rules service will call local service instead of dev server
   */
  LOCAL_RULES: process.env.REACT_APP_LOCAL_RULES === 'true',
  AUTH0_DOMAIN: process.env.REACT_APP_AUTH0_DOMAIN,
  AUTH0_CLIENT_ID: process.env.REACT_APP_AUTH0_CLIENT_ID,
  AUTH0_REALM: process.env.REACT_APP_AUTH0_REALM,
  AUTH0_API_AUDIENCE: process.env.REACT_APP_AUTH0_API_AUDIENCE,
  BASE_URL: process.env.REACT_APP_BASE_URL,
  SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN,
  TENANT_KEY: process.env.REACT_APP_TENANT_KEY,
  USER_KEY: process.env.REACT_APP_USER_KEY,
  DOCUSIGN_CLIENT_ID: process.env.REACT_APP_DOCUSIGN_CLIENT_ID,
  HEAP_APP_ID: process.env.REACT_APP_HEAP_APP_ID,
  LAUNCH_DARKLY_CLIENT_ID:
    process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID || '6334b29de243b410eeb37ffc',
  APPCUE_APP_ID: process.env.REACT_APP_APPCUE_APP_ID,
} as const;

export const AUTH0 = {
  domain: ENVIRONMENT.AUTH0_DOMAIN || '',
  clientId: ENVIRONMENT.AUTH0_CLIENT_ID || '',
  callbackUrl: auth0CallbackUrl,
  realm: ENVIRONMENT.AUTH0_REALM,
  apiAudience: ENVIRONMENT.AUTH0_API_AUDIENCE,
  scope: 'openid profile email offline_access', // defaulted `openid profile email`
  responseType: 'token id_token',
  googleConnectionName: 'google-oauth2',
  returnTo: auth0ReturnUrl,
};

export const MARKETING_WEBSITE_URL = 'https://monetizenow.io';

export const SENTRY = {
  dsn: ENVIRONMENT.SENTRY_DSN,
};

export const KEYS = {
  tenantKey: ENVIRONMENT.TENANT_KEY || '',
  userKey: ENVIRONMENT.USER_KEY || '',
};

export const RELEASE = {
  releaseVersion:
    process.env.REACT_RELEASE_VERSION || 'monetizeplatform-ui@0.1',
};

export const DOCUSIGN = {
  clientId:
    ENVIRONMENT.DOCUSIGN_CLIENT_ID || 'd751511b-1158-47fd-aaf6-a95556a65fda',
};

export const METABASE_CONFIG = {
  baseUrl: 'https://reporting.monetizeplatform.com',
  embeddedUrl: 'https://reporting.monetizeplatform.com/auth/sso',
  logoutUrl: 'https://reporting.monetizeplatform.com/auth/logout',
  appearance: {
    return_to: `/?${new URLSearchParams({
      action_buttons: 'true',
      additional_info: 'true',
      breadcrumbs: 'true',
      header: 'true',
      // locale
      new_button: 'true',
      search: 'true',
      side_nav: 'true',
      top_nav: 'true',
    }).toString()}`,
  },
};

export const HEAP_CONFIG = {
  appId: ENVIRONMENT.HEAP_APP_ID,
};
