import { z } from 'zod';
import { getRequiredMessage } from '../utils/messages';

export enum RefundReasonEnum {
  SUCCEEDED = 'SUCCEEDED',
  PENDING = 'PENDING',
  FAILED = 'FAILED',
  CANCELED = 'CANCELED',
}

export const APPROVED_REFUND_STATUS = new Set([
  RefundReasonEnum.SUCCEEDED,
  RefundReasonEnum.PENDING,
]);

export const FAILED_REFUND_STATUS = new Set([
  RefundReasonEnum.FAILED,
  RefundReasonEnum.CANCELED,
]);

export const REFUND_STATUS_DISPLAY: {
  [key in RefundReasonEnum]: string;
} = {
  PENDING: 'Pending',
  FAILED: 'Failed',
  CANCELED: 'Canceled',
  SUCCEEDED: 'Succeeded',
};

export const RefundReasonEnumZ = z.nativeEnum(RefundReasonEnum);

export const RefundReqSchema = z.object({
  refundAmount: z
    .union([z.string(), z.number()])
    .refine(
      (val) => val !== null && val !== undefined && val !== '',
      getRequiredMessage('Amount'),
    )
    .transform((val) => Number(val)),
  description: z.string().max(255),
});

export type IRefundReqSchema = z.infer<typeof RefundReqSchema>;

export const RefundSchema = z.object({
  id: z.string(),
  accountId: z.string(),
  sourceId: z.string(),
  sourceType: z.enum(['payment']),
  amount: z.number(),
  currency: z.string(),
  status: RefundReasonEnumZ,
  description: z.string().optional(),
  settledDate: z.string().refine((val) => !isNaN(Date.parse(val)), {
    message: 'Invalid date format',
  }),
  gatewayReference: z.string(),
  errorCode: z.string().nullable(),
  errorMessage: z.string().nullable(),
  createdBy: z.string(),
  createDate: z.string().refine((val) => !isNaN(Date.parse(val)), {
    message: 'Invalid date format',
  }),
  lastModifiedBy: z.string(),
  modifyDate: z.string().refine((val) => !isNaN(Date.parse(val)), {
    message: 'Invalid date format',
  }),
  paymentMethodId: z.string(),
});

export type IRefundSchema = z.infer<typeof RefundSchema>;
