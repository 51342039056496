import { z } from 'zod';

export enum SalesforceOrgTypeEnum {
  SANDBOX = 'SANDBOX',
  PRODUCTION = 'PRODUCTION',
  CUSTOM = 'CUSTOM',
}
export const SalesforceOrgTypeEnumZ = z.nativeEnum(SalesforceOrgTypeEnum);

export const SalesforceSchema = z.object({
  // refreshToken: z.string().nonempty(getRequiredMessage('Refresh Token')),
  // instanceUrl: z.string().nonempty(getRequiredMessage('Instance URL')),
  customUrl: z.string().nullish(),
  orgType: SalesforceOrgTypeEnumZ,
});

export type ISalesforceSchema = z.infer<typeof SalesforceSchema>;

export interface ISalesforce {
  refreshToken: string;
  instanceUrl: string;
  customUrl: string;
  orgType: SalesforceOrgTypeEnum;
}

// POST /connector/crm/salesforce/sync
export interface CrmResyncResponse {
  syncedEntities: CrmResyncResponseSuccess[];
  errors: CrmResyncResponseError[];
  syncSummary: 'success' | 'error' | 'partial_success';
}

export interface CrmResyncResponseSuccess {
  data: any;
  crmSyncData: {
    id: string;
    type: string;
  };
  type: string;
}

export interface CrmResyncResponseError {
  data: any;
  error: {
    operationKey: string;
    description: string;
  };
  type: string;
}

export interface CrmConnectionError {
  message: boolean;
}

export interface HubSpotConnection {
  hubId: string;
  hubDomain: string;
  userEmail: string;
  connectionError?: CrmConnectionError; // Not yet implemented on BE or in UI
}

export interface CrmConnection {
  hubspot: HubSpotConnection | null;
  salesforce: ISalesforce | null; // FIXME: BE has not yet implemented this
}

export interface CrmHealthCheck {
  crmType: {
    serialized: string;
  };
  error: string;
}

// This will be the eventual ISalesforce shape
//   {
//     "instanceUrl": "https://speed-drive-2653.scratch.my.salesforce.com",
//     "organizationId": "00D8N0000004mMYUAY",
//     "organizationType": "Enterprise Edition",
//     "organizationName": "ABC Corp",
//     "isSandbox": false,
//     "userId": "0055f000003EwifAAC",
//     "username": "austin@monetizenow.io.something",
//     "email": "austin@monetizenow.io",
//     "displayName": "Austin Turner",
//     "connectionError": null
//   }

export type CrmType = 'salesforce' | 'hubspot';

// GET /connector/crm/salesforce/search/accounts/${accountId}/opportunities
export interface OpportunitySearchResponse {
  crm: CrmType;
  results: OpportunitySearchResponseItem[];
}

export interface OpportunitySearchResponseItem {
  closeDate: string;
  id: string;
  name: string;
  stage: string;
}
