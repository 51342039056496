import { Controller } from 'react-hook-form';
import {
  MCustomSelect,
  MFormField,
  MGrid,
  MGridItem,
  MText,
} from '~app/components/Monetize';
import { IOfferingRes, IRateResBaseSchema } from '~app/types';
import { formatInteger } from '~app/utils';
import { getOfferingNameById } from '../../quoteUtils';
import { RateItemContent } from '../quoteOffering/RateItemContent';

export default function BulkRatesRow({
  field,
  offerings,
  errorMessages,
  disabled,
  control,
  index,
  itemListByOfferingId,
  billingFrequency,
  moreThanOneRate,
  handleErrorMessageValidation,
  getBillingFrequencyLabel,
}: {
  field: { offeringId: string };
  offerings: IOfferingRes[];
  errorMessages: Record<string, string> | undefined;
  disabled?: boolean;
  control: any;
  index: number;
  itemListByOfferingId: Record<
    string,
    {
      items: IRateResBaseSchema[];
      isBillingFrequencyMatch: boolean;
    }
  >;
  billingFrequency: string;
  moreThanOneRate: boolean;
  handleErrorMessageValidation: (offeringId: string) => void;
  getBillingFrequencyLabel: (billingFrequency: string) => string;
}) {
  return (
    <MGrid templateColumns="repeat(12, 1fr)" gap={4}>
      <MGridItem colSpan={6} mb={4}>
        <MText alignContent="center" mt={2}>
          {getOfferingNameById(field.offeringId, offerings)}
        </MText>
      </MGridItem>

      <MGridItem colSpan={6} mb={4}>
        <MFormField
          isRequired
          error={errorMessages?.[field?.offeringId]}
          mb={2}
          isDisabled={disabled}
        >
          <Controller
            name={`bulkRate.${index}.rateId`}
            control={control}
            render={({ field: { onChange, ...rest } }) => (
              <MCustomSelect
                isDisabled={disabled}
                placeholder="Select Rate"
                items={itemListByOfferingId[field.offeringId].items ?? []}
                itemTitle="name"
                itemValue="id"
                onChange={(val) => {
                  onChange(val);
                  handleErrorMessageValidation(field.offeringId);
                }}
                renderItemContent={RateItemContent}
                {...rest}
              />
            )}
          />
        </MFormField>

        {errorMessages?.[field?.offeringId] && (
          <MText color="tRed.base" fontSize="13px">
            {errorMessages?.[field?.offeringId]}
          </MText>
        )}

        {itemListByOfferingId[field.offeringId].isBillingFrequencyMatch &&
          moreThanOneRate && (
            <MText fontSize="13px">
              There are{' '}
              {formatInteger(
                itemListByOfferingId[field.offeringId].items.length,
              )}{' '}
              rates available to be billed{' '}
              {getBillingFrequencyLabel(billingFrequency)}. Please review.
            </MText>
          )}
      </MGridItem>
    </MGrid>
  );
}
