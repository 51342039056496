import { Row } from '@tanstack/react-table';
import { ensureBoolean } from '../../../../utils';
import {
  BooleanOption,
  DateFilterComparison,
  DateFilterData,
  DateFilterDataSchema,
  SelectionOption,
  isBetweenComparison,
} from '../DataTableTypes';
import { BLANK_TABLE_FILTER_KEY } from '../tableUtilsNew';

/**
 * Filter Function for Set Filter
 */
export const setFilterFn = (
  row: Row<unknown>,
  columnId: string,
  filterValue: Set<any>,
  addMeta: (meta: any) => void,
) => {
  if (!filterValue || !filterValue.size) {
    return true;
  }
  const value = row.getValue<any>(columnId) ?? BLANK_TABLE_FILTER_KEY;
  return filterValue.has(value);
};

/**
 * Filter Function for Selection column filter
 */
export const selectionFilterFn = (
  row: Row<unknown>,
  columnId: string,
  filterValue: SelectionOption,
  addMeta: (meta: any) => void,
) => {
  if (!filterValue || filterValue === 'ALL') {
    return true;
  }
  const isSelected = row.getIsSelected();
  if (
    (filterValue === 'SELECTED' && isSelected) ||
    (filterValue === 'UNSELECTED' && !isSelected)
  ) {
    return true;
  }
  return false;
};

/**
 * Filter Function for Boolean column filter
 */
export const booleanFilterFn = (
  row: Row<unknown>,
  columnId: string,
  filterValue: BooleanOption,
  addMeta: (meta: any) => void,
) => {
  if (!filterValue || filterValue === 'ALL') {
    return true;
  }
  const value = ensureBoolean(row.getValue<boolean | null>(columnId));
  if (
    (filterValue === 'CHECKED' && value) ||
    (filterValue === 'UNCHECKED' && !value)
  ) {
    return true;
  }
  return false;
};

/**
 * Filter for DATE filter variant
 * Any datetime value is converted to a date string before comparison
 */
export const dateFilterFn = (
  row: Row<unknown>,
  columnId: string,
  filterValue: DateFilterData,
  addMeta: (meta: any) => void,
) => {
  const parseResults = DateFilterDataSchema.safeParse(filterValue);
  if (!parseResults.success) {
    return true;
  }
  let value = row.getValue<string | null>(columnId) ?? null;
  const { comparison, firstDate, secondDate } = parseResults.data;

  // Handle Blank / Not Blank first
  switch (comparison) {
    case DateFilterComparison.IS_BLANK:
      return !value;
    case DateFilterComparison.IS_NOT_BLANK:
      return !!value;
    default:
      break;
  }

  if (!firstDate || (isBetweenComparison(comparison) && !secondDate)) {
    return true;
  }

  if (!value) {
    return false;
  }
  // strip time to allow for proper comparison with datetime
  value = value.substring(0, 10);

  switch (comparison) {
    case DateFilterComparison.EQUALS:
      return value === firstDate;
    case DateFilterComparison.GREATER_THAN:
      return value > firstDate;
    case DateFilterComparison.GREATER_THAN_EQUAL:
      return value >= firstDate;
    case DateFilterComparison.LESS_THAN:
      return value < firstDate;
    case DateFilterComparison.LESS_THAN_EQUAL:
      return value <= firstDate;
    case DateFilterComparison.BETWEEN_EXCLUSIVE: {
      if (!secondDate) {
        return true;
      }
      return value > firstDate && value < secondDate;
    }
    case 'BETWEEN_INCLUSIVE': {
      if (!secondDate) {
        return true;
      }
      return value >= firstDate && value <= secondDate;
    }
    default:
      return true;
  }
};
