import { TransactableSourceType, TransactableTypeDisplay } from '../../types';
import { MText } from '../Monetize';

interface TransactionApplicationTableHeaderProps {
  sourceType: TransactableSourceType;
}

export const TransactionApplicationTableHeader = ({
  sourceType,
}: TransactionApplicationTableHeaderProps) => {
  let entityName = TransactableTypeDisplay[sourceType];
  return (
    <>
      <HeaderLabel alignment="left" label="Invoice" colSpan={2} />
      <HeaderLabel alignment="right" label="Amount" />
      <HeaderLabel
        alignment="center"
        label="Applied from other Payments & Credits"
      />
      <HeaderLabel
        alignment="center"
        label={`Applied from this ${entityName}`}
      />
      <HeaderLabel alignment="right" label="Amount Due" />
      <HeaderLabel alignment="right" label="Status" />
    </>
  );
};

const HeaderLabel = ({
  alignment,
  colSpan,
  label,
}: {
  alignment: 'left' | 'center' | 'right';
  colSpan?: number;
  label: string;
}) => {
  let justifySelf = 'flex-start';
  if (alignment === 'center') {
    justifySelf = 'center';
  } else if (alignment === 'right') {
    justifySelf = 'flex-end';
  }

  return (
    <MText
      role="columnheader"
      fontWeight="600"
      fontSize="md"
      gridColumn={colSpan ? `span ${colSpan}` : undefined}
      textAlign={alignment}
      justifySelf={justifySelf}
      maxW="13rem"
    >
      {label}
    </MText>
  );
};
