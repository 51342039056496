import { Text, TextProps } from '@chakra-ui/react';
import { MAvatar } from '../../MAvatar';

type UserAvatarAndNameProps = {
  userName: string;
  isAvatarOnly?: boolean;
  textProps?: TextProps;
};

export const UserAvatarAndName = ({
  userName,
  textProps,
  isAvatarOnly = false,
}: UserAvatarAndNameProps) => {
  return (
    <>
      <MAvatar name={userName} mr="2" ml={1} />
      {!isAvatarOnly && (
        <Text noOfLines={1} display="inline-block" {...textProps}>
          {userName}
        </Text>
      )}
    </>
  );
};
