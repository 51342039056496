import { ListItem } from '@chakra-ui/react';
import useFlags from 'launchdarkly-react-client-sdk/lib/useFlags';
import { useRecoilState } from 'recoil';
import { IBillGroupResp, IQuoteRespSchema } from '~app/types';
import { MText, MUnorderedList } from '../../../../components/Monetize';
import { QuoteBannerItem } from './QuoteBannerItem';
import { bannerDismissedSelector, checkUsageDate } from './quoteBanner.utils';

const BannerContent = ({ bannerTextList }: { bannerTextList: string[] }) => {
  if (bannerTextList.length === 0) {
    return null;
  }

  if (bannerTextList.length === 1) {
    return <MText>{bannerTextList[0]}</MText>;
  }

  return (
    <MUnorderedList>
      {bannerTextList.map((bannerText) => (
        <ListItem key={bannerText}>
          <MText>{bannerText}</MText>
        </ListItem>
      ))}
    </MUnorderedList>
  );
};

export function UsageProductBanner({
  quote,
  billGroup,
}: {
  quote: IQuoteRespSchema;
  billGroup: IBillGroupResp;
}) {
  const { disableInactiveProductBanner } = useFlags();
  const [dismissed, setDismissed] = useRecoilState(
    bannerDismissedSelector([quote.id, 'UsageProductWarningBanner']),
  );
  const bannerTextList = checkUsageDate(quote, billGroup);

  if (disableInactiveProductBanner || dismissed || !bannerTextList.length) {
    return null;
  }

  return (
    <QuoteBannerItem onDismiss={() => setDismissed(true)} type="warning">
      <BannerContent bannerTextList={bannerTextList} />
    </QuoteBannerItem>
  );
}
