import { popoverAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

/**
 * {@link https://chakra-ui.com/docs/components/popover/theming}
 */

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(popoverAnatomy.keys);

const baseStyle = definePartsStyle((props) => ({
  content: {},
  closeButton: {
    bg: 'none',
    color: 'tPurple.dark',
    iconColorHover: 'tPurple.base',
    p: 0,
    btnSize: 4,
    containerSize: 4,
    top: '4',
    right: '4',
    _hover: {
      bg: 'none',
    },
  },
  header: {
    p: 0,
    borderBottomWidth: 0,
    fontWeight: 600,
  },
  body: {
    p: 4,
  },
  footer: {
    px: 0,
    mx: 4,
    py: 4,
  },
}));

const sizes = {
  sm: definePartsStyle({
    content: {
      w: '206px',
    },
  }),
  smaller: definePartsStyle({
    content: {
      w: '222px',
    },
  }),
};

/**
 * TODO: All dropdowns should have their own variant
 * and normal popovers should have the base style
 */
const popover = definePartsStyle({
  content: {
    p: 4,
    border: '0',
    boxShadow: 'popover',
  },
});

const variants = {
  popover,
};

export const popoverTheme = defineMultiStyleConfig({
  baseStyle,
  sizes,
  variants,
});
