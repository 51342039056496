import { useFormContext } from 'react-hook-form';
import {
  CustomFieldDataForm,
  CustomFieldDataFormProps,
} from '~app/components/CustomFields/CustomFieldDataForm';
import {
  CustomFieldEntityEnum,
  ICustomFieldRecordSchema,
  IQuoteRequestSchema,
} from '~app/types';
import { nullifyEmptyStrings } from '~app/utils/misc';
import { useQuoteContext } from '../quoteContext';

interface QuoteCustomFieldsProps extends Partial<CustomFieldDataFormProps> {}

export const QuoteCustomFields = ({ ...rest }: QuoteCustomFieldsProps) => {
  const { quoteData, isReadOnly } = useQuoteContext();
  const { setValue } = useFormContext<IQuoteRequestSchema>();

  if (
    !quoteData.quote?.customFields ||
    !quoteData.quote ||
    !quoteData.quote?.id
  ) {
    return null;
  }

  const handleValueUpdate = (
    customFields: ICustomFieldRecordSchema,
    shouldPersist: boolean = true,
  ) => {
    setValue('customFields', customFields);
    if (shouldPersist) {
      quoteData.updateQuote(quoteData.quote!.id, {
        ...quoteData.quote,
        customFields: nullifyEmptyStrings(customFields),
      } as any);
    }
  };

  return (
    <CustomFieldDataForm
      entity={CustomFieldEntityEnum.QUOTE}
      value={quoteData.quote?.customFields}
      setValue={handleValueUpdate}
      mode="accordion"
      mt="4"
      fieldColSpan={4}
      isReadOnly={isReadOnly}
      showDivider
      {...rest}
    />
  );
};
