import { zodResolver } from '@hookform/resolvers/zod';
import React, { FunctionComponent as FC, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { handleApiErrorToast } from '~app/api/axios';
import { useAddTenantApiKey } from '~app/api/settingsService';
import { useGetUserRoles } from '~app/api/usersService';
import {
  MBox,
  MButton,
  MCenterModal,
  MFormField,
  MGrid,
  MGridItem,
  MInput,
  MStack,
} from '~components/Monetize';
import {
  ISettingApiKeyFormSchema,
  SettingApiKeyFormSchema,
  UserRoleEnum,
} from '~types';

interface APIKeyFormProps {
  children?: React.ReactNode;
  isOpen: boolean;
  showApiKeyModal: (apiKey: string) => void;
  onClose: () => void;
}

const formDefault: ISettingApiKeyFormSchema = {
  name: '',
  roleIds: [],
};
const APIKeyForm: FC<APIKeyFormProps> = (props: APIKeyFormProps) => {
  const { isOpen, onClose, showApiKeyModal } = props;
  const { data: userRoles, isLoading: roleLoading } = useGetUserRoles({
    first: 0,
    rows: 100,
    page: 0,
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm<ISettingApiKeyFormSchema>({
    resolver: zodResolver(SettingApiKeyFormSchema.passthrough()),
    mode: 'onSubmit',
    defaultValues: formDefault,
  });

  useEffect(() => {
    if (isOpen) {
      // pass
    } else {
      reset(formDefault);
    }
  }, [reset, isOpen]);

  const { mutateAsync: doAddApiKey, isLoading: saveLoading } =
    useAddTenantApiKey();

  const onSubmit = async (requestData: ISettingApiKeyFormSchema) => {
    try {
      const tenantAdminRole = userRoles?.content?.find(
        (role: any) => role.name === UserRoleEnum.TENANT_ADMIN,
      );

      const body = {
        name: requestData.name,
        roleIds: tenantAdminRole && [tenantAdminRole.id],
      };
      const createdApiKey = await doAddApiKey(body);
      showApiKeyModal(createdApiKey.apiKey);
      onClose();
    } catch (err) {
      handleApiErrorToast(err);
    }
  };

  const onError = (errs: any, event: any) => {
    // pass handle error
  };

  return (
    <MCenterModal
      isOpen={isOpen}
      onClose={onClose}
      modalTitle="New API Key"
      modalBodyProps={{ pt: 0 }}
      renderFooter={() => (
        <MStack
          spacing={4}
          direction="row"
          align="center"
          justify="right"
          flex={1}
        >
          <MButton onClick={onClose} variant="cancel" minW="auto">
            Cancel
          </MButton>
          <MButton
            variant="primary"
            isLoading={saveLoading || roleLoading}
            onClick={handleSubmit(onSubmit, onError)}
            minW="auto"
          >
            Save
          </MButton>
        </MStack>
      )}
    >
      <MBox>
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <MGrid templateColumns="repeat(12, 1fr)" gap={4}>
            <MGridItem colSpan={12}>
              <MFormField
                error={errors.name}
                label="Name"
                isRequired
                hint="Name is used for records created or modified by this API key"
              >
                <Controller
                  name="name"
                  control={control}
                  defaultValue=""
                  render={({ field: { onBlur, ...rest } }) => (
                    <MInput maxW="30rem" {...rest} />
                  )}
                />
              </MFormField>
            </MGridItem>
          </MGrid>
        </form>
      </MBox>
    </MCenterModal>
  );
};

APIKeyForm.defaultProps = {
  children: null,
};

export default APIKeyForm;
