import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { handleApiErrorToast } from '~app/api/axios';
import { MButton, MCenterModal, MStack } from '~app/components/Monetize';
import {
  IPayment,
  ManualPaymentUpdateReq,
  ManualPaymentUpdateReqSchema,
} from '~types';
import { useUpdateManualPayment } from '../../../api/paymentsService';
import { CreatePaymentWithManualPayment } from './CreatePaymentWithManualPayment';

type UpdatePaymentModalProps = {
  accountId: string;
  payment: IPayment;
  onClose: (reload: boolean) => void;
};

export const UpdatePaymentModal = ({
  accountId,
  payment,
  onClose,
}: UpdatePaymentModalProps) => {
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const { mutateAsync: doUpdatePayment } = useUpdateManualPayment();

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isValid },
  } = useForm<ManualPaymentUpdateReq>({
    resolver: zodResolver(ManualPaymentUpdateReqSchema),
    mode: 'onChange',
    defaultValues: {
      referenceNo: payment.referenceNo || '',
      paymentDate: payment.paymentDate || '',
      description: payment.description || '',
    },
  });

  const onSubmit = async (payload: ManualPaymentUpdateReq) => {
    setIsSaving(true);
    try {
      await doUpdatePayment({
        accountId,
        paymentId: payment.id,
        payload,
      });
      handleClose(true);
    } catch (err) {
      handleApiErrorToast(err);
    } finally {
      setIsSaving(false);
    }
  };

  function handleClose(reload = false) {
    onClose(reload);
    reset();
  }

  if (!payment) {
    return null;
  }

  return (
    <MCenterModal
      size="md"
      isOpen
      onClose={handleClose}
      modalTitle="Update Payment"
      renderFooter={() => (
        <MStack
          spacing={4}
          direction="row"
          align="center"
          justify="right"
          flex={1}
        >
          <MButton
            onClick={() => handleClose()}
            variant="cancel"
            minW="auto"
            isDisabled={isSaving}
          >
            Cancel
          </MButton>
          <MButton
            form="payment-form"
            type="submit"
            variant="primary"
            isDisabled={!isValid}
            isLoading={isSaving}
            minW="auto"
          >
            Update
          </MButton>
        </MStack>
      )}
    >
      <form id="payment-form" onSubmit={handleSubmit(onSubmit)}>
        <CreatePaymentWithManualPayment
          mode="UPDATE"
          control={control as any}
          errors={errors as any}
          amount={payment.amount}
          currency={payment.currency}
          isSaving={isSaving}
        />
      </form>
    </MCenterModal>
  );
};
