import { loadStripe } from '@stripe/stripe-js';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { doCreatePaymentMethodByAccount } from '~app/api/accountsService';
import { handleApiErrorToast } from '~app/api/axios';
import { useGetPaymentGatewayById } from '~app/api/paymentsService';
import { getAccountPaymentMethodRoute } from '~app/constants/routes';
import { useQuery } from '~app/hooks';
import { useAuth } from '~app/services/auth0';
import { useToast } from '~app/services/toast';
import { tempPaymentMethodState } from '~app/store/global.store';
import { IPaymentGateway, IPaymentMethodReq } from '~app/types';
import { processClientSecretId } from '~app/utils/stripe';
import AppLoading from '~components/App/AppLoading';

export const PaymentMethodsStripeCallback = () => {
  const query = useQuery();
  const { accountId = '', paymentGatewayId = '' }: any = useParams();
  const { tenantId } = useAuth();
  const navigate = useNavigate();
  const { addToast } = useToast();

  // load saved payment method form in local state
  const [tempPaymentMethod, setTempPaymentMethod] = useRecoilState(
    tempPaymentMethodState,
  );
  const clientSecretId = query.get('setup_intent_client_secret');

  const { isLoading: loading } = useGetPaymentGatewayById(
    { tenantId, id: paymentGatewayId },
    {
      onSuccess: async (gateway: IPaymentGateway) => {
        if (!gateway) {
          throw new Error('Sorry, failed to load payment gateway.');
        }
        const stripe = await loadStripe(gateway.publicKey);

        if (!stripe) {
          throw new Error('Sorry, failed to load stripe.');
        }
        if (!clientSecretId) {
          throw new Error('Sorry, failed to connect.');
        }
        if (!tempPaymentMethod) {
          throw new Error('Sorry, payment method information is incorrect.');
        }

        const { setupIntent, message } = await processClientSecretId(
          stripe,
          clientSecretId,
        );

        const newPaymentMethod: IPaymentMethodReq = {
          billingDetails: {
            fullName: tempPaymentMethod.contact?.fullName || '',
            email: tempPaymentMethod.contact?.email,
            phone: tempPaymentMethod.contact?.phone,
            title: tempPaymentMethod.contact?.title,
            line1: tempPaymentMethod.contact?.address?.line1,
            line2: tempPaymentMethod.contact?.address?.line2,
            city: tempPaymentMethod.contact?.address?.city,
            state: tempPaymentMethod.contact?.address?.state,
            postalCode: tempPaymentMethod.contact?.address?.postalCode,
            country: tempPaymentMethod.contact?.address?.country,
          },
          paymentMethodName: tempPaymentMethod.paymentMethodName,
          paymentToken: setupIntent.id,
          paymentGatewayId: gateway.id,
          oneTime: false,
          testPaymentMethod: true,
        };

        await doCreatePaymentMethodByAccount(accountId, newPaymentMethod);

        // make sure to clear
        setTempPaymentMethod(null);

        addToast({
          summary: 'Stripe Connected.',
          detail: message,
          severity: 'info',
        });
        navigate(getAccountPaymentMethodRoute(accountId), {});
      },
      onError: (error) => {
        handleApiErrorToast(error);
      },
    },
  );

  if (loading) {
    return <AppLoading loadingText="Validating connection..." />;
  }

  return null;
};
