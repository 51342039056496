import {
  Box,
  Icon,
  RadioGroupProps,
  Flex,
  Spinner,
  Radio,
  RadioGroup,
  Stack,
} from '@chakra-ui/react';
import React, { FunctionComponent as FC } from 'react';
import { useSelectData } from './MCustomSelect/components/useSelectData';
import MText from './MText';

interface MCustomRadioGroupProps extends Omit<RadioGroupProps, 'children'> {
  children?: any;
  items?: any[];
  itemTitle?: string;
  itemValue?: string;
  itemDescription?: string | React.ReactElement;
  endpoint?: string;
  loadAll?: boolean;
  disabled?: boolean;
}

const MCustomRadioGroup: FC<MCustomRadioGroupProps> = React.forwardRef<
  any,
  MCustomRadioGroupProps
>((props: MCustomRadioGroupProps, ref) => {
  const {
    children,
    items: externalItems,
    itemTitle,
    itemValue,
    itemDescription,
    endpoint,
    loadAll,
    disabled,
    value,
    onChange,
    placeholder,
    ...rest
  } = props;
  const { getValue, items, getTitle, getTitleFromValue, loading } =
    useSelectData({ endpoint, loadAll, itemValue, externalItems, itemTitle });

  const handleChange = (item: any) => {
    if (onChange) {
      onChange(getValue(item));
    }
  };
  const getDescription = (item: any) =>
    item ? item[itemDescription as any] : '';

  const renderItems = () =>
    items.map((item: any, index: number) => {
      const val = getValue(item);
      const isSelected = val === value;
      const isDisabled = item.disabled || disabled;
      return (
        <Box
          key={index}
          onClick={() => !isDisabled && handleChange(item)}
          opacity={isDisabled && 0.8}
        >
          <Radio isChecked={isSelected} disabled={isDisabled}>
            <MText
              color={isDisabled ? 'tGray.darkPurple' : 'tPurple.base'}
              isTruncated
              noOfLines={1}
            >
              {getTitle(item)}
            </MText>
          </Radio>
          {!!itemDescription && (
            <MText color="tGray.darkPurple" ml="5">
              {getDescription(item)}
            </MText>
          )}
        </Box>
      );
    });

  if (loading) {
    return <Spinner size="xs" />;
  }

  return (
    <RadioGroup position="relative" w="100%" {...rest}>
      <Stack spacing={4} direction="column">
        {renderItems()}
      </Stack>
    </RadioGroup>
  );
});

export const MIconAction = ({ icon }: { icon: any }) => (
  <Icon
    fontSize="lg"
    as={icon}
    minWidth="0"
    background="none"
    _hover={{
      color: 'tIndigo.base',
      background: 'none',
    }}
  />
);

export const MCustomRadioGroupItem = ({ children, ...rest }: any) => (
  <Flex
    color="tIndigo.base"
    p="2"
    borderRadius="2"
    cursor="pointer"
    _hover={{ color: 'white', background: 'tIndigo.base' }}
    alignItems="center"
    {...rest}
  >
    {children}
  </Flex>
);

MCustomRadioGroup.defaultProps = {
  children: null,
  items: [],
  itemTitle: 'title',
  itemValue: 'value',
  itemDescription: '',
  endpoint: '',
  loadAll: true,
  disabled: false,
};

export default MCustomRadioGroup;
