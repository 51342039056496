import { MouseEventHandler } from 'react';
import { MButton } from '~app/components/Monetize';
import { useFlags } from '~app/services/launchDarkly';
import {
  IOfferingRes,
  IQuoteItemReqSchema,
  IQuoteOfferingRespSchema,
  SelectedProductsWithinOfferingForm,
} from '~app/types';
import { ManageProductsModal } from './ManageProductsModal';

interface IManageProductsProps {
  offering?: IOfferingRes | null;
  quoteItems: IQuoteItemReqSchema[];
  quoteOffering?: IQuoteOfferingRespSchema | null;
  watchRateId?: string | null;
  isManageProductDisabled: boolean;
  updateOfferingItems: (offering: SelectedProductsWithinOfferingForm) => void;
  modalConfig: {
    isOpen: boolean;
    onClose: () => void;
    onOpen: MouseEventHandler<HTMLButtonElement>;
    onDrawerClose: () => void;
    onDrawerOpen: () => void;
  };
  setDrawerOpen: (value: boolean) => void;
}

export const ManageProducts = ({
  offering,
  quoteItems,
  quoteOffering,
  watchRateId,
  isManageProductDisabled,
  modalConfig,
  updateOfferingItems,
  setDrawerOpen,
}: IManageProductsProps) => {
  const { isOpen, onClose, onDrawerClose, onDrawerOpen } = modalConfig;
  const { allowOptionalProducts } = useFlags();

  const handleClose = () => {
    onDrawerClose();
    onClose();
  };

  const handleOpen = () => {
    onDrawerOpen();
    setDrawerOpen(true);
  };

  if (quoteItems && !quoteItems?.length) {
    return null;
  }

  return (
    <>
      {/* if all products are mandatory do not show this button */}
      {watchRateId && allowOptionalProducts && (
        <MButton
          variant="tertiary"
          onClick={handleOpen}
          isDisabled={isManageProductDisabled}
        >
          Manage Products ({quoteItems?.length}/
          {offering?.offeringProducts.length})
        </MButton>
      )}
      {isOpen && offering && (
        <ManageProductsModal
          offering={offering}
          quoteItems={quoteItems}
          onSave={updateOfferingItems}
          onClose={handleClose}
          quoteOffering={quoteOffering}
        />
      )}
    </>
  );
};
