import { HStack } from '@chakra-ui/react';
import { addMonths } from 'date-fns/addMonths';
import { endOfMonth } from 'date-fns/endOfMonth';
import { format as formatDate } from 'date-fns/format';
import { parse as parseDate } from 'date-fns/parse';
import { startOfDay } from 'date-fns/startOfDay';
import { startOfMonth } from 'date-fns/startOfMonth';
import { useEffect, useState } from 'react';
import { handleApiErrorToast } from '~app/api/axios';
import { useExportRevRec, useRevRecSchedule } from '~app/api/revenueService';
import {
  MBox,
  MButton,
  MCurrencySelect,
  MCustomSelect,
  MFlex,
  MFormField,
  MPageContainer,
  MPageHeader,
  MPageLoader,
} from '~app/components/Monetize';
import MEmptyDataPlaceholder from '~app/components/Monetize/MEmptyDataPlaceholder';
import { MReadOnlyInput } from '~app/components/Monetize/MReadOnlyInput';
import { useCurrencies } from '~app/hooks/useCurrencies';
import { useDocumentHead } from '~app/services/documentHead';
import { MimeTypeEnum } from '~app/types';
import { getMonthlyDropdownItems } from '~app/utils';
import { saveFile } from '~app/utils/download';
import { RevRecChart } from './RevRecChart';
import { RevRecTable } from './RevRecTable';

const DEFAULT_CURRENCY = 'USD';
const DEFAULT_FROM = formatDate(
  addMonths(startOfMonth(new Date()), -1),
  'yyyy-MM',
);
const DEFAULT_TO = formatDate(addMonths(endOfMonth(new Date()), 11), 'yyyy-MM');
const DEFAULT_RECOGNIZED_FROM = formatDate(
  addMonths(endOfMonth(new Date()), 11),
  'yyyy-MM',
);

export const RevRecPage = () => {
  const { setDocTitle } = useDocumentHead();
  useEffect(() => {
    setDocTitle('Revenue');
  }, []);

  const {
    currencies,
    defaultCurrency,
    isLoading: isLoadingCurrencies,
  } = useCurrencies();

  const [startPeriod, setStartPeriod] = useState(DEFAULT_FROM);
  const [endPeriod, setEndPeriod] = useState(DEFAULT_TO);
  const [endDateFormatted, setEndDateFormatted] = useState(DEFAULT_TO);
  const [recognizedAsOfPeriod, setRecognizedAsOfPeriod] = useState(
    DEFAULT_RECOGNIZED_FROM,
  );
  const [currency, setCurrency] = useState<string>(DEFAULT_CURRENCY);
  const [startDateOptions] = useState(() =>
    getMonthlyDropdownItems(new Date(2021, 0, 1), new Date()),
  );
  const [endDateOptions, setEndDateOptions] = useState<
    { label: string; value: string }[]
  >([]);

  useEffect(() => {
    if (defaultCurrency) {
      setCurrency(defaultCurrency);
    }
  }, [defaultCurrency]);

  useEffect(() => {
    setEndDateFormatted(
      formatDate(parseDate(endPeriod, 'yyyy-MM', new Date()), 'MMM yyyy'),
    );
  }, [endPeriod]);

  useEffect(() => {
    const newEndDate = addMonths(
      parseDate(startPeriod, 'yyyy-MM', startOfMonth(new Date())),
      11,
    );
    setEndPeriod(formatDate(newEndDate, 'yyyy-MM'));
    setRecognizedAsOfPeriod(formatDate(newEndDate, 'yyyy-MM'));
    setEndDateOptions(getMonthlyDropdownItems(newEndDate, 6));
  }, [startPeriod]);

  const { data: revRecData, isLoading } = useRevRecSchedule(
    { startPeriod, endPeriod, recognizedAsOfPeriod, currency },
    {
      enabled:
        !!startPeriod && !!endPeriod && !!recognizedAsOfPeriod && !!currency,
      onError: (error) => {
        handleApiErrorToast(error);
      },
    },
  );

  const { mutate: doDownload } = useExportRevRec({
    // eslint-disable-next-line @typescript-eslint/no-shadow
    onSuccess: (csv, { startPeriod, endPeriod }) => {
      saveFile(
        csv,
        `rev-rec-waterfall_${startPeriod}_to_${endPeriod}.csv`,
        MimeTypeEnum.CSV,
      );
    },
    onError: () => {},
  });

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const handleStartDateChange = (startDate: string) => {
    const newStartDate = parseDate(
      startDate,
      'yyyy-MM',
      startOfDay(new Date()),
    );
    setStartPeriod(formatDate(newStartDate, 'yyyy-MM'));
  };
  const handleCurrencyChange = (value: string) => {
    setCurrency(value);
  };
  const isEmpty = !isLoading && revRecData?.recognitionSchedule.length === 0;

  return (
    <MPageContainer enableAccountSearch maxWidth="100%" data-testid="revenue">
      <MPageHeader title="Revenue Recognition" />
      <MBox w="100%" h="100%">
        {(isLoading || isLoadingCurrencies) && <MPageLoader />}

        {!isLoading && !isLoadingCurrencies && revRecData && (
          <>
            <MFlex justifyContent="space-between" alignItems="end" mb={2}>
              <HStack gap={4}>
                <MFormField label="Start Date">
                  <MCustomSelect
                    showQueryInput
                    items={startDateOptions}
                    itemTitle="label"
                    itemValue="value"
                    value={startPeriod}
                    onChange={(e) => handleStartDateChange(`${e}`)}
                  />
                </MFormField>
                <MFormField label="Until" maxW={20}>
                  <MReadOnlyInput value={endDateFormatted} />
                </MFormField>
                {currencies.length > 1 && (
                  <MFormField label="Currency" maxW={24}>
                    <MCurrencySelect
                      value={currency}
                      onChange={(e) => handleCurrencyChange(`${e}`)}
                    />
                  </MFormField>
                )}
                <MFormField label="Recognized from">
                  <MCustomSelect
                    showQueryInput
                    items={endDateOptions}
                    itemTitle="label"
                    itemValue="value"
                    value={recognizedAsOfPeriod}
                    onChange={(e) => setRecognizedAsOfPeriod(`${e}`)}
                  />
                </MFormField>
              </HStack>
              {!isEmpty && (
                <MFlex>
                  <MButton
                    variant="secondary"
                    onClick={() =>
                      doDownload({
                        startPeriod,
                        endPeriod,
                        recognizedAsOfPeriod,
                        currency: DEFAULT_CURRENCY,
                      })
                    }
                  >
                    Download CSV
                  </MButton>
                </MFlex>
              )}
            </MFlex>
            {isEmpty ? (
              <>
                <MEmptyDataPlaceholder
                  mainMessage="No revenue data"
                  smallMessage="Choose a different period range."
                />
              </>
            ) : (
              <>
                <RevRecTable revRecData={revRecData} />
                <RevRecChart revRecData={revRecData} mt={12} pb={24} />
              </>
            )}
          </>
        )}
      </MBox>
    </MPageContainer>
  );
};
