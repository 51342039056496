import { Flex, FlexProps, Icon, Text } from '@chakra-ui/react';
import { FunctionComponent as FC } from 'react';
import { MdAutorenew, MdCheckCircle, MdError } from 'react-icons/md';

interface MLastSavedStatusProps extends FlexProps {
  isLoading: boolean;
  isError: boolean;
}

export const MLastSavedStatus: FC<MLastSavedStatusProps> = ({
  isLoading,
  isError,
  ...rest
}: MLastSavedStatusProps) => {
  return (
    <Flex alignItems="center" {...rest}>
      {isLoading && (
        <>
          <Icon as={MdAutorenew} h={4} w={4} color="tGray.darkPurple" />
          <Text
            ml={1}
            color="tGray.darkPurple"
            fontSize={'sm'}
            fontWeight="normal"
          >
            Saving..
          </Text>
        </>
      )}

      {!isLoading && isError && (
        <>
          <Icon as={MdError} h={4} w={4} color="tRed.base" />
          <Text ml={1} color="tRed.base" fontSize={'sm'} fontWeight="normal">
            Could not save changes
          </Text>
        </>
      )}

      {!isLoading && !isError && (
        <>
          <Icon as={MdCheckCircle} color="tGreen.approval" h={4} w={4} />
          <Text
            ml={1}
            color="tGreen.approval"
            fontSize={'sm'}
            fontWeight="normal"
          >
            Saved
          </Text>
        </>
      )}
    </Flex>
  );
};
