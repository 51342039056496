import {
  Accordion,
  AccordionItem,
  AccordionPanel,
  Spinner,
} from '@chakra-ui/react';
import React, {
  FunctionComponent as FC,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { doGetCreditNotesForInvoice } from '~app/api/accountsService';
import { handleApiErrorToast } from '~app/api/axios';
import {
  MAccordionCustomButton,
  MAccordionCustomButtonItem,
  MFlex,
  MStack,
  MText,
} from '~app/components/Monetize';
import { PAPER_PAGE_MAX_WIDTH } from '~app/constants/quotes';
import { ICreditNoteSchema } from '~app/types/creditNoteTypes';
import { formatCurrency } from '~app/utils';
import { toDateShort } from '~app/utils/dates';
import { IInvoiceRespSchema } from '~types';

export interface InvoiceCreditNotesProps {
  invoice: IInvoiceRespSchema;
}

const InvoiceCreditNotes: FC<InvoiceCreditNotesProps> = (
  props: InvoiceCreditNotesProps,
) => {
  const { invoice } = props;

  const [loading, setLoading] = useState(false);
  const [creditNotes, setCreditNotes] = useState<ICreditNoteSchema[]>([]);

  const getCreditNotes = useCallback(async () => {
    try {
      setLoading(true);
      const creditNotesResponse = await doGetCreditNotesForInvoice(invoice.id);
      setCreditNotes(creditNotesResponse.content);
    } catch (error) {
      handleApiErrorToast(error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (invoice) {
      getCreditNotes();
    }
  }, [invoice]);

  return (
    <MStack w="100%" maxWidth={PAPER_PAGE_MAX_WIDTH}>
      {creditNotes.length && (
        <MText as="h2" fontWeight="bold" fontSize="lg" mt={8} mb={2}>
          Credit Notes
          {loading && <Spinner size="xs" ml={2} />}
        </MText>
      )}
      <Accordion allowMultiple w="100%" mt={4}>
        {creditNotes.map(
          ({
            amount,
            id,
            status,
            type,
            refundable,
            createDate,
            description,
          }) => (
            <AccordionItem
              key={id}
              mt={4}
              borderWidth="1px"
              borderRadius="md"
              borderColor="tGray.back"
            >
              {({ isExpanded }) => (
                <>
                  <MAccordionCustomButton
                    isExpanded={isExpanded}
                    label={toDateShort(createDate)}
                  >
                    <MAccordionCustomButtonItem
                      align="right"
                      label="Amount:"
                      value={formatCurrency(amount)}
                      isExpanded={isExpanded}
                      hideWhenExpanded
                    />
                  </MAccordionCustomButton>
                  <AccordionPanel pr={8}>
                    <MFlex justifyContent="space-between">
                      <MStack spacing={24} direction="row">
                        <StackedLabelValue
                          align="left"
                          label="Date"
                          value={toDateShort(createDate)}
                        />
                        <StackedLabelValue
                          align="left"
                          label="Refundable"
                          value={refundable ? 'Yes' : 'No'}
                        />
                        <StackedLabelValue
                          align="left"
                          label="Comments"
                          value={description}
                        />
                      </MStack>
                      <StackedLabelValue
                        align="right"
                        label="Amount"
                        value={formatCurrency(+amount)}
                      />
                    </MFlex>
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>
          ),
        )}
      </Accordion>
    </MStack>
  );
};

interface StackedLabelValueProps {
  label: React.ReactNode;
  value: React.ReactNode;
  align?: 'left' | 'right';
}

const StackedLabelValue: FC<StackedLabelValueProps> = (
  props: StackedLabelValueProps,
) => {
  const { label, value, align = 'right' } = props;
  return (
    <MStack spacing="0px">
      <MText py={0} m={0} fontWeight="bold" textAlign={align} fontSize="md">
        {label}
      </MText>{' '}
      <MText py={0} m={0} textAlign={align} fontSize="sm">
        {value}
      </MText>
    </MStack>
  );
};

StackedLabelValue.defaultProps = {
  align: 'right',
};

export default InvoiceCreditNotes;
