import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { ZodObject } from 'zod';
import { useUpdateInvoiceCustomFields } from '~app/api/accountsService';
import { handleApiErrorToast } from '~app/api/axios';
import { CustomFormDataFormField } from '~app/components/CustomFields/CustomFormDataFormField';
import {
  MButton,
  MCenterModal,
  MSimpleGrid,
  MStack,
} from '~app/components/Monetize';
import { useACL } from '~app/services/acl/acl';
import { useToast } from '~app/services/toast';
import {
  CustomFieldEntityEnum,
  CustomFieldRecordSchema,
  ICustomFieldRecordSchema,
  ICustomFieldResSchema,
} from '~app/types';
import { convertFormValuesToReq } from '~app/utils/customFields';
import { nullifyEmptyStrings } from '~app/utils/misc';

type EditCustomFieldsModalProp = {
  invoiceId: string;
  isOpen: boolean;
  customFieldList: ICustomFieldResSchema[];
  customFiledValues?: ICustomFieldRecordSchema;
  zodSchemasByEntity: Record<CustomFieldEntityEnum, ZodObject<any>>;
  onClose: () => void;
  refreshData: () => void;
};

export const EditCustomFieldsModal = ({
  invoiceId,
  isOpen,
  customFieldList,
  customFiledValues,
  zodSchemasByEntity,
  onClose,
  refreshData,
}: EditCustomFieldsModalProp) => {
  const { canDo } = useACL();
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const zodSchema = zodSchemasByEntity[CustomFieldEntityEnum.INVOICE];
  const canUpdateBilling = canDo([['billing', 'update']]);

  const { mutateAsync: doUpdateInvoiceCustomFields } =
    useUpdateInvoiceCustomFields();

  const { control, handleSubmit, getValues } =
    useForm<ICustomFieldRecordSchema>({
      resolver: zodResolver(
        zodSchemasByEntity[CustomFieldEntityEnum.INVOICE] ??
          CustomFieldRecordSchema,
      ),
      mode: 'onChange',
      defaultValues: customFiledValues,
      values: customFiledValues,
      resetOptions: {
        keepDirtyValues: true,
        keepErrors: true,
      },
    });

  const onSubmit = async (formData: ICustomFieldRecordSchema) => {
    setLoading(true);

    try {
      const customFields = { ...nullifyEmptyStrings(formData) };
      const parsedCustomFields = CustomFieldRecordSchema.parse(customFields);
      await doUpdateInvoiceCustomFields({
        invoiceId: invoiceId,
        customFields: parsedCustomFields,
      });
      addToast({
        summary: 'Updated',
        detail: 'Additional fields have been successfully updated.',
        severity: 'success',
      });
      refreshData();
      onClose();
    } catch (err) {
      handleApiErrorToast(err);
    } finally {
      setLoading(false);
    }
  };

  const renderFooter = () => (
    <MStack spacing={4} direction="row" align="center" justify="right" flex={1}>
      <MButton variant="cancel" onClick={onClose} minW="auto">
        Cancel
      </MButton>
      <MButton
        form="edit-custom-fields-form"
        isLoading={loading}
        variant="primary"
        type="submit"
        minW="auto"
      >
        Save
      </MButton>
    </MStack>
  );

  return (
    <MCenterModal
      isOpen={isOpen}
      onClose={onClose}
      modalTitle="Edit Additional Fields"
      size="md"
      modalHeaderProps={{
        pb: 2,
      }}
      renderFooter={renderFooter}
    >
      <form id="edit-custom-fields-form" onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="customFields"
          control={control}
          render={({ field: { onChange } }) => (
            <MSimpleGrid columns={1} gap={4} mb={6} alignItems="flex-end">
              {customFieldList?.map((customField: ICustomFieldResSchema) => {
                return (
                  <CustomFormDataFormField
                    key={`${customField.entity}-${customField.key}`}
                    control={control}
                    customField={customField}
                    onSave={(field: string, value: any) => {
                      const parsedValue = { ...getValues(), [field]: value };
                      onChange(
                        convertFormValuesToReq(
                          zodSchema.parse(parsedValue) || parsedValue,
                        ),
                      );
                    }}
                    isDisabled={!canUpdateBilling}
                    isReadOnly={!canUpdateBilling}
                  />
                );
              })}
            </MSimpleGrid>
          )}
        />
      </form>
    </MCenterModal>
  );
};
