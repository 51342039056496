import { MVStack } from '~app/components/Monetize';
import { ITenant } from '~app/types';
import { TenantCard } from './TenantCard';

interface TenantListProps {
  origin?: 'modal' | 'page';
  tenants: ITenant[];
  onSwitchTenant: (tenant: ITenant) => void;
  onAcceptInvite: (tenant: ITenant) => void;
  onEdit?: (tenant: ITenant) => void;
  onDelete?: (tenant: ITenant) => void;
}

export const TenantList = ({
  origin,
  tenants,
  onSwitchTenant,
  onAcceptInvite,
  onEdit,
  onDelete,
}: TenantListProps) => {
  return (
    <MVStack spacing={2} align="stretch" mt={4}>
      {tenants.map((tenant: ITenant) => (
        <TenantCard
          origin={origin}
          key={tenant.id}
          tenant={tenant}
          onClick={onSwitchTenant}
          onAccept={onAcceptInvite}
          onEdit={onEdit}
          onDelete={onDelete}
        />
      ))}
    </MVStack>
  );
};
