import {
  Box,
  Center,
  Divider,
  Flex,
  HStack,
  Heading,
  Icon,
  Text,
  TextProps,
} from '@chakra-ui/react';
import { FC, Fragment } from 'react';
import { MdArrowDropDown } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { MBackButton } from './MBackButton';
import { MEntityStatusBadge } from './MEntityStatusBadge';
import { MParentLink } from './MParentLink';
import { MStatusIDBox } from './MStatusIDBox';

interface MPageHeaderLeftSectionProp {
  title?: string | React.ReactNode;
  id?: string;
  size?: string;
  tag?: React.ReactNode; // TODO: Rename tag prop to something generic
  tagStyle?: TextProps;
  subTitle?: React.ReactNode;
  hasBackButton?: boolean;
  backButtonTitle?: string;
  backButtonLink?: string | object;
  copyUrl?: string | boolean;
  parentLink?: string;
  parentLinkTitle?: string;
  /** Alternate react component for the id */
  customIdCopyBox?: React.ReactNode;
  /** Added after parent link and id with dividers between */
  extraSubtitleParts?: React.ReactNode[];
  status?: string;
  firstRowPlaceholder?: React.ReactNode;
  backButtonCallback?: () => void;
  onToggle?: () => void;
}

export const MPageHeaderLeftSection: FC<MPageHeaderLeftSectionProp> = ({
  hasBackButton,
  backButtonLink,
  backButtonTitle,
  size = 'lg',
  title,
  tag,
  tagStyle,
  parentLink,
  parentLinkTitle,
  id,
  copyUrl,
  customIdCopyBox,
  extraSubtitleParts,
  subTitle,
  status,
  firstRowPlaceholder,
  backButtonCallback,
  onToggle,
}) => {
  const navigate = useNavigate();
  let backButtonFn = backButtonCallback;
  if (backButtonLink) {
    backButtonFn = () => navigate(backButtonLink);
  }

  return (
    <Flex>
      {hasBackButton && (
        <MBackButton
          onClick={
            backButtonCallback || backButtonLink
              ? backButtonFn
              : () => navigate(-1)
          }
          backButtonTitle={backButtonTitle}
          containerProps={{
            mt: 0.5,
          }}
        />
      )}

      <Box ml={hasBackButton ? 1.5 : 0}>
        <Flex align="center" mb="0.5">
          {onToggle && (
            <Flex
              align="center"
              _hover={{ background: 'tGray.support', cursor: 'pointer' }}
              onClick={onToggle}
            >
              <Heading size={size} color="tPurple.base">
                {title}
              </Heading>
              <Icon as={MdArrowDropDown} h={6} w={6} />
            </Flex>
          )}
          {!onToggle && (
            <Heading size={size} color="tPurple.base">
              {title}
            </Heading>
          )}

          {status && <MEntityStatusBadge status={status} />}
          {!!tag && (
            <Text
              as="span"
              fontSize="sm"
              ml={2}
              color="tGray.darkGrayPurple"
              lineHeight="1.125rem"
              {...tagStyle}
            >
              {tag}
            </Text>
          )}
        </Flex>
        {firstRowPlaceholder}
        <HStack flexWrap="wrap" ml={0} gap={0}>
          <MParentLink
            parentLink={parentLink}
            parentLinkTitle={parentLinkTitle}
          />
          {!!parentLink && !!parentLinkTitle && !!id && (
            <Center m={0} w={0} height={3}>
              <Divider
                m={0}
                orientation="vertical"
                borderColor="tGray.darkGrayPurple"
              />
            </Center>
          )}
          <MStatusIDBox
            id={id}
            copyUrl={copyUrl}
            customIdCopyBox={customIdCopyBox}
            mb="0.5"
          />
          {extraSubtitleParts?.map((part, i) => (
            <Fragment key={i}>
              {/* Determine if divider should be rendered based on a prior item existing */}
              {(i !== 0 || !!parentLink || !!id) && (
                <Center m={0} w={0} height={3}>
                  <Divider
                    m={0}
                    orientation="vertical"
                    borderColor="tGray.darkGrayPurple"
                  />
                </Center>
              )}
              {part}
            </Fragment>
          ))}
        </HStack>

        {subTitle}
      </Box>
    </Flex>
  );
};
