import { useState } from 'react';
import { useGetRate } from '~app/api/productCatalogService';
import {
  MAccordionButton,
  MAccordionIcon,
  MAccordionItem,
  MAccordionPanel,
  MFlex,
  MSpinner,
  MText,
} from '~app/components/Monetize';
import { MCurrency } from '~app/components/Monetize/MCurrency';
import { PRICE_MODEL_OPTIONS } from '~app/constants/offerings';
import { formatNumber } from '~app/utils';
import { toDateShort } from '~app/utils/dates';
import { getOrderedPricesUnderProduct } from '~app/utils/product';
import CustomRateProductsWithPrice from './CustomRateProductsWithPrice';

interface CustomRateAccordionItemProps {
  rateId: string;
  name: string;
  createDate: string;
  accountName: string;
  accountId: string;
  currency: string;
}
export const CustomRateAccordionItem = ({
  rateId,
  name,
  createDate,
  accountName,
  accountId,
  currency,
}: CustomRateAccordionItemProps) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const { isInitialLoading, data } = useGetRate(rateId, {
    enabled: isExpanded,
  });
  const productsWithPrice = data ? getOrderedPricesUnderProduct(data) : [];

  const renderSpinner = () => {
    return (
      <MFlex justifyContent="center" alignItems="center" h="full">
        <MSpinner />
      </MFlex>
    );
  };

  const renderProductWithPrice = () => {
    return (
      <>
        {productsWithPrice.map(({ id: key, name: productName, prices }) => (
          <CustomRateProductsWithPrice key={key} productName={productName}>
            <>
              {prices.map(
                ({ id: priceId, priceModel, from, to, amount }, idx) => (
                  <MFlex
                    key={`${idx}-${priceId}`}
                    justifyContent="space-between"
                    color="tPurple.dark"
                    pl={2}
                    gap={2}
                    w="full"
                  >
                    <MText fontSize="sm" fontWeight="normal" flex="1">
                      {PRICE_MODEL_OPTIONS[priceModel]}
                    </MText>
                    <MFlex>
                      <MText fontWeight="normal" fontSize="sm">{`${formatNumber(
                        from,
                        {
                          maximumFractionDigits: 0,
                        },
                      )} - ${
                        formatNumber(to, {
                          maximumFractionDigits: 0,
                        }) || '∞'
                      }`}</MText>
                    </MFlex>
                    <MCurrency
                      flex="1"
                      fontSize="sm"
                      fontWeight="normal"
                      textAlign="right"
                      amount={amount}
                      options={{ maximumFractionDigits: 'max', currency }}
                    />
                  </MFlex>
                ),
              )}
            </>
          </CustomRateProductsWithPrice>
        ))}
      </>
    );
  };
  return (
    <MAccordionItem
      borderTop="none"
      borderLeft="none"
      borderRight="none"
      borderWidth="1px"
      borderColor="tGray.back"
    >
      <MAccordionButton
        px={0}
        onClick={() => setIsExpanded(!isExpanded)}
        _active={{ bg: 'none' }}
        _hover={{ bg: 'none' }}
      >
        <MFlex
          justifyContent="space-between"
          alignItems="center"
          w="full"
          columnGap={4}
        >
          <MFlex
            direction="column"
            justifyContent="flex-start"
            textAlign="left"
            flex={1}
          >
            <MText
              color="tPurple.dark"
              noOfLines={1}
              fontWeight="medium"
              fontSize="sm"
            >
              {name}
            </MText>
            <MText color="tGray.acGray" fontWeight="normal" fontSize="xs">
              {toDateShort(createDate)}
            </MText>
          </MFlex>

          <MFlex flex={1} justifyContent="flex-end" textAlign="right" gap={2}>
            <MFlex flexDirection="column">
              <MText
                color="tPurple.dark"
                fontWeight="normal"
                fontSize="sm"
                noOfLines={1}
              >
                {accountName}
              </MText>
              <MText
                color="tGray.acGray"
                fontWeight="normal"
                fontSize="xs"
                mt={0}
              >
                {accountId}
              </MText>
            </MFlex>
            <MAccordionIcon color="tPurple.dark" />
          </MFlex>
        </MFlex>
      </MAccordionButton>

      <MAccordionPanel pr={0} pt={0} pb={4}>
        {isInitialLoading ? renderSpinner() : renderProductWithPrice()}
      </MAccordionPanel>
    </MAccordionItem>
  );
};
