import { BoxProps } from '@chakra-ui/react';
import { FunctionComponent as FC, useCallback, useRef, useState } from 'react';
import { AiOutlineFilePdf } from 'react-icons/ai';
import { doPrintInvoiceToPdf } from '~app/api/accountsService';
import { MBox, MCustomIconButton, MTooltip } from '~app/components/Monetize';
import PreviewPdfDrawer from '~app/components/Monetize/PreviewPdfDrawer/PreviewPdfDrawer';
import { Maybe } from '../../types';

interface PreviewInvoicePdfDrawerProps extends BoxProps {
  invoiceId: string;
  invoiceNumber: Maybe<string>;
}

/**
 * Allow user to click a button to open a drawer and view invoice PDF documents
 */
export const PreviewInvoicePdfDrawer: FC<PreviewInvoicePdfDrawerProps> = (
  props: PreviewInvoicePdfDrawerProps,
) => {
  const { invoiceId, invoiceNumber, ...rest } = props;

  const buttonRef = useRef<typeof MCustomIconButton>(null);
  const [isOpen, setIsOpen] = useState(false);

  const fetchDocument = useCallback(async (): Promise<ArrayBuffer | null> => {
    const { data } = await doPrintInvoiceToPdf(invoiceId, invoiceNumber);
    return data;
  }, [invoiceId, invoiceNumber]);

  function handleOpen() {
    setIsOpen(true);
  }

  return (
    <MTooltip label={!isOpen ? 'View Invoice Document' : null}>
      <MBox position="relative" {...rest}>
        <MCustomIconButton
          ref={buttonRef}
          btnSize={5}
          variant="icon"
          p={3}
          icon={AiOutlineFilePdf}
          iconColor="tPurple.base"
          onClick={handleOpen}
        />

        {isOpen && (
          <PreviewPdfDrawer
            filename={`invoice-${invoiceId}`}
            isOpen={isOpen}
            onCloseFocusRef={buttonRef}
            fetchDocument={fetchDocument}
            onClose={() => setIsOpen(false)}
          />
        )}
      </MBox>
    </MTooltip>
  );
};
