import { useEffect, useState } from 'react';

export const useNavigatorPermission = () => {
  const [canCopyClipboard, setCanCopyClipboard] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const write = await navigator.permissions.query({
          name: 'clipboard-write' as any,
        });
        setCanCopyClipboard(write.state === 'granted');
      } catch (e) {
        setCanCopyClipboard(false);
      }
    })();
  }, []);

  return {
    canCopyClipboard,
  };
};
