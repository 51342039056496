import { FunctionComponent as FC } from 'react';
import { MBox } from '~app/components/Monetize';
import { IContactAddressDataSchema } from '~app/types';
import { LegalEntityAddressFormatEnum } from '~app/types/legalEntityTypes';
import { UseFormReturn } from 'react-hook-form';
import { ContactsAddressItem } from './ContactsAddressItem';

interface ContactsAddressListProps {
  isDisabled?: boolean;
  accountId: string;
  addressDataForm: UseFormReturn<IContactAddressDataSchema>;
  addressFormat?: LegalEntityAddressFormatEnum | null;
}

export const ContactsAddressList: FC<ContactsAddressListProps> = ({
  isDisabled = false,
  accountId,
  addressDataForm,
  addressFormat = LegalEntityAddressFormatEnum.US_FORMAT,
}: ContactsAddressListProps) => {
  const {
    formState: { errors },
    control,
  } = addressDataForm;
  return (
    <MBox>
      <ContactsAddressItem
        label="Billing Address"
        subLabel="Account Billing Address"
        isDisabled={isDisabled}
        accountId={accountId}
        addressDataForm={addressDataForm}
        addressFormat={addressFormat}
        name="billingAddressId"
      />
      <ContactsAddressItem
        label="Shipping Address"
        subLabel="Account Shipping Address"
        isDisabled={isDisabled}
        accountId={accountId}
        addressDataForm={addressDataForm}
        addressFormat={addressFormat}
        name="shippingAddressId"
      />
    </MBox>
  );
};
