import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useRecoilState } from 'recoil';
import AppTenantSwitchModal from '~app/components/App/AppTenantSwitchModal';
import { tempRedirectInfoState } from '~app/store/global.store';
import AppLoading from '~components/App/AppLoading';
import { ROUTES } from '~constants';

const SelectTenant = () => {
  const [loading, setLoading] = useState(true);
  const [redirectInfo, setRedirectInfo] = useRecoilState(tempRedirectInfoState);
  const onClose = () => {};
  const onOpen = () => {};

  const navigate = useNavigate();

  useEffect(() => {
    if (redirectInfo && redirectInfo.tenantId && redirectInfo.redirectUrl) {
      // Only when tenantId is set, then consume tenantId & redirectUrl
      setRedirectInfo(null);
      navigate(
        ROUTES.getSwitchTenantRoute(
          redirectInfo.tenantId,
          redirectInfo.redirectUrl,
        ),
      );
    } else {
      // If not, then let user select the tenant, and only consume redireUrl
      // (This will be only when it's set vai onRedirectCallback. And that is only when state.returnTo is set loginWithRedirect)
      setLoading(false);
    }
  }, [redirectInfo]);

  if (loading) {
    return <AppLoading />;
  }

  return (
    <>
      <AppTenantSwitchModal
        onClose={onClose}
        onOpen={onOpen}
        isOpen
        persistentModal
        showLogout
        shouldSelectFirstOnlyTenant
      />
    </>
  );
};

export default SelectTenant;
