import { useMemo } from 'react';
import {
  Control,
  Controller,
  FieldErrors,
  useFieldArray,
} from 'react-hook-form';
import { BsTrash } from 'react-icons/bs';
import { composeGetQuery } from '../../../../api/utils';
import {
  MBox,
  MCustomIconButton,
  MFlex,
  MFormField,
  MSimpleGrid,
  MText,
} from '../../../../components/Monetize';
import { DatePicker } from '../../../../components/Monetize/DatePicker/DatePicker';
import { GRID_PROPS } from '../../../../constants/oneTimeInvoice';
import { OfferingTypesEnum } from '../../../../types';
import {
  IOneTimeInvoiceFormSchemaWithOfferings,
  IOneTimeOfferingRequestSchemaUi,
} from '../../../../types/oneTimeInvoiceTypes';
import { arrayToObject } from '../../../../utils/misc';
import { OneTimeInvoiceOfferingDropdown } from './OneTimeInvoiceOfferingDropdown';
import { OneTimeInvoiceOfferingProduct } from './OneTimeInvoiceOfferingProduct';

interface OneTimeInvoiceOfferingProps {
  control: Control<IOneTimeInvoiceFormSchemaWithOfferings>;
  index: number;
  errors: FieldErrors<IOneTimeInvoiceFormSchemaWithOfferings>;
  currency: string;
  values: IOneTimeOfferingRequestSchemaUi;
  handleRemove: (index: number) => void;
  handleUpdate: (index: number, offeringId: string) => void;
}

const listApiConfig = composeGetQuery({
  first: 0,
  rows: 25,
  page: 0,
  sortField: 'name',
  sortOrder: 1,
});

export const OneTimeInvoiceOffering = ({
  control,
  index,
  errors,
  currency,
  values,
  handleRemove,
  handleUpdate,
}: OneTimeInvoiceOfferingProps) => {
  const { fields: productFields } = useFieldArray({
    control,
    name: `offerings.${index}.products`,
  });

  const isOneTimeOfferingType =
    values.metadata.offering?.type === OfferingTypesEnum.ONETIME;
  const selectedOffering = values.metadata.offering;

  const productsById = useMemo(
    () => arrayToObject(selectedOffering?.products || [], 'id'),
    [],
  );

  return (
    <>
      <MSimpleGrid {...GRID_PROPS}>
        <MBox>
          <MFormField
            error={errors?.offerings?.[index]?.offeringId}
            maxW="19rem"
          >
            <Controller
              name={`offerings.${index}.offeringId`}
              control={control}
              render={({ field: { onChange, ...rest } }) => (
                <OneTimeInvoiceOfferingDropdown
                  onChange={(value) => handleUpdate(index, value as any)}
                  {...rest}
                />
              )}
            />
          </MFormField>
        </MBox>
        <MFormField error={errors?.offerings?.[index]?.startDate} maxW="11rem">
          <Controller
            name={`offerings.${index}.startDate`}
            control={control}
            render={({ field }) => <DatePicker {...field} />}
          />
        </MFormField>
        {isOneTimeOfferingType ? (
          <MText fontStyle="italic" fontWeight="400" color="tGray.darkPurple">
            Not Applicable
          </MText>
        ) : (
          <MFormField error={errors?.offerings?.[index]?.endDate} maxW="11rem">
            <Controller
              name={`offerings.${index}.endDate`}
              control={control}
              render={({ field }) => <DatePicker {...field} />}
            />
          </MFormField>
        )}

        <MFlex gridColumn="4/-1" justify="flex-end" align="center">
          <MCustomIconButton
            variant="icon"
            btnSize={4}
            containerSize={8}
            p={3}
            icon={BsTrash}
            onClick={() => handleRemove(index)}
          />
        </MFlex>
      </MSimpleGrid>

      {selectedOffering?.description && (
        <MBox maxW="19rem" mb="2">
          <MText noOfLines={2} maxWidth="80ch">
            {selectedOffering.description}
          </MText>
        </MBox>
      )}

      {productFields.map((item, productIndex) => (
        <OneTimeInvoiceOfferingProduct
          key={item.id}
          control={control}
          errors={errors}
          offeringIndex={index}
          index={productIndex}
          selectedProduct={productsById[item.productId]}
          currency={currency}
        />
      ))}
    </>
  );
};
