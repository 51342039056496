import { FC } from 'react';
import {
  MBox,
  MCard,
  MFlex,
  MIcon,
  MPageLoader,
  MText,
  MTooltip,
} from '~app/components/Monetize';
import { useQuoteContext } from '../../Quote/quoteContext';
import { MdInfo } from 'react-icons/md';
import { QuoteDocumentsView } from '../../Quote/components/quoteDocuments/QuoteDocumentsView';
import { QUOTE_READONLY_STATUSES } from '~app/constants/quotes';
import { QuoteDocumentIcon } from '~app/assets/icons';
import { ListItem, UnorderedList } from '@chakra-ui/react';

interface QuoteUploadDocumentsProps {}
export const QuoteUploadDocuments: FC<
  QuoteUploadDocumentsProps
> = ({}: QuoteUploadDocumentsProps) => {
  const { isReadOnly, quoteData, handleUpdateQuoteWithFormReset } =
    useQuoteContext();

  const { quote } = quoteData;

  if (!quote) {
    return <MPageLoader />;
  }

  return (
    <MCard width={'100%'} p={2} borderColor="tGray.lightPurple">
      <MFlex justifyContent="space-between" alignItems="center" mb="2" p="2">
        <MFlex alignItems="center">
          <MIcon as={QuoteDocumentIcon} />
          <MText ml="2" fontSize="lg" fontWeight="bold">
            Documents
          </MText>
          <MTooltip
            usePopover={true}
            placement="end-start"
            label={
              <MBox fontSize="xs">
                The Documents in this section will appear on the PDF in the
                following order:
                <UnorderedList pl="2">
                  <ListItem>Cover</ListItem>
                  <ListItem>Order Form</ListItem>
                  <ListItem>Footer</ListItem>
                  <ListItem>Statement of Work (SOW)</ListItem>
                  <ListItem>Master Service Agreement (MSA)</ListItem>
                </UnorderedList>
              </MBox>
            }
          >
            <MFlex alignItems="center">
              <MIcon as={MdInfo} ml="2" />
            </MFlex>
          </MTooltip>
        </MFlex>
      </MFlex>
      <MFlex>
        <QuoteDocumentsView
          isReadOnly={isReadOnly || QUOTE_READONLY_STATUSES.has(quote.status)}
          quote={quote}
          onUpdate={handleUpdateQuoteWithFormReset}
        />
      </MFlex>
    </MCard>
  );
};
