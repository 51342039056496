import { FunctionComponent as FC } from 'react';
import { FilterTypeEqual, RenderTableFilterOptionProps } from '~app/types';
import { MBox, MCheckbox, MText } from '../..';

const quoteAges = [
  { label: 'More than 5 days', value: '5', id: 1 },
  { label: 'More than 10 days', value: '10', id: 2 },
];

export const DaysPendingApprovalFilterOptionContent: FC<
  RenderTableFilterOptionProps
> = (props: RenderTableFilterOptionProps) => {
  const { filter, filterOption, handleFilterChange } = props;
  const internalFilter = filter as FilterTypeEqual;

  return (
    <>
      {quoteAges.map(({ label, value, id }, index: any) => (
        <MBox key={id}>
          <MCheckbox
            my={1}
            isChecked={internalFilter?.value === value}
            onChange={() => {
              if (!internalFilter?.value || internalFilter?.value !== value) {
                handleFilterChange(value, filterOption);
              } else {
                handleFilterChange('', filterOption);
              }
            }}
          >
            <MText ml="1" fontWeight="medium">
              {label}
            </MText>
          </MCheckbox>
        </MBox>
      ))}
    </>
  );
};
