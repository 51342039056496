import { Link } from 'react-router-dom';
import { ButtonCard, ButtonCardProps } from './ButtonCard';

export interface LinkCardProps extends Omit<ButtonCardProps, 'onClick'> {
  to: string;
}

export const LinkCard = ({ to, ...rest }: LinkCardProps) => {
  return (
    <Link to={to}>
      <ButtonCard {...rest} />
    </Link>
  );
};
