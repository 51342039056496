import { MDivider, MGrid, MSkeleton, MText } from '~app/components/Monetize';
import { Maybe } from '../../types';
import { formatCurrency, formatNumber } from '../../utils';

interface KeyValueCalloutBase {
  testIdRoot?: string;
  isLoading?: boolean;
  label: string;
  value: Maybe<string | number>;
  type: 'currency' | 'number' | 'text';
  currency?: string;
  verticalDividerBefore?: boolean;
  verticalDividerAfter?: boolean;
}

interface KeyValueCalloutCurrency extends KeyValueCalloutBase {
  value: Maybe<number>;
  type: 'currency';
  currency: string;
  minimumFractionDigits?: never;
  maximumFractionDigits?: never;
}

interface KeyValueCalloutNumber extends KeyValueCalloutBase {
  value: Maybe<number>;
  type: 'number';
  currency?: never;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
}

interface KeyValueCalloutText extends KeyValueCalloutBase {
  value: Maybe<string>;
  type: 'text';
  currency?: never;
  minimumFractionDigits?: never;
  maximumFractionDigits?: never;
}

type KeyValueCalloutProps =
  | KeyValueCalloutCurrency
  | KeyValueCalloutNumber
  | KeyValueCalloutText;

/**
 * Used to show a key-value pair in a large style e.g. on dashboards and account overview
 */
export const KeyValueCallout = ({
  testIdRoot = 'key-value-callout',
  isLoading,
  label,
  value,
  type,
  currency,
  minimumFractionDigits = 0,
  maximumFractionDigits = 0,
  verticalDividerBefore,
  verticalDividerAfter,
}: KeyValueCalloutProps) => {
  switch (type) {
    case 'currency':
      value = formatCurrency(value, { currency });
      break;
    case 'number':
      value = formatNumber(value, {
        minimumFractionDigits,
        maximumFractionDigits,
      });
      break;
    case 'text':
    default:
      break;
  }

  return (
    <>
      {verticalDividerBefore && (
        <MDivider orientation="vertical" variant="thick" />
      )}
      <MGrid
        justifyItems="start"
        data-testid={`${testIdRoot}-${label.toLowerCase()}`}
      >
        <MText fontSize="md" fontWeight="normal" color="tGray.darkPurple">
          {label}
        </MText>
        {isLoading ? (
          <MSkeleton height={5} w="full" />
        ) : (
          <MText fontWeight="bold" fontSize="2.5xl">
            {value}
          </MText>
        )}
      </MGrid>
      {verticalDividerAfter && (
        <MDivider orientation="vertical" variant="thick" />
      )}
    </>
  );
};
