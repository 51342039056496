import { useDisclosure } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { MdClear, MdSearch } from 'react-icons/md';
import { useGetWebHookEvents } from '~app/api/webhooksService';
import { pluralize } from '~app/utils';
import {
  MAccordion,
  MAccordionButton,
  MAccordionIcon,
  MAccordionItem,
  MAccordionPanel,
  MBox,
  MButton,
  MCenterModal,
  MCheckbox,
  MIcon,
  MInput,
  MInputGroup,
  MInputLeftElement,
  MInputRightElement,
  MSpinner,
  MStack,
  MText,
} from '~components/Monetize';
import { IEventCategory, IEventType, IWebhookEvent } from '~types';

interface EventSelectModalProps {
  onChange: (...event: any[]) => void;
  value: IWebhookEvent[];
  disabled?: boolean;
}

const EventSelectModal = React.forwardRef(
  ({ onChange, value, disabled = false }: EventSelectModalProps, ref) => {
    const [events, setEvents] = useState(value || []);
    const [isEditing, setIsEditing] = useState<boolean>(false);

    const { isOpen, onOpen, onClose } = useDisclosure();
    const [filteredWebhookEvents, setFilteredWebhookEvents] = useState<
      IEventCategory[]
    >([]);
    const [query, setQuery] = useState<string>('');

    const { data: webhookEvents, isLoading: loading } = useGetWebHookEvents();

    useEffect(() => {
      if (Array.isArray(value)) {
        setEvents(value);
      }
      if (!value || (value && Array.isArray(value) && value.length === 0)) {
        setIsEditing(true);
      } else {
        setIsEditing(false);
      }
    }, [value]);

    useEffect(() => {
      if (webhookEvents) {
        if (query) {
          const q = query.toLowerCase();
          const filteredEvents = webhookEvents
            ?.map((event) => {
              const newTypes = event.types.filter(
                (type) =>
                  type.key.toLowerCase().includes(q) ||
                  type.description.toLowerCase().includes(q),
              );
              return {
                ...event,
                types: newTypes,
              };
            })
            .filter((event) => event.types.length > 0);
          setFilteredWebhookEvents(filteredEvents);
        } else {
          setFilteredWebhookEvents(webhookEvents);
        }
      }
    }, [query, webhookEvents]);

    useEffect(() => {
      if (query && !isOpen) {
        setQuery('');
      }
    }, [isOpen]);

    const onSave = () => {
      onChange(events);
      onClose();
    };

    const isSelected = (type: IEventType) => {
      return events.filter((ev) => ev.eventType === type.key).length > 0;
    };
    const onToggleSelect = (type: IEventType) => {
      if (isSelected(type)) {
        const newEvents = events.filter((ev) => ev.eventType !== type.key);
        setEvents(newEvents);
      } else {
        const newEvents = events.concat([
          {
            eventType: type.key,
          },
        ]);
        setEvents(newEvents);
      }
    };

    const renderSelectedTag = (webhookEvent: IEventCategory) => {
      const count = webhookEvent.types.filter(isSelected).length;
      if (count === 0) {
        return null;
      }
      return (
        <MBox borderRadius={2} bgColor="tBlue.hover" px={1} py={0.5} mr="4">
          <MText color="tIndigo.base" fontSize="12" fontWeight={500}>
            {count} selected
          </MText>
        </MBox>
      );
    };
    const renderEvents = () => {
      return (
        <MBox maxH="400px" className="custom-scroll-bar-v1">
          <MAccordion allowMultiple variant="default">
            {filteredWebhookEvents.map((webhookEvent, index) => (
              <MAccordionItem
                borderTop="none"
                borderBottom="1px"
                borderBottomColor="gray.200"
                key={index}
              >
                <MAccordionButton
                  px="0"
                  py="2"
                  _active={{ bg: 'none' }}
                  _hover={{ bg: 'none' }}
                >
                  <MBox flex="1" display="flex" justifyContent="space-between">
                    <MText color="tPurple.base" fontWeight="500">
                      {webhookEvent.category}
                    </MText>
                    <MBox display="flex" alignItems="center">
                      {renderSelectedTag(webhookEvent)}
                      <MText mr="2">
                        {webhookEvent.types.length} event
                        {webhookEvent.types.length > 1 && 's'}
                      </MText>
                    </MBox>
                  </MBox>
                  <MAccordionIcon color="tGray.darkPurple" />
                </MAccordionButton>

                <MAccordionPanel px="3px" py="2">
                  {webhookEvent.types.map((type) => (
                    <MBox key={type.key}>
                      <MCheckbox
                        key={type.key}
                        isChecked={isSelected(type)}
                        colorScheme="tGray.darkPurple"
                        variant="select-events"
                        onChange={() => onToggleSelect(type)}
                      >
                        {type.key}
                      </MCheckbox>
                      <MText ml="7" mb={3} color="tGray.darkPurple">
                        {type.description}
                      </MText>
                    </MBox>
                  ))}
                </MAccordionPanel>
              </MAccordionItem>
            ))}
          </MAccordion>
        </MBox>
      );
    };

    const renderSelectedEvents = () => {
      const types = events.map((ev) => ev.eventType);
      const availableWebhookEvents = webhookEvents
        ?.map((event) => {
          const avTypes = event.types.filter((type) =>
            types.includes(type.key),
          );
          return {
            category: event.category,
            types: avTypes,
          };
        })
        .filter((event) => event.types.length > 0);
      return (
        <MBox maxHeight="400px" className="custom-scroll-bar-v1">
          {availableWebhookEvents?.map((webhookEvent, index) => (
            <MBox
              borderTop="none"
              borderBottom="1px"
              borderBottomColor="gray.200"
              key={index}
            >
              <MBox
                px="0"
                py="2"
                _active={{ bg: 'none' }}
                _hover={{ bg: 'none' }}
              >
                <MBox flex="1" display="flex" alignItems="center">
                  <MText color="tPurple.base" mr="2">
                    {webhookEvent.category}
                  </MText>
                  <MBox display="flex">{renderSelectedTag(webhookEvent)}</MBox>
                </MBox>
              </MBox>

              <MBox px="3px" py="2">
                {webhookEvent.types.map(({ key, description }, idx) => (
                  <MBox key={key}>
                    <MText color="tPurple.dark" mt={idx > 0 ? 2 : 0}>
                      {key}
                    </MText>
                    <MText color="tGray.darkPurple">{description}</MText>
                  </MBox>
                ))}
              </MBox>
            </MBox>
          ))}
        </MBox>
      );
    };

    return (
      <>
        <MBox>
          <MButton
            variant="tertiary"
            role="button"
            onClick={onOpen}
            p={0}
            ml={events.length > 0 ? '-33px' : '-18px'}
          >
            {events.length === 0 && 'Select Events'}
            {events.length > 0 &&
              `${events.length} ${pluralize(' Event', events.length)}`}
          </MButton>
        </MBox>
        <MCenterModal
          returnFocusOnClose={false}
          isOpen={isOpen}
          onClose={onClose}
          modalTitle={isEditing ? 'Select Events' : 'Selected Events'}
          modalHeaderProps={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
          renderModalTitleActions={() =>
            !isEditing &&
            !disabled && (
              <MText
                ml={60}
                color="tIndigo.base"
                fontWeight="bold"
                fontSize="sm"
                _hover={{ color: 'tPurple.base', cursor: 'pointer' }}
                onClick={() => setIsEditing(true)}
              >
                Edit Events
              </MText>
            )
          }
          renderFooter={() => (
            <MStack
              spacing={4}
              direction="row"
              align="center"
              justify="right"
              flex={1}
            >
              <MButton onClick={onClose} variant="cancel" minW="auto">
                Cancel
              </MButton>
              {isEditing && (
                <MButton
                  variant="primary"
                  onClick={onSave}
                  type="submit"
                  minW="auto"
                >
                  Save
                </MButton>
              )}
            </MStack>
          )}
          modalContentProps={{ 'data-testid': 'contact-form' } as any}
        >
          {loading ? (
            <MBox display="flex" justifyContent="center">
              <MSpinner />
            </MBox>
          ) : (
            <MBox>
              {isEditing && (
                <MInputGroup mb="2">
                  <MInputLeftElement pointerEvents="none">
                    <MIcon as={MdSearch} color="tGray.darkPurple" />
                  </MInputLeftElement>
                  <MInput
                    placeholder="Search event...."
                    value={query || ''}
                    onChange={(ev) => setQuery(ev.target.value)}
                  />
                  <MInputRightElement>
                    <MIcon
                      as={MdClear}
                      color="tGray.darkPurple"
                      _hover={{
                        cursor: 'pointer',
                        color: 'tPurple.base',
                      }}
                      onClick={() => setQuery('')}
                    />
                  </MInputRightElement>
                </MInputGroup>
              )}

              {isEditing ? renderEvents() : renderSelectedEvents()}
            </MBox>
          )}
        </MCenterModal>
      </>
    );
  },
);

export default EventSelectModal;
