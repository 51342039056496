import { selectAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';
import Input from './Input';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(selectAnatomy.keys);

const baseStyle = definePartsStyle({
  field: defineStyle({
    color: 'tPurple.base',
    _disabled: {
      cursor: 'not-allowed',
      color: 'tGray.acGray',
      background: 'tGray.support',
      _placeholder: {
        color: 'transparent',
      },
    },
  }),
  icon: defineStyle({
    width: '1.5rem',
    height: '100%',
    insetEnd: '0.5rem',
    position: 'relative',
    color: 'currentColor',
    fontSize: '1.25rem',
    _disabled: {
      cursor: 'not-allowed',
      color: 'tGray.acGray',
      background: 'tGray.support',
      opacity: 'unset',
      _placeholder: {
        color: 'transparent',
      },
    },
    _focus: {
      color: 'tIndigo.base',
    },
  }),
});

const sizes = {
  sm: definePartsStyle({
    icon: defineStyle({ fontSize: '0.45rem' }),
  }),
  md: definePartsStyle({
    icon: defineStyle({ fontSize: '0.625rem' }),
  }),
  lg: definePartsStyle({
    icon: defineStyle({ fontSize: '0.625rem' }),
  }),
  xs: definePartsStyle({
    icon: defineStyle({ insetEnd: '0.25rem' }),
  }),
};

export const selectTheme = defineMultiStyleConfig({
  variants: Input.variants,
  baseStyle,
  sizes,
  defaultProps: {
    size: 'sm',
    colorScheme: 'tPurple',
  },
});

export default selectTheme;
