import { useDisclosure } from '@chakra-ui/react';
import { useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useGetAccountById } from '~api/accountsService';
import { useGetRateById } from '~app/api/productCatalogService';
import {
  useGetSubscriptionById,
  useGetSubscriptionARR,
  useGetSubscriptionEstimate,
  useGetSubscriptionHistory,
  useGetSubscriptionMRR,
  useGetSubscriptionTotal,
  useSubscriptionScheduledChanges,
} from '~app/api/subscriptionsService';
import {
  MBox,
  MButton,
  MCenter,
  MDivider,
  MFlex,
  MGrid,
  MPageContainer,
  MPageHeader,
  MPageLoader,
  MText,
} from '~app/components/Monetize';
import { ROUTES } from '~app/constants';
import {
  RATE_BILLING_FREQUENCY_DISPLAY,
  RATE_BILLING_FREQUENCY_MAP,
  getRateUsageBillingFrequencyDisplay,
} from '~app/constants/offerings';
import { SubscriptionBillingStatusDisplayText } from '~app/constants/subscriptions';
import { useHandleApiError } from '~app/hooks/useHandleApiError';
import { ACLCheck } from '~app/services/acl/ACLCheck';
import { logger } from '~app/services/logger';
import {
  DEFAULT_ROWS,
  IBillGroupResp,
  ProductTypeEnum,
  SubscriptionBillingStatusEnum,
  TDataTablePager,
} from '~app/types';
import { sortByAlphabetically } from '~app/utils';
import { SubscriptionHeaderTag } from './components/SubscriptionHeaderTag';
import { CancelSubscription } from './components/SubscriptionOverview/CancelSubscription';
import { ScheduledChanges } from './components/SubscriptionOverview/ScheduledChanges';
import { SubscriptionDetails } from './components/SubscriptionOverview/SubscriptionDetails';
import { SubscriptionHistory } from './components/SubscriptionOverview/SubscriptionHistory';
import { TotalCard } from './components/SubscriptionOverview/TotalCard';
import { UsageDetails } from './components/SubscriptionOverview/UsageDetails';
import { useGetById } from '~app/api/queryUtils';

export const SubscriptionOverview = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [searchParams] = useSearchParams();
  const subscriptionId = params.subscriptionId || '';
  const accountId = params.accountId || '';
  const { handleApiError } = useHandleApiError();

  const { data: accountDetails, isLoading: accountDetailsLoading } =
    useGetAccountById(accountId, {
      enabled: !!accountId,
    });
  const { data: subscription, isLoading: subscriptionLoading } =
    useGetSubscriptionById(subscriptionId, {
      enabled: !!subscriptionId,
    });

  const { data: billGroup, isLoading: billGroupLoading } =
    useGetById<IBillGroupResp>('billGroups', subscription?.billGroupId!, {
      enabled: !!subscription?.billGroupId,
    });

  const {
    isOpen: isCancelModalOpen,
    onOpen: onOpenCancelModal,
    onClose: onCloseCancelModal,
  } = useDisclosure();

  const [pager] = useState<TDataTablePager>({
    first: 0,
    rows: DEFAULT_ROWS,
    page: 0,
  });

  const { data: subscriptionHistory, isLoading: subscriptionHistoryLoading } =
    useGetSubscriptionHistory(pager, accountId, subscriptionId, {
      enabled: !!accountId && !!subscription,
      onError: (error) => {
        logger.warn(error);
      },
    });

  const { data: mrrAmount, isLoading: mrrAmountLoading } =
    useGetSubscriptionMRR(accountId, subscriptionId, {
      enabled: !!accountId && !!subscription,
      onError: (error) => {
        logger.warn(error);
      },
    });

  const { data: arrAmount, isLoading: arrAmountLoading } =
    useGetSubscriptionARR(accountId, subscriptionId, {
      enabled: !!accountId && !!subscription,
      onError: (error) => {
        logger.warn(error);
      },
    });

  const { data: estimateAmount, isLoading: estimateLoading } =
    useGetSubscriptionEstimate(accountId, subscriptionId, {
      enabled: !!accountId && !!subscription,
      onError: (error) => {
        logger.warn(error);
      },
    });

  const { data: totalAmount, isLoading: totalAmountLoading } =
    useGetSubscriptionTotal(accountId, subscriptionId, {
      enabled: !!accountId && !!subscription,
      onError: (error) => {
        logger.warn(error);
      },
    });

  const { data: rate, isLoading: rateLoading } = useGetRateById(
    subscription?.rateId!,
    {
      enabled: !!subscription?.rateId,
      onError: (error) => {
        logger.warn(error);
      },
    },
  );

  const { data: scheduledChanges, isLoading: scheduleChangesLoading } =
    useSubscriptionScheduledChanges(subscriptionId);

  const isCancelled =
    subscription?.billingStatus === SubscriptionBillingStatusEnum.CANCELED;

  const handleEdit = () => {
    if (subscription && subscription?.id) {
      navigate(ROUTES.getSubscriptionEditRoute(accountId, subscription?.id));
    }
  };

  const handleSubscriptionOverviewBackBtn = () => {
    const billGroupId = searchParams.get('billGroupId');
    if (searchParams.get('source') === 'accountOverview') {
      navigate(ROUTES.getAccountDetailRoute(accountId));
    } else if (billGroupId) {
      navigate(ROUTES.getBillGroupPageRoute(billGroupId, 'subscriptions'));
    } else {
      navigate(ROUTES.getAccountSubscriptionsRoute(accountId));
    }
  };

  // scheduled changes API not implemented yet
  const hasScheduledChanges = !!scheduledChanges && scheduledChanges.length > 0;

  const productSubscriptionItems =
    subscription?.subscriptionItems.filter(
      (item) => item.product?.productType !== ProductTypeEnum.USAGE,
    ) || [];

  const usageSubscriptionItems =
    subscription?.subscriptionItems
      .filter((item) => item.product?.productType === ProductTypeEnum.USAGE)
      .sort((a, b) => sortByAlphabetically()(a.product, b.product)) || [];

  const isLoading =
    accountDetailsLoading ||
    subscriptionLoading ||
    subscriptionHistoryLoading ||
    mrrAmountLoading ||
    arrAmountLoading ||
    estimateLoading ||
    totalAmountLoading ||
    rateLoading ||
    scheduleChangesLoading ||
    billGroupLoading ||
    !billGroup;

  if (isLoading) {
    return <MPageLoader />;
  }

  return (
    <MPageContainer alignItems="stretch">
      <MPageHeader
        hasBackButton
        parentLink={ROUTES.getAccountSubscriptionsRoute(accountId)}
        parentLinkTitle={accountDetails?.accountName}
        backButtonCallback={() => handleSubscriptionOverviewBackBtn()}
        title={`${subscription?.offering?.name || ''} - ${rate?.name}`}
        status={
          subscription?.billingStatus
            ? SubscriptionBillingStatusDisplayText[subscription?.billingStatus]
            : ''
        }
        tag={
          <MBox display="flex">
            {SubscriptionHeaderTag(
              subscription?.locked || false,
              hasScheduledChanges,
            )}
          </MBox>
        }
        id={subscription?.id}
        copyUrl
        subTitle={
          <MFlex>
            {rate?.billingFrequency && (
              <>
                <MText fontWeight="normal">{`Billing Frequency:  ${
                  RATE_BILLING_FREQUENCY_MAP[rate.billingFrequency](
                    rate.billingFrequencyInMonths,
                  ).label
                }`}</MText>
                <MCenter w={4} height={4} mt={0.5}>
                  <MDivider
                    orientation="vertical"
                    borderColor="tGray.darkGrayPurple"
                  />
                </MCenter>
              </>
            )}
            {rate && (
              <MText fontWeight="normal">{`Usage Frequency:  ${getRateUsageBillingFrequencyDisplay(
                rate.usageBillingFrequency,
              )}`}</MText>
            )}
          </MFlex>
        }
      >
        <MFlex align="center">
          <ACLCheck acls={[['billing', 'update']]}>
            <MButton
              data-testid="edit-view-subscription-form"
              mr={!isCancelled && !subscription?.locked ? 2 : 0}
              variant="tertiary"
              isDisabled={!subscription}
              onClick={handleEdit}
            >
              {`${
                isCancelled || subscription?.locked ? 'View' : 'Edit'
              } Subscription`}
            </MButton>
            <>
              {!isCancelled && !subscription?.locked && (
                <MButton
                  data-testid="cancel-subscription"
                  variant="deleteOutline"
                  isDisabled={!subscription}
                  onClick={onOpenCancelModal}
                >
                  Cancel Subscription
                </MButton>
              )}
            </>
          </ACLCheck>
        </MFlex>
      </MPageHeader>
      <MBox flex="1" mt="4" px={8}>
        <MGrid
          justifyItems="center"
          alignItems="center"
          gridTemplateColumns="repeat(4, 1fr 0.1fr)"
          mt={2}
        >
          <TotalCard
            labelName="MRR"
            totalAmount={mrrAmount?.total}
            currency={billGroup?.currency}
          />
          <MDivider orientation="vertical" />
          <TotalCard
            labelName="ARR"
            totalAmount={arrAmount?.total}
            currency={billGroup?.currency}
          />
          <MDivider orientation="vertical" />
          <TotalCard
            labelName="Renewal Estimate"
            totalAmount={estimateAmount?.total}
            currency={billGroup?.currency}
          />
          <MDivider orientation="vertical" />
          <TotalCard
            labelName="Total Spend"
            totalAmount={totalAmount?.total}
            currency={billGroup?.currency}
          />
        </MGrid>

        <MDivider mt={6} mb={2} />

        {subscription && productSubscriptionItems.length > 0 && (
          <SubscriptionDetails
            productSubscriptionItems={productSubscriptionItems}
            rate={rate}
            subscription={subscription}
          />
        )}

        {subscription && usageSubscriptionItems.length > 0 && (
          <UsageDetails
            subscription={subscription}
            usageSubscriptionItems={usageSubscriptionItems}
            rate={rate}
            accountId={accountId}
          />
        )}

        <MBox h="3" />
        {hasScheduledChanges && (
          <ScheduledChanges
            scheduledChanges={scheduledChanges}
            subscriptionItems={subscription?.subscriptionItems}
          />
        )}

        <SubscriptionHistory
          subscriptionHistory={subscriptionHistory?.content || []}
        />
      </MBox>

      <CancelSubscription
        isOpen={isCancelModalOpen}
        onClose={(redirect: boolean = true) => {
          onCloseCancelModal();
          redirect && navigate(ROUTES.getAccountSubscriptionsRoute(accountId));
        }}
        accountId={accountId}
        billGroupId={subscription?.billGroupId || ''}
        subscriptionId={subscriptionId}
      />
    </MPageContainer>
  );
};
