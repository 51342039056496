import React from 'react';
import {
  MPageContainer,
  MSettingsPageHeader,
  MText,
} from '~app/components/Monetize';

const Subscriptions = () => {
  const test = '';
  return (
    <MPageContainer alignItems="stretch">
      <MSettingsPageHeader title="Subscriptions" />
    </MPageContainer>
  );
};

export default Subscriptions;
