import { Spinner } from '@chakra-ui/react';
import { FunctionComponent, ReactNode } from 'react';
import { MBox, MText } from '~components/Monetize';

type AppLoadingProp = {
  children?: ReactNode;
  loadingText?: string;
};

const AppLoading: FunctionComponent<AppLoadingProp> = ({
  children = null,
  loadingText = '',
}: AppLoadingProp) => (
  <MBox
    width="100vw"
    height="100vh"
    background="#00000033"
    display="flex"
    alignItems="center"
    justifyContent="center"
    flexDir="column"
    position="absolute"
    top="0"
    left="0"
  >
    {!!loadingText && (
      <MText mb="2" fontSize="lg">
        {loadingText}
      </MText>
    )}
    <Spinner />
    {children}
  </MBox>
);

export default AppLoading;
