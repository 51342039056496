import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { addDays } from 'date-fns/addDays';
import { formatISO } from 'date-fns/formatISO';
import { isBefore } from 'date-fns/isBefore';
import { useState } from 'react';
import { useInvokeManualBillRun } from '../../../api/accountsService';
import { handleApiErrorToast } from '../../../api/axios';
import { useGetBillGroups } from '../../../api/billGroupService';
import { SELECTION_COL } from '../../../components/Monetize/DataTableNew/dataTableNewRenderers';
import {
  addressWithContactBodyTemplate,
  dateBodyTemplate,
  enumDisplayBodyTemplate,
  getColHeaderFilters,
  getColHeaderWithSize,
  getCustomFieldsColumnGroup,
  nameWithIdBodyTemplate,
  textBodyTemplate,
} from '../../../components/Monetize/DataTableNew/tableUtilsNew';
import {
  getAccountDetailRoute,
  getBillGroupPageRoute,
} from '../../../constants/routes';
import { useCustomFields } from '../../../hooks/useCustomFields';
import { useToast } from '../../../services/toast';
import {
  ADDRESS_SOURCE_DISPLAY,
  BILL_GROUP_INVOICE_FREQUENCY_MAP,
  BillGroupListUi,
  CustomFieldEntityEnum,
  DEFAULT_PAGER,
  FilterTypeOperator,
  NET_TERMS_DISPLAY,
  TDataTablePager,
} from '../../../types';
import {
  buildFilterParamsRequestObject,
  deDuplicateRecords,
} from '../../../utils';
import {
  adjustTimeZoneOffsetWithUtc,
  parseDateAsUtc,
} from '../../../utils/dates';

const TODAY = adjustTimeZoneOffsetWithUtc(new Date());

const columnHelper = createColumnHelper<BillGroupListUi>();

const COLUMNS_BASE: ColumnDef<any, any>[] = [
  {
    ...SELECTION_COL,
  },
  {
    id: 'billGroupDetails',
    header: 'Bill Group Details',
    columns: [
      columnHelper.accessor('account.accountName', {
        ...getColHeaderWithSize('Account', 250),
        ...getColHeaderFilters('SET'),
        cell: nameWithIdBodyTemplate<BillGroupListUi, unknown>({
          idProp: 'account.id' as any,
          nameProp: 'account.accountName' as any,
          idLinkFn: (accountId) => getAccountDetailRoute(accountId),
        }),
        enableSorting: true,
        sortingFn: 'text',
      }),
      columnHelper.accessor('name', {
        ...getColHeaderWithSize('Bill Group', 250),
        ...getColHeaderFilters('SET'),
        cell: nameWithIdBodyTemplate<BillGroupListUi, unknown>({
          idProp: 'id',
          nameProp: 'name',
          idLinkFn: (billGroupId) => getBillGroupPageRoute(billGroupId),
        }),
      }),
      columnHelper.accessor('nextInvoiceDate', {
        ...getColHeaderWithSize('Next Invoice Date'),
        ...getColHeaderFilters('DATE'),
        enableSorting: true,
        cell: dateBodyTemplate(),
        sortingFn: 'alphanumeric',
      }),
      columnHelper.accessor('invoiceFrequencyLabel', {
        ...getColHeaderWithSize('Invoicing Frequency'),
        ...getColHeaderFilters('SET'),
        cell: textBodyTemplate(),
        enableSorting: false,
      }),
      columnHelper.accessor('netTerms', {
        ...getColHeaderWithSize('Net Terms'),
        ...getColHeaderFilters('SET'),
        cell: enumDisplayBodyTemplate({
          displayMap: NET_TERMS_DISPLAY,
        }),
        enableSorting: true,
        sortingFn: 'text',
      }),
      columnHelper.accessor('emailCustomerInvoice', {
        ...getColHeaderWithSize('Email Customer Invoice'),
        ...getColHeaderFilters('SET'),
        cell: textBodyTemplate(),
        enableSorting: true,
        sortingFn: 'text',
      }),
    ],
  },
  {
    id: 'addresses',
    header: 'Address Information',
    columns: [
      columnHelper.accessor('billingAddress', {
        ...getColHeaderWithSize('Billing', 250),
        cell: addressWithContactBodyTemplate<BillGroupListUi>({
          contactProp: 'billingContact',
        }),
        enableSorting: false,
      }),
      columnHelper.accessor('shippingAddress', {
        ...getColHeaderWithSize('Shipping', 250),
        cell: addressWithContactBodyTemplate<BillGroupListUi>({
          contactProp: 'shippingContact',
        }),
        enableSorting: false,
      }),
      columnHelper.accessor('addressSource', {
        ...getColHeaderWithSize('Address Source', 160),
        ...getColHeaderFilters('SET'),
        cell: enumDisplayBodyTemplate({
          displayMap: ADDRESS_SOURCE_DISPLAY,
        }),
        enableSorting: true,
        sortingFn: 'text',
      }),
    ],
  },
  {
    id: 'customerValues',
    header: 'Customer Values',
    columns: [
      columnHelper.accessor('purchaseOrderNumber', {
        ...getColHeaderWithSize('PO Number'),
        ...getColHeaderFilters('SET'),
        cell: textBodyTemplate(),
        enableSorting: true,
        sortingFn: 'text',
      }),
      columnHelper.accessor('registrationNumber', {
        ...getColHeaderWithSize('Registration Number'),
        ...getColHeaderFilters('SET'),
        cell: textBodyTemplate(),
        enableSorting: true,
        sortingFn: 'text',
      }),
      columnHelper.accessor('vatNumber', {
        ...getColHeaderWithSize('VAT Number'),
        ...getColHeaderFilters('SET'),
        cell: textBodyTemplate(),
        enableSorting: true,
        sortingFn: 'text',
      }),
    ],
  },
];

const PAGER: TDataTablePager = {
  ...DEFAULT_PAGER,
  rows: 100,
};

export const useManualBillRun = (selectedRows: BillGroupListUi[]) => {
  const [columns, setColumns] = useState<ColumnDef<any, any>[]>(COLUMNS_BASE);
  const [progress, setProgress] = useState(0);
  const { addToast } = useToast();
  // Server side filters to fetch data
  const [filters, setFilters] = useState(
    buildFilterParamsRequestObject([
      {
        key: 'nextInvoiceDate',
        value: { max: formatISO(TODAY, { representation: 'date' }), min: '' },
        operator: FilterTypeOperator.GLTE,
      },
      {
        key: 'status',
        value: 'ACTIVE',
        operator: FilterTypeOperator.EQUAL,
      },
    ]),
  );

  const customFieldQueryResult = useCustomFields(
    [CustomFieldEntityEnum.ACCOUNT, CustomFieldEntityEnum.BILL_GROUP],
    true,
    true,
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (data.length === 0) {
          return;
        }
        const newColumns = [...COLUMNS_BASE];
        const accountCustomFields = data.filter(
          (field) => field.entity === CustomFieldEntityEnum.ACCOUNT,
        );
        const billGroupCustomFields = data.filter(
          (field) => field.entity === CustomFieldEntityEnum.BILL_GROUP,
        );
        if (billGroupCustomFields.length) {
          newColumns.push(
            getCustomFieldsColumnGroup({
              id: 'billGroup.customFields',
              header: 'Bill Group Custom Fields',
              accessorPathPrefix: 'customFields',
              customFieldMetadata: billGroupCustomFields,
            }),
          );
        }
        if (accountCustomFields.length) {
          newColumns.push(
            getCustomFieldsColumnGroup({
              id: 'account.customFields',
              header: 'Account Custom Fields',
              accessorPathPrefix: 'account.customFields',
              customFieldMetadata: accountCustomFields,
            }),
          );
        }
        setColumns(newColumns);
      },
    },
  );

  const billGroupQueryResult = useGetBillGroups(
    {
      config: PAGER,
      filters,
      onProgress: setProgress,
    },
    {
      select: (data) => {
        // If the bill run is processing bill groups when reload is triggered,
        // the same bill group can be returned multiple times if it changed between requests.
        const eligibleBillGroups = deDuplicateRecords(data, 'id')
          .filter(
            ({ nextInvoiceDate, invoiceDelay }) =>
              !!nextInvoiceDate &&
              isBefore(
                addDays(parseDateAsUtc(nextInvoiceDate), invoiceDelay || 0),
                new Date(),
              ),
          )
          .map(
            (billGroup): BillGroupListUi => ({
              ...billGroup,
              emailCustomerInvoice: billGroup.autoEmailInvoice
                ? 'Automatically'
                : 'Manually',
              // Transforming in advance to simplify display and filtering since display is a composite of two fields
              invoiceFrequencyLabel: !billGroup.invoicingFrequency
                ? 'None'
                : BILL_GROUP_INVOICE_FREQUENCY_MAP[
                    billGroup.invoicingFrequency
                  ](billGroup.invoicingFrequencyInMonths).label,
            }),
          );
        return eligibleBillGroups;
      },
      refetchOnWindowFocus: false,
    },
  );

  const { mutateAsync: invokeManualBillRun, isLoading: batchInitIsLoading } =
    useInvokeManualBillRun({
      onSuccess: () => {
        addToast({
          summary: `Bill run submitted successfully`,
          detail: `This can take a few minutes to complete. Reload the results to view changes.`,
          severity: 'success',
        });
      },
      onError: (err) => {
        handleApiErrorToast(err);
      },
    });

  const handleInvokeManualBillRun = async () => {
    try {
      await invokeManualBillRun({
        billGroupIds: selectedRows.map((row) => row.id),
      });
    } catch (ex) {
      handleApiErrorToast(ex);
    }
  };

  return {
    customFieldQueryResult,
    billGroupQueryResult,
    columns,
    progress,
    handleInvokeManualBillRun,
    batchInitIsLoading,
  };
};
