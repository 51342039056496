export const ComingSoonIcon = (props: any) => {
  return (
    <svg
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2721_8734)">
        <path
          d="M22.3092 18.001L18.8809 21.6592L28.5717 32L32 28.3417L22.3092 18.001Z"
          fill="currentColor"
        />
        <path
          d="M24.8886 12.7401C28.0078 12.7401 30.5451 10.0326 30.5451 6.70419C30.5451 5.70395 30.2866 4.77269 29.8825 3.94491L25.5189 8.60119L23.1109 6.03161L27.4745 1.37533C26.6987 0.944191 25.826 0.668263 24.8886 0.668263C21.7695 0.668263 19.2321 3.37581 19.2321 6.70419C19.2321 7.41125 19.3614 8.08382 19.5715 8.70466L16.5817 11.8951L13.7049 8.82538L14.8524 7.60095L12.5736 5.16934L15.9998 1.51329C14.1089 -0.504431 11.0383 -0.504431 9.14738 1.51329L3.42623 7.6182L5.70499 10.0498H1.14746L0 11.2742L5.72115 17.3791L6.86862 16.1547V11.2742L9.14738 13.7059L10.2948 12.4814L13.1716 15.5511L1.19595 28.33L4.62218 31.9861L23.0139 12.378C23.5957 12.6021 24.226 12.7401 24.8886 12.7401Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_2721_8734">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
