import React, { FunctionComponent as FC } from 'react';
import { Column, ColumnProps } from 'primereact/column';

interface MDataTableColumnProps extends ColumnProps {
  children?: React.ReactNode;
}

const MDataTableColumn: FC<MDataTableColumnProps> = (
  props: MDataTableColumnProps,
) => {
  const { children, ...rest } = props;

  return <Column {...rest}>{children}</Column>;
};

MDataTableColumn.defaultProps = {
  children: null,
};

export default MDataTableColumn;
