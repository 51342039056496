import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { handleApiErrorToast } from '~app/api/axios';
import {
  cpqServiceQueryKeys,
  doCreateQuoteOffering,
  doDeleteQuoteOffering,
  doRemoveQuoteOffering,
  doRevertQuoteOffering,
  doUpdateQuoteOffering,
} from '~app/api/cpqService';
import {
  IQuoteOfferingRemoveReqSchema,
  IQuoteOfferingReqSchema,
  IQuoteOfferingRespSchema,
} from '~app/types';

const useQuoteOffering = (quoteId: string) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [quoteOffering, setQuoteOffering] =
    useState<IQuoteOfferingRespSchema | null>(null);
  const queryClient = useQueryClient();

  const createQuoteOffering = async (
    data: IQuoteOfferingReqSchema,
  ): Promise<IQuoteOfferingRespSchema | null> => {
    try {
      setLoading(true);
      const res = await doCreateQuoteOffering(quoteId, data);
      setQuoteOffering(res);

      queryClient.invalidateQueries([
        ...cpqServiceQueryKeys.quotePrices(quoteId),
      ]);
      return res;
    } catch (error) {
      handleApiErrorToast(error);
      return null;
    } finally {
      setLoading(false);
    }
  };

  const updateQuoteOffering = async (
    id: string,
    data: IQuoteOfferingReqSchema,
  ): Promise<IQuoteOfferingRespSchema | null> => {
    try {
      setLoading(true);
      const res = await doUpdateQuoteOffering(quoteId, id, data);
      setQuoteOffering(res);

      queryClient.invalidateQueries([
        ...cpqServiceQueryKeys.quotePrices(quoteId),
      ]);
      return res;
    } catch (error) {
      handleApiErrorToast(error);
      return null;
    } finally {
      setLoading(false);
    }
  };

  const deleteQuoteOffering = async (id: string) => {
    try {
      setLoading(true);
      await doDeleteQuoteOffering(quoteId, id);

      queryClient.invalidateQueries([
        ...cpqServiceQueryKeys.quotePrices(quoteId),
      ]);
    } catch (error) {
      handleApiErrorToast(error);
    } finally {
      setLoading(false);
    }
  };

  const revertQuoteOffering = async (id: string) => {
    try {
      setLoading(true);
      const res = await doRevertQuoteOffering(quoteId, id);

      queryClient.invalidateQueries([
        ...cpqServiceQueryKeys.quotePrices(quoteId),
      ]);
      return res;
    } catch (error) {
      handleApiErrorToast(error);
      return null;
    } finally {
      setLoading(false);
    }
  };

  const removeQuoteOffering = async (
    payload: IQuoteOfferingRemoveReqSchema,
  ) => {
    try {
      setLoading(true);
      const res = await doRemoveQuoteOffering(quoteId, payload);

      queryClient.invalidateQueries([
        ...cpqServiceQueryKeys.quotePrices(quoteId),
      ]);
      return res;
    } catch (error) {
      handleApiErrorToast(error);
      return null;
    } finally {
      setLoading(false);
    }
  };
  return {
    loading,
    quoteOffering,
    createQuoteOffering,
    updateQuoteOffering,
    deleteQuoteOffering,
    revertQuoteOffering,
    removeQuoteOffering,
  };
};

export default useQuoteOffering;
