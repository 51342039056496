import {
  MBox,
  MCircularProgress,
  MCircularProgressLabel,
} from '~app/components/Monetize';

export const LogoUploadingIndicator = ({
  uploadProgressPercentage,
}: {
  uploadProgressPercentage: number;
}) => {
  return (
    <MBox position="relative" minH="100px">
      <MBox
        position="absolute"
        width="100%"
        height="100%"
        top="0px"
        alignItems="center"
        justifyContent="center"
        display="flex"
        backgroundColor="rgba(24, 36, 60, 0.97)"
      >
        <MCircularProgress
          value={uploadProgressPercentage}
          color="tPurple.base"
        >
          <MCircularProgressLabel color="white">
            {uploadProgressPercentage}%
          </MCircularProgressLabel>
        </MCircularProgress>
      </MBox>
    </MBox>
  );
};
