import isEmpty from 'lodash/isEmpty';
import { FC, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetAccountById } from '~app/api/accountsService';
import { useGetRefundById, usePrintRefundToHtml } from '~app/api/refundService';
import { DynamicLabelValueBanner } from '~app/components/DynamicLabelValueBanner/DynamicLabelValueBanner';
import {
  MBox,
  MPageContainer,
  MPageHeader,
  MSpinner,
} from '~app/components/Monetize';
import { HtmlIFrameContentRenderer } from '~app/components/Monetize/HtmlIFrameContentRenderer';
import { ROUTES } from '~app/constants';
import { useHandleApiError } from '~app/hooks/useHandleApiError';
import { REFUND_STATUS_DISPLAY } from '~app/types';
import { toDateShort } from '../../../utils/dates';
import { RefundActions } from './RefundActions';

const refundReadableErrorMessage: { [key: string]: string } = {
  lost_or_stolen_card: 'The card was reported lost or stolen.',
  expired_or_canceled_card: 'The card has expired or was canceled.',
  charge_for_pending_refund_disputed:
    'The charge for the pending refund was disputed.',
  insufficient_funds: 'There are insufficient funds available.',
  declined: 'The transaction was declined.',
  merchant_request: 'Refund requested by the merchant.',
  unknown: 'The reason for the refund failure is unknown.',
};

export const RefundPage: FC = () => {
  const params = useParams();
  const navigate = useNavigate();

  const refundId = params?.refundId || '';
  const accountId = params?.accountId || '';

  const { handleApiError } = useHandleApiError();

  const { data: refund, isLoading: isRefundIdLoading } = useGetRefundById(
    refundId,
    {
      enabled: !!refundId,
      onError: (err) =>
        handleApiError(err, ROUTES.getAccountPaymentsRoute(accountId)),
    },
  );

  const { data: accountDetails } = useGetAccountById(accountId, {
    enabled: !!accountId,
    refetchOnWindowFocus: false,
  });

  const handleBackNavigation = () => {
    navigate(ROUTES.getAccountPaymentsRoute(accountId!));
  };

  const { data, isLoading, isRefetching, error, isInitialLoading } =
    usePrintRefundToHtml({
      refundId,
    });

  const DisplayBannerComponent = useMemo(() => {
    if (!isRefundIdLoading) {
      if (isEmpty(refund)) {
        return null;
      }

      const data = [
        {
          label: 'Created Date',
          value: toDateShort(refund?.createDate),
        },
        {
          label: 'Last Modified Date',
          value: toDateShort(refund?.modifyDate),
        },
        {
          label: 'Settled Date',
          value: toDateShort(refund?.settledDate),
        },
      ];
      refund?.gatewayReference &&
        data.push({
          label: 'Gateway Reference No.',
          value: refund?.gatewayReference,
        });
      refund?.description &&
        data.push({
          label: 'Description',
          value: refund?.description,
        });
      refund?.errorCode &&
        data.push({
          label: 'Error Code',
          value: refund?.errorCode,
        });
      refund.errorMessage &&
        data.push({
          label: 'Error Message',
          value: refundReadableErrorMessage[refund.errorMessage],
        });

      return <DynamicLabelValueBanner title="Refund Details" data={data} />;
    }
    return (
      <MBox w="100%" display="flex" alignItems="center" justifyContent="center">
        <MSpinner size="sm" />
      </MBox>
    );
  }, [isRefundIdLoading, refund]);

  if (error) {
    // TODO: add error state
    return null;
  }

  return (
    <MPageContainer height="auto">
      <MPageHeader
        hasBackButton
        title="View Refund"
        status={refund?.status ? REFUND_STATUS_DISPLAY[refund?.status] : ''}
        parentLink={ROUTES.getAccountPaymentsRoute(accountId)}
        parentLinkTitle={accountId && accountDetails?.accountName}
        copyUrl
        backButtonCallback={handleBackNavigation}
        id={refund?.id}
      >
        {refund && <RefundActions refund={refund} />}
      </MPageHeader>

      <MBox
        width="100%"
        px={[1, 1, 8]}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        {DisplayBannerComponent}
        <MBox
          width="100%"
          mt={6}
          borderWidth={0.5}
          borderColor="tBlue.hover"
          background="tWhite.titanWhite"
        >
          <HtmlIFrameContentRenderer
            id={'refund-content'}
            html={data}
            isInitialLoading={isInitialLoading}
            isReFetching={isLoading || isRefetching}
          ></HtmlIFrameContentRenderer>
        </MBox>
      </MBox>
    </MPageContainer>
  );
};
