const InvoicePastDueInfoIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="currentColor"
      {...props}
    >
      <path
        d="M17.6666 7.66666H11C10.0833 7.66666 9.33331 8.41666 9.33331 9.33332V22.6667C9.33331 23.5833 10.0833 24.3333 11 24.3333H21C21.9166 24.3333 22.6666 23.5833 22.6666 22.6667V12.6667L17.6666 7.66666ZM11 22.6667V9.33332H16.8333V12.6667H21V22.6667H11ZM15.1666 21.8333H16.8333V21H17.6666C18.125 21 18.5 20.625 18.5 20.1667V17.6667C18.5 17.2083 18.125 16.8333 17.6666 16.8333H15.1666V16H18.5V14.3333H16.8333V13.5H15.1666V14.3333H14.3333C13.875 14.3333 13.5 14.7083 13.5 15.1667V17.6667C13.5 18.125 13.875 18.5 14.3333 18.5H16.8333V19.3333H13.5V21H15.1666V21.8333Z"
        fill="currentColor"
      />
      <circle cx="21.5" cy="8.5" r="4" fill="#BF4848" stroke="#BF4848" />
    </svg>
  );
};

export default InvoicePastDueInfoIcon;
