import React, { FunctionComponent as FC } from 'react';
import { FormatNumberOptions } from '~app/types';
import { formatCurrency } from '~app/utils';
import MText, { MTextProps } from './MText';

interface MCurrencyProps extends MTextProps {
  amount: number | string | null | undefined;
  options: Omit<FormatNumberOptions, 'currency'> & { currency: string };
}
export const MCurrency: FC<MCurrencyProps> = (props: MCurrencyProps) => {
  const { amount, options, ...rest } = props;
  const formatted = formatCurrency(amount, options);
  return <MText {...rest}>{formatted}</MText>;
};
