import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ROLES, ROUTES } from '~app/constants';
import { useAuth } from '~app/services/auth0';

const useDashboardTab = () => {
  const { currentTenantUserHasRole } = useAuth();

  const getDashboardTabRouteByRole = (): string => {
    if (currentTenantUserHasRole(ROLES.DASHBOARD_FINANCE_ROLES)) {
      return ROUTES.DASHBOARD_FINANCE;
    }
    if (currentTenantUserHasRole(ROLES.DASHBOARD_SALES_ROLES)) {
      return ROUTES.DASHBOARD_SALES;
    }
    if (currentTenantUserHasRole(ROLES.DASHBOARD_APPROVAL_ROLES)) {
      return ROUTES.DASHBOARD_APPROVALS;
    }
    return ROUTES.ACCESS_DENIED;
  };

  return {
    getDashboardTabRouteByRole,
  };
};

export default useDashboardTab;
