import { FC, useMemo } from 'react';
import InvoicesIcon from '~app/assets/icons/InvoicesIcon';
import { QuoteBodyAccordion } from '~app/components/Quotes/QuoteBodyAccordion';
import QuoteFormBillingSchedule from '../../Quote/components/QuoteFormBillingSchedule';
import { hasVariableAmountProduct } from '~app/utils/quotes';
import { useFlags } from '~app/services/launchDarkly';
import { QuoteInternalBillingInfo } from './QuoteInternalBillingInfo';
import { useQuoteContext } from '../../Quote/quoteContext';
import { QuoteStatusEnum, QuoteTypeEnum } from '~app/types';

interface QuoteInternalBillingProps {
  onOpenContactDrawer: () => void;
}

export const QuoteInternalBilling: FC<QuoteInternalBillingProps> = ({
  onOpenContactDrawer,
}: QuoteInternalBillingProps) => {
  const { hideBillingSchedule } = useFlags();
  const { quoteData } = useQuoteContext();
  const { quote } = quoteData;

  const anyVariableAmountProducts = hasVariableAmountProduct(quote);

  const title = useMemo(() => {
    if (quote?.status !== QuoteStatusEnum.DRAFT) {
      return 'Billing Details';
    }
    if (quote?.type === QuoteTypeEnum.NEW) {
      return 'Add Billing Details';
    } else {
      return 'Confirm Billing Details';
    }
  }, [quote?.status, quote?.type]);

  return (
    <QuoteBodyAccordion title={title} icon={InvoicesIcon} mb="0">
      <QuoteInternalBillingInfo onOpenContactDrawer={onOpenContactDrawer} />

      {!hideBillingSchedule &&
        quote?.quoteOfferings &&
        quote?.quoteOfferings.length > 0 && (
          <QuoteFormBillingSchedule
            loading={quoteData.billingScheduleLoading}
            borderWidth="1px"
            borderRadius="0.25rem"
            mt="6"
            borderColor="tGray.lightPurple"
            itemProps={{ border: 'none' }}
            anyVariableAmountProducts={anyVariableAmountProducts}
          />
        )}
    </QuoteBodyAccordion>
  );
};
