import { useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form';
import {
  InvoiceApplicationFormData,
  SelectionStateEnum,
  TransactableSourceType,
} from '../../types';
import { MGrid } from '../Monetize';
import { TransactionApplicationTableHeader } from './TransactionApplicationTableHeader';
import { TransactionApplicationTableRow } from './TransactionApplicationTableRow';
import { TransactionApplicationTableSelectRow } from './TransactionApplicationTableSelectRow';

const TABLE_GRID_TEMPLATE_COLUMNS =
  'minmax(auto, 1.25rem) minmax(8rem, auto) repeat(2, minmax(auto, 1fr)) repeat(3, minmax(auto, 8rem))';

interface TransactionApplicationTableProps {
  sourceType: TransactableSourceType;
  formState: UseFormReturn<InvoiceApplicationFormData>;
  /**
   * Full amount of Payment or Credit
   */
  totalAvailableAmount: number;
  /**
   * Currently unapplied amount of payment or credit
   */
  availableAmount: number;
  isDisabled?: boolean;
}

export const TransactionApplicationTable = ({
  sourceType,
  totalAvailableAmount,
  formState,
  availableAmount,
  isDisabled = false,
}: TransactionApplicationTableProps) => {
  const { watch, setValue } = formState;
  const checkedState = watch('checkedState');
  const rows = watch('rows');

  const allSelected = rows.every(
    (row) => row.checkedState === SelectionStateEnum.ALL,
  );

  const someSelected =
    !allSelected &&
    rows.some(
      (row) =>
        row.checkedState === SelectionStateEnum.ALL ||
        row.checkedState === SelectionStateEnum.SOME,
    );

  useEffect(() => {
    if (allSelected) {
      setValue('checkedState', SelectionStateEnum.ALL);
    } else if (someSelected) {
      setValue('checkedState', SelectionStateEnum.SOME);
    } else {
      setValue('checkedState', SelectionStateEnum.NONE);
    }
  }, [allSelected, setValue, someSelected]);

  const handleToggleSelectAll = () => {
    if (
      checkedState === SelectionStateEnum.ALL ||
      checkedState === SelectionStateEnum.SOME
    ) {
      setValue('checkedState', SelectionStateEnum.NONE);
      setValue(
        'rows',
        rows.map((row) => ({
          ...row,
          appliedAmount: 0,
          checkedState: SelectionStateEnum.NONE,
        })),
      );
    } else {
      let amountAvailable = totalAvailableAmount;
      setValue(
        'rows',
        rows.map((row) => {
          const { amountDueWithoutApplied } = row;
          const appliedAmount = Math.min(
            amountAvailable,
            amountDueWithoutApplied,
          );
          amountAvailable -= appliedAmount;
          return { ...row, appliedAmount };
        }),
      );
    }
  };

  return (
    <MGrid
      role="table"
      gridTemplateColumns={TABLE_GRID_TEMPLATE_COLUMNS}
      columnGap={4}
      rowGap={4}
      px="4"
    >
      <TransactionApplicationTableHeader sourceType={sourceType} />
      <TransactionApplicationTableSelectRow
        checkedState={checkedState}
        isDisabled={isDisabled}
        onToggleSelectAll={handleToggleSelectAll}
      />
      {rows.map((row, i) => (
        <TransactionApplicationTableRow
          key={i}
          index={i}
          row={row}
          formState={formState}
          isDisabled={isDisabled}
          availableAmount={availableAmount}
        />
      ))}
    </MGrid>
  );
};
