import { useGetBillGroupStatsById } from '~app/api/accountsService';
import { MDivider, MGrid } from '~app/components/Monetize';
import { IBillGroupResp, IDunningStepCreateSchema, Maybe } from '~app/types';
import { formatCurrency } from '~app/utils';
import { toDateShort } from '~app/utils/dates';
import { BillGroupDetailsCard } from '../../BillGroupDetailsCard';

interface BillGroupDetailHeaderProps {
  billGroup: IBillGroupResp;
  dunningStep?: Maybe<IDunningStepCreateSchema>;
}

export const BillGroupDetailHeader = ({
  billGroup,
  dunningStep,
}: BillGroupDetailHeaderProps) => {
  const currency = billGroup.currency;

  const { data: stats } = useGetBillGroupStatsById(billGroup.id);

  return (
    <MGrid
      justifyItems="start"
      gridTemplateColumns="repeat(5, 1fr 0.1fr) 1fr"
      columnGap={2}
      mb={10}
    >
      {billGroup.nextInvoiceDate && (
        <>
          <BillGroupDetailsCard
            label="Next Invoice Date"
            value={toDateShort(billGroup.nextInvoiceDate)}
          />
          <MDivider
            orientation="vertical"
            variant="thick"
            justifySelf="center"
          />
        </>
      )}
      {billGroup.dunningStepId && (
        <>
          <BillGroupDetailsCard
            label="Dunning Step"
            value={dunningStep?.stepName || ''}
          />
          <MDivider
            orientation="vertical"
            variant="thick"
            justifySelf="center"
          />
        </>
      )}
      {stats && (
        <>
          <BillGroupDetailsCard
            label="Open Quotes"
            value={stats.openQuotes.toString()}
          />
          <MDivider
            orientation="vertical"
            variant="thick"
            justifySelf="center"
          />
          <BillGroupDetailsCard label="Currency" value={currency} />
          <MDivider
            orientation="vertical"
            variant="thick"
            justifySelf="center"
          />
          <BillGroupDetailsCard
            label="Invoiced"
            value={formatCurrency(stats.invoicedAmount, { currency })}
          />
          <MDivider
            orientation="vertical"
            variant="thick"
            justifySelf="center"
          />
          <BillGroupDetailsCard
            label="Amount Outstanding"
            value={formatCurrency(stats.outstandingAmount, { currency })}
          />
        </>
      )}
    </MGrid>
  );
};
