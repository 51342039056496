import { useMemo } from 'react';
import { QUOTE_TEMPLATE_ITEMS } from '../constants/quotes';
import { QuoteTemplates, useFlags } from '../services/launchDarkly';

export const BLANK_TEMPLATE_VALUE = '_BLANK_';

export const useQuoteTemplatesList = (allowEmptyDefault = false) => {
  const { quoteTemplates: quoteTemplatesFlag } = useFlags();

  const quoteTemplates = useMemo(() => {
    const enabledItems = new Set(
      quoteTemplatesFlag
        ? Object.keys(quoteTemplatesFlag).filter(
            (key) => quoteTemplatesFlag[key as keyof QuoteTemplates],
          )
        : [],
    );
    const items = QUOTE_TEMPLATE_ITEMS.filter(
      ({ value, isDefault }) => isDefault || enabledItems.has(value),
    );
    if (allowEmptyDefault) {
      items.unshift({
        title: '- Tenant Default -',
        value: BLANK_TEMPLATE_VALUE,
      });
    }
    return items;
  }, [allowEmptyDefault, quoteTemplatesFlag]);

  return {
    quoteTemplates,
  };
};
