// eslint-disable-next-line no-restricted-imports
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useEffect } from 'react';
import { useParams } from 'react-router';
import { initLaunchDarklyContextAnonymous } from '../../services/launchDarkly';

interface AnonymousFeatureFlagWrapperProps {
  children: React.ReactNode;
}

/**
 * Setup feature flag for anonymous user
 * The user is anonymous, but still includes the tenant context
 */
export function AnonymousFeatureFlagWrapper({
  children,
}: AnonymousFeatureFlagWrapperProps) {
  const ldClient = useLDClient();
  const { tenantId, invoiceSecretId } = useParams();

  useEffect(() => {
    if (tenantId) {
      initLaunchDarklyContextAnonymous(ldClient, tenantId, invoiceSecretId);
    }
  }, [invoiceSecretId, ldClient, tenantId]);

  return <>{children}</>;
}
