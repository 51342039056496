import React, { useEffect } from 'react';
import { useAuth } from '~app/services/auth0';
import AppLoading from '~components/App/AppLoading';

const EmptyLogin = () => {
  const { loginWithRedirect } = useAuth();

  useEffect(() => {
    loginWithRedirect();
  }, []);

  return <AppLoading />;
};

export default EmptyLogin;
