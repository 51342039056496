import { addDays } from 'date-fns/addDays';
import { addMonths } from 'date-fns/addMonths';
import { parseISO } from 'date-fns/parseISO';
import { Control, Controller } from 'react-hook-form';
import {
  DatePicker,
  DatePickerProps,
} from '~app/components/Monetize/DatePicker/DatePicker';
import {
  IOfferingRes,
  IQuoteOfferingReqSchema,
  IQuoteOfferingRespSchema,
  IQuoteRespSchema,
  OfferingTypesEnum,
  ProductTypeEnum,
  RateBillingFrequencyEnum,
} from '~app/types';

interface QuoteOfferingScheduledEndDateProps {
  isOfferingLoading: boolean;
  quote: IQuoteRespSchema;
  control: Control<IQuoteOfferingReqSchema>;
  priorScheduledQuoteOffering?: IQuoteOfferingRespSchema | null;
  nextScheduledQuoteOffering?: IQuoteOfferingRespSchema | null;
  isReadOnly?: boolean;
  isDisabled?: boolean;
  isChildOffering?: boolean;
  onChange?: (date: string) => void;
  offering: IOfferingRes;
  monthInterval: number;
  parentQuoteOffering?: IQuoteOfferingRespSchema | null;
}

export const QuoteOfferingScheduledEndDate = ({
  isOfferingLoading,
  control,
  quote,
  priorScheduledQuoteOffering,
  nextScheduledQuoteOffering,
  isReadOnly,
  isDisabled = false,
  isChildOffering = false,
  onChange: onChangeProp,
  offering,
  monthInterval,
  parentQuoteOffering,
}: QuoteOfferingScheduledEndDateProps) => {
  const isMinCommit = offering.type === OfferingTypesEnum.MIN_COMMIT;
  const hasUsageProduct =
    offering.products?.some(
      (product) => product.productType === ProductTypeEnum.USAGE,
    ) ?? false;

  const datepickerOptions: Partial<DatePickerProps> = {};

  if (isMinCommit || hasUsageProduct) {
    datepickerOptions.mode =
      parentQuoteOffering?.billingFrequency ===
      RateBillingFrequencyEnum.ANNUALLY
        ? 'YEAR'
        : 'MONTH';
    datepickerOptions.interval =
      parentQuoteOffering?.billingFrequency ===
      RateBillingFrequencyEnum.ANNUALLY
        ? 1
        : (monthInterval as 1 | 3 | 6); // This will be only 1 or 3 or 6

    // Last frequency period cannot be selected
    datepickerOptions.minDate = priorScheduledQuoteOffering?.endDate
      ? addMonths(parseISO(priorScheduledQuoteOffering?.endDate), monthInterval)
      : addDays(
          addMonths(parseISO(quote.contractStartDate), monthInterval),
          -1,
        );
    datepickerOptions.maxDate = parseISO(
      nextScheduledQuoteOffering?.endDate || quote.contractEndDate,
    );
    datepickerOptions.baseDate = parseISO(quote.contractEndDate);
    datepickerOptions.anchorMonth =
      parseISO(quote.contractEndDate).getMonth() + 1;
  } else {
    const minDateStr =
      parentQuoteOffering?.startDate || quote.contractStartDate;
    let maxDate = parseISO(quote.contractEndDate);

    if (nextScheduledQuoteOffering?.endDate) {
      maxDate = addDays(parseISO(nextScheduledQuoteOffering?.endDate), -1);
    }

    datepickerOptions.minDate = isChildOffering
      ? addDays(parseISO(minDateStr), 1)
      : parseISO(minDateStr);
    datepickerOptions.maxDate = maxDate;
  }

  return (
    <>
      <Controller
        control={control}
        name={isChildOffering ? 'schedule.endDate' : 'endDate'}
        render={({ field: { onChange, value, ...rest } }) => (
          <DatePicker
            {...rest}
            value={!isChildOffering && !value ? quote.contractEndDate : value}
            {...datepickerOptions}
            onChange={(date) => {
              onChange(date);
              onChangeProp && onChangeProp(date!);
            }}
            isReadOnly={isReadOnly}
            isDisabled={isOfferingLoading || isDisabled}
            variant={isReadOnly ? 'readonly' : 'primary'}
          />
        )}
      />
    </>
  );
};
