import { avatarAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';
import defaultTheme from '@chakra-ui/theme';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(avatarAnatomy.keys);

const baseStyle = definePartsStyle({
  container: {
    color: 'tWhite.base',
  },
  badge: {
    border: 0,
  },
});

const md = defineStyle({
  width: '52px',
  height: '52px',
});

const sizes = {
  ...defaultTheme.components.Avatar.sizes,
  md: definePartsStyle({
    ...defaultTheme.components.Avatar.sizes?.md,
    label: { fontSize: '18px', fontWeight: 'semibold' },
    container: md,
  }),
};

export const avatarTheme = defineMultiStyleConfig({
  baseStyle,
  defaultProps: {
    size: 'xs',
  },
  sizes,
});
