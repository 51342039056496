import { Link, useNavigate } from 'react-router-dom';
import { handleApiErrorToast } from '~app/api/axios';
import { useGetAllApprovalList } from '~app/api/ruleService';
import { useDeleteTeam } from '~app/api/settingsService';
import { MBox, MLink, MText } from '~app/components/Monetize';
import { ROUTES } from '~app/constants';
import { useConfirmModal } from '~app/services/confirmModal';
import { useToast } from '~app/services/toast';

export const useHandleDeleteTeam = () => {
  const { addToast } = useToast();
  const navigate = useNavigate();
  const { mutateAsync } = useGetAllApprovalList();
  const {
    showConfirmModal: showDeleteModal,
    setModalLoading: setDeleteModalLoading,
    closeModal: closeDeleteModal,
  } = useConfirmModal();
  const { mutateAsync: deleteTeamMutation, isLoading: deleteLoading } =
    useDeleteTeam();

  const handleDeleteTeam = async ({
    teamName,
    teamId,
  }: {
    teamName: string;
    teamId: string;
  }) => {
    try {
      const approvalsAssignedToTeam = await mutateAsync({ teamId });

      if (
        approvalsAssignedToTeam?.length &&
        approvalsAssignedToTeam.length > 0
      ) {
        showDeleteModal({
          title: `You can't delete ${teamName}`,
          description:
            'This team is associated with an approval rule. Update any approval rules that are using this team as the approver to a different team or person.',
          content: (
            <MBox mt="4">
              <MText fontSize="md" fontWeight="600" mb="1">
                Rules need to be updated
              </MText>

              {approvalsAssignedToTeam.map(({ id, name }) => (
                <MBox key={id}>
                  <MLink
                    fontSize="md"
                    as={Link}
                    to={ROUTES.getRuleEditRoute(id, 'approvals')}
                    onClick={closeDeleteModal}
                  >
                    {name}
                  </MLink>
                </MBox>
              ))}
            </MBox>
          ),
          modalFooterProps: {
            display: 'none',
          },
        });
        return;
      }

      const onYes = async () => {
        setDeleteModalLoading(true);
        try {
          const startTime = new Date().getTime();
          await deleteTeamMutation(teamId);

          addToast({
            summary: 'Team Removed Successfully',
            detail: `${teamName} has successfully been removed.`,
            severity: 'success',
            timeTaken: new Date().getTime() - startTime,
          });
        } catch (err) {
          handleApiErrorToast(err);
        } finally {
          setDeleteModalLoading(false);
          closeDeleteModal();
          navigate(ROUTES.SETTINGS_TEAMS);
        }
      };

      showDeleteModal({
        title: `Are you sure you want to delete ${teamName}?`,
        description: 'Once deleted, This action cannot be reversed.',
        onYes,
        yesButton: 'Delete',
        yesBtnProps: {
          variant: 'delete' as any,
        },
        noBtnProps: {
          variant: 'cancel' as any,
        },
      });
    } catch (error) {
      handleApiErrorToast(error);
    }
  };

  return {
    handleDeleteTeam,
    deleteLoading,
  };
};
