import { MCustomSelect, MFlex } from '~app/components/Monetize';
import { Highlighter } from '~app/components/Monetize/MPageSearchInput/Highlighter';
import { FieldMappingBaseFieldType } from '~app/types/fieldMappingTypes';
import { CrmFieldMappingSearchInput } from './CrmFieldMappingSearchInput';

type CrmFieldMappingDropdownProps = {
  placeholder: string;
  fields: FieldMappingBaseFieldType[];
  fieldProps: any;
  index: number;
  handleOnClear: (idx: number, side: 'source' | 'target') => void;
  onChange: (value: string) => void;
  validateData: (index: number) => void;
  getVariant: (idx: number, side: 'source' | 'target') => 'unsaved' | undefined;
  changeMode: (ev: any, idx: number) => void;
  setAlert: (ev: any, idx: number) => void;
  isMNField?: boolean;
};

export const CrmFieldMappingDropdown = ({
  placeholder,
  fields,
  fieldProps,
  index,
  handleOnClear,
  onChange,
  validateData,
  getVariant,
  changeMode,
  setAlert,
  isMNField,
}: CrmFieldMappingDropdownProps) => {
  return (
    <MCustomSelect
      isLazy
      returnItem
      clearable
      onClear={() => handleOnClear(index, 'source')}
      onChange={(ev) => {
        onChange(ev as any);
        validateData(index);
        isMNField && changeMode(ev, index);
        setAlert(ev, index);
      }}
      itemTitle="displayLabel"
      itemValue="fieldApiName"
      useMainInputAsSearchInput
      internalFilterFields={['displayLabel', 'fieldApiName', 'dataType']}
      MainInputComponent={CrmFieldMappingSearchInput}
      placeholder={placeholder}
      items={fields}
      useHighlightedItemList
      showItemDivider
      renderItemContent={({ item, query }) => {
        const { dataType, displayLabel, fieldApiName } =
          item as FieldMappingBaseFieldType;
        return (
          <MFlex
            direction="column"
            overflow="hidden"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
          >
            <Highlighter
              title={displayLabel}
              color="tPurple.dark"
              fontSize="sm"
              fontWeight="medium"
              textToHighlight={displayLabel}
              searchWords={[query]}
            />
            <Highlighter
              title={fieldApiName}
              color="tGray.darkPurple"
              fontSize="xs"
              textToHighlight={fieldApiName}
              searchWords={[query]}
            />
            <Highlighter
              title={dataType}
              color="tGray.darkPurple"
              fontSize="xs"
              textTransform="capitalize"
              textToHighlight={dataType}
              searchWords={[query]}
            />
          </MFlex>
        );
      }}
      inputProps={{
        autoComplete: 'off',
        variant: getVariant(index, 'source'),
      }}
      popOverContentProps={{
        width: 'lg',
      }}
      {...fieldProps}
    />
  );
};
