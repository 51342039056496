import { format as formatDate } from 'date-fns/format';
import { parseISO } from 'date-fns/parseISO';
import { useGetChurnedAccounts } from '~app/api/dashboardsService';
import { MBox, MChart, MSkeleton, MText } from '~app/components/Monetize';
import { IChartSeriesAndOptions, IFinanceDashboardRes } from '~app/types';
import { churnedAccountsChartOptions } from './chartOptions';

export const ChurnedAccountsChart = () => {
  const { isLoading, data: churnedAccounts } =
    useGetChurnedAccounts<IChartSeriesAndOptions>({
      select: (data) => ({
        options: churnedAccountsChartOptions,
        series: [
          {
            data: (data as IFinanceDashboardRes).monthlyAccounts?.map(
              ({ yearMonth, accounts }: any) => ({
                x: formatDate(parseISO(yearMonth), 'MMM'),
                y: accounts,
              }),
            ),
          },
        ],
      }),
    });

  return (
    <MBox>
      <MText fontSize="lg" fontWeight="bold">
        Churned Accounts
      </MText>
      {isLoading ? (
        <MSkeleton height={300} />
      ) : (
        <MChart
          options={churnedAccounts?.options || {}}
          series={churnedAccounts?.series || []}
          height={300}
        />
      )}
    </MBox>
  );
};
