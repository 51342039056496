import { BoxProps } from '@chakra-ui/react';
import { FunctionComponent as FC, useCallback, useRef, useState } from 'react';
import {
  doPreviewInvoice,
  usePreviewInvoiceJson,
} from '~app/api/accountsService';
import { InvoiceTaxCalculationError } from '~app/components/Invoices/InvoiceTaxCalculationError';
import { MBox, MButton, MCustomIconButton } from '~app/components/Monetize';
import PreviewPdfDrawer from '~app/components/Monetize/PreviewPdfDrawer/PreviewPdfDrawer';
import { TAX_ERROR_CAN_RESOLVE_STATUSES } from '~app/constants/invoice';

interface PreviewBillGroupInvoicePdfDrawerProps extends BoxProps {
  accountId: string;
  billGroupId: string;
}

/**
 * Allow user to click a button to open a drawer and view invoice PDF document for a bill group
 */
export const PreviewBillGroupInvoicePdfDrawer: FC<
  PreviewBillGroupInvoicePdfDrawerProps
> = ({
  accountId,
  billGroupId,
  ...rest
}: PreviewBillGroupInvoicePdfDrawerProps) => {
  const buttonRef = useRef<typeof MCustomIconButton>(null);
  const [isOpen, setIsOpen] = useState(false);

  const { data: invoice } = usePreviewInvoiceJson(
    { accountId, billGroupId },
    {
      enabled: isOpen,
    },
  );

  const fetchDocument = useCallback(
    async (): Promise<ArrayBuffer | null> =>
      await doPreviewInvoice(billGroupId),
    [billGroupId],
  );

  function handleOpen() {
    setIsOpen(true);
  }

  return (
    <MBox position="relative" {...rest}>
      <MButton variant="tertiary" onClick={handleOpen}>
        Preview Invoice
      </MButton>
      {isOpen && (
        <PreviewPdfDrawer
          filename="invoice-preview"
          isOpen={isOpen}
          onCloseFocusRef={buttonRef}
          fetchDocument={fetchDocument}
          onClose={() => setIsOpen(false)}
        >
          <InvoiceTaxCalculationError
            taxError={invoice?.taxError}
            showReviewAction={
              invoice?.status &&
              TAX_ERROR_CAN_RESOLVE_STATUSES.has(invoice.status)
            }
          />
        </PreviewPdfDrawer>
      )}
    </MBox>
  );
};
