import React, { FunctionComponent as FC, useState } from 'react';

import {
  ApprovalStatusEnum,
  ApprovalStatusUIEnum,
  IApprovalRespSchema,
  IApprovalSchemaUI,
  IQuoteRespSchema,
  QuoteStatusEnum,
} from '~app/types';
import { toDateMMMDDYYYY } from '~app/utils/dates';
import { MBox, MText } from '../Monetize';

import { useAuth } from '~app/services/auth0';
import ApprovalStepPopover from './ApprovalStepPopover';
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  BoxProps,
} from '@chakra-ui/react';
import { logger } from '~app/services/logger';
import { QUOTE_APPROVE_ACTIONABLE_STATUSES } from '~app/constants/quotes';
import MPopoverWrapper from '../Monetize/MPopoverWrapper';

interface StepProps {
  isFirst: boolean;
  isLast: boolean;
  approval: IApprovalSchemaUI;
  onApprove: () => void;
  onReject: () => void;
  onRetract: () => void;
  isCurrentIndex: boolean;
  isAfterRejected: boolean;
  width?: number;
  extraStatus?: 'DONE' | 'PENDING' | '';
  quote: IQuoteRespSchema;
  ContentComponent?: React.ReactNode;
  containerStyles?: BoxProps;
}

const height = 35.3;
// Pythagorean (arrowWidth+borderWidth)*(arrowWidth+borderWidth) + (arrowWidth+borderWidth)*(arrowWidth+borderWidth) = height
const arrowWidth = 24;
const borderWidth = 1;
const innerPadding = 10;
const borderRadius = '2px';
const triWidth = height / 2;
const heightDiffToCenter = (height - arrowWidth) / 2;

const getApprovalStepName = (approval: IApprovalSchemaUI) => {
  switch (approval.status) {
    case ApprovalStatusUIEnum.SENT:
      return 'Sent';
    case ApprovalStatusUIEnum.SUBMITTED:
      return 'Submitted';
    case ApprovalStatusUIEnum.ACCEPTED:
      return 'Accepted';
    case ApprovalStatusUIEnum.MANUALLY_ACCEPTED:
      return 'Manually Accepted';
    case ApprovalStatusUIEnum.NOT_REQUIRED:
      return 'Approval not required';
    default:
      return approval.name;
  }
};
const getReason = ({
  approval,
  quote,
  isLast,
}: {
  approval: IApprovalSchemaUI;
  quote: IQuoteRespSchema;
  isLast: boolean;
}) => {
  const isManualAccepted = quote.manualAcceptanceReason && isLast;

  if (isManualAccepted) {
    return {
      title: 'Reason for Manually Accepting',
      reason: quote.manualAcceptanceReason,
    };
  }
  if (approval.status === ApprovalStatusEnum.DECLINED) {
    return {
      title: 'Reason for Rejection',
      reason: approval.declineReason,
    };
  }

  return null;
};
const CURRENT_STATE = {
  backColor: 'var(--chakra-colors-tPurple-linkWater)',
  borderColor: 'var(--chakra-colors-tPurple-linkWater)',
  textColor: 'tGray.acBlack',
};
const DEFAULT_STATE = {
  backColor: 'var(--chakra-colors-tGray-support)',
  borderColor: 'var(--chakra-colors-tGray-back)',
  textColor: 'tGray.acBlack',
};
const REJECTED_STATE = {
  backColor: 'var(--chakra-colors-tGray-support)',
  borderColor: 'var(--chakra-colors-tGray-back)',
  textColor: 'tGray.acBlack',
};
const APPROVED_STATE = {
  backColor: 'var(--chakra-colors-tGreen-approval)',
  borderColor: 'var(--chakra-colors-tGreen-approval)',
  textColor: 'tWhite.base',
};
const DECLIEND_STATE = {
  backColor: 'var(--chakra-colors-tRed-base)',
  borderColor: 'var(--chakra-colors-tRed-base)',
  textColor: 'tWhite.base',
};
const CAN_APPROVE_STATE = {
  backColor: 'var(--chakra-colors-tGray-support)',
  borderColor: 'var(--chakra-colors-tIndigo-base)',
  textColor: 'tGray.acBlack',
};

const getTheme = ({
  isCurrentUserOrTeam,
  isApproved,
  isNoAction,
  isDeclined,
  skipped,
  isCurrentIndex,
  isAfterRejected,
  extraStatus,
  approval,
  quote,
  isLast,
}: {
  isCurrentUserOrTeam: boolean;
  isApproved: boolean;
  isNoAction: boolean;
  isDeclined: boolean;
  skipped: boolean;
  isCurrentIndex: boolean;
  isAfterRejected: boolean;
  extraStatus?: 'DONE' | 'PENDING' | '';
  approval: IApprovalRespSchema | IApprovalSchemaUI;
  quote: IQuoteRespSchema;
  isLast: boolean;
}) => {
  const canApprove =
    isCurrentUserOrTeam &&
    isNoAction &&
    quote.status === QuoteStatusEnum.REVIEW;
  if (extraStatus === 'DONE') {
    let subLabel = '';
    if (
      approval.status === ApprovalStatusUIEnum.SUBMITTED &&
      quote.approvalSubmittedAt
    ) {
      subLabel = toDateMMMDDYYYY(quote.approvalSubmittedAt);
    }
    if (approval.status === ApprovalStatusUIEnum.SENT && quote.sentDate) {
      subLabel = toDateMMMDDYYYY(quote.sentDate);
    }
    if (quote.manualAcceptanceReason && isLast) {
      subLabel = quote?.acceptedAt ? toDateMMMDDYYYY(quote.acceptedAt) : '';
    }

    return {
      ...APPROVED_STATE,
      showHover: false,
      canDoActions: false,
      isDisabled: false,
      subLabel: subLabel,
    };
  }

  if (extraStatus === 'PENDING') {
    const isSent = approval.status === ApprovalStatusUIEnum.SENT;
    const subLabel =
      isSent && quote.sentDate
        ? `Sent - ${toDateMMMDDYYYY(quote.sentDate)}`
        : '';

    if (isCurrentIndex) {
      return {
        ...CURRENT_STATE,
        showHover: false,
        canDoActions: false,
        isDisabled: false,
        subLabel,
      };
    }

    return {
      ...(isAfterRejected ? REJECTED_STATE : DEFAULT_STATE),
      showHover: false,
      canDoActions: false,
      isDisabled: false,
      subLabel,
    };
  }

  const approverName = approval.teamId ? approval.teamName : approval.username;
  if (isAfterRejected) {
    // disabled state
    return {
      ...REJECTED_STATE,
      showHover: true,
      canDoActions: false,
      isDisabled: true,
      subLabel: `${approverName}`,
    };
  }
  if (isDeclined) {
    // red but hover for current user
    return {
      ...DECLIEND_STATE,
      showHover: true,
      canDoActions: !!isCurrentUserOrTeam,
      isDisabled: false,
      subLabel: `${approverName} - ${toDateMMMDDYYYY(quote.declinedDate)}`,
    };
  }
  if (skipped) {
    return {
      ...APPROVED_STATE,
      showHover: true,
      canDoActions: false,
      isDisabled: false,
      subLabel: `${approverName} - Skipped`,
    };
  }
  if (isApproved) {
    return {
      ...APPROVED_STATE,
      showHover: true,
      canDoActions: !!isCurrentUserOrTeam,
      isDisabled: false,
      subLabel: `${approverName} - ${toDateMMMDDYYYY(
        approval.approvalTimestamp,
      )}`,
    };
  }
  if (isCurrentIndex) {
    // little indication of current user
    return {
      ...CURRENT_STATE,
      borderColor: canApprove
        ? 'var(--chakra-colors-tIndigo-base)'
        : CURRENT_STATE.borderColor,
      showHover: true,
      canDoActions: !!isCurrentUserOrTeam,
      isDisabled: false,
      subLabel: `${approverName}`,
    };
  }
  if (canApprove) {
    logger.log(approval.username || approval.teamName, 'canApprove');
    return {
      ...CAN_APPROVE_STATE,
      showHover: true,
      canDoActions: !!isCurrentUserOrTeam,
      isDisabled: false,
      subLabel: `${approverName}`,
    };
  }

  return {
    ...(isAfterRejected ? REJECTED_STATE : DEFAULT_STATE),
    showHover: true,
    canDoActions: !!isCurrentUserOrTeam,
    isDisabled: false,
    subLabel: `${approverName}`,
  };
};

const Step: FC<StepProps> = React.forwardRef<any, StepProps>(
  (props: StepProps, ref) => {
    const {
      isFirst,
      isLast,
      approval,
      onApprove,
      onReject,
      isCurrentIndex,
      isAfterRejected,
      width,
      extraStatus,
      quote,
      ContentComponent,
      onRetract,
      containerStyles,
    } = props;

    const { userId } = useAuth();
    const isCurrentUserOrTeam =
      userId === approval.userId || approval.currentUserApprover;
    const isApproved = approval.status === ApprovalStatusEnum.APPROVED;
    const isNoAction = approval.status === ApprovalStatusEnum.NO_ACTION;
    const isDeclined = approval.status === ApprovalStatusEnum.DECLINED;

    const { skipped } = approval;
    const approvalStepName = getApprovalStepName(approval);

    const showRightTri = !isLast;
    const showLeftTri = !isFirst;

    const {
      showHover: showHoverGetTheme,
      isDisabled,
      backColor,
      textColor,
      borderColor,
      subLabel,
      canDoActions: canDoActionsGetTheme,
    } = getTheme({
      isCurrentUserOrTeam,
      isApproved,
      isNoAction,
      isDeclined,
      isCurrentIndex,
      skipped,
      isAfterRejected,
      extraStatus,
      approval,
      quote,
      isLast,
    });
    // if it's not review, hide all hover actions
    const showActionButtons = QUOTE_APPROVE_ACTIONABLE_STATUSES.has(
      quote.status,
    );
    const canShowPopup =
      quote.status !== QuoteStatusEnum.DRAFT && showHoverGetTheme;
    const canDoActions = showActionButtons && canDoActionsGetTheme;
    const [showPopup, setShowPopup] = useState(false);

    const reason = getReason({ approval, quote, isLast });

    return (
      <MBox position="relative" {...containerStyles}>
        <MPopoverWrapper
          placement="bottom"
          trigger="hover"
          usePortal
          isOpen={showPopup}
          onClose={() => setShowPopup(false)}
          triggerContent={
            <PopoverTrigger>
              <MBox
                as="span"
                role="group"
                cursor={isDisabled ? 'not-allowed' : 'pointer'}
                my="2"
                minW={`${width}px`}
                display="flex"
                alignItems="center"
                background={backColor}
                paddingLeft={`${(showLeftTri ? triWidth : 0) + innerPadding}px`}
                paddingRight={`${innerPadding + triWidth}px`}
                borderRadius={`${!showLeftTri ? borderRadius : 0} ${
                  !showRightTri ? borderRadius : 0
                } ${!showRightTri ? borderRadius : 0} ${
                  !showLeftTri ? borderRadius : 0
                }`}
                h={`${height}px`}
                borderColor={borderColor}
                borderTopWidth={`${borderWidth}px`}
                borderBottomWidth={`${borderWidth}px`}
                borderLeftWidth={!showLeftTri ? `${borderWidth}px` : '0px'}
                borderRightWidth={!showRightTri ? `${borderWidth}px` : '0px'}
                position="relative"
                height={`${height}px`}
                _before={
                  showLeftTri
                    ? {
                        content: '" "',
                        borderTop: `${borderWidth}px solid ${borderColor}`,
                        borderRight: `${borderWidth}px solid ${borderColor}`,
                        width: `${arrowWidth}px`,
                        height: `${arrowWidth}px`,
                        position: 'absolute',
                        left: `${heightDiffToCenter}px`,
                        // top: '0px',
                        backgroundColor: 'white',
                        zIndex: 50,
                        transform: `translate(-${triWidth}px,0px) rotate(45deg)`,
                      }
                    : {}
                }
                _after={
                  showRightTri
                    ? {
                        content: '" "',
                        borderTop: `${borderWidth}px solid ${borderColor}`,
                        borderRight: `${borderWidth}px solid ${borderColor}`,
                        width: `${arrowWidth}px`,
                        height: `${arrowWidth}px`,
                        position: 'absolute',
                        right: `${heightDiffToCenter}px`,
                        // top: '0px',
                        backgroundColor: backColor,
                        zIndex: 150,
                        transform: `translate(${triWidth}px,0px) rotate(45deg)`,
                      }
                    : {}
                }
                onMouseEnter={() => canShowPopup && setShowPopup(true)}
                zIndex={100}
              >
                {ContentComponent ? (
                  ContentComponent
                ) : (
                  <>
                    <MBox maxW="100%">
                      <MText
                        fontSize="sm"
                        color={textColor}
                        noOfLines={1}
                        isTruncated
                        lineHeight={1}
                        mb="0.5"
                      >
                        {approvalStepName}
                      </MText>
                      <MText
                        fontSize="xxs"
                        color={textColor}
                        noOfLines={1}
                        isTruncated
                        lineHeight={1}
                      >
                        {subLabel}
                      </MText>
                    </MBox>
                  </>
                )}
              </MBox>
            </PopoverTrigger>
          }
          popoverContentProps={{
            display: 'flex',
            alignItems: 'center',
            border: 'none',
            bg: 'transparent',
            zIndex: 200,
          }}
          bodyContent={
            <>
              <MBox
                bg="white"
                borderRadius={4}
                w="200px"
                boxShadow={'0px 4px 25px rgba(180, 188, 239, 0.38)'}
              >
                <ApprovalStepPopover
                  quote={quote}
                  approval={approval}
                  reason={reason?.reason || ''}
                  reasonTitle={reason?.title || ''}
                  canDoActions={canDoActions}
                  onClose={() => setShowPopup(false)}
                  onApprove={onApprove}
                  onReject={onReject}
                  onRetract={onRetract}
                />
              </MBox>
            </>
          }
        />
      </MBox>
    );
  },
);

Step.defaultProps = {
  width: 180,
  extraStatus: '',
};

export default Step;
