import { FormControl, FormLabel } from '@chakra-ui/react';
import { FunctionComponent as FC } from 'react';
import { FilterTypeGlte, RenderTableFilterOptionProps } from '~app/types';
import { MVStack } from '../../chakra';
import { DatePicker } from '../../DatePicker/DatePicker';

interface DateGlteTableFilterOptionContentProps
  extends RenderTableFilterOptionProps {
  showTimePicker?: boolean;
}

const DateGlteTableFilterOptionContent: FC<
  DateGlteTableFilterOptionContentProps
> = ({
  filter,
  filterOption,
  handleFilterChange,
  showTimePicker = false,
}: DateGlteTableFilterOptionContentProps) => {
  const internalFilter = filter as FilterTypeGlte;
  return (
    <MVStack>
      <FormControl>
        <FormLabel>From</FormLabel>
        <DatePicker
          includeTime={showTimePicker}
          placeholder="From"
          onChange={(date) => {
            handleFilterChange(
              {
                ...internalFilter?.value,
                min: date!,
              },
              filterOption,
            );
          }}
          value={internalFilter?.value?.min}
          clearable
        />
      </FormControl>
      <FormControl>
        <FormLabel>To</FormLabel>
        <DatePicker
          includeTime={showTimePicker}
          placeholder="To"
          onChange={(date) => {
            handleFilterChange(
              {
                ...internalFilter?.value,
                max: date!,
              },
              filterOption,
            );
          }}
          value={internalFilter?.value?.max}
          clearable
        />
      </FormControl>
    </MVStack>
  );
};

export { DateGlteTableFilterOptionContent };
