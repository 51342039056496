import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { handleApiErrorToast } from '~app/api/axios';
import { useUpdateNewQuoteType } from '~app/api/cpqService';
import {
  MBox,
  MButton,
  MCenterModal,
  MStack,
  MText,
} from '~app/components/Monetize';
import {
  INewQuoteTypeReqSchema,
  IQuoteRespSchema,
  NewQuoteTypeEnum,
} from '~app/types';
import { ManualRenewalForm } from './ManualRenewalForm';

interface ManualRenewalModalProps {
  isOpen: boolean;
  quoteId: string;
  manualRenewalForm: UseFormReturn<INewQuoteTypeReqSchema, object>;
  setQuote: (quote: IQuoteRespSchema | null) => void;
  onClose: () => void;
}

const ManualRenewalModal = (props: ManualRenewalModalProps) => {
  const { isOpen, quoteId, setQuote, onClose, manualRenewalForm } = props;
  const { formState, handleSubmit } = manualRenewalForm;
  const { isValid, isDirty } = formState;
  const { mutate: updateNewQuoteType, isLoading } = useUpdateNewQuoteType({
    onSuccess: (data) => {
      setQuote(data);
      onClose();
    },
    onError: (error) => {
      handleApiErrorToast(error);
    },
  });

  const onSubmit = async (data: INewQuoteTypeReqSchema) =>
    updateNewQuoteType({
      quoteId,
      payload: {
        ...data,
        previousArr: Number(data.previousArr),
        type: NewQuoteTypeEnum.MANUAL_RENEWAL,
      },
    });

  return (
    <MCenterModal
      size="md"
      isOpen={isOpen}
      onClose={onClose}
      modalHeaderProps={{ mr: '12' }}
      modalTitle={`Set as Renewal`}
      modalBodyProps={{ pt: 0 }}
      modalContentProps={{ 'data-testid': 'manual-renewal-modal' } as any}
      renderFooter={() => (
        <MStack
          spacing={4}
          direction="row"
          align="center"
          justify="end"
          flex={1}
        >
          <MButton onClick={onClose} variant="cancel" minW="auto">
            Cancel
          </MButton>
          <MButton
            isLoading={isLoading}
            variant="primary"
            onClick={handleSubmit(onSubmit)}
            isDisabled={!isValid || !isDirty || isLoading}
            type="submit"
            minW="auto"
          >
            Save
          </MButton>
        </MStack>
      )}
    >
      <MText mb={4}>
        This is a way to create a Renewal Quote without having the original
        Quote in MonetizeNow.
      </MText>
      <MText mb={4}>
        Provide the ARR of the previous quote so that the Incremental ARR can be
        calculated for reporting.
      </MText>

      <ManualRenewalForm
        onSubmit={onSubmit}
        manualRenewalForm={manualRenewalForm}
      />
    </MCenterModal>
  );
};

export default ManualRenewalModal;
