import { usePrintInvoiceToHtml } from '~app/api/accountsService';
import { HtmlIFrameContentRenderer } from '~app/components/Monetize/HtmlIFrameContentRenderer';
import { IInvoiceRespSchema } from '~app/types';

interface InvoiceHtmlProps {
  invoice: IInvoiceRespSchema;
}

export const InvoiceHtml = ({ invoice }: InvoiceHtmlProps) => {
  const { data, isLoading, isRefetching, error, isInitialLoading } =
    usePrintInvoiceToHtml({
      invoiceId: invoice.id,
      lastModifiedTimestamp: invoice.modifyDate,
    });

  if (error) {
    // TODO: add error state
    return null;
  }

  return (
    <HtmlIFrameContentRenderer
      id="invoice-content"
      html={data}
      isInitialLoading={isInitialLoading}
      isReFetching={isLoading || isRefetching}
    ></HtmlIFrameContentRenderer>
  );
};
