import { FC, useState } from 'react';
import { SettingCardEdit } from '~app/components/Settings/SettingCardEdit';
import { SettingCardView } from '~app/components/Settings/SettingCardView';
import { useFlags } from '~app/services/launchDarkly';
import { HubSpotConnection, SettingCardViewAction } from '~app/types';
import { HubSpotFieldMapping } from './HubSpotFieldMapping';

interface HubspotCrmConnectionProps {
  connection: HubSpotConnection;
  reAuthorize: () => void;
  verifyConnection: () => Promise<void>;
}

export const HubspotCrmConnection: FC<HubspotCrmConnectionProps> = ({
  connection,
  reAuthorize,
  verifyConnection,
}) => {
  const { crmFieldMapping } = useFlags();
  const [mode, setMode] = useState<'VIEW' | 'EDIT'>('VIEW');

  const handleAction = async (action: SettingCardViewAction) => {
    switch (action) {
      case 'RE_AUTHENTICATE':
        reAuthorize();
        break;
      case 'VERIFY':
        await verifyConnection();
        break;
      case 'DELETE':
        break; // TODO: BE not implemented

      default:
        break;
    }
  };

  return (
    <>
      {mode === 'VIEW' && (
        <SettingCardView
          omitEditButton
          status={'ACTIVE'} // Once BE has error state, we will implement here
          actions={[
            { action: 'RE_AUTHENTICATE', label: 'Re-Authenticate' },
            { action: 'VERIFY', label: 'Verify Connection' },
            // { // TODO: not yet implemented on BE
            //   action: 'DELETE',
            //   label: 'Delete',
            //   isDanger: true,
            //   confirmProps: {
            //     title: `Are you sure you want to delete this connection?`,
            //     description:
            //       'Once removed, this configuration will not longer be available.',
            //   },
            // },
          ]}
          type="HubSpot"
          tags={[
            {
              label: 'Hub ID',
              value: connection.hubId,
            },
            {
              label: 'Username',
              value: connection.userEmail,
            },
            {
              label: 'Hub Domain',
              value: connection.hubDomain,
            },
          ]}
          onEdit={() => setMode('EDIT')}
          onAction={handleAction}
        />
      )}
      {mode === 'EDIT' && (
        <SettingCardEdit
          type="HubSpot"
          showCloseButton
          handleCancel={() => setMode('VIEW')}
        >
          TODO
        </SettingCardEdit>
      )}
      {crmFieldMapping && <HubSpotFieldMapping />}
    </>
  );
};
