import { forwardRef } from 'react';
import { DataTableFilterRef, FilterProps } from '../DataTableTypes';
import { DataTableHeaderBooleanFilter } from './DataTableHeaderBooleanFilter';
import { DataTableHeaderDateFilter } from './DataTableHeaderDateFilter';
import { DataTableHeaderSelectionFilter } from './DataTableHeaderSelectionFilter';
import { DataTableHeaderSetFilter } from './DataTableHeaderSetFilter';

/**
 * Filter component that is displayed in the header of the table
 * and renders proper filter component based on the column meta
 *
 * Values are configured here
 * {@link frontend/src/global.d.ts}
 */
export const DateTableHeaderFilter = forwardRef<
  DataTableFilterRef,
  FilterProps<any>
>((props, ref) => {
  const { column } = props;
  const { filterVariant } = column.columnDef.meta ?? {};

  switch (filterVariant) {
    case 'BOOLEAN':
      return <DataTableHeaderBooleanFilter ref={ref} {...props} />;
    case 'DATE':
      return <DataTableHeaderDateFilter ref={ref} {...props} />;
    case 'SET':
      return <DataTableHeaderSetFilter ref={ref} {...props} />;
    case 'SELECTION':
      return <DataTableHeaderSelectionFilter ref={ref} {...props} />;
    default:
      return null;
  }
});
