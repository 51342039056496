import { BoxProps } from '@chakra-ui/react';
import { ApexOptions } from 'apexcharts';
import { format as formatDate } from 'date-fns/format';
import { parse as parseDate } from 'date-fns/parse';
import { useEffect, useState } from 'react';
import { MBox, MChart } from '~app/components/Monetize';
import { logger } from '~app/services/logger';
import { colors } from '~app/styles/theme';
import { IRevenueRecognitionResponse } from '~app/types/revenueTypes';
import { BASE_BAR_CHART_OPTIONS } from '~app/utils/charts';
import { groupBy } from '~app/utils/misc';
import { getRevRecPeriods } from './rev-rec.utils';

type ChartData = { name: string; data: number[] }[];

interface RevRecChartProps extends BoxProps {
  revRecData: IRevenueRecognitionResponse;
}

export const RevRecChart = ({ revRecData, ...rest }: RevRecChartProps) => {
  const [series, setSeries] = useState<ChartData>([]);
  const [options, setOptions] = useState<ApexOptions>({});

  useEffect(() => {
    const { chartData, months } = getChartData(revRecData);
    const chartOptions = getChartOptions(months);
    logger.log({ chartData, chartOptions });
    setSeries(chartData);
    setOptions(chartOptions);
  }, [revRecData]);

  return (
    <MBox {...rest}>
      <MChart
        pl={2}
        pr={2}
        type="bar"
        options={options}
        series={series}
        height={500}
      />
    </MBox>
  );
};

const getChartData = ({
  startPeriod,
  recognizedAsOfPeriod,
  totals,
}: IRevenueRecognitionResponse): { months: string[]; chartData: ChartData } => {
  const totalsPeriodsByMonth = groupBy(totals.periods, 'period');
  const periods = getRevRecPeriods(startPeriod, recognizedAsOfPeriod);
  const { months, deferred, recognized } = periods.reduce(
    (
      acc: { months: string[]; deferred: number[]; recognized: number[] },
      period,
    ) => {
      const totalPeriod = totalsPeriodsByMonth[period];
      acc.months.push(period);
      acc.deferred.push(totalPeriod ? totalPeriod.deferredAmount : 0);
      acc.recognized.push(totalPeriod ? totalPeriod.recognizedAmount : 0);
      return acc;
    },
    {
      months: [],
      deferred: [],
      recognized: [],
    },
  );
  return {
    months,
    chartData: [
      { name: 'Deferred', data: deferred },
      { name: 'Recognized', data: recognized },
    ],
  };
};

const getChartOptions = (months: string[]): ApexOptions => {
  const options: ApexOptions = {
    ...BASE_BAR_CHART_OPTIONS,
    plotOptions: {
      bar: {
        columnWidth: '75%',
      },
    },
    fill: {
      colors: [colors.tIndigo.base, colors.tBlue.lightShade],
    },
    xaxis: {
      ...BASE_BAR_CHART_OPTIONS.xaxis,
      categories: months.map((month) =>
        formatDate(parseDate(month, 'yyyy-MM', new Date()), 'MMM yyyy'),
      ),
    },
    legend: {
      show: true,
      position: 'top',
      horizontalAlign: 'right',
      markers: {
        fillColors: [colors.tIndigo.base, colors.tBlue.lightShade],
      },
    },
  };

  return options;
};
