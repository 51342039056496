import { ControllerRenderProps } from 'react-hook-form';
import { MCustomSelect } from '~app/components/Monetize';
import { QuestionCompareFromTypes } from '~app/constants/guidedQuoting';
import {
  GuidedQuotingReq,
  QuestionCompareFromEnum,
  QuestionReq,
  QuestionTypesEnum,
} from '~app/types';
import { objectToSelectOptions } from '~app/utils/misc';

interface GuidedQuotingCompareToQuestionProps {
  responseType: QuestionTypesEnum;
  compareFrom: QuestionCompareFromEnum | null | undefined;
  field: ControllerRenderProps<GuidedQuotingReq, any>;
  questions: QuestionReq[];
  index: number;
  firstValue: any;
}

export const GuidedQuotingCompareToQuestion = ({
  responseType,
  compareFrom,
  field,
  questions,
  index,
  firstValue,
}: GuidedQuotingCompareToQuestionProps) => {
  const otherQuestions = questions.filter(
    (question) => question.questionNumber < index + 1,
  );
  const questionOptions: Record<string, string> = Object.assign(
    {},
    ...otherQuestions
      .filter((question) => {
        // Ensure dropdowns are treated as text
        const { type, customFieldEntity } = question;
        // Special handling for dropdowns to ensure that the entity is the same
        if (type === QuestionTypesEnum.CUSTOM_FIELD) {
          if (
            customFieldEntity === 'OFFERING' &&
            compareFrom ===
              QuestionCompareFromEnum.OFFERING_CUSTOM_FIELD_DROPDOWN
          ) {
            return true;
          }
          if (
            customFieldEntity === 'PRODUCT' &&
            compareFrom ===
              QuestionCompareFromEnum.PRODUCT_CUSTOM_FIELD_DROPDOWN
          ) {
            return true;
          }
          return false;
        }
        return type === QuestionCompareFromTypes[compareFrom ?? responseType];
      })
      .map((question) => ({
        [question.id]: `${question.questionNumber} - ${question.questionText}`,
      })),
  );
  return (
    <MCustomSelect
      items={objectToSelectOptions(questionOptions)}
      {...field}
      value={firstValue}
    />
  );
};
