import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const baseStyle = defineStyle({
  fontSize: 'sm',
  marginEnd: 3,
  mb: 2,
  fontWeight: '600',
  color: 'tPurple.dark',
});

export const formLabelTheme = defineStyleConfig({
  baseStyle,
});

export default formLabelTheme;
