import React, { FunctionComponent as FC } from 'react';
import { MdClose } from 'react-icons/md';
import MCustomIconButton, { MCustomIconButtonProps } from './MCustomIconButton';

export interface MCloseButtonProps
  extends Omit<MCustomIconButtonProps, 'icon'> {
  variant?: 'bare' | 'backgroundHover';
  size?: 'sm' | 'md' | 'lg';
  onClick: () => void;
}

const MCloseButton: FC<MCloseButtonProps> = React.forwardRef<
  any,
  MCloseButtonProps
>((props: MCloseButtonProps, ref) => {
  // eslint-disable-next-line react/prop-types
  const { variant = 'backgroundHover', size = 'md', onClick, ...rest } = props;

  let btnSize = 4;
  let containerSize = 4;

  switch (size) {
    case 'sm':
      btnSize = 3;
      containerSize = 4;
      break;
    case 'lg':
      btnSize = 5;
      containerSize = 5;
      break;
    default:
      break;
  }

  if (variant === 'bare') {
    return (
      <MCustomIconButton
        ref={ref}
        aria-label="Close"
        icon={MdClose}
        bg="none"
        color="tPurple.dark"
        iconColorHover="tPurple.base"
        _hover={{
          bg: 'none',
        }}
        ml="2"
        btnSize={btnSize}
        padding={0}
        containerSize={containerSize}
        onClick={onClick}
        {...rest}
      />
    );
  }

  return (
    <MCustomIconButton
      ref={ref}
      variant="icon"
      btnSize={btnSize}
      aria-label="Close"
      icon={MdClose}
      iconColorHover="tIndigo.base"
      onClick={onClick}
      {...rest}
    />
  );
});

MCloseButton.defaultProps = {
  variant: 'backgroundHover',
  size: 'md',
};

export default MCloseButton;
