import * as z from 'zod';

export enum BillPricingProrationModelEnum {
  MONTH_BASED = 'MONTH_BASED',
  CALENDAR_MONTH_BASED = 'CALENDAR_MONTH_BASED',
  DAY_BASED = 'DAY_BASED',
}
export const BillPricingProrationModelEnumZ = z.nativeEnum(
  BillPricingProrationModelEnum,
);

export const BillPricingSchema = z.object({
  prorationModel: BillPricingProrationModelEnumZ,
});

export type IBillPricingSchema = z.infer<typeof BillPricingSchema>;

export interface IBillPricing {
  prorationModel: BillPricingProrationModelEnum;
}
