import {
  IQuoteOfferingRespSchema,
  IQuoteRespSchema,
  IOfferingRes,
  PriceDisplayEnum,
  IRateResSchema,
} from '~app/types';
import { PriceDisplaySettingsTable } from './PriceDisplaySettingsTable';
import { convertRateResToReq } from '~app/utils/rates';

type PriceDisplaySettingsConfigurationProps = {
  isReadOnly?: boolean;
  quoteOffering?: IQuoteOfferingRespSchema | null;
  handleQuoteOfferingUpdate: ({
    priceDisplay,
  }: {
    priceDisplay?: PriceDisplayEnum;
  }) => void;
  offering: IOfferingRes | null;
  offeringRate: IRateResSchema | null;
  quote?: IQuoteRespSchema | null;
  isOfferingLoading: boolean;
};

export const PriceDisplaySettingsConfiguration = ({
  isReadOnly,
  quoteOffering,
  handleQuoteOfferingUpdate,
  offering,
  offeringRate,
  quote,
  isOfferingLoading,
}: PriceDisplaySettingsConfigurationProps) => {
  if (!offering || !offeringRate) {
    return null;
  }

  return (
    <PriceDisplaySettingsTable
      isReadOnly={isReadOnly}
      value={quoteOffering?.options?.priceDisplay}
      onChange={(value) => {
        handleQuoteOfferingUpdate({ priceDisplay: value });
      }}
      rate={convertRateResToReq(
        offeringRate,
        offering.products,
        quote?.currency!,
      )}
      quoteOfferingName={quoteOffering?.offeringName || ''}
      products={offering.products}
      isLoading={isOfferingLoading}
    />
  );
};
