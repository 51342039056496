import { z } from 'zod';

/**
 * This file avoids circular dependencies between opportunityTypes and quoteTypes
 */

export enum OpportunityStatusEnum {
  WON = 'WON',
  CANCELED = 'CANCELED',
  ACTIVE = 'ACTIVE',
  LOST = 'LOST',
}

export const OpportunityStatusEnumZ = z.nativeEnum(OpportunityStatusEnum);
