/**
 * Round a number to a specific number of digits
 *
 * @link {https://stackoverflow.com/questions/15762768/javascript-math-round-to-two-decimal-places}
 *
 * @param value
 * @param digits
 * @returns
 */
export const roundNumberToDecimal = (value: number, digits = 2) => {
  let negative = false;
  if (digits === undefined) {
    digits = 0;
  }
  if (value < 0) {
    negative = true;
    value = value * -1;
  }
  const multiplicator = Math.pow(10, digits);
  value = parseFloat((value * multiplicator).toFixed(11));
  value = +(Math.round(value) / multiplicator).toFixed(digits);
  if (negative) {
    value = +(value * -1).toFixed(digits);
  }
  return value;
};
