import { Container, ContainerProps } from '@chakra-ui/react';
import { ReactNode, forwardRef, useState } from 'react';
import { useFlags } from '../../services/launchDarkly';
import { AccountGlobalSearch } from '../Account/GlobalAccountSearch';
import { MFlex } from './chakra';

interface MPageContainerProps extends ContainerProps {
  enableAccountSearch?: boolean;
  children?: ReactNode;
}

export const MPageContainer = forwardRef<any, MPageContainerProps>(
  (
    { enableAccountSearch = false, children, ...rest }: MPageContainerProps,
    ref,
  ) => {
    const { globalAccountSearch } = useFlags();
    const [containerClicked, setContainerClicked] = useState(false);

    /** Honor feature flag in addition to passed in setting */
    enableAccountSearch = globalAccountSearch && enableAccountSearch;
    const height = enableAccountSearch ? 'calc(100vh - 125px)' : '100vh';
    const defaultProps: ContainerProps = {
      minHeight: height,
      centerContent: true,
      width: 'auto',
      px: ['4px', '4px', '32px'],
      pt: enableAccountSearch ? '6px' : ['24px', '24px', '32px'],
      maxWidth: '1440px',
    };

    return (
      <>
        {enableAccountSearch && (
          <MFlex
            style={{ cursor: 'text' }}
            onBlur={() => setContainerClicked(false)}
            onClick={() => setContainerClicked(true)}
            top={0}
            position="sticky"
            width="100%"
            height="100%"
            zIndex={5}
            h={16}
            pl={4}
            mb={3}
            backgroundColor="tGray.sidebarDark"
          >
            <AccountGlobalSearch containerClicked={containerClicked} />
          </MFlex>
        )}
        <Container ref={ref} {...defaultProps} {...rest}>
          {children}
        </Container>
      </>
    );
  },
);

export default MPageContainer;
