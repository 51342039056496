import { Divider, GridProps } from '@chakra-ui/layout';
import { FunctionComponent, ReactNode } from 'react';
import { MdArrowDropDown, MdArrowDropUp, MdClear } from 'react-icons/md';
import { MCustomIconButton, MText } from '..';
import { MCircle, MFlex, MGrid, MGridItem, MIcon, MTooltip } from '../chakra';

interface StepProps {
  stepNumber: number;
  stepIndex?: number;
  isLastStep?: boolean;
  stepData?: any;
  totalSteps?: number;
  removable?: boolean;
  isOrderable?: boolean;
  containerProps?: Omit<GridProps, 'templateColumns'>;
  removeStep?: (id: string | number) => void;
  reOrderSteps?: (stepData: any, reOrderType: string) => void;
  children: ReactNode;
}

export const CustomSteps: FunctionComponent<StepProps> = ({
  stepNumber,
  stepData,
  stepIndex = 0,
  isLastStep = false,
  totalSteps = 0,
  removable = true,
  isOrderable = false,
  containerProps,
  removeStep,
  reOrderSteps,
  children,
}: StepProps) => {
  const hoveProperties = {
    bg: 'tRed.paleRed',
    borderColor: 'tRed.base',
    cursor: 'pointer',
  };
  const displayStepNum = { display: 'block' };
  const hideStepNum = { display: 'none' };

  const handleRemoveStep = () => {
    if (removable && removeStep) {
      return removeStep(stepData?.id);
    }
    return null;
  };

  const handleReorderSteps = (reOrderType: string) => {
    if (reOrderSteps) {
      return reOrderSteps(stepData, reOrderType);
    }
    return null;
  };

  return (
    <MGrid
      templateColumns="repeat(1, .05fr) repeat(1, 1fr) "
      gap={4}
      {...containerProps}
    >
      <MGridItem>
        <MFlex h="full" justify="center">
          {isOrderable && (
            <MFlex
              h="32px"
              p="0"
              m="0"
              flexDirection="column"
              justifyContent="center"
              alignItems="flex-end"
            >
              <MTooltip label="Reorder Step" placement="bottom-end">
                <MCustomIconButton
                  position="relative"
                  icon={MdArrowDropUp}
                  color="tPurple.dark"
                  padding={0}
                  isDisabled={stepIndex === 0}
                  onClick={() => handleReorderSteps('up')}
                />
              </MTooltip>
              <MTooltip label="Reorder Step" placement="bottom-end">
                <MCustomIconButton
                  position="relative"
                  icon={MdArrowDropDown}
                  color="tPurple.dark"
                  padding={0}
                  isDisabled={stepNumber === totalSteps}
                  onClick={() => handleReorderSteps('down')}
                />
              </MTooltip>
            </MFlex>
          )}
          <MFlex flexDir="column" alignItems="center" justify="center">
            <MTooltip
              label="Delete Step"
              placement="bottom-end"
              isDisabled={!removable}
            >
              <MCircle
                size={8}
                bg="tWhite.base"
                color="tPurple.dark"
                border="1px solid"
                borderColor="tGray.light"
                role="group"
                _hover={removable ? hoveProperties : {}}
                onClick={handleRemoveStep}
              >
                <MText _groupHover={removable ? hideStepNum : displayStepNum}>
                  {stepNumber}
                </MText>
                {removable && (
                  <MIcon
                    as={MdClear}
                    color="tRed.base"
                    display="none"
                    _groupHover={{ display: 'block' }}
                  />
                )}
              </MCircle>
            </MTooltip>

            <Divider orientation="vertical" borderColor="tGray.light" />
          </MFlex>
        </MFlex>
      </MGridItem>
      <MGridItem mb={!isLastStep ? 5 : 0}>{children}</MGridItem>
    </MGrid>
  );
};
