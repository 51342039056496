import { FC } from 'react';
import { MButton, MStack } from '~app/components/Monetize';

interface QuoteDocumentsFooterProps {
  saving: boolean;
  handleClose: () => void;
}
export const QuoteDocumentsFooter: FC<QuoteDocumentsFooterProps> = ({
  saving,
  handleClose,
}: QuoteDocumentsFooterProps) => {
  return (
    <MStack spacing={4} direction="row" align="center" justify="right" flex={1}>
      <MButton
        minW="auto"
        variant="cancel"
        isDisabled={saving}
        onClick={handleClose}
      >
        Cancel
      </MButton>
      <MButton
        form="quote-documents-form"
        minW="auto"
        variant="primary"
        isDisabled={saving}
        isLoading={saving}
        type="submit"
      >
        Save
      </MButton>
    </MStack>
  );
};
