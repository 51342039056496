import { EsignAccountBaseUrlEnum } from '~app/types';

export const DOCUSIGN_ACCOUNT_URL_TO_OAUTH_URL_MAPPING = {
  [EsignAccountBaseUrlEnum.DEMO]: 'https://account-d.docusign.com/oauth/auth',
  [EsignAccountBaseUrlEnum.PRODUCTION]:
    'https://account.docusign.com/oauth/auth',
};

export const DocusignAccountUrlDisplay = [
  { title: 'Demo', value: EsignAccountBaseUrlEnum.DEMO },
  { title: 'Production', value: EsignAccountBaseUrlEnum.PRODUCTION },
];

export const DocusignAccountUrlDisplayForProduction = [
  { title: 'Production', value: EsignAccountBaseUrlEnum.PRODUCTION },
];

export const DOCUSIGN_ACCOUNT_BASE_URL_TO_ENVIRONMENT_MAPPING = {
  [EsignAccountBaseUrlEnum.DEMO]: 'Demo',
  [EsignAccountBaseUrlEnum.PRODUCTION]: 'Production',
};