import { FC } from 'react';
import {
  MBox,
  MButton,
  MCard,
  MFlex,
  MGrid,
  MIcon,
  MLink,
  MPageLoader,
  MStatusIDBox,
  MText,
} from '~app/components/Monetize';
import { QuoteStatusEnum, QuoteTypeEnum } from '~app/types';
import { useQuoteContext } from '../../Quote/quoteContext';
import { MdSettings } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { MParentLink } from '~app/components/Monetize/MParentLink';
import { AddPoNumberSectionV2 } from '~app/components/Quotes/AddPoNumberSectionV2';
import { ChangeOwnerV2 } from '~app/components/Quotes/ChangeOwnerV2';
import { QuoteBasicInfoLine } from '~app/components/Quotes/QuoteBasicInfoLine';
import QuoteBasicOppotunity from '~app/components/Quotes/QuoteBasicOppotunity';
import { QuoteReviewCustomRates } from '~app/components/Quotes/QuoteReviewCustomRates';
import { QuoteReviewInfoEsign } from '~app/components/Quotes/QuoteReviewEsign';
import { ROUTES } from '~app/constants';
import { getAccountQuotesRoute } from '~app/constants/routes';
import { useQuoteSettings } from '~app/hooks';
import { QuoteDocumentsV2 } from '../../Quote/components/quoteDocuments/QuoteDocumentsV2';
import { QuoteTypeTag } from '../../Quote/components/QuoteTypeTag';

interface QuoteReviewInfoProps {
  onOpenContactDrawer: () => void;
}
export const QuoteReviewInfo: FC<QuoteReviewInfoProps> = ({
  onOpenContactDrawer,
}: QuoteReviewInfoProps) => {
  const {
    isReadOnly,
    quoteData: { quote, setQuote },
    opportunityLinkData,
    handleChangeOwner,
    setIsQuoteSaving,
    handleUpdateQuoteWithFormReset,
  } = useQuoteContext();
  const { quoteSettings } = useQuoteSettings();

  if (!quote) {
    return <MPageLoader />;
  }
  const lineProps = {
    orientation: 'vertical' as const,
  };

  return (
    <MCard width={'100%'} p={4} borderColor="tGray.lightPurple" mb="8">
      <MGrid gridTemplateColumns={['repeat(5, 1fr)']} gap="4">
        <QuoteBasicInfoLine title="Quote ID" {...lineProps}>
          <MBox>
            <MStatusIDBox id={quote?.id!} copyUrl displayIcon={false} />
          </MBox>
        </QuoteBasicInfoLine>
        <QuoteBasicInfoLine
          title="Owner"
          inputId="quote-header-owner"
          {...lineProps}
        >
          <ChangeOwnerV2
            inputId="quote-header-owner"
            isReadOnly={isReadOnly}
            value={quote?.owner}
            ownerName={quote?.ownerName}
            onChange={(e) => handleChangeOwner(String(e))}
          />
        </QuoteBasicInfoLine>
        <QuoteBasicInfoLine title="Opportunity" {...lineProps}>
          {opportunityLinkData && (
            <QuoteBasicOppotunity {...opportunityLinkData} />
          )}
        </QuoteBasicInfoLine>

        <QuoteBasicInfoLine title="Contract ID" {...lineProps}>
          {!quote.contractId ? (
            <MText fontStyle="italic">Defined after quote processing</MText>
          ) : (
            <MLink
              fontSize="sm"
              isTruncated
              maxWidth="200px"
              to={ROUTES.getQuoteContractEditRoute(quote.id, quote.contractId!)}
              as={Link}
              replace
            >
              {quote.contractId}
            </MLink>
          )}
        </QuoteBasicInfoLine>

        {quoteSettings?.allowPONumberOnQuotes && (
          <QuoteBasicInfoLine title="PO Number" {...lineProps}>
            <MBox>
              <AddPoNumberSectionV2
                quote={quote}
                setQuote={setQuote}
                setIsQuoteSaving={setIsQuoteSaving}
                buttonProps={{ ml: -3 }}
                previewPropsNonEdit={{
                  justifyContent: 'flex-start',
                }}
                inputProps={{
                  maxW: '100px',
                }}
              />
            </MBox>
          </QuoteBasicInfoLine>
        )}

        <QuoteBasicInfoLine title="Contacts" {...lineProps}>
          <MButton
            variant="tertiary"
            onClick={() => {
              onOpenContactDrawer();
            }}
            minW="auto"
            size="sm"
            ml="-3"
          >
            <MIcon as={MdSettings} mr="1" />
            Manage
          </MButton>
        </QuoteBasicInfoLine>
        <QuoteBasicInfoLine title="Documents" {...lineProps}>
          <QuoteDocumentsV2
            isReadOnly={isReadOnly || quote?.status !== QuoteStatusEnum.DRAFT}
            quote={quote}
            onUpdate={handleUpdateQuoteWithFormReset}
          />
        </QuoteBasicInfoLine>
        {quote.requiresEsign && (
          <QuoteBasicInfoLine title="Will be sent through eSign" {...lineProps}>
            <QuoteReviewInfoEsign quote={quote} />
          </QuoteBasicInfoLine>
        )}
        <QuoteBasicInfoLine title="Uses Custom Rates" {...lineProps}>
          <QuoteReviewCustomRates quote={quote} />
        </QuoteBasicInfoLine>
      </MGrid>
    </MCard>
  );
};
