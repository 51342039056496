import { Tbody, Thead, Tr, useDisclosure } from '@chakra-ui/react';
import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import {
  Control,
  Controller,
  FieldErrors,
  UseFormGetValues,
  UseFormSetValue,
  useFieldArray,
  useWatch,
} from 'react-hook-form';
import { handleApiErrorToast } from '~app/api/axios';
import { doGetSubscriptionPrices } from '~app/api/cpqService';
import { DatePicker } from '~app/components/Monetize/DatePicker/DatePicker';
import { useProductOfferings } from '~app/hooks';
import { useFlags } from '~app/services/launchDarkly';
import Sentry from '~app/services/sentry';
import { CustomSelectItem } from '~app/types/mCustomSelectTypes';
import { sortByProductObjType } from '~app/utils';
import { toDateOnly } from '~app/utils/dates';
import { groupBy } from '~app/utils/misc';
import { convertSubscriptionsFormToAPISchema } from '~app/utils/subscriptions';
import {
  MBox,
  MCheckbox,
  MCustomSelect,
  MFormField,
  MHStack,
  MTable,
} from '~components/Monetize';
import { SUBSCRIPTIONS } from '~constants';
import {
  SUBSCRIPTION_TABLE_HEADER,
  getBlankSubscriptionOffering,
} from '~constants/subscriptions';
import {
  IBillGroupResp,
  ISubscriptionListPricingResponseSchema,
  ISubscriptionOfferingReqUI,
  ISubscriptionProductReqUI,
  ISubscriptionsFormReq,
  ISubscriptionsFormReqUI,
  OfferingResWithRateRecords,
  StrKeyObj,
  SubscriptionBillingStatusEnum,
  SubscriptionsFormReqSchema,
} from '~types';
import { BillGroupFormModal } from '../../BillGroups/BillGroupFormModal';
import { SubscriptionFormFooter } from './SubscriptionFormFooter';
import { SubscriptionFormHeaderCell } from './SubscriptionFormHeaderCell';
import { SubscriptionFormLoading } from './SubscriptionFormLoading';
import { SubscriptionOffering } from './SubscriptionOffering';

interface SubscriptionsFormProps {
  errors: FieldErrors<any>;
  control: Control<ISubscriptionsFormReqUI>;
  accountId: string;
  subscriptionId: string;
  billGroups: IBillGroupResp[];
  disabledBillGroupIds: string[];
  watchBillGroup: string;
  setValue: UseFormSetValue<ISubscriptionsFormReqUI>;
  getValues: UseFormGetValues<ISubscriptionsFormReqUI>;
  isLocked: boolean;
  billGroupsLoading: boolean;
  setFormIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  savedSubscriptionsForm: ISubscriptionsFormReqUI | null;
  onNewBillGroup?: (id?: string) => void;
  orderablesObj: ReturnType<typeof useProductOfferings>;
  hide: boolean;
  currency: string;
}

export const SubscriptionsForm: FC<SubscriptionsFormProps> = ({
  control,
  errors,
  accountId,
  subscriptionId,
  billGroups,
  disabledBillGroupIds,
  watchBillGroup,
  setValue,
  getValues,
  isLocked,
  billGroupsLoading,
  setFormIsLoading,
  savedSubscriptionsForm,
  onNewBillGroup,
  orderablesObj,
  hide,
  currency,
}: PropsWithChildren<SubscriptionsFormProps>) => {
  const abortController = new AbortController();
  const [loadingForm, setLoadingForm] = useState<boolean>(true);
  const [loadingPrices, setLoadingPrices] = useState<boolean>(false);
  const { fields, append } = useFieldArray({
    control,
    name: 'subscriptions',
  });
  const watchFieldArray = useWatch({ control, name: 'subscriptions' });
  const watchProrate = useWatch({ control, name: 'prorate' });
  const watchCollect = useWatch({ control, name: 'collectNow' });
  const controlledFields = fields.map((field, index) => ({
    ...field,
    ...watchFieldArray[index],
  }));
  const showTableFooter = subscriptionId || watchFieldArray.length > 1;
  const { allowBillGroupCreation } = useFlags();

  const [pricingData, setPricingData] =
    useState<ISubscriptionListPricingResponseSchema | null>(null);
  const resetPricingData = () => setPricingData(null);

  useEffect(() => {
    return () => {
      abortController.abort();
    };
  }, []);

  useEffect(() => {
    // inform parent component (SubscriptionsPage) that form is loading
    setFormIsLoading(loadingForm);
  }, [loadingForm, setFormIsLoading]);

  useEffect(() => {
    // when prorate is unselected, unselect collect as well
    if (!watchProrate && watchCollect) {
      setValue('collectNow', false);
    }
  }, [watchProrate, watchCollect, setValue]);

  const {
    isOpen: isBillGroupModalOpen,
    onOpen: onOpenBillGroupModal,
    onClose: onCloseBillGroupModal,
  } = useDisclosure();

  const setFormState = async (data: ISubscriptionsFormReqUI) => {
    setValue(`billGroupId`, data.billGroupId);
    setValue(`subscriptions.0`, data.subscriptions[0]);
    await getPrices();
    setLoadingForm(false);
  };

  useEffect(() => {
    if (savedSubscriptionsForm) {
      setFormState(savedSubscriptionsForm);
    } else {
      setLoadingForm(false);
    }
  }, [savedSubscriptionsForm]);

  const appendBlankRow = () => {
    const emptyRows = getValues('subscriptions').filter(
      ({ offeringId }) => !offeringId,
    );
    // only allow appending a blank row if making a new suscription and there are no existing blank rows.
    if (emptyRows.length < 1) {
      append(getBlankSubscriptionOffering());
    }
  };

  const getOrderable = (
    data: ReturnType<typeof useProductOfferings>,
    offeringId: string,
  ): OfferingResWithRateRecords => {
    return data.productOfferingsObj.byId[offeringId];
  };

  const handleOfferingChange = async (
    offeringRow: ISubscriptionOfferingReqUI,
    subscriptionOfferingIdx: number,
    offeringId: string,
  ) => {
    if (!offeringId) {
      return;
    }
    const { products } = getOrderable(orderablesObj, offeringId);
    const offeringProducts: ISubscriptionProductReqUI[] = (products || []).map(
      (product) => {
        const units = product.productType === 'USAGE' ? '0' : '1'; // default for newly added products
        return {
          productId: product.id,
          product,
          units,
        };
      },
    );
    setValue(
      `subscriptions.${subscriptionOfferingIdx}.subscriptionItems`,
      sortByProductObjType(offeringProducts) as ISubscriptionProductReqUI[],
    );
  };

  const handleRateChange = async (
    offeringRow: ISubscriptionOfferingReqUI,
    subscriptionOfferingIdx: number,
    rateId: string,
  ) => {
    try {
      if (!offeringRow.offeringId) {
        return;
      }
      const { rates } = getOrderable(orderablesObj, offeringRow.offeringId);
      const selectedRateObj = (rates || []).find(({ id }) => id === rateId);
      const offeringRateDescription = selectedRateObj?.description || '';
      setValue(
        `subscriptions.${subscriptionOfferingIdx}.description`,
        offeringRateDescription,
      );
      if (!subscriptionId) {
        appendBlankRow();
      }
      getPrices();
    } catch (error: any) {
      handleApiErrorToast(error, {
        summary: 'Error while fetching rates',
      });
    }
  };

  const removeUnsavedSubscription = (subscriptionOfferingIdx: number) => {
    // subscription was never saved to backend, we will remove it completely
    setTimeout(() => {
      let subscriptions = getValues('subscriptions');
      subscriptions.splice(subscriptionOfferingIdx, 1);
      if (!subscriptions.length) {
        subscriptions = [getBlankSubscriptionOffering()];
      }
      setValue('subscriptions', subscriptions);
      getPrices();
    }, 0);
  };

  const getPrices = async () => {
    setLoadingPrices(true);
    let formData: ISubscriptionsFormReq = {
      ...convertSubscriptionsFormToAPISchema(getValues()),
      preview: true,
    };

    if (!formData?.subscriptions?.length) {
      setLoadingPrices(false);
      resetPricingData();
      return;
    }
    const parsed = SubscriptionsFormReqSchema.safeParse(formData);
    if (parsed.success) {
      formData = parsed.data;
    } else {
      Sentry.captureException(parsed.error.issues, {
        tags: {
          type: 'SUBSCRIPTION_FORM_PRICING',
        },
      });
    }
    try {
      const data = await doGetSubscriptionPrices(
        abortController.signal,
        formData,
      );
      setPricingData(data);
    } catch (error: any) {
      handleApiErrorToast(error);
    } finally {
      setLoadingPrices(false);
    }
  };

  if (loadingForm) {
    return <SubscriptionFormLoading />;
  }

  // TODO: only do this when billGroups change
  const billGroupOptions: (IBillGroupResp | StrKeyObj)[] =
    Array.from(billGroups);

  const pricedOfferingRecords = pricingData
    ? groupBy(pricingData.subscriptions, 'referenceId')
    : {};

  const isCanceled =
    savedSubscriptionsForm?.subscriptions[0].billingStatus ===
    SubscriptionBillingStatusEnum.CANCELED;

  const getPrependItems = (): CustomSelectItem[] => {
    return allowBillGroupCreation
      ? [
          {
            item: SUBSCRIPTIONS.NEW_BILL_GROUP_OPTION,
            isAction: true,
            hasDivider: true,
            onClick: ({ onClose }) => {
              onClose && onClose();
              onOpenBillGroupModal();
            },
          },
        ]
      : [];
  };

  if (hide) {
    return null;
  }

  return (
    <MBox flex="1" px={8}>
      <MHStack w="full" spacing={6} align="flex-end">
        <MBox w={isLocked ? 'auto' : '352px'}>
          <MFormField error={errors?.billGroup} label="Bill Group" isRequired>
            <Controller
              name="billGroupId"
              control={control}
              render={({ field }) => (
                <MCustomSelect
                  {...field}
                  placeholder="Select Billing Group"
                  inputProps={{
                    _placeholder: { color: 'tPurple.dark' },
                  }}
                  loading={billGroupsLoading}
                  items={billGroupOptions}
                  itemTitle="name"
                  itemValue="id"
                  isReadOnly={!!subscriptionId}
                  isDisabled={!!subscriptionId}
                  disabledItems={disabledBillGroupIds}
                  prependItems={getPrependItems()}
                  data-testid="Select Billing Group"
                />
              )}
            />
          </MFormField>
        </MBox>
        {!subscriptionId && (
          <MBox w={subscriptionId ? '0px' : 'auto'}>
            <MFormField
              error={errors?.effectiveDate}
              label="Effective Date"
              isRequired
            >
              <Controller
                name="effectiveDate"
                control={control}
                defaultValue={toDateOnly(new Date(), 'userTimezone')}
                render={({ field }) => (
                  <DatePicker {...field} isDisabled={isLocked || isCanceled} />
                )}
              />
            </MFormField>
          </MBox>
        )}
        <MHStack spacing={3.5} h={8}>
          <MFormField error={errors.prorate}>
            <Controller
              name="prorate"
              control={control}
              render={({ field: { value, ...rest } }) => (
                <MCheckbox
                  isDisabled={isLocked || isCanceled}
                  isChecked={value as any}
                  {...rest}
                >
                  <MBox as="span">Prorate</MBox>
                </MCheckbox>
              )}
            />
          </MFormField>
          <MFormField error={errors.collect}>
            <Controller
              name="collectNow"
              control={control}
              render={({ field: { value, ...rest } }) => (
                <MCheckbox
                  isDisabled={isLocked || !getValues('prorate') || isCanceled}
                  isChecked={value as any}
                  {...rest}
                >
                  <MBox as="span">Collect</MBox>
                </MCheckbox>
              )}
            />
          </MFormField>
        </MHStack>
      </MHStack>

      {orderablesObj.productOfferingsObj && (
        <MTable mt={5} overflow="scroll">
          <Thead>
            <Tr>
              {SUBSCRIPTION_TABLE_HEADER.map((header: string) => (
                <SubscriptionFormHeaderCell
                  key={header}
                  value={header}
                  fontSize="md"
                >
                  {header}
                </SubscriptionFormHeaderCell>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {controlledFields.map(
              (
                offeringRow: ISubscriptionOfferingReqUI,
                subscriptionOfferingIdx: number,
              ) => (
                <SubscriptionOffering
                  key={offeringRow.referenceId}
                  accountId={accountId}
                  offeringRow={offeringRow}
                  subscriptionOfferingIdx={subscriptionOfferingIdx}
                  errors={errors}
                  control={control}
                  subscriptionId={subscriptionId}
                  pricedOffering={
                    pricedOfferingRecords[offeringRow.referenceId]
                  }
                  orderablesObj={orderablesObj}
                  isLocked={isLocked}
                  isCanceled={
                    offeringRow.billingStatus ===
                    SubscriptionBillingStatusEnum.CANCELED
                  }
                  savedSubscriptionOffering={
                    savedSubscriptionsForm?.subscriptions[0] || null
                  }
                  loadingForm={loadingForm}
                  loadingPrices={loadingPrices}
                  getValues={getValues}
                  setValue={setValue}
                  handleRateChange={handleRateChange}
                  handleOfferingChange={handleOfferingChange}
                  removeUnsavedSubscription={removeUnsavedSubscription}
                  getPrices={getPrices}
                  watchBillGroup={watchBillGroup}
                  currency={currency}
                />
              ),
            )}
          </Tbody>
          {showTableFooter && (
            <SubscriptionFormFooter
              pricingData={pricingData}
              currency={currency}
            />
          )}
        </MTable>
      )}
      {isBillGroupModalOpen && (
        <BillGroupFormModal
          isOpen={isBillGroupModalOpen}
          onClose={onCloseBillGroupModal}
          onNewBillGroup={onNewBillGroup}
          accountId={accountId}
        />
      )}
    </MBox>
  );
};
