import {
  Box,
  BoxProps,
  chakra,
  Icon,
  PlacementWithLogical,
  Popover,
  PopoverContent,
  PopoverContentProps,
  PopoverProps,
  PopoverTrigger,
} from '@chakra-ui/react';
import React, { FC, forwardRef } from 'react';
import { MTooltip } from './chakra';

interface MDropdownActionsProps {
  children: any;
  renderTrigger: (isOpen?: boolean, onClose?: () => void) => React.ReactElement;
  popOverContentProps?: PopoverContentProps;
  enabled?: boolean;
  boxProps?: BoxProps & { 'data-testid': string };
  placement?: PlacementWithLogical;
  popoverProps?: PopoverProps;
}

const MDropdownActions: FC<MDropdownActionsProps> = ({
  renderTrigger,
  children,
  popOverContentProps,
  enabled = false,
  boxProps,
  popoverProps,
  ...rest
}: MDropdownActionsProps) => {
  return (
    <Box position="relative" onClick={(e) => e.stopPropagation()} {...boxProps}>
      <Popover
        placement="bottom-end"
        closeOnBlur
        trigger="click"
        offset={[0, 0]}
        strategy="fixed"
        {...popoverProps}
        {...rest}
      >
        {({ isOpen, onClose }) => (
          <>
            <PopoverTrigger>{renderTrigger(isOpen, onClose)}</PopoverTrigger>
            <PopoverContent
              border="1px solid"
              borderColor="tGray.back"
              borderRadius="2"
              p="2"
              w="fit-content"
              filter="drop-shadow(0px 4px 8px rgba(24, 36, 60, 0.15))"
              {...popOverContentProps}
            >
              {typeof children === 'function'
                ? children({ isOpen, onClose })
                : children}
            </PopoverContent>
          </>
        )}
      </Popover>
    </Box>
  );
};

export const MIconAction = ({ icon }: { icon: any }) => (
  <Icon
    fontSize="lg"
    as={icon}
    minWidth="0"
    background="none"
    cursor="pointer"
    _hover={{
      color: 'tIndigo.base',
      background: 'none',
    }}
  />
);

interface MDropdownActionItemProps extends BoxProps {
  children?: React.ReactNode;
  colorScheme?: string;
  id?: string;
  isDisabled?: boolean;
  tooltipMsg?: string;
}

export const MDropdownActionItem: FC<MDropdownActionItemProps> = forwardRef<
  any,
  MDropdownActionItemProps
>(
  (
    {
      children,
      colorScheme = 'tPurple.base',
      id,
      isDisabled,
      tooltipMsg,
      ...rest
    },
    ref,
  ) => {
    let hoverBackground = 'tBlue.hover';
    if (colorScheme === 'tRed.base') {
      hoverBackground = 'tRed.cosmos';
    }
    return (
      <MTooltip
        label={tooltipMsg}
        placement="bottom-end"
        isDisabled={!tooltipMsg}
      >
        <Box
          ref={ref}
          color={isDisabled ? 'tGray.acGray' : colorScheme}
          p="2"
          borderRadius="2"
          cursor={isDisabled ? 'not-allowed' : 'pointer'}
          _hover={
            !isDisabled
              ? { fontWeight: 'bold', background: hoverBackground }
              : {}
          }
          _active={
            !isDisabled
              ? { fontWeight: 'bold', color: 'white', background: colorScheme }
              : {}
          }
          _focus={
            !isDisabled
              ? { fontWeight: 'bold', color: 'white', background: colorScheme }
              : {}
          }
          data-testid={`data-table-actions-${id}`}
          {...rest}
        >
          {children}
        </Box>
      </MTooltip>
    );
  },
);

export default chakra(MDropdownActions);
