import update from 'immutability-helper';
import { useCallback, useEffect, useState } from 'react';
import { logger } from '~app/services/logger';
import {
  IQuoteOfferingRespSchema,
  Maybe,
  OnQuoteOfferingChangeAction,
  QuoteOfferingWithChildren,
} from '~app/types';
import {
  getQuoteOfferingsWithChildren,
  isQuoteOfferingAddedToAmendment,
} from '~app/utils/quotes';
import { ensureArray } from '../../../api/utils';
import { useQuoteContext } from './quoteContext';
import { QuoteFormOfferingRow } from './QuoteFormOfferingRow';
import { QuoteFormOfferingTableContent } from './QuoteFormOfferingTableContent';
import { QuoteFormOfferingTableContentAmendV2 } from './QuoteFormOfferingTableContentAmendV2';

export const QuoteFormOfferingTable = () => {
  const { quoteData, quotePrices, useAmendmentV2 } = useQuoteContext();
  const { quote, fetchQuote, reorderQuoteOfferings } = quoteData;

  const getQuoteOfferingsWithChildrenInternal = (
    quoteOfferings: IQuoteOfferingRespSchema[],
  ) => {
    const quoteOfferingsWithChildren =
      getQuoteOfferingsWithChildren(quoteOfferings);

    if (useAmendmentV2) {
      return quoteOfferingsWithChildren.sort((a, b) => {
        return (
          (isQuoteOfferingAddedToAmendment(a.quoteOffering) ? 1 : -1) -
          (isQuoteOfferingAddedToAmendment(b.quoteOffering) ? 1 : -1)
        );
      });
    } else {
      return quoteOfferingsWithChildren;
    }
  };
  const onQuoteOfferingChange = useCallback(
    async (
      action: OnQuoteOfferingChangeAction,
      /**
       * One or more quote offerings to work with
       * Delete is the only use-case where there are multiple offerings
       */
      quoteOfferings?: Maybe<
        IQuoteOfferingRespSchema | IQuoteOfferingRespSchema[]
      >,
      oldQuoteOfferingId?: string,
      skipFetch = false,
    ) => {
      if (quote?.id) {
        try {
          // Update in-memory quote so page updates instantly while quote is re-fetched
          if (quoteOfferings) {
            let tempQuoteOfferings = [
              ...(quoteData.quote?.quoteOfferings || []),
            ];

            ensureArray(quoteOfferings).forEach((quoteOffering) => {
              switch (action) {
                case 'ADD':
                  tempQuoteOfferings = [...tempQuoteOfferings, quoteOffering];
                  break;
                case 'DELETE':
                  tempQuoteOfferings = tempQuoteOfferings.filter(
                    ({ id, parentQuoteOfferingId }) =>
                      id !== quoteOffering.id ||
                      parentQuoteOfferingId !== quoteOffering.id,
                  );
                  break;
                case 'UPDATE':
                  tempQuoteOfferings = tempQuoteOfferings.map((currItem) =>
                    currItem.id === quoteOffering.id ? quoteOffering : currItem,
                  );
                  break;
                case 'REPLACE':
                  // Remove oldQuoteOfferingId, then add the new offering to the end
                  if (oldQuoteOfferingId) {
                    tempQuoteOfferings = [
                      ...tempQuoteOfferings.filter(
                        ({ id }) => id !== oldQuoteOfferingId,
                      ),
                      quoteOffering,
                    ];
                  }
                  break;
                default:
                  break;
              }
            });

            setOrderedQuoteOfferings(
              getQuoteOfferingsWithChildrenInternal(tempQuoteOfferings),
            );
          }
        } catch (ex) {
          // will self-resolve after re-fetching quote
          logger.warn('Error setting quote offerings', ex);
        }
        !skipFetch && (await fetchQuote(quote.id));
      }
    },
    [fetchQuote, quote, quoteData.quote?.quoteOfferings],
  );

  const [orderedQuoteOfferings, setOrderedQuoteOfferings] = useState<
    QuoteOfferingWithChildren[]
  >([]);
  // used to determine if order actually changed before calling API
  const [priorQuoteOfferingOrder, setPriorQuoteOfferingOrder] = useState<
    string[]
  >([]);

  // Convert quoteOfferings into nested structure with children
  useEffect(() => {
    const quoteOfferingWithChildren = getQuoteOfferingsWithChildrenInternal(
      quoteData.quote?.quoteOfferings || [],
    );
    setOrderedQuoteOfferings(quoteOfferingWithChildren);
    setPriorQuoteOfferingOrder(
      quoteOfferingWithChildren.map(({ quoteOffering }) => quoteOffering?.id),
    );
  }, [quoteData.quote?.quoteOfferings]);

  const saveMoveOffering = useCallback(() => {
    // TODO: call backend API to save the new order
    // should detect if order actually changed
    // child quote offerings should be moved with their parent (not sure if BE considers children)
    const newOfferingOrder = orderedQuoteOfferings.map(
      ({ quoteOffering }) => quoteOffering.id,
    );
    const didChange = newOfferingOrder.some(
      (id, i) => priorQuoteOfferingOrder[i] !== id,
    );
    if (didChange && quoteData.quote) {
      // call api to save new order
      reorderQuoteOfferings(quoteData.quote.id, newOfferingOrder);
    }
  }, [
    orderedQuoteOfferings,
    priorQuoteOfferingOrder,
    quoteData.quote,
    reorderQuoteOfferings,
  ]);

  const moveOffering = useCallback((dragIndex: number, hoverIndex: number) => {
    try {
      setOrderedQuoteOfferings((prevQuoteOfferings) =>
        update(prevQuoteOfferings, {
          $splice: [
            [dragIndex, 1],
            [
              hoverIndex,
              0,
              prevQuoteOfferings[dragIndex] as QuoteOfferingWithChildren,
            ],
          ],
        }),
      );
    } catch (ex) {
      logger.warn('Error reordering quote offerings', ex);
    }
  }, []);

  const renderQuoteOfferings = useCallback(
    (
      quoteOfferings: QuoteOfferingWithChildren[],
      baseDragIndex = 0,
      baseIndex = 0,
    ) => {
      let currIndex = 0;
      return quoteOfferings.map(({ quoteOffering, children }, i) => {
        const index = currIndex;
        currIndex++;
        if (children?.length) {
          currIndex += children.length;
        }

        return (
          <QuoteFormOfferingRow
            dragIndex={baseDragIndex + i}
            index={baseIndex + index}
            key={quoteOffering.id}
            quote={quote}
            quoteOffering={quoteOffering}
            childQuoteOfferings={children}
            quotePrices={quotePrices}
            onChange={onQuoteOfferingChange}
            moveOffering={moveOffering}
            saveMoveOffering={saveMoveOffering}
            quoteOfferings={quoteOfferings}
          />
        );
      });
    },
    [moveOffering, onQuoteOfferingChange, quote, quotePrices, saveMoveOffering],
  );

  if (useAmendmentV2) {
    return (
      <QuoteFormOfferingTableContentAmendV2
        quoteOfferings={orderedQuoteOfferings}
        quote={quote}
        onChange={onQuoteOfferingChange}
        quotePrices={quotePrices}
        renderQuoteOfferings={renderQuoteOfferings}
      />
    );
  }
  return (
    <QuoteFormOfferingTableContent
      quoteOfferings={orderedQuoteOfferings}
      quote={quote}
      onChange={onQuoteOfferingChange}
      quotePrices={quotePrices}
      renderQuoteOfferings={renderQuoteOfferings}
    />
  );
};
