import { OfferingTypesEnum } from '~app/types';

export const getOfferingType = (
  type?: OfferingTypesEnum,
): {
  isSubscription: boolean;
  isOneTime: boolean;
  isMinCommit: boolean;
  isPercentOfTotal: boolean;
} => {
  if (type) {
    return {
      isSubscription: type === OfferingTypesEnum.SUBSCRIPTION,
      isOneTime: type === OfferingTypesEnum.ONETIME,
      isMinCommit: type === OfferingTypesEnum.MIN_COMMIT,
      isPercentOfTotal: type === OfferingTypesEnum.CUSTOM_PERCENT_OF_TOTAL,
    };
  }

  return {
    isSubscription: false,
    isOneTime: false,
    isMinCommit: false,
    isPercentOfTotal: false,
  };
};
