import { useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { ROUTES } from '~app/constants';
import { useConfigDataFetcher } from '~app/hooks/useConfigDataFetcher';
import { useAuth } from '~app/services/auth0';
import { tempRedirectInfoState } from '~app/store/global.store';

export const useAuthRouteInterceptor = () => {
  const {
    isAuthenticated,
    user,
    isLoading,
    accessToken,
    tenantId,
    userId,
    getTenantIdsFromAccessToken,
    currentTenant,
    currentTenantUser,
    isTokenRefreshed,
  } = useAuth();
  const setRedirectInfo = useSetRecoilState(tempRedirectInfoState);
  const [isReady, setIsReady] = useState(false);
  const [nextRoute, setNextRoute] = useState(ROUTES.ROOT);
  const { isConfigLoading } = useConfigDataFetcher({
    enabled: !!tenantId && isAuthenticated,
  });

  useEffect(() => {
    const tenantIds = getTenantIdsFromAccessToken();

    if (user && !user.email_verified) {
      setIsReady(true);
      setNextRoute(ROUTES.ONBOARD_VERIFY_EMAIL);
    } else if (!userId) {
      setIsReady(true);
      setNextRoute(ROUTES.ONBOARD_CREATE_USER);
    } else if (tenantIds.length === 0) {
      setIsReady(true);
      setNextRoute(ROUTES.ONBOARD_CREATE_TENANT);
    } else if (tenantIds.length > 0 && !currentTenant) {
      setIsReady(true);
      setNextRoute(ROUTES.ONBOARD_SELECT_TENANT);
    } else if (isTokenRefreshed) {
      setIsReady(true);
      setNextRoute(ROUTES.ROOT);
    }
  }, [user, accessToken, tenantId, isAuthenticated, userId, isTokenRefreshed]);

  if (!isAuthenticated) {
    // If the current tenant is valid, save it before the redirect
    setRedirectInfo({
      tenantId,
      redirectUrl: `${window.location.pathname}${window.location.search}`,
    });

    return {
      isReady: true,
      nextRoute: ROUTES.AUTH_LOGOUT,
    };
  }

  // If tenant user is not loaded, block moving to next routes
  if (!currentTenantUser && tenantId && userId) {
    return {
      isReady: false,
    };
  }

  // If tenantId is valid and config is not loaded, show loading page.
  if (tenantId && isConfigLoading) {
    return {
      isReady: false,
    };
  }

  if (isLoading || !isTokenRefreshed) {
    return {
      isReady: false,
    };
  }

  return {
    isReady,
    nextRoute,
  };
};
