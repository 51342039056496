export interface CountryWithState {
  name: string;
  isoCode: string;
  states: CountryState[];
}

export interface CountryState {
  name: string;
  isoCode: string;
}

export type CountryStateMap = Record<string, CountryState[]>;

export const countriesWithStates = [
  {
    name: "Afghanistan",
    isoCode: "AF",
    states: [
      {
        name: "Badakhshan",
        isoCode: "BDS",
      },
      {
        name: "Badghis",
        isoCode: "BDG",
      },
      {
        name: "Baghlan",
        isoCode: "BGL",
      },
      {
        name: "Balkh",
        isoCode: "BAL",
      },
      {
        name: "Bamyan",
        isoCode: "BAM",
      },
      {
        name: "Daykundi",
        isoCode: "DAY",
      },
      {
        name: "Farah",
        isoCode: "FRA",
      },
      {
        name: "Faryab",
        isoCode: "FYB",
      },
      {
        name: "Ghazni",
        isoCode: "GHA",
      },
      {
        name: "Ghōr",
        isoCode: "GHO",
      },
      {
        name: "Helmand",
        isoCode: "HEL",
      },
      {
        name: "Herat",
        isoCode: "HER",
      },
      {
        name: "Jowzjan",
        isoCode: "JOW",
      },
      {
        name: "Kabul",
        isoCode: "KAB",
      },
      {
        name: "Kandahar",
        isoCode: "KAN",
      },
      {
        name: "Kapisa",
        isoCode: "KAP",
      },
      {
        name: "Khost",
        isoCode: "KHO",
      },
      {
        name: "Kunar",
        isoCode: "KNR",
      },
      {
        name: "Kunduz Province",
        isoCode: "KDZ",
      },
      {
        name: "Laghman",
        isoCode: "LAG",
      },
      {
        name: "Logar",
        isoCode: "LOG",
      },
      {
        name: "Nangarhar",
        isoCode: "NAN",
      },
      {
        name: "Nimruz",
        isoCode: "NIM",
      },
      {
        name: "Nuristan",
        isoCode: "NUR",
      },
      {
        name: "Paktia",
        isoCode: "PIA",
      },
      {
        name: "Paktika",
        isoCode: "PKA",
      },
      {
        name: "Panjshir",
        isoCode: "PAN",
      },
      {
        name: "Parwan",
        isoCode: "PAR",
      },
      {
        name: "Samangan",
        isoCode: "SAM",
      },
      {
        name: "Sar-e Pol",
        isoCode: "SAR",
      },
      {
        name: "Takhar",
        isoCode: "TAK",
      },
      {
        name: "Urozgan",
        isoCode: "URU",
      },
      {
        name: "Zabul",
        isoCode: "ZAB",
      },
    ],
  },
  {
    name: "Aland Islands",
    isoCode: "AX",
    states: [],
  },
  {
    name: "Albania",
    isoCode: "AL",
    states: [
      {
        name: "Berat County",
        isoCode: "01",
      },
      {
        name: "Berat District",
        isoCode: "BR",
      },
      {
        name: "Bulqizë District",
        isoCode: "BU",
      },
      {
        name: "Delvinë District",
        isoCode: "DL",
      },
      {
        name: "Devoll District",
        isoCode: "DV",
      },
      {
        name: "Dibër County",
        isoCode: "09",
      },
      {
        name: "Dibër District",
        isoCode: "DI",
      },
      {
        name: "Durrës County",
        isoCode: "02",
      },
      {
        name: "Durrës District",
        isoCode: "DR",
      },
      {
        name: "Elbasan County",
        isoCode: "03",
      },
      {
        name: "Fier County",
        isoCode: "04",
      },
      {
        name: "Fier District",
        isoCode: "FR",
      },
      {
        name: "Gjirokastër County",
        isoCode: "05",
      },
      {
        name: "Gjirokastër District",
        isoCode: "GJ",
      },
      {
        name: "Gramsh District",
        isoCode: "GR",
      },
      {
        name: "Has District",
        isoCode: "HA",
      },
      {
        name: "Kavajë District",
        isoCode: "KA",
      },
      {
        name: "Kolonjë District",
        isoCode: "ER",
      },
      {
        name: "Korçë County",
        isoCode: "06",
      },
      {
        name: "Korçë District",
        isoCode: "KO",
      },
      {
        name: "Krujë District",
        isoCode: "KR",
      },
      {
        name: "Kuçovë District",
        isoCode: "KC",
      },
      {
        name: "Kukës County",
        isoCode: "07",
      },
      {
        name: "Kukës District",
        isoCode: "KU",
      },
      {
        name: "Kurbin District",
        isoCode: "KB",
      },
      {
        name: "Lezhë County",
        isoCode: "08",
      },
      {
        name: "Lezhë District",
        isoCode: "LE",
      },
      {
        name: "Librazhd District",
        isoCode: "LB",
      },
      {
        name: "Lushnjë District",
        isoCode: "LU",
      },
      {
        name: "Malësi e Madhe District",
        isoCode: "MM",
      },
      {
        name: "Mallakastër District",
        isoCode: "MK",
      },
      {
        name: "Mat District",
        isoCode: "MT",
      },
      {
        name: "Mirditë District",
        isoCode: "MR",
      },
      {
        name: "Peqin District",
        isoCode: "PQ",
      },
      {
        name: "Përmet District",
        isoCode: "PR",
      },
      {
        name: "Pogradec District",
        isoCode: "PG",
      },
      {
        name: "Pukë District",
        isoCode: "PU",
      },
      {
        name: "Sarandë District",
        isoCode: "SR",
      },
      {
        name: "Shkodër County",
        isoCode: "10",
      },
      {
        name: "Shkodër District",
        isoCode: "SH",
      },
      {
        name: "Skrapar District",
        isoCode: "SK",
      },
      {
        name: "Tepelenë District",
        isoCode: "TE",
      },
      {
        name: "Tirana County",
        isoCode: "11",
      },
      {
        name: "Tirana District",
        isoCode: "TR",
      },
      {
        name: "Tropojë District",
        isoCode: "TP",
      },
      {
        name: "Vlorë County",
        isoCode: "12",
      },
      {
        name: "Vlorë District",
        isoCode: "VL",
      },
    ],
  },
  {
    name: "Algeria",
    isoCode: "DZ",
    states: [
      {
        name: "Adrar",
        isoCode: "01",
      },
      {
        name: "Aïn Defla",
        isoCode: "44",
      },
      {
        name: "Aïn Témouchent",
        isoCode: "46",
      },
      {
        name: "Algiers",
        isoCode: "16",
      },
      {
        name: "Annaba",
        isoCode: "23",
      },
      {
        name: "Batna",
        isoCode: "05",
      },
      {
        name: "Béchar",
        isoCode: "08",
      },
      {
        name: "Béjaïa",
        isoCode: "06",
      },
      {
        name: "Béni Abbès",
        isoCode: "53",
      },
      {
        name: "Biskra",
        isoCode: "07",
      },
      {
        name: "Blida",
        isoCode: "09",
      },
      {
        name: "Bordj Baji Mokhtar",
        isoCode: "52",
      },
      {
        name: "Bordj Bou Arréridj",
        isoCode: "34",
      },
      {
        name: "Bouïra",
        isoCode: "10",
      },
      {
        name: "Boumerdès",
        isoCode: "35",
      },
      {
        name: "Chlef",
        isoCode: "02",
      },
      {
        name: "Constantine",
        isoCode: "25",
      },
      {
        name: "Djanet",
        isoCode: "56",
      },
      {
        name: "Djelfa",
        isoCode: "17",
      },
      {
        name: "El Bayadh",
        isoCode: "32",
      },
      {
        name: "El M'ghair",
        isoCode: "49",
      },
      {
        name: "El Menia",
        isoCode: "50",
      },
      {
        name: "El Oued",
        isoCode: "39",
      },
      {
        name: "El Tarf",
        isoCode: "36",
      },
      {
        name: "Ghardaïa",
        isoCode: "47",
      },
      {
        name: "Guelma",
        isoCode: "24",
      },
      {
        name: "Illizi",
        isoCode: "33",
      },
      {
        name: "In Guezzam",
        isoCode: "58",
      },
      {
        name: "In Salah",
        isoCode: "57",
      },
      {
        name: "Jijel",
        isoCode: "18",
      },
      {
        name: "Khenchela",
        isoCode: "40",
      },
      {
        name: "Laghouat",
        isoCode: "03",
      },
      {
        name: "M'Sila",
        isoCode: "28",
      },
      {
        name: "Mascara",
        isoCode: "29",
      },
      {
        name: "Médéa",
        isoCode: "26",
      },
      {
        name: "Mila",
        isoCode: "43",
      },
      {
        name: "Mostaganem",
        isoCode: "27",
      },
      {
        name: "Naama",
        isoCode: "45",
      },
      {
        name: "Oran",
        isoCode: "31",
      },
      {
        name: "Ouargla",
        isoCode: "30",
      },
      {
        name: "Ouled Djellal",
        isoCode: "51",
      },
      {
        name: "Oum El Bouaghi",
        isoCode: "04",
      },
      {
        name: "Relizane",
        isoCode: "48",
      },
      {
        name: "Saïda",
        isoCode: "20",
      },
      {
        name: "Sétif",
        isoCode: "19",
      },
      {
        name: "Sidi Bel Abbès",
        isoCode: "22",
      },
      {
        name: "Skikda",
        isoCode: "21",
      },
      {
        name: "Souk Ahras",
        isoCode: "41",
      },
      {
        name: "Tamanghasset",
        isoCode: "11",
      },
      {
        name: "Tébessa",
        isoCode: "12",
      },
      {
        name: "Tiaret",
        isoCode: "14",
      },
      {
        name: "Timimoun",
        isoCode: "54",
      },
      {
        name: "Tindouf",
        isoCode: "37",
      },
      {
        name: "Tipasa",
        isoCode: "42",
      },
      {
        name: "Tissemsilt",
        isoCode: "38",
      },
      {
        name: "Tizi Ouzou",
        isoCode: "15",
      },
      {
        name: "Tlemcen",
        isoCode: "13",
      },
      {
        name: "Touggourt",
        isoCode: "55",
      },
    ],
  },
  {
    name: "American Samoa",
    isoCode: "AS",
    states: [],
  },
  {
    name: "Andorra",
    isoCode: "AD",
    states: [
      {
        name: "Andorra la Vella",
        isoCode: "07",
      },
      {
        name: "Canillo",
        isoCode: "02",
      },
      {
        name: "Encamp",
        isoCode: "03",
      },
      {
        name: "Escaldes-Engordany",
        isoCode: "08",
      },
      {
        name: "La Massana",
        isoCode: "04",
      },
      {
        name: "Ordino",
        isoCode: "05",
      },
      {
        name: "Sant Julià de Lòria",
        isoCode: "06",
      },
    ],
  },
  {
    name: "Angola",
    isoCode: "AO",
    states: [
      {
        name: "Bengo Province",
        isoCode: "BGO",
      },
      {
        name: "Benguela Province",
        isoCode: "BGU",
      },
      {
        name: "Bié Province",
        isoCode: "BIE",
      },
      {
        name: "Cabinda Province",
        isoCode: "CAB",
      },
      {
        name: "Cuando Cubango Province",
        isoCode: "CCU",
      },
      {
        name: "Cuanza Norte Province",
        isoCode: "CNO",
      },
      {
        name: "Cuanza Sul",
        isoCode: "CUS",
      },
      {
        name: "Cunene Province",
        isoCode: "CNN",
      },
      {
        name: "Huambo Province",
        isoCode: "HUA",
      },
      {
        name: "Huíla Province",
        isoCode: "HUI",
      },
      {
        name: "Luanda Province",
        isoCode: "LUA",
      },
      {
        name: "Lunda Norte Province",
        isoCode: "LNO",
      },
      {
        name: "Lunda Sul Province",
        isoCode: "LSU",
      },
      {
        name: "Malanje Province",
        isoCode: "MAL",
      },
      {
        name: "Moxico Province",
        isoCode: "MOX",
      },
      {
        name: "Uíge Province",
        isoCode: "UIG",
      },
      {
        name: "Zaire Province",
        isoCode: "ZAI",
      },
    ],
  },
  {
    name: "Anguilla",
    isoCode: "AI",
    states: [],
  },
  {
    name: "Antarctica",
    isoCode: "AQ",
    states: [],
  },
  {
    name: "Antigua And Barbuda",
    isoCode: "AG",
    states: [
      {
        name: "Barbuda",
        isoCode: "10",
      },
      {
        name: "Redonda",
        isoCode: "11",
      },
      {
        name: "Saint George Parish",
        isoCode: "03",
      },
      {
        name: "Saint John Parish",
        isoCode: "04",
      },
      {
        name: "Saint Mary Parish",
        isoCode: "05",
      },
      {
        name: "Saint Paul Parish",
        isoCode: "06",
      },
      {
        name: "Saint Peter Parish",
        isoCode: "07",
      },
      {
        name: "Saint Philip Parish",
        isoCode: "08",
      },
    ],
  },
  {
    name: "Argentina",
    isoCode: "AR",
    states: [
      {
        name: "Buenos Aires",
        isoCode: "B",
      },
      {
        name: "Catamarca",
        isoCode: "K",
      },
      {
        name: "Chaco",
        isoCode: "H",
      },
      {
        name: "Chubut",
        isoCode: "U",
      },
      {
        name: "Ciudad Autónoma de Buenos Aires",
        isoCode: "C",
      },
      {
        name: "Córdoba",
        isoCode: "X",
      },
      {
        name: "Corrientes",
        isoCode: "W",
      },
      {
        name: "Entre Ríos",
        isoCode: "E",
      },
      {
        name: "Formosa",
        isoCode: "P",
      },
      {
        name: "Jujuy",
        isoCode: "Y",
      },
      {
        name: "La Pampa",
        isoCode: "L",
      },
      {
        name: "La Rioja",
        isoCode: "F",
      },
      {
        name: "Mendoza",
        isoCode: "M",
      },
      {
        name: "Misiones",
        isoCode: "N",
      },
      {
        name: "Neuquén",
        isoCode: "Q",
      },
      {
        name: "Río Negro",
        isoCode: "R",
      },
      {
        name: "Salta",
        isoCode: "A",
      },
      {
        name: "San Juan",
        isoCode: "J",
      },
      {
        name: "San Luis",
        isoCode: "D",
      },
      {
        name: "Santa Cruz",
        isoCode: "Z",
      },
      {
        name: "Santa Fe",
        isoCode: "S",
      },
      {
        name: "Santiago del Estero",
        isoCode: "G",
      },
      {
        name: "Tierra del Fuego",
        isoCode: "V",
      },
      {
        name: "Tucumán",
        isoCode: "T",
      },
    ],
  },
  {
    name: "Armenia",
    isoCode: "AM",
    states: [
      {
        name: "Aragatsotn Region",
        isoCode: "AG",
      },
      {
        name: "Ararat Province",
        isoCode: "AR",
      },
      {
        name: "Armavir Region",
        isoCode: "AV",
      },
      {
        name: "Gegharkunik Province",
        isoCode: "GR",
      },
      {
        name: "Kotayk Region",
        isoCode: "KT",
      },
      {
        name: "Lori Region",
        isoCode: "LO",
      },
      {
        name: "Shirak Region",
        isoCode: "SH",
      },
      {
        name: "Syunik Province",
        isoCode: "SU",
      },
      {
        name: "Tavush Region",
        isoCode: "TV",
      },
      {
        name: "Vayots Dzor Region",
        isoCode: "VD",
      },
      {
        name: "Yerevan",
        isoCode: "ER",
      },
    ],
  },
  {
    name: "Aruba",
    isoCode: "AW",
    states: [],
  },
  {
    name: "Australia",
    isoCode: "AU",
    states: [
      {
        name: "Australian Capital Territory",
        isoCode: "ACT",
      },
      {
        name: "New South Wales",
        isoCode: "NSW",
      },
      {
        name: "Northern Territory",
        isoCode: "NT",
      },
      {
        name: "Queensland",
        isoCode: "QLD",
      },
      {
        name: "South Australia",
        isoCode: "SA",
      },
      {
        name: "Tasmania",
        isoCode: "TAS",
      },
      {
        name: "Victoria",
        isoCode: "VIC",
      },
      {
        name: "Western Australia",
        isoCode: "WA",
      },
    ],
  },
  {
    name: "Austria",
    isoCode: "AT",
    states: [
      {
        name: "Burgenland",
        isoCode: "1",
      },
      {
        name: "Carinthia",
        isoCode: "2",
      },
      {
        name: "Lower Austria",
        isoCode: "3",
      },
      {
        name: "Salzburg",
        isoCode: "5",
      },
      {
        name: "Styria",
        isoCode: "6",
      },
      {
        name: "Tyrol",
        isoCode: "7",
      },
      {
        name: "Upper Austria",
        isoCode: "4",
      },
      {
        name: "Vienna",
        isoCode: "9",
      },
      {
        name: "Vorarlberg",
        isoCode: "8",
      },
    ],
  },
  {
    name: "Azerbaijan",
    isoCode: "AZ",
    states: [
      {
        name: "Absheron District",
        isoCode: "ABS",
      },
      {
        name: "Agdam District",
        isoCode: "AGM",
      },
      {
        name: "Agdash District",
        isoCode: "AGS",
      },
      {
        name: "Aghjabadi District",
        isoCode: "AGC",
      },
      {
        name: "Agstafa District",
        isoCode: "AGA",
      },
      {
        name: "Agsu District",
        isoCode: "AGU",
      },
      {
        name: "Astara District",
        isoCode: "AST",
      },
      {
        name: "Babek District",
        isoCode: "BAB",
      },
      {
        name: "Baku",
        isoCode: "BA",
      },
      {
        name: "Balakan District",
        isoCode: "BAL",
      },
      {
        name: "Barda District",
        isoCode: "BAR",
      },
      {
        name: "Beylagan District",
        isoCode: "BEY",
      },
      {
        name: "Bilasuvar District",
        isoCode: "BIL",
      },
      {
        name: "Dashkasan District",
        isoCode: "DAS",
      },
      {
        name: "Fizuli District",
        isoCode: "FUZ",
      },
      {
        name: "Ganja",
        isoCode: "GA",
      },
      {
        name: "Gədəbəy",
        isoCode: "GAD",
      },
      {
        name: "Gobustan District",
        isoCode: "QOB",
      },
      {
        name: "Goranboy District",
        isoCode: "GOR",
      },
      {
        name: "Goychay",
        isoCode: "GOY",
      },
      {
        name: "Goygol District",
        isoCode: "GYG",
      },
      {
        name: "Hajigabul District",
        isoCode: "HAC",
      },
      {
        name: "Imishli District",
        isoCode: "IMI",
      },
      {
        name: "Ismailli District",
        isoCode: "ISM",
      },
      {
        name: "Jabrayil District",
        isoCode: "CAB",
      },
      {
        name: "Jalilabad District",
        isoCode: "CAL",
      },
      {
        name: "Julfa District",
        isoCode: "CUL",
      },
      {
        name: "Kalbajar District",
        isoCode: "KAL",
      },
      {
        name: "Kangarli District",
        isoCode: "KAN",
      },
      {
        name: "Khachmaz District",
        isoCode: "XAC",
      },
      {
        name: "Khizi District",
        isoCode: "XIZ",
      },
      {
        name: "Khojali District",
        isoCode: "XCI",
      },
      {
        name: "Kurdamir District",
        isoCode: "KUR",
      },
      {
        name: "Lachin District",
        isoCode: "LAC",
      },
      {
        name: "Lankaran",
        isoCode: "LAN",
      },
      {
        name: "Lankaran District",
        isoCode: "LA",
      },
      {
        name: "Lerik District",
        isoCode: "LER",
      },
      {
        name: "Martuni",
        isoCode: "XVD",
      },
      {
        name: "Masally District",
        isoCode: "MAS",
      },
      {
        name: "Mingachevir",
        isoCode: "MI",
      },
      {
        name: "Nakhchivan Autonomous Republic",
        isoCode: "NX",
      },
      {
        name: "Neftchala District",
        isoCode: "NEF",
      },
      {
        name: "Oghuz District",
        isoCode: "OGU",
      },
      {
        name: "Ordubad District",
        isoCode: "ORD",
      },
      {
        name: "Qabala District",
        isoCode: "QAB",
      },
      {
        name: "Qakh District",
        isoCode: "QAX",
      },
      {
        name: "Qazakh District",
        isoCode: "QAZ",
      },
      {
        name: "Quba District",
        isoCode: "QBA",
      },
      {
        name: "Qubadli District",
        isoCode: "QBI",
      },
      {
        name: "Qusar District",
        isoCode: "QUS",
      },
      {
        name: "Saatly District",
        isoCode: "SAT",
      },
      {
        name: "Sabirabad District",
        isoCode: "SAB",
      },
      {
        name: "Sadarak District",
        isoCode: "SAD",
      },
      {
        name: "Salyan District",
        isoCode: "SAL",
      },
      {
        name: "Samukh District",
        isoCode: "SMX",
      },
      {
        name: "Shabran District",
        isoCode: "SBN",
      },
      {
        name: "Shahbuz District",
        isoCode: "SAH",
      },
      {
        name: "Shaki",
        isoCode: "SA",
      },
      {
        name: "Shaki District",
        isoCode: "SAK",
      },
      {
        name: "Shamakhi District",
        isoCode: "SMI",
      },
      {
        name: "Shamkir District",
        isoCode: "SKR",
      },
      {
        name: "Sharur District",
        isoCode: "SAR",
      },
      {
        name: "Shirvan",
        isoCode: "SR",
      },
      {
        name: "Shusha District",
        isoCode: "SUS",
      },
      {
        name: "Siazan District",
        isoCode: "SIY",
      },
      {
        name: "Sumqayit",
        isoCode: "SM",
      },
      {
        name: "Tartar District",
        isoCode: "TAR",
      },
      {
        name: "Tovuz District",
        isoCode: "TOV",
      },
      {
        name: "Ujar District",
        isoCode: "UCA",
      },
      {
        name: "Yardymli District",
        isoCode: "YAR",
      },
      {
        name: "Yevlakh",
        isoCode: "YE",
      },
      {
        name: "Yevlakh District",
        isoCode: "YEV",
      },
      {
        name: "Zangilan District",
        isoCode: "ZAN",
      },
      {
        name: "Zaqatala District",
        isoCode: "ZAQ",
      },
      {
        name: "Zardab District",
        isoCode: "ZAR",
      },
    ],
  },
  {
    name: "Bahrain",
    isoCode: "BH",
    states: [
      {
        name: "Capital Governorate",
        isoCode: "13",
      },
      {
        name: "Central Governorate",
        isoCode: "16",
      },
      {
        name: "Muharraq Governorate",
        isoCode: "15",
      },
      {
        name: "Northern Governorate",
        isoCode: "17",
      },
      {
        name: "Southern Governorate",
        isoCode: "14",
      },
    ],
  },
  {
    name: "Bangladesh",
    isoCode: "BD",
    states: [
      {
        name: "Bagerhat District",
        isoCode: "05",
      },
      {
        name: "Bahadia",
        isoCode: "33",
      },
      {
        name: "Bandarban District",
        isoCode: "01",
      },
      {
        name: "Barguna District",
        isoCode: "02",
      },
      {
        name: "Barisal District",
        isoCode: "06",
      },
      {
        name: "Barisal Division",
        isoCode: "A",
      },
      {
        name: "Bhola District",
        isoCode: "07",
      },
      {
        name: "Bogra District",
        isoCode: "03",
      },
      {
        name: "Brahmanbaria District",
        isoCode: "04",
      },
      {
        name: "Chandpur District",
        isoCode: "09",
      },
      {
        name: "Chapai Nawabganj District",
        isoCode: "45",
      },
      {
        name: "Chittagong District",
        isoCode: "10",
      },
      {
        name: "Chittagong Division",
        isoCode: "B",
      },
      {
        name: "Chuadanga District",
        isoCode: "12",
      },
      {
        name: "Comilla District",
        isoCode: "08",
      },
      {
        name: "Cox's Bazar District",
        isoCode: "11",
      },
      {
        name: "Dhaka District",
        isoCode: "13",
      },
      {
        name: "Dhaka Division",
        isoCode: "C",
      },
      {
        name: "Dinajpur District",
        isoCode: "14",
      },
      {
        name: "Faridpur District",
        isoCode: "15",
      },
      {
        name: "Feni District",
        isoCode: "16",
      },
      {
        name: "Gaibandha District",
        isoCode: "19",
      },
      {
        name: "Gazipur District",
        isoCode: "18",
      },
      {
        name: "Gopalganj District",
        isoCode: "17",
      },
      {
        name: "Habiganj District",
        isoCode: "20",
      },
      {
        name: "Jamalpur District",
        isoCode: "21",
      },
      {
        name: "Jessore District",
        isoCode: "22",
      },
      {
        name: "Jhalokati District",
        isoCode: "25",
      },
      {
        name: "Jhenaidah District",
        isoCode: "23",
      },
      {
        name: "Joypurhat District",
        isoCode: "24",
      },
      {
        name: "Khagrachari District",
        isoCode: "29",
      },
      {
        name: "Khulna District",
        isoCode: "27",
      },
      {
        name: "Khulna Division",
        isoCode: "D",
      },
      {
        name: "Kishoreganj District",
        isoCode: "26",
      },
      {
        name: "Kurigram District",
        isoCode: "28",
      },
      {
        name: "Kushtia District",
        isoCode: "30",
      },
      {
        name: "Lakshmipur District",
        isoCode: "31",
      },
      {
        name: "Lalmonirhat District",
        isoCode: "32",
      },
      {
        name: "Madaripur District",
        isoCode: "36",
      },
      {
        name: "Meherpur District",
        isoCode: "39",
      },
      {
        name: "Moulvibazar District",
        isoCode: "38",
      },
      {
        name: "Munshiganj District",
        isoCode: "35",
      },
      {
        name: "Mymensingh District",
        isoCode: "34",
      },
      {
        name: "Mymensingh Division",
        isoCode: "H",
      },
      {
        name: "Naogaon District",
        isoCode: "48",
      },
      {
        name: "Narail District",
        isoCode: "43",
      },
      {
        name: "Narayanganj District",
        isoCode: "40",
      },
      {
        name: "Natore District",
        isoCode: "44",
      },
      {
        name: "Netrokona District",
        isoCode: "41",
      },
      {
        name: "Nilphamari District",
        isoCode: "46",
      },
      {
        name: "Noakhali District",
        isoCode: "47",
      },
      {
        name: "Pabna District",
        isoCode: "49",
      },
      {
        name: "Panchagarh District",
        isoCode: "52",
      },
      {
        name: "Patuakhali District",
        isoCode: "51",
      },
      {
        name: "Pirojpur District",
        isoCode: "50",
      },
      {
        name: "Rajbari District",
        isoCode: "53",
      },
      {
        name: "Rajshahi District",
        isoCode: "54",
      },
      {
        name: "Rajshahi Division",
        isoCode: "E",
      },
      {
        name: "Rangamati Hill District",
        isoCode: "56",
      },
      {
        name: "Rangpur District",
        isoCode: "55",
      },
      {
        name: "Rangpur Division",
        isoCode: "F",
      },
      {
        name: "Satkhira District",
        isoCode: "58",
      },
      {
        name: "Shariatpur District",
        isoCode: "62",
      },
      {
        name: "Sherpur District",
        isoCode: "57",
      },
      {
        name: "Sirajganj District",
        isoCode: "59",
      },
      {
        name: "Sunamganj District",
        isoCode: "61",
      },
      {
        name: "Sylhet District",
        isoCode: "60",
      },
      {
        name: "Sylhet Division",
        isoCode: "G",
      },
      {
        name: "Tangail District",
        isoCode: "63",
      },
      {
        name: "Thakurgaon District",
        isoCode: "64",
      },
    ],
  },
  {
    name: "Barbados",
    isoCode: "BB",
    states: [
      {
        name: "Christ Church",
        isoCode: "01",
      },
      {
        name: "Saint Andrew",
        isoCode: "02",
      },
      {
        name: "Saint George",
        isoCode: "03",
      },
      {
        name: "Saint James",
        isoCode: "04",
      },
      {
        name: "Saint John",
        isoCode: "05",
      },
      {
        name: "Saint Joseph",
        isoCode: "06",
      },
      {
        name: "Saint Lucy",
        isoCode: "07",
      },
      {
        name: "Saint Michael",
        isoCode: "08",
      },
      {
        name: "Saint Peter",
        isoCode: "09",
      },
      {
        name: "Saint Philip",
        isoCode: "10",
      },
      {
        name: "Saint Thomas",
        isoCode: "11",
      },
    ],
  },
  {
    name: "Belarus",
    isoCode: "BY",
    states: [
      {
        name: "Brest Region",
        isoCode: "BR",
      },
      {
        name: "Gomel Region",
        isoCode: "HO",
      },
      {
        name: "Grodno Region",
        isoCode: "HR",
      },
      {
        name: "Minsk",
        isoCode: "HM",
      },
      {
        name: "Minsk Region",
        isoCode: "MI",
      },
      {
        name: "Mogilev Region",
        isoCode: "MA",
      },
      {
        name: "Vitebsk Region",
        isoCode: "VI",
      },
    ],
  },
  {
    name: "Belgium",
    isoCode: "BE",
    states: [
      {
        name: "Antwerp",
        isoCode: "VAN",
      },
      {
        name: "Brussels-Capital Region",
        isoCode: "BRU",
      },
      {
        name: "East Flanders",
        isoCode: "VOV",
      },
      {
        name: "Flanders",
        isoCode: "VLG",
      },
      {
        name: "Flemish Brabant",
        isoCode: "VBR",
      },
      {
        name: "Hainaut",
        isoCode: "WHT",
      },
      {
        name: "Liège",
        isoCode: "WLG",
      },
      {
        name: "Limburg",
        isoCode: "VLI",
      },
      {
        name: "Luxembourg",
        isoCode: "WLX",
      },
      {
        name: "Namur",
        isoCode: "WNA",
      },
      {
        name: "Wallonia",
        isoCode: "WAL",
      },
      {
        name: "Walloon Brabant",
        isoCode: "WBR",
      },
      {
        name: "West Flanders",
        isoCode: "VWV",
      },
    ],
  },
  {
    name: "Belize",
    isoCode: "BZ",
    states: [
      {
        name: "Belize District",
        isoCode: "BZ",
      },
      {
        name: "Cayo District",
        isoCode: "CY",
      },
      {
        name: "Corozal District",
        isoCode: "CZL",
      },
      {
        name: "Orange Walk District",
        isoCode: "OW",
      },
      {
        name: "Stann Creek District",
        isoCode: "SC",
      },
      {
        name: "Toledo District",
        isoCode: "TOL",
      },
    ],
  },
  {
    name: "Benin",
    isoCode: "BJ",
    states: [
      {
        name: "Alibori Department",
        isoCode: "AL",
      },
      {
        name: "Atakora Department",
        isoCode: "AK",
      },
      {
        name: "Atlantique Department",
        isoCode: "AQ",
      },
      {
        name: "Borgou Department",
        isoCode: "BO",
      },
      {
        name: "Collines Department",
        isoCode: "CO",
      },
      {
        name: "Donga Department",
        isoCode: "DO",
      },
      {
        name: "Kouffo Department",
        isoCode: "KO",
      },
      {
        name: "Littoral Department",
        isoCode: "LI",
      },
      {
        name: "Mono Department",
        isoCode: "MO",
      },
      {
        name: "Ouémé Department",
        isoCode: "OU",
      },
      {
        name: "Plateau Department",
        isoCode: "PL",
      },
      {
        name: "Zou Department",
        isoCode: "ZO",
      },
    ],
  },
  {
    name: "Bermuda",
    isoCode: "BM",
    states: [
      {
        name: "Devonshire Parish",
        isoCode: "DEV",
      },
      {
        name: "Hamilton Parish",
        isoCode: "HA",
      },
      {
        name: "Paget Parish",
        isoCode: "PAG",
      },
      {
        name: "Pembroke Parish",
        isoCode: "PEM",
      },
      {
        name: "Saint George's Parish",
        isoCode: "SGE",
      },
      {
        name: "Sandys Parish",
        isoCode: "SAN",
      },
      {
        name: "Smith's Parish,",
        isoCode: "SMI",
      },
      {
        name: "Southampton Parish",
        isoCode: "SOU",
      },
      {
        name: "Warwick Parish",
        isoCode: "WAR",
      },
    ],
  },
  {
    name: "Bhutan",
    isoCode: "BT",
    states: [
      {
        name: "Bumthang District",
        isoCode: "33",
      },
      {
        name: "Chukha District",
        isoCode: "12",
      },
      {
        name: "Dagana District",
        isoCode: "22",
      },
      {
        name: "Gasa District",
        isoCode: "GA",
      },
      {
        name: "Haa District",
        isoCode: "13",
      },
      {
        name: "Lhuntse District",
        isoCode: "44",
      },
      {
        name: "Mongar District",
        isoCode: "42",
      },
      {
        name: "Paro District",
        isoCode: "11",
      },
      {
        name: "Pemagatshel District",
        isoCode: "43",
      },
      {
        name: "Punakha District",
        isoCode: "23",
      },
      {
        name: "Samdrup Jongkhar District",
        isoCode: "45",
      },
      {
        name: "Samtse District",
        isoCode: "14",
      },
      {
        name: "Sarpang District",
        isoCode: "31",
      },
      {
        name: "Thimphu District",
        isoCode: "15",
      },
      {
        name: "Trashigang District",
        isoCode: "41",
      },
      {
        name: "Trongsa District",
        isoCode: "32",
      },
      {
        name: "Tsirang District",
        isoCode: "21",
      },
      {
        name: "Wangdue Phodrang District",
        isoCode: "24",
      },
      {
        name: "Zhemgang District",
        isoCode: "34",
      },
    ],
  },
  {
    name: "Bolivia",
    isoCode: "BO",
    states: [
      {
        name: "Beni Department",
        isoCode: "B",
      },
      {
        name: "Chuquisaca Department",
        isoCode: "H",
      },
      {
        name: "Cochabamba Department",
        isoCode: "C",
      },
      {
        name: "La Paz Department",
        isoCode: "L",
      },
      {
        name: "Oruro Department",
        isoCode: "O",
      },
      {
        name: "Pando Department",
        isoCode: "N",
      },
      {
        name: "Potosí Department",
        isoCode: "P",
      },
      {
        name: "Santa Cruz Department",
        isoCode: "S",
      },
      {
        name: "Tarija Department",
        isoCode: "T",
      },
    ],
  },
  {
    name: "Bonaire, Sint Eustatius and Saba",
    isoCode: "BQ",
    states: [
      {
        name: "Bonaire",
        isoCode: "BQ1",
      },
      {
        name: "Saba",
        isoCode: "BQ2",
      },
      {
        name: "Sint Eustatius",
        isoCode: "BQ3",
      },
    ],
  },
  {
    name: "Bosnia and Herzegovina",
    isoCode: "BA",
    states: [
      {
        name: "Bosnian Podrinje Canton",
        isoCode: "05",
      },
      {
        name: "Brčko District",
        isoCode: "BRC",
      },
      {
        name: "Canton 10",
        isoCode: "10",
      },
      {
        name: "Central Bosnia Canton",
        isoCode: "06",
      },
      {
        name: "Federation of Bosnia and Herzegovina",
        isoCode: "BIH",
      },
      {
        name: "Herzegovina-Neretva Canton",
        isoCode: "07",
      },
      {
        name: "Posavina Canton",
        isoCode: "02",
      },
      {
        name: "Republika Srpska",
        isoCode: "SRP",
      },
      {
        name: "Sarajevo Canton",
        isoCode: "09",
      },
      {
        name: "Tuzla Canton",
        isoCode: "03",
      },
      {
        name: "Una-Sana Canton",
        isoCode: "01",
      },
      {
        name: "West Herzegovina Canton",
        isoCode: "08",
      },
      {
        name: "Zenica-Doboj Canton",
        isoCode: "04",
      },
    ],
  },
  {
    name: "Botswana",
    isoCode: "BW",
    states: [
      {
        name: "Central District",
        isoCode: "CE",
      },
      {
        name: "Ghanzi District",
        isoCode: "GH",
      },
      {
        name: "Kgalagadi District",
        isoCode: "KG",
      },
      {
        name: "Kgatleng District",
        isoCode: "KL",
      },
      {
        name: "Kweneng District",
        isoCode: "KW",
      },
      {
        name: "Ngamiland",
        isoCode: "NG",
      },
      {
        name: "North-East District",
        isoCode: "NE",
      },
      {
        name: "North-West District",
        isoCode: "NW",
      },
      {
        name: "South-East District",
        isoCode: "SE",
      },
      {
        name: "Southern District",
        isoCode: "SO",
      },
    ],
  },
  {
    name: "Bouvet Island",
    isoCode: "BV",
    states: [],
  },
  {
    name: "Brazil",
    isoCode: "BR",
    states: [
      {
        name: "Acre",
        isoCode: "AC",
      },
      {
        name: "Alagoas",
        isoCode: "AL",
      },
      {
        name: "Amapá",
        isoCode: "AP",
      },
      {
        name: "Amazonas",
        isoCode: "AM",
      },
      {
        name: "Bahia",
        isoCode: "BA",
      },
      {
        name: "Ceará",
        isoCode: "CE",
      },
      {
        name: "Distrito Federal",
        isoCode: "DF",
      },
      {
        name: "Espírito Santo",
        isoCode: "ES",
      },
      {
        name: "Goiás",
        isoCode: "GO",
      },
      {
        name: "Maranhão",
        isoCode: "MA",
      },
      {
        name: "Mato Grosso",
        isoCode: "MT",
      },
      {
        name: "Mato Grosso do Sul",
        isoCode: "MS",
      },
      {
        name: "Minas Gerais",
        isoCode: "MG",
      },
      {
        name: "Pará",
        isoCode: "PA",
      },
      {
        name: "Paraíba",
        isoCode: "PB",
      },
      {
        name: "Paraná",
        isoCode: "PR",
      },
      {
        name: "Pernambuco",
        isoCode: "PE",
      },
      {
        name: "Piauí",
        isoCode: "PI",
      },
      {
        name: "Rio de Janeiro",
        isoCode: "RJ",
      },
      {
        name: "Rio Grande do Norte",
        isoCode: "RN",
      },
      {
        name: "Rio Grande do Sul",
        isoCode: "RS",
      },
      {
        name: "Rondônia",
        isoCode: "RO",
      },
      {
        name: "Roraima",
        isoCode: "RR",
      },
      {
        name: "Santa Catarina",
        isoCode: "SC",
      },
      {
        name: "São Paulo",
        isoCode: "SP",
      },
      {
        name: "Sergipe",
        isoCode: "SE",
      },
      {
        name: "Tocantins",
        isoCode: "TO",
      },
    ],
  },
  {
    name: "British Indian Ocean Territory",
    isoCode: "IO",
    states: [],
  },
  {
    name: "Brunei",
    isoCode: "BN",
    states: [
      {
        name: "Belait District",
        isoCode: "BE",
      },
      {
        name: "Brunei-Muara District",
        isoCode: "BM",
      },
      {
        name: "Temburong District",
        isoCode: "TE",
      },
      {
        name: "Tutong District",
        isoCode: "TU",
      },
    ],
  },
  {
    name: "Bulgaria",
    isoCode: "BG",
    states: [
      {
        name: "Blagoevgrad Province",
        isoCode: "01",
      },
      {
        name: "Burgas Province",
        isoCode: "02",
      },
      {
        name: "Dobrich Province",
        isoCode: "08",
      },
      {
        name: "Gabrovo Province",
        isoCode: "07",
      },
      {
        name: "Haskovo Province",
        isoCode: "26",
      },
      {
        name: "Kardzhali Province",
        isoCode: "09",
      },
      {
        name: "Kyustendil Province",
        isoCode: "10",
      },
      {
        name: "Lovech Province",
        isoCode: "11",
      },
      {
        name: "Montana Province",
        isoCode: "12",
      },
      {
        name: "Pazardzhik Province",
        isoCode: "13",
      },
      {
        name: "Pernik Province",
        isoCode: "14",
      },
      {
        name: "Pleven Province",
        isoCode: "15",
      },
      {
        name: "Plovdiv Province",
        isoCode: "16",
      },
      {
        name: "Razgrad Province",
        isoCode: "17",
      },
      {
        name: "Ruse Province",
        isoCode: "18",
      },
      {
        name: "Shumen",
        isoCode: "27",
      },
      {
        name: "Silistra Province",
        isoCode: "19",
      },
      {
        name: "Sliven Province",
        isoCode: "20",
      },
      {
        name: "Smolyan Province",
        isoCode: "21",
      },
      {
        name: "Sofia City Province",
        isoCode: "22",
      },
      {
        name: "Sofia Province",
        isoCode: "23",
      },
      {
        name: "Stara Zagora Province",
        isoCode: "24",
      },
      {
        name: "Targovishte Province",
        isoCode: "25",
      },
      {
        name: "Varna Province",
        isoCode: "03",
      },
      {
        name: "Veliko Tarnovo Province",
        isoCode: "04",
      },
      {
        name: "Vidin Province",
        isoCode: "05",
      },
      {
        name: "Vratsa Province",
        isoCode: "06",
      },
      {
        name: "Yambol Province",
        isoCode: "28",
      },
    ],
  },
  {
    name: "Burkina Faso",
    isoCode: "BF",
    states: [
      {
        name: "Balé Province",
        isoCode: "BAL",
      },
      {
        name: "Bam Province",
        isoCode: "BAM",
      },
      {
        name: "Banwa Province",
        isoCode: "BAN",
      },
      {
        name: "Bazèga Province",
        isoCode: "BAZ",
      },
      {
        name: "Boucle du Mouhoun Region",
        isoCode: "01",
      },
      {
        name: "Bougouriba Province",
        isoCode: "BGR",
      },
      {
        name: "Boulgou",
        isoCode: "BLG",
      },
      {
        name: "Cascades Region",
        isoCode: "02",
      },
      {
        name: "Centre",
        isoCode: "03",
      },
      {
        name: "Centre-Est Region",
        isoCode: "04",
      },
      {
        name: "Centre-Nord Region",
        isoCode: "05",
      },
      {
        name: "Centre-Ouest Region",
        isoCode: "06",
      },
      {
        name: "Centre-Sud Region",
        isoCode: "07",
      },
      {
        name: "Comoé Province",
        isoCode: "COM",
      },
      {
        name: "Est Region",
        isoCode: "08",
      },
      {
        name: "Ganzourgou Province",
        isoCode: "GAN",
      },
      {
        name: "Gnagna Province",
        isoCode: "GNA",
      },
      {
        name: "Gourma Province",
        isoCode: "GOU",
      },
      {
        name: "Hauts-Bassins Region",
        isoCode: "09",
      },
      {
        name: "Houet Province",
        isoCode: "HOU",
      },
      {
        name: "Ioba Province",
        isoCode: "IOB",
      },
      {
        name: "Kadiogo Province",
        isoCode: "KAD",
      },
      {
        name: "Kénédougou Province",
        isoCode: "KEN",
      },
      {
        name: "Komondjari Province",
        isoCode: "KMD",
      },
      {
        name: "Kompienga Province",
        isoCode: "KMP",
      },
      {
        name: "Kossi Province",
        isoCode: "KOS",
      },
      {
        name: "Koulpélogo Province",
        isoCode: "KOP",
      },
      {
        name: "Kouritenga Province",
        isoCode: "KOT",
      },
      {
        name: "Kourwéogo Province",
        isoCode: "KOW",
      },
      {
        name: "Léraba Province",
        isoCode: "LER",
      },
      {
        name: "Loroum Province",
        isoCode: "LOR",
      },
      {
        name: "Mouhoun",
        isoCode: "MOU",
      },
      {
        name: "Nahouri Province",
        isoCode: "NAO",
      },
      {
        name: "Namentenga Province",
        isoCode: "NAM",
      },
      {
        name: "Nayala Province",
        isoCode: "NAY",
      },
      {
        name: "Nord Region, Burkina Faso",
        isoCode: "10",
      },
      {
        name: "Noumbiel Province",
        isoCode: "NOU",
      },
      {
        name: "Oubritenga Province",
        isoCode: "OUB",
      },
      {
        name: "Oudalan Province",
        isoCode: "OUD",
      },
      {
        name: "Passoré Province",
        isoCode: "PAS",
      },
      {
        name: "Plateau-Central Region",
        isoCode: "11",
      },
      {
        name: "Poni Province",
        isoCode: "PON",
      },
      {
        name: "Sahel Region",
        isoCode: "12",
      },
      {
        name: "Sanguié Province",
        isoCode: "SNG",
      },
      {
        name: "Sanmatenga Province",
        isoCode: "SMT",
      },
      {
        name: "Séno Province",
        isoCode: "SEN",
      },
      {
        name: "Sissili Province",
        isoCode: "SIS",
      },
      {
        name: "Soum Province",
        isoCode: "SOM",
      },
      {
        name: "Sourou Province",
        isoCode: "SOR",
      },
      {
        name: "Sud-Ouest Region",
        isoCode: "13",
      },
      {
        name: "Tapoa Province",
        isoCode: "TAP",
      },
      {
        name: "Tuy Province",
        isoCode: "TUI",
      },
      {
        name: "Yagha Province",
        isoCode: "YAG",
      },
      {
        name: "Yatenga Province",
        isoCode: "YAT",
      },
      {
        name: "Ziro Province",
        isoCode: "ZIR",
      },
      {
        name: "Zondoma Province",
        isoCode: "ZON",
      },
      {
        name: "Zoundwéogo Province",
        isoCode: "ZOU",
      },
    ],
  },
  {
    name: "Burundi",
    isoCode: "BI",
    states: [
      {
        name: "Bubanza Province",
        isoCode: "BB",
      },
      {
        name: "Bujumbura Mairie Province",
        isoCode: "BM",
      },
      {
        name: "Bujumbura Rural Province",
        isoCode: "BL",
      },
      {
        name: "Bururi Province",
        isoCode: "BR",
      },
      {
        name: "Cankuzo Province",
        isoCode: "CA",
      },
      {
        name: "Cibitoke Province",
        isoCode: "CI",
      },
      {
        name: "Gitega Province",
        isoCode: "GI",
      },
      {
        name: "Karuzi Province",
        isoCode: "KR",
      },
      {
        name: "Kayanza Province",
        isoCode: "KY",
      },
      {
        name: "Kirundo Province",
        isoCode: "KI",
      },
      {
        name: "Makamba Province",
        isoCode: "MA",
      },
      {
        name: "Muramvya Province",
        isoCode: "MU",
      },
      {
        name: "Muyinga Province",
        isoCode: "MY",
      },
      {
        name: "Mwaro Province",
        isoCode: "MW",
      },
      {
        name: "Ngozi Province",
        isoCode: "NG",
      },
      {
        name: "Rumonge Province",
        isoCode: "RM",
      },
      {
        name: "Rutana Province",
        isoCode: "RT",
      },
      {
        name: "Ruyigi Province",
        isoCode: "RY",
      },
    ],
  },
  {
    name: "Cambodia",
    isoCode: "KH",
    states: [
      {
        name: "Banteay Meanchey",
        isoCode: "1",
      },
      {
        name: "Battambang",
        isoCode: "2",
      },
      {
        name: "Kampong Cham",
        isoCode: "3",
      },
      {
        name: "Kampong Chhnang",
        isoCode: "4",
      },
      {
        name: "Kampong Speu",
        isoCode: "5",
      },
      {
        name: "Kampong Thom",
        isoCode: "6",
      },
      {
        name: "Kampot",
        isoCode: "7",
      },
      {
        name: "Kandal",
        isoCode: "8",
      },
      {
        name: "Kep",
        isoCode: "23",
      },
      {
        name: "Koh Kong",
        isoCode: "9",
      },
      {
        name: "Kratie",
        isoCode: "10",
      },
      {
        name: "Mondulkiri",
        isoCode: "11",
      },
      {
        name: "Oddar Meanchey",
        isoCode: "22",
      },
      {
        name: "Pailin",
        isoCode: "24",
      },
      {
        name: "Phnom Penh",
        isoCode: "12",
      },
      {
        name: "Preah Vihear",
        isoCode: "13",
      },
      {
        name: "Prey Veng",
        isoCode: "14",
      },
      {
        name: "Pursat",
        isoCode: "15",
      },
      {
        name: "Ratanakiri",
        isoCode: "16",
      },
      {
        name: "Siem Reap",
        isoCode: "17",
      },
      {
        name: "Sihanoukville",
        isoCode: "18",
      },
      {
        name: "Stung Treng",
        isoCode: "19",
      },
      {
        name: "Svay Rieng",
        isoCode: "20",
      },
      {
        name: "Takeo",
        isoCode: "21",
      },
    ],
  },
  {
    name: "Cameroon",
    isoCode: "CM",
    states: [
      {
        name: "Adamawa",
        isoCode: "AD",
      },
      {
        name: "Centre",
        isoCode: "CE",
      },
      {
        name: "East",
        isoCode: "ES",
      },
      {
        name: "Far North",
        isoCode: "EN",
      },
      {
        name: "Littoral",
        isoCode: "LT",
      },
      {
        name: "North",
        isoCode: "NO",
      },
      {
        name: "Northwest",
        isoCode: "NW",
      },
      {
        name: "South",
        isoCode: "SU",
      },
      {
        name: "Southwest",
        isoCode: "SW",
      },
      {
        name: "West",
        isoCode: "OU",
      },
    ],
  },
  {
    name: "Canada",
    isoCode: "CA",
    states: [
      {
        name: "Alberta",
        isoCode: "AB",
      },
      {
        name: "British Columbia",
        isoCode: "BC",
      },
      {
        name: "Manitoba",
        isoCode: "MB",
      },
      {
        name: "New Brunswick",
        isoCode: "NB",
      },
      {
        name: "Newfoundland and Labrador",
        isoCode: "NL",
      },
      {
        name: "Northwest Territories",
        isoCode: "NT",
      },
      {
        name: "Nova Scotia",
        isoCode: "NS",
      },
      {
        name: "Nunavut",
        isoCode: "NU",
      },
      {
        name: "Ontario",
        isoCode: "ON",
      },
      {
        name: "Prince Edward Island",
        isoCode: "PE",
      },
      {
        name: "Quebec",
        isoCode: "QC",
      },
      {
        name: "Saskatchewan",
        isoCode: "SK",
      },
      {
        name: "Yukon",
        isoCode: "YT",
      },
    ],
  },
  {
    name: "Cape Verde",
    isoCode: "CV",
    states: [
      {
        name: "Barlavento Islands",
        isoCode: "B",
      },
      {
        name: "Boa Vista",
        isoCode: "BV",
      },
      {
        name: "Brava",
        isoCode: "BR",
      },
      {
        name: "Maio Municipality",
        isoCode: "MA",
      },
      {
        name: "Mosteiros",
        isoCode: "MO",
      },
      {
        name: "Paul",
        isoCode: "PA",
      },
      {
        name: "Porto Novo",
        isoCode: "PN",
      },
      {
        name: "Praia",
        isoCode: "PR",
      },
      {
        name: "Ribeira Brava Municipality",
        isoCode: "RB",
      },
      {
        name: "Ribeira Grande",
        isoCode: "RG",
      },
      {
        name: "Ribeira Grande de Santiago",
        isoCode: "RS",
      },
      {
        name: "Sal",
        isoCode: "SL",
      },
      {
        name: "Santa Catarina",
        isoCode: "CA",
      },
      {
        name: "Santa Catarina do Fogo",
        isoCode: "CF",
      },
      {
        name: "Santa Cruz",
        isoCode: "CR",
      },
      {
        name: "São Domingos",
        isoCode: "SD",
      },
      {
        name: "São Filipe",
        isoCode: "SF",
      },
      {
        name: "São Lourenço dos Órgãos",
        isoCode: "SO",
      },
      {
        name: "São Miguel",
        isoCode: "SM",
      },
      {
        name: "São Vicente",
        isoCode: "SV",
      },
      {
        name: "Sotavento Islands",
        isoCode: "S",
      },
      {
        name: "Tarrafal",
        isoCode: "TA",
      },
      {
        name: "Tarrafal de São Nicolau",
        isoCode: "TS",
      },
    ],
  },
  {
    name: "Cayman Islands",
    isoCode: "KY",
    states: [],
  },
  {
    name: "Central African Republic",
    isoCode: "CF",
    states: [
      {
        name: "Bamingui-Bangoran Prefecture",
        isoCode: "BB",
      },
      {
        name: "Bangui",
        isoCode: "BGF",
      },
      {
        name: "Basse-Kotto Prefecture",
        isoCode: "BK",
      },
      {
        name: "Haut-Mbomou Prefecture",
        isoCode: "HM",
      },
      {
        name: "Haute-Kotto Prefecture",
        isoCode: "HK",
      },
      {
        name: "Kémo Prefecture",
        isoCode: "KG",
      },
      {
        name: "Lobaye Prefecture",
        isoCode: "LB",
      },
      {
        name: "Mambéré-Kadéï",
        isoCode: "HS",
      },
      {
        name: "Mbomou Prefecture",
        isoCode: "MB",
      },
      {
        name: "Nana-Grébizi Economic Prefecture",
        isoCode: "KB",
      },
      {
        name: "Nana-Mambéré Prefecture",
        isoCode: "NM",
      },
      {
        name: "Ombella-M'Poko Prefecture",
        isoCode: "MP",
      },
      {
        name: "Ouaka Prefecture",
        isoCode: "UK",
      },
      {
        name: "Ouham Prefecture",
        isoCode: "AC",
      },
      {
        name: "Ouham-Pendé Prefecture",
        isoCode: "OP",
      },
      {
        name: "Sangha-Mbaéré",
        isoCode: "SE",
      },
      {
        name: "Vakaga Prefecture",
        isoCode: "VK",
      },
    ],
  },
  {
    name: "Chad",
    isoCode: "TD",
    states: [
      {
        name: "Bahr el Gazel",
        isoCode: "BG",
      },
      {
        name: "Batha Region",
        isoCode: "BA",
      },
      {
        name: "Borkou",
        isoCode: "BO",
      },
      {
        name: "Ennedi Region",
        isoCode: "EN",
      },
      {
        name: "Ennedi-Est",
        isoCode: "EE",
      },
      {
        name: "Ennedi-Ouest",
        isoCode: "EO",
      },
      {
        name: "Guéra Region",
        isoCode: "GR",
      },
      {
        name: "Hadjer-Lamis",
        isoCode: "HL",
      },
      {
        name: "Kanem Region",
        isoCode: "KA",
      },
      {
        name: "Lac Region",
        isoCode: "LC",
      },
      {
        name: "Logone Occidental Region",
        isoCode: "LO",
      },
      {
        name: "Logone Oriental Region",
        isoCode: "LR",
      },
      {
        name: "Mandoul Region",
        isoCode: "MA",
      },
      {
        name: "Mayo-Kebbi Est Region",
        isoCode: "ME",
      },
      {
        name: "Mayo-Kebbi Ouest Region",
        isoCode: "MO",
      },
      {
        name: "Moyen-Chari Region",
        isoCode: "MC",
      },
      {
        name: "N'Djamena",
        isoCode: "ND",
      },
      {
        name: "Ouaddaï Region",
        isoCode: "OD",
      },
      {
        name: "Salamat Region",
        isoCode: "SA",
      },
      {
        name: "Sila Region",
        isoCode: "SI",
      },
      {
        name: "Tandjilé Region",
        isoCode: "TA",
      },
      {
        name: "Tibesti Region",
        isoCode: "TI",
      },
      {
        name: "Wadi Fira Region",
        isoCode: "WF",
      },
    ],
  },
  {
    name: "Chile",
    isoCode: "CL",
    states: [
      {
        name: "Aisén del General Carlos Ibañez del Campo",
        isoCode: "AI",
      },
      {
        name: "Antofagasta",
        isoCode: "AN",
      },
      {
        name: "Arica y Parinacota",
        isoCode: "AP",
      },
      {
        name: "Atacama",
        isoCode: "AT",
      },
      {
        name: "Biobío",
        isoCode: "BI",
      },
      {
        name: "Coquimbo",
        isoCode: "CO",
      },
      {
        name: "La Araucanía",
        isoCode: "AR",
      },
      {
        name: "Libertador General Bernardo O'Higgins",
        isoCode: "LI",
      },
      {
        name: "Los Lagos",
        isoCode: "LL",
      },
      {
        name: "Los Ríos",
        isoCode: "LR",
      },
      {
        name: "Magallanes y de la Antártica Chilena",
        isoCode: "MA",
      },
      {
        name: "Maule",
        isoCode: "ML",
      },
      {
        name: "Ñuble",
        isoCode: "NB",
      },
      {
        name: "Región Metropolitana de Santiago",
        isoCode: "RM",
      },
      {
        name: "Tarapacá",
        isoCode: "TA",
      },
      {
        name: "Valparaíso",
        isoCode: "VS",
      },
    ],
  },
  {
    name: "China",
    isoCode: "CN",
    states: [
      {
        name: "Anhui",
        isoCode: "AH",
      },
      {
        name: "Beijing",
        isoCode: "BJ",
      },
      {
        name: "Chongqing",
        isoCode: "CQ",
      },
      {
        name: "Fujian",
        isoCode: "FJ",
      },
      {
        name: "Gansu",
        isoCode: "GS",
      },
      {
        name: "Guangdong",
        isoCode: "GD",
      },
      {
        name: "Guangxi Zhuang",
        isoCode: "GX",
      },
      {
        name: "Guizhou",
        isoCode: "GZ",
      },
      {
        name: "Hainan",
        isoCode: "HI",
      },
      {
        name: "Hebei",
        isoCode: "HE",
      },
      {
        name: "Heilongjiang",
        isoCode: "HL",
      },
      {
        name: "Henan",
        isoCode: "HA",
      },
      {
        name: "Hong Kong SAR",
        isoCode: "HK",
      },
      {
        name: "Hubei",
        isoCode: "HB",
      },
      {
        name: "Hunan",
        isoCode: "HN",
      },
      {
        name: "Inner Mongolia",
        isoCode: "NM",
      },
      {
        name: "Jiangsu",
        isoCode: "JS",
      },
      {
        name: "Jiangxi",
        isoCode: "JX",
      },
      {
        name: "Jilin",
        isoCode: "JL",
      },
      {
        name: "Liaoning",
        isoCode: "LN",
      },
      {
        name: "Macau SAR",
        isoCode: "MO",
      },
      {
        name: "Ningxia Huizu",
        isoCode: "NX",
      },
      {
        name: "Qinghai",
        isoCode: "QH",
      },
      {
        name: "Shaanxi",
        isoCode: "SN",
      },
      {
        name: "Shandong",
        isoCode: "SD",
      },
      {
        name: "Shanghai",
        isoCode: "SH",
      },
      {
        name: "Shanxi",
        isoCode: "SX",
      },
      {
        name: "Sichuan",
        isoCode: "SC",
      },
      {
        name: "Taiwan",
        isoCode: "TW",
      },
      {
        name: "Tianjin",
        isoCode: "TJ",
      },
      {
        name: "Xinjiang",
        isoCode: "XJ",
      },
      {
        name: "Xizang",
        isoCode: "XZ",
      },
      {
        name: "Yunnan",
        isoCode: "YN",
      },
      {
        name: "Zhejiang",
        isoCode: "ZJ",
      },
    ],
  },
  {
    name: "Christmas Island",
    isoCode: "CX",
    states: [],
  },
  {
    name: "Cocos (Keeling) Islands",
    isoCode: "CC",
    states: [],
  },
  {
    name: "Colombia",
    isoCode: "CO",
    states: [
      {
        name: "Amazonas",
        isoCode: "AMA",
      },
      {
        name: "Antioquia",
        isoCode: "ANT",
      },
      {
        name: "Arauca",
        isoCode: "ARA",
      },
      {
        name: "Archipiélago de San Andrés, Providencia y Santa Catalina",
        isoCode: "SAP",
      },
      {
        name: "Atlántico",
        isoCode: "ATL",
      },
      {
        name: "Bogotá D.C.",
        isoCode: "DC",
      },
      {
        name: "Bolívar",
        isoCode: "BOL",
      },
      {
        name: "Boyacá",
        isoCode: "BOY",
      },
      {
        name: "Caldas",
        isoCode: "CAL",
      },
      {
        name: "Caquetá",
        isoCode: "CAQ",
      },
      {
        name: "Casanare",
        isoCode: "CAS",
      },
      {
        name: "Cauca",
        isoCode: "CAU",
      },
      {
        name: "Cesar",
        isoCode: "CES",
      },
      {
        name: "Chocó",
        isoCode: "CHO",
      },
      {
        name: "Córdoba",
        isoCode: "COR",
      },
      {
        name: "Cundinamarca",
        isoCode: "CUN",
      },
      {
        name: "Guainía",
        isoCode: "GUA",
      },
      {
        name: "Guaviare",
        isoCode: "GUV",
      },
      {
        name: "Huila",
        isoCode: "HUI",
      },
      {
        name: "La Guajira",
        isoCode: "LAG",
      },
      {
        name: "Magdalena",
        isoCode: "MAG",
      },
      {
        name: "Meta",
        isoCode: "MET",
      },
      {
        name: "Nariño",
        isoCode: "NAR",
      },
      {
        name: "Norte de Santander",
        isoCode: "NSA",
      },
      {
        name: "Putumayo",
        isoCode: "PUT",
      },
      {
        name: "Quindío",
        isoCode: "QUI",
      },
      {
        name: "Risaralda",
        isoCode: "RIS",
      },
      {
        name: "Santander",
        isoCode: "SAN",
      },
      {
        name: "Sucre",
        isoCode: "SUC",
      },
      {
        name: "Tolima",
        isoCode: "TOL",
      },
      {
        name: "Valle del Cauca",
        isoCode: "VAC",
      },
      {
        name: "Vaupés",
        isoCode: "VAU",
      },
      {
        name: "Vichada",
        isoCode: "VID",
      },
    ],
  },
  {
    name: "Comoros",
    isoCode: "KM",
    states: [
      {
        name: "Anjouan",
        isoCode: "A",
      },
      {
        name: "Grande Comore",
        isoCode: "G",
      },
      {
        name: "Mohéli",
        isoCode: "M",
      },
    ],
  },
  {
    name: "Congo",
    isoCode: "CG",
    states: [
      {
        name: "Bouenza Department",
        isoCode: "11",
      },
      {
        name: "Brazzaville",
        isoCode: "BZV",
      },
      {
        name: "Cuvette Department",
        isoCode: "8",
      },
      {
        name: "Cuvette-Ouest Department",
        isoCode: "15",
      },
      {
        name: "Kouilou Department",
        isoCode: "5",
      },
      {
        name: "Lékoumou Department",
        isoCode: "2",
      },
      {
        name: "Likouala Department",
        isoCode: "7",
      },
      {
        name: "Niari Department",
        isoCode: "9",
      },
      {
        name: "Plateaux Department",
        isoCode: "14",
      },
      {
        name: "Pointe-Noire",
        isoCode: "16",
      },
      {
        name: "Pool Department",
        isoCode: "12",
      },
      {
        name: "Sangha Department",
        isoCode: "13",
      },
    ],
  },
  {
    name: "Cook Islands",
    isoCode: "CK",
    states: [],
  },
  {
    name: "Costa Rica",
    isoCode: "CR",
    states: [
      {
        name: "Alajuela Province",
        isoCode: "A",
      },
      {
        name: "Guanacaste Province",
        isoCode: "G",
      },
      {
        name: "Heredia Province",
        isoCode: "H",
      },
      {
        name: "Limón Province",
        isoCode: "L",
      },
      {
        name: "Provincia de Cartago",
        isoCode: "C",
      },
      {
        name: "Puntarenas Province",
        isoCode: "P",
      },
      {
        name: "San José Province",
        isoCode: "SJ",
      },
    ],
  },
  {
    name: "Cote D'Ivoire (Ivory Coast)",
    isoCode: "CI",
    states: [
      {
        name: "Abidjan",
        isoCode: "AB",
      },
      {
        name: "Agnéby",
        isoCode: "16",
      },
      {
        name: "Bafing Region",
        isoCode: "17",
      },
      {
        name: "Bas-Sassandra District",
        isoCode: "BS",
      },
      {
        name: "Bas-Sassandra Region",
        isoCode: "09",
      },
      {
        name: "Comoé District",
        isoCode: "CM",
      },
      {
        name: "Denguélé District",
        isoCode: "DN",
      },
      {
        name: "Denguélé Region",
        isoCode: "10",
      },
      {
        name: "Dix-Huit Montagnes",
        isoCode: "06",
      },
      {
        name: "Fromager",
        isoCode: "18",
      },
      {
        name: "Gôh-Djiboua District",
        isoCode: "GD",
      },
      {
        name: "Haut-Sassandra",
        isoCode: "02",
      },
      {
        name: "Lacs District",
        isoCode: "LC",
      },
      {
        name: "Lacs Region",
        isoCode: "07",
      },
      {
        name: "Lagunes District",
        isoCode: "LG",
      },
      {
        name: "Lagunes region",
        isoCode: "01",
      },
      {
        name: "Marahoué Region",
        isoCode: "12",
      },
      {
        name: "Montagnes District",
        isoCode: "MG",
      },
      {
        name: "Moyen-Cavally",
        isoCode: "19",
      },
      {
        name: "Moyen-Comoé",
        isoCode: "05",
      },
      {
        name: "N'zi-Comoé",
        isoCode: "11",
      },
      {
        name: "Sassandra-Marahoué District",
        isoCode: "SM",
      },
      {
        name: "Savanes Region",
        isoCode: "03",
      },
      {
        name: "Sud-Bandama",
        isoCode: "15",
      },
      {
        name: "Sud-Comoé",
        isoCode: "13",
      },
      {
        name: "Vallée du Bandama District",
        isoCode: "VB",
      },
      {
        name: "Vallée du Bandama Region",
        isoCode: "04",
      },
      {
        name: "Woroba District",
        isoCode: "WR",
      },
      {
        name: "Worodougou",
        isoCode: "14",
      },
      {
        name: "Yamoussoukro",
        isoCode: "YM",
      },
      {
        name: "Zanzan Region",
        isoCode: "ZZ",
      },
    ],
  },
  {
    name: "Croatia",
    isoCode: "HR",
    states: [
      {
        name: "Bjelovar-Bilogora",
        isoCode: "07",
      },
      {
        name: "Brod-Posavina",
        isoCode: "12",
      },
      {
        name: "Dubrovnik-Neretva",
        isoCode: "19",
      },
      {
        name: "Istria",
        isoCode: "18",
      },
      {
        name: "Karlovac",
        isoCode: "04",
      },
      {
        name: "Koprivnica-Križevci",
        isoCode: "06",
      },
      {
        name: "Krapina-Zagorje",
        isoCode: "02",
      },
      {
        name: "Lika-Senj",
        isoCode: "09",
      },
      {
        name: "Međimurje",
        isoCode: "20",
      },
      {
        name: "Osijek-Baranja",
        isoCode: "14",
      },
      {
        name: "Požega-Slavonia",
        isoCode: "11",
      },
      {
        name: "Primorje-Gorski Kotar",
        isoCode: "08",
      },
      {
        name: "Šibenik-Knin",
        isoCode: "15",
      },
      {
        name: "Sisak-Moslavina",
        isoCode: "03",
      },
      {
        name: "Split-Dalmatia",
        isoCode: "17",
      },
      {
        name: "Varaždin",
        isoCode: "05",
      },
      {
        name: "Virovitica-Podravina",
        isoCode: "10",
      },
      {
        name: "Vukovar-Syrmia",
        isoCode: "16",
      },
      {
        name: "Zadar",
        isoCode: "13",
      },
      {
        name: "Zagreb",
        isoCode: "01",
      },
      {
        name: "Zagreb",
        isoCode: "21",
      },
    ],
  },
  {
    name: "Cuba",
    isoCode: "CU",
    states: [
      {
        name: "Artemisa Province",
        isoCode: "15",
      },
      {
        name: "Camagüey Province",
        isoCode: "09",
      },
      {
        name: "Ciego de Ávila Province",
        isoCode: "08",
      },
      {
        name: "Cienfuegos Province",
        isoCode: "06",
      },
      {
        name: "Granma Province",
        isoCode: "12",
      },
      {
        name: "Guantánamo Province",
        isoCode: "14",
      },
      {
        name: "Havana Province",
        isoCode: "03",
      },
      {
        name: "Holguín Province",
        isoCode: "11",
      },
      {
        name: "Isla de la Juventud",
        isoCode: "99",
      },
      {
        name: "Las Tunas Province",
        isoCode: "10",
      },
      {
        name: "Matanzas Province",
        isoCode: "04",
      },
      {
        name: "Mayabeque Province",
        isoCode: "16",
      },
      {
        name: "Pinar del Río Province",
        isoCode: "01",
      },
      {
        name: "Sancti Spíritus Province",
        isoCode: "07",
      },
      {
        name: "Santiago de Cuba Province",
        isoCode: "13",
      },
      {
        name: "Villa Clara Province",
        isoCode: "05",
      },
    ],
  },
  {
    name: "Curaçao",
    isoCode: "CW",
    states: [],
  },
  {
    name: "Cyprus",
    isoCode: "CY",
    states: [
      {
        name: "Famagusta District (Mağusa)",
        isoCode: "04",
      },
      {
        name: "Kyrenia District (Keryneia)",
        isoCode: "06",
      },
      {
        name: "Larnaca District (Larnaka)",
        isoCode: "03",
      },
      {
        name: "Limassol District (Leymasun)",
        isoCode: "02",
      },
      {
        name: "Nicosia District (Lefkoşa)",
        isoCode: "01",
      },
      {
        name: "Paphos District (Pafos)",
        isoCode: "05",
      },
    ],
  },
  {
    name: "Czech Republic",
    isoCode: "CZ",
    states: [
      {
        name: "Benešov",
        isoCode: "201",
      },
      {
        name: "Beroun",
        isoCode: "202",
      },
      {
        name: "Blansko",
        isoCode: "641",
      },
      {
        name: "Břeclav",
        isoCode: "644",
      },
      {
        name: "Brno-město",
        isoCode: "642",
      },
      {
        name: "Brno-venkov",
        isoCode: "643",
      },
      {
        name: "Bruntál",
        isoCode: "801",
      },
      {
        name: "Česká Lípa",
        isoCode: "511",
      },
      {
        name: "České Budějovice",
        isoCode: "311",
      },
      {
        name: "Český Krumlov",
        isoCode: "312",
      },
      {
        name: "Cheb",
        isoCode: "411",
      },
      {
        name: "Chomutov",
        isoCode: "422",
      },
      {
        name: "Chrudim",
        isoCode: "531",
      },
      {
        name: "Děčín",
        isoCode: "421",
      },
      {
        name: "Domažlice",
        isoCode: "321",
      },
      {
        name: "Frýdek-Místek",
        isoCode: "802",
      },
      {
        name: "Havlíčkův Brod",
        isoCode: "631",
      },
      {
        name: "Hodonín",
        isoCode: "645",
      },
      {
        name: "Hradec Králové",
        isoCode: "521",
      },
      {
        name: "Jablonec nad Nisou",
        isoCode: "512",
      },
      {
        name: "Jeseník",
        isoCode: "711",
      },
      {
        name: "Jičín",
        isoCode: "522",
      },
      {
        name: "Jihlava",
        isoCode: "632",
      },
      {
        name: "Jihočeský kraj",
        isoCode: "31",
      },
      {
        name: "Jihomoravský kraj",
        isoCode: "64",
      },
      {
        name: "Jindřichův Hradec",
        isoCode: "313",
      },
      {
        name: "Karlovarský kraj",
        isoCode: "41",
      },
      {
        name: "Karlovy Vary",
        isoCode: "412",
      },
      {
        name: "Karviná",
        isoCode: "803",
      },
      {
        name: "Kladno",
        isoCode: "203",
      },
      {
        name: "Klatovy",
        isoCode: "322",
      },
      {
        name: "Kolín",
        isoCode: "204",
      },
      {
        name: "Kraj Vysočina",
        isoCode: "63",
      },
      {
        name: "Královéhradecký kraj",
        isoCode: "52",
      },
      {
        name: "Kroměříž",
        isoCode: "721",
      },
      {
        name: "Kutná Hora",
        isoCode: "205",
      },
      {
        name: "Liberec",
        isoCode: "513",
      },
      {
        name: "Liberecký kraj",
        isoCode: "51",
      },
      {
        name: "Litoměřice",
        isoCode: "423",
      },
      {
        name: "Louny",
        isoCode: "424",
      },
      {
        name: "Mělník",
        isoCode: "206",
      },
      {
        name: "Mladá Boleslav",
        isoCode: "207",
      },
      {
        name: "Moravskoslezský kraj",
        isoCode: "80",
      },
      {
        name: "Most",
        isoCode: "425",
      },
      {
        name: "Náchod",
        isoCode: "523",
      },
      {
        name: "Nový Jičín",
        isoCode: "804",
      },
      {
        name: "Nymburk",
        isoCode: "208",
      },
      {
        name: "Olomouc",
        isoCode: "712",
      },
      {
        name: "Olomoucký kraj",
        isoCode: "71",
      },
      {
        name: "Opava",
        isoCode: "805",
      },
      {
        name: "Ostrava-město",
        isoCode: "806",
      },
      {
        name: "Pardubice",
        isoCode: "532",
      },
      {
        name: "Pardubický kraj",
        isoCode: "53",
      },
      {
        name: "Pelhřimov",
        isoCode: "633",
      },
      {
        name: "Písek",
        isoCode: "314",
      },
      {
        name: "Plzeň-jih",
        isoCode: "324",
      },
      {
        name: "Plzeň-město",
        isoCode: "323",
      },
      {
        name: "Plzeň-sever",
        isoCode: "325",
      },
      {
        name: "Plzeňský kraj",
        isoCode: "32",
      },
      {
        name: "Prachatice",
        isoCode: "315",
      },
      {
        name: "Praha-východ",
        isoCode: "209",
      },
      {
        name: "Praha-západ",
        isoCode: "20A",
      },
      {
        name: "Praha, Hlavní město",
        isoCode: "10",
      },
      {
        name: "Přerov",
        isoCode: "714",
      },
      {
        name: "Příbram",
        isoCode: "20B",
      },
      {
        name: "Prostějov",
        isoCode: "713",
      },
      {
        name: "Rakovník",
        isoCode: "20C",
      },
      {
        name: "Rokycany",
        isoCode: "326",
      },
      {
        name: "Rychnov nad Kněžnou",
        isoCode: "524",
      },
      {
        name: "Semily",
        isoCode: "514",
      },
      {
        name: "Sokolov",
        isoCode: "413",
      },
      {
        name: "Strakonice",
        isoCode: "316",
      },
      {
        name: "Středočeský kraj",
        isoCode: "20",
      },
      {
        name: "Šumperk",
        isoCode: "715",
      },
      {
        name: "Svitavy",
        isoCode: "533",
      },
      {
        name: "Tábor",
        isoCode: "317",
      },
      {
        name: "Tachov",
        isoCode: "327",
      },
      {
        name: "Teplice",
        isoCode: "426",
      },
      {
        name: "Třebíč",
        isoCode: "634",
      },
      {
        name: "Trutnov",
        isoCode: "525",
      },
      {
        name: "Uherské Hradiště",
        isoCode: "722",
      },
      {
        name: "Ústecký kraj",
        isoCode: "42",
      },
      {
        name: "Ústí nad Labem",
        isoCode: "427",
      },
      {
        name: "Ústí nad Orlicí",
        isoCode: "534",
      },
      {
        name: "Vsetín",
        isoCode: "723",
      },
      {
        name: "Vyškov",
        isoCode: "646",
      },
      {
        name: "Žďár nad Sázavou",
        isoCode: "635",
      },
      {
        name: "Zlín",
        isoCode: "724",
      },
      {
        name: "Zlínský kraj",
        isoCode: "72",
      },
      {
        name: "Znojmo",
        isoCode: "647",
      },
    ],
  },
  {
    name: "Democratic Republic of the Congo",
    isoCode: "CD",
    states: [
      {
        name: "Bas-Uélé",
        isoCode: "BU",
      },
      {
        name: "Équateur",
        isoCode: "EQ",
      },
      {
        name: "Haut-Katanga",
        isoCode: "HK",
      },
      {
        name: "Haut-Lomami",
        isoCode: "HL",
      },
      {
        name: "Haut-Uélé",
        isoCode: "HU",
      },
      {
        name: "Ituri",
        isoCode: "IT",
      },
      {
        name: "Kasaï",
        isoCode: "KS",
      },
      {
        name: "Kasaï Central",
        isoCode: "KC",
      },
      {
        name: "Kasaï Oriental",
        isoCode: "KE",
      },
      {
        name: "Kinshasa",
        isoCode: "KN",
      },
      {
        name: "Kongo Central",
        isoCode: "BC",
      },
      {
        name: "Kwango",
        isoCode: "KG",
      },
      {
        name: "Kwilu",
        isoCode: "KL",
      },
      {
        name: "Lomami",
        isoCode: "LO",
      },
      {
        name: "Lualaba",
        isoCode: "LU",
      },
      {
        name: "Mai-Ndombe",
        isoCode: "MN",
      },
      {
        name: "Maniema",
        isoCode: "MA",
      },
      {
        name: "Mongala",
        isoCode: "MO",
      },
      {
        name: "Nord-Kivu",
        isoCode: "NK",
      },
      {
        name: "Nord-Ubangi",
        isoCode: "NU",
      },
      {
        name: "Sankuru",
        isoCode: "SA",
      },
      {
        name: "Sud-Kivu",
        isoCode: "SK",
      },
      {
        name: "Sud-Ubangi",
        isoCode: "SU",
      },
      {
        name: "Tanganyika",
        isoCode: "TA",
      },
      {
        name: "Tshopo",
        isoCode: "TO",
      },
      {
        name: "Tshuapa",
        isoCode: "TU",
      },
    ],
  },
  {
    name: "Denmark",
    isoCode: "DK",
    states: [
      {
        name: "Capital Region of Denmark",
        isoCode: "84",
      },
      {
        name: "Central Denmark Region",
        isoCode: "82",
      },
      {
        name: "North Denmark Region",
        isoCode: "81",
      },
      {
        name: "Region of Southern Denmark",
        isoCode: "83",
      },
      {
        name: "Region Zealand",
        isoCode: "85",
      },
    ],
  },
  {
    name: "Djibouti",
    isoCode: "DJ",
    states: [
      {
        name: "Ali Sabieh Region",
        isoCode: "AS",
      },
      {
        name: "Arta Region",
        isoCode: "AR",
      },
      {
        name: "Dikhil Region",
        isoCode: "DI",
      },
      {
        name: "Djibouti",
        isoCode: "DJ",
      },
      {
        name: "Obock Region",
        isoCode: "OB",
      },
      {
        name: "Tadjourah Region",
        isoCode: "TA",
      },
    ],
  },
  {
    name: "Dominica",
    isoCode: "DM",
    states: [
      {
        name: "Saint Andrew Parish",
        isoCode: "02",
      },
      {
        name: "Saint David Parish",
        isoCode: "03",
      },
      {
        name: "Saint George Parish",
        isoCode: "04",
      },
      {
        name: "Saint John Parish",
        isoCode: "05",
      },
      {
        name: "Saint Joseph Parish",
        isoCode: "06",
      },
      {
        name: "Saint Luke Parish",
        isoCode: "07",
      },
      {
        name: "Saint Mark Parish",
        isoCode: "08",
      },
      {
        name: "Saint Patrick Parish",
        isoCode: "09",
      },
      {
        name: "Saint Paul Parish",
        isoCode: "10",
      },
      {
        name: "Saint Peter Parish",
        isoCode: "11",
      },
    ],
  },
  {
    name: "Dominican Republic",
    isoCode: "DO",
    states: [
      {
        name: "Azua Province",
        isoCode: "02",
      },
      {
        name: "Baoruco Province",
        isoCode: "03",
      },
      {
        name: "Barahona Province",
        isoCode: "04",
      },
      {
        name: "Dajabón Province",
        isoCode: "05",
      },
      {
        name: "Distrito Nacional",
        isoCode: "01",
      },
      {
        name: "Duarte Province",
        isoCode: "06",
      },
      {
        name: "El Seibo Province",
        isoCode: "08",
      },
      {
        name: "Espaillat Province",
        isoCode: "09",
      },
      {
        name: "Hato Mayor Province",
        isoCode: "30",
      },
      {
        name: "Hermanas Mirabal Province",
        isoCode: "19",
      },
      {
        name: "Independencia",
        isoCode: "10",
      },
      {
        name: "La Altagracia Province",
        isoCode: "11",
      },
      {
        name: "La Romana Province",
        isoCode: "12",
      },
      {
        name: "La Vega Province",
        isoCode: "13",
      },
      {
        name: "María Trinidad Sánchez Province",
        isoCode: "14",
      },
      {
        name: "Monseñor Nouel Province",
        isoCode: "28",
      },
      {
        name: "Monte Cristi Province",
        isoCode: "15",
      },
      {
        name: "Monte Plata Province",
        isoCode: "29",
      },
      {
        name: "Pedernales Province",
        isoCode: "16",
      },
      {
        name: "Peravia Province",
        isoCode: "17",
      },
      {
        name: "Puerto Plata Province",
        isoCode: "18",
      },
      {
        name: "Samaná Province",
        isoCode: "20",
      },
      {
        name: "San Cristóbal Province",
        isoCode: "21",
      },
      {
        name: "San José de Ocoa Province",
        isoCode: "31",
      },
      {
        name: "San Juan Province",
        isoCode: "22",
      },
      {
        name: "San Pedro de Macorís",
        isoCode: "23",
      },
      {
        name: "Sánchez Ramírez Province",
        isoCode: "24",
      },
      {
        name: "Santiago Province",
        isoCode: "25",
      },
      {
        name: "Santiago Rodríguez Province",
        isoCode: "26",
      },
      {
        name: "Santo Domingo Province",
        isoCode: "32",
      },
      {
        name: "Valverde Province",
        isoCode: "27",
      },
    ],
  },
  {
    name: "East Timor",
    isoCode: "TL",
    states: [
      {
        name: "Aileu municipality",
        isoCode: "AL",
      },
      {
        name: "Ainaro Municipality",
        isoCode: "AN",
      },
      {
        name: "Baucau Municipality",
        isoCode: "BA",
      },
      {
        name: "Bobonaro Municipality",
        isoCode: "BO",
      },
      {
        name: "Cova Lima Municipality",
        isoCode: "CO",
      },
      {
        name: "Dili municipality",
        isoCode: "DI",
      },
      {
        name: "Ermera District",
        isoCode: "ER",
      },
      {
        name: "Lautém Municipality",
        isoCode: "LA",
      },
      {
        name: "Liquiçá Municipality",
        isoCode: "LI",
      },
      {
        name: "Manatuto District",
        isoCode: "MT",
      },
      {
        name: "Manufahi Municipality",
        isoCode: "MF",
      },
      {
        name: "Viqueque Municipality",
        isoCode: "VI",
      },
    ],
  },
  {
    name: "Ecuador",
    isoCode: "EC",
    states: [
      {
        name: "Azuay",
        isoCode: "A",
      },
      {
        name: "Bolívar",
        isoCode: "B",
      },
      {
        name: "Cañar",
        isoCode: "F",
      },
      {
        name: "Carchi",
        isoCode: "C",
      },
      {
        name: "Chimborazo",
        isoCode: "H",
      },
      {
        name: "Cotopaxi",
        isoCode: "X",
      },
      {
        name: "El Oro",
        isoCode: "O",
      },
      {
        name: "Esmeraldas",
        isoCode: "E",
      },
      {
        name: "Galápagos",
        isoCode: "W",
      },
      {
        name: "Guayas",
        isoCode: "G",
      },
      {
        name: "Imbabura",
        isoCode: "I",
      },
      {
        name: "Loja",
        isoCode: "L",
      },
      {
        name: "Los Ríos",
        isoCode: "R",
      },
      {
        name: "Manabí",
        isoCode: "M",
      },
      {
        name: "Morona-Santiago",
        isoCode: "S",
      },
      {
        name: "Napo",
        isoCode: "N",
      },
      {
        name: "Orellana",
        isoCode: "D",
      },
      {
        name: "Pastaza",
        isoCode: "Y",
      },
      {
        name: "Pichincha",
        isoCode: "P",
      },
      {
        name: "Santa Elena",
        isoCode: "SE",
      },
      {
        name: "Santo Domingo de los Tsáchilas",
        isoCode: "SD",
      },
      {
        name: "Sucumbíos",
        isoCode: "U",
      },
      {
        name: "Tungurahua",
        isoCode: "T",
      },
      {
        name: "Zamora Chinchipe",
        isoCode: "Z",
      },
    ],
  },
  {
    name: "Egypt",
    isoCode: "EG",
    states: [
      {
        name: "Alexandria",
        isoCode: "ALX",
      },
      {
        name: "Aswan",
        isoCode: "ASN",
      },
      {
        name: "Asyut",
        isoCode: "AST",
      },
      {
        name: "Beheira",
        isoCode: "BH",
      },
      {
        name: "Beni Suef",
        isoCode: "BNS",
      },
      {
        name: "Cairo",
        isoCode: "C",
      },
      {
        name: "Dakahlia",
        isoCode: "DK",
      },
      {
        name: "Damietta",
        isoCode: "DT",
      },
      {
        name: "Faiyum",
        isoCode: "FYM",
      },
      {
        name: "Gharbia",
        isoCode: "GH",
      },
      {
        name: "Giza",
        isoCode: "GZ",
      },
      {
        name: "Ismailia",
        isoCode: "IS",
      },
      {
        name: "Kafr el-Sheikh",
        isoCode: "KFS",
      },
      {
        name: "Luxor",
        isoCode: "LX",
      },
      {
        name: "Matrouh",
        isoCode: "MT",
      },
      {
        name: "Minya",
        isoCode: "MN",
      },
      {
        name: "Monufia",
        isoCode: "MNF",
      },
      {
        name: "New Valley",
        isoCode: "WAD",
      },
      {
        name: "North Sinai",
        isoCode: "SIN",
      },
      {
        name: "Port Said",
        isoCode: "PTS",
      },
      {
        name: "Qalyubia",
        isoCode: "KB",
      },
      {
        name: "Qena",
        isoCode: "KN",
      },
      {
        name: "Red Sea",
        isoCode: "BA",
      },
      {
        name: "Sharqia",
        isoCode: "SHR",
      },
      {
        name: "Sohag",
        isoCode: "SHG",
      },
      {
        name: "South Sinai",
        isoCode: "JS",
      },
      {
        name: "Suez",
        isoCode: "SUZ",
      },
    ],
  },
  {
    name: "El Salvador",
    isoCode: "SV",
    states: [
      {
        name: "Ahuachapán Department",
        isoCode: "AH",
      },
      {
        name: "Cabañas Department",
        isoCode: "CA",
      },
      {
        name: "Chalatenango Department",
        isoCode: "CH",
      },
      {
        name: "Cuscatlán Department",
        isoCode: "CU",
      },
      {
        name: "La Libertad Department",
        isoCode: "LI",
      },
      {
        name: "La Paz Department",
        isoCode: "PA",
      },
      {
        name: "La Unión Department",
        isoCode: "UN",
      },
      {
        name: "Morazán Department",
        isoCode: "MO",
      },
      {
        name: "San Miguel Department",
        isoCode: "SM",
      },
      {
        name: "San Salvador Department",
        isoCode: "SS",
      },
      {
        name: "San Vicente Department",
        isoCode: "SV",
      },
      {
        name: "Santa Ana Department",
        isoCode: "SA",
      },
      {
        name: "Sonsonate Department",
        isoCode: "SO",
      },
      {
        name: "Usulután Department",
        isoCode: "US",
      },
    ],
  },
  {
    name: "Equatorial Guinea",
    isoCode: "GQ",
    states: [
      {
        name: "Annobón Province",
        isoCode: "AN",
      },
      {
        name: "Bioko Norte Province",
        isoCode: "BN",
      },
      {
        name: "Bioko Sur Province",
        isoCode: "BS",
      },
      {
        name: "Centro Sur Province",
        isoCode: "CS",
      },
      {
        name: "Insular Region",
        isoCode: "I",
      },
      {
        name: "Kié-Ntem Province",
        isoCode: "KN",
      },
      {
        name: "Litoral Province",
        isoCode: "LI",
      },
      {
        name: "Río Muni",
        isoCode: "C",
      },
      {
        name: "Wele-Nzas Province",
        isoCode: "WN",
      },
    ],
  },
  {
    name: "Eritrea",
    isoCode: "ER",
    states: [
      {
        name: "Anseba Region",
        isoCode: "AN",
      },
      {
        name: "Debub Region",
        isoCode: "DU",
      },
      {
        name: "Gash-Barka Region",
        isoCode: "GB",
      },
      {
        name: "Maekel Region",
        isoCode: "MA",
      },
      {
        name: "Northern Red Sea Region",
        isoCode: "SK",
      },
      {
        name: "Southern Red Sea Region",
        isoCode: "DK",
      },
    ],
  },
  {
    name: "Estonia",
    isoCode: "EE",
    states: [
      {
        name: "Harju County",
        isoCode: "37",
      },
      {
        name: "Hiiu County",
        isoCode: "39",
      },
      {
        name: "Ida-Viru County",
        isoCode: "44",
      },
      {
        name: "Järva County",
        isoCode: "51",
      },
      {
        name: "Jõgeva County",
        isoCode: "49",
      },
      {
        name: "Lääne County",
        isoCode: "57",
      },
      {
        name: "Lääne-Viru County",
        isoCode: "59",
      },
      {
        name: "Pärnu County",
        isoCode: "67",
      },
      {
        name: "Põlva County",
        isoCode: "65",
      },
      {
        name: "Rapla County",
        isoCode: "70",
      },
      {
        name: "Saare County",
        isoCode: "74",
      },
      {
        name: "Tartu County",
        isoCode: "78",
      },
      {
        name: "Valga County",
        isoCode: "82",
      },
      {
        name: "Viljandi County",
        isoCode: "84",
      },
      {
        name: "Võru County",
        isoCode: "86",
      },
    ],
  },
  {
    name: "Ethiopia",
    isoCode: "ET",
    states: [
      {
        name: "Addis Ababa",
        isoCode: "AA",
      },
      {
        name: "Afar Region",
        isoCode: "AF",
      },
      {
        name: "Amhara Region",
        isoCode: "AM",
      },
      {
        name: "Benishangul-Gumuz Region",
        isoCode: "BE",
      },
      {
        name: "Dire Dawa",
        isoCode: "DD",
      },
      {
        name: "Gambela Region",
        isoCode: "GA",
      },
      {
        name: "Harari Region",
        isoCode: "HA",
      },
      {
        name: "Oromia Region",
        isoCode: "OR",
      },
      {
        name: "Somali Region",
        isoCode: "SO",
      },
      {
        name: "Southern Nations, Nationalities, and Peoples' Region",
        isoCode: "SN",
      },
      {
        name: "Tigray Region",
        isoCode: "TI",
      },
    ],
  },
  {
    name: "Falkland Islands",
    isoCode: "FK",
    states: [],
  },
  {
    name: "Faroe Islands",
    isoCode: "FO",
    states: [],
  },
  {
    name: "Fiji Islands",
    isoCode: "FJ",
    states: [
      {
        name: "Ba",
        isoCode: "01",
      },
      {
        name: "Bua",
        isoCode: "02",
      },
      {
        name: "Cakaudrove",
        isoCode: "03",
      },
      {
        name: "Central Division",
        isoCode: "C",
      },
      {
        name: "Eastern Division",
        isoCode: "E",
      },
      {
        name: "Kadavu",
        isoCode: "04",
      },
      {
        name: "Lau",
        isoCode: "05",
      },
      {
        name: "Lomaiviti",
        isoCode: "06",
      },
      {
        name: "Macuata",
        isoCode: "07",
      },
      {
        name: "Nadroga-Navosa",
        isoCode: "08",
      },
      {
        name: "Naitasiri",
        isoCode: "09",
      },
      {
        name: "Namosi",
        isoCode: "10",
      },
      {
        name: "Northern Division",
        isoCode: "N",
      },
      {
        name: "Ra",
        isoCode: "11",
      },
      {
        name: "Rewa",
        isoCode: "12",
      },
      {
        name: "Rotuma",
        isoCode: "R",
      },
      {
        name: "Serua",
        isoCode: "13",
      },
      {
        name: "Tailevu",
        isoCode: "14",
      },
      {
        name: "Western Division",
        isoCode: "W",
      },
    ],
  },
  {
    name: "Finland",
    isoCode: "FI",
    states: [
      {
        name: "Åland Islands",
        isoCode: "01",
      },
      {
        name: "Central Finland",
        isoCode: "08",
      },
      {
        name: "Central Ostrobothnia",
        isoCode: "07",
      },
      {
        name: "Eastern Finland Province",
        isoCode: "IS",
      },
      {
        name: "Finland Proper",
        isoCode: "19",
      },
      {
        name: "Kainuu",
        isoCode: "05",
      },
      {
        name: "Kymenlaakso",
        isoCode: "09",
      },
      {
        name: "Lapland",
        isoCode: "LL",
      },
      {
        name: "North Karelia",
        isoCode: "13",
      },
      {
        name: "Northern Ostrobothnia",
        isoCode: "14",
      },
      {
        name: "Northern Savonia",
        isoCode: "15",
      },
      {
        name: "Ostrobothnia",
        isoCode: "12",
      },
      {
        name: "Oulu Province",
        isoCode: "OL",
      },
      {
        name: "Päijänne Tavastia",
        isoCode: "16",
      },
      {
        name: "Pirkanmaa",
        isoCode: "11",
      },
      {
        name: "Satakunta",
        isoCode: "17",
      },
      {
        name: "South Karelia",
        isoCode: "02",
      },
      {
        name: "Southern Ostrobothnia",
        isoCode: "03",
      },
      {
        name: "Southern Savonia",
        isoCode: "04",
      },
      {
        name: "Tavastia Proper",
        isoCode: "06",
      },
      {
        name: "Uusimaa",
        isoCode: "18",
      },
    ],
  },
  {
    name: "France",
    isoCode: "FR",
    states: [
      {
        name: "Ain",
        isoCode: "01",
      },
      {
        name: "Aisne",
        isoCode: "02",
      },
      {
        name: "Allier",
        isoCode: "03",
      },
      {
        name: "Alpes-de-Haute-Provence",
        isoCode: "04",
      },
      {
        name: "Alpes-Maritimes",
        isoCode: "06",
      },
      {
        name: "Alsace",
        isoCode: "6AE",
      },
      {
        name: "Ardèche",
        isoCode: "07",
      },
      {
        name: "Ardennes",
        isoCode: "08",
      },
      {
        name: "Ariège",
        isoCode: "09",
      },
      {
        name: "Aube",
        isoCode: "10",
      },
      {
        name: "Aude",
        isoCode: "11",
      },
      {
        name: "Auvergne-Rhône-Alpes",
        isoCode: "ARA",
      },
      {
        name: "Aveyron",
        isoCode: "12",
      },
      {
        name: "Bas-Rhin",
        isoCode: "67",
      },
      {
        name: "Bouches-du-Rhône",
        isoCode: "13",
      },
      {
        name: "Bourgogne-Franche-Comté",
        isoCode: "BFC",
      },
      {
        name: "Bretagne",
        isoCode: "BRE",
      },
      {
        name: "Calvados",
        isoCode: "14",
      },
      {
        name: "Cantal",
        isoCode: "15",
      },
      {
        name: "Centre-Val de Loire",
        isoCode: "CVL",
      },
      {
        name: "Charente",
        isoCode: "16",
      },
      {
        name: "Charente-Maritime",
        isoCode: "17",
      },
      {
        name: "Cher",
        isoCode: "18",
      },
      {
        name: "Clipperton",
        isoCode: "CP",
      },
      {
        name: "Corrèze",
        isoCode: "19",
      },
      {
        name: "Corse",
        isoCode: "20R",
      },
      {
        name: "Corse-du-Sud",
        isoCode: "2A",
      },
      {
        name: "Côte-d'Or",
        isoCode: "21",
      },
      {
        name: "Côtes-d'Armor",
        isoCode: "22",
      },
      {
        name: "Creuse",
        isoCode: "23",
      },
      {
        name: "Deux-Sèvres",
        isoCode: "79",
      },
      {
        name: "Dordogne",
        isoCode: "24",
      },
      {
        name: "Doubs",
        isoCode: "25",
      },
      {
        name: "Drôme",
        isoCode: "26",
      },
      {
        name: "Essonne",
        isoCode: "91",
      },
      {
        name: "Eure",
        isoCode: "27",
      },
      {
        name: "Eure-et-Loir",
        isoCode: "28",
      },
      {
        name: "Finistère",
        isoCode: "29",
      },
      {
        name: "French Guiana",
        isoCode: "973",
      },
      {
        name: "French Polynesia",
        isoCode: "PF",
      },
      {
        name: "French Southern and Antarctic Lands",
        isoCode: "TF",
      },
      {
        name: "Gard",
        isoCode: "30",
      },
      {
        name: "Gers",
        isoCode: "32",
      },
      {
        name: "Gironde",
        isoCode: "33",
      },
      {
        name: "Grand-Est",
        isoCode: "GES",
      },
      {
        name: "Guadeloupe",
        isoCode: "971",
      },
      {
        name: "Haut-Rhin",
        isoCode: "68",
      },
      {
        name: "Haute-Corse",
        isoCode: "2B",
      },
      {
        name: "Haute-Garonne",
        isoCode: "31",
      },
      {
        name: "Haute-Loire",
        isoCode: "43",
      },
      {
        name: "Haute-Marne",
        isoCode: "52",
      },
      {
        name: "Haute-Saône",
        isoCode: "70",
      },
      {
        name: "Haute-Savoie",
        isoCode: "74",
      },
      {
        name: "Haute-Vienne",
        isoCode: "87",
      },
      {
        name: "Hautes-Alpes",
        isoCode: "05",
      },
      {
        name: "Hautes-Pyrénées",
        isoCode: "65",
      },
      {
        name: "Hauts-de-France",
        isoCode: "HDF",
      },
      {
        name: "Hauts-de-Seine",
        isoCode: "92",
      },
      {
        name: "Hérault",
        isoCode: "34",
      },
      {
        name: "Île-de-France",
        isoCode: "IDF",
      },
      {
        name: "Ille-et-Vilaine",
        isoCode: "35",
      },
      {
        name: "Indre",
        isoCode: "36",
      },
      {
        name: "Indre-et-Loire",
        isoCode: "37",
      },
      {
        name: "Isère",
        isoCode: "38",
      },
      {
        name: "Jura",
        isoCode: "39",
      },
      {
        name: "La Réunion",
        isoCode: "974",
      },
      {
        name: "Landes",
        isoCode: "40",
      },
      {
        name: "Loir-et-Cher",
        isoCode: "41",
      },
      {
        name: "Loire",
        isoCode: "42",
      },
      {
        name: "Loire-Atlantique",
        isoCode: "44",
      },
      {
        name: "Loiret",
        isoCode: "45",
      },
      {
        name: "Lot",
        isoCode: "46",
      },
      {
        name: "Lot-et-Garonne",
        isoCode: "47",
      },
      {
        name: "Lozère",
        isoCode: "48",
      },
      {
        name: "Maine-et-Loire",
        isoCode: "49",
      },
      {
        name: "Manche",
        isoCode: "50",
      },
      {
        name: "Marne",
        isoCode: "51",
      },
      {
        name: "Martinique",
        isoCode: "972",
      },
      {
        name: "Mayenne",
        isoCode: "53",
      },
      {
        name: "Mayotte",
        isoCode: "976",
      },
      {
        name: "Métropole de Lyon",
        isoCode: "69M",
      },
      {
        name: "Meurthe-et-Moselle",
        isoCode: "54",
      },
      {
        name: "Meuse",
        isoCode: "55",
      },
      {
        name: "Morbihan",
        isoCode: "56",
      },
      {
        name: "Moselle",
        isoCode: "57",
      },
      {
        name: "Nièvre",
        isoCode: "58",
      },
      {
        name: "Nord",
        isoCode: "59",
      },
      {
        name: "Normandie",
        isoCode: "NOR",
      },
      {
        name: "Nouvelle-Aquitaine",
        isoCode: "NAQ",
      },
      {
        name: "Occitanie",
        isoCode: "OCC",
      },
      {
        name: "Oise",
        isoCode: "60",
      },
      {
        name: "Orne",
        isoCode: "61",
      },
      {
        name: "Paris",
        isoCode: "75C",
      },
      {
        name: "Pas-de-Calais",
        isoCode: "62",
      },
      {
        name: "Pays-de-la-Loire",
        isoCode: "PDL",
      },
      {
        name: "Provence-Alpes-Côte-d’Azur",
        isoCode: "PAC",
      },
      {
        name: "Puy-de-Dôme",
        isoCode: "63",
      },
      {
        name: "Pyrénées-Atlantiques",
        isoCode: "64",
      },
      {
        name: "Pyrénées-Orientales",
        isoCode: "66",
      },
      {
        name: "Rhône",
        isoCode: "69",
      },
      {
        name: "Saint Pierre and Miquelon",
        isoCode: "PM",
      },
      {
        name: "Saint-Barthélemy",
        isoCode: "BL",
      },
      {
        name: "Saint-Martin",
        isoCode: "MF",
      },
      {
        name: "Saône-et-Loire",
        isoCode: "71",
      },
      {
        name: "Sarthe",
        isoCode: "72",
      },
      {
        name: "Savoie",
        isoCode: "73",
      },
      {
        name: "Seine-et-Marne",
        isoCode: "77",
      },
      {
        name: "Seine-Maritime",
        isoCode: "76",
      },
      {
        name: "Seine-Saint-Denis",
        isoCode: "93",
      },
      {
        name: "Somme",
        isoCode: "80",
      },
      {
        name: "Tarn",
        isoCode: "81",
      },
      {
        name: "Tarn-et-Garonne",
        isoCode: "82",
      },
      {
        name: "Territoire de Belfort",
        isoCode: "90",
      },
      {
        name: "Val-d'Oise",
        isoCode: "95",
      },
      {
        name: "Val-de-Marne",
        isoCode: "94",
      },
      {
        name: "Var",
        isoCode: "83",
      },
      {
        name: "Vaucluse",
        isoCode: "84",
      },
      {
        name: "Vendée",
        isoCode: "85",
      },
      {
        name: "Vienne",
        isoCode: "86",
      },
      {
        name: "Vosges",
        isoCode: "88",
      },
      {
        name: "Wallis and Futuna",
        isoCode: "WF",
      },
      {
        name: "Yonne",
        isoCode: "89",
      },
      {
        name: "Yvelines",
        isoCode: "78",
      },
    ],
  },
  {
    name: "French Guiana",
    isoCode: "GF",
    states: [],
  },
  {
    name: "French Polynesia",
    isoCode: "PF",
    states: [],
  },
  {
    name: "French Southern Territories",
    isoCode: "TF",
    states: [],
  },
  {
    name: "Gabon",
    isoCode: "GA",
    states: [
      {
        name: "Estuaire Province",
        isoCode: "1",
      },
      {
        name: "Haut-Ogooué Province",
        isoCode: "2",
      },
      {
        name: "Moyen-Ogooué Province",
        isoCode: "3",
      },
      {
        name: "Ngounié Province",
        isoCode: "4",
      },
      {
        name: "Nyanga Province",
        isoCode: "5",
      },
      {
        name: "Ogooué-Ivindo Province",
        isoCode: "6",
      },
      {
        name: "Ogooué-Lolo Province",
        isoCode: "7",
      },
      {
        name: "Ogooué-Maritime Province",
        isoCode: "8",
      },
      {
        name: "Woleu-Ntem Province",
        isoCode: "9",
      },
    ],
  },
  {
    name: "Gambia The",
    isoCode: "GM",
    states: [
      {
        name: "Banjul",
        isoCode: "B",
      },
      {
        name: "Central River Division",
        isoCode: "M",
      },
      {
        name: "Lower River Division",
        isoCode: "L",
      },
      {
        name: "North Bank Division",
        isoCode: "N",
      },
      {
        name: "Upper River Division",
        isoCode: "U",
      },
      {
        name: "West Coast Division",
        isoCode: "W",
      },
    ],
  },
  {
    name: "Georgia",
    isoCode: "GE",
    states: [
      {
        name: "Adjara",
        isoCode: "AJ",
      },
      {
        name: "Autonomous Republic of Abkhazia",
        isoCode: "AB",
      },
      {
        name: "Guria",
        isoCode: "GU",
      },
      {
        name: "Imereti",
        isoCode: "IM",
      },
      {
        name: "Kakheti",
        isoCode: "KA",
      },
      {
        name: "Khelvachauri Municipality",
        isoCode: "29",
      },
      {
        name: "Kvemo Kartli",
        isoCode: "KK",
      },
      {
        name: "Mtskheta-Mtianeti",
        isoCode: "MM",
      },
      {
        name: "Racha-Lechkhumi and Kvemo Svaneti",
        isoCode: "RL",
      },
      {
        name: "Samegrelo-Zemo Svaneti",
        isoCode: "SZ",
      },
      {
        name: "Samtskhe-Javakheti",
        isoCode: "SJ",
      },
      {
        name: "Senaki Municipality",
        isoCode: "50",
      },
      {
        name: "Shida Kartli",
        isoCode: "SK",
      },
      {
        name: "Tbilisi",
        isoCode: "TB",
      },
    ],
  },
  {
    name: "Germany",
    isoCode: "DE",
    states: [
      {
        name: "Baden-Württemberg",
        isoCode: "BW",
      },
      {
        name: "Bavaria",
        isoCode: "BY",
      },
      {
        name: "Berlin",
        isoCode: "BE",
      },
      {
        name: "Brandenburg",
        isoCode: "BB",
      },
      {
        name: "Bremen",
        isoCode: "HB",
      },
      {
        name: "Hamburg",
        isoCode: "HH",
      },
      {
        name: "Hesse",
        isoCode: "HE",
      },
      {
        name: "Lower Saxony",
        isoCode: "NI",
      },
      {
        name: "Mecklenburg-Vorpommern",
        isoCode: "MV",
      },
      {
        name: "North Rhine-Westphalia",
        isoCode: "NW",
      },
      {
        name: "Rhineland-Palatinate",
        isoCode: "RP",
      },
      {
        name: "Saarland",
        isoCode: "SL",
      },
      {
        name: "Saxony",
        isoCode: "SN",
      },
      {
        name: "Saxony-Anhalt",
        isoCode: "ST",
      },
      {
        name: "Schleswig-Holstein",
        isoCode: "SH",
      },
      {
        name: "Thuringia",
        isoCode: "TH",
      },
    ],
  },
  {
    name: "Ghana",
    isoCode: "GH",
    states: [
      {
        name: "Ahafo",
        isoCode: "AF",
      },
      {
        name: "Ashanti",
        isoCode: "AH",
      },
      {
        name: "Bono",
        isoCode: "BO",
      },
      {
        name: "Bono East",
        isoCode: "BE",
      },
      {
        name: "Central",
        isoCode: "CP",
      },
      {
        name: "Eastern",
        isoCode: "EP",
      },
      {
        name: "Greater Accra",
        isoCode: "AA",
      },
      {
        name: "North East",
        isoCode: "NE",
      },
      {
        name: "Northern",
        isoCode: "NP",
      },
      {
        name: "Oti",
        isoCode: "OT",
      },
      {
        name: "Savannah",
        isoCode: "SV",
      },
      {
        name: "Upper East",
        isoCode: "UE",
      },
      {
        name: "Upper West",
        isoCode: "UW",
      },
      {
        name: "Volta",
        isoCode: "TV",
      },
      {
        name: "Western",
        isoCode: "WP",
      },
      {
        name: "Western North",
        isoCode: "WN",
      },
    ],
  },
  {
    name: "Gibraltar",
    isoCode: "GI",
    states: [],
  },
  {
    name: "Greece",
    isoCode: "GR",
    states: [
      {
        name: "Achaea Regional Unit",
        isoCode: "13",
      },
      {
        name: "Aetolia-Acarnania Regional Unit",
        isoCode: "01",
      },
      {
        name: "Arcadia Prefecture",
        isoCode: "12",
      },
      {
        name: "Argolis Regional Unit",
        isoCode: "11",
      },
      {
        name: "Attica Region",
        isoCode: "I",
      },
      {
        name: "Boeotia Regional Unit",
        isoCode: "03",
      },
      {
        name: "Central Greece Region",
        isoCode: "H",
      },
      {
        name: "Central Macedonia",
        isoCode: "B",
      },
      {
        name: "Chania Regional Unit",
        isoCode: "94",
      },
      {
        name: "Corfu Prefecture",
        isoCode: "22",
      },
      {
        name: "Corinthia Regional Unit",
        isoCode: "15",
      },
      {
        name: "Crete Region",
        isoCode: "M",
      },
      {
        name: "Drama Regional Unit",
        isoCode: "52",
      },
      {
        name: "East Attica Regional Unit",
        isoCode: "A2",
      },
      {
        name: "East Macedonia and Thrace",
        isoCode: "A",
      },
      {
        name: "Epirus Region",
        isoCode: "D",
      },
      {
        name: "Euboea",
        isoCode: "04",
      },
      {
        name: "Grevena Prefecture",
        isoCode: "51",
      },
      {
        name: "Imathia Regional Unit",
        isoCode: "53",
      },
      {
        name: "Ioannina Regional Unit",
        isoCode: "33",
      },
      {
        name: "Ionian Islands Region",
        isoCode: "F",
      },
      {
        name: "Karditsa Regional Unit",
        isoCode: "41",
      },
      {
        name: "Kastoria Regional Unit",
        isoCode: "56",
      },
      {
        name: "Kefalonia Prefecture",
        isoCode: "23",
      },
      {
        name: "Kilkis Regional Unit",
        isoCode: "57",
      },
      {
        name: "Kozani Prefecture",
        isoCode: "58",
      },
      {
        name: "Laconia",
        isoCode: "16",
      },
      {
        name: "Larissa Prefecture",
        isoCode: "42",
      },
      {
        name: "Lefkada Regional Unit",
        isoCode: "24",
      },
      {
        name: "Pella Regional Unit",
        isoCode: "59",
      },
      {
        name: "Peloponnese Region",
        isoCode: "J",
      },
      {
        name: "Phthiotis Prefecture",
        isoCode: "06",
      },
      {
        name: "Preveza Prefecture",
        isoCode: "34",
      },
      {
        name: "Serres Prefecture",
        isoCode: "62",
      },
      {
        name: "South Aegean",
        isoCode: "L",
      },
      {
        name: "Thessaloniki Regional Unit",
        isoCode: "54",
      },
      {
        name: "West Greece Region",
        isoCode: "G",
      },
      {
        name: "West Macedonia Region",
        isoCode: "C",
      },
    ],
  },
  {
    name: "Greenland",
    isoCode: "GL",
    states: [],
  },
  {
    name: "Grenada",
    isoCode: "GD",
    states: [
      {
        name: "Carriacou and Petite Martinique",
        isoCode: "10",
      },
      {
        name: "Saint Andrew Parish",
        isoCode: "01",
      },
      {
        name: "Saint David Parish",
        isoCode: "02",
      },
      {
        name: "Saint George Parish",
        isoCode: "03",
      },
      {
        name: "Saint John Parish",
        isoCode: "04",
      },
      {
        name: "Saint Mark Parish",
        isoCode: "05",
      },
      {
        name: "Saint Patrick Parish",
        isoCode: "06",
      },
    ],
  },
  {
    name: "Guadeloupe",
    isoCode: "GP",
    states: [],
  },
  {
    name: "Guam",
    isoCode: "GU",
    states: [],
  },
  {
    name: "Guatemala",
    isoCode: "GT",
    states: [
      {
        name: "Alta Verapaz Department",
        isoCode: "AV",
      },
      {
        name: "Baja Verapaz Department",
        isoCode: "BV",
      },
      {
        name: "Chimaltenango Department",
        isoCode: "CM",
      },
      {
        name: "Chiquimula Department",
        isoCode: "CQ",
      },
      {
        name: "El Progreso Department",
        isoCode: "PR",
      },
      {
        name: "Escuintla Department",
        isoCode: "ES",
      },
      {
        name: "Guatemala Department",
        isoCode: "GU",
      },
      {
        name: "Huehuetenango Department",
        isoCode: "HU",
      },
      {
        name: "Izabal Department",
        isoCode: "IZ",
      },
      {
        name: "Jalapa Department",
        isoCode: "JA",
      },
      {
        name: "Jutiapa Department",
        isoCode: "JU",
      },
      {
        name: "Petén Department",
        isoCode: "PE",
      },
      {
        name: "Quetzaltenango Department",
        isoCode: "QZ",
      },
      {
        name: "Quiché Department",
        isoCode: "QC",
      },
      {
        name: "Retalhuleu Department",
        isoCode: "RE",
      },
      {
        name: "Sacatepéquez Department",
        isoCode: "SA",
      },
      {
        name: "San Marcos Department",
        isoCode: "SM",
      },
      {
        name: "Santa Rosa Department",
        isoCode: "SR",
      },
      {
        name: "Sololá Department",
        isoCode: "SO",
      },
      {
        name: "Suchitepéquez Department",
        isoCode: "SU",
      },
      {
        name: "Totonicapán Department",
        isoCode: "TO",
      },
    ],
  },
  {
    name: "Guernsey and Alderney",
    isoCode: "GG",
    states: [],
  },
  {
    name: "Guinea",
    isoCode: "GN",
    states: [
      {
        name: "Beyla Prefecture",
        isoCode: "BE",
      },
      {
        name: "Boffa Prefecture",
        isoCode: "BF",
      },
      {
        name: "Boké Prefecture",
        isoCode: "BK",
      },
      {
        name: "Boké Region",
        isoCode: "B",
      },
      {
        name: "Conakry",
        isoCode: "C",
      },
      {
        name: "Coyah Prefecture",
        isoCode: "CO",
      },
      {
        name: "Dabola Prefecture",
        isoCode: "DB",
      },
      {
        name: "Dalaba Prefecture",
        isoCode: "DL",
      },
      {
        name: "Dinguiraye Prefecture",
        isoCode: "DI",
      },
      {
        name: "Dubréka Prefecture",
        isoCode: "DU",
      },
      {
        name: "Faranah Prefecture",
        isoCode: "FA",
      },
      {
        name: "Forécariah Prefecture",
        isoCode: "FO",
      },
      {
        name: "Fria Prefecture",
        isoCode: "FR",
      },
      {
        name: "Gaoual Prefecture",
        isoCode: "GA",
      },
      {
        name: "Guéckédou Prefecture",
        isoCode: "GU",
      },
      {
        name: "Kankan Prefecture",
        isoCode: "KA",
      },
      {
        name: "Kankan Region",
        isoCode: "K",
      },
      {
        name: "Kérouané Prefecture",
        isoCode: "KE",
      },
      {
        name: "Kindia Prefecture",
        isoCode: "KD",
      },
      {
        name: "Kindia Region",
        isoCode: "D",
      },
      {
        name: "Kissidougou Prefecture",
        isoCode: "KS",
      },
      {
        name: "Koubia Prefecture",
        isoCode: "KB",
      },
      {
        name: "Koundara Prefecture",
        isoCode: "KN",
      },
      {
        name: "Kouroussa Prefecture",
        isoCode: "KO",
      },
      {
        name: "Labé Prefecture",
        isoCode: "LA",
      },
      {
        name: "Labé Region",
        isoCode: "L",
      },
      {
        name: "Lélouma Prefecture",
        isoCode: "LE",
      },
      {
        name: "Lola Prefecture",
        isoCode: "LO",
      },
      {
        name: "Macenta Prefecture",
        isoCode: "MC",
      },
      {
        name: "Mali Prefecture",
        isoCode: "ML",
      },
      {
        name: "Mamou Prefecture",
        isoCode: "MM",
      },
      {
        name: "Mamou Region",
        isoCode: "M",
      },
      {
        name: "Mandiana Prefecture",
        isoCode: "MD",
      },
      {
        name: "Nzérékoré Prefecture",
        isoCode: "NZ",
      },
      {
        name: "Nzérékoré Region",
        isoCode: "N",
      },
      {
        name: "Pita Prefecture",
        isoCode: "PI",
      },
      {
        name: "Siguiri Prefecture",
        isoCode: "SI",
      },
      {
        name: "Télimélé Prefecture",
        isoCode: "TE",
      },
      {
        name: "Tougué Prefecture",
        isoCode: "TO",
      },
      {
        name: "Yomou Prefecture",
        isoCode: "YO",
      },
    ],
  },
  {
    name: "Guinea-Bissau",
    isoCode: "GW",
    states: [
      {
        name: "Bafatá",
        isoCode: "BA",
      },
      {
        name: "Biombo Region",
        isoCode: "BM",
      },
      {
        name: "Bolama Region",
        isoCode: "BL",
      },
      {
        name: "Cacheu Region",
        isoCode: "CA",
      },
      {
        name: "Gabú Region",
        isoCode: "GA",
      },
      {
        name: "Leste Province",
        isoCode: "L",
      },
      {
        name: "Norte Province",
        isoCode: "N",
      },
      {
        name: "Oio Region",
        isoCode: "OI",
      },
      {
        name: "Quinara Region",
        isoCode: "QU",
      },
      {
        name: "Sul Province",
        isoCode: "S",
      },
      {
        name: "Tombali Region",
        isoCode: "TO",
      },
    ],
  },
  {
    name: "Guyana",
    isoCode: "GY",
    states: [
      {
        name: "Barima-Waini",
        isoCode: "BA",
      },
      {
        name: "Cuyuni-Mazaruni",
        isoCode: "CU",
      },
      {
        name: "Demerara-Mahaica",
        isoCode: "DE",
      },
      {
        name: "East Berbice-Corentyne",
        isoCode: "EB",
      },
      {
        name: "Essequibo Islands-West Demerara",
        isoCode: "ES",
      },
      {
        name: "Mahaica-Berbice",
        isoCode: "MA",
      },
      {
        name: "Pomeroon-Supenaam",
        isoCode: "PM",
      },
      {
        name: "Potaro-Siparuni",
        isoCode: "PT",
      },
      {
        name: "Upper Demerara-Berbice",
        isoCode: "UD",
      },
      {
        name: "Upper Takutu-Upper Essequibo",
        isoCode: "UT",
      },
    ],
  },
  {
    name: "Haiti",
    isoCode: "HT",
    states: [
      {
        name: "Artibonite",
        isoCode: "AR",
      },
      {
        name: "Centre",
        isoCode: "CE",
      },
      {
        name: "Grand'Anse",
        isoCode: "GA",
      },
      {
        name: "Nippes",
        isoCode: "NI",
      },
      {
        name: "Nord",
        isoCode: "ND",
      },
      {
        name: "Nord-Est",
        isoCode: "NE",
      },
      {
        name: "Nord-Ouest",
        isoCode: "NO",
      },
      {
        name: "Ouest",
        isoCode: "OU",
      },
      {
        name: "Sud",
        isoCode: "SD",
      },
      {
        name: "Sud-Est",
        isoCode: "SE",
      },
    ],
  },
  {
    name: "Heard Island and McDonald Islands",
    isoCode: "HM",
    states: [],
  },
  {
    name: "Honduras",
    isoCode: "HN",
    states: [
      {
        name: "Atlántida Department",
        isoCode: "AT",
      },
      {
        name: "Bay Islands Department",
        isoCode: "IB",
      },
      {
        name: "Choluteca Department",
        isoCode: "CH",
      },
      {
        name: "Colón Department",
        isoCode: "CL",
      },
      {
        name: "Comayagua Department",
        isoCode: "CM",
      },
      {
        name: "Copán Department",
        isoCode: "CP",
      },
      {
        name: "Cortés Department",
        isoCode: "CR",
      },
      {
        name: "El Paraíso Department",
        isoCode: "EP",
      },
      {
        name: "Francisco Morazán Department",
        isoCode: "FM",
      },
      {
        name: "Gracias a Dios Department",
        isoCode: "GD",
      },
      {
        name: "Intibucá Department",
        isoCode: "IN",
      },
      {
        name: "La Paz Department",
        isoCode: "LP",
      },
      {
        name: "Lempira Department",
        isoCode: "LE",
      },
      {
        name: "Ocotepeque Department",
        isoCode: "OC",
      },
      {
        name: "Olancho Department",
        isoCode: "OL",
      },
      {
        name: "Santa Bárbara Department",
        isoCode: "SB",
      },
      {
        name: "Valle Department",
        isoCode: "VA",
      },
      {
        name: "Yoro Department",
        isoCode: "YO",
      },
    ],
  },
  {
    name: "Hong Kong S.A.R.",
    isoCode: "HK",
    states: [
      {
        name: "Central and Western District",
        isoCode: "HCW",
      },
      {
        name: "Eastern",
        isoCode: "HEA",
      },
      {
        name: "Islands District",
        isoCode: "NIS",
      },
      {
        name: "Kowloon City",
        isoCode: "KKC",
      },
      {
        name: "Kwai Tsing",
        isoCode: "NKT",
      },
      {
        name: "Kwun Tong",
        isoCode: "KKT",
      },
      {
        name: "North",
        isoCode: "NNO",
      },
      {
        name: "Sai Kung District",
        isoCode: "NSK",
      },
      {
        name: "Sha Tin",
        isoCode: "NST",
      },
      {
        name: "Sham Shui Po",
        isoCode: "KSS",
      },
      {
        name: "Southern",
        isoCode: "HSO",
      },
      {
        name: "Tai Po District",
        isoCode: "NTP",
      },
      {
        name: "Tsuen Wan District",
        isoCode: "NTW",
      },
      {
        name: "Tuen Mun",
        isoCode: "NTM",
      },
      {
        name: "Wan Chai",
        isoCode: "HWC",
      },
      {
        name: "Wong Tai Sin",
        isoCode: "KWT",
      },
      {
        name: "Yau Tsim Mong",
        isoCode: "KYT",
      },
      {
        name: "Yuen Long District",
        isoCode: "NYL",
      },
    ],
  },
  {
    name: "Hungary",
    isoCode: "HU",
    states: [
      {
        name: "Bács-Kiskun",
        isoCode: "BK",
      },
      {
        name: "Baranya",
        isoCode: "BA",
      },
      {
        name: "Békés",
        isoCode: "BE",
      },
      {
        name: "Békéscsaba",
        isoCode: "BC",
      },
      {
        name: "Borsod-Abaúj-Zemplén",
        isoCode: "BZ",
      },
      {
        name: "Budapest",
        isoCode: "BU",
      },
      {
        name: "Csongrád County",
        isoCode: "CS",
      },
      {
        name: "Debrecen",
        isoCode: "DE",
      },
      {
        name: "Dunaújváros",
        isoCode: "DU",
      },
      {
        name: "Eger",
        isoCode: "EG",
      },
      {
        name: "Érd",
        isoCode: "ER",
      },
      {
        name: "Fejér County",
        isoCode: "FE",
      },
      {
        name: "Győr",
        isoCode: "GY",
      },
      {
        name: "Győr-Moson-Sopron County",
        isoCode: "GS",
      },
      {
        name: "Hajdú-Bihar County",
        isoCode: "HB",
      },
      {
        name: "Heves County",
        isoCode: "HE",
      },
      {
        name: "Hódmezővásárhely",
        isoCode: "HV",
      },
      {
        name: "Jász-Nagykun-Szolnok County",
        isoCode: "JN",
      },
      {
        name: "Kaposvár",
        isoCode: "KV",
      },
      {
        name: "Kecskemét",
        isoCode: "KM",
      },
      {
        name: "Komárom-Esztergom",
        isoCode: "KE",
      },
      {
        name: "Miskolc",
        isoCode: "MI",
      },
      {
        name: "Nagykanizsa",
        isoCode: "NK",
      },
      {
        name: "Nógrád County",
        isoCode: "NO",
      },
      {
        name: "Nyíregyháza",
        isoCode: "NY",
      },
      {
        name: "Pécs",
        isoCode: "PS",
      },
      {
        name: "Pest County",
        isoCode: "PE",
      },
      {
        name: "Salgótarján",
        isoCode: "ST",
      },
      {
        name: "Somogy County",
        isoCode: "SO",
      },
      {
        name: "Sopron",
        isoCode: "SN",
      },
      {
        name: "Szabolcs-Szatmár-Bereg County",
        isoCode: "SZ",
      },
      {
        name: "Szeged",
        isoCode: "SD",
      },
      {
        name: "Székesfehérvár",
        isoCode: "SF",
      },
      {
        name: "Szekszárd",
        isoCode: "SS",
      },
      {
        name: "Szolnok",
        isoCode: "SK",
      },
      {
        name: "Szombathely",
        isoCode: "SH",
      },
      {
        name: "Tatabánya",
        isoCode: "TB",
      },
      {
        name: "Tolna County",
        isoCode: "TO",
      },
      {
        name: "Vas County",
        isoCode: "VA",
      },
      {
        name: "Veszprém",
        isoCode: "VM",
      },
      {
        name: "Veszprém County",
        isoCode: "VE",
      },
      {
        name: "Zala County",
        isoCode: "ZA",
      },
      {
        name: "Zalaegerszeg",
        isoCode: "ZE",
      },
    ],
  },
  {
    name: "Iceland",
    isoCode: "IS",
    states: [
      {
        name: "Capital Region",
        isoCode: "1",
      },
      {
        name: "Eastern Region",
        isoCode: "7",
      },
      {
        name: "Northeastern Region",
        isoCode: "6",
      },
      {
        name: "Northwestern Region",
        isoCode: "5",
      },
      {
        name: "Southern Peninsula Region",
        isoCode: "2",
      },
      {
        name: "Southern Region",
        isoCode: "8",
      },
      {
        name: "Western Region",
        isoCode: "3",
      },
      {
        name: "Westfjords",
        isoCode: "4",
      },
    ],
  },
  {
    name: "India",
    isoCode: "IN",
    states: [
      {
        name: "Andaman and Nicobar Islands",
        isoCode: "AN",
      },
      {
        name: "Andhra Pradesh",
        isoCode: "AP",
      },
      {
        name: "Arunachal Pradesh",
        isoCode: "AR",
      },
      {
        name: "Assam",
        isoCode: "AS",
      },
      {
        name: "Bihar",
        isoCode: "BR",
      },
      {
        name: "Chandigarh",
        isoCode: "CH",
      },
      {
        name: "Chhattisgarh",
        isoCode: "CT",
      },
      {
        name: "Dadra and Nagar Haveli and Daman and Diu",
        isoCode: "DH",
      },
      {
        name: "Delhi",
        isoCode: "DL",
      },
      {
        name: "Goa",
        isoCode: "GA",
      },
      {
        name: "Gujarat",
        isoCode: "GJ",
      },
      {
        name: "Haryana",
        isoCode: "HR",
      },
      {
        name: "Himachal Pradesh",
        isoCode: "HP",
      },
      {
        name: "Jammu and Kashmir",
        isoCode: "JK",
      },
      {
        name: "Jharkhand",
        isoCode: "JH",
      },
      {
        name: "Karnataka",
        isoCode: "KA",
      },
      {
        name: "Kerala",
        isoCode: "KL",
      },
      {
        name: "Ladakh",
        isoCode: "LA",
      },
      {
        name: "Lakshadweep",
        isoCode: "LD",
      },
      {
        name: "Madhya Pradesh",
        isoCode: "MP",
      },
      {
        name: "Maharashtra",
        isoCode: "MH",
      },
      {
        name: "Manipur",
        isoCode: "MN",
      },
      {
        name: "Meghalaya",
        isoCode: "ML",
      },
      {
        name: "Mizoram",
        isoCode: "MZ",
      },
      {
        name: "Nagaland",
        isoCode: "NL",
      },
      {
        name: "Odisha",
        isoCode: "OR",
      },
      {
        name: "Puducherry",
        isoCode: "PY",
      },
      {
        name: "Punjab",
        isoCode: "PB",
      },
      {
        name: "Rajasthan",
        isoCode: "RJ",
      },
      {
        name: "Sikkim",
        isoCode: "SK",
      },
      {
        name: "Tamil Nadu",
        isoCode: "TN",
      },
      {
        name: "Telangana",
        isoCode: "TG",
      },
      {
        name: "Tripura",
        isoCode: "TR",
      },
      {
        name: "Uttar Pradesh",
        isoCode: "UP",
      },
      {
        name: "Uttarakhand",
        isoCode: "UT",
      },
      {
        name: "West Bengal",
        isoCode: "WB",
      },
    ],
  },
  {
    name: "Indonesia",
    isoCode: "ID",
    states: [
      {
        name: "Aceh",
        isoCode: "AC",
      },
      {
        name: "Bali",
        isoCode: "BA",
      },
      {
        name: "Banten",
        isoCode: "BT",
      },
      {
        name: "Bengkulu",
        isoCode: "BE",
      },
      {
        name: "DI Yogyakarta",
        isoCode: "YO",
      },
      {
        name: "DKI Jakarta",
        isoCode: "JK",
      },
      {
        name: "Gorontalo",
        isoCode: "GO",
      },
      {
        name: "Jambi",
        isoCode: "JA",
      },
      {
        name: "Jawa Barat",
        isoCode: "JB",
      },
      {
        name: "Jawa Tengah",
        isoCode: "JT",
      },
      {
        name: "Jawa Timur",
        isoCode: "JI",
      },
      {
        name: "Kalimantan Barat",
        isoCode: "KB",
      },
      {
        name: "Kalimantan Selatan",
        isoCode: "KS",
      },
      {
        name: "Kalimantan Tengah",
        isoCode: "KT",
      },
      {
        name: "Kalimantan Timur",
        isoCode: "KI",
      },
      {
        name: "Kalimantan Utara",
        isoCode: "KU",
      },
      {
        name: "Kepulauan Bangka Belitung",
        isoCode: "BB",
      },
      {
        name: "Kepulauan Riau",
        isoCode: "KR",
      },
      {
        name: "Lampung",
        isoCode: "LA",
      },
      {
        name: "Maluku",
        isoCode: "MA",
      },
      {
        name: "Maluku Utara",
        isoCode: "MU",
      },
      {
        name: "Nusa Tenggara Barat",
        isoCode: "NB",
      },
      {
        name: "Nusa Tenggara Timur",
        isoCode: "NT",
      },
      {
        name: "Papua",
        isoCode: "PA",
      },
      {
        name: "Papua Barat",
        isoCode: "PB",
      },
      {
        name: "Riau",
        isoCode: "RI",
      },
      {
        name: "Sulawesi Barat",
        isoCode: "SR",
      },
      {
        name: "Sulawesi Selatan",
        isoCode: "SN",
      },
      {
        name: "Sulawesi Tengah",
        isoCode: "ST",
      },
      {
        name: "Sulawesi Tenggara",
        isoCode: "SG",
      },
      {
        name: "Sulawesi Utara",
        isoCode: "SA",
      },
      {
        name: "Sumatera Barat",
        isoCode: "SB",
      },
      {
        name: "Sumatera Selatan",
        isoCode: "SS",
      },
      {
        name: "Sumatera Utara",
        isoCode: "SU",
      },
    ],
  },
  {
    name: "Iran",
    isoCode: "IR",
    states: [
      {
        name: "Alborz",
        isoCode: "30",
      },
      {
        name: "Ardabil",
        isoCode: "24",
      },
      {
        name: "Bushehr",
        isoCode: "18",
      },
      {
        name: "Chaharmahal and Bakhtiari",
        isoCode: "14",
      },
      {
        name: "East Azerbaijan",
        isoCode: "03",
      },
      {
        name: "Fars",
        isoCode: "07",
      },
      {
        name: "Gilan",
        isoCode: "01",
      },
      {
        name: "Golestan",
        isoCode: "27",
      },
      {
        name: "Hamadan",
        isoCode: "13",
      },
      {
        name: "Hormozgan",
        isoCode: "22",
      },
      {
        name: "Ilam",
        isoCode: "16",
      },
      {
        name: "Isfahan",
        isoCode: "10",
      },
      {
        name: "Kerman",
        isoCode: "08",
      },
      {
        name: "Kermanshah",
        isoCode: "05",
      },
      {
        name: "Khuzestan",
        isoCode: "06",
      },
      {
        name: "Kohgiluyeh and Boyer-Ahmad",
        isoCode: "17",
      },
      {
        name: "Kurdistan",
        isoCode: "12",
      },
      {
        name: "Lorestan",
        isoCode: "15",
      },
      {
        name: "Markazi",
        isoCode: "00",
      },
      {
        name: "Mazandaran",
        isoCode: "02",
      },
      {
        name: "North Khorasan",
        isoCode: "28",
      },
      {
        name: "Qazvin",
        isoCode: "26",
      },
      {
        name: "Qom",
        isoCode: "25",
      },
      {
        name: "Razavi Khorasan",
        isoCode: "09",
      },
      {
        name: "Semnan",
        isoCode: "20",
      },
      {
        name: "Sistan and Baluchestan",
        isoCode: "11",
      },
      {
        name: "South Khorasan",
        isoCode: "29",
      },
      {
        name: "Tehran",
        isoCode: "23",
      },
      {
        name: "West Azarbaijan",
        isoCode: "04",
      },
      {
        name: "Yazd",
        isoCode: "21",
      },
      {
        name: "Zanjan",
        isoCode: "19",
      },
    ],
  },
  {
    name: "Iraq",
    isoCode: "IQ",
    states: [
      {
        name: "Al Anbar Governorate",
        isoCode: "AN",
      },
      {
        name: "Al Muthanna Governorate",
        isoCode: "MU",
      },
      {
        name: "Al-Qādisiyyah Governorate",
        isoCode: "QA",
      },
      {
        name: "Babylon Governorate",
        isoCode: "BB",
      },
      {
        name: "Baghdad Governorate",
        isoCode: "BG",
      },
      {
        name: "Basra Governorate",
        isoCode: "BA",
      },
      {
        name: "Dhi Qar Governorate",
        isoCode: "DQ",
      },
      {
        name: "Diyala Governorate",
        isoCode: "DI",
      },
      {
        name: "Dohuk Governorate",
        isoCode: "DA",
      },
      {
        name: "Erbil Governorate",
        isoCode: "AR",
      },
      {
        name: "Karbala Governorate",
        isoCode: "KA",
      },
      {
        name: "Kirkuk Governorate",
        isoCode: "KI",
      },
      {
        name: "Maysan Governorate",
        isoCode: "MA",
      },
      {
        name: "Najaf Governorate",
        isoCode: "NA",
      },
      {
        name: "Nineveh Governorate",
        isoCode: "NI",
      },
      {
        name: "Saladin Governorate",
        isoCode: "SD",
      },
      {
        name: "Sulaymaniyah Governorate",
        isoCode: "SU",
      },
      {
        name: "Wasit Governorate",
        isoCode: "WA",
      },
    ],
  },
  {
    name: "Ireland",
    isoCode: "IE",
    states: [
      {
        name: "Carlow",
        isoCode: "CW",
      },
      {
        name: "Cavan",
        isoCode: "CN",
      },
      {
        name: "Clare",
        isoCode: "CE",
      },
      {
        name: "Connacht",
        isoCode: "C",
      },
      {
        name: "Cork",
        isoCode: "CO",
      },
      {
        name: "Donegal",
        isoCode: "DL",
      },
      {
        name: "Dublin",
        isoCode: "D",
      },
      {
        name: "Galway",
        isoCode: "G",
      },
      {
        name: "Kerry",
        isoCode: "KY",
      },
      {
        name: "Kildare",
        isoCode: "KE",
      },
      {
        name: "Kilkenny",
        isoCode: "KK",
      },
      {
        name: "Laois",
        isoCode: "LS",
      },
      {
        name: "Leinster",
        isoCode: "L",
      },
      {
        name: "Limerick",
        isoCode: "LK",
      },
      {
        name: "Longford",
        isoCode: "LD",
      },
      {
        name: "Louth",
        isoCode: "LH",
      },
      {
        name: "Mayo",
        isoCode: "MO",
      },
      {
        name: "Meath",
        isoCode: "MH",
      },
      {
        name: "Monaghan",
        isoCode: "MN",
      },
      {
        name: "Munster",
        isoCode: "M",
      },
      {
        name: "Offaly",
        isoCode: "OY",
      },
      {
        name: "Roscommon",
        isoCode: "RN",
      },
      {
        name: "Sligo",
        isoCode: "SO",
      },
      {
        name: "Tipperary",
        isoCode: "TA",
      },
      {
        name: "Ulster",
        isoCode: "U",
      },
      {
        name: "Waterford",
        isoCode: "WD",
      },
      {
        name: "Westmeath",
        isoCode: "WH",
      },
      {
        name: "Wexford",
        isoCode: "WX",
      },
      {
        name: "Wicklow",
        isoCode: "WW",
      },
    ],
  },
  {
    name: "Israel",
    isoCode: "IL",
    states: [
      {
        name: "Central District",
        isoCode: "M",
      },
      {
        name: "Haifa District",
        isoCode: "HA",
      },
      {
        name: "Jerusalem District",
        isoCode: "JM",
      },
      {
        name: "Northern District",
        isoCode: "Z",
      },
      {
        name: "Southern District",
        isoCode: "D",
      },
      {
        name: "Tel Aviv District",
        isoCode: "TA",
      },
    ],
  },
  {
    name: "Italy",
    isoCode: "IT",
    states: [
      {
        name: "Abruzzo",
        isoCode: "65",
      },
      {
        name: "Agrigento",
        isoCode: "AG",
      },
      {
        name: "Alessandria",
        isoCode: "AL",
      },
      {
        name: "Ancona",
        isoCode: "AN",
      },
      {
        name: "Aosta Valley",
        isoCode: "23",
      },
      {
        name: "Apulia",
        isoCode: "75",
      },
      {
        name: "Ascoli Piceno",
        isoCode: "AP",
      },
      {
        name: "Asti",
        isoCode: "AT",
      },
      {
        name: "Avellino",
        isoCode: "AV",
      },
      {
        name: "Bari",
        isoCode: "BA",
      },
      {
        name: "Barletta-Andria-Trani",
        isoCode: "BT",
      },
      {
        name: "Basilicata",
        isoCode: "77",
      },
      {
        name: "Belluno",
        isoCode: "BL",
      },
      {
        name: "Benevento",
        isoCode: "BN",
      },
      {
        name: "Bergamo",
        isoCode: "BG",
      },
      {
        name: "Biella",
        isoCode: "BI",
      },
      {
        name: "Bologna",
        isoCode: "BO",
      },
      {
        name: "Brescia",
        isoCode: "BS",
      },
      {
        name: "Brindisi",
        isoCode: "BR",
      },
      {
        name: "Cagliari",
        isoCode: "CA",
      },
      {
        name: "Calabria",
        isoCode: "78",
      },
      {
        name: "Caltanissetta",
        isoCode: "CL",
      },
      {
        name: "Campania",
        isoCode: "72",
      },
      {
        name: "Campobasso",
        isoCode: "CB",
      },
      {
        name: "Caserta",
        isoCode: "CE",
      },
      {
        name: "Catania",
        isoCode: "CT",
      },
      {
        name: "Catanzaro",
        isoCode: "CZ",
      },
      {
        name: "Chieti",
        isoCode: "CH",
      },
      {
        name: "Como",
        isoCode: "CO",
      },
      {
        name: "Cosenza",
        isoCode: "CS",
      },
      {
        name: "Cremona",
        isoCode: "CR",
      },
      {
        name: "Crotone",
        isoCode: "KR",
      },
      {
        name: "Cuneo",
        isoCode: "CN",
      },
      {
        name: "Emilia-Romagna",
        isoCode: "45",
      },
      {
        name: "Enna",
        isoCode: "EN",
      },
      {
        name: "Fermo",
        isoCode: "FM",
      },
      {
        name: "Ferrara",
        isoCode: "FE",
      },
      {
        name: "Florence",
        isoCode: "FI",
      },
      {
        name: "Foggia",
        isoCode: "FG",
      },
      {
        name: "Forlì-Cesena",
        isoCode: "FC",
      },
      {
        name: "Friuli–Venezia Giulia",
        isoCode: "36",
      },
      {
        name: "Frosinone",
        isoCode: "FR",
      },
      {
        name: "Genoa",
        isoCode: "GE",
      },
      {
        name: "Gorizia",
        isoCode: "GO",
      },
      {
        name: "Grosseto",
        isoCode: "GR",
      },
      {
        name: "Imperia",
        isoCode: "IM",
      },
      {
        name: "Isernia",
        isoCode: "IS",
      },
      {
        name: "L'Aquila",
        isoCode: "AQ",
      },
      {
        name: "La Spezia",
        isoCode: "SP",
      },
      {
        name: "Latina",
        isoCode: "LT",
      },
      {
        name: "Lazio",
        isoCode: "62",
      },
      {
        name: "Lecce",
        isoCode: "LE",
      },
      {
        name: "Lecco",
        isoCode: "LC",
      },
      {
        name: "Liguria",
        isoCode: "42",
      },
      {
        name: "Livorno",
        isoCode: "LI",
      },
      {
        name: "Lodi",
        isoCode: "LO",
      },
      {
        name: "Lombardy",
        isoCode: "25",
      },
      {
        name: "Lucca",
        isoCode: "LU",
      },
      {
        name: "Macerata",
        isoCode: "MC",
      },
      {
        name: "Mantua",
        isoCode: "MN",
      },
      {
        name: "Marche",
        isoCode: "57",
      },
      {
        name: "Massa and Carrara",
        isoCode: "MS",
      },
      {
        name: "Matera",
        isoCode: "MT",
      },
      {
        name: "Medio Campidano",
        isoCode: "VS",
      },
      {
        name: "Messina",
        isoCode: "ME",
      },
      {
        name: "Milan",
        isoCode: "MI",
      },
      {
        name: "Modena",
        isoCode: "MO",
      },
      {
        name: "Molise",
        isoCode: "67",
      },
      {
        name: "Monza and Brianza",
        isoCode: "MB",
      },
      {
        name: "Naples",
        isoCode: "NA",
      },
      {
        name: "Novara",
        isoCode: "NO",
      },
      {
        name: "Nuoro",
        isoCode: "NU",
      },
      {
        name: "Oristano",
        isoCode: "OR",
      },
      {
        name: "Padua",
        isoCode: "PD",
      },
      {
        name: "Palermo",
        isoCode: "PA",
      },
      {
        name: "Parma",
        isoCode: "PR",
      },
      {
        name: "Pavia",
        isoCode: "PV",
      },
      {
        name: "Perugia",
        isoCode: "PG",
      },
      {
        name: "Pesaro and Urbino",
        isoCode: "PU",
      },
      {
        name: "Pescara",
        isoCode: "PE",
      },
      {
        name: "Piacenza",
        isoCode: "PC",
      },
      {
        name: "Piedmont",
        isoCode: "21",
      },
      {
        name: "Pisa",
        isoCode: "PI",
      },
      {
        name: "Pistoia",
        isoCode: "PT",
      },
      {
        name: "Pordenone",
        isoCode: "PN",
      },
      {
        name: "Potenza",
        isoCode: "PZ",
      },
      {
        name: "Prato",
        isoCode: "PO",
      },
      {
        name: "Ragusa",
        isoCode: "RG",
      },
      {
        name: "Ravenna",
        isoCode: "RA",
      },
      {
        name: "Reggio Calabria",
        isoCode: "RC",
      },
      {
        name: "Reggio Emilia",
        isoCode: "RE",
      },
      {
        name: "Rieti",
        isoCode: "RI",
      },
      {
        name: "Rimini",
        isoCode: "RN",
      },
      {
        name: "Rome",
        isoCode: "RM",
      },
      {
        name: "Rovigo",
        isoCode: "RO",
      },
      {
        name: "Salerno",
        isoCode: "SA",
      },
      {
        name: "Sardinia",
        isoCode: "88",
      },
      {
        name: "Sassari",
        isoCode: "SS",
      },
      {
        name: "Savona",
        isoCode: "SV",
      },
      {
        name: "Sicily",
        isoCode: "82",
      },
      {
        name: "Siena",
        isoCode: "SI",
      },
      {
        name: "Siracusa",
        isoCode: "SR",
      },
      {
        name: "Sondrio",
        isoCode: "SO",
      },
      {
        name: "South Sardinia",
        isoCode: "SU",
      },
      {
        name: "South Tyrol",
        isoCode: "BZ",
      },
      {
        name: "Taranto",
        isoCode: "TA",
      },
      {
        name: "Teramo",
        isoCode: "TE",
      },
      {
        name: "Terni",
        isoCode: "TR",
      },
      {
        name: "Trapani",
        isoCode: "TP",
      },
      {
        name: "Trentino",
        isoCode: "TN",
      },
      {
        name: "Trentino-South Tyrol",
        isoCode: "32",
      },
      {
        name: "Treviso",
        isoCode: "TV",
      },
      {
        name: "Trieste",
        isoCode: "TS",
      },
      {
        name: "Turin",
        isoCode: "TO",
      },
      {
        name: "Tuscany",
        isoCode: "52",
      },
      {
        name: "Udine",
        isoCode: "UD",
      },
      {
        name: "Umbria",
        isoCode: "55",
      },
      {
        name: "Varese",
        isoCode: "VA",
      },
      {
        name: "Veneto",
        isoCode: "34",
      },
      {
        name: "Venice",
        isoCode: "VE",
      },
      {
        name: "Verbano-Cusio-Ossola",
        isoCode: "VB",
      },
      {
        name: "Vercelli",
        isoCode: "VC",
      },
      {
        name: "Verona",
        isoCode: "VR",
      },
      {
        name: "Vibo Valentia",
        isoCode: "VV",
      },
      {
        name: "Vicenza",
        isoCode: "VI",
      },
      {
        name: "Viterbo",
        isoCode: "VT",
      },
    ],
  },
  {
    name: "Jamaica",
    isoCode: "JM",
    states: [
      {
        name: "Clarendon Parish",
        isoCode: "13",
      },
      {
        name: "Hanover Parish",
        isoCode: "09",
      },
      {
        name: "Kingston Parish",
        isoCode: "01",
      },
      {
        name: "Manchester Parish",
        isoCode: "12",
      },
      {
        name: "Portland Parish",
        isoCode: "04",
      },
      {
        name: "Saint Andrew",
        isoCode: "02",
      },
      {
        name: "Saint Ann Parish",
        isoCode: "06",
      },
      {
        name: "Saint Catherine Parish",
        isoCode: "14",
      },
      {
        name: "Saint Elizabeth Parish",
        isoCode: "11",
      },
      {
        name: "Saint James Parish",
        isoCode: "08",
      },
      {
        name: "Saint Mary Parish",
        isoCode: "05",
      },
      {
        name: "Saint Thomas Parish",
        isoCode: "03",
      },
      {
        name: "Trelawny Parish",
        isoCode: "07",
      },
      {
        name: "Westmoreland Parish",
        isoCode: "10",
      },
    ],
  },
  {
    name: "Japan",
    isoCode: "JP",
    states: [
      {
        name: "Aichi Prefecture",
        isoCode: "23",
      },
      {
        name: "Akita Prefecture",
        isoCode: "05",
      },
      {
        name: "Aomori Prefecture",
        isoCode: "02",
      },
      {
        name: "Chiba Prefecture",
        isoCode: "12",
      },
      {
        name: "Ehime Prefecture",
        isoCode: "38",
      },
      {
        name: "Fukui Prefecture",
        isoCode: "18",
      },
      {
        name: "Fukuoka Prefecture",
        isoCode: "40",
      },
      {
        name: "Fukushima Prefecture",
        isoCode: "07",
      },
      {
        name: "Gifu Prefecture",
        isoCode: "21",
      },
      {
        name: "Gunma Prefecture",
        isoCode: "10",
      },
      {
        name: "Hiroshima Prefecture",
        isoCode: "34",
      },
      {
        name: "Hokkaidō Prefecture",
        isoCode: "01",
      },
      {
        name: "Hyōgo Prefecture",
        isoCode: "28",
      },
      {
        name: "Ibaraki Prefecture",
        isoCode: "08",
      },
      {
        name: "Ishikawa Prefecture",
        isoCode: "17",
      },
      {
        name: "Iwate Prefecture",
        isoCode: "03",
      },
      {
        name: "Kagawa Prefecture",
        isoCode: "37",
      },
      {
        name: "Kagoshima Prefecture",
        isoCode: "46",
      },
      {
        name: "Kanagawa Prefecture",
        isoCode: "14",
      },
      {
        name: "Kōchi Prefecture",
        isoCode: "39",
      },
      {
        name: "Kumamoto Prefecture",
        isoCode: "43",
      },
      {
        name: "Kyōto Prefecture",
        isoCode: "26",
      },
      {
        name: "Mie Prefecture",
        isoCode: "24",
      },
      {
        name: "Miyagi Prefecture",
        isoCode: "04",
      },
      {
        name: "Miyazaki Prefecture",
        isoCode: "45",
      },
      {
        name: "Nagano Prefecture",
        isoCode: "20",
      },
      {
        name: "Nagasaki Prefecture",
        isoCode: "42",
      },
      {
        name: "Nara Prefecture",
        isoCode: "29",
      },
      {
        name: "Niigata Prefecture",
        isoCode: "15",
      },
      {
        name: "Ōita Prefecture",
        isoCode: "44",
      },
      {
        name: "Okayama Prefecture",
        isoCode: "33",
      },
      {
        name: "Okinawa Prefecture",
        isoCode: "47",
      },
      {
        name: "Ōsaka Prefecture",
        isoCode: "27",
      },
      {
        name: "Saga Prefecture",
        isoCode: "41",
      },
      {
        name: "Saitama Prefecture",
        isoCode: "11",
      },
      {
        name: "Shiga Prefecture",
        isoCode: "25",
      },
      {
        name: "Shimane Prefecture",
        isoCode: "32",
      },
      {
        name: "Shizuoka Prefecture",
        isoCode: "22",
      },
      {
        name: "Tochigi Prefecture",
        isoCode: "09",
      },
      {
        name: "Tokushima Prefecture",
        isoCode: "36",
      },
      {
        name: "Tokyo",
        isoCode: "13",
      },
      {
        name: "Tottori Prefecture",
        isoCode: "31",
      },
      {
        name: "Toyama Prefecture",
        isoCode: "16",
      },
      {
        name: "Wakayama Prefecture",
        isoCode: "30",
      },
      {
        name: "Yamagata Prefecture",
        isoCode: "06",
      },
      {
        name: "Yamaguchi Prefecture",
        isoCode: "35",
      },
      {
        name: "Yamanashi Prefecture",
        isoCode: "19",
      },
    ],
  },
  {
    name: "Jersey",
    isoCode: "JE",
    states: [],
  },
  {
    name: "Jordan",
    isoCode: "JO",
    states: [
      {
        name: "Ajloun Governorate",
        isoCode: "AJ",
      },
      {
        name: "Amman Governorate",
        isoCode: "AM",
      },
      {
        name: "Aqaba Governorate",
        isoCode: "AQ",
      },
      {
        name: "Balqa Governorate",
        isoCode: "BA",
      },
      {
        name: "Irbid Governorate",
        isoCode: "IR",
      },
      {
        name: "Jerash Governorate",
        isoCode: "JA",
      },
      {
        name: "Karak Governorate",
        isoCode: "KA",
      },
      {
        name: "Ma'an Governorate",
        isoCode: "MN",
      },
      {
        name: "Madaba Governorate",
        isoCode: "MD",
      },
      {
        name: "Mafraq Governorate",
        isoCode: "MA",
      },
      {
        name: "Tafilah Governorate",
        isoCode: "AT",
      },
      {
        name: "Zarqa Governorate",
        isoCode: "AZ",
      },
    ],
  },
  {
    name: "Kazakhstan",
    isoCode: "KZ",
    states: [
      {
        name: "Akmola Region",
        isoCode: "AKM",
      },
      {
        name: "Aktobe Region",
        isoCode: "AKT",
      },
      {
        name: "Almaty",
        isoCode: "ALA",
      },
      {
        name: "Almaty Region",
        isoCode: "ALM",
      },
      {
        name: "Atyrau Region",
        isoCode: "ATY",
      },
      {
        name: "Baikonur",
        isoCode: "BAY",
      },
      {
        name: "East Kazakhstan Region",
        isoCode: "VOS",
      },
      {
        name: "Jambyl Region",
        isoCode: "ZHA",
      },
      {
        name: "Karaganda Region",
        isoCode: "KAR",
      },
      {
        name: "Kostanay Region",
        isoCode: "KUS",
      },
      {
        name: "Kyzylorda Region",
        isoCode: "KZY",
      },
      {
        name: "Mangystau Region",
        isoCode: "MAN",
      },
      {
        name: "North Kazakhstan Region",
        isoCode: "SEV",
      },
      {
        name: "Nur-Sultan",
        isoCode: "AST",
      },
      {
        name: "Pavlodar Region",
        isoCode: "PAV",
      },
      {
        name: "Turkestan Region",
        isoCode: "YUZ",
      },
      {
        name: "West Kazakhstan Province",
        isoCode: "ZAP",
      },
    ],
  },
  {
    name: "Kenya",
    isoCode: "KE",
    states: [
      {
        name: "Baringo",
        isoCode: "01",
      },
      {
        name: "Bomet",
        isoCode: "02",
      },
      {
        name: "Bungoma",
        isoCode: "03",
      },
      {
        name: "Busia",
        isoCode: "04",
      },
      {
        name: "Elgeyo-Marakwet",
        isoCode: "05",
      },
      {
        name: "Embu",
        isoCode: "06",
      },
      {
        name: "Garissa",
        isoCode: "07",
      },
      {
        name: "Homa Bay",
        isoCode: "08",
      },
      {
        name: "Isiolo",
        isoCode: "09",
      },
      {
        name: "Kajiado",
        isoCode: "10",
      },
      {
        name: "Kakamega",
        isoCode: "11",
      },
      {
        name: "Kericho",
        isoCode: "12",
      },
      {
        name: "Kiambu",
        isoCode: "13",
      },
      {
        name: "Kilifi",
        isoCode: "14",
      },
      {
        name: "Kirinyaga",
        isoCode: "15",
      },
      {
        name: "Kisii",
        isoCode: "16",
      },
      {
        name: "Kisumu",
        isoCode: "17",
      },
      {
        name: "Kitui",
        isoCode: "18",
      },
      {
        name: "Kwale",
        isoCode: "19",
      },
      {
        name: "Laikipia",
        isoCode: "20",
      },
      {
        name: "Lamu",
        isoCode: "21",
      },
      {
        name: "Machakos",
        isoCode: "22",
      },
      {
        name: "Makueni",
        isoCode: "23",
      },
      {
        name: "Mandera",
        isoCode: "24",
      },
      {
        name: "Marsabit",
        isoCode: "25",
      },
      {
        name: "Meru",
        isoCode: "26",
      },
      {
        name: "Migori",
        isoCode: "27",
      },
      {
        name: "Mombasa",
        isoCode: "28",
      },
      {
        name: "Murang'a",
        isoCode: "29",
      },
      {
        name: "Nairobi City",
        isoCode: "30",
      },
      {
        name: "Nakuru",
        isoCode: "31",
      },
      {
        name: "Nandi",
        isoCode: "32",
      },
      {
        name: "Narok",
        isoCode: "33",
      },
      {
        name: "Nyamira",
        isoCode: "34",
      },
      {
        name: "Nyandarua",
        isoCode: "35",
      },
      {
        name: "Nyeri",
        isoCode: "36",
      },
      {
        name: "Samburu",
        isoCode: "37",
      },
      {
        name: "Siaya",
        isoCode: "38",
      },
      {
        name: "Taita–Taveta",
        isoCode: "39",
      },
      {
        name: "Tana River",
        isoCode: "40",
      },
      {
        name: "Tharaka-Nithi",
        isoCode: "41",
      },
      {
        name: "Trans Nzoia",
        isoCode: "42",
      },
      {
        name: "Turkana",
        isoCode: "43",
      },
      {
        name: "Uasin Gishu",
        isoCode: "44",
      },
      {
        name: "Vihiga",
        isoCode: "45",
      },
      {
        name: "Wajir",
        isoCode: "46",
      },
      {
        name: "West Pokot",
        isoCode: "47",
      },
    ],
  },
  {
    name: "Kiribati",
    isoCode: "KI",
    states: [
      {
        name: "Gilbert Islands",
        isoCode: "G",
      },
      {
        name: "Line Islands",
        isoCode: "L",
      },
      {
        name: "Phoenix Islands",
        isoCode: "P",
      },
    ],
  },
  {
    name: "Kosovo",
    isoCode: "XK",
    states: [
      {
        name: "Đakovica District (Gjakove)",
        isoCode: "XDG",
      },
      {
        name: "Gjilan District",
        isoCode: "XGJ",
      },
      {
        name: "Kosovska Mitrovica District",
        isoCode: "XKM",
      },
      {
        name: "Peć District",
        isoCode: "XPE",
      },
      {
        name: "Pristina (Priştine)",
        isoCode: "XPI",
      },
      {
        name: "Prizren District",
        isoCode: "XPR",
      },
      {
        name: "Uroševac District (Ferizaj)",
        isoCode: "XUF",
      },
    ],
  },
  {
    name: "Kuwait",
    isoCode: "KW",
    states: [
      {
        name: "Al Ahmadi Governorate",
        isoCode: "AH",
      },
      {
        name: "Al Farwaniyah Governorate",
        isoCode: "FA",
      },
      {
        name: "Al Jahra Governorate",
        isoCode: "JA",
      },
      {
        name: "Capital Governorate",
        isoCode: "KU",
      },
      {
        name: "Hawalli Governorate",
        isoCode: "HA",
      },
      {
        name: "Mubarak Al-Kabeer Governorate",
        isoCode: "MU",
      },
    ],
  },
  {
    name: "Kyrgyzstan",
    isoCode: "KG",
    states: [
      {
        name: "Batken Region",
        isoCode: "B",
      },
      {
        name: "Bishkek",
        isoCode: "GB",
      },
      {
        name: "Chuy Region",
        isoCode: "C",
      },
      {
        name: "Issyk-Kul Region",
        isoCode: "Y",
      },
      {
        name: "Jalal-Abad Region",
        isoCode: "J",
      },
      {
        name: "Naryn Region",
        isoCode: "N",
      },
      {
        name: "Osh",
        isoCode: "GO",
      },
      {
        name: "Osh Region",
        isoCode: "O",
      },
      {
        name: "Talas Region",
        isoCode: "T",
      },
    ],
  },
  {
    name: "Laos",
    isoCode: "LA",
    states: [
      {
        name: "Attapeu Province",
        isoCode: "AT",
      },
      {
        name: "Bokeo Province",
        isoCode: "BK",
      },
      {
        name: "Bolikhamsai Province",
        isoCode: "BL",
      },
      {
        name: "Champasak Province",
        isoCode: "CH",
      },
      {
        name: "Houaphanh Province",
        isoCode: "HO",
      },
      {
        name: "Khammouane Province",
        isoCode: "KH",
      },
      {
        name: "Luang Namtha Province",
        isoCode: "LM",
      },
      {
        name: "Luang Prabang Province",
        isoCode: "LP",
      },
      {
        name: "Oudomxay Province",
        isoCode: "OU",
      },
      {
        name: "Phongsaly Province",
        isoCode: "PH",
      },
      {
        name: "Sainyabuli Province",
        isoCode: "XA",
      },
      {
        name: "Salavan Province",
        isoCode: "SL",
      },
      {
        name: "Savannakhet Province",
        isoCode: "SV",
      },
      {
        name: "Sekong Province",
        isoCode: "XE",
      },
      {
        name: "Vientiane Prefecture",
        isoCode: "VT",
      },
      {
        name: "Vientiane Province",
        isoCode: "VI",
      },
      {
        name: "Xaisomboun",
        isoCode: "XN",
      },
      {
        name: "Xaisomboun Province",
        isoCode: "XS",
      },
      {
        name: "Xiangkhouang Province",
        isoCode: "XI",
      },
    ],
  },
  {
    name: "Latvia",
    isoCode: "LV",
    states: [
      {
        name: "Aglona Municipality",
        isoCode: "001",
      },
      {
        name: "Aizkraukle Municipality",
        isoCode: "002",
      },
      {
        name: "Aizpute Municipality",
        isoCode: "003",
      },
      {
        name: "Aknīste Municipality",
        isoCode: "004",
      },
      {
        name: "Aloja Municipality",
        isoCode: "005",
      },
      {
        name: "Alsunga Municipality",
        isoCode: "006",
      },
      {
        name: "Alūksne Municipality",
        isoCode: "007",
      },
      {
        name: "Amata Municipality",
        isoCode: "008",
      },
      {
        name: "Ape Municipality",
        isoCode: "009",
      },
      {
        name: "Auce Municipality",
        isoCode: "010",
      },
      {
        name: "Babīte Municipality",
        isoCode: "012",
      },
      {
        name: "Baldone Municipality",
        isoCode: "013",
      },
      {
        name: "Baltinava Municipality",
        isoCode: "014",
      },
      {
        name: "Balvi Municipality",
        isoCode: "015",
      },
      {
        name: "Bauska Municipality",
        isoCode: "016",
      },
      {
        name: "Beverīna Municipality",
        isoCode: "017",
      },
      {
        name: "Brocēni Municipality",
        isoCode: "018",
      },
      {
        name: "Burtnieki Municipality",
        isoCode: "019",
      },
      {
        name: "Carnikava Municipality",
        isoCode: "020",
      },
      {
        name: "Cēsis Municipality",
        isoCode: "022",
      },
      {
        name: "Cesvaine Municipality",
        isoCode: "021",
      },
      {
        name: "Cibla Municipality",
        isoCode: "023",
      },
      {
        name: "Dagda Municipality",
        isoCode: "024",
      },
      {
        name: "Daugavpils",
        isoCode: "DGV",
      },
      {
        name: "Daugavpils Municipality",
        isoCode: "025",
      },
      {
        name: "Dobele Municipality",
        isoCode: "026",
      },
      {
        name: "Dundaga Municipality",
        isoCode: "027",
      },
      {
        name: "Durbe Municipality",
        isoCode: "028",
      },
      {
        name: "Engure Municipality",
        isoCode: "029",
      },
      {
        name: "Ērgļi Municipality",
        isoCode: "030",
      },
      {
        name: "Garkalne Municipality",
        isoCode: "031",
      },
      {
        name: "Grobiņa Municipality",
        isoCode: "032",
      },
      {
        name: "Gulbene Municipality",
        isoCode: "033",
      },
      {
        name: "Iecava Municipality",
        isoCode: "034",
      },
      {
        name: "Ikšķile Municipality",
        isoCode: "035",
      },
      {
        name: "Ilūkste Municipality",
        isoCode: "036",
      },
      {
        name: "Inčukalns Municipality",
        isoCode: "037",
      },
      {
        name: "Jaunjelgava Municipality",
        isoCode: "038",
      },
      {
        name: "Jaunpiebalga Municipality",
        isoCode: "039",
      },
      {
        name: "Jaunpils Municipality",
        isoCode: "040",
      },
      {
        name: "Jēkabpils",
        isoCode: "JKB",
      },
      {
        name: "Jēkabpils Municipality",
        isoCode: "042",
      },
      {
        name: "Jelgava",
        isoCode: "JEL",
      },
      {
        name: "Jelgava Municipality",
        isoCode: "041",
      },
      {
        name: "Jūrmala",
        isoCode: "JUR",
      },
      {
        name: "Kandava Municipality",
        isoCode: "043",
      },
      {
        name: "Kārsava Municipality",
        isoCode: "044",
      },
      {
        name: "Ķegums Municipality",
        isoCode: "051",
      },
      {
        name: "Ķekava Municipality",
        isoCode: "052",
      },
      {
        name: "Kocēni Municipality",
        isoCode: "045",
      },
      {
        name: "Koknese Municipality",
        isoCode: "046",
      },
      {
        name: "Krāslava Municipality",
        isoCode: "047",
      },
      {
        name: "Krimulda Municipality",
        isoCode: "048",
      },
      {
        name: "Krustpils Municipality",
        isoCode: "049",
      },
      {
        name: "Kuldīga Municipality",
        isoCode: "050",
      },
      {
        name: "Lielvārde Municipality",
        isoCode: "053",
      },
      {
        name: "Liepāja",
        isoCode: "LPX",
      },
      {
        name: "Līgatne Municipality",
        isoCode: "055",
      },
      {
        name: "Limbaži Municipality",
        isoCode: "054",
      },
      {
        name: "Līvāni Municipality",
        isoCode: "056",
      },
      {
        name: "Lubāna Municipality",
        isoCode: "057",
      },
      {
        name: "Ludza Municipality",
        isoCode: "058",
      },
      {
        name: "Madona Municipality",
        isoCode: "059",
      },
      {
        name: "Mālpils Municipality",
        isoCode: "061",
      },
      {
        name: "Mārupe Municipality",
        isoCode: "062",
      },
      {
        name: "Mazsalaca Municipality",
        isoCode: "060",
      },
      {
        name: "Mērsrags Municipality",
        isoCode: "063",
      },
      {
        name: "Naukšēni Municipality",
        isoCode: "064",
      },
      {
        name: "Nereta Municipality",
        isoCode: "065",
      },
      {
        name: "Nīca Municipality",
        isoCode: "066",
      },
      {
        name: "Ogre Municipality",
        isoCode: "067",
      },
      {
        name: "Olaine Municipality",
        isoCode: "068",
      },
      {
        name: "Ozolnieki Municipality",
        isoCode: "069",
      },
      {
        name: "Pārgauja Municipality",
        isoCode: "070",
      },
      {
        name: "Pāvilosta Municipality",
        isoCode: "071",
      },
      {
        name: "Pļaviņas Municipality",
        isoCode: "072",
      },
      {
        name: "Preiļi Municipality",
        isoCode: "073",
      },
      {
        name: "Priekule Municipality",
        isoCode: "074",
      },
      {
        name: "Priekuļi Municipality",
        isoCode: "075",
      },
      {
        name: "Rauna Municipality",
        isoCode: "076",
      },
      {
        name: "Rēzekne",
        isoCode: "REZ",
      },
      {
        name: "Rēzekne Municipality",
        isoCode: "077",
      },
      {
        name: "Riebiņi Municipality",
        isoCode: "078",
      },
      {
        name: "Riga",
        isoCode: "RIX",
      },
      {
        name: "Roja Municipality",
        isoCode: "079",
      },
      {
        name: "Ropaži Municipality",
        isoCode: "080",
      },
      {
        name: "Rucava Municipality",
        isoCode: "081",
      },
      {
        name: "Rugāji Municipality",
        isoCode: "082",
      },
      {
        name: "Rūjiena Municipality",
        isoCode: "084",
      },
      {
        name: "Rundāle Municipality",
        isoCode: "083",
      },
      {
        name: "Sala Municipality",
        isoCode: "085",
      },
      {
        name: "Salacgrīva Municipality",
        isoCode: "086",
      },
      {
        name: "Salaspils Municipality",
        isoCode: "087",
      },
      {
        name: "Saldus Municipality",
        isoCode: "088",
      },
      {
        name: "Saulkrasti Municipality",
        isoCode: "089",
      },
      {
        name: "Sēja Municipality",
        isoCode: "090",
      },
      {
        name: "Sigulda Municipality",
        isoCode: "091",
      },
      {
        name: "Skrīveri Municipality",
        isoCode: "092",
      },
      {
        name: "Skrunda Municipality",
        isoCode: "093",
      },
      {
        name: "Smiltene Municipality",
        isoCode: "094",
      },
      {
        name: "Stopiņi Municipality",
        isoCode: "095",
      },
      {
        name: "Strenči Municipality",
        isoCode: "096",
      },
      {
        name: "Talsi Municipality",
        isoCode: "097",
      },
      {
        name: "Tērvete Municipality",
        isoCode: "098",
      },
      {
        name: "Tukums Municipality",
        isoCode: "099",
      },
      {
        name: "Vaiņode Municipality",
        isoCode: "100",
      },
      {
        name: "Valka Municipality",
        isoCode: "101",
      },
      {
        name: "Valmiera",
        isoCode: "VMR",
      },
      {
        name: "Varakļāni Municipality",
        isoCode: "102",
      },
      {
        name: "Vārkava Municipality",
        isoCode: "103",
      },
      {
        name: "Vecpiebalga Municipality",
        isoCode: "104",
      },
      {
        name: "Vecumnieki Municipality",
        isoCode: "105",
      },
      {
        name: "Ventspils",
        isoCode: "VEN",
      },
      {
        name: "Ventspils Municipality",
        isoCode: "106",
      },
      {
        name: "Viesīte Municipality",
        isoCode: "107",
      },
      {
        name: "Viļaka Municipality",
        isoCode: "108",
      },
      {
        name: "Viļāni Municipality",
        isoCode: "109",
      },
      {
        name: "Zilupe Municipality",
        isoCode: "110",
      },
    ],
  },
  {
    name: "Lebanon",
    isoCode: "LB",
    states: [
      {
        name: "Akkar Governorate",
        isoCode: "AK",
      },
      {
        name: "Baalbek-Hermel Governorate",
        isoCode: "BH",
      },
      {
        name: "Beirut Governorate",
        isoCode: "BA",
      },
      {
        name: "Beqaa Governorate",
        isoCode: "BI",
      },
      {
        name: "Mount Lebanon Governorate",
        isoCode: "JL",
      },
      {
        name: "Nabatieh Governorate",
        isoCode: "NA",
      },
      {
        name: "North Governorate",
        isoCode: "AS",
      },
      {
        name: "South Governorate",
        isoCode: "JA",
      },
    ],
  },
  {
    name: "Lesotho",
    isoCode: "LS",
    states: [
      {
        name: "Berea District",
        isoCode: "D",
      },
      {
        name: "Butha-Buthe District",
        isoCode: "B",
      },
      {
        name: "Leribe District",
        isoCode: "C",
      },
      {
        name: "Mafeteng District",
        isoCode: "E",
      },
      {
        name: "Maseru District",
        isoCode: "A",
      },
      {
        name: "Mohale's Hoek District",
        isoCode: "F",
      },
      {
        name: "Mokhotlong District",
        isoCode: "J",
      },
      {
        name: "Qacha's Nek District",
        isoCode: "H",
      },
      {
        name: "Quthing District",
        isoCode: "G",
      },
      {
        name: "Thaba-Tseka District",
        isoCode: "K",
      },
    ],
  },
  {
    name: "Liberia",
    isoCode: "LR",
    states: [
      {
        name: "Bomi County",
        isoCode: "BM",
      },
      {
        name: "Bong County",
        isoCode: "BG",
      },
      {
        name: "Gbarpolu County",
        isoCode: "GP",
      },
      {
        name: "Grand Bassa County",
        isoCode: "GB",
      },
      {
        name: "Grand Cape Mount County",
        isoCode: "CM",
      },
      {
        name: "Grand Gedeh County",
        isoCode: "GG",
      },
      {
        name: "Grand Kru County",
        isoCode: "GK",
      },
      {
        name: "Lofa County",
        isoCode: "LO",
      },
      {
        name: "Margibi County",
        isoCode: "MG",
      },
      {
        name: "Maryland County",
        isoCode: "MY",
      },
      {
        name: "Montserrado County",
        isoCode: "MO",
      },
      {
        name: "Nimba",
        isoCode: "NI",
      },
      {
        name: "River Cess County",
        isoCode: "RI",
      },
      {
        name: "River Gee County",
        isoCode: "RG",
      },
      {
        name: "Sinoe County",
        isoCode: "SI",
      },
    ],
  },
  {
    name: "Libya",
    isoCode: "LY",
    states: [
      {
        name: "Al Wahat District",
        isoCode: "WA",
      },
      {
        name: "Benghazi",
        isoCode: "BA",
      },
      {
        name: "Derna District",
        isoCode: "DR",
      },
      {
        name: "Ghat District",
        isoCode: "GT",
      },
      {
        name: "Jabal al Akhdar",
        isoCode: "JA",
      },
      {
        name: "Jabal al Gharbi District",
        isoCode: "JG",
      },
      {
        name: "Jafara",
        isoCode: "JI",
      },
      {
        name: "Jufra",
        isoCode: "JU",
      },
      {
        name: "Kufra District",
        isoCode: "KF",
      },
      {
        name: "Marj District",
        isoCode: "MJ",
      },
      {
        name: "Misrata District",
        isoCode: "MI",
      },
      {
        name: "Murqub",
        isoCode: "MB",
      },
      {
        name: "Murzuq District",
        isoCode: "MQ",
      },
      {
        name: "Nalut District",
        isoCode: "NL",
      },
      {
        name: "Nuqat al Khams",
        isoCode: "NQ",
      },
      {
        name: "Sabha District",
        isoCode: "SB",
      },
      {
        name: "Sirte District",
        isoCode: "SR",
      },
      {
        name: "Tripoli District",
        isoCode: "TB",
      },
      {
        name: "Wadi al Hayaa District",
        isoCode: "WD",
      },
      {
        name: "Wadi al Shatii District",
        isoCode: "WS",
      },
      {
        name: "Zawiya District",
        isoCode: "ZA",
      },
    ],
  },
  {
    name: "Liechtenstein",
    isoCode: "LI",
    states: [
      {
        name: "Balzers",
        isoCode: "01",
      },
      {
        name: "Eschen",
        isoCode: "02",
      },
      {
        name: "Gamprin",
        isoCode: "03",
      },
      {
        name: "Mauren",
        isoCode: "04",
      },
      {
        name: "Planken",
        isoCode: "05",
      },
      {
        name: "Ruggell",
        isoCode: "06",
      },
      {
        name: "Schaan",
        isoCode: "07",
      },
      {
        name: "Schellenberg",
        isoCode: "08",
      },
      {
        name: "Triesen",
        isoCode: "09",
      },
      {
        name: "Triesenberg",
        isoCode: "10",
      },
      {
        name: "Vaduz",
        isoCode: "11",
      },
    ],
  },
  {
    name: "Lithuania",
    isoCode: "LT",
    states: [
      {
        name: "Akmenė District Municipality",
        isoCode: "01",
      },
      {
        name: "Alytus City Municipality",
        isoCode: "02",
      },
      {
        name: "Alytus County",
        isoCode: "AL",
      },
      {
        name: "Alytus District Municipality",
        isoCode: "03",
      },
      {
        name: "Birštonas Municipality",
        isoCode: "05",
      },
      {
        name: "Biržai District Municipality",
        isoCode: "06",
      },
      {
        name: "Druskininkai municipality",
        isoCode: "07",
      },
      {
        name: "Elektrėnai municipality",
        isoCode: "08",
      },
      {
        name: "Ignalina District Municipality",
        isoCode: "09",
      },
      {
        name: "Jonava District Municipality",
        isoCode: "10",
      },
      {
        name: "Joniškis District Municipality",
        isoCode: "11",
      },
      {
        name: "Jurbarkas District Municipality",
        isoCode: "12",
      },
      {
        name: "Kaišiadorys District Municipality",
        isoCode: "13",
      },
      {
        name: "Kalvarija municipality",
        isoCode: "14",
      },
      {
        name: "Kaunas City Municipality",
        isoCode: "15",
      },
      {
        name: "Kaunas County",
        isoCode: "KU",
      },
      {
        name: "Kaunas District Municipality",
        isoCode: "16",
      },
      {
        name: "Kazlų Rūda municipality",
        isoCode: "17",
      },
      {
        name: "Kėdainiai District Municipality",
        isoCode: "18",
      },
      {
        name: "Kelmė District Municipality",
        isoCode: "19",
      },
      {
        name: "Klaipeda City Municipality",
        isoCode: "20",
      },
      {
        name: "Klaipėda County",
        isoCode: "KL",
      },
      {
        name: "Klaipėda District Municipality",
        isoCode: "21",
      },
      {
        name: "Kretinga District Municipality",
        isoCode: "22",
      },
      {
        name: "Kupiškis District Municipality",
        isoCode: "23",
      },
      {
        name: "Lazdijai District Municipality",
        isoCode: "24",
      },
      {
        name: "Marijampolė County",
        isoCode: "MR",
      },
      {
        name: "Marijampolė Municipality",
        isoCode: "25",
      },
      {
        name: "Mažeikiai District Municipality",
        isoCode: "26",
      },
      {
        name: "Molėtai District Municipality",
        isoCode: "27",
      },
      {
        name: "Neringa Municipality",
        isoCode: "28",
      },
      {
        name: "Pagėgiai municipality",
        isoCode: "29",
      },
      {
        name: "Pakruojis District Municipality",
        isoCode: "30",
      },
      {
        name: "Palanga City Municipality",
        isoCode: "31",
      },
      {
        name: "Panevėžys City Municipality",
        isoCode: "32",
      },
      {
        name: "Panevėžys County",
        isoCode: "PN",
      },
      {
        name: "Panevėžys District Municipality",
        isoCode: "33",
      },
      {
        name: "Pasvalys District Municipality",
        isoCode: "34",
      },
      {
        name: "Plungė District Municipality",
        isoCode: "35",
      },
      {
        name: "Prienai District Municipality",
        isoCode: "36",
      },
      {
        name: "Radviliškis District Municipality",
        isoCode: "37",
      },
      {
        name: "Raseiniai District Municipality",
        isoCode: "38",
      },
      {
        name: "Rietavas municipality",
        isoCode: "39",
      },
      {
        name: "Rokiškis District Municipality",
        isoCode: "40",
      },
      {
        name: "Šakiai District Municipality",
        isoCode: "41",
      },
      {
        name: "Šalčininkai District Municipality",
        isoCode: "42",
      },
      {
        name: "Šiauliai City Municipality",
        isoCode: "43",
      },
      {
        name: "Šiauliai County",
        isoCode: "SA",
      },
      {
        name: "Šiauliai District Municipality",
        isoCode: "44",
      },
      {
        name: "Šilalė District Municipality",
        isoCode: "45",
      },
      {
        name: "Šilutė District Municipality",
        isoCode: "46",
      },
      {
        name: "Širvintos District Municipality",
        isoCode: "47",
      },
      {
        name: "Skuodas District Municipality",
        isoCode: "48",
      },
      {
        name: "Švenčionys District Municipality",
        isoCode: "49",
      },
      {
        name: "Tauragė County",
        isoCode: "TA",
      },
      {
        name: "Tauragė District Municipality",
        isoCode: "50",
      },
      {
        name: "Telšiai County",
        isoCode: "TE",
      },
      {
        name: "Telšiai District Municipality",
        isoCode: "51",
      },
      {
        name: "Trakai District Municipality",
        isoCode: "52",
      },
      {
        name: "Ukmergė District Municipality",
        isoCode: "53",
      },
      {
        name: "Utena County",
        isoCode: "UT",
      },
      {
        name: "Utena District Municipality",
        isoCode: "54",
      },
      {
        name: "Varėna District Municipality",
        isoCode: "55",
      },
      {
        name: "Vilkaviškis District Municipality",
        isoCode: "56",
      },
      {
        name: "Vilnius City Municipality",
        isoCode: "57",
      },
      {
        name: "Vilnius County",
        isoCode: "VL",
      },
      {
        name: "Vilnius District Municipality",
        isoCode: "58",
      },
      {
        name: "Visaginas Municipality",
        isoCode: "59",
      },
      {
        name: "Zarasai District Municipality",
        isoCode: "60",
      },
    ],
  },
  {
    name: "Luxembourg",
    isoCode: "LU",
    states: [
      {
        name: "Canton of Capellen",
        isoCode: "CA",
      },
      {
        name: "Canton of Clervaux",
        isoCode: "CL",
      },
      {
        name: "Canton of Diekirch",
        isoCode: "DI",
      },
      {
        name: "Canton of Echternach",
        isoCode: "EC",
      },
      {
        name: "Canton of Esch-sur-Alzette",
        isoCode: "ES",
      },
      {
        name: "Canton of Grevenmacher",
        isoCode: "GR",
      },
      {
        name: "Canton of Luxembourg",
        isoCode: "LU",
      },
      {
        name: "Canton of Mersch",
        isoCode: "ME",
      },
      {
        name: "Canton of Redange",
        isoCode: "RD",
      },
      {
        name: "Canton of Remich",
        isoCode: "RM",
      },
      {
        name: "Canton of Vianden",
        isoCode: "VD",
      },
      {
        name: "Canton of Wiltz",
        isoCode: "WI",
      },
      {
        name: "Diekirch District",
        isoCode: "D",
      },
      {
        name: "Grevenmacher District",
        isoCode: "G",
      },
      {
        name: "Luxembourg District",
        isoCode: "L",
      },
    ],
  },
  {
    name: "Macau S.A.R.",
    isoCode: "MO",
    states: [],
  },
  {
    name: "Macedonia",
    isoCode: "MK",
    states: [
      {
        name: "Aerodrom Municipality",
        isoCode: "01",
      },
      {
        name: "Aračinovo Municipality",
        isoCode: "02",
      },
      {
        name: "Berovo Municipality",
        isoCode: "03",
      },
      {
        name: "Bitola Municipality",
        isoCode: "04",
      },
      {
        name: "Bogdanci Municipality",
        isoCode: "05",
      },
      {
        name: "Bogovinje Municipality",
        isoCode: "06",
      },
      {
        name: "Bosilovo Municipality",
        isoCode: "07",
      },
      {
        name: "Brvenica Municipality",
        isoCode: "08",
      },
      {
        name: "Butel Municipality",
        isoCode: "09",
      },
      {
        name: "Čair Municipality",
        isoCode: "79",
      },
      {
        name: "Čaška Municipality",
        isoCode: "80",
      },
      {
        name: "Centar Municipality",
        isoCode: "77",
      },
      {
        name: "Centar Župa Municipality",
        isoCode: "78",
      },
      {
        name: "Češinovo-Obleševo Municipality",
        isoCode: "81",
      },
      {
        name: "Čučer-Sandevo Municipality",
        isoCode: "82",
      },
      {
        name: "Debarca Municipality",
        isoCode: "22",
      },
      {
        name: "Delčevo Municipality",
        isoCode: "23",
      },
      {
        name: "Demir Hisar Municipality",
        isoCode: "25",
      },
      {
        name: "Demir Kapija Municipality",
        isoCode: "24",
      },
      {
        name: "Dojran Municipality",
        isoCode: "26",
      },
      {
        name: "Dolneni Municipality",
        isoCode: "27",
      },
      {
        name: "Drugovo Municipality",
        isoCode: "28",
      },
      {
        name: "Gazi Baba Municipality",
        isoCode: "17",
      },
      {
        name: "Gevgelija Municipality",
        isoCode: "18",
      },
      {
        name: "Gjorče Petrov Municipality",
        isoCode: "29",
      },
      {
        name: "Gostivar Municipality",
        isoCode: "19",
      },
      {
        name: "Gradsko Municipality",
        isoCode: "20",
      },
      {
        name: "Greater Skopje",
        isoCode: "85",
      },
      {
        name: "Ilinden Municipality",
        isoCode: "34",
      },
      {
        name: "Jegunovce Municipality",
        isoCode: "35",
      },
      {
        name: "Karbinci",
        isoCode: "37",
      },
      {
        name: "Karpoš Municipality",
        isoCode: "38",
      },
      {
        name: "Kavadarci Municipality",
        isoCode: "36",
      },
      {
        name: "Kičevo Municipality",
        isoCode: "40",
      },
      {
        name: "Kisela Voda Municipality",
        isoCode: "39",
      },
      {
        name: "Kočani Municipality",
        isoCode: "42",
      },
      {
        name: "Konče Municipality",
        isoCode: "41",
      },
      {
        name: "Kratovo Municipality",
        isoCode: "43",
      },
      {
        name: "Kriva Palanka Municipality",
        isoCode: "44",
      },
      {
        name: "Krivogaštani Municipality",
        isoCode: "45",
      },
      {
        name: "Kruševo Municipality",
        isoCode: "46",
      },
      {
        name: "Kumanovo Municipality",
        isoCode: "47",
      },
      {
        name: "Lipkovo Municipality",
        isoCode: "48",
      },
      {
        name: "Lozovo Municipality",
        isoCode: "49",
      },
      {
        name: "Makedonska Kamenica Municipality",
        isoCode: "51",
      },
      {
        name: "Makedonski Brod Municipality",
        isoCode: "52",
      },
      {
        name: "Mavrovo and Rostuša Municipality",
        isoCode: "50",
      },
      {
        name: "Mogila Municipality",
        isoCode: "53",
      },
      {
        name: "Negotino Municipality",
        isoCode: "54",
      },
      {
        name: "Novaci Municipality",
        isoCode: "55",
      },
      {
        name: "Novo Selo Municipality",
        isoCode: "56",
      },
      {
        name: "Ohrid Municipality",
        isoCode: "58",
      },
      {
        name: "Oslomej Municipality",
        isoCode: "57",
      },
      {
        name: "Pehčevo Municipality",
        isoCode: "60",
      },
      {
        name: "Petrovec Municipality",
        isoCode: "59",
      },
      {
        name: "Plasnica Municipality",
        isoCode: "61",
      },
      {
        name: "Prilep Municipality",
        isoCode: "62",
      },
      {
        name: "Probištip Municipality",
        isoCode: "63",
      },
      {
        name: "Radoviš Municipality",
        isoCode: "64",
      },
      {
        name: "Rankovce Municipality",
        isoCode: "65",
      },
      {
        name: "Resen Municipality",
        isoCode: "66",
      },
      {
        name: "Rosoman Municipality",
        isoCode: "67",
      },
      {
        name: "Saraj Municipality",
        isoCode: "68",
      },
      {
        name: "Sopište Municipality",
        isoCode: "70",
      },
      {
        name: "Staro Nagoričane Municipality",
        isoCode: "71",
      },
      {
        name: "Štip Municipality",
        isoCode: "83",
      },
      {
        name: "Struga Municipality",
        isoCode: "72",
      },
      {
        name: "Strumica Municipality",
        isoCode: "73",
      },
      {
        name: "Studeničani Municipality",
        isoCode: "74",
      },
      {
        name: "Šuto Orizari Municipality",
        isoCode: "84",
      },
      {
        name: "Sveti Nikole Municipality",
        isoCode: "69",
      },
      {
        name: "Tearce Municipality",
        isoCode: "75",
      },
      {
        name: "Tetovo Municipality",
        isoCode: "76",
      },
      {
        name: "Valandovo Municipality",
        isoCode: "10",
      },
      {
        name: "Vasilevo Municipality",
        isoCode: "11",
      },
      {
        name: "Veles Municipality",
        isoCode: "13",
      },
      {
        name: "Vevčani Municipality",
        isoCode: "12",
      },
      {
        name: "Vinica Municipality",
        isoCode: "14",
      },
      {
        name: "Vraneštica Municipality",
        isoCode: "15",
      },
      {
        name: "Vrapčište Municipality",
        isoCode: "16",
      },
      {
        name: "Zajas Municipality",
        isoCode: "31",
      },
      {
        name: "Zelenikovo Municipality",
        isoCode: "32",
      },
      {
        name: "Želino Municipality",
        isoCode: "30",
      },
      {
        name: "Zrnovci Municipality",
        isoCode: "33",
      },
    ],
  },
  {
    name: "Madagascar",
    isoCode: "MG",
    states: [
      {
        name: "Antananarivo Province",
        isoCode: "T",
      },
      {
        name: "Antsiranana Province",
        isoCode: "D",
      },
      {
        name: "Fianarantsoa Province",
        isoCode: "F",
      },
      {
        name: "Mahajanga Province",
        isoCode: "M",
      },
      {
        name: "Toamasina Province",
        isoCode: "A",
      },
      {
        name: "Toliara Province",
        isoCode: "U",
      },
    ],
  },
  {
    name: "Malawi",
    isoCode: "MW",
    states: [
      {
        name: "Balaka District",
        isoCode: "BA",
      },
      {
        name: "Blantyre District",
        isoCode: "BL",
      },
      {
        name: "Central Region",
        isoCode: "C",
      },
      {
        name: "Chikwawa District",
        isoCode: "CK",
      },
      {
        name: "Chiradzulu District",
        isoCode: "CR",
      },
      {
        name: "Chitipa district",
        isoCode: "CT",
      },
      {
        name: "Dedza District",
        isoCode: "DE",
      },
      {
        name: "Dowa District",
        isoCode: "DO",
      },
      {
        name: "Karonga District",
        isoCode: "KR",
      },
      {
        name: "Kasungu District",
        isoCode: "KS",
      },
      {
        name: "Likoma District",
        isoCode: "LK",
      },
      {
        name: "Lilongwe District",
        isoCode: "LI",
      },
      {
        name: "Machinga District",
        isoCode: "MH",
      },
      {
        name: "Mangochi District",
        isoCode: "MG",
      },
      {
        name: "Mchinji District",
        isoCode: "MC",
      },
      {
        name: "Mulanje District",
        isoCode: "MU",
      },
      {
        name: "Mwanza District",
        isoCode: "MW",
      },
      {
        name: "Mzimba District",
        isoCode: "MZ",
      },
      {
        name: "Nkhata Bay District",
        isoCode: "NB",
      },
      {
        name: "Nkhotakota District",
        isoCode: "NK",
      },
      {
        name: "Northern Region",
        isoCode: "N",
      },
      {
        name: "Nsanje District",
        isoCode: "NS",
      },
      {
        name: "Ntcheu District",
        isoCode: "NU",
      },
      {
        name: "Ntchisi District",
        isoCode: "NI",
      },
      {
        name: "Phalombe District",
        isoCode: "PH",
      },
      {
        name: "Rumphi District",
        isoCode: "RU",
      },
      {
        name: "Salima District",
        isoCode: "SA",
      },
      {
        name: "Southern Region",
        isoCode: "S",
      },
      {
        name: "Thyolo District",
        isoCode: "TH",
      },
      {
        name: "Zomba District",
        isoCode: "ZO",
      },
    ],
  },
  {
    name: "Malaysia",
    isoCode: "MY",
    states: [
      {
        name: "Johor",
        isoCode: "01",
      },
      {
        name: "Kedah",
        isoCode: "02",
      },
      {
        name: "Kelantan",
        isoCode: "03",
      },
      {
        name: "Kuala Lumpur",
        isoCode: "14",
      },
      {
        name: "Labuan",
        isoCode: "15",
      },
      {
        name: "Malacca",
        isoCode: "04",
      },
      {
        name: "Negeri Sembilan",
        isoCode: "05",
      },
      {
        name: "Pahang",
        isoCode: "06",
      },
      {
        name: "Penang",
        isoCode: "07",
      },
      {
        name: "Perak",
        isoCode: "08",
      },
      {
        name: "Perlis",
        isoCode: "09",
      },
      {
        name: "Putrajaya",
        isoCode: "16",
      },
      {
        name: "Sabah",
        isoCode: "12",
      },
      {
        name: "Sarawak",
        isoCode: "13",
      },
      {
        name: "Selangor",
        isoCode: "10",
      },
      {
        name: "Terengganu",
        isoCode: "11",
      },
    ],
  },
  {
    name: "Maldives",
    isoCode: "MV",
    states: [
      {
        name: "Addu Atoll",
        isoCode: "01",
      },
      {
        name: "Alif Alif Atoll",
        isoCode: "02",
      },
      {
        name: "Alif Dhaal Atoll",
        isoCode: "00",
      },
      {
        name: "Central Province",
        isoCode: "CE",
      },
      {
        name: "Dhaalu Atoll",
        isoCode: "17",
      },
      {
        name: "Faafu Atoll",
        isoCode: "14",
      },
      {
        name: "Gaafu Alif Atoll",
        isoCode: "27",
      },
      {
        name: "Gaafu Dhaalu Atoll",
        isoCode: "28",
      },
      {
        name: "Gnaviyani Atoll",
        isoCode: "29",
      },
      {
        name: "Haa Alif Atoll",
        isoCode: "07",
      },
      {
        name: "Haa Dhaalu Atoll",
        isoCode: "23",
      },
      {
        name: "Kaafu Atoll",
        isoCode: "26",
      },
      {
        name: "Laamu Atoll",
        isoCode: "05",
      },
      {
        name: "Lhaviyani Atoll",
        isoCode: "03",
      },
      {
        name: "Malé",
        isoCode: "MLE",
      },
      {
        name: "Meemu Atoll",
        isoCode: "12",
      },
      {
        name: "Noonu Atoll",
        isoCode: "25",
      },
      {
        name: "North Central Province",
        isoCode: "NC",
      },
      {
        name: "North Province",
        isoCode: "NO",
      },
      {
        name: "Raa Atoll",
        isoCode: "13",
      },
      {
        name: "Shaviyani Atoll",
        isoCode: "24",
      },
      {
        name: "South Central Province",
        isoCode: "SC",
      },
      {
        name: "South Province",
        isoCode: "SU",
      },
      {
        name: "Thaa Atoll",
        isoCode: "08",
      },
      {
        name: "Upper South Province",
        isoCode: "US",
      },
      {
        name: "Vaavu Atoll",
        isoCode: "04",
      },
    ],
  },
  {
    name: "Mali",
    isoCode: "ML",
    states: [
      {
        name: "Bamako",
        isoCode: "BKO",
      },
      {
        name: "Gao Region",
        isoCode: "7",
      },
      {
        name: "Kayes Region",
        isoCode: "1",
      },
      {
        name: "Kidal Region",
        isoCode: "8",
      },
      {
        name: "Koulikoro Region",
        isoCode: "2",
      },
      {
        name: "Ménaka Region",
        isoCode: "9",
      },
      {
        name: "Mopti Region",
        isoCode: "5",
      },
      {
        name: "Ségou Region",
        isoCode: "4",
      },
      {
        name: "Sikasso Region",
        isoCode: "3",
      },
      {
        name: "Taoudénit Region",
        isoCode: "10",
      },
      {
        name: "Tombouctou Region",
        isoCode: "6",
      },
    ],
  },
  {
    name: "Malta",
    isoCode: "MT",
    states: [
      {
        name: "Attard",
        isoCode: "01",
      },
      {
        name: "Balzan",
        isoCode: "02",
      },
      {
        name: "Birgu",
        isoCode: "03",
      },
      {
        name: "Birkirkara",
        isoCode: "04",
      },
      {
        name: "Birżebbuġa",
        isoCode: "05",
      },
      {
        name: "Cospicua",
        isoCode: "06",
      },
      {
        name: "Dingli",
        isoCode: "07",
      },
      {
        name: "Fgura",
        isoCode: "08",
      },
      {
        name: "Floriana",
        isoCode: "09",
      },
      {
        name: "Fontana",
        isoCode: "10",
      },
      {
        name: "Għajnsielem",
        isoCode: "13",
      },
      {
        name: "Għarb",
        isoCode: "14",
      },
      {
        name: "Għargħur",
        isoCode: "15",
      },
      {
        name: "Għasri",
        isoCode: "16",
      },
      {
        name: "Għaxaq",
        isoCode: "17",
      },
      {
        name: "Gudja",
        isoCode: "11",
      },
      {
        name: "Gżira",
        isoCode: "12",
      },
      {
        name: "Ħamrun",
        isoCode: "18",
      },
      {
        name: "Iklin",
        isoCode: "19",
      },
      {
        name: "Kalkara",
        isoCode: "21",
      },
      {
        name: "Kerċem",
        isoCode: "22",
      },
      {
        name: "Kirkop",
        isoCode: "23",
      },
      {
        name: "Lija",
        isoCode: "24",
      },
      {
        name: "Luqa",
        isoCode: "25",
      },
      {
        name: "Marsa",
        isoCode: "26",
      },
      {
        name: "Marsaskala",
        isoCode: "27",
      },
      {
        name: "Marsaxlokk",
        isoCode: "28",
      },
      {
        name: "Mdina",
        isoCode: "29",
      },
      {
        name: "Mellieħa",
        isoCode: "30",
      },
      {
        name: "Mġarr",
        isoCode: "31",
      },
      {
        name: "Mosta",
        isoCode: "32",
      },
      {
        name: "Mqabba",
        isoCode: "33",
      },
      {
        name: "Msida",
        isoCode: "34",
      },
      {
        name: "Mtarfa",
        isoCode: "35",
      },
      {
        name: "Munxar",
        isoCode: "36",
      },
      {
        name: "Nadur",
        isoCode: "37",
      },
      {
        name: "Naxxar",
        isoCode: "38",
      },
      {
        name: "Paola",
        isoCode: "39",
      },
      {
        name: "Pembroke",
        isoCode: "40",
      },
      {
        name: "Pietà",
        isoCode: "41",
      },
      {
        name: "Qala",
        isoCode: "42",
      },
      {
        name: "Qormi",
        isoCode: "43",
      },
      {
        name: "Qrendi",
        isoCode: "44",
      },
      {
        name: "Rabat",
        isoCode: "46",
      },
      {
        name: "Saint Lawrence",
        isoCode: "50",
      },
      {
        name: "San Ġwann",
        isoCode: "49",
      },
      {
        name: "Sannat",
        isoCode: "52",
      },
      {
        name: "Santa Luċija",
        isoCode: "53",
      },
      {
        name: "Santa Venera",
        isoCode: "54",
      },
      {
        name: "Senglea",
        isoCode: "20",
      },
      {
        name: "Siġġiewi",
        isoCode: "55",
      },
      {
        name: "Sliema",
        isoCode: "56",
      },
      {
        name: "St. Julian's",
        isoCode: "48",
      },
      {
        name: "St. Paul's Bay",
        isoCode: "51",
      },
      {
        name: "Swieqi",
        isoCode: "57",
      },
      {
        name: "Ta' Xbiex",
        isoCode: "58",
      },
      {
        name: "Tarxien",
        isoCode: "59",
      },
      {
        name: "Valletta",
        isoCode: "60",
      },
      {
        name: "Victoria",
        isoCode: "45",
      },
      {
        name: "Xagħra",
        isoCode: "61",
      },
      {
        name: "Xewkija",
        isoCode: "62",
      },
      {
        name: "Xgħajra",
        isoCode: "63",
      },
      {
        name: "Żabbar",
        isoCode: "64",
      },
      {
        name: "Żebbuġ Gozo",
        isoCode: "65",
      },
      {
        name: "Żebbuġ Malta",
        isoCode: "66",
      },
      {
        name: "Żejtun",
        isoCode: "67",
      },
      {
        name: "Żurrieq",
        isoCode: "68",
      },
    ],
  },
  {
    name: "Man (Isle of)",
    isoCode: "IM",
    states: [],
  },
  {
    name: "Marshall Islands",
    isoCode: "MH",
    states: [
      {
        name: "Ralik Chain",
        isoCode: "L",
      },
      {
        name: "Ratak Chain",
        isoCode: "T",
      },
    ],
  },
  {
    name: "Martinique",
    isoCode: "MQ",
    states: [],
  },
  {
    name: "Mauritania",
    isoCode: "MR",
    states: [
      {
        name: "Adrar",
        isoCode: "07",
      },
      {
        name: "Assaba",
        isoCode: "03",
      },
      {
        name: "Brakna",
        isoCode: "05",
      },
      {
        name: "Dakhlet Nouadhibou",
        isoCode: "08",
      },
      {
        name: "Gorgol",
        isoCode: "04",
      },
      {
        name: "Guidimaka",
        isoCode: "10",
      },
      {
        name: "Hodh Ech Chargui",
        isoCode: "01",
      },
      {
        name: "Hodh El Gharbi",
        isoCode: "02",
      },
      {
        name: "Inchiri",
        isoCode: "12",
      },
      {
        name: "Nouakchott-Nord",
        isoCode: "14",
      },
      {
        name: "Nouakchott-Ouest",
        isoCode: "13",
      },
      {
        name: "Nouakchott-Sud",
        isoCode: "15",
      },
      {
        name: "Tagant",
        isoCode: "09",
      },
      {
        name: "Tiris Zemmour",
        isoCode: "11",
      },
      {
        name: "Trarza",
        isoCode: "06",
      },
    ],
  },
  {
    name: "Mauritius",
    isoCode: "MU",
    states: [
      {
        name: "Agaléga",
        isoCode: "AG",
      },
      {
        name: "Beau Bassin-Rose Hill",
        isoCode: "BR",
      },
      {
        name: "Cargados Carajos",
        isoCode: "CC",
      },
      {
        name: "Curepipe",
        isoCode: "CU",
      },
      {
        name: "Flacq District",
        isoCode: "FL",
      },
      {
        name: "Grand Port District",
        isoCode: "GP",
      },
      {
        name: "Moka District",
        isoCode: "MO",
      },
      {
        name: "Pamplemousses District",
        isoCode: "PA",
      },
      {
        name: "Plaines Wilhems District",
        isoCode: "PW",
      },
      {
        name: "Port Louis",
        isoCode: "PU",
      },
      {
        name: "Port Louis District",
        isoCode: "PL",
      },
      {
        name: "Quatre Bornes",
        isoCode: "QB",
      },
      {
        name: "Rivière du Rempart District",
        isoCode: "RR",
      },
      {
        name: "Rivière Noire District",
        isoCode: "BL",
      },
      {
        name: "Rodrigues",
        isoCode: "RO",
      },
      {
        name: "Savanne District",
        isoCode: "SA",
      },
      {
        name: "Vacoas-Phoenix",
        isoCode: "VP",
      },
    ],
  },
  {
    name: "Mayotte",
    isoCode: "YT",
    states: [],
  },
  {
    name: "Mexico",
    isoCode: "MX",
    states: [
      {
        name: "Aguascalientes",
        isoCode: "AGU",
      },
      {
        name: "Baja California",
        isoCode: "BCN",
      },
      {
        name: "Baja California Sur",
        isoCode: "BCS",
      },
      {
        name: "Campeche",
        isoCode: "CAM",
      },
      {
        name: "Chiapas",
        isoCode: "CHP",
      },
      {
        name: "Chihuahua",
        isoCode: "CHH",
      },
      {
        name: "Ciudad de México",
        isoCode: "CDMX",
      },
      {
        name: "Coahuila de Zaragoza",
        isoCode: "COA",
      },
      {
        name: "Colima",
        isoCode: "COL",
      },
      {
        name: "Durango",
        isoCode: "DUR",
      },
      {
        name: "Estado de México",
        isoCode: "MEX",
      },
      {
        name: "Guanajuato",
        isoCode: "GUA",
      },
      {
        name: "Guerrero",
        isoCode: "GRO",
      },
      {
        name: "Hidalgo",
        isoCode: "HID",
      },
      {
        name: "Jalisco",
        isoCode: "JAL",
      },
      {
        name: "Michoacán de Ocampo",
        isoCode: "MIC",
      },
      {
        name: "Morelos",
        isoCode: "MOR",
      },
      {
        name: "Nayarit",
        isoCode: "NAY",
      },
      {
        name: "Nuevo León",
        isoCode: "NLE",
      },
      {
        name: "Oaxaca",
        isoCode: "OAX",
      },
      {
        name: "Puebla",
        isoCode: "PUE",
      },
      {
        name: "Querétaro",
        isoCode: "QUE",
      },
      {
        name: "Quintana Roo",
        isoCode: "ROO",
      },
      {
        name: "San Luis Potosí",
        isoCode: "SLP",
      },
      {
        name: "Sinaloa",
        isoCode: "SIN",
      },
      {
        name: "Sonora",
        isoCode: "SON",
      },
      {
        name: "Tabasco",
        isoCode: "TAB",
      },
      {
        name: "Tamaulipas",
        isoCode: "TAM",
      },
      {
        name: "Tlaxcala",
        isoCode: "TLA",
      },
      {
        name: "Veracruz de Ignacio de la Llave",
        isoCode: "VER",
      },
      {
        name: "Yucatán",
        isoCode: "YUC",
      },
      {
        name: "Zacatecas",
        isoCode: "ZAC",
      },
    ],
  },
  {
    name: "Micronesia",
    isoCode: "FM",
    states: [
      {
        name: "Chuuk State",
        isoCode: "TRK",
      },
      {
        name: "Kosrae State",
        isoCode: "KSA",
      },
      {
        name: "Pohnpei State",
        isoCode: "PNI",
      },
      {
        name: "Yap State",
        isoCode: "YAP",
      },
    ],
  },
  {
    name: "Moldova",
    isoCode: "MD",
    states: [
      {
        name: "Anenii Noi District",
        isoCode: "AN",
      },
      {
        name: "Bălți Municipality",
        isoCode: "BA",
      },
      {
        name: "Basarabeasca District",
        isoCode: "BS",
      },
      {
        name: "Bender Municipality",
        isoCode: "BD",
      },
      {
        name: "Briceni District",
        isoCode: "BR",
      },
      {
        name: "Cahul District",
        isoCode: "CA",
      },
      {
        name: "Călărași District",
        isoCode: "CL",
      },
      {
        name: "Cantemir District",
        isoCode: "CT",
      },
      {
        name: "Căușeni District",
        isoCode: "CS",
      },
      {
        name: "Chișinău Municipality",
        isoCode: "CU",
      },
      {
        name: "Cimișlia District",
        isoCode: "CM",
      },
      {
        name: "Criuleni District",
        isoCode: "CR",
      },
      {
        name: "Dondușeni District",
        isoCode: "DO",
      },
      {
        name: "Drochia District",
        isoCode: "DR",
      },
      {
        name: "Dubăsari District",
        isoCode: "DU",
      },
      {
        name: "Edineț District",
        isoCode: "ED",
      },
      {
        name: "Fălești District",
        isoCode: "FA",
      },
      {
        name: "Florești District",
        isoCode: "FL",
      },
      {
        name: "Gagauzia",
        isoCode: "GA",
      },
      {
        name: "Glodeni District",
        isoCode: "GL",
      },
      {
        name: "Hîncești District",
        isoCode: "HI",
      },
      {
        name: "Ialoveni District",
        isoCode: "IA",
      },
      {
        name: "Nisporeni District",
        isoCode: "NI",
      },
      {
        name: "Ocnița District",
        isoCode: "OC",
      },
      {
        name: "Orhei District",
        isoCode: "OR",
      },
      {
        name: "Rezina District",
        isoCode: "RE",
      },
      {
        name: "Rîșcani District",
        isoCode: "RI",
      },
      {
        name: "Sîngerei District",
        isoCode: "SI",
      },
      {
        name: "Șoldănești District",
        isoCode: "SD",
      },
      {
        name: "Soroca District",
        isoCode: "SO",
      },
      {
        name: "Ștefan Vodă District",
        isoCode: "SV",
      },
      {
        name: "Strășeni District",
        isoCode: "ST",
      },
      {
        name: "Taraclia District",
        isoCode: "TA",
      },
      {
        name: "Telenești District",
        isoCode: "TE",
      },
      {
        name: "Transnistria autonomous territorial unit",
        isoCode: "SN",
      },
      {
        name: "Ungheni District",
        isoCode: "UN",
      },
    ],
  },
  {
    name: "Monaco",
    isoCode: "MC",
    states: [
      {
        name: "La Colle",
        isoCode: "CL",
      },
      {
        name: "La Condamine",
        isoCode: "CO",
      },
      {
        name: "Moneghetti",
        isoCode: "MG",
      },
    ],
  },
  {
    name: "Mongolia",
    isoCode: "MN",
    states: [
      {
        name: "Arkhangai Province",
        isoCode: "073",
      },
      {
        name: "Bayan-Ölgii Province",
        isoCode: "071",
      },
      {
        name: "Bayankhongor Province",
        isoCode: "069",
      },
      {
        name: "Bulgan Province",
        isoCode: "067",
      },
      {
        name: "Darkhan-Uul Province",
        isoCode: "037",
      },
      {
        name: "Dornod Province",
        isoCode: "061",
      },
      {
        name: "Dornogovi Province",
        isoCode: "063",
      },
      {
        name: "Dundgovi Province",
        isoCode: "059",
      },
      {
        name: "Govi-Altai Province",
        isoCode: "065",
      },
      {
        name: "Govisümber Province",
        isoCode: "064",
      },
      {
        name: "Khentii Province",
        isoCode: "039",
      },
      {
        name: "Khovd Province",
        isoCode: "043",
      },
      {
        name: "Khövsgöl Province",
        isoCode: "041",
      },
      {
        name: "Ömnögovi Province",
        isoCode: "053",
      },
      {
        name: "Orkhon Province",
        isoCode: "035",
      },
      {
        name: "Övörkhangai Province",
        isoCode: "055",
      },
      {
        name: "Selenge Province",
        isoCode: "049",
      },
      {
        name: "Sükhbaatar Province",
        isoCode: "051",
      },
      {
        name: "Töv Province",
        isoCode: "047",
      },
      {
        name: "Uvs Province",
        isoCode: "046",
      },
      {
        name: "Zavkhan Province",
        isoCode: "057",
      },
    ],
  },
  {
    name: "Montenegro",
    isoCode: "ME",
    states: [
      {
        name: "Andrijevica Municipality",
        isoCode: "01",
      },
      {
        name: "Bar Municipality",
        isoCode: "02",
      },
      {
        name: "Berane Municipality",
        isoCode: "03",
      },
      {
        name: "Bijelo Polje Municipality",
        isoCode: "04",
      },
      {
        name: "Budva Municipality",
        isoCode: "05",
      },
      {
        name: "Danilovgrad Municipality",
        isoCode: "07",
      },
      {
        name: "Gusinje Municipality",
        isoCode: "22",
      },
      {
        name: "Kolašin Municipality",
        isoCode: "09",
      },
      {
        name: "Kotor Municipality",
        isoCode: "10",
      },
      {
        name: "Mojkovac Municipality",
        isoCode: "11",
      },
      {
        name: "Nikšić Municipality",
        isoCode: "12",
      },
      {
        name: "Old Royal Capital Cetinje",
        isoCode: "06",
      },
      {
        name: "Petnjica Municipality",
        isoCode: "23",
      },
      {
        name: "Plav Municipality",
        isoCode: "13",
      },
      {
        name: "Pljevlja Municipality",
        isoCode: "14",
      },
      {
        name: "Plužine Municipality",
        isoCode: "15",
      },
      {
        name: "Podgorica Municipality",
        isoCode: "16",
      },
      {
        name: "Rožaje Municipality",
        isoCode: "17",
      },
      {
        name: "Šavnik Municipality",
        isoCode: "18",
      },
      {
        name: "Tivat Municipality",
        isoCode: "19",
      },
      {
        name: "Ulcinj Municipality",
        isoCode: "20",
      },
      {
        name: "Žabljak Municipality",
        isoCode: "21",
      },
    ],
  },
  {
    name: "Montserrat",
    isoCode: "MS",
    states: [],
  },
  {
    name: "Morocco",
    isoCode: "MA",
    states: [
      {
        name: "Agadir-Ida-Ou-Tanane",
        isoCode: "AGD",
      },
      {
        name: "Al Haouz",
        isoCode: "HAO",
      },
      {
        name: "Al Hoceïma",
        isoCode: "HOC",
      },
      {
        name: "Aousserd (EH)",
        isoCode: "AOU",
      },
      {
        name: "Assa-Zag (EH-partial)",
        isoCode: "ASZ",
      },
      {
        name: "Azilal",
        isoCode: "AZI",
      },
      {
        name: "Béni Mellal",
        isoCode: "BEM",
      },
      {
        name: "Béni Mellal-Khénifra",
        isoCode: "05",
      },
      {
        name: "Benslimane",
        isoCode: "BES",
      },
      {
        name: "Berkane",
        isoCode: "BER",
      },
      {
        name: "Berrechid",
        isoCode: "BRR",
      },
      {
        name: "Boujdour (EH)",
        isoCode: "BOD",
      },
      {
        name: "Boulemane",
        isoCode: "BOM",
      },
      {
        name: "Casablanca",
        isoCode: "CAS",
      },
      {
        name: "Casablanca-Settat",
        isoCode: "06",
      },
      {
        name: "Chefchaouen",
        isoCode: "CHE",
      },
      {
        name: "Chichaoua",
        isoCode: "CHI",
      },
      {
        name: "Chtouka-Ait Baha",
        isoCode: "CHT",
      },
      {
        name: "Dakhla-Oued Ed-Dahab (EH)",
        isoCode: "12",
      },
      {
        name: "Drâa-Tafilalet",
        isoCode: "08",
      },
      {
        name: "Driouch",
        isoCode: "DRI",
      },
      {
        name: "El Hajeb",
        isoCode: "HAJ",
      },
      {
        name: "El Jadida",
        isoCode: "JDI",
      },
      {
        name: "El Kelâa des Sraghna",
        isoCode: "KES",
      },
      {
        name: "Errachidia",
        isoCode: "ERR",
      },
      {
        name: "Es-Semara (EH-partial)",
        isoCode: "ESM",
      },
      {
        name: "Essaouira",
        isoCode: "ESI",
      },
      {
        name: "Fahs-Anjra",
        isoCode: "FAH",
      },
      {
        name: "Fès",
        isoCode: "FES",
      },
      {
        name: "Fès-Meknès",
        isoCode: "03",
      },
      {
        name: "Figuig",
        isoCode: "FIG",
      },
      {
        name: "Fquih Ben Salah",
        isoCode: "FQH",
      },
      {
        name: "Guelmim",
        isoCode: "GUE",
      },
      {
        name: "Guelmim-Oued Noun (EH-partial)",
        isoCode: "10",
      },
      {
        name: "Guercif",
        isoCode: "GUF",
      },
      {
        name: "Ifrane",
        isoCode: "IFR",
      },
      {
        name: "Inezgane-Ait Melloul",
        isoCode: "INE",
      },
      {
        name: "Jerada",
        isoCode: "JRA",
      },
      {
        name: "Kénitra",
        isoCode: "KEN",
      },
      {
        name: "Khémisset",
        isoCode: "KHE",
      },
      {
        name: "Khénifra",
        isoCode: "KHN",
      },
      {
        name: "Khouribga",
        isoCode: "KHO",
      },
      {
        name: "L'Oriental",
        isoCode: "02",
      },
      {
        name: "Laâyoune (EH)",
        isoCode: "LAA",
      },
      {
        name: "Laâyoune-Sakia El Hamra (EH-partial)",
        isoCode: "11",
      },
      {
        name: "Larache",
        isoCode: "LAR",
      },
      {
        name: "M’diq-Fnideq",
        isoCode: "MDF",
      },
      {
        name: "Marrakech",
        isoCode: "MAR",
      },
      {
        name: "Marrakesh-Safi",
        isoCode: "07",
      },
      {
        name: "Médiouna",
        isoCode: "MED",
      },
      {
        name: "Meknès",
        isoCode: "MEK",
      },
      {
        name: "Midelt",
        isoCode: "MID",
      },
      {
        name: "Mohammadia",
        isoCode: "MOH",
      },
      {
        name: "Moulay Yacoub",
        isoCode: "MOU",
      },
      {
        name: "Nador",
        isoCode: "NAD",
      },
      {
        name: "Nouaceur",
        isoCode: "NOU",
      },
      {
        name: "Ouarzazate",
        isoCode: "OUA",
      },
      {
        name: "Oued Ed-Dahab (EH)",
        isoCode: "OUD",
      },
      {
        name: "Ouezzane",
        isoCode: "OUZ",
      },
      {
        name: "Oujda-Angad",
        isoCode: "OUJ",
      },
      {
        name: "Rabat",
        isoCode: "RAB",
      },
      {
        name: "Rabat-Salé-Kénitra",
        isoCode: "04",
      },
      {
        name: "Rehamna",
        isoCode: "REH",
      },
      {
        name: "Safi",
        isoCode: "SAF",
      },
      {
        name: "Salé",
        isoCode: "SAL",
      },
      {
        name: "Sefrou",
        isoCode: "SEF",
      },
      {
        name: "Settat",
        isoCode: "SET",
      },
      {
        name: "Sidi Bennour",
        isoCode: "SIB",
      },
      {
        name: "Sidi Ifni",
        isoCode: "SIF",
      },
      {
        name: "Sidi Kacem",
        isoCode: "SIK",
      },
      {
        name: "Sidi Slimane",
        isoCode: "SIL",
      },
      {
        name: "Skhirate-Témara",
        isoCode: "SKH",
      },
      {
        name: "Souss-Massa",
        isoCode: "09",
      },
      {
        name: "Tan-Tan (EH-partial)",
        isoCode: "TNT",
      },
      {
        name: "Tanger-Assilah",
        isoCode: "TNG",
      },
      {
        name: "Tanger-Tétouan-Al Hoceïma",
        isoCode: "01",
      },
      {
        name: "Taounate",
        isoCode: "TAO",
      },
      {
        name: "Taourirt",
        isoCode: "TAI",
      },
      {
        name: "Tarfaya (EH-partial)",
        isoCode: "TAF",
      },
      {
        name: "Taroudannt",
        isoCode: "TAR",
      },
      {
        name: "Tata",
        isoCode: "TAT",
      },
      {
        name: "Taza",
        isoCode: "TAZ",
      },
      {
        name: "Tétouan",
        isoCode: "TET",
      },
      {
        name: "Tinghir",
        isoCode: "TIN",
      },
      {
        name: "Tiznit",
        isoCode: "TIZ",
      },
      {
        name: "Youssoufia",
        isoCode: "YUS",
      },
      {
        name: "Zagora",
        isoCode: "ZAG",
      },
    ],
  },
  {
    name: "Mozambique",
    isoCode: "MZ",
    states: [
      {
        name: "Cabo Delgado Province",
        isoCode: "P",
      },
      {
        name: "Gaza Province",
        isoCode: "G",
      },
      {
        name: "Inhambane Province",
        isoCode: "I",
      },
      {
        name: "Manica Province",
        isoCode: "B",
      },
      {
        name: "Maputo",
        isoCode: "MPM",
      },
      {
        name: "Maputo Province",
        isoCode: "L",
      },
      {
        name: "Nampula Province",
        isoCode: "N",
      },
      {
        name: "Niassa Province",
        isoCode: "A",
      },
      {
        name: "Sofala Province",
        isoCode: "S",
      },
      {
        name: "Tete Province",
        isoCode: "T",
      },
      {
        name: "Zambezia Province",
        isoCode: "Q",
      },
    ],
  },
  {
    name: "Myanmar",
    isoCode: "MM",
    states: [
      {
        name: "Ayeyarwady Region",
        isoCode: "07",
      },
      {
        name: "Bago",
        isoCode: "02",
      },
      {
        name: "Chin State",
        isoCode: "14",
      },
      {
        name: "Kachin State",
        isoCode: "11",
      },
      {
        name: "Kayah State",
        isoCode: "12",
      },
      {
        name: "Kayin State",
        isoCode: "13",
      },
      {
        name: "Magway Region",
        isoCode: "03",
      },
      {
        name: "Mandalay Region",
        isoCode: "04",
      },
      {
        name: "Mon State",
        isoCode: "15",
      },
      {
        name: "Naypyidaw Union Territory",
        isoCode: "18",
      },
      {
        name: "Rakhine State",
        isoCode: "16",
      },
      {
        name: "Sagaing Region",
        isoCode: "01",
      },
      {
        name: "Shan State",
        isoCode: "17",
      },
      {
        name: "Tanintharyi Region",
        isoCode: "05",
      },
      {
        name: "Yangon Region",
        isoCode: "06",
      },
    ],
  },
  {
    name: "Namibia",
    isoCode: "NA",
    states: [
      {
        name: "Erongo Region",
        isoCode: "ER",
      },
      {
        name: "Hardap Region",
        isoCode: "HA",
      },
      {
        name: "Karas Region",
        isoCode: "KA",
      },
      {
        name: "Kavango East Region",
        isoCode: "KE",
      },
      {
        name: "Kavango West Region",
        isoCode: "KW",
      },
      {
        name: "Khomas Region",
        isoCode: "KH",
      },
      {
        name: "Kunene Region",
        isoCode: "KU",
      },
      {
        name: "Ohangwena Region",
        isoCode: "OW",
      },
      {
        name: "Omaheke Region",
        isoCode: "OH",
      },
      {
        name: "Omusati Region",
        isoCode: "OS",
      },
      {
        name: "Oshana Region",
        isoCode: "ON",
      },
      {
        name: "Oshikoto Region",
        isoCode: "OT",
      },
      {
        name: "Otjozondjupa Region",
        isoCode: "OD",
      },
      {
        name: "Zambezi Region",
        isoCode: "CA",
      },
    ],
  },
  {
    name: "Nauru",
    isoCode: "NR",
    states: [
      {
        name: "Aiwo District",
        isoCode: "01",
      },
      {
        name: "Anabar District",
        isoCode: "02",
      },
      {
        name: "Anetan District",
        isoCode: "03",
      },
      {
        name: "Anibare District",
        isoCode: "04",
      },
      {
        name: "Baiti District",
        isoCode: "05",
      },
      {
        name: "Boe District",
        isoCode: "06",
      },
      {
        name: "Buada District",
        isoCode: "07",
      },
      {
        name: "Denigomodu District",
        isoCode: "08",
      },
      {
        name: "Ewa District",
        isoCode: "09",
      },
      {
        name: "Ijuw District",
        isoCode: "10",
      },
      {
        name: "Meneng District",
        isoCode: "11",
      },
      {
        name: "Nibok District",
        isoCode: "12",
      },
      {
        name: "Uaboe District",
        isoCode: "13",
      },
      {
        name: "Yaren District",
        isoCode: "14",
      },
    ],
  },
  {
    name: "Nepal",
    isoCode: "NP",
    states: [
      {
        name: "Bagmati Zone",
        isoCode: "BA",
      },
      {
        name: "Bheri Zone",
        isoCode: "BH",
      },
      {
        name: "Central Region",
        isoCode: "1",
      },
      {
        name: "Dhaulagiri Zone",
        isoCode: "DH",
      },
      {
        name: "Eastern Development Region",
        isoCode: "4",
      },
      {
        name: "Far-Western Development Region",
        isoCode: "5",
      },
      {
        name: "Gandaki Zone",
        isoCode: "GA",
      },
      {
        name: "Janakpur Zone",
        isoCode: "JA",
      },
      {
        name: "Karnali Zone",
        isoCode: "KA",
      },
      {
        name: "Kosi Zone",
        isoCode: "KO",
      },
      {
        name: "Lumbini Zone",
        isoCode: "LU",
      },
      {
        name: "Mahakali Zone",
        isoCode: "MA",
      },
      {
        name: "Mechi Zone",
        isoCode: "ME",
      },
      {
        name: "Mid-Western Region",
        isoCode: "2",
      },
      {
        name: "Narayani Zone",
        isoCode: "NA",
      },
      {
        name: "Rapti Zone",
        isoCode: "RA",
      },
      {
        name: "Sagarmatha Zone",
        isoCode: "SA",
      },
      {
        name: "Seti Zone",
        isoCode: "SE",
      },
      {
        name: "Western Region",
        isoCode: "3",
      },
    ],
  },
  {
    name: "Netherlands",
    isoCode: "NL",
    states: [
      {
        name: "Bonaire",
        isoCode: "BQ1",
      },
      {
        name: "Drenthe",
        isoCode: "DR",
      },
      {
        name: "Flevoland",
        isoCode: "FL",
      },
      {
        name: "Friesland",
        isoCode: "FR",
      },
      {
        name: "Gelderland",
        isoCode: "GE",
      },
      {
        name: "Groningen",
        isoCode: "GR",
      },
      {
        name: "Limburg",
        isoCode: "LI",
      },
      {
        name: "North Brabant",
        isoCode: "NB",
      },
      {
        name: "North Holland",
        isoCode: "NH",
      },
      {
        name: "Overijssel",
        isoCode: "OV",
      },
      {
        name: "Saba",
        isoCode: "BQ2",
      },
      {
        name: "Sint Eustatius",
        isoCode: "BQ3",
      },
      {
        name: "South Holland",
        isoCode: "ZH",
      },
      {
        name: "Utrecht",
        isoCode: "UT",
      },
      {
        name: "Zeeland",
        isoCode: "ZE",
      },
    ],
  },
  {
    name: "New Caledonia",
    isoCode: "NC",
    states: [],
  },
  {
    name: "New Zealand",
    isoCode: "NZ",
    states: [
      {
        name: "Auckland Region",
        isoCode: "AUK",
      },
      {
        name: "Bay of Plenty Region",
        isoCode: "BOP",
      },
      {
        name: "Canterbury Region",
        isoCode: "CAN",
      },
      {
        name: "Chatham Islands",
        isoCode: "CIT",
      },
      {
        name: "Gisborne District",
        isoCode: "GIS",
      },
      {
        name: "Hawke's Bay Region",
        isoCode: "HKB",
      },
      {
        name: "Manawatu-Wanganui Region",
        isoCode: "MWT",
      },
      {
        name: "Marlborough Region",
        isoCode: "MBH",
      },
      {
        name: "Nelson Region",
        isoCode: "NSN",
      },
      {
        name: "Northland Region",
        isoCode: "NTL",
      },
      {
        name: "Otago Region",
        isoCode: "OTA",
      },
      {
        name: "Southland Region",
        isoCode: "STL",
      },
      {
        name: "Taranaki Region",
        isoCode: "TKI",
      },
      {
        name: "Tasman District",
        isoCode: "TAS",
      },
      {
        name: "Waikato Region",
        isoCode: "WKO",
      },
      {
        name: "Wellington Region",
        isoCode: "WGN",
      },
      {
        name: "West Coast Region",
        isoCode: "WTC",
      },
    ],
  },
  {
    name: "Nicaragua",
    isoCode: "NI",
    states: [
      {
        name: "Boaco",
        isoCode: "BO",
      },
      {
        name: "Carazo",
        isoCode: "CA",
      },
      {
        name: "Chinandega",
        isoCode: "CI",
      },
      {
        name: "Chontales",
        isoCode: "CO",
      },
      {
        name: "Estelí",
        isoCode: "ES",
      },
      {
        name: "Granada",
        isoCode: "GR",
      },
      {
        name: "Jinotega",
        isoCode: "JI",
      },
      {
        name: "León",
        isoCode: "LE",
      },
      {
        name: "Madriz",
        isoCode: "MD",
      },
      {
        name: "Managua",
        isoCode: "MN",
      },
      {
        name: "Masaya",
        isoCode: "MS",
      },
      {
        name: "Matagalpa",
        isoCode: "MT",
      },
      {
        name: "North Caribbean Coast",
        isoCode: "AN",
      },
      {
        name: "Nueva Segovia",
        isoCode: "NS",
      },
      {
        name: "Río San Juan",
        isoCode: "SJ",
      },
      {
        name: "Rivas",
        isoCode: "RI",
      },
      {
        name: "South Caribbean Coast",
        isoCode: "AS",
      },
    ],
  },
  {
    name: "Niger",
    isoCode: "NE",
    states: [
      {
        name: "Agadez Region",
        isoCode: "1",
      },
      {
        name: "Diffa Region",
        isoCode: "2",
      },
      {
        name: "Dosso Region",
        isoCode: "3",
      },
      {
        name: "Maradi Region",
        isoCode: "4",
      },
      {
        name: "Tahoua Region",
        isoCode: "5",
      },
      {
        name: "Tillabéri Region",
        isoCode: "6",
      },
      {
        name: "Zinder Region",
        isoCode: "7",
      },
    ],
  },
  {
    name: "Nigeria",
    isoCode: "NG",
    states: [
      {
        name: "Abia",
        isoCode: "AB",
      },
      {
        name: "Abuja Federal Capital Territory",
        isoCode: "FC",
      },
      {
        name: "Adamawa",
        isoCode: "AD",
      },
      {
        name: "Akwa Ibom",
        isoCode: "AK",
      },
      {
        name: "Anambra",
        isoCode: "AN",
      },
      {
        name: "Bauchi",
        isoCode: "BA",
      },
      {
        name: "Bayelsa",
        isoCode: "BY",
      },
      {
        name: "Benue",
        isoCode: "BE",
      },
      {
        name: "Borno",
        isoCode: "BO",
      },
      {
        name: "Cross River",
        isoCode: "CR",
      },
      {
        name: "Delta",
        isoCode: "DE",
      },
      {
        name: "Ebonyi",
        isoCode: "EB",
      },
      {
        name: "Edo",
        isoCode: "ED",
      },
      {
        name: "Ekiti",
        isoCode: "EK",
      },
      {
        name: "Enugu",
        isoCode: "EN",
      },
      {
        name: "Gombe",
        isoCode: "GO",
      },
      {
        name: "Imo",
        isoCode: "IM",
      },
      {
        name: "Jigawa",
        isoCode: "JI",
      },
      {
        name: "Kaduna",
        isoCode: "KD",
      },
      {
        name: "Kano",
        isoCode: "KN",
      },
      {
        name: "Katsina",
        isoCode: "KT",
      },
      {
        name: "Kebbi",
        isoCode: "KE",
      },
      {
        name: "Kogi",
        isoCode: "KO",
      },
      {
        name: "Kwara",
        isoCode: "KW",
      },
      {
        name: "Lagos",
        isoCode: "LA",
      },
      {
        name: "Nasarawa",
        isoCode: "NA",
      },
      {
        name: "Niger",
        isoCode: "NI",
      },
      {
        name: "Ogun",
        isoCode: "OG",
      },
      {
        name: "Ondo",
        isoCode: "ON",
      },
      {
        name: "Osun",
        isoCode: "OS",
      },
      {
        name: "Oyo",
        isoCode: "OY",
      },
      {
        name: "Plateau",
        isoCode: "PL",
      },
      {
        name: "Rivers",
        isoCode: "RI",
      },
      {
        name: "Sokoto",
        isoCode: "SO",
      },
      {
        name: "Taraba",
        isoCode: "TA",
      },
      {
        name: "Yobe",
        isoCode: "YO",
      },
      {
        name: "Zamfara",
        isoCode: "ZA",
      },
    ],
  },
  {
    name: "Niue",
    isoCode: "NU",
    states: [],
  },
  {
    name: "Norfolk Island",
    isoCode: "NF",
    states: [],
  },
  {
    name: "North Korea",
    isoCode: "KP",
    states: [
      {
        name: "Chagang Province",
        isoCode: "04",
      },
      {
        name: "Kangwon Province",
        isoCode: "07",
      },
      {
        name: "North Hamgyong Province",
        isoCode: "09",
      },
      {
        name: "North Hwanghae Province",
        isoCode: "06",
      },
      {
        name: "North Pyongan Province",
        isoCode: "03",
      },
      {
        name: "Pyongyang",
        isoCode: "01",
      },
      {
        name: "Rason",
        isoCode: "13",
      },
      {
        name: "Ryanggang Province",
        isoCode: "10",
      },
      {
        name: "South Hamgyong Province",
        isoCode: "08",
      },
      {
        name: "South Hwanghae Province",
        isoCode: "05",
      },
      {
        name: "South Pyongan Province",
        isoCode: "02",
      },
    ],
  },
  {
    name: "Northern Mariana Islands",
    isoCode: "MP",
    states: [],
  },
  {
    name: "Norway",
    isoCode: "NO",
    states: [
      {
        name: "Akershus",
        isoCode: "02",
      },
      {
        name: "Buskerud",
        isoCode: "06",
      },
      {
        name: "Finnmark",
        isoCode: "20",
      },
      {
        name: "Hedmark",
        isoCode: "04",
      },
      {
        name: "Hordaland",
        isoCode: "12",
      },
      {
        name: "Jan Mayen",
        isoCode: "22",
      },
      {
        name: "Møre og Romsdal",
        isoCode: "15",
      },
      {
        name: "Nord-Trøndelag",
        isoCode: "17",
      },
      {
        name: "Nordland",
        isoCode: "18",
      },
      {
        name: "Oppland",
        isoCode: "05",
      },
      {
        name: "Oslo",
        isoCode: "03",
      },
      {
        name: "Østfold",
        isoCode: "01",
      },
      {
        name: "Rogaland",
        isoCode: "11",
      },
      {
        name: "Sogn og Fjordane",
        isoCode: "14",
      },
      {
        name: "Sør-Trøndelag",
        isoCode: "16",
      },
      {
        name: "Svalbard",
        isoCode: "21",
      },
      {
        name: "Telemark",
        isoCode: "08",
      },
      {
        name: "Troms",
        isoCode: "19",
      },
      {
        name: "Trøndelag",
        isoCode: "50",
      },
      {
        name: "Vest-Agder",
        isoCode: "10",
      },
      {
        name: "Vestfold",
        isoCode: "07",
      },
    ],
  },
  {
    name: "Oman",
    isoCode: "OM",
    states: [
      {
        name: "Ad Dakhiliyah Governorate",
        isoCode: "DA",
      },
      {
        name: "Ad Dhahirah Governorate",
        isoCode: "ZA",
      },
      {
        name: "Al Batinah North Governorate",
        isoCode: "BS",
      },
      {
        name: "Al Batinah Region",
        isoCode: "BA",
      },
      {
        name: "Al Batinah South Governorate",
        isoCode: "BJ",
      },
      {
        name: "Al Buraimi Governorate",
        isoCode: "BU",
      },
      {
        name: "Al Wusta Governorate",
        isoCode: "WU",
      },
      {
        name: "Ash Sharqiyah North Governorate",
        isoCode: "SS",
      },
      {
        name: "Ash Sharqiyah Region",
        isoCode: "SH",
      },
      {
        name: "Ash Sharqiyah South Governorate",
        isoCode: "SJ",
      },
      {
        name: "Dhofar Governorate",
        isoCode: "ZU",
      },
      {
        name: "Musandam Governorate",
        isoCode: "MU",
      },
      {
        name: "Muscat Governorate",
        isoCode: "MA",
      },
    ],
  },
  {
    name: "Pakistan",
    isoCode: "PK",
    states: [
      {
        name: "Azad Kashmir",
        isoCode: "JK",
      },
      {
        name: "Balochistan",
        isoCode: "BA",
      },
      {
        name: "Federally Administered Tribal Areas",
        isoCode: "TA",
      },
      {
        name: "Gilgit-Baltistan",
        isoCode: "GB",
      },
      {
        name: "Islamabad Capital Territory",
        isoCode: "IS",
      },
      {
        name: "Khyber Pakhtunkhwa",
        isoCode: "KP",
      },
      {
        name: "Punjab",
        isoCode: "PB",
      },
      {
        name: "Sindh",
        isoCode: "SD",
      },
    ],
  },
  {
    name: "Palau",
    isoCode: "PW",
    states: [
      {
        name: "Aimeliik",
        isoCode: "002",
      },
      {
        name: "Airai",
        isoCode: "004",
      },
      {
        name: "Angaur",
        isoCode: "010",
      },
      {
        name: "Hatohobei",
        isoCode: "050",
      },
      {
        name: "Kayangel",
        isoCode: "100",
      },
      {
        name: "Koror",
        isoCode: "150",
      },
      {
        name: "Melekeok",
        isoCode: "212",
      },
      {
        name: "Ngaraard",
        isoCode: "214",
      },
      {
        name: "Ngarchelong",
        isoCode: "218",
      },
      {
        name: "Ngardmau",
        isoCode: "222",
      },
      {
        name: "Ngatpang",
        isoCode: "224",
      },
      {
        name: "Ngchesar",
        isoCode: "226",
      },
      {
        name: "Ngeremlengui",
        isoCode: "227",
      },
      {
        name: "Ngiwal",
        isoCode: "228",
      },
      {
        name: "Peleliu",
        isoCode: "350",
      },
      {
        name: "Sonsorol",
        isoCode: "370",
      },
    ],
  },
  {
    name: "Palestinian Territory Occupied",
    isoCode: "PS",
    states: [],
  },
  {
    name: "Panama",
    isoCode: "PA",
    states: [
      {
        name: "Bocas del Toro Province",
        isoCode: "1",
      },
      {
        name: "Chiriquí Province",
        isoCode: "4",
      },
      {
        name: "Coclé Province",
        isoCode: "2",
      },
      {
        name: "Colón Province",
        isoCode: "3",
      },
      {
        name: "Darién Province",
        isoCode: "5",
      },
      {
        name: "Emberá-Wounaan Comarca",
        isoCode: "EM",
      },
      {
        name: "Guna Yala",
        isoCode: "KY",
      },
      {
        name: "Herrera Province",
        isoCode: "6",
      },
      {
        name: "Los Santos Province",
        isoCode: "7",
      },
      {
        name: "Ngöbe-Buglé Comarca",
        isoCode: "NB",
      },
      {
        name: "Panamá Oeste Province",
        isoCode: "10",
      },
      {
        name: "Panamá Province",
        isoCode: "8",
      },
      {
        name: "Veraguas Province",
        isoCode: "9",
      },
    ],
  },
  {
    name: "Papua new Guinea",
    isoCode: "PG",
    states: [
      {
        name: "Bougainville",
        isoCode: "NSB",
      },
      {
        name: "Central Province",
        isoCode: "CPM",
      },
      {
        name: "Chimbu Province",
        isoCode: "CPK",
      },
      {
        name: "East New Britain",
        isoCode: "EBR",
      },
      {
        name: "Eastern Highlands Province",
        isoCode: "EHG",
      },
      {
        name: "Enga Province",
        isoCode: "EPW",
      },
      {
        name: "Gulf",
        isoCode: "GPK",
      },
      {
        name: "Hela",
        isoCode: "HLA",
      },
      {
        name: "Jiwaka Province",
        isoCode: "JWK",
      },
      {
        name: "Madang Province",
        isoCode: "MPM",
      },
      {
        name: "Manus Province",
        isoCode: "MRL",
      },
      {
        name: "Milne Bay Province",
        isoCode: "MBA",
      },
      {
        name: "Morobe Province",
        isoCode: "MPL",
      },
      {
        name: "New Ireland Province",
        isoCode: "NIK",
      },
      {
        name: "Oro Province",
        isoCode: "NPP",
      },
      {
        name: "Port Moresby",
        isoCode: "NCD",
      },
      {
        name: "Sandaun Province",
        isoCode: "SAN",
      },
      {
        name: "Southern Highlands Province",
        isoCode: "SHM",
      },
      {
        name: "West New Britain Province",
        isoCode: "WBK",
      },
      {
        name: "Western Highlands Province",
        isoCode: "WHM",
      },
      {
        name: "Western Province",
        isoCode: "WPD",
      },
    ],
  },
  {
    name: "Paraguay",
    isoCode: "PY",
    states: [
      {
        name: "Alto Paraguay Department",
        isoCode: "16",
      },
      {
        name: "Alto Paraná Department",
        isoCode: "10",
      },
      {
        name: "Amambay Department",
        isoCode: "13",
      },
      {
        name: "Boquerón Department",
        isoCode: "19",
      },
      {
        name: "Caaguazú",
        isoCode: "5",
      },
      {
        name: "Caazapá",
        isoCode: "6",
      },
      {
        name: "Canindeyú",
        isoCode: "14",
      },
      {
        name: "Central Department",
        isoCode: "11",
      },
      {
        name: "Concepción Department",
        isoCode: "1",
      },
      {
        name: "Cordillera Department",
        isoCode: "3",
      },
      {
        name: "Guairá Department",
        isoCode: "4",
      },
      {
        name: "Itapúa",
        isoCode: "7",
      },
      {
        name: "Misiones Department",
        isoCode: "8",
      },
      {
        name: "Ñeembucú Department",
        isoCode: "12",
      },
      {
        name: "Paraguarí Department",
        isoCode: "9",
      },
      {
        name: "Presidente Hayes Department",
        isoCode: "15",
      },
      {
        name: "San Pedro Department",
        isoCode: "2",
      },
    ],
  },
  {
    name: "Peru",
    isoCode: "PE",
    states: [
      {
        name: "Amazonas",
        isoCode: "AMA",
      },
      {
        name: "Áncash",
        isoCode: "ANC",
      },
      {
        name: "Apurímac",
        isoCode: "APU",
      },
      {
        name: "Arequipa",
        isoCode: "ARE",
      },
      {
        name: "Ayacucho",
        isoCode: "AYA",
      },
      {
        name: "Cajamarca",
        isoCode: "CAJ",
      },
      {
        name: "Callao",
        isoCode: "CAL",
      },
      {
        name: "Cusco",
        isoCode: "CUS",
      },
      {
        name: "Huancavelica",
        isoCode: "HUV",
      },
      {
        name: "Huanuco",
        isoCode: "HUC",
      },
      {
        name: "Ica",
        isoCode: "ICA",
      },
      {
        name: "Junín",
        isoCode: "JUN",
      },
      {
        name: "La Libertad",
        isoCode: "LAL",
      },
      {
        name: "Lambayeque",
        isoCode: "LAM",
      },
      {
        name: "Lima",
        isoCode: "LIM",
      },
      {
        name: "Loreto",
        isoCode: "LOR",
      },
      {
        name: "Madre de Dios",
        isoCode: "MDD",
      },
      {
        name: "Moquegua",
        isoCode: "MOQ",
      },
      {
        name: "Pasco",
        isoCode: "PAS",
      },
      {
        name: "Piura",
        isoCode: "PIU",
      },
      {
        name: "Puno",
        isoCode: "PUN",
      },
      {
        name: "San Martín",
        isoCode: "SAM",
      },
      {
        name: "Tacna",
        isoCode: "TAC",
      },
      {
        name: "Tumbes",
        isoCode: "TUM",
      },
      {
        name: "Ucayali",
        isoCode: "UCA",
      },
    ],
  },
  {
    name: "Philippines",
    isoCode: "PH",
    states: [
      {
        name: "Abra",
        isoCode: "ABR",
      },
      {
        name: "Agusan del Norte",
        isoCode: "AGN",
      },
      {
        name: "Agusan del Sur",
        isoCode: "AGS",
      },
      {
        name: "Aklan",
        isoCode: "AKL",
      },
      {
        name: "Albay",
        isoCode: "ALB",
      },
      {
        name: "Antique",
        isoCode: "ANT",
      },
      {
        name: "Apayao",
        isoCode: "APA",
      },
      {
        name: "Aurora",
        isoCode: "AUR",
      },
      {
        name: "Autonomous Region in Muslim Mindanao",
        isoCode: "14",
      },
      {
        name: "Basilan",
        isoCode: "BAS",
      },
      {
        name: "Bataan",
        isoCode: "BAN",
      },
      {
        name: "Batanes",
        isoCode: "BTN",
      },
      {
        name: "Batangas",
        isoCode: "BTG",
      },
      {
        name: "Benguet",
        isoCode: "BEN",
      },
      {
        name: "Bicol Region",
        isoCode: "05",
      },
      {
        name: "Biliran",
        isoCode: "BIL",
      },
      {
        name: "Bohol",
        isoCode: "BOH",
      },
      {
        name: "Bukidnon",
        isoCode: "BUK",
      },
      {
        name: "Bulacan",
        isoCode: "BUL",
      },
      {
        name: "Cagayan",
        isoCode: "CAG",
      },
      {
        name: "Cagayan Valley",
        isoCode: "02",
      },
      {
        name: "Calabarzon",
        isoCode: "40",
      },
      {
        name: "Camarines Norte",
        isoCode: "CAN",
      },
      {
        name: "Camarines Sur",
        isoCode: "CAS",
      },
      {
        name: "Camiguin",
        isoCode: "CAM",
      },
      {
        name: "Capiz",
        isoCode: "CAP",
      },
      {
        name: "Caraga",
        isoCode: "13",
      },
      {
        name: "Catanduanes",
        isoCode: "CAT",
      },
      {
        name: "Cavite",
        isoCode: "CAV",
      },
      {
        name: "Cebu",
        isoCode: "CEB",
      },
      {
        name: "Central Luzon",
        isoCode: "03",
      },
      {
        name: "Central Visayas",
        isoCode: "07",
      },
      {
        name: "Compostela Valley",
        isoCode: "COM",
      },
      {
        name: "Cordillera Administrative Region",
        isoCode: "15",
      },
      {
        name: "Cotabato",
        isoCode: "NCO",
      },
      {
        name: "Davao del Norte",
        isoCode: "DAV",
      },
      {
        name: "Davao del Sur",
        isoCode: "DAS",
      },
      {
        name: "Davao Occidental",
        isoCode: "DVO",
      },
      {
        name: "Davao Oriental",
        isoCode: "DAO",
      },
      {
        name: "Davao Region",
        isoCode: "11",
      },
      {
        name: "Dinagat Islands",
        isoCode: "DIN",
      },
      {
        name: "Eastern Samar",
        isoCode: "EAS",
      },
      {
        name: "Eastern Visayas",
        isoCode: "08",
      },
      {
        name: "Guimaras",
        isoCode: "GUI",
      },
      {
        name: "Ifugao",
        isoCode: "IFU",
      },
      {
        name: "Ilocos Norte",
        isoCode: "ILN",
      },
      {
        name: "Ilocos Region",
        isoCode: "01",
      },
      {
        name: "Ilocos Sur",
        isoCode: "ILS",
      },
      {
        name: "Iloilo",
        isoCode: "ILI",
      },
      {
        name: "Isabela",
        isoCode: "ISA",
      },
      {
        name: "Kalinga",
        isoCode: "KAL",
      },
      {
        name: "La Union",
        isoCode: "LUN",
      },
      {
        name: "Laguna",
        isoCode: "LAG",
      },
      {
        name: "Lanao del Norte",
        isoCode: "LAN",
      },
      {
        name: "Lanao del Sur",
        isoCode: "LAS",
      },
      {
        name: "Leyte",
        isoCode: "LEY",
      },
      {
        name: "Maguindanao",
        isoCode: "MAG",
      },
      {
        name: "Marinduque",
        isoCode: "MAD",
      },
      {
        name: "Masbate",
        isoCode: "MAS",
      },
      {
        name: "Metro Manila",
        isoCode: "NCR",
      },
      {
        name: "Mimaropa",
        isoCode: "41",
      },
      {
        name: "Misamis Occidental",
        isoCode: "MSC",
      },
      {
        name: "Misamis Oriental",
        isoCode: "MSR",
      },
      {
        name: "Mountain Province",
        isoCode: "MOU",
      },
      {
        name: "Negros Occidental",
        isoCode: "NEC",
      },
      {
        name: "Negros Oriental",
        isoCode: "NER",
      },
      {
        name: "Northern Mindanao",
        isoCode: "10",
      },
      {
        name: "Northern Samar",
        isoCode: "NSA",
      },
      {
        name: "Nueva Ecija",
        isoCode: "NUE",
      },
      {
        name: "Nueva Vizcaya",
        isoCode: "NUV",
      },
      {
        name: "Occidental Mindoro",
        isoCode: "MDC",
      },
      {
        name: "Oriental Mindoro",
        isoCode: "MDR",
      },
      {
        name: "Palawan",
        isoCode: "PLW",
      },
      {
        name: "Pampanga",
        isoCode: "PAM",
      },
      {
        name: "Pangasinan",
        isoCode: "PAN",
      },
      {
        name: "Quezon",
        isoCode: "QUE",
      },
      {
        name: "Quirino",
        isoCode: "QUI",
      },
      {
        name: "Rizal",
        isoCode: "RIZ",
      },
      {
        name: "Romblon",
        isoCode: "ROM",
      },
      {
        name: "Sarangani",
        isoCode: "SAR",
      },
      {
        name: "Siquijor",
        isoCode: "SIG",
      },
      {
        name: "Soccsksargen",
        isoCode: "12",
      },
      {
        name: "Sorsogon",
        isoCode: "SOR",
      },
      {
        name: "South Cotabato",
        isoCode: "SCO",
      },
      {
        name: "Southern Leyte",
        isoCode: "SLE",
      },
      {
        name: "Sultan Kudarat",
        isoCode: "SUK",
      },
      {
        name: "Sulu",
        isoCode: "SLU",
      },
      {
        name: "Surigao del Norte",
        isoCode: "SUN",
      },
      {
        name: "Surigao del Sur",
        isoCode: "SUR",
      },
      {
        name: "Tarlac",
        isoCode: "TAR",
      },
      {
        name: "Tawi-Tawi",
        isoCode: "TAW",
      },
      {
        name: "Western Visayas",
        isoCode: "06",
      },
      {
        name: "Zambales",
        isoCode: "ZMB",
      },
      {
        name: "Zamboanga del Norte",
        isoCode: "ZAN",
      },
      {
        name: "Zamboanga del Sur",
        isoCode: "ZAS",
      },
      {
        name: "Zamboanga Peninsula",
        isoCode: "09",
      },
      {
        name: "Zamboanga Sibugay",
        isoCode: "ZSI",
      },
    ],
  },
  {
    name: "Pitcairn Island",
    isoCode: "PN",
    states: [],
  },
  {
    name: "Poland",
    isoCode: "PL",
    states: [
      {
        name: "Greater Poland Voivodeship",
        isoCode: "WP",
      },
      {
        name: "Kuyavian-Pomeranian Voivodeship",
        isoCode: "KP",
      },
      {
        name: "Lesser Poland Voivodeship",
        isoCode: "MA",
      },
      {
        name: "Lower Silesian Voivodeship",
        isoCode: "DS",
      },
      {
        name: "Lublin Voivodeship",
        isoCode: "LU",
      },
      {
        name: "Lubusz Voivodeship",
        isoCode: "LB",
      },
      {
        name: "Łódź Voivodeship",
        isoCode: "LD",
      },
      {
        name: "Masovian Voivodeship",
        isoCode: "MZ",
      },
      {
        name: "Opole Voivodeship",
        isoCode: "OP",
      },
      {
        name: "Podkarpackie Voivodeship",
        isoCode: "PK",
      },
      {
        name: "Podlaskie Voivodeship",
        isoCode: "PD",
      },
      {
        name: "Pomeranian Voivodeship",
        isoCode: "PM",
      },
      {
        name: "Silesian Voivodeship",
        isoCode: "SL",
      },
      {
        name: "Świętokrzyskie Voivodeship",
        isoCode: "SK",
      },
      {
        name: "Warmian-Masurian Voivodeship",
        isoCode: "WN",
      },
      {
        name: "West Pomeranian Voivodeship",
        isoCode: "ZP",
      },
    ],
  },
  {
    name: "Portugal",
    isoCode: "PT",
    states: [
      {
        name: "Açores",
        isoCode: "20",
      },
      {
        name: "Aveiro",
        isoCode: "01",
      },
      {
        name: "Beja",
        isoCode: "02",
      },
      {
        name: "Braga",
        isoCode: "03",
      },
      {
        name: "Bragança",
        isoCode: "04",
      },
      {
        name: "Castelo Branco",
        isoCode: "05",
      },
      {
        name: "Coimbra",
        isoCode: "06",
      },
      {
        name: "Évora",
        isoCode: "07",
      },
      {
        name: "Faro",
        isoCode: "08",
      },
      {
        name: "Guarda",
        isoCode: "09",
      },
      {
        name: "Leiria",
        isoCode: "10",
      },
      {
        name: "Lisbon",
        isoCode: "11",
      },
      {
        name: "Madeira",
        isoCode: "30",
      },
      {
        name: "Portalegre",
        isoCode: "12",
      },
      {
        name: "Porto",
        isoCode: "13",
      },
      {
        name: "Santarém",
        isoCode: "14",
      },
      {
        name: "Setúbal",
        isoCode: "15",
      },
      {
        name: "Viana do Castelo",
        isoCode: "16",
      },
      {
        name: "Vila Real",
        isoCode: "17",
      },
      {
        name: "Viseu",
        isoCode: "18",
      },
    ],
  },
  {
    name: "Puerto Rico",
    isoCode: "PR",
    states: [
      {
        name: "Arecibo",
        isoCode: "AR",
      },
      {
        name: "Bayamon",
        isoCode: "BY",
      },
      {
        name: "Caguas",
        isoCode: "CG",
      },
      {
        name: "Carolina",
        isoCode: "CL",
      },
      {
        name: "Guaynabo",
        isoCode: "GN",
      },
      {
        name: "Mayagüez",
        isoCode: "MG",
      },
      {
        name: "Ponce",
        isoCode: "PO",
      },
      {
        name: "San Juan",
        isoCode: "SJ",
      },
      {
        name: "Toa Baja",
        isoCode: "TB",
      },
      {
        name: "Trujillo Alto",
        isoCode: "TA",
      },
    ],
  },
  {
    name: "Qatar",
    isoCode: "QA",
    states: [
      {
        name: "Al Daayen",
        isoCode: "ZA",
      },
      {
        name: "Al Khor",
        isoCode: "KH",
      },
      {
        name: "Al Rayyan Municipality",
        isoCode: "RA",
      },
      {
        name: "Al Wakrah",
        isoCode: "WA",
      },
      {
        name: "Al-Shahaniya",
        isoCode: "SH",
      },
      {
        name: "Doha",
        isoCode: "DA",
      },
      {
        name: "Madinat ash Shamal",
        isoCode: "MS",
      },
      {
        name: "Umm Salal Municipality",
        isoCode: "US",
      },
    ],
  },
  {
    name: "Reunion",
    isoCode: "RE",
    states: [],
  },
  {
    name: "Romania",
    isoCode: "RO",
    states: [
      {
        name: "Alba",
        isoCode: "AB",
      },
      {
        name: "Arad County",
        isoCode: "AR",
      },
      {
        name: "Arges",
        isoCode: "AG",
      },
      {
        name: "Bacău County",
        isoCode: "BC",
      },
      {
        name: "Bihor County",
        isoCode: "BH",
      },
      {
        name: "Bistrița-Năsăud County",
        isoCode: "BN",
      },
      {
        name: "Botoșani County",
        isoCode: "BT",
      },
      {
        name: "Braila",
        isoCode: "BR",
      },
      {
        name: "Brașov County",
        isoCode: "BV",
      },
      {
        name: "Bucharest",
        isoCode: "B",
      },
      {
        name: "Buzău County",
        isoCode: "BZ",
      },
      {
        name: "Călărași County",
        isoCode: "CL",
      },
      {
        name: "Caraș-Severin County",
        isoCode: "CS",
      },
      {
        name: "Cluj County",
        isoCode: "CJ",
      },
      {
        name: "Constanța County",
        isoCode: "CT",
      },
      {
        name: "Covasna County",
        isoCode: "CV",
      },
      {
        name: "Dâmbovița County",
        isoCode: "DB",
      },
      {
        name: "Dolj County",
        isoCode: "DJ",
      },
      {
        name: "Galați County",
        isoCode: "GL",
      },
      {
        name: "Giurgiu County",
        isoCode: "GR",
      },
      {
        name: "Gorj County",
        isoCode: "GJ",
      },
      {
        name: "Harghita County",
        isoCode: "HR",
      },
      {
        name: "Hunedoara County",
        isoCode: "HD",
      },
      {
        name: "Ialomița County",
        isoCode: "IL",
      },
      {
        name: "Iași County",
        isoCode: "IS",
      },
      {
        name: "Ilfov County",
        isoCode: "IF",
      },
      {
        name: "Maramureș County",
        isoCode: "MM",
      },
      {
        name: "Mehedinți County",
        isoCode: "MH",
      },
      {
        name: "Mureș County",
        isoCode: "MS",
      },
      {
        name: "Neamț County",
        isoCode: "NT",
      },
      {
        name: "Olt County",
        isoCode: "OT",
      },
      {
        name: "Prahova County",
        isoCode: "PH",
      },
      {
        name: "Sălaj County",
        isoCode: "SJ",
      },
      {
        name: "Satu Mare County",
        isoCode: "SM",
      },
      {
        name: "Sibiu County",
        isoCode: "SB",
      },
      {
        name: "Suceava County",
        isoCode: "SV",
      },
      {
        name: "Teleorman County",
        isoCode: "TR",
      },
      {
        name: "Timiș County",
        isoCode: "TM",
      },
      {
        name: "Tulcea County",
        isoCode: "TL",
      },
      {
        name: "Vâlcea County",
        isoCode: "VL",
      },
      {
        name: "Vaslui County",
        isoCode: "VS",
      },
      {
        name: "Vrancea County",
        isoCode: "VN",
      },
    ],
  },
  {
    name: "Russia",
    isoCode: "RU",
    states: [
      {
        name: "Altai Krai",
        isoCode: "ALT",
      },
      {
        name: "Altai Republic",
        isoCode: "AL",
      },
      {
        name: "Amur Oblast",
        isoCode: "AMU",
      },
      {
        name: "Arkhangelsk",
        isoCode: "ARK",
      },
      {
        name: "Astrakhan Oblast",
        isoCode: "AST",
      },
      {
        name: "Belgorod Oblast",
        isoCode: "BEL",
      },
      {
        name: "Bryansk Oblast",
        isoCode: "BRY",
      },
      {
        name: "Chechen Republic",
        isoCode: "CE",
      },
      {
        name: "Chelyabinsk Oblast",
        isoCode: "CHE",
      },
      {
        name: "Chukotka Autonomous Okrug",
        isoCode: "CHU",
      },
      {
        name: "Chuvash Republic",
        isoCode: "CU",
      },
      {
        name: "Irkutsk",
        isoCode: "IRK",
      },
      {
        name: "Ivanovo Oblast",
        isoCode: "IVA",
      },
      {
        name: "Jewish Autonomous Oblast",
        isoCode: "YEV",
      },
      {
        name: "Kabardino-Balkar Republic",
        isoCode: "KB",
      },
      {
        name: "Kaliningrad",
        isoCode: "KGD",
      },
      {
        name: "Kaluga Oblast",
        isoCode: "KLU",
      },
      {
        name: "Kamchatka Krai",
        isoCode: "KAM",
      },
      {
        name: "Karachay-Cherkess Republic",
        isoCode: "KC",
      },
      {
        name: "Kemerovo Oblast",
        isoCode: "KEM",
      },
      {
        name: "Khabarovsk Krai",
        isoCode: "KHA",
      },
      {
        name: "Khanty-Mansi Autonomous Okrug",
        isoCode: "KHM",
      },
      {
        name: "Kirov Oblast",
        isoCode: "KIR",
      },
      {
        name: "Komi Republic",
        isoCode: "KO",
      },
      {
        name: "Kostroma Oblast",
        isoCode: "KOS",
      },
      {
        name: "Krasnodar Krai",
        isoCode: "KDA",
      },
      {
        name: "Krasnoyarsk Krai",
        isoCode: "KYA",
      },
      {
        name: "Kurgan Oblast",
        isoCode: "KGN",
      },
      {
        name: "Kursk Oblast",
        isoCode: "KRS",
      },
      {
        name: "Leningrad Oblast",
        isoCode: "LEN",
      },
      {
        name: "Lipetsk Oblast",
        isoCode: "LIP",
      },
      {
        name: "Magadan Oblast",
        isoCode: "MAG",
      },
      {
        name: "Mari El Republic",
        isoCode: "ME",
      },
      {
        name: "Moscow",
        isoCode: "MOW",
      },
      {
        name: "Moscow Oblast",
        isoCode: "MOS",
      },
      {
        name: "Murmansk Oblast",
        isoCode: "MUR",
      },
      {
        name: "Nenets Autonomous Okrug",
        isoCode: "NEN",
      },
      {
        name: "Nizhny Novgorod Oblast",
        isoCode: "NIZ",
      },
      {
        name: "Novgorod Oblast",
        isoCode: "NGR",
      },
      {
        name: "Novosibirsk",
        isoCode: "NVS",
      },
      {
        name: "Omsk Oblast",
        isoCode: "OMS",
      },
      {
        name: "Orenburg Oblast",
        isoCode: "ORE",
      },
      {
        name: "Oryol Oblast",
        isoCode: "ORL",
      },
      {
        name: "Penza Oblast",
        isoCode: "PNZ",
      },
      {
        name: "Perm Krai",
        isoCode: "PER",
      },
      {
        name: "Primorsky Krai",
        isoCode: "PRI",
      },
      {
        name: "Pskov Oblast",
        isoCode: "PSK",
      },
      {
        name: "Republic of Adygea",
        isoCode: "AD",
      },
      {
        name: "Republic of Bashkortostan",
        isoCode: "BA",
      },
      {
        name: "Republic of Buryatia",
        isoCode: "BU",
      },
      {
        name: "Republic of Dagestan",
        isoCode: "DA",
      },
      {
        name: "Republic of Ingushetia",
        isoCode: "IN",
      },
      {
        name: "Republic of Kalmykia",
        isoCode: "KL",
      },
      {
        name: "Republic of Karelia",
        isoCode: "KR",
      },
      {
        name: "Republic of Khakassia",
        isoCode: "KK",
      },
      {
        name: "Republic of Mordovia",
        isoCode: "MO",
      },
      {
        name: "Republic of North Ossetia-Alania",
        isoCode: "SE",
      },
      {
        name: "Republic of Tatarstan",
        isoCode: "TA",
      },
      {
        name: "Rostov Oblast",
        isoCode: "ROS",
      },
      {
        name: "Ryazan Oblast",
        isoCode: "RYA",
      },
      {
        name: "Saint Petersburg",
        isoCode: "SPE",
      },
      {
        name: "Sakha Republic",
        isoCode: "SA",
      },
      {
        name: "Sakhalin",
        isoCode: "SAK",
      },
      {
        name: "Samara Oblast",
        isoCode: "SAM",
      },
      {
        name: "Saratov Oblast",
        isoCode: "SAR",
      },
      {
        name: "Sevastopol",
        isoCode: "UA-40",
      },
      {
        name: "Smolensk Oblast",
        isoCode: "SMO",
      },
      {
        name: "Stavropol Krai",
        isoCode: "STA",
      },
      {
        name: "Sverdlovsk",
        isoCode: "SVE",
      },
      {
        name: "Tambov Oblast",
        isoCode: "TAM",
      },
      {
        name: "Tomsk Oblast",
        isoCode: "TOM",
      },
      {
        name: "Tula Oblast",
        isoCode: "TUL",
      },
      {
        name: "Tuva Republic",
        isoCode: "TY",
      },
      {
        name: "Tver Oblast",
        isoCode: "TVE",
      },
      {
        name: "Tyumen Oblast",
        isoCode: "TYU",
      },
      {
        name: "Udmurt Republic",
        isoCode: "UD",
      },
      {
        name: "Ulyanovsk Oblast",
        isoCode: "ULY",
      },
      {
        name: "Vladimir Oblast",
        isoCode: "VLA",
      },
      {
        name: "Volgograd Oblast",
        isoCode: "VGG",
      },
      {
        name: "Vologda Oblast",
        isoCode: "VLG",
      },
      {
        name: "Voronezh Oblast",
        isoCode: "VOR",
      },
      {
        name: "Yamalo-Nenets Autonomous Okrug",
        isoCode: "YAN",
      },
      {
        name: "Yaroslavl Oblast",
        isoCode: "YAR",
      },
      {
        name: "Zabaykalsky Krai",
        isoCode: "ZAB",
      },
    ],
  },
  {
    name: "Rwanda",
    isoCode: "RW",
    states: [
      {
        name: "Eastern Province",
        isoCode: "02",
      },
      {
        name: "Kigali district",
        isoCode: "01",
      },
      {
        name: "Northern Province",
        isoCode: "03",
      },
      {
        name: "Southern Province",
        isoCode: "05",
      },
      {
        name: "Western Province",
        isoCode: "04",
      },
    ],
  },
  {
    name: "Saint Helena",
    isoCode: "SH",
    states: [],
  },
  {
    name: "Saint Kitts And Nevis",
    isoCode: "KN",
    states: [
      {
        name: "Christ Church Nichola Town Parish",
        isoCode: "01",
      },
      {
        name: "Nevis",
        isoCode: "N",
      },
      {
        name: "Saint Anne Sandy Point Parish",
        isoCode: "02",
      },
      {
        name: "Saint George Gingerland Parish",
        isoCode: "04",
      },
      {
        name: "Saint James Windward Parish",
        isoCode: "05",
      },
      {
        name: "Saint John Capisterre Parish",
        isoCode: "06",
      },
      {
        name: "Saint John Figtree Parish",
        isoCode: "07",
      },
      {
        name: "Saint Kitts",
        isoCode: "K",
      },
      {
        name: "Saint Mary Cayon Parish",
        isoCode: "08",
      },
      {
        name: "Saint Paul Capisterre Parish",
        isoCode: "09",
      },
      {
        name: "Saint Paul Charlestown Parish",
        isoCode: "10",
      },
      {
        name: "Saint Peter Basseterre Parish",
        isoCode: "11",
      },
      {
        name: "Saint Thomas Lowland Parish",
        isoCode: "12",
      },
      {
        name: "Saint Thomas Middle Island Parish",
        isoCode: "13",
      },
      {
        name: "Trinity Palmetto Point Parish",
        isoCode: "15",
      },
    ],
  },
  {
    name: "Saint Lucia",
    isoCode: "LC",
    states: [
      {
        name: "Anse la Raye Quarter",
        isoCode: "01",
      },
      {
        name: "Canaries",
        isoCode: "12",
      },
      {
        name: "Castries Quarter",
        isoCode: "02",
      },
      {
        name: "Choiseul Quarter",
        isoCode: "03",
      },
      {
        name: "Dauphin Quarter",
        isoCode: "04",
      },
      {
        name: "Dennery Quarter",
        isoCode: "05",
      },
      {
        name: "Gros Islet Quarter",
        isoCode: "06",
      },
      {
        name: "Laborie Quarter",
        isoCode: "07",
      },
      {
        name: "Micoud Quarter",
        isoCode: "08",
      },
      {
        name: "Praslin Quarter",
        isoCode: "09",
      },
      {
        name: "Soufrière Quarter",
        isoCode: "10",
      },
      {
        name: "Vieux Fort Quarter",
        isoCode: "11",
      },
    ],
  },
  {
    name: "Saint Pierre and Miquelon",
    isoCode: "PM",
    states: [],
  },
  {
    name: "Saint Vincent And The Grenadines",
    isoCode: "VC",
    states: [
      {
        name: "Charlotte Parish",
        isoCode: "01",
      },
      {
        name: "Grenadines Parish",
        isoCode: "06",
      },
      {
        name: "Saint Andrew Parish",
        isoCode: "02",
      },
      {
        name: "Saint David Parish",
        isoCode: "03",
      },
      {
        name: "Saint George Parish",
        isoCode: "04",
      },
      {
        name: "Saint Patrick Parish",
        isoCode: "05",
      },
    ],
  },
  {
    name: "Saint-Barthelemy",
    isoCode: "BL",
    states: [],
  },
  {
    name: "Saint-Martin (French part)",
    isoCode: "MF",
    states: [],
  },
  {
    name: "Samoa",
    isoCode: "WS",
    states: [
      {
        name: "A'ana",
        isoCode: "AA",
      },
      {
        name: "Aiga-i-le-Tai",
        isoCode: "AL",
      },
      {
        name: "Atua",
        isoCode: "AT",
      },
      {
        name: "Fa'asaleleaga",
        isoCode: "FA",
      },
      {
        name: "Gaga'emauga",
        isoCode: "GE",
      },
      {
        name: "Gaga'ifomauga",
        isoCode: "GI",
      },
      {
        name: "Palauli",
        isoCode: "PA",
      },
      {
        name: "Satupa'itea",
        isoCode: "SA",
      },
      {
        name: "Tuamasaga",
        isoCode: "TU",
      },
      {
        name: "Va'a-o-Fonoti",
        isoCode: "VF",
      },
      {
        name: "Vaisigano",
        isoCode: "VS",
      },
    ],
  },
  {
    name: "San Marino",
    isoCode: "SM",
    states: [
      {
        name: "Acquaviva",
        isoCode: "01",
      },
      {
        name: "Borgo Maggiore",
        isoCode: "06",
      },
      {
        name: "Chiesanuova",
        isoCode: "02",
      },
      {
        name: "Domagnano",
        isoCode: "03",
      },
      {
        name: "Faetano",
        isoCode: "04",
      },
      {
        name: "Fiorentino",
        isoCode: "05",
      },
      {
        name: "Montegiardino",
        isoCode: "08",
      },
      {
        name: "San Marino",
        isoCode: "07",
      },
      {
        name: "Serravalle",
        isoCode: "09",
      },
    ],
  },
  {
    name: "Sao Tome and Principe",
    isoCode: "ST",
    states: [
      {
        name: "Príncipe Province",
        isoCode: "P",
      },
      {
        name: "São Tomé Province",
        isoCode: "S",
      },
    ],
  },
  {
    name: "Saudi Arabia",
    isoCode: "SA",
    states: [
      {
        name: "'Asir",
        isoCode: "14",
      },
      {
        name: "Al Bahah",
        isoCode: "11",
      },
      {
        name: "Al Jawf",
        isoCode: "12",
      },
      {
        name: "Al Madinah",
        isoCode: "03",
      },
      {
        name: "Al-Qassim",
        isoCode: "05",
      },
      {
        name: "Eastern Province",
        isoCode: "04",
      },
      {
        name: "Ha'il",
        isoCode: "06",
      },
      {
        name: "Jizan",
        isoCode: "09",
      },
      {
        name: "Makkah",
        isoCode: "02",
      },
      {
        name: "Najran",
        isoCode: "10",
      },
      {
        name: "Northern Borders",
        isoCode: "08",
      },
      {
        name: "Riyadh",
        isoCode: "01",
      },
      {
        name: "Tabuk",
        isoCode: "07",
      },
    ],
  },
  {
    name: "Senegal",
    isoCode: "SN",
    states: [
      {
        name: "Dakar",
        isoCode: "DK",
      },
      {
        name: "Diourbel Region",
        isoCode: "DB",
      },
      {
        name: "Fatick",
        isoCode: "FK",
      },
      {
        name: "Kaffrine",
        isoCode: "KA",
      },
      {
        name: "Kaolack",
        isoCode: "KL",
      },
      {
        name: "Kédougou",
        isoCode: "KE",
      },
      {
        name: "Kolda",
        isoCode: "KD",
      },
      {
        name: "Louga",
        isoCode: "LG",
      },
      {
        name: "Matam",
        isoCode: "MT",
      },
      {
        name: "Saint-Louis",
        isoCode: "SL",
      },
      {
        name: "Sédhiou",
        isoCode: "SE",
      },
      {
        name: "Tambacounda Region",
        isoCode: "TC",
      },
      {
        name: "Thiès Region",
        isoCode: "TH",
      },
      {
        name: "Ziguinchor",
        isoCode: "ZG",
      },
    ],
  },
  {
    name: "Serbia",
    isoCode: "RS",
    states: [
      {
        name: "Belgrade",
        isoCode: "00",
      },
      {
        name: "Bor District",
        isoCode: "14",
      },
      {
        name: "Braničevo District",
        isoCode: "11",
      },
      {
        name: "Central Banat District",
        isoCode: "02",
      },
      {
        name: "Jablanica District",
        isoCode: "23",
      },
      {
        name: "Kolubara District",
        isoCode: "09",
      },
      {
        name: "Mačva District",
        isoCode: "08",
      },
      {
        name: "Moravica District",
        isoCode: "17",
      },
      {
        name: "Nišava District",
        isoCode: "20",
      },
      {
        name: "North Bačka District",
        isoCode: "01",
      },
      {
        name: "North Banat District",
        isoCode: "03",
      },
      {
        name: "Pčinja District",
        isoCode: "24",
      },
      {
        name: "Pirot District",
        isoCode: "22",
      },
      {
        name: "Podunavlje District",
        isoCode: "10",
      },
      {
        name: "Pomoravlje District",
        isoCode: "13",
      },
      {
        name: "Rasina District",
        isoCode: "19",
      },
      {
        name: "Raška District",
        isoCode: "18",
      },
      {
        name: "South Bačka District",
        isoCode: "06",
      },
      {
        name: "South Banat District",
        isoCode: "04",
      },
      {
        name: "Srem District",
        isoCode: "07",
      },
      {
        name: "Šumadija District",
        isoCode: "12",
      },
      {
        name: "Toplica District",
        isoCode: "21",
      },
      {
        name: "Vojvodina",
        isoCode: "VO",
      },
      {
        name: "West Bačka District",
        isoCode: "05",
      },
      {
        name: "Zaječar District",
        isoCode: "15",
      },
      {
        name: "Zlatibor District",
        isoCode: "16",
      },
    ],
  },
  {
    name: "Seychelles",
    isoCode: "SC",
    states: [
      {
        name: "Anse Boileau",
        isoCode: "02",
      },
      {
        name: "Anse Royale",
        isoCode: "05",
      },
      {
        name: "Anse-aux-Pins",
        isoCode: "01",
      },
      {
        name: "Au Cap",
        isoCode: "04",
      },
      {
        name: "Baie Lazare",
        isoCode: "06",
      },
      {
        name: "Baie Sainte Anne",
        isoCode: "07",
      },
      {
        name: "Beau Vallon",
        isoCode: "08",
      },
      {
        name: "Bel Air",
        isoCode: "09",
      },
      {
        name: "Bel Ombre",
        isoCode: "10",
      },
      {
        name: "Cascade",
        isoCode: "11",
      },
      {
        name: "Glacis",
        isoCode: "12",
      },
      {
        name: "Grand'Anse Mahé",
        isoCode: "13",
      },
      {
        name: "Grand'Anse Praslin",
        isoCode: "14",
      },
      {
        name: "La Digue",
        isoCode: "15",
      },
      {
        name: "La Rivière Anglaise",
        isoCode: "16",
      },
      {
        name: "Les Mamelles",
        isoCode: "24",
      },
      {
        name: "Mont Buxton",
        isoCode: "17",
      },
      {
        name: "Mont Fleuri",
        isoCode: "18",
      },
      {
        name: "Plaisance",
        isoCode: "19",
      },
      {
        name: "Pointe La Rue",
        isoCode: "20",
      },
      {
        name: "Port Glaud",
        isoCode: "21",
      },
      {
        name: "Roche Caiman",
        isoCode: "25",
      },
      {
        name: "Saint Louis",
        isoCode: "22",
      },
      {
        name: "Takamaka",
        isoCode: "23",
      },
    ],
  },
  {
    name: "Sierra Leone",
    isoCode: "SL",
    states: [
      {
        name: "Eastern Province",
        isoCode: "E",
      },
      {
        name: "Northern Province",
        isoCode: "N",
      },
      {
        name: "Southern Province",
        isoCode: "S",
      },
      {
        name: "Western Area",
        isoCode: "W",
      },
    ],
  },
  {
    name: "Singapore",
    isoCode: "SG",
    states: [
      {
        name: "Central Singapore Community Development Council",
        isoCode: "01",
      },
      {
        name: "North East Community Development Council",
        isoCode: "02",
      },
      {
        name: "North West Community Development Council",
        isoCode: "03",
      },
      {
        name: "South East Community Development Council",
        isoCode: "04",
      },
      {
        name: "South West Community Development Council",
        isoCode: "05",
      },
    ],
  },
  {
    name: "Sint Maarten (Dutch part)",
    isoCode: "SX",
    states: [],
  },
  {
    name: "Slovakia",
    isoCode: "SK",
    states: [
      {
        name: "Banská Bystrica Region",
        isoCode: "BC",
      },
      {
        name: "Bratislava Region",
        isoCode: "BL",
      },
      {
        name: "Košice Region",
        isoCode: "KI",
      },
      {
        name: "Nitra Region",
        isoCode: "NI",
      },
      {
        name: "Prešov Region",
        isoCode: "PV",
      },
      {
        name: "Trenčín Region",
        isoCode: "TC",
      },
      {
        name: "Trnava Region",
        isoCode: "TA",
      },
      {
        name: "Žilina Region",
        isoCode: "ZI",
      },
    ],
  },
  {
    name: "Slovenia",
    isoCode: "SI",
    states: [
      {
        name: "Ajdovščina Municipality",
        isoCode: "001",
      },
      {
        name: "Ankaran Municipality",
        isoCode: "213",
      },
      {
        name: "Beltinci Municipality",
        isoCode: "002",
      },
      {
        name: "Benedikt Municipality",
        isoCode: "148",
      },
      {
        name: "Bistrica ob Sotli Municipality",
        isoCode: "149",
      },
      {
        name: "Bled Municipality",
        isoCode: "003",
      },
      {
        name: "Bloke Municipality",
        isoCode: "150",
      },
      {
        name: "Bohinj Municipality",
        isoCode: "004",
      },
      {
        name: "Borovnica Municipality",
        isoCode: "005",
      },
      {
        name: "Bovec Municipality",
        isoCode: "006",
      },
      {
        name: "Braslovče Municipality",
        isoCode: "151",
      },
      {
        name: "Brda Municipality",
        isoCode: "007",
      },
      {
        name: "Brežice Municipality",
        isoCode: "009",
      },
      {
        name: "Brezovica Municipality",
        isoCode: "008",
      },
      {
        name: "Cankova Municipality",
        isoCode: "152",
      },
      {
        name: "Cerklje na Gorenjskem Municipality",
        isoCode: "012",
      },
      {
        name: "Cerknica Municipality",
        isoCode: "013",
      },
      {
        name: "Cerkno Municipality",
        isoCode: "014",
      },
      {
        name: "Cerkvenjak Municipality",
        isoCode: "153",
      },
      {
        name: "City Municipality of Celje",
        isoCode: "011",
      },
      {
        name: "City Municipality of Novo Mesto",
        isoCode: "085",
      },
      {
        name: "Črenšovci Municipality",
        isoCode: "015",
      },
      {
        name: "Črna na Koroškem Municipality",
        isoCode: "016",
      },
      {
        name: "Črnomelj Municipality",
        isoCode: "017",
      },
      {
        name: "Destrnik Municipality",
        isoCode: "018",
      },
      {
        name: "Divača Municipality",
        isoCode: "019",
      },
      {
        name: "Dobje Municipality",
        isoCode: "154",
      },
      {
        name: "Dobrepolje Municipality",
        isoCode: "020",
      },
      {
        name: "Dobrna Municipality",
        isoCode: "155",
      },
      {
        name: "Dobrova–Polhov Gradec Municipality",
        isoCode: "021",
      },
      {
        name: "Dobrovnik Municipality",
        isoCode: "156",
      },
      {
        name: "Dol pri Ljubljani Municipality",
        isoCode: "022",
      },
      {
        name: "Dolenjske Toplice Municipality",
        isoCode: "157",
      },
      {
        name: "Domžale Municipality",
        isoCode: "023",
      },
      {
        name: "Dornava Municipality",
        isoCode: "024",
      },
      {
        name: "Dravograd Municipality",
        isoCode: "025",
      },
      {
        name: "Duplek Municipality",
        isoCode: "026",
      },
      {
        name: "Gorenja Vas–Poljane Municipality",
        isoCode: "027",
      },
      {
        name: "Gorišnica Municipality",
        isoCode: "028",
      },
      {
        name: "Gorje Municipality",
        isoCode: "207",
      },
      {
        name: "Gornja Radgona Municipality",
        isoCode: "029",
      },
      {
        name: "Gornji Grad Municipality",
        isoCode: "030",
      },
      {
        name: "Gornji Petrovci Municipality",
        isoCode: "031",
      },
      {
        name: "Grad Municipality",
        isoCode: "158",
      },
      {
        name: "Grosuplje Municipality",
        isoCode: "032",
      },
      {
        name: "Hajdina Municipality",
        isoCode: "159",
      },
      {
        name: "Hoče–Slivnica Municipality",
        isoCode: "160",
      },
      {
        name: "Hodoš Municipality",
        isoCode: "161",
      },
      {
        name: "Horjul Municipality",
        isoCode: "162",
      },
      {
        name: "Hrastnik Municipality",
        isoCode: "034",
      },
      {
        name: "Hrpelje–Kozina Municipality",
        isoCode: "035",
      },
      {
        name: "Idrija Municipality",
        isoCode: "036",
      },
      {
        name: "Ig Municipality",
        isoCode: "037",
      },
      {
        name: "Ivančna Gorica Municipality",
        isoCode: "039",
      },
      {
        name: "Izola Municipality",
        isoCode: "040",
      },
      {
        name: "Jesenice Municipality",
        isoCode: "041",
      },
      {
        name: "Jezersko Municipality",
        isoCode: "163",
      },
      {
        name: "Juršinci Municipality",
        isoCode: "042",
      },
      {
        name: "Kamnik Municipality",
        isoCode: "043",
      },
      {
        name: "Kanal ob Soči Municipality",
        isoCode: "044",
      },
      {
        name: "Kidričevo Municipality",
        isoCode: "045",
      },
      {
        name: "Kobarid Municipality",
        isoCode: "046",
      },
      {
        name: "Kobilje Municipality",
        isoCode: "047",
      },
      {
        name: "Kočevje Municipality",
        isoCode: "048",
      },
      {
        name: "Komen Municipality",
        isoCode: "049",
      },
      {
        name: "Komenda Municipality",
        isoCode: "164",
      },
      {
        name: "Koper City Municipality",
        isoCode: "050",
      },
      {
        name: "Kostanjevica na Krki Municipality",
        isoCode: "197",
      },
      {
        name: "Kostel Municipality",
        isoCode: "165",
      },
      {
        name: "Kozje Municipality",
        isoCode: "051",
      },
      {
        name: "Kranj City Municipality",
        isoCode: "052",
      },
      {
        name: "Kranjska Gora Municipality",
        isoCode: "053",
      },
      {
        name: "Križevci Municipality",
        isoCode: "166",
      },
      {
        name: "Kungota",
        isoCode: "055",
      },
      {
        name: "Kuzma Municipality",
        isoCode: "056",
      },
      {
        name: "Laško Municipality",
        isoCode: "057",
      },
      {
        name: "Lenart Municipality",
        isoCode: "058",
      },
      {
        name: "Lendava Municipality",
        isoCode: "059",
      },
      {
        name: "Litija Municipality",
        isoCode: "060",
      },
      {
        name: "Ljubljana City Municipality",
        isoCode: "061",
      },
      {
        name: "Ljubno Municipality",
        isoCode: "062",
      },
      {
        name: "Ljutomer Municipality",
        isoCode: "063",
      },
      {
        name: "Log–Dragomer Municipality",
        isoCode: "208",
      },
      {
        name: "Logatec Municipality",
        isoCode: "064",
      },
      {
        name: "Loška Dolina Municipality",
        isoCode: "065",
      },
      {
        name: "Loški Potok Municipality",
        isoCode: "066",
      },
      {
        name: "Lovrenc na Pohorju Municipality",
        isoCode: "167",
      },
      {
        name: "Luče Municipality",
        isoCode: "067",
      },
      {
        name: "Lukovica Municipality",
        isoCode: "068",
      },
      {
        name: "Majšperk Municipality",
        isoCode: "069",
      },
      {
        name: "Makole Municipality",
        isoCode: "198",
      },
      {
        name: "Maribor City Municipality",
        isoCode: "070",
      },
      {
        name: "Markovci Municipality",
        isoCode: "168",
      },
      {
        name: "Medvode Municipality",
        isoCode: "071",
      },
      {
        name: "Mengeš Municipality",
        isoCode: "072",
      },
      {
        name: "Metlika Municipality",
        isoCode: "073",
      },
      {
        name: "Mežica Municipality",
        isoCode: "074",
      },
      {
        name: "Miklavž na Dravskem Polju Municipality",
        isoCode: "169",
      },
      {
        name: "Miren–Kostanjevica Municipality",
        isoCode: "075",
      },
      {
        name: "Mirna Municipality",
        isoCode: "212",
      },
      {
        name: "Mirna Peč Municipality",
        isoCode: "170",
      },
      {
        name: "Mislinja Municipality",
        isoCode: "076",
      },
      {
        name: "Mokronog–Trebelno Municipality",
        isoCode: "199",
      },
      {
        name: "Moravče Municipality",
        isoCode: "077",
      },
      {
        name: "Moravske Toplice Municipality",
        isoCode: "078",
      },
      {
        name: "Mozirje Municipality",
        isoCode: "079",
      },
      {
        name: "Municipality of Apače",
        isoCode: "195",
      },
      {
        name: "Municipality of Cirkulane",
        isoCode: "196",
      },
      {
        name: "Municipality of Ilirska Bistrica",
        isoCode: "038",
      },
      {
        name: "Municipality of Krško",
        isoCode: "054",
      },
      {
        name: "Municipality of Škofljica",
        isoCode: "123",
      },
      {
        name: "Murska Sobota City Municipality",
        isoCode: "080",
      },
      {
        name: "Muta Municipality",
        isoCode: "081",
      },
      {
        name: "Naklo Municipality",
        isoCode: "082",
      },
      {
        name: "Nazarje Municipality",
        isoCode: "083",
      },
      {
        name: "Nova Gorica City Municipality",
        isoCode: "084",
      },
      {
        name: "Odranci Municipality",
        isoCode: "086",
      },
      {
        name: "Oplotnica",
        isoCode: "171",
      },
      {
        name: "Ormož Municipality",
        isoCode: "087",
      },
      {
        name: "Osilnica Municipality",
        isoCode: "088",
      },
      {
        name: "Pesnica Municipality",
        isoCode: "089",
      },
      {
        name: "Piran Municipality",
        isoCode: "090",
      },
      {
        name: "Pivka Municipality",
        isoCode: "091",
      },
      {
        name: "Podčetrtek Municipality",
        isoCode: "092",
      },
      {
        name: "Podlehnik Municipality",
        isoCode: "172",
      },
      {
        name: "Podvelka Municipality",
        isoCode: "093",
      },
      {
        name: "Poljčane Municipality",
        isoCode: "200",
      },
      {
        name: "Polzela Municipality",
        isoCode: "173",
      },
      {
        name: "Postojna Municipality",
        isoCode: "094",
      },
      {
        name: "Prebold Municipality",
        isoCode: "174",
      },
      {
        name: "Preddvor Municipality",
        isoCode: "095",
      },
      {
        name: "Prevalje Municipality",
        isoCode: "175",
      },
      {
        name: "Ptuj City Municipality",
        isoCode: "096",
      },
      {
        name: "Puconci Municipality",
        isoCode: "097",
      },
      {
        name: "Rače–Fram Municipality",
        isoCode: "098",
      },
      {
        name: "Radeče Municipality",
        isoCode: "099",
      },
      {
        name: "Radenci Municipality",
        isoCode: "100",
      },
      {
        name: "Radlje ob Dravi Municipality",
        isoCode: "101",
      },
      {
        name: "Radovljica Municipality",
        isoCode: "102",
      },
      {
        name: "Ravne na Koroškem Municipality",
        isoCode: "103",
      },
      {
        name: "Razkrižje Municipality",
        isoCode: "176",
      },
      {
        name: "Rečica ob Savinji Municipality",
        isoCode: "209",
      },
      {
        name: "Renče–Vogrsko Municipality",
        isoCode: "201",
      },
      {
        name: "Ribnica Municipality",
        isoCode: "104",
      },
      {
        name: "Ribnica na Pohorju Municipality",
        isoCode: "177",
      },
      {
        name: "Rogaška Slatina Municipality",
        isoCode: "106",
      },
      {
        name: "Rogašovci Municipality",
        isoCode: "105",
      },
      {
        name: "Rogatec Municipality",
        isoCode: "107",
      },
      {
        name: "Ruše Municipality",
        isoCode: "108",
      },
      {
        name: "Šalovci Municipality",
        isoCode: "033",
      },
      {
        name: "Selnica ob Dravi Municipality",
        isoCode: "178",
      },
      {
        name: "Semič Municipality",
        isoCode: "109",
      },
      {
        name: "Šempeter–Vrtojba Municipality",
        isoCode: "183",
      },
      {
        name: "Šenčur Municipality",
        isoCode: "117",
      },
      {
        name: "Šentilj Municipality",
        isoCode: "118",
      },
      {
        name: "Šentjernej Municipality",
        isoCode: "119",
      },
      {
        name: "Šentjur Municipality",
        isoCode: "120",
      },
      {
        name: "Šentrupert Municipality",
        isoCode: "211",
      },
      {
        name: "Sevnica Municipality",
        isoCode: "110",
      },
      {
        name: "Sežana Municipality",
        isoCode: "111",
      },
      {
        name: "Škocjan Municipality",
        isoCode: "121",
      },
      {
        name: "Škofja Loka Municipality",
        isoCode: "122",
      },
      {
        name: "Slovenj Gradec City Municipality",
        isoCode: "112",
      },
      {
        name: "Slovenska Bistrica Municipality",
        isoCode: "113",
      },
      {
        name: "Slovenske Konjice Municipality",
        isoCode: "114",
      },
      {
        name: "Šmarje pri Jelšah Municipality",
        isoCode: "124",
      },
      {
        name: "Šmarješke Toplice Municipality",
        isoCode: "206",
      },
      {
        name: "Šmartno ob Paki Municipality",
        isoCode: "125",
      },
      {
        name: "Šmartno pri Litiji Municipality",
        isoCode: "194",
      },
      {
        name: "Sodražica Municipality",
        isoCode: "179",
      },
      {
        name: "Solčava Municipality",
        isoCode: "180",
      },
      {
        name: "Šoštanj Municipality",
        isoCode: "126",
      },
      {
        name: "Središče ob Dravi",
        isoCode: "202",
      },
      {
        name: "Starše Municipality",
        isoCode: "115",
      },
      {
        name: "Štore Municipality",
        isoCode: "127",
      },
      {
        name: "Straža Municipality",
        isoCode: "203",
      },
      {
        name: "Sveta Ana Municipality",
        isoCode: "181",
      },
      {
        name: "Sveta Trojica v Slovenskih Goricah Municipality",
        isoCode: "204",
      },
      {
        name: "Sveti Andraž v Slovenskih Goricah Municipality",
        isoCode: "182",
      },
      {
        name: "Sveti Jurij ob Ščavnici Municipality",
        isoCode: "116",
      },
      {
        name: "Sveti Jurij v Slovenskih Goricah Municipality",
        isoCode: "210",
      },
      {
        name: "Sveti Tomaž Municipality",
        isoCode: "205",
      },
      {
        name: "Tabor Municipality",
        isoCode: "184",
      },
      {
        name: "Tišina Municipality",
        isoCode: "010",
      },
      {
        name: "Tolmin Municipality",
        isoCode: "128",
      },
      {
        name: "Trbovlje Municipality",
        isoCode: "129",
      },
      {
        name: "Trebnje Municipality",
        isoCode: "130",
      },
      {
        name: "Trnovska Vas Municipality",
        isoCode: "185",
      },
      {
        name: "Tržič Municipality",
        isoCode: "131",
      },
      {
        name: "Trzin Municipality",
        isoCode: "186",
      },
      {
        name: "Turnišče Municipality",
        isoCode: "132",
      },
      {
        name: "Velika Polana Municipality",
        isoCode: "187",
      },
      {
        name: "Velike Lašče Municipality",
        isoCode: "134",
      },
      {
        name: "Veržej Municipality",
        isoCode: "188",
      },
      {
        name: "Videm Municipality",
        isoCode: "135",
      },
      {
        name: "Vipava Municipality",
        isoCode: "136",
      },
      {
        name: "Vitanje Municipality",
        isoCode: "137",
      },
      {
        name: "Vodice Municipality",
        isoCode: "138",
      },
      {
        name: "Vojnik Municipality",
        isoCode: "139",
      },
      {
        name: "Vransko Municipality",
        isoCode: "189",
      },
      {
        name: "Vrhnika Municipality",
        isoCode: "140",
      },
      {
        name: "Vuzenica Municipality",
        isoCode: "141",
      },
      {
        name: "Zagorje ob Savi Municipality",
        isoCode: "142",
      },
      {
        name: "Žalec Municipality",
        isoCode: "190",
      },
      {
        name: "Zavrč Municipality",
        isoCode: "143",
      },
      {
        name: "Železniki Municipality",
        isoCode: "146",
      },
      {
        name: "Žetale Municipality",
        isoCode: "191",
      },
      {
        name: "Žiri Municipality",
        isoCode: "147",
      },
      {
        name: "Žirovnica Municipality",
        isoCode: "192",
      },
      {
        name: "Zreče Municipality",
        isoCode: "144",
      },
      {
        name: "Žužemberk Municipality",
        isoCode: "193",
      },
    ],
  },
  {
    name: "Solomon Islands",
    isoCode: "SB",
    states: [
      {
        name: "Central Province",
        isoCode: "CE",
      },
      {
        name: "Choiseul Province",
        isoCode: "CH",
      },
      {
        name: "Guadalcanal Province",
        isoCode: "GU",
      },
      {
        name: "Honiara",
        isoCode: "CT",
      },
      {
        name: "Isabel Province",
        isoCode: "IS",
      },
      {
        name: "Makira-Ulawa Province",
        isoCode: "MK",
      },
      {
        name: "Malaita Province",
        isoCode: "ML",
      },
      {
        name: "Rennell and Bellona Province",
        isoCode: "RB",
      },
      {
        name: "Temotu Province",
        isoCode: "TE",
      },
      {
        name: "Western Province",
        isoCode: "WE",
      },
    ],
  },
  {
    name: "Somalia",
    isoCode: "SO",
    states: [
      {
        name: "Awdal Region",
        isoCode: "AW",
      },
      {
        name: "Bakool",
        isoCode: "BK",
      },
      {
        name: "Banaadir",
        isoCode: "BN",
      },
      {
        name: "Bari",
        isoCode: "BR",
      },
      {
        name: "Bay",
        isoCode: "BY",
      },
      {
        name: "Galguduud",
        isoCode: "GA",
      },
      {
        name: "Gedo",
        isoCode: "GE",
      },
      {
        name: "Hiran",
        isoCode: "HI",
      },
      {
        name: "Lower Juba",
        isoCode: "JH",
      },
      {
        name: "Lower Shebelle",
        isoCode: "SH",
      },
      {
        name: "Middle Juba",
        isoCode: "JD",
      },
      {
        name: "Middle Shebelle",
        isoCode: "SD",
      },
      {
        name: "Mudug",
        isoCode: "MU",
      },
      {
        name: "Nugal",
        isoCode: "NU",
      },
      {
        name: "Sanaag Region",
        isoCode: "SA",
      },
      {
        name: "Togdheer Region",
        isoCode: "TO",
      },
    ],
  },
  {
    name: "South Africa",
    isoCode: "ZA",
    states: [
      {
        name: "Eastern Cape",
        isoCode: "EC",
      },
      {
        name: "Free State",
        isoCode: "FS",
      },
      {
        name: "Gauteng",
        isoCode: "GP",
      },
      {
        name: "KwaZulu-Natal",
        isoCode: "KZN",
      },
      {
        name: "Limpopo",
        isoCode: "LP",
      },
      {
        name: "Mpumalanga",
        isoCode: "MP",
      },
      {
        name: "North West",
        isoCode: "NW",
      },
      {
        name: "Northern Cape",
        isoCode: "NC",
      },
      {
        name: "Western Cape",
        isoCode: "WC",
      },
    ],
  },
  {
    name: "South Georgia",
    isoCode: "GS",
    states: [],
  },
  {
    name: "South Korea",
    isoCode: "KR",
    states: [
      {
        name: "Busan",
        isoCode: "26",
      },
      {
        name: "Daegu",
        isoCode: "27",
      },
      {
        name: "Daejeon",
        isoCode: "30",
      },
      {
        name: "Gangwon Province",
        isoCode: "42",
      },
      {
        name: "Gwangju",
        isoCode: "29",
      },
      {
        name: "Gyeonggi Province",
        isoCode: "41",
      },
      {
        name: "Incheon",
        isoCode: "28",
      },
      {
        name: "Jeju",
        isoCode: "49",
      },
      {
        name: "North Chungcheong Province",
        isoCode: "43",
      },
      {
        name: "North Gyeongsang Province",
        isoCode: "47",
      },
      {
        name: "North Jeolla Province",
        isoCode: "45",
      },
      {
        name: "Sejong City",
        isoCode: "50",
      },
      {
        name: "Seoul",
        isoCode: "11",
      },
      {
        name: "South Chungcheong Province",
        isoCode: "44",
      },
      {
        name: "South Gyeongsang Province",
        isoCode: "48",
      },
      {
        name: "South Jeolla Province",
        isoCode: "46",
      },
      {
        name: "Ulsan",
        isoCode: "31",
      },
    ],
  },
  {
    name: "South Sudan",
    isoCode: "SS",
    states: [
      {
        name: "Central Equatoria",
        isoCode: "EC",
      },
      {
        name: "Eastern Equatoria",
        isoCode: "EE",
      },
      {
        name: "Jonglei State",
        isoCode: "JG",
      },
      {
        name: "Lakes",
        isoCode: "LK",
      },
      {
        name: "Northern Bahr el Ghazal",
        isoCode: "BN",
      },
      {
        name: "Unity",
        isoCode: "UY",
      },
      {
        name: "Upper Nile",
        isoCode: "NU",
      },
      {
        name: "Warrap",
        isoCode: "WR",
      },
      {
        name: "Western Bahr el Ghazal",
        isoCode: "BW",
      },
      {
        name: "Western Equatoria",
        isoCode: "EW",
      },
    ],
  },
  {
    name: "Spain",
    isoCode: "ES",
    states: [
      {
        name: "Andalusia",
        isoCode: "AN",
      },
      {
        name: "Aragon",
        isoCode: "AR",
      },
      {
        name: "Asturias",
        isoCode: "AS",
      },
      {
        name: "Ávila",
        isoCode: "AV",
      },
      {
        name: "Balearic Islands",
        isoCode: "PM",
      },
      {
        name: "Basque Country",
        isoCode: "PV",
      },
      {
        name: "Burgos Province",
        isoCode: "BU",
      },
      {
        name: "Canary Islands",
        isoCode: "CN",
      },
      {
        name: "Cantabria",
        isoCode: "CB",
      },
      {
        name: "Castile and León",
        isoCode: "CL",
      },
      {
        name: "Castilla La Mancha",
        isoCode: "CM",
      },
      {
        name: "Catalonia",
        isoCode: "CT",
      },
      {
        name: "Ceuta",
        isoCode: "CE",
      },
      {
        name: "Extremadura",
        isoCode: "EX",
      },
      {
        name: "Galicia",
        isoCode: "GA",
      },
      {
        name: "La Rioja",
        isoCode: "RI",
      },
      {
        name: "Léon",
        isoCode: "LE",
      },
      {
        name: "Madrid",
        isoCode: "MD",
      },
      {
        name: "Melilla",
        isoCode: "ML",
      },
      {
        name: "Murcia",
        isoCode: "MC",
      },
      {
        name: "Navarra",
        isoCode: "NC",
      },
      {
        name: "Palencia Province",
        isoCode: "P",
      },
      {
        name: "Salamanca Province",
        isoCode: "SA",
      },
      {
        name: "Segovia Province",
        isoCode: "SG",
      },
      {
        name: "Soria Province",
        isoCode: "SO",
      },
      {
        name: "Valencia",
        isoCode: "VC",
      },
      {
        name: "Valladolid Province",
        isoCode: "VA",
      },
      {
        name: "Zamora Province",
        isoCode: "ZA",
      },
    ],
  },
  {
    name: "Sri Lanka",
    isoCode: "LK",
    states: [
      {
        name: "Ampara District",
        isoCode: "52",
      },
      {
        name: "Anuradhapura District",
        isoCode: "71",
      },
      {
        name: "Badulla District",
        isoCode: "81",
      },
      {
        name: "Batticaloa District",
        isoCode: "51",
      },
      {
        name: "Central Province",
        isoCode: "2",
      },
      {
        name: "Colombo District",
        isoCode: "11",
      },
      {
        name: "Eastern Province",
        isoCode: "5",
      },
      {
        name: "Galle District",
        isoCode: "31",
      },
      {
        name: "Gampaha District",
        isoCode: "12",
      },
      {
        name: "Hambantota District",
        isoCode: "33",
      },
      {
        name: "Jaffna District",
        isoCode: "41",
      },
      {
        name: "Kalutara District",
        isoCode: "13",
      },
      {
        name: "Kandy District",
        isoCode: "21",
      },
      {
        name: "Kegalle District",
        isoCode: "92",
      },
      {
        name: "Kilinochchi District",
        isoCode: "42",
      },
      {
        name: "Mannar District",
        isoCode: "43",
      },
      {
        name: "Matale District",
        isoCode: "22",
      },
      {
        name: "Matara District",
        isoCode: "32",
      },
      {
        name: "Monaragala District",
        isoCode: "82",
      },
      {
        name: "Mullaitivu District",
        isoCode: "45",
      },
      {
        name: "North Central Province",
        isoCode: "7",
      },
      {
        name: "North Western Province",
        isoCode: "6",
      },
      {
        name: "Northern Province",
        isoCode: "4",
      },
      {
        name: "Nuwara Eliya District",
        isoCode: "23",
      },
      {
        name: "Polonnaruwa District",
        isoCode: "72",
      },
      {
        name: "Puttalam District",
        isoCode: "62",
      },
      {
        name: "Ratnapura district",
        isoCode: "91",
      },
      {
        name: "Sabaragamuwa Province",
        isoCode: "9",
      },
      {
        name: "Southern Province",
        isoCode: "3",
      },
      {
        name: "Trincomalee District",
        isoCode: "53",
      },
      {
        name: "Uva Province",
        isoCode: "8",
      },
      {
        name: "Vavuniya District",
        isoCode: "44",
      },
      {
        name: "Western Province",
        isoCode: "1",
      },
    ],
  },
  {
    name: "Sudan",
    isoCode: "SD",
    states: [
      {
        name: "Al Jazirah",
        isoCode: "GZ",
      },
      {
        name: "Al Qadarif",
        isoCode: "GD",
      },
      {
        name: "Blue Nile",
        isoCode: "NB",
      },
      {
        name: "Central Darfur",
        isoCode: "DC",
      },
      {
        name: "East Darfur",
        isoCode: "DE",
      },
      {
        name: "Kassala",
        isoCode: "KA",
      },
      {
        name: "Khartoum",
        isoCode: "KH",
      },
      {
        name: "North Darfur",
        isoCode: "DN",
      },
      {
        name: "North Kordofan",
        isoCode: "KN",
      },
      {
        name: "Northern",
        isoCode: "NO",
      },
      {
        name: "Red Sea",
        isoCode: "RS",
      },
      {
        name: "River Nile",
        isoCode: "NR",
      },
      {
        name: "Sennar",
        isoCode: "SI",
      },
      {
        name: "South Darfur",
        isoCode: "DS",
      },
      {
        name: "South Kordofan",
        isoCode: "KS",
      },
      {
        name: "West Darfur",
        isoCode: "DW",
      },
      {
        name: "West Kordofan",
        isoCode: "GK",
      },
      {
        name: "White Nile",
        isoCode: "NW",
      },
    ],
  },
  {
    name: "Suriname",
    isoCode: "SR",
    states: [
      {
        name: "Brokopondo District",
        isoCode: "BR",
      },
      {
        name: "Commewijne District",
        isoCode: "CM",
      },
      {
        name: "Coronie District",
        isoCode: "CR",
      },
      {
        name: "Marowijne District",
        isoCode: "MA",
      },
      {
        name: "Nickerie District",
        isoCode: "NI",
      },
      {
        name: "Para District",
        isoCode: "PR",
      },
      {
        name: "Paramaribo District",
        isoCode: "PM",
      },
      {
        name: "Saramacca District",
        isoCode: "SA",
      },
      {
        name: "Sipaliwini District",
        isoCode: "SI",
      },
      {
        name: "Wanica District",
        isoCode: "WA",
      },
    ],
  },
  {
    name: "Svalbard And Jan Mayen Islands",
    isoCode: "SJ",
    states: [],
  },
  {
    name: "Swaziland",
    isoCode: "SZ",
    states: [
      {
        name: "Hhohho District",
        isoCode: "HH",
      },
      {
        name: "Lubombo District",
        isoCode: "LU",
      },
      {
        name: "Manzini District",
        isoCode: "MA",
      },
      {
        name: "Shiselweni District",
        isoCode: "SH",
      },
    ],
  },
  {
    name: "Sweden",
    isoCode: "SE",
    states: [
      {
        name: "Blekinge",
        isoCode: "K",
      },
      {
        name: "Dalarna County",
        isoCode: "W",
      },
      {
        name: "Gävleborg County",
        isoCode: "X",
      },
      {
        name: "Gotland County",
        isoCode: "I",
      },
      {
        name: "Halland County",
        isoCode: "N",
      },
      {
        name: "Jönköping County",
        isoCode: "F",
      },
      {
        name: "Kalmar County",
        isoCode: "H",
      },
      {
        name: "Kronoberg County",
        isoCode: "G",
      },
      {
        name: "Norrbotten County",
        isoCode: "BD",
      },
      {
        name: "Örebro County",
        isoCode: "T",
      },
      {
        name: "Östergötland County",
        isoCode: "E",
      },
      {
        name: "Skåne County",
        isoCode: "M",
      },
      {
        name: "Södermanland County",
        isoCode: "D",
      },
      {
        name: "Stockholm County",
        isoCode: "AB",
      },
      {
        name: "Uppsala County",
        isoCode: "C",
      },
      {
        name: "Värmland County",
        isoCode: "S",
      },
      {
        name: "Västerbotten County",
        isoCode: "AC",
      },
      {
        name: "Västernorrland County",
        isoCode: "Y",
      },
      {
        name: "Västmanland County",
        isoCode: "U",
      },
      {
        name: "Västra Götaland County",
        isoCode: "O",
      },
    ],
  },
  {
    name: "Switzerland",
    isoCode: "CH",
    states: [
      {
        name: "Aargau",
        isoCode: "AG",
      },
      {
        name: "Appenzell Ausserrhoden",
        isoCode: "AR",
      },
      {
        name: "Appenzell Innerrhoden",
        isoCode: "AI",
      },
      {
        name: "Basel-Land",
        isoCode: "BL",
      },
      {
        name: "Basel-Stadt",
        isoCode: "BS",
      },
      {
        name: "Bern",
        isoCode: "BE",
      },
      {
        name: "Fribourg",
        isoCode: "FR",
      },
      {
        name: "Geneva",
        isoCode: "GE",
      },
      {
        name: "Glarus",
        isoCode: "GL",
      },
      {
        name: "Graubünden",
        isoCode: "GR",
      },
      {
        name: "Jura",
        isoCode: "JU",
      },
      {
        name: "Lucerne",
        isoCode: "LU",
      },
      {
        name: "Neuchâtel",
        isoCode: "NE",
      },
      {
        name: "Nidwalden",
        isoCode: "NW",
      },
      {
        name: "Obwalden",
        isoCode: "OW",
      },
      {
        name: "Schaffhausen",
        isoCode: "SH",
      },
      {
        name: "Schwyz",
        isoCode: "SZ",
      },
      {
        name: "Solothurn",
        isoCode: "SO",
      },
      {
        name: "St. Gallen",
        isoCode: "SG",
      },
      {
        name: "Thurgau",
        isoCode: "TG",
      },
      {
        name: "Ticino",
        isoCode: "TI",
      },
      {
        name: "Uri",
        isoCode: "UR",
      },
      {
        name: "Valais",
        isoCode: "VS",
      },
      {
        name: "Vaud",
        isoCode: "VD",
      },
      {
        name: "Zug",
        isoCode: "ZG",
      },
      {
        name: "Zürich",
        isoCode: "ZH",
      },
    ],
  },
  {
    name: "Syria",
    isoCode: "SY",
    states: [
      {
        name: "Al-Hasakah Governorate",
        isoCode: "HA",
      },
      {
        name: "Al-Raqqah Governorate",
        isoCode: "RA",
      },
      {
        name: "Aleppo Governorate",
        isoCode: "HL",
      },
      {
        name: "As-Suwayda Governorate",
        isoCode: "SU",
      },
      {
        name: "Damascus Governorate",
        isoCode: "DI",
      },
      {
        name: "Daraa Governorate",
        isoCode: "DR",
      },
      {
        name: "Deir ez-Zor Governorate",
        isoCode: "DY",
      },
      {
        name: "Hama Governorate",
        isoCode: "HM",
      },
      {
        name: "Homs Governorate",
        isoCode: "HI",
      },
      {
        name: "Idlib Governorate",
        isoCode: "ID",
      },
      {
        name: "Latakia Governorate",
        isoCode: "LA",
      },
      {
        name: "Quneitra Governorate",
        isoCode: "QU",
      },
      {
        name: "Rif Dimashq Governorate",
        isoCode: "RD",
      },
      {
        name: "Tartus Governorate",
        isoCode: "TA",
      },
    ],
  },
  {
    name: "Taiwan",
    isoCode: "TW",
    states: [
      {
        name: "Changhua",
        isoCode: "CHA",
      },
      {
        name: "Chiayi",
        isoCode: "CYI",
      },
      {
        name: "Chiayi",
        isoCode: "CYQ",
      },
      {
        name: "Hsinchu",
        isoCode: "HSQ",
      },
      {
        name: "Hsinchu",
        isoCode: "HSZ",
      },
      {
        name: "Hualien",
        isoCode: "HUA",
      },
      {
        name: "Kaohsiung",
        isoCode: "KHH",
      },
      {
        name: "Keelung",
        isoCode: "KEE",
      },
      {
        name: "Kinmen",
        isoCode: "KIN",
      },
      {
        name: "Lienchiang",
        isoCode: "LIE",
      },
      {
        name: "Miaoli",
        isoCode: "MIA",
      },
      {
        name: "Nantou",
        isoCode: "NAN",
      },
      {
        name: "New Taipei",
        isoCode: "NWT",
      },
      {
        name: "Penghu",
        isoCode: "PEN",
      },
      {
        name: "Pingtung",
        isoCode: "PIF",
      },
      {
        name: "Taichung",
        isoCode: "TXG",
      },
      {
        name: "Tainan",
        isoCode: "TNN",
      },
      {
        name: "Taipei",
        isoCode: "TPE",
      },
      {
        name: "Taitung",
        isoCode: "TTT",
      },
      {
        name: "Taoyuan",
        isoCode: "TAO",
      },
      {
        name: "Yilan",
        isoCode: "ILA",
      },
      {
        name: "Yunlin",
        isoCode: "YUN",
      },
    ],
  },
  {
    name: "Tajikistan",
    isoCode: "TJ",
    states: [
      {
        name: "districts of Republican Subordination",
        isoCode: "RA",
      },
      {
        name: "Gorno-Badakhshan Autonomous Province",
        isoCode: "GB",
      },
      {
        name: "Khatlon Province",
        isoCode: "KT",
      },
      {
        name: "Sughd Province",
        isoCode: "SU",
      },
    ],
  },
  {
    name: "Tanzania",
    isoCode: "TZ",
    states: [
      {
        name: "Arusha",
        isoCode: "01",
      },
      {
        name: "Dar es Salaam",
        isoCode: "02",
      },
      {
        name: "Dodoma",
        isoCode: "03",
      },
      {
        name: "Geita",
        isoCode: "27",
      },
      {
        name: "Iringa",
        isoCode: "04",
      },
      {
        name: "Kagera",
        isoCode: "05",
      },
      {
        name: "Katavi",
        isoCode: "28",
      },
      {
        name: "Kigoma",
        isoCode: "08",
      },
      {
        name: "Kilimanjaro",
        isoCode: "09",
      },
      {
        name: "Lindi",
        isoCode: "12",
      },
      {
        name: "Manyara",
        isoCode: "26",
      },
      {
        name: "Mara",
        isoCode: "13",
      },
      {
        name: "Mbeya",
        isoCode: "14",
      },
      {
        name: "Morogoro",
        isoCode: "16",
      },
      {
        name: "Mtwara",
        isoCode: "17",
      },
      {
        name: "Mwanza",
        isoCode: "18",
      },
      {
        name: "Njombe",
        isoCode: "29",
      },
      {
        name: "Pemba North",
        isoCode: "06",
      },
      {
        name: "Pemba South",
        isoCode: "10",
      },
      {
        name: "Pwani",
        isoCode: "19",
      },
      {
        name: "Rukwa",
        isoCode: "20",
      },
      {
        name: "Ruvuma",
        isoCode: "21",
      },
      {
        name: "Shinyanga",
        isoCode: "22",
      },
      {
        name: "Simiyu",
        isoCode: "30",
      },
      {
        name: "Singida",
        isoCode: "23",
      },
      {
        name: "Songwe",
        isoCode: "31",
      },
      {
        name: "Tabora",
        isoCode: "24",
      },
      {
        name: "Tanga",
        isoCode: "25",
      },
      {
        name: "Zanzibar North",
        isoCode: "07",
      },
      {
        name: "Zanzibar South",
        isoCode: "11",
      },
      {
        name: "Zanzibar West",
        isoCode: "15",
      },
    ],
  },
  {
    name: "Thailand",
    isoCode: "TH",
    states: [
      {
        name: "Amnat Charoen",
        isoCode: "37",
      },
      {
        name: "Ang Thong",
        isoCode: "15",
      },
      {
        name: "Bangkok",
        isoCode: "10",
      },
      {
        name: "Bueng Kan",
        isoCode: "38",
      },
      {
        name: "Buri Ram",
        isoCode: "31",
      },
      {
        name: "Chachoengsao",
        isoCode: "24",
      },
      {
        name: "Chai Nat",
        isoCode: "18",
      },
      {
        name: "Chaiyaphum",
        isoCode: "36",
      },
      {
        name: "Chanthaburi",
        isoCode: "22",
      },
      {
        name: "Chiang Mai",
        isoCode: "50",
      },
      {
        name: "Chiang Rai",
        isoCode: "57",
      },
      {
        name: "Chon Buri",
        isoCode: "20",
      },
      {
        name: "Chumphon",
        isoCode: "86",
      },
      {
        name: "Kalasin",
        isoCode: "46",
      },
      {
        name: "Kamphaeng Phet",
        isoCode: "62",
      },
      {
        name: "Kanchanaburi",
        isoCode: "71",
      },
      {
        name: "Khon Kaen",
        isoCode: "40",
      },
      {
        name: "Krabi",
        isoCode: "81",
      },
      {
        name: "Lampang",
        isoCode: "52",
      },
      {
        name: "Lamphun",
        isoCode: "51",
      },
      {
        name: "Loei",
        isoCode: "42",
      },
      {
        name: "Lop Buri",
        isoCode: "16",
      },
      {
        name: "Mae Hong Son",
        isoCode: "58",
      },
      {
        name: "Maha Sarakham",
        isoCode: "44",
      },
      {
        name: "Mukdahan",
        isoCode: "49",
      },
      {
        name: "Nakhon Nayok",
        isoCode: "26",
      },
      {
        name: "Nakhon Pathom",
        isoCode: "73",
      },
      {
        name: "Nakhon Phanom",
        isoCode: "48",
      },
      {
        name: "Nakhon Ratchasima",
        isoCode: "30",
      },
      {
        name: "Nakhon Sawan",
        isoCode: "60",
      },
      {
        name: "Nakhon Si Thammarat",
        isoCode: "80",
      },
      {
        name: "Nan",
        isoCode: "55",
      },
      {
        name: "Narathiwat",
        isoCode: "96",
      },
      {
        name: "Nong Bua Lam Phu",
        isoCode: "39",
      },
      {
        name: "Nong Khai",
        isoCode: "43",
      },
      {
        name: "Nonthaburi",
        isoCode: "12",
      },
      {
        name: "Pathum Thani",
        isoCode: "13",
      },
      {
        name: "Pattani",
        isoCode: "94",
      },
      {
        name: "Pattaya",
        isoCode: "S",
      },
      {
        name: "Phangnga",
        isoCode: "82",
      },
      {
        name: "Phatthalung",
        isoCode: "93",
      },
      {
        name: "Phayao",
        isoCode: "56",
      },
      {
        name: "Phetchabun",
        isoCode: "67",
      },
      {
        name: "Phetchaburi",
        isoCode: "76",
      },
      {
        name: "Phichit",
        isoCode: "66",
      },
      {
        name: "Phitsanulok",
        isoCode: "65",
      },
      {
        name: "Phra Nakhon Si Ayutthaya",
        isoCode: "14",
      },
      {
        name: "Phrae",
        isoCode: "54",
      },
      {
        name: "Phuket",
        isoCode: "83",
      },
      {
        name: "Prachin Buri",
        isoCode: "25",
      },
      {
        name: "Prachuap Khiri Khan",
        isoCode: "77",
      },
      {
        name: "Ranong",
        isoCode: "85",
      },
      {
        name: "Ratchaburi",
        isoCode: "70",
      },
      {
        name: "Rayong",
        isoCode: "21",
      },
      {
        name: "Roi Et",
        isoCode: "45",
      },
      {
        name: "Sa Kaeo",
        isoCode: "27",
      },
      {
        name: "Sakon Nakhon",
        isoCode: "47",
      },
      {
        name: "Samut Prakan",
        isoCode: "11",
      },
      {
        name: "Samut Sakhon",
        isoCode: "74",
      },
      {
        name: "Samut Songkhram",
        isoCode: "75",
      },
      {
        name: "Saraburi",
        isoCode: "19",
      },
      {
        name: "Satun",
        isoCode: "91",
      },
      {
        name: "Si Sa Ket",
        isoCode: "33",
      },
      {
        name: "Sing Buri",
        isoCode: "17",
      },
      {
        name: "Songkhla",
        isoCode: "90",
      },
      {
        name: "Sukhothai",
        isoCode: "64",
      },
      {
        name: "Suphan Buri",
        isoCode: "72",
      },
      {
        name: "Surat Thani",
        isoCode: "84",
      },
      {
        name: "Surin",
        isoCode: "32",
      },
      {
        name: "Tak",
        isoCode: "63",
      },
      {
        name: "Trang",
        isoCode: "92",
      },
      {
        name: "Trat",
        isoCode: "23",
      },
      {
        name: "Ubon Ratchathani",
        isoCode: "34",
      },
      {
        name: "Udon Thani",
        isoCode: "41",
      },
      {
        name: "Uthai Thani",
        isoCode: "61",
      },
      {
        name: "Uttaradit",
        isoCode: "53",
      },
      {
        name: "Yala",
        isoCode: "95",
      },
      {
        name: "Yasothon",
        isoCode: "35",
      },
    ],
  },
  {
    name: "The Bahamas",
    isoCode: "BS",
    states: [
      {
        name: "Acklins",
        isoCode: "AK",
      },
      {
        name: "Acklins and Crooked Islands",
        isoCode: "AC",
      },
      {
        name: "Berry Islands",
        isoCode: "BY",
      },
      {
        name: "Bimini",
        isoCode: "BI",
      },
      {
        name: "Black Point",
        isoCode: "BP",
      },
      {
        name: "Cat Island",
        isoCode: "CI",
      },
      {
        name: "Central Abaco",
        isoCode: "CO",
      },
      {
        name: "Central Andros",
        isoCode: "CS",
      },
      {
        name: "Central Eleuthera",
        isoCode: "CE",
      },
      {
        name: "Crooked Island",
        isoCode: "CK",
      },
      {
        name: "East Grand Bahama",
        isoCode: "EG",
      },
      {
        name: "Exuma",
        isoCode: "EX",
      },
      {
        name: "Freeport",
        isoCode: "FP",
      },
      {
        name: "Fresh Creek",
        isoCode: "FC",
      },
      {
        name: "Governor's Harbour",
        isoCode: "GH",
      },
      {
        name: "Grand Cay",
        isoCode: "GC",
      },
      {
        name: "Green Turtle Cay",
        isoCode: "GT",
      },
      {
        name: "Harbour Island",
        isoCode: "HI",
      },
      {
        name: "High Rock",
        isoCode: "HR",
      },
      {
        name: "Hope Town",
        isoCode: "HT",
      },
      {
        name: "Inagua",
        isoCode: "IN",
      },
      {
        name: "Kemps Bay",
        isoCode: "KB",
      },
      {
        name: "Long Island",
        isoCode: "LI",
      },
      {
        name: "Mangrove Cay",
        isoCode: "MC",
      },
      {
        name: "Marsh Harbour",
        isoCode: "MH",
      },
      {
        name: "Mayaguana District",
        isoCode: "MG",
      },
      {
        name: "New Providence",
        isoCode: "NP",
      },
      {
        name: "Nichollstown and Berry Islands",
        isoCode: "NB",
      },
      {
        name: "North Abaco",
        isoCode: "NO",
      },
      {
        name: "North Andros",
        isoCode: "NS",
      },
      {
        name: "North Eleuthera",
        isoCode: "NE",
      },
      {
        name: "Ragged Island",
        isoCode: "RI",
      },
      {
        name: "Rock Sound",
        isoCode: "RS",
      },
      {
        name: "Rum Cay District",
        isoCode: "RC",
      },
      {
        name: "San Salvador and Rum Cay",
        isoCode: "SR",
      },
      {
        name: "San Salvador Island",
        isoCode: "SS",
      },
      {
        name: "Sandy Point",
        isoCode: "SP",
      },
      {
        name: "South Abaco",
        isoCode: "SO",
      },
      {
        name: "South Andros",
        isoCode: "SA",
      },
      {
        name: "South Eleuthera",
        isoCode: "SE",
      },
      {
        name: "Spanish Wells",
        isoCode: "SW",
      },
      {
        name: "West Grand Bahama",
        isoCode: "WG",
      },
    ],
  },
  {
    name: "Togo",
    isoCode: "TG",
    states: [
      {
        name: "Centrale Region",
        isoCode: "C",
      },
      {
        name: "Kara Region",
        isoCode: "K",
      },
      {
        name: "Maritime",
        isoCode: "M",
      },
      {
        name: "Plateaux Region",
        isoCode: "P",
      },
      {
        name: "Savanes Region",
        isoCode: "S",
      },
    ],
  },
  {
    name: "Tokelau",
    isoCode: "TK",
    states: [],
  },
  {
    name: "Tonga",
    isoCode: "TO",
    states: [
      {
        name: "Haʻapai",
        isoCode: "02",
      },
      {
        name: "ʻEua",
        isoCode: "01",
      },
      {
        name: "Niuas",
        isoCode: "03",
      },
      {
        name: "Tongatapu",
        isoCode: "04",
      },
      {
        name: "Vavaʻu",
        isoCode: "05",
      },
    ],
  },
  {
    name: "Trinidad And Tobago",
    isoCode: "TT",
    states: [
      {
        name: "Arima",
        isoCode: "ARI",
      },
      {
        name: "Chaguanas",
        isoCode: "CHA",
      },
      {
        name: "Couva-Tabaquite-Talparo Regional Corporation",
        isoCode: "CTT",
      },
      {
        name: "Diego Martin Regional Corporation",
        isoCode: "DMN",
      },
      {
        name: "Eastern Tobago",
        isoCode: "ETO",
      },
      {
        name: "Penal-Debe Regional Corporation",
        isoCode: "PED",
      },
      {
        name: "Point Fortin",
        isoCode: "PTF",
      },
      {
        name: "Port of Spain",
        isoCode: "POS",
      },
      {
        name: "Princes Town Regional Corporation",
        isoCode: "PRT",
      },
      {
        name: "Rio Claro-Mayaro Regional Corporation",
        isoCode: "MRC",
      },
      {
        name: "San Fernando",
        isoCode: "SFO",
      },
      {
        name: "San Juan-Laventille Regional Corporation",
        isoCode: "SJL",
      },
      {
        name: "Sangre Grande Regional Corporation",
        isoCode: "SGE",
      },
      {
        name: "Siparia Regional Corporation",
        isoCode: "SIP",
      },
      {
        name: "Tunapuna-Piarco Regional Corporation",
        isoCode: "TUP",
      },
      {
        name: "Western Tobago",
        isoCode: "WTO",
      },
    ],
  },
  {
    name: "Tunisia",
    isoCode: "TN",
    states: [
      {
        name: "Ariana Governorate",
        isoCode: "12",
      },
      {
        name: "Ben Arous Governorate",
        isoCode: "13",
      },
      {
        name: "Bizerte Governorate",
        isoCode: "23",
      },
      {
        name: "Gabès Governorate",
        isoCode: "81",
      },
      {
        name: "Gafsa Governorate",
        isoCode: "71",
      },
      {
        name: "Jendouba Governorate",
        isoCode: "32",
      },
      {
        name: "Kairouan Governorate",
        isoCode: "41",
      },
      {
        name: "Kasserine Governorate",
        isoCode: "42",
      },
      {
        name: "Kassrine",
        isoCode: "31",
      },
      {
        name: "Kebili Governorate",
        isoCode: "73",
      },
      {
        name: "Kef Governorate",
        isoCode: "33",
      },
      {
        name: "Mahdia Governorate",
        isoCode: "53",
      },
      {
        name: "Manouba Governorate",
        isoCode: "14",
      },
      {
        name: "Medenine Governorate",
        isoCode: "82",
      },
      {
        name: "Monastir Governorate",
        isoCode: "52",
      },
      {
        name: "Sfax Governorate",
        isoCode: "61",
      },
      {
        name: "Sidi Bouzid Governorate",
        isoCode: "43",
      },
      {
        name: "Siliana Governorate",
        isoCode: "34",
      },
      {
        name: "Sousse Governorate",
        isoCode: "51",
      },
      {
        name: "Tataouine Governorate",
        isoCode: "83",
      },
      {
        name: "Tozeur Governorate",
        isoCode: "72",
      },
      {
        name: "Tunis Governorate",
        isoCode: "11",
      },
      {
        name: "Zaghouan Governorate",
        isoCode: "22",
      },
    ],
  },
  {
    name: "Turkey",
    isoCode: "TR",
    states: [
      {
        name: "Adana",
        isoCode: "01",
      },
      {
        name: "Adıyaman",
        isoCode: "02",
      },
      {
        name: "Afyonkarahisar",
        isoCode: "03",
      },
      {
        name: "Ağrı",
        isoCode: "04",
      },
      {
        name: "Aksaray",
        isoCode: "68",
      },
      {
        name: "Amasya",
        isoCode: "05",
      },
      {
        name: "Ankara",
        isoCode: "06",
      },
      {
        name: "Antalya",
        isoCode: "07",
      },
      {
        name: "Ardahan",
        isoCode: "75",
      },
      {
        name: "Artvin",
        isoCode: "08",
      },
      {
        name: "Aydın",
        isoCode: "09",
      },
      {
        name: "Balıkesir",
        isoCode: "10",
      },
      {
        name: "Bartın",
        isoCode: "74",
      },
      {
        name: "Batman",
        isoCode: "72",
      },
      {
        name: "Bayburt",
        isoCode: "69",
      },
      {
        name: "Bilecik",
        isoCode: "11",
      },
      {
        name: "Bingöl",
        isoCode: "12",
      },
      {
        name: "Bitlis",
        isoCode: "13",
      },
      {
        name: "Bolu",
        isoCode: "14",
      },
      {
        name: "Burdur",
        isoCode: "15",
      },
      {
        name: "Bursa",
        isoCode: "16",
      },
      {
        name: "Çanakkale",
        isoCode: "17",
      },
      {
        name: "Çankırı",
        isoCode: "18",
      },
      {
        name: "Çorum",
        isoCode: "19",
      },
      {
        name: "Denizli",
        isoCode: "20",
      },
      {
        name: "Diyarbakır",
        isoCode: "21",
      },
      {
        name: "Düzce",
        isoCode: "81",
      },
      {
        name: "Edirne",
        isoCode: "22",
      },
      {
        name: "Elazığ",
        isoCode: "23",
      },
      {
        name: "Erzincan",
        isoCode: "24",
      },
      {
        name: "Erzurum",
        isoCode: "25",
      },
      {
        name: "Eskişehir",
        isoCode: "26",
      },
      {
        name: "Gaziantep",
        isoCode: "27",
      },
      {
        name: "Giresun",
        isoCode: "28",
      },
      {
        name: "Gümüşhane",
        isoCode: "29",
      },
      {
        name: "Hakkâri",
        isoCode: "30",
      },
      {
        name: "Hatay",
        isoCode: "31",
      },
      {
        name: "Iğdır",
        isoCode: "76",
      },
      {
        name: "Isparta",
        isoCode: "32",
      },
      {
        name: "Istanbul",
        isoCode: "34",
      },
      {
        name: "İzmir",
        isoCode: "35",
      },
      {
        name: "Kahramanmaraş",
        isoCode: "46",
      },
      {
        name: "Karabük",
        isoCode: "78",
      },
      {
        name: "Karaman",
        isoCode: "70",
      },
      {
        name: "Kars",
        isoCode: "36",
      },
      {
        name: "Kastamonu",
        isoCode: "37",
      },
      {
        name: "Kayseri",
        isoCode: "38",
      },
      {
        name: "Kilis",
        isoCode: "79",
      },
      {
        name: "Kırıkkale",
        isoCode: "71",
      },
      {
        name: "Kırklareli",
        isoCode: "39",
      },
      {
        name: "Kırşehir",
        isoCode: "40",
      },
      {
        name: "Kocaeli",
        isoCode: "41",
      },
      {
        name: "Konya",
        isoCode: "42",
      },
      {
        name: "Kütahya",
        isoCode: "43",
      },
      {
        name: "Malatya",
        isoCode: "44",
      },
      {
        name: "Manisa",
        isoCode: "45",
      },
      {
        name: "Mardin",
        isoCode: "47",
      },
      {
        name: "Mersin",
        isoCode: "33",
      },
      {
        name: "Muğla",
        isoCode: "48",
      },
      {
        name: "Muş",
        isoCode: "49",
      },
      {
        name: "Nevşehir",
        isoCode: "50",
      },
      {
        name: "Niğde",
        isoCode: "51",
      },
      {
        name: "Ordu",
        isoCode: "52",
      },
      {
        name: "Osmaniye",
        isoCode: "80",
      },
      {
        name: "Rize",
        isoCode: "53",
      },
      {
        name: "Sakarya",
        isoCode: "54",
      },
      {
        name: "Samsun",
        isoCode: "55",
      },
      {
        name: "Şanlıurfa",
        isoCode: "63",
      },
      {
        name: "Siirt",
        isoCode: "56",
      },
      {
        name: "Sinop",
        isoCode: "57",
      },
      {
        name: "Sivas",
        isoCode: "58",
      },
      {
        name: "Şırnak",
        isoCode: "73",
      },
      {
        name: "Tekirdağ",
        isoCode: "59",
      },
      {
        name: "Tokat",
        isoCode: "60",
      },
      {
        name: "Trabzon",
        isoCode: "61",
      },
      {
        name: "Tunceli",
        isoCode: "62",
      },
      {
        name: "Uşak",
        isoCode: "64",
      },
      {
        name: "Van",
        isoCode: "65",
      },
      {
        name: "Yalova",
        isoCode: "77",
      },
      {
        name: "Yozgat",
        isoCode: "66",
      },
      {
        name: "Zonguldak",
        isoCode: "67",
      },
    ],
  },
  {
    name: "Turkmenistan",
    isoCode: "TM",
    states: [
      {
        name: "Ahal Region",
        isoCode: "A",
      },
      {
        name: "Ashgabat",
        isoCode: "S",
      },
      {
        name: "Balkan Region",
        isoCode: "B",
      },
      {
        name: "Daşoguz Region",
        isoCode: "D",
      },
      {
        name: "Lebap Region",
        isoCode: "L",
      },
      {
        name: "Mary Region",
        isoCode: "M",
      },
    ],
  },
  {
    name: "Turks And Caicos Islands",
    isoCode: "TC",
    states: [],
  },
  {
    name: "Tuvalu",
    isoCode: "TV",
    states: [
      {
        name: "Funafuti",
        isoCode: "FUN",
      },
      {
        name: "Nanumanga",
        isoCode: "NMG",
      },
      {
        name: "Nanumea",
        isoCode: "NMA",
      },
      {
        name: "Niutao Island Council",
        isoCode: "NIT",
      },
      {
        name: "Nui",
        isoCode: "NUI",
      },
      {
        name: "Nukufetau",
        isoCode: "NKF",
      },
      {
        name: "Nukulaelae",
        isoCode: "NKL",
      },
      {
        name: "Vaitupu",
        isoCode: "VAI",
      },
    ],
  },
  {
    name: "Uganda",
    isoCode: "UG",
    states: [
      {
        name: "Abim District",
        isoCode: "314",
      },
      {
        name: "Adjumani District",
        isoCode: "301",
      },
      {
        name: "Agago District",
        isoCode: "322",
      },
      {
        name: "Alebtong District",
        isoCode: "323",
      },
      {
        name: "Amolatar District",
        isoCode: "315",
      },
      {
        name: "Amudat District",
        isoCode: "324",
      },
      {
        name: "Amuria District",
        isoCode: "216",
      },
      {
        name: "Amuru District",
        isoCode: "316",
      },
      {
        name: "Apac District",
        isoCode: "302",
      },
      {
        name: "Arua District",
        isoCode: "303",
      },
      {
        name: "Budaka District",
        isoCode: "217",
      },
      {
        name: "Bududa District",
        isoCode: "218",
      },
      {
        name: "Bugiri District",
        isoCode: "201",
      },
      {
        name: "Buhweju District",
        isoCode: "420",
      },
      {
        name: "Buikwe District",
        isoCode: "117",
      },
      {
        name: "Bukedea District",
        isoCode: "219",
      },
      {
        name: "Bukomansimbi District",
        isoCode: "118",
      },
      {
        name: "Bukwo District",
        isoCode: "220",
      },
      {
        name: "Bulambuli District",
        isoCode: "225",
      },
      {
        name: "Buliisa District",
        isoCode: "416",
      },
      {
        name: "Bundibugyo District",
        isoCode: "401",
      },
      {
        name: "Bunyangabu District",
        isoCode: "430",
      },
      {
        name: "Bushenyi District",
        isoCode: "402",
      },
      {
        name: "Busia District",
        isoCode: "202",
      },
      {
        name: "Butaleja District",
        isoCode: "221",
      },
      {
        name: "Butambala District",
        isoCode: "119",
      },
      {
        name: "Butebo District",
        isoCode: "233",
      },
      {
        name: "Buvuma District",
        isoCode: "120",
      },
      {
        name: "Buyende District",
        isoCode: "226",
      },
      {
        name: "Central Region",
        isoCode: "C",
      },
      {
        name: "Dokolo District",
        isoCode: "317",
      },
      {
        name: "Eastern Region",
        isoCode: "E",
      },
      {
        name: "Gomba District",
        isoCode: "121",
      },
      {
        name: "Gulu District",
        isoCode: "304",
      },
      {
        name: "Ibanda District",
        isoCode: "417",
      },
      {
        name: "Iganga District",
        isoCode: "203",
      },
      {
        name: "Isingiro District",
        isoCode: "418",
      },
      {
        name: "Jinja District",
        isoCode: "204",
      },
      {
        name: "Kaabong District",
        isoCode: "318",
      },
      {
        name: "Kabale District",
        isoCode: "404",
      },
      {
        name: "Kabarole District",
        isoCode: "405",
      },
      {
        name: "Kaberamaido District",
        isoCode: "213",
      },
      {
        name: "Kagadi District",
        isoCode: "427",
      },
      {
        name: "Kakumiro District",
        isoCode: "428",
      },
      {
        name: "Kalangala District",
        isoCode: "101",
      },
      {
        name: "Kaliro District",
        isoCode: "222",
      },
      {
        name: "Kalungu District",
        isoCode: "122",
      },
      {
        name: "Kampala District",
        isoCode: "102",
      },
      {
        name: "Kamuli District",
        isoCode: "205",
      },
      {
        name: "Kamwenge District",
        isoCode: "413",
      },
      {
        name: "Kanungu District",
        isoCode: "414",
      },
      {
        name: "Kapchorwa District",
        isoCode: "206",
      },
      {
        name: "Kasese District",
        isoCode: "406",
      },
      {
        name: "Katakwi District",
        isoCode: "207",
      },
      {
        name: "Kayunga District",
        isoCode: "112",
      },
      {
        name: "Kibaale District",
        isoCode: "407",
      },
      {
        name: "Kiboga District",
        isoCode: "103",
      },
      {
        name: "Kibuku District",
        isoCode: "227",
      },
      {
        name: "Kiruhura District",
        isoCode: "419",
      },
      {
        name: "Kiryandongo District",
        isoCode: "421",
      },
      {
        name: "Kisoro District",
        isoCode: "408",
      },
      {
        name: "Kitgum District",
        isoCode: "305",
      },
      {
        name: "Koboko District",
        isoCode: "319",
      },
      {
        name: "Kole District",
        isoCode: "325",
      },
      {
        name: "Kotido District",
        isoCode: "306",
      },
      {
        name: "Kumi District",
        isoCode: "208",
      },
      {
        name: "Kween District",
        isoCode: "228",
      },
      {
        name: "Kyankwanzi District",
        isoCode: "123",
      },
      {
        name: "Kyegegwa District",
        isoCode: "422",
      },
      {
        name: "Kyenjojo District",
        isoCode: "415",
      },
      {
        name: "Kyotera District",
        isoCode: "125",
      },
      {
        name: "Lamwo District",
        isoCode: "326",
      },
      {
        name: "Lira District",
        isoCode: "307",
      },
      {
        name: "Luuka District",
        isoCode: "229",
      },
      {
        name: "Luwero District",
        isoCode: "104",
      },
      {
        name: "Lwengo District",
        isoCode: "124",
      },
      {
        name: "Lyantonde District",
        isoCode: "114",
      },
      {
        name: "Manafwa District",
        isoCode: "223",
      },
      {
        name: "Maracha District",
        isoCode: "320",
      },
      {
        name: "Masaka District",
        isoCode: "105",
      },
      {
        name: "Masindi District",
        isoCode: "409",
      },
      {
        name: "Mayuge District",
        isoCode: "214",
      },
      {
        name: "Mbale District",
        isoCode: "209",
      },
      {
        name: "Mbarara District",
        isoCode: "410",
      },
      {
        name: "Mitooma District",
        isoCode: "423",
      },
      {
        name: "Mityana District",
        isoCode: "115",
      },
      {
        name: "Moroto District",
        isoCode: "308",
      },
      {
        name: "Moyo District",
        isoCode: "309",
      },
      {
        name: "Mpigi District",
        isoCode: "106",
      },
      {
        name: "Mubende District",
        isoCode: "107",
      },
      {
        name: "Mukono District",
        isoCode: "108",
      },
      {
        name: "Nakapiripirit District",
        isoCode: "311",
      },
      {
        name: "Nakaseke District",
        isoCode: "116",
      },
      {
        name: "Nakasongola District",
        isoCode: "109",
      },
      {
        name: "Namayingo District",
        isoCode: "230",
      },
      {
        name: "Namisindwa District",
        isoCode: "234",
      },
      {
        name: "Namutumba District",
        isoCode: "224",
      },
      {
        name: "Napak District",
        isoCode: "327",
      },
      {
        name: "Nebbi District",
        isoCode: "310",
      },
      {
        name: "Ngora District",
        isoCode: "231",
      },
      {
        name: "Northern Region",
        isoCode: "N",
      },
      {
        name: "Ntoroko District",
        isoCode: "424",
      },
      {
        name: "Ntungamo District",
        isoCode: "411",
      },
      {
        name: "Nwoya District",
        isoCode: "328",
      },
      {
        name: "Omoro District",
        isoCode: "331",
      },
      {
        name: "Otuke District",
        isoCode: "329",
      },
      {
        name: "Oyam District",
        isoCode: "321",
      },
      {
        name: "Pader District",
        isoCode: "312",
      },
      {
        name: "Pakwach District",
        isoCode: "332",
      },
      {
        name: "Pallisa District",
        isoCode: "210",
      },
      {
        name: "Rakai District",
        isoCode: "110",
      },
      {
        name: "Rubanda District",
        isoCode: "429",
      },
      {
        name: "Rubirizi District",
        isoCode: "425",
      },
      {
        name: "Rukiga District",
        isoCode: "431",
      },
      {
        name: "Rukungiri District",
        isoCode: "412",
      },
      {
        name: "Sembabule District",
        isoCode: "111",
      },
      {
        name: "Serere District",
        isoCode: "232",
      },
      {
        name: "Sheema District",
        isoCode: "426",
      },
      {
        name: "Sironko District",
        isoCode: "215",
      },
      {
        name: "Soroti District",
        isoCode: "211",
      },
      {
        name: "Tororo District",
        isoCode: "212",
      },
      {
        name: "Wakiso District",
        isoCode: "113",
      },
      {
        name: "Western Region",
        isoCode: "W",
      },
      {
        name: "Yumbe District",
        isoCode: "313",
      },
      {
        name: "Zombo District",
        isoCode: "330",
      },
    ],
  },
  {
    name: "Ukraine",
    isoCode: "UA",
    states: [
      {
        name: "Autonomous Republic of Crimea",
        isoCode: "43",
      },
      {
        name: "Cherkaska oblast",
        isoCode: "71",
      },
      {
        name: "Chernihivska oblast",
        isoCode: "74",
      },
      {
        name: "Chernivetska oblast",
        isoCode: "77",
      },
      {
        name: "Dnipropetrovska oblast",
        isoCode: "12",
      },
      {
        name: "Donetska oblast",
        isoCode: "14",
      },
      {
        name: "Ivano-Frankivska oblast",
        isoCode: "26",
      },
      {
        name: "Kharkivska oblast",
        isoCode: "63",
      },
      {
        name: "Khersonska oblast",
        isoCode: "65",
      },
      {
        name: "Khmelnytska oblast",
        isoCode: "68",
      },
      {
        name: "Kirovohradska oblast",
        isoCode: "35",
      },
      {
        name: "Kyiv",
        isoCode: "30",
      },
      {
        name: "Kyivska oblast",
        isoCode: "32",
      },
      {
        name: "Luhanska oblast",
        isoCode: "09",
      },
      {
        name: "Lvivska oblast",
        isoCode: "46",
      },
      {
        name: "Mykolaivska oblast",
        isoCode: "48",
      },
      {
        name: "Odeska oblast",
        isoCode: "51",
      },
      {
        name: "Poltavska oblast",
        isoCode: "53",
      },
      {
        name: "Rivnenska oblast",
        isoCode: "56",
      },
      {
        name: "Sumska oblast",
        isoCode: "59",
      },
      {
        name: "Ternopilska oblast",
        isoCode: "61",
      },
      {
        name: "Vinnytska oblast",
        isoCode: "05",
      },
      {
        name: "Volynska oblast",
        isoCode: "07",
      },
      {
        name: "Zakarpatska Oblast",
        isoCode: "21",
      },
      {
        name: "Zaporizka oblast",
        isoCode: "23",
      },
      {
        name: "Zhytomyrska oblast",
        isoCode: "18",
      },
    ],
  },
  {
    name: "United Arab Emirates",
    isoCode: "AE",
    states: [
      {
        name: "Abu Dhabi Emirate",
        isoCode: "AZ",
      },
      {
        name: "Ajman Emirate",
        isoCode: "AJ",
      },
      {
        name: "Dubai",
        isoCode: "DU",
      },
      {
        name: "Fujairah",
        isoCode: "FU",
      },
      {
        name: "Ras al-Khaimah",
        isoCode: "RK",
      },
      {
        name: "Sharjah Emirate",
        isoCode: "SH",
      },
      {
        name: "Umm al-Quwain",
        isoCode: "UQ",
      },
    ],
  },
  {
    name: "United Kingdom",
    isoCode: "GB",
    states: [
      {
        name: "Aberdeen",
        isoCode: "ABE",
      },
      {
        name: "Aberdeenshire",
        isoCode: "ABD",
      },
      {
        name: "Angus",
        isoCode: "ANS",
      },
      {
        name: "Antrim",
        isoCode: "ANT",
      },
      {
        name: "Antrim and Newtownabbey",
        isoCode: "ANN",
      },
      {
        name: "Ards",
        isoCode: "ARD",
      },
      {
        name: "Ards and North Down",
        isoCode: "AND",
      },
      {
        name: "Argyll and Bute",
        isoCode: "AGB",
      },
      {
        name: "Armagh City and District Council",
        isoCode: "ARM",
      },
      {
        name: "Armagh, Banbridge and Craigavon",
        isoCode: "ABC",
      },
      {
        name: "Ascension Island",
        isoCode: "SH-AC",
      },
      {
        name: "Ballymena Borough",
        isoCode: "BLA",
      },
      {
        name: "Ballymoney",
        isoCode: "BLY",
      },
      {
        name: "Banbridge",
        isoCode: "BNB",
      },
      {
        name: "Barnsley",
        isoCode: "BNS",
      },
      {
        name: "Bath and North East Somerset",
        isoCode: "BAS",
      },
      {
        name: "Bedford",
        isoCode: "BDF",
      },
      {
        name: "Belfast district",
        isoCode: "BFS",
      },
      {
        name: "Birmingham",
        isoCode: "BIR",
      },
      {
        name: "Blackburn with Darwen",
        isoCode: "BBD",
      },
      {
        name: "Blackpool",
        isoCode: "BPL",
      },
      {
        name: "Blaenau Gwent County Borough",
        isoCode: "BGW",
      },
      {
        name: "Bolton",
        isoCode: "BOL",
      },
      {
        name: "Bournemouth",
        isoCode: "BMH",
      },
      {
        name: "Bracknell Forest",
        isoCode: "BRC",
      },
      {
        name: "Bradford",
        isoCode: "BRD",
      },
      {
        name: "Bridgend County Borough",
        isoCode: "BGE",
      },
      {
        name: "Brighton and Hove",
        isoCode: "BNH",
      },
      {
        name: "Buckinghamshire",
        isoCode: "BKM",
      },
      {
        name: "Bury",
        isoCode: "BUR",
      },
      {
        name: "Caerphilly County Borough",
        isoCode: "CAY",
      },
      {
        name: "Calderdale",
        isoCode: "CLD",
      },
      {
        name: "Cambridgeshire",
        isoCode: "CAM",
      },
      {
        name: "Carmarthenshire",
        isoCode: "CMN",
      },
      {
        name: "Carrickfergus Borough Council",
        isoCode: "CKF",
      },
      {
        name: "Castlereagh",
        isoCode: "CSR",
      },
      {
        name: "Causeway Coast and Glens",
        isoCode: "CCG",
      },
      {
        name: "Central Bedfordshire",
        isoCode: "CBF",
      },
      {
        name: "Ceredigion",
        isoCode: "CGN",
      },
      {
        name: "Cheshire East",
        isoCode: "CHE",
      },
      {
        name: "Cheshire West and Chester",
        isoCode: "CHW",
      },
      {
        name: "City and County of Cardiff",
        isoCode: "CRF",
      },
      {
        name: "City and County of Swansea",
        isoCode: "SWA",
      },
      {
        name: "City of Bristol",
        isoCode: "BST",
      },
      {
        name: "City of Derby",
        isoCode: "DER",
      },
      {
        name: "City of Kingston upon Hull",
        isoCode: "KHL",
      },
      {
        name: "City of Leicester",
        isoCode: "LCE",
      },
      {
        name: "City of London",
        isoCode: "LND",
      },
      {
        name: "City of Nottingham",
        isoCode: "NGM",
      },
      {
        name: "City of Peterborough",
        isoCode: "PTE",
      },
      {
        name: "City of Plymouth",
        isoCode: "PLY",
      },
      {
        name: "City of Portsmouth",
        isoCode: "POR",
      },
      {
        name: "City of Southampton",
        isoCode: "STH",
      },
      {
        name: "City of Stoke-on-Trent",
        isoCode: "STE",
      },
      {
        name: "City of Sunderland",
        isoCode: "SND",
      },
      {
        name: "City of Westminster",
        isoCode: "WSM",
      },
      {
        name: "City of Wolverhampton",
        isoCode: "WLV",
      },
      {
        name: "City of York",
        isoCode: "YOR",
      },
      {
        name: "Clackmannanshire",
        isoCode: "CLK",
      },
      {
        name: "Coleraine Borough Council",
        isoCode: "CLR",
      },
      {
        name: "Conwy County Borough",
        isoCode: "CWY",
      },
      {
        name: "Cookstown District Council",
        isoCode: "CKT",
      },
      {
        name: "Cornwall",
        isoCode: "CON",
      },
      {
        name: "County Durham",
        isoCode: "DUR",
      },
      {
        name: "Coventry",
        isoCode: "COV",
      },
      {
        name: "Craigavon Borough Council",
        isoCode: "CGV",
      },
      {
        name: "Cumbria",
        isoCode: "CMA",
      },
      {
        name: "Darlington",
        isoCode: "DAL",
      },
      {
        name: "Denbighshire",
        isoCode: "DEN",
      },
      {
        name: "Derbyshire",
        isoCode: "DBY",
      },
      {
        name: "Derry City and Strabane",
        isoCode: "DRS",
      },
      {
        name: "Derry City Council",
        isoCode: "DRY",
      },
      {
        name: "Devon",
        isoCode: "DEV",
      },
      {
        name: "Doncaster",
        isoCode: "DNC",
      },
      {
        name: "Dorset",
        isoCode: "DOR",
      },
      {
        name: "Down District Council",
        isoCode: "DOW",
      },
      {
        name: "Dudley",
        isoCode: "DUD",
      },
      {
        name: "Dumfries and Galloway",
        isoCode: "DGY",
      },
      {
        name: "Dundee",
        isoCode: "DND",
      },
      {
        name: "Dungannon and South Tyrone Borough Council",
        isoCode: "DGN",
      },
      {
        name: "East Ayrshire",
        isoCode: "EAY",
      },
      {
        name: "East Dunbartonshire",
        isoCode: "EDU",
      },
      {
        name: "East Lothian",
        isoCode: "ELN",
      },
      {
        name: "East Renfrewshire",
        isoCode: "ERW",
      },
      {
        name: "East Riding of Yorkshire",
        isoCode: "ERY",
      },
      {
        name: "East Sussex",
        isoCode: "ESX",
      },
      {
        name: "Edinburgh",
        isoCode: "EDH",
      },
      {
        name: "England",
        isoCode: "ENG",
      },
      {
        name: "Essex",
        isoCode: "ESS",
      },
      {
        name: "Falkirk",
        isoCode: "FAL",
      },
      {
        name: "Fermanagh and Omagh",
        isoCode: "FMO",
      },
      {
        name: "Fermanagh District Council",
        isoCode: "FER",
      },
      {
        name: "Fife",
        isoCode: "FIF",
      },
      {
        name: "Flintshire",
        isoCode: "FLN",
      },
      {
        name: "Gateshead",
        isoCode: "GAT",
      },
      {
        name: "Glasgow",
        isoCode: "GLG",
      },
      {
        name: "Gloucestershire",
        isoCode: "GLS",
      },
      {
        name: "Gwynedd",
        isoCode: "GWN",
      },
      {
        name: "Halton",
        isoCode: "HAL",
      },
      {
        name: "Hampshire",
        isoCode: "HAM",
      },
      {
        name: "Hartlepool",
        isoCode: "HPL",
      },
      {
        name: "Herefordshire",
        isoCode: "HEF",
      },
      {
        name: "Hertfordshire",
        isoCode: "HRT",
      },
      {
        name: "Highland",
        isoCode: "HLD",
      },
      {
        name: "Inverclyde",
        isoCode: "IVC",
      },
      {
        name: "Isle of Wight",
        isoCode: "IOW",
      },
      {
        name: "Isles of Scilly",
        isoCode: "IOS",
      },
      {
        name: "Kent",
        isoCode: "KEN",
      },
      {
        name: "Kirklees",
        isoCode: "KIR",
      },
      {
        name: "Knowsley",
        isoCode: "KWL",
      },
      {
        name: "Lancashire",
        isoCode: "LAN",
      },
      {
        name: "Larne Borough Council",
        isoCode: "LRN",
      },
      {
        name: "Leeds",
        isoCode: "LDS",
      },
      {
        name: "Leicestershire",
        isoCode: "LEC",
      },
      {
        name: "Limavady Borough Council",
        isoCode: "LMV",
      },
      {
        name: "Lincolnshire",
        isoCode: "LIN",
      },
      {
        name: "Lisburn and Castlereagh",
        isoCode: "LBC",
      },
      {
        name: "Lisburn City Council",
        isoCode: "LSB",
      },
      {
        name: "Liverpool",
        isoCode: "LIV",
      },
      {
        name: "London Borough of Barking and Dagenham",
        isoCode: "BDG",
      },
      {
        name: "London Borough of Barnet",
        isoCode: "BNE",
      },
      {
        name: "London Borough of Bexley",
        isoCode: "BEX",
      },
      {
        name: "London Borough of Brent",
        isoCode: "BEN",
      },
      {
        name: "London Borough of Bromley",
        isoCode: "BRY",
      },
      {
        name: "London Borough of Camden",
        isoCode: "CMD",
      },
      {
        name: "London Borough of Croydon",
        isoCode: "CRY",
      },
      {
        name: "London Borough of Ealing",
        isoCode: "EAL",
      },
      {
        name: "London Borough of Enfield",
        isoCode: "ENF",
      },
      {
        name: "London Borough of Hackney",
        isoCode: "HCK",
      },
      {
        name: "London Borough of Hammersmith and Fulham",
        isoCode: "HMF",
      },
      {
        name: "London Borough of Haringey",
        isoCode: "HRY",
      },
      {
        name: "London Borough of Harrow",
        isoCode: "HRW",
      },
      {
        name: "London Borough of Havering",
        isoCode: "HAV",
      },
      {
        name: "London Borough of Hillingdon",
        isoCode: "HIL",
      },
      {
        name: "London Borough of Hounslow",
        isoCode: "HNS",
      },
      {
        name: "London Borough of Islington",
        isoCode: "ISL",
      },
      {
        name: "London Borough of Lambeth",
        isoCode: "LBH",
      },
      {
        name: "London Borough of Lewisham",
        isoCode: "LEW",
      },
      {
        name: "London Borough of Merton",
        isoCode: "MRT",
      },
      {
        name: "London Borough of Newham",
        isoCode: "NWM",
      },
      {
        name: "London Borough of Redbridge",
        isoCode: "RDB",
      },
      {
        name: "London Borough of Richmond upon Thames",
        isoCode: "RIC",
      },
      {
        name: "London Borough of Southwark",
        isoCode: "SWK",
      },
      {
        name: "London Borough of Sutton",
        isoCode: "STN",
      },
      {
        name: "London Borough of Tower Hamlets",
        isoCode: "TWH",
      },
      {
        name: "London Borough of Waltham Forest",
        isoCode: "WFT",
      },
      {
        name: "London Borough of Wandsworth",
        isoCode: "WND",
      },
      {
        name: "Magherafelt District Council",
        isoCode: "MFT",
      },
      {
        name: "Manchester",
        isoCode: "MAN",
      },
      {
        name: "Medway",
        isoCode: "MDW",
      },
      {
        name: "Merthyr Tydfil County Borough",
        isoCode: "MTY",
      },
      {
        name: "Metropolitan Borough of Wigan",
        isoCode: "WGN",
      },
      {
        name: "Mid and East Antrim",
        isoCode: "MEA",
      },
      {
        name: "Mid Ulster",
        isoCode: "MUL",
      },
      {
        name: "Middlesbrough",
        isoCode: "MDB",
      },
      {
        name: "Midlothian",
        isoCode: "MLN",
      },
      {
        name: "Milton Keynes",
        isoCode: "MIK",
      },
      {
        name: "Monmouthshire",
        isoCode: "MON",
      },
      {
        name: "Moray",
        isoCode: "MRY",
      },
      {
        name: "Moyle District Council",
        isoCode: "MYL",
      },
      {
        name: "Neath Port Talbot County Borough",
        isoCode: "NTL",
      },
      {
        name: "Newcastle upon Tyne",
        isoCode: "NET",
      },
      {
        name: "Newport",
        isoCode: "NWP",
      },
      {
        name: "Newry and Mourne District Council",
        isoCode: "NYM",
      },
      {
        name: "Newry, Mourne and Down",
        isoCode: "NMD",
      },
      {
        name: "Newtownabbey Borough Council",
        isoCode: "NTA",
      },
      {
        name: "Norfolk",
        isoCode: "NFK",
      },
      {
        name: "North Ayrshire",
        isoCode: "NAY",
      },
      {
        name: "North Down Borough Council",
        isoCode: "NDN",
      },
      {
        name: "North East Lincolnshire",
        isoCode: "NEL",
      },
      {
        name: "North Lanarkshire",
        isoCode: "NLK",
      },
      {
        name: "North Lincolnshire",
        isoCode: "NLN",
      },
      {
        name: "North Somerset",
        isoCode: "NSM",
      },
      {
        name: "North Tyneside",
        isoCode: "NTY",
      },
      {
        name: "North Yorkshire",
        isoCode: "NYK",
      },
      {
        name: "Northamptonshire",
        isoCode: "NTH",
      },
      {
        name: "Northern Ireland",
        isoCode: "NIR",
      },
      {
        name: "Northumberland",
        isoCode: "NBL",
      },
      {
        name: "Nottinghamshire",
        isoCode: "NTT",
      },
      {
        name: "Oldham",
        isoCode: "OLD",
      },
      {
        name: "Omagh District Council",
        isoCode: "OMH",
      },
      {
        name: "Orkney Islands",
        isoCode: "ORK",
      },
      {
        name: "Outer Hebrides",
        isoCode: "ELS",
      },
      {
        name: "Oxfordshire",
        isoCode: "OXF",
      },
      {
        name: "Pembrokeshire",
        isoCode: "PEM",
      },
      {
        name: "Perth and Kinross",
        isoCode: "PKN",
      },
      {
        name: "Poole",
        isoCode: "POL",
      },
      {
        name: "Powys",
        isoCode: "POW",
      },
      {
        name: "Reading",
        isoCode: "RDG",
      },
      {
        name: "Redcar and Cleveland",
        isoCode: "RCC",
      },
      {
        name: "Renfrewshire",
        isoCode: "RFW",
      },
      {
        name: "Rhondda Cynon Taf",
        isoCode: "RCT",
      },
      {
        name: "Rochdale",
        isoCode: "RCH",
      },
      {
        name: "Rotherham",
        isoCode: "ROT",
      },
      {
        name: "Royal Borough of Greenwich",
        isoCode: "GRE",
      },
      {
        name: "Royal Borough of Kensington and Chelsea",
        isoCode: "KEC",
      },
      {
        name: "Royal Borough of Kingston upon Thames",
        isoCode: "KTT",
      },
      {
        name: "Rutland",
        isoCode: "RUT",
      },
      {
        name: "Saint Helena",
        isoCode: "SH-HL",
      },
      {
        name: "Salford",
        isoCode: "SLF",
      },
      {
        name: "Sandwell",
        isoCode: "SAW",
      },
      {
        name: "Scotland",
        isoCode: "SCT",
      },
      {
        name: "Scottish Borders",
        isoCode: "SCB",
      },
      {
        name: "Sefton",
        isoCode: "SFT",
      },
      {
        name: "Sheffield",
        isoCode: "SHF",
      },
      {
        name: "Shetland Islands",
        isoCode: "ZET",
      },
      {
        name: "Shropshire",
        isoCode: "SHR",
      },
      {
        name: "Slough",
        isoCode: "SLG",
      },
      {
        name: "Solihull",
        isoCode: "SOL",
      },
      {
        name: "Somerset",
        isoCode: "SOM",
      },
      {
        name: "South Ayrshire",
        isoCode: "SAY",
      },
      {
        name: "South Gloucestershire",
        isoCode: "SGC",
      },
      {
        name: "South Lanarkshire",
        isoCode: "SLK",
      },
      {
        name: "South Tyneside",
        isoCode: "STY",
      },
      {
        name: "Southend-on-Sea",
        isoCode: "SOS",
      },
      {
        name: "St Helens",
        isoCode: "SHN",
      },
      {
        name: "Staffordshire",
        isoCode: "STS",
      },
      {
        name: "Stirling",
        isoCode: "STG",
      },
      {
        name: "Stockport",
        isoCode: "SKP",
      },
      {
        name: "Stockton-on-Tees",
        isoCode: "STT",
      },
      {
        name: "Strabane District Council",
        isoCode: "STB",
      },
      {
        name: "Suffolk",
        isoCode: "SFK",
      },
      {
        name: "Surrey",
        isoCode: "SRY",
      },
      {
        name: "Swindon",
        isoCode: "SWD",
      },
      {
        name: "Tameside",
        isoCode: "TAM",
      },
      {
        name: "Telford and Wrekin",
        isoCode: "TFW",
      },
      {
        name: "Thurrock",
        isoCode: "THR",
      },
      {
        name: "Torbay",
        isoCode: "TOB",
      },
      {
        name: "Torfaen",
        isoCode: "TOF",
      },
      {
        name: "Trafford",
        isoCode: "TRF",
      },
      {
        name: "United Kingdom",
        isoCode: "UKM",
      },
      {
        name: "Vale of Glamorgan",
        isoCode: "VGL",
      },
      {
        name: "Wakefield",
        isoCode: "WKF",
      },
      {
        name: "Wales",
        isoCode: "WLS",
      },
      {
        name: "Walsall",
        isoCode: "WLL",
      },
      {
        name: "Warrington",
        isoCode: "WRT",
      },
      {
        name: "Warwickshire",
        isoCode: "WAR",
      },
      {
        name: "West Berkshire",
        isoCode: "WBK",
      },
      {
        name: "West Dunbartonshire",
        isoCode: "WDU",
      },
      {
        name: "West Lothian",
        isoCode: "WLN",
      },
      {
        name: "West Sussex",
        isoCode: "WSX",
      },
      {
        name: "Wiltshire",
        isoCode: "WIL",
      },
      {
        name: "Windsor and Maidenhead",
        isoCode: "WNM",
      },
      {
        name: "Wirral",
        isoCode: "WRL",
      },
      {
        name: "Wokingham",
        isoCode: "WOK",
      },
      {
        name: "Worcestershire",
        isoCode: "WOR",
      },
      {
        name: "Wrexham County Borough",
        isoCode: "WRX",
      },
    ],
  },
  {
    name: "United States",
    isoCode: "US",
    states: [
      {
        name: "Alabama",
        isoCode: "AL",
      },
      {
        name: "Alaska",
        isoCode: "AK",
      },
      {
        name: "American Samoa",
        isoCode: "AS",
      },
      {
        name: "Arizona",
        isoCode: "AZ",
      },
      {
        name: "Arkansas",
        isoCode: "AR",
      },
      {
        name: "Baker Island",
        isoCode: "UM-81",
      },
      {
        name: "California",
        isoCode: "CA",
      },
      {
        name: "Colorado",
        isoCode: "CO",
      },
      {
        name: "Connecticut",
        isoCode: "CT",
      },
      {
        name: "Delaware",
        isoCode: "DE",
      },
      {
        name: "District of Columbia",
        isoCode: "DC",
      },
      {
        name: "Florida",
        isoCode: "FL",
      },
      {
        name: "Georgia",
        isoCode: "GA",
      },
      {
        name: "Guam",
        isoCode: "GU",
      },
      {
        name: "Hawaii",
        isoCode: "HI",
      },
      {
        name: "Howland Island",
        isoCode: "UM-84",
      },
      {
        name: "Idaho",
        isoCode: "ID",
      },
      {
        name: "Illinois",
        isoCode: "IL",
      },
      {
        name: "Indiana",
        isoCode: "IN",
      },
      {
        name: "Iowa",
        isoCode: "IA",
      },
      {
        name: "Jarvis Island",
        isoCode: "UM-86",
      },
      {
        name: "Johnston Atoll",
        isoCode: "UM-67",
      },
      {
        name: "Kansas",
        isoCode: "KS",
      },
      {
        name: "Kentucky",
        isoCode: "KY",
      },
      {
        name: "Kingman Reef",
        isoCode: "UM-89",
      },
      {
        name: "Louisiana",
        isoCode: "LA",
      },
      {
        name: "Maine",
        isoCode: "ME",
      },
      {
        name: "Maryland",
        isoCode: "MD",
      },
      {
        name: "Massachusetts",
        isoCode: "MA",
      },
      {
        name: "Michigan",
        isoCode: "MI",
      },
      {
        name: "Midway Atoll",
        isoCode: "UM-71",
      },
      {
        name: "Minnesota",
        isoCode: "MN",
      },
      {
        name: "Mississippi",
        isoCode: "MS",
      },
      {
        name: "Missouri",
        isoCode: "MO",
      },
      {
        name: "Montana",
        isoCode: "MT",
      },
      {
        name: "Navassa Island",
        isoCode: "UM-76",
      },
      {
        name: "Nebraska",
        isoCode: "NE",
      },
      {
        name: "Nevada",
        isoCode: "NV",
      },
      {
        name: "New Hampshire",
        isoCode: "NH",
      },
      {
        name: "New Jersey",
        isoCode: "NJ",
      },
      {
        name: "New Mexico",
        isoCode: "NM",
      },
      {
        name: "New York",
        isoCode: "NY",
      },
      {
        name: "North Carolina",
        isoCode: "NC",
      },
      {
        name: "North Dakota",
        isoCode: "ND",
      },
      {
        name: "Northern Mariana Islands",
        isoCode: "MP",
      },
      {
        name: "Ohio",
        isoCode: "OH",
      },
      {
        name: "Oklahoma",
        isoCode: "OK",
      },
      {
        name: "Oregon",
        isoCode: "OR",
      },
      {
        name: "Palmyra Atoll",
        isoCode: "UM-95",
      },
      {
        name: "Pennsylvania",
        isoCode: "PA",
      },
      {
        name: "Puerto Rico",
        isoCode: "PR",
      },
      {
        name: "Rhode Island",
        isoCode: "RI",
      },
      {
        name: "South Carolina",
        isoCode: "SC",
      },
      {
        name: "South Dakota",
        isoCode: "SD",
      },
      {
        name: "Tennessee",
        isoCode: "TN",
      },
      {
        name: "Texas",
        isoCode: "TX",
      },
      {
        name: "United States Minor Outlying Islands",
        isoCode: "UM",
      },
      {
        name: "United States Virgin Islands",
        isoCode: "VI",
      },
      {
        name: "Utah",
        isoCode: "UT",
      },
      {
        name: "Vermont",
        isoCode: "VT",
      },
      {
        name: "Virginia",
        isoCode: "VA",
      },
      {
        name: "Wake Island",
        isoCode: "UM-79",
      },
      {
        name: "Washington",
        isoCode: "WA",
      },
      {
        name: "West Virginia",
        isoCode: "WV",
      },
      {
        name: "Wisconsin",
        isoCode: "WI",
      },
      {
        name: "Wyoming",
        isoCode: "WY",
      },
    ],
  },
  {
    name: "United States Minor Outlying Islands",
    isoCode: "UM",
    states: [],
  },
  {
    name: "Uruguay",
    isoCode: "UY",
    states: [
      {
        name: "Artigas Department",
        isoCode: "AR",
      },
      {
        name: "Canelones Department",
        isoCode: "CA",
      },
      {
        name: "Cerro Largo Department",
        isoCode: "CL",
      },
      {
        name: "Colonia Department",
        isoCode: "CO",
      },
      {
        name: "Durazno Department",
        isoCode: "DU",
      },
      {
        name: "Flores Department",
        isoCode: "FS",
      },
      {
        name: "Florida Department",
        isoCode: "FD",
      },
      {
        name: "Lavalleja Department",
        isoCode: "LA",
      },
      {
        name: "Maldonado Department",
        isoCode: "MA",
      },
      {
        name: "Montevideo Department",
        isoCode: "MO",
      },
      {
        name: "Paysandú Department",
        isoCode: "PA",
      },
      {
        name: "Río Negro Department",
        isoCode: "RN",
      },
      {
        name: "Rivera Department",
        isoCode: "RV",
      },
      {
        name: "Rocha Department",
        isoCode: "RO",
      },
      {
        name: "Salto Department",
        isoCode: "SA",
      },
      {
        name: "San José Department",
        isoCode: "SJ",
      },
      {
        name: "Soriano Department",
        isoCode: "SO",
      },
      {
        name: "Tacuarembó Department",
        isoCode: "TA",
      },
      {
        name: "Treinta y Tres Department",
        isoCode: "TT",
      },
    ],
  },
  {
    name: "Uzbekistan",
    isoCode: "UZ",
    states: [
      {
        name: "Andijan Region",
        isoCode: "AN",
      },
      {
        name: "Bukhara Region",
        isoCode: "BU",
      },
      {
        name: "Fergana Region",
        isoCode: "FA",
      },
      {
        name: "Jizzakh Region",
        isoCode: "JI",
      },
      {
        name: "Karakalpakstan",
        isoCode: "QR",
      },
      {
        name: "Namangan Region",
        isoCode: "NG",
      },
      {
        name: "Navoiy Region",
        isoCode: "NW",
      },
      {
        name: "Qashqadaryo Region",
        isoCode: "QA",
      },
      {
        name: "Samarqand Region",
        isoCode: "SA",
      },
      {
        name: "Sirdaryo Region",
        isoCode: "SI",
      },
      {
        name: "Surxondaryo Region",
        isoCode: "SU",
      },
      {
        name: "Tashkent",
        isoCode: "TK",
      },
      {
        name: "Tashkent Region",
        isoCode: "TO",
      },
      {
        name: "Xorazm Region",
        isoCode: "XO",
      },
    ],
  },
  {
    name: "Vanuatu",
    isoCode: "VU",
    states: [
      {
        name: "Malampa",
        isoCode: "MAP",
      },
      {
        name: "Penama",
        isoCode: "PAM",
      },
      {
        name: "Sanma",
        isoCode: "SAM",
      },
      {
        name: "Shefa",
        isoCode: "SEE",
      },
      {
        name: "Tafea",
        isoCode: "TAE",
      },
      {
        name: "Torba",
        isoCode: "TOB",
      },
    ],
  },
  {
    name: "Vatican City State (Holy See)",
    isoCode: "VA",
    states: [],
  },
  {
    name: "Venezuela",
    isoCode: "VE",
    states: [
      {
        name: "Amazonas",
        isoCode: "Z",
      },
      {
        name: "Anzoátegui",
        isoCode: "B",
      },
      {
        name: "Apure",
        isoCode: "C",
      },
      {
        name: "Aragua",
        isoCode: "D",
      },
      {
        name: "Barinas",
        isoCode: "E",
      },
      {
        name: "Bolívar",
        isoCode: "F",
      },
      {
        name: "Carabobo",
        isoCode: "G",
      },
      {
        name: "Cojedes",
        isoCode: "H",
      },
      {
        name: "Delta Amacuro",
        isoCode: "Y",
      },
      {
        name: "Distrito Capital",
        isoCode: "A",
      },
      {
        name: "Falcón",
        isoCode: "I",
      },
      {
        name: "Federal Dependencies of Venezuela",
        isoCode: "W",
      },
      {
        name: "Guárico",
        isoCode: "J",
      },
      {
        name: "La Guaira",
        isoCode: "X",
      },
      {
        name: "Lara",
        isoCode: "K",
      },
      {
        name: "Mérida",
        isoCode: "L",
      },
      {
        name: "Miranda",
        isoCode: "M",
      },
      {
        name: "Monagas",
        isoCode: "N",
      },
      {
        name: "Nueva Esparta",
        isoCode: "O",
      },
      {
        name: "Portuguesa",
        isoCode: "P",
      },
      {
        name: "Sucre",
        isoCode: "R",
      },
      {
        name: "Táchira",
        isoCode: "S",
      },
      {
        name: "Trujillo",
        isoCode: "T",
      },
      {
        name: "Yaracuy",
        isoCode: "U",
      },
      {
        name: "Zulia",
        isoCode: "V",
      },
    ],
  },
  {
    name: "Vietnam",
    isoCode: "VN",
    states: [
      {
        name: "An Giang",
        isoCode: "44",
      },
      {
        name: "Bà Rịa-Vũng Tàu",
        isoCode: "43",
      },
      {
        name: "Bắc Giang",
        isoCode: "54",
      },
      {
        name: "Bắc Kạn",
        isoCode: "53",
      },
      {
        name: "Bạc Liêu",
        isoCode: "55",
      },
      {
        name: "Bắc Ninh",
        isoCode: "56",
      },
      {
        name: "Bến Tre",
        isoCode: "50",
      },
      {
        name: "Bình Dương",
        isoCode: "57",
      },
      {
        name: "Bình Định",
        isoCode: "31",
      },
      {
        name: "Bình Phước",
        isoCode: "58",
      },
      {
        name: "Bình Thuận",
        isoCode: "40",
      },
      {
        name: "Cà Mau",
        isoCode: "59",
      },
      {
        name: "Cần Thơ",
        isoCode: "CT",
      },
      {
        name: "Cao Bằng",
        isoCode: "04",
      },
      {
        name: "Đà Nẵng",
        isoCode: "DN",
      },
      {
        name: "Đắk Lắk",
        isoCode: "33",
      },
      {
        name: "Đắk Nông",
        isoCode: "72",
      },
      {
        name: "Điện Biên",
        isoCode: "71",
      },
      {
        name: "Đồng Nai",
        isoCode: "39",
      },
      {
        name: "Đồng Tháp",
        isoCode: "45",
      },
      {
        name: "Gia Lai",
        isoCode: "30",
      },
      {
        name: "Hà Giang",
        isoCode: "03",
      },
      {
        name: "Hà Nam",
        isoCode: "63",
      },
      {
        name: "Hà Nội",
        isoCode: "HN",
      },
      {
        name: "Hà Tĩnh",
        isoCode: "23",
      },
      {
        name: "Hải Dương",
        isoCode: "61",
      },
      {
        name: "Hải Phòng",
        isoCode: "HP",
      },
      {
        name: "Hậu Giang",
        isoCode: "73",
      },
      {
        name: "Hồ Chí Minh",
        isoCode: "SG",
      },
      {
        name: "Hòa Bình",
        isoCode: "14",
      },
      {
        name: "Hưng Yên",
        isoCode: "66",
      },
      {
        name: "Khánh Hòa",
        isoCode: "34",
      },
      {
        name: "Kiên Giang",
        isoCode: "47",
      },
      {
        name: "Kon Tum",
        isoCode: "28",
      },
      {
        name: "Lai Châu",
        isoCode: "01",
      },
      {
        name: "Lâm Đồng",
        isoCode: "35",
      },
      {
        name: "Lạng Sơn",
        isoCode: "09",
      },
      {
        name: "Lào Cai",
        isoCode: "02",
      },
      {
        name: "Long An",
        isoCode: "41",
      },
      {
        name: "Nam Định",
        isoCode: "67",
      },
      {
        name: "Nghệ An",
        isoCode: "22",
      },
      {
        name: "Ninh Bình",
        isoCode: "18",
      },
      {
        name: "Ninh Thuận",
        isoCode: "36",
      },
      {
        name: "Phú Thọ",
        isoCode: "68",
      },
      {
        name: "Phú Yên",
        isoCode: "32",
      },
      {
        name: "Quảng Bình",
        isoCode: "24",
      },
      {
        name: "Quảng Nam",
        isoCode: "27",
      },
      {
        name: "Quảng Ngãi",
        isoCode: "29",
      },
      {
        name: "Quảng Ninh",
        isoCode: "13",
      },
      {
        name: "Quảng Trị",
        isoCode: "25",
      },
      {
        name: "Sóc Trăng",
        isoCode: "52",
      },
      {
        name: "Sơn La",
        isoCode: "05",
      },
      {
        name: "Tây Ninh",
        isoCode: "37",
      },
      {
        name: "Thái Bình",
        isoCode: "20",
      },
      {
        name: "Thái Nguyên",
        isoCode: "69",
      },
      {
        name: "Thanh Hóa",
        isoCode: "21",
      },
      {
        name: "Thừa Thiên-Huế",
        isoCode: "26",
      },
      {
        name: "Tiền Giang",
        isoCode: "46",
      },
      {
        name: "Trà Vinh",
        isoCode: "51",
      },
      {
        name: "Tuyên Quang",
        isoCode: "07",
      },
      {
        name: "Vĩnh Long",
        isoCode: "49",
      },
      {
        name: "Vĩnh Phúc",
        isoCode: "70",
      },
      {
        name: "Yên Bái",
        isoCode: "06",
      },
    ],
  },
  {
    name: "Virgin Islands (British)",
    isoCode: "VG",
    states: [],
  },
  {
    name: "Virgin Islands (US)",
    isoCode: "VI",
    states: [
      {
        name: "Saint Croix",
        isoCode: "SC",
      },
      {
        name: "Saint John",
        isoCode: "SJ",
      },
      {
        name: "Saint Thomas",
        isoCode: "ST",
      },
    ],
  },
  {
    name: "Wallis And Futuna Islands",
    isoCode: "WF",
    states: [],
  },
  {
    name: "Western Sahara",
    isoCode: "EH",
    states: [],
  },
  {
    name: "Yemen",
    isoCode: "YE",
    states: [
      {
        name: "'Adan Governorate",
        isoCode: "AD",
      },
      {
        name: "'Amran Governorate",
        isoCode: "AM",
      },
      {
        name: "Abyan Governorate",
        isoCode: "AB",
      },
      {
        name: "Al Bayda' Governorate",
        isoCode: "BA",
      },
      {
        name: "Al Hudaydah Governorate",
        isoCode: "HU",
      },
      {
        name: "Al Jawf Governorate",
        isoCode: "JA",
      },
      {
        name: "Al Mahrah Governorate",
        isoCode: "MR",
      },
      {
        name: "Al Mahwit Governorate",
        isoCode: "MW",
      },
      {
        name: "Dhamar Governorate",
        isoCode: "DH",
      },
      {
        name: "Hadhramaut Governorate",
        isoCode: "HD",
      },
      {
        name: "Hajjah Governorate",
        isoCode: "HJ",
      },
      {
        name: "Ibb Governorate",
        isoCode: "IB",
      },
      {
        name: "Lahij Governorate",
        isoCode: "LA",
      },
      {
        name: "Ma'rib Governorate",
        isoCode: "MA",
      },
      {
        name: "Raymah Governorate",
        isoCode: "RA",
      },
      {
        name: "Saada Governorate",
        isoCode: "SD",
      },
      {
        name: "Sana'a",
        isoCode: "SA",
      },
      {
        name: "Sana'a Governorate",
        isoCode: "SN",
      },
      {
        name: "Shabwah Governorate",
        isoCode: "SH",
      },
      {
        name: "Socotra Governorate",
        isoCode: "SU",
      },
      {
        name: "Ta'izz Governorate",
        isoCode: "TA",
      },
    ],
  },
  {
    name: "Zambia",
    isoCode: "ZM",
    states: [
      {
        name: "Central Province",
        isoCode: "02",
      },
      {
        name: "Copperbelt Province",
        isoCode: "08",
      },
      {
        name: "Eastern Province",
        isoCode: "03",
      },
      {
        name: "Luapula Province",
        isoCode: "04",
      },
      {
        name: "Lusaka Province",
        isoCode: "09",
      },
      {
        name: "Muchinga Province",
        isoCode: "10",
      },
      {
        name: "Northern Province",
        isoCode: "05",
      },
      {
        name: "Northwestern Province",
        isoCode: "06",
      },
      {
        name: "Southern Province",
        isoCode: "07",
      },
      {
        name: "Western Province",
        isoCode: "01",
      },
    ],
  },
  {
    name: "Zimbabwe",
    isoCode: "ZW",
    states: [
      {
        name: "Bulawayo Province",
        isoCode: "BU",
      },
      {
        name: "Harare Province",
        isoCode: "HA",
      },
      {
        name: "Manicaland",
        isoCode: "MA",
      },
      {
        name: "Mashonaland Central Province",
        isoCode: "MC",
      },
      {
        name: "Mashonaland East Province",
        isoCode: "ME",
      },
      {
        name: "Mashonaland West Province",
        isoCode: "MW",
      },
      {
        name: "Masvingo Province",
        isoCode: "MV",
      },
      {
        name: "Matabeleland North Province",
        isoCode: "MN",
      },
      {
        name: "Matabeleland South Province",
        isoCode: "MS",
      },
      {
        name: "Midlands Province",
        isoCode: "MI",
      },
    ],
  },
];

export const statesByCountry = countriesWithStates.reduce(
  (acc: Record<string, CountryState[]>, country) => {
    acc[country.isoCode] = country.states;
    return acc;
  },
  {}
);
