import { useState } from 'react';
import { CustomSelectStateProps } from '~app/types/mCustomSelectTypes';

export const useCursor = ({
  isItemSelectable,
  prependItems,
  items: itemsProp,
  resultListRef,
  isDisabledItem,
  itemRefs,
}: Pick<
  CustomSelectStateProps,
  | 'isItemSelectable'
  | 'items'
  | 'prependItems'
  | 'resultListRef'
  | 'isDisabledItem'
  | 'itemRefs'
>) => {
  const items = prependItems ? [...prependItems, ...itemsProp] : itemsProp;
  const [cursor, setCursor] = useState(-1);

  const setCursorWrapper = (index: number) => {
    setCursor(index);
  };

  const setCursorAndScroll = (cursorIndex: number) => {
    setCursorWrapper(cursorIndex);
    if (
      cursorIndex < 0 ||
      cursorIndex > items.length ||
      !itemRefs?.current[cursorIndex]
    ) {
      return;
    }
    if (typeof itemRefs.current[cursorIndex].scrollIntoView === 'function') {
      itemRefs.current[cursorIndex].scrollIntoView({
        block: 'nearest',
        inline: 'nearest',
      });
    }
  };

  const setCursorCurrent = (index: number) => {
    if (isItemSelectable(items[index]) && !isDisabledItem(items[index])) {
      setCursorWrapper(index);
    }
  };
  const setNextCursor = () => {
    if (!items.length) {
      return;
    }
    let nCursor = cursor + 1;
    // Wrap around to beginning
    if (nCursor >= items.length) {
      nCursor = 0;
    }
    while (
      items[nCursor] &&
      (!isItemSelectable(items[nCursor]) || isDisabledItem(items[nCursor]))
    ) {
      nCursor += 1;
    }

    if (nCursor <= items.length - 1) {
      setCursorAndScroll(nCursor);
    }
  };
  const setPrevCursor = () => {
    if (!items.length) {
      return;
    }
    let nCursor = cursor - 1;
    // Wrap around to end
    if (nCursor < 0) {
      nCursor = items.length - 1;
    }
    while (
      items[nCursor] &&
      (!isItemSelectable(items[nCursor]) || isDisabledItem(items[nCursor]))
    ) {
      nCursor -= 1;
    }

    if (nCursor >= 0) {
      setCursorAndScroll(nCursor);
    }
  };

  return {
    cursor,
    setCursor: setCursorWrapper,
    setCursorCurrent,
    setNextCursor,
    setPrevCursor,
    setCursorAndScroll,
  };
};
