import { string, object, number } from 'zod';
import { BaseResponseSchema, MCustomNumberTypeRequired } from './miscTypes';

export const QuoteItemCustomPriceSchema = object({
  id: string().nullish(),
  unitAmount: MCustomNumberTypeRequired('Price cannot be empty'),
});

export const SubscriptionItemCustomPriceSchema = BaseResponseSchema.extend({
  id: string(),
  amount: number(),
});
