import { z } from 'zod';

export const BillGroupOverviewSchema = z.object({
  id: z.string(),
  name: z.string(),
  currency: z.string(),
});

export type IBillGroupOverviewSchema = z.infer<typeof BillGroupOverviewSchema>;

export const SubscriptionOverviewSchema = z.object({
  id: z.string(),
  name: z.string(),
  totalProducts: z.number(),
  renewalEstimate: z.number(),
});

export type ISubscriptionOverviewSchema = z.infer<
  typeof SubscriptionOverviewSchema
>;

export const OverviewItem = z.object({
  billgroup: BillGroupOverviewSchema,
  subscriptionOverviews: z.array(SubscriptionOverviewSchema),
});

export type IOverviewItem = z.infer<typeof OverviewItem>;

export const OverviewResponseSchema = z.object({
  subscriptionOverviewsByBillGroup: z.array(OverviewItem),
});

export type IOverviewResponse = z.infer<typeof OverviewResponseSchema>;

export const AccountOverviewStatSchema = z.object({
  recentInvoiceAmount: z.number().default(0),
  mrr: z.number().default(0),
  arr: z.number().default(0),
  lifetimeValue: z.number().default(0),
});

export type IAccountOverviewStat = z.infer<typeof AccountOverviewStatSchema>;
