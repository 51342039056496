import { useNavigate } from 'react-router';
import {
  handleApiErrorToast,
  isAxiosError,
  isAxiosErrorMatchedStatus,
} from '~app/api/axios';
/**
This is for handle BE api error in FE. 
 */
export const useHandleApiError = () => {
  const navigate = useNavigate();

  /**
Normally, displays BE error message as toast.
But for error status 404, You have an option to pass a route and if status is 404 it will navigate to that route.

 *
 * @param error BE error
 * @param route (optional) Pass route to navigate that route when status is 400||404.
 * @returns
 */
  const handleApiError = (error: any, route?: string) => {
    if (isAxiosError(error)) {
      handleApiErrorToast(error);
      if (route && isAxiosErrorMatchedStatus(error, 400, 404)) {
        navigate(route, { replace: true });
      }
    }
  };

  return {
    handleApiError,
  };
};
