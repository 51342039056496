import { useState, useEffect } from 'react';

export const useReducedMotion = (defaultVal = true) => {
  const [reducedMotion, setReducedMotion] = useState(defaultVal);

  // Callback for media query change events.
  function queryChangeHandler(event: any) {
    setReducedMotion(event.target.matches);
  }

  useEffect(() => {
    // Grab the reduced motion media query,
    const mediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)');

    if (mediaQuery) {
      setReducedMotion(mediaQuery.matches);

      mediaQuery.addEventListener('change', queryChangeHandler);

      // Remove the event listener when the component unmounts.
      return () => mediaQuery.removeEventListener('change', queryChangeHandler);
    }
  }, []);

  return reducedMotion;
};
