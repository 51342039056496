import { inputAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(inputAnatomy.keys);

// Sized apply to multiple parts
const sharedSizes = {
  xl: defineStyle({
    fontSize: 'xl',
    px: 4,
    h: 16,
    borderRadius: 'md',
  }),
  lg: defineStyle({
    fontSize: 'lg',
    px: 4,
    h: 12,
    borderRadius: 'md',
  }),
  md: defineStyle({
    fontSize: 'md',
    px: 4,
    h: 10,
    borderRadius: 'md',
  }),
  sm: defineStyle({
    fontSize: 'sm',
    px: 2,
    h: 8,
    borderRadius: 'base',
  }),
  xs: defineStyle({
    fontSize: 'xs',
    px: 2,
    h: 6,
    borderRadius: 'sm',
  }),
};

const sizes = {
  xl: definePartsStyle({
    field: sharedSizes.xl,
    addon: sharedSizes.xl,
  }),
  lg: definePartsStyle({
    field: sharedSizes.lg,
    addon: sharedSizes.lg,
  }),
  md: definePartsStyle({
    field: sharedSizes.md,
    addon: sharedSizes.md,
  }),
  sm: definePartsStyle({
    field: sharedSizes.sm,
    addon: sharedSizes.sm,
  }),
  xs: definePartsStyle({
    field: sharedSizes.xs,
    addon: sharedSizes.xs,
  }),
};

const baseStyle = definePartsStyle({
  field: {
    border: '1px solid',
    borderColor: 'tGray.lightPurple',
    bg: 'tWhite.base',
    color: 'tPurple.dark',
    fontWeight: 'normal',
    _disabled: {
      cursor: 'not-allowed',
      color: 'tGray.acGray',
      background: 'tGray.support',
      opacity: 'unset',
      _placeholder: {
        color: 'transparent',
      },
    },
    _invalid: {
      borderColor: 'tRed.base',
      borderWidth: '1px',
      color: 'tRed.base',
    },
    _focus: {
      zIndex: 1,
      // Not sure why, but other built-in chakra colors appeared to override this
      borderColor: '#b4bcef !important', // 'tBlue.lightShade',
      bg: 'tBlue.hover',
      color: 'tIndigo.base',
      _readOnly: {
        color: 'tPurple.dark',
      },
    },
    _placeholder: {
      color: 'tGray.lightPurple',
      fontWeight: 'normal',
    },
  },
});

const variantSearch = definePartsStyle({
  field: {
    ...baseStyle.field,
    bg: 'tGray.support',
    _focus: {
      bg: 'tGray.support',
      color: 'tPurple.dark',
    },
  },
});

const variantQuantity = definePartsStyle({
  field: {
    ...baseStyle.field,
    fontWeight: 'bold',
    _readOnly: {
      boxShadow: 'none !important',
      userSelect: 'all',
    },
  },
});

const variantReadonly = definePartsStyle({
  field: {
    ...baseStyle.field,
    _readOnly: {
      border: 'none !important',
      paddingLeft: 0,
      opacity: 1,
      resize: 'none',
      color: 'tPurple.dark',
      bg: 'transparent !important',
      boxShadow: 'none !important',
      userSelect: 'all',
      _placeholder: {
        color: 'transparent',
      },
    },
  },
});

const variantUnsaved = definePartsStyle({
  ...baseStyle,
  field: {
    ...baseStyle.field,
    borderLeftWidth: '3px',
    borderLeftColor: 'tOrange.base',
    _hover: {
      borderLeftColor: 'tOrange.base',
    },
    _focus: {
      ...baseStyle.field._focus,
      borderLeftWidth: '1px',
    },
  },
  addon: {
    borderLeftColor: 'tOrange.base',
    borderLeftWidth: '3px',
  },
});

export const inputTheme = defineMultiStyleConfig({
  variants: {
    search: variantSearch,
    quantity: variantQuantity,
    readonly: variantReadonly,
    unsaved: variantUnsaved,
    outline: baseStyle, // For some reason, base styles were overridden by the built-in default variant
  },
  baseStyle,
  sizes,
  defaultProps: {
    size: 'sm',
    variant: 'outline',
  },
});

export default inputTheme;
