import { Dispatch, useRef } from 'react';
import { composeGetQuery } from '~app/api/utils';
import { listApiConfig } from '~app/constants/guidedQuoting';
import { renderItemContentForItemsWithIdName } from '~app/routes/Settings/rules-v3/rules.utils';
import { useFlags } from '~app/services/launchDarkly';
import { IQuestion } from '~app/types';
import MCustomMultiSelect from '../MCustomSelect/MCustomMultiSelect';

interface SelectProps {
  question: IQuestion;
  context: Record<string, any>;
  filters: any;
  value: any;
  setValue: Dispatch<any>;
}

const OfferingDropdownMultiSelect = ({
  question,
  context,
  filters,
  value,
  setValue,
}: SelectProps) => {
  const hasSetItemsRef = useRef(false);
  const { salesDemoMedinsightTempGuidedQuotingSpecialConfig } = useFlags();

  function handleItemsFetched(items: any[]) {
    // CUSTOM LOGIC FOR SALES DEMO - Auto-select offerings
    // (this might be a real thing in the future, but we would want to handle it better)

    if (
      !hasSetItemsRef.current &&
      salesDemoMedinsightTempGuidedQuotingSpecialConfig?.defaultSelectedOfferings
    ) {
      const offeringIds = new Set(
        Object.keys(
          salesDemoMedinsightTempGuidedQuotingSpecialConfig.defaultSelectedOfferings ||
            {},
        )
          .filter(
            (key) =>
              context[key] &&
              salesDemoMedinsightTempGuidedQuotingSpecialConfig
                ?.defaultSelectedOfferings?.[key]?.[context[key]?.value],
          )
          .flatMap(
            (key) =>
              salesDemoMedinsightTempGuidedQuotingSpecialConfig
                ?.defaultSelectedOfferings?.[key][context[key]?.value]
                ?.offeringIds || [],
          ),
      );
      if (!offeringIds.size) {
        return;
      }

      hasSetItemsRef.current = true;
      const itemsToSelect = items.filter((item) => offeringIds.has(item.id));
      if (itemsToSelect.length) {
        // setValues(itemsToSelect);
        setTimeout(() => {
          setValue(itemsToSelect);
        });
      }
    }
  }

  return (
    <MCustomMultiSelect
      mt="0 !important"
      showQueryInput
      closeButtonText="Apply"
      returnItem
      endpoint="/api/offerings"
      additionalSearchParams={composeGetQuery(listApiConfig, {
        ...filters,
        status: 'ACTIVE',
      })}
      // TODO: this could be bad if there are a lot of offerings
      // this is only used so that medinsight demo can work
      loadAll
      getByIdEndpointFn={(record: any) => `/api/offerings/${record.id}`}
      itemTitle="name"
      itemValue="id"
      renderItemContent={renderItemContentForItemsWithIdName}
      value={value ?? []}
      onChange={setValue}
      multiple
      inputProps={{
        _placeholder: { color: 'tPurple.dark' },
        _disabled: {
          _placeholder: {
            color: 'tGray.darkPurple',
          },
        },
      }}
      onFetchedItems={handleItemsFetched}
    />
  );
};

export default OfferingDropdownMultiSelect;
