import { Dispatch, Fragment, SetStateAction } from 'react';
import {
  MDivider,
  MGrid,
  MGridItem,
  MText,
  MVStack,
} from '~app/components/Monetize';
import OfferingDropdownMultiSelect from '~app/components/Monetize/Dropdowns/OfferingDropdownMultiSelect';
import {
  GuidedQuotingContext,
  IOfferingRes,
  IQuestion,
  Maybe,
} from '~app/types';
import { ProductQuantityTable } from './../ProductQuantityTable';

interface QuestionFormOfferingProps {
  question: IQuestion;
  context: Record<string, any>;
  setContext: Dispatch<SetStateAction<GuidedQuotingContext>>;
  filter?: Maybe<Record<string, any>>;
  value: any;
  onChange: (value: any) => void;
}

export const QuestionFormOffering = ({
  question,
  context,
  setContext,
  filter,
  value,
  onChange,
}: QuestionFormOfferingProps) => {
  return (
    <>
      <MText fontWeight="semibold" mb={2}>
        {question.questionNumber}. {question.questionText}
      </MText>
      <OfferingDropdownMultiSelect
        question={question}
        context={context}
        filters={filter}
        value={value}
        setValue={onChange}
      />
      {value &&
        context[question.id] &&
        Object.keys(context[question.id].value).length > 0 &&
        Object.values(context[question.id].value).some((offering: any) =>
          offering.products?.some((product: any) => product.type !== 'USAGE'),
        ) && (
          <MVStack width="100%">
            <MGrid
              templateAreas={`"offr prod qty"`}
              templateColumns={`200px 1fr 100px`}
              width="100%"
              mt={2}
            >
              <MGridItem
                area="offr"
                display="flex"
                alignItems="center"
                justifyContent="left"
              >
                <MText fontWeight="semibold">Offering Name</MText>
              </MGridItem>
              <MGridItem
                area="prod"
                display="flex"
                alignItems="center"
                justifyContent="left"
              >
                <MText fontWeight="semibold">Product Name</MText>
              </MGridItem>
              <MGridItem
                area="qty"
                display="flex"
                alignItems="center"
                justifyContent="left"
              >
                <MText fontWeight="semibold">Quantity</MText>
              </MGridItem>
            </MGrid>
            {Array.isArray(value) &&
              value.map(({ id: offeringId }: IOfferingRes, index) => (
                <Fragment key={offeringId}>
                  <ProductQuantityTable
                    questionId={question.id}
                    offeringId={offeringId}
                    context={context}
                    setContext={setContext}
                  />
                  {index !== value.length - 1 && <MDivider />}
                </Fragment>
              ))}
          </MVStack>
        )}
    </>
  );
};
