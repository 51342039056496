export const BillAdvanceIcon = (props: any) => (
  <svg
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    {...props}
  >
    <g>
      <path d="M12.75 10.5V12.75H3.75V14.25H12.75V16.5L15.75 13.5L12.75 10.5Z" />
      <path d="M9.15625 6.71886C8.1595 6.45979 7.83896 6.19194 7.83896 5.7748C7.83896 5.29619 8.28245 4.96248 9.02452 4.96248C9.80611 4.96248 10.0959 5.33571 10.1223 5.88458H11.0927C11.0619 5.12933 10.6009 4.43556 9.68316 4.21162V3.25H8.36587V4.19845C7.51403 4.38287 6.82904 4.93613 6.82904 5.78359C6.82904 6.7979 7.66771 7.30286 8.89279 7.59705C9.99053 7.86051 10.2101 8.24692 10.2101 8.65527C10.2101 8.95825 9.99492 9.44126 9.02452 9.44126C8.11998 9.44126 7.76431 9.03729 7.71601 8.51915H6.75C6.80269 9.48078 7.52281 10.0209 8.36587 10.2009V11.1537H9.68316V10.2097C10.5394 10.0472 11.22 9.55103 11.22 8.65088C11.22 7.40385 10.153 6.97793 9.15625 6.71886Z" />
    </g>
  </svg>
);
