import { forwardRef } from 'react';
import { MBox, MCustomIconButton, MTooltip } from '~app/components/Monetize';
import { ContactTypes, ContactWithTypes } from '~app/types';
import type { QuoteContactItemProps } from './QuoteContactItem';

interface ContactItemItemIconProps
  extends Pick<QuoteContactItemProps, 'onToggleType'> {
  tooltip: string;
  mr?: string;
  contact: ContactWithTypes;
  isInternal?: boolean;
  disabled?: boolean;
  type: ContactTypes;
  isActive: boolean;
  allowDeselect?: boolean;
  inactiveIcon: any;
  activeIcon: any;
}

const ContactItemItemIcon = forwardRef<any, ContactItemItemIconProps>(
  (props: ContactItemItemIconProps, ref) => {
    const {
      tooltip,
      mr = '1',
      contact,
      isInternal,
      disabled,
      type,
      isActive,
      allowDeselect,
      inactiveIcon,
      activeIcon,
      // eslint-disable-next-line react/prop-types
      onToggleType,
    } = props;

    return (
      <MBox>
        {' '}
        <MTooltip label={tooltip} placement="bottom-end">
          {/* wrapped in box to allow tooltip to apply to a disabled button */}
          <MBox>
            <MCustomIconButton
              variant="icon"
              name={type}
              isDisabled={disabled}
              aria-label={type}
              mr={mr}
              btnSize={6}
              padding={0}
              containerSize={8}
              iconProps={{
                _active: { color: 'unset' },
                _focus: { color: 'unset' },
              }}
              aria-pressed={isActive}
              icon={isActive ? activeIcon : inactiveIcon}
              onClick={(ev) => {
                !disabled &&
                  (allowDeselect || !isActive) &&
                  onToggleType(contact, type, !isActive, isInternal || false);
              }}
            />
          </MBox>
        </MTooltip>
      </MBox>
    );
  },
);

ContactItemItemIcon.defaultProps = {
  isInternal: false,
  disabled: false,
};

export default ContactItemItemIcon;
