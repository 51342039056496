import React, { createContext, useContext, useEffect, useState } from 'react';
import { ACLActionType } from '~app/types';
import { useAuth } from '../auth0';
import { ACL, ACLActionItemType, ACLAvailableAction } from './actions';
import { useFlags } from '../launchDarkly';

const defaultContext = {};

const ACLContext = createContext<ACLProviderType>(defaultContext as any);

type ACLProviderType = {
  /**
   * This function will check if current user is allowed to do a certain list of actions.
   * canDo([['accounts', 'read']]) returns if user can read accounts
   * canDo([['accounts', 'read'], ['subscription', 'write']]) returns if user can read accounts or write subscriptions
   *
   * @param acts a list of actions to check if user is allowed to do. ex - [['accounts', 'read']]
   * @returns returns true if user can do any of the action listed in acts
   */
  canDo: (acts: ACLActionItemType[], skipFFCheck?: boolean) => boolean;
};

export const useACL = () => useContext(ACLContext);

export const useACLProvider = () => {
  const { currentTenantUser } = useAuth();
  const [availableActions, setAvailableActions] =
    useState<ACLAvailableAction | null>(null);
  const { preventContactCreate, preventContactEdit } = useFlags();

  const checkIsAllowedWithFeatureFlag = (acts: ACLActionItemType[]) => {
    return acts.some((act) => {
      const [entity, action] = act;

      if (
        (entity === 'account_contacts' &&
          action === 'create' &&
          preventContactCreate) ||
        (entity === 'account_contacts' &&
          action === 'update' &&
          preventContactEdit)
      ) {
        return false;
      }

      // If preventContactCreate/Edit is true, disable account create/edit
      // https://monetizenow.atlassian.net/browse/BP-8615
      if (
        (entity === 'accounts' &&
          action === 'create' &&
          preventContactCreate) ||
        (entity === 'accounts' && action === 'update' && preventContactEdit)
      ) {
        return false;
      }
      return true;
    });
  };

  /**
   * Check if user has permission to do a certain list of actions
   * @param acts a list of actions to check if user is allowed to do. ex - [['accounts', 'read']]
   * @param skipFFCheck skip feature flag check
   * @returns returns true if user can do any of the action listed in acts
   */
  const canDo = (acts: ACLActionItemType[], skipFFCheck = false) => {
    if (!currentTenantUser || !availableActions) {
      // Just allow true until currentTenantUser is loaded. This will prevent potential unnecessary redirect on ProtectedRoute.
      return true;
    }
    let isAllowed = acts.some((act) => {
      const [entity, action] = act;

      return availableActions[entity]?.[action];
    });

    if (!skipFFCheck) {
      isAllowed = isAllowed && checkIsAllowedWithFeatureFlag(acts);
    }

    return isAllowed;
  };

  useEffect(() => {
    if (currentTenantUser) {
      const actions: ACLAvailableAction = {};
      for (const entity in ACL) {
        const entityACL = ACL[entity as keyof typeof ACL];
        for (const action in entityACL) {
          const acl = entityACL[action as ACLActionType];
          if (acl && Array.isArray(currentTenantUser?.roles)) {
            const hasPermission = currentTenantUser?.roles.some((role) =>
              acl.includes(role.name),
            );
            if (hasPermission) {
              if (actions[entity as keyof typeof ACL]) {
                actions[entity as keyof typeof ACL]![action as ACLActionType] =
                  true;
              } else {
                actions[entity as keyof typeof ACL] = {
                  [action as ACLActionType]: true,
                };
              }
            }
          }
        }
      }
      setAvailableActions(actions);
    }
  }, [currentTenantUser]);

  return { canDo };
};

export const ACLProvider = ({
  children,
}: {
  children: React.ReactElement | React.ReactNode;
}) => {
  const data = useACLProvider();
  return <ACLContext.Provider value={data}>{children}</ACLContext.Provider>;
};
