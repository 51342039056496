import { FormControl, FormLabel, Switch } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { handleApiErrorToast } from '~app/api/axios';
import {
  MBox,
  MButton,
  MDivider,
  MFlex,
  MFormField,
  MInput,
  MRadio,
  MRadioGroup,
  MStack,
  MText,
  RadioStyleButtonGroup,
} from '~app/components/Monetize';
import { logger } from '~app/services/logger';
import { ContractEndActionEnum, RenewalTermLengthEnum } from '~app/types';
import {
  IQuoteSettingsContractRenewalTerms,
  QuoteSettingsContractRenewalTerms,
} from '~app/types/quoteSettingsTypes';
import { QUOTE_CUSTOM_CONTRACT_LENGTHS } from '../../../../constants/quotes';

const customContractOptions = QUOTE_CUSTOM_CONTRACT_LENGTHS.map((value) => ({
  label: `${value}`,
  value: `${value}`,
}));

interface ContractRenewalTermsFormProps {
  contractRenewalTerms: IQuoteSettingsContractRenewalTerms;
  onSubmit: (data: IQuoteSettingsContractRenewalTerms) => Promise<void>;
}
export const ContractRenewalTermsForm = ({
  contractRenewalTerms,
  onSubmit,
}: ContractRenewalTermsFormProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const {
    handleSubmit,
    setValue,
    watch,
    control,
    clearErrors,
    reset,
    formState: { errors, isDirty },
  } = useForm<IQuoteSettingsContractRenewalTerms>({
    resolver: zodResolver(QuoteSettingsContractRenewalTerms),
    mode: 'onChange',
    defaultValues: contractRenewalTerms,
    values: contractRenewalTerms,
    resetOptions: {
      keepDirty: true,
      keepErrors: true,
    },
  });

  const renewalTermLengthType = watch('renewalTermLength.type');
  const watchRenewalLength = watch('renewalTermLength.months');

  const onContractEndActionSubmit = async (
    data: IQuoteSettingsContractRenewalTerms,
  ) => {
    try {
      setIsLoading(true);
      await onSubmit(data);
    } catch (err: any) {
      handleApiErrorToast(err);
    } finally {
      setIsLoading(false);
      reset(data);
    }
  };

  const onError = (err: any) => logger.error(err);

  return (
    <>
      <MText fontSize="md" fontWeight="600">
        Default Contract End Action
      </MText>
      <MFormField mt="2" mb="4" error={errors.contractEndAction}>
        <Controller
          name="contractEndAction"
          control={control}
          render={({ field: { name, value, onChange, ...rest } }) => (
            <FormControl
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              px="2"
            >
              <FormLabel htmlFor={name} fontWeight="400" mb="0">
                Auto Renew Contract
              </FormLabel>

              <Switch
                size="md"
                id={name}
                aria-describedby={`${name}-subheading`}
                isChecked={value === ContractEndActionEnum.RENEW}
                onChange={(event) =>
                  onChange(
                    event.target.checked
                      ? ContractEndActionEnum.RENEW
                      : ContractEndActionEnum.CANCEL,
                  )
                }
              />
            </FormControl>
          )}
        />
      </MFormField>

      <MDivider />

      <MText mt="4" fontSize="md" fontWeight="600">
        Renewal Configuration
      </MText>

      <MFormField
        mt="2"
        mb="4"
        error={
          errors.renewalTermLength?.type || errors.renewalTermLength?.months
        }
      >
        <MFlex alignItems="flex-end" gap="2">
          <Controller
            name="renewalTermLength.type"
            control={control}
            render={({ field: { onChange, ...rest } }) => (
              <MRadioGroup
                onChange={(val) => {
                  onChange(val);

                  if (val === RenewalTermLengthEnum.ContractLength) {
                    setValue('renewalTermLength.months', null, {
                      shouldDirty: true,
                      shouldValidate: true,
                    });
                    if (errors.renewalTermLength?.months) {
                      clearErrors('renewalTermLength.months');
                    }
                  }
                }}
                {...rest}
              >
                <MStack>
                  <MRadio
                    isInvalid={!!errors.renewalTermLength?.type}
                    value={RenewalTermLengthEnum.ContractLength}
                    fontWeight="bold"
                  >
                    Same as Contract Length
                  </MRadio>
                  <MRadio
                    value={RenewalTermLengthEnum.FixedMonths}
                    fontWeight="bold"
                    minH={8}
                  >
                    Custom Length in Months
                  </MRadio>
                </MStack>
              </MRadioGroup>
            )}
          />
        </MFlex>

        {renewalTermLengthType === RenewalTermLengthEnum.FixedMonths && (
          <MBox pl="5">
            <Controller
              control={control}
              name="renewalTermLength.months"
              render={({ field: { value, ...rest } }) => (
                <MInput w="10rem" value={value || ''} {...rest} />
              )}
            />

            <RadioStyleButtonGroup
              name="renewalLength"
              containerProps={{ maxW: '160px', mt: 2 }}
              options={customContractOptions}
              value={`${watchRenewalLength || ''}` || undefined}
              defaultValue={`${watchRenewalLength || ''}` || undefined}
              onChange={(value: string) => {
                setValue('renewalTermLength.months', Number(value), {
                  shouldDirty: true,
                  shouldValidate: true,
                });
              }}
            />
          </MBox>
        )}
      </MFormField>

      <MDivider />

      <MFormField
        mt="4"
        label="Notice Period (in days)"
        isRequired
        labelProps={{
          display: 'block',
        }}
        tooltip="Notice of non-renewal by this number of days before Contract ends"
        error={errors.autoRenewalNoticePeriod}
      >
        <Controller
          control={control}
          name="autoRenewalNoticePeriod"
          render={({ field }) => <MInput maxW="11.25rem" {...field} />}
        />
      </MFormField>
      <MText mt="2" fontWeight="400" color="tGray.acGray">
        Example: If Contract with auto-renewal ends on July 15th with notice
        period of 10 days, notice of non-renewal must be received by July 5th
      </MText>
      <MDivider my="4" />
      <MFlex justify="end">
        <MButton
          variant="primary"
          onClick={handleSubmit(onContractEndActionSubmit, onError)}
          isDisabled={!isDirty || isLoading}
          isLoading={isLoading}
        >
          Save
        </MButton>
      </MFlex>
    </>
  );
};
