import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { handleApiErrorToast } from '~app/api/axios';
import {
  useGetConfigurationPricing,
  useUpdateConfigurationPricing,
} from '~app/api/settingsService';
import {
  MAlert,
  MBox,
  MButton,
  MCustomSelect,
  MFlex,
  MFormField,
  MGrid,
  MGridItem,
  MHStack,
  MPageContainer,
  MPageLoader,
  MSettingsPageHeader,
} from '~app/components/Monetize';
import { SETTINGS } from '~app/constants';
import { useDocumentHead } from '~app/services/documentHead';
import { useFlags } from '~app/services/launchDarkly';
import {
  BillPricingProrationModelEnum,
  BillPricingSchema,
  IBillPricing,
  IBillPricingSchema,
} from '~app/types';

const Pricing = () => {
  const { setDocTitle } = useDocumentHead();
  const { data: billPricing, isLoading: isPricingLoading } =
    useGetConfigurationPricing();

  const { isLoading: isSaveLoading, mutate: updatePricingMutation } =
    useUpdateConfigurationPricing();

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty, isValid },
    reset,
  } = useForm<IBillPricingSchema>({
    resolver: zodResolver(BillPricingSchema),
    mode: 'onChange',
    defaultValues: { ...billPricing },
  });

  useEffect(() => {
    if (billPricing) {
      reset({ ...billPricing });
    }
  }, [billPricing]);

  useEffect(() => {
    setDocTitle('Settings', 'Pricing');
  }, []);

  const onSubmit = async (payload: IBillPricing) => {
    updatePricingMutation(payload, {
      onSuccess: () => {},
      onError: (err) => {
        handleApiErrorToast(err);
      },
    });
  };
  const { showCalendarMonthProration, disableProrationMethod } = useFlags();
  const [prorationOptions, setProrationOptions] = useState<
    Record<'title' | 'value', string>[]
  >([]);
  useEffect(() => {
    if (showCalendarMonthProration) {
      setProrationOptions(
        SETTINGS.SETTING_BILLING_PRICING_PRORATION_MODEL_DISPLAY_STRING,
      );
    } else {
      setProrationOptions(
        SETTINGS.SETTING_BILLING_PRICING_PRORATION_MODEL_DISPLAY_STRING.filter(
          ({ value }) =>
            value !== BillPricingProrationModelEnum.CALENDAR_MONTH_BASED,
        ),
      );
    }
  }, [showCalendarMonthProration]);

  return (
    <MPageContainer alignItems="stretch">
      <MSettingsPageHeader title="Pricing">
        <MHStack spacing="4">
          <MButton
            variant="primary"
            onClick={handleSubmit(onSubmit)}
            isLoading={isSaveLoading}
            isDisabled={isSaveLoading || !isValid || !isDirty}
          >
            Save
          </MButton>
        </MHStack>
      </MSettingsPageHeader>

      {isPricingLoading ? (
        <MFlex justify="center" grow={1} height={'100%'}>
          <MPageLoader height="auto" />
        </MFlex>
      ) : (
        <MBox w="100%" mb="6">
          {billPricing && (
            <MAlert
              type="warning"
              bg="tOrange.input"
              mb={4}
              alertIconProps={{ boxSize: 4 }}
              alertDescriptionProps={{ fontSize: 'sm' }}
            >
              Changing the Proration Model is NOT recommended as it impacts
              Invoices in already Processed Quotes.
            </MAlert>
          )}
          <MBox maxWidth="200">
            <form onSubmit={handleSubmit(onSubmit)}>
              <MGrid templateColumns="repeat(12, 1fr)" gap={4}>
                <MGridItem colSpan={12}>
                  <MFormField
                    error={errors.prorationModel}
                    label="Proration Model"
                    isRequired
                  >
                    <Controller
                      name="prorationModel"
                      control={control}
                      render={({ field }) => (
                        <MCustomSelect
                          items={prorationOptions}
                          {...field}
                          placeholder="Proration Model"
                          isDisabled={disableProrationMethod}
                        />
                      )}
                    />
                  </MFormField>
                </MGridItem>
              </MGrid>
            </form>
          </MBox>
        </MBox>
      )}
    </MPageContainer>
  );
};

export default Pricing;
