import { z } from 'zod';

export type CurrencyCodesAll = keyof typeof ALL_CURRENCIES;
export type CurrencyCodes = keyof typeof SUPPORTED_CURRENCIES;

/**
 * Sourced from https://en.wikipedia.org/wiki/List_of_circulating_currencies
 */
export const ALL_CURRENCIES = {
  AED: { name: 'United Arab Emirates dirham', symbol: 'Dh', code: 'AED' },
  AFN: { name: 'Afghan afghani', symbol: 'Af', code: 'AFN' },
  ALL: { name: 'Albanian lek', symbol: 'Lek', code: 'ALL' },
  AMD: { name: 'Armenian dram', symbol: '֏', code: 'AMD' },
  ANG: { name: 'Netherlands Antillean guilder', symbol: 'ƒ', code: 'ANG' },
  AOA: { name: 'Angolan kwanza', symbol: 'Kz', code: 'AOA' },
  ARS: { name: 'Argentine peso', symbol: '$', code: 'ARS' },
  AUD: { name: 'Australian dollar', symbol: '$', code: 'AUD' },
  AWG: { name: 'Aruban florin', symbol: 'ƒ', code: 'AWG' },
  AZN: { name: 'Azerbaijani manat', symbol: '₼', code: 'AZN' },
  BAM: {
    name: 'Bosnia and Herzegovina convertible mark',
    symbol: 'KM',
    code: 'BAM',
  },
  BBD: { name: 'Barbadian dollar', symbol: '$', code: 'BBD' },
  BDT: { name: 'Bangladeshi taka', symbol: '৳', code: 'BDT' },
  BGN: { name: 'Bulgarian lev', symbol: 'Lev', code: 'BGN' },
  BHD: { name: 'Bahraini dinar', symbol: 'BD', code: 'BHD' },
  BIF: { name: 'Burundian franc', symbol: 'Fr', code: 'BIF' },
  BMD: { name: 'Bermudian dollar', symbol: '$', code: 'BMD' },
  BND: { name: 'Brunei dollar', symbol: '$', code: 'BND' },
  BOB: { name: 'Bolivian boliviano', symbol: 'Bs', code: 'BOB' },
  BRL: { name: 'Brazilian real', symbol: 'R$', code: 'BRL' },
  BSD: { name: 'Bahamian dollar', symbol: '$', code: 'BSD' },
  BTN: { name: 'Bhutanese ngultrum', symbol: 'Nu', code: 'BTN' },
  BWP: { name: 'Botswana pula', symbol: 'P', code: 'BWP' },
  BYN: { name: 'Belarusian ruble', symbol: 'Br', code: 'BYN' },
  BZD: { name: 'Belize dollar', symbol: '$', code: 'BZD' },
  CAD: { name: 'Canadian dollar', symbol: '$', code: 'CAD' },
  CDF: { name: 'Congolese franc', symbol: 'Fr', code: 'CDF' },
  CHF: { name: 'Swiss franc', symbol: 'Fr', code: 'CHF' },
  CLP: { name: 'Chilean peso', symbol: '$', code: 'CLP' },
  CNY: { name: 'Renminbi', symbol: '¥', code: 'CNY' },
  COP: { name: 'Colombian peso', symbol: '$', code: 'COP' },
  CRC: { name: 'Costa Rican colón', symbol: '₡', code: 'CRC' },
  CUP: { name: 'Cuban peso', symbol: '$', code: 'CUP' },
  CVE: { name: 'Cape Verdean escudo', symbol: '$', code: 'CVE' },
  CZK: { name: 'Czech koruna', symbol: 'Kč', code: 'CZK' },
  DJF: { name: 'Djiboutian franc', symbol: 'Fr', code: 'DJF' },
  DKK: { name: 'Danish krone', symbol: 'kr', code: 'DKK' },
  DOP: { name: 'Dominican peso', symbol: '$', code: 'DOP' },
  DZD: { name: 'Algerian dinar', symbol: 'DA', code: 'DZD' },
  EGP: { name: 'Egyptian pound', symbol: 'LE', code: 'EGP' },
  ERN: { name: 'Eritrean nakfa', symbol: 'Nkf', code: 'ERN' },
  ETB: { name: 'Ethiopian birr', symbol: 'Br', code: 'ETB' },
  EUR: { name: 'Euro', symbol: '€', code: 'EUR' },
  FJD: { name: 'Fijian dollar', symbol: '$', code: 'FJD' },
  FKP: { name: 'Falkland Islands pound', symbol: '£', code: 'FKP' },
  GBP: { name: 'Sterling', symbol: '£', code: 'GBP' },
  GEL: { name: 'Georgian lari', symbol: '₾', code: 'GEL' },
  GHS: { name: 'Ghanaian cedi', symbol: '₵', code: 'GHS' },
  GIP: { name: 'Gibraltar pound', symbol: '£', code: 'GIP' },
  GMD: { name: 'Gambian dalasi', symbol: 'D', code: 'GMD' },
  GNF: { name: 'Guinean franc', symbol: 'Fr', code: 'GNF' },
  GTQ: { name: 'Guatemalan quetzal', symbol: 'Q', code: 'GTQ' },
  GYD: { name: 'Guyanese dollar', symbol: '$', code: 'GYD' },
  HKD: { name: 'Hong Kong dollar', symbol: '$', code: 'HKD' },
  HNL: { name: 'Honduran lempira', symbol: 'L', code: 'HNL' },
  HTG: { name: 'Haitian gourde', symbol: 'G', code: 'HTG' },
  HUF: { name: 'Hungarian forint', symbol: 'Ft', code: 'HUF' },
  IDR: { name: 'Indonesian rupiah', symbol: 'Rp', code: 'IDR' },
  ILS: { name: 'Israeli new shekel', symbol: '₪', code: 'ILS' },
  INR: { name: 'Indian rupee', symbol: '₹', code: 'INR' },
  IQD: { name: 'Iraqi dinar', symbol: 'ID', code: 'IQD' },
  IRR: { name: 'Iranian rial', symbol: 'Rl', code: 'IRR' },
  ISK: { name: 'Icelandic króna', symbol: 'kr', code: 'ISK' },
  JMD: { name: 'Jamaican dollar', symbol: '$', code: 'JMD' },
  JOD: { name: 'Jordanian dinar', symbol: 'JD', code: 'JOD' },
  JPY: { name: 'Japanese yen', symbol: '¥', code: 'JPY' },
  KES: { name: 'Kenyan shilling', symbol: 'Sh', code: 'KES' },
  KGS: { name: 'Kyrgyz som', symbol: 'som', code: 'KGS' },
  KHR: { name: 'Cambodian riel', symbol: '៛', code: 'KHR' },
  KMF: { name: 'Comorian franc', symbol: 'Fr', code: 'KMF' },
  KPW: { name: 'North Korean won', symbol: '₩', code: 'KPW' },
  KRW: { name: 'South Korean won', symbol: '₩', code: 'KRW' },
  KWD: { name: 'Kuwaiti dinar', symbol: 'KD', code: 'KWD' },
  KYD: { name: 'Cayman Islands dollar', symbol: '$', code: 'KYD' },
  KZT: { name: 'Kazakhstani tenge', symbol: '₸', code: 'KZT' },
  LAK: { name: 'Lao kip', symbol: '₭', code: 'LAK' },
  LBP: { name: 'Lebanese pound', symbol: 'LL', code: 'LBP' },
  LKR: { name: 'Sri Lankan rupee', symbol: 'Re', code: 'LKR' },
  LRD: { name: 'Liberian dollar', symbol: '$', code: 'LRD' },
  LSL: { name: 'Lesotho loti', symbol: 'L', code: 'LSL' },
  LYD: { name: 'Libyan dinar', symbol: 'LD', code: 'LYD' },
  MAD: { name: 'Moroccan dirham', symbol: 'DH', code: 'MAD' },
  MDL: { name: 'Moldovan leu', symbol: 'Leu', code: 'MDL' },
  MGA: { name: 'Malagasy ariary', symbol: 'Ar', code: 'MGA' },
  MKD: { name: 'Macedonian denar', symbol: 'DEN', code: 'MKD' },
  MMK: { name: 'Burmese kyat', symbol: 'K', code: 'MMK' },
  MNT: { name: 'Mongolian tögrög', symbol: '₮', code: 'MNT' },
  MOP: { name: 'Macanese pataca', symbol: 'MOP$', code: 'MOP' },
  MRU: { name: 'Mauritanian ouguiya', symbol: 'UM', code: 'MRU' },
  MUR: { name: 'Mauritian rupee', symbol: 'Re', code: 'MUR' },
  MVR: { name: 'Maldivian rufiyaa', symbol: 'Rf', code: 'MVR' },
  MWK: { name: 'Malawian kwacha', symbol: 'K', code: 'MWK' },
  MXN: { name: 'Mexican peso', symbol: '$', code: 'MXN' },
  MYR: { name: 'Malaysian ringgit', symbol: 'RM', code: 'MYR' },
  MZN: { name: 'Mozambican metical', symbol: 'Mt', code: 'MZN' },
  NAD: { name: 'Namibian dollar', symbol: '$', code: 'NAD' },
  NGN: { name: 'Nigerian naira', symbol: '₦', code: 'NGN' },
  NIO: { name: 'Nicaraguan córdoba', symbol: 'C$', code: 'NIO' },
  NOK: { name: 'Norwegian krone', symbol: 'kr', code: 'NOK' },
  NPR: { name: 'Nepalese rupee', symbol: 'Re', code: 'NPR' },
  NZD: { name: 'New Zealand dollar', symbol: '$', code: 'NZD' },
  OMR: { name: 'Omani rial', symbol: 'RO', code: 'OMR' },
  PAB: { name: 'Panamanian balboa', symbol: 'B/', code: 'PAB' },
  PEN: { name: 'Peruvian sol', symbol: 'S/', code: 'PEN' },
  PGK: { name: 'Papua New Guinean kina', symbol: 'K', code: 'PGK' },
  PHP: { name: 'Philippine peso', symbol: '₱', code: 'PHP' },
  PKR: { name: 'Pakistani rupee', symbol: 'Re', code: 'PKR' },
  PLN: { name: 'Polish złoty', symbol: 'zł', code: 'PLN' },
  PYG: { name: 'Paraguayan guaraní', symbol: '₲', code: 'PYG' },
  QAR: { name: 'Qatari riyal', symbol: 'QR', code: 'QAR' },
  RON: { name: 'Romanian leu', symbol: 'Leu', code: 'RON' },
  RSD: { name: 'Serbian dinar', symbol: 'DIN', code: 'RSD' },
  RUB: { name: 'Russian ruble', symbol: '₽', code: 'RUB' },
  RWF: { name: 'Rwandan franc', symbol: 'Fr', code: 'RWF' },
  SAR: { name: 'Saudi riyal', symbol: 'Rl', code: 'SAR' },
  SBD: { name: 'Solomon Islands dollar', symbol: '$', code: 'SBD' },
  SCR: { name: 'Seychellois rupee', symbol: 'Re', code: 'SCR' },
  SDG: { name: 'Sudanese pound', symbol: 'LS', code: 'SDG' },
  SEK: { name: 'Swedish krona', symbol: 'kr', code: 'SEK' },
  SGD: { name: 'Singapore dollar', symbol: '$', code: 'SGD' },
  SHP: { name: 'Saint Helena pound', symbol: '£', code: 'SHP' },
  SLE: { name: 'Sierra Leonean leone', symbol: 'Le', code: 'SLE' },
  SOS: { name: 'Somali shilling', symbol: 'Sh', code: 'SOS' },
  SRD: { name: 'Surinamese dollar', symbol: '$', code: 'SRD' },
  SSP: { name: 'South Sudanese pound', symbol: '(none)', code: 'SSP' },
  STN: { name: 'São Tomé and Príncipe dobra', symbol: 'Db', code: 'STN' },
  SYP: { name: 'Syrian pound', symbol: 'LS', code: 'SYP' },
  SZL: { name: 'Swazi lilangeni', symbol: 'L', code: 'SZL' },
  THB: { name: 'Thai baht', symbol: '฿', code: 'THB' },
  TJS: { name: 'Tajikistani somoni', symbol: 'SM', code: 'TJS' },
  TMT: { name: 'Turkmenistani manat', symbol: 'm', code: 'TMT' },
  TND: { name: 'Tunisian dinar', symbol: 'DT', code: 'TND' },
  TOP: { name: "Tongan pa'anga", symbol: 'T$', code: 'TOP' },
  TRY: { name: 'Turkish lira', symbol: '₺', code: 'TRY' },
  TTD: { name: 'Trinidad and Tobago dollar', symbol: '$', code: 'TTD' },
  TWD: { name: 'New Taiwan dollar', symbol: '$', code: 'TWD' },
  TZS: { name: 'Tanzanian shilling', symbol: 'Sh', code: 'TZS' },
  UAH: { name: 'Ukrainian hryvnia', symbol: '₴', code: 'UAH' },
  UGX: { name: 'Ugandan shilling', symbol: 'Sh', code: 'UGX' },
  USD: { name: 'United States dollar', symbol: '$', code: 'USD' },
  UYU: { name: 'Uruguayan peso', symbol: '$', code: 'UYU' },
  UZS: { name: 'Uzbekistani sum', symbol: 'soum', code: 'UZS' },
  VED: { name: 'Venezuelan digital bolívar', symbol: 'Bs.D', code: 'VED' },
  VES: { name: 'Venezuelan sovereign bolívar', symbol: 'Bs.S', code: 'VES' },
  VND: { name: 'Vietnamese đồng', symbol: '₫', code: 'VND' },
  VUV: { name: 'Vanuatu vatu', symbol: 'VT', code: 'VUV' },
  WST: { name: 'Samoan tālā', symbol: '$', code: 'WST' },
  XAF: { name: 'Central African CFA franc', symbol: 'Fr', code: 'XAF' },
  XCD: { name: 'Eastern Caribbean dollar', symbol: '$', code: 'XCD' },
  XOF: { name: 'West African CFA franc', symbol: 'Fr', code: 'XOF' },
  XPF: { name: 'CFP franc', symbol: 'Fr', code: 'XPF' },
  YER: { name: 'Yemeni rial', symbol: 'Rl', code: 'YER' },
  ZAR: { name: 'South African rand', symbol: 'R', code: 'ZAR' },
  ZMW: { name: 'Zambian kwacha', symbol: 'K', code: 'ZMW' },
} as const;

// FIXME: Backend API should provide list of available currencies
export const SUPPORTED_CURRENCIES = {
  AUD: ALL_CURRENCIES.AUD,
  BYN: ALL_CURRENCIES.BYN,
  CAD: ALL_CURRENCIES.CAD,
  CNY: ALL_CURRENCIES.CNY,
  DKK: ALL_CURRENCIES.DKK,
  EUR: ALL_CURRENCIES.EUR,
  GBP: ALL_CURRENCIES.GBP,
  INR: ALL_CURRENCIES.INR,
  ISK: ALL_CURRENCIES.ISK,
  NOK: ALL_CURRENCIES.NOK,
  SEK: ALL_CURRENCIES.SEK,
  SGD: ALL_CURRENCIES.SGD,
  TWD: ALL_CURRENCIES.TWD,
  USD: ALL_CURRENCIES.USD,
};

export const AllCurrencyCodesSchema = z.enum(
  Object.keys(ALL_CURRENCIES) as [keyof typeof ALL_CURRENCIES],
);

export const SupportedCurrencyCodesSchema = z.enum(
  Object.keys(SUPPORTED_CURRENCIES) as [keyof typeof SUPPORTED_CURRENCIES],
);

export type AllCurrencyCodes = z.infer<typeof AllCurrencyCodesSchema>;
export type SupportedCurrencyCodes = z.infer<
  typeof SupportedCurrencyCodesSchema
>;

/**
 * Get the currency symbol for a currency.
 * If for some reason no match is found, then fallback to the provided currency code.
 */
export function getCurrencySymbol(currencyCode: CurrencyCodesAll) {
  return ALL_CURRENCIES[currencyCode]?.symbol || currencyCode;
}
