import { MBox, MFlex } from '~app/components/Monetize';
import { QuoteStatusEnum } from '../../../../types';
import { useQuoteContext } from '../quoteContext';
import { QuoteDocuments } from './quoteDocuments/QuoteDocuments';

export const QuoteFormContractRow = () => {
  const {
    isReadOnly,
    quoteData: { quote },
    handleUpdateQuoteWithFormReset,
  } = useQuoteContext();

  return (
    <MFlex alignItems="flex-end">
      <MBox display="inline-block">
        <QuoteDocuments
          isReadOnly={isReadOnly || quote?.status !== QuoteStatusEnum.DRAFT}
          quote={quote}
          onUpdate={handleUpdateQuoteWithFormReset}
        />
      </MBox>
    </MFlex>
  );
};
