import { MBox } from '~app/components/Monetize';
import {
  IQuoteRespSchema,
  QuoteOfferingProps,
  QuoteOfferingWithChildren,
  QuoteStatusEnum,
} from '~app/types';
import QuoteOffering from './components/quoteOffering/QuoteOffering';
import { QuoteFormOfferingTableHeader } from './QuoteFormOfferingTableHeader';

interface QuoteFormOfferingRowProps
  extends Pick<QuoteOfferingProps, 'onChange' | 'quotePrices'> {
  quoteOfferings: QuoteOfferingWithChildren[];
  quote?: IQuoteRespSchema | null;
  renderQuoteOfferings: (
    quoteOfferings: QuoteOfferingWithChildren[],
    baseDragIndex?: number,
    dragIndex?: number,
  ) => JSX.Element[];
}

export const QuoteFormOfferingTableContent = ({
  quoteOfferings,
  quote,
  onChange,
  quotePrices,
  renderQuoteOfferings,
}: QuoteFormOfferingRowProps) => {
  return (
    <>
      <QuoteFormOfferingTableHeader />
      <MBox>{renderQuoteOfferings(quoteOfferings)}</MBox>

      {/* Empty quote offering used to allow user to add a new offering */}
      {quote?.status === QuoteStatusEnum.DRAFT && quote?.id && (
        <QuoteOffering
          quotePrices={quotePrices}
          onChange={onChange}
          index={-1}
          quoteOfferings={quoteOfferings}
        />
      )}
    </>
  );
};
