import {
  IQuoteOfferingRespSchema,
  IQuotePrice,
  IQuoteRespSchema,
  QuoteOfferingProps,
  QuoteOfferingWithChildren,
  QuoteStatusEnum,
} from '~app/types';
import QuoteOffering from './components/quoteOffering/QuoteOffering';
import { QuoteOfferingDraggable } from './components/quoteOffering/QuoteOfferingDraggable';

interface QuoteFormOfferingRowProps
  extends Pick<
    QuoteOfferingProps,
    | 'index'
    | 'quoteOffering'
    | 'onChange'
    | 'childQuoteOfferings'
    | 'quotePrices'
    | 'index'
    | 'quoteOfferings'
  > {
  dragIndex: number;
  quote?: IQuoteRespSchema | null;
  moveOffering: (dragIndex: number, hoverIndex: number) => void;
  saveMoveOffering: () => void;
}

export const QuoteFormOfferingRow = ({
  quoteOffering,
  quote,
  childQuoteOfferings,
  quotePrices,
  onChange,
  index,
  dragIndex,
  moveOffering,
  saveMoveOffering,
  quoteOfferings,
}: QuoteFormOfferingRowProps) => {
  if (quoteOffering?.id) {
    if (quote?.status === QuoteStatusEnum.DRAFT) {
      return (
        <QuoteOfferingDraggable
          key={quoteOffering.id}
          index={dragIndex}
          quoteOffering={quoteOffering}
          moveOffering={moveOffering}
          saveMoveOffering={saveMoveOffering}
        >
          <QuoteOffering
            quoteOffering={quoteOffering}
            childQuoteOfferings={childQuoteOfferings}
            index={index}
            quotePrices={quotePrices}
            onChange={onChange}
            quoteOfferings={quoteOfferings}
          />
        </QuoteOfferingDraggable>
      );
    }

    return (
      <QuoteOffering
        quoteOffering={quoteOffering}
        childQuoteOfferings={childQuoteOfferings}
        index={index}
        quotePrices={quotePrices}
        onChange={onChange}
      />
    );
  }
  return null;
};
