import partition from 'lodash/partition';
import { IUsersInfo } from '~app/types';
import { sortByAlphabetically } from '.';

export const sortTeamUsers = (users: IUsersInfo[], ownerId: string) => {
  if (Array.isArray(users)) {
    return partition(
      users.filter(Boolean).sort(sortByAlphabetically('name')),
      ({ id }) => id === ownerId,
    ).flat();
  }
  return [];
};
