import { BoxProps } from '@chakra-ui/react';
import { MFlex, MText } from '~app/components/Monetize';
import { formatCurrency } from '~app/utils';

interface IPastDueInfoProps extends BoxProps {
  pastDueAmount: number;
  currency: string;
}
const PastDueAmountInfo = ({
  pastDueAmount,
  currency,
  ...rest
}: IPastDueInfoProps) => {
  if (pastDueAmount <= 0) {
    return null;
  }
  return (
    <MFlex {...rest} gap={1}>
      <MText color="tRed.base" fontWeight="semibold">
        Past Due:
      </MText>
      <MText color="tRed.base">
        {formatCurrency(pastDueAmount, {
          currency,
          maximumFractionDigits: 2,
        })}
      </MText>
    </MFlex>
  );
};

export default PastDueAmountInfo;
