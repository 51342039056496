import { IInvoiceRespSchema, InvoiceSummaryResp, Maybe } from '~app/types';

export function isSummaryInvoiceResponse(
  invoice: IInvoiceRespSchema | InvoiceSummaryResp,
): invoice is InvoiceSummaryResp {
  return !(invoice as IInvoiceRespSchema).billingContact;
}

export const getTaxCalculationError = (
  taxError?: Maybe<string>,
  canUpdateTax: boolean = false,
): { message: string; reviewAction?: 'ADDRESS' | 'TAX-CONFIG' } => {
  switch (taxError) {
    case 'customerAddressCouldNotResolve':
      return {
        message:
          'Tax calculation error: Address is invalid, confirm the invoice shipping address.',
        reviewAction: 'ADDRESS',
      };
    case 'invalidToken':
      if (canUpdateTax) {
        return {
          message:
            'Tax calculation error: Authentication failure, confirm your API key.',
          reviewAction: 'TAX-CONFIG',
        };
      }
      return {
        message:
          'Tax calculation error: Authentication failure, please notify your administrator.',
      };
    case 'productExternalIdUnknown':
      return {
        message:
          'Tax calculation error: Product mapping not configured correctly with your tax provider.',
      };
    // For all other errors (and unknown errors) fallback to showing the user the actual message
    case 'currencyCodeNotSupported':
    case 'taxDateTooFarInFuture':
    case 'transactionFrozenForFiling':
    case 'transactionIdNotFound':
    default: {
      let reviewAction: 'ADDRESS' | 'TAX-CONFIG' | undefined;
      const normalizedTaxError = taxError?.toLowerCase() || '';
      /**
       * These are mostly here for Avalara - we only have the message and not the code,
       * so we make a best-attempt to guess the action based on the message.
       */
      if (
        ['address', 'postal code', 'province', 'country', 'zip'].some((token) =>
          normalizedTaxError.includes(token),
        )
      ) {
        reviewAction = 'ADDRESS';
      }
      if (
        ['authentication', 'authenticate'].some((token) =>
          normalizedTaxError.includes(token),
        )
      ) {
        reviewAction = 'ADDRESS';
      }
      return { message: `Tax calculation error: ${taxError}`, reviewAction };
    }
  }
};
