import { useRecoilState } from 'recoil';
import { switchTenantVisibleState } from '~app/store/global.store';
import AppTenantSwitchModal from './AppTenantSwitchModal';

const AppTenantSwitchModalWrapper = (props: any) => {
  const [modalVisible, setSwitchTenantVisible] = useRecoilState(
    switchTenantVisibleState,
  );

  const onClose = () => {
    setSwitchTenantVisible(false);
  };

  const onOpen = () => {
    setSwitchTenantVisible(true);
  };

  if (!modalVisible) {
    return null;
  }

  return <AppTenantSwitchModal onClose={onClose} onOpen={onOpen} isOpen />;
};

export default AppTenantSwitchModalWrapper;
