import { FunctionComponent as FC, useRef, useState } from 'react';
import { useCreateUsageRecord } from '~app/api/accountsService';
import { logger } from '~app/services/logger';
import { IUsageEventRequestSchema, IUsageEventResSchema } from '~app/types';
import { splitArrayToMaxSize } from '~app/utils/misc';
import { MButton, MCenterModal, MFlex, MStack } from '~components/Monetize';
import { UploadUsageFileSummary } from './UploadUsageFileSummary';
import { UploadUsageInput } from './UploadUsageInput';
import { UploadUsageProgress } from './UploadUsageProgress';

interface ProcessingStatus {
  state: 'NOT_STARTED' | 'IN_PROGRESS' | 'FINISHED';
  successCount: number;
  failureCount: number;
  errors: IUsageEventResSchema['failedEvents'];
  total: number;
}

const defaultStatus: ProcessingStatus = {
  failureCount: 0,
  state: 'NOT_STARTED',
  successCount: 0,
  total: 0,
  errors: [],
};

interface UploadUsageModalProps {
  accountId: string;
  subscriptionId: string;
  onClose: (didSave?: boolean) => void;
}

export const UploadUsageModal: FC<UploadUsageModalProps> = (
  props: UploadUsageModalProps,
) => {
  const { accountId, subscriptionId, onClose } = props;

  const isCancelled = useRef(false);
  const [dataToUpload, setDataToUpload] = useState<IUsageEventRequestSchema[]>(
    [],
  );
  const [filename, setFilename] = useState<string | null>(null);
  const { mutateAsync: doPostSubscriptionUsage } = useCreateUsageRecord();

  const [results, setResults] = useState<ProcessingStatus>(defaultStatus);

  const hasData = dataToUpload.length > 0;

  const handleData = (filename: string, data: IUsageEventRequestSchema[]) => {
    setFilename(filename);
    setDataToUpload(data);
  };

  const handleRemoveFile = () => {
    setFilename(null);
    setDataToUpload([]);
  };

  const handleImport = async () => {
    isCancelled.current = false;
    setResults({
      failureCount: 0,
      state: 'IN_PROGRESS',
      successCount: 0,
      total: dataToUpload.length,
      errors: [],
    });

    const dataSets = splitArrayToMaxSize(dataToUpload, 50);
    for (const records of dataSets) {
      // User Cancelled
      if (isCancelled.current) {
        break;
      }

      try {
        const { failedEvents } = await doPostSubscriptionUsage(records);
        setResults((priorResults) => {
          return {
            ...priorResults,
            failureCount: priorResults.failureCount + failedEvents.length,
            successCount:
              priorResults.successCount + records.length - failedEvents.length,
            errors: [...priorResults.errors, ...failedEvents],
          };
        });
      } catch (ex) {
        logger.warn('Error processing usage records', ex);
        setResults((priorResults) => {
          return {
            ...priorResults,
            failureCount: priorResults.failureCount + records.length,
          };
        });
      }
    }

    setResults((priorResults) => ({
      ...priorResults,
      state: 'FINISHED',
    }));
  };

  function handleCancel() {
    if (results.state === 'IN_PROGRESS') {
      isCancelled.current = true;
      // update state to trigger re-render
      setResults((priorResults) => ({ ...priorResults }));
    } else {
      handleClose();
    }
  }

  function handleClose() {
    onClose(results.state === 'FINISHED');
    setFilename(null);
    setDataToUpload([]);
    setResults(defaultStatus);
    isCancelled.current = false;
  }

  return (
    <MCenterModal
      isOpen
      onClose={() => {
        results.state !== 'IN_PROGRESS' && handleClose();
      }}
      modalTitle="Import Usage"
      size="lg"
      showCloseButton={results.state !== 'IN_PROGRESS'}
      closeOnEsc={results.state !== 'IN_PROGRESS'}
      closeOnOverlayClick={results.state !== 'IN_PROGRESS'}
      renderFooter={() => (
        <MStack
          spacing={4}
          direction="row"
          align="center"
          justify="right"
          flex={1}
        >
          {/* If in progress, this button will cancel the import but will not close the modal */}
          <MButton
            variant="cancel"
            minW="auto"
            isLoading={results.state === 'IN_PROGRESS' && isCancelled.current}
            onClick={() => handleCancel()}
          >
            {results.state === 'FINISHED' ? 'Close' : 'Cancel'}
          </MButton>
          {results.state === 'NOT_STARTED' && (
            <MButton
              form="manual-usage-form"
              data-testid="manual-usage-form-submit"
              variant="primary"
              isDisabled={!hasData}
              minW="auto"
              onClick={handleImport}
            >
              Import
            </MButton>
          )}
        </MStack>
      )}
    >
      <MFlex flexDirection="column">
        {/* File Upload Input */}
        {results.state === 'NOT_STARTED' && !hasData && (
          <UploadUsageInput
            recordData={{
              accountId: accountId,
              subscriptionId: subscriptionId,
            }}
            onData={handleData}
          />
        )}
        {/* File Summary */}
        {results.state === 'NOT_STARTED' && filename && (
          <UploadUsageFileSummary
            filename={filename}
            rows={dataToUpload.length}
            onRemoveFile={handleRemoveFile}
          />
        )}
        {/* Progress Indicator */}
        {results.state !== 'NOT_STARTED' && (
          <UploadUsageProgress
            finished={results.state === 'FINISHED'}
            successCount={results.successCount}
            failureCount={results.failureCount}
            total={results.total}
            errors={results.errors}
          />
        )}
      </MFlex>
    </MCenterModal>
  );
};
