import {
  FocusLock,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  useDisclosure,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect } from 'react';
import { Controller, UseFormSetValue, useForm } from 'react-hook-form';
import {
  MButton,
  MCustomNumberInput,
  MDivider,
  MFlex,
  MFormField,
  MPopover,
  MText,
  MVStack,
  RadioStyleButtonGroup,
} from '~app/components/Monetize';
import { MTertiaryArrowButton } from '~app/components/Monetize/MTertiaryArrowButton';
import {
  QUOTE_CONTRACT_LENGTHS,
  QUOTE_CUSTOM_CONTRACT_LENGTHS,
} from '~app/constants/quotes';
import {
  ContactLengthPopoverSchema,
  IContactLengthPopoverSchema,
  IQuoteRequestSchema,
  IQuoteRespSchema,
} from '~app/types';
import { pluralize } from '~app/utils';
import {
  getContractLengthInMonthOrYear,
  isContractLengthWholeYear,
} from '~app/utils/quotes';

interface IQuoteContractLengthPopoverV2Props {
  isCustomContractLength: boolean;
  isNew: boolean;
  isAmendment: boolean;
  quote: IQuoteRespSchema;
  setValue: UseFormSetValue<IQuoteRequestSchema>;
  handleSubmitButton: () => void;
  isDisabled?: boolean;
}

/**
 * This component manage the popup form state and submission for both- Whole Years or Any Months
 *
 * if isCustomContractLength is true means it's Any Months otherwise Whole Years
 */

const customContractOptions = QUOTE_CUSTOM_CONTRACT_LENGTHS.map((value) => ({
  label: `${value}`,
  value: `${value}`,
}));
const contractLengthOptions = QUOTE_CONTRACT_LENGTHS.map((value) => ({
  label: `${value}`,
  value: `${value}`,
}));

export const QuoteContractLengthPopoverV2 = ({
  isCustomContractLength,
  isNew,
  quote,
  setValue,
  isAmendment,
  handleSubmitButton,
  isDisabled,
}: IQuoteContractLengthPopoverV2Props) => {
  const { onOpen, onClose, isOpen } = useDisclosure();

  const {
    formState: { isDirty, isValid, errors },
    control,
    handleSubmit,
    setValue: setContractLengthFormValue,
    watch,
    setError,
    clearErrors,
    reset,
  } = useForm<IContactLengthPopoverSchema>({
    resolver: zodResolver(ContactLengthPopoverSchema),
    mode: 'onChange',
    defaultValues: {
      contractLength: null,
    },
  });
  const watchContractLength = watch('contractLength') || 0;

  const handleOnOpen = () => {
    if (isDisabled) {
      return;
    }

    const defaultContractVal = getContractLengthInMonthOrYear(
      isCustomContractLength,
      quote.contractLength,
    );

    setContractLengthFormValue(
      'contractLength',
      !isCustomContractLength &&
        !isContractLengthWholeYear(quote.contractLength)
        ? null // As CustomContractLength config changes from tenant level and value not whole year so showing blank on popover [BP-8982]
        : defaultContractVal,
      {
        shouldDirty: true,
        shouldValidate: true,
      },
    );
    onOpen();
  };

  useEffect(() => {
    if (quote.contractLength) {
      const contractVal = getContractLengthInMonthOrYear(
        isCustomContractLength,
        quote.contractLength,
      );
      reset({
        contractLength:
          !isCustomContractLength &&
          !isContractLengthWholeYear(quote.contractLength)
            ? null
            : contractVal,
      });
    }
  }, [isCustomContractLength, quote.contractLength, reset]);

  const onChangeContractLength = (value: number) => {
    const contractLengthMonths = isCustomContractLength ? value : value * 12;

    setContractLengthFormValue('contractLength', value, {
      shouldDirty: contractLengthMonths > 0 && contractLengthMonths < 121,
      shouldValidate: contractLengthMonths > 0 && contractLengthMonths < 121,
    });

    if (contractLengthMonths === 0 || contractLengthMonths > 120) {
      setError(
        'contractLength',
        {
          message: `The contract length must be between ${
            isCustomContractLength ? '1 and 120' : '1 and 10 years'
          }`,
        },
        { shouldFocus: true },
      );
    } else {
      clearErrors('contractLength');
    }
  };

  const displayDefaultContractLengths = () => {
    const defaultContractLength = isCustomContractLength
      ? QUOTE_CUSTOM_CONTRACT_LENGTHS
      : QUOTE_CONTRACT_LENGTHS;
    return defaultContractLength.map((length: number) => (
      <MButton
        key={length}
        variant="secondary"
        size={isCustomContractLength ? 'md' : 'xs'}
        fontSize="xs"
        fontWeight="semibold"
        minW={isCustomContractLength ? '58px' : '31px'}
        onClick={() =>
          setContractLengthFormValue('contractLength', length, {
            shouldDirty: true,
            shouldValidate: true,
          })
        }
        _focus={{ boxShadow: 'none' }}
      >
        {length}
      </MButton>
    ));
  };

  const contractLengthLabel =
    !isCustomContractLength && isContractLengthWholeYear(quote.contractLength)
      ? pluralize('Year', quote.contractLength / 12)
      : pluralize('Month', quote.contractLength);

  const onSubmitForm = () => {
    onClose();
    setValue(
      'contractLength',
      isCustomContractLength ? watchContractLength : watchContractLength * 12,
      {
        shouldDirty: true,
        shouldValidate: true,
      },
    );
    handleSubmitButton();
  };

  return (
    <MPopover
      isLazy
      isOpen={isOpen}
      onOpen={handleOnOpen}
      onClose={onClose}
      returnFocusOnClose={true}
      size={'smaller'}
      strategy="fixed"
      placement="bottom-end"
      offset={[0, 0]}
    >
      <MFlex minH={8} align="center">
        <PopoverTrigger>
          {isDisabled ? (
            <MText data-testid="quote-custom-contractLength-value">
              {getContractLengthInMonthOrYear(
                isCustomContractLength,
                quote.contractLength,
              )}{' '}
              {contractLengthLabel}
            </MText>
          ) : (
            <MTertiaryArrowButton
              isOpen={isOpen}
              setIsOpen={(val) => (val ? handleOnOpen() : onClose())}
              mr="-3"
            >
              {getContractLengthInMonthOrYear(
                isCustomContractLength,
                quote.contractLength,
              )}{' '}
              {contractLengthLabel}
            </MTertiaryArrowButton>
          )}
        </PopoverTrigger>
      </MFlex>

      <Portal>
        <PopoverContent data-testid="custom-contractLength-popover-content">
          <PopoverBody>
            <FocusLock>
              <form onSubmit={handleSubmit(onSubmitForm)}>
                <MText fontWeight={'semibold'} color="tPurple.dark">
                  Number of {isCustomContractLength ? 'Months' : 'Years'}
                </MText>
                <MVStack spacing={2} alignItems={'flex-start'} mt={2}>
                  <MFormField error={errors?.contractLength} isRequired>
                    <Controller
                      name="contractLength"
                      control={control}
                      render={({ field: { value, onChange, ...rest } }) => (
                        <MCustomNumberInput
                          inputMode="numeric"
                          value={value as number}
                          onChange={(
                            valueAsString: string,
                            valueAsNumber: number,
                          ) => {
                            onChangeContractLength(valueAsNumber);
                          }}
                          {...rest}
                        />
                      )}
                    />
                  </MFormField>

                  <RadioStyleButtonGroup
                    name="contractLength"
                    options={
                      isCustomContractLength
                        ? customContractOptions
                        : contractLengthOptions
                    }
                    value={`${watchContractLength || ''}` || undefined}
                    defaultValue={`${watchContractLength || ''}` || undefined}
                    onChange={(value: string) => {
                      setContractLengthFormValue(
                        'contractLength',
                        Number(value),
                        {
                          shouldDirty: true,
                          shouldValidate: true,
                        },
                      );
                    }}
                  />
                  <MDivider />
                  <MVStack alignItems={'flex-end'} w="full">
                    <MButton
                      type="submit"
                      minWidth={'80px'}
                      isDisabled={
                        !isDirty || !isValid || !!errors?.contractLength
                      }
                    >
                      Save
                    </MButton>
                  </MVStack>
                </MVStack>
              </form>
            </FocusLock>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </MPopover>
  );
};
