import {
  useInfiniteQuery,
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';
import groupBy from 'lodash/groupBy';
import api, { apiGet, apiPost, apiPut } from '~api/axios';
import {
  ICreditNoteReqSchema,
  ICreditNoteSchema,
} from '~app/types/creditNoteTypes';
import { sortByAlphabetically } from '~app/utils';
import {
  arrayToObject,
  nullifyEmptyStrings,
  orderObjectsBy,
} from '~app/utils/misc';
import {
  ApiListInfiniteScrollResponse,
  ApiListResponse,
  CreateInvoicePaymentRequest,
  DEFAULT_PAGER,
  GenericApiResponse,
  GetListApiConfig,
  GetListApiFilter,
  IAccount,
  IAccountDetails,
  IAccountHistoryResp,
  IAccountOverviewStat,
  IAccountRespSchema,
  IBillGroupResp,
  IBillGroupStats,
  ICustomFieldRecordSchema,
  IInvoiceContactUpdateSchema,
  IInvoiceRespSchema,
  IManualPayment,
  ImportablePaymentMethodResp,
  ImportPaymentMethodResp,
  InvoiceSummaryResp,
  IOverviewItem,
  IOverviewResponse,
  IPayment,
  IPaymentGatewayAccountSchema,
  IPaymentMethodReq,
  IPaymentMethodResp,
  IUsageEvent,
  IUsageEventListRequestSchema,
  IUsageEventRequestSchema,
  IUsageEventResSchema,
  IUsageEventUpdateReqSchema,
  Maybe,
  PaymentGatewayClientSecretResponse,
  SetupIntentResponse,
} from '~types';
import { getGroupedAndSortedPaymentMethodOptions } from '../utils/payment.utils';
import { invoiceServiceQueryKeys } from './invoiceService';
import {
  accountServiceQueryKeys,
  subscriptionServiceQueryKeys,
} from './queryKeysService';
import {
  setByIdCacheFromReturnedList,
  updateListCacheWithUpdatedItem,
} from './queryUtilsHelpers';
import { composeGetQuery } from './utils';

// accounts
/** TODO: @deprecate */
export const getAccountById = async (accountId: string) => {
  const res = await apiGet<IAccountDetails>(`/api/accounts/${accountId}`);
  return res.data;
};

export const doGetBillGroupById = async (billGroupId: string) => {
  const res = await apiGet<IBillGroupResp>(`/api/billGroups/${billGroupId}`);
  return res.data;
};
export function useGetBillGroupStatsById(
  billGroupId: string,
  options: {
    enabled?: boolean;
    onSuccess?: (data: IBillGroupStats) => void;
    onError?: (data: unknown) => void;
    refetchOnWindowFocus?: boolean;
  } = {},
) {
  return useQuery(
    [...accountServiceQueryKeys.billGroups.billGroupStatsById(billGroupId)],
    {
      queryFn: () =>
        apiGet<IBillGroupStats>(`/api/billGroups/${billGroupId}/stats`).then(
          (res) => res.data,
        ),
      ...options,
    },
  );
}

export function useGetAccountById(
  accountId: string,
  options: {
    enabled?: boolean;
    onSuccess?: (data: IAccountRespSchema) => void;
    onError?: (data: unknown) => void;
    refetchOnWindowFocus?: boolean;
  } = {},
) {
  return useQuery(
    [...accountServiceQueryKeys.accounts.accountDetail(accountId)],
    {
      queryFn: () =>
        apiGet<IAccountRespSchema>(`/api/accounts/${accountId}`).then(
          (res) => res.data,
        ),
      ...options,
    },
  );
}

/** TODO: @deprecate */
export const getAccountList = async (
  config: GetListApiConfig,
  filters?: GetListApiFilter,
): Promise<ApiListResponse<IAccount>> => {
  const params = composeGetQuery(config, filters);
  const res = await apiGet<ApiListResponse<IAccount>>('/api/accounts', {
    params,
  });
  return res.data;
};

export function useGetAccountList({
  config,
  filters,
  options,
  useSearchApi = false,
}: {
  config: GetListApiConfig;
  filters?: GetListApiFilter;
  options?: { staleTime: number; keepPreviousData?: boolean };
  /** If true, will call the search API, which expects query=searchTerm parameter */
  useSearchApi?: boolean;
}) {
  const params = composeGetQuery(config, filters);
  const endpoint = useSearchApi ? '/api/accounts/search' : '/api/accounts';
  return useQuery<ApiListResponse<IAccountDetails>>(
    [...accountServiceQueryKeys.accounts.accountList(), params],
    {
      queryFn: () =>
        apiGet<ApiListResponse<IAccountDetails>>(endpoint, {
          params,
        }).then((res) => res.data),
      ...options,
    },
  );
}

export function useCreateAccount(
  options: Partial<
    UseMutationOptions<IAccount, unknown, Partial<IAccount>>
  > = {},
) {
  const { onSuccess, ...restOptions } = options;
  const queryClient = useQueryClient();
  return useMutation<IAccount, unknown, Partial<IAccount>>(
    (payload) =>
      apiPost<IAccount>('/api/accounts', payload).then((res) => res.data),
    {
      onSuccess: (...rest) => {
        queryClient.invalidateQueries(
          accountServiceQueryKeys.accounts.accountList(),
        );
        queryClient.setQueryData(
          [...accountServiceQueryKeys.accounts.base, rest[0]?.id],
          rest[0],
        );
        onSuccess && onSuccess(...rest);
      },
      ...restOptions,
    },
  );
}
export function useUpdateAccount(
  options: Partial<
    UseMutationOptions<
      IAccount,
      unknown,
      { id: string; data: Partial<IAccount> }
    >
  > = {},
) {
  const { onSuccess, ...restOptions } = options;
  const queryClient = useQueryClient();
  return useMutation<
    IAccount,
    unknown,
    { id: string; data: Partial<IAccount> }
  >(
    ({ id, data }) =>
      apiPut<IAccount>(`/api/accounts/${id}`, nullifyEmptyStrings(data)).then(
        (res) => res.data,
      ),
    {
      onSuccess: (...rest) => {
        queryClient.setQueryData(
          [...accountServiceQueryKeys.accounts.base, rest?.[0].id],
          rest[0],
        );
        onSuccess && onSuccess(...rest);
      },
      ...restOptions,
    },
  );
}

export const doDeactivateAccount = async (
  id: string,
): Promise<GenericApiResponse> => {
  const res = await apiPut<GenericApiResponse>(`/api/accounts/${id}/cancel`);
  return res.data;
};

export const doActivateAccount = async (id: string): Promise<IAccount> => {
  const res = await apiPut<IAccount>(`/api/accounts/${id}/activate`);
  return res.data;
};

// Sample ends

// TODO-BREN remove the function below once it's unused
export const fetchAccountsService = async (): Promise<IAccount[]> =>
  api.get('/api/accounts').then((res) => res.data.content);

export const createAccountService = async (payload: any): Promise<any> =>
  api.post('/api/accounts/', payload).then((res) => res.data);

export const updateAccountService = async (
  payload: any,
  accountId: string,
): Promise<any> =>
  api
    .put(`/api/accounts/${accountId}`, { ...payload, status: payload.status })
    .then((res) => res.data);

export const fetchAccountDetailsByAccountId = async (
  accountId: string,
): Promise<IAccountDetails[]> =>
  api.get(`/api/accounts/${accountId}`).then((res) => res.data);

export const doGetAccountHistory = async (
  accountId: string,
  config: GetListApiConfig,
  filters?: GetListApiFilter,
): Promise<ApiListResponse<IAccountHistoryResp>> => {
  const url = `/api/account/${accountId}/history`;
  const params = composeGetQuery(config, filters);
  const res = await apiGet<ApiListResponse<IAccountHistoryResp>>(url, {
    params,
  });
  return res.data;
};

export function useGetAccountOverview(
  accountId: string,
  billGroupId?: string,
  options: Partial<
    UseQueryOptions<IOverviewResponse, unknown, IOverviewItem[]>
  > = {},
) {
  return useQuery<IOverviewResponse, unknown, IOverviewItem[]>(
    [...accountServiceQueryKeys.accounts.accountDetailOverview(accountId)],
    {
      queryFn: () =>
        apiGet<IOverviewResponse>(
          `/api/v2/accounts/${accountId}/subscriptions/overview`,
        ).then(({ data }) => data),
      select: ({ subscriptionOverviewsByBillGroup }) => {
        const filteredData = billGroupId
          ? subscriptionOverviewsByBillGroup.filter(
              ({ billgroup }) => billgroup.id === billGroupId,
            )
          : subscriptionOverviewsByBillGroup;
        const orderedData = orderObjectsBy(filteredData, [
          'billgroup.name',
          'billgroup.id',
        ] as any);

        return orderedData.map(({ subscriptionOverviews, ...rest }) => {
          return {
            ...rest,
            subscriptionOverviews: subscriptionOverviews.sort(
              sortByAlphabetically('name'),
            ),
          };
        });
      },
      ...options,
    },
  );
}
export const useGetAccountOverviewStats = (
  accountId: string,
  options: { onError?: (err: unknown) => void } = {},
) => {
  return useQuery<IAccountOverviewStat>(
    [
      ...accountServiceQueryKeys.accounts.accountDetailOverview(accountId),
      'stat',
    ],
    {
      queryFn: () =>
        apiGet<IAccountOverviewStat>(
          `/api/accounts/${accountId}/overview`,
        ).then(({ data }) => data),
      ...options,
    },
  );
};

export function useGetUsageRecordsInfiniteLoad(
  query: IUsageEventListRequestSchema,
  config: GetListApiConfig,
  options: {
    enabled?: boolean;
    onError?: (err: unknown) => void;
  } = {},
) {
  const params = composeGetQuery(config, {
    ...query,
    usageTypeIds: query.usageTypeIds ? query.usageTypeIds.join(',') : '',
  });

  return useInfiniteQuery([...accountServiceQueryKeys.usage.usageList(query)], {
    queryFn: ({ pageParam = undefined }) =>
      apiGet<ApiListInfiniteScrollResponse<IUsageEvent>>(`/usage/events`, {
        params: {
          ...params,
          nextPageToken: pageParam,
        },
      }).then((res) => res.data),
    getNextPageParam: (lastPage) => lastPage.pageable?.nextPageToken,
    retry: 0,
    ...options,
  });
}

export function useCreateUsageRecord(
  options: {
    onSuccess?: (data: IUsageEventResSchema) => void;
    onError?: (err: unknown) => void;
  } = {},
) {
  const { onSuccess, ...restOptions } = options;
  const queryClient = useQueryClient();
  return useMutation<IUsageEventResSchema, unknown, IUsageEventRequestSchema[]>(
    (payload) =>
      apiPost<IUsageEventResSchema>(`/usage/events`, payload).then(
        (res) => res.data,
      ),
    {
      onSuccess: (data, payload) => {
        queryClient.invalidateQueries([
          ...accountServiceQueryKeys.usage.usageListBase(),
        ]);
        payload.forEach((usageRecord) => {
          queryClient.invalidateQueries([
            ...subscriptionServiceQueryKeys.subscriptionUsageConsumption({
              subscriptionId: usageRecord.subscriptionId,
            }),
          ]);
        });
        onSuccess && onSuccess(data);
      },
      ...restOptions,
    },
  );
}

export function useEditUsageRecord(
  options: {
    onSuccess?: () => void;
    onError?: (err: unknown) => void;
  } = {},
) {
  const { onSuccess, ...restOptions } = options;
  const queryClient = useQueryClient();
  return useMutation<
    void,
    unknown,
    { id: string; payload: IUsageEventUpdateReqSchema }
  >(
    ({ id, payload }) =>
      apiPut<void>(`/usage/events/${id}`, payload).then((res) => res.data),
    {
      onSuccess: (data, { id, payload }) => {
        updateListCacheWithUpdatedItem(
          queryClient,
          [...accountServiceQueryKeys.usage.usageListBase()],
          { id, ...payload },
          true,
        );
        queryClient.invalidateQueries([
          ...accountServiceQueryKeys.usage.usageListBase(),
        ]);
        onSuccess && onSuccess();
      },
      ...restOptions,
    },
  );
}

// recent payments apis
export function useGetPaymentsByAccount<SelectData = ApiListResponse<IPayment>>(
  {
    accountId,
    config,
    filters,
  }: {
    accountId: string;
    config?: GetListApiConfig;
    filters?: GetListApiFilter;
  },
  options: {
    select?: (data: ApiListResponse<IPayment>) => SelectData;
    onError?: (err: unknown) => void;
  } = {},
) {
  let params = {};
  if (config) {
    params = composeGetQuery(config, filters);
  }
  return useQuery(
    [...accountServiceQueryKeys.payments.paymentByAccount(accountId), params],
    {
      queryFn: async () =>
        apiGet<ApiListResponse<IPayment>>(
          `/api/accounts/${accountId}/payments`,
          {
            params,
          },
        ).then((res) => res.data),
      ...options,
    },
  );
}

export function useGetPaymentById(
  accountId: string,
  paymentId: string,
  options: {
    enabled?: boolean;
    onSuccess?: (data: IPayment) => void;
    onError?: (data: unknown) => void;
    refetchOnWindowFocus?: boolean;
  } = {},
) {
  return useQuery(
    [...accountServiceQueryKeys.payments.paymentById(paymentId)],
    {
      queryFn: () =>
        apiGet<IPayment>(
          `/api/accounts/${accountId}/payments/${paymentId}`,
        ).then((res) => res.data),
      ...options,
    },
  );
}

export function useInvoiceListByAccount<
  SelectData = ApiListResponse<InvoiceSummaryResp>,
>(
  {
    accountId,
    config,
    filters,
  }: {
    accountId: string;
    config?: GetListApiConfig;
    filters?: GetListApiFilter;
  },
  options: Partial<
    UseQueryOptions<ApiListResponse<InvoiceSummaryResp>, unknown, SelectData>
  > = {},
) {
  let params = {};
  if (config) {
    params = composeGetQuery(config, filters);
  }
  return useQuery<ApiListResponse<InvoiceSummaryResp>, unknown, SelectData>(
    [accountServiceQueryKeys.accounts.invoiceList(accountId), params],
    {
      queryFn: async () =>
        apiGet<ApiListResponse<InvoiceSummaryResp>>(
          `/api/accounts/${accountId}/invoices`,
          {
            params,
          },
        ).then((res) => res.data),
      ...options,
    },
  );
}

// payment methods apis

export const doCreatePaymentMethodByAccount = async (
  accountId: string,
  data: IPaymentMethodReq,
) => {
  const res = await apiPost<IPaymentMethodResp>(
    `/api/accounts/${accountId}/paymentMethods`,
    data,
  );
  return res.data;
};

/** @deprecated, use useGetPaymentMethodsByAccount instead */
export const doGetPaymentMethodsByAccount = async (
  signal: AbortSignal,
  accountId: string,
  config?: GetListApiConfig,
  filters?: GetListApiFilter,
): Promise<ApiListResponse<IPaymentMethodResp>> => {
  let params = {};
  if (config) {
    params = composeGetQuery(config, filters);
  }
  const res = await apiGet<ApiListResponse<IPaymentMethodResp>>(
    `/api/accounts/${accountId}/paymentMethods`,
    {
      params,
      signal,
    },
  );
  return res.data;
};

export function useGetPaymentMethodsByAccount<
  SelectData extends ApiListResponse<IPaymentMethodResp>,
>(
  {
    accountId,
    config,
    filters,
  }: {
    accountId: string;
    config?: GetListApiConfig;
    filters?: GetListApiFilter;
  },
  options: Partial<
    UseQueryOptions<ApiListResponse<IPaymentMethodResp>, unknown, SelectData>
  > = {},
) {
  const queryClient = useQueryClient();
  let params = {};
  if (config) {
    params = composeGetQuery(config, filters);
  }
  const { onSuccess, ...restOptions } = options;
  return useQuery<ApiListResponse<IPaymentMethodResp>, unknown, SelectData>(
    [
      ...accountServiceQueryKeys.paymentMethods.paymentMethodList(accountId),
      params,
    ],
    {
      queryFn: ({ signal }) =>
        apiGet(`/api/accounts/${accountId}/paymentMethods`, {
          params,
          signal,
        }).then((res) => res.data),
      onSuccess: (data) => {
        setByIdCacheFromReturnedList(
          queryClient,
          (id) => [...accountServiceQueryKeys.paymentMethods.paymentMethod(id)],
          data as ApiListResponse<IPaymentMethodResp>,
        );
        onSuccess && onSuccess(data);
      },
      ...restOptions,
    },
  );
}

/**
 * Get payment methods by account where items are grouped for easy lookup
 *
 */
export const useGetPaymentMethodsByAccountWithGroupedItems = ({
  accountId,
  filters,
  filterFn,
}: {
  accountId: string;
  filters: GetListApiFilter;
  filterFn?: (data: IPaymentMethodResp) => boolean;
}) => {
  return useGetPaymentMethodsByAccount<
    ApiListResponse<IPaymentMethodResp> & {
      paymentMethodsByGatewayId: Record<
        string,
        ReturnType<
          typeof getGroupedAndSortedPaymentMethodOptions<IPaymentMethodResp>
        >
      >;
      paymentMethodsById: Record<string, IPaymentMethodResp>;
    }
  >(
    {
      accountId: accountId,
      config: DEFAULT_PAGER,
      filters,
    },
    {
      select: (data) => {
        // Since we do not support a NOT IN query, filter out manually
        const _paymentMethods = data.content.filter((item) =>
          filterFn ? filterFn(item) : true,
        );

        const paymentMethodsByGatewayId = groupBy(
          _paymentMethods,
          'paymentGateway.id',
        );

        return {
          ...data,
          paymentMethodsByGatewayId: Object.keys(
            paymentMethodsByGatewayId,
          ).reduce(
            (
              acc: Record<
                string,
                ReturnType<
                  typeof getGroupedAndSortedPaymentMethodOptions<IPaymentMethodResp>
                >
              >,
              gatewayId,
            ) => {
              acc[gatewayId] = getGroupedAndSortedPaymentMethodOptions(
                paymentMethodsByGatewayId[gatewayId],
              );
              return acc;
            },
            {},
          ),
          paymentMethodsById: arrayToObject(_paymentMethods, 'id'),
        };
      },
    },
  );
};

function doCreatePaymentFromPaymentMethod(
  accountId: string,
  invoiceId: string,
  payload: { paymentMethodId: string; amount: number },
) {
  return apiPost<IPayment>(
    `/api/accounts/${accountId}/payments/invoice/${invoiceId}/pay`,
    payload,
  ).then((res) => res.data);
}

function doCreatePaymentFromAchPaymentMethod(
  invoiceId: string,
  payload: { paymentMethodId: string; amount: number },
) {
  return apiPost<IPayment>(
    `/api/invoices/${invoiceId}/achCreditPayments`,
    payload,
  ).then((res) => res.data);
}

function doCreateManualPayment(
  accountId: string,
  invoiceId: string,
  payload: IManualPayment,
) {
  return apiPost<IPayment>(
    `/api/accounts/${accountId}/payments/invoice/${invoiceId}/pay/manual`,
    payload,
  ).then((res) => res.data);
}

export function useCreateInvoicePayment(
  options: {
    onError?: (err: unknown) => void;
    onSuccess?: (data: IPayment) => void;
  } = {},
) {
  const queryClient = useQueryClient();
  const { onSuccess, ...restOptions } = options;
  return useMutation<IPayment, unknown, CreateInvoicePaymentRequest>({
    mutationFn: ({ type, accountId, invoiceId, payload }) => {
      switch (type) {
        case 'electronic': {
          return doCreatePaymentFromPaymentMethod(
            accountId,
            invoiceId,
            payload,
          );
        }
        case 'achCredit': {
          return doCreatePaymentFromAchPaymentMethod(invoiceId, payload);
        }
        case 'usBankTransfer': {
          return doCreatePaymentFromAchPaymentMethod(invoiceId, payload);
        }
        case 'manual': {
          return doCreateManualPayment(accountId, invoiceId, payload);
        }
      }
    },
    onSuccess: (data, { accountId, invoiceId }) => {
      queryClient.invalidateQueries(['invoices', 'list']);
      queryClient.invalidateQueries(['invoices', invoiceId]);
      queryClient.invalidateQueries([
        ...accountServiceQueryKeys.accounts.invoiceList(accountId),
      ]);
      queryClient.invalidateQueries([
        ...accountServiceQueryKeys.payments.paymentByAccount(accountId),
      ]);
      onSuccess && onSuccess(data);
    },
    ...restOptions,
  });
}

export const doGetCreditNotesForInvoice = async (invoiceId: string) => {
  const res = await apiGet<ApiListResponse<ICreditNoteSchema>>(
    `/api/invoices/${invoiceId}/creditNotes`,
  );
  return res.data;
};

export const doCreateCreditNoteForInvoice = async (
  invoiceId: string,
  data: ICreditNoteReqSchema,
) => {
  const res = await apiPost<ICreditNoteSchema>(
    `/api/invoices/${invoiceId}/creditNotes`,
    data,
  );
  return res.data;
};

export const doDownloadInvoiceAsCsv = async (invoiceId: string) => {
  const res = await apiGet(`/api/invoices/${invoiceId}/exportCsv`);
  return res.data;
};

export const doPrintInvoiceToPdf = async (
  invoiceId: string,
  invoiceNumber: Maybe<string>,
) => {
  const res = await apiGet<ArrayBuffer>(`/api/invoices/${invoiceId}/print`, {
    responseType: 'arraybuffer',
    headers: {
      accept: 'application/pdf',
    },
  });
  return {
    data: res.data,
    fileName: `invoice-${invoiceNumber || invoiceId}.pdf`,
  };
};

export const usePrintInvoiceToHtml = (
  {
    invoiceId,
    lastModifiedTimestamp,
  }: {
    invoiceId: string;
    /** Used for caching response */
    lastModifiedTimestamp?: string;
  },
  options: {
    enabled?: boolean;
    onSuccess?: (data: string) => void;
    onError?: (error: unknown) => void;
  } = {},
) => {
  return useQuery(
    [...invoiceServiceQueryKeys.htmlTemplate(invoiceId), lastModifiedTimestamp],
    {
      queryFn: () =>
        apiGet<string>(`/api/invoices/${invoiceId}/print`, {
          responseType: 'text',
          headers: {
            accept: 'text/html',
          },
        }).then((res) => res.data),
      refetchOnWindowFocus: false,
      retry: false,
      keepPreviousData: true,
      ...options,
    },
  );
};

export const usePrintCreditToHtml = (
  {
    creditId,
    lastModifiedTimestamp,
  }: {
    creditId: string;
    /** Used for caching response */
    lastModifiedTimestamp?: string;
  },
  options: {
    enabled?: boolean;
    onSuccess?: (data: string) => void;
    onError?: (error: unknown) => void;
  } = {},
) => {
  return useQuery(
    [
      ...accountServiceQueryKeys.credits.htmlTemplate(creditId),
      lastModifiedTimestamp,
    ],
    {
      queryFn: () =>
        apiGet<string>(`/api/credits/${creditId}/print`, {
          responseType: 'text',
          headers: {
            accept: 'text/html',
          },
        }).then((res) => res.data),
      refetchOnWindowFocus: false,
      retry: false,
      keepPreviousData: true,
      ...options,
    },
  );
};

export const doPrintCreditToPdf = async (creditId?: string) => {
  const res = await apiGet<ArrayBuffer>(`/api/credits/${creditId}/print`, {
    responseType: 'arraybuffer',
    headers: {
      accept: 'application/pdf',
    },
  });
  return { data: res.data, fileName: `credit-${creditId}.pdf` };
};

export function useGetCreditNotesById(
  creditNoteId: string,
  options?: { onError?: (error: any) => void },
) {
  return useQuery(
    [...accountServiceQueryKeys.creditNotes.creditNoteById(creditNoteId)],
    {
      queryFn: () =>
        apiGet<ICreditNoteSchema>(`/api/creditNotes/${creditNoteId}`).then(
          (res) => res.data,
        ),
      enabled: !!creditNoteId,
      refetchOnWindowFocus: false,
      ...options,
    },
  );
}

export const usePrintCreditNoteToHtml = (
  {
    creditNoteId,
    lastModifiedTimestamp,
  }: {
    creditNoteId: string;
    /** Used for caching response */
    lastModifiedTimestamp?: string;
  },
  options: {
    enabled?: boolean;
    onSuccess?: (data: string) => void;
    onError?: (error: unknown) => void;
  } = {},
) => {
  return useQuery(
    [
      ...accountServiceQueryKeys.creditNotes.htmlTemplate(creditNoteId),
      lastModifiedTimestamp,
    ],
    {
      queryFn: () =>
        apiGet<string>(`/api/creditNotes/${creditNoteId}/print`, {
          responseType: 'text',
          headers: {
            accept: 'text/html',
          },
        }).then((res) => res.data),
      refetchOnWindowFocus: false,
      retry: false,
      keepPreviousData: true,
      ...options,
    },
  );
};

export const doPrintCreditNoteToPdf = async (creditNoteId?: string) => {
  const res = await apiGet<ArrayBuffer>(
    `/api/creditNotes/${creditNoteId}/print`,
    {
      responseType: 'arraybuffer',
      headers: {
        accept: 'application/pdf',
      },
    },
  );
  return { data: res.data, fileName: `creditNote-${creditNoteId}.pdf` };
};

// payment gateways apis

export const doGetGatewayAccountsByAccount = async (
  accountId: string,
  gatewayId: string,
) => {
  const res = await apiGet<IPaymentGatewayAccountSchema>(
    `/api/accounts/${accountId}/paymentGateways/${gatewayId}/gatewayAccount`,
  );
  return res.data;
};

export const doCreatePaymentGatewayByAccount = async (
  accountId: string,
  type: string,
  gatewayId: string,
) => {
  const res = await apiPost<IPaymentGatewayAccountSchema>(
    `/api/accounts/${accountId}/paymentGateways/${gatewayId}/gatewayAccount`,
    {
      gatewayType: type,
    },
  );
  return res.data;
};

export const doCreateOrGetPaymentGatewayByAccount = async (
  accountId: string,
  paymentGatewayType: string,
  paymentGatewayId: string,
) => {
  try {
    const gateway = await doGetGatewayAccountsByAccount(
      accountId,
      paymentGatewayId,
    );
    return gateway;
  } catch (err) {
    const gateway = await doCreatePaymentGatewayByAccount(
      accountId,
      paymentGatewayType,
      paymentGatewayId,
    );
    return gateway;
  }
};

export const doCreateGatewayAccountSetupIntent = async (
  gatewayId: string,
  gatewayAccountId: string,
) => {
  // Swagger shows the incorrect response type for this endpoint: https://monetizenow.atlassian.net/browse/BP-6308
  const res = await apiPost<SetupIntentResponse>(
    `/api/paymentGateways/${gatewayId}/gatewayAccounts/${gatewayAccountId}/setupIntent`,
  );
  return res.data;
};

export const doGetGatewayAccountClientSecret = async (
  gatewayId: string,
  gatewayAccountId: string,
) => {
  const res = await apiGet<PaymentGatewayClientSecretResponse>(
    `/api/paymentGateways/${gatewayId}/gatewayAccounts/${gatewayAccountId}/clientSecret`,
  );
  return res.data;
};

// dunning process apis

export function dunningProcessPath(): string {
  return `/api/dunning/dunningProcess`;
}

// End

export function useSetContactOnInvoice(
  options: {
    onError?: (err: unknown) => void;
    onSuccess?: (data: IInvoiceContactUpdateSchema) => void;
  } = {},
) {
  const { onSuccess, ...restOptions } = options;
  return useMutation<
    IInvoiceContactUpdateSchema,
    unknown,
    {
      invoiceId: string | undefined;
      data: IInvoiceContactUpdateSchema;
    }
  >(
    ({ invoiceId, data }) =>
      apiPut<any>(`/api/invoice/${invoiceId}/contacts`, data).then(
        (res) => res.data,
      ),
    {
      onSuccess: (response) => {
        onSuccess && onSuccess(response);
      },
      ...restOptions,
    },
  );
}

export function useUpdatePoNumberOnQuote(options = {}) {
  return useMutation<
    any,
    unknown,
    {
      quoteId: string;
      poNumber?: string;
    }
  >(
    ({ quoteId, poNumber }) =>
      apiPut(`/api/quotes/${quoteId}/purchaseOrderNumber`, {
        purchaseOrderNumber: poNumber || null,
      }).then((res) => res.data),
    {
      ...options,
    },
  );
}

export function useSendEmail(
  options: {
    onError?: (err: unknown) => void;
    onSuccess?: () => void;
  } = {},
) {
  return useMutation<
    void,
    unknown,
    {
      invoiceId: string;
      overrideCcEmails?: string[] | null;
    }
  >(
    ({ invoiceId, overrideCcEmails }) =>
      apiPost(`/api/invoices/${invoiceId}/sendBillingEmail`, {
        overrideCcEmails,
      }).then((res) => res.data),
    options,
  );
}

export const doPreviewInvoice = async (billGroupId: string) => {
  return await apiGet<ArrayBuffer>(
    `/api/billGroups/${billGroupId}/invoices/preview`,
    {
      responseType: 'arraybuffer',
      headers: {
        accept: 'application/pdf',
      },
    },
  ).then((res) => res.data);
};

export const usePreviewInvoiceJson = <SelectData = IInvoiceRespSchema>(
  { accountId, billGroupId }: { accountId: string; billGroupId: string },
  options: Partial<
    UseQueryOptions<IInvoiceRespSchema, unknown, SelectData>
  > = {},
) => {
  return useQuery<IInvoiceRespSchema, unknown, SelectData>(
    [
      ...accountServiceQueryKeys.accounts.invoicePreview(
        accountId,
        billGroupId,
      ),
    ],
    {
      queryFn: () =>
        apiGet<IInvoiceRespSchema>(
          `/api/billGroups/${billGroupId}/invoices/preview`,
        ).then((res) => res.data),
      ...options,
    },
  );
};

export function useGeneratePastInvoice(
  options: {
    onError?: (err: unknown) => void;
    onSuccess?: () => void;
  } = {},
) {
  return useMutation<
    void,
    unknown,
    {
      accountId: string;
      billGroupId: string;
    }
  >(
    ({ accountId, billGroupId }) =>
      apiPost(
        `/api/accounts/${accountId}/billGroups/${billGroupId}/manualBatchRun`,
      ).then((res) => res.data),
    options,
  );
}

export function useInvokeManualBillRun(
  options: {
    onError?: (err: unknown) => void;
    onSuccess?: () => void;
  } = {},
) {
  return useMutation<
    void,
    unknown,
    {
      billGroupIds: string[];
    }
  >(
    ({ billGroupIds }) =>
      apiPost(`/api/billGroups/manualBatchRun`, billGroupIds).then(
        (res) => res.data,
      ),
    options,
  );
}

export function useSearchForImportablePaymentMethods(
  options?: UseMutationOptions<
    ImportablePaymentMethodResp,
    AxiosError<GenericApiResponse>,
    any
  >,
) {
  return useMutation<
    ImportablePaymentMethodResp,
    AxiosError<GenericApiResponse>,
    { gatewayId: string; gatewayAccountId: string; accountId: string }
  >(
    ({ gatewayId, gatewayAccountId, accountId }) =>
      apiGet<ImportablePaymentMethodResp>(
        `/api/paymentGateways/${gatewayId}/gatewayAccounts/search?gatewayAccountId=${gatewayAccountId}&accountId=${accountId}`,
      ).then((res) => res.data),
    options,
  );
}

export function useImportPaymentMethods(
  options: UseMutationOptions<
    ImportPaymentMethodResp,
    AxiosError<GenericApiResponse>,
    any
  >,
) {
  const { onSuccess, ...rest } = options;
  const queryClient = useQueryClient();

  return useMutation<
    ImportPaymentMethodResp,
    AxiosError<GenericApiResponse>,
    {
      accountId: string;
      gatewayAccountId: string;
      gatewayId: string;
      externalPaymentMethodIds: string[];
    }
  >(
    ({ accountId, gatewayId, gatewayAccountId, externalPaymentMethodIds }) =>
      apiPost<ImportPaymentMethodResp>(
        `/api/accounts/${accountId}/paymentMethods/import`,
        {
          gatewayId,
          gatewayAccountId,
          externalPaymentMethodIds,
        },
      ).then((res) => res.data),

    {
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries([
          ...accountServiceQueryKeys.paymentMethods.paymentMethodList(
            variables.accountId,
          ),
        ]);
        options.onSuccess && options.onSuccess(data, variables, context);
      },
      ...rest,
    },
  );
}

export function useUpdateInvoiceCustomFields(
  options: {
    onError?: (err: unknown) => void;
    onSuccess?: () => void;
  } = {},
) {
  const queryClient = useQueryClient();
  const { onSuccess, ...restOptions } = options;
  return useMutation<
    unknown,
    unknown,
    {
      invoiceId: string;
      customFields: ICustomFieldRecordSchema;
    }
  >(
    ({ invoiceId, customFields }) =>
      apiPut<any>(`/api/invoices/${invoiceId}/customFields`, customFields).then(
        (res) => res.data,
      ),
    {
      onSuccess: (response, { invoiceId }) => {
        queryClient.invalidateQueries([
          ...invoiceServiceQueryKeys.invoiceDetail(invoiceId),
        ]);

        onSuccess && onSuccess();
      },
      ...restOptions,
    },
  );
}
