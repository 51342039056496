import ReadonlyCompanyLogo from '~app/routes/Quotes/Quote/CompanyLogo';
import { useGetById } from '~app/api/queryUtils';
import { MBox, MHeading } from '~app/components/Monetize';
import { LegalEntityLogoUploader } from './components/LegalEntityLogoUploader';
import { useLegalEntityLogo } from '~app/hooks/useLegalEntityLogo';

interface LegalEntityLogoProps {
  isReadOnly: boolean;
  legalEntityId?: string;
  resetErrorState?: boolean;
}
export const LegalEntityLogo = ({
  isReadOnly,
  legalEntityId,
  resetErrorState = false,
}: LegalEntityLogoProps) => {
  const companyLogoData = useLegalEntityLogo({ id: legalEntityId });
  if (isReadOnly && companyLogoData.data) {
    return (
      <>
        <MHeading size="xs">Upload Logo</MHeading>
        <MBox mt={2} mb={4}>
          <ReadonlyCompanyLogo
            legalEntityId={legalEntityId}
            legalEntityLogoId={companyLogoData.data}
          />
        </MBox>
      </>
    );
  }
  return (
    <>
      <MHeading size="xs">Upload Logo</MHeading>
      <MBox mt={2} mb={4}>
        <LegalEntityLogoUploader
          legalEntityId={legalEntityId}
          companyLogoData={companyLogoData}
          resetErrorState={resetErrorState}
        />
      </MBox>
    </>
  );
};
