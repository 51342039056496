import { ColumnProps } from 'primereact/column';
import React, { FunctionComponent as FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { handleApiErrorToast } from '~app/api/axios';
import { useGetListData, usePerformEntityAction } from '~app/api/queryUtils';
import {
  MDataTable,
  MHStack,
  MPageContainer,
  MPageSearchInput,
} from '~app/components/Monetize';
import { MDataTableFilter } from '~app/components/Monetize/DataTable';
import DataTableActions from '~app/components/Monetize/DataTable/MDataTableActions';
import { ExportTableButton } from '~app/components/Monetize/ExportEntityButton';
import { ROUTES } from '~app/constants';
import { getUsageTypeEditRoute } from '~app/constants/routes';
import { USAGE_TYPE_STATUS_DISPLAY } from '~app/constants/usageTypes';
import { useSettingsReadOnlyRule } from '~app/hooks/useSettingsReadOnlyRule';
import {
  tableFilterSelector,
  tablePagerSelector,
} from '~app/store/global.store';
import { FilterStateKeys } from '~app/store/store.types';
import {
  buildFilterParamsRequestObject,
  getFiltersApplied,
  getIsTrulyEmptyList,
} from '~app/utils';
import { objectToObjArray } from '~app/utils/misc';
import {
  idBodyTemplate,
  statusBodyTemplate,
  textBodyTemplate,
} from '~app/utils/tableUtils';
import {
  FilterType,
  FilterTypeOperator,
  GetListApiFilter,
  IUsageType,
  UsageTypeStatusEnum,
} from '~types';
import PageHeader from '../components/PageHeader';
import TableHeader from '../components/TableHeader';

interface IUsageTypeListProps {}

const UsageTypeListPage: FC<IUsageTypeListProps> = () => {
  const navigate = useNavigate();
  const [initialFilters, persistTableFilter] = useRecoilState(
    tableFilterSelector(FilterStateKeys.USAGE_TYPE_LIST),
  );

  const [pager, setPager] = useRecoilState(
    tablePagerSelector(FilterStateKeys.USAGE_TYPE_LIST),
  );

  const [filters, setFilters] = useState<FilterType[]>(initialFilters);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [searchKey] = useState<string>('name');
  const [filterParams, setFilterParams] = useState<GetListApiFilter>(() =>
    buildFilterParamsRequestObject(filters, searchTerm, searchKey),
  );

  useEffect(() => {
    persistTableFilter(filters);
  }, [filters, persistTableFilter]);

  const {
    data: usageTypeList,
    isLoading,
    isFetched,
    isRefetching,
    refetch,
    isError,
    error,
  } = useGetListData<IUsageType>('productCatalogUsageTypes', {
    config: pager,
    filters: filterParams,
  });

  // using mutateAsync so we can show a loading indicator on the menu while action is executing
  const { mutateAsync: doActivateDeactivate } =
    usePerformEntityAction<IUsageType>('productCatalogUsageTypes', {
      onError: (err) => handleApiErrorToast(err),
    });

  const loading = isLoading || (isRefetching && !isFetched);
  const isTrulyEmptyList = getIsTrulyEmptyList({
    loading,
    totalElements: usageTypeList?.totalElements || 0,
    filters,
    searchTerm,
    page: pager.page,
  });

  const onResetFilter = () => {
    setFilters([]);
  };

  useEffect(() => {
    setFilterParams(
      buildFilterParamsRequestObject(filters, searchTerm, searchKey),
    );
  }, [pager, searchTerm, filters, searchKey]);

  const onSelectEdit = (id: string) => {
    navigate(getUsageTypeEditRoute(id));
  };

  const { canCreateSettings } = useSettingsReadOnlyRule();

  const actionBodyTemplate = (rowData: IUsageType) => {
    const { id, name, status } = rowData;
    if (!canCreateSettings) {
      return null;
    }
    const confirmProps = {
      title: `Are you sure you want to deactivate "${name}"?`,
      description:
        'Once deactivated, this usage type will no longer be available',
    };
    const actions = [
      {
        title: 'Edit',
        enabled: true,
        action: () => onSelectEdit(id),
      },
      {
        title: 'Activate',
        enabled: status === UsageTypeStatusEnum.INACTIVE,
        action: () => doActivateDeactivate({ id, action: 'activate' }),
      },
      {
        title: 'Deactivate',
        color: 'danger',
        confirmProps: {
          ...confirmProps,
          yesButton: 'Deactivate',
        },
        enabled: status === UsageTypeStatusEnum.ACTIVE,
        action: () => doActivateDeactivate({ id, action: 'deactivate' }),
      },
    ];
    return <DataTableActions actions={actions} />;
  };

  const columns: ColumnProps[] = [
    {
      className: 'overflow-hidden',
      field: 'id',
      header: 'ID',
      body: idBodyTemplate<IUsageType>('id'),
      style: { width: '10em' },
    },
    {
      className: 'overflow-hidden',
      field: 'name',
      header: 'Name',
      body: textBodyTemplate<IUsageType>('name'),
      sortable: true,
    },
    {
      field: 'description',
      header: 'Description',
      body: textBodyTemplate<IUsageType>('description'),
      sortable: true,
      className: 'table-cell-lg',
    },
    {
      field: 'unitName',
      header: 'Unit Name',
      body: textBodyTemplate<IUsageType>('unitName'),
      sortable: true,
    },
    {
      field: 'status',
      header: 'Status',
      body: statusBodyTemplate<IUsageType, UsageTypeStatusEnum>(
        'status',
        USAGE_TYPE_STATUS_DISPLAY,
      ),
      sortable: true,
    },
    {
      body: actionBodyTemplate,
    },
  ];

  const statusItems = objectToObjArray(
    USAGE_TYPE_STATUS_DISPLAY,
    'value',
    'label',
  );

  const filterOptions = [
    {
      title: 'Status',
      key: 'status',
      operator: FilterTypeOperator.IN,
      items: statusItems,
    },
  ];

  const hasFiltersOrSearchApplied =
    getFiltersApplied(filters) > 0 || !!searchTerm;

  const filterComponentReset = React.useRef<any>(null);
  const searchComponentReset = React.useRef<any>(null);

  return (
    <MPageContainer>
      <PageHeader />
      <TableHeader
        model="Usage Type"
        showCreateNewBtn={!isTrulyEmptyList && canCreateSettings}
      >
        {!isTrulyEmptyList && (
          <MHStack spacing="2">
            <MPageSearchInput
              placeholderKey="Name"
              value={searchTerm}
              onChange={(e: any) => setSearchTerm(e)}
              count={usageTypeList?.totalElements}
              resetSearch={searchComponentReset}
            />
            <MDataTableFilter
              filters={filters}
              filterOptions={filterOptions}
              setFilters={(f) => setFilters && setFilters(f)}
              onResetFilter={onResetFilter}
              resetFilter={filterComponentReset}
            />
            <ExportTableButton
              entity="usageTypes"
              filters={filters}
              searchKey={searchKey}
              searchTerm={searchTerm}
              sortField={pager.sortField}
              sortOrder={pager.sortOrder}
            />
          </MHStack>
        )}
      </TableHeader>

      <MDataTable
        value={usageTypeList?.content}
        totalRecords={usageTypeList?.totalElements}
        totalPages={usageTypeList?.totalPages}
        pager={pager}
        setPager={setPager}
        rowHover
        className="p-datatable-responsive"
        loading={loading}
        columns={columns}
        filtersApplied={hasFiltersOrSearchApplied}
        resetFilter={() => {
          filterComponentReset.current && filterComponentReset.current();
          searchComponentReset.current && searchComponentReset.current();
        }}
        singleEmptyPlaceholder
        emptyFilterResultsProps={{
          mainMessage: 'Looks like there are no usage types here.',
          smallMessage: null,
          btnLabel: 'New Usage Type',
          to: ROUTES.USAGE_TYPE_NEW,
          secondaryBtnLabel: hasFiltersOrSearchApplied
            ? 'Clear filters'
            : undefined,
        }}
        onRowClick={(e) => onSelectEdit(e.data?.id)}
        errorProps={{
          isFetchingError: isError,
          error: error,
          onRefetchData: refetch,
        }}
      />
    </MPageContainer>
  );
};

export default UsageTypeListPage;
