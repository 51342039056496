import { MCenter, MFlex, MText } from '~components/Monetize';

interface AppDocsEmptyStateProps {
  hasSearchQuery: boolean;
}

export const AppDocsEmptyState = ({
  hasSearchQuery,
}: AppDocsEmptyStateProps) => {
  if (hasSearchQuery) {
    return (
      <MCenter mt={6}>
        <MFlex flexDirection="column" alignItems="center">
          <MText fontSize="md" textAlign="center">
            No results found.
          </MText>
          <MText fontSize="sm" textAlign="center">
            Try searching with different keywords.
          </MText>
        </MFlex>
      </MCenter>
    );
  }

  return (
    <MCenter mt={6}>
      <MFlex flexDirection="column" alignItems="center">
        <MText fontSize="md" textAlign="center">
          Start typing to search.
        </MText>
      </MFlex>
    </MCenter>
  );
};
