export const filteredByQuery = (
  items: any[],
  query: string,
  itemNameField: string,
  isSubtitleItem: any,
  internalFilterFields?: string[],
) => {
  if (!query) {
    return items;
  }

  return items.filter((item) => {
    const isObject = typeof item === 'object';

    if (isObject) {
      if (
        Array.isArray(internalFilterFields) &&
        internalFilterFields.length > 0
      ) {
        return internalFilterFields.some((field) => {
          return item[field]?.toLowerCase().indexOf(query.toLowerCase()) !== -1;
        });
      }

      return (
        isSubtitleItem(item) ||
        item[itemNameField]?.toLowerCase().indexOf(query.toLowerCase()) !== -1
      );
    }
    return item?.toLowerCase().indexOf(query.toLowerCase()) !== -1;
  });
};
