import {
  Box,
  BoxProps,
  CircularProgress,
  PopoverProps,
} from '@chakra-ui/react';
import { FC, MouseEventHandler, useState } from 'react';
import { IconType } from 'react-icons/lib';
import { MdMoreVert } from 'react-icons/md';
import { handleApiErrorToast } from '~app/api/axios';
import { useConfirmModal } from '~app/services/confirmModal';
import { IConfirmModalProps } from '~app/types';
import {
  MCustomIconButton,
  MDropdownActionItem,
  MDropdownActions,
  MText,
} from '~components/Monetize/';
import { MBox, MCenter, MIcon } from '~components/Monetize/chakra';
import { MCustomIconButtonProps } from '../MCustomIconButton';

export type ActionType = {
  title?: string;
  icon?: IconType;
  action?: () => any | Promise<void>;
  color?: string;
  enabled?: boolean;
  confirmProps?: Partial<IConfirmModalProps> | null;
  loading?: boolean;
  actionElement?: React.ReactElement;
  isDisabled?: boolean;
  tooltipMsg?: string;
};

interface DataTableActionsProps {
  actions: ActionType[];
  boxProps?: BoxProps;
  iconContainerProps?: Partial<MCustomIconButtonProps>;
  showPlaceholderIfNoItems?: boolean;
  popoverProps?: PopoverProps;
}

export const DataTableActions: FC<DataTableActionsProps> = ({
  actions,
  boxProps,
  iconContainerProps,
  showPlaceholderIfNoItems = false,
  popoverProps = {},
}: DataTableActionsProps) => {
  const { showConfirmModal, setModalLoading, closeModal } = useConfirmModal();
  const [actionLoading, setActionLoading] = useState(false);

  const confirmAction = (
    confirmProps: Partial<IConfirmModalProps>,
    action: () => void,
  ) => {
    const onYes = async () => {
      setModalLoading(true);
      try {
        action && (await action());
      } catch (err) {
        handleApiErrorToast(err);
      }
      setModalLoading(false);
      closeModal();
    };

    showConfirmModal({
      onYes,
      yesBtnProps: {
        variant: 'delete',
      },
      noBtnProps: {
        variant: 'cancel',
      },
      ...confirmProps,
    });
  };

  // if there are no active links hide the dropdown all together
  const activeLinks = actions.filter((a) => a.enabled);
  if (activeLinks.length === 0) {
    return showPlaceholderIfNoItems ? <MBox minW="20px" /> : null;
  }

  return (
    <MDropdownActions
      popOverContentProps={{ minW: '11.625rem' }}
      boxProps={{
        'data-testid': 'data-table-actions',
        ...boxProps,
      }}
      placement="bottom-end"
      renderTrigger={() => (
        <MCustomIconButton
          data-testid="table-dropdown-actions"
          variant="icon"
          className="table-dropdown-actions"
          btnSize={6}
          p={3}
          // FIXME: this ensures that after closing the button does not show a border
          // BUT also means there is no visual indication that the button is focused when using keyboard navigation
          _focus={{ bg: 'none' }}
          {...iconContainerProps}
          icon={MdMoreVert}
        />
      )}
      popoverProps={popoverProps}
    >
      {({ onClose }: any) => (
        <Box position="relative">
          {actions.map(
            ({
              action,
              title,
              icon,
              enabled,
              color,
              confirmProps,
              loading,
              actionElement,
              isDisabled,
              tooltipMsg,
            }) => {
              const handleAction: MouseEventHandler<HTMLDivElement> = (ev) => {
                if (!action) {
                  return;
                }

                const maybePromise = confirmProps
                  ? confirmAction(confirmProps, action)
                  : action();
                // For async actions that don't have a confirmation modal, the menu will stay open with a loading indicator until finished
                if (maybePromise instanceof Promise) {
                  setActionLoading(true);
                  maybePromise.finally(() => {
                    setActionLoading(false);
                    onClose();
                  });
                } else {
                  onClose();
                }
              };

              if (!enabled) {
                return null;
              }
              if (actionElement) {
                return actionElement;
              }

              const textColor =
                color === 'danger' ? 'tRed.base' : 'tPurple.dark';

              return (
                <MDropdownActionItem
                  key={title}
                  onClick={!isDisabled ? handleAction : undefined}
                  colorScheme={textColor}
                  display="flex"
                  alignItems="center"
                  data-testid={`data-table-actions-${title}`}
                  role="group"
                  isDisabled={isDisabled}
                  tooltipMsg={tooltipMsg}
                >
                  {icon && <MIcon as={icon} w={3} h={3} mr={2} />}
                  <MText color="inherit">{title}</MText>
                  {loading && <CircularProgress isIndeterminate size={4} />}
                </MDropdownActionItem>
              );
            },
          )}
          {actionLoading && (
            <Box
              position="absolute"
              top={0}
              left={0}
              width="100%"
              height="100%"
              backgroundColor="white"
              opacity={0.75}
            >
              <MCenter height="100%">
                <CircularProgress isIndeterminate size={8} />
              </MCenter>
            </Box>
          )}
        </Box>
      )}
    </MDropdownActions>
  );
};

export default DataTableActions;
