import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useGetById, useGetListData } from '../../../api/queryUtils';
import { MButton, MCenterModal, MStack } from '../../../components/Monetize';
import { ROUTES } from '../../../constants';
import { logger } from '../../../services/logger';
import {
  IAccountRespSchema,
  IBillGroupResp,
  IOneTimeInvoiceReqSchema,
  OneTimeInvoiceReqSchema,
} from '../../../types';
import { ILegalEntityResponseSchema } from '../../../types/legalEntityTypes';
import { OneTimeInvoiceModalContent } from './OneTimeInvoiceModalContent';

interface OneTimeInvoiceModalProps {
  isOpen: boolean;
  accountDetails: IAccountRespSchema;
  onClose: () => void;
}
export const OneTimeInvoiceModal = ({
  accountDetails,
  isOpen,
  onClose,
}: OneTimeInvoiceModalProps) => {
  const navigate = useNavigate();
  const { data: billGroupsData, isLoading: billGroupsLoading } =
    useGetListData<IBillGroupResp>(
      'billGroups',
      { config: { first: 50, page: 0 } },
      {
        enabled: !!accountDetails?.id,
        endpointArgs: { accountId: accountDetails?.id! },
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        staleTime: 1000 * 60 * 60 * 24,
        cacheTime: 1000 * 60 * 60 * 24,
      },
    );

  const { data: legalEntity } = useGetById<ILegalEntityResponseSchema>(
    'legalEntities',
    accountDetails?.defaultLegalEntityId!,
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 60 * 24,
      cacheTime: 1000 * 60 * 60 * 24,
    },
  );

  const isOnlyOneBillGroup = billGroupsData?.content?.length === 1;
  const {
    watch,
    control,
    formState: { isValid },
    handleSubmit,
  } = useForm<IOneTimeInvoiceReqSchema>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: zodResolver(OneTimeInvoiceReqSchema),
    defaultValues: {
      accountId: accountDetails.id,
      currency: accountDetails.defaultCurrency,
      legalEntityId: accountDetails.defaultLegalEntityId,
      billGroupId: isOnlyOneBillGroup
        ? billGroupsData.content[0].id
        : undefined,
    },
    values: isOnlyOneBillGroup
      ? {
          accountId: accountDetails.id,
          currency: accountDetails.defaultCurrency,
          legalEntityId: accountDetails.defaultLegalEntityId,
          billGroupId: billGroupsData.content[0].id,
        }
      : undefined,
  });

  const onSubmit = ({
    accountId,
    billGroupId,
    currency,
    legalEntityId,
  }: IOneTimeInvoiceReqSchema) => {
    const billGroup = billGroupsData?.content.find(
      (billgroup) => billgroup.id === billGroupId,
    );
    // TODO: this is an error case, we should handle it
    if (!billGroup) {
      return;
    }
    onClose();
    navigate(
      ROUTES.getOneTimeInvoiceCreateRoute(accountId, {
        billGroupId,
        currency,
        legalEntityId,
        addressSource: billGroup.addressSource as any, // FIXME: al addressSource should be from same enum
        billingContactId: billGroup.billingContactId,
        shippingContactId: billGroup.shippingContactId,
        billingAddressId: billGroup.billingAddress?.id,
        shippingAddressId: billGroup.shippingAddress?.id,
        netTerms: billGroup.netTerms,
        purchaseOrderNumber: billGroup.purchaseOrderNumber,
        // TODO: vat/registration number,
      }),
    );
  };

  const onError = (error: any) => {
    logger.error('Error while creating one time invoice =======>', error);
  };

  return (
    <MCenterModal
      onClose={onClose}
      isOpen={isOpen}
      modalTitle="New One-Time Invoice"
      modalFooterProps={{
        py: 4,
      }}
      renderFooter={() => (
        <MStack
          spacing={4}
          direction="row"
          align="center"
          justify="right"
          flex={1}
        >
          <MButton variant="cancel" size="sm" onClick={onClose}>
            Cancel
          </MButton>
          <MButton
            variant="primary"
            size="sm"
            isDisabled={!isValid}
            onClick={handleSubmit(onSubmit, onError)}
          >
            Create
          </MButton>
        </MStack>
      )}
    >
      <OneTimeInvoiceModalContent
        legalEntity={legalEntity}
        billGroups={billGroupsData?.content || []}
        billGroupsLoading={billGroupsLoading}
        accountDetails={accountDetails}
        control={control}
      />
    </MCenterModal>
  );
};
