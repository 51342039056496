import { ALL_CURRENCIES, CurrencyCodes } from '~app/constants/currencies';
import { FilterTypeEqual, RenderTableFilterOptionProps } from '~app/types';
import { MFlex } from '../../chakra';
import { MainSearchInput } from '../../MCustomSelect/components/MainSearchInput';
import { MCustomSelect } from '../../MCustomSelect/MCustomSelect';
import MText from '../../MText';

export const CurrencyTableFilterOptionContent = (
  props: RenderTableFilterOptionProps,
) => {
  const { filter, filterOption, handleFilterChange } = props;
  const internalFilter = filter as FilterTypeEqual;

  return (
    <MCustomSelect
      clearable
      useMainInputAsSearchInput
      itemTitle="code"
      itemValue="code"
      displayAvatar
      endpoint={`/api/currencies`}
      MainInputComponent={MainSearchInput}
      renderItemContent={({ item, isSelected }) => {
        return (
          <MFlex alignItems="center">
            <MText fontWeight="700" mr="2">
              {item.code}
            </MText>
            <MText fontWeight="normal">
              {ALL_CURRENCIES[item.code as CurrencyCodes]?.name}
            </MText>
          </MFlex>
        );
      }}
      value={internalFilter?.value || ''}
      onChange={(val: any) => {
        handleFilterChange(val, filterOption);
      }}
    />
  );
};
