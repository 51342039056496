import { List, ListItem } from '@chakra-ui/react';
import { handleApiErrorToast } from '../../../../api/axios';
import { useMassEmailCustomerInvoices } from '../../../../api/invoiceService';
import { MCenterModal, MFlex, MText } from '../../../../components/Monetize';
import { InvoiceSummaryResp } from '../../../../types';
import { formatInteger, pluralize } from '../../../../utils';
import { useMassInvoiceAction } from '../useMassInvoiceAction';
import { EmailListItemContent } from './EmailListItemContent';
import { MassEmailModalFooter } from './MassEmailModalFooter';

interface InvoiceManagementMassEmailModalProps {
  selectedRows: InvoiceSummaryResp[];
  /**
   * Used to determine correct order of selected rows
   */
  visibleRows: InvoiceSummaryResp[];
  onClose: () => void;
}

export const InvoiceManagementMassEmailModal = ({
  selectedRows: selectedRowsUnordered,
  visibleRows,
  onClose,
}: InvoiceManagementMassEmailModalProps) => {
  const { mutate: doMassEmailCustomerInvoices, isLoading } =
    useMassEmailCustomerInvoices({
      onProgress: (progress) => onProgress(progress),
      onSuccess: (results) => showCompletionToast(results),
      onError: (err) => handleApiErrorToast(err),
    });

  const {
    abortController,
    initiateSubmit,
    hasSubmitted,
    onProgress,
    progress,
    rows,
    showCompletionToast,
  } = useMassInvoiceAction({
    selectedRows: selectedRowsUnordered,
    visibleRows,
    isLoading,
    verbiage: {
      successTitle: 'Success',
      successSingularMessage: 'Emailed 1 invoice',
      successSuffix: 'sent',
      failureTitle: 'Sending Email Failed',
      failureSingularMessage: 'Failed to send email',
      failureSuffix: 'failed to send',
      partialFailureTitle: 'Sending Email Partially Failed',
      partialFailureSuffix: 'failed to send',
    },
  });

  function handleSubmit() {
    const uniqueEmailAddressCount = new Set(
      rows.flatMap((row) => [
        ...row.billGroup.ccEmails,
        row.billingContact.email,
      ]),
    ).size;
    initiateSubmit({
      data: rows,
      callback: (newAbortController) => {
        doMassEmailCustomerInvoices({
          abortSignal: newAbortController.signal,
          invoiceIds: rows.map((row) => row.id),
        });
      },
      confirm: {
        title: `Send ${formatInteger(rows.length)} ${pluralize(
          'Email',
          rows.length,
        )}?`,
        content: (
          <MFlex flexDirection="column">
            <MText>
              You are about to send {pluralize('email', rows.length)} for{' '}
              {formatInteger(rows.length)} {pluralize('invoice', rows.length)}{' '}
              to {formatInteger(uniqueEmailAddressCount)} unique{' '}
              {pluralize('recipient', uniqueEmailAddressCount)}.
            </MText>
            <MText pt={1}>
              One email is sent for each invoice, but some emails may have
              multiple recipients.
            </MText>
          </MFlex>
        ),
        yesButton: `Send ${pluralize('Email', rows.length)}`,
      },
    });
  }

  function handleCancel() {
    if (isLoading) {
      abortController.abort();
    } else {
      onClose();
    }
  }

  return (
    <MCenterModal
      blockScrollOnMount
      closeOnEsc={!isLoading}
      closeOnOverlayClick={!isLoading}
      showCloseButton={!isLoading}
      modalTitle={`You are about to send email for ${formatInteger(
        rows.length,
      )} ${pluralize('invoice', rows.length)}`}
      renderFooter={() => (
        <MassEmailModalFooter
          success={progress?.success}
          failure={progress?.failure}
          isLoading={isLoading}
          hasSubmitted={hasSubmitted}
          onCancel={handleCancel}
          onSubmit={handleSubmit}
        />
      )}
      isOpen
      onClose={onClose}
    >
      <List px={2}>
        {rows.map((row, i) => (
          <ListItem
            key={row.id}
            color="tPurple.dark"
            fontSize={12}
            borderBottom={i !== rows.length - 1 ? '1px' : '0'}
            borderBottomColor="tGray.back"
            py="1"
          >
            <EmailListItemContent
              row={row}
              result={progress?.resultsById[row.id]}
              isLoading={isLoading}
            />
          </ListItem>
        ))}
      </List>
    </MCenterModal>
  );
};
