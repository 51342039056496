import { CustomFieldDataForm } from '~app/components/CustomFields/CustomFieldDataForm';
import { CustomFieldEntityEnum, ICustomFieldRecordSchema } from '~app/types';

interface OneTimeInvoiceCustomFieldRowProps {
  isReadOnly?: boolean;
  customFields: ICustomFieldRecordSchema;
  updateValue: (val: ICustomFieldRecordSchema) => void;
}

export const OneTimeInvoiceCustomFieldRow = ({
  isReadOnly = false,
  customFields,
  updateValue,
}: OneTimeInvoiceCustomFieldRowProps) => {
  return (
    <CustomFieldDataForm
      entity={CustomFieldEntityEnum.INVOICE}
      value={customFields}
      setValue={updateValue}
      mode="accordion"
      mt="4"
      fieldColSpan={4}
      isReadOnly={isReadOnly}
      showDivider
    />
  );
};
