import { loadStripe } from '@stripe/stripe-js';
import { FunctionComponent as FC, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { handleApiErrorToast } from '~app/api/axios';
import { useInvoicePaymentForInvoiceShare } from '~app/api/shareService';
import { ROUTES } from '~app/constants';
import { useQuery } from '~app/hooks';
import { tempSharedInvoicePaymentState } from '~app/store/global.store';
import { ISharedInvoicePaymentReq } from '~app/types';
import { processClientSecretId } from '~app/utils/stripe';
import AppLoading from '~components/App/AppLoading';

// this component only used if Stripe determines the payment requires
// a callback, otherwise we handle the payment response in SharedInvoiceFormFields
export const StripePaymentCallback: FC = () => {
  const params = useParams();
  const tenantId = params.tenantId || '';
  const invoiceSecretId = params.invoiceSecretId || '';
  const query = useQuery();
  const navigate = useNavigate();

  const [sharedInvoicePayment, setSharedInvoicePayment] = useRecoilState(
    tempSharedInvoicePaymentState,
  );
  const { mutateAsync: doCreateSharedInvoicePayment } =
    useInvoicePaymentForInvoiceShare();

  const clientSecretId = query.get('setup_intent_client_secret');

  useEffect(() => {
    const run = async () => {
      if (!sharedInvoicePayment) {
        return;
      }

      try {
        const stripe = await loadStripe(sharedInvoicePayment.stripePublicKey);

        if (!stripe) {
          throw new Error('Sorry, failed to load stripe.');
        }

        if (!clientSecretId) {
          throw new Error('Sorry, failed to connect.');
        }

        const { setupIntent, message } = await processClientSecretId(
          stripe,
          clientSecretId,
        );

        const newPayment: ISharedInvoicePaymentReq = {
          amount: sharedInvoicePayment.amount,
          paymentToken: setupIntent.id,
          paymentMethodName:
            sharedInvoicePayment.billingDetails.paymentMethodName ||
            sharedInvoicePayment.billingDetails.fullName, // the paymentMethodName property is not returned in the API response
          description: '',
          billingDetails: sharedInvoicePayment.billingDetails,
        };
        await doCreateSharedInvoicePayment({
          invoiceSecretId,
          tenantId,
          body: newPayment,
        });

        setSharedInvoicePayment(null);
      } catch (error) {
        handleApiErrorToast(error);
      }

      // Redirect to the invoice page
      navigate(ROUTES.getInvoiceShareRoute(tenantId, invoiceSecretId), {});
    };

    run();
  }, [sharedInvoicePayment, clientSecretId]);

  return <AppLoading />;
};
