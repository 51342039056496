import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const thick = defineStyle({
  borderWidth: '2px',
  borderStyle: 'solid',
  borderColor: 'tPurple.linkWater',
});

export const dividerTheme = defineStyleConfig({
  variants: {
    thick,
  },
});

export default dividerTheme;
