import { MdInfo } from 'react-icons/md';
import { MBox, MFlex, MIcon, MText, MTooltip } from '~app/components/Monetize';
import { IQuoteOfferingRespSchema } from '~app/types';
import { toDateShort } from '~app/utils/dates';

export interface QuoteOfferingDateProps {
  isChildOffering: boolean;
  /** Amendment or contract start date - used to determine if startDate was modified */
  quoteStartDate?: string;
  quoteEndDate?: string;
  startDate?: string;
  endDate?: string;
  isLastSegment?: boolean;
  isOfferingOneTime?: boolean;
  isOpen: boolean;
  childOfferingCount?: number;
  childQuoteOfferings: IQuoteOfferingRespSchema[];
  offeringEndDate: string;
  isOfferingEndBeforeContract: boolean;
}

export const QuoteOfferingDate = ({
  isChildOffering,
  quoteStartDate,
  quoteEndDate,
  startDate,
  endDate,
  isOpen,
  isLastSegment = false,
  childOfferingCount = 0,

  isOfferingOneTime = false,
  childQuoteOfferings = [],
  offeringEndDate,
  isOfferingEndBeforeContract,
}: QuoteOfferingDateProps) => {
  if (!quoteStartDate || !startDate || !endDate) {
    return <MBox />;
  }

  const isStartDateModified = startDate !== quoteStartDate;

  return (
    <MFlex
      alignItems="center"
      whiteSpace="pre"
      fontSize="sm"
      fontWeight="normal"
      color="tPurple.dark"
      minH="8"
    >
      <MText
        fontWeight={!isChildOffering && isStartDateModified ? '600' : '400'}
        textDecoration={
          !isChildOffering && isStartDateModified ? 'underline' : 'initial'
        }
      >
        {toDateShort(startDate)}
      </MText>
      {!isOfferingOneTime && (
        <>
          <MBox as="span" mx={1}>
            -
          </MBox>
          {toDateShort(offeringEndDate)}
          {isOfferingEndBeforeContract && (
            <MTooltip
              label="Offering ends before end of Contract"
              placement="bottom-start"
              size="md"
            >
              <MFlex alignItems="center" ml="2">
                <MIcon as={MdInfo} color="tOrange.tangerine" />
              </MFlex>
            </MTooltip>
          )}
        </>
      )}
    </MFlex>
  );
};

export default QuoteOfferingDate;
