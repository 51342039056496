import { Card, CardBody, CardHeader } from '@chakra-ui/react';
import { FC } from 'react';
import { MCloseButton, MFlex, MText } from '~app/components/Monetize';
import { SETTING_CARD_LOGOS } from '~app/constants/settings';
import { SettingCardType } from '~app/types';

interface SettingCardEditProps {
  type: SettingCardType;
  showCloseButton?: boolean;
  handleCancel?: () => void;
  children: React.ReactNode;
}

export const SettingCardEdit: FC<SettingCardEditProps> = ({
  type,
  showCloseButton = false,
  handleCancel = () => {},
  children,
}) => {
  const Logo = SETTING_CARD_LOGOS[type];

  return (
    <Card variant="edit" w="37.5rem">
      <CardHeader>
        <MFlex justifyContent="space-between" alignItems="center">
          <MFlex alignItems="center">
            <Logo />
            <MText mx={2} fontSize="14px">
              {type}
            </MText>
          </MFlex>
          {showCloseButton && <MCloseButton onClick={handleCancel} />}
        </MFlex>
      </CardHeader>
      <CardBody>{children}</CardBody>
    </Card>
  );
};
