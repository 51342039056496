import { Link } from 'react-router-dom';
import { MLink, MTag } from '~app/components/Monetize';

export const MParentLink = ({
  parentLink,
  parentLinkTitle,
}: {
  parentLink?: string;
  parentLinkTitle?: string;
}) => {
  if (!(parentLink && parentLinkTitle)) {
    return null;
  }

  return (
    <MLink
      fontSize="sm"
      fontWeight="normal"
      to={parentLink}
      as={Link}
      _hover={{
        textDecoration: 'underline',
      }}
    >
      {parentLinkTitle}
    </MLink>
  );
};
