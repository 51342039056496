import { IAddressSchema } from '~app/types';
import { MCustomSelect, MFlex, MText, MTooltip } from '../Monetize';
import { Highlighter } from '../Monetize/MPageSearchInput/Highlighter';
import { MCustomSelectProps } from '~app/types/mCustomSelectTypes';
import React, { useEffect, useState } from 'react';
import { toTitleCase } from '~app/utils';
import { MainSearchInputV2 } from '../Monetize/MCustomSelect/components/MainSearchInputV2';
import { useGetAccountById } from '~app/api/accountsService';
import { getAddress } from '~app/utils/address';
import { LegalEntityAddressFormatEnum } from '~app/types/legalEntityTypes';

interface IAddressWithType {
  type: 'billing' | 'shipping';
  fullAddress: string;
  address: IAddressSchema;
  id: string;
}
export const AccountAddressSelectRenderItem = ({
  item,
  query,
}: {
  item: IAddressWithType;
  query: string;
}) => {
  const { address, fullAddress } = item;
  return (
    <MFlex
      direction="column"
      overflow="hidden"
      whiteSpace="nowrap"
      textOverflow="ellipsis"
      w="100%"
      textAlign="right"
    >
      {!address ? (
        <MText fontWeight="medium" fontStyle="italic">
          No Address Listed
        </MText>
      ) : (
        <Highlighter
          title={fullAddress}
          color="tPurple.dark"
          fontSize="sm"
          fontWeight="medium"
          textToHighlight={fullAddress}
          searchWords={[query]}
          whiteSpace="normal"
        />
      )}

      <MText fontSize="xs" color="tGray.darkPurple">
        Account {toTitleCase((item as IAddressWithType).type)} Address
      </MText>
    </MFlex>
  );
};

interface AccountAddressSelectProps extends MCustomSelectProps {
  accountId: string;
  addressFormat?: LegalEntityAddressFormatEnum | null;
  isReadOnly?: boolean;
  trucatedReadonly?: boolean;
}

export const AccountAddressSelect = React.forwardRef<
  any,
  AccountAddressSelectProps
>(
  (
    {
      accountId,
      addressFormat = LegalEntityAddressFormatEnum.US_FORMAT,
      onChange,
      value,
      isReadOnly,
      trucatedReadonly,
      inputProps,
      popOverContentProps,
      ...rest
    }: AccountAddressSelectProps,
    ref,
  ) => {
    const { data: accountDetails, isLoading: isAccountLoading } =
      useGetAccountById(accountId, {
        enabled: !!accountId,
        refetchOnWindowFocus: false,
      });
    const [query, setQuery] = useState('');

    const [addresses, setAddresses] = useState<
      {
        type: string;
        id: string;
        address?: IAddressSchema | null;
        fullAddress: string;
      }[]
    >([]);
    useEffect(() => {
      setAddresses(
        [
          { type: 'billing', address: accountDetails?.billingAddress },
          { type: 'shipping', address: accountDetails?.shippingAddress },
        ].map(({ type, address }) => ({
          address,
          id: address?.id || type,
          type,
          fullAddress: getAddress(address, { addressFormat }).fullAddress,
        })),
      );
    }, [accountDetails, addressFormat]);

    const disabledItems = addresses
      .filter((addr) => !addr.address)
      .map((addr) => addr.id);

    const [iAddresses, setIAddresses] = useState(addresses);
    useEffect(() => {
      if (query.trim()) {
        setIAddresses(
          addresses?.filter(({ fullAddress }) =>
            fullAddress.toLowerCase().includes(query.toLowerCase()),
          ),
        );
      } else {
        setIAddresses(addresses);
      }
    }, [addresses, query]);

    if (isReadOnly) {
      const address = addresses.find((addr) => addr.id === value);
      if (address) {
        return (
          <>
            {trucatedReadonly ? (
              <MTooltip label={address.fullAddress}>
                <MText noOfLines={1} isTruncated whiteSpace={'initial'}>
                  {address.fullAddress}
                </MText>
              </MTooltip>
            ) : (
              <MText>{address.fullAddress}</MText>
            )}

            <MText fontSize="xs" color="tGray.darkPurple">
              Account {toTitleCase(address.type)} Address
            </MText>
          </>
        );
      }

      return null;
    }

    return (
      <MCustomSelect
        ref={ref}
        onChange={onChange}
        isLazy
        useMainInputAsSearchInput
        MainInputComponent={MainSearchInputV2}
        placeholder="Select"
        itemTitle="fullAddress"
        itemValue="id"
        items={iAddresses}
        loading={isAccountLoading}
        useHighlightedItemList
        hideHighlightSearchResultsCount
        showItemDivider
        inputProps={{
          autoComplete: 'off',
          ...inputProps,
        }}
        useExternalQuery
        externalQuery={query}
        onChangeQuery={setQuery}
        skipFilter={true}
        renderItemContent={AccountAddressSelectRenderItem}
        value={value}
        disabledItems={disabledItems}
        popOverContentProps={{
          width: '100%',
          minWidth: '20rem',
          ...popOverContentProps,
        }}
        {...rest}
      />
    );
  },
);
