import React from 'react';

export const RevenueIcon = (props: any) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.06 10.69L12 9.75L14.06 8.81L15 6.75L15.94 8.81L18 9.75L15.94 10.69L15 12.75L14.06 10.69ZM4 14.75L4.94 12.69L7 11.75L4.94 10.81L4 8.75L3.06 10.81L1 11.75L3.06 12.69L4 14.75ZM4.5 21.25L10.5 15.24L14.5 19.24L23 9.68L21.59 8.27L14.5 16.24L10.5 12.24L3 19.75L4.5 21.25Z"
      fill="currentColor"
    />
    <path
      d="M8.75 9.5C7.23406 9.5 6 8.26594 6 6.75C6 5.23406 7.23406 4 8.75 4C10.2659 4 11.5 5.23406 11.5 6.75C11.5 8.26594 10.2659 9.5 8.75 9.5ZM9.05594 6.44063C8.44406 6.23781 8.14844 6.11063 8.14844 5.7875C8.14844 5.43687 8.53 5.30969 8.77063 5.30969C9.22094 5.30969 9.38594 5.65 9.42375 5.77031L9.96688 5.54C9.91531 5.38875 9.685 4.88344 9.0525 4.77344V4.34375H8.45094V4.77688C7.55719 4.96938 7.55031 5.75656 7.55031 5.79438C7.55031 6.57469 8.32375 6.79469 8.70187 6.93219C9.245 7.12469 9.48563 7.3 9.48563 7.63C9.48563 8.01844 9.12469 8.18344 8.805 8.18344C8.17937 8.18344 8.00063 7.54062 7.98 7.465L7.40938 7.69531C7.62594 8.44812 8.19313 8.65094 8.4475 8.71281V9.15625H9.04906V8.73C9.22781 8.69906 10.0872 8.52719 10.0872 7.62313C10.0906 7.14531 9.88094 6.72594 9.05594 6.44063Z"
      fill="currentColor"
    />
  </svg>
);
