import { Box, BoxProps, Icon, Tooltip } from '@chakra-ui/react';
import { FC } from 'react';
import { MdKeyboardArrowLeft } from 'react-icons/md';

interface MBackButtonProps {
  onClick?: () => void;
  backButtonTitle?: string;
  containerProps?: BoxProps;
}

export const MBackButton: FC<MBackButtonProps> = ({
  onClick,
  backButtonTitle,
  containerProps,
}: MBackButtonProps) => {
  return (
    <Box onClick={onClick} data-testid="pageHeader-back" {...containerProps}>
      <Tooltip shouldWrapChildren label={backButtonTitle}>
        <Icon
          verticalAlign="middle"
          as={MdKeyboardArrowLeft}
          w={6}
          h={6}
          color="tGray.darkPurple"
          cursor="pointer"
          _hover={{ color: 'tPurple.base' }}
        />
      </Tooltip>
    </Box>
  );
};
