import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const variantPrimary = defineStyle({
  borderColor: 'tIndigo.base',
});

export const spinnerTheme = defineStyleConfig({
  variants: {
    primary: variantPrimary,
  },
});

export default spinnerTheme;
