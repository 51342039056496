import { DividerProps, GridProps, TextProps } from '@chakra-ui/react';
import { FunctionComponent, PropsWithChildren } from 'react';
import { MDivider, MGrid, MText } from '../Monetize';
import { MTextProps } from '../Monetize/MText';

export interface FooterRowProps {
  name?: string;
  containerProps?: GridProps & { 'data-testid'?: string };
  nameProps?: MTextProps;
  childrenProps?: MTextProps & { 'data-testid'?: string };
  py?: string | number;
  bgColor?: string;
  value: string;
  dividerProps?: DividerProps;
  extraValues?: { value: string; styles?: MTextProps }[];
}

export const FooterRow: FunctionComponent<FooterRowProps> = ({
  name,
  containerProps,
  nameProps,
  childrenProps,

  py,
  bgColor,
  value,
  dividerProps,
  extraValues = [],
}: PropsWithChildren<FooterRowProps>) => {
  const nameStyles: TextProps = {
    py: py || 4,
    bgColor,
    pl: 4,
    textAlign: 'left',
    fontWeight: 'semibold',
    fontSize: 'inherit',
    ...nameProps,
  };
  const childrenStyles: TextProps = {
    py: py || 4,
    pr: 4,
    fontWeight: 'semibold',
    textAlign: 'right',
    bgColor: bgColor,
    fontSize: 'inherit',
    ...childrenProps,
  };

  const gridTemplateColumns = `1fr repeat(${1 + extraValues.length}, 1fr)`;

  return (
    <MGrid gridTemplateColumns={gridTemplateColumns} {...containerProps}>
      <MText {...nameStyles}>{name || ''}</MText>
      {extraValues?.map((val, index) => (
        <MText {...childrenStyles} {...val.styles} key={index}>
          {val.value}
        </MText>
      ))}
      <MText {...childrenStyles}>{value}</MText>

      {dividerProps?.display !== 'none' && (
        <MDivider gridColumn="1/-1" {...dividerProps} />
      )}
    </MGrid>
  );
};
