import { useEffect, useState } from 'react';
import { useGetById, useGetListData } from '~app/api/queryUtils';
import { doTestTaxConnection } from '~app/api/settingsService';
import {
  MBox,
  MDivider,
  MFlex,
  MPageContainer,
  MSettingsPageHeader,
  MSkeleton,
  MText,
} from '~app/components/Monetize';
import { TAX_PROVIDER_DISPLAY } from '~app/constants/taxes';
import { useToast } from '~app/services/toast';
import {
  ITaxConnectionBaseSchema,
  ITaxConnectionSchema,
  TaxProviderTypeEnum,
  TaxStatusEnum,
} from '~app/types/taxTypes';
import { useDocumentHead } from '~services/documentHead';
import { parseMessageFromError } from '../../../../api/axios';
import { useFlags } from '../../../../services/launchDarkly';
import { TaxCardEdit } from './components/TaxCardEdit';
import { TaxCardEmpty } from './components/TaxCardEmpty';
import { AnrokForm } from './components/anrok/AnrokForm';
import { AnrokTaxConnection } from './components/anrok/AnrokTaxConnection';
import { AvalaraForm } from './components/avalara/AvalaraForm';
import { AvalaraTaxConnection } from './components/avalara/AvalaraTaxConnection';

export const TaxConnections = () => {
  const { taxAvalara } = useFlags();
  const [newConnectionType, setNewConnectionType] =
    useState<TaxProviderTypeEnum | null>(null);
  const { data: taxConnections, isLoading } = useGetListData<
    ITaxConnectionBaseSchema[],
    ITaxConnectionBaseSchema[]
  >('tax');
  const isAnrokConnectionEnabled = taxConnections?.find(
    ({ provider }) => provider === TaxProviderTypeEnum.ANROK,
  );
  const isAvalaraConnectionEnabled = taxConnections?.find(
    ({ provider }) => provider === TaxProviderTypeEnum.AVALARA,
  );
  const { data: anrokConnection, isLoading: isAnrokConnectionLoading } =
    useGetById<ITaxConnectionSchema>('tax', TaxProviderTypeEnum.ANROK, {
      enabled: !isLoading && !!isAnrokConnectionEnabled,
    });

  const { data: avalaraConnection, isLoading: isAvalaraConnectionLoading } =
    useGetById<ITaxConnectionSchema>('tax', TaxProviderTypeEnum.AVALARA, {
      enabled: !isLoading && !!isAvalaraConnectionEnabled,
    });
  const isAnyConnectionActive =
    anrokConnection?.status === TaxStatusEnum.ACTIVE ||
    avalaraConnection?.status === TaxStatusEnum.ACTIVE;
  const isAllTaxConfigured = !!(anrokConnection && avalaraConnection);
  const { addToast } = useToast();
  const { setDocTitle } = useDocumentHead();

  useEffect(() => {
    setDocTitle('Settings', 'Tax');
  }, []);

  const verifyConnection = async (provider: TaxProviderTypeEnum) => {
    try {
      await doTestTaxConnection({
        provider,
        credentials: null,
      });
      const providerName = TAX_PROVIDER_DISPLAY[provider];

      addToast({
        summary: 'Connection Verified',
        detail: `Your connection to ${providerName} is configured correctly.`,
        severity: 'success',
      });
    } catch (ex) {
      const { summary, detail } = parseMessageFromError(ex);
      addToast({
        summary,
        detail,
        severity: 'error',
      });
    }
  };

  return (
    <MPageContainer alignItems="stretch">
      <MSettingsPageHeader title="Tax" />

      <MBox maxW="600px">
        <MText color="tGray.darkPurple" mb={4}>
          {!newConnectionType &&
            `Connect your tax processor and manage how you will handle taxation
          during billing.`}
          {newConnectionType === TaxProviderTypeEnum.ANROK &&
            `Connect to Anrok to sync taxation data to MonetizeNow.`}
          {newConnectionType === TaxProviderTypeEnum.AVALARA &&
            `Connect to Avalara to sync taxation data to MonetizeNow.`}
        </MText>
        {isLoading ||
        (isAnrokConnectionEnabled && isAnrokConnectionLoading) ||
        (isAvalaraConnectionEnabled && isAvalaraConnectionLoading) ? (
          <MSkeleton height={36} w="full" />
        ) : (
          <MBox>
            {/* NO CONNECTION */}
            {avalaraConnection && !newConnectionType && (
              <MBox mb="4">
                <AvalaraTaxConnection
                  connection={avalaraConnection}
                  verifyConnection={verifyConnection}
                  isAvalaraConnectionLoading={isAvalaraConnectionLoading}
                />
              </MBox>
            )}

            {anrokConnection && !newConnectionType && (
              <AnrokTaxConnection
                connection={anrokConnection}
                verifyConnection={verifyConnection}
              />
            )}

            {/* NEW AVALARA CONNECTION */}
            {newConnectionType === TaxProviderTypeEnum.AVALARA && (
              <TaxCardEdit type={TaxProviderTypeEnum.AVALARA}>
                <AvalaraForm handleCancel={() => setNewConnectionType(null)} />
              </TaxCardEdit>
            )}

            {/* NEW ANROK CONNECTION */}
            {newConnectionType === TaxProviderTypeEnum.ANROK && (
              <TaxCardEdit type={TaxProviderTypeEnum.ANROK}>
                <AnrokForm handleCancel={() => setNewConnectionType(null)} />
              </TaxCardEdit>
            )}

            {isAnyConnectionActive && !isAllTaxConfigured && (
              <>
                <MDivider my={4} />
                <MBox mt={4}>
                  <MText fontWeight="600" fontSize="md">
                    Other Providers
                  </MText>
                  <MText>
                    In order to connect to another provider, you must disable
                    your current connection.
                  </MText>
                </MBox>
              </>
            )}

            {!newConnectionType && (
              <MFlex gap={6} mt={4}>
                {!avalaraConnection && taxAvalara && (
                  <TaxCardEmpty
                    isDisabled={isAnyConnectionActive}
                    type={TaxProviderTypeEnum.AVALARA}
                    onClick={() =>
                      setNewConnectionType(TaxProviderTypeEnum.AVALARA)
                    }
                  />
                )}
                {!anrokConnection && (
                  <TaxCardEmpty
                    isDisabled={isAnyConnectionActive}
                    type={TaxProviderTypeEnum.ANROK}
                    onClick={() =>
                      setNewConnectionType(TaxProviderTypeEnum.ANROK)
                    }
                  />
                )}
              </MFlex>
            )}
          </MBox>
        )}
      </MBox>
    </MPageContainer>
  );
};
