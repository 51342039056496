import { useState } from 'react';

export type RecordState<K extends string | number, V> = Record<K, V>;

export const useRecordState = <K extends string | number, V>(
  initialState: RecordState<K, V>,
) => {
  const [state, setState] = useState<RecordState<K, V>>(initialState);

  const addItem = (key: K, value: V) => {
    setState((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const removeItem = (key: K) => {
    const newState = { ...state };
    delete newState[key];
    setState(newState);
  };

  return { state, addItem, removeItem };
};
