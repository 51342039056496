import {
  CustomFieldTypeEnum,
  GetListApiConfig,
  GuidedQuotingStatusEnum,
  QuestionComparatorEnum,
  QuestionCompareFromEnum,
  QuestionFilterByEnum,
  QuestionTypesEnum,
  QuoteSourceFieldName,
} from '../types';
import { CUSTOM_SELECT_SUBTITLE_TYPE, OPTION_TYPE_FIELD } from './customSelect';
import { RATE_BILLING_FREQUENCY_DISPLAY } from './offerings';

export const GuidedQuotingStatusDisplayText: Record<string, string> = {
  [GuidedQuotingStatusEnum.ACTIVE]: 'Active',
  [GuidedQuotingStatusEnum.INACTIVE]: 'Inactive',
};

export const listApiConfig: GetListApiConfig = {
  first: 0,
  rows: 25,
  page: 0,
  sortField: 'name',
  sortOrder: 1,
};

export const QUESTION_TYPE_OPTIONS = [
  {
    title: 'FREE-FORM INPUT',
    [OPTION_TYPE_FIELD]: CUSTOM_SELECT_SUBTITLE_TYPE,
  },
  {
    title: 'Boolean',
    value: QuestionTypesEnum.BOOLEAN,
    description: 'Allow user to enter a true/false value',
  },
  {
    title: 'Date',
    value: QuestionTypesEnum.DATE,
    description: 'Allow user to choose a date',
  },
  {
    title: 'Number',
    value: QuestionTypesEnum.NUMBER,
    description: 'Allow user to enter a number',
  },
  {
    title: 'Text',
    value: QuestionTypesEnum.TEXT,
    description: 'Allow user to enter free-form text',
  },
  {
    title: 'Custom Field',
    value: QuestionTypesEnum.CUSTOM_FIELD,
    description: 'Allow user to provide a value for a custom field',
  },
  {
    title: 'REFERENCE FIELDS',
    [OPTION_TYPE_FIELD]: CUSTOM_SELECT_SUBTITLE_TYPE,
  },
  {
    title: 'Billing Frequency',
    value: QuestionTypesEnum.BILLING_FREQUENCY,
    description: 'Allow user to select a billing frequency',
  },
  {
    title: 'Currency',
    value: QuestionTypesEnum.CURRENCY,
    description: 'Allow user to choose which currency to use',
  },
  {
    title: 'Legal Entity',
    value: QuestionTypesEnum.LEGAL_ENTITY,
    description: 'Allow user to select a legal entity to use',
  },
  {
    title: 'PRODUCT CATALOG FIELDS',
    [OPTION_TYPE_FIELD]: CUSTOM_SELECT_SUBTITLE_TYPE,
  },
  {
    title: 'Offering',
    value: QuestionTypesEnum.OFFERING,
    description: 'Allow user to choose from a list of offerings',
  },
  {
    title: 'Product',
    value: QuestionTypesEnum.PRODUCT,
    description:
      'Allow user to select products, which can drive which offerings show up',
  },
  {
    title: 'Rate',
    value: QuestionTypesEnum.RATE,
    description:
      'Allow user to choose which rates to use, this is usually done after the offering question',
  },
];

const ConstantQuestionVariable = {
  [QuestionFilterByEnum.NONE]: 'None',
  [QuestionFilterByEnum.CONSTANT]: 'Constant Value',
  [QuestionFilterByEnum.QUESTION]: 'Question Response',
  [QuestionFilterByEnum.VARIABLE]: 'Variable',
};

const ConstantAndQuestion = {
  [QuestionFilterByEnum.NONE]: 'None',
  [QuestionFilterByEnum.CONSTANT]: 'Constant Value',
  [QuestionFilterByEnum.QUESTION]: 'Question Response',
};

const ConstantOnly = {
  [QuestionFilterByEnum.NONE]: 'None',
  [QuestionFilterByEnum.CONSTANT]: 'Constant Value',
};

const QuestionOnly = {
  [QuestionFilterByEnum.NONE]: 'None',
  [QuestionFilterByEnum.QUESTION]: 'Question Response',
};

const None = {
  [QuestionFilterByEnum.NONE]: 'None',
};

export const QuestionFilterByDisplayText: Record<
  QuestionTypesEnum,
  Partial<Record<QuestionFilterByEnum, string>>
> = {
  [QuestionTypesEnum.BILLING_FREQUENCY]: ConstantOnly,
  [QuestionTypesEnum.DATE]: ConstantQuestionVariable,
  [QuestionTypesEnum.NUMBER]: ConstantAndQuestion,
  [QuestionTypesEnum.OFFERING]: ConstantQuestionVariable,
  [QuestionTypesEnum.PRODUCT]: ConstantQuestionVariable,
  [QuestionTypesEnum.RATE]: ConstantQuestionVariable,
  [QuestionTypesEnum.TEXT]: None,
  [QuestionTypesEnum.BOOLEAN]: None,
  [QuestionTypesEnum.CURRENCY]: None,
  [QuestionTypesEnum.CUSTOM_FIELD]: None,
  [QuestionTypesEnum.LEGAL_ENTITY]: None,
};

export const QuestionOfferingFields = {
  [QuestionCompareFromEnum.CREATE_DATE]: 'Create Date',
  [QuestionCompareFromEnum.MODIFY_DATE]: 'Modify Date',
  [QuestionCompareFromEnum.START_DATE]: 'Start Date',
  [QuestionCompareFromEnum.END_DATE]: 'End Date',
  [QuestionCompareFromEnum.OFFERING_ID]: 'Offering ID',
  [QuestionCompareFromEnum.OFFERING_CUSTOM_FIELD_CHECKBOX]:
    'Custom Field - Checkbox',
  [QuestionCompareFromEnum.OFFERING_CUSTOM_FIELD_DATE]: 'Custom Field - Date',
  [QuestionCompareFromEnum.OFFERING_CUSTOM_FIELD_DROPDOWN]:
    'Custom Field - Dropdown',
  [QuestionCompareFromEnum.OFFERING_CUSTOM_FIELD_NUMBER]:
    'Custom Field - Number',
  [QuestionCompareFromEnum.OFFERING_CUSTOM_FIELD_TEXT]: 'Custom Field - Text',
};

export const QuestionProductFields = {
  [QuestionCompareFromEnum.CREATE_DATE]: 'Create Date',
  [QuestionCompareFromEnum.MODIFY_DATE]: 'Modify Date',
  [QuestionCompareFromEnum.PRODUCT_ID]: 'Product ID',
  [QuestionCompareFromEnum.PRODUCT_CUSTOM_FIELD_CHECKBOX]:
    'Custom Field - Checkbox',
  [QuestionCompareFromEnum.PRODUCT_CUSTOM_FIELD_DATE]: 'Custom Field - Date',
  [QuestionCompareFromEnum.PRODUCT_CUSTOM_FIELD_DROPDOWN]:
    'Custom Field - Dropdown',
  [QuestionCompareFromEnum.PRODUCT_CUSTOM_FIELD_NUMBER]:
    'Custom Field - Number',
  [QuestionCompareFromEnum.PRODUCT_CUSTOM_FIELD_TEXT]: 'Custom Field - Text',
};
export const QuestionRateFields = {
  [QuestionCompareFromEnum.CREATE_DATE]: 'Create Date',
  [QuestionCompareFromEnum.MODIFY_DATE]: 'Modify Date',
  [QuestionCompareFromEnum.RATE_ID]: 'Rate ID',
  [QuestionCompareFromEnum.BILLING_FREQUENCY]: 'Billing Frequency',
};

export const QuestionCompareFromTypes: Record<string, QuestionTypesEnum> = {
  [QuestionCompareFromEnum.CREATE_DATE]: QuestionTypesEnum.DATE,
  [QuestionCompareFromEnum.MODIFY_DATE]: QuestionTypesEnum.DATE,
  [QuestionCompareFromEnum.START_DATE]: QuestionTypesEnum.DATE,
  [QuestionCompareFromEnum.END_DATE]: QuestionTypesEnum.DATE,
  [QuestionCompareFromEnum.OFFERING_ID]: QuestionTypesEnum.OFFERING,
  [QuestionCompareFromEnum.OFFERING_CUSTOM_FIELD_DATE]: QuestionTypesEnum.DATE,
  [QuestionCompareFromEnum.OFFERING_CUSTOM_FIELD_DROPDOWN]:
    QuestionTypesEnum.CUSTOM_FIELD,
  [QuestionCompareFromEnum.OFFERING_CUSTOM_FIELD_NUMBER]:
    QuestionTypesEnum.NUMBER,
  [QuestionCompareFromEnum.OFFERING_CUSTOM_FIELD_TEXT]: QuestionTypesEnum.TEXT,
  [QuestionCompareFromEnum.PRODUCT_ID]: QuestionTypesEnum.PRODUCT,
  [QuestionCompareFromEnum.PRODUCT_CUSTOM_FIELD_DATE]: QuestionTypesEnum.DATE,
  [QuestionCompareFromEnum.PRODUCT_CUSTOM_FIELD_DROPDOWN]:
    QuestionTypesEnum.CUSTOM_FIELD,
  [QuestionCompareFromEnum.PRODUCT_CUSTOM_FIELD_NUMBER]:
    QuestionTypesEnum.NUMBER,
  [QuestionCompareFromEnum.PRODUCT_CUSTOM_FIELD_TEXT]: QuestionTypesEnum.TEXT,
  [QuestionCompareFromEnum.RATE_ID]: QuestionTypesEnum.RATE,
  [QuestionCompareFromEnum.BILLING_FREQUENCY]:
    QuestionTypesEnum.BILLING_FREQUENCY,
  [QuestionTypesEnum.DATE]: QuestionTypesEnum.DATE,
  [QuestionTypesEnum.NUMBER]: QuestionTypesEnum.NUMBER,
};

export const QuestionCompareFromFields: Record<
  string,
  Record<string, Record<string, string>>
> = {
  [QuestionTypesEnum.OFFERING]: {
    [QuestionFilterByEnum.QUESTION]: (({
      OFFERING_ID,
      OFFERING_CUSTOM_FIELD_CHECKBOX,
      ...rest
    }) => rest)(QuestionOfferingFields),
    [QuestionFilterByEnum.CONSTANT]: QuestionOfferingFields,
    [QuestionFilterByEnum.VARIABLE]: (({
      OFFERING_ID,
      OFFERING_CUSTOM_FIELD_CHECKBOX,
      OFFERING_CUSTOM_FIELD_NUMBER,
      OFFERING_CUSTOM_FIELD_TEXT,
      ...rest
    }) => rest)(QuestionOfferingFields),
  },
  [QuestionTypesEnum.PRODUCT]: {
    [QuestionFilterByEnum.QUESTION]: (({
      PRODUCT_ID,
      PRODUCT_CUSTOM_FIELD_CHECKBOX,
      ...rest
    }) => rest)(QuestionProductFields),
    [QuestionFilterByEnum.CONSTANT]: QuestionProductFields,
    [QuestionFilterByEnum.VARIABLE]: (({
      PRODUCT_ID,
      PRODUCT_CUSTOM_FIELD_CHECKBOX,
      PRODUCT_CUSTOM_FIELD_NUMBER,
      PRODUCT_CUSTOM_FIELD_TEXT,
      ...rest
    }) => rest)(QuestionProductFields),
  },
  [QuestionTypesEnum.RATE]: {
    [QuestionFilterByEnum.QUESTION]: (({ RATE_ID, ...rest }) => rest)(
      QuestionRateFields,
    ),
    [QuestionFilterByEnum.CONSTANT]: QuestionRateFields,
    [QuestionFilterByEnum.VARIABLE]: (({ RATE_ID, ...rest }) => rest)(
      QuestionRateFields,
    ),
  },
};

// node.response.comparator
export const QuestionComparatorsDisplayText = {
  [QuestionComparatorEnum.EQUAL]: 'Equal',
  [QuestionComparatorEnum.GREATER_THAN]: 'Greater Than',
  [QuestionComparatorEnum.LESS_THAN]: 'Less Than',
  [QuestionComparatorEnum.GREATER_THAN_OR_EQUAL]: 'Greater Than or Equal',
  [QuestionComparatorEnum.LESS_THAN_OR_EQUAL]: 'Less Than or Equal',
};

export const OnlyEqualFields: Array<
  QuestionCompareFromEnum | QuestionTypesEnum
> = [
  QuestionCompareFromEnum.PRODUCT_ID,
  QuestionCompareFromEnum.OFFERING_ID,
  QuestionCompareFromEnum.RATE_ID,
  QuestionCompareFromEnum.BILLING_FREQUENCY,
  QuestionTypesEnum.BILLING_FREQUENCY,
  QuestionCompareFromEnum.PRODUCT_CUSTOM_FIELD_DROPDOWN,
  QuestionCompareFromEnum.OFFERING_CUSTOM_FIELD_DROPDOWN,
  QuestionCompareFromEnum.PRODUCT_CUSTOM_FIELD_CHECKBOX,
  QuestionCompareFromEnum.OFFERING_CUSTOM_FIELD_CHECKBOX,
  QuestionCompareFromEnum.PRODUCT_CUSTOM_FIELD_TEXT,
  QuestionCompareFromEnum.OFFERING_CUSTOM_FIELD_TEXT,
];

// node.response.compare_to
export const CustomFieldCheckboxValues: Record<string, string> = {
  TRUE: 'True',
  FALSE: 'False',
};

export type QuestionCompareToConstantOptions =
  | {
      type: 'DATE' | 'NUMBER' | 'DROPDOWN' | 'TEXT';
      itemsType?: never;
      items?: never;
    }
  | {
      type: 'MULTI-SELECT' | 'SELECT';
      itemsType?: 'REF';
      items?: never;
    }
  | {
      type: 'MULTI-SELECT' | 'SELECT';
      itemsType?: 'OBJECT';
      items: Record<string, string>;
    };

export const QuestionCompareToConstant: Record<
  | Exclude<
      QuestionTypesEnum,
      | QuestionTypesEnum.PRODUCT
      | QuestionTypesEnum.OFFERING
      | QuestionTypesEnum.RATE
      | QuestionTypesEnum.TEXT
      | QuestionTypesEnum.CURRENCY
      | QuestionTypesEnum.CUSTOM_FIELD
      | QuestionTypesEnum.BOOLEAN
      | QuestionTypesEnum.LEGAL_ENTITY
    >
  | QuestionCompareFromEnum,
  QuestionCompareToConstantOptions
> = {
  [QuestionTypesEnum.BILLING_FREQUENCY]: {
    type: 'MULTI-SELECT',
    itemsType: 'OBJECT',
    items: RATE_BILLING_FREQUENCY_DISPLAY,
  },
  [QuestionCompareFromEnum.PRODUCT_ID]: {
    type: 'MULTI-SELECT',
    itemsType: 'REF',
  },
  [QuestionCompareFromEnum.OFFERING_ID]: {
    type: 'MULTI-SELECT',
    itemsType: 'REF',
  },
  [QuestionCompareFromEnum.RATE_ID]: { type: 'MULTI-SELECT', itemsType: 'REF' },
  [QuestionTypesEnum.DATE]: { type: 'DATE' },
  [QuestionTypesEnum.NUMBER]: { type: 'NUMBER' },
  // [QuestionTypesEnum.BOOLEAN]: { type: 'BOOLEAN' },
  [QuestionCompareFromEnum.CREATE_DATE]: { type: 'DATE' },
  [QuestionCompareFromEnum.MODIFY_DATE]: { type: 'DATE' },
  [QuestionCompareFromEnum.START_DATE]: { type: 'DATE' },
  [QuestionCompareFromEnum.END_DATE]: { type: 'DATE' },
  [QuestionCompareFromEnum.PRODUCT_CUSTOM_FIELD_CHECKBOX]: {
    type: 'SELECT',
    itemsType: 'OBJECT',
    items: CustomFieldCheckboxValues,
  },
  [QuestionCompareFromEnum.OFFERING_CUSTOM_FIELD_CHECKBOX]: {
    type: 'SELECT',
    itemsType: 'OBJECT',
    items: CustomFieldCheckboxValues,
  },
  [QuestionCompareFromEnum.PRODUCT_CUSTOM_FIELD_DATE]: { type: 'DATE' },
  [QuestionCompareFromEnum.OFFERING_CUSTOM_FIELD_DATE]: { type: 'DATE' },
  [QuestionCompareFromEnum.PRODUCT_CUSTOM_FIELD_DROPDOWN]: { type: 'DROPDOWN' },
  [QuestionCompareFromEnum.OFFERING_CUSTOM_FIELD_DROPDOWN]: {
    type: 'DROPDOWN',
  },
  [QuestionCompareFromEnum.PRODUCT_CUSTOM_FIELD_NUMBER]: { type: 'NUMBER' },
  [QuestionCompareFromEnum.OFFERING_CUSTOM_FIELD_NUMBER]: { type: 'NUMBER' },
  [QuestionCompareFromEnum.PRODUCT_CUSTOM_FIELD_TEXT]: { type: 'TEXT' },
  [QuestionCompareFromEnum.OFFERING_CUSTOM_FIELD_TEXT]: { type: 'TEXT' },
};

// Guided Quoting
export const GuidedQuotingPopulateByToAvailableTypes: Partial<
  Record<QuestionTypesEnum, Record<string, string>>
> = {
  [QuestionTypesEnum.DATE]: ConstantQuestionVariable,
  [QuestionTypesEnum.NUMBER]: ConstantAndQuestion,
  [QuestionTypesEnum.OFFERING]: QuestionOnly,
  [QuestionTypesEnum.CURRENCY]: ConstantAndQuestion,
  [QuestionTypesEnum.CUSTOM_FIELD]: ConstantAndQuestion,
  [QuestionTypesEnum.LEGAL_ENTITY]: ConstantAndQuestion,
};

export const GuidedQuotingVariableTypes: Record<string, string[]> = {
  [QuestionTypesEnum.DATE]: ['TODAY', 'THIRTY_DAYS'],
};

export const GuidedQuotingVariables: Record<string, string> = {
  TODAY: 'Day of Quote creation',
  THIRTY_DAYS: '30 days after Quote creation',
};

export const GuidedQuotingVariableValues: Record<string, any> = {
  TODAY: new Date().toISOString().split('T')[0],
  THIRTY_DAYS: new Date(new Date().setDate(new Date().getDate() + 30))
    .toISOString()
    .split('T')[0],
};

export const GuidedQuotingFieldDisplayText: Record<
  QuoteSourceFieldName,
  string
> = {
  expirationDate: 'Expiration Date',
  contractStartDate: 'Contract Start Date',
  contractLength: 'Contract Length',
  currency: 'Currency',
  legalEntity: 'Legal Entity',
};

export const GuidedQuotingFieldDataTypeMapping: Record<
  QuoteSourceFieldName,
  QuestionTypesEnum
> = {
  expirationDate: QuestionTypesEnum.DATE,
  contractStartDate: QuestionTypesEnum.DATE,
  contractLength: QuestionTypesEnum.NUMBER,
  currency: QuestionTypesEnum.CURRENCY,
  legalEntity: QuestionTypesEnum.LEGAL_ENTITY,
};

export const CustomFieldTypeToGuidedSellingType: Record<
  CustomFieldTypeEnum,
  QuestionTypesEnum
> = {
  [CustomFieldTypeEnum.CHECKBOX]: QuestionTypesEnum.BOOLEAN,
  [CustomFieldTypeEnum.DATE]: QuestionTypesEnum.DATE,
  [CustomFieldTypeEnum.DROPDOWN]: QuestionTypesEnum.TEXT,
  [CustomFieldTypeEnum.NUMBER]: QuestionTypesEnum.NUMBER,
  [CustomFieldTypeEnum.SINGLE_LINE_TEXT]: QuestionTypesEnum.TEXT,
};
