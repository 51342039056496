import { QueryClient, QueryKey } from '@tanstack/react-query';
import isFunction from 'lodash/isFunction';
import isString from 'lodash/isString';
import { logger } from '~app/services/logger';
import { ApiListResponse } from '~types';

/**
 * Given a record, make sure it has an id property
 */
export function isEntityWithId(data: any): data is { id: string } {
  return !!data && isString(data.id) && data.id.length > 0;
}

export function isApiListResponse(data: any): data is ApiListResponse {
  return Array.isArray(data?.content);
}

/**
 * Handles getting invalidateKeys based on an array or a function that returns an array
 *
 * @param invalidateKeys
 * @param record
 * @returns
 */
export function getInvalidateKeys(
  invalidateKeys: QueryKey[] | ((id: string, args?: any) => QueryKey[]),
  record: unknown,
  endpointArgs?: any,
) {
  if (Array.isArray(invalidateKeys)) {
    return invalidateKeys;
  } else if (isEntityWithId(record)) {
    return invalidateKeys(record.id, endpointArgs);
  }
  // Ignore - unable to calculate keys
  return [];
}

export function getListQueryKey(
  queryKey: QueryKey | ((args: any) => QueryKey),
  endpointArgs?: any,
) {
  if (isFunction(queryKey)) {
    return queryKey(endpointArgs);
  }
  return queryKey;
}

/**
 * When a list of data is returned, set each record in the cache by the record id
 *
 * This should only be used when the list payload includes the entire record for each entry (which is the default)
 *
 * @param queryClient queryClient to use to set the cache
 * @param byIdQueryKey function that will be called to construct the key for the cache using the recordId
 * @param data ApiListResponse
 */
export function setByIdCacheFromReturnedList(
  queryClient: QueryClient,
  byIdQueryKey: (id: string, endpointArgs?: any) => QueryKey,
  data: ApiListResponse<any> | any[],
  endpointArgs?: any,
) {
  const items = isApiListResponse(data) ? data.content : data;
  if (isFunction(byIdQueryKey) && Array.isArray(items)) {
    items.filter(isEntityWithId).forEach((record) => {
      queryClient.setQueryData(
        [...byIdQueryKey(record.id, endpointArgs)],
        record,
      );
    });
  }
}

/**
 * When a record is updated, update the same records in the cache from list responses.
 *
 * This allows the UI to update immediately upon successful response without waiting for re-fetch response.
 *
 * @param queryClient
 * @param listKey - Full or partial key for the list query
 * @param updatedData - Data to replace existing record with
 * @param mergeRecords - If true, merge the updatedData with the existing record in the cache
 * @returns
 */
export function updateListCacheWithUpdatedItem(
  queryClient: QueryClient,
  listQueryKey: QueryKey,
  updatedData: any,
  mergeRecords = false,
) {
  try {
    if (!updatedData || !isEntityWithId(updatedData)) {
      return;
    }
    queryClient
      .getQueryCache()
      .findAll({
        // Include all keys that start with listQueryKey
        queryKey: listQueryKey,
        // Only include lists that have the current record in the list
        predicate: (cache) =>
          (cache.state?.data as ApiListResponse<any>)?.content?.some(
            (record) => isEntityWithId(record) && record.id === updatedData.id,
          ),
      })
      .forEach((cache) => {
        const existingData = cache.state?.data as ApiListResponse<any>;
        cache.setData({
          ...existingData,
          content: existingData.content.map((record) => {
            if (record.id !== updatedData.id) {
              return record;
            }
            return mergeRecords ? { ...record, ...updatedData } : updatedData;
          }),
        });
      });
  } catch (ex) {
    logger.warn(
      '[QUERY][ERROR] Failed to update list cache with updated item',
      ex,
    );
  }
}

/**
 * When a record is deleted, update the same records in the cache from list responses.
 *
 * This allows the UI to update immediately upon successful response without waiting for re-fetch response.
 *
 */
export function updateListCacheWithRemovedItem(
  queryClient: QueryClient,
  listQueryKey: QueryKey,
  recordId: string,
) {
  try {
    if (!recordId) {
      return;
    }
    queryClient
      .getQueryCache()
      .findAll({
        // Include all keys that start with listQueryKey
        queryKey: listQueryKey,
        // Only include lists that have the current record in the list
        predicate: (cache) =>
          (cache.state?.data as ApiListResponse<any>)?.content?.some(
            (record) => isEntityWithId(record) && record.id === recordId,
          ),
      })
      .forEach((cache) => {
        const existingData = cache.state?.data as ApiListResponse<any>;
        cache.setData({
          ...existingData,
          content: existingData.content.filter(
            (record) => record.id !== recordId,
          ),
        });
      });
    // Since pagination would potentially return different results, invalidate all list queries
    queryClient.invalidateQueries(listQueryKey);
  } catch (ex) {
    logger.warn(
      '[QUERY][ERROR] Failed to update list cache with deleted item',
      ex,
    );
  }
}
