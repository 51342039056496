import { useQuery } from '@tanstack/react-query';
import { apiDelete, apiGet, apiGetAllList, apiPut } from '~api/axios';
import {
  ApiListResponse,
  GetListApiConfig,
  GetListApiFilter,
  IQuoteRespSchema,
} from '~types';
import { composeGetQuery } from './utils';

export const approvalsQueryKeys = {
  base: ['quotes', 'awaitingApproval'] as const,
  dashboard: () => [...approvalsQueryKeys.base, 'list'] as const,
};

export async function doQuoteApprove(approvalId: string): Promise<any> {
  const res = await apiPut<any>(`/api/quotes/approvals/${approvalId}/approve`);
  return res.data;
}

export async function doQuoteReject(
  approvalId: string,
  data: any,
): Promise<any> {
  const res = await apiPut<any>(
    `/api/quotes/approvals/${approvalId}/decline`,
    data,
  );
  return res.data;
}

export async function doQuoteRetract(approvalId: string): Promise<any> {
  const res = await apiDelete<any>(`/api/quotes/approvals/${approvalId}`);
  return res.data;
}
export function useGetQuoteApprovalDashboard(
  {
    config,
    filters,
  }: {
    config: GetListApiConfig;
    filters?: GetListApiFilter;
  },
  options: {
    enabled?: boolean;
    onSuccess?: (data: IQuoteRespSchema[]) => void;
  } = {},
) {
  const params = composeGetQuery(config, filters);
  return useQuery<IQuoteRespSchema[]>(
    [...approvalsQueryKeys.dashboard(), params],
    {
      queryFn: () =>
        apiGetAllList<IQuoteRespSchema>('/api/quotes/approvals/dashboard', {
          rows: 20, // This API is really slow, limit to keep request time down
          config,
          filters,
        }),
      ...options,
    },
  );
}

export async function doGetQuoteApprovalDashboard(
  config: GetListApiConfig,
  filters?: GetListApiFilter,
): Promise<ApiListResponse<IQuoteRespSchema>> {
  const params = composeGetQuery(config, filters);
  const res = await apiGet<ApiListResponse<IQuoteRespSchema>>(
    '/api/quotes/approvals/dashboard',
    {
      params,
    },
  );
  return res.data;
}
