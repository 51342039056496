import { FunctionComponent as FC } from 'react';
import { StripeWrapper } from '~app/components/PaymentMethods/Form/StripeWrapper';
import { IInvoiceRespSchema } from '~app/types';
import { SharedInvoiceFormFields } from './SharedInvoiceFormFields';

interface SharedInvoicePaymentModalProps {
  allowPartialPayment: boolean;
  clientSecret: string;
  invoice: IInvoiceRespSchema;
  invoiceSecretId: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  isOpen: boolean;
  publicKey: string;
  tenantId: string;
  onClose: () => void;
}

export const SharedInvoicePaymentModal: FC<SharedInvoicePaymentModalProps> = (
  props: SharedInvoicePaymentModalProps,
) => {
  const { publicKey, clientSecret } = props;

  if (!publicKey || !clientSecret) {
    return null;
  }

  return (
    <StripeWrapper apiKey={publicKey} clientSecret={clientSecret}>
      <SharedInvoiceFormFields {...props} setupIntent={clientSecret} />
    </StripeWrapper>
  );
};
