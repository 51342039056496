import { apiPut } from '~api/axios';
import { ICustomFieldRecordSchema } from '~types';

export const doUpdateCustomFields = async ({
  entity,
  id,
  data,
}: {
  entity: 'credits' | 'creditNotes';
  id: string;
  data: ICustomFieldRecordSchema;
}) => {
  const res = await apiPut<ICustomFieldRecordSchema>(
    `/api/${entity}/${id}/customFields`,
    data,
  );
  return res.data;
};
