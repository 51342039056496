import { useDisclosure } from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { ColumnProps } from 'primereact/column';
import React, { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { doPrintInvoiceToPdf } from '~app/api/accountsService';
import { handleApiErrorToast } from '~app/api/axios';
import { doExportEntity } from '~app/api/entityExportService';
import { accountServiceQueryKeys } from '~app/api/queryKeysService';
import { useRevRecSettings } from '~app/api/revenueService';
import { MDataTable } from '~app/components/Monetize';
import { MDataTableActions } from '~app/components/Monetize/DataTable';
import { ROUTES } from '~app/constants';
import {
  InvoiceStatusEnumDisplay,
  REVENUE_SCHEDULE_STATUSES,
} from '~app/constants/invoice';
import { useACL } from '~app/services/acl/acl';
import { getFiltersApplied } from '~app/utils';
import { downloadBlobAsFile } from '~app/utils/download';
import { getTaxCalculationError } from '~app/utils/invoices';
import {
  currencyBodyTemplate,
  dateBodyTemplate,
  headerAndSubheaderTemplate,
  nameWithIdBodyTemplate,
  nestedTextBodyTemplate,
  statusWithErrorTooltipTemplate,
} from '~app/utils/tableUtils';
import {
  IAccountSnapshotSchema,
  IBillGroupResp,
  InvoiceStatusEnum,
  InvoiceSummaryResp,
  UseEntityDataResponseType,
} from '~types';
import { InvoiceRevenueScheduleModal } from './InvoiceRevenueSchedule/InvoiceRevenueScheduleModal';
import { PayInvoiceModal } from './createPayment/CreatePaymentModal';

interface InvoicesProps
  extends Omit<UseEntityDataResponseType<InvoiceSummaryResp>, 'loadData'> {
  filterComponentReset: React.MutableRefObject<any>;
  searchComponentReset?: React.MutableRefObject<any>;
  showAccount?: boolean;
  searchTerm?: string;
  source?: 'accounts' | 'dashboard';
  omitBillGroup?: boolean;
}

export const InvoiceList: FC<InvoicesProps> = ({
  source,
  filters,
  setPager,
  pager,
  isLoading,
  invoices,
  filterComponentReset,
  showAccount = false,
  searchComponentReset = { current: null },
  searchTerm,
  omitBillGroup = false,
}: InvoicesProps) => {
  const navigate = useNavigate();
  const params = useParams();
  const accountId = params.accountId || '';

  const { data: recRevSettings } = useRevRecSettings();
  const queryClient = useQueryClient();
  const { canDo } = useACL();
  const canUpdateBilling = canDo([['billing', 'update']]);
  const canReadBilling = canDo([['billing', 'read']]);
  const paymentModalState = useDisclosure();
  const revenueScheduleModalState = useDisclosure();

  const [invoice, setInvoice] = useState<InvoiceSummaryResp>();

  useEffect(() => {
    if (
      invoice &&
      !paymentModalState.isOpen &&
      !revenueScheduleModalState.isOpen
    ) {
      setInvoice(undefined);
    }
  }, [invoice, paymentModalState.isOpen, revenueScheduleModalState.isOpen]);

  const actionBodyTemplate = (rowData: InvoiceSummaryResp) => {
    const { status, amount } = rowData;
    const actions = [
      {
        title: 'Pay Invoice',
        enabled:
          status === InvoiceStatusEnum.UNPAID &&
          Number(amount) > 0 &&
          canUpdateBilling,
        action: () => {
          setInvoice(rowData);
          paymentModalState.onOpen();
        },
      },
      {
        title: 'Print to PDF',
        enabled: true,
        action: async () => {
          try {
            const { data, fileName } = await doPrintInvoiceToPdf(
              rowData.id,
              rowData.invoiceNumber,
            );
            downloadBlobAsFile(data, fileName);
          } catch (err) {
            handleApiErrorToast(err);
          }
        },
      },
      {
        title: 'Export CSV',
        enabled: canReadBilling,
        action: async () => {
          try {
            await doExportEntity({
              entity: 'invoice',
              filename: `invoice-${rowData.invoiceNumber || rowData.id}.csv`,
              endpointParams: [rowData.id],
            });
          } catch (err) {
            handleApiErrorToast(err);
          }
        },
      },
      {
        title: 'View Revenue Schedule',
        enabled:
          recRevSettings?.enabled &&
          REVENUE_SCHEDULE_STATUSES.has(rowData.status),
        action: () => {
          setInvoice(rowData);
          revenueScheduleModalState.onOpen();
        },
      },
    ];
    return <MDataTableActions actions={actions} />;
  };

  const handleRowClick = (rowData: any, openInNewWindow = false) => {
    const generatedRoute = ROUTES.getInvoiceDetailRoute(rowData.id);
    const routeToNavigate = params.billGroupId
      ? `${generatedRoute}?billGroupId=${params.billGroupId}`
      : generatedRoute;

    openInNewWindow
      ? window.open(routeToNavigate)
      : navigate(routeToNavigate, {
          state: { source },
        });
  };

  const handlePaymentModalClose = (reload: boolean) => {
    if (reload) {
      queryClient.invalidateQueries([
        accountServiceQueryKeys.accounts.invoiceList(accountId),
      ]);
    }

    paymentModalState.onClose();
  };

  let columns: ColumnProps[] = [
    {
      className: ' overflow-hidden table-cell-md',
      field: 'invoiceNumber',
      header: 'Invoice Number',
      body: headerAndSubheaderTemplate<InvoiceSummaryResp>(
        'invoiceNumber',
        'id',
      ),
    },
    {
      className: 'overflow-hidden',
      field: 'billGroupId',
      header: 'Bill Group',
      body: nameWithIdBodyTemplate<InvoiceSummaryResp, IBillGroupResp>(
        'billGroup',
      ),
      style: {
        width: '12.5rem',
      },
    },
    {
      field: 'billDate',
      header: 'Bill Date',
      body: dateBodyTemplate<InvoiceSummaryResp>('billDate'),
      sortable: true,
      style: { width: '10em' },
    },
    {
      field: 'dueDate',
      header: 'Due Date',
      sortable: true,
      body: dateBodyTemplate<InvoiceSummaryResp>('dueDate'),
    },
    {
      field: 'lastSent',
      header: 'Sent Date',
      body: dateBodyTemplate<InvoiceSummaryResp>('lastSent'),
    },
    {
      field: 'status',
      header: 'Status',
      sortable: true,
      body: statusWithErrorTooltipTemplate<
        InvoiceSummaryResp,
        InvoiceStatusEnum
      >('status', 'taxError', InvoiceStatusEnumDisplay, getTaxCalculationError),
    },
    {
      field: 'amount',
      header: 'Amount',
      sortable: true,
      body: currencyBodyTemplate<InvoiceSummaryResp>('amount', 'currency'),
      style: { textAlign: 'right' },
    },
    {
      field: 'amountDue',
      header: 'Amount Due',
      sortable: true,
      body: currencyBodyTemplate<InvoiceSummaryResp>('amountDue', 'currency'),
      style: { textAlign: 'right' },
    },
    { field: 'action', header: '', sortable: false, body: actionBodyTemplate },
  ];

  const accountColumn: ColumnProps = {
    field: 'account.accountName',
    header: 'Account Name',
    sortable: false,
    body: nestedTextBodyTemplate<InvoiceSummaryResp, IAccountSnapshotSchema>(
      'account',
      'accountName',
    ),
    className: 'table-cell-lg',
  };

  if (omitBillGroup) {
    columns = columns.filter((column) => column.field !== 'billGroupId');
  }

  if (showAccount) {
    columns.splice(1, 0, accountColumn);
  }

  return (
    <>
      <MDataTable
        value={invoices?.content}
        totalRecords={invoices?.totalElements}
        totalPages={invoices?.totalPages}
        pager={pager}
        setPager={setPager}
        rowHover
        onRowClick={(e, openInNewWindow) =>
          handleRowClick(e.data, openInNewWindow)
        }
        className="p-datatable-responsive"
        emptyProps={{
          mainMessage: 'Looks like there are no invoices here.',
          smallMessage:
            'When you create a quote, the invoice will be added here.',
        }}
        filtersApplied={getFiltersApplied(filters) > 0 || !!searchTerm}
        resetFilter={() => {
          filterComponentReset.current && filterComponentReset.current();
          searchComponentReset &&
            searchComponentReset.current &&
            searchComponentReset.current();
        }}
        loading={isLoading}
        columns={columns}
      />
      {paymentModalState.isOpen && !!invoice && (
        <PayInvoiceModal
          isOpen={paymentModalState.isOpen}
          invoice={invoice}
          onClose={handlePaymentModalClose}
        />
      )}
      {!!invoice && revenueScheduleModalState.isOpen && (
        <InvoiceRevenueScheduleModal
          isOpen
          invoice={invoice}
          onClose={() => revenueScheduleModalState.onClose()}
        />
      )}
    </>
  );
};
