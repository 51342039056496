import { MBox, MGrid } from '~app/components/Monetize';
import { ChurnedAccountsChart } from './ChurnedAccountsChart';
import { FinanceDashboardHeaderItems } from './FinanceDashboardHeaderItems';
import { NewPayingCustomersChart } from './NewPayingCustomersChart';
import { RevenueChart } from './RevenueChart';
import { SalesChart } from './SalesChart';

interface FinanceDashboardV2Props {
  currency: string;
}

export const FinanceDashboardV2 = ({ currency }: FinanceDashboardV2Props) => {
  if (!currency) {
    return null;
  }

  return (
    <MBox>
      <FinanceDashboardHeaderItems currency={currency} />
      <MGrid gridTemplateColumns="repeat(2, 1fr)" gap={14} mt={8}>
        <NewPayingCustomersChart />
        <ChurnedAccountsChart />
        <SalesChart currency={currency} />
        <RevenueChart currency={currency} />
      </MGrid>
    </MBox>
  );
};
