import { FC, ReactNode, useState } from 'react';
import { FiExternalLink } from 'react-icons/fi';
import { SettingCardEdit } from '~app/components/Settings/SettingCardEdit';
import { SettingCardView } from '~app/components/Settings/SettingCardView';
import { SettingCardViewAction } from '~app/types';
import {
  INetsuite,
  NetsuiteCustomerLookupEnum,
  NetsuiteCustomerLookupEnumDisplay,
  NetsuiteProductExtIdEnumDisplay,
} from '~app/types/accountingTypes';
import { useFlags } from '../../../../../services/launchDarkly';
import { NetSuiteFieldMapping } from './NetSuiteFieldMapping';
import { NetsuiteForm } from './NetsuiteForm';

interface NetSuiteConnectionProps {
  connection: INetsuite;
  description?: ReactNode;
}

export const NetSuiteConnection: FC<NetSuiteConnectionProps> = ({
  connection,
  description,
}) => {
  const { allowNetsuiteFieldMapping } = useFlags();
  const [mode, setMode] = useState<'VIEW' | 'EDIT'>('VIEW');

  const handleAction = async (action: SettingCardViewAction) => {
    switch (action) {
      case 'DOCS-LINK':
        window.open(
          'https://docs.monetizenow.io/docs/authentication-setup',
          '_blank',
        );
        break;
      default:
        break;
    }
  };

  return (
    <>
      {mode === 'VIEW' && (
        <SettingCardView
          status={'ACTIVE'} // Once BE has error state, we will implement here
          actions={[
            // { action: 'RE_AUTHENTICATE', label: 'Re-Authenticate' },
            // { action: 'VERIFY', label: 'Verify Connection' },
            { action: 'DOCS-LINK', label: 'NetSuite', icon: FiExternalLink },
            // {
            //   action: 'DELETE',
            //   label: 'Delete',
            //   isDanger: true,
            //   confirmProps: {
            //     title: `Are you sure you want to delete this connection?`,
            //     description:
            //       'Once removed, this configuration will not longer be available.',
            //   },
            // },
          ]}
          type="NetSuite"
          tags={[
            {
              label: 'Account ID',
              value: connection.accountId,
            },
            {
              label: 'Consumer Key',
              value: connection.consumerKey,
              colSpan: 1,
            },
            {
              label: 'Consumer Secret',
              value: connection.consumerSecret,
              colSpan: 1,
            },
            {
              label: 'Access Token ID',
              value: connection.tokenId,
              colSpan: 1,
            },
            {
              label: 'Access Token Secret',
              value: connection.tokenSecret,
              colSpan: 1,
            },
            {
              label: 'Matching Field for Customer Lookup',
              value:
                NetsuiteCustomerLookupEnumDisplay[
                  connection.customerLookup || NetsuiteCustomerLookupEnum.NONE
                ],
              colSpan: 1,
            },
            {
              label: 'Netsuite Item External ID',
              value:
                NetsuiteProductExtIdEnumDisplay[
                  connection.netsuiteProductExternalId
                ],
              colSpan: 1,
            },
            {
              label: 'Netsuite Item Id for Tax Line',
              value: connection.taxItemId || 'None',
              colSpan: 1,
            },
          ]}
          onEdit={() => setMode('EDIT')}
          onAction={handleAction}
        />
      )}
      {mode === 'EDIT' && (
        <>
          {description}
          <SettingCardEdit type="NetSuite">
            <NetsuiteForm
              connection={connection}
              onClose={() => setMode('VIEW')}
            />
          </SettingCardEdit>
        </>
      )}

      {allowNetsuiteFieldMapping && <NetSuiteFieldMapping />}
    </>
  );
};
