export enum TenantStatusEnum {
  ACTIVE = 'ACTIVE',
  INVITED = 'INVITED',
}

export const TENANT_STATUS_DISPLAY: {
  [key in TenantStatusEnum]: string;
} = {
  ACTIVE: 'Active',
  INVITED: 'Invited',
};
