import { Tooltip, useDisclosure } from '@chakra-ui/react';
import { FC, useRef, useState } from 'react';
import { MdOutlineHistory } from 'react-icons/md';
import { useGetTeamHistory } from '~app/api/settingsService';
import {
  MBox,
  MButton,
  MCircularProgress,
  MCustomIconButton,
  MDrawer,
  MDrawerBody,
  MDrawerCloseButton,
  MDrawerContent,
  MDrawerFooter,
  MDrawerHeader,
  MDrawerOverlay,
  MFlex,
  MStack,
  MText,
} from '~app/components/Monetize';
import MEmptyDataPlaceholder from '~app/components/Monetize/MEmptyDataPlaceholder';
import { DEFAULT_PAGER, IHistory, TDataTablePager } from '~app/types';
import { formatInteger } from '~app/utils';
import { TeamHistoryCard } from '../components/TeamHistoryCard';

export const TeamHistoryDrawer: FC<{
  teamId: string;
}> = ({ teamId }: any) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const historyBtnRef = useRef<any>();
  const historyBodyTopRef = useRef<HTMLDivElement | null>(null);
  const historyBodyRef = useRef<HTMLDivElement | null>(null);
  const [pager, setPager] = useState<TDataTablePager>(DEFAULT_PAGER);

  const {
    isLoading: historyLoading,
    data: teamHistory,
    isFetching,
  } = useGetTeamHistory({
    config: pager,
    teamId: teamId,
  });

  const scrollToTop = () => {
    historyBodyTopRef?.current &&
      historyBodyTopRef.current.scrollIntoView({
        behavior: 'smooth',
      });
  };
  const shouldRenderLoadMoreBtn = (teamHistory?.totalPages ?? 0) > 1;
  const hasHistoryRecordContent = (teamHistory?.content.length ?? 0) > 0;

  return (
    <MBox position="relative">
      <Tooltip isDisabled={isOpen} label="View History" placement="bottom-end">
        <MCustomIconButton
          icon={MdOutlineHistory}
          containerSize={8}
          btnSize={6}
          onClick={onOpen}
          aria-label="Team History"
          mr={1}
          variant="icon"
        />
      </Tooltip>
      <MDrawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        size="sm"
        finalFocusRef={historyBtnRef}
        blockScrollOnMount={false}
      >
        <MDrawerOverlay />
        <MDrawerContent>
          <MDrawerCloseButton />
          <MDrawerHeader color="tPurple.base">Team History</MDrawerHeader>
          <MDrawerBody px={2} bgColor="tGray.sidebarDark" ref={historyBodyRef}>
            <MBox ref={historyBodyTopRef} />
            {historyLoading && (
              <MFlex w="full" h="full" justify="center" align="center">
                <MCircularProgress isIndeterminate size={6} />
              </MFlex>
            )}
            {!historyLoading && !hasHistoryRecordContent && (
              <MFlex flexDirection="column" bgColor="tWhite.base">
                <MEmptyDataPlaceholder
                  mainMessage="Looks like there are no team history here."
                  boxProps={{ h: '3.25rem' }}
                  mainMessageProps={{
                    fontSize: 'md',
                    fontWeight: 'semibold',
                  }}
                />
              </MFlex>
            )}

            <MStack boxShadow="initial" spacing={2}>
              {teamHistory?.content?.map((historyRecord: IHistory, i) => (
                <TeamHistoryCard
                  key={i}
                  message={historyRecord.message}
                  time={historyRecord.createDate}
                />
              ))}
            </MStack>

            <MFlex align="center" justify="center" flexDir="column" mt={4}>
              <MText
                align="center"
                fontSize="xs"
                color="tPurple.base"
              >{`Showing ${formatInteger(teamHistory?.content.length)}
              of ${formatInteger(teamHistory?.totalElements)}`}</MText>
              {shouldRenderLoadMoreBtn && (
                <MButton
                  isLoading={!historyLoading && isFetching}
                  variant="tertiary"
                  onClick={() =>
                    setPager((currentPager) => ({
                      ...currentPager,
                      rows: currentPager.rows + 20,
                    }))
                  }
                >
                  Load More
                </MButton>
              )}
              {historyBodyRef.current &&
                historyBodyRef.current.scrollHeight >
                  historyBodyRef.current.clientHeight && (
                  <MButton variant="tertiary" onClick={scrollToTop} mt={2}>
                    Back to Top
                  </MButton>
                )}
            </MFlex>
          </MDrawerBody>
          <MDrawerFooter>
            <MBox w="full">
              <MFlex justify="center">
                <MButton
                  mt={5}
                  variant="cancel"
                  justifySelf="center"
                  onClick={onClose}
                >
                  Close
                </MButton>
              </MFlex>
            </MBox>
          </MDrawerFooter>
        </MDrawerContent>
      </MDrawer>
    </MBox>
  );
};
