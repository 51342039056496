import { Control, Controller, FieldErrors } from 'react-hook-form';
import { IRefundReqSchema } from '../../types';
import { getFormattedDate, toDateOnly } from '../../utils/dates';
import {
  MCustomNumberInput,
  MFormField,
  MGridItem,
  MText,
  MTextarea,
} from '../Monetize';

const currentDate = getFormattedDate(
  toDateOnly(new Date(), 'userTimezone'),
  'MMM dd, yyyy',
);

interface RefundCreateFormProps {
  isLoading: boolean;
  control: Control<IRefundReqSchema>;
  errors: FieldErrors<IRefundReqSchema>;
}

export const RefundCreateForm = ({
  isLoading,
  control,
  errors,
}: RefundCreateFormProps) => {
  return (
    <>
      <MGridItem colSpan={6}>
        <MFormField error={errors.refundAmount} label="Amount" isRequired>
          <Controller
            name="refundAmount"
            control={control}
            render={({ field: { onChange, ...field } }) => (
              <MCustomNumberInput
                isDisabled={isLoading}
                precision={2}
                inputMode="decimal"
                onChange={(value) => onChange(value)}
                {...field}
              />
            )}
          />
        </MFormField>
      </MGridItem>
      <MGridItem colSpan={6}>
        <MFormField label="Refund Date">
          <MText lineHeight={2.2}>{currentDate}</MText>
        </MFormField>
      </MGridItem>
      <MGridItem colSpan={12}>
        <MFormField error={errors.description} label="Reason" isRequired>
          <MText mb={2} fontSize={12}>
            This will be listed on the Refund document, which may be visible to
            end customers.
          </MText>
          <Controller
            name="description"
            control={control}
            render={({ field: { onChange, ...field } }) => (
              <MTextarea
                isDisabled={isLoading}
                rows={1}
                placeholder="Reasons for refund"
                onChange={(value) => onChange(value)}
                {...field}
              />
            )}
          />
        </MFormField>
      </MGridItem>
    </>
  );
};
