import { zodResolver } from '@hookform/resolvers/zod';
import { FC, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  IOfferingRes,
  IProduct,
  IRateReqSchemaUI,
  MinCommitConfigTypeEnum,
  OfferingTypesEnum,
  PercentOfTotalConfigTypeEnum,
  RateReqSchemaUI,
  RateTypeEnum,
  SubscriptionTimingEnum,
} from '~app/types';
import { getOfferingType } from '../../offeringUtils';
import RateForm from './RateForm';
import {
  getDefaultBillingFrequency,
  getDefaultUsageBillingFrequency,
  getProductTypeExistence,
} from './RatePriceUtils';

export interface RateProps {
  id: string;
  products: IProduct[];
  type: OfferingTypesEnum;
  typeId: string;
  archivedOffering?: boolean;
  rateCloneData?: IRateReqSchemaUI;
  setRateCloneData: (data?: IRateReqSchemaUI) => void;
  handleCloneRate: (data: IRateReqSchemaUI) => void;
  handleDirtyRateIds: (rateId: string, type: 'add' | 'remove') => void;
  onChange?: (rateId: string) => void;
  removeRateTempUUId: (savedTempRateId: string) => void;
  onRemove?: () => void;
  isReadOnly?: boolean;
  isExpandedAll?: boolean;
  offering?: IOfferingRes;
}

const RateFormProvider: FC<RateProps> = ({
  id,
  products,
  type,
  typeId,
  archivedOffering,
  rateCloneData,
  handleCloneRate,
  setRateCloneData,
  handleDirtyRateIds,
  onChange,
  removeRateTempUUId,
  onRemove,
  isReadOnly,
  isExpandedAll = false,
  offering,
}: RateProps) => {
  const isClonedRate = id.match('clone-') !== null;
  const { isOneTime, isMinCommit, isSubscription, isPercentOfTotal } =
    getOfferingType(type);
  const { isSubscriptionProductTypeExist, isUsageProductTypeExist } =
    getProductTypeExistence(products);

  const methods = useForm<IRateReqSchemaUI>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      rateType: RateTypeEnum.CATALOG,
      billingFrequency: getDefaultBillingFrequency({
        isOneTime,
        isMinCommit,
        isPercentOfTotal,
        isSubscription,
        isSubscriptionProductTypeExist,
      }),
      billingFrequencyInMonths: null,
      subscriptionTiming: isMinCommit
        ? SubscriptionTimingEnum.ARREARS
        : SubscriptionTimingEnum.ADVANCE,
      usageBillingFrequency: getDefaultUsageBillingFrequency({
        isMinCommit,
        isUsageProductTypeExist,
      }),
      minCommitConfig:
        type === OfferingTypesEnum.MIN_COMMIT
          ? { type: MinCommitConfigTypeEnum.USAGE_PRODUCTS }
          : null,
      percentOfTotalConfig:
        type === OfferingTypesEnum.CUSTOM_PERCENT_OF_TOTAL
          ? { type: PercentOfTotalConfigTypeEnum.ALL_ELIGIBLE }
          : null,
    },
    resolver: zodResolver(RateReqSchemaUI),
  });

  const { getValues, setValue, reset } = methods;
  const { name } = getValues();

  useEffect(() => {
    if (!name && rateCloneData && isClonedRate) {
      reset(rateCloneData);

      const copyOfName = rateCloneData.name
        ? `Copy of ${rateCloneData.name}`
        : 'Copy Of ';
      //simple hack to make the form dirty
      setTimeout(() => {
        setValue('name', copyOfName, {
          shouldDirty: true,
        });
      });

      setRateCloneData(undefined);
    }
  }, [rateCloneData, isClonedRate]);

  return (
    <FormProvider {...methods}>
      <form
        style={{ width: '100%' }}
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <RateForm
          id={id}
          type={type}
          archivedOffering={archivedOffering}
          typeId={typeId}
          products={products}
          isClonedRate={isClonedRate}
          onChange={onChange}
          removeRateTempUUId={removeRateTempUUId}
          onRemove={onRemove}
          handleCloneRate={handleCloneRate}
          handleDirtyRateIds={handleDirtyRateIds}
          isReadOnly={isReadOnly}
          isSubscriptionProductTypeExist={isSubscriptionProductTypeExist}
          isSubscription={isSubscription}
          isExpandedAll={isExpandedAll}
          offering={offering}
        />
      </form>
    </FormProvider>
  );
};

export default RateFormProvider;
