const AccountingIcon = (props: any) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 20 15"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.3636 8.63636C10.6061 8.63636 9.96212 8.37121 9.43182 7.84091C8.90152 7.31061 8.63636 6.66667 8.63636 5.90909C8.63636 5.15152 8.90152 4.50758 9.43182 3.97727C9.96212 3.44697 10.6061 3.18182 11.3636 3.18182C12.1212 3.18182 12.7652 3.44697 13.2955 3.97727C13.8258 4.50758 14.0909 5.15152 14.0909 5.90909C14.0909 6.66667 13.8258 7.31061 13.2955 7.84091C12.7652 8.37121 12.1212 8.63636 11.3636 8.63636ZM4.09091 11.8182C3.71591 11.8182 3.39489 11.6847 3.12784 11.4176C2.8608 11.1506 2.72727 10.8295 2.72727 10.4545V1.36364C2.72727 0.988636 2.8608 0.667614 3.12784 0.400568C3.39489 0.133523 3.71591 0 4.09091 0H18.6364C19.0114 0 19.3324 0.133523 19.5994 0.400568C19.8665 0.667614 20 0.988636 20 1.36364V10.4545C20 10.8295 19.8665 11.1506 19.5994 11.4176C19.3324 11.6847 19.0114 11.8182 18.6364 11.8182H4.09091ZM6.36364 10.4545H16.3636C16.3636 9.81818 16.5833 9.2803 17.0227 8.84091C17.4621 8.40152 18 8.18182 18.6364 8.18182V3.63636C18 3.63636 17.4621 3.41667 17.0227 2.97727C16.5833 2.53788 16.3636 2 16.3636 1.36364H6.36364C6.36364 2 6.14394 2.53788 5.70455 2.97727C5.26515 3.41667 4.72727 3.63636 4.09091 3.63636V8.18182C4.72727 8.18182 5.26515 8.40152 5.70455 8.84091C6.14394 9.2803 6.36364 9.81818 6.36364 10.4545ZM17.2727 14.5455H1.36364C0.988636 14.5455 0.667614 14.4119 0.400568 14.1449C0.133523 13.8778 0 13.5568 0 13.1818V2.72727H1.36364V13.1818H17.2727V14.5455Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default AccountingIcon;
