import isString from 'lodash/isString';
import { ReactNode } from 'react';
import MText from './MText';

interface TitleValuePairProps {
  title: string;
  value?: string | ReactNode;
}

/**
 * Produces a title/value pair where the header is not bold, but the value is.
 * If the value is not a string, it will be displayed as is.
 */
export const TitleValuePair = ({ title, value }: TitleValuePairProps) => {
  return (
    <>
      <MText fontSize="md">{title}</MText>
      {!value || isString(value) ? (
        <MText fontWeight="bold" fontSize="md" noOfLines={1}>
          {value || ''}
        </MText>
      ) : (
        value
      )}
    </>
  );
};
