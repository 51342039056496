import { Flex } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { CurrencyCodes, SUPPORTED_CURRENCIES } from '~app/constants/currencies';
import { useCurrencies } from '~app/hooks/useCurrencies';
import { MCustomSelectProps } from '~app/types/mCustomSelectTypes';
import { MCustomSelect } from './MCustomSelect/MCustomSelect';
import MText from './MText';

interface MCurrencySelectProps {
  useUnselectedCurrencies?: boolean;
}
interface MCurrencySelectProps
  extends Omit<
    MCustomSelectProps,
    | 'items'
    | 'itemTitle'
    | 'itemValue'
    | 'loading'
    | 'defaultValue'
    | 'showQueryInput'
  > {}

const MCurrencySelect = React.forwardRef<any, MCurrencySelectProps>(
  ({ useUnselectedCurrencies, ...rest }: MCurrencySelectProps, ref) => {
    const {
      currencies,
      defaultCurrency,
      isLoading: isLoadingCurrencies,
    } = useCurrencies();
    const [unselectedCurrencies, setUnselectedCurrencies] = useState<
      {
        code: string;
        description: string;
      }[]
    >(currencies);

    useEffect(() => {
      if (!useUnselectedCurrencies) {
        return;
      }
      const selectedCurrencies = new Set(currencies.map(({ code }) => code));
      setUnselectedCurrencies(
        Object.keys(SUPPORTED_CURRENCIES)
          .filter(
            (currency) => !selectedCurrencies.has(currency as CurrencyCodes),
          )
          .map((currency) => ({
            code: SUPPORTED_CURRENCIES[currency as CurrencyCodes].code,
            description: SUPPORTED_CURRENCIES[currency as CurrencyCodes].name,
          })),
      );
    }, [currencies, useUnselectedCurrencies]);

    return (
      <MCustomSelect
        placeholder="Currency"
        ref={ref}
        items={!useUnselectedCurrencies ? currencies : unselectedCurrencies}
        itemTitle="code"
        itemValue="code"
        loading={isLoadingCurrencies}
        defaultValue={defaultCurrency}
        showQueryInput
        renderItemContent={({ title, item, isSubtitle }) => {
          return (
            <Flex alignItems="center">
              <MText
                color="inherit"
                fontWeight="bold"
                isTruncated
                noOfLines={1}
                fontSize={isSubtitle ? '12px' : 'sm'}
                mr="2"
              >
                {title}
              </MText>
              <MText
                fontWeight={'normal'}
                color="inherit"
                isTruncated
                noOfLines={1}
                fontSize={isSubtitle ? '12px' : 'sm'}
              >
                {item.description}
              </MText>
            </Flex>
          );
        }}
        {...rest}
      />
    );
  },
);

export default MCurrencySelect;
