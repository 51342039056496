import { FilterTypeEqual, RenderTableFilterOptionProps } from '~app/types';
import { MFlex, MSwitch } from '../../chakra';
import MText from '../../MText';

export const ToggleFilterOptionContent = ({
  label,
  filter,
  filterOption,
  handleFilterChange,
}: RenderTableFilterOptionProps & { label: string }) => {
  const internalFilter = filter as FilterTypeEqual;
  const { options } = filterOption;

  const falseValue = options?.falseValue ?? '';
  const trueValue = options?.trueValue ?? '';
  const isChecked = internalFilter?.value === trueValue;

  const handleToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.checked ? trueValue : falseValue;
    handleFilterChange(newValue, filterOption);
  };

  return (
    <MFlex alignItems="center" my="2" justifyContent="space-between">
      <MText mr="4">{label}</MText>
      <MSwitch size="md" isChecked={isChecked} onChange={handleToggleChange} />
    </MFlex>
  );
};
