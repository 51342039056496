import { Fragment, useState } from 'react';
import {
  Control,
  Controller,
  FieldErrors,
  useFieldArray,
} from 'react-hook-form';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';
import {
  MBox,
  MButton,
  MCenterModal,
  MCloseButton,
  MCustomNumberInput,
  MDivider,
  MFlex,
  MFormField,
  MIcon,
  MInput,
  MText,
  RadioStyleButtonGroup,
} from '~app/components/Monetize';
import {
  RATE_BILLING_FREQUENCY_MAP,
  RATE_BILLING_FREQUENCY_NUMBER_MAP,
} from '~app/constants/offerings';
import { IRateReqSchemaUI, Maybe, RateBillingFrequencyEnum } from '~app/types';
import { frequencyOptions } from './RateSubscriptionFrequency';

interface AdditionalFrequencyModalProps {
  isOpen: boolean;
  control: Control<IRateReqSchemaUI>;
  errors: FieldErrors<IRateReqSchemaUI>;
  isAdditionalFrequencyRateLoading: boolean;
  billingFrequencyInMonths: Maybe<number>;
  billingFrequency: Maybe<RateBillingFrequencyEnum>;
  onClose: () => void;
  handleSaveOrUpdate: () => void;
}

export const AdditionalFrequencyModal = ({
  isOpen,
  control,
  errors,
  isAdditionalFrequencyRateLoading,
  onClose,
  handleSaveOrUpdate,
  billingFrequency,
  billingFrequencyInMonths,
}: AdditionalFrequencyModalProps) => {
  const isValid = Object.keys(errors).length === 0;
  const [expandedIds, setExpandedIds] = useState<Set<string>>(new Set());
  const [value, setValue] = useState<number>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: `additionalFrequencies`,
    keyName: 'id',
  });

  const additionalFrequenciesInMonths = fields.length
    ? fields.map(({ billingFrequencyInMonths }) => billingFrequencyInMonths)
    : [];

  const frequenciesAlreadyAdded = [
    RATE_BILLING_FREQUENCY_MAP[billingFrequency!](billingFrequencyInMonths)
      .month,
    ...additionalFrequenciesInMonths,
  ];

  return (
    <MCenterModal
      isOpen={isOpen}
      onClose={onClose}
      modalTitle="Create Additional Frequencies of Same Rate"
      subTitle={
        <>
          <MText fontWeight="400">
            Prices will automatically adjusted proportionally.
          </MText>
          <MText fontWeight="400">
            For example, a $100/month price would be adjusted to $1200/year.
            Prices may be edited after new Rates are created.
          </MText>
        </>
      }
      renderFooter={() => {
        return (
          <>
            <MButton
              isDisabled={isAdditionalFrequencyRateLoading}
              variant="cancel"
              onClick={onClose}
            >
              Cancel
            </MButton>
            <MButton
              ml={4}
              isLoading={isAdditionalFrequencyRateLoading}
              isDisabled={
                !fields.length || isAdditionalFrequencyRateLoading || !isValid
              }
              onClick={handleSaveOrUpdate}
            >{`Create ${fields.length} Rates`}</MButton>
          </>
        );
      }}
    >
      <MBox>
        <MFormField label="Subscription Frequency to Create">
          <MFlex gap="2" align="center">
            <MText>Every</MText>
            <MCustomNumberInput
              w="3.25rem"
              value={value || ''}
              onChange={(_, valueAsNumber: number) => setValue(valueAsNumber)}
            />
            <MText>Month(s)</MText>
          </MFlex>
        </MFormField>

        <MDivider my="2" />
        <RadioStyleButtonGroup
          name="renewalLength"
          containerProps={{ columns: 2 }}
          options={frequencyOptions}
          value={`${value || ''}` || undefined}
          defaultValue={`${value || ''}` || undefined}
          onChange={(value: string) => {
            setValue(+value);
          }}
          buttonProps={{
            _checked: {
              outline: 0,
            },
            _focus: {
              boxShadow: 'none',
            },
          }}
        />

        <MButton
          mt={4}
          minWidth={20}
          isDisabled={
            !value ||
            isAdditionalFrequencyRateLoading ||
            frequenciesAlreadyAdded.includes(value)
          }
          onClick={() => {
            if (value) {
              append({
                name: '',
                description: '',
                billingFrequency: [0, 1, 3, 6, 12].includes(value)
                  ? (RATE_BILLING_FREQUENCY_NUMBER_MAP[value]
                      .value as RateBillingFrequencyEnum)
                  : RateBillingFrequencyEnum.CUSTOM,
                billingFrequencyInMonths: value,
              });
            }
          }}
        >
          Add
        </MButton>
      </MBox>

      {fields.map((field, index) => {
        return (
          <Fragment key={field.id}>
            <MFlex
              cursor="pointer"
              align="center"
              gap="1"
              mt="2"
              onClick={() => {
                setExpandedIds((prevIds) => {
                  if (prevIds.has(field.id)) {
                    const next = new Set(prevIds);
                    next.delete(field.id);
                    return next;
                  } else {
                    return new Set(prevIds).add(field.id);
                  }
                });
              }}
            >
              <MIcon
                as={expandedIds.has(field.id) ? MdExpandLess : MdExpandMore}
                w={5}
                h={5}
              />
              <MText fontWeight="700" fontSize="lg">
                {RATE_BILLING_FREQUENCY_MAP[
                  field.billingFrequency as RateBillingFrequencyEnum
                ](field.billingFrequencyInMonths).label.replace('ly', '')}{' '}
                Rate
              </MText>
            </MFlex>
            {!expandedIds.has(field.id) && (
              <MFlex gap={2} key={field.id} ml={6}>
                <MFormField
                  w="13rem"
                  minW="13rem"
                  labelProps={{ display: 'block' }}
                  label="Rate Name"
                  isRequired
                  error={errors?.additionalFrequencies?.[index]?.name}
                >
                  <Controller
                    name={`additionalFrequencies.${index}.name`}
                    control={control}
                    render={({ field }) => <MInput {...field} />}
                  />
                </MFormField>
                <MFormField
                  labelProps={{ display: 'block' }}
                  label="Description"
                  isRequired
                  error={errors?.additionalFrequencies?.[index]?.description}
                >
                  <Controller
                    name={`additionalFrequencies.${index}.description`}
                    control={control}
                    render={({ field: { value, ...rest } }) => (
                      <MInput value={value || ''} {...rest} maxLength={80} />
                    )}
                  />
                </MFormField>
                <MCloseButton
                  alignSelf="flex-end"
                  h={8}
                  px={4}
                  onClick={() => remove(index)}
                />
              </MFlex>
            )}
          </Fragment>
        );
      })}
    </MCenterModal>
  );
};
