import { ButtonProps, Grid } from '@chakra-ui/react';
import { CalendarMonth, useDatePicker } from '@rehookify/datepicker';
import MButton from '../MButton';

interface MonthViewProps {
  months: CalendarMonth[];
  interval?: 1 | 3 | 6;
  anchorMonth?: number;
  onChange: (month: CalendarMonth) => void;
  monthBtnPropsFn: ReturnType<
    typeof useDatePicker
  >['propGetters']['monthButton'];
}

/**
 * Displays the years in a grid and allows user to select a year
 */
export const MonthView = ({
  months,
  interval = 1,
  anchorMonth = 1,
  onChange,
  monthBtnPropsFn,
}: MonthViewProps) => {
  return (
    <Grid
      gridTemplateColumns="repeat(3, minmax(0, 1fr))"
      rowGap="0.5rem"
      columnGap=".1rem"
      overflowY="auto"
    >
      {months.map((month, i) => {
        // Calculate the index of the month relative to the anchorMonth
        const relativeIndex = (i - anchorMonth + 1 + 12) % 12;

        // Check if the relative index is a multiple of the interval
        const isDisabled =
          relativeIndex % interval !== 0 || !!monthBtnPropsFn(month).disabled;

        return (
          <MonthButton
            key={month.month}
            calendarMonth={{
              ...month,
              disabled: isDisabled,
            }}
            {...monthBtnPropsFn(month)}
            disabled={isDisabled}
            onClick={() => !isDisabled && onChange(month)}
          />
        );
      })}
    </Grid>
  );
};

const MonthButton = ({
  calendarMonth,
  ...rest
}: {
  calendarMonth: CalendarMonth;
} & ButtonProps) => {
  const { month, active, disabled, selected } = calendarMonth;
  let buttonProps: ButtonProps = {};

  if (selected) {
    buttonProps = {
      ...buttonProps,
      bg: 'tIndigo.base',
      color: 'white',
      _hover: {
        bg: 'tIndigo.base',
      },
      _focus: {
        bg: 'tIndigo.base',
      },
    };
  }

  if (active) {
    buttonProps = { ...buttonProps, border: '1px solid tGray.lightPurple' };
  }

  return (
    <MButton
      variant="tertiary"
      minW="unset"
      h="32px"
      m={1}
      isDisabled={disabled}
      color="tPurple.dark"
      fontWeight={400}
      borderRadius="3"
      _focus={{
        bg: 'inherit',
      }}
      _focusVisible={{
        outlineStyle: 'auto !important',
        outlineWidth: '1px !important',
        outlineColor: '#4659DA !important',
      }}
      {...buttonProps}
      {...rest}
    >
      {month}
    </MButton>
  );
};
