import { DEFAULT_PAGER, IContactRespSchema } from '~app/types';
import { MCustomSelect, MFlex } from '../Monetize';
import { Highlighter } from '../Monetize/MPageSearchInput/Highlighter';
import { MCustomSelectProps } from '~app/types/mCustomSelectTypes';
import React, { useEffect, useState } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { handleApiErrorToast } from '~app/api/axios';
import { useGetAccountContacts } from '~app/api/contactsService';
import { CONTACTS } from '~app/constants';
import { useCtrlEnterHotkey } from '~app/hooks/useHotkeys';
import { sortAlphabetically } from '~app/utils';
import { MainSearchInputV2 } from '../Monetize/MCustomSelect/components/MainSearchInputV2';
import { ContactFormModal } from './ContactFormModal';

export const contactSelectRenderItem = ({
  item,
  query,
}: {
  item: IContactRespSchema;
  query: string;
}) => {
  const { email, fullName } = item as IContactRespSchema;
  return (
    <MFlex
      direction="column"
      overflow="hidden"
      whiteSpace="nowrap"
      textOverflow="ellipsis"
    >
      <Highlighter
        title={fullName}
        color="tPurple.dark"
        fontSize="sm"
        fontWeight="medium"
        textToHighlight={fullName}
        searchWords={[query]}
      />
      <Highlighter
        title={email}
        color="tGray.darkPurple"
        fontSize="xs"
        textToHighlight={email}
        searchWords={[query]}
      />
    </MFlex>
  );
};

interface ContactSelectProps extends MCustomSelectProps {
  onSuccess?: (data: IContactRespSchema[]) => void;
  accountId?: string;
  showAddContacts?: boolean;
  isLoading?: boolean;
  returnItem?: boolean;
}

export const ContactSelect = React.forwardRef<any, ContactSelectProps>(
  (
    {
      onSuccess,
      accountId,
      onChange,
      showAddContacts,
      isLoading,
      returnItem,
      ...rest
    }: ContactSelectProps,
    ref,
  ) => {
    const [contactQuery, setContactQuery] = useState('');

    const {
      isOpen: isContactModalOpen,
      onOpen: onOpenContactModal,
      onClose: onCloseContactModal,
    } = useDisclosure();

    useCtrlEnterHotkey(() => {
      if (!isContactModalOpen) {
        onCloseContactModalHandle();
      }
    });

    const onCloseContactModalHandle = (contact?: IContactRespSchema) => {
      if (contact) {
        // setValue('contactId', contact.id, {
        //   shouldValidate: true,
        //   shouldDirty: true,
        // });
        onChange?.(returnItem ? (contact as any) : (contact.id as any));
      }
      onCloseContactModal();
    };

    const { isLoading: isContactListLoading, data: contactList } =
      useGetAccountContacts(
        {
          isGetAll: true,
          accountId: accountId!,
          config: { ...DEFAULT_PAGER, rows: 100 },
        },
        {
          enabled: !!accountId,
          onError: (error) => handleApiErrorToast(error),
          select: (data) => {
            const content = data?.content || [];
            return content.sort(sortAlphabetically('fullName'));
          },
          onSuccess: (data) => {
            onSuccess?.(data || []);
          },
        },
      );

    const [iContacts, setIContacts] = useState(contactList);

    useEffect(() => {
      if (contactQuery) {
        setIContacts(
          contactList?.filter(
            (c) =>
              (c.fullName &&
                c.fullName
                  .toLowerCase()
                  .includes(contactQuery.toLowerCase())) ||
              (c.email &&
                c.email.toLowerCase().includes(contactQuery.toLowerCase())),
          ),
        );
      } else {
        setIContacts(contactList);
      }
    }, [contactList, contactQuery]);

    return (
      <>
        <MCustomSelect
          ref={ref}
          onChange={onChange}
          isLazy
          clearable
          useMainInputAsSearchInput
          MainInputComponent={MainSearchInputV2}
          placeholder="Select"
          itemTitle="fullName"
          itemValue="id"
          items={iContacts}
          loading={isContactListLoading || isLoading}
          useHighlightedItemList
          showItemDivider
          inputProps={{
            autoComplete: 'off',
          }}
          useExternalQuery
          externalQuery={contactQuery}
          onChangeQuery={setContactQuery}
          skipFilter={true}
          prependItems={
            showAddContacts
              ? [
                  {
                    item: CONTACTS.NEW_CONTACT,
                    isAction: true,
                    hasDivider: true,
                    onClick: ({ onClose }) => {
                      onClose && onClose();
                      onOpenContactModal();
                    },
                  },
                ]
              : []
          }
          renderItemContent={contactSelectRenderItem}
          returnItem={returnItem}
          {...rest}
        />
        <ContactFormModal
          isOpen={isContactModalOpen}
          onClose={onCloseContactModalHandle}
          accountId={accountId}
        />
      </>
    );
  },
);
