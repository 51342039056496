import React, { FunctionComponent as FC, useState } from 'react';
import { Box, Flex, Icon, Tooltip, Text, TextProps } from '@chakra-ui/react';
import { MdCheckCircle } from 'react-icons/md';
import { MTooltip } from './MTooltip';
import { copyToClipboard } from '~app/utils';
import { useNavigatorPermission } from '~app/hooks/useNavigatorPermission';

interface MClipboardCopyBoxProps extends TextProps {
  copyValue: string;
  displayText?: string;
  fontSize?: string;
  isDisabled?: boolean;
  tooltipMessage?: string;
  renderContent?: null | (() => React.ReactElement);
  onCopyContent?: ({
    copyToClipboard,
    setCopiedContent,
    setShowCopyTooltip,
  }: {
    copyToClipboard: (str: string) => void;
    setCopiedContent: (val: boolean) => void;
    setShowCopyTooltip: (val: boolean) => void;
  }) => void;
}

export const MClipboardCopyBox: FC<MClipboardCopyBoxProps> = React.forwardRef<
  any,
  MClipboardCopyBoxProps
>((props: MClipboardCopyBoxProps, ref) => {
  const {
    copyValue,
    displayText,
    fontSize,
    isDisabled: isDisabledProp,
    tooltipMessage,
    renderContent,
    onCopyContent,
    ...rest
  } = props;
  const [copiedContent, setCopiedContent] = useState<boolean>(false);
  const [showCopyTooltip, setShowCopyTooltip] = useState<boolean>(false);
  const { canCopyClipboard } = useNavigatorPermission();
  const isDisabled = isDisabledProp || !canCopyClipboard;

  const copyContent = (ev: React.MouseEvent<HTMLElement>) => {
    if (isDisabled) {
      return;
    }
    if (onCopyContent) {
      onCopyContent({
        copyToClipboard,
        setCopiedContent,
        setShowCopyTooltip,
      });
    } else {
      ev.stopPropagation();
      copyToClipboard(copyValue);
      setCopiedContent(true);
      setShowCopyTooltip(false);
      setTimeout(() => {
        setCopiedContent(false);
      }, 1000);
    }
  };

  const copiedLabel = !isDisabled && (
    <Flex alignItems="center">
      <Icon as={MdCheckCircle} w="4" h="4" mr="1" />
      <Text>Copied!</Text>
    </Flex>
  );

  // there is a bug with Chakra handling background colors of this tooltip that occurs only in test, so
  // this temporary workaround skips the custom color
  const bgColor = process.env.NODE_ENV === 'test' ? {} : { bg: 'tPurple.dark' };

  return (
    <Flex alignItems="center">
      <MTooltip
        hasArrow
        shouldWrapChildren
        label={tooltipMessage}
        isOpen={showCopyTooltip}
        placement="top-start"
        {...bgColor}
      >
        <Box
          onMouseEnter={() => !isDisabled && setShowCopyTooltip(true)}
          onMouseLeave={() => !isDisabled && setShowCopyTooltip(false)}
        >
          <Tooltip
            hasArrow
            label={copiedLabel}
            placement="top-start"
            isOpen={copiedContent}
            closeDelay={1000}
            {...bgColor}
          >
            {!renderContent ? (
              <Text
                fontSize={fontSize}
                fontWeight="normal"
                color="tGray.darkGrayPurple"
                onClick={copyContent}
                cursor={!isDisabled ? 'pointer' : 'default'}
                {...rest}
              >
                {displayText && (
                  <Text as="span" fontWeight="bold" mr={1}>
                    {displayText}
                  </Text>
                )}
              </Text>
            ) : (
              <Box
                onClick={copyContent}
                as="span"
                display="flex"
                alignItems="center"
              >
                {renderContent()}
              </Box>
            )}
          </Tooltip>
        </Box>
      </MTooltip>
    </Flex>
  );
});

MClipboardCopyBox.defaultProps = {
  tooltipMessage: 'Copy to Clipboard',
  fontSize: 'sm',
  isDisabled: false,
  renderContent: null,
};
