import { useDisclosure } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { UseInfiniteQueryResult } from '@tanstack/react-query';
import { addDays } from 'date-fns/addDays';
import { endOfDay } from 'date-fns/endOfDay';
import { formatISO } from 'date-fns/formatISO';
import { fromUnixTime } from 'date-fns/fromUnixTime';
import { getUnixTime } from 'date-fns/getUnixTime';
import { parseISO } from 'date-fns/parseISO';
import { startOfDay } from 'date-fns/startOfDay';
import { ColumnProps } from 'primereact/column';
import { useMemo, useState } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useGetUsageRecordsInfiniteLoad } from '~app/api/accountsService';
import { handleApiErrorToast } from '~app/api/axios';
import { useGetById } from '~app/api/queryUtils';
import { MFlex, MText } from '~app/components/Monetize';
import DataTableActions from '~app/components/Monetize/DataTable/MDataTableActions';
import {
  getAccountDetailRoute,
  getSubscriptionOverviewRoute,
} from '~app/constants/routes';
import { useACL } from '~app/services/acl/acl';
import {
  ApiListInfiniteScrollResponse,
  DEFAULT_PAGER,
  FilterType,
  FilterTypeOperator,
  IGetSubscriptionItemSchema,
  IGetSubscriptionSchema,
  IRateResSchema,
  IUsageEvent,
  IUsageEventListRequestSchema,
  IUsageTypeResSchema,
  SubscriptionBillingStatusEnum,
  TDataTablePager,
  UsageEventListRequestSchema,
} from '~app/types';
import { formatNumber } from '~app/utils';
import { replaceUserTimezoneWithUtc, toDateTimeShort } from '~app/utils/dates';
import { textBodyTemplate } from '~app/utils/tableUtils';

interface UseSubscriptionUsageParams {
  accountId: string;
  subscriptionId: string;
  productId: string;
}

interface UseSubscriptionUsageReturn {
  formData: Partial<UseFormReturn<IUsageEventListRequestSchema>>;
  getUsageRecordsQuery: UseInfiniteQueryResult<
    ApiListInfiniteScrollResponse<IUsageEvent>
  >;
  isUsageFormOpen: boolean;
  isManualUsageModalOpen: boolean;
  usageRecords: IUsageEvent[];
  totalElements: number;
  columns: ColumnProps[];
  filters: FilterType[];
  isCanceled: boolean;
  subscription: IGetSubscriptionSchema | undefined;
  subscriptionItem: IGetSubscriptionItemSchema | null;
  rate: IRateResSchema | undefined;
  isLoadingSubscription: boolean;
  activeUsageRecord: IUsageEvent | undefined;
  canUpdateUsage: boolean;
  pager: TDataTablePager;
  onOpenUsageForm: () => void;
  onCloseUsageForm: () => void;
  onOpenUsageUpload: () => void;
  onCloseUsageUpload: () => void;
  setActiveUsageRecord: (usageRecord: IUsageEvent | undefined) => void;
  setSubscriptionItem: (
    subscriptionItem: IGetSubscriptionItemSchema | null,
  ) => void;
}

export const useSubscriptionUsage = ({
  accountId,
  subscriptionId,
  productId,
}: UseSubscriptionUsageParams): UseSubscriptionUsageReturn => {
  const navigate = useNavigate();
  const { canDo } = useACL();

  const canUpdateUsage = canDo([['billing', 'update']]);

  // Modal Controls
  const {
    isOpen: isManualUsageModalOpen,
    onClose: onCloseUsageUpload,
    onOpen: onOpenUsageUpload,
  } = useDisclosure();

  const {
    isOpen: isUsageFormOpen,
    onOpen: onOpenUsageForm,
    onClose: onCloseUsageForm,
  } = useDisclosure();

  // State Management
  const [earliestAllowedEditDate, setEarliestAllowedEditDate] =
    useState<number>(() => getUnixTime(addDays(new Date(), -30)));
  const [subscriptionItem, setSubscriptionItem] =
    useState<IGetSubscriptionItemSchema | null>(null);
  const [activeUsageRecord, setActiveUsageRecord] = useState<
    IUsageEvent | undefined
  >();
  const [usageTypes, setUsageTypes] = useState<IUsageTypeResSchema[]>([]);

  const [pager] = useState<TDataTablePager>(() => ({
    ...DEFAULT_PAGER,
    rows: 100,
    sortField: 'timestamp',
    sortOrder: 1,
  }));

  const periodDefaultStartDate = replaceUserTimezoneWithUtc(
    formatISO(startOfDay(addDays(new Date(), -30))),
  ).toISOString();

  const periodDefaultEndDate = replaceUserTimezoneWithUtc(
    formatISO(endOfDay(new Date())),
  ).toISOString();

  const { control, setValue, getValues, watch, formState } =
    useForm<IUsageEventListRequestSchema>({
      resolver: zodResolver(UsageEventListRequestSchema),
      mode: 'onChange',
      defaultValues: {
        subscriptionId,
        usageTypeIds: [],
        startTime: periodDefaultStartDate,
        endTime: periodDefaultEndDate,
      },
    });

  // Watch form fields to trigger re-renders
  watch('startTime');
  watch('endTime');
  watch('usageTypeIds');

  const { data: subscription, isLoading: isLoadingSubscription } =
    useGetById<IGetSubscriptionSchema>('accountSubscriptions', subscriptionId, {
      enabled: !!subscriptionId,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        const subscriptionItemTemp = data?.subscriptionItems.find(
          (item) => item.product.id === productId,
        );
        if (subscriptionItemTemp) {
          setSubscriptionItem(subscriptionItemTemp);

          const startTime = startOfDay(
            parseISO(data.usagePeriodStart ?? periodDefaultStartDate),
          );
          setEarliestAllowedEditDate(getUnixTime(startTime));
          setValue('startTime', formatISO(startTime));

          setValue(
            'endTime',
            formatISO(
              endOfDay(parseISO(data.usagePeriodEnd ?? periodDefaultEndDate)),
            ),
          );
        } else {
          handleApiErrorToast(
            `The provided product does not exist on the subscription.`,
          );
          navigate(getSubscriptionOverviewRoute(accountId, subscriptionId));
          return;
        }

        if (
          subscriptionItemTemp?.product.usageTypes &&
          subscriptionItemTemp?.product.usageTypes.length > 0
        ) {
          setUsageTypes(subscriptionItemTemp?.product.usageTypes);
          setValue(
            'usageTypeIds',
            subscriptionItemTemp?.product.usageTypes.map(
              (usageType) => usageType.id,
            ) || [],
          );
        } else {
          handleApiErrorToast(`The provided product is not a usage product.`);
          navigate(getSubscriptionOverviewRoute(accountId, subscriptionId));
          return;
        }
      },
      onError: (err) => {
        handleApiErrorToast(err);
        navigate(getAccountDetailRoute(accountId));
        return null;
      },
    });

  const isCanceled =
    subscription?.billingStatus === SubscriptionBillingStatusEnum.CANCELED;

  const { data: rate } = useGetById<IRateResSchema>(
    'productCatalogRates',
    subscription?.rateId!,
    { enabled: !!subscription?.rateId!, refetchOnWindowFocus: false },
  );

  const payload = getValues();
  const getUsageRecordsQuery = useGetUsageRecordsInfiniteLoad(
    {
      ...payload,
      startTime: replaceUserTimezoneWithUtc(payload.startTime).toISOString(),
      endTime: replaceUserTimezoneWithUtc(payload.endTime).toISOString(),
    },
    pager,
    {
      enabled:
        !!payload.subscriptionId &&
        payload.usageTypeIds.length > 0 &&
        formState.isValid,
      onError: (err) => handleApiErrorToast(err),
    },
  );
  const { data: usageRecordsAllPages } = getUsageRecordsQuery;

  const usageRecords =
    usageRecordsAllPages?.pages.flatMap((page) => page.content) || [];
  const totalElements = usageRecordsAllPages?.pages?.[0].totalElements || 0;

  const filters = useMemo((): FilterType[] => {
    return [
      {
        key: 'startTime',
        value: replaceUserTimezoneWithUtc(payload.startTime).toISOString(),
        operator: FilterTypeOperator.EQUAL,
      },
      {
        key: 'endTime',
        value: replaceUserTimezoneWithUtc(payload.endTime).toISOString(),
        operator: FilterTypeOperator.EQUAL,
      },
      {
        key: 'subscriptionId',
        value: payload.subscriptionId,
        operator: FilterTypeOperator.EQUAL,
      },
      {
        key: 'usageTypeIds',
        value: payload.usageTypeIds.join(','),
        operator: FilterTypeOperator.EQUAL,
      },
      {
        key: 'sort',
        value: `${pager.sortField}:${pager.sortOrder === 1 ? 'asc' : 'desc'}`,
        operator: FilterTypeOperator.EQUAL,
      },
    ];
  }, [
    payload.endTime,
    payload.startTime,
    payload.subscriptionId,
    payload.usageTypeIds,
    pager.sortField,
    pager.sortOrder,
  ]);

  const columns: ColumnProps[] = useMemo(
    () => [
      {
        className: 'overflow-hidden table-cell-lg',
        field: 'id',
        header: 'ID',
        body: textBodyTemplate<IUsageEvent>('id'),
      },
      {
        className: 'overflow-hidden table-cell-lg',
        field: 'usageTypeId',
        header: 'Usage Type ID',
        body: textBodyTemplate<IUsageEvent>('usageTypeId'),
      },
      {
        field: 'timestamp',
        header: 'Timestamp (UTC)',
        sortable: false,
        body: (usageEvent: IUsageEvent) => (
          <MText>
            {toDateTimeShort(fromUnixTime(Number(usageEvent.timestamp)), 'UTC')}
          </MText>
        ),
      },
      {
        field: 'unitsConsumed',
        header: 'Units',
        sortable: false,
        body: (usageEvent: IUsageEvent) => {
          const usageType = usageTypes.find(
            ({ id }) => id === usageEvent.usageTypeId,
          );
          if (!usageType) {
            return null;
          }
          return (
            <MText>
              {formatNumber(usageEvent.unitsConsumed, {
                minimumFractionDigits: usageType?.decimalPlaces || 0,
                maximumFractionDigits: usageType?.decimalPlaces || 0,
              })}{' '}
              {usageType?.unitName}
            </MText>
          );
        },
      },
      {
        field: 'usageType',
        header: 'Type',
        sortable: false,
        body: (usageEvent: IUsageEvent) => {
          const usageType = usageTypes.find(
            ({ id }) => id === usageEvent.usageTypeId,
          );
          if (!usageType) {
            return null;
          }
          return <MText>{usageType?.name}</MText>;
        },
      },
      {
        field: 'action',
        header: '',
        sortable: false,
        body: (usageRecord: IUsageEvent) => {
          if (!canUpdateUsage) {
            return null;
          }
          return (
            <MFlex align="center" justify="flex-end">
              <DataTableActions
                actions={[
                  {
                    title: 'Edit',
                    enabled:
                      !isCanceled &&
                      usageRecord.timestamp >= earliestAllowedEditDate,
                    action: () => {
                      setActiveUsageRecord(usageRecord);
                      onOpenUsageForm();
                    },
                  },
                ]}
              />
            </MFlex>
          );
        },
      },
    ],
    [
      canUpdateUsage,
      earliestAllowedEditDate,
      isCanceled,
      usageTypes,
      onOpenUsageForm,
    ],
  );

  return {
    formData: {
      control,
      formState,
    },
    getUsageRecordsQuery,
    isUsageFormOpen,
    isManualUsageModalOpen,
    usageRecords,
    totalElements,
    columns,
    filters,
    isCanceled,
    subscription,
    rate,
    isLoadingSubscription,
    activeUsageRecord,
    subscriptionItem,
    canUpdateUsage,
    pager,
    onOpenUsageForm,
    onCloseUsageForm,
    onOpenUsageUpload,
    onCloseUsageUpload,
    setActiveUsageRecord,
    setSubscriptionItem,
  };
};
