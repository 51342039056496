import { TextProps } from '@chakra-ui/react';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';
import {
  MCustomIconButton,
  MFlex,
  MIcon,
  MText,
} from '~app/components/Monetize';

export const QuoteDocumentLink = ({
  fileName,
  disabled = false,
  isReadOnly = false,
  showDeleteIcon = false,
  showDivider = false,
  onClick,
  onRemoveItem,
  showLinkIcon = true,
  textStyles,
}: {
  fileName: string;
  showDeleteIcon?: boolean;
  disabled?: boolean;
  isReadOnly?: boolean;
  showDivider?: boolean;
  onClick: () => void;
  onRemoveItem?: () => void;
  showLinkIcon?: boolean;
  textStyles?: TextProps;
}) => {
  return (
    <MFlex
      background="tGray.support"
      border="tGray.lightPurple"
      alignItems="center"
      fontSize="sm"
      maxW={'100%'}
      minW="0"
    >
      {showLinkIcon && (
        <MIcon
          as={FaExternalLinkAlt}
          color="tGray.darkPurple"
          cursor="pointer"
          onClick={onClick}
        />
      )}
      <MText
        title={fileName}
        maxW={showDivider ? 'auto' : '15rem'}
        mx={1}
        noOfLines={1}
        fontStyle="italic"
        textDecoration="underline"
        cursor="pointer"
        onClick={onClick}
        {...textStyles}
      >
        {fileName}
      </MText>
      {showDeleteIcon && !disabled && !isReadOnly && (
        <MCustomIconButton
          icon={MdClose}
          data-testid="clear-item-btn"
          btnSize={4}
          containerSize={5}
          bg="none"
          iconColor={'tGray.darkPurple'}
          _hover={{
            bg: 'none',
            color: 'tGray.darkPurple',
          }}
          _focus={{
            bg: 'none',
          }}
          onClick={onRemoveItem}
        />
      )}
    </MFlex>
  );
};
