import { TextProps } from '@chakra-ui/react';
import { MdClose, MdOpenInNew } from 'react-icons/md';
import {
  MCustomIconButton,
  MFlex,
  MIcon,
  MText,
} from '~app/components/Monetize';

export const QuoteDocumentLinkV2 = ({
  fileName,
  disabled = false,
  isReadOnly = false,
  showDeleteIcon = false,
  showDivider = false,
  onClick,
  onRemoveItem,
  showLinkIcon = false,
  textStyles,
}: {
  fileName: string;
  showDeleteIcon?: boolean;
  disabled?: boolean;
  isReadOnly?: boolean;
  showDivider?: boolean;
  onClick: () => void;
  onRemoveItem?: () => void;
  showLinkIcon?: boolean;
  textStyles?: TextProps;
}) => {
  return (
    <MFlex alignItems="center" fontSize="sm" maxW={'100%'} minW="0">
      {showLinkIcon && (
        <MIcon
          as={MdOpenInNew}
          color="tPurple.base"
          cursor="pointer"
          fontSize="lg"
          onClick={onClick}
          mr="1"
        />
      )}
      <MText
        title={fileName}
        mr={2}
        noOfLines={showLinkIcon ? 2 : 1}
        fontStyle="italic"
        textDecoration="underline"
        cursor="pointer"
        onClick={onClick}
        isTruncated
        display="block"
        {...textStyles}
      >
        {fileName}
      </MText>
      {showDeleteIcon && !disabled && !isReadOnly && (
        <MCustomIconButton
          icon={MdClose}
          data-testid="clear-item-btn"
          btnSize={4}
          containerSize={5}
          bg="none"
          iconColor={'tGray.darkPurple'}
          onClick={onRemoveItem}
        />
      )}
    </MFlex>
  );
};
