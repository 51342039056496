import { useDisclosure } from '@chakra-ui/react';
import { forwardRef, useEffect, useState } from 'react';

import { handleApiErrorToast } from '~app/api/axios';
import { ExportEntityButtonUI } from '~app/components/Monetize/ExportEntityButtonUI';
import { EXPORT } from '~app/constants';
import { FilterType, IBillGroupResp, SortOrder } from '~app/types';
import { downloadInvoices } from '../utils';

interface BillingScheduleDownloadButtonProps {
  entity: EXPORT.ExportEntity;
  contractIds: string[];
  isDisabled?: boolean;
  /** Filers that can optionally be included in export, user can choose */
  filters?: FilterType[];
  searchKey?: string;
  searchTerm?: string;
  /** Filters to apply without asking for user consent */
  internalFilters?: FilterType[];
  sortField?: string;
  sortOrder?: SortOrder;
  /** Required for some entities if the endpoint has a path with ids */
  endpointParams?: string;
  /** Show modal no matter what */
  forceShowModal?: boolean;
  /** Transform results if needed */
  transformResults?: (results: any[]) => any[];
  customConfigElement?: React.ReactElement;
  onStateChanged?: ({ isLoading }: { isLoading: boolean }) => void;
  /** Add Internal Filters to the endpoint url without showing modal  */
  applyInternalFiltersWithoutConfirmation?: boolean;
  tooltipLabel?: string;
  billGroup: IBillGroupResp;
}

export const BillingScheduleDownloadButton = forwardRef(
  (
    {
      isDisabled = false,
      filters,
      forceShowModal = false,
      customConfigElement,
      onStateChanged,
      tooltipLabel = 'Export CSV',
      contractIds,
      billGroup,
    }: BillingScheduleDownloadButtonProps,
    ref,
  ) => {
    const showModal = forceShowModal || Boolean(filters?.length);
    const [isLoading, setIsLoading] = useState(false);
    const [applyFilters, setApplyFilters] = useState(true);
    const {
      isOpen: isModalOpen,
      onClose: closeModal,
      onOpen: openModal,
    } = useDisclosure();

    const handleButtonClick = async () => {
      if (showModal) {
        openModal();
      } else {
        handleDownload();
      }
    };

    const handleDownload = async () => {
      setIsLoading(true);
      try {
        await downloadInvoices({ billGroup, contractIds });
        setApplyFilters(true);
      } catch (ex) {
        handleApiErrorToast(ex);
      } finally {
        setIsLoading(false);
        closeModal();
      }
    };

    useEffect(() => {
      onStateChanged && onStateChanged({ isLoading });
    }, [isLoading, onStateChanged]);

    return (
      <ExportEntityButtonUI
        ref={ref}
        handleButtonClick={handleButtonClick}
        handleDownload={handleDownload}
        isDisabled={isDisabled}
        tooltipLabel={tooltipLabel}
        isLoading={isLoading}
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        customConfigElement={customConfigElement}
        applyFilters={applyFilters}
        setApplyFilters={setApplyFilters}
      />
    );
  },
);
