import { Link } from '@chakra-ui/react';
import { MHeading, MText } from '~components/Monetize';
import { SearchResult } from './AppSupport.types';

interface AppDocsResultItemProps {
  item: SearchResult;
}

export const AppDocsResultItem = ({ item }: AppDocsResultItemProps) => {
  const { url, _highlightResult } = item;
  return (
    <Link
      display="flex"
      flexDirection="column"
      href={url}
      target="_blank"
      p={2}
      mb={2}
      borderBottom="1px"
      borderBottomColor="tGray.back"
      _focus={{
        backgroundColor: 'tBlue.hover',
      }}
      _hover={{
        backgroundColor: 'tBlue.hover',
      }}
    >
      <MHeading
        as="h2"
        size="md"
        overflow="hidden"
        dangerouslySetInnerHTML={{
          __html: _highlightResult.title.value,
        }}
      />
      <MText
        noOfLines={3}
        dangerouslySetInnerHTML={{
          __html:
            _highlightResult.excerpt?.value || _highlightResult.body?.value,
        }}
      />
    </Link>
  );
};
