import { MBox, MGrid } from '~app/components/Monetize';
import { useFlags } from '~app/services/launchDarkly';
import { hasVariableAmountProduct } from '~app/utils/quotes';
import { QuoteCustomFields } from './components/QuoteCustomFields';
import QuoteFormBillingSchedule from './components/QuoteFormBillingSchedule';
import { QuoteFormConditionalTerms } from './components/QuoteFormConditionalTerms';
import { QuoteFormContractRow } from './components/QuoteFormContractRow';
import { QuoteFormDiscounts } from './components/QuoteFormDiscounts';
import { QuoteFormPricesSection } from './components/QuoteFormPricesSection';
import QuoteFormTerms from './components/QuoteFormTerms';
import { QuoteFormTotalRow } from './components/QuoteFormTotalRow';
import { QuoteFormTotalRowAmendV2 } from './components/QuoteFormTotalRowAmendV2';
import { useQuoteContext } from './quoteContext';
import { QuoteFormOfferingTable } from './QuoteFormOfferingTable';

const QuoteFormBody = () => {
  const { hideBillingSchedule } = useFlags();
  const { quoteData, useAmendmentV2 } = useQuoteContext();
  const { quote } = quoteData;

  const anyVariableAmountProducts = hasVariableAmountProduct(quote);

  return (
    <>
      <MBox height="12" />
      <QuoteFormOfferingTable />

      <MGrid
        gridTemplateColumns={useAmendmentV2 ? '1fr 2fr' : '8fr 3.2fr'}
        gridTemplateRows="1fr"
        columnGap={4}
        w="full"
      >
        <QuoteFormContractRow />

        {useAmendmentV2 ? (
          <QuoteFormTotalRowAmendV2
            anyVariableAmountProducts={anyVariableAmountProducts}
          />
        ) : (
          <QuoteFormTotalRow
            anyVariableAmountProducts={anyVariableAmountProducts}
          />
        )}
      </MGrid>

      <QuoteCustomFields />

      {!hideBillingSchedule &&
        quote?.quoteOfferings &&
        quote?.quoteOfferings.length > 0 && (
          <QuoteFormBillingSchedule
            loading={quoteData.billingScheduleLoading}
            mt={8}
            borderWidth="1px"
            borderRadius="md"
            borderColor="tGray.lightPurple"
            itemProps={{ border: 'none' }}
            anyVariableAmountProducts={anyVariableAmountProducts}
          />
        )}

      {quote && (
        <QuoteFormDiscounts
          quote={quote}
          mt={8}
          borderWidth="1px"
          borderRadius="md"
          borderColor="tGray.back"
          itemProps={{ border: 'none' }}
        />
      )}
      {quote?.id && (
        <QuoteFormPricesSection
          quoteId={quote?.id}
          currency={quote?.currency}
          mt={8}
          borderWidth="1px"
          borderRadius="md"
          borderColor="tGray.back"
          itemProps={{ border: 'none' }}
        />
      )}
      <QuoteFormTerms mt={8} />
      <QuoteFormConditionalTerms mt={8} />
      <MBox height="100px" />
    </>
  );
};

export default QuoteFormBody;
