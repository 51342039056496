import { useQuery } from '@tanstack/react-query';
import { apiGet, apiPut } from '~api/axios';
import {
  OpportunityResponseSchema,
  OpportunitySearchResponse,
  OpportunitySearchResponseItem,
} from '~app/types';

export const crmServiceQueryKeys = {
  base: ['crm'] as const,
  opportunities: () => [...crmServiceQueryKeys.base, 'opportunities'] as const,
};

// link an opportunity id to a quote. customId = opportunityId
export async function doLinkOpportunityToQuote(
  quoteId: string,
  customId: string,
): Promise<OpportunityResponseSchema> {
  const res = await apiPut<OpportunityResponseSchema>(
    `/api/opportunities/customId/${customId}/quotes/${quoteId}/link`,
  );
  return res.data;
}

/**
 * Search for opportunities in either Hubspot or Salesforce
 *
 * @param accountId
 * @param searchTerm
 * @param options
 * @returns
 */
export function useSearchCrmOpportunities<
  SelectData = OpportunitySearchResponseItem[],
>(
  accountId: string,
  searchTerm: string,
  options: {
    enabled?: boolean;
    select?: (err: OpportunitySearchResponseItem[]) => SelectData;
    onError?: (err: unknown) => void;
    onSuccess?: (data: SelectData) => void;
  } = {},
) {
  return useQuery(
    [...crmServiceQueryKeys.opportunities(), accountId, searchTerm],
    {
      queryFn: () => doSearchCrmOpportunities(accountId, searchTerm),
      keepPreviousData: true,
      retry: false,
      refetchOnWindowFocus: false,
      ...options,
    },
  );
}

export function doSearchCrmOpportunities(
  accountId: string,
  searchTerm: string,
) {
  return apiGet<OpportunitySearchResponse>(
    `/connector/crm/salesforce/search/accounts/${accountId}/opportunities`,
    { params: { q: searchTerm } },
  ).then((res) => res.data.results);
}
