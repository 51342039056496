import React, { FunctionComponent, PropsWithChildren } from 'react';
import { Tr, Td } from '@chakra-ui/table';
import { MFlex, MText, MTooltip } from '~components/Monetize';
import { Icon } from '@chakra-ui/react';
import { InfoIcon } from '~app/assets/icons';
interface SubscriptionFooterRowProps {
  name: string;
  amount: number | string;
  nameProps?: {};
  colSpan?: number;
  discountInfo?: boolean;
}

export const SubscriptionFooterRow: FunctionComponent<
  SubscriptionFooterRowProps
> = (props: PropsWithChildren<SubscriptionFooterRowProps>) => {
  const { colSpan, name, nameProps, amount, discountInfo } = props;
  return (
    <Tr>
      <Td colSpan={colSpan} border={0} />
      <Td
        colSpan={2}
        fontWeight="bold"
        fontSize="sm"
        color="tPurple.base"
        p="16px !important"
        {...nameProps}
      >
        <MFlex
          w="100%"
          flexDir="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <MText fontWeight={600} fontSize={16}>
            {name}
          </MText>
          <MFlex gap=".5rem" alignItems="center">
            {discountInfo && (
              <MTooltip
                shouldWrapChildren
                label="The positive discount number is a result of reducing an existing discount (or quantity of discounted items)."
                placement="bottom"
              >
                <Icon as={InfoIcon} />
              </MTooltip>
            )}
            <MText
              textAlign="right"
              fontWeight={name === 'Discount' ? 'normal' : 600}
              fontSize={16}
              data-testid={`Total ${name}`}
            >
              {amount}
            </MText>
          </MFlex>
        </MFlex>
      </Td>
    </Tr>
  );
};

SubscriptionFooterRow.defaultProps = {
  colSpan: 4,
  nameProps: {},
};
