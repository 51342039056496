import { z } from 'zod';
import { getRequiredMessage } from '~app/utils/messages';
import { roundNumberToDecimal } from '~app/utils/numberUtils';
import { IAccount } from './accountTypes';
import { UseEntityDataResponseType } from './componentTypes';
import { ContactRespSchema, IContactRespSchema } from './contactTypes';
import { IBaseResponse } from './miscTypes';
import { IPaymentGateway } from './paymentGatewayTypes';

export const PaymentMethodContactSchema = z.object({
  fullName: z.string(),
  email: z.string().nullish(),
  phone: z.string().nullish(),
  title: z.string().nullish(),
  line1: z.string().nullish(),
  line2: z.string().nullish(),
  city: z.string().nullish(),
  state: z.string().nullish(),
  postalCode: z.string().nullish(),
  country: z.string().nullish(),
});

export type IPaymentMethodContactSchema = z.infer<
  typeof PaymentMethodContactSchema
>;

/**
 * Data model for form, does not match API request structure
 */
export const MakePaymentFormSchemaBase = z.object({
  type: z.enum(['manual', 'gateway']),
  paymentMethodId: z.string().nullish(),
  amount: z
    .union([z.string(), z.number()])
    .nullish()
    .refine((val) => val !== null && val !== undefined && val !== '', {
      message: getRequiredMessage('Amuont'),
    })
    // .transform((val) => roundNumberToDecimal(Number(val), 2)),
    .transform((val) => roundNumberToDecimal(Number(val), 2)),
  paymentDate: z.string({
    required_error: getRequiredMessage('Payment Date'),
  }),
  referenceNo: z.string().nullish(),
  description: z.string().max(255).nullish(),
});

export const MakePaymentFormSchema = MakePaymentFormSchemaBase.superRefine(
  (data, ctx) => {
    if (data.type === 'gateway') {
      if (!data.paymentMethodId) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `Payment method is required`,
          path: ['paymentMethodId'],
        });
      }
    }
  },
);

export type MakePaymentForm = z.infer<typeof MakePaymentFormSchema>;

export const ManualPaymentUpdateReqSchema = MakePaymentFormSchemaBase.pick({
  paymentDate: true,
  referenceNo: true,
  description: true,
});

export type ManualPaymentUpdateReq = z.infer<
  typeof ManualPaymentUpdateReqSchema
>;

export const GatewayPaymentSchema = z.object({
  id: z.string().nonempty('Required'),
  amount: z.number().nonnegative('Must be a positive number'),
});
export type IGatewayPayment = z.infer<typeof GatewayPaymentSchema>;

export const ManualPaymentSchema = z.object({
  amount: z.number(),
  paymentDate: z.string().nonempty(getRequiredMessage('Payment Date')),
  referenceNo: z.string().nullish(),
  description: z.string().max(255).nullish(),
});
export type IManualPayment = z.infer<typeof ManualPaymentSchema>;

// from POST /api/accounts/{accountId}/paymentMethods
export const PaymentMethodReqSchema = z.object({
  billingDetails: PaymentMethodContactSchema,
  paymentMethodName: z.string().min(1, { message: getRequiredMessage('Name') }),
  paymentToken: z.string(),
  paymentGatewayId: z.string(),
  customId: z.string().nullish(),
  description: z.string().nullish(), // FE no longer using this
  oneTime: z.boolean(),
  testPaymentMethod: z.boolean(), // not used by FE, probably not by BE either
});
export type IPaymentMethodReq = z.infer<typeof PaymentMethodReqSchema>;

export const ImportPaymentMethodReqSchema = z.object({
  gatewayAccountId: z
    .string()
    .nonempty(getRequiredMessage('Payment Account Id')),
  gatewayId: z.string().nonempty(getRequiredMessage('Gateway Account Id')),
});
export type IImportPaymentMethodReqSchema = z.infer<
  typeof ImportPaymentMethodReqSchema
>;

// represents the only 2 fields the user submits via the UI, other fields come from Stripe or are unused
export const PaymentMethodReqSchemaUI = PaymentMethodReqSchema.pick({
  paymentMethodName: true,
}).extend({
  /** contactId is used to handle the UI selection, but then all data gets populated on billingDetails */
  contactId: z.string().nonempty(getRequiredMessage('Contact')),
  /** This is set from the contactId, and this turns into billingDetails on actual request */
  contact: ContactRespSchema.nullish(),
});
export type IPaymentMethodReqUI = z.infer<typeof PaymentMethodReqSchemaUI>;

export enum PaymentMethodStatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  CANCELED = 'CANCELED',
  EXPIRED = 'EXPIRED',
}
export const PaymentMethodStatusEnumZ = z.nativeEnum(PaymentMethodStatusEnum);

export enum PaymentMethodTypeEnum {
  CREDIT_CARD = 'CREDIT_CARD',
  ACH_CREDIT = 'ACH_CREDIT',
  US_BANK_TRANSFER = 'US_BANK_TRANSFER',
  DIRECT_DEBIT = 'DIRECT_DEBIT',
  MANUAL = 'MANUAL',
}
export const PaymentMethodTypeEnumZ = z.nativeEnum(PaymentMethodTypeEnum);

export enum CreditCardTypeEnum {
  VISA = 'VISA',
  MASTERCARD = 'MASTERCARD',
  DISCOVER = 'DISCOVER',
  AMEX = 'AMEX',
  DINERS = 'DINERS',
  JCB = 'JCB',
  UNIONPAY = 'UNIONPAY',
}
export const CreditCardTypeEnumZ = z.nativeEnum(CreditCardTypeEnum);

export type IPaymentMethodResp = IBaseResponse & {
  paymentMethodName: string;
  paymentMethodType: PaymentMethodTypeEnum;
  paymentGateway: IPaymentGateway;
  account?: IAccount;
  addressValidation?: any;
  gatewayCustomerId: string;
  /** @deprecated - use billingDetails */
  contact?: IContactRespSchema;
  billingDetails: IPaymentMethodContactSchema;
  creditCardType: CreditCardTypeEnum;
  creditCardGateWayToken?: any;
  creditCardNumber?: any;
  cvcValidation?: boolean;
  expirationMonth?: number;
  expirationYear?: number;
  customId?: string;
  defaultPaymentMethod?: boolean;
  description?: string;
  externalPaymentMethodId?: string;
  lastFour?: string;
  oneTime?: false;
  postalValidation?: any;
  status?: PaymentMethodStatusEnum;
  testPaymentMethod?: boolean;
  userId?: string;
  amount?: string;
  date?: string;
  referenceNo?: string;
  comment?: string;
  accountType?: string; // Direct Debit
  bankName?: string; // Direct Debit, ACH Credit
  routingNumber?: string; // Direct Debit, ACH Credit
  swiftCode?: string; // ACH Credit
  accountNumber?: string; // ACH Credit
};

export type PaymentMethodSummaryResponse = {
  id: string;
  name: string;
  status: PaymentMethodStatusEnum;
  paymentMethodType: PaymentMethodTypeEnum;
  description?: string;
  lastFour?: string;
  creditCardType?: string;
  expirationMonth?: number;
  expirationYear?: number;
  bankName?: string;
};

export type PaymentMethodsDataType = Omit<
  UseEntityDataResponseType<IPaymentMethodResp>,
  'loadData'
> & {
  selected: any;
  setSelected: any;
  openCreateModal: any;
  openEditModal: any;
};
export type ImportablePaymentMethodItem = {
  type: PaymentMethodTypeEnum;
  id: null | string;
  externalId: string;
  lastFour: string;

  routingNumber?: string;
  bankName?: string;
  accountType?: string;
  accountHolderType?: string;

  cardType?: string;
  expirationYear?: number;
  expirationMonth?: number;
  cvcValidation?: boolean;
  addressValidation?: boolean;
  postalValidation?: boolean;

  swiftCode?: string;
  accountNumber?: string;
};
export type ImportablePaymentMethodResp = {
  gatewayAccount: {
    id: string;
    name: string;
    email: string;
  };
  paymentMethods: ImportablePaymentMethodItem[];
};
export type ImportPaymentMethodResp = {
  gatewayAccount: {
    id: string;
    name: string;
    email: string;
  };
  succeededImports: ImportablePaymentMethodItem[];
  failedImports: {
    externalPaymentMethodId: string;
    reason: string;
  }[];
  existingPaymentMethods: ImportablePaymentMethodItem[];
};

export enum ImportStep {
  SELECT_GATEWAY_ACCOUNT_ID = 1,
  SELECT_PAYMENT_METHOD = 2,
  SUBMITTED_PAYMENT_METHOD = 3,
}
