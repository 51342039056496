import noop from 'lodash/noop';
import { handleApiErrorToast } from '~app/api/axios';
import { useGetQuoteBillingSchedule } from '~app/api/cpqService';
import { IQuoteBillingScheduleRespSchema } from '~app/types';
import { useFlags } from '../services/launchDarkly';

export interface QuoteDataTypes {
  loading: boolean;
  quoteBillingSchedule?: IQuoteBillingScheduleRespSchema | null;
  fetchBillingSchedule: () => void;
}

export const useBillingSchedule = (quoteId: string): QuoteDataTypes => {
  const { allowBillingScheduleV2 } = useFlags();

  const {
    data: quoteBillingSchedule,
    refetch: fetchBillingSchedule,
    isFetching: loading,
  } = useGetQuoteBillingSchedule(
    quoteId,
    allowBillingScheduleV2 ? 'V2' : 'V1',
    {
      onError: (error) => handleApiErrorToast(error),
    },
  );

  return {
    loading,
    quoteBillingSchedule: quoteBillingSchedule,
    fetchBillingSchedule: quoteId ? fetchBillingSchedule : noop,
  };
};
