import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const baseStyle = defineStyle({
  minW: 'initial',
  _hover: {
    backgroundColor: 'tBlue.hover',
    _disabled: {
      backgroundColor: 'none',
    },
  },
});

const sizes = {
  sm: defineStyle({
    w: 3,
    h: 4,
    fontSize: '10px',
    p: 3,
  }),
  md: defineStyle({
    w: 6,
    h: 6,
    fontSize: '12px',
  }),
  lg: defineStyle({
    w: 5,
    h: 5,
    fontSize: '1.25rem',
  }),
};

export const closeButtonTheme = defineStyleConfig({
  baseStyle,
  sizes,
  defaultProps: {
    size: 'md',
  },
});

export default closeButtonTheme;
