import { radioAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import Checkbox from './Checkbox';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(radioAnatomy.keys);

const baseStyle = definePartsStyle((props) => ({
  control: {
    ...Checkbox.baseStyle?.(props).control,
    borderRadius: 'full',
    borderWidth: '1.25px',
    borderColor: 'tPurple.dark',
    color: 'tPurple.dark',
    fontWeight: '400',
    _checked: {
      background: 'transparent',
      borderColor: 'tIndigo.base',
      _before: {
        content: `""`,
        display: 'inline-block',
        pos: 'relative',
        w: '2',
        h: '2',
        borderRadius: '50%',
        bg: 'tIndigo.base',
      },
    },
    _disabled: {
      backgroundColor: 'transparent',
      borderColor: 'tGray.acGray',
      _checked: {
        background: 'transparent',
        borderColor: 'tGray.acGray',
        _before: {
          background: 'tGray.acGray',
        },
      },
    },
    _focus: {
      boxShadow: 'none',
    },
  },
  label: {
    ...Checkbox.baseStyle?.(props).label,
    color: 'tPurple.dark',
    _disabled: {
      opacity: 1,
      color: 'tGray.acGray',
    },
  },
}));

const sizes = {
  md: definePartsStyle({
    control: { w: 3.5, h: 3.5 },
    label: { fontSize: 'sm' },
  }),
  lg: definePartsStyle({
    control: { w: 5, h: 5 },
    label: { fontSize: 'lg' },
  }),
  sm: definePartsStyle({
    control: { width: 3, height: 3 },
    label: { fontSize: 'sm' },
  }),
};

export const radioTheme = defineMultiStyleConfig({
  baseStyle,
  sizes,
  defaultProps: {
    size: 'md',
    colorScheme: 'tIndigo',
  },
});

export default radioTheme;
