const QuoteSettingIcon = (props: any) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2603_514)">
        <path
          d="M14.5 11.5H18.5V7.5H14.5V11.5ZM5.5 11.5H9.5V7.5H5.5V11.5ZM15.65 17L17.65 13H13V6H20V13.2L18.1 17H15.65ZM6.65 17L8.65 13H4V6H11V13.2L9.1 17H6.65Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_2603_514">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default QuoteSettingIcon;
