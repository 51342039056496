import { isBefore } from 'date-fns/isBefore';
import { parseISO } from 'date-fns/parseISO';
import { startOfDay } from 'date-fns/startOfDay';
import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { QUOTE_NOT_ACTIONABLE_STATUSES } from '~app/constants/quotes';
import {
  ContractStatusEnum,
  IContractWithQuotes,
  IQuoteRespSchema,
  QuoteAmendmentVersionEnum,
  QuoteItemAmendmentStatusEnum,
  QuoteTypeEnum,
} from '~app/types';
import { toDateShort } from '~app/utils/dates';
import { QuoteBannerItem } from './QuoteBannerItem';
import { bannerDismissedSelector } from './quoteBanner.utils';

export function ContractWillBeCancelledBanner({
  contract,
  quote,
}: {
  contract: IContractWithQuotes;
  quote: IQuoteRespSchema;
}) {
  const [dismissed, setDismissed] = useRecoilState(
    bannerDismissedSelector([quote.id, 'ContractWillBeCancelledBanner']),
  );
  const [willContractClose, setWillContractClose] = useState(false);
  const [maxOfferingEndDate, setMaxOfferingEndDate] = useState<string | null>(
    null,
  );

  useEffect(() => {
    if (!dismissed) {
      const maxOfferingEndDateValue = calculateCancellationDate(quote);

      setMaxOfferingEndDate(maxOfferingEndDateValue?.dateStr || null);

      // if max offering end date is before contract end date
      setWillContractClose(
        !!maxOfferingEndDateValue?.date &&
          isBefore(
            maxOfferingEndDateValue?.date,
            startOfDay(parseISO(quote.contractEndDate)),
          ),
      );
    }
  }, [dismissed, quote]);

  if (
    // Avoid showing the banner if the contract is already canceled
    contract.status === ContractStatusEnum.CANCELED ||
    quote.type !== QuoteTypeEnum.AMENDMENT ||
    QUOTE_NOT_ACTIONABLE_STATUSES.has(quote.status) ||
    !willContractClose ||
    dismissed
  ) {
    return null;
  }

  return (
    <QuoteBannerItem onDismiss={() => setDismissed(true)} type="info">
      Upon processing this Quote, the Contract will be canceled as of{' '}
      {toDateShort(maxOfferingEndDate)} and cannot be renewed.
    </QuoteBannerItem>
  );
}

function calculateCancellationDate(quote: IQuoteRespSchema) {
  if (quote.quoteOfferings.length === 0) {
    return null;
  }

  switch (quote.amendmentVersion) {
    case QuoteAmendmentVersionEnum.v1: {
      const areAllOfferingsRemoved = quote.quoteOfferings.every(
        (quoteOffering) =>
          // Removed items should not be considered
          quoteOffering.items.some(
            (item) =>
              item.amendmentStatus === QuoteItemAmendmentStatusEnum.REMOVED,
          ),
      );
      return areAllOfferingsRemoved && quote.contractAmendmentDate
        ? {
            date: startOfDay(parseISO(quote.contractAmendmentDate)),
            dateStr: quote.contractAmendmentDate,
          }
        : null;
    }
    case QuoteAmendmentVersionEnum.v2:
    default: {
      return quote.quoteOfferings
        .filter((quoteOffering) =>
          // Removed items should not be considered
          quoteOffering.items.some(
            (item) =>
              item.amendmentStatus !== QuoteItemAmendmentStatusEnum.REMOVED,
          ),
        )
        .reduce(
          (prev, curr) =>
            isBefore(prev.date, startOfDay(parseISO(curr.endDate)))
              ? {
                  date: startOfDay(parseISO(curr.endDate)),
                  dateStr: curr.endDate,
                }
              : prev,
          {
            date: startOfDay(parseISO(quote.contractStartDate)),
            dateStr: quote.contractStartDate,
          },
        );
    }
  }
}
