import { Icon, useDisclosure } from '@chakra-ui/react';
import { ColumnProps } from 'primereact/column';
import { useEffect, useRef, useState } from 'react';
import { MdLink } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { useGetTenantById } from '~api/tenantService';
import {
  doGetInviteLink,
  useAddOrDeleteUserRoles,
  useCancelOrResendInvite,
  useGetUserRoles,
  useGetUsersListByTenantId,
} from '~api/usersService';
import { handleApiErrorToast } from '~app/api/axios';
import { usePerformEntityAction } from '~app/api/queryUtils';
import { MDataTableFilter } from '~app/components/Monetize/DataTable';
import DataTableActions, {
  ActionType,
} from '~app/components/Monetize/DataTable/MDataTableActions';
import { ExportTableButton } from '~app/components/Monetize/ExportEntityButton';
import InviteForm, {
  handleTransformRoleLabel,
} from '~app/components/Users/InviteForm';
import { ROUTES, USERS } from '~app/constants';
import { USER_ROLES_DISPLAY } from '~app/constants/roles';
import { userStatusItems } from '~app/constants/users';
import { useACL } from '~app/services/acl/acl';
import { useAuth } from '~app/services/auth0';
import { useDocumentHead } from '~app/services/documentHead';
import { useFlags } from '~app/services/launchDarkly';
import { useToast } from '~app/services/toast';
import { tableFilterSelector } from '~app/store/global.store';
import { FilterStateKeys } from '~app/store/store.types';
import { buildFilterParamsRequestObject } from '~app/utils';
import { sendResetPassword } from '~app/utils/auth0';
import { textBodyTemplate } from '~app/utils/tableUtils';
import {
  MBox,
  MButton,
  MDataTable,
  MFlex,
  MPageContainer,
  MPageLoader,
  MPageSearchInput,
  MSettingsPageHeader,
  MSpinner,
  MText,
} from '~components/Monetize';
import { MClipboardCopyBox } from '~components/Monetize/MClipboardCopyBox';
import {
  DEFAULT_PAGER,
  FilterType,
  FilterTypeOperator,
  GetListApiFilter,
  IUser,
  IUserInviteLink,
  IUserRole,
  TDataTablePager,
  UserRoleEnum,
  UserStatusEnum,
} from '~types';
import { UserRoleDropdown } from './UserRoleDropdown';

const ROLE_PAGER: TDataTablePager = {
  ...DEFAULT_PAGER,
  sortField: 'name',
  sortOrder: 1,
};

interface IUserInviteLinkObject {
  userId: string;
  link?: IUserInviteLink;
}

export const ManageUsers = () => {
  const { metabaseReporting } = useFlags();
  const { setDocTitle } = useDocumentHead();
  const navigate = useNavigate();
  const [initialFilters, persistTableFilter] = useRecoilState(
    tableFilterSelector(FilterStateKeys.USER_LIST),
  );
  const { currentTenant, userId } = useAuth();
  const { canDo } = useACL();
  const canInviteUpdateRoles = canDo([
    ['users', 'create'],
    ['users', 'update'],
  ]);

  const tenantId = currentTenant?.id || '';
  const [pager, setPager] = useState<TDataTablePager>(DEFAULT_PAGER);
  const [filters, setFilters] = useState<FilterType[]>(initialFilters);
  const filterComponentReset = useRef<any>(null);

  const filterOptions = [
    {
      title: 'Status',
      key: 'status',
      operator: FilterTypeOperator.IN,
      items: userStatusItems,
    },
  ];

  const [globalFilter, setGlobalFilter] = useState<string>('');
  const [inviteLinks, setInviteLinks] = useState<IUserInviteLinkObject[]>([]);
  const [copyLink] = useState<string>('');
  const [searchKey] = useState<string>('name');
  const [filterParams, setFilterParams] = useState<GetListApiFilter>(() =>
    buildFilterParamsRequestObject(filters, globalFilter, searchKey),
  );

  const { data: tenant, isLoading: isLoadingTenant } = useGetTenantById(
    tenantId,
    {
      enabled: !!tenantId,
      onError: (error) => {
        handleApiErrorToast(error);
        navigate(ROUTES.TENANT_LIST);
      },
    },
  );

  const {
    isOpen: isInviteModalOpen,
    onOpen: onOpenInviteModal,
    onClose: onCloseInviteModal,
  } = useDisclosure();

  const { addToast } = useToast();

  const { data: listData, isLoading: getUserListLoading } =
    useGetUsersListByTenantId<IUser>({
      tenantId,
      config: pager,
      filters: filterParams,
      axiosConfig: { customXTenantId: tenantId },
    });

  const { data: userRoles, isLoading: roleLoading } = useGetUserRoles<
    IUserRole[]
  >(
    ROLE_PAGER,
    { name: { contains: globalFilter } },
    {
      axiosConfig: { customXTenantId: tenantId },
      select: (data) => {
        if (!Array.isArray(data?.content)) {
          return [];
        }
        return (
          data.content
            .map((role) => {
              return {
                roleName: handleTransformRoleLabel(role.name),
                ...role,
              };
            })
            // Require feature flag to view REPORTING role
            .filter(
              (role) =>
                role.name !== UserRoleEnum.REPORTING || metabaseReporting,
            )
            // FIXME: SALES_MANAGER is not implemented on backend, removing from list of selectable options
            // https://monetizenow-workspace.slack.com/archives/C01T5UN56P7/p1668629531475229
            .filter((role) => role.name !== UserRoleEnum.SALES_MANAGER)
        );
      },
    },
  );

  useEffect(() => {
    persistTableFilter(filters);
  }, [filters, persistTableFilter]);

  useEffect(() => {
    setDocTitle('Settings', 'Users');
  }, []);

  useEffect(() => {
    setFilterParams(
      buildFilterParamsRequestObject(filters, globalFilter, searchKey),
    );
  }, [pager, globalFilter, filters, searchKey]);

  const doActivateDeactivateUser = usePerformEntityAction('tenantUser', {
    axiosConfig: { customXTenantId: tenantId },
    onError: (error) => handleApiErrorToast(error),
  });

  const doCancelOrResendInvite = useCancelOrResendInvite({
    onError: (error) => handleApiErrorToast(error),
  });

  const doAddOrDeleteUserRoles = useAddOrDeleteUserRoles({
    onError: (error) => handleApiErrorToast(error),
  });

  const loading = getUserListLoading || isLoadingTenant || roleLoading;

  const onChangeRole = async (user: IUser, ev: IUserRole[]) => {
    const previousRoleIds = new Set(user.roles.map(({ id }) => id));
    const idsToAdd: string[] = [];

    ev.forEach(({ id }) => {
      if (previousRoleIds.has(id)) {
        previousRoleIds.delete(id);
      } else {
        idsToAdd.push(id);
      }
    });

    const idsToRemove = Array.from(previousRoleIds);

    doAddOrDeleteUserRoles.mutate({
      tenantId: tenantId,
      userId: user.id || '',
      idsToAdd,
      idsToRemove,
      userRoles,
    });
  };

  const actionBodyTemplate = (rowData: IUser) => {
    const isCurrentUser = userId === rowData.id;

    const actions: ActionType[] = [
      {
        title: 'Activate',
        enabled:
          !isCurrentUser && rowData.status === UserStatusEnum.DEACTIVATED,
        action: () =>
          doActivateDeactivateUser.mutateAsync({
            id: rowData.id || '',
            action: 'activate',
          }),
      },
      {
        title: 'Resend Invite',
        enabled:
          !isCurrentUser &&
          canInviteUpdateRoles &&
          rowData.status === UserStatusEnum.INVITED,
        action: () =>
          doCancelOrResendInvite.mutateAsync({
            action: 'resend',
            userId: rowData.id || '',
          }),
      },
      {
        title: 'Cancel Invite',
        enabled:
          !isCurrentUser &&
          canInviteUpdateRoles &&
          rowData.status === UserStatusEnum.INVITED,
        color: 'danger',
        action: async () => {
          const data = await doCancelOrResendInvite.mutateAsync({
            action: 'cancel',
            userId: rowData.id || '',
          });
          if (data) {
            addToast({
              summary: 'Invitation Canceled',
              detail: `Invitation canceled for ${rowData.name}`,
              severity: 'info',
            });
          }
        },
      },
      {
        title: 'Reset Password',
        enabled: rowData.status === UserStatusEnum.ACTIVE,
        action: async () => {
          try {
            await sendResetPassword({ email: rowData.email });

            addToast({
              summary: 'Reset Password',
              detail: `A password reset email has been sent to ${rowData.email}`,
            });
          } catch (err) {
            addToast({
              summary: 'Reset Password',
              detail: `Failed to send reset email to ${rowData.email}`,
              severity: 'error',
            });
          }
        },
      },
      {
        title: 'Deactivate',
        enabled: !isCurrentUser && rowData.status === UserStatusEnum.ACTIVE,
        color: 'danger',
        confirmProps: {
          title: `Are you sure you want to deactivate "${rowData.name}"?`,
          description:
            'Once deactivated, the user will no longer be in the tenant.',
          yesButton: 'Deactivate',
        },
        action: () =>
          doActivateDeactivateUser.mutateAsync({
            id: rowData.id || '',
            action: 'deactivate',
          }),
      },
    ];

    return <DataTableActions actions={actions} />;
  };

  const userStatusBodyTemplate = (rowData: IUser) => {
    const link = inviteLinks.find((l) => l.userId === rowData.id);

    return (
      <MFlex>
        <MText isTruncated noOfLines={1} mr={3}>
          {USERS.USER_STATUS_DISPLAY_STRING[rowData.status]}
        </MText>
        {rowData.status === UserStatusEnum.INVITED && (
          <MClipboardCopyBox
            copyValue={copyLink}
            tooltipMessage="Copy to Clipboard"
            isDisabled={link && !link.link}
            onCopyContent={async ({
              copyToClipboard,
              setCopiedContent,
              setShowCopyTooltip,
            }: {
              copyToClipboard: (str: string) => void;
              setCopiedContent: (val: boolean) => void;
              setShowCopyTooltip: (val: boolean) => void;
            }) => {
              try {
                setShowCopyTooltip(false);
                const inviteLink = await getUserInviteLinks(rowData.id!);
                if (inviteLink?.link) {
                  copyToClipboard(inviteLink.link.url);
                  setCopiedContent(true);
                  setTimeout(() => {
                    setCopiedContent(false);
                  }, 1000);
                }
                setShowCopyTooltip(false);
              } catch (err) {
                addToast({
                  summary: 'Invitation link',
                  detail: `Failed to retrieve the link.`,
                  severity: 'error',
                });
              }
            }}
            renderContent={() => {
              const inviteLink = inviteLinks.find(
                (l) => l.userId === rowData.id!,
              );

              return inviteLink && !inviteLink.link ? (
                <MSpinner size="xs" />
              ) : (
                <Icon
                  verticalAlign="middle"
                  as={MdLink}
                  boxSize={3.5}
                  color="tPurple.dark"
                  cursor="pointer"
                />
              );
            }}
          />
        )}
      </MFlex>
    );
  };

  const roleBodyTemplate = (rowData: IUser) => {
    if (!rowData.roles) {
      return null;
    }

    // If it's current user
    if (userId === rowData.id || !canInviteUpdateRoles) {
      return (
        <MBox>
          <MText>
            {rowData.roles
              .map(
                (role) =>
                  USER_ROLES_DISPLAY[role.name as UserRoleEnum] || role.name,
              )
              .join(', ')}
          </MText>
        </MBox>
      );
    }

    return (
      <UserRoleDropdown
        user={rowData}
        userRoles={userRoles || []}
        onChangeRole={onChangeRole}
      />
    );
  };

  const columns: ColumnProps[] = [
    {
      className: 'overflow-hidden',
      field: 'name',
      header: 'User',
      sortable: true,
      body: textBodyTemplate<IUser>('name'),
    },
    {
      className: 'overflow-hidden',
      field: 'email',
      header: 'Email',
      sortable: true,
      body: textBodyTemplate<IUser>('email'),
    },
    {
      className: 'overflow-hidden',
      field: 'status',
      header: 'Status',
      sortable: false,
      body: userStatusBodyTemplate,
    },
    {
      className: 'overflow-hidden',
      field: 'roles',
      header: 'Role',
      sortable: false,
      body: roleBodyTemplate,
    },
    {
      className: 'overflow-hidden table-cell-sm',
      field: 'action',
      header: '',
      sortable: false,
      body: actionBodyTemplate,
    },
  ];

  if (isLoadingTenant) {
    return <MPageLoader />;
  }

  if (!tenant) {
    return null;
  }

  const getUserInviteLinks = async (id: string) => {
    const link = inviteLinks.find((l) => l.userId === id);

    const linksArray = [...inviteLinks];
    if (!link) {
      linksArray.push({
        userId: id,
      });
      setInviteLinks(linksArray);
      const inviteLink = await doGetInviteLink(id);
      const linkIndex = linksArray.findIndex((l) => l.userId === id);
      const newLink = {
        userId: id,
        link: inviteLink,
      };

      if (linkIndex === -1) {
        linksArray.push(newLink);
      } else {
        linksArray.splice(linkIndex, 1, newLink);
      }

      setInviteLinks([...linksArray]);
      return {
        userId: id,
        link: inviteLink,
      };
    } else {
      return link;
    }
  };

  return (
    <MPageContainer data-testid="tenant-container">
      <MSettingsPageHeader title={tenant.name}>
        <MFlex>
          <MPageSearchInput
            placeholderKey="User"
            value={globalFilter}
            onChange={(e: any) => setGlobalFilter(e)}
            count={listData?.totalElements}
          />
          <MDataTableFilter
            filters={filters}
            filterOptions={filterOptions}
            setFilters={setFilters}
            onResetFilter={() => setFilters([])}
            resetFilter={filterComponentReset}
          />
          <ExportTableButton
            entity="users"
            filters={filters}
            searchKey={searchKey}
            searchTerm={globalFilter}
            sortField={pager.sortField}
            sortOrder={pager.sortOrder}
            endpointParams={[tenantId]}
          />
          <MButton
            variant="primary"
            ml="2"
            isDisabled={!canInviteUpdateRoles}
            onClick={onOpenInviteModal}
            data-testid="invite-users-btn"
          >
            Invite Users
          </MButton>
        </MFlex>
      </MSettingsPageHeader>

      <MBox width="100%">
        <MDataTable
          value={listData?.content}
          totalRecords={listData?.totalElements}
          totalPages={listData?.totalPages}
          pager={pager}
          setPager={setPager}
          rowHover
          emptyProps={{
            mainMessage: 'Looks like there are no users here.',
          }}
          className="p-datatable-responsive"
          loading={loading}
          columns={columns}
        />
      </MBox>

      <InviteForm
        userRoles={userRoles}
        isOpen={isInviteModalOpen}
        onClose={onCloseInviteModal}
        tenantId={tenantId}
      />
    </MPageContainer>
  );
};
