import { Control, Controller, FieldErrors } from 'react-hook-form';
import {
  MCustomNumberInput,
  MFormField,
  MFormFieldReadOnly,
  MGrid,
  MGridItem,
  MInput,
  MTextarea,
} from '~app/components/Monetize';
import { DatePicker } from '~app/components/Monetize/DatePicker/DatePicker';
import { useFlags } from '~app/services/launchDarkly';
import { formatCurrency } from '~app/utils';
import { MakePaymentForm } from '~types';

interface CreatePaymentWithManualPaymentBase {
  allowPartialPayment?: boolean;
  mode?: 'CREATE' | 'UPDATE';
  amount?: number;
  currency?: string;
  control: Control<MakePaymentForm>;
  errors: FieldErrors<MakePaymentForm>;
  isSaving: boolean;
}

interface CreatePaymentWithManualPaymentCreate
  extends CreatePaymentWithManualPaymentBase {
  allowPartialPayment?: boolean;
  mode?: 'CREATE';
}

interface CreatePaymentWithManualPaymentUpdate
  extends CreatePaymentWithManualPaymentBase {
  allowPartialPayment?: false | never;
  mode: 'UPDATE';
  amount: number;
  currency: string;
}

type CreatePaymentWithManualPaymentProps =
  | CreatePaymentWithManualPaymentCreate
  | CreatePaymentWithManualPaymentUpdate;

export const CreatePaymentWithManualPayment = ({
  allowPartialPayment = false,
  mode = 'CREATE',
  amount,
  currency,
  control,
  errors,
  isSaving,
}: CreatePaymentWithManualPaymentProps) => {
  const { allowManualPaymentDateModification } = useFlags();

  return (
    <>
      <MGrid templateColumns="repeat(2, 1fr)" mt="4" gap={4}>
        <MGridItem colSpan={1}>
          {mode === 'CREATE' ? (
            <MFormField error={errors.amount} label="Amount" isRequired>
              <Controller
                name="amount"
                control={control}
                render={({ field: { onChange, ...field } }) => (
                  <MCustomNumberInput
                    isDisabled={!allowPartialPayment || isSaving}
                    inputMode="decimal"
                    onChange={(value) => onChange(value)}
                    {...field}
                  />
                )}
              />
            </MFormField>
          ) : (
            <MFormFieldReadOnly label="Amount">
              {formatCurrency(amount, { currency })}
            </MFormFieldReadOnly>
          )}
        </MGridItem>
        <MGridItem colSpan={1}>
          <MFormField
            error={errors.paymentDate}
            label="Payment Date"
            isRequired
          >
            <Controller
              name="paymentDate"
              control={control}
              render={({ field }) => (
                <DatePicker
                  isDisabled={isSaving || !allowManualPaymentDateModification}
                  isReadOnly={!allowManualPaymentDateModification}
                  readonlyContentProps={{
                    style: {
                      lineHeight: 2,
                    },
                  }}
                  {...field}
                />
              )}
            />
          </MFormField>
        </MGridItem>
      </MGrid>
      <MGrid mt="4" rowGap={4}>
        <MGridItem colSpan={1}>
          <MFormField label="Reference No.">
            <Controller
              name="referenceNo"
              control={control}
              defaultValue=""
              render={({ field: { value, ...rest } }) => (
                <MInput
                  placeholder="Check 1234"
                  isDisabled={isSaving}
                  value={value || ''}
                  {...rest}
                />
              )}
            />
          </MFormField>
        </MGridItem>
        <MGridItem colSpan={1}>
          <MFormField label="Comments">
            <Controller
              name="description"
              control={control}
              defaultValue=""
              render={({ field: { ref, onBlur, value, ...rest } }) => (
                <MTextarea
                  placeholder="Reasons for manual payment"
                  disabled={isSaving}
                  value={value || ''}
                  {...rest}
                />
              )}
            />
          </MFormField>
        </MGridItem>
      </MGrid>
    </>
  );
};
