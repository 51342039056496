import { FunctionComponent as FC } from 'react';
import { getAccountById } from '~app/api/accountsService';
import { FilterTypeEqual, RenderTableFilterOptionProps } from '~app/types';
import { MainSearchInput } from '../../MCustomSelect/components/MainSearchInput';
import { MCustomSelect } from '../../MCustomSelect/MCustomSelect';
import { useCustomSelectValue } from '../../MCustomSelect/useCustomSelectValue';

interface AccountIdTableFilterOptionContentProps
  extends RenderTableFilterOptionProps {}

export const AccountIdTableFilterOptionContent: FC<
  AccountIdTableFilterOptionContentProps
> = ({
  filter,
  filterOption,
  handleFilterChange,
}: AccountIdTableFilterOptionContentProps) => {
  const internalFilter = filter as FilterTypeEqual;
  const { internalValue, isLoading, onInternalValueChange } =
    useCustomSelectValue({
      value: internalFilter?.value,
      setValue: (value: string) => {
        handleFilterChange(value, filterOption);
      },
      getOneById: getAccountById,
    });

  return (
    <MCustomSelect
      isLazy
      clearable
      itemTitle="accountName"
      itemValue="id"
      placeholder="Search"
      useMainInputAsSearchInput
      endpoint="api/accounts"
      MainInputComponent={MainSearchInput}
      value={internalValue}
      returnItem
      onChange={(val: any) => {
        onInternalValueChange(val);
      }}
      loading={isLoading}
    />
  );
};
