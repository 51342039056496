import lodashGet from 'lodash/get';
import isNumber from 'lodash/isNumber';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';
import orderBy from 'lodash/orderBy';
import { specialCharacterRegex } from '~app/constants/common';
import { GenericApiResponse } from '~app/types';

/**
 * Groups an array of objects by a given key
 * If there are duplicate keys in the array, the last one will be used
 *
 * @param items List of objects to group
 * @param key Key in items to group by
 * @param initialValue Optional initial value for the output object, usually not required
 */
export function arrayToObject<T>(
  items: T[],
  key: keyof T,
  initialValue?: Record<string, T>,
): Record<string, T> {
  initialValue = initialValue || {};
  if (!Array.isArray(items)) {
    return initialValue;
  }
  return items.reduce((output: Record<string, T>, item) => {
    output[item[key] as any] = item;
    return output;
  }, initialValue);
}

/**
 * Groups arrays by provided key and returns an array with the key
 * in the same order that the records were provided
 */
export function arrayToObjectOrdered<T>(
  records: T[],
  key: keyof T,
): { orderedIds: string[]; byId: Record<string, T> } {
  if (!Array.isArray(records)) {
    return { orderedIds: [], byId: {} };
  }

  return records.reduce(
    (output: { orderedIds: string[]; byId: Record<string, T> }, record, i) => {
      output.orderedIds[i] = record[key] as string;
      output.orderedIds[i] = record[key] as string;
      output.byId[record[key] as any] = record;
      return output;
    },
    {
      orderedIds: [],
      byId: {},
    },
  );
}

export const getDiscountLabel = (
  discountPercent: number | undefined | null,
): string => (discountPercent ? `Discount (${discountPercent}%)` : 'Discount');

/**
 * Converts an object into an array of key value pairs with the provided key/value
 *
 * This is useful to convert an object into something that can be used in a dropdown
 *
 *
 */
export const objectToObjArray = <TKey extends string, TValue extends string>(
  object: Record<string, string>,
  keyName: TKey,
  valueName: TValue,
): Record<TKey | TValue, string>[] => {
  const results = Object.entries(object).map(([key, value]) => {
    const newObj = {
      [keyName]: key,
      [valueName]: value,
    } as Record<TKey | TValue, string>;
    return newObj;
  });
  return results;
};

/**
 * Use this if a dropdown has a dynamic set of enum values
 * @param values an array of enums
 * @param displayObject Pass in the display object map to get the enum labels from
 */
export const enumListToSelectOptions = (
  values: any[],
  displayObject: Record<string, string>,
) => {
  return objectToObjArray(
    values.reduce((acc, value) => {
      acc[value] = displayObject[value];
      return acc;
    }, {}),
    'value',
    'title',
  );
};

// returns keys needed by Chakra select component
// { key1: value1, key2: value2 } => [{value: key1, title: value1}, {value: key2, title: value2}]
export const objectToSelectOptions = (object: Record<string, string>) =>
  objectToObjArray(object, 'value', 'title');

export const mapToObjArray = (
  map: Map<string, string>,
  keyName: string,
  valueName: string,
) => {
  const objArr: Record<string, string>[] = [];
  map.forEach((value, key) => {
    const newObj: Record<string, string> = {};
    newObj[keyName] = key;
    newObj[valueName] = value;
    objArr.push(newObj);
  });
  return objArr;
};

// returns keys needed by Chakra select component from a Map, order preservation is guaranteed
// Map{ key1: value1, key2: value2 } => [{value: key1, title: value1}, {value: key2, title: value2}]
export const mapToOrderedSelectOptions = (map: Map<string, string>) => {
  return mapToObjArray(map, 'value', 'title');
};

export const convertObjectValues = <T extends Record<string, any>>(
  obj: T,
  fromValue: any,
  toValue: any,
  trimStrings = false,
): T => {
  // recursively converts object values, iterates through array values, does not alter any other value types or keys
  const convertedObject = obj;

  const handleArray = (arr: any[]): any[] => {
    if (!arr) {
      return arr;
    }
    return arr.map((member) => {
      if (Array.isArray(member)) {
        return handleArray(member);
      }
      if (member && typeof member === 'object') {
        return convertObjectValues(member, fromValue, toValue, trimStrings);
      }
      if (trimStrings && typeof member === 'string') {
        return member.trim();
      }
      return member;
    });
  };

  Object.keys(obj).forEach((key: string) => {
    let val: any = (obj as any)[key];
    // optionally trim leading/trailing whitespace from strings
    if (trimStrings && typeof val === 'string') {
      val = val.trim();
      (convertedObject as any)[key] = val;
    }
    if (Array.isArray(val)) {
      (convertedObject as any)[key] = handleArray(val);
    } else if (val && typeof val === 'object') {
      (convertedObject as any)[key] = convertObjectValues(
        val,
        fromValue,
        toValue,
        trimStrings,
      );
    } else if (val === fromValue) {
      (convertedObject as any)[key] = toValue;
    }
  });

  return convertedObject;
};

export const nullifyEmptyStrings = <T extends Record<string, any>>(
  obj: T,
  trimStrings = true,
): T => convertObjectValues(obj, '', null, trimStrings);

// useful for preparing a form, since react-hook-forms don't like to be initialized with null
export const stringifyNulls = <T extends Record<string, any>>(obj: T): T =>
  convertObjectValues(obj, null, '');

export const getKeyByValue = (
  obj: Record<string, any>,
  value: any,
): string | undefined => Object.keys(obj).find((key) => obj[key] === value);

export const getTotalByKey = (array: Array<object>, key: string) => {
  let total = 0;
  if (!array?.length) {
    return total;
  }
  array.forEach((obj: any) => {
    total += obj[key];
  });
  return total;
};

export const replaceSpecialCharacterToEmptyString = (value: string) =>
  value.replace(specialCharacterRegex, '');

type UnknownArrayOrObject = unknown[] | Record<string, unknown>;

// https://github.com/react-hook-form/react-hook-form/discussions/1991#discussioncomment-351784
export const dirtyValues = (
  dirtyFields: UnknownArrayOrObject | boolean,
  allValues: UnknownArrayOrObject,
): UnknownArrayOrObject => {
  // NOTE: Recursive function.

  // If *any* item in an array was modified, the entire array must be submitted, because there's no
  // way to indicate "placeholders" for unchanged elements. `dirtyFields` is `true` for leaves.
  if (dirtyFields === true || Array.isArray(dirtyFields)) {
    return allValues;
  }

  // Here, we have an object.
  return Object.fromEntries(
    Object.keys(dirtyFields).map((key) => [
      key,
      dirtyValues(
        dirtyFields[key as keyof object],
        allValues[key as keyof object],
      ),
    ]),
  );
};

/**
 * Perform a case-insensitive sort on an array of objects by the specified property.
 * This uses lodash.get and will work with nested properties. (requires caller to specify "as any")
 */
export function orderObjectsBy<T>(
  items: T[],
  fields: (keyof T)[],
  order: ('asc' | 'desc')[] = ['asc'],
): T[] {
  const orderByItereeFns = fields.map((field) => (item: T) => {
    const value = lodashGet(item, field);
    return isString(value) ? value.toLowerCase() : value;
  });
  return orderBy(items, orderByItereeFns, order);
}

/**
 * Perform a case-insensitive sort on an array of string.
 */
export function orderStringsBy(
  items: string[],
  order: 'asc' | 'desc' = 'asc',
): string[] {
  const orderByItereeFn = (value: unknown) =>
    isString(value) ? value.toLowerCase() : value;
  return orderBy(items, [orderByItereeFn], [order]);
}

/**
 * @deprecated Use `arrayToObject` instead.
 */
export const groupBy = arrayToObject;

/**
 * Returns a promise that is delayed by {milliseconds}
 * @param milliseconds
 */
export async function delay(milliseconds: number) {
  // return await for better async stack trace support in case of errors.
  // eslint-disable-next-line @typescript-eslint/return-await
  return await new Promise((resolve) => setTimeout(resolve, milliseconds));
}

/**
 * Used in Zod validation to check input that will be transformed to number
 * @param value
 */
export const isNotNullish = (value: string | number): boolean =>
  !!value || value === 0;

/**
 * Given an array and a maximum size, splits the array into multiple arrays of the maximum size
 * @param items
 * @param maxSize
 * @returns
 */
export function splitArrayToMaxSize<T>(items: T[], maxSize: number): T[][] {
  if (!maxSize || maxSize < 1) {
    throw new Error('maxSize must be greater than 0');
  }
  if (!items || items.length === 0) {
    return [[]];
  }
  const output: T[][] = [];
  let currSet: T[] = [];
  items.forEach((item) => {
    if (currSet.length < maxSize) {
      currSet.push(item);
    } else {
      output.push(currSet);
      currSet = [item];
    }
  });
  if (currSet.length > 0) {
    output.push(currSet);
  }
  return output;
}

function testPlatform(re: RegExp) {
  return typeof window !== 'undefined' && window.navigator != null
    ? re.test(
        // eslint-disable-next-line @typescript-eslint/dot-notation
        (window.navigator as any)['userAgentData']?.platform ||
          window.navigator.platform,
      )
    : false;
}

export function isMac() {
  return testPlatform(/^Mac/i);
}

/**
 * Converts an array of objects into a grouped object based on a specified key.
 * @param {T[]} list - The array of objects to be grouped.
 * @param {keyof T} key - The key property to group by.
 * @returns {Record<string, T[]>} - The grouped object where keys represent the grouped values and values are arrays of objects.
 */
export const arrayToObjects = <T>(
  list: T[],
  key: keyof T,
): Record<string, T[]> =>
  list.reduce((acc: Record<string, T[]>, obj: T) => {
    const keyValue = String(obj[key]);
    acc[keyValue] = acc[keyValue] || [];
    acc[keyValue].push(obj);
    return acc;
  }, {});

export const isGenericApiResponse = (
  response: unknown,
): response is GenericApiResponse => {
  return (
    isObject(response) &&
    'message' in response &&
    isString(response.message) &&
    'status' in response &&
    isNumber(response.status)
  );
};
