import { Spinner } from '@chakra-ui/react';
import { Suspense, forwardRef, lazy } from 'react';
// eslint-disable-next-line no-restricted-imports
import type { Props as ChartProps } from 'react-apexcharts';

// eslint-disable-next-line no-restricted-imports
const Chart = lazy(() => import('react-apexcharts'));

export const MChart = forwardRef<any, ChartProps>(
  (props: ChartProps, ref: any) => {
    return (
      <Suspense fallback={<Spinner />}>
        <Chart {...props} ref={ref} />
      </Suspense>
    );
  },
);

export default MChart;
