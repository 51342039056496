import { isBefore } from 'date-fns/isBefore';
import { parse as parseDate } from 'date-fns/parse';
import { startOfMonth } from 'date-fns/startOfMonth';
import { IRevenueRecognitionResponse } from '~app/types/revenueTypes';
import { getPeriodsForRange } from '~app/utils';
import { groupBy } from '~app/utils/misc';

type RowData = Record<string, string | number | null>;

export function transformRevRecToTabularDataOld(
  revRecData: IRevenueRecognitionResponse,
): { rows: RowData[]; footer: RowData } {
  const { currency, recognitionSchedule, totals } = revRecData;

  // The first schedule will have all periods, each subsequent schedule will be missing n-1 periods from beginning
  // eslint-disable-next-line prefer-destructuring
  const periods = recognitionSchedule[0]?.periods || [];

  const rows = recognitionSchedule.reduce((acc: RowData[], schedule) => {
    const row: RowData = {
      period: schedule.bookingPeriod,
      currency,
      total: schedule.totalAmount,
      recognized: schedule.recognizedAsOf.recognizedAmount,
      deferred: schedule.recognizedAsOf.deferredAmount,
    };

    const schedulePeriodsByMonth = groupBy(schedule.periods, 'period');
    periods.forEach(({ period }) => {
      if (schedulePeriodsByMonth[period]) {
        row[period] = schedulePeriodsByMonth[period].recognizedAmount;
      } else {
        row[period] = null;
      }
    });
    acc.push(row);
    return acc;
  }, []);

  return {
    rows,
    footer: {
      type: 'TOTAL',
      period: 'Total',
      total: totals.amount,
      ...totals.periods.reduce((acc: RowData, item) => {
        acc[item.period] = item.recognizedAmount;
        return acc;
      }, {}),
      recognized: totals.recognizedAmount,
      deferred: totals.deferredAmount,
    },
  };
}

/**
 * Wrapper around getPeriods from utils to avoid having duplicated logic
 */
export function getRevRecPeriods(
  startDate: string,
  recognizedAsOfDate: string,
) {
  return getPeriodsForRange(
    parseDate(startDate, 'yyyy-MM', new Date()),
    parseDate(recognizedAsOfDate, 'yyyy-MM', new Date()),
    'MONTH',
    'yyyy-MM',
  );
}

/**
 * Convert revenueRecognition to tabular data
 * This is used for the table and the export
 */
export function transformRevRecToTabularData({
  currency,
  recognitionSchedule,
  totals,
  startPeriod,
  recognizedAsOfPeriod,
}: IRevenueRecognitionResponse): { rows: RowData[]; footer: RowData } {
  const periods = getRevRecPeriods(startPeriod, recognizedAsOfPeriod);

  const rows = recognitionSchedule.reduce((acc: RowData[], schedule) => {
    const bookingDate = parseDate(
      schedule.bookingPeriod,
      'yyyy-MM',
      startOfMonth(new Date()),
    );
    const row: RowData = {
      period: schedule.bookingPeriod,
      currency,
      total: schedule.totalAmount,
      recognized: schedule.recognizedAsOf.recognizedAmount,
      deferred: schedule.recognizedAsOf.deferredAmount,
    };

    const schedulePeriodsByMonth = groupBy(schedule.periods, 'period');
    periods.forEach((period) => {
      const periodDate = parseDate(period, 'yyyy-MM', startOfMonth(new Date()));
      if (schedulePeriodsByMonth[period]) {
        row[period] = schedulePeriodsByMonth[period].recognizedAmount;
      } else {
        row[period] = isBefore(periodDate, bookingDate) ? null : 0;
      }
    });
    acc.push(row);
    return acc;
  }, []);

  const totalsPeriodsByMonth = groupBy(totals.periods, 'period');

  return {
    rows,
    footer: {
      type: 'TOTAL',
      period: 'Total',
      currency,
      total: totals.amount,
      ...periods.reduce((acc: RowData, period) => {
        acc[period] = totalsPeriodsByMonth[period]
          ? totalsPeriodsByMonth[period].recognizedAmount
          : 0;
        return acc;
      }, {}),
      recognized: totals.recognizedAmount,
      deferred: totals.deferredAmount,
    },
  };
}
