import { FunctionComponent as FC } from 'react';
import { SalesforceIcon } from '~app/assets/icons/SalesforceIcon';
import { OpportunityModal } from '~app/components/Opportunities/OpportunityModal';
import { shortenID } from '~app/utils';
import { MBox, MButton, MIcon, MTag, MText } from '~components/Monetize';
import { IQuoteRespSchema, Maybe } from '~types';
import { useGetCrmConfiguration } from '~app/api/settingsService';
import { useParams } from 'react-router';
import { QuotePrimaryBadge } from '~app/routes/Quotes/Quote/components/QuotePrimaryBadge';

export interface QuoteBasicOppotunityDataTypes {
  isOpen: boolean;
  quote?: Maybe<IQuoteRespSchema>;
  onOpen: () => void;
  onClose: () => void;
  /** Fetch quote */
  loadData?: Maybe<() => void>;
}

interface QuoteBasicOppotunityProps {
  isOpen: boolean;
  isDisabled?: boolean;
  quote?: Maybe<IQuoteRespSchema>;
  onOpen: () => void;
  onClose: (skipNavigate?: boolean) => void;
  loadData?: Maybe<() => void>;
}

export const QuoteBasicOppotunity: FC<QuoteBasicOppotunityProps> = ({
  isOpen,
  isDisabled,
  quote,
  onOpen,
  onClose,
  loadData,
}: QuoteBasicOppotunityProps) => {
  const params = useParams();
  const { opportunity } = quote || {};
  const opportunityId = opportunity?.id || params?.opportunityId || '';

  const { data: crmConnections } = useGetCrmConfiguration({
    enabled: isOpen && !!opportunityId,
  });

  // Don't show if an opportunity does not exist (should never happen)
  if (!quote || !opportunity) {
    return null;
  }

  return (
    <MBox position="relative" display="flex" alignItems="center">
      {quote.id === opportunity?.primaryQuoteId && (
        <MTag variant="blue" fontSize="xs" mr="1" px="1">
          Primary Quote
        </MTag>
      )}
      <MButton
        variant="underline"
        display="flex"
        alignItems="center"
        onClick={onOpen}
        w="initial"
      >
        {opportunity?.id && (
          <MText color="inherit" title={opportunity.id}>
            {shortenID(opportunity?.id, 3, 5)}
          </MText>
        )}
        {crmConnections?.salesforce && opportunity?.customId && (
          <MIcon as={SalesforceIcon} w={7} ml="2" />
        )}
      </MButton>

      <OpportunityModal
        isOpen={isOpen}
        isDisabled={isDisabled}
        onClose={() => {
          loadData && loadData();
          onClose();
        }}
        opportunityId={opportunity.id}
      />
    </MBox>
  );
};

export default QuoteBasicOppotunity;
