import { modalAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(modalAnatomy.keys);

const baseStyle = definePartsStyle({
  body: defineStyle({
    pb: 6,
    px: 4,
  }),
  closeButton: defineStyle({
    position: 'absolute',
    top: 4,
    insetEnd: 4,
    fontSize: '12px',
  }),
  dialog: defineStyle({
    borderRadius: 'md',
  }),
  dialogContainer: defineStyle({}),
  footer: defineStyle({
    pt: 6,
    px: 4,
    pb: 4,
    borderTop: '1px solid',
    borderColor: 'tGray.gray89',
  }),
  header: defineStyle({
    px: 4,
    py: 4,
    fontSize: 'md',
    fontWeight: 'bold',
    color: 'tPurple.base',
  }),
  overlay: defineStyle({
    bg: '#18243CEE',
  }),
});

const sizes = {
  xl: definePartsStyle({
    dialog: {
      maxW: '80vw',
      width: '800px',
    },
  }),
};

export const modalTheme = defineMultiStyleConfig({
  baseStyle,
  sizes,
});

export default modalTheme;
