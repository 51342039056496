import { Stripe } from '@stripe/stripe-js';

export const processClientSecretId = async (
  stripe: Stripe,
  clientSecretId: string,
) => {
  const setupIntentResp = await stripe.retrieveSetupIntent(clientSecretId);
  const { setupIntent } = setupIntentResp;

  let message = '';
  if (!setupIntent) {
    throw new Error('Sorry, failed to connect.');
  }
  // Some payment methods will [immediately succeed or fail][0] upon
  // confirmation, while others will first enter a `processing` state.

  // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification
  switch (setupIntent.status) {
    case 'succeeded': {
      message = 'Success! Your payment method has been saved.';
      break;
    }

    case 'processing': {
      message =
        "Processing payment details. We'll update you when processing is complete.";
      break;
    }

    case 'requires_payment_method': {
      message =
        'Failed to process payment details. Please try another payment method.';

      throw new Error('Sorry, unknown status from stripe.');
    }

    default: {
      throw new Error('Sorry, unknown status from stripe.');
    }
  }

  return { message, setupIntent };
};
