import {
  Box,
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormErrorMessageProps,
  FormHelperText,
  FormLabel,
  FormLabelProps,
  TooltipProps,
} from '@chakra-ui/react';
import React, { FC, ReactNode, forwardRef } from 'react';
import { MdInfo } from 'react-icons/md';
import { MBox, MFlex, MIcon } from './chakra';
import { MTooltip } from './MTooltip';

export interface MFormFieldProps extends FormControlProps {
  label?: string;
  labelProps?: FormLabelProps;
  labelRightComponent?: React.ReactNode;
  skipLabel?: boolean;
  hint?: string;
  isHorizontal?: boolean;
  tooltip?: ReactNode | string;
  tooltipSize?: TooltipProps['size'];
  tooltipColor?: TooltipProps['color'];
  tooltipUsePopover?: boolean;
  error?: any;
  isReadOnly?: boolean;
  children?: React.ReactNode;
  formErrorMessageProps?: FormErrorMessageProps;
}

const MFormField: FC<MFormFieldProps> = forwardRef<
  HTMLDivElement,
  MFormFieldProps
>(
  (
    {
      label = '',
      labelProps,
      skipLabel = false,
      hint = '',
      isHorizontal = false,
      tooltip,
      tooltipSize,
      tooltipColor = 'tPurple.base',
      tooltipUsePopover = false,
      isReadOnly = false,
      error,
      children,
      formErrorMessageProps,
      labelRightComponent,
      ...rest
    }: MFormFieldProps,
    ref,
  ) => {
    const verticalLabelProps = isReadOnly
      ? { mb: 0, ...labelProps }
      : labelProps;

    if (isHorizontal) {
      return (
        <FormControl
          ref={ref}
          isInvalid={!!error?.message}
          errortext={error?.message}
          display="flex"
          alignItems="center"
          {...rest}
        >
          {!!label && !tooltip && (
            <FormLabel mb="0" display="inline-block" {...labelProps}>
              {label}
            </FormLabel>
          )}
          {!!label && !!tooltip && (
            <MFlex>
              <FormLabel mr="0" display="inline-block" {...labelProps}>
                {label}
              </FormLabel>
              <MTooltip
                label={tooltip}
                placement="bottom-start"
                size={tooltipSize}
                usePopover={tooltipUsePopover}
              >
                <MFlex alignItems="center">
                  <MIcon as={MdInfo} color="tPurple.base" mx="2" />
                </MFlex>
              </MTooltip>
            </MFlex>
          )}
          <MBox flex="1">
            {children}
            {!!error && (
              <FormErrorMessage
                color="tRed.base"
                fontSize="sm"
                {...formErrorMessageProps}
              >
                {error?.message}
              </FormErrorMessage>
            )}
            {!!hint && <FormHelperText>{hint}</FormHelperText>}
          </MBox>
        </FormControl>
      );
    }

    return (
      <FormControl
        ref={ref}
        isInvalid={!!error?.message}
        errortext={error?.message}
        {...rest}
      >
        {!!label && !tooltip && (
          <MFlex justify="space-between" align="center">
            <FormLabel mr="0" display="inline-block" {...verticalLabelProps}>
              {label}
            </FormLabel>
            {labelRightComponent && labelRightComponent}
          </MFlex>
        )}
        {!!label && !!tooltip && (
          <MFlex>
            <MFlex justify="space-between" align="center">
              <MFlex align="center">
                <FormLabel
                  mr="0"
                  display="inline-block"
                  {...verticalLabelProps}
                >
                  {label}
                </FormLabel>
                <MTooltip
                  label={tooltip}
                  placement="bottom-start"
                  usePopover={tooltipUsePopover}
                >
                  <MBox mt="-0.5">
                    <MIcon as={MdInfo} color={tooltipColor} ml="1" />
                  </MBox>
                </MTooltip>
              </MFlex>
              {labelRightComponent && labelRightComponent}
            </MFlex>
          </MFlex>
        )}
        {!label && !!skipLabel && <Box mt="7" />}
        {children}
        {!!error && (
          <FormErrorMessage
            color="tRed.base"
            fontSize="sm"
            {...formErrorMessageProps}
          >
            {error?.message}
          </FormErrorMessage>
        )}
        {!!hint && <FormHelperText>{hint}</FormHelperText>}
      </FormControl>
    );
  },
);

export default MFormField;
