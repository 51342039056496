import { Dispatch } from 'react';
import { composeGetQuery } from '~app/api/utils';
import { listApiConfig } from '~app/constants/guidedQuoting';
import { renderItemContentForItemsWithIdName } from '~app/routes/Settings/rules-v3/rules.utils';
import MCustomMultiSelect from '../MCustomSelect/MCustomMultiSelect';

interface SelectProps {
  filters: any;
  value: any;
  setValue: Dispatch<any>;
}

const ProductDropdownMultiSelect = ({
  filters,
  value,
  setValue,
}: SelectProps) => {
  return (
    <MCustomMultiSelect
      mt="0 !important"
      showQueryInput
      returnItem={true}
      closeButtonText="Apply"
      endpoint="/api/products"
      additionalSearchParams={composeGetQuery(listApiConfig, filters)}
      getByIdEndpointFn={(record: any) => `/api/products/${record.id}`}
      itemTitle="name"
      itemValue="id"
      renderItemContent={renderItemContentForItemsWithIdName}
      value={value ?? []}
      onChange={(newValue: any) => setValue(newValue)}
      isLazy
      multiple
      inputProps={{
        _placeholder: { color: 'tPurple.dark' },
        _disabled: {
          _placeholder: {
            color: 'tGray.darkPurple',
          },
        },
      }}
    />
  );
};

export default ProductDropdownMultiSelect;
