import { addDays } from 'date-fns/addDays';
import { endOfDay } from 'date-fns/endOfDay';
import { isValid } from 'date-fns/isValid';
import { parseISO } from 'date-fns/parseISO';
import { startOfDay } from 'date-fns/startOfDay';
import clamp from 'lodash/clamp';
import isString from 'lodash/isString';
import {
  GuidedQuotingVariableValues,
  OnlyEqualFields,
  QuestionCompareFromFields,
  QuestionCompareFromTypes,
} from '~app/constants/guidedQuoting';
import {
  CollaborationAccessEnum,
  ContextProduct,
  GuidedQuotingContext,
  IAccountRespSchema,
  ICustomFieldRecordSchema,
  IGuidedQuotingRespSchema,
  IQuestion,
  IQuoteItemReqSchema,
  IQuoteRequestSchema,
  IRateResSchema,
  Maybe,
  NetTermsEnum,
  QUESTION_PREFIX,
  QuestionComparatorEnum,
  QuestionFilterByEnum,
  QuestionTypesEnum,
  QuoteSourceField,
} from '~app/types';
import { getFirstValue } from '~app/utils';

const underscoreTextToCamelCase = (text: string) => {
  text = text.toLowerCase();
  return text.replace(/_([a-z])/g, (g) => g[1].toUpperCase());
};

const dateApiFilter = (
  field: string,
  value: string,
  comparator: QuestionComparatorEnum,
) => {
  const startVal = startOfDay(parseISO(value)).toISOString();
  const endVal = endOfDay(parseISO(value)).toISOString();

  switch (comparator) {
    case QuestionComparatorEnum.EQUAL:
      return {
        [field]: {
          between: {
            bt: `${startVal},${endVal}`,
          },
        },
      };
    case QuestionComparatorEnum.GREATER_THAN:
      return {
        [field]: {
          between: {
            gte: endVal,
          },
        },
      };
    case QuestionComparatorEnum.GREATER_THAN_OR_EQUAL:
      return {
        [field]: {
          between: {
            gte: startVal,
          },
        },
      };
    case QuestionComparatorEnum.LESS_THAN:
      return {
        [field]: {
          between: {
            lte: startVal,
          },
        },
      };
    case QuestionComparatorEnum.LESS_THAN_OR_EQUAL:
      return {
        [field]: {
          between: {
            lte: endVal,
          },
        },
      };
    default:
      return {};
  }
};

const datePickerFilter = (
  value: string,
  comparator: QuestionComparatorEnum,
) => {
  const val = parseISO(value);
  switch (comparator) {
    case QuestionComparatorEnum.EQUAL:
      return {
        min: val,
        max: val,
      };
    case QuestionComparatorEnum.GREATER_THAN:
      return {
        min: addDays(startOfDay(val), 1),
      };
    case QuestionComparatorEnum.GREATER_THAN_OR_EQUAL:
      return { min: val };
    case QuestionComparatorEnum.LESS_THAN:
      return { max: addDays(startOfDay(val), -1) };
    case QuestionComparatorEnum.LESS_THAN_OR_EQUAL:
      return { max: val };
    default:
      return {};
  }
};

const numberFilter = (value: string, comparator: QuestionComparatorEnum) => {
  const val = Number(value);
  switch (comparator) {
    case QuestionComparatorEnum.EQUAL:
      return {
        min: val,
        max: val,
      };
    case QuestionComparatorEnum.GREATER_THAN:
      return {
        min: val + 1,
      };
    case QuestionComparatorEnum.GREATER_THAN_OR_EQUAL:
      return { min: val };
    case QuestionComparatorEnum.LESS_THAN:
      return { max: val - 1 };
    case QuestionComparatorEnum.LESS_THAN_OR_EQUAL:
      return { max: val };
    default:
      return {};
  }
};

const numberApiFilter = (
  field: string,
  value: string,
  comparator: QuestionComparatorEnum,
) => {
  const val = Number(value);

  switch (comparator) {
    case QuestionComparatorEnum.EQUAL:
      return {
        [field]: val,
      };
    case QuestionComparatorEnum.GREATER_THAN:
      return {
        [field]: {
          between: {
            gte: val + 1,
          },
        },
      };
    case QuestionComparatorEnum.GREATER_THAN_OR_EQUAL:
      return {
        [field]: {
          between: {
            gte: val,
          },
        },
      };
    case QuestionComparatorEnum.LESS_THAN:
      return {
        [field]: {
          between: {
            lte: val - 1,
          },
        },
      };
    case QuestionComparatorEnum.LESS_THAN_OR_EQUAL:
      return {
        [field]: {
          between: {
            lte: val,
          },
        },
      };
    default:
      return {};
  }
};

const customFieldFilter = (
  type: string,
  customField: string,
  value: string,
  comparator: QuestionComparatorEnum,
) => {
  switch (type) {
    case 'PRODUCT_CUSTOM_FIELD_DATE':
    case 'OFFERING_CUSTOM_FIELD_DATE':
      return dateApiFilter(customField, value, comparator);
    case 'PRODUCT_CUSTOM_FIELD_NUMBER':
    case 'OFFERING_CUSTOM_FIELD_NUMBER':
      return numberApiFilter(customField, value, comparator);
    case 'PRODUCT_CUSTOM_FIELD_CHECKBOX':
    case 'OFFERING_CUSTOM_FIELD_CHECKBOX':
      return {
        [customField]: value === 'TRUE',
      };
    default:
      return {
        [customField]: value,
      };
  }
};

export const questionFilter = (response: IQuestion, context: any) => {
  let filter = {};

  if (
    response.compareTo &&
    response.compareTo.length > 0 &&
    response.comparator
  ) {
    const compareToFirstValue = getFirstValue(response.compareTo);
    if (response.filterBy === 'CONSTANT') {
      // PRODUCT_CUSTOM_FIELD, OFFERING_CUSTOM_FIELD
      if (response.customField) {
        filter = customFieldFilter(
          response.customField,
          response.customField,
          compareToFirstValue,
          response.comparator,
        );
      }
      // PRODUCT, OFFERING, RATE - not custom fields
      else if (response.compareFrom) {
        const field = underscoreTextToCamelCase(response.compareFrom);
        // create_date, modify_date, start_date, end_date
        if (
          QuestionCompareFromTypes[response.compareFrom] ===
          QuestionTypesEnum.DATE
        ) {
          filter = dateApiFilter(
            field,
            compareToFirstValue,
            response.comparator,
          );
        }
        // product_id, offering_id, rate_id
        else if (
          [
            QuestionTypesEnum.PRODUCT,
            QuestionTypesEnum.OFFERING,
            QuestionTypesEnum.RATE,
          ].includes(QuestionCompareFromTypes[response.compareFrom])
        ) {
          filter = {
            id: {
              in: response.compareTo,
            },
          };
        }
        // billing_frequency
        else if (
          QuestionCompareFromTypes[response.compareFrom] ===
          QuestionTypesEnum.BILLING_FREQUENCY
        ) {
          filter = {
            [field]: { in: response.compareTo },
          };
        }
      }
      // NUMBER, DATE, BILLING_FREQUENCY
      else {
        if (response.type === 'DATE') {
          filter = datePickerFilter(compareToFirstValue, response.comparator);
        } else if (response.type === 'NUMBER') {
          filter = numberFilter(compareToFirstValue, response.comparator);
        } else if (response.type === 'BILLING_FREQUENCY') {
          filter = {
            billingFrequency: { in: response.compareTo },
          };
        }
      }
    } else if (response.filterBy === 'QUESTION') {
      // PRODUCT_CUSTOM_FIELD, OFFERING_CUSTOM_FIELD
      if (response.customField && response.compareFrom) {
        filter = customFieldFilter(
          response.compareFrom.toString(),
          response.customField,
          context[compareToFirstValue].value,
          response.comparator,
        );
      }
      // PRODUCT, OFFERING, RATE - not custom fields
      else if (response.compareFrom) {
        const field = underscoreTextToCamelCase(response.compareFrom);
        // create_date, modify_date, start_date, end_date
        if (
          Object.keys(QuestionCompareFromFields.OFFERING.CONSTANT).includes(
            response.compareFrom,
          )
        ) {
          filter = dateApiFilter(
            field,
            context[compareToFirstValue].value,
            response.comparator,
          );
        }
        // billing_frequency
        else if (OnlyEqualFields.includes(response.compareFrom)) {
          filter = {
            billingFrequency: { in: context[compareToFirstValue].value },
          };
        }
      }
      // NUMBER, DATE
      else {
        if (response.type === 'DATE') {
          filter = datePickerFilter(
            context[compareToFirstValue].value,
            response.comparator,
          );
        } else if (response.type === 'NUMBER') {
          filter = numberFilter(
            context[compareToFirstValue].value,
            response.comparator,
          );
        }
      }
    } else if (response.filterBy === 'VARIABLE') {
      // DATE
      if (response.type === 'DATE') {
        filter = datePickerFilter(
          GuidedQuotingVariableValues[compareToFirstValue],
          response.comparator,
        );
      }
    }
  }

  return filter;
};

/**
 * Prepare quote payload for creation
 * We favor any field set during the guided selling process
 * but we allow the parent to pass in fallback/default values which are most
 * commonly used when mapping data from the CRM to the quote
 *
 * @param guidedQuoting Guided quoting process - used to determine field mappings
 * @param account Account to create the quote for
 * @param context Questions and their answers
 * @param defaultFields Any default fields owned by the immediate parent (e.x. netTerms)
 * @param getInitialQuoteFieldValues Function to allow grandparents to get additional default values
 * @returns
 */
export const getQuoteCreatePayload = (
  guidedQuoting: IGuidedQuotingRespSchema,
  account: IAccountRespSchema,
  context: GuidedQuotingContext,
  defaultFields: Partial<IQuoteRequestSchema>,
  getInitialQuoteFieldValues?: () => Partial<IQuoteRequestSchema>,
) => {
  // Get value from quoteSourceField with a fallback to a default value
  function getValue(quoteSourceField?: QuoteSourceField, defaultValue?: any) {
    if (!quoteSourceField) {
      return defaultValue;
    }
    const { type, value } = quoteSourceField;
    if (type === QuestionFilterByEnum.NONE) {
      return defaultValue ?? value;
    }
    if (type === QuestionFilterByEnum.CONSTANT) {
      return value;
    }
    if (
      type === QuestionFilterByEnum.QUESTION &&
      isString(value) &&
      value?.includes(QUESTION_PREFIX)
    ) {
      return context[value]?.value;
    }
    if (
      type === QuestionFilterByEnum.VARIABLE &&
      isString(value) &&
      value &&
      Object.keys(GuidedQuotingVariableValues).includes(value)
    ) {
      return GuidedQuotingVariableValues[value];
    }
    return defaultValue;
  }

  function getDate(value: string | null | undefined) {
    if (value) {
      if (value.includes(QUESTION_PREFIX)) {
        return context[value].value;
      } else if (Object.keys(GuidedQuotingVariableValues).includes(value)) {
        return GuidedQuotingVariableValues[value];
      } else {
        return value;
      }
    }
    return null;
  }

  function getContractLength(fallback = 12) {
    const sourceField = guidedQuoting.quoteSourceFields?.find(
      ({ quoteField }) => quoteField === 'contractLength',
    );
    if (sourceField) {
      return getValue(sourceField, fallback);
    }
    if (guidedQuoting.contractLengthSource) {
      if (guidedQuoting.contractLengthSource.startsWith(QUESTION_PREFIX)) {
        return context[guidedQuoting.contractLengthSource].value;
      } else {
        return guidedQuoting.contractLengthSource;
      }
    }
    return fallback;
  }

  function getCustomFields(initialValues: ICustomFieldRecordSchema = {}) {
    const customFields = { ...initialValues };
    guidedQuoting.quoteSourceFields
      ?.filter(({ quoteField }) => quoteField.startsWith('customFields.'))
      .forEach((sourceField) => {
        customFields[sourceField.quoteField.split('.')[1]] =
          getValue(sourceField);
      });
    return customFields;
  }

  // parent can set default values for the quote fields
  // each quote field has behavior most common for each field
  const initialValue = getInitialQuoteFieldValues?.() || {};

  const quoteCreatePayload: IQuoteRequestSchema = {
    ...initialValue,
    description:
      initialValue.description ?? 'Guided Quote for ' + account?.accountName,
    accountId: initialValue.accountId ?? account.id,
    expirationDate: getValue(
      guidedQuoting.quoteSourceFields?.find(
        ({ quoteField }) => quoteField === 'expirationDate',
      ),
      initialValue.expirationDate ??
        getDate(guidedQuoting?.expirationDateSource),
    ),
    currency: getValue(
      guidedQuoting.quoteSourceFields?.find(
        ({ quoteField }) => quoteField === 'currency',
      ),
      initialValue.currency ?? account?.defaultCurrency ?? 'USD',
    ),
    netTerms:
      initialValue.netTerms ?? defaultFields.netTerms ?? NetTermsEnum.NET_30,
    contractStartDate: getValue(
      guidedQuoting.quoteSourceFields?.find(
        ({ quoteField }) => quoteField === 'contractStartDate',
      ),
      initialValue.contractStartDate ??
        getDate(guidedQuoting?.contractStartDateSource),
    ),
    contractLength: Number(
      getContractLength(initialValue.contractLength ?? 12),
    ),
    autoRenewContract: initialValue.autoRenewContract ?? false,
    contractRenewalTerms: initialValue.contractRenewalTerms,
    requiresEsign: initialValue.requiresEsign ?? false,
    legalEntityId: getValue(
      guidedQuoting.quoteSourceFields?.find(
        ({ quoteField }) => quoteField === 'legalEntity',
      ),
      initialValue.legalEntityId ?? account.defaultLegalEntityId,
    ),
    collaborationAccess:
      initialValue.collaborationAccess ?? CollaborationAccessEnum.NONE,
    customFields: getCustomFields(initialValue.customFields),
  };

  return quoteCreatePayload;
};

export const isQuestionSubmitDisabled = (
  context: any,
  currentQuestion: IQuestion,
  offeringQuestion?: Maybe<IQuestion>,
) => {
  // This checks if the next button should be disabled in the question flow
  // If the question is an offering question, it checks if all the products have a quantity and that some offerings have been selected
  // If the question is a rate question, it checks if a rate has been selected for each offering
  // Otherwise, it checks if the question has a value in the "context" object
  if (context && currentQuestion && context[currentQuestion.id]) {
    if (currentQuestion.type === QuestionTypesEnum.OFFERING) {
      return (
        !Object.values(context[currentQuestion.id].value).every((item: any) =>
          // Every selected product has a quantity
          (item.products as ContextProduct[]).every(
            (product) => !product.isSelected || product.qty !== '',
          ),
        ) || Object.keys(context[currentQuestion.id].value).length < 1
      );
    } else if (
      currentQuestion.type === QuestionTypesEnum.RATE &&
      offeringQuestion
    ) {
      return !Object.values(context[offeringQuestion.id].value).every(
        (item: any) => item.rate !== '',
      );
    } else {
      if (typeof context[currentQuestion.id].value === 'object') {
        return Object.keys(context[currentQuestion.id].value).length === 0;
      } else {
        return String(context[currentQuestion.id].value).length === 0;
      }
    }
  } else {
    return true;
  }
};

export const ensureQuantityWithinRateBounds = (
  items: IQuoteItemReqSchema[],
  { productPriceRanges }: IRateResSchema,
): IQuoteItemReqSchema[] => {
  return items.map(
    (item): IQuoteItemReqSchema => ({
      ...item,
      quantity: clamp(
        item.quantity,
        productPriceRanges[item.productId]?.min || 0,
        productPriceRanges[item.productId]?.max ?? Number.MAX_SAFE_INTEGER,
      ),
    }),
  );
};

export const parseCompareTo = (
  compareTo: Maybe<string | string[]>,
): string[] => {
  if (Array.isArray(compareTo)) {
    return compareTo;
  } else if (isString(compareTo)) {
    return compareTo.split(',');
  }
  return [];
};

export function validDateQuestionValue(value: unknown) {
  if (!value) {
    return true;
  } else if (typeof value === 'string') {
    return isValid(parseISO(value));
  } else {
    return false;
  }
}
