import { defineStyle, defineStyleConfig } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

const baseStyle = defineStyle((props) => {
  const bg = mode('tPurple.dark', 'tPurple.200')(props);
  return {
    '--tooltip-bg': `colors.${bg}`,
    px: '8px',
    py: '6px',
    bg: 'var(--tooltip-bg)',
    '--popper-arrow-bg': 'var(--tooltip-bg)',
    color: mode('tWhite.base', 'tPurple.dark')(props),
    borderRadius: 'sm',
    fontWeight: 400,
    fontSize: '12',
    boxShadow: 'md',
    zIndex: 'tooltip',
  };
});

const sizes = {
  xs: defineStyle({
    maxW: '150px',
  }),
  sm: defineStyle({
    maxW: '225px',
  }),
  md: defineStyle({
    maxW: '320px',
  }),
  lg: defineStyle({
    maxW: '400px',
  }),
};

export const tooltipTheme = defineStyleConfig({
  baseStyle,
  sizes,
  defaultProps: {
    size: 'md',
  },
});

export default tooltipTheme;
