import { ColumnProps } from 'primereact/column';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  MButton,
  MDataTable,
  MFlex,
  MPageContainer,
  MSettingsPageHeader,
  MText,
  shouldOpenInNewWindow,
} from '~app/components/Monetize';
import { ExportTableButton } from '~app/components/Monetize/ExportEntityButton';
import { ROUTES } from '~app/constants';
import { getRuleEditRoute } from '~app/constants/routes';
import { RULE_STATUS_DISPLAY, RULE_TARGET_DISPLAY } from '~app/constants/rules';
import { useRuleTableData } from '~app/routes/Settings/rules-v3/useRuleTableData';
import { useDocumentHead } from '~app/services/documentHead';
import {
  dateTimeBodyTemplate,
  numberBodyTemplate,
  statusBodyTemplate,
  textBodyTemplate,
} from '~app/utils/tableUtils';
import {
  FilterTypeOperator,
  IRuleResSchema,
  RuleStatusEnum,
  RuleTargetEnum,
  RuleTypeEnum,
} from '~types';

interface ApprovalRulesProps {
  /** @deprecated */
  V3?: true;
}

const ApprovalRules = ({}: ApprovalRulesProps) => {
  const { pager, setPager, loading, rules, actionBodyTemplate } =
    useRuleTableData({ type: RuleTypeEnum.APPROVAL });

  const { setDocTitle } = useDocumentHead();
  const navigate = useNavigate();
  const redirectToCreate = () => navigate(ROUTES.SETTINGS_APPROVALS_V3_CREATE);

  const onSelectEdit = (id: string, openInNewWindow = false) => {
    openInNewWindow
      ? window.open(getRuleEditRoute(id, 'approvals'), '_blank')
      : navigate(getRuleEditRoute(id, 'approvals'));
  };

  const approvalRuleApproverBodyTemplate = (data: IRuleResSchema) => (
    <MText isTruncated noOfLines={1}>
      {data?.action?.approval?.username || data?.action?.approval?.teamName}
    </MText>
  );

  const approvalRuleMandatoryBodyTemplate = (data: IRuleResSchema) => (
    <MText>{data?.action?.approval?.mandatory ? 'No' : 'Yes'}</MText>
  );

  const columns: ColumnProps[] = [
    {
      className: 'overflow-hidden',
      field: 'name',
      header: 'Name',
      sortable: true,
      body: textBodyTemplate<IRuleResSchema>('name'),
    },
    {
      field: '',
      header: 'Approver',
      body: approvalRuleApproverBodyTemplate,
    },
    {
      field: 'priority',
      header: 'Priority',
      sortable: true,
      body: numberBodyTemplate<IRuleResSchema>('priority'),
      bodyStyle: {
        paddingLeft: '3.5rem',
      },
    },
    {
      field: 'target',
      header: 'Target',
      sortable: true,
      body: statusBodyTemplate<IRuleResSchema, RuleTargetEnum>(
        'target',
        RULE_TARGET_DISPLAY,
      ),
    },
    {
      field: 'mandatory',
      header: 'Skippable',
      body: approvalRuleMandatoryBodyTemplate,
    },
    {
      field: 'status',
      header: 'Status',
      sortable: true,
      body: statusBodyTemplate<IRuleResSchema, RuleStatusEnum>(
        'status',
        RULE_STATUS_DISPLAY,
      ),
    },
    {
      field: 'modifyDate',
      header: 'Updated',
      sortable: true,
      body: dateTimeBodyTemplate('modifyDate'),
      className: 'table-cell-md',
    },
    { body: actionBodyTemplate, style: { width: '4em' } },
  ];

  useEffect(() => {
    setDocTitle('Settings', 'Approval Rules');
  }, []);

  return (
    <MPageContainer
      alignItems="stretch"
      overflowY="auto"
      data-testid="approvals-list-page"
    >
      <MSettingsPageHeader title="Approval Rules">
        {rules?.content.length !== 0 && !loading && (
          <MFlex gap={4}>
            <ExportTableButton
              applyInternalFiltersWithoutConfirmation
              entity="approvalRules"
              internalFilters={[
                {
                  key: 'type',
                  value: RuleTypeEnum.APPROVAL,
                  operator: FilterTypeOperator.EQUAL,
                },
              ]}
            />
            <MButton
              variant="primary"
              onClick={redirectToCreate}
              data-testid="new-approval-btn"
            >
              New
            </MButton>
          </MFlex>
        )}
      </MSettingsPageHeader>
      <MDataTable
        value={rules?.content}
        totalRecords={rules?.totalElements}
        totalPages={rules?.totalPages}
        pager={pager}
        setPager={setPager}
        rowHover
        className="p-datatable-responsive"
        loading={loading}
        columns={columns}
        emptyProps={{
          mainMessage: 'Looks like there are no approvals here.',
          btnLabel: 'New Approval Rule',
          to: ROUTES.SETTINGS_APPROVALS_V3_CREATE,
          btnProps: { 'data-testid': 'new-approval-btn' },
        }}
        onRowClick={(e) => {
          onSelectEdit(e.data?.id, shouldOpenInNewWindow(e));
        }}
      />
    </MPageContainer>
  );
};

export default ApprovalRules;
