import { useQueryClient } from '@tanstack/react-query';
import { FormEvent, useState } from 'react';
import { handleApiErrorToast } from '~app/api/axios';
import { doUpdateCustomFields } from '~app/api/customFieldsService';
import { accountServiceQueryKeys } from '~app/api/queryKeysService';
import { MEntityStatusBadge } from '~app/components/Monetize/MEntityStatusBadge';
import { MParentLink } from '~app/components/Monetize/MParentLink';
import {
  CreditStatusEnumDisplay,
  CreditTypeEnumDisplay,
} from '~app/constants/credits';
import { getAccountCreditsRoute } from '~app/constants/routes';
import { useACL } from '~app/services/acl/acl';
import { NeutralStyle, formatCurrency } from '~app/utils';
import { toDateShort } from '~app/utils/dates';
import { nullifyEmptyStrings } from '~app/utils/misc';
import { CustomFieldDataForm } from '~components/CustomFields/CustomFieldDataForm';
import {
  MBox,
  MButton,
  MCenter,
  MCenterModal,
  MDivider,
  MFormFieldReadOnly,
  MGrid,
  MGridItem,
  MHStack,
  MStack,
  MStatusIDBox,
} from '~components/Monetize';
import {
  CreditStatusEnum,
  CreditTypeEnum,
  CustomFieldEntityEnum,
  IAccountRespSchema,
  ICustomFieldRecordSchema,
  IGetCreditSchema,
} from '~types';

interface CreditEditModalProps {
  account: IAccountRespSchema;
  credit: IGetCreditSchema;
  onClose: () => void;
  onSave: (customFields: ICustomFieldRecordSchema) => void;
}

/**
 * This is only used for viewing a credit note and editing custom fields
 */
export const CreditEditModal = ({
  account,
  credit,
  onClose,
  onSave,
}: CreditEditModalProps) => {
  const { canDo } = useACL();
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState<boolean>(false);

  const canEdit = canDo([['billing', 'update']]);

  const hasCustomFields = Object.keys(credit.customFields ?? {}).length > 0;

  const [customFields, setCustomFields] = useState<ICustomFieldRecordSchema>(
    credit.customFields ?? {},
  );

  const onSubmit = async (event: FormEvent) => {
    event.preventDefault();
    setLoading(true);

    if (credit && customFields) {
      const payload = {
        entity: 'credits',
        id: credit.id,
        data: nullifyEmptyStrings(customFields),
      } as const;
      try {
        await doUpdateCustomFields(payload);
        queryClient.removeQueries([
          ...accountServiceQueryKeys.credits.creditDetail(credit.id),
        ]);
        onSave(customFields);
      } catch (err) {
        handleApiErrorToast(err);
      } finally {
        setLoading(false);
      }
    }
  };

  const overrideStatusStyle =
    credit?.status === CreditStatusEnum.APPLIED ? NeutralStyle : {};

  const renderFooterComponent = () => {
    return (
      <MStack
        spacing={4}
        direction="row"
        align="center"
        justify="right"
        flex={1}
      >
        <MButton onClick={onClose} variant="cancel" minW="auto">
          Cancel
        </MButton>
        <MButton
          data-testid="credit-form-submit"
          form="credit-form"
          type="submit"
          variant="primary"
          isLoading={loading}
          minW="auto"
        >
          Save
        </MButton>
      </MStack>
    );
  };

  return (
    <MCenterModal
      isOpen
      onClose={onClose}
      modalTitle={
        canEdit && hasCustomFields ? 'Edit Credit Note' : 'View Credit Note'
      }
      subTitle={
        <MHStack>
          <MParentLink
            parentLink={getAccountCreditsRoute(account.id)}
            parentLinkTitle={account.accountName}
          />
          <MCenter m={0} w={0} height={3}>
            <MDivider
              m={0}
              orientation="vertical"
              borderColor="tGray.darkGrayPurple"
            />
          </MCenter>
          <MStatusIDBox id={credit.id} mb="0.5" />
        </MHStack>
      }
      size="lg"
      renderFooter={
        canEdit && hasCustomFields ? renderFooterComponent : undefined
      }
      renderModalTitleActions={() =>
        credit?.status && (
          <MEntityStatusBadge
            status={CreditStatusEnumDisplay[credit.status]}
            {...overrideStatusStyle}
          />
        )
      }
      modalHeaderProps={{ display: 'flex', alignItems: 'center' }}
    >
      <MBox>
        <form id="credit-form" data-testid="credit-form" onSubmit={onSubmit}>
          <MGrid templateColumns="repeat(12, 1fr)" gap={4}>
            <MGridItem colSpan={6}>
              <MFormFieldReadOnly label="Type">
                {CreditTypeEnumDisplay[CreditTypeEnum.CREDIT_NOTE]}
              </MFormFieldReadOnly>
            </MGridItem>
            <MGridItem colSpan={6}>
              <MFormFieldReadOnly label="Amount">
                {formatCurrency(credit.amount, {
                  currency: credit.currency,
                })}
              </MFormFieldReadOnly>
            </MGridItem>
            <MGridItem colSpan={12}>
              <MFormFieldReadOnly label="Reason">
                {credit.reason}
              </MFormFieldReadOnly>
            </MGridItem>
            {credit.expirationDate && (
              <MGridItem colSpan={12}>
                <MFormFieldReadOnly label="Reason">
                  {toDateShort(credit.expirationDate)}
                </MFormFieldReadOnly>
              </MGridItem>
            )}

            {hasCustomFields && (
              <MGridItem colSpan={12}>
                <CustomFieldDataForm
                  entity={CustomFieldEntityEnum.CREDIT}
                  value={customFields}
                  isReadOnly={!canEdit}
                  setValue={(val) => {
                    setCustomFields(val);
                  }}
                  fieldColSpan={6}
                  mode="modal"
                />
              </MGridItem>
            )}
          </MGrid>
        </form>
      </MBox>
    </MCenterModal>
  );
};
