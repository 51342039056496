import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { useState } from 'react';
import { useGetAllInvoices } from '../../../api/invoiceService';
import { SELECTION_COL } from '../../../components/Monetize/DataTableNew/dataTableNewRenderers';
import {
  addressBodyTemplate,
  contactBodyTemplate,
  currencyBodyTemplate,
  dateBodyTemplate,
  datePeriodBodyTemplate,
  dateTimeBodyTemplate,
  emailListBodyTemplate,
  enumDisplayBodyTemplate,
  getColHeaderFilters,
  getColHeaderWithSize,
  getCustomFieldsColumnGroup,
  nameWithIdBodyTemplate,
  textBodyTemplate,
} from '../../../components/Monetize/DataTableNew/tableUtilsNew';
import {
  getAccountDetailRoute,
  getBillGroupPageRoute,
  getInvoiceDetailRoute,
} from '../../../constants/routes';
import { useCustomFields } from '../../../hooks/useCustomFields';
import {
  CustomFieldEntityEnum,
  DEFAULT_PAGER,
  FilterTypeOperator,
  InvoiceSummaryResp,
  NET_TERMS_DISPLAY,
  TDataTablePager,
} from '../../../types';
import { buildFilterParamsRequestObject } from '../../../utils';

const columnHelper = createColumnHelper<InvoiceSummaryResp>();

const COLUMNS_BASE: ColumnDef<any, any>[] = [
  {
    ...SELECTION_COL,
  },
  columnHelper.group({
    id: 'invoiceDetails',
    header: 'Invoice Details',
    columns: [
      columnHelper.accessor('invoiceNumber', {
        ...getColHeaderWithSize('Invoice Number', 190),
        ...getColHeaderFilters('SET'),
        cell: nameWithIdBodyTemplate<InvoiceSummaryResp, unknown>({
          idProp: 'id',
          nameProp: 'invoiceNumber',
          idLinkFn: (invoiceId) => getInvoiceDetailRoute(invoiceId),
        }),
        enableSorting: false,
      }),
      columnHelper.accessor('account.accountName', {
        ...getColHeaderWithSize('Account', 250),
        ...getColHeaderFilters('SET'),
        cell: nameWithIdBodyTemplate<InvoiceSummaryResp, unknown>({
          idProp: 'account.id' as any,
          nameProp: 'account.accountName' as any,
          idLinkFn: (accountId) => getAccountDetailRoute(accountId),
        }),
        enableSorting: true,
        sortingFn: 'text',
      }),
      columnHelper.accessor('billGroup.id', {
        ...getColHeaderWithSize('Bill Group', 250),
        ...getColHeaderFilters('SET'),
        cell: nameWithIdBodyTemplate<InvoiceSummaryResp, unknown>({
          idProp: 'billGroup.id' as any,
          nameProp: 'billGroup.name' as any,
          idLinkFn: (billGroupId) => getBillGroupPageRoute(billGroupId),
        }),
        enableSorting: true,
        sortingFn: 'text',
      }),
    ],
  }),
  columnHelper.group({
    id: 'dates',
    header: 'Dates',
    columns: [
      columnHelper.accessor('createDate', {
        ...getColHeaderWithSize('Created Date'),
        ...getColHeaderFilters('DATE'),
        enableSorting: true,
        cell: dateTimeBodyTemplate(),
        sortingFn: 'alphanumeric',
      }),
      columnHelper.accessor('lastSent', {
        ...getColHeaderWithSize('Last Emailed Date'),
        ...getColHeaderFilters('DATE'),
        enableSorting: true,
        cell: dateTimeBodyTemplate(),
        sortingFn: 'alphanumeric',
      }),
      columnHelper.accessor('billDate', {
        ...getColHeaderWithSize('Bill Date'),
        ...getColHeaderFilters('DATE'),
        enableSorting: true,
        cell: dateBodyTemplate(),
        sortingFn: 'alphanumeric',
      }),
      columnHelper.accessor('dueDate', {
        ...getColHeaderWithSize('Due Date'),
        ...getColHeaderFilters('DATE'),
        enableSorting: true,
        cell: dateBodyTemplate(),
        sortingFn: 'alphanumeric',
      }),
      columnHelper.accessor('netTerms', {
        ...getColHeaderWithSize('Net Terms'),
        ...getColHeaderFilters('SET'),
        cell: enumDisplayBodyTemplate({
          displayMap: NET_TERMS_DISPLAY,
        }),
        enableSorting: true,
        sortingFn: 'text',
      }),
      columnHelper.accessor('startDate', {
        ...getColHeaderWithSize('Period', 225),
        enableSorting: false,
        cell: datePeriodBodyTemplate({
          endPeriodProperty: 'endDate',
        }),
        enableColumnFilter: false,
      }),
    ],
  }),
  columnHelper.group({
    id: 'amounts',
    header: 'Amounts',
    columns: [
      columnHelper.accessor('amount', {
        ...getColHeaderWithSize('Total Amount', 225),
        ...getColHeaderFilters('SET'),
        enableSorting: true,
        cell: currencyBodyTemplate({
          currencyProperty: 'currency',
        }),
      }),
      columnHelper.accessor('amountDue', {
        ...getColHeaderWithSize('Amount Due', 225),
        ...getColHeaderFilters('SET'),
        enableSorting: true,
        cell: currencyBodyTemplate({
          currencyProperty: 'currency',
        }),
      }),
    ],
  }),
  columnHelper.group({
    id: 'contactInformation',
    header: 'Contact Information',
    columns: [
      columnHelper.accessor('billingContact', {
        ...getColHeaderWithSize('Billing Contact', 250),
        cell: contactBodyTemplate(),
        enableSorting: false,
      }),
      columnHelper.accessor('billGroup.ccEmails', {
        ...getColHeaderWithSize('CC Emails', 250),
        cell: emailListBodyTemplate(),
        enableSorting: false,
      }),
      columnHelper.accessor('billingAddress', {
        ...getColHeaderWithSize('Billing Address', 250),
        cell: addressBodyTemplate(),
        enableSorting: false,
      }),
      columnHelper.accessor('shippingContact', {
        ...getColHeaderWithSize('Shipping Contact', 250),
        cell: contactBodyTemplate(),
        enableSorting: false,
      }),
      columnHelper.accessor('shippingAddress', {
        ...getColHeaderWithSize('Shipping Address', 250),
        cell: addressBodyTemplate(),
        enableSorting: false,
      }),
    ],
  }),
  columnHelper.group({
    id: 'customerValues',
    header: 'Customer Values',
    columns: [
      columnHelper.accessor('purchaseOrderNumber', {
        ...getColHeaderWithSize('PO Number'),
        ...getColHeaderFilters('SET'),
        cell: textBodyTemplate(),
        enableSorting: true,
        sortingFn: 'text',
      }),
      columnHelper.accessor('registrationNumber', {
        ...getColHeaderWithSize('Registration Number'),
        ...getColHeaderFilters('SET'),
        cell: textBodyTemplate(),
        enableSorting: true,
        sortingFn: 'text',
      }),
      columnHelper.accessor('vatNumber', {
        ...getColHeaderWithSize('VAT Number'),
        ...getColHeaderFilters('SET'),
        meta: { filterVariant: 'SET' },
        cell: textBodyTemplate(),
        enableSorting: true,
      }),
    ],
  }),
];

const PAGER: TDataTablePager = {
  ...DEFAULT_PAGER,
  first: 100,
};

export const useInvoiceManagement = () => {
  const [columns, setColumns] = useState<ColumnDef<any, any>[]>(COLUMNS_BASE);
  const [progress, setProgress] = useState(0);

  // Server side filters to fetch data
  const [filters] = useState(
    buildFilterParamsRequestObject([
      {
        key: 'status',
        value: 'UNPAID',
        operator: FilterTypeOperator.EQUAL,
      },
    ]),
  );

  const customFieldQueryResult = useCustomFields(
    [
      CustomFieldEntityEnum.ACCOUNT,
      CustomFieldEntityEnum.BILL_GROUP,
      CustomFieldEntityEnum.INVOICE,
    ],
    true,
    true,
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (data.length === 0) {
          return;
        }
        const newColumns = [...COLUMNS_BASE];
        const accountCustomFields = data.filter(
          (field) => field.entity === CustomFieldEntityEnum.ACCOUNT,
        );
        const billGroupCustomFields = data.filter(
          (field) => field.entity === CustomFieldEntityEnum.BILL_GROUP,
        );
        const invoiceCustomFields = data.filter(
          (field) => field.entity === CustomFieldEntityEnum.INVOICE,
        );
        if (invoiceCustomFields.length) {
          newColumns.push(
            getCustomFieldsColumnGroup({
              id: 'invoice.customFields',
              header: 'Invoice Custom Fields',
              accessorPathPrefix: 'customFields',
              customFieldMetadata: invoiceCustomFields,
            }),
          );
        }

        if (billGroupCustomFields.length) {
          newColumns.push(
            getCustomFieldsColumnGroup({
              id: 'billGroup.customFields',
              header: 'Bill Group Custom Fields',
              accessorPathPrefix: 'billGroup.customFields',
              customFieldMetadata: billGroupCustomFields,
            }),
          );
        }

        if (accountCustomFields.length) {
          newColumns.push(
            getCustomFieldsColumnGroup({
              id: 'account.customFields',
              header: 'Account Custom Fields',
              accessorPathPrefix: 'account.customFields',
              customFieldMetadata: accountCustomFields,
            }),
          );
        }
        setColumns(newColumns);
      },
    },
  );

  const invoiceQueryResult = useGetAllInvoices(
    { config: PAGER, filters, onProgress: setProgress },
    { refetchOnWindowFocus: false },
  );

  return {
    customFieldQueryResult,
    invoiceQueryResult,
    columns,
    progress,
  };
};
