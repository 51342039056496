import { z } from 'zod';
import { getRequiredMessage } from '../utils/messages';

export enum TaxProviderTypeEnum {
  ANROK = 'ANROK',
  AVALARA = 'AVALARA',
}
export const TaxProviderTypeEnumZ = z.nativeEnum(TaxProviderTypeEnum);

export enum TaxEnvironments {
  SANDBOX = 'SANDBOX',
  PRODUCTION = 'PRODUCTION',
}
export const TaxEnvironmentsZ = z.nativeEnum(TaxEnvironments);

export enum TaxIdentifierEnum {
  PRODUCT_ID = 'PRODUCT_ID',
  SKU = 'SKU',
  CUSTOM_ID = 'CUSTOM_ID',
  TAX_ITEM_CODE = 'TAX_ITEM_CODE',
}

export const TaxIdentifierEnumZ = z.nativeEnum(TaxIdentifierEnum);

export enum TaxStatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}
export const TaxStatusEnumZ = z.nativeEnum(TaxStatusEnum);

export const TaxConnectionBaseSchema = z.object({
  provider: TaxProviderTypeEnumZ,
  status: TaxStatusEnumZ,
});

export type ITaxConnectionBaseSchema = z.infer<typeof TaxConnectionBaseSchema>;

export const TaxConnectionSchema = TaxConnectionBaseSchema.extend({
  provider: TaxProviderTypeEnumZ,
  credentials: z
    .object({
      /** Anrok */
      apiKey: z.string().nullish(), // Required if Provider is Anrok
      /** Avalara */
      accountNumber: z.string().nullish(), // Required if Provider is Avalara
      licenseKey: z.string().nullish(), // Required if Provider is Avalara
      environment: TaxEnvironmentsZ.nullish(), // Required if Provider is Avalara
      companyCode: z.string().nullish(), // Required if Provider is Avalara
    })
    .nullable(),
  configurations: z.object({
    /** Anrok and Avalara */
    identifier: TaxIdentifierEnumZ.nullish(), // Required for both Anrok and Avalara
    /** Avalara */
    validateAddress: z.boolean().nullish(),
    postAndCommitTransactions: z.boolean().nullish(),
    enableLogging: z.boolean().nullish(),
  }),
  status: TaxStatusEnumZ,
})
  .superRefine((val, ctx) => {
    if (val.provider === TaxProviderTypeEnum.ANROK) {
      if (val.credentials && !val.credentials.apiKey) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: getRequiredMessage('API Key'),
          path: ['credentials.apiKey'],
        });
      }

      if (!val.configurations.identifier) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: getRequiredMessage('Identifier'),
          path: ['configurations.identifier'],
        });
      }
    }

    if (val.provider === TaxProviderTypeEnum.AVALARA) {
      if (val.credentials) {
        if (!val.credentials.accountNumber) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: getRequiredMessage('Account Number'),
            path: ['credentials.accountNumber'],
          });
        }
        if (!val.credentials.licenseKey) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: getRequiredMessage('License Key'),
            path: ['credentials.licenseKey'],
          });
        }
        if (!val.credentials.environment) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: getRequiredMessage('Environment'),
            path: ['credentials.environment'],
          });
        }
        if (!val.credentials.companyCode) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: getRequiredMessage('Company Code'),
            path: ['credentials.companyCode'],
          });
        }
      }
    }
  })
  .transform((val) => {
    if (val.provider === TaxProviderTypeEnum.ANROK) {
      return {
        ...val,
        credentials: val.credentials
          ? {
              apiKey: val.credentials.apiKey,
            }
          : null,
        configurations: {
          identifier: val.configurations.identifier,
        },
      };
    }
    if (val.provider === TaxProviderTypeEnum.AVALARA) {
      return {
        ...val,
        credentials: val.credentials
          ? {
              accountNumber: val.credentials.accountNumber,
              licenseKey: val.credentials.licenseKey,
              environment: val.credentials.environment,
              companyCode: val.credentials.companyCode,
            }
          : null,
        configurations: {
          validateAddress: val.configurations.validateAddress ?? false,
          postAndCommitTransactions:
            val.configurations.postAndCommitTransactions ?? false,
          enableLogging: val.configurations.enableLogging ?? false,
        },
      };
    }

    return val;
  });

export type ITaxConnectionSchema = z.infer<typeof TaxConnectionSchema>;

export const TaxConnectionVerifyReqSchema = z.object({
  provider: TaxProviderTypeEnumZ,
  credentials: z
    .union([
      z.object({
        apiKey: z.string(),
      }),
      z.object({
        accountNumber: z.string(),
        licenseKey: z.string(),
        environment: z.string(),
        companyCode: z.string(),
      }),
    ])
    .nullable(),
});

export type ITaxConnectionVerifyReqSchema = z.infer<
  typeof TaxConnectionVerifyReqSchema
>;
