import { Heading, Progress } from '@chakra-ui/react';
import { unparse } from 'papaparse';
import { FunctionComponent as FC } from 'react';
import { FiDownload } from 'react-icons/fi';
import { MdWarning } from 'react-icons/md';
import {
  MAlert,
  MBox,
  MButton,
  MFlex,
  MIcon,
  MText,
} from '~app/components/Monetize';
import { IUsageEventResSchema, MimeTypeEnum } from '~app/types';
import { formatNumber } from '~app/utils';
import { saveFile } from '~app/utils/download';

const CSV_FIELDS = [
  'error',
  'id',
  'accountId',
  'subscriptionId',
  'usageTypeId',
  'unitsConsumed',
  'timestamp',
];

interface UploadUsageProgressProps {
  finished: boolean;
  successCount: number;
  failureCount: number;
  total: number;
  errors: IUsageEventResSchema['failedEvents'];
}

export const UploadUsageProgress: FC<UploadUsageProgressProps> = (
  props: UploadUsageProgressProps,
) => {
  const { finished, successCount, failureCount, total, errors } = props;

  const progress = (failureCount + successCount / total) * 100;

  function handleDownloadErrors() {
    const csv = unparse(
      {
        fields: CSV_FIELDS,
        data: errors.map((error) => ({
          error: error.reason,
          ...error.event,
        })),
      },
      { header: true },
    );
    saveFile(
      csv,
      `usage-import-errors-${new Date().getTime()}.csv`,
      MimeTypeEnum.CSV,
    );
  }

  return (
    <>
      {!finished && (
        <>
          <Heading size="sm" mb={4}>
            Importing..
          </Heading>
          <Progress colorScheme="tIndigo" size="sm" value={progress} />
          <MText mt={4} fontStyle="italic">
            {successCount} of{' '}
            {formatNumber(total, {
              maximumFractionDigits: 0,
            })}{' '}
            rows imported
          </MText>
          {/* Results */}
          {!!failureCount && (
            <MFlex alignItems="center">
              <MIcon
                as={MdWarning}
                w={4}
                h={4}
                mr={2}
                color="tOrange.tangerine"
              />
              {formatNumber(total, {
                maximumFractionDigits: 0,
              })}{' '}
              import errors
            </MFlex>
          )}
        </>
      )}
      {finished && (
        <>
          {failureCount ? (
            <MFlex flexDirection="column">
              <MAlert type="warning">
                {formatNumber(failureCount, { maximumFractionDigits: 0 })}{' '}
                records were not successfully uploaded. Fix the errors by
                downloading the file below and try again.
              </MAlert>
              <MText my={4} fontStyle="italic">
                {formatNumber(successCount, { maximumFractionDigits: 0 })} out
                of {formatNumber(total, { maximumFractionDigits: 0 })} rows
                successfully imported
              </MText>
              <MBox>
                <MButton
                  variant="tertiary"
                  size="sm"
                  ml={-3}
                  mt={4}
                  onClick={handleDownloadErrors}
                >
                  <MIcon as={FiDownload} mr={2} />
                  Download Errors
                </MButton>
              </MBox>
            </MFlex>
          ) : (
            <MFlex flexDirection="column">
              <MAlert type="success">
                Your usage file has been imported successfully.
              </MAlert>
              <MText mt={4} fontStyle="italic">
                {formatNumber(successCount, { maximumFractionDigits: 0 })} out
                of {formatNumber(total, { maximumFractionDigits: 0 })} rows
                successfully imported
              </MText>
            </MFlex>
          )}
        </>
      )}
    </>
  );
};
