import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import AppConfirmModal from '~components/App/AppConfirmModal';
import AppLoading from '~components/App/AppLoading';
import AppTenantSwitchModalWrapper from '~components/App/AppTenantSwitchModalWrapper';
import AppToastWrapper from '~components/App/AppToastWrapper';
import DocumentHead from '~components/App/DocumentHead';
import './App.scss';
import { useSentry } from './components/Sentry/useSentryHook';
import AppRoutes from './routes/AppRoutes';
import { DragAndDropCustomDragLayer } from './routes/DragAndDropCustomDragLayer';
import { useAuth } from './services/auth0';
import { useCommonToast } from './services/toast';
import { navigationState } from './store/global.store';

const useLocationRedirect = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [navigation, setNavigation] = useRecoilState(navigationState);

  useEffect(() => {
    window && window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    if (navigation) {
      navigate(navigation.route, navigation?.config);
      setNavigation(null);
    }
  }, [navigate, navigation, setNavigation]);
};

const AppWrapper = () => {
  const { isLoading } = useAuth();
  useSentry();
  useLocationRedirect();
  useCommonToast();

  if (isLoading) {
    return <AppLoading />;
  }

  return (
    <>
      <DragAndDropCustomDragLayer />
      <AppRoutes />
      <AppConfirmModal />
      <AppToastWrapper />
      <AppTenantSwitchModalWrapper />
      <DocumentHead />
    </>
  );
};

export default AppWrapper;
