import { Card, CardBody, CardHeader } from '@chakra-ui/react';
import React, { FunctionComponent as FC, useState } from 'react';
import { useNavigate } from 'react-router';

import { MBox, MButton, MFlex, MHStack, MText } from '~app/components/Monetize';
import { MDataTableActions } from '~app/components/Monetize/DataTable';
import { MemberAvatar } from '~app/components/Teams/MemberAvatar';
import { MemberExpand } from '~app/components/Teams/MemberExpand';
import { getTeamsDetailsRoute } from '~app/constants/routes';
import { ITeamResponseSchema } from '~app/types';
import { toTitleCase } from '~app/utils';

interface TeamItemCardProps {
  team: ITeamResponseSchema;
  handleViewTeam?: (teamId: string) => void;
  handleDelete?: (teamName: string, teamId: string) => void;
  loading?: boolean;
}
const MAX_INITIAL_TEAMS = 18;

const TeamItemCard: FC<TeamItemCardProps> = ({
  team,
  loading = false,
  handleViewTeam,
  handleDelete,
}: TeamItemCardProps) => {
  const navigate = useNavigate();
  const [isExpanded, setIsExpanded] = useState(false);
  const { name, id, type, owner, users } = team;
  let teamUsers = users;
  const totalUsers = teamUsers.length;
  const showExpand = teamUsers.length > MAX_INITIAL_TEAMS;
  teamUsers = isExpanded ? teamUsers : teamUsers.slice(0, MAX_INITIAL_TEAMS);

  const actionMenu = (userName: string, userId: string) => {
    const actions = [
      {
        title: 'Delete',
        color: 'danger',
        enabled: true,
        loading,
        action: () => handleDelete && handleDelete(userName, userId!),
      },
    ];
    return <MDataTableActions actions={actions} />;
  };

  const handleMemberExpand = () => {
    setIsExpanded(false);
    navigate(getTeamsDetailsRoute(id));
  };

  return (
    <Card variant="edit" mb="4" borderColor={'tGray.back'}>
      <CardHeader py={2}>
        <MFlex alignItems="center">
          <MFlex flex="1">
            <MText noOfLines={1} isTruncated>
              {name}
            </MText>
          </MFlex>

          <MFlex>
            <MButton
              variant="tertiary"
              minW="80px"
              mr="1"
              onClick={() => handleViewTeam && handleViewTeam(id)}
              data-testid="team-member-btn-view"
            >
              View
            </MButton>
            <MFlex alignSelf={'center'}>{actionMenu(name, id)}</MFlex>
          </MFlex>
        </MFlex>
      </CardHeader>
      <CardBody>
        <MHStack spacing="8">
          <MBox mr={5}>
            <MText fontWeight="semibold" mb={2}>
              Type
            </MText>
            <MText>{toTitleCase(type)}</MText>
          </MBox>
          <MBox>
            <MText fontWeight="semibold" mb={2}>
              Members
            </MText>

            <MFlex flexWrap={'wrap'} rowGap={2}>
              <MemberAvatar members={teamUsers} owner={owner} />

              {showExpand && (
                <MemberExpand
                  maxInitMember={MAX_INITIAL_TEAMS}
                  totalLength={totalUsers}
                  handleExpand={handleMemberExpand}
                />
              )}
            </MFlex>
          </MBox>
        </MHStack>
      </CardBody>
    </Card>
  );
};

export default TeamItemCard;
