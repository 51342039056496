import { useDisclosure } from '@chakra-ui/react';
import {
  MBox,
  MButton,
  MFlex,
  MSettingsPageHeader,
  MText,
} from '~app/components/Monetize';
import { MDataTableActions } from '~app/components/Monetize/DataTable';
import { ROUTES } from '~app/constants';
import { TEAM_TYPE_ENUM_DISPLAY } from '~app/constants/teams';
import { ITeamResponseSchema } from '~app/types';
import { OwnerChange } from '../TeamDetails/OwnerChangeModal';
import { TeamHistoryDrawer } from '../TeamDetails/TeamHistoryDrawer';
import { useHandleDeleteTeam } from '../useHandleDeleteTeam';
import { AddMemberModal } from './AddMemberModal';
import { EditTeamNameModal } from './EditTeamNameModal';

interface TeamDetailsHeaderProps {
  team: ITeamResponseSchema;
}

export const TeamDetailsHeader = ({ team }: TeamDetailsHeaderProps) => {
  const { handleDeleteTeam } = useHandleDeleteTeam();
  const { name: teamName, id: teamId, type: teamType, owner, users } = team;
  const {
    isOpen: isAddMemberModalOpen,
    onOpen: onOpenAddMemberModal,
    onClose: onCloseAddMemberModal,
  } = useDisclosure();

  const {
    isOpen: isEditTeamNameModalOpen,
    onOpen: onOpenEditTeamNameModal,
    onClose: onCloseEditTeamNameModal,
  } = useDisclosure();

  const {
    isOpen: isOwnerChangeModalOpen,
    onOpen: onOpenOwnerChangeModal,
    onClose: onCloseOwnerChangeModal,
  } = useDisclosure();

  const actionMenu = (teamRes: ITeamResponseSchema) => {
    const actions = [
      {
        title: 'Change Owner',
        enabled: !!teamRes?.id,
        action: () => teamRes?.id && onOpenOwnerChangeModal(),
      },
      {
        title: 'Edit Team Name',
        enabled: !!teamRes?.id,
        action: () => teamRes?.id && onOpenEditTeamNameModal(),
      },
      {
        title: 'Delete',
        color: 'danger',
        enabled: !!teamRes?.id,
        action: () =>
          teamRes?.id &&
          handleDeleteTeam({ teamName: teamRes.name, teamId: teamRes.id }),
      },
    ];

    return <MDataTableActions actions={actions} />;
  };

  return (
    <MBox>
      <MSettingsPageHeader
        divider={false}
        hasBackButton
        backButtonTitle="Go Back"
        backButtonLink={ROUTES.SETTINGS_TEAMS}
        title={teamName}
        id={teamId}
        extraSubtitleParts={[<MText>{TEAM_TYPE_ENUM_DISPLAY[teamType]}</MText>]}
      >
        <MFlex alignItems="center" columnGap={2}>
          <TeamHistoryDrawer teamId={teamId} />
          <MButton
            variant="secondary"
            onClick={onOpenAddMemberModal}
            isDisabled={!team}
          >
            Add Member
          </MButton>
          {team && actionMenu(team)}
        </MFlex>
      </MSettingsPageHeader>

      {isAddMemberModalOpen && team && (
        <AddMemberModal
          isOpen={isAddMemberModalOpen}
          onClose={onCloseAddMemberModal}
          team={team}
        />
      )}

      {isEditTeamNameModalOpen && team && (
        <EditTeamNameModal
          isOpen={isEditTeamNameModalOpen}
          onClose={onCloseEditTeamNameModal}
          team={team}
        />
      )}

      {team?.owner && (
        <OwnerChange
          isOpen={isOwnerChangeModalOpen}
          onClose={onCloseOwnerChangeModal}
          teamOwnerId={owner}
          teamId={teamId!}
          teamUsers={users}
        />
      )}
    </MBox>
  );
};
