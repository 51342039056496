import { useState } from 'react';
import { handleApiErrorToast } from '~app/api/axios';
import {
  useCreateEntity,
  useGetById,
  useUpdateEntity,
} from '~app/api/queryUtils';
import { IOfferingReqSchema, IOfferingRes } from '~app/types';

const useOffering = (id: string) => {
  const [error, setError] = useState<any>(null);

  const {
    data: offering,
    isInitialLoading: isOfferingLoading,
    refetch: fetchOffering,
  } = useGetById<IOfferingRes>('productCatalogOfferings', id, {
    enabled: !!id,
    refetchOnWindowFocus: false,
  });

  const { mutateAsync: createOffering, isLoading: isCreateOfferingLoading } =
    useCreateEntity<IOfferingRes, IOfferingReqSchema>(
      'productCatalogOfferingsV2',
      {
        onError: (error) => {
          handleApiErrorToast(error);
          setError(error);
        },
      },
    );
  const { mutateAsync: updateOffering, isLoading: isUpdateOfferingLoading } =
    useUpdateEntity<IOfferingRes, IOfferingReqSchema>(
      'productCatalogOfferingsV2',
      {
        onError: (error) => {
          setError(error);
          handleApiErrorToast(error);
        },
      },
    );

  return {
    isFetching: isOfferingLoading,
    loading: isCreateOfferingLoading || isUpdateOfferingLoading,
    offering,
    error,
    fetchOffering,
    createOffering,
    updateOffering,
  };
};

export default useOffering;
