import { MText, MTooltip } from '../../../../components/Monetize';
import { ViewToggle } from './ViewToggle';

interface QuoteInternalExternalViewToggleProps {
  isInternalView: boolean;
  setIsInternalView: (val: boolean) => void;
}
export const QuoteInternalExternalViewToggle = ({
  isInternalView,
  setIsInternalView,
}: QuoteInternalExternalViewToggleProps) => {
  return (
    <ViewToggle
      leftLabel={
        <MTooltip
          label="View Quote details including prices, billing schedule, and custom fields without changing Quote status"
          placement="bottom-end"
          shouldWrapChildren
        >
          <MText tabIndex={-1} pt="4px">
            Internal View
          </MText>
        </MTooltip>
      }
      rightLabel={
        <MTooltip
          label="View Quote PDF the way your customer sees it"
          placement="bottom-end"
          shouldWrapChildren
        >
          <MText tabIndex={-1} pt="4px">
            Customer View
          </MText>
        </MTooltip>
      }
      isChecked={!isInternalView}
      onChange={(val) => {
        setIsInternalView(!val.target.checked);
      }}
    />
  );
};
