import { useDisclosure } from '@chakra-ui/react';
import { FunctionComponent as FC } from 'react';
import { MdOutlineFileUpload } from 'react-icons/md';

import {
  MBox,
  MButton,
  MFlex,
  MFormField,
  MFormFieldProps,
  MIcon,
  MText,
} from '~app/components/Monetize';
import { QUOTE_READONLY_STATUSES } from '~app/constants/quotes';
import { IQuoteRequestSchema, IQuoteRespSchema } from '~app/types';
import { pluralize } from '~app/utils';
import { QuoteDocumentsModal } from './QuoteDocumentsModal';

export function getNumberOfDocuments(quote?: IQuoteRespSchema) {
  let numDocuments = 0;
  if (!quote) {
    return numDocuments;
  }

  const {
    documentLocation,
    documentUrl,
    sowDocumentStorageId,
    signedDocumentStorageId,
    coverDocumentStorageId,
    footerDocumentStorageId,
  } = quote;

  numDocuments += documentLocation || documentUrl ? 1 : 0; // MSA
  numDocuments += sowDocumentStorageId ? 1 : 0; // SOW
  numDocuments += signedDocumentStorageId ? 1 : 0; // Signed Quote Document
  numDocuments += coverDocumentStorageId ? 1 : 0; // Pdf Cover
  numDocuments += footerDocumentStorageId ? 1 : 0; // Pdf Footer

  return numDocuments;
}

interface QuoteDocumentsProps {
  formFieldProps?: MFormFieldProps;
  quote?: IQuoteRespSchema | null;
  isReadOnly?: boolean;
  onUpdate: (data: IQuoteRequestSchema) => void;
}

export const QuoteDocuments: FC<QuoteDocumentsProps> = ({
  formFieldProps,
  quote,
  isReadOnly,
  onUpdate,
}: QuoteDocumentsProps) => {
  const {
    isOpen: isModalOpen,
    onOpen: openModal,
    onClose: closeModal,
  } = useDisclosure();

  if (!quote) {
    return null;
  }

  const disabled = isReadOnly || QUOTE_READONLY_STATUSES.has(quote.status);
  const numDocuments = getNumberOfDocuments(quote!);
  const hasDocuments = numDocuments > 0;

  return (
    <>
      <MFormField label="Documents" isDisabled={!quote} {...formFieldProps}>
        <MFlex h={8} alignItems="center">
          <MBox w="full">
            {hasDocuments && (
              <MButton
                width="full"
                onClick={openModal}
                variant="unstyled"
                background="tGray.support"
                border="tGray.lightPurple"
                fontWeight="normal"
                lineHeight="1rem"
                height="1.5rem"
              >
                <MText mx={2} fontStyle="italic" textDecoration="underline">
                  {numDocuments} {pluralize('document', numDocuments)} added
                </MText>
              </MButton>
            )}
            {!hasDocuments && (
              <>
                {disabled ? (
                  <MText display="inline-block" fontStyle="italic">
                    No documents added
                  </MText>
                ) : (
                  <MButton
                    leftIcon={<MIcon as={MdOutlineFileUpload} boxSize={4} />}
                    onClick={openModal}
                    variant="tertiary"
                    size="sm"
                    ml={-3}
                  >
                    Upload
                  </MButton>
                )}
              </>
            )}
          </MBox>
        </MFlex>
      </MFormField>
      {isModalOpen && (
        <QuoteDocumentsModal
          quote={quote}
          disabled={disabled}
          onUpdate={onUpdate}
          isOpen
          onClose={closeModal}
        />
      )}
    </>
  );
};
