import { Stack } from '@chakra-ui/react';
import { MButton, MFlex, MText } from '../../../../components/Monetize';
import { formatInteger, pluralize } from '../../../../utils';

interface MassEmailModalFooterProps {
  total: number;
  success?: number;
  failure?: number;
  isLoading: boolean;
  hasSubmitted: boolean;
  onCancel: () => void;
}

export const MassEmailModalFooter = ({
  total,
  success,
  failure,
  isLoading,
  hasSubmitted,
  onCancel,
}: MassEmailModalFooterProps) => {
  const hasProgress = success !== undefined || failure !== undefined;
  return (
    <MFlex w="100%" alignContent="space-between">
      <Stack spacing={1} direction="column">
        {hasProgress && (
          <>
            <MText color="tGreen.approval">
              {formatInteger(success || 0)} credited successfully
            </MText>
            <MText color="tRed.base">
              {formatInteger(failure || 0)} {pluralize('error', failure || 0)}
            </MText>
          </>
        )}
      </Stack>
      <Stack
        spacing={4}
        direction="row"
        align="center"
        justify="right"
        flex={1}
      >
        <MButton variant="cancel" minW="auto" onClick={onCancel}>
          Cancel
        </MButton>
        <MButton
          form="mass-email-form"
          type="submit"
          variant="primary"
          minW="auto"
          isDisabled={isLoading || hasSubmitted}
          isLoading={isLoading}
        >
          Credit {formatInteger(total)} Invoices
        </MButton>
      </Stack>
    </MFlex>
  );
};
