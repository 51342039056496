import { useInvoiceRevenueSchedule } from '~app/api/invoiceService';
import {
  MAccordion,
  MCenterModal,
  MPageHeader,
  MSkeleton,
  MVStack,
} from '~app/components/Monetize';
import { getAccountInvoicesRoute } from '~app/constants/routes';
import { IInvoiceRespSchema, InvoiceSummaryResp } from '~types';
import { InvoiceRevenueScheduleAccordionItem } from './InvoiceRevenueScheduleAccordionItem';

type InvoiceRevenueScheduleModalProps = {
  isOpen: boolean;
  invoice: IInvoiceRespSchema | InvoiceSummaryResp;
  onClose: () => void;
};

export const InvoiceRevenueScheduleModal = ({
  isOpen,
  invoice,
  onClose,
}: InvoiceRevenueScheduleModalProps) => {
  const { data: revenueSchedule, isLoading } = useInvoiceRevenueSchedule(
    invoice.id,
  );

  const title = (
    <MVStack alignItems="flex-start">
      <MPageHeader
        title="Revenue Schedule"
        parentLink={getAccountInvoicesRoute(invoice.account.id)}
        parentLinkTitle={invoice.account.accountName}
        copyUrl
        id={invoice.id}
        containerProps={{ mb: 0 }}
      ></MPageHeader>
    </MVStack>
  );

  return (
    <MCenterModal
      size="5xl"
      isOpen={isOpen}
      onClose={onClose}
      // modalTitle="Revenue Schedule"
      modalHeaderProps={{ fontSize: 'lg', display: 'flex' }}
      renderModalTitleActions={() => title}
      // TODO: header stuff
    >
      {isLoading && (
        <>
          <MSkeleton w="full" py={5} />
          <MSkeleton w="full" py={5} />
          <MSkeleton w="full" py={5} />
        </>
      )}
      {revenueSchedule && (
        <MAccordion allowMultiple defaultIndex={[0]} variant="default">
          {revenueSchedule.schedulesByProduct.map((item, i) => (
            <InvoiceRevenueScheduleAccordionItem
              key={i}
              productRevRecSchedule={item}
              currency={invoice.currency}
            />
          ))}
        </MAccordion>
      )}
    </MCenterModal>
  );
};
