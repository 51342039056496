import { ApexOptions } from 'apexcharts';
import { format as formatDate } from 'date-fns/format';
import { parseISO } from 'date-fns/parseISO';
import { IChartDataObj, IFinanceDashboardRes } from '~app/types';
import { formatCurrency } from '~app/utils';
import {
  getBaseLineOptionsCurrencyYAxis,
  getBaseLineOptionsNumberYAxis,
} from '~app/utils/charts';

const styles = `background: var(--chakra-colors-tPurple-base) !important;padding: 6px 8px;color: white;font-weight: bold;font-size: 10px;`;

const accountsCustomTooltip: ApexOptions['tooltip'] = {
  custom: ({ series, seriesIndex, dataPointIndex, w }: any) =>
    `<div class="custom-tooltip" style="${styles}">${series[seriesIndex][dataPointIndex]}</div>`,
};

const revenueCustomTooltip: ApexOptions['tooltip'] = {
  custom: ({ series, seriesIndex, dataPointIndex, w }: any) =>
    `<div class="custom-tooltip" style="${styles}">${formatCurrency(
      series[seriesIndex][dataPointIndex],
    )}</div>`,
};

const churnedCustomTooltip: ApexOptions['tooltip'] = {
  custom: ({ series, seriesIndex, dataPointIndex, w }: any) =>
    `<div class="custom-tooltip" style="${styles}">${series[seriesIndex][dataPointIndex]}</div>`,
};

const salesCustomTooltip: ApexOptions['tooltip'] = {
  custom: ({ series, seriesIndex, dataPointIndex, w }: any) =>
    `<div class="custom-tooltip" style="${styles}">${formatCurrency(
      series[seriesIndex][dataPointIndex],
    )}</div>`,
};

// FIXME: we need to use account currency
export const newAccountsChartOptions: ApexOptions = {
  ...getBaseLineOptionsCurrencyYAxis(),
  tooltip: accountsCustomTooltip,
};
export const newPayingCustomerChartOptions: ApexOptions = {
  ...getBaseLineOptionsNumberYAxis(),
  tooltip: accountsCustomTooltip,
};

export const revenueChartOptions = (currency = 'USD'): ApexOptions => ({
  ...getBaseLineOptionsCurrencyYAxis(currency),
  tooltip: revenueCustomTooltip,
});

export const churnedAccountsChartOptions: ApexOptions = {
  ...getBaseLineOptionsNumberYAxis(),
  tooltip: {
    ...churnedCustomTooltip,
    onDatasetHover: {
      highlightDataSeries: false,
    },
  },
};

export const salesChartOptions = (currency = 'USD'): ApexOptions => ({
  ...getBaseLineOptionsCurrencyYAxis(currency),
  tooltip: salesCustomTooltip,
});

export const getChartDataFormat = (
  response: IFinanceDashboardRes,
  source: string,
): IChartDataObj[] => {
  let chartData: IChartDataObj[] = [];

  if (source === 'account') {
    if (response?.monthlyAccounts?.length) {
      chartData = response.monthlyAccounts.map(
        ({ yearMonth, accounts }: any) => ({
          x: formatDate(parseISO(yearMonth), 'MMM'),
          y: accounts,
        }),
      );
    }
  }

  if (response?.monthlyRevenue?.length) {
    chartData = response.monthlyRevenue.map(({ yearMonth, revenue }: any) => ({
      x: formatDate(parseISO(yearMonth), 'MMM'),
      y: revenue,
    }));
  }

  return chartData;
};
