import { ListItem, OrderedList, useDisclosure } from '@chakra-ui/react';
import { useMemo } from 'react';
import {
  MBox,
  MButton,
  MCenterModal,
  MStack,
  MText,
} from '~app/components/Monetize';
import { IQuoteRespSchema } from '~app/types';

interface QuoteReviewCustomRatesProps {
  quote: IQuoteRespSchema;
}

export const QuoteReviewCustomRates = ({
  quote,
}: QuoteReviewCustomRatesProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  function handleClose() {
    onClose();
  }

  const customRateNames = useMemo(
    () =>
      quote.quoteOfferings
        .filter((offering) => offering.accountSpecificRate)
        .map(({ rateName, offeringName }) => ({ rateName, offeringName })),
    [quote.quoteOfferings],
  );

  return (
    <>
      {customRateNames?.length > 0 ? (
        <MButton
          variant="underline"
          onClick={onOpen}
          fontSize="sm"
          fontWeight="normal"
          w="initial"
        >
          View All
        </MButton>
      ) : (
        <MText color="tPurple.base">None</MText>
      )}
      <MCenterModal
        isOpen={isOpen}
        onClose={handleClose}
        modalTitle="This Quote is using these Custom Rates:"
        closeOnOverlayClick
        closeOnEsc
        modalHeaderProps={{ fontSize: 'lg', display: 'flex' }}
        renderFooter={() => (
          <MStack
            spacing={4}
            direction="row"
            align="center"
            justify="right"
            flex={1}
          >
            <MButton minW="auto" variant="cancel" onClick={handleClose}>
              Close
            </MButton>
          </MStack>
        )}
      >
        <MBox>
          <>
            <OrderedList>
              {customRateNames.map(({ rateName, offeringName }, idx) => {
                return (
                  <ListItem
                    key={`rate-name-${idx}`}
                    color="tPurple.dark"
                    fontSize="sm"
                  >
                    {`${offeringName} - ${rateName}`}
                  </ListItem>
                );
              })}
            </OrderedList>
          </>
        </MBox>
      </MCenterModal>
    </>
  );
};
