import { Radio, Stack } from '@chakra-ui/react';
import isNull from 'lodash/isNull';
import {
  Control,
  Controller,
  FormState,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import {
  MCustomNumberInput,
  MFlex,
  MFormField,
  MRadioGroup,
  MStack,
  MText,
} from '~app/components/Monetize';
import { FREQUENCY_ITEMS } from '~app/constants/quotes';
import { IBulkQuoteOfferingReqSchema } from '~app/types';

interface BillingFrequencyProps {
  control: Control<IBulkQuoteOfferingReqSchema>;
  errors: FormState<IBulkQuoteOfferingReqSchema>['errors'];
  setValue: UseFormSetValue<IBulkQuoteOfferingReqSchema>;
  watch: UseFormWatch<IBulkQuoteOfferingReqSchema>;
  disabled?: boolean;
}

export const BillingFrequency = ({
  control,
  errors,
  setValue,
  watch,
  disabled,
}: BillingFrequencyProps) => {
  const billingFreqValue = watch('billingFrequency');
  const customValue = watch('customBillingFrequency');

  const handleCustomBillingFrequencyChange = (
    valueAsString: string,
    valueAsNumber: number,
  ) => {
    // this switch case handles the custom billing frequency value
    switch (valueAsNumber) {
      case 0:
        setValue('customBillingFrequency', null);
        setValue('billingFrequency', '0');
        return;
      case 1:
        setValue('billingFrequency', '1');
        setValue('customBillingFrequency', 1);
        return;
      case 3:
        setValue('billingFrequency', '3');
        setValue('customBillingFrequency', 3);
        return;
      case 6:
        setValue('billingFrequency', '6');
        setValue('customBillingFrequency', 6);
        return;
      case 12:
        setValue('billingFrequency', '12');
        setValue('customBillingFrequency', 12);
        return;
      default:
        setValue('billingFrequency', '0');
        setValue('customBillingFrequency', valueAsNumber);
        return;
    }
  };

  return (
    <MFormField
      error={errors.billingFrequency}
      isRequired
      isDisabled={disabled}
    >
      <Controller
        control={control}
        name={'billingFrequency'}
        render={({ field: { onChange, value, ...rest } }) => {
          return (
            <MRadioGroup
              value={value}
              onChange={onChange}
              {...rest}
              mb={4}
              isDisabled={disabled}
            >
              <MStack direction="column" gap={2} align={'flex-start'}>
                {FREQUENCY_ITEMS.map((freq) => (
                  <Radio
                    key={freq.title}
                    value={freq.value.toString()}
                    onChange={(val) => {
                      onChange(val);
                      if (!isNull(customValue)) {
                        setValue('customBillingFrequency', null);
                      }
                    }}
                  >
                    {freq.title}
                  </Radio>
                ))}
              </MStack>
            </MRadioGroup>
          );
        }}
      ></Controller>
      <MRadioGroup value={billingFreqValue}>
        <Stack direction="row">
          <Radio
            value="0"
            onChange={(val) => {
              setValue('billingFrequency', '0');
            }}
            isDisabled={disabled}
          >
            <MFlex gap="2" align="center">
              <MText>Every</MText>
            </MFlex>
          </Radio>
          <MFlex gap="2" align="center">
            <MCustomNumberInput
              isDisabled={disabled}
              value={customValue ?? ''}
              w="3.25rem"
              onFocus={() => {
                if (!billingFreqValue) {
                  setValue('billingFrequency', '0');
                }
              }}
              onChange={handleCustomBillingFrequencyChange}
            />
            <MText>Month(s)</MText>
          </MFlex>
        </Stack>
      </MRadioGroup>
    </MFormField>
  );
};
