import { addMonths } from 'date-fns/addMonths';
import { isAfter } from 'date-fns/isAfter';
import { parseISO } from 'date-fns/parseISO';
import { startOfDay } from 'date-fns/startOfDay';
import { useMemo } from 'react';
import { UpcomingRenewalIcon } from '~app/assets/icons/UpcomingRenewalIcon';
import {
  ContractEndActionEnum,
  ContractStatusEnum,
  IContract,
} from '~app/types';
import { MIcon, MTooltip } from '../Monetize';

interface IContractRenewalIndicatorProps {
  contract: IContract;
}
export const ContractRenewalIndicator = ({
  contract,
}: IContractRenewalIndicatorProps) => {
  const { hasCountdown, message, iconColor } = useMemo((): {
    hasCountdown: boolean;
    message?: string;
    iconColor?: string;
  } => {
    const currentDate = startOfDay(new Date());
    const renewalDate = addMonths(parseISO(contract.endDate), -3);
    const warningDate = addMonths(parseISO(contract.endDate), -1);

    if (isAfter(currentDate, warningDate)) {
      return {
        hasCountdown: true,
        message: 'Renewal is coming up in 1 month',
        iconColor: 'tOrange.tangerine',
      };
    } else if (isAfter(currentDate, renewalDate)) {
      return {
        hasCountdown: true,
        message: 'Renewal is coming up in 3 months',
        iconColor: 'tGray.darkPurple',
      };
    } else if (isAfter(currentDate, parseISO(contract.endDate))) {
      return {
        hasCountdown: true,
        message: 'Contract has already ended',
        iconColor: 'tRed.base',
      };
    }

    return {
      hasCountdown: false,
    };
  }, [contract]);

  const hasUpcomingRenewal =
    contract.endAction === ContractEndActionEnum.MANUAL_RENEW &&
    contract.status === ContractStatusEnum.ACTIVE &&
    hasCountdown;

  return hasUpcomingRenewal ? (
    <MTooltip label={message} placement="bottom-start" shouldWrapChildren>
      <MIcon as={UpcomingRenewalIcon} boxSize={4} color={iconColor} mt={1} />
    </MTooltip>
  ) : null;
};
