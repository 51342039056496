import { Icon, useDisclosure } from '@chakra-ui/react';
import { MdSearch } from 'react-icons/md';
import { useAuth } from '~app/services/auth0';
import { MButton, MFlex, MText } from '~components/Monetize';
import { AppHelpIcon } from '../../assets/icons/AppHelpIcon';
import { ADMIN_ROLES } from '../../constants/roles';
import { useFlags } from '../../services/launchDarkly';
import { MIcon, MTooltip } from '../Monetize/chakra';
import { AppDocsModal } from './AppDocsModal';

interface AppHelpSearchProps {
  isOverlay: boolean;
}

export const AppHelpSearch = ({ isOverlay }: AppHelpSearchProps) => {
  const { isAppDocsSearch } = useFlags();
  const { currentTenantUserHasRole } = useAuth();
  const { onOpen, onClose, isOpen } = useDisclosure();

  const isAdmin = currentTenantUserHasRole(ADMIN_ROLES);

  if (!isAppDocsSearch) {
    return null;
  }

  return (
    <MFlex
      position="relative"
      cursor="pointer"
      alignItems="center"
      justifyContent="center"
      marginBottom={1}
      p={2}
      maxW="inherit"
      pl={isOverlay ? 0 : 4}
      pr={isOverlay ? 0 : 6}
      role="group"
    >
      <MTooltip label={!isOverlay ? '' : 'Search Docs'}>
        {isOverlay ? (
          <MIcon
            width={6}
            height={6}
            as={AppHelpIcon}
            color="tBlue.lightShade"
            onClick={() => onOpen()}
            _hover={{
              color: 'tPurple.dark',
            }}
          />
        ) : (
          <MButton
            variant="unstyled"
            display="flex"
            justifyContent="start"
            border="1px solid"
            borderColor="tGray.lightPurple"
            borderRadius="md"
            textAlign="left"
            maxW={isOverlay ? '32px' : '100%'}
            minW={isOverlay ? '32px' : '100%'}
            p={1}
            fontWeight="normal"
            mx={4}
            onClick={() => onOpen()}
          >
            <Icon as={MdSearch} w="5" h="5" color="tPurple.dark" />
            <MText ml={2} opacity={0.8}>
              Search Docs
            </MText>
          </MButton>
        )}
      </MTooltip>

      {<AppDocsModal isAdmin={isAdmin} isOpen={isOpen} onClose={onClose} />}
    </MFlex>
  );
};
