import { FC, useRef, useState } from 'react';
import { AiOutlineLink } from 'react-icons/ai';
import { MdOutlineFileUpload } from 'react-icons/md';
import {
  MBox,
  MCustomIconButton,
  MFlex,
  MFormField,
  MInput,
  MText,
} from '~app/components/Monetize';
import { MFileDragDropUpload } from '~app/components/Monetize/MFileDragDropUpload';
import { ActiveDocumentType } from '~app/types/quoteDocumentsType';
import { QuoteDocumentLink } from '../../../../../components/Quotes/QuoteDocumentLink';

interface QuoteDocumentsUploadItemProps {
  label: string;
  allowLink?: boolean;
  activeItem: ActiveDocumentType;
  linkUrl: string;
  savedItemText?: string;
  disabled?: boolean;
  isReadOnly?: boolean;
  showDivider?: boolean;
  showControlButton?: boolean;
  dndLabel?: string;
  onSetActiveItem?: (type: ActiveDocumentType) => void;
  onAddItem?: (
    data: { type: 'file'; value: File } | { type: 'link'; value: string },
  ) => void;
  onRemoveItem?: () => void;
  onGotoSavedItem: () => void;
  saveBtnContent?: (data: { value: string; error?: string }) => React.ReactNode;
}

export const QuoteDocumentsUploadItem: FC<QuoteDocumentsUploadItemProps> = ({
  label,
  allowLink,
  activeItem,
  linkUrl,
  savedItemText,
  disabled = false,
  isReadOnly = false,
  showDivider = false,
  onSetActiveItem,
  onAddItem,
  onRemoveItem,
  onGotoSavedItem,
  showControlButton = false,
  dndLabel,
  saveBtnContent,
}: QuoteDocumentsUploadItemProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [internalLinkUrlValue, setInternalLinkUrlValue] =
    useState<string>(linkUrl);
  const [inputError, setInputError] = useState<null | { message: string }>(
    null,
  );

  // Show error message if input is not a proper URL (onBlur)
  function handleInputValidation() {
    if (linkUrl && inputRef.current) {
      const { typeMismatch, tooLong } = inputRef.current.validity;
      if (typeMismatch || tooLong) {
        setInputError({ message: 'A valid URL is required' });
        return;
      }
    }
    setInputError(null);
  }
  const shouldShowUploadControlButtons =
    (!isReadOnly && activeItem === 'none') ||
    (!isReadOnly && showControlButton);
  return (
    <MFlex flexDirection="column">
      <MFlex
        justifyContent="space-between"
        alignItems="center"
        w="100%"
        mb={showDivider ? 0 : 2}
      >
        <MBox>
          <MText fontWeight="bold">{label}</MText>
        </MBox>
        {shouldShowUploadControlButtons && (
          <MFlex alignItems="center">
            <MCustomIconButton
              icon={MdOutlineFileUpload}
              data-testid="upload-file-btn"
              btnSize={6}
              containerSize={6}
              bg="none"
              iconColorHover="tIndigo.base"
              _hover={{
                bg: 'none',
              }}
              _focus={{
                bg: 'none',
              }}
              onClick={() =>
                !savedItemText && onSetActiveItem && onSetActiveItem('file')
              }
            />
            {allowLink && (
              <MCustomIconButton
                icon={AiOutlineLink}
                data-testid="set-link-btn"
                btnSize={6}
                containerSize={6}
                ml={2}
                iconColorHover="tIndigo.base"
                bg="none"
                _hover={{
                  bg: 'none',
                }}
                _focus={{
                  bg: 'none',
                }}
                onClick={() =>
                  !savedItemText && onSetActiveItem && onSetActiveItem('link')
                }
              />
            )}
          </MFlex>
        )}
      </MFlex>

      {!savedItemText && !disabled && activeItem === 'file' && (
        <MFileDragDropUpload
          p={3}
          mt={showDivider ? 4 : 2}
          mb={showDivider ? 0 : 2}
          accept={['pdf']}
          label={dndLabel ? dndLabel : `Drag and Drop your ${label} file here`}
          onFileUpload={(file) =>
            onAddItem && onAddItem({ type: 'file', value: file })
          }
          maxFileSize={10}
        />
      )}

      {!savedItemText && !disabled && activeItem === 'link' && (
        <MFlex align="flex-start" gap={2}>
          <MFormField error={inputError}>
            <MInput
              ref={inputRef}
              placeholder="https://"
              maxLength={255}
              type="url"
              onBlur={handleInputValidation}
              onChange={(event) => {
                if (saveBtnContent) {
                  setInternalLinkUrlValue(event.target.value);
                  if (inputRef.current) {
                    const { typeMismatch, tooLong } = inputRef.current.validity;
                    if (typeMismatch || tooLong) {
                      setInputError({ message: 'A valid URL is required' });
                      return;
                    }
                  }
                  setInputError(null);
                } else {
                  onAddItem &&
                    onAddItem({ type: 'link', value: event.target.value });
                }
              }}
              value={saveBtnContent ? internalLinkUrlValue : linkUrl}
              mt={showDivider ? 4 : 0}
            />
          </MFormField>
          {saveBtnContent &&
            saveBtnContent({
              error: inputError?.message,
              value: inputRef.current?.value ?? '',
            })}
        </MFlex>
      )}

      {!savedItemText && isReadOnly && (
        <MText fontStyle="italic" mb="2">
          No document added
        </MText>
      )}

      {savedItemText && (
        <MBox mr="auto" mb={showDivider ? 0 : 2}>
          <QuoteDocumentLink
            showDeleteIcon
            showDivider={showDivider}
            fileName={savedItemText}
            isReadOnly={isReadOnly}
            disabled={disabled}
            onClick={onGotoSavedItem}
            onRemoveItem={onRemoveItem}
          />
        </MBox>
      )}
    </MFlex>
  );
};
