import { Flex } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { handleApiErrorToast } from '~app/api/axios';
import { INoteReqSchema, INoteResSchema, NoteReqSchema } from '~app/types';
import {
  MBox,
  MButton,
  MCloseButton,
  MFlex,
  MFormField,
  MGrid,
  MGridItem,
  MInput,
  MTextarea,
} from '../Monetize';

interface INoteFormProps {
  initialNote?: INoteResSchema;
  onClose: () => void;
  createNote: (data: INoteReqSchema) => void;
  updateNote: (data: INoteReqSchema) => void;
  handleNoteModified?: (action: 'ADD' | 'CANCEL', note: INoteResSchema) => void;
}

export const NoteForm = (props: INoteFormProps) => {
  const { initialNote, onClose, createNote, updateNote, handleNoteModified } =
    props;

  const [submitLoading, setSubmitLoading] = useState(false);

  const {
    handleSubmit,
    reset,
    control,
    watch,
    getValues,
    formState: { errors },
  } = useForm<INoteReqSchema>({
    resolver: zodResolver(NoteReqSchema),
    mode: 'onSubmit',
    defaultValues: { ...initialNote },
  });

  const watchNoteBody = watch('note');
  const watchSubject = watch('subject');

  useEffect(() => {
    if (handleNoteModified) {
      const tempNote = getValues() as INoteResSchema;
      if (tempNote.id) {
        handleNoteModified('ADD', tempNote);
      } else {
        handleNoteModified('ADD', tempNote);
      }
    }
  }, [getValues, handleNoteModified, watchNoteBody, watchSubject]);

  const onSubmit = async (data: INoteReqSchema) => {
    try {
      setSubmitLoading(true);
      if (!data?.id) {
        createNote(data);
      } else {
        updateNote(data);
      }
      onClose();
      reset();
      setSubmitLoading(false);
    } catch (error) {
      setSubmitLoading(false);
      handleApiErrorToast(error);
    }
  };

  return (
    <MBox px="4" py="4" mb={2} bgColor="tWhite.base">
      <form onSubmit={handleSubmit(onSubmit)}>
        <MFlex justifyContent="flex-end">
          <MCloseButton onClick={() => onClose()} />
        </MFlex>
        <MGrid templateColumns="repeat(12, 1fr)" gap={4}>
          <MGridItem colSpan={12}>
            <MFormField error={errors.subject} label="Title" isRequired>
              <Controller
                name="subject"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <MInput
                    {...field}
                    autoFocus
                    placeholder="Note Title"
                    isDisabled={submitLoading}
                  />
                )}
              />
            </MFormField>
          </MGridItem>

          <MGridItem colSpan={12}>
            <MFormField error={errors.note} label="Comments" isRequired>
              <Controller
                name="note"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <MTextarea
                    {...field}
                    placeholder="Add notes"
                    isDisabled={submitLoading}
                  />
                )}
              />
            </MFormField>
          </MGridItem>
          <MGridItem colSpan={12}>
            <Flex alignItems="center" justifyContent="flex-end">
              <MButton
                ml="2"
                minWidth="auto"
                variant="primary"
                onClick={handleSubmit(onSubmit)}
                isDisabled={submitLoading}
                isLoading={submitLoading}
              >
                Save
              </MButton>
            </Flex>
          </MGridItem>
        </MGrid>
      </form>
    </MBox>
  );
};
