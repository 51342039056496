import { useDisclosure } from '@chakra-ui/react';
import { ColumnProps } from 'primereact/column';
import { useEffect, useState } from 'react';
import {
  useGetTenantApiKeys,
  useRevokeTenantApiKey,
} from '~api/settingsService';
import DataTableActions, {
  ActionType,
} from '~app/components/Monetize/DataTable/MDataTableActions';
import { API_KEY_STATUS_DISPLAY } from '~app/constants/apiKeys';
import { useSettingsReadOnlyRule } from '~app/hooks/useSettingsReadOnlyRule';
import { useACL } from '~app/services/acl/acl';
import { useDocumentHead } from '~app/services/documentHead';
import { useToast } from '~app/services/toast';
import {
  dateBodyTemplate,
  statusBodyTemplate,
  textBodyTemplate,
} from '~app/utils/tableUtils';
import {
  MBox,
  MButton,
  MDataTable,
  MFlex,
  MPageContainer,
  MSettingsPageHeader,
  MText,
} from '~components/Monetize';
import {
  ApiKeyStatusEnum,
  DEFAULT_PAGER,
  IApiKey,
  TDataTablePager,
} from '~types';
import APIKeyForm from './APIKeyForm';
import { ApiKeyModal } from './ApiKeyModal';

export const ApiKeysPage = () => {
  const { setDocTitle } = useDocumentHead();
  const { canDo } = useACL();
  const [addedNewApiKey, setAddedNewApiKey] = useState<string>('');
  const [pager, setPager] = useState<TDataTablePager>(DEFAULT_PAGER);

  const [globalFilter, setGlobalFilter] = useState<string>('');
  const { addToast } = useToast();
  const { mutateAsync: doRevokeTenantApiKey } = useRevokeTenantApiKey();

  const {
    isOpen: isApiKeyModalOpen,
    onOpen: onOpenApiKeyModal,
    onClose: onCloseApiKeyModal,
  } = useDisclosure();

  const {
    isOpen: isViewApiKeyModalOpen,
    onOpen: onOpenViewApiKeyModal,
    onClose: onCloseViewApiKeyModal,
  } = useDisclosure();

  const { data: tenantApiKeys, isLoading } = useGetTenantApiKeys({
    config: pager,
    filters: {
      name: { contains: globalFilter },
    },
    options: {},
  });

  const doDeactivateAPIKey = async (apiKeyId: string) => {
    await doRevokeTenantApiKey(apiKeyId);
    addToast({
      summary: 'API Key deactivated',
      detail: `You have successfully deactivated the API key `,
      severity: 'success',
    });
  };
  const { isReadOnly } = useSettingsReadOnlyRule();
  const actionBodyTemplate = (rowData: IApiKey) => {
    if (isReadOnly) {
      return null;
    }

    const actions: ActionType[] = [
      {
        title: 'Deactivate',
        enabled: rowData.status === ApiKeyStatusEnum.ACTIVE,
        color: 'danger',
        confirmProps: {
          title: `Are you sure you want to deactivate this API key?`,
          description:
            'Any integrations using this API key will no longer work.',
        },
        action: () => doDeactivateAPIKey(rowData.id),
      },
    ];

    return <DataTableActions actions={actions} />;
  };

  const columns: ColumnProps[] = [
    {
      className: 'overflow-hidden',
      field: 'name',
      header: 'Name',
      body: textBodyTemplate<IApiKey>('name'),
    },

    {
      className: 'overflow-hidden',
      field: 'status',
      header: 'Status',
      body: statusBodyTemplate<IApiKey, ApiKeyStatusEnum>(
        'status',
        API_KEY_STATUS_DISPLAY,
      ),
    },
    {
      className: 'overflow-hidden',
      field: 'createdAt',
      header: 'Date Created',
      body: dateBodyTemplate<IApiKey>('createdAt'),
    },

    {
      className: 'overflow-hidden table-cell-sm',
      field: 'action',
      header: '',
      body: actionBodyTemplate,
    },
  ];

  useEffect(() => {
    setDocTitle('Settings', 'API Keys');
  }, []);

  return (
    <MPageContainer data-testid="tenant-container" alignItems="stretch">
      <MSettingsPageHeader title="API Keys">
        {tenantApiKeys?.apiKeys.length !== 0 && !isLoading && !isReadOnly && (
          <MFlex>
            <MButton
              variant="primary"
              ml="2"
              onClick={onOpenApiKeyModal}
              data-testid="invite-users-btn"
            >
              New API Key
            </MButton>
          </MFlex>
        )}
      </MSettingsPageHeader>

      <MText mb={6} color="tGray.darkGrayPurple">
        API keys allow you to integrate with MonetizeNow and build rich
        integrations. Refer to the API documentation.
      </MText>

      <MBox width="100%">
        <MDataTable
          value={tenantApiKeys?.apiKeys}
          totalRecords={tenantApiKeys?.apiKeys.length}
          pager={pager}
          setPager={setPager}
          rowHover
          emptyProps={{
            mainMessage: 'Looks like there are no API Keys added yet.',
            btnLabel: canDo([['apiKeys', 'create']]) ? 'New API Key' : '',
            onClick: onOpenApiKeyModal,
          }}
          filtersApplied={!!globalFilter}
          className="p-datatable-responsive"
          loading={isLoading}
          columns={columns}
          paginator={false}
        />
      </MBox>

      <APIKeyForm
        isOpen={isApiKeyModalOpen}
        showApiKeyModal={(apiKey) => {
          setAddedNewApiKey(apiKey);
          onOpenViewApiKeyModal();
        }}
        onClose={() => {
          onCloseApiKeyModal();
        }}
      />

      <ApiKeyModal
        isOpen={isViewApiKeyModalOpen}
        onClose={() => {
          setAddedNewApiKey('');
          onCloseViewApiKeyModal();
        }}
        apiKey={addedNewApiKey}
      />
    </MPageContainer>
  );
};
