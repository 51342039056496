import { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useDocumentHead } from '~app/services/documentHead';
import { TeamDetails } from './TeamDetails/TeamDetails';
import { TeamForm } from './TeamForm/TeamForm';
import { TeamList } from './TeamList/TeamList';

export const TeamsPage = () => {
  const { setDocTitle } = useDocumentHead();

  useEffect(() => {
    setDocTitle('Settings', 'Teams');
  }, []);

  return (
    <Routes>
      <Route path="" element={<TeamList />} />
      <Route path="/new" element={<TeamForm />} />
      <Route path="/:id" element={<TeamDetails />} />
      <Route path="/:id/edit" element={<TeamForm />} />
    </Routes>
  );
};
