import React, { FunctionComponent as FC } from 'react';
import { MdKeyboardArrowLeft } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import {
  MBox,
  MCenter,
  MDivider,
  MFlex,
  MHeading,
  MIcon,
  MIDCopyBox,
  MText,
  MTooltip,
} from '.';
import { MEntityStatusBadge } from './MEntityStatusBadge';

export interface MSettingsPageHeaderProps {
  title?: string | React.ReactNode;
  tag?: React.ReactNode;
  id?: string;
  divider?: boolean;
  hasBackButton?: boolean;
  backButtonTitle?: string;
  backButtonLink?: string | object;
  backButtonCallback?: () => void;
  children?: React.ReactNode;
  status?: string;
  extraSubtitleParts?: React.ReactNode[];
}

type BackButtonProps = Pick<
  MSettingsPageHeaderProps,
  'backButtonTitle' | 'backButtonLink' | 'backButtonCallback'
>;

const BackButton: FC<BackButtonProps> = ({
  backButtonTitle,
  backButtonLink,
  backButtonCallback,
}: BackButtonProps) => {
  const navigate = useNavigate();
  let backButtonFn = backButtonCallback;
  if (backButtonLink) {
    backButtonFn = () => navigate(backButtonLink);
  }
  return (
    <MBox
      data-testid="settings-page-back-button"
      onClick={
        backButtonCallback || backButtonLink ? backButtonFn : () => navigate(-1)
      }
    >
      <MTooltip label={backButtonTitle}>
        {/* the span is necessary to handle the ref Tooltip passes, which react-icons can't handle, see https://chakra-ui.com/docs/overlay/tooltip */}
        <span>
          <MIcon
            as={MdKeyboardArrowLeft}
            w={5}
            h={5}
            mt="3px"
            color="tGray.darkPurple"
            cursor="pointer"
            _hover={{ color: 'tPurple.base' }}
            verticalAlign="middle"
          />
        </span>
      </MTooltip>
    </MBox>
  );
};

const MSettingsPageHeader: FC<MSettingsPageHeaderProps> = React.forwardRef<
  any,
  MSettingsPageHeaderProps
>(
  (
    {
      title,
      tag,
      id,
      divider = true,
      hasBackButton = false,
      backButtonTitle,
      backButtonLink,
      backButtonCallback,
      children,
      status,
      extraSubtitleParts,
    }: MSettingsPageHeaderProps,
    ref,
  ) => {
    return (
      <MBox w="100%" mb={6} ref={ref}>
        <MFlex
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
          ml={hasBackButton ? -2 : 0}
        >
          <MFlex>
            {hasBackButton && (
              <BackButton
                backButtonTitle={backButtonTitle}
                backButtonLink={backButtonLink}
                backButtonCallback={backButtonCallback}
              />
            )}

            <MFlex align="center" ml={hasBackButton ? 2 : 0} justify="center">
              <MHeading size="lg">{title}</MHeading>
              {status && <MEntityStatusBadge status={status} />}
              {tag && (
                <MText as="span" mt={1}>
                  {tag}
                </MText>
              )}
            </MFlex>
          </MFlex>

          <MFlex alignItems="center">{children}</MFlex>
        </MFlex>
        <MFlex ml={3.5} mt={0.5} columnGap={2} alignItems="center">
          {id && <MIDCopyBox copyValue={id} />}

          {extraSubtitleParts?.map((part, i) => (
            <React.Fragment key={i}>
              {/* Determine if divider should be rendered based on a prior item existing */}
              {(i !== 0 || !!id) && (
                <MCenter m={0} w={0} height={3}>
                  <MDivider
                    m={0}
                    orientation="vertical"
                    borderColor="tGray.darkGrayPurple"
                  />
                </MCenter>
              )}
              {part}
            </React.Fragment>
          ))}
        </MFlex>
        {divider && (
          <MDivider
            mt={2}
            borderWidth="1px"
            borderColor="tBlue.hover"
            w="100%"
          />
        )}
      </MBox>
    );
  },
);

export default MSettingsPageHeader;
