import { format as formatDate } from 'date-fns/format';
import clamp from 'lodash/clamp';

interface State {
  isValid: boolean;
  hour: StateSegment;
  minute: StateSegment;
  amPm: StateSegment;
}

export type SegmentType = 'hour' | 'minute' | 'amPm';

export interface StateSegment {
  type: SegmentType;
  min: number;
  max: number;
  value: number;
  textValue: string;
  placeholder: string;
  showPlaceholder: boolean;
}

export const getTimPickerInitialState = (date?: Date | null): State => {
  const initialState: State = {
    isValid: !!date,
    hour: {
      type: 'hour',
      min: 1,
      max: 12,
      value: 0,
      textValue: '',
      placeholder: '--',
      showPlaceholder: true,
    },
    minute: {
      type: 'minute',
      min: 0,
      max: 59,
      value: 0,
      textValue: '',
      placeholder: '--',
      showPlaceholder: true,
    },
    amPm: {
      type: 'amPm',
      min: 0,
      max: 12,
      value: 0,
      textValue: 'AM',
      placeholder: 'AM',
      showPlaceholder: true,
    },
  };

  if (date) {
    const [hour, minute, amPm] = formatDate(date, 'h mm a').split(' ');
    initialState.hour = {
      ...initialState.hour,
      value: Number(hour),
      textValue: hour,
      showPlaceholder: false,
    };
    initialState.minute = {
      ...initialState.minute,
      value: Number(minute),
      textValue: minute,
      showPlaceholder: false,
    };
    initialState.amPm = {
      ...initialState.amPm,
      value: amPm === 'AM' ? 0 : 12,
      textValue: amPm,
      showPlaceholder: false,
    };
  }

  return initialState;
};

type Action =
  | {
      type: 'INIT';
      payload: { value?: Date | null };
    }
  | {
      type: 'CLEAR';
      payload: { type: SegmentType };
    }
  | {
      type: 'UPDATE';
      payload: {
        type: SegmentType;
        value: number;
      };
    };

export function timePickerReducer(state: State, action: Action): State {
  switch (action.type) {
    case 'INIT': {
      return { ...getTimPickerInitialState(action.payload.value) };
    }
    case 'CLEAR': {
      const { type } = action.payload;
      if (type === 'amPm') {
        return {
          ...state,
          isValid: !!state.hour.textValue && !!state.minute.textValue,
          amPm: {
            ...state.amPm,
            value: 0,
            showPlaceholder: true,
            textValue: 'AM',
          },
        };
      }
      return {
        ...state,
        isValid: false,
        [type]: {
          ...state[type],
          value: 0,
          showPlaceholder: true,
          textValue: '',
        },
      };
    }
    case 'UPDATE': {
      const { type, value } = action.payload;
      let isValid = !!state.hour.textValue && !!state.minute.textValue;
      const segment = { ...state[type] };
      segment.showPlaceholder = false;
      if (type === 'amPm') {
        segment.textValue = value === 0 ? 'AM' : 'PM';
        segment.value = value === 0 ? 0 : 12;
      } else {
        segment.value = clamp(value, segment.min, segment.max);
        segment.textValue = `${segment.value}`;
        isValid = isValid && !!segment.textValue;
      }
      if (type === 'minute') {
        segment.textValue = segment.textValue.padStart(2, '0');
      }
      return { ...state, isValid, [type]: segment };
    }
    default:
      throw new Error('Invalid action');
  }
}
