import { usePrintInvoiceToHtmlForInvoiceShare } from '~app/api/shareService';
import { HtmlIFrameContentRenderer } from '~app/components/Monetize/HtmlIFrameContentRenderer';
import { IInvoiceRespSchema } from '~app/types';

interface InvoiceHtmlPublicProps {
  invoice: IInvoiceRespSchema;
  tenantId?: string;
  invoiceSecretId?: string;
}

export const InvoiceHtmlPublic = ({
  invoice,
  tenantId,
  invoiceSecretId,
}: InvoiceHtmlPublicProps) => {
  const { data, isLoading, isRefetching, error, isInitialLoading } =
    usePrintInvoiceToHtmlForInvoiceShare(
      {
        invoiceSecretId: invoiceSecretId!,
        tenantId: tenantId!,
        lastModifiedTimestamp: invoice.modifyDate,
      },
      { enabled: !!invoiceSecretId && !!tenantId },
    );

  if (error) {
    // TODO: add error state
    return null;
  }

  return (
    <HtmlIFrameContentRenderer
      id="invoice-content"
      html={data}
      isInitialLoading={isInitialLoading}
      isReFetching={isLoading || isRefetching}
    ></HtmlIFrameContentRenderer>
  );
};
