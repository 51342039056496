import {
  Box,
  Divider,
  LinkBox,
  LinkOverlay,
  List,
  ListItem,
} from '@chakra-ui/react';
import { FC, Fragment, MutableRefObject, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ApiListResponse } from '~app/types';
import { MHStack, MTag } from '../chakra';
import { Highlighter } from './Highlighter';
import { SearchedItemType } from './MSearchInput';
import { useKeyPress } from './useKeyPress';
import { sortSearchRecordByStatus } from './utils';

interface SearchedListProps {
  debouncedValue: string;
  nameField: string;
  isFetching: boolean;
  cursor: number;
  data: ApiListResponse<SearchedItemType>;
  itemRefs: MutableRefObject<{
    [index: number]: HTMLAnchorElement;
  }>;
  setCursorAndScroll: (index: number) => void;

  handleReset: () => void;
  generateRoute: (id: string) => string;
}

export const SearchedList: FC<SearchedListProps> = ({
  debouncedValue,
  nameField,
  isFetching,
  data,
  cursor,
  itemRefs,
  setCursorAndScroll,

  handleReset,
  generateRoute,
}) => {
  const arrowUpPressed = useKeyPress('ArrowUp');
  const arrowDownPressed = useKeyPress('ArrowDown');
  const enterPressed = useKeyPress('Enter');

  useEffect(() => {
    if (arrowUpPressed) {
      const nextIndex = cursor !== 0 ? cursor - 1 : data.content.length - 1;
      setCursorAndScroll(nextIndex);
    }
  }, [arrowUpPressed, data]);

  useEffect(() => {
    if (arrowDownPressed) {
      const nextIndex = cursor !== data.content.length - 1 ? cursor + 1 : 0;
      setCursorAndScroll(nextIndex);
    }
  }, [arrowDownPressed, data]);

  useEffect(() => {
    if (
      enterPressed &&
      typeof itemRefs.current?.[cursor]?.click === 'function'
    ) {
      itemRefs.current?.[cursor].click();
    }
  }, [enterPressed, cursor]);

  const searchWords = debouncedValue.split(/\s/).filter((word) => word);
  const records = data?.content || [];

  return (
    <List maxH="30rem" overflowY="auto">
      {!isFetching &&
        sortSearchRecordByStatus(records).map(
          ({ id, customId, [nameField]: name, status }, index) => {
            return (
              <Fragment key={id}>
                <LinkBox
                  as={ListItem}
                  borderRadius="3"
                  p="2"
                  tabIndex={-1}
                  cursor="pointer"
                  role="presentation"
                  _hover={{ background: 'tGray.support' }}
                  background={
                    index === cursor ? 'tGray.support' : 'transparent'
                  }
                >
                  <MHStack>
                    {status === 'INACTIVE' && (
                      <MTag variant="gray" fontSize="xs">
                        Inactive
                      </MTag>
                    )}
                    <LinkOverlay
                      as={Link}
                      to={generateRoute(id)}
                      ref={(element: HTMLAnchorElement) => {
                        itemRefs.current[index] = element;
                      }}
                      onClick={() => handleReset()}
                    >
                      <Highlighter
                        textToHighlight={name}
                        searchWords={searchWords}
                        fontWeight="500"
                      />
                    </LinkOverlay>
                  </MHStack>
                  <Highlighter
                    color="tGray.darkPurple"
                    textToHighlight={id}
                    searchWords={searchWords}
                    fontSize="xs"
                  />
                  {customId && (
                    <Highlighter
                      color="tGray.darkPurple"
                      textToHighlight={customId}
                      searchWords={searchWords}
                      fontSize="xs"
                    />
                  )}
                </LinkBox>
                {records.length > 1 && records.length !== index + 1 && (
                  <Box pr="2">
                    <Divider mx="2" />
                  </Box>
                )}
              </Fragment>
            );
          },
        )}
    </List>
  );
};
