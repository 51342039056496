import { FC } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import {
  MBox,
  MCard,
  MCustomIconButton,
  MCustomSelect,
  MFlex,
  MHStack,
  MPageLoader,
  MText,
  QuoteSelectInput,
} from '~app/components/Monetize';
import {
  IQuoteRequestSchema,
  ContactTypes,
  IContactRespSchema,
  INetTerm,
  QuoteSettingsDefaultAddressSourceEnum,
} from '~app/types';
import { useQuoteContext } from '../../Quote/quoteContext';
import { AccountAddressSelect } from '~app/components/Account/AccountAddressSelect';
import { AddPoNumberSectionV2 } from '~app/components/Quotes/AddPoNumberSectionV2';
import { QuoteBasicContactSelect } from '~app/components/Quotes/QuoteBasicContactSelect';
import { QuoteBasicInfoLine } from '~app/components/Quotes/QuoteBasicInfoLine';
import { useQuoteSettings, useNetTerms } from '~app/hooks';
import { LegalEntityAddressFormatEnum } from '~app/types/legalEntityTypes';
import { getAddress } from '~app/utils/address';
import { MdSettings } from 'react-icons/md';

interface QuoteInternalBillingInfoProps {
  onOpenContactDrawer: () => void;
}
export const QuoteInternalBillingInfo: FC<QuoteInternalBillingInfoProps> = ({
  onOpenContactDrawer,
}: QuoteInternalBillingInfoProps) => {
  const {
    isReadOnly,
    quoteContacts,
    quoteData: { quote, setQuote },
    handleSubmitButtonWithoutDirtyCheck,
    setIsQuoteSaving,
  } = useQuoteContext();

  const { quoteSettings } = useQuoteSettings();

  const { control, getValues, setValue } =
    useFormContext<IQuoteRequestSchema>();

  const selectedNetTerm = getValues('netTerms');
  const { activeNetTerms, netTermsById } = useNetTerms();
  // make sure that if inactive net term is selected, it is included in dropdown
  let netTermsOptions: INetTerm[] = activeNetTerms;
  if (
    selectedNetTerm &&
    netTermsById[selectedNetTerm] &&
    !netTermsById[selectedNetTerm].active
  ) {
    netTermsOptions = [...netTermsOptions, netTermsById[selectedNetTerm]];
  }

  const {
    externalContacts,
    externalAccountContacts,
    handleChangeContact,
    addressDataForm,
    addressSource,
    isDisabled,
  } = quoteContacts;
  const billingAddressId = addressDataForm.watch('billingAddressId');

  const billingContact = externalContacts.find((contact) => contact.billing);

  const addressFormat = LegalEntityAddressFormatEnum.US_FORMAT;

  const handleChangeContactAndEvaluate = async (
    contact: IContactRespSchema,
    type: ContactTypes,
    isInternal: boolean,
  ) => {
    handleChangeContact(contact, type, isInternal);
  };

  if (!quote) {
    return <MPageLoader />;
  }

  const lineProps = {
    orientation: 'vertical' as const,
    w: '170px',
    mr: '6',
    maxW: '170px',
  };
  return (
    <MCard width={'100%'} p={6} borderColor="tGray.lightPurple">
      <MFlex flexWrap={'wrap'}>
        {quoteSettings?.allowPONumberOnQuotes && (
          <QuoteBasicInfoLine title="PO Number" {...lineProps}>
            <MBox>
              <AddPoNumberSectionV2
                quote={quote}
                setQuote={setQuote}
                setIsQuoteSaving={setIsQuoteSaving}
                buttonProps={{ ml: '-3' }}
                previewPropsNonEdit={{
                  justifyContent: 'flex-start',
                }}
                inputProps={{
                  maxW: '100px',
                }}
              />
            </MBox>
          </QuoteBasicInfoLine>
        )}

        <QuoteBasicInfoLine title="Net Terms" {...lineProps}>
          <MBox ml={isReadOnly ? 0 : '-3'}>
            <Controller
              name="netTerms"
              control={control}
              render={({ field: { onChange, ...rest } }) => (
                <MCustomSelect
                  isDisabled={isReadOnly}
                  isReadOnly={isReadOnly}
                  MainInputComponent={QuoteSelectInput}
                  itemTitle="name"
                  itemValue="value"
                  items={netTermsOptions}
                  popOverProps={{
                    placement: 'bottom-start',
                    matchWidth: false,
                  }}
                  popOverContentProps={{ minWidth: '10rem' }}
                  onChange={(e: any) => {
                    onChange(e);
                    handleSubmitButtonWithoutDirtyCheck();
                  }}
                  showContentInInput
                  {...rest}
                />
              )}
            />
          </MBox>
        </QuoteBasicInfoLine>

        <QuoteBasicInfoLine
          title="Billing Contact"
          showRequired={!billingContact}
          {...lineProps}
          labelExtra={
            <MCustomIconButton
              btnSize={4}
              minW={6}
              minH={6}
              variant="icon"
              icon={MdSettings}
              iconColor="tIndigo.base"
              onClick={() => {
                onOpenContactDrawer();
              }}
            />
          }
        >
          <MBox ml={isDisabled ? 0 : '-3'}>
            <QuoteBasicContactSelect
              value={billingContact?.contact}
              onChange={(contact) =>
                handleChangeContactAndEvaluate(contact, 'billing', false)
              }
              contacts={externalAccountContacts}
              isDisabled={isDisabled}
              isReadOnly={isDisabled}
              popOverProps={{
                placement: 'bottom-start',
                matchWidth: false,
              }}
              accountId={quote?.accountId}
            />
          </MBox>
        </QuoteBasicInfoLine>

        <QuoteBasicInfoLine
          title="Billing Address"
          showRequired={
            addressSource === QuoteSettingsDefaultAddressSourceEnum.ACCOUNT
              ? !billingAddressId
              : !billingContact
          }
          {...lineProps}
        >
          <MBox
            display="flex"
            flexDir="column"
            alignItems="flex-end"
            textAlign="left"
          >
            <MBox ml={isDisabled ? 0 : '-3'}>
              {addressSource ===
                QuoteSettingsDefaultAddressSourceEnum.ACCOUNT &&
                quote?.accountId && (
                  <Controller
                    name={'billingAddressId'}
                    control={addressDataForm.control}
                    render={({ field: { ...rest } }) => {
                      return (
                        <AccountAddressSelect
                          isDisabled={isDisabled}
                          isReadOnly={isDisabled}
                          addressFormat={addressFormat}
                          accountId={quote?.accountId}
                          MainInputComponent={QuoteSelectInput}
                          showContentInInput
                          renderContentInInput={({
                            inputTitle,
                            onToggleOpen,
                          }) => (
                            <MFlex align="center" onClick={onToggleOpen}>
                              <MText
                                fontWeight="normal"
                                isTruncated
                                noOfLines={2}
                                color="inherit"
                                whiteSpace={'initial'}
                                textAlign="left"
                              >
                                {inputTitle}
                              </MText>
                            </MFlex>
                          )}
                          inputProps={{
                            autoComplete: 'off',
                          }}
                          placeholder="Select"
                          trucatedReadonly
                          popOverProps={{
                            placement: 'bottom-start',
                            matchWidth: false,
                          }}
                          popOverContentProps={{
                            width: '100%',
                            maxWidth: '300px',
                          }}
                          {...rest}
                        />
                      );
                    }}
                  />
                )}
            </MBox>
            {addressSource ===
              QuoteSettingsDefaultAddressSourceEnum.CONTACT && (
              <MText>
                {
                  getAddress(billingContact?.contact?.address, {
                    addressFormat,
                  }).fullAddress
                }
              </MText>
            )}
          </MBox>
        </QuoteBasicInfoLine>
      </MFlex>
    </MCard>
  );
};
