import { KeyValueCallout, MGrid } from '~app/components/Monetize';
import {
  useGetActiveAccounts,
  useGetActiveSubscriptions,
  useGetArr,
  useGetMrr,
  useGetUnpaidInvoices,
} from '../../../../api/dashboardsService';

interface FinanceDashboardHeaderItemsProps {
  currency: string;
}

export const FinanceDashboardHeaderItems = ({
  currency,
}: FinanceDashboardHeaderItemsProps) => {
  const { isLoading: arrLoading, data: arr } = useGetArr(currency);
  const { isLoading: mrrLoading, data: mrr } = useGetMrr(currency);
  const { isLoading: activeAccountsLoading, data: activeAccounts } =
    useGetActiveAccounts(currency);
  const { isLoading: activeSubscriptionsLoading, data: activeSubscriptions } =
    useGetActiveSubscriptions(currency);
  const { isLoading: unpaidInvoicesLoading, data: unpaidInvoices } =
    useGetUnpaidInvoices(currency);

  return (
    <MGrid
      justifyItems="start"
      gridTemplateColumns="repeat(5, 1fr 0.1fr)"
      columnGap={2}
    >
      <KeyValueCallout
        testIdRoot="finance-dashboard-callout"
        type="currency"
        label="MRR"
        isLoading={mrrLoading}
        value={mrr?.total}
        currency={currency}
        verticalDividerAfter
      />
      <KeyValueCallout
        testIdRoot="finance-dashboard-callout"
        type="currency"
        label="ARR"
        isLoading={arrLoading}
        value={arr?.total}
        currency={currency}
        verticalDividerAfter
      />
      <KeyValueCallout
        testIdRoot="finance-dashboard-callout"
        type="number"
        label="Active Accounts"
        isLoading={activeAccountsLoading}
        value={activeAccounts?.total}
        verticalDividerAfter
      />
      <KeyValueCallout
        testIdRoot="finance-dashboard-callout"
        type="number"
        label="Active Subscriptions"
        isLoading={activeSubscriptionsLoading}
        value={activeSubscriptions?.total}
        verticalDividerAfter
      />
      <KeyValueCallout
        testIdRoot="finance-dashboard-callout"
        type="currency"
        label="Unpaid Invoices"
        isLoading={unpaidInvoicesLoading}
        value={unpaidInvoices?.total}
        currency={currency}
      />
    </MGrid>
  );
};
