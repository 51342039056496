import { useState } from 'react';
import { MCenterModal, MFlex, MSearchInput } from '~components/Monetize';
import { AppDocsEmptyState } from './AppDocsEmptyState';
import { AppDocsGetMoreHelp } from './AppDocsGetMoreHelp';
import { AppDocsResultItem } from './AppDocsResultItem';
import { useDocsSearch } from './useDocsSearch';

interface AppDocsModalProps {
  isAdmin?: boolean;
  isOpen: boolean;
  onClose: () => void;
}

export const AppDocsModal = ({
  isAdmin = false,
  isOpen,
  onClose,
}: AppDocsModalProps) => {
  const [query, setQuery] = useState('');

  const { data: results, isLoading, isFetching } = useDocsSearch(isOpen, query);

  const hasResults = !!results && results?.length > 0;

  return (
    <MCenterModal
      blockScrollOnMount
      closeOnOverlayClick
      modalBodyProps={{ height: '80vh' }}
      showCloseButton={false}
      modalTitle={
        <MSearchInput
          count={results?.length}
          loading={isLoading || isFetching}
          value={query}
          onChange={setQuery}
          placeholder="Search documentation..."
        />
      }
      renderFooter={() => <AppDocsGetMoreHelp isAdmin={isAdmin} />}
      modalFooterProps={{ justifyContent: 'center', border: 'none' }}
      isOpen={isOpen}
      onClose={onClose}
      size="2xl"
    >
      <MFlex className="searchResults" flexDirection="column">
        {results?.map((item) => (
          <AppDocsResultItem key={item.referenceId} item={item} />
        ))}

        {!hasResults && (
          <AppDocsEmptyState hasSearchQuery={!!query && !isFetching} />
        )}
      </MFlex>
    </MCenterModal>
  );
};
