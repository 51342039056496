import { IPayment, PaymentMethodTypeEnum } from '~app/types';
import { toDateTimeShort } from '~app/utils/dates';
import { colors } from '~styles/theme';
import { MCard, MFlex, MHeading, MText } from '../Monetize';

interface KeyValueDetailProps {
  label: string;
  value: string;
}

const KeyValueDetail = (prop: KeyValueDetailProps) => {
  const id = `label_${prop.label}`;
  return (
    <MFlex direction="column" gap={2} pt={2}>
      <MText id={id} color={colors.tGray.darkPurple}>
        {prop.label}
      </MText>
      <MText aria-labelledby={id}>{prop.value}</MText>
    </MFlex>
  );
};

interface PaymentDetailsProps {
  payment: IPayment;
}

export const PaymentDetails = ({ payment }: PaymentDetailsProps) => {
  const {
    createDate,
    modifyDate,
    paymentType,
    description,
    paymentDate,
    gatewayCode,
    gatewayReference,
    gatewayMessage,
  } = payment;
  return (
    <MCard
      width={'100%'}
      p={6}
      borderRadius="lg"
      borderColor={colors.tGray.lightPurple}
    >
      <MFlex
        borderBottom="2px solid"
        borderColor={colors.tWhite.titanWhite}
        py={2}
      >
        <MHeading color="tPurple.dark" fontSize={16} fontWeight={600}>
          Payment Details
        </MHeading>
      </MFlex>

      <MFlex flexWrap="wrap" justifyContent="space-between" pt="4">
        <KeyValueDetail
          label={'Created'}
          value={toDateTimeShort(createDate ?? '')}
        />
        <KeyValueDetail
          label={'Updated'}
          value={toDateTimeShort(modifyDate ?? '')}
        />
        <KeyValueDetail
          label={'Payment Date'}
          value={toDateTimeShort(
            (paymentType === PaymentMethodTypeEnum.MANUAL
              ? paymentDate
              : createDate) ?? '',
          )}
        />
        {gatewayReference && (
          <KeyValueDetail
            label={'Gateway Reference No.'}
            value={gatewayReference ?? ''}
          />
        )}
        {gatewayCode && (
          <KeyValueDetail label={'Gateway Code'} value={gatewayCode ?? ''} />
        )}
        {gatewayMessage && (
          <KeyValueDetail label={'Gateway Message'} value={gatewayMessage} />
        )}
        {description && (
          <KeyValueDetail label={'Description'} value={description} />
        )}
      </MFlex>
    </MCard>
  );
};
