const ApiKeysIcon = (props: any) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2603_365)">
        <path
          d="M12.4611 19.1537C11.3129 20.3019 9.92835 20.876 8.30739 20.876C6.68644 20.876 5.30187 20.3019 4.1537 19.1537C3.00552 18.0055 2.43143 16.621 2.43143 15C2.43143 13.379 3.00552 11.9945 4.1537 10.8463C5.06548 9.93452 6.05044 9.3942 7.10856 9.22535C8.16669 9.0565 9.18541 9.16907 10.1647 9.56305L17.4253 2.30252L21.0049 5.88213L19.2151 7.67193L21.5283 9.98517L18.2864 13.2271L15.9732 10.9138L13.7443 13.1427C14.1383 14.122 14.2509 15.1407 14.082 16.1988C13.9132 17.257 13.3729 18.2419 12.4611 19.1537ZM11.3467 18.0393C12.1572 17.2288 12.5483 16.2973 12.5202 15.2448C12.492 14.1923 12.2697 13.379 11.8532 12.805L16.0407 8.61749L18.3539 10.9307L19.2995 9.98517L16.9863 7.67193L18.7761 5.88213L17.4253 4.53133L10.5024 11.4542C9.92835 11.0377 9.11506 10.8153 8.06256 10.7872C7.01007 10.7591 6.07858 11.1502 5.2681 11.9607C4.42386 12.805 4.00173 13.8181 4.00173 15C4.00173 16.1819 4.42386 17.195 5.2681 18.0393C6.11235 18.8835 7.12545 19.3057 8.3074 19.3057C9.48934 19.3057 10.5024 18.8835 11.3467 18.0393ZM9.50623 16.1988C9.84392 15.8611 10.0128 15.4615 10.0128 15C10.0128 14.5385 9.84392 14.1389 9.50623 13.8012C9.16853 13.4635 8.76892 13.2946 8.30739 13.2946C7.84587 13.2946 7.44626 13.4635 7.10856 13.8012C6.77086 14.1389 6.60202 14.5385 6.60202 15C6.60202 15.4615 6.77086 15.8611 7.10856 16.1988C7.44626 16.5365 7.84587 16.7054 8.30739 16.7054C8.76892 16.7054 9.16853 16.5365 9.50623 16.1988Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_2603_365">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ApiKeysIcon;
