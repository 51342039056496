import { Dispatch } from 'react';
import { RATE_BILLING_FREQUENCY_DISPLAY } from '~app/constants/offerings';
import { RateBillingFrequencyEnum } from '~app/types';
import { objectToSelectOptions } from '~app/utils/misc';
import MCustomMultiSelect from '../MCustomSelect/MCustomMultiSelect';

interface SelectProps {
  filters: any;
  value: any;
  setValue: Dispatch<any>;
}

const BillingFrequencyDropdownSelect = ({
  filters,
  value,
  setValue,
}: SelectProps) => {
  return (
    <MCustomMultiSelect
      mt="0 !important"
      closeButtonText="Apply"
      items={
        Object.keys(filters ?? {})?.length > 0 && filters.billingFrequency
          ? objectToSelectOptions(
              (
                filters?.billingFrequency.in as Array<RateBillingFrequencyEnum>
              ).reduce((acc: any, key: RateBillingFrequencyEnum) => {
                acc[key] = RATE_BILLING_FREQUENCY_DISPLAY[key];
                return acc;
              }, {}),
            )
          : objectToSelectOptions(RATE_BILLING_FREQUENCY_DISPLAY)
      }
      value={value ?? []}
      onChange={(newValue: any) => setValue(newValue)}
      multiple
      inputProps={{
        _placeholder: { color: 'tPurple.dark' },
        _disabled: {
          _placeholder: {
            color: 'tGray.darkPurple',
          },
        },
      }}
    />
  );
};

export default BillingFrequencyDropdownSelect;
