import { IStorage } from './storageTypes';

export type QuoteDocumentActiveItemType = 'msa' | 'cover' | 'footer';

export type ActiveDocumentType = 'file' | 'link' | 'none';

export interface DocumentItemState {
  savedData: {
    storage?: IStorage;
    link?: string;
  };
  activeItem: ActiveDocumentType;
  linkUrl: string;
  fileToUpload?: {
    file: File;
    fileName: string;
  };
  savedItemText: string;
  hideBtn?: boolean;
  saving?: boolean;
}
export const ActiveItemTypeToMetaDataMap: Record<
  string,
  { documentType: string; type?: string }
> = {
  msa: {
    documentType: 'MSA',
    type: 'FILE',
  },
  link: {
    documentType: 'MSA',
    type: 'URL',
  },
  cover: {
    documentType: 'COVER',
  },
  footer: {
    documentType: 'FOOTER',
  },
};

export const QuoteDocumentActiveItemTypeToConfigurationKey: {
  [key in QuoteDocumentActiveItemType | 'link']: string;
} = {
  msa: 'DEFAULT_DOCUMENT_LOCATION',
  cover: 'DEFAULT_DOCUMENT_COVER',
  footer: 'DEFAULT_DOCUMENT_FOOTER',
  link: 'DEFAULT_DOCUMENT_URL',
};
