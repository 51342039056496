import { CardBody } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import {
  MBox,
  MButton,
  MCard,
  MCloseButton,
  MDivider,
  MFlex,
  MHeading,
  MText,
  MTooltip,
  MVStack,
} from '~app/components/Monetize';
import { ContactWithTypes, IContactRespSchema } from '~app/types';
import { sortAlphabetically } from '~app/utils';
import { QuoteContactForm } from './QuoteContactForm';
import QuoteContactsSelectInput from './QuoteContactsSelectInput';

interface IQuoteContactAdditionalRecipientsProps {
  accountId: string;
  loadingContacts: boolean;
  contacts: IContactRespSchema[]; // external(Accounts) contacts
  selectedContacts: IContactRespSchema[];
  selectedExternalContacts: ContactWithTypes[];
  onSelectedContactsChange?: (contactId: string) => void;
  handleCreatedNewContact?: (contact: IContactRespSchema) => void;
  onRemoveAdditionalContact: (contactId: string) => void;
}

const AdditionalContactItems = ({
  contact,
  onRemove,
}: {
  contact: IContactRespSchema;
  onRemove: (id: string) => void;
}) => {
  const { id, fullName, email } = contact;

  return (
    <MFlex
      key={id}
      flexDirection="row"
      mb={4}
      data-testid="additional-contact-items"
    >
      <MVStack width="full">
        <MText fontSize="md" isTruncated w="full">
          {fullName}
        </MText>
        <MText color="tGray.darkPurple" isTruncated w="full">
          {email}
        </MText>
      </MVStack>

      <MTooltip label="Remove Contact" placement="bottom-end">
        <MCloseButton
          aria-label="Remove Contact"
          onClick={() => onRemove(id)}
        />
      </MTooltip>
    </MFlex>
  );
};

export const QuoteContactAdditionalRecipients = ({
  accountId,
  loadingContacts,
  contacts,
  selectedContacts,
  selectedExternalContacts,
  onSelectedContactsChange,
  handleCreatedNewContact = (contact: IContactRespSchema) => {},
  onRemoveAdditionalContact,
}: IQuoteContactAdditionalRecipientsProps) => {
  const [availableContacts, setAvailableContacts] = useState(contacts);
  const [addContactActive, setAddContactActive] = useState(
    !loadingContacts && !selectedContacts?.length,
  );
  const [newContactOpen, setNewContactOpen] = useState(false);

  useEffect(() => {
    if (contacts && selectedContacts) {
      let externalContactId = '';
      if (selectedExternalContacts) {
        const extContactAsEsign = selectedExternalContacts.filter(
          (extAcc) => extAcc.esign && !extAcc.contact.internal,
        );
        externalContactId = extContactAsEsign[0]?.contact.id;
      }
      const contactIds = new Set(selectedContacts.map(({ id }) => id));
      setAvailableContacts(
        contacts
          .filter(({ id }) => !contactIds.has(id) && id !== externalContactId)
          .sort((a, b) => sortAlphabetically('fullName')(a, b)),
      );
    }
  }, [contacts, selectedContacts]);

  const handleOnNewContactSelect = () => {
    setAddContactActive(false);
    setNewContactOpen(true);
  };

  const onCloseContactForm = (contact?: IContactRespSchema) => {
    if (contact) {
      handleCreatedNewContact && handleCreatedNewContact(contact);
    }

    setNewContactOpen(false);
    setAddContactActive(true);
  };

  return (
    <MCard variant="borderless" data-testid="additional-contact-recipients">
      <CardBody>
        <MHeading size="sm" fontWeight="semibold" pb={4}>
          Additional Recipients
        </MHeading>
        <MBox width="full">
          {selectedContacts
            ?.sort(sortAlphabetically('fullName'))
            .map((contact) => (
              <AdditionalContactItems
                key={contact.id}
                contact={contact}
                onRemove={onRemoveAdditionalContact}
              />
            ))}
        </MBox>

        {newContactOpen && (
          <>
            <MDivider />
            <MCard variant="borderless">
              <CardBody p={0} pt={4}>
                <QuoteContactForm
                  data-testid="additional-contact-form"
                  accountId={accountId}
                  isCcContact
                  isCompact={false}
                  onClose={onCloseContactForm}
                />
              </CardBody>
            </MCard>
          </>
        )}

        {!addContactActive && !newContactOpen && (
          <MBox>
            <MButton
              data-testid="add-additional-contact-btn"
              variant="tertiary"
              size="sm"
              onClick={() => setAddContactActive(true)}
            >
              + Add Contact
            </MButton>
          </MBox>
        )}

        {addContactActive && (
          <QuoteContactsSelectInput
            data-testid="additional-contact-select"
            contacts={availableContacts}
            onChange={(value) =>
              onSelectedContactsChange && onSelectedContactsChange(value)
            }
            onNewContact={handleOnNewContactSelect}
            canCreateContact={true}
          />
        )}
      </CardBody>
    </MCard>
  );
};
