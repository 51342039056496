import { AccordionPanel } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { handleApiErrorToast } from '~app/api/axios';
import {
  useDefaultDocumentRemover,
  useDefaultDocumentUploader,
} from '~app/api/settingsService';
import {
  MAccordion,
  MAccordionCustomButton,
  MAccordionItem,
  MButton,
  MGrid,
  MGridItem,
  MSkeleton,
  MStack,
  MText,
} from '~app/components/Monetize';
import { QuoteDocumentsUploadItem } from '~app/routes/Quotes/Quote/components/quoteDocuments/QuoteDocumentsUploadItem';
import { useFlags } from '~app/services/launchDarkly';
import {
  ActiveDocumentType,
  ActiveItemTypeToMetaDataMap,
  DocumentItemState,
  QuoteDocumentActiveItemType,
  QuoteDocumentActiveItemTypeToConfigurationKey,
} from '~app/types/quoteDocumentsType';
import { IDefaultQuoteDocumentsSchema } from '~app/types/quoteSettingsTypes';
import { convertToBase64, showPdfInNewTab } from '~app/utils/download';
import { arrayToObject } from '~app/utils/misc';

export interface IDefaultQuoteDocumentsProps {
  isLoading: boolean;
  defaultQuoteDocuments: IDefaultQuoteDocumentsSchema[];
}
const DefaultQuoteDocuments = ({
  isLoading,
  defaultQuoteDocuments,
}: IDefaultQuoteDocumentsProps) => {
  const { pdfCoverFooter } = useFlags();

  const { mutate: uploadDocument, isLoading: isUploadingDocument } =
    useDefaultDocumentUploader();
  const { mutate: removeDocument } = useDefaultDocumentRemover();

  const [msaData, setMsaData] = useState<DocumentItemState>({
    savedData: {
      storage: undefined,
      link: undefined,
    },
    activeItem: 'none',
    linkUrl: '',
    savedItemText: '',
    hideBtn: false,
  });
  const [pdfCoverData, setPdfCoverData] = useState<DocumentItemState>({
    savedData: {
      storage: undefined,
    },
    activeItem: 'none',
    linkUrl: '',
    savedItemText: '',
    hideBtn: false,
  });

  const [pdfFooterData, setPdfFooterData] = useState<DocumentItemState>({
    savedData: {
      storage: undefined,
    },
    activeItem: 'none',
    linkUrl: '',
    savedItemText: '',
    hideBtn: false,
  });

  const documentHandlerMap = {
    setter: {
      msa: setMsaData,
      cover: setPdfCoverData,
      footer: setPdfFooterData,
    },
    getter: {
      msa: msaData,
      cover: pdfCoverData,
      footer: pdfFooterData,
    },
  };

  useEffect(() => {
    const data = arrayToObject(defaultQuoteDocuments, 'key');
    const defaultMsaData =
      data.DEFAULT_DOCUMENT_LOCATION as IDefaultQuoteDocumentsSchema;
    const defaultMsaLink =
      data.DEFAULT_DOCUMENT_URL as IDefaultQuoteDocumentsSchema;
    const defaultPdfCoverData =
      data.DEFAULT_DOCUMENT_COVER as IDefaultQuoteDocumentsSchema;
    const defaultPdfFooterData =
      data.DEFAULT_DOCUMENT_FOOTER as IDefaultQuoteDocumentsSchema;

    if (defaultMsaData) {
      setMsaData((prevValue) => {
        let activeItem: ActiveDocumentType = 'none';
        if (defaultMsaData.storage) {
          activeItem = 'file';
        } else if (defaultMsaData.url) {
          activeItem = 'link';
        }
        return {
          ...prevValue,
          savedData: {
            link: undefined,
            storage: defaultMsaData.storage,
          },
          activeItem,
          savedItemText:
            defaultMsaData.storage?.filename || defaultMsaData.url || '',
          hideBtn: true,
        };
      });
    }
    if (defaultMsaLink) {
      setMsaData((prevValue) => {
        let activeItem: ActiveDocumentType = 'none';
        if (defaultMsaLink.url) {
          activeItem = 'link';
        }
        return {
          ...prevValue,
          activeItem,
          savedData: {
            link: defaultMsaLink.url || '',
            storage: undefined,
          },
          savedItemText: defaultMsaLink.url || '',
          linkUrl: defaultMsaLink.url || '',
          hideBtn: true,
        };
      });
    }
    if (defaultPdfCoverData) {
      setPdfCoverData((prevValue) => {
        let activeItem: ActiveDocumentType = 'none';
        if (defaultPdfCoverData.storage) {
          activeItem = 'file';
        }
        return {
          ...prevValue,
          savedData: {
            link: undefined,
            storage: defaultPdfCoverData.storage,
          },
          activeItem,
          savedItemText: defaultPdfCoverData.storage?.filename || '',
          hideBtn: true,
        };
      });
    }
    if (defaultPdfFooterData) {
      setPdfFooterData((priorData) => {
        let activeItem: ActiveDocumentType = 'none';
        if (defaultPdfFooterData.storage) {
          activeItem = 'file';
        }
        return {
          ...priorData,
          savedData: {
            link: undefined,
            storage: defaultPdfFooterData.storage,
          },
          activeItem,
          savedItemText: defaultPdfFooterData.storage?.filename || '',
          hideBtn: true,
        };
      });
    }
  }, [defaultQuoteDocuments]);

  const handleDocumentUpload = (
    type: string,
    extraData: Record<string, string> = {},
    file?: File,
  ) => {
    const formData = new FormData();
    formData.append(
      'metadata',
      new Blob(
        [
          JSON.stringify({
            ...ActiveItemTypeToMetaDataMap[type],
            ...extraData,
          }),
        ],
        {
          type: 'application/json',
        },
      ),
    );
    if (file) {
      formData.append('file', file);
    }
    uploadDocument(formData, { onError: (err) => handleApiErrorToast(err) });
  };

  const handleActiveItemChange = (
    type: QuoteDocumentActiveItemType,
    item: ActiveDocumentType,
  ) => {
    documentHandlerMap.setter[type]((prevValue) => ({
      ...prevValue,
      activeItem: item,
    }));
  };

  const handleAddItem = async (
    type: QuoteDocumentActiveItemType,
    item: { type: 'file'; value: File } | { type: 'link'; value: string },
  ) => {
    if (item.type === 'file') {
      documentHandlerMap.setter[type]((prevValue) => ({
        ...prevValue,
        fileToUpload: {
          file: item.value,
          fileName: item.value.name,
        },
        savedItemText: item.value.name,
        hideBtn: true,
      }));
      handleDocumentUpload(type, {}, item.value);
    } else {
      documentHandlerMap.setter[type]((prevValue) => ({
        ...prevValue,
        linkUrl: item.value,
        savedItemText: '',
        hideBtn: true,
      }));
      handleDocumentUpload(item.type, { documentUrl: item.value });
    }
  };

  const handleRemoveItem = (type: QuoteDocumentActiveItemType) => {
    documentHandlerMap.setter[type]((prevValue) => ({
      ...prevValue,
      fileToUpload: undefined,
      linkUrl: '',
      activeItem: 'none',
      savedItemText: '',
      hideBtn: false,
    }));
    const activeType =
      documentHandlerMap.getter[type].activeItem === 'link' ? 'link' : type;
    removeDocument(QuoteDocumentActiveItemTypeToConfigurationKey[activeType]);
  };

  const handleGoToItem = async (type: QuoteDocumentActiveItemType) => {
    if (documentHandlerMap.getter[type].activeItem === 'file') {
      let base64Data = '';
      let fileName = '';
      if (documentHandlerMap.getter[type].fileToUpload) {
        base64Data = await convertToBase64(
          documentHandlerMap.getter[type].fileToUpload!.file,
        );
        // eslint-disable-next-line prefer-destructuring
        fileName = documentHandlerMap.getter[type].fileToUpload!.fileName;
      } else if (
        documentHandlerMap.getter[type].savedData.storage?.contentBase64Encoded
      ) {
        base64Data =
          documentHandlerMap.getter[type].savedData.storage!
            .contentBase64Encoded!;
        fileName = documentHandlerMap.getter[type].savedData.storage!.filename;
      }
      showPdfInNewTab(base64Data, fileName);
    } else {
      const link =
        documentHandlerMap.getter[type].linkUrl ||
        documentHandlerMap.getter[type].savedData.link;
      if (link) {
        window.open(link, '_blank');
      }
    }
  };

  if (isLoading) {
    return <MSkeleton height="51" />;
  }

  return (
    <MStack w="full" data-testid="default-msa" mt={4}>
      <MAccordion allowMultiple reduceMotion w="full" mt={4}>
        <MAccordionItem
          borderWidth="1px"
          borderRadius="md"
          borderColor="tGray.back"
          data-testid="default-msa-accordion"
        >
          {({ isExpanded }) => (
            <>
              <MAccordionCustomButton
                isExpanded={isExpanded}
                label="Quote Documents"
              />
              <AccordionPanel mx={6}>
                <MText color="tGray.darkPurple">
                  The default MSA, cover page and footer will be attached to the
                  PDF of all Quotes.
                </MText>
                <MGrid templateColumns="repeat(12, 1fr)" gap={4} mt={6}>
                  <MGridItem colSpan={12}>
                    <QuoteDocumentsUploadItem
                      allowLink
                      showControlButton={!msaData.hideBtn}
                      isReadOnly={false}
                      linkUrl={msaData.linkUrl}
                      savedItemText={msaData.savedItemText}
                      label="Master Service Agreement (MSA)"
                      dndLabel="Drag and Drop your MSA file here"
                      activeItem={msaData.activeItem}
                      onSetActiveItem={(item) =>
                        handleActiveItemChange('msa', item)
                      }
                      onAddItem={(item) => handleAddItem('msa', item)}
                      onRemoveItem={() => handleRemoveItem('msa')}
                      onGotoSavedItem={() => handleGoToItem('msa')}
                      saveBtnContent={({ value, error }) => {
                        return (
                          <MButton
                            isDisabled={!!error || isUploadingDocument}
                            isLoading={isUploadingDocument}
                            onClick={() =>
                              handleAddItem('msa', { type: 'link', value })
                            }
                          >
                            Save
                          </MButton>
                        );
                      }}
                    />
                  </MGridItem>
                  {pdfCoverFooter && (
                    <>
                      <MGridItem colSpan={12}>
                        <QuoteDocumentsUploadItem
                          isReadOnly={false}
                          showControlButton={!msaData.hideBtn}
                          label="Cover"
                          dndLabel="Drag and Drop your cover here"
                          activeItem={pdfCoverData.activeItem}
                          linkUrl={pdfCoverData.linkUrl}
                          savedItemText={pdfCoverData.savedItemText}
                          onSetActiveItem={(item) =>
                            handleActiveItemChange('cover', item)
                          }
                          onAddItem={(item) => handleAddItem('cover', item)}
                          onRemoveItem={() => handleRemoveItem('cover')}
                          onGotoSavedItem={() => handleGoToItem('cover')}
                        />
                      </MGridItem>
                      <MGridItem colSpan={12}>
                        <QuoteDocumentsUploadItem
                          isReadOnly={false}
                          showControlButton={!msaData.hideBtn}
                          label="Footer"
                          dndLabel="Drag and Drop your footer here"
                          activeItem={pdfFooterData.activeItem}
                          linkUrl={pdfFooterData.linkUrl}
                          savedItemText={pdfFooterData.savedItemText}
                          onSetActiveItem={(item) =>
                            handleActiveItemChange('footer', item)
                          }
                          onAddItem={(item) => handleAddItem('footer', item)}
                          onRemoveItem={() => handleRemoveItem('footer')}
                          onGotoSavedItem={() => handleGoToItem('footer')}
                        />
                      </MGridItem>
                    </>
                  )}
                </MGrid>
              </AccordionPanel>
            </>
          )}
        </MAccordionItem>
      </MAccordion>
    </MStack>
  );
};

export default DefaultQuoteDocuments;
