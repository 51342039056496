import { Icon, TextProps } from '@chakra-ui/react';
import { FunctionComponent as FC } from 'react';
import { BillAdvanceIcon } from '~app/assets/icons/BillAdvanceIcon';
import { BillArrearsIcon } from '~app/assets/icons/BillArrearsIcon';
import { InvoiceItemTypeEnumDisplay } from '~app/constants/invoice';
import { PRODUCT_TYPE_DISPLAY_SHORT } from '~app/constants/products';
import {
  InvoiceItemTypeEnum,
  ProductTypeEnum,
  SubscriptionTimingEnum,
} from '~app/types';
import { MFlex, MText } from '../Monetize';
import { MBox, MTooltip } from './chakra';

interface SubscriptionTimingIconProps {
  productType: ProductTypeEnum | InvoiceItemTypeEnum;
  subscriptionTiming?: SubscriptionTimingEnum | null;
}

const subscriptionProductTypes = new Set([
  ProductTypeEnum.ADVANCE,
  ProductTypeEnum.MIN_COMMIT,
  InvoiceItemTypeEnum.SUBSCRIPTION,
]);

const SubscriptionTimingIcon = ({
  productType,
  subscriptionTiming,
}: SubscriptionTimingIconProps) => {
  if (!subscriptionProductTypes.has(productType)) {
    return null;
  }
  if (subscriptionTiming === SubscriptionTimingEnum.ARREARS) {
    return (
      <MTooltip label="Billed in arrears">
        <MBox display="inline">
          <Icon
            as={BillArrearsIcon}
            fontSize="xl"
            mb="-.35rem"
            color="tPurple.base"
          />
        </MBox>
      </MTooltip>
    );
  }
  // default to advance
  return (
    <MTooltip label="Billed in advance">
      <MBox display="inline">
        <Icon
          as={BillAdvanceIcon}
          fontSize="xl"
          mb="-.35rem"
          color="tPurple.base"
        />
      </MBox>
    </MTooltip>
  );
};

interface ProductDisplayProps extends TextProps {
  name: string;
  subscriptionTiming?: SubscriptionTimingEnum | null;
  productType: ProductTypeEnum | InvoiceItemTypeEnum;
  disabled?: boolean;
  /** If true, the product type in parentheses will not be displayed */
  omitProductType?: boolean;
  omitSubscriptionIcon?: boolean;
  isScheduledChange?: boolean;
}

export const MProductDisplay: FC<ProductDisplayProps> = ({
  name,
  productType,
  disabled,
  omitProductType = false,
  omitSubscriptionIcon = false,
  isScheduledChange,
  subscriptionTiming,
  ...rest
}: ProductDisplayProps) => {
  return (
    <MFlex data-testid="product-display">
      <MText
        fontWeight={isScheduledChange ? 'normal' : 'bold'}
        disabled={disabled}
        fontSize={isScheduledChange ? 'xs' : 'sm'}
        noOfLines={5}
        {...rest}
      >
        {name}
      </MText>
      {productType && (
        <MText
          as="span"
          fontWeight="normal"
          ml={1}
          disabled={disabled}
          fontSize={isScheduledChange ? 'xs' : 'sm'}
          display="flex"
          {...rest}
        >
          {!omitProductType && (
            <>
              (
              {productType in ProductTypeEnum
                ? PRODUCT_TYPE_DISPLAY_SHORT[productType as ProductTypeEnum]
                : InvoiceItemTypeEnumDisplay[
                    productType as InvoiceItemTypeEnum
                  ]}
              {!omitSubscriptionIcon && (
                <SubscriptionTimingIcon
                  productType={productType}
                  subscriptionTiming={subscriptionTiming}
                />
              )}
              )
            </>
          )}
        </MText>
      )}
    </MFlex>
  );
};
