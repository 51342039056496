import * as React from 'react';
import { useSearchParams } from 'react-router-dom';

const JSURL = require('jsurl');

function useQueryParam<T>(key: string): [T | undefined, (newQuery: T) => void] {
  const [searchParams, setSearchParams] = useSearchParams();
  const paramValue = searchParams.get(key);

  const value = React.useMemo(() => JSURL.parse(paramValue), [paramValue]);

  const setValue = React.useCallback(
    (newValue: T) => {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set(key, JSURL.stringify(newValue));
      setSearchParams(newSearchParams);
    },
    [key, searchParams, setSearchParams],
  );

  return [value, setValue];
}

export default useQueryParam;
