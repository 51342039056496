import { useEffect } from 'react';
import { useGetQuoteSettings } from '~app/api/settingsService';
import {
  INetTerm,
  Maybe,
  NET_TERMS_DISPLAY,
  NetTermsEnum,
  NetTermsStatusEnum,
} from '~app/types';
import { arrayToObject } from '~app/utils/misc';

/**
 *
 * @param selectedNetTerm If provided, this will be included in the activeNetTerms array
 * @returns
 */
const useNetTerms = (selectedNetTerm?: Maybe<NetTermsEnum>) => {
  const { data, isLoading } = useGetQuoteSettings({
    select: ({ netTerms: netTermsFromServer }) => {
      const netTerms: INetTerm[] = Object.entries(netTermsFromServer).map(
        ([key, { status }]) => ({
          name: NET_TERMS_DISPLAY[key as NetTermsEnum],
          value: key as NetTermsEnum,
          active:
            status === NetTermsStatusEnum.ACTIVE ||
            status === NetTermsStatusEnum.PRIMARY ||
            key === selectedNetTerm,
          primary: status === NetTermsStatusEnum.PRIMARY,
        }),
      );
      return {
        netTerms,
        defaultNetTerm: netTerms.find(({ primary }) => primary) || netTerms[0],
        activeNetTerms: netTerms.filter(({ active }) => active) || [],
        netTermsById: arrayToObject(netTerms, 'value'),
      };
    },
  });

  // If a net term is selected to be included as active, but it's not in the activeNetTerms array, add it
  useEffect(() => {
    if (selectedNetTerm && data?.activeNetTerms) {
      const netTerm = data.activeNetTerms.find(
        ({ value }) => value === selectedNetTerm,
      );
      if (!netTerm) {
        data.activeNetTerms = [
          ...data.activeNetTerms,
          data.netTermsById[selectedNetTerm],
        ];
      }
    }
  }, [selectedNetTerm, data?.activeNetTerms]);

  return {
    isLoading,
    netTerms: data?.netTerms || [],
    defaultNetTerm: data?.defaultNetTerm || null,
    activeNetTerms: data?.activeNetTerms || [],
    netTermsById: data?.netTermsById || {},
  };
};

export default useNetTerms;
