const PaymentGatewayIcon = (props: any) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20 4H4C2.89 4 2.01 4.89 2.01 6L2 18C2 19.11 2.89 20 4 20H20C21.11 20 22 19.11 22 18V6C22 4.89 21.11 4 20 4ZM20 18H4V12H20V18ZM20 8H4V6H20V8Z"
        fill="currentColor"
      />
      <rect
        x="14.2305"
        y="13.1641"
        width="9.36241"
        height="9.4469"
        rx="4.6812"
        transform="rotate(1.39913 14.2305 13.1641)"
        fill="white"
      />
      <path
        d="M21.3672 18.3864L21.3635 18.3933C21.3803 18.2782 21.3972 18.1596 21.4001 18.0406C21.403 17.9217 21.3952 17.8094 21.3841 17.6936L21.3874 17.7007L22.2576 17.0498L21.4434 15.5525L20.4293 15.9338L20.4327 15.9374C20.2541 15.793 20.0576 15.6692 19.8429 15.5729L19.8464 15.573L19.7116 14.4984L18.0076 14.4567L17.8274 15.5272L17.8309 15.5272C17.6118 15.6129 17.4094 15.727 17.224 15.8625L17.2276 15.8591L16.2299 15.4251L15.3401 16.8808L16.1775 17.5735L16.1811 17.5666C16.1643 17.6817 16.1511 17.7934 16.1482 17.9124C16.1453 18.0313 16.1529 18.1505 16.1675 18.2664L16.1642 18.2594L15.4153 18.8187L15.2976 18.9069L16.112 20.3972L17.1295 20.0195L17.1229 20.0053C17.3048 20.1533 17.5013 20.2771 17.7195 20.3735L17.7091 20.3732L17.8402 21.4547L19.5372 21.4962C19.5372 21.4962 19.5492 21.4335 19.5618 21.3497L19.7174 20.4258L19.7139 20.4257C19.933 20.34 20.1389 20.226 20.3278 20.0871L20.3205 20.1009L21.3184 20.5279L22.2045 19.0791C22.2045 19.0791 22.1565 19.0359 22.0912 18.9853L21.3672 18.3864ZM18.7425 19.2011C18.0672 19.1846 17.5313 18.6218 17.5478 17.9465C17.5642 17.2712 18.127 16.7353 18.8023 16.7518C19.4776 16.7683 20.0135 17.3311 19.997 18.0064C19.9805 18.6817 19.4178 19.2176 18.7425 19.2011Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default PaymentGatewayIcon;
