import React, { FunctionComponent, PropsWithChildren } from 'react';
import { Th } from '@chakra-ui/react';

const getAlignment = (label: string) => {
  switch (label) {
    case 'Quantity':
      return 'center';
    case 'Discount':
      return 'left';
    case 'Cost':
    case 'Amount':
      return 'right';
    default:
      return 'left';
  }
};

export const SubscriptionFormHeaderCell: FunctionComponent<any> = (
  props: PropsWithChildren<any>,
): React.ReactElement => {
  const { value, children, ...rest } = props;
  const alignment = getAlignment(value);

  return (
    <Th
      key={value}
      letterSpacing="normal"
      textTransform="capitalize"
      fontSize="sm"
      fontWeight="bold"
      color="tPurple.base"
      textAlign={alignment}
      py="16px !important"
      pr={['Amount', 'Discount'].includes(value) && '16px !important'}
      {...rest}
    >
      {children}
    </Th>
  );
};
