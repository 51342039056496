import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { MdInfo } from 'react-icons/md';
import {
  MBox,
  MButton,
  MCurrencySelect,
  MCustomSelect,
  MFlex,
  MFormField,
  MIcon,
  MInput,
  MLockedTextOrContent,
  MSwitch,
  MText,
  MTextarea,
  MTooltip,
} from '~app/components/Monetize';
import { DatePicker } from '~app/components/Monetize/DatePicker/DatePicker';
import { OFFERINGS } from '~app/constants';
import {
  RATE_BILLING_FREQUENCY_MAP,
  getBillingFrequencyItems,
  getRateUsageBillingFrequencyDisplay,
  getSubscriptionTimingDisplay,
  getUsageFrequencyItems,
} from '~app/constants/offerings';
import { useCurrencies } from '~app/hooks/useCurrencies';
import { useFlags } from '~app/services/launchDarkly';
import {
  IRateReqSchemaUI,
  OfferingTypesEnum,
  RateBillingFrequencyEnum,
  RateStatusEnum,
  RateUsageBillingFrequencyEnum,
  SubscriptionTimingEnum,
} from '~app/types';
import { getOfferingType } from '../../offeringUtils';
import LockedStatus from './LockedStatus';
import { RateActions } from './RateActions';
import { RateSubscriptionFrequency } from './RateSubscriptionFrequency';

interface RateFormHeaderProps {
  nameRef: React.MutableRefObject<HTMLInputElement | null>;
  loading: boolean;
  isLocked: boolean;
  currency: string;
  rateId: string;
  type: OfferingTypesEnum;
  billingFrequency?: RateBillingFrequencyEnum | null;
  billingFrequencyInMonths?: number | null;
  usageBillingFrequency?: RateUsageBillingFrequencyEnum | null;
  archivedOffering?: boolean;
  subscriptionTiming?: SubscriptionTimingEnum | null;
  handleReset: () => void;
  handleRemove: () => void;
  handleClone: () => void;
  isReadOnly?: boolean;
  isSubscriptionProductTypeExist: boolean;
  isSubscription: boolean;
}
export const RateFormHeader = ({
  loading,
  archivedOffering,
  nameRef,
  currency,
  isLocked,
  rateId,
  type,
  billingFrequency,
  billingFrequencyInMonths,
  subscriptionTiming,
  usageBillingFrequency,
  handleClone,
  handleRemove,
  isReadOnly,
}: RateFormHeaderProps) => {
  const {
    setValue,
    getValues,
    control,
    formState: { errors, dirtyFields },
  } = useFormContext<IRateReqSchemaUI>();

  const { id } = getValues();
  const [allFieldsVisibility, setAllFieldsVisibility] = useState(true);
  const subscriptionFrequencyItems = getBillingFrequencyItems(type);
  const { defaultCurrency } = useCurrencies();
  const { isOneTime, isMinCommit } = getOfferingType(type);
  const { showQuotableField, allowMinCommitAdditionalFrequencies } = useFlags();
  const rateUsageFrequencyOptions = getUsageFrequencyItems(type);

  return (
    <>
      <MBox>
        <MFormField
          labelProps={{ display: 'block' }}
          error={errors?.name}
          label="Rate Name"
          isRequired
        >
          <Controller
            name="name"
            defaultValue=""
            control={control}
            render={({ field: { ref, ...rest } }) => (
              <MInput
                placeholder="New Rate"
                isDisabled={loading || archivedOffering}
                isReadOnly={isReadOnly}
                bgColor="tWhite.base"
                variant={
                  isReadOnly
                    ? 'readonly'
                    : !!rateId && !!rest.value && dirtyFields.name
                    ? 'unsaved'
                    : 'primary'
                }
                ref={(e) => {
                  ref(e);
                  nameRef.current = e;
                }}
                {...rest}
              />
            )}
          />
        </MFormField>
      </MBox>

      <MBox>
        <MFormField
          labelProps={{ display: 'block' }}
          error={errors?.status}
          label="Status"
          isRequired
        >
          <Controller
            name="status"
            defaultValue={RateStatusEnum.ACTIVE}
            control={control}
            render={({ field }) => (
              <MCustomSelect
                inputProps={{
                  variant: isReadOnly
                    ? 'readonly'
                    : !!rateId && dirtyFields.status
                    ? 'unsaved'
                    : 'primary',
                }}
                isDisabled={loading || archivedOffering}
                isReadOnly={isReadOnly}
                bgColor="tWhite.base"
                placeholder="Status"
                items={OFFERINGS.RATE_STATUS_MODEL_DISPLAY_STRING}
                {...field}
              />
            )}
          />
        </MFormField>
      </MBox>

      <MBox>
        <MFormField
          labelProps={{ display: 'block' }}
          error={errors?.currency}
          label="Currency"
          isRequired={!isLocked}
          data-testid="rate-currency"
        >
          <MLockedTextOrContent text={currency} h={8} isLocked={isLocked}>
            <Controller
              name="currency"
              defaultValue={defaultCurrency}
              control={control}
              render={({ field }) => (
                <MCurrencySelect
                  inputProps={{
                    variant: isReadOnly
                      ? 'readonly'
                      : !!rateId && dirtyFields.currency
                      ? 'unsaved'
                      : 'primary',
                  }}
                  isDisabled={loading || archivedOffering}
                  isReadOnly={isReadOnly}
                  {...field}
                />
              )}
            />
          </MLockedTextOrContent>
        </MFormField>
      </MBox>

      <MFormField
        labelProps={{ display: 'block' }}
        error={errors?.billingFrequency}
        label={
          isMinCommit ? 'Minimum Commit Frequency' : 'Subscription Frequency'
        }
        isRequired={!isLocked && type !== OfferingTypesEnum.ONETIME}
        data-testid="rate-billing-frequency"
        gridColumn={isMinCommit ? 'span 2' : 'span 1'}
      >
        <MLockedTextOrContent
          text={
            billingFrequency
              ? RATE_BILLING_FREQUENCY_MAP[billingFrequency](
                  billingFrequencyInMonths,
                ).label
              : ''
          }
          h={8}
          isLocked={
            isLocked ||
            isReadOnly ||
            loading ||
            isOneTime ||
            archivedOffering ||
            (!allowMinCommitAdditionalFrequencies && isMinCommit)
          }
        >
          <RateSubscriptionFrequency
            isDisabled={
              loading ||
              isOneTime ||
              archivedOffering ||
              (!allowMinCommitAdditionalFrequencies && isMinCommit)
            }
            isDirty={!!rateId && dirtyFields.billingFrequency}
            isReadOnly={
              isReadOnly ||
              (!allowMinCommitAdditionalFrequencies && isMinCommit)
            }
            subscriptionFrequencyItems={subscriptionFrequencyItems}
            control={control}
            data={{
              billingFrequency,
              billingFrequencyInMonths,
            }}
            onChange={(data) => {
              setValue('billingFrequency', data.billingFrequency, {
                shouldDirty: true,
                shouldValidate: true,
              });
              setValue(
                'billingFrequencyInMonths',
                data.billingFrequencyInMonths,
                {
                  shouldDirty: true,
                  shouldValidate: true,
                },
              );
              if (isMinCommit) {
                setValue(
                  'usageBillingFrequency',
                  data.billingFrequency as any as RateUsageBillingFrequencyEnum, // possible billingFrequency on MIN_COMMIT are monthly/quarterly/semi-anually/anually
                  {
                    shouldDirty: true,
                    shouldValidate: true,
                  },
                );
              }
            }}
            disableCustomFrequency={isMinCommit}
          />
        </MLockedTextOrContent>
      </MFormField>

      <MFormField
        labelProps={{ display: 'block' }}
        error={errors?.subscriptionTiming}
        label={isMinCommit ? 'Minimum Commit Timing' : 'Subscription Timing'}
        isRequired={!isLocked && type !== OfferingTypesEnum.ONETIME}
        data-testid="rate-subscription-timing"
      >
        <MLockedTextOrContent
          text={getSubscriptionTimingDisplay(subscriptionTiming)}
          h={8}
          isLocked={isLocked || type === OfferingTypesEnum.ONETIME}
        >
          <Controller
            name="subscriptionTiming"
            control={control}
            render={({ field }) => (
              <MCustomSelect
                inputProps={{
                  variant: isReadOnly
                    ? 'readonly'
                    : !!rateId && dirtyFields.subscriptionTiming
                    ? 'unsaved'
                    : 'primary',
                }}
                isDisabled={loading || type === OfferingTypesEnum.ONETIME}
                isReadOnly={isReadOnly}
                bgColor="tWhite.base"
                placeholder="Subscription Timing"
                items={OFFERINGS.SUBSCRIPTION_TIMING_UI_OPTIONS}
                {...field}
              />
            )}
          />
        </MLockedTextOrContent>
      </MFormField>

      {!isMinCommit && (
        <MFormField
          labelProps={{ display: 'block' }}
          error={errors?.usageBillingFrequency}
          label="Usage Frequency"
          data-testid="rate-usage-billing-frequency"
        >
          <MLockedTextOrContent
            text={getRateUsageBillingFrequencyDisplay(usageBillingFrequency)}
            h={8}
            isLocked
          >
            {/* this select never appears currently as we have only one usage billing frequency choice */}
            <Controller
              name="usageBillingFrequency"
              control={control}
              render={({ field: { value, ...rest } }) => (
                <MCustomSelect
                  inputProps={{
                    variant:
                      !!rateId && dirtyFields.usageBillingFrequency
                        ? 'unsaved'
                        : 'primary',
                  }}
                  bgColor="tWhite.base"
                  placeholder="Usage Frequency"
                  items={rateUsageFrequencyOptions}
                  value={value}
                  {...rest}
                />
              )}
            />
          </MLockedTextOrContent>{' '}
        </MFormField>
      )}

      <MFlex justifyContent="flex-end" align="flex-start" mr={-2}>
        {isLocked && <LockedStatus containerProps={{ mt: 0.5, mr: 1 }} />}
        {!isReadOnly && (
          <RateActions
            isLocked={isLocked}
            isNewRate={id?.match('new-') !== null}
            rateId={rateId}
            handleClone={handleClone}
            handleRemove={handleRemove}
          />
        )}
      </MFlex>
      <MBox />
      {allFieldsVisibility && (
        <>
          <MBox>
            <MFormField
              labelProps={{ display: 'block' }}
              error={errors?.description}
              label="Description"
              isRequired
            >
              <Controller
                name="description"
                defaultValue=""
                control={control}
                render={({ field: { value, ...rest } }) => (
                  <MTextarea
                    variant={
                      isReadOnly
                        ? 'readonly'
                        : !!rateId && !!value && dirtyFields.description
                        ? 'unsaved'
                        : 'primary'
                    }
                    value={value || ''}
                    borderRadius="3"
                    maxLength={80}
                    rows={2}
                    minHeight="30px"
                    isDisabled={isReadOnly}
                    isReadOnly={isReadOnly}
                    {...rest}
                  />
                )}
              />
            </MFormField>
          </MBox>
          <MBox gridColumn="span 2">
            <MFormField
              labelProps={{ display: 'block' }}
              error={errors?.internalDescription}
              label="Internal Description"
              tooltip="This internal description will only be shown when creating a quote. This will not be visible to the customer."
            >
              <Controller
                name="internalDescription"
                defaultValue=""
                control={control}
                render={({ field: { value, ...rest } }) => (
                  <MTextarea
                    variant={
                      isReadOnly
                        ? 'readonly'
                        : !!rateId && !!value && dirtyFields.internalDescription
                        ? 'unsaved'
                        : 'primary'
                    }
                    value={value || ''}
                    borderRadius="3"
                    maxLength={255}
                    rows={2}
                    minHeight="30px"
                    isDisabled={isReadOnly}
                    isReadOnly={isReadOnly}
                    {...rest}
                  />
                )}
              />
            </MFormField>
          </MBox>
          <MFormField
            labelProps={{ display: 'block' }}
            error={errors.startDate}
            label="Start Date"
          >
            <Controller
              name="startDate"
              control={control}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  variant={
                    isReadOnly
                      ? 'readonly'
                      : !!rateId && !!field.value && dirtyFields.startDate
                      ? 'unsaved'
                      : 'primary'
                  }
                  placeholder="Select Start Date"
                  clearable
                  isDisabled={loading || archivedOffering}
                  isReadOnly={isReadOnly}
                />
              )}
            />
          </MFormField>

          <MFormField
            labelProps={{ display: 'block' }}
            error={errors.endDate}
            label="End Date"
          >
            <Controller
              name="endDate"
              control={control}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  variant={
                    isReadOnly
                      ? 'readonly'
                      : !!rateId && !!field.value && dirtyFields.endDate
                      ? 'unsaved'
                      : 'primary'
                  }
                  placeholder="Select End Date"
                  clearable
                  isDisabled={loading || archivedOffering}
                  isReadOnly={isReadOnly}
                />
              )}
            />
          </MFormField>
          <MFlex mt="29px">
            {showQuotableField && (
              <MTooltip
                label="Allow this Rate to be added to Quotes"
                placement="bottom-start"
                shouldWrapChildren
              >
                <MFormField error={errors?.quotable}>
                  <Controller
                    name="quotable"
                    control={control}
                    render={({ field: { value, ...rest } }) => (
                      <MFlex align="center">
                        <MText fontWeight="semibold">Quotable</MText>
                        <MBox mt="0.5" mr={2}>
                          <MIcon
                            as={MdInfo}
                            color="tPurple.base"
                            ml="1"
                            w={3.5}
                            h={3.5}
                          />
                        </MBox>
                        <MSwitch
                          data-testid="toggle-quotable"
                          isChecked={value}
                          isDisabled={loading || archivedOffering || isReadOnly}
                          variant={
                            isReadOnly
                              ? 'readonly'
                              : !!rateId && dirtyFields.quotable
                              ? 'unsaved'
                              : 'select-events'
                          }
                          {...rest}
                        />
                      </MFlex>
                    )}
                  />
                </MFormField>
              </MTooltip>
            )}
          </MFlex>
        </>
      )}
      {allFieldsVisibility && (
        <>
          <MBox />
          <MBox />
        </>
      )}
      <MBox>
        <MButton
          variant="tertiary"
          fontWeight="600"
          ml={-6}
          onClick={() => {
            setAllFieldsVisibility((visibility) => !visibility);
          }}
        >
          {allFieldsVisibility ? 'Less Fields' : 'More Fields'}
        </MButton>
      </MBox>
    </>
  );
};
