import { useState } from 'react';
import { handleApiErrorToast } from '~app/api/axios';
import { doGetUsersListByTenantId } from '~app/api/usersService';
import {
  DEFAULT_PAGER,
  IUser,
  TDataTablePager,
  UserStatusEnum,
} from '~app/types';

const pager: TDataTablePager = {
  ...DEFAULT_PAGER,
  sortField: 'name',
  sortOrder: 1,
};

const useTeam = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [teamOwner, setTeamOwner] = useState<IUser[]>([]);

  const searchTeamOwners = async (tenantId: string, query: string) => {
    try {
      setLoading(true);
      const result = await doGetUsersListByTenantId(
        tenantId,
        pager,
        {
          name: { contains: query },
          status: UserStatusEnum.ACTIVE,
        },
        { customXTenantId: tenantId },
      );
      setTeamOwner(result.content);
    } catch (error: any) {
      handleApiErrorToast(error, {
        summary: 'Error while fetching team owner',
      });
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    searchTeamOwners,
    teamOwner,
  };
};

export default useTeam;
