import { useDisclosure } from '@chakra-ui/react';
import { FunctionComponent as FC } from 'react';
import { MdOutlineFileUpload } from 'react-icons/md';

import {
  MBox,
  MButton,
  MFlex,
  MFormField,
  MFormFieldProps,
  MIcon,
  MText,
} from '~app/components/Monetize';
import { QUOTE_READONLY_STATUSES } from '~app/constants/quotes';
import { IQuoteRequestSchema, IQuoteRespSchema } from '~app/types';
import { QuoteDocumentsModalV2 } from './QuoteDocumentsModalV2';

export function getNumberOfDocuments(quote?: IQuoteRespSchema) {
  let numDocuments = 0;
  if (!quote) {
    return numDocuments;
  }

  const {
    documentLocation,
    documentUrl,
    sowDocumentStorageId,
    signedDocumentStorageId,
    coverDocumentStorageId,
    footerDocumentStorageId,
  } = quote;

  numDocuments += documentLocation || documentUrl ? 1 : 0; // MSA
  numDocuments += sowDocumentStorageId ? 1 : 0; // SOW
  numDocuments += signedDocumentStorageId ? 1 : 0; // Signed Quote Document
  numDocuments += coverDocumentStorageId ? 1 : 0; // Pdf Cover
  numDocuments += footerDocumentStorageId ? 1 : 0; // Pdf Footer

  return numDocuments;
}

interface QuoteDocumentsV2Props {
  formFieldProps?: MFormFieldProps;
  quote?: IQuoteRespSchema | null;
  isReadOnly?: boolean;
  onUpdate: (data: IQuoteRequestSchema) => void;
}

export const QuoteDocumentsV2: FC<QuoteDocumentsV2Props> = ({
  formFieldProps,
  quote,
  isReadOnly,
  onUpdate,
}: QuoteDocumentsV2Props) => {
  const {
    isOpen: isModalOpen,
    onOpen: openModal,
    onClose: closeModal,
  } = useDisclosure();

  if (!quote) {
    return null;
  }

  const disabled = isReadOnly || QUOTE_READONLY_STATUSES.has(quote.status);
  const numDocuments = getNumberOfDocuments(quote!);
  const hasDocuments = numDocuments > 0;

  return (
    <>
      <MFlex h={8} alignItems="center">
        <MBox w="full">
          {hasDocuments && (
            <MButton
              variant="underline"
              onClick={openModal}
              fontSize="sm"
              fontWeight="normal"
              w="initial"
            >
              View all
            </MButton>
          )}
          {!hasDocuments && (
            <>
              {disabled ? (
                <MText display="inline-block" fontStyle="italic">
                  No documents added
                </MText>
              ) : (
                <MButton
                  leftIcon={<MIcon as={MdOutlineFileUpload} boxSize={4} />}
                  onClick={openModal}
                  variant="tertiary"
                  size="sm"
                  mr={-3}
                >
                  Upload
                </MButton>
              )}
            </>
          )}
        </MBox>
      </MFlex>
      {isModalOpen && (
        <QuoteDocumentsModalV2
          quote={quote}
          disabled={disabled}
          onUpdate={onUpdate}
          isOpen
          onClose={closeModal}
        />
      )}
    </>
  );
};
