import {
  MAvatar,
  MBox,
  MButton,
  MFlex,
  MTag,
  MText,
  MVStack,
} from '~app/components/Monetize';
import { IUsersInfo } from '~app/types';
import { sortTeamUsers } from '~app/utils/settings';

interface TeamMemberCardProps {
  teamMembers: IUsersInfo[];
  teamOwner: string;
  handleRemoveMember: (memberId: string, name: string) => void;
}
export const TeamMemberCard = ({
  teamMembers,
  teamOwner,
  handleRemoveMember,
}: TeamMemberCardProps) => {
  const loadMemberActionBtn = (memberId: string, name: string) => (
    <MBox pt={memberId === teamOwner ? 2 : 0} w="full" textAlign="center">
      {memberId === teamOwner ? (
        <MTag bgColor="tBlue.hover" color="tIndigo.base">
          Owner
        </MTag>
      ) : (
        <MButton
          w="full"
          variant="tertiary"
          _hover={{ bgColor: 'tRed.cosmos' }}
          color="tRed.base"
          fontWeight="semibold"
          onClick={() => handleRemoveMember(memberId, name)}
        >
          Remove
        </MButton>
      )}
    </MBox>
  );

  return (
    <MFlex columnGap={5} rowGap={4} flexWrap="wrap">
      {sortTeamUsers(teamMembers, teamOwner).map(({ id, name }) => (
        <MVStack
          key={id}
          w="203px"
          h="154px"
          border="1px solid"
          borderColor="tGray.support"
          p={3}
          alignItems="center"
        >
          <MAvatar name={name || ''} size={'md'} />

          <MText noOfLines={1} pt={1} w="full" textAlign="center" title={name}>
            {name}
          </MText>

          {loadMemberActionBtn(id, name)}
        </MVStack>
      ))}
    </MFlex>
  );
};
