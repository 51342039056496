import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useGetTeams } from '~app/api/settingsService';
import {
  MButton,
  MFlex,
  MPageContainer,
  MPageLoader,
  MSettingsPageHeader,
} from '~app/components/Monetize';
import { MPaginator } from '~app/components/Monetize/DataTable';
import MEmptyDataPlaceholder from '~app/components/Monetize/MEmptyDataPlaceholder';
import { ROUTES } from '~app/constants';
import { getTeamsDetailsRoute } from '~app/constants/routes';
import { DEFAULT_PAGER, TDataTablePager } from '~app/types';
import { useHandleDeleteTeam } from '../useHandleDeleteTeam';
import TeamItemCard from './TeamItemCard';

export const TeamList = () => {
  const navigate = useNavigate();
  const [pager, setPager] = useState<TDataTablePager>(() => ({
    ...DEFAULT_PAGER,
    sortField: 'name',
    sortOrder: 1,
  }));

  const { isLoading: loading, data: teams } = useGetTeams({ config: pager });
  const { handleDeleteTeam, deleteLoading } = useHandleDeleteTeam();

  return (
    <MPageContainer alignItems="stretch" data-testid="teams-home-pg">
      <MSettingsPageHeader title="Teams">
        {teams?.content.length !== 0 && !loading && (
          <MFlex alignItems="center">
            <MButton
              as={Link}
              to="new"
              variant="primary"
              data-testid="new-team-btn"
            >
              New
            </MButton>
          </MFlex>
        )}
      </MSettingsPageHeader>
      {loading && <MPageLoader />}
      {teams?.content?.length === 0 && !loading && (
        <MEmptyDataPlaceholder
          mainMessage="Looks like there are no teams here."
          btnLabel="New Team"
          to={ROUTES.SETTINGS_TEAMS_NEW}
        />
      )}

      <MFlex maxW="37.5rem" flexDir={'column'}>
        {teams?.content.map((team) => (
          <TeamItemCard
            key={team.id}
            team={team}
            loading={deleteLoading}
            handleViewTeam={(teamId: string) =>
              navigate(getTeamsDetailsRoute(teamId))
            }
            handleDelete={(teamName, teamId) =>
              handleDeleteTeam({ teamName, teamId })
            }
          />
        ))}

        {!!teams?.content.length && (
          <MPaginator
            pager={pager}
            totalRecords={teams?.totalElements!}
            onPageChange={(newPager) => setPager({ ...pager, ...newPager })}
          />
        )}
      </MFlex>
    </MPageContainer>
  );
};
