import { z } from 'zod';
import { TenantStatusEnum } from '~app/constants/tenants';
import {
  DataTablePagerSchema,
  DEFAULT_PAGER,
  DiscountStatusEnum,
  EsignSchema,
  FilterType,
  FilterTypeOperator,
  OfferingStatusEnum,
  PartialRecord,
  PaymentMethodReqSchemaUI,
  ProductStatusEnum,
  SharedInvoicePaymentSchemaUI,
  TDataTablePager,
  UsageTypeStatusEnum,
} from '~app/types';

export enum PersistKeysEnum {
  USER_ID = 'persist:userId',
  CURRENT_TENANT = 'persist:currentTenant',
  ACCESS_TOKEN = 'persist:accessToken',
  PAYMENT_METHOD = 'persist:temp:paymentMethod',
  DOCUSIGN = 'persist:temp:docusign',
  REDIRECT_INFO = 'persist:temp:redirectInfo',
  QUOTE_PREFERENCES = 'persist:quotes',
  TABLE_FILTERS = 'persist:tableFilters',
  TABLE_PAGERS = 'persist:tablePagers',
  SHARED_INVOICE_KEY = 'persist:temp:sharedInvoice',
  DASHBOARD_CURRENCY_FILTER = 'persist:dashboardCurrencyFilter',
}

/**
 * Used for filters and Pagers
 * This is the key used to know what entry in localstorage to use
 */
export enum FilterStateKeys {
  PRODUCT_LIST = 'PRODUCT_LIST',
  OFFERING_LIST = 'OFFERING_LIST',
  DISCOUNT_LIST = 'DISCOUNT_LIST',
  USAGE_TYPE_LIST = 'USAGE_TYPE_LIST',
  ACCOUNT_LIST = 'ACCOUNT_LIST',
  QUOTE_LIST = 'QUOTE_LIST',
  QUOTE_DASHBOARD_LIST = 'QUOTE_DASHBOARD_LIST',
  CONTRACT_LIST = 'CONTRACT_LIST',
  CONTRACT_DASHBOARD_LIST = 'CONTRACT_DASHBOARD_LIST',
  OPPORTUNITY_LIST = 'OPPORTUNITY_LIST',
  OPPORTUNITY_DASHBOARD_LIST = 'OPPORTUNITY_DASHBOARD_LIST',
  INVOICE_LIST = 'INVOICE_LIST',
  DASHBOARD_APPROVAL_LIST = 'DASHBOARD_APPROVAL_LIST',
  USER_LIST = 'USER_LIST',
  APPROVAL_RULES_LIST = 'APPROVAL_RULES_LIST',
  VALIDATION_RULES_LIST = 'VALIDATION_RULES_LIST',
  CONDITIONAL_TERM_RULES_LIST = 'CONDITIONAL_TERM_RULES_LIST',
  CUSTOM_FIELDS_LIST = 'CUSTOM_FIELDS_LIST',
  GUIDED_QUOTING_LIST = 'GUIDED_QUOTING_LIST',
}

export const TableFilterSchema = z.record(z.array(z.any()));
export const TablePagerSchema = z.record(DataTablePagerSchema);
export type IFilterStorageState = PartialRecord<FilterStateKeys, FilterType[]>;

export const stringValueSchema = z.string();
export const arrayValueSchema = z.array(z.any());

export const TenantStorageSchema = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string().nullish(),
  isAdmin: z.boolean().default(false),
  status: z
    .nativeEnum(TenantStatusEnum)
    .optional()
    .default(TenantStatusEnum.ACTIVE),
  testTenant: z.boolean().default(true),
  timezone: z.string().default('UTC'),
});

export const quoteLocalPreferencesSchema = z.record(
  z.object({ advanced: z.boolean() }),
);
export type QuoteLocalPreferences = z.infer<typeof quoteLocalPreferencesSchema>;

export const TempDocuSignInfoSchema = z.object({
  data: EsignSchema,
  method: z.enum(['post', 'put']),
});
export type TempDocuSignInfo = z.infer<typeof TempDocuSignInfoSchema>;

export const RedirectInfoSchema = z.object({
  tenantId: z.string().nullish(),
  redirectUrl: z.string().nullish(),
});
export type RedirectInfo = z.infer<typeof RedirectInfoSchema>;

export function getDefaultFilter(key: FilterStateKeys): FilterType[] {
  switch (key) {
    case FilterStateKeys.DISCOUNT_LIST:
      return [
        {
          key: 'accountId',
          value: false,
          operator: FilterTypeOperator.TOGGLE,
          options: {
            falseValue: 'eq:null',
          },
        },
        {
          key: 'status',
          value: [DiscountStatusEnum.ACTIVE],
          operator: FilterTypeOperator.IN,
        },
      ];
    case FilterStateKeys.OFFERING_LIST:
      return [
        {
          key: 'status',
          value: [OfferingStatusEnum.ACTIVE],
          operator: FilterTypeOperator.IN,
        },
      ];
    case FilterStateKeys.PRODUCT_LIST:
      return [
        {
          key: 'status',
          value: [ProductStatusEnum.ACTIVE],
          operator: FilterTypeOperator.IN,
        },
      ];
    case FilterStateKeys.USAGE_TYPE_LIST:
      return [
        {
          key: 'status',
          value: [UsageTypeStatusEnum.ACTIVE],
          operator: FilterTypeOperator.IN,
        },
      ];
    case FilterStateKeys.ACCOUNT_LIST:
    case FilterStateKeys.QUOTE_LIST:
    case FilterStateKeys.QUOTE_DASHBOARD_LIST:
    case FilterStateKeys.CONTRACT_LIST:
    case FilterStateKeys.CONTRACT_DASHBOARD_LIST:
    case FilterStateKeys.OPPORTUNITY_LIST:
    case FilterStateKeys.OPPORTUNITY_DASHBOARD_LIST:
    case FilterStateKeys.INVOICE_LIST:
    case FilterStateKeys.APPROVAL_RULES_LIST:
    case FilterStateKeys.VALIDATION_RULES_LIST:
      return [];
    default:
      return [];
  }
}

export function getDefaultPager(key: FilterStateKeys): TDataTablePager {
  switch (key) {
    case FilterStateKeys.OFFERING_LIST:
    case FilterStateKeys.DISCOUNT_LIST:
    case FilterStateKeys.APPROVAL_RULES_LIST:
    case FilterStateKeys.VALIDATION_RULES_LIST:
      return { ...DEFAULT_PAGER, sortField: 'name', sortOrder: 1 };
    case FilterStateKeys.QUOTE_LIST:
    case FilterStateKeys.CONTRACT_LIST:
    case FilterStateKeys.CONTRACT_DASHBOARD_LIST:
    case FilterStateKeys.OPPORTUNITY_LIST:
    case FilterStateKeys.OPPORTUNITY_DASHBOARD_LIST:
      return { ...DEFAULT_PAGER, sortField: 'modifyDate', sortOrder: -1 };
    case FilterStateKeys.PRODUCT_LIST:
    case FilterStateKeys.USAGE_TYPE_LIST:
    case FilterStateKeys.ACCOUNT_LIST:
    case FilterStateKeys.QUOTE_DASHBOARD_LIST:
    case FilterStateKeys.INVOICE_LIST:
      return { ...DEFAULT_PAGER };
    default:
      return { ...DEFAULT_PAGER };
  }
}

export enum StorageOptions {
  LOCAL = 'LOCAL',
  SESSION = 'SESSION',
  ALL = 'ALL',
}

export type StorageType =
  | StorageOptions.LOCAL
  | StorageOptions.SESSION
  | StorageOptions.ALL;

// Union of all schemas allowed in browser storage
export type SchemaValidators =
  | typeof stringValueSchema
  | typeof arrayValueSchema
  | typeof quoteLocalPreferencesSchema
  | typeof TenantStorageSchema
  | typeof PaymentMethodReqSchemaUI
  | typeof TempDocuSignInfoSchema
  | typeof RedirectInfoSchema
  | typeof TableFilterSchema
  | typeof TablePagerSchema
  | typeof SharedInvoicePaymentSchemaUI;

interface IBrowserStorageProps<T> {
  persistKey: PersistKeysEnum;
  schemaValidator: T;
}

export interface IGetBrowserStorageProps<T extends SchemaValidators, TDefault>
  extends IBrowserStorageProps<T> {
  defaultValue?: z.infer<T> | TDefault;
  type?: StorageType;
}

export interface ISaveToBrowserStorageProps<T extends SchemaValidators>
  extends IBrowserStorageProps<T> {
  value: z.infer<T>;
  type?: StorageType;
  transformFn?: (value: z.infer<T>) => any;
}
