import {
  FormControl,
  FormLabel,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Switch,
  useDisclosure,
} from '@chakra-ui/react';
import { FC, useEffect, useState } from 'react';
import { MdShare } from 'react-icons/md';
import CollaborationIcon from '~app/assets/icons/CollaborationIcon';
import {
  MBox,
  MButton,
  MCustomIconButton,
  MDivider,
  MFlex,
  MPopover,
  MRadio,
  MRadioGroup,
  MStack,
  MText,
  MTooltip,
} from '~app/components/Monetize';
import { getQuoteBaseRoute } from '~app/constants/routes';
import { useNavigatorPermission } from '~app/hooks/useNavigatorPermission';
import { useNonInitialEffect } from '~app/hooks/useNonInitialEffect';
import {
  CollaborationAccessEnum,
  CollaborationAccessLevelEnum,
  QuoteStatusEnum,
} from '~app/types';
import { copyToClipboard } from '~app/utils';

interface QuoteCollaborationPopoverProps {
  quoteId: string;
  quoteStatus: QuoteStatusEnum;
  collaborationAccess: CollaborationAccessEnum;
  accessLevel: CollaborationAccessLevelEnum | null;
  onChange: (collaborationAccess: CollaborationAccessEnum) => void;
}

function getDefaultStatus(
  collaborationAccess: CollaborationAccessEnum,
): CollaborationAccessEnum {
  return collaborationAccess === CollaborationAccessEnum.NONE
    ? CollaborationAccessEnum.VIEW
    : collaborationAccess;
}

export const QuoteCollaborationPopover: FC<QuoteCollaborationPopoverProps> = ({
  quoteId,
  collaborationAccess,
  accessLevel,
  quoteStatus,
  onChange,
}: QuoteCollaborationPopoverProps) => {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const { canCopyClipboard } = useNavigatorPermission();

  // Store internal state to ensure form never changes from prop being changed since quote save is debounced
  const [collaborationAccessInternal, setCollaborationAccessInternal] =
    useState(collaborationAccess || CollaborationAccessEnum.NONE);

  const [isCopied, setIsCopied] = useState(false);
  const { icon, iconColor } =
    collaborationAccessInternal === CollaborationAccessEnum.NONE
      ? { icon: MdShare, iconColor: 'tPurple.base' }
      : { icon: CollaborationIcon, iconColor: 'tBlue.light' };

  const [isEnabled, setIsEnabled] = useState(
    () => collaborationAccessInternal !== CollaborationAccessEnum.NONE,
  );

  const [editOrViewValue, setEditOrViewValue] = useState(() =>
    getDefaultStatus(collaborationAccessInternal),
  );

  useEffect(() => {
    if (collaborationAccessInternal) {
      setEditOrViewValue(getDefaultStatus(collaborationAccessInternal));
    }
  }, [collaborationAccessInternal]);

  useNonInitialEffect(() => {
    if (isEnabled && collaborationAccessInternal !== editOrViewValue) {
      // user enabled collaboration or changed access level
      onChange(editOrViewValue);
      setCollaborationAccessInternal(editOrViewValue);
    } else if (
      !isEnabled &&
      collaborationAccessInternal !== CollaborationAccessEnum.NONE
    ) {
      // user disabled collaboration
      onChange(CollaborationAccessEnum.NONE);
      setCollaborationAccessInternal(CollaborationAccessEnum.NONE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collaborationAccessInternal, isEnabled, editOrViewValue]);

  const toggleEnabled = () => {
    setIsEnabled(!isEnabled);
  };

  const handleCopyLinkToClipboard = (ev: React.MouseEvent<HTMLElement>) => {
    ev.stopPropagation();
    copyToClipboard(`${location.origin}${getQuoteBaseRoute(quoteId)}`);
    setIsCopied(true);
    setTimeout(() => {
      onClose();
    }, 1000);
  };

  function handleOnOpen() {
    setIsCopied(false);
    setCollaborationAccessInternal(
      collaborationAccess || CollaborationAccessEnum.NONE,
    );
    onOpen();
  }

  return (
    <MPopover
      isLazy
      placement="right-start"
      isOpen={isOpen}
      onOpen={handleOnOpen}
      onClose={onClose}
      returnFocusOnClose={false}
    >
      <MTooltip label={'Share'} placement="top-end" hasArrow shouldWrapChildren>
        <PopoverTrigger>
          <MCustomIconButton
            variant="icon"
            aria-label="Share Quote"
            icon={icon}
            iconColor={iconColor}
            btnSize={4}
            padding={0}
            containerSize={4}
          />
        </PopoverTrigger>
      </MTooltip>
      <Portal>
        <PopoverContent>
          <PopoverArrow />
          <PopoverBody px={4} pt={4} pb={2}>
            <MFlex flexDirection="column">
              <FormControl
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <FormLabel htmlFor="allow-collaboration-switch" mb="0">
                  Allow Collaboration
                </FormLabel>
                <Switch
                  id="allow-collaboration-switch"
                  isChecked={isEnabled}
                  size="md"
                  onChange={() => toggleEnabled()}
                  isDisabled={
                    accessLevel === CollaborationAccessLevelEnum.READ ||
                    ![
                      QuoteStatusEnum.DRAFT,
                      QuoteStatusEnum.APPROVED,
                      QuoteStatusEnum.REVIEW,
                    ].includes(quoteStatus)
                  }
                />
              </FormControl>
              <MText my={1} pr={8}>
                This allows anyone at this organization to view or edit this
                Quote. Approvers already have access to this quote.
              </MText>

              <MRadioGroup
                data-testid="collaboration-access-radio-group"
                onChange={(value) =>
                  setEditOrViewValue(value as CollaborationAccessEnum)
                }
                value={editOrViewValue}
                isDisabled={!isEnabled}
              >
                <MStack direction="row">
                  <MRadio
                    isDisabled={
                      !isEnabled ||
                      accessLevel === CollaborationAccessLevelEnum.READ
                    }
                    value={CollaborationAccessEnum.VIEW}
                  >
                    View
                  </MRadio>
                  <MRadio
                    isDisabled={
                      !isEnabled ||
                      accessLevel === CollaborationAccessLevelEnum.READ
                    }
                    value={CollaborationAccessEnum.EDIT}
                  >
                    Edit
                  </MRadio>
                </MStack>
              </MRadioGroup>

              {canCopyClipboard && <MDivider my={2} />}
              {canCopyClipboard && (
                <MBox>
                  {isCopied ? (
                    <MText
                      fontWeight={'semibold'}
                      color={'tIndigo.base'}
                      height="8"
                      lineHeight={8}
                    >
                      Copied!
                    </MText>
                  ) : (
                    <MButton
                      variant="tertiary"
                      size="sm"
                      ml={-3}
                      onClick={handleCopyLinkToClipboard}
                    >
                      Copy Link
                    </MButton>
                  )}
                </MBox>
              )}
            </MFlex>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </MPopover>
  );
};
