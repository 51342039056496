import { MFlex, MIcon, MText, MTooltip } from '~app/components/Monetize';
import { PRICE_MODEL_OPTIONS } from '~app/constants/offerings';
import { PriceModelEnum } from '~app/types';
import {
  getPriceModelHelperText,
  getTooltipDisabledMessage,
} from '../RatePriceUtils';
import { MdInfo } from 'react-icons/md';
import { TooltipOrChildren } from './TooltipOrChildren';

export const PriceModelItemContent = ({
  title,
  item,
  isSubtitle,
  isSelected,
  isHighlight,
  isDisabled,
  selectedPriceModel /** Selected price model in 0th position */,
}: {
  title: string;
  item: any;
  isSubtitle: boolean;
  isSelected: boolean;
  isHighlight: boolean;
  isDisabled: boolean;
  selectedPriceModel: string;
}) => {
  return (
    <TooltipOrChildren
      isDisabled={isDisabled}
      label={getTooltipDisabledMessage({
        selectedPriceModel,
        disabledPriceModel: title,
      })}
    >
      <MFlex
        alignItems="center"
        justifyContent="space-between"
        w="100%"
        h="22px"
        position="relative"
        role="group"
        _hover={{
          div: {
            display: 'flex',
          },
        }}
      >
        <MText
          color="inherit"
          isTruncated
          noOfLines={1}
          fontSize={isSubtitle ? '12px' : 'sm'}
          mb="-2px"
          mt="0px"
          width="90%"
        >
          {PRICE_MODEL_OPTIONS[title as PriceModelEnum] || title}
        </MText>

        {!isDisabled && (
          <MTooltip
            label={getPriceModelHelperText(item.value)}
            placement="bottom-start"
          >
            <MFlex display="none">
              <MIcon as={MdInfo} color="tPurple.base" />
            </MFlex>
          </MTooltip>
        )}
      </MFlex>
    </TooltipOrChildren>
  );
};
