import { checkboxAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(checkboxAnatomy.keys);

const baseStyle = definePartsStyle((props) => ({
  container: {
    alignItems: 'flex-start',
  },
  control: {
    borderColor: 'tGray.darkPurple',
    _checked: {
      bg: 'tIndigo.base',
      borderColor: 'tIndigo.base',
      // TODO: do we need this?
      color: mode('white', 'gray.900')(props),
      _disabled: {
        background: 'tGray.acGray',
        borderColor: 'tGray.acGray',
        color: 'white',
      },
    },
    _disabled: {
      background: 'transparent',
      borderColor: 'tGray.acGray',
    },
    // Inverse option
    // _indeterminate: {
    //   bg: 'tIndigo.base',
    //   borderColor: 'tIndigo.base',
    //   color: mode('white', 'gray.900')(props),
    //   _disabled: {
    //     background: 'white',
    //     borderColor: 'tGray.acGray',
    //     color: 'tGray.acGray',
    //   },
    // },
    _indeterminate: {
      bg: 'unset',
      borderColor: 'tIndigo.base',
      color: 'tIndigo.base',
      iconSize: '0.625rem',
      _disabled: {
        background: 'white',
        borderColor: 'tGray.acGray',
        color: 'tGray.acGray',
      },
    },
  },
  label: {
    mt: '-1px',
    marginInlineStart: 1.5,
    lineHeight: '18px',
    _disabled: {
      opacity: 1,
      color: 'tGray.acGray',
    },
  },
}));

const sizes = {
  sm: definePartsStyle({
    control: { h: 4, w: 4 },
    label: { fontSize: 'sm' },
    icon: { fontSize: '0.45rem' },
  }),
  md: definePartsStyle({
    control: { w: 5, h: 5 },
    label: { fontSize: 'md' },
    icon: { fontSize: '0.625rem' },
  }),
  lg: definePartsStyle({
    control: { w: 6, h: 6 },
    label: { fontSize: 'lg' },
    icon: { fontSize: '0.625rem' },
  }),
};

const variantSelectEvents = definePartsStyle((props) => ({
  control: {
    ...baseStyle(props).control,
    borderColor: 'tGray.darkPurple',
    color: 'tIndigo.base',
    _checked: {
      bg: 'tIndigo.base',
      borderColor: 'tIndigo.base',
      _disabled: {
        bg: 'tGray.darkPurple',
        color: 'white',
      },
    },
    _disabled: {
      bg: 'tGray.darkPurple',
      color: 'white',
    },
  },
  label: {
    ...baseStyle(props).label,
    color: 'tPurple.base',
    fontWeight: 'medium',
  },
}));

const variantCustomSelect = definePartsStyle((props) => ({
  control: defineStyle({
    ...variantSelectEvents(props).control,
  }),
  label: defineStyle({
    ...variantSelectEvents(props).label,
    fontWeight: 'normal',
    w: '100%',
  }),
}));

const variantUnsaved = definePartsStyle((props) => {
  const fieldStyle = variantSelectEvents(props);
  return {
    ...fieldStyle,
    control: {
      ...fieldStyle.control,
      boxShadow: '0 0 0 10px rgba(228, 121, 21, 0.25)',
      clipPath: 'circle(100%)',
      backgroundColor: 'rgba(228, 121, 21, 0.25)',
    },
  };
});

export const checkboxTheme = defineMultiStyleConfig({
  variants: {
    'select-events': variantSelectEvents,
    'custom-select': variantCustomSelect,
    unsaved: variantUnsaved,
  },
  baseStyle,
  sizes,
  defaultProps: {
    size: 'sm',
    colorScheme: 'tPurple',
  },
});

export default checkboxTheme;
