import { StyleProps, useDisclosure } from '@chakra-ui/react';
import { FunctionComponent as FC } from 'react';
import { OpportunityLinkFilledIcon } from '~app/assets/icons/OpportunityLinkFilledIcon';
import { OpportunityLinkIcon } from '~app/assets/icons/OpportunityLinkIcon';
import { OpportunityModal } from '~app/components/Opportunities/OpportunityModal';
import { MBox, MCustomIconButton, MTooltip } from '~components/Monetize';
import { IQuoteRespSchema, Maybe } from '~types';

export interface OpportunityLinkDataTypes {
  isOpen: boolean;
  quote?: Maybe<IQuoteRespSchema>;
  onOpen: () => void;
  onClose: () => void;
  /** Fetch quote */
  loadData?: Maybe<() => void>;
}

export const useOpportunityLinkData = ({
  quote,
  loadData,
}: {
  quote?: Maybe<IQuoteRespSchema>;
  loadData?: Maybe<() => void>;
} = {}): OpportunityLinkDataTypes => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return {
    isOpen,
    quote,
    loadData,
    onOpen,
    onClose,
  };
};

interface OpportunityLinkProps {
  isOpen: boolean;
  isDisabled?: boolean;
  quote?: Maybe<IQuoteRespSchema>;
  iconButtonStyles?: StyleProps;
  onOpen: () => void;
  onClose: (skipNavigate?: boolean) => void;
  loadData?: Maybe<() => void>;
}

export const OpportunityLink: FC<OpportunityLinkProps> = ({
  isOpen,
  isDisabled,
  quote,
  iconButtonStyles = {},
  onOpen,
  onClose,
  loadData,
}: OpportunityLinkProps) => {
  // Don't show if an opportunity does not exist (should never happen)
  if (!quote || !quote.opportunity) {
    return null;
  }

  const { opportunity } = quote;

  return (
    <MBox position="relative">
      <MTooltip
        isDisabled={isOpen}
        label="View Opportunity"
        placement="bottom-start"
      >
        <MCustomIconButton
          btnSize={5}
          variant="icon"
          aria-label="Link Salesforce Opportunity"
          p={4}
          mx={0.5}
          icon={
            opportunity?.customId
              ? OpportunityLinkFilledIcon
              : OpportunityLinkIcon
          }
          iconColor={opportunity?.customId ? 'tIndigo.base' : 'tPurple.base'}
          onClick={onOpen}
          {...iconButtonStyles}
        />
      </MTooltip>
      <OpportunityModal
        isOpen={isOpen}
        isDisabled={isDisabled}
        onClose={() => {
          loadData && loadData();
          onClose();
        }}
        opportunityId={opportunity.id}
      />
    </MBox>
  );
};

export default OpportunityLink;
