import {
  CustomFieldPermissionsEnum,
  ICustomFieldRecordSchema,
  ICustomFieldResSchema,
} from '../types';
import { nullifyEmptyStrings } from './misc';

export const sortCustomFields = (
  fieldA: ICustomFieldResSchema,
  fieldB: ICustomFieldResSchema,
) => {
  const fieldAWritable = fieldA.permissions.includes(
    CustomFieldPermissionsEnum.WRITE,
  );
  const fieldBWritable = fieldB.permissions.includes(
    CustomFieldPermissionsEnum.WRITE,
  );
  if (fieldAWritable !== fieldBWritable) {
    return fieldAWritable ? -1 : 1;
  }

  return fieldA.displayLabel
    .toLowerCase()
    .localeCompare(fieldB.displayLabel.toLowerCase());
};

export const handleCustomFieldToDisplayBannerFormat = (
  valueFields: ICustomFieldRecordSchema,
  customFields: ICustomFieldResSchema[],
) => {
  /**
   * lets not mutate the array in place
   * beacuse the caller may pass the reference itself
   */
  const customFieldList = [...customFields].sort(sortCustomFields);

  const customFieldToDisplay = customFieldList.map((field) => {
    const fieldKey = field.key;
    const fieldValue = valueFields[fieldKey]?.toString() ?? 'Blank';
    const fieldLabel = field.displayLabel;

    return {
      label: fieldLabel,
      value: fieldValue,
    };
  });

  return customFieldToDisplay;
};

/**
 * Convert form values to request format
 * @param values ICustomFieldRecordSchema
 * @returns ICustomFieldRecordSchema
 */
export const convertFormValuesToReq = (values?: ICustomFieldRecordSchema) => {
  if (typeof values !== 'object') {
    return values || {};
  }

  return nullifyEmptyStrings(values);
};
