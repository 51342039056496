import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  MButton,
  MDivider,
  MFlex,
  MFormField,
  MHStack,
  MInput,
  MRadio,
  MRadioGroup,
  MStack,
  MText,
} from '~app/components/Monetize';
import { SALESFORCE } from '~app/constants';
import { getSalesforceUrl } from '~app/constants/salesforce';
import {
  ISalesforce,
  ISalesforceSchema,
  SalesforceOrgTypeEnum,
  SalesforceSchema,
} from '~app/types';

interface SalesforceFormProps {
  connection?: ISalesforce | null;
  handleCancel: () => void;
  onAuthorize: (data: ISalesforceSchema) => Promise<void>;
}

export const SalesforceForm = ({
  connection,
  handleCancel,
  onAuthorize,
}: SalesforceFormProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors, isValid },
  } = useForm<ISalesforceSchema>({
    resolver: zodResolver(SalesforceSchema),
    mode: 'onChange',
    defaultValues: {
      orgType:
        (connection?.orgType?.toUpperCase() as any) ||
        SalesforceOrgTypeEnum.PRODUCTION,
    },
  });
  const watchOrgType = watch('orgType');
  const watchCustomUrl = watch('customUrl');

  const isCustomOrgType = watchOrgType === SalesforceOrgTypeEnum.CUSTOM;

  const onSubmitHandler = async (data: ISalesforceSchema) => {
    setIsLoading(true);
    const customUrl = getSalesforceUrl(watchOrgType, watchCustomUrl);
    await onAuthorize({ ...data, customUrl });
    setIsLoading(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)} style={{ width: '100%' }}>
      <MFlex flexDirection="column">
        <MFormField error={errors.orgType} label="Login Domain" isRequired>
          <Controller
            name="orgType"
            control={control}
            defaultValue={SalesforceOrgTypeEnum.SANDBOX}
            render={({ field }) => (
              <MRadioGroup {...field}>
                <MStack direction="row" spacing={3}>
                  {SALESFORCE.SalesforceOrgTypeSelectOptions.map(
                    ({ value, title }) => {
                      return (
                        <MRadio key={value} value={value}>
                          {title}
                        </MRadio>
                      );
                    },
                  )}
                </MStack>
              </MRadioGroup>
            )}
          />
        </MFormField>
        <MFlex alignItems="end">
          <MFormField error={errors.customUrl} label="URL">
            <Controller
              name="customUrl"
              control={control}
              defaultValue=""
              render={({ field: { value, ...rest } }) => (
                <MInput
                  {...rest}
                  value={
                    isCustomOrgType
                      ? (value as string)
                      : getSalesforceUrl(watchOrgType)
                  }
                  isDisabled={!isCustomOrgType}
                />
              )}
            />
          </MFormField>
        </MFlex>
        {isCustomOrgType && (
          <MText mt={2}>{getSalesforceUrl(watchOrgType, watchCustomUrl)}</MText>
        )}
        <MDivider my={4} />
        <MHStack spacing="4" justify="end" align="center">
          <MButton variant="cancel" onClick={handleCancel}>
            Cancel
          </MButton>
          <MButton
            type="submit"
            variant="primary"
            onClick={handleSubmit(onSubmitHandler)}
            isLoading={isLoading}
            isDisabled={
              !isValid || (isCustomOrgType && !watchCustomUrl) || isLoading
            }
          >
            Connect
          </MButton>
        </MHStack>
      </MFlex>
    </form>
  );
};
