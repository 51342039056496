import { FC, useState } from 'react';
import {
  MBox,
  MCustomIconButton,
  MFlex,
  MGrid,
  MSpinner,
} from '~app/components/Monetize';
import { useFlags } from '~app/services/launchDarkly';
import { IQuoteRequestSchema, IQuoteRespSchema } from '~app/types';
import { QuoteDocumentsViewItem } from './QuoteDocumentsViewItem';
import { useQuoteDocumentsV2 } from './useQuoteDocumentsV2';
import { MdCheck, MdClose } from 'react-icons/md';

interface QuoteDocumentsViewProps {
  quote: IQuoteRespSchema;
  isReadOnly: boolean;
  onUpdate: (data: IQuoteRequestSchema) => void;
}

export const QuoteDocumentsView: FC<QuoteDocumentsViewProps> = ({
  quote,
  isReadOnly,
  onUpdate,
}: QuoteDocumentsViewProps) => {
  const featureFlags = useFlags();

  const {
    msaData,
    sowData,
    pdfCoverData,
    pdfFooterData,

    msaDocument,
    sowDocument,
    pdfCoverDocument,
    pdfFooterDocument,

    handleActiveItemChange,
    handleAddItem,
    handleRemoveItem,
    handleGoToItem,
    isLoadingInitialData,
  } = useQuoteDocumentsV2({
    quote,
    onUpdate,
  });

  return (
    <MFlex ml="2" w="full" mb="2">
      <MGrid
        templateColumns={[
          'repeat(1, 1fr)',
          'repeat(1, 1fr)',
          'repeat(1, 1fr)',
          'repeat(1, 1fr)',
          'repeat(4, 1fr)',
        ]}
        gap={4}
        flex="1"
        ml="6"
      >
        {featureFlags.pdfCoverFooter && (
          <>
            <MBox flex="1" mr="4" minW="0">
              <QuoteDocumentsViewItem
                label="Cover"
                activeItem={pdfCoverData.activeItem}
                linkUrl={pdfCoverData.linkUrl}
                savedItemText={pdfCoverData.savedItemText}
                disabled={pdfCoverData.saving}
                isLoading={pdfCoverData.saving || pdfCoverDocument.isFetching}
                isReadOnly={isReadOnly}
                onSetActiveItem={(item) =>
                  handleActiveItemChange('cover', item)
                }
                onAddItem={(data) => handleAddItem('cover', data)}
                onRemoveItem={() => handleRemoveItem('cover')}
                onGotoSavedItem={() => handleGoToItem('cover')}
                showControlButton
              />
            </MBox>
            <MBox flex="1" mr="4" minW="0">
              <QuoteDocumentsViewItem
                label="Footer"
                activeItem={pdfFooterData.activeItem}
                linkUrl={pdfFooterData.linkUrl}
                savedItemText={pdfFooterData.savedItemText}
                disabled={pdfFooterData.saving}
                isLoading={pdfFooterData.saving || pdfFooterDocument.isFetching}
                isReadOnly={isReadOnly}
                onSetActiveItem={(item) =>
                  handleActiveItemChange('footer', item)
                }
                onAddItem={(data) => handleAddItem('footer', data)}
                onRemoveItem={() => handleRemoveItem('footer')}
                onGotoSavedItem={() => handleGoToItem('footer')}
                showControlButton
              />
            </MBox>
          </>
        )}
        <MBox flex="1" mr="4" minW="0">
          <QuoteDocumentsViewItem
            label="Statement of Work (SOW)"
            activeItem={sowData.activeItem}
            linkUrl={sowData.linkUrl}
            savedItemText={sowData.savedItemText}
            disabled={sowData.saving || sowDocument.isFetching}
            isLoading={sowData.saving}
            isReadOnly={isReadOnly}
            onSetActiveItem={(item) => handleActiveItemChange('sow', item)}
            onAddItem={(data) => handleAddItem('sow', data)}
            onRemoveItem={() => handleRemoveItem('sow')}
            onGotoSavedItem={() => handleGoToItem('sow')}
            showControlButton
          />
        </MBox>
        <MBox flex="1" mr="4" minW="0">
          <QuoteDocumentsViewItem
            label="Master Service Agreement (MSA)"
            allowLink
            activeItem={msaData.activeItem}
            linkUrl={msaData.linkUrl}
            savedItemText={msaData.savedItemText}
            disabled={msaData.saving}
            isLoading={msaData.saving || msaDocument.isFetching}
            isReadOnly={isReadOnly}
            onSetActiveItem={(item) => handleActiveItemChange('msa', item)}
            onAddItem={(data) => handleAddItem('msa', data)}
            onRemoveItem={() => handleRemoveItem('msa')}
            onGotoSavedItem={() => handleGoToItem('msa')}
            saveBtnContent={({ value, error }) => {
              return (
                <>
                  <MCustomIconButton
                    variant="icon"
                    containerSize="8"
                    btnSize={5}
                    cursor="pointer"
                    icon={MdCheck}
                    onClick={() => {
                      handleAddItem('msa', { type: 'link', value });
                    }}
                    iconColor="tPurple.base"
                  />
                  <MCustomIconButton
                    variant="icon"
                    containerSize="8"
                    btnSize={5}
                    cursor="pointer"
                    icon={MdClose}
                    onClick={() => {
                      handleActiveItemChange('msa', 'none');
                    }}
                    iconColor="tPurple.base"
                  />
                </>
              );
            }}
          />
        </MBox>
      </MGrid>
    </MFlex>
  );
};
