import { AxiosError } from 'axios';
import React from 'react';
import { MdCheckCircle } from 'react-icons/md';
import { PaymentMethodTypeEnumDisplay } from '~app/constants/paymentMethods';

import {
  MAlertTag,
  MBox,
  MCheckbox,
  MDivider,
  MFlex,
  MFormLabel,
  MIcon,
  MText,
} from '~components/Monetize';

import {
  GenericApiResponse,
  ImportPaymentMethodResp,
  ImportStep,
  ImportablePaymentMethodItem,
  ImportablePaymentMethodResp,
  PaymentMethodTypeEnum,
} from '~types';

interface ImportPaymentMethodListProps {
  step: ImportStep;
  selectedPMs: string[];
  setSelectedPMs: (val: string[]) => void;
  dataImportablePaymentMethod?: ImportablePaymentMethodResp;
  errorSaveImportPaymentMethod?: AxiosError<GenericApiResponse> | null;
  dataSaveImportPaymentMethod?: ImportPaymentMethodResp;
}

export const ImportPaymentMethodList = ({
  selectedPMs,
  setSelectedPMs,
  step,
  dataImportablePaymentMethod,
  errorSaveImportPaymentMethod,
  dataSaveImportPaymentMethod,
}: ImportPaymentMethodListProps) => {
  const onToggleCheckItem = (item: ImportablePaymentMethodItem) => {
    const index = selectedPMs.indexOf(item.externalId);
    if (index === -1) {
      setSelectedPMs(selectedPMs.concat([item.externalId]));
    } else {
      selectedPMs.splice(index, 1);
      setSelectedPMs([...selectedPMs]);
    }
  };

  let paymentMethods = dataImportablePaymentMethod?.paymentMethods || [];

  if (
    step === ImportStep.SUBMITTED_PAYMENT_METHOD &&
    errorSaveImportPaymentMethod
  ) {
    return (
      <MBox alignSelf="stretch">
        <MAlertTag type={'error'} padding="4" alignItems="flex-start">
          <MBox ml="1">
            <MText color="tGray.acBlack" fontSize="sm">
              {errorSaveImportPaymentMethod?.response?.data?.message ||
                'There was an error importing your payment. Try again.'}
            </MText>
          </MBox>
        </MAlertTag>
      </MBox>
    );
  }

  const isSubmitted = step === ImportStep.SUBMITTED_PAYMENT_METHOD;
  if (isSubmitted) {
    paymentMethods = paymentMethods?.filter((paymentMethod) =>
      selectedPMs.includes(paymentMethod.externalId),
    );
  }

  return (
    <>
      {paymentMethods.map((paymentMethod, index) => {
        const isChecked = selectedPMs.includes(paymentMethod.externalId);
        const succeed = dataSaveImportPaymentMethod?.succeededImports.find(
          (pm) => pm.externalId === paymentMethod.externalId,
        );
        const failed = dataSaveImportPaymentMethod?.failedImports.find(
          (pm) => pm.externalPaymentMethodId === paymentMethod.externalId,
        );
        const existing =
          dataSaveImportPaymentMethod?.existingPaymentMethods.find(
            (pm) => pm.externalId === paymentMethod.externalId,
          );

        return (
          <React.Fragment key={paymentMethod.externalId}>
            <MBox mt={2} mb={2} alignSelf="stretch">
              <MFlex>
                <MBox flex="1">
                  {!isSubmitted && (
                    <MCheckbox
                      id={paymentMethod.externalId}
                      isChecked={isChecked}
                      onChange={() => onToggleCheckItem(paymentMethod)}
                    >
                      <MFormLabel
                        m="0"
                        ml="1"
                        htmlFor={paymentMethod.externalId}
                        fontSize="sm"
                        fontWeight="500"
                      >
                        **** **** **** {paymentMethod.lastFour}
                      </MFormLabel>
                    </MCheckbox>
                  )}
                  {isSubmitted && (
                    <MFlex>
                      {isChecked && !failed && (
                        <MIcon
                          as={MdCheckCircle}
                          color={existing ? 'tGreen.lime' : 'tGreen.mDarkShade'}
                        />
                      )}
                      {(!isChecked || failed) && <MBox w="4" />}
                      <MText fontSize="sm" fontWeight="500" ml="2">
                        **** **** **** {paymentMethod.lastFour}
                      </MText>
                    </MFlex>
                  )}

                  <MBox ml="6">
                    {paymentMethod.type ==
                      PaymentMethodTypeEnum.DIRECT_DEBIT && (
                      <>
                        <MText fontSize="xs">{paymentMethod.bankName}</MText>
                        <MText fontSize="xs">
                          {paymentMethod.routingNumber}
                        </MText>
                      </>
                    )}
                    {paymentMethod.type ==
                      PaymentMethodTypeEnum.CREDIT_CARD && (
                      <>
                        <MText fontSize="xs">{paymentMethod.cardType}</MText>
                        <MText fontSize="xs">
                          {paymentMethod.expirationMonth}/
                          {paymentMethod.expirationYear}
                        </MText>
                      </>
                    )}
                    {paymentMethod.type == PaymentMethodTypeEnum.ACH_CREDIT && (
                      <>
                        <MText fontSize="xs">{paymentMethod.bankName}</MText>
                        <MText fontSize="xs">
                          {paymentMethod.routingNumber}
                        </MText>
                        <MText fontSize="xs">
                          {paymentMethod.accountNumber}
                        </MText>
                        <MText fontSize="xs">{paymentMethod.swiftCode}</MText>
                      </>
                    )}
                    {isSubmitted && !!existing && (
                      <MText
                        fontSize="xs"
                        color="tGray.acGray"
                        textStyle="italic"
                      >
                        Skipped
                      </MText>
                    )}
                  </MBox>
                </MBox>
                <MBox ml="auto">
                  <MText fontSize="xs" color="tGray.acGray">
                    {PaymentMethodTypeEnumDisplay[paymentMethod.type]}
                  </MText>
                </MBox>
              </MFlex>
              {isSubmitted && !!failed && (
                <MAlertTag type={'warning'} padding="4" w="full" mt="2">
                  <MBox ml="1">
                    <MText color="tGray.acBlack" fontSize="sm">
                      {failed.reason}
                    </MText>
                  </MBox>
                </MAlertTag>
              )}
            </MBox>
            {index !== paymentMethods.length - 1 && (
              <MDivider
                h={1}
                borderColor="tBlue.hover"
                alignSelf="end"
                opacity={1}
                borderStyle="solid"
              />
            )}
          </React.Fragment>
        );
      })}
    </>
  );
};
