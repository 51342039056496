import { useEffect, useState } from 'react';
import { doGetUsersListByTenantId } from '~app/api/usersService';
import { useAuth } from '~app/services/auth0';
import { logger } from '~app/services/logger';
import { groupBy } from '~app/utils/misc';
import { DEFAULT_PAGER, IUser, TDataTablePager } from '~types';

const pager: TDataTablePager = {
  ...DEFAULT_PAGER,
  rows: 500,
  sortField: 'name',
};

// TODO: ideally the transport layer (axios) would manage caching global objects like this
// and we would store in persistent browser cacheStorage or IndexedDb and resolve cache or re-fetch there
let CACHED_USERS: IUser[];

/**
 * Fetches all users in tenant and caches them in memory so that subsequent calls
 * do not require a full re-fetch for data that does not change often.
 *
 * This hook auto-fetches on init
 */
export const useTenantUsers = () => {
  const [hasLoaded, setHasLoaded] = useState<boolean>(!!CACHED_USERS);
  const [loading, setLoading] = useState<boolean>(false);
  const [users, setUsers] = useState<IUser[]>(CACHED_USERS || []);
  const [usersById, setUsersById] = useState<Record<string, IUser>>({});

  // users to pair with list of notes
  const { tenantId } = useAuth();

  useEffect(() => {
    if (hasLoaded) {
      return;
    }
    (async () => {
      setLoading(true);
      try {
        // TODO: paginate through all users
        const results = await doGetUsersListByTenantId(tenantId, pager);
        setUsers(results.content);
        setHasLoaded(true);
      } catch (ex) {
        logger.warn('[ERROR] Could not fetch users', ex);
      } finally {
        setLoading(false);
      }
    })();
  }, [tenantId]);

  useEffect(() => {
    if (users) {
      CACHED_USERS = users;
      setUsersById(groupBy(users, 'id'));
    }
  }, [users]);

  return {
    loading,
    users,
    usersById,
  };
};
