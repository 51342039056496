import React, { useMemo } from 'react';
import {
  Control,
  Controller,
  UseFormSetValue,
  useWatch,
} from 'react-hook-form';
import {
  MBox,
  MFlex,
  MFormField,
  MText,
  MTooltip,
} from '~app/components/Monetize';
import { DiscountCell } from '~app/components/Quotes';
import {
  AmountUnitTypeEnum,
  IQuoteItemRespSchema,
  IQuoteOfferingReqSchema,
  IQuoteRespSchema,
} from '~app/types';
import { getQuoteItemDiscountText } from '~app/utils';
import { toDateShort } from '~app/utils/dates';
import { QuoteItemDiscountEditPopover } from './QuoteItemDiscountEditPopover';

export interface QuoteItemDiscountInputOrValueProps {
  index: number;
  errors: any;
  isDisabled?: boolean;
  enableCustomDiscounts: boolean;
  control: Control<IQuoteOfferingReqSchema>;
  setValue: UseFormSetValue<IQuoteOfferingReqSchema>;
  itemFromServer: IQuoteItemRespSchema;
  quote: IQuoteRespSchema;
  customDiscountType?: AmountUnitTypeEnum | null;
  discountAmountRef: React.MutableRefObject<any>;
  onChangeDiscountType: (type: AmountUnitTypeEnum) => void;
  handleQuoteOffering: () => void;
  disableUnitTypeSelect?: boolean;
  disableUnitTypeMessage?: string;
  offeringId?: string;
}

export const QuoteItemDiscountInputOrValue = ({
  errors,
  isDisabled,
  enableCustomDiscounts,
  control,
  setValue,
  index,
  quote,
  customDiscountType,
  discountAmountRef,
  itemFromServer,
  onChangeDiscountType,
  handleQuoteOffering,
  disableUnitTypeSelect,
  disableUnitTypeMessage,
  offeringId,
}: QuoteItemDiscountInputOrValueProps) => {
  const customDiscountAmountOrPercent = useWatch({
    control,
    name: `items.${index}.customDiscountAmountOrPercent`,
  });

  const { offeringPeriod, billingFrequencyInMonths } = useMemo(() => {
    let offeringPeriod = '';
    let billingFrequencyInMonths = 12;
    const [filteredOffering] = quote.quoteOfferings.filter(
      (offering) => offering.id === offeringId,
    );

    if (filteredOffering) {
      offeringPeriod = `${toDateShort(
        filteredOffering?.startDate,
      )} - ${toDateShort(filteredOffering?.endDate)}`;
    }

    return {
      offeringPeriod,
      billingFrequencyInMonths,
    };
  }, [quote.quoteOfferings]);

  if (enableCustomDiscounts) {
    return (
      <MFlex data-testid="discount-cell" alignItems="center">
        <MFormField
          error={errors?.items?.[index]?.customDiscountAmountOrPercent}
          alignSelf="center"
        >
          <Controller
            control={control}
            name={`items.${index}.customDiscountAmountOrPercent`}
            render={({ field: { value, onChange, onBlur } }) => (
              <DiscountCell
                isDisabled={isDisabled}
                item={itemFromServer}
                quote={quote}
                discountType={customDiscountType}
                value={value ? value.toString() : ''}
                ref={discountAmountRef}
                onChangeAmountUnitType={onChangeDiscountType}
                onChange={(valueAsString: string, valueAsNumber: number) => {
                  onChange(valueAsString);
                }}
                onBlur={() => {
                  onBlur();
                  handleQuoteOffering();
                }}
                targetAmountOptions={
                  enableCustomDiscounts
                    ? {
                        amountWithoutDiscount:
                          itemFromServer.amountWithoutDiscount,
                        billingFrequencyInMonths,
                        contractLength: quote.contractLength,
                        currency: quote.currency,
                        discountPercent: itemFromServer.discountPercent,
                        offeringPeriod,
                        productName: itemFromServer.productName,
                        targetAmount: itemFromServer.amount,
                      }
                    : undefined
                }
                disableUnitTypeSelect={disableUnitTypeSelect}
                disableUnitTypeMessage={disableUnitTypeMessage}
              />
            )}
          />
        </MFormField>
        {customDiscountAmountOrPercent ? (
          <QuoteItemDiscountEditPopover
            control={control}
            errors={errors}
            setValue={setValue}
            index={index}
            disabled={isDisabled}
            itemFromServer={itemFromServer}
            handleQuoteOffering={handleQuoteOffering}
          />
        ) : (
          <MBox w={'24px'} />
        )}
      </MFlex>
    );
  }

  const discountTooltip = (
    <MFlex flexDirection="column">
      <MText textColor="white" fontStyle="italic">
        Discounts Applied:
      </MText>
      {(itemFromServer.discounts || []).map((d, i) => {
        const {
          discountName: name,
          discountType: type,
          discountAmountOrPercent: value,
        } = d;
        return (
          <MText key={`${name}-${i}`} textColor="white" fontWeight="bold">
            {`${name} - ${type === 'PERCENTAGE' ? `${value}%` : `$${value}`}`}
          </MText>
        );
      })}
    </MFlex>
  );

  const isTooltipDisabled =
    itemFromServer.discounts?.length === 0 || !itemFromServer.discountPercent;

  const discountText = getQuoteItemDiscountText(itemFromServer);

  return (
    <MTooltip
      isDisabled={isTooltipDisabled}
      label={discountTooltip}
      placement="bottom-start"
    >
      <MFlex _hover={{ cursor: isTooltipDisabled ? 'initial' : 'pointer' }}>
        <MText justifyItems="center" fontWeight="400" alignSelf="center">
          {discountText}
        </MText>
      </MFlex>
    </MTooltip>
  );
};
