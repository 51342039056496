import { FunctionComponent as FC, useMemo } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { formatCurrency } from '~app/utils';
import {
  MCustomNumberInput,
  MCustomSelect,
  MFormField,
  MGrid,
  MGridItem,
  MText,
} from '~components/Monetize';
import {
  IInvoiceRespSchema,
  PaymentMethodSummaryResponse,
  SharedInvoicePaymentExistingMethod,
} from '~types';
import { PaymentMethodItemRenderer } from '../../../components/PaymentMethods/PaymentMethodItemRenderer';
import { getGroupedAndSortedPaymentMethodOptions } from '../../../utils/payment.utils';

interface SharedInvoiceFormExistingPaymentMethodProps {
  allowPartialPayment: boolean;
  formState: UseFormReturn<SharedInvoicePaymentExistingMethod>;
  invoice: IInvoiceRespSchema;
  isDisabled?: boolean;
  paymentMethods: PaymentMethodSummaryResponse[];
}

export const SharedInvoiceFormExistingPaymentMethod: FC<
  SharedInvoiceFormExistingPaymentMethodProps
> = ({
  allowPartialPayment,
  formState,
  invoice,
  isDisabled,
  paymentMethods,
}: SharedInvoiceFormExistingPaymentMethodProps) => {
  const {
    control,
    formState: { errors },
  } = formState;

  const paymentMethodItems = useMemo(
    () => getGroupedAndSortedPaymentMethodOptions(paymentMethods),
    [paymentMethods],
  );

  return (
    <MGrid templateColumns="repeat(12, 1fr)" gap={4} my={3}>
      <MGridItem colSpan={12}>
        <MFormField
          isDisabled={isDisabled}
          error={errors?.amount}
          label="Amount"
          isRequired
        >
          <Controller
            name="amount"
            control={control}
            render={({ field: { onChange, ...field } }) =>
              allowPartialPayment ? (
                <MCustomNumberInput
                  precision={2}
                  isDisabled={isDisabled}
                  onChange={(_, valueAsNumber) => onChange(valueAsNumber)}
                  {...field}
                />
              ) : (
                <MText>
                  {formatCurrency(field.value, {
                    currency: invoice.currency,
                  })}
                </MText>
              )
            }
          />
        </MFormField>
      </MGridItem>
      <MGridItem colSpan={12}>
        <MFormField
          isDisabled={isDisabled}
          error={errors?.paymentMethodId}
          label="Payment Method"
          isRequired
        >
          <Controller
            name="paymentMethodId"
            control={control}
            render={({ field }) => (
              <MCustomSelect
                items={paymentMethodItems}
                itemTitle="name"
                itemValue="id"
                renderItemContent={PaymentMethodItemRenderer}
                {...field}
              />
            )}
          />
        </MFormField>
      </MGridItem>
    </MGrid>
  );
};
