import { useHotkeys, Options } from 'react-hotkeys-hook';

export const useCtrlEnterHotkey = (callback: () => void, deps: any[] = []) => {
  const hotKeyOptions: Options = {
    enableOnFormTags: ['INPUT', 'TEXTAREA', 'SELECT'],
  };
  // returning the ref and using the HTMLDivElement type not needed unless scoping this listener as per https://react-hotkeys-hook.vercel.app/docs/documentation/useHotkeys/scoping-hotkeys
  const ref = useHotkeys<HTMLDivElement>(
    'ctrl+enter, cmd+enter',
    () => {
      callback();
    },
    hotKeyOptions,
    deps,
  );
  return ref;
};

export const useEnterHotkey = (callback: () => void, deps: any[] = []) => {
  const hotKeyOptions: Options = {
    enableOnFormTags: ['INPUT', 'TEXTAREA', 'SELECT'],
  };
  // returning the ref and using the HTMLDivElement type not needed unless scoping this listener as per https://react-hotkeys-hook.vercel.app/docs/documentation/useHotkeys/scoping-hotkeys
  const ref = useHotkeys<HTMLDivElement>(
    'enter',
    () => {
      callback();
    },
    hotKeyOptions,
    deps,
  );
  return ref;
};
