import { Switch } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { handleApiErrorToast } from '~app/api/axios';
import {
  MAccordion,
  MAccordionCustomButton,
  MAccordionCustomButtonItem,
  MBox,
  MButton,
  MFlex,
  MFormField,
  MGrid,
  MGridItem,
  MStack,
} from '~app/components/Monetize';
import MEditor from '~app/components/Monetize/MEditor';
import { MSettingAccordionItem } from '~app/components/Monetize/MSettingAccordionItem';
import {
  IQuoteApprovalNoteSchema,
  QuoteApprovalNoteSchema,
} from '~app/types/quoteSettingsTypes';

interface ApprovalNoteProps {
  approvalMessage: IQuoteApprovalNoteSchema;
  onSubmit: (data: IQuoteApprovalNoteSchema) => Promise<void>;
}

const ApprovalNotes = (props: ApprovalNoteProps) => {
  const { approvalMessage, onSubmit } = props;
  const [isLoading, setIsLoading] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty, isValid },
    reset,
    watch,
  } = useForm<IQuoteApprovalNoteSchema>({
    resolver: zodResolver(QuoteApprovalNoteSchema),
    mode: 'onChange',
    defaultValues: {
      status: true,
      approvalNote: '',
    },
  });

  useEffect(() => {
    if (approvalMessage) {
      reset({
        ...approvalMessage,
        approvalNote: approvalMessage.approvalNote || '',
      });
    }
  }, [approvalMessage]);

  const handleSubmitNote = async (data: IQuoteApprovalNoteSchema) => {
    try {
      setIsLoading(true);
      await onSubmit(data);
    } catch (err: any) {
      handleApiErrorToast(err);
    } finally {
      setIsLoading(false);
    }
  };

  const formApprovalStatus = watch('status');

  return (
    <MStack w="100%" data-testid="approval-notes-config">
      <form
        onSubmit={handleSubmit(handleSubmitNote)}
        data-testid="quote-setting-form"
      >
        <MAccordion allowMultiple>
          <MSettingAccordionItem
            description="This message will show up before a quote goes to the review
                  screen."
            renderAccordionButton={({
              isExpanded,
            }: {
              isExpanded: boolean;
            }) => (
              <MAccordionCustomButton
                isExpanded={isExpanded}
                label="Approval Notes"
              >
                {!isExpanded ? (
                  <MAccordionCustomButtonItem
                    label="Status:"
                    value={formApprovalStatus ? 'Active' : 'Inactive'}
                    w="70px"
                  />
                ) : (
                  <MFormField
                    error={errors?.status}
                    onClick={(ev) => ev.stopPropagation()}
                  >
                    <Controller
                      name="status"
                      control={control}
                      render={({ field: { value, onChange, ...rest } }) => (
                        <Switch
                          isChecked={value}
                          onChange={(ev) => {
                            onChange(ev.target.checked);
                            handleSubmit(onSubmit)();
                          }}
                          {...rest}
                        />
                      )}
                    />
                  </MFormField>
                )}
              </MAccordionCustomButton>
            )}
          >
            <MBox mt="6">
              <MGrid templateColumns="repeat(12, 1fr)" gap={4}>
                <MGridItem colSpan={12}>
                  <MFormField error={errors.approvalNote}>
                    <Controller
                      name="approvalNote"
                      control={control}
                      render={({ field: { onChange, value, ...rest } }) => (
                        <MEditor
                          autoScroll
                          handleEditorChange={onChange}
                          boxProps={{ pb: 0, minHeight: 125 }}
                          init={{ height: 125 }}
                          disabled={!formApprovalStatus}
                          value={value || ''}
                          {...rest}
                        />
                      )}
                    />
                  </MFormField>
                </MGridItem>
              </MGrid>
              <MGridItem colSpan={12} mt="4">
                <MFlex justify="end">
                  <MButton
                    variant="primary"
                    onClick={handleSubmit(handleSubmitNote)}
                    isDisabled={
                      !isDirty || !isValid || !formApprovalStatus || isLoading
                    }
                    isLoading={isLoading}
                  >
                    Save
                  </MButton>
                </MFlex>
              </MGridItem>
            </MBox>
          </MSettingAccordionItem>
        </MAccordion>
      </form>
    </MStack>
  );
};

export default ApprovalNotes;
