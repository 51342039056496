import {
  TaxEnvironments,
  TaxIdentifierEnum,
  TaxProviderTypeEnum,
  TaxStatusEnum,
} from '~app/types/taxTypes';
import { objectToSelectOptions } from '~app/utils/misc';

export const TAX_IDENTIFIER_DISPLAY: {
  [key in TaxIdentifierEnum]: string;
} = {
  PRODUCT_ID: 'Product ID',
  TAX_ITEM_CODE: 'Tax Item Code',
  CUSTOM_ID: 'Custom ID',
  SKU: 'SKUs',
};

export const TAX_IDENTIFIER_SELECT_OPTIONS = objectToSelectOptions(
  TAX_IDENTIFIER_DISPLAY,
);

export const TAX_PROVIDER_DISPLAY: {
  [key in TaxProviderTypeEnum]: string;
} = {
  ANROK: 'Anrok',
  AVALARA: 'Avalara',
};

export const TAX_STATUS_DISPLAY: {
  [key in TaxStatusEnum]: string;
} = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
};

export const TAX_ENVIRONMENTS_DISPLAY: {
  [key in TaxEnvironments]: string;
} = {
  PRODUCTION: 'Production',
  SANDBOX: 'Sandbox',
};

export const TAX_ENVIRONMENTS_SELECT_OPTIONS = objectToSelectOptions(
  TAX_ENVIRONMENTS_DISPLAY,
);

export const TAX_ENTITY_USE_CODES = [
  { title: 'Agriculture', value: 'H' },
  { title: 'Charitable/Exempt Org', value: 'E' },
  { title: 'Commercial Aquaculture', value: 'P' },
  { title: 'Commercial Fishery', value: 'Q' },
  { title: 'Direct Mail', value: 'K' },
  { title: 'Direct Pay', value: 'J' },
  { title: 'Educational Org', value: 'M' },
  { title: 'Federal Gov', value: 'A' },
  { title: 'Foreign Diplomat', value: 'D' },
  { title: 'Industrial Prod/Manufacturers', value: 'I' },
  { title: 'Local Government', value: 'N' },
  { title: 'Non-Exempt Taxable Customer', value: 'TAXABLE' },
  { title: 'Non-Resident', value: 'R' },
  { title: 'Other/Custom', value: 'L' },
  { title: 'Religious Org', value: 'F' },
  { title: 'Resale', value: 'G' },
  { title: 'State Gov', value: 'B' },
  { title: 'Tribal Government', value: 'C' },
];
