import { apiGet } from './axios';
import { ApiQueryItem } from './queryUtils';
import { asQueryUtil } from './utils';

/**
 * Legal Entities
 */
export const legalEntitiesServiceQueryKeys = {
  base: ['legalEntities'] as const,
  legalEntityList: () =>
    [...legalEntitiesServiceQueryKeys.base, 'list'] as const,
  legalEntityDetail: (id: string) =>
    [...legalEntitiesServiceQueryKeys.base, id] as const,
};

const legalEntities: ApiQueryItem = {
  list: {
    endpoint: () => `/api/configurations/legalEntities`,
    queryKey: () => legalEntitiesServiceQueryKeys.legalEntityList(),
    byIdQueryKey: (id: string) =>
      legalEntitiesServiceQueryKeys.legalEntityDetail(id),
  },
  byId: {
    endpoint: (id: string) => `/api/configurations/legalEntities/${id}`,
    queryKey: (id: string) =>
      legalEntitiesServiceQueryKeys.legalEntityDetail(id),
  },
  create: {
    endpoint: () => `/api/configurations/legalEntities`,
    invalidateKeys: (id: string) => [
      legalEntitiesServiceQueryKeys.legalEntityList(),
    ],
    setDataKey: (id: string) =>
      legalEntitiesServiceQueryKeys.legalEntityDetail(id),
  },
  update: {
    endpoint: (id) => `/api/configurations/legalEntities/${id}`,
    invalidateKeys: (id: string) => [
      legalEntitiesServiceQueryKeys.legalEntityList(),
    ],
    setDataKey: (id: string) =>
      legalEntitiesServiceQueryKeys.legalEntityDetail(id),
  },
  delete: {
    endpoint: (id) => `/api/configurations/legalEntities/${id}`,
    invalidateKeys: (id: string) => [
      legalEntitiesServiceQueryKeys.legalEntityList(),
    ],
    setDataKey: (id: string) =>
      legalEntitiesServiceQueryKeys.legalEntityDetail(id),
  },
};

/**
 * Storage by Legal Entities
 */
export const storageByLegalEntityServiceQueryKeys = {
  base: ['storageByLegalEntity'] as const,
  legalEntityStorageDetail: (id: string) =>
    [...storageByLegalEntityServiceQueryKeys.base, id] as const,
};

const storageByLegalEntity: Required<
  Omit<ApiQueryItem, 'create' | 'update' | 'list'>
> = {
  byId: {
    endpoint: (id: string) => `/api/configurations/legalEntities/${id}/logo`,
    queryKey: (id: string) =>
      storageByLegalEntityServiceQueryKeys.legalEntityStorageDetail(id),
  },
  upload: {
    endpoint: (id) => `/api/configurations/legalEntities/${id}/logo`,
    invalidateKeys: (id: string) => [
      storageByLegalEntityServiceQueryKeys.legalEntityStorageDetail(id),
    ],
    setDataKey: (id: string) =>
      storageByLegalEntityServiceQueryKeys.legalEntityStorageDetail(id),
  },
  delete: {
    endpoint: (id) => `/api/configurations/legalEntities/${id}/logo`,
    invalidateKeys: (id: string) => [
      storageByLegalEntityServiceQueryKeys.legalEntityStorageDetail(id),
    ],
    setDataKey: (id: string) =>
      storageByLegalEntityServiceQueryKeys.legalEntityStorageDetail(id),
  },
};

export const LEGAL_ENTITY_SERVICE_API = asQueryUtil({
  legalEntities,
  storageByLegalEntity,
});

export const doGetDefaultLegalEntity = async () => {
  const res = await apiGet(
    '/api/configurations/legalEntities?defaultEntity=true',
  );
  return res.data;
};
