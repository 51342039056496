import { Link } from '@chakra-ui/react';
import isString from 'lodash/isString';
import { MAlert, MText } from '~components/Monetize';

interface PendingVerificationBannerProps {
  requiresVerification:
    | boolean
    | {
        verificationUrl: string;
      };
}

export const PendingVerificationBanner = ({
  requiresVerification,
}: PendingVerificationBannerProps) => {
  if (!requiresVerification) {
    return null;
  }

  return (
    <MAlert type="info">
      <MText>
        This payment requires additional verification. Check your email for
        further instructions.
      </MText>
      <MText mt={2}>
        Once your verification is complete, return to this page to finish making
        the payment for this invoice.
      </MText>
      <MText mt={2}>
        {isString(requiresVerification) && (
          <Link
            ref={requiresVerification}
            rel="noopener noreferrer"
            target="_blank"
          >
            Go to verification page
          </Link>
        )}
      </MText>
    </MAlert>
  );
};
