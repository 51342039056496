import { z } from 'zod';
import { objectToSelectOptions } from '../utils/misc';

export interface IQuickBook {
  companyId: string;
  refundLineItemId?: string;
}

export interface AccountingConnectionsResponse {
  quickbooks: IQuickBook | null;
  netsuite: INetsuite | null;
}

export enum QuickBookEnvEnum {
  SANDBOX = 'sandbox',
  PRODUCTION = 'production',
}
export const QuickBookEnvEnumZ = z.nativeEnum(QuickBookEnvEnum);

export const QuickBookSchema = z.object({
  environment: QuickBookEnvEnumZ,
});

export type IQuickBookSchema = z.infer<typeof QuickBookSchema>;

export enum NetsuiteCustomerLookupEnum {
  id = 'id',
  customId = 'customId',
  accountName = 'accountName',
  NONE = 'NONE', // This is only for form validation, never used on actual backend
}
export const NetsuiteCustomerLookupEnumZ = z.nativeEnum(
  NetsuiteCustomerLookupEnum,
);

export const NetsuiteCustomerLookupEnumDisplay: Record<
  NetsuiteCustomerLookupEnum,
  string
> = {
  NONE: 'None',
  accountName: 'Account Name',
  customId: 'Custom ID',
  id: 'MonetizeNow Account ID',
};
export const NETSUITE_CUSTOMER_LOOKUP_OPTIONS = objectToSelectOptions(
  NetsuiteCustomerLookupEnumDisplay,
);

export enum NetsuiteProductExtIdEnum {
  // sku = 'sku', // FIXME: this is broken on BE
  id = 'id',
}
export const NetsuiteProductExtIdEnumZ = z.nativeEnum(NetsuiteProductExtIdEnum);

export const NetsuiteProductExtIdEnumDisplay: Record<
  NetsuiteProductExtIdEnum,
  string
> = {
  id: 'MonetizeNow Product ID',
  // sku: 'SKU', // FIXME: this is broken on BE
};
export const NETSUITE_PRODUCT_EXT_ID_OPTIONS = objectToSelectOptions(
  NetsuiteProductExtIdEnumDisplay,
);

export const NetsuiteSchema = z.object({
  accountId: z.string(),
  consumerKey: z.string(),
  consumerSecret: z.string(),
  tokenId: z.string(),
  tokenSecret: z.string(),
  customerLookup: NetsuiteCustomerLookupEnumZ.nullish(),
  netsuiteProductExternalId: NetsuiteProductExtIdEnumZ,
  taxItemId: z.string().nullish(),
  apiVersion: z.string().nullish(),
});

export type INetsuite = z.infer<typeof NetsuiteSchema>;

export const QuickBookRefundSyncSchema = z.object({
  productId: z.string(),
});

export type IQuickBookRefundSyncSchema = z.infer<
  typeof QuickBookRefundSyncSchema
>;
