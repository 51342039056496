import {
  AccordionButton,
  AccordionItemProps,
  FormLabel,
  Icon,
} from '@chakra-ui/react';
import { FC } from 'react';
import { MdExpandMore, MdChevronRight } from 'react-icons/md';
import {
  MAccordionItem,
  MAccordionPanel,
  MBox,
  MFlex,
  MIcon,
  MText,
  MTooltip,
} from '~app/components/Monetize';

interface QuoteBodyAccordionProps extends AccordionItemProps {
  title: string;
  children?: React.ReactNode;
  icon: any;
  /**
   * Hides the expand icon on right side of accoridon button
   */
  hideExpandIcon?: boolean;
  /**
   * Add detail content on right side of accordion button
   */
  rightContent?: React.ReactNode;
  /**
   * Hides the panel content
   */
  hidePanel?: boolean;
}

export const QuoteBodyAccordion: FC<QuoteBodyAccordionProps> = ({
  title,
  children,
  icon,
  hideExpandIcon,
  rightContent,
  hidePanel,
  ...rest
}: QuoteBodyAccordionProps) => {
  return (
    <MAccordionItem border="none" mb={hidePanel ? '0' : '2'} {...rest}>
      {({ isExpanded }) => (
        <>
          <h2>
            <AccordionButton onClick={() => {}}>
              <MFlex alignItems="center" alignContent="spread" w="full">
                <MFlex textAlign="left" alignItems="center" py={3} flexGrow={1}>
                  <MIcon as={icon} boxSize="1.5rem" />
                  <MText fontWeight="600" fontSize="lg" ml={2}>
                    {title}
                  </MText>
                </MFlex>
                <MFlex alignItems="center">
                  {rightContent}
                  {!hideExpandIcon && (
                    <MFlex alignItems="center">
                      {isExpanded ? (
                        <Icon boxSize="1.5rem" as={MdExpandMore} />
                      ) : (
                        <Icon boxSize="1.5rem" as={MdChevronRight} />
                      )}
                    </MFlex>
                  )}
                </MFlex>
              </MFlex>
            </AccordionButton>
          </h2>
          {!hidePanel && <MAccordionPanel p="6">{children}</MAccordionPanel>}
        </>
      )}
    </MAccordionItem>
  );
};
