import { FlexProps, TextProps } from '@chakra-ui/react';
import React from 'react';
import { LegalEntityAddressFormatEnum } from '~app/types/legalEntityTypes';
import { getAddress } from '~app/utils/address';
import { MFlex, MText } from '~components/Monetize';
import { IContactRespSchema } from '~types';

interface ContactAddressProps extends FlexProps {
  contact: IContactRespSchema;
  omitProperties?: (keyof IContactRespSchema)[];
  textProps?: TextProps;
  addressFormat?: LegalEntityAddressFormatEnum | null;
}

export const ContactAddress = React.forwardRef(
  (
    {
      contact,
      omitProperties = [],
      textProps,
      addressFormat = LegalEntityAddressFormatEnum.US_FORMAT,
      ...rest
    }: ContactAddressProps,
    ref,
  ) => {
    const omitPropertiesSet = new Set(omitProperties);

    const { title, fullName, email, address } = contact;
    const { addressLine1, addressLine2, otherAddress } = getAddress(address, {
      addressFormat,
    });

    return (
      <MFlex flexDirection="column" {...rest}>
        {!omitPropertiesSet.has('fullName') && (
          <MText noOfLines={1} {...textProps}>
            {fullName}
          </MText>
        )}
        {!omitPropertiesSet.has('title') && (
          <MText noOfLines={1} {...textProps}>
            {title}
          </MText>
        )}
        {!omitPropertiesSet.has('email') && (
          <MText noOfLines={1} {...textProps}>
            {email}
          </MText>
        )}
        {!omitPropertiesSet.has('address') && (
          <>
            <MText noOfLines={1} {...textProps}>
              {addressLine1}
            </MText>
            <MText noOfLines={1} {...textProps}>
              {addressLine2}
            </MText>
          </>
        )}
        {!omitPropertiesSet.has('address') && (
          <MText noOfLines={1} {...textProps}>
            {otherAddress}
          </MText>
        )}
      </MFlex>
    );
  },
);
