export const MigratedQuoteIcon = (props: any) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="path-1-outside-1_1145_1353"
        maskUnits="userSpaceOnUse"
        x="3.46069"
        y="2.375"
        width="11"
        height="13"
        fill="black"
      >
        <rect fill="white" x="3.46069" y="2.375" width="11" height="13" />
        <path d="M5.53277 14.625C5.24326 14.625 4.99221 14.5187 4.7796 14.3061C4.567 14.0935 4.46069 13.8424 4.46069 13.5529V4.46065C4.46069 4.16209 4.567 3.90651 4.7796 3.69391C4.99221 3.4813 5.24326 3.375 5.53277 3.375H10.4453L13.5394 6.46909V13.5529C13.5394 13.8424 13.4331 14.0935 13.2205 14.3061C13.0079 14.5187 12.7523 14.625 12.4538 14.625H5.53277ZM10.4514 6.98477C10.1408 6.98477 9.88892 6.73293 9.88892 6.42227V4.46065H5.53277V13.5529H12.4538V6.98477H10.4514Z" />
      </mask>
      <path
        d="M5.53277 14.625C5.24326 14.625 4.99221 14.5187 4.7796 14.3061C4.567 14.0935 4.46069 13.8424 4.46069 13.5529V4.46065C4.46069 4.16209 4.567 3.90651 4.7796 3.69391C4.99221 3.4813 5.24326 3.375 5.53277 3.375H10.4453L13.5394 6.46909V13.5529C13.5394 13.8424 13.4331 14.0935 13.2205 14.3061C13.0079 14.5187 12.7523 14.625 12.4538 14.625H5.53277ZM10.4514 6.98477C10.1408 6.98477 9.88892 6.73293 9.88892 6.42227V4.46065H5.53277V13.5529H12.4538V6.98477H10.4514Z"
        fill="#6478FF"
      />
      <path
        d="M5.50914 4.46065V6.98477H5.55639V4.46065H5.50914ZM10.4453 3.375L10.4787 3.34159L10.4649 3.32775H10.4453V3.375ZM13.5394 6.46909H13.5867V6.44952L13.5728 6.43568L13.5394 6.46909ZM9.88892 4.46065H9.93617V4.4134H9.88892V4.46065ZM5.53277 4.46065V4.4134H5.48552V4.46065H5.53277ZM5.53277 13.5529H5.48552V13.6002H5.53277V13.5529ZM12.4538 13.5529V13.6002H12.501V13.5529H12.4538ZM12.4538 6.98477H12.501V6.93752H12.4538V6.98477ZM5.53277 14.5778C5.25632 14.5778 5.01714 14.4768 4.81301 14.2727L4.74619 14.3395C4.96728 14.5606 5.2302 14.6722 5.53277 14.6722V14.5778ZM4.81301 14.2727C4.60889 14.0686 4.50794 13.8294 4.50794 13.5529H4.41344C4.41344 13.8555 4.52511 14.1184 4.74619 14.3395L4.81301 14.2727ZM4.50794 13.5529V4.46065H4.41344V13.5529H4.50794ZM4.50794 4.46065C4.50794 4.17472 4.6092 3.93113 4.81301 3.72732L4.74619 3.6605C4.52479 3.88189 4.41344 4.14946 4.41344 4.46065H4.50794ZM4.81301 3.72732C5.01714 3.52319 5.25632 3.42225 5.53277 3.42225V3.32775C5.2302 3.32775 4.96728 3.43941 4.74619 3.6605L4.81301 3.72732ZM5.53277 3.42225H10.4453V3.32775H5.53277V3.42225ZM10.4119 3.40841L13.506 6.5025L13.5728 6.43568L10.4787 3.34159L10.4119 3.40841ZM13.4922 6.46909V13.5529H13.5867V6.46909H13.4922ZM13.4922 13.5529C13.4922 13.8294 13.3912 14.0686 13.1871 14.2727L13.2539 14.3395C13.475 14.1184 13.5867 13.8555 13.5867 13.5529H13.4922ZM13.1871 14.2727C12.9833 14.4765 12.7397 14.5778 12.4538 14.5778V14.6722C12.7649 14.6722 13.0325 14.5609 13.2539 14.3395L13.1871 14.2727ZM12.4538 14.5778H5.53277V14.6722H12.4538V14.5778ZM9.93617 6.42227V4.46065H9.84167V6.42227H9.93617ZM9.88892 4.4134H5.53277V4.5079H9.88892V4.4134ZM5.48552 4.46065V13.5529H5.58002V4.46065H5.48552ZM5.53277 13.6002H12.4538V13.5057H5.53277V13.6002ZM12.501 13.5529V6.98477H12.4065V13.5529H12.501ZM12.4538 6.93752H10.4514V7.03202H12.4538V6.93752ZM9.84167 6.42227C9.84167 6.75903 10.1147 7.03202 10.4514 7.03202V6.93752C10.1669 6.93752 9.93617 6.70684 9.93617 6.42227H9.84167Z"
        fill="#6478FF"
        mask="url(#path-1-outside-1_1145_1353)"
      />
      <path
        d="M9.5625 11.25L9.16875 10.8422L10.1672 9.84375H6.75V9.28125H10.1672L9.16875 8.28281L9.5625 7.875L11.25 9.5625L9.5625 11.25Z"
        fill="#6478FF"
      />
    </svg>
  );
};
