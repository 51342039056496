import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const baseStyle = defineStyle({
  lineHeight: '1.2',
  borderRadius: 'sm',
  fontWeight: 'bold',
  _focus: {
    boxShadow: 'none !important',
  },
});

const variants = {
  primary: defineStyle({
    bg: 'tIndigo.base',
    color: 'white',
    _hover: {
      bg: 'tPurple.base',
      _disabled: {
        bg: 'tGray.lightPurple',
      },
    },
    _disabled: {
      opacity: 1,
      bg: 'tGray.lightPurple',
      color: 'tGray.darkGrayPurple',
    },
    _focus: {
      boxShadow: '0px 2px 8px 0px #18243C66 !important',
    },
  }),
  secondary: defineStyle({
    bg: 'tWhite.base',
    color: 'tRoyalBlue.base',
    border: '1px solid',
    borderColor: 'tRoyalBlue.base',
    _hover: {
      bg: 'tBlue.hover',
      _disabled: {
        bg: 'tGray.darkPurple',
      },
    },
    _disabled: {
      bg: 'tGray.darkPurple',
      color: 'tGray.lightPurple',
    },
    _focus: {
      boxShadow: '0px 2px 8px 0px #18243C66 !important',
    },
  }),
  tertiary: defineStyle({
    bg: 'transparent',
    border: 'none',
    color: 'tRoyalBlue.base',
    _hover: {
      bg: 'tBlue.hover',
    },
    _active: {
      bg: 'tBlue.lightShade',
    },
    _disabled: {
      color: 'tGray.darkPurple',
    },
    _focus: {
      bg: 'tBlue.lightShade',
    },
  }),
  cancel: defineStyle({
    border: '1px solid',
    borderColor: 'tGray.darkGrayPurple',
    color: 'tGray.darkGrayPurple',
    _hover: {
      bg: 'tGray.back',
      borderColor: 'tPurple.base',
      color: 'tPurple.base',
    },
    _active: {
      color: 'tRoyalBlue.base',
    },
    _disabled: {
      color: 'tGray.lightPurple',
    },
    _focus: {
      boxShadow: '0px 2px 8px 0px #18243C66 !important',
    },
  }),
  icon: {
    color: 'tPurple.base',
    background: 'none',
    _focus: {
      background: 'tGray.lightPurple',
    },
    _active: {
      background: 'tGray.lightPurple',
    },
    _hover: {
      background: 'tBlue.hover',
    },
    _disabled: {
      color: 'tGray.darkGrayPruple',
      _focus: {
        background: 'none',
      },
      _active: {
        background: 'none',
      },
      _hover: {
        background: 'none',
      },
    },
  },
  delete: defineStyle({
    bg: 'tRed.base',
    color: 'white',
    _hover: {
      bg: 'tRed.hover',
    },
    _disabled: {
      bg: 'tGray.darkPurple',
      color: 'tGray.lightPurple',
      _hover: {
        bg: '#606070 !important',
      },
    },
    _focus: {
      boxShadow: '0px 2px 8px 0px #18243C66 !important',
    },
  }),
  deleteOutline: defineStyle({
    bg: 'white',
    color: 'tRed.base',
    border: '1px solid',
    borderColor: 'tRed.base',
    borderRadius: '2px',
    _hover: {
      bg: 'tRed.cosmos',
    },
    _disabled: {
      border: 'none',
      bg: 'tGray.darkPurple',
      color: 'tGray.lightPurple',
    },
    _focus: {
      boxShadow: '0px 2px 8px 0px #18243C66 !important',
    },
  }),
  underline: defineStyle({
    fontWeight: 400,
    bg: 'transparent',
    border: 'none',
    color: 'tIndigo.base',
    padding: 0,
    margin: 0,
    minW: 'initial',
    _hover: {
      textDecoration: 'underline',
    },
    _focus: {
      textDecoration: 'underline',
    },
  }),

  plain: defineStyle({
    bg: 'transparent',
    border: 'none',
    color: 'tPurple.base',
    paddingRight: '8px',
    paddingLeft: '12px',
    _hover: {
      bg: 'tBlue.hover',
      color: 'tIndigo.base',
    },
    _active: {
      bg: 'tBlue.lightShade',
    },
    _disabled: {
      color: 'tGray.darkPurple',
    },
    _focus: {
      bg: 'tBlue.hover',
      color: 'tIndigo.base',
    },
    fontWeight: '400',
  }),
};

const sizes = {
  lg: defineStyle({
    h: '40px',
    minW: '150px',
    fontSize: 'lg',
  }),
  md: defineStyle({
    h: '32px',
    minW: '120px',
    fontSize: 'sm',
  }),
  sm: defineStyle({
    h: 8,
    minW: 8,
    fontSize: 'sm',
  }),
  xs: defineStyle({
    h: 6,
    minW: 6,
    fontSize: 'xs',
  }),
};

export const buttonTheme = defineStyleConfig({
  baseStyle,
  variants: {
    ...variants,
    link: variants.tertiary,
  },
  sizes,
  defaultProps: {
    variant: 'primary',
    size: 'md',
  },
});

export default buttonTheme;
