import {
  Control,
  Controller,
  FieldErrors,
  useFieldArray,
  UseFormWatch,
} from 'react-hook-form';
import {
  MBox,
  MCustomNumberInput,
  MFlex,
  MFormField,
  MText,
} from '~app/components/Monetize';
import { IPaymentGatewayReqUISchema } from '~app/types';
import { PaymentMethodTypeEnumDisplay } from '../../../../../constants/paymentMethods';

interface StripeFormProps {
  control: Control<IPaymentGatewayReqUISchema>;
  errors: FieldErrors<IPaymentGatewayReqUISchema>;
  watch: UseFormWatch<IPaymentGatewayReqUISchema>;
}

export const StripeFormAmountConfiguration = ({
  control,
  errors,
  watch,
}: StripeFormProps) => {
  const usBankTransferEnabled = watch('usBankTransferEnabled');

  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      control,
      name: 'paymentConfiguration.configurations',
    },
  );

  return (
    <>
      <MText fontSize="lg" fontWeight="bold" mt={2}>
        Payment Amount Configuration
      </MText>
      <MText fontSize="sm">Configure the payment amounts allowed.</MText>

      {fields.map((field, index) => (
        <MBox key={field.id} mt={2}>
          <MText fontSize="md" fontWeight="bold" mb={2}>
            {PaymentMethodTypeEnumDisplay[field.type]}
          </MText>

          {field.amountConfigurations.map(
            ({ currency, maximum, minimum }, fieldIndex) => (
              <MFlex key={currency} alignItems="center">
                <MText fontSize="sm" fontWeight="bold" mt={8} mr={2}>
                  {currency}
                </MText>
                <MFormField
                  error={
                    errors.paymentConfiguration?.configurations?.[index]
                      ?.amountConfigurations?.[fieldIndex]?.minimum
                  }
                  label="Minimum Payment Amount"
                  w="100"
                >
                  <Controller
                    name={`paymentConfiguration.configurations.${index}.amountConfigurations.${fieldIndex}.minimum`}
                    control={control}
                    render={({ field: { value, ...rest } }) => (
                      <MFlex key={currency} alignItems="center">
                        <MCustomNumberInput
                          inputMode="numeric"
                          value={value ?? ''}
                          w="175px"
                          isDisabled={!usBankTransferEnabled}
                          {...rest}
                        />
                      </MFlex>
                    )}
                  />
                </MFormField>
                <MFormField
                  error={
                    errors.paymentConfiguration?.configurations?.[index]
                      ?.amountConfigurations?.[fieldIndex]?.maximum
                  }
                  label="Maximum Payment Amount"
                  w="100"
                  ml={4}
                >
                  <Controller
                    name={`paymentConfiguration.configurations.${index}.amountConfigurations.${fieldIndex}.maximum`}
                    control={control}
                    render={({ field: { value, ...rest } }) => (
                      <MFlex key={currency} alignItems="center">
                        <MCustomNumberInput
                          inputMode="numeric"
                          value={value ?? ''}
                          w="175px"
                          isDisabled={!usBankTransferEnabled}
                          {...rest}
                        />
                      </MFlex>
                    )}
                  />
                </MFormField>
              </MFlex>
            ),
          )}
        </MBox>
      ))}
    </>
  );
};
