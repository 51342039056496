import { Checkbox, Flex, Text, VStack } from '@chakra-ui/react';
import isObject from 'lodash/isObject';
import { MAvatar } from '../../MAvatar';

type MInputSearchTagCheckBoxProps = {
  data: any;
  selectedData: any;
  displayAvatar: boolean;
  labelField: string;
  handleCheckboxCheck: any;
  handleDisableCheckbox?: (item: any) => boolean;
};

export const MInputSearchTagCheckBox = ({
  data,
  selectedData,
  displayAvatar,
  labelField,
  handleCheckboxCheck,
  handleDisableCheckbox,
}: MInputSearchTagCheckBoxProps) => {
  const getTitle = (item: any) => {
    return isObject(item) ? item[labelField as keyof typeof item] : item;
  };

  const checkboxes = data.map((item: any, index: number) => {
    const isSelected = selectedData.some((tag: any) => tag.id === item.id);

    return (
      <Flex
        h={8}
        key={`cb-${index}`}
        _hover={{ background: 'tBlue.hover', fontWeight: 'bold' }}
        cursor="pointer"
        px="2"
        alignItems="center"
      >
        <Checkbox
          onChange={(event) => {
            handleCheckboxCheck && handleCheckboxCheck(item, event);
          }}
          mt="0"
          w="100%"
          isChecked={isSelected}
          isDisabled={handleDisableCheckbox && handleDisableCheckbox(item)}
          fontWeight={(isSelected as any) && 'bold'}
          variant="custom-select"
        >
          <Flex>
            {displayAvatar && <MAvatar name={item.name} />}
            <Text
              color="tPurple.dark"
              noOfLines={1}
              fontSize="sm"
              ml="2"
              textOverflow="ellipsis"
              maxW="370px"
            >
              {getTitle(item)}
            </Text>
          </Flex>
        </Checkbox>
      </Flex>
    );
  });

  if (data.length === 0) {
    return null;
  }
  return (
    <VStack align="left" spacing="0">
      {checkboxes}
    </VStack>
  );
};
