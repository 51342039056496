import { BoxProps } from '@chakra-ui/react';
import { Controller, useFormContext, useFormState } from 'react-hook-form';
import { MBox, MFormField, MText } from '~app/components/Monetize';
import MEditor from '~app/components/Monetize/MEditor';
import { QuoteStatusEnum } from '../../../../types';
import { useQuoteContext } from '../quoteContext';

export interface QuoteFormTermsProps extends BoxProps {}

const QuoteFormTerms = (props: QuoteFormTermsProps) => {
  const {
    quoteData: { quote },
    handleSubmitButtonWithoutDirtyCheck,
    isReadOnly,
  } = useQuoteContext();

  const { control } = useFormContext();
  const { errors } = useFormState({
    control,
  });

  return (
    <MBox {...props}>
      <MText fontSize="lg" fontWeight="bold" mb={4}>
        Terms and Conditions
      </MText>
      <MFormField isDisabled={!quote} error={errors.contractTerms}>
        <Controller
          name="contractTerms"
          control={control}
          render={({ field: { onChange, onBlur, ...fields } }) => (
            <MEditor
              autoScroll
              disabled={
                !quote || isReadOnly || quote.status !== QuoteStatusEnum.DRAFT
              }
              handleEditorChange={onChange}
              onBlur={() => {
                handleSubmitButtonWithoutDirtyCheck();
                onBlur();
              }}
              boxProps={{ pb: 0, minH: 200, w: '100%' }}
              {...fields}
            />
          )}
        />
      </MFormField>
    </MBox>
  );
};

export default QuoteFormTerms;
