import {
  QueryClient,
  QueryClientConfig,
  QueryClientProvider,
} from '@tanstack/react-query';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { createRoot } from 'react-dom/client';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import RecoilNexus from 'recoil-nexus';
import { ConfirmModalProvider } from '~services/confirmModal';
import { DocumentHeadProvider } from '~services/documentHead';
import { ToastProvider } from '~services/toast';
import AppWrapper from './AppWrapper';
import { ACLProvider } from './services/acl/acl';
import { MAuth0Wrapper } from './services/auth0';
import { ENABLE_LAUNCHDARKLY, LDProviderConfig } from './services/launchDarkly';
import MChakraProvider from './styles/MChakraProvider';
import { ReactQueryDevtoolsWrapper } from './utils/ReactQueryDevtoolsWrapper';
// eslint-disable-next-line no-restricted-imports
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { SentryErrorBoundary } from './components/Sentry/SentryErrorBoundary';

const container = document.getElementById('root');
const root = createRoot(container!);

const queryClientConfig: QueryClientConfig = {
  defaultOptions: {
    queries: {
      retry: 1,
      /**
       * There is a Chrome bug that impacts some users
       * {@link https://github.com/TanStack/query/issues/4772}
       * {@link https://bugs.chromium.org/p/chromium/issues/detail?id=678075}
       */
      networkMode: 'always',
    },
    mutations: {
      networkMode: 'always',
    },
  },
};

const queryClient = new QueryClient(queryClientConfig);

const WrappedApp = () => (
  <RecoilRoot>
    {/* Small library to allow updating state outside react component */}
    <RecoilNexus />
    <Router>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtoolsWrapper />
        <MChakraProvider>
          <MAuth0Wrapper>
            <ToastProvider>
              <ConfirmModalProvider>
                <ACLProvider>
                  <DocumentHeadProvider>
                    <DndProvider backend={HTML5Backend}>
                      <SentryErrorBoundary>
                        <AppWrapper />
                      </SentryErrorBoundary>
                    </DndProvider>
                  </DocumentHeadProvider>
                </ACLProvider>
              </ConfirmModalProvider>
            </ToastProvider>
          </MAuth0Wrapper>
        </MChakraProvider>
      </QueryClientProvider>
    </Router>
  </RecoilRoot>
);

(async () => {
  const LDProvider = await asyncWithLDProvider(LDProviderConfig);

  const App = ENABLE_LAUNCHDARKLY ? (
    <LDProvider>
      <WrappedApp />
    </LDProvider>
  ) : (
    <WrappedApp />
  );

  root.render(App);
})();
