import { BoxProps, Spinner } from '@chakra-ui/react';
import { FC } from 'react';
import { MBox } from '~components/Monetize/chakra';

type MPageLoaderProp = BoxProps;

const MPageLoader: FC<MPageLoaderProp> = (props: MPageLoaderProp) => (
  <MBox
    height="100vh"
    display="flex"
    alignItems="center"
    justifyContent="center"
    {...props}
  >
    <Spinner />
  </MBox>
);

export default MPageLoader;
