import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useResendVerificationLink } from '~app/api/usersService';
import {
  MBox,
  MButton,
  MHeading,
  MLink,
  MPageContainer,
  MText,
} from '~app/components/Monetize';
import { useAuth } from '~app/services/auth0';
import { useToast } from '~app/services/toast';
import AppLoading from '~components/App/AppLoading';
import { ROUTES } from '~constants';

const VerifyEmail = () => {
  const { getAndHandleAccessTokenSilently } = useAuth();
  const { user, getDecodedToken } = useAuth();
  const { addToast } = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    const run = async () => {
      await getAndHandleAccessTokenSilently();
      if (user && user.email_verified) {
        navigate(ROUTES.ROOT);
      }
    };

    run();
  }, [user]);

  const { isLoading, mutate } = useResendVerificationLink({
    onSuccess: () => {
      addToast({
        summary: 'Email Sent',
        detail: `Verification email has been sent to ${user.email}.`,
        severity: 'info',
      });
    },
    onError: (error) => {
      addToast({
        severity: 'error',
        summary: 'Error',
        detail: 'Failed to send verification email. Please try again later.',
      });
    },
  });

  const resendVerificationLink = async () => {
    const tokenDecoded = getDecodedToken();
    tokenDecoded && mutate({ subject: tokenDecoded.sub });
  };

  if (!user) {
    return <AppLoading />;
  }
  if (user && user.email_verified) {
    return null;
  }

  return (
    <MPageContainer alignItems="center" justifyContent="center">
      <MHeading fontSize="xl" my="2">
        Verify Your Email Address
      </MHeading>
      <MText fontSize="sm" color="tGray.darkPurple">
        A verification link has been sent to <b>{user.email}</b>
      </MText>
      <MButton
        variant="primary"
        my="2"
        mt="4"
        onClick={resendVerificationLink}
        isLoading={isLoading}
      >
        Resend Verification Link
      </MButton>

      <MBox my="2" display="flex" alignItems="center">
        <MText mr="2">Not the right user?</MText>
        <MLink
          fontSize="sm"
          as={Link}
          to={ROUTES.AUTH_LOGOUT}
          textDecor="underline"
        >
          Log out
        </MLink>
      </MBox>
    </MPageContainer>
  );
};

export default VerifyEmail;
