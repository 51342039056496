import { Tab } from '@chakra-ui/react';
import useResizeObserver from '@react-hook/resize-observer';
import type { PDFDocumentProxy } from 'pdfjs-dist/types/src/display/api';
import React, {
  FC,
  RefObject,
  Suspense,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { MdOutlineFileDownload } from 'react-icons/md';
import { TextLayerItemInternal } from 'react-pdf/dist/Page';
import { handleApiErrorToast } from '~app/api/axios';
import {
  MCustomIconButton,
  MDivider,
  MDrawer,
  MDrawerBody,
  MDrawerCloseButton,
  MDrawerContent,
  MDrawerHeader,
  MDrawerOverlay,
  MFlex,
  MPageLoader,
  MTabs,
  MText,
} from '~app/components/Monetize';
import { getSafeFilename } from '~app/utils';
import { downloadBlobAsFile } from '~app/utils/download';
import lazyWithPreload from '../../App/LazyLoadRetry';
import { PreviewPdfDrawerZoom } from './PreviewPdfDrawerZoom';
import './styles.scss';

// Lazy load PDF library - reduces bundle size and does not cause issues in unit test environment
const Document = lazyWithPreload(() =>
  import('react-pdf/dist/esm/entry.webpack5').then((module) => ({
    default: module.Document as any,
  })),
);

const Page = lazyWithPreload(() =>
  import('react-pdf/dist/esm/entry.webpack5').then((module) => ({
    default: module.Page as any,
  })),
);

/**
 * Highlights text in PDF
 *
 * https://github.com/wojtekmaj/react-pdf/wiki/Recipes#highlight-text-on-the-page
 * https://gist.github.com/wojtekmaj/f265f55e89ccb4ce70fbd2f8c7b1d95d
 *
 * @param text
 * @param pattern
 * @returns
 */
function highlightPattern(text: string, pattern: string | RegExp): any {
  const splitText = text.split(pattern);
  if (splitText.length <= 1) {
    return text;
  }

  const matches = text.match(pattern);
  return splitText.reduce((arr: any[], element, index) => {
    return matches && matches[index]
      ? [...arr, element, <mark key={index}>{matches[index]}</mark>]
      : [...arr, element];
  }, []);
}

interface PreviewPdfDrawerProps {
  /** Filename to use when pdf is downloaded */
  filename: string;
  /**
   * Highlight text that matches provided regex
   * /Docusign tags: /\/m[0-9a-z]{3}\//i
   * https://monetizenow.atlassian.net/wiki/spaces/PE/pages/1159004161/Docusign+Tabs
   */
  highlightTextPattern?: string | RegExp;
  isOpen: boolean;
  onCloseFocusRef?: RefObject<any>;
  /** Optional list of tabs to show below drawer header */
  tabs?: { label: string; value: string }[];
  /** Defaults to 0, can set if the initially active tab should be different */
  defaultTab?: number;
  /**
   * Optional content to show above PDF document
   * This will only render if the PDF document is loaded
   */
  children?: React.ReactNode;
  /** Fetch PDF document ArrayBuffer. If tabs are used, the active tab will be provided */
  fetchDocument: (tab?: string) => Promise<ArrayBuffer | null>;
  onClose: () => void;
}

/**
 * Side drawer for previewing PDF documents
 */
export const PreviewPdfDrawer: FC<PreviewPdfDrawerProps> = ({
  filename,
  isOpen,
  onCloseFocusRef,
  highlightTextPattern,
  tabs = [],
  defaultTab = 0,
  children,
  fetchDocument,
  onClose,
}) => {
  const drawerBodyRef = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(false);
  const [documents, setDocuments] = useState<{
    [key: string]: ArrayBuffer | null;
  }>({});
  const [numPages, setNumPages] = useState<number | null>(null);
  const [size, setSize] = useState<DOMRect>();
  const [zoom, setZoom] = useState(1);
  const [tabIndex, setTabIndex] = useState(defaultTab);

  // If browser is resized, the PDF will be adjusted to fit the new size
  useResizeObserver(drawerBodyRef, (entry) => setSize(entry.contentRect));

  useLayoutEffect(() => {
    if (drawerBodyRef.current) {
      const roundingRect = drawerBodyRef.current.getBoundingClientRect();
      roundingRect && setSize(roundingRect);
    }
  }, [drawerBodyRef]);
  const currentTabValue = tabs?.length ? tabs[tabIndex].value : 'blank';

  const document = documents[currentTabValue];

  useEffect(() => {
    if (!isOpen) {
      return;
    }
    (async () => {
      // Cache document response
      if (documents[currentTabValue]) {
        return;
      }

      try {
        setLoading(true);
        const pdfDocument = await fetchDocument(currentTabValue);
        setDocuments({
          ...documents,
          [currentTabValue]: pdfDocument || null,
        });
      } catch (ex) {
        handleApiErrorToast(ex);
      } finally {
        setLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, tabIndex]);

  /**
   * Allows highlighting of text within PDF
   * https://github.com/wojtekmaj/react-pdf/wiki/Recipes#highlight-text-on-the-page
   */
  const textRenderer = useCallback(
    (textItem: TextLayerItemInternal) => {
      if (!highlightTextPattern) {
        return textItem.str;
      }
      return highlightPattern(textItem.str, highlightTextPattern);
    },
    [highlightTextPattern],
  );

  const onDocumentLoadSuccess = (pdf: PDFDocumentProxy) => {
    setNumPages(pdf?.numPages || 0);
  };

  const downloadPdf = async () => {
    try {
      if (document) {
        downloadBlobAsFile(document, `${getSafeFilename(filename)}.pdf`);
      }
    } catch (err) {
      handleApiErrorToast(err);
    }
  };

  function handleTabChange(newTabIndex: number) {
    setZoom(1);
    setTabIndex(newTabIndex);
  }

  function handleClose() {
    setZoom(1);
    setLoading(false);
    setDocuments({});
    onClose();
  }

  return (
    <MDrawer
      isOpen={isOpen}
      size="xl"
      placement="right"
      onClose={handleClose}
      finalFocusRef={onCloseFocusRef}
    >
      <MDrawerOverlay />
      <MDrawerContent>
        <MDrawerCloseButton />
        <MDrawerHeader borderBottomWidth="1px">
          <MFlex justify="space-between" alignItems="center">
            <MText fontSize="inherit" fontWeight="inherit">
              Preview
            </MText>
            <PreviewPdfDrawerZoom zoom={zoom} onChange={setZoom} />
            <MFlex>
              <MCustomIconButton
                variant="icon"
                containerSize="6"
                btnSize={5}
                fontSize="lg"
                _focus={{ background: 'tGray.support' }}
                icon={MdOutlineFileDownload}
                onClick={() => downloadPdf()}
                color="tPurple.dark"
                iconColorHover="tPurple.base"
                mr={6}
                isDisabled={!document}
              />
            </MFlex>
          </MFlex>
        </MDrawerHeader>

        {!!tabs?.length && (
          <MTabs
            variant="centered"
            size="sm"
            defaultIndex={defaultTab}
            onChange={handleTabChange}
          >
            {tabs.map(({ label, value }) => (
              <Tab key={value}>{label}</Tab>
            ))}
          </MTabs>
        )}

        <MDrawerBody
          ref={drawerBodyRef}
          backgroundColor="tGray.sidebarDark"
          overflowX="scroll"
        >
          <Suspense
            fallback={
              <MFlex justify="center" grow={1}>
                <MPageLoader />
              </MFlex>
            }
          >
            {(loading || !document) && (
              <MFlex justify="center" grow={1}>
                <MPageLoader />
              </MFlex>
            )}
            {document && (
              <MFlex w="full" flexDir="column">
                {children}
                <Document
                  loading={
                    <MFlex justify="center" grow={1}>
                      <MPageLoader />
                    </MFlex>
                  }
                  file={{ data: document }}
                  onLoadSuccess={onDocumentLoadSuccess}
                >
                  {Array.from(new Array(numPages), (el, index) => (
                    <MFlex key={`page_${index + 1}`} p={1} justify="center">
                      <Page
                        customTextRenderer={textRenderer}
                        pageNumber={index + 1}
                        scale={zoom}
                        className="pdf-page"
                        height={
                          (size?.height ||
                            drawerBodyRef.current?.clientHeight ||
                            400) - 10
                        }
                      />
                    </MFlex>
                  ))}
                </Document>
              </MFlex>
            )}
          </Suspense>
        </MDrawerBody>
        <MDivider />
      </MDrawerContent>
    </MDrawer>
  );
};

export default PreviewPdfDrawer;
