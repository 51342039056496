import React, { FunctionComponent, PropsWithChildren } from 'react';
import { Tr, Td } from '@chakra-ui/table';
import { TotalRowCellProps } from '~app/types';
import { MFlex, MText } from '~components/Monetize';
import { MCurrency } from '~components/Monetize/MCurrency';

export const SubscriptionTotalCell: FunctionComponent<TotalRowCellProps> = (
  props: PropsWithChildren<TotalRowCellProps>,
) => {
  const { colSpan, name, nameProps, amountProps, amount } = props;
  return (
    <Tr>
      <Td colSpan={colSpan} border={0} />
      <Td
        colSpan={2}
        fontWeight="bold"
        fontSize="sm"
        color="tPurple.base"
        p="16px !important"
        border={0}
        background="tGray.support"
        borderRadius={7}
        {...nameProps}
      >
        <MFlex
          w="100%"
          flexDir="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <MText fontWeight={600} fontSize={18}>
            {name}
          </MText>
          <MCurrency
            amount={amount}
            textAlign="right"
            fontWeight={600}
            fontSize={18}
            data-testid={`Total ${name}`}
            options={amountProps}
          />
        </MFlex>
      </Td>
    </Tr>
  );
};

SubscriptionTotalCell.defaultProps = {
  colSpan: 4,
  nameProps: {},
};
