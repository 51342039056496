import { useDisclosure } from '@chakra-ui/react';
import DataTableActions from '~app/components/Monetize/DataTable/MDataTableActions';
import { useACL } from '~app/services/acl/acl';
import {
  IPayment,
  PaymentMethodTypeEnum,
  PaymentStatusEnum,
  TransactableSourceType,
} from '~types';
import { doPrintPaymentToPdf } from '../../../api/paymentsService';
import { TransactionApplicationModal } from '../../../components/TransactionApplication/TransactionApplicationModal';
import { useFlags } from '../../../services/launchDarkly';
import { downloadAndSaveToPDF } from '../../../utils';
import { UpdatePaymentModal } from '../../Invoices/createPayment/UpdatePaymentModal';
import { RefundModal } from './RefundModal';

interface PaymentActionsProps {
  accountId: string;
  payment: IPayment;
  refetch: () => void;
}

export const PaymentActions = ({
  accountId,
  payment,
  refetch,
}: PaymentActionsProps) => {
  const { allowRefunds } = useFlags();
  const { canDo } = useACL();
  const { newApplicationOfPaymentCredit } = useFlags();
  const refundModalState = useDisclosure();
  const updatePaymentModalState = useDisclosure();

  const canApplyPayment = canDo([['billing', 'create']]);
  const hasBillingUpdatePermissions = canDo([['billing', 'update']]);

  const applyEntityToInvoicesModalState = useDisclosure();

  const { paymentStatus, paymentType, refundDate } = payment;

  const actions = [
    {
      title: payment.appliedAmount
        ? 'Edit Payment Applications'
        : 'Apply Payment',
      enabled:
        newApplicationOfPaymentCredit &&
        canApplyPayment &&
        paymentStatus !== PaymentStatusEnum.FAILED,
      action: () => {
        applyEntityToInvoicesModalState.onOpen();
      },
    },
    {
      title: 'Update Payment',
      enabled:
        hasBillingUpdatePermissions &&
        !refundDate &&
        paymentStatus === PaymentStatusEnum.SUCCESS &&
        paymentType === PaymentMethodTypeEnum.MANUAL,
      action: () => {
        updatePaymentModalState.onOpen();
      },
    },
    {
      title: 'Refund',
      enabled:
        hasBillingUpdatePermissions &&
        paymentStatus === PaymentStatusEnum.SUCCESS &&
        (payment.unAppliedAmount ?? 0) > 0 && // checking if payment has unapplied amount
        allowRefunds,
      action: () => {
        refundModalState.onOpen();
      },
    },
    {
      title: 'Print to PDF',
      enabled: true,
      action: () => downloadAndSaveToPDF(() => doPrintPaymentToPdf(payment.id)),
    },
  ];

  if (!accountId || !payment) {
    return null;
  }

  return (
    <>
      <DataTableActions actions={actions} />
      {refundModalState.isOpen && payment && (
        <RefundModal
          isOpen
          onClose={(reload?: boolean) => {
            reload && refetch();
            refundModalState.onClose();
          }}
          payment={payment}
        />
      )}
      {updatePaymentModalState.isOpen && (
        <UpdatePaymentModal
          onClose={(reload?: boolean) => {
            reload && refetch();
            updatePaymentModalState.onClose();
          }}
          accountId={accountId}
          payment={payment}
        />
      )}
      {applyEntityToInvoicesModalState.isOpen && (
        <TransactionApplicationModal
          modalTitle={`Apply Payment to Invoice`}
          accountId={accountId}
          sourceId={payment.id}
          sourceType={TransactableSourceType.payment}
          sourceOriginalAmount={payment.amount}
          currency={payment.currency}
          onClose={() => applyEntityToInvoicesModalState.onClose()}
        />
      )}
    </>
  );
};
