import toNumber from 'lodash/toNumber';
import { parse, unparse } from 'papaparse';
import { FunctionComponent as FC, useState } from 'react';
import { FiDownload } from 'react-icons/fi';
import { v4 as uuidv4 } from 'uuid';
import { MFileDragDropUpload } from '~app/components/Monetize/MFileDragDropUpload';
import { ISO8601Format } from '~app/constants/dates';
import { logger } from '~app/services/logger';
import {
  IUsageEventRequestSchema,
  MimeTypeEnum,
  UsageEventRequestSchema,
} from '~app/types';
import { getFormattedDateUtc, parseDatetime } from '~app/utils/dates';
import { saveFile } from '~app/utils/download';
import { MAlert, MBox, MButton, MFlex, MIcon } from '~components/Monetize';

const CSV_FIELDS = [
  // Optional fields
  'id',
  'accountId',
  'subscriptionId',

  // Required fields
  'unitsConsumed',
  'timestamp',
  'ratedPrice',
  'usageTypeId',
];

interface UploadUsageInputProps {
  recordData: {
    accountId: string;
    subscriptionId: string;
  };
  onData: (filename: string, data: IUsageEventRequestSchema[]) => void;
}

/**
 * Allow user to upload a usage file and have the results processed and returned
 */
export const UploadUsageInput: FC<UploadUsageInputProps> = ({
  recordData,
  onData,
}: UploadUsageInputProps) => {
  const [fileError, setFileError] = useState(false);

  const handleDownloadTemplate = () => {
    const { accountId, subscriptionId } = recordData;
    const EXAMPLE_RECORD = {
      id: uuidv4().slice(0, 23),
      accountId,
      subscriptionId,
      unitsConsumed: '1',
      timestamp: getFormattedDateUtc(new Date(), ISO8601Format),
      usageTypeId: '',
    };
    const csv = unparse(
      {
        fields: CSV_FIELDS,
        data: [EXAMPLE_RECORD],
      },
      { header: true },
    );
    saveFile(csv, `usage-template.csv`, MimeTypeEnum.CSV);
  };

  const handleFileUpload = (file: File) => {
    setFileError(false);
    parse<Partial<IUsageEventRequestSchema>>(file, {
      header: true,
      transform: (value, header: string) => {
        if (header === 'unitsConsumed') {
          const numberValue = Number(value || 0) as number;
          return isNaN(numberValue) ? 0 : numberValue;
        } else if (header === 'timestamp') {
          return parseDatetime(value);
        } else if (header === 'ratedPrice') {
          return value ? toNumber(value) : null;
        }
        return value;
      },
      complete: ({ data, errors }) => {
        try {
          if (errors?.length) {
            // TODO: we can ignore blank rows - those errors should be ok
            throw new Error('Errors parsing file');
          }
          const results = data.map((row) => {
            return UsageEventRequestSchema.parse({
              id: row.id || uuidv4().slice(0, 23),
              accountId: row.accountId || recordData.accountId,
              subscriptionId: row.subscriptionId || recordData.subscriptionId,
              usageTypeId: row.usageTypeId,
              timestamp: row.timestamp,
              unitsConsumed: row.unitsConsumed || 0,
              ratedPrice: row.ratedPrice,
            });
          });
          logger.log('Parsed file', { results });
          onData(file.name, results);
        } catch (ex) {
          logger.warn('Error parsing usage file', ex);
          logger.log({ data, errors });
          setFileError(true);
        }
      },
    });
  };

  return (
    <MFlex flexDirection="column">
      {fileError && (
        <MAlert type="error">
          There was an error with the file you are trying to import. Try again.
        </MAlert>
      )}
      <MFileDragDropUpload
        p={5}
        mt={4}
        accept={['csv']}
        label="Drag and Drop your file here"
        subtitle="You can download our usage template to get started."
        onFileUpload={handleFileUpload}
        maxFileSize={10}
      />
      <MBox>
        <MButton
          variant="tertiary"
          size="sm"
          ml={-3}
          mt={4}
          onClick={handleDownloadTemplate}
        >
          <MIcon as={FiDownload} mr={2} />
          Download Template
        </MButton>
      </MBox>
    </MFlex>
  );
};
