import { Column, ColumnMeta, Table } from '@tanstack/react-table';
import { z } from 'zod';

export type SelectionOption = 'ALL' | 'SELECTED' | 'UNSELECTED';
export type BooleanOption = 'ALL' | 'CHECKED' | 'UNCHECKED';

export interface FilterProps<TData> {
  column: Column<any, unknown>;
  table: Table<TData>;
}

export type DataTableRef = {
  table: Table<any>;
} & DataTableFilterRef;

export type DataTableFilterLimit = Exclude<
  ColumnMeta<unknown, unknown>['filterVariant'],
  undefined
>;

export interface DataTableFilterRef {
  /**
   * Reset all filters
   */
  resetFilters(): void;
  /**
   * Set filter value for specific column
   */
  setFilterValue(options: { columnKey: string; value: unknown }): void;
}

export enum DateFilterComparison {
  IS_BLANK = 'IS_BLANK',
  IS_NOT_BLANK = 'IS_NOT_BLANK',
  EQUALS = 'EQUALS',
  GREATER_THAN = 'GREATER_THAN',
  GREATER_THAN_EQUAL = 'GREATER_THAN_EQUAL',
  LESS_THAN = 'LESS_THAN',
  LESS_THAN_EQUAL = 'LESS_THAN_EQUAL',
  BETWEEN_EXCLUSIVE = 'BETWEEN_EXCLUSIVE',
  BETWEEN_INCLUSIVE = 'BETWEEN_INCLUSIVE',
}

export const DateFilterDataSchema = z.object({
  comparison: z.nativeEnum(DateFilterComparison),
  firstDate: z.string().nullish(),
  secondDate: z.string().nullish().optional(),
});

export type DateFilterData = z.infer<typeof DateFilterDataSchema>;

export function isBlankNotBlankComparison(
  comparison: DateFilterComparison,
): boolean {
  return (
    comparison === DateFilterComparison.IS_BLANK ||
    comparison === DateFilterComparison.IS_NOT_BLANK
  );
}

export function isBetweenComparison(comparison: DateFilterComparison): boolean {
  return (
    comparison === DateFilterComparison.BETWEEN_EXCLUSIVE ||
    comparison === DateFilterComparison.BETWEEN_INCLUSIVE
  );
}
