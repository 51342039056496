export const EMAIL_PLACEHOLDER = 'email@address.com';
export const PHONE_NUMBER_PLACEHOLDER = '+1 (123) 456-7890';
export const ADDRESS1_PLACEHOLDER = 'Street Address or P.O. Box';
export const ADDRESS2_PLACEHOLDER = 'Apartment, Suite, Building, etc.';
export const CITY_PLACEHOLDER = 'New York City';
export const STATE_PLACEHOLDER = 'New York';
export const COUNTRY_PLACEHOLDER = 'USA';
export const ZIP_PLACEHOLDER = '12345';
export const NAME_PERSON_PLACEHOLDER = 'John Smith';
export const TITLE_PLACEHOLDER = 'Title';
export const NAME_COMPANY_PLACEHOLDER = 'ACME Inc.';
export const CUSTOM_ID_PLACEHOLDER = 'Enter Custom ID';
export const SKU_PLACEHOLDER = 'Enter a SKU to reference this product';
export const STATUS_PLACEHOLDER = 'Select a status';
export const DATE_PLACEHOLDER = 'Select a date';
export const SELECT_PLACEHOLDER = 'Select';
