import { FC, Fragment } from 'react';
import { MFormField, MGrid, MGridItem, MText } from '~app/components/Monetize';
import { USAGE_TYPE_STATUS_DISPLAY } from '~app/constants/usageTypes';
import { IUsageTypeResSchema } from '~app/types';
import { UsageTypeDetails } from './UsageTypeDetils';

interface UsageTypeViewProps {
  usageTypes?: IUsageTypeResSchema[] | null;
}

//View Usage used under the product

export const UsageTypeView: FC<UsageTypeViewProps> = ({
  usageTypes,
}: UsageTypeViewProps) => {
  if (!usageTypes || usageTypes.length === 0) {
    return null;
  }

  return (
    <MGrid templateColumns="180px 1fr" gap={4} mt={1}>
      <MGridItem>
        <MText fontWeight="bold">Usage Type ID</MText>
      </MGridItem>
      <MGridItem>
        <MText fontWeight="bold">Usage Type</MText>
      </MGridItem>
      {usageTypes.map((usageType, index) => (
        <Fragment key={index}>
          <MGridItem>
            <MText>{usageType.id}</MText>
          </MGridItem>
          <MGridItem>
            <UsageTypeDetails usageType={usageType} />
          </MGridItem>
        </Fragment>
      ))}
    </MGrid>
  );
};
