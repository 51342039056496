import { number, object, string, z } from 'zod';
import { getRequiredMessage } from '~app/utils/messages';
import { BaseResponseSchema } from './miscTypes';

export enum AmountUnitTypeEnum {
  FLAT = 'FLAT',
  PERCENTAGE = 'PERCENTAGE',
}
export const DiscountTypeEnumZ = z.nativeEnum(AmountUnitTypeEnum);

export enum DiscountStatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  PENDING = 'PENDING',
  EXPIRED = 'EXPIRED',
}
export const DiscountStatusEnumZ = z.nativeEnum(DiscountStatusEnum);

export enum DiscountDurationEnum {
  LIMITED = 'LIMITED',
  UNLIMITED = 'UNLIMITED',
  ONE_TIME = 'ONE_TIME',
}

export const DiscountDurationEnumZ = z.nativeEnum(DiscountDurationEnum);
// from GET /api/discounts/{discountId}
export interface IDiscount {
  id: string;
  createdBy: string;
  createDate: Date;
  lastModifiedBy: string;
  modifyDate: Date;
  name: string;
  description: string;
  discountCode: string;
  discountType: AmountUnitTypeEnum;
  status: DiscountStatusEnum;
  quoteId: string;
  discountAmount: number;
  currency: 'USD';
  durationType: string;
  durationMonths: number;
  maxUses: number;
  remainingUses: number;
  startDate: string;
  endDate: string;
  autoRemove: boolean;
  accountId: string;
  scopeProductId: string;
  scopeOfferingId: string;
  locked: boolean;
}

export const DiscountSchema = z.object({
  id: z.string().optional(),
  name: z.string().max(40).nonempty(getRequiredMessage('Name')),
  description: z.string().max(80).nonempty(getRequiredMessage('Description')),
  status: DiscountStatusEnumZ,
  discountType: DiscountTypeEnumZ.optional(),
  discountCode: z
    .string()
    .max(80)
    .nonempty(getRequiredMessage('Discount Code')),
  discountAmount: z
    .union([z.string(), z.number()])
    .refine(
      (val) => val !== null && val !== undefined && val !== '',
      getRequiredMessage('Amount'),
    )
    .transform((val) => Number(val)),
  durationType: z.string().nonempty(getRequiredMessage('Duration Type')),
  discountName: z.string().optional(),
  discountAmountOrPercent: z.number().optional(),
  durationMonths: z
    .union([z.string().nullish(), z.number().nullish()])
    .transform((val) => (val === null ? null : Number(val))),
  maxUses: z
    .union([z.string().nullish(), z.number().nullish()])
    .transform((val) => (val === null ? null : Number(val))),
  startDate: z.string().nullish(),
  endDate: z.string().nullish(),
  autoRemove: z.boolean(),
  scopeOfferingId: z.union([z.string().nullish(), z.any().nullish()]).nullish(),
  scopeProductId: z.union([z.string().nullish(), z.any().nullish()]).nullish(),
  accountId: z.string().nullish(),
});

export type IDiscountSchema = z.infer<typeof DiscountSchema>;

export const QuoteItemDiscountSchema = z.object({
  discountId: z.string().nonempty(getRequiredMessage('discountId')),
  discountName: z.string().nonempty(getRequiredMessage('discountName')),
  discountType: DiscountTypeEnumZ,
  discountAmountOrPercent: z.number().nonnegative().nullish(),
  discountAmount: z.number().nonnegative().nullish(),
});

export type IQuoteItemDiscountSchema = z.infer<typeof QuoteItemDiscountSchema>;

export interface IDiscountInput {
  discountType: AmountUnitTypeEnum;
  discountAmount: number;
}

// from GET /api/discounts:
export const DiscountRespSchema = BaseResponseSchema.extend({
  name: string(),
  description: string(),
  discountType: DiscountTypeEnumZ,
  status: DiscountStatusEnumZ,
  discountCode: string(),
  quoteId: string(),
  discountAmount: number(),
  currency: string(),
});

export type IDiscountRespSchema = z.infer<typeof DiscountRespSchema>;

export const ApplicableItemDiscountsSchema = object({
  id: string(),
  discount: DiscountSchema,
  applicableDiscountType: DiscountTypeEnumZ,
  applicableDiscountAmount: number(),
});

export type IApplicableItemDiscountsSchema = z.infer<
  typeof ApplicableItemDiscountsSchema
>;
