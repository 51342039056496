import { FC, useMemo } from 'react';
import { AiOutlineProduct } from 'react-icons/ai';
import { QuoteBodyAccordion } from '~app/components/Quotes/QuoteBodyAccordion';
import { useQuoteContext } from '../../Quote/quoteContext';
import { QuoteStatusEnum, QuoteTypeEnum } from '~app/types';
import { QuoteInternalOfferingTable } from './QuoteInternalOfferingTable';
import { hasVariableAmountProduct } from '~app/utils/quotes';
import { MGrid, MBox } from '~app/components/Monetize';
import { QuoteFormTotalRow } from '../../Quote/components/QuoteFormTotalRow';
import { QuoteFormTotalRowAmendV2 } from '../../Quote/components/QuoteFormTotalRowAmendV2';

interface QuoteInternalOfferingsProps {}

export const QuoteInternalOfferings: FC<
  QuoteInternalOfferingsProps
> = ({}: QuoteInternalOfferingsProps) => {
  const { quoteData, useAmendmentV2 } = useQuoteContext();
  const { quote } = quoteData;

  const title = useMemo(() => {
    if (quote?.status !== QuoteStatusEnum.DRAFT) {
      return 'Offerings';
    }
    if (quote?.type === QuoteTypeEnum.NEW) {
      return 'Select Offerings';
    } else {
      return 'Edit Offerings';
    }
  }, [quote?.status, quote?.type]);
  const anyVariableAmountProducts = hasVariableAmountProduct(quote);
  return (
    <QuoteBodyAccordion
      title={`${title} (${quote?.quoteOfferings.length})`}
      icon={AiOutlineProduct}
    >
      <QuoteInternalOfferingTable />

      <MGrid
        gridTemplateColumns={useAmendmentV2 ? '1fr 2fr' : '8fr 3.2fr'}
        gridTemplateRows="1fr"
        columnGap={4}
        w="full"
      >
        <MBox />

        {useAmendmentV2 ? (
          <QuoteFormTotalRowAmendV2
            anyVariableAmountProducts={anyVariableAmountProducts}
          />
        ) : (
          <QuoteFormTotalRow
            anyVariableAmountProducts={anyVariableAmountProducts}
          />
        )}
      </MGrid>
    </QuoteBodyAccordion>
  );
};
