import { ButtonProps, Grid } from '@chakra-ui/react';
import { CalendarYear, useDatePicker } from '@rehookify/datepicker';
import MButton from '../MButton';

interface YearViewProps {
  years: CalendarYear[];
  onChange: (year: CalendarYear) => void;
  yearBtnPropsFn: ReturnType<typeof useDatePicker>['propGetters']['yearButton'];
}

/**
 * Displays the years in a grid and allows user to select a year
 */
export const YearView = ({
  years,
  onChange,
  yearBtnPropsFn,
}: YearViewProps) => {
  return (
    <Grid
      gridTemplateColumns="repeat(3, minmax(0, 1fr))"
      rowGap="0.5rem"
      columnGap=".1rem"
      overflowY="auto"
    >
      {years.map((year) => (
        <YearButton
          key={year.year}
          calendarYear={year}
          {...yearBtnPropsFn(year)}
          onClick={() => onChange(year)}
        />
      ))}
    </Grid>
  );
};

const YearButton = ({
  calendarYear,
  ...rest
}: {
  calendarYear: CalendarYear;
} & ButtonProps) => {
  const { year, active, disabled, selected } = calendarYear;
  let buttonProps: ButtonProps = {};

  if (selected) {
    buttonProps = {
      ...buttonProps,
      bg: 'tIndigo.base',
      color: 'white',
      _hover: {
        bg: 'tIndigo.base',
      },
      _focus: {
        bg: 'tIndigo.base',
      },
    };
  }

  if (active) {
    buttonProps = { ...buttonProps, border: '1px solid tGray.lightPurple' };
  }

  return (
    <MButton
      variant="tertiary"
      minW="unset"
      h="32px"
      m={1}
      isDisabled={disabled}
      color="tPurple.dark"
      fontWeight={400}
      borderRadius="3"
      _focus={{
        bg: 'inherit',
      }}
      _focusVisible={{
        outlineStyle: 'auto !important',
        outlineWidth: '1px !important',
        outlineColor: '#4659DA !important',
      }}
      {...buttonProps}
      {...rest}
    >
      {year}
    </MButton>
  );
};
