import { ColumnProps } from 'primereact/column';
import { DataTableExpandedRows } from 'primereact/datatable';
import { useCallback, useRef, useState } from 'react';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';
import { handleApiErrorToast } from '~app/api/axios';
import {
  doGetBillingScheduleByContractId,
  useGetContracts,
} from '~app/api/cpqService';
import {
  MBox,
  MCustomIconButton,
  MDataTable,
  MFlex,
  MHStack,
  MText,
} from '~app/components/Monetize';
import DataTableActions from '~app/components/Monetize/DataTable/MDataTableActions';
import { MCurrency } from '~app/components/Monetize/MCurrency';
import {
  ContractStatusEnum,
  DEFAULT_PAGER,
  IBillGroupResp,
  IBillingScheduleResSchema,
} from '~app/types';
import { toDateShort } from '~app/utils/dates';
import { InvoicesUnderContractTable } from '../components/InvoicesUnderContractTable';

import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { doPrintInvoiceToPdf } from '~app/api/accountsService';
import { MDataTableFilter } from '~app/components/Monetize/DataTable';
import PreviewPdfDrawer from '~app/components/Monetize/PreviewPdfDrawer/PreviewPdfDrawer';
import { ROUTES } from '~app/constants';
import { CONTRACT_STATUS_DISPLAY } from '~app/constants/contracts';
import { buildFilterParamsRequestObject } from '~app/utils';
import { FilterType, GetListApiFilter, TDataTablePager } from '~types';
import { CONTRACT_FILTER_OPTIONS_FOR_ACCOUNT_DETAIL } from '../../../Sales/ContractListMain';
import { BillingScheduleDownloadButton } from '../components/BillingScheduleDownloadButton';
import { downloadInvoices } from '../utils';

export const useContractsByBillGroupId = (billGroupId: string) => {
  const [filters, setFilters] = useState<FilterType[]>([]);
  const [searchKey] = useState<string>('id');
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [pager, setPager] = useState<TDataTablePager>(DEFAULT_PAGER);
  const [filterParams, setFilterParams] = useState<GetListApiFilter>(() =>
    buildFilterParamsRequestObject(filters, searchTerm, searchKey),
  );

  useEffect(() => {
    if (filters || searchTerm || pager) {
      setFilterParams(
        buildFilterParamsRequestObject(filters, searchTerm, searchKey),
      );
    }
  }, [filters, searchTerm, pager, searchKey]);

  const {
    isLoading,
    isFetched,
    isRefetching,
    data: listData,
  } = useGetContracts(
    {
      config: pager,
      filters: billGroupId ? { ...filterParams, billGroupId } : filterParams,
      url: `/api/contracts`,
    },
    {
      onError: (error) => handleApiErrorToast(error),
    },
  );

  const onResetFilter = () => {
    setFilters([]);
  };

  return {
    pager,
    setPager,
    filters,
    setFilters,
    onResetFilter,
    searchKey,
    searchTerm,
    setSearchTerm,
    totalRecords: listData?.totalElements || 0,
    listData,
    loading: isLoading || (isRefetching && !isFetched),
  };
};

interface BillGroupBillingScheduleTabProps {
  billGroup: IBillGroupResp;
}

export const BillGroupBillingScheduleTab = ({
  billGroup,
}: BillGroupBillingScheduleTabProps) => {
  const navigate = useNavigate();
  const rowRefs = useRef<{ [id: string]: HTMLButtonElement }>({});
  const filterComponentReset = useRef<any>(null);

  const {
    filters,
    setFilters,
    searchKey,
    searchTerm,
    onResetFilter,
    listData,
    pager,
    setPager,
    loading,
  } = useContractsByBillGroupId(billGroup.id);
  const [pdfInvoiceId, setPdfInvoiceId] = useState<string | null>(null);
  const [billingScheduleLoading, setBillingScheduleLoading] = useState<
    Set<string>
  >(new Set([]));
  const [expandedRows, setExpandedRows] = useState<DataTableExpandedRows>({});
  const [billingScheduleByContractId, setBillingScheduleByContractId] =
    useState<Record<string, IBillingScheduleResSchema>>({});
  const [isUsageProductExists, setIsUsageProductExists] =
    useState<boolean>(false);

  const fetchDocument = useCallback(async (): Promise<ArrayBuffer | null> => {
    if (pdfInvoiceId) {
      // FIXME: figure out if we can get the invoiceNumber in this context
      const { data } = await doPrintInvoiceToPdf(pdfInvoiceId, null);
      return data;
    }
    return null;
  }, [pdfInvoiceId]);

  const columns: ColumnProps[] = [
    {
      expander: true,
      field: 'id',
      header: 'Name',
      body: (data, op) => {
        return (
          <MFlex gap={1} align="center">
            <MCustomIconButton
              ref={(element: HTMLButtonElement) => {
                rowRefs.current[data.id] = element;
              }}
              isDisabled={billingScheduleLoading.has(data.id)}
              icon={!!expandedRows[data.id] ? MdExpandLess : MdExpandMore}
              btnSize={5}
              onClick={op.expander?.onClick}
              _focus={{
                bg: 'transparent',
              }}
            />
            <MText>{data.id}</MText>
          </MFlex>
        );
      },
      style: { width: '15rem' },
    },
    {
      field: 'servicePeriod',
      header: 'Service Period',
      style: { width: '10rem' },
      body: ({ startDate, endDate }) => (
        <MText>
          {toDateShort(startDate)} - {toDateShort(endDate)}
        </MText>
      ),
    },
    {
      field: 'invoiceDate',
      header: 'Invoice Date',
      style: { width: '10rem' },
    },
    {
      field: 'amount',
      header: 'Amount',
      style: { width: '7rem' },
      body: ({ totalValue, currency }) => {
        return <MCurrency options={{ currency }} amount={totalValue} />;
      },
    },
    {
      field: 'status',
      header: 'Status',
      style: { textAlign: 'left', width: '5rem' },
      body: (data) => (
        <MText>
          {CONTRACT_STATUS_DISPLAY[data.status as ContractStatusEnum]}
        </MText>
      ),
    },
    {
      style: { textAlign: 'left', width: '2rem' },
      body: (data) => {
        const actions = [
          {
            title: 'View Contract',
            enabled: true,
            action: () => {
              navigate(
                ROUTES.getBillingScheduleContractsEditRoute(
                  billGroup.id,
                  data.id,
                ),
              );
            },
          },
          {
            title: 'Export CSV',
            enabled: true,
            action: () => {
              downloadInvoices({ billGroup, contractIds: [data.id] });
            },
          },
        ];
        return <DataTableActions actions={actions} />;
      },
    },
  ];

  const contractIds: string[] =
    listData?.content.map((contract: { id: string }) => contract.id) ?? [];

  return (
    <MBox>
      <MFlex mb={6} justify="space-between">
        <MBox>
          <MText>Onetime invoices are not shown on this screen</MText>
          {isUsageProductExists && (
            <MText>
              *Excludes usage. Usage is calculated at time of Invoice
              generation.
            </MText>
          )}
        </MBox>
        <MFlex>
          <MHStack spacing="2">
            <MDataTableFilter
              filters={filters}
              filterOptions={CONTRACT_FILTER_OPTIONS_FOR_ACCOUNT_DETAIL}
              setFilters={setFilters}
              onResetFilter={onResetFilter}
              resetFilter={filterComponentReset}
            />
            <BillingScheduleDownloadButton
              entity="billingSchedule"
              contractIds={contractIds}
              billGroup={billGroup}
              filters={filters}
              searchKey={searchKey}
              searchTerm={searchTerm}
              sortField={pager.sortField}
              sortOrder={pager.sortOrder}
            />
          </MHStack>
        </MFlex>
      </MFlex>

      <MDataTable
        tableStyle={{ tableLayout: 'fixed' }}
        value={listData?.content || []}
        expandedRows={expandedRows}
        onRowExpand={async ({ data: { id } }) => {
          if (!billingScheduleByContractId[id]) {
            try {
              setBillingScheduleLoading((prevState) => prevState.add(id));
              const res = await doGetBillingScheduleByContractId(id);
              setBillingScheduleByContractId((prevState) => ({
                ...prevState,
                [id]: res,
              }));
              if (!isUsageProductExists && res.usageProductExists) {
                setIsUsageProductExists(true);
              }
            } catch (error) {
              handleApiErrorToast(error);
            } finally {
              setBillingScheduleLoading((prevState) => {
                prevState.delete(id);
                return prevState;
              });
            }
          }
        }}
        dataKey="id"
        onRowToggle={({ data }) =>
          setExpandedRows(data as DataTableExpandedRows)
        }
        rowExpansionTemplate={({ id }) => (
          <InvoicesUnderContractTable
            rowRefs={rowRefs}
            billingSchedule={billingScheduleByContractId?.[id]}
            expandedRows={expandedRows}
            loading={loading}
            billingScheduleLoading={billingScheduleLoading.has(id)}
            setExpandedRows={setExpandedRows}
            setPdfInvoiceId={setPdfInvoiceId}
          />
        )}
        onRowClick={({ data: { id } }) => {
          if (!billingScheduleLoading.has(id)) {
            rowRefs.current?.[id].click?.();
          }
        }}
        loading={loading}
        columns={columns}
        pager={pager}
        setPager={setPager}
        totalRecords={listData?.totalElements}
        totalPages={listData?.totalPages}
      />

      {!!pdfInvoiceId && (
        <PreviewPdfDrawer
          filename={`invoice-${pdfInvoiceId}`}
          isOpen={!!pdfInvoiceId}
          fetchDocument={fetchDocument}
          onClose={() => setPdfInvoiceId(null)}
        />
      )}
    </MBox>
  );
};
