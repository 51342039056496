import z, { number, object } from 'zod';
import { InvoiceEmailTimingSchema } from './invoiceTypes';

export const InvoiceTermsSettingsSchema = z.object({
  invoiceTermsActive: z.boolean(),
  invoiceTerms: z.string().optional(),
});

export type IInvoiceTermsSettingsSchema = z.infer<
  typeof InvoiceTermsSettingsSchema
>;

export interface IInvoiceTermsSettings {
  invoiceTermsActive: boolean;
  invoiceTerms: string;
}

// from GET /api/configurations/invoiceSettings
export enum InvoiceCreationEnum {
  AUTOMATIC = 'AUTOMATIC',
  MANUAL = 'MANUAL',
}

export const InvoiceCreationEnumZ = z.nativeEnum(InvoiceCreationEnum);

export enum FirstInvoiceCreationStrategyEnum {
  FOLLOW_EXISTING_SETTINGS = 'FOLLOW_EXISTING_SETTINGS',
  GENERATE_ON_QUOTE_PROCESSED = 'GENERATE_ON_QUOTE_PROCESSED',
}

export const FirstInvoiceCreationStrategyEnumZ = z.nativeEnum(
  FirstInvoiceCreationStrategyEnum,
);

export const InvoiceTemplateConfigSchema = z.object({
  showUsageWithoutEvents: z.boolean(),
  showOffering: z.boolean(),
});

export type IInvoiceTemplateConfigSchema = z.infer<
  typeof InvoiceTemplateConfigSchema
>;

export interface IInvoiceTemplateConfigRes {
  showUsageWithoutEvents: boolean;
  showOffering: boolean;
}

export const InvoiceAutomationSettingsSchema = object({
  invoiceCreation: InvoiceCreationEnumZ,
  invoiceDelay: z.number().min(-30).max(30),
  firstInvoiceCreationStrategy: FirstInvoiceCreationStrategyEnumZ,
});

export type IInvoiceAutomationSettings = z.infer<
  typeof InvoiceAutomationSettingsSchema
>;

export const InvoiceSettingsSchema = object({
  invoiceAutomation: InvoiceAutomationSettingsSchema,
  invoiceTerms: InvoiceTermsSettingsSchema,
  invoiceEmailingConfig: InvoiceEmailTimingSchema,
  invoiceTemplateConfig: InvoiceTemplateConfigSchema,
});

export type IInvoiceSettings = z.infer<typeof InvoiceSettingsSchema>;
