import { createContext, useCallback, useContext, useState } from 'react';

import { IDocumentHeadProps } from '~types';

const defaultContext = {
  headData: { title: '', subtitle: '' },
};

type DocumentHeadType = {
  headData: IDocumentHeadProps;
  setHeadData: (headData: IDocumentHeadProps) => void;
  setDocTitle: (title?: string, subtitle?: string) => void;
};

const DocumentHeadContext = createContext<DocumentHeadType>(
  defaultContext as any,
);

export const useDocumentHead = () => useContext(DocumentHeadContext);

const useDocumentHeadProvider = () => {
  const [headData, setHeadData] = useState<IDocumentHeadProps>({
    title: '',
    subtitle: '',
  });
  const setDocTitle = useCallback(
    (title: string = '', subtitle: string = '') => {
      setHeadData({ title, subtitle });
    },
    [],
  );
  return { headData, setHeadData, setDocTitle };
};

export const DocumentHeadProvider = ({ children }: { children: any }) => {
  const data = useDocumentHeadProvider();
  return (
    <DocumentHeadContext.Provider value={data}>
      {children}
    </DocumentHeadContext.Provider>
  );
};
