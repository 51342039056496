import { BoxProps, ContainerProps } from '@chakra-ui/react';
import { ReactNode, forwardRef } from 'react';
import { MBox } from './chakra';

interface MPageTopNavContainerProps extends BoxProps {
  children?: ReactNode;
}

export const MPageTopNavContainer = forwardRef<any, MPageTopNavContainerProps>(
  ({ children, ...rest }: MPageTopNavContainerProps, ref) => {
    const defaultProps: ContainerProps = {
      width: 'auto',
      px: ['4px', '4px', '32px'],
    };

    return (
      <>
        <MBox ref={ref} {...defaultProps} {...rest}>
          {children}
        </MBox>
      </>
    );
  },
);
