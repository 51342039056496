import { FunctionComponent as FC } from 'react';
import {
  Control,
  Controller,
  FieldErrors,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import { MdOpenInNew } from 'react-icons/md';
import { useRecoilValue } from 'recoil';
import { useGetListData } from '~app/api/queryUtils';
import { CustomFieldDataForm } from '~app/components/CustomFields/CustomFieldDataForm';
import { ACCOUNTS } from '~app/constants';
import { CUSTOM_ID_PLACEHOLDER } from '~app/constants/placeholders';
import { TAX_ENTITY_USE_CODES } from '~app/constants/taxes';
import { appGlobalDataState } from '~app/store/global.store';
import { ILegalEntityResponseSchema } from '~app/types/legalEntityTypes';
import {
  ITaxConnectionBaseSchema,
  TaxProviderTypeEnum,
  TaxStatusEnum,
} from '~app/types/taxTypes';
import { getAddress } from '~app/utils/address';
import {
  MCheckbox,
  MCurrencySelect,
  MCustomSelect,
  MDivider,
  MFormField,
  MGrid,
  MGridItem,
  MIcon,
  MInput,
  MLink,
  MText,
  MTextarea,
  MTooltip,
} from '~components/Monetize';
import {
  CustomFieldEntityEnum,
  DEFAULT_PAGER,
  IAccountSchema,
  IDunnings,
} from '~types';
import { MLegalEntitySelect } from '../../components/Monetize/MLegalEntitySelect';

interface AccountFormProps {
  control: Control<IAccountSchema, object>;
  setValue: UseFormSetValue<IAccountSchema>;
  watch: UseFormWatch<IAccountSchema>;
  errors: FieldErrors<IAccountSchema>;
  isReadOnly?: boolean;
  isExisting?: boolean;
  legalEntities?: ILegalEntityResponseSchema[];
}

const AccountForm: FC<AccountFormProps> = ({
  control,
  errors,
  isReadOnly,
  legalEntities = [],
  watch,
}: AccountFormProps) => {
  const isReadOnlyProps = {
    isDisabled: isReadOnly,
    isReadOnly: isReadOnly,
    variant: isReadOnly ? 'readonly' : 'primary',
  };
  const { hasMultipleCurrency, hasMultipleLegalEntity } =
    useRecoilValue(appGlobalDataState);

  const { data: taxConnections } = useGetListData<
    ITaxConnectionBaseSchema[],
    ITaxConnectionBaseSchema[]
  >('tax', undefined, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
  });

  const { data: dunnings } = useGetListData<IDunnings>(
    'dunnings',
    {
      config: DEFAULT_PAGER,
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 60 * 24,
      cacheTime: 1000 * 60 * 60 * 24,
    },
  );

  const isAvalaraConnectionEnabled = taxConnections?.find(
    ({ provider, status }) =>
      provider === TaxProviderTypeEnum.AVALARA &&
      status === TaxStatusEnum.ACTIVE,
  );

  const billingAddress = watch('billingAddress');
  const shippingAddress = watch('shippingAddress');
  const defaultLegalEntityId = watch('defaultLegalEntityId');
  const selectedLegalEntity = legalEntities.find(
    (entity) => entity.id === defaultLegalEntityId,
  );

  return (
    <MGrid templateColumns="repeat(12, 1fr)" gap={4}>
      <MGridItem colSpan={9}>
        <MFormField error={errors.accountName} label="Account Name" isRequired>
          <Controller
            name="accountName"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <MInput
                data-testid="account-company-name"
                {...field}
                {...isReadOnlyProps}
              />
            )}
          />
        </MFormField>
      </MGridItem>
      <MGridItem colSpan={3}>
        <MFormField error={errors.isTestAccount} label="" skipLabel>
          <Controller
            name="isTestAccount"
            control={control}
            defaultValue
            render={({ field: { value, ...rest } }) => (
              <MCheckbox
                isChecked={value as any}
                mt="2"
                textColor="tPurple.dark"
                {...rest}
                {...isReadOnlyProps}
              >
                Test Account
              </MCheckbox>
            )}
          />
        </MFormField>
      </MGridItem>

      <MGridItem colSpan={12}>
        <MFormField label="Billing Address">
          <MTooltip
            label="To make edits to Addresses, refer to your CRM Account Settings"
            placement="bottom-start"
          >
            <MText display="inline">
              {billingAddress
                ? getAddress(billingAddress, {
                    addressFormat: selectedLegalEntity?.addressFormat,
                  }).fullAddress
                : 'No address assigned'}
            </MText>
          </MTooltip>
        </MFormField>
      </MGridItem>

      <MGridItem colSpan={12}>
        <MFormField label="Shipping Address">
          <MTooltip
            label="To make edits to Addresses, refer to your CRM Account Settings"
            placement="bottom-start"
          >
            <MText display="inline">
              {shippingAddress
                ? getAddress(shippingAddress, {
                    addressFormat: selectedLegalEntity?.addressFormat,
                  }).fullAddress
                : 'No address assigned'}
            </MText>
          </MTooltip>
        </MFormField>
      </MGridItem>

      {hasMultipleLegalEntity && (
        <MGridItem colSpan={hasMultipleCurrency ? 5 : 9}>
          <MFormField
            error={errors.defaultLegalEntityId}
            label="Legal Entity"
            isRequired
          >
            <Controller
              name="defaultLegalEntityId"
              control={control}
              render={({ field }) => (
                <MLegalEntitySelect {...field} {...isReadOnlyProps} />
              )}
            />
          </MFormField>
        </MGridItem>
      )}
      {hasMultipleCurrency && (
        <MGridItem colSpan={hasMultipleLegalEntity ? 4 : 9}>
          <MFormField
            error={errors.defaultCurrency}
            label="Default Currency"
            isRequired
          >
            <Controller
              name="defaultCurrency"
              control={control}
              render={({ field }) => (
                <MCurrencySelect {...field} {...isReadOnlyProps} />
              )}
            />
          </MFormField>
        </MGridItem>
      )}

      <MGridItem colSpan={3}>
        <MFormField error={errors.status} label="Status" isRequired>
          <Controller
            name="status"
            control={control}
            render={({ field }) => (
              <MCustomSelect
                itemTitle="name"
                itemValue="value"
                items={ACCOUNTS.STATUS_OPTIONS}
                {...field}
                {...isReadOnlyProps}
              />
            )}
          />
        </MFormField>
      </MGridItem>

      <MGridItem colSpan={6}>
        <MFormField
          error={errors.defaultDunningProcessId}
          label="Dunning for New Bill Groups"
        >
          <Controller
            name="defaultDunningProcessId"
            control={control}
            render={({ field }) => (
              <MCustomSelect
                placeholder="Select"
                items={(dunnings?.content || []).map((x: IDunnings) => ({
                  title: x.name,
                  value: x.id,
                }))}
                clearable
                {...field}
                {...isReadOnlyProps}
              />
            )}
          />
        </MFormField>
      </MGridItem>
      <MGridItem colSpan={6}>
        <MFormField error={errors.customId} label="Custom ID">
          <Controller
            name="customId"
            control={control}
            defaultValue=""
            render={({ field: { value, ...rest } }) => (
              <MInput
                placeholder={CUSTOM_ID_PLACEHOLDER}
                value={value || ''}
                {...rest}
                {...isReadOnlyProps}
              />
            )}
          />
        </MFormField>
      </MGridItem>

      <MGridItem colSpan={12}>
        <MFormField
          error={errors.ccEmails}
          label="Account-level CCs"
          data-testid="email-text-area"
          tooltipUsePopover={true}
          tooltip={
            <>
              <MText fontSize="xs" color="tWhite.base">
                Use to CC in Dunning process. Recommended to manage CC list in
                CRM and map to MonetizeNow.{' '}
                <MLink
                  href="https://docs.monetizenow.io/docs/dunning#keep-everyone-informed-throughout-dunning-process"
                  target="_blank"
                  color="tWhite.base"
                >
                  Learn more. <MIcon as={MdOpenInNew} />
                </MLink>
              </MText>
            </>
          }
        >
          <Controller
            name="ccEmails"
            control={control}
            render={({ field: { value, ...rest } }) => (
              <MTextarea
                value={value || ''}
                placeholder="Enter emails"
                spellCheck={false}
                rows={1}
                {...rest}
                {...isReadOnlyProps}
              />
            )}
          />
        </MFormField>
      </MGridItem>

      {isAvalaraConnectionEnabled && (
        <>
          <MGridItem colSpan={12} mt={4}>
            <MDivider />
          </MGridItem>
          <MGridItem colSpan={6}>
            <MFormField
              error={errors.taxExemptionNumber}
              label="Tax Exemption Number"
            >
              <Controller
                name="taxExemptionNumber"
                control={control}
                render={({ field: { value, ...rest } }) => (
                  <MInput value={value || ''} {...rest} {...isReadOnlyProps} />
                )}
              />
            </MFormField>
          </MGridItem>
          <MGridItem colSpan={6}>
            <MFormField
              error={errors.taxEntityUseCode}
              label="Tax Exemption Reason"
            >
              <Controller
                name="taxEntityUseCode"
                control={control}
                render={({ field }) => (
                  <MCustomSelect
                    clearable
                    items={TAX_ENTITY_USE_CODES}
                    {...field}
                    {...isReadOnlyProps}
                  />
                )}
              />
            </MFormField>
          </MGridItem>
        </>
      )}
      <MGridItem colSpan={12}>
        <Controller
          name="customFields"
          control={control}
          defaultValue={{}}
          render={({ field: { value, onChange } }) => (
            <CustomFieldDataForm
              entity={CustomFieldEntityEnum.ACCOUNT}
              value={value}
              setValue={(val) => onChange(val)}
              fieldColSpan={6}
              mode="modal"
              {...isReadOnlyProps}
            />
          )}
        />
      </MGridItem>
    </MGrid>
  );
};

export default AccountForm;
