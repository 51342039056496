import { TransactableSourceType, TransactableTypeDisplay } from '../../types';
import { MAlert, MBox, MButton, MFlex, MStack, MText } from '../Monetize';
import { MCurrency } from '../Monetize/MCurrency';

interface TransactionApplicationModalFooterProps {
  sourceType: TransactableSourceType;
  /**
   * Full amount of Payment or Credit
   */
  totalAmount: number;
  /**
   * Currently applied amount of payment or credit
   */
  appliedAmount: number;
  /**
   * Currently unapplied amount of payment or credit
   */
  availableAmount: number;
  refundedAmount: number;
  currency: string;
  isDisabled: boolean;
  isLoading: boolean;
  onClose: () => void;
}

export const TransactionApplicationModalFooter = ({
  sourceType,
  totalAmount,
  appliedAmount,
  availableAmount,
  refundedAmount,
  currency,
  isDisabled = false,
  isLoading = false,
  onClose,
}: TransactionApplicationModalFooterProps) => {
  const entityName = TransactableTypeDisplay[sourceType];
  const isInvalidValidAmount = availableAmount < 0;
  return (
    <MBox w="full">
      {isInvalidValidAmount && (
        <MAlert
          type="error"
          mb={6}
          alertProps={{ py: 2 }}
          alertIconProps={{ boxSize: 4 }}
          alertDescriptionProps={{ fontSize: 'sm' }}
        >
          {refundedAmount > 0
            ? `Amount Applied cannot exceed non-refunded Amount.`
            : `Amount Applied cannot exceed Original ${entityName} Amount.`}
        </MAlert>
      )}
      <MBox
        border="1px solid var(--chakra-colors-tGray-back)"
        borderRadius={8}
        p="4"
        mx="4"
        mb="4"
        mt="2"
      >
        <MFlex justifyContent="space-between" mb={2}>
          <MText fontSize="sm">Original {entityName} Amount</MText>
          <MCurrency
            amount={totalAmount}
            options={{ currency, maximumFractionDigits: 2 }}
            fontSize="sm"
            textAlign="right"
          />
        </MFlex>
        <MFlex justifyContent="space-between" mb={2}>
          <MText fontSize="sm">Applied from this {entityName}</MText>
          <MCurrency
            amount={-appliedAmount}
            options={{ currency, maximumFractionDigits: 2 }}
            fontSize="sm"
            textAlign="right"
          />
        </MFlex>
        {!!refundedAmount && (
          <MFlex justifyContent="space-between" mb={2}>
            <MText fontSize="sm">Refunded from this {entityName}</MText>
            <MCurrency
              amount={-refundedAmount}
              options={{ currency, maximumFractionDigits: 2 }}
              fontSize="sm"
              textAlign="right"
            />
          </MFlex>
        )}
        <MFlex justifyContent="space-between">
          <MText fontSize="lg" fontWeight="600">
            {entityName} Amount Remaining
          </MText>
          <MCurrency
            amount={availableAmount}
            options={{ currency, maximumFractionDigits: 2 }}
            fontSize="lg"
            fontWeight="600"
            textAlign="right"
          />
        </MFlex>
      </MBox>
      <MStack
        spacing={4}
        direction="row"
        align="center"
        justify="right"
        flex={1}
      >
        <MButton
          variant="cancel"
          minW="auto"
          isDisabled={isLoading}
          onClick={onClose}
        >
          Cancel
        </MButton>
        <MButton
          form="apply-payments-credits-form"
          variant="primary"
          type="submit"
          minW="auto"
          isDisabled={isLoading || isInvalidValidAmount || isDisabled}
          isLoading={isLoading}
        >
          Save
        </MButton>
      </MStack>
    </MBox>
  );
};
