import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AppLoading from '~components/App/AppLoading';
import { ROUTES } from '~constants';
import { useAuth } from '~app/services/auth0';

const RefreshToken = () => {
  const { getAndHandleAccessTokenSilently } = useAuth();
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();

  const getUserMetadata = async () => {
    await getAndHandleAccessTokenSilently();
    navigate(ROUTES.ROOT, { replace: true });
  };

  useEffect(() => {
    if (isAuthenticated) {
      getUserMetadata();
    }
  }, [isAuthenticated]);

  return <AppLoading loadingText="Switching the tenant..." />;
};

export default RefreshToken;
