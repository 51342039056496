import { StackProps } from '@chakra-ui/react';
import { FC } from 'react';
import { MdLock } from 'react-icons/md';
import { AnrokLogo, AvalaraLogo } from '~app/assets/images';
import { MIcon, MImage } from '~app/components/Monetize';
import { ButtonCard } from '~app/components/Monetize/Cards/ButtonCard';
import { TAX_PROVIDER_DISPLAY } from '~app/constants/taxes';
import { TaxProviderTypeEnum } from '~app/types/taxTypes';

interface TaxCardEmptyProps extends StackProps {
  type: TaxProviderTypeEnum;
  isLocked?: boolean;
  isDisabled?: boolean;
  onClick?: () => void;
}

export const TaxCardEmpty: FC<TaxCardEmptyProps> = ({
  type,
  isLocked,
  isDisabled,
  onClick,
  ...rest
}) => {
  const logo = type === TaxProviderTypeEnum.ANROK ? AnrokLogo : AvalaraLogo;
  const provider = TAX_PROVIDER_DISPLAY[type];
  return (
    <ButtonCard
      h="82px"
      titleLeftContent={
        <MImage h="25px" src={logo} opacity={isDisabled ? 0.5 : 1} />
      }
      title={provider}
      description={`Connect to ${provider}`}
      isDisabled={isLocked || isDisabled}
      titleRightContent={
        isLocked ? (
          <MIcon as={MdLock} w={3} h={3} mt={1} color="tPurple.dark" />
        ) : undefined
      }
      width={isLocked ? '100%' : undefined}
      onClick={isDisabled ? undefined : onClick}
      cursor={isDisabled ? 'not-allowed' : undefined}
      {...rest}
    />
  );
};
