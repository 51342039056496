import { BoxProps, FlexProps } from '@chakra-ui/react';
import React, { FunctionComponent as FC, ReactNode } from 'react';
import { MBox, MFlex } from './chakra';

interface MRowWithLineSeparatorProps extends FlexProps {
  leftLabel: ReactNode | JSX.Element;
  rightLabel: ReactNode | JSX.Element;
  lineProps?: BoxProps;
}

const MRowWithLineSeparator: FC<MRowWithLineSeparatorProps> = React.forwardRef<
  any,
  MRowWithLineSeparatorProps
>((props: MRowWithLineSeparatorProps, ref) => {
  const { leftLabel, rightLabel, lineProps, ...rest } = props;

  return (
    <MFlex
      justifyContent="space-between"
      alignItems="center"
      width="100%"
      my={2}
      {...rest}
    >
      <MBox>{leftLabel}</MBox>
      <MBox
        h="1px"
        background="tGray.lightPurple"
        mx="4"
        flexGrow={1}
        {...lineProps}
      />
      <MBox>{rightLabel}</MBox>
    </MFlex>
  );
});

MRowWithLineSeparator.defaultProps = {
  lineProps: {},
};

export default MRowWithLineSeparator;
