import z from 'zod';
import { SUPPORTED_CURRENCIES } from '~app/constants/currencies';
import { getRequiredMessage } from '../utils/messages';
import { NetTermsEnumZ } from './netTermsType';
import { ProductResSchema } from './productTypes';
import { RateBillingFrequencyEnum, SubscriptionTimingEnum } from './rateTypes';

export type OnboardingDataProducts = 'products';
export type OnboardingDataOfferings = 'offerings';
export type OnboardingDataRates = 'rates';
export type OnboardingDataQuotes = 'quotes';

export type FileDropType =
  | OnboardingDataProducts
  | OnboardingDataOfferings
  | OnboardingDataRates
  | OnboardingDataQuotes;

export type OnboardingParseSchemas =
  | typeof ProductOnboardingSchema
  | typeof OfferingOnboardingSchema
  | typeof RateOnboardingSchema
  | typeof QuoteOnboardingSchema;

export type RowType<T> = T extends OnboardingDataProducts
  ? ProductOnboarding
  : T extends OnboardingDataOfferings
  ? OfferingOnboarding
  : T extends OnboardingDataRates
  ? RateOnboarding
  : T extends OnboardingDataQuotes
  ? QuoteOnboarding
  : never;

export type ParseReturnType =
  | ParseReturnTypeProduct
  | ParseReturnTypeOfferings
  | ParseReturnTypeRates
  | ParseReturnTypeQuotes;

export type ParseReturnTypeProduct = {
  type: OnboardingDataProducts;
  data: ProductOnboarding[];
  errors: z.ZodIssue[];
};

export type ParseReturnTypeOfferings = {
  type: OnboardingDataOfferings;
  data: OfferingOnboarding[];
  errors: z.ZodIssue[];
};

export type ParseReturnTypeRates = {
  type: OnboardingDataRates;
  data: RatesByOfferingByName;
  errors: z.ZodIssue[];
};

export type ParseReturnTypeQuotes = {
  type: OnboardingDataQuotes;
  data: Record<string, QuoteOnboarding[]>;
  errors: z.ZodIssue[];
};

export type RatesByOfferingByName = Record<
  string,
  Record<string, Record<string, RateOnboarding[]>>
>;

export enum OnboardingProductTypes {
  SUBSCRIPTION = 'SUBSCRIPTION',
  ADVANCE = 'ADVANCE',
  MIN_COMMIT = 'MIN_COMMIT',
}

export const ProductOnboardingSchema = z.object({
  'Product Name': z
    .string({
      required_error: getRequiredMessage('Product Name'),
    })
    .nullish()
    .refine((value) => !!value, {
      message: getRequiredMessage('Product Name'),
    }),
  Description: z
    .string({
      required_error: getRequiredMessage('Description'),
    })
    .nullish()
    .refine((value) => !!value, {
      message: getRequiredMessage('Description'),
    }),
  'Product Type': z
    .nativeEnum(OnboardingProductTypes)
    .nullish()
    .refine((value) => !!value, {
      message: getRequiredMessage('Product Type'),
    }),
  Status: z
    .enum(['ACTIVE', 'INACTIVE'])
    .nullish()
    .refine((value) => !!value, {
      message: getRequiredMessage('Status'),
    }),
  SKU: z.union([z.string(), z.number()]).nullish(),
});

export type ProductOnboarding = z.infer<typeof ProductOnboardingSchema>;

export enum OnboardingOfferingTypes {
  SUBSCRIPTION = 'SUBSCRIPTION',
  MIN_COMMIT = 'MIN_COMMIT',
}

export enum OnboardingOfferingPricingModels {
  VOLUME = 'VOLUME',
  TIERED = 'TIERED',
  FLAT = 'FLAT',
}

export enum OnboardingOfferingStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export const OfferingOnboardingSchema = z.object({
  'Offering Name': z
    .string()
    .nullish()
    .refine((value) => !!value, {
      message: getRequiredMessage('Offering Name'),
    }),
  Status: z
    .nativeEnum(OnboardingOfferingStatus)
    .nullish()
    .refine((value) => !!value, {
      message: getRequiredMessage('Status'),
    }),
  Type: z
    .nativeEnum(OnboardingOfferingTypes)
    .nullish()
    .refine((value) => !!value, {
      message: getRequiredMessage('Type'),
    }),
  'Pricing Model': z
    .nativeEnum(OnboardingOfferingPricingModels)
    .nullish()
    .refine((value) => !!value, {
      message: getRequiredMessage('Pricing Model'),
    }),
  Monthly: z.union([z.string(), z.number(), z.null()]),
  Quarterly: z.union([z.string(), z.number(), z.null()]),
  Annually: z.union([z.string(), z.number(), z.null()]),
  'Product 1': z
    .string()
    .nullish()
    .refine((value) => !!value, {
      message: getRequiredMessage('Product 1'),
    }),
  'Product 2': z.union([z.string(), z.null()]),
  'Product 3': z.union([z.string(), z.null()]),
  'Product 4': z.union([z.string(), z.null()]),
  'Product 5': z.union([z.string(), z.null()]),
  'Product IDs': z
    .string()
    .nullish()
    .refine((value) => !!value, {
      message: getRequiredMessage('Product IDs'),
    }),
});

export type OfferingOnboarding = z.infer<typeof OfferingOnboardingSchema>;

export const QuoteOnboardingSchema = z.object({
  'Account Name': z.string(),
  'Account ID': z.string().nullable(),
  'Auto Renew': z.enum(['Y', 'N']).nullable(),
  'Opportunity ID': z
    .string()
    .nullable()
    .refine((value) => !/\s/.test(value ?? '')),
  'Contract Length': z.number().int().min(1).max(120),
  'Contract Start Date': z.date(),
  Currency: z
    .enum(['', ...Object.keys(SUPPORTED_CURRENCIES)] as const)
    .nullable(),
  Description: z.string().nullable(),
  'Net Terms': NetTermsEnumZ.nullable(),
  'Contact Name': z.string(),
  'Contact ID': z.string().nullable(),
  'Offering Name': z.string(),
  'Offering ID': z.string().nullable(),
  'Rate Name': z.string().nullable(),
  'Rate ID': z.string().nullable(),
  'Product Name': z.string(),
  'Product ID': z.string().nullable(),
  Quantity: z.number().int().min(1),
  'Total Amount Over Contract Length': z.number().min(0),
});

export type QuoteOnboarding = z.infer<typeof QuoteOnboardingSchema>;

export const RateOnboardingSchema = z.object({
  'Offering Name': z
    .string()
    .nullish()
    .refine((value) => !!value, {
      message: getRequiredMessage('Offering Name'),
    }),
  'Offering ID': z
    .string()
    .nullish()
    .refine((value) => !!value, {
      message: getRequiredMessage('Offering ID'),
    }),
  'Rate Name': z
    .string()
    .nullish()
    .refine((value) => !!value, {
      message: getRequiredMessage('Rate Name'),
    }),
  'Billing Frequency': z
    .nativeEnum(RateBillingFrequencyEnum)
    .nullish()
    .refine((value) => !!value, {
      message: getRequiredMessage('Billing Frequency'),
    }),
  'Subscription Frequency': z
    .nativeEnum(SubscriptionTimingEnum)
    .nullish()
    .refine((value) => !!value, {
      message: getRequiredMessage('Subscription Frequency'),
    }),
  'Product Name': z
    .string()
    .nullish()
    .refine((value) => !!value, {
      message: getRequiredMessage('Product Name'),
    }),
  'Product ID': z
    .string()
    .nullish()
    .refine((value) => !!value, {
      message: getRequiredMessage('Product ID'),
    }),
  Type: z
    .enum(['VOLUME', 'TIERED', 'FLAT'])
    .nullish()
    .refine((value) => !!value, {
      message: getRequiredMessage('Type'),
    }),
  From: z
    .number()
    .nullish()
    .refine((value) => !!value, {
      message: getRequiredMessage('From'),
    }),
  To: z.union([z.number(), z.string()]).nullish(),
  Price: z
    .number()
    .nullish()
    .refine((value) => !!value, {
      message: getRequiredMessage('Price'),
    }),
  Note: z.union([z.string(), z.number()]).nullish(),
});

export type RateOnboarding = z.infer<typeof RateOnboardingSchema>;

export const OfferingTimingOnboardingSchema = z.object({
  model: z.enum(['VOLUME', 'TIERED', 'FLAT']),
  monthly: z.union([z.string(), z.number(), z.null()]),
  quarterly: z.union([z.string(), z.number(), z.null()]),
  annually: z.union([z.string(), z.number(), z.null()]),
});

export type OfferingTimingOnboarding = z.infer<
  typeof OfferingTimingOnboardingSchema
>;
export const OfferingResponseOnboardingSchema = z.object({
  name: z.string(),
  products: z.array(ProductResSchema),
  id: z.string(),
});

export type OfferingResponseOnboarding = z.infer<
  typeof OfferingResponseOnboardingSchema
>;
