import {
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
} from '@chakra-ui/react';
import { FC, useState } from 'react';
import { MBox } from '~app/components/Monetize';
import { QUOTE_READONLY_STATUSES } from '~app/constants/quotes';
import { IQuoteRequestSchema, IQuoteRespSchema } from '~app/types';
import { QuoteDocumentsBody } from './QuoteDocumentsBody';
import { QuoteDocumentsFooter } from './QuoteDocumentsFooter';

interface QuoteDocumentsPopoverContentProps {
  quote: IQuoteRespSchema;
  disabled?: boolean;
  onUpdate: (data: IQuoteRequestSchema, requestType: string) => void;
  onClose: (didSave?: boolean) => void;
}

export const QuoteDocumentsPopoverContent: FC<
  QuoteDocumentsPopoverContentProps
> = ({
  quote,
  disabled,
  onUpdate,
  onClose,
}: QuoteDocumentsPopoverContentProps) => {
  const [saving, setSaving] = useState(false);
  const isReadOnly = disabled || QUOTE_READONLY_STATUSES.has(quote.status);

  function handleClose() {
    if (saving) {
      return;
    }
    onClose();
  }

  return (
    <PopoverContent>
      <PopoverBody pb={!isReadOnly ? '0 !important' : undefined}>
        <PopoverArrow />
        <MBox>
          <QuoteDocumentsBody
            quote={quote}
            saving={saving}
            isReadOnly={isReadOnly}
            setSaving={setSaving}
            onUpdate={onUpdate}
            handleClose={handleClose}
          />
        </MBox>
      </PopoverBody>
      {!isReadOnly && (
        <PopoverFooter>
          <QuoteDocumentsFooter saving={saving} handleClose={handleClose} />
        </PopoverFooter>
      )}
    </PopoverContent>
  );
};
