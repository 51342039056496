import { useState } from 'react';

export const useQuery = ({
  useExternalQuery,
  externalQuery,
  onChangeQuery,
}: {
  useExternalQuery?: boolean;
  externalQuery?: string;
  onChangeQuery?: null | ((query: string) => void);
}) => {
  const [internalQuery, setInternalQuery] = useState('');

  const query = useExternalQuery ? externalQuery || '' : internalQuery;
  const setQuery = useExternalQuery
    ? onChangeQuery || (() => {})
    : setInternalQuery;

  return { query, setQuery };
};
