export const MonetizeNowTextLogo = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 140 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M58.5999 2.13281V5.01679H56.2617V7.63675H58.5999V16.8574H64.2725V14.2983H61.304V7.61644H64.0692V5.01679H61.2837V2.13281H58.5999Z"
      fill="white"
    />
    <path
      d="M14.4193 0.53125L9.51935 13.2248C8.42143 10.0768 7.18118 6.98974 6.00193 4.10576L4.61937 0.53125L0.695312 0.55156V16.8197H3.41978L3.33846 4.89784L8.21811 16.8806H10.8206L15.6392 5.06032V16.84H18.3434V0.55156L14.4193 0.53125Z"
      fill="white"
    />
    <path
      d="M47.5636 9.36929C47.8279 8.65845 48.2345 8.1304 48.8241 7.78513C50.0034 7.07429 51.2436 7.07429 52.4636 7.78513C53.0328 8.11009 53.3988 8.63814 53.5615 9.36929H47.5636ZM54.7001 12.4767H53.7648L53.7038 12.7204C53.7038 12.7407 53.6835 12.8016 53.6631 12.8626L53.5818 13.025C53.3582 13.5328 52.9922 13.8984 52.5245 14.1421C51.4876 14.6498 50.41 14.7108 49.3528 14.2639C48.3565 13.8577 47.5026 12.7407 47.3806 11.7455H56.5503L56.5299 11.4003C56.5299 11.2784 56.5096 11.1769 56.5096 11.055C56.5096 10.8113 56.4893 10.5676 56.4689 10.3238C56.2656 8.51628 55.676 7.21646 54.5984 6.24159C53.1752 4.94177 51.386 4.51527 49.2511 4.92146C46.9943 5.36828 45.4694 6.76965 44.8188 8.9631C44.1071 11.38 44.5951 13.5531 46.2013 15.2388C47.3806 16.4777 48.8445 17.087 50.5727 17.087C51.081 17.087 51.6096 17.0261 52.1586 16.9245C54.1714 16.5386 55.554 15.381 56.2453 13.4922C56.2859 13.35 56.3266 13.2078 56.3673 13.086V13.0657C56.3876 13.0047 56.3876 12.9438 56.4079 12.8829L56.5096 12.4767H55.5947C55.2897 12.4767 55.005 12.4767 54.7001 12.4767Z"
      fill="white"
    />
    <path
      d="M83.3652 9.36607C83.6295 8.65523 84.0361 8.12717 84.6257 7.78191C85.805 7.07107 87.0452 7.07107 88.2651 7.78191C88.8344 8.10686 89.2004 8.63492 89.3631 9.36607H83.3652ZM90.5016 12.4735H89.546L89.485 12.7172C89.485 12.7375 89.4647 12.7984 89.4444 12.8593L89.3631 13.0218C89.1394 13.5296 88.7734 13.8951 88.3058 14.1389C87.2689 14.6466 86.1913 14.7075 85.134 14.2607C84.1378 13.8545 83.2838 12.7375 83.1618 11.7423H92.3315L92.3112 11.397C92.3112 11.2752 92.2908 11.1736 92.2908 11.0518C92.2705 10.8081 92.2705 10.5643 92.2502 10.3206C92.0469 8.51306 91.4572 7.21324 90.3796 6.23837C88.9564 4.93855 87.1672 4.49173 85.0324 4.91824C82.7755 5.36505 81.2506 6.76642 80.6 8.95987C79.8884 11.3767 80.3764 13.5499 81.9826 15.2356C83.1618 16.4745 84.6257 17.0838 86.3539 17.0838C86.8622 17.0838 87.3909 17.0228 87.9398 16.9213C89.9527 16.5354 91.3352 15.3777 92.0265 13.4889C92.0672 13.3468 92.1079 13.2046 92.1485 13.0827V13.0624C92.1688 13.0015 92.1689 12.9406 92.1892 12.8796L92.2908 12.4735H91.3759C91.0913 12.4735 90.8066 12.4735 90.5016 12.4735Z"
      fill="white"
    />
    <path
      d="M69.9681 5.12109V7.68012H76.21L69.7852 14.5651V16.9414H80.0934V14.3823H73.4246L79.7681 7.53795L79.8088 7.47702V5.12109H69.9681Z"
      fill="white"
    />
    <path
      d="M105.105 0.53125V12.8389L96.8706 0.612489L96.8299 0.53125H93.3125V16.779H96.0573V4.26824L104.231 16.6369L104.332 16.779H107.85V0.53125H105.105Z"
      fill="white"
    />
    <path
      d="M136.615 5.09766L134.196 12.9169L131.797 5.09766H128.543L126.144 12.9169L123.725 5.09766H120.695L124.802 16.9585H127.466L130.17 9.17991L132.874 16.9585H135.538L139.645 5.09766H136.615Z"
      fill="white"
    />
    <path d="M68.3416 5.08203H65.7188V16.882H68.3416V5.08203Z" fill="white" />
    <path
      d="M67.0429 3.63707C67.9172 3.63707 68.6288 2.92623 68.6288 2.05291C68.6288 1.17959 67.9172 0.46875 67.0429 0.46875C66.1686 0.46875 65.457 1.17959 65.457 2.05291C65.457 2.92623 66.1686 3.63707 67.0429 3.63707Z"
      fill="white"
    />
    <path
      d="M43.5958 9.2254C43.5551 7.92558 43.0672 6.78824 42.1319 5.91492C41.278 5.10253 39.8344 4.67602 38.3705 4.79788C37.0083 4.89943 35.829 5.44779 35.0157 6.32111L34.8328 5.02129H32.6776H32.4336V16.8415H35.1377L35.1174 16.6588V15.1152C35.1174 13.9373 35.1174 12.739 35.1174 11.5204C35.1174 11.0127 35.1581 10.5455 35.2394 10.0987C35.5647 8.31147 36.8456 7.23505 38.5738 7.29598C39.8141 7.3366 40.607 8.02713 40.851 9.2254C40.912 9.53005 40.9323 9.855 40.9323 10.2612C40.9323 11.6829 40.9323 13.1046 40.9323 14.5669V16.8212H43.6161V16.1307C43.6161 15.4605 43.6161 14.7903 43.6161 14.0997V14.0794C43.6365 12.5156 43.6365 10.8705 43.5958 9.2254Z"
      fill="white"
    />
    <path
      d="M28.9166 10.9265C28.9166 11.942 28.571 12.8559 27.9 13.5261C27.2698 14.1558 26.4361 14.501 25.5009 14.501C23.549 14.501 22.0851 12.9778 22.0851 10.9468C22.0851 9.93133 22.4511 8.99708 23.1017 8.32686C23.732 7.69726 24.5859 7.35199 25.5212 7.35199C27.4324 7.3723 28.8963 8.91584 28.9166 10.9265ZM29.9129 6.58022C28.7743 5.42257 27.2088 4.79297 25.5212 4.79297C22.0038 4.81328 19.4013 7.35199 19.3403 10.8453C19.32 12.5919 19.9503 14.1964 21.1295 15.3743C22.2478 16.4914 23.7727 17.1007 25.3992 17.1007C25.4399 17.1007 25.4805 17.1007 25.5212 17.1007C28.9573 17.1007 31.6208 14.5213 31.6818 11.1296C31.7021 9.36266 31.0718 7.75819 29.9129 6.58022Z"
      fill="white"
    />
    <path
      d="M118.316 10.9265C118.316 11.942 117.97 12.8559 117.299 13.5261C116.669 14.1558 115.835 14.501 114.9 14.501C112.948 14.501 111.484 12.9778 111.484 10.9468C111.484 9.93133 111.85 8.99708 112.501 8.32686C113.131 7.69726 113.985 7.35199 114.92 7.35199C116.832 7.3723 118.296 8.91584 118.316 10.9265ZM119.312 6.58022C118.174 5.42257 116.628 4.79297 114.941 4.79297H114.9C111.383 4.81328 108.78 7.35199 108.719 10.8453C108.699 12.5919 109.329 14.1964 110.508 15.3743C111.627 16.4914 113.152 17.1007 114.778 17.1007C114.819 17.1007 114.859 17.1007 114.9 17.1007C118.336 17.1007 121 14.5213 121.061 11.1296C121.101 9.36266 120.471 7.75819 119.312 6.58022Z"
      fill="white"
    />
  </svg>
);
