import { BoxProps } from '@chakra-ui/react';
import { Paginator, PaginatorPageChangeEvent } from 'primereact/paginator';
import { TDataTablePager } from '~app/types';
import { MBox } from '../chakra';
import { getPaginatorTemplate } from './MDataTable';

interface MPaginatorProps extends BoxProps {
  pager: TDataTablePager;
  totalRecords: number;
  onPageChange: (event: PaginatorPageChangeEvent) => void;
}

export const MPaginator = (props: MPaginatorProps) => {
  const { pager, totalRecords, onPageChange, ...rest } = props;

  return (
    <MBox className="monetize-pagination" {...rest}>
      <Paginator
        first={pager.first}
        rows={pager.rows}
        totalRecords={totalRecords}
        rowsPerPageOptions={[20, 50, 100]}
        onPageChange={onPageChange}
        template={getPaginatorTemplate(pager.page + 1)}
      />
    </MBox>
  );
};
