import {
  FocusLock,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  useDisclosure,
} from '@chakra-ui/react';
import lodashGet from 'lodash/get';
import isObject from 'lodash/isObject';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { MdFilterAlt, MdFilterList } from 'react-icons/md';
import { useNonInitialEffect } from '../../../../hooks/useNonInitialEffect';
import { DatePicker } from '../../DatePicker/DatePicker';
import MButton from '../../MButton';
import MCustomIconButton from '../../MCustomIconButton';
import MCustomSelect from '../../MCustomSelect/MCustomSelect';
import { MainSearchInputV2 } from '../../MCustomSelect/components/MainSearchInputV2';
import MFormField from '../../MFormField';
import MText from '../../MText';
import { MFlex } from '../../chakra';
import {
  DataTableFilterRef,
  DateFilterComparison,
  DateFilterData,
  FilterProps,
  isBetweenComparison,
  isBlankNotBlankComparison,
} from '../DataTableTypes';

// Stable value to avoid re-renders
const EMPTY_ARRAY: Array<{ label: string; value: string | number | boolean }> =
  [];

const COMPARISON_VALUES = [
  { title: 'Is Blank', value: DateFilterComparison.IS_BLANK },
  { title: 'Is Not Blank', value: DateFilterComparison.IS_NOT_BLANK },
  { title: 'Equals', value: DateFilterComparison.EQUALS },
  { title: 'Greater Than', value: DateFilterComparison.GREATER_THAN },
  {
    title: 'Greater Than or Equal',
    value: DateFilterComparison.GREATER_THAN_EQUAL,
  },
  { title: 'Less Than', value: DateFilterComparison.LESS_THAN },
  { title: 'Less Than or Equal', value: DateFilterComparison.LESS_THAN_EQUAL },
  {
    title: 'Between (Exclusive)',
    value: DateFilterComparison.BETWEEN_EXCLUSIVE,
  },
  {
    title: 'Between (Inclusive)',
    value: DateFilterComparison.BETWEEN_INCLUSIVE,
  },
];

/**
 * Set Filter Component
 */
export const DataTableHeaderDateFilter = forwardRef<
  DataTableFilterRef,
  FilterProps<any>
>(({ column, table }, ref) => {
  useImperativeHandle(
    ref,
    (): DataTableFilterRef => ({
      resetFilters: () => {
        handleResetFilters();
      },
      setFilterValue: ({ columnKey, value }) => {
        if (
          columnKey === column.id &&
          isObject(value) &&
          'comparison' in value &&
          'firstDate' in value
        ) {
          column.setFilterValue(value);
        }
      },
    }),
  );

  const key = lodashGet(column, 'columnDef.accessorKey', column.id);
  const filterValues =
    table.options.meta?.filterValues?.get(key) || EMPTY_ARRAY;

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [selectedComparison, setSelectedComparison] = useState(
    DateFilterComparison.GREATER_THAN,
  );
  const [selectedFirstDate, setSelectedFirstDate] = useState<string | null>(
    null,
  );
  const [selectedSecondDate, setSelectedSecondDate] = useState<string | null>(
    null,
  );

  useNonInitialEffect(() => {
    const dateFilterData: DateFilterData = {
      comparison: selectedComparison,
      firstDate: selectedFirstDate,
      secondDate: selectedSecondDate,
    };
    column.setFilterValue(dateFilterData);
  }, [column, selectedComparison, selectedFirstDate, selectedSecondDate]);

  const handleResetFilters = () => {
    setSelectedComparison(DateFilterComparison.GREATER_THAN);
    setSelectedFirstDate(null);
    setSelectedSecondDate(null);
    const dateFilterData: DateFilterData = {
      comparison: selectedComparison,
      firstDate: selectedFirstDate,
      secondDate: selectedSecondDate,
    };
    column.setFilterValue(dateFilterData);
    handleClose();
  };

  const handleClose = () => {
    onClose();
  };

  const filtersApplied =
    isBlankNotBlankComparison(selectedComparison) ||
    (!!filterValues &&
      selectedFirstDate &&
      (!isBetweenComparison(selectedComparison) || !!selectedSecondDate));

  return (
    <Popover
      placement="auto-start"
      trigger="click"
      offset={[0, 0]}
      variant="responsive"
      isOpen={isOpen}
      onClose={handleClose}
      strategy="fixed"
      isLazy
    >
      <PopoverTrigger>
        <MCustomIconButton
          data-testid={`table-column-filter-${key}`}
          btnSize={5}
          onClick={onOpen}
          icon={filtersApplied ? MdFilterAlt : MdFilterList}
          variant="icon"
          ml="2"
          color={filtersApplied ? 'tIndigo.base' : 'tPurple.base'}
          iconColor={filtersApplied ? 'tIndigo.base' : 'tPurple.base'}
        />
      </PopoverTrigger>
      <PopoverContent>
        {isOpen && (
          <FocusLock>
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverHeader fontWeight="semibold">
              <MText pt={4} px={4}>
                Filter
              </MText>
            </PopoverHeader>
            <PopoverBody minW="304px">
              <MFormField label="Comparison">
                <MCustomSelect
                  useMainInputAsSearchInput
                  MainInputComponent={MainSearchInputV2}
                  items={COMPARISON_VALUES}
                  itemTitle="title"
                  itemValue="value"
                  value={selectedComparison}
                  onBlur={(ev) => ev.preventDefault()}
                  onChange={(value: any) =>
                    setSelectedComparison(value as DateFilterComparison)
                  }
                />
              </MFormField>
              {!isBlankNotBlankComparison(selectedComparison) && (
                <>
                  <MFormField
                    pt={2}
                    label={
                      isBetweenComparison(selectedComparison)
                        ? 'Start'
                        : undefined
                    }
                  >
                    <DatePicker
                      value={selectedFirstDate}
                      onChange={(value) => {
                        setSelectedFirstDate(value);
                      }}
                      clearable
                    />
                  </MFormField>
                  {isBetweenComparison(selectedComparison) && (
                    <MFormField pt={2} label="End">
                      <DatePicker
                        value={selectedSecondDate}
                        onChange={(value) => {
                          setSelectedSecondDate(value);
                        }}
                        clearable
                      />
                    </MFormField>
                  )}
                </>
              )}
            </PopoverBody>
            <PopoverFooter>
              <MFlex justifyContent="space-between">
                <MButton
                  variant="tertiary"
                  size="sm"
                  onClick={handleResetFilters}
                >
                  Reset
                </MButton>
              </MFlex>
            </PopoverFooter>
          </FocusLock>
        )}
      </PopoverContent>
    </Popover>
  );
});
