import find from 'lodash/find';
import React, { useEffect, useRef, useState } from 'react';
import { Control, Controller, UseFormSetValue } from 'react-hook-form';
import {
  MBox,
  MCustomMultiSelect,
  MFormField,
  MLockedTextOrContent,
} from '~app/components/Monetize';
import { MCustomMultiSelectRef } from '~app/components/Monetize/MCustomSelect/MCustomMultiSelect';
import { MSearchInputWithClear } from '~app/components/Monetize/MSearchInputWithClear';
import {
  CUSTOM_SELECT_SUBTITLE_TYPE,
  OPTION_TYPE_FIELD,
} from '~app/constants/customSelect';
import {
  AmountUnitTypeEnum,
  IDiscount,
  IQuoteItemReqSchema,
  IQuoteOfferingReqSchema,
  IQuoteOfferingRespSchema,
} from '~app/types';
import { CustomSelectItem } from '~app/types/mCustomSelectTypes';

export interface QuoteOfferingDiscountProps {
  isOpen: boolean;
  quoteOffering?: IQuoteOfferingRespSchema | null;
  quoteItems?: IQuoteItemReqSchema[];
  errors: any;
  control: Control<IQuoteOfferingReqSchema>;
  offeringId: string;
  rateId?: string | null;
  discounts: IDiscount[] | null;
  discountIds?: string[] | null;
  enableCustomDiscounts: boolean;
  isRemoved?: boolean;
  disabled?: boolean;
  isReadOnly?: boolean;
  handleQuoteOfferingWithoutDirtyCheck: () => void;
  setEnableCustomDiscounts: React.Dispatch<React.SetStateAction<boolean>>;
  setValue: UseFormSetValue<IQuoteOfferingReqSchema>;
}

export const QuoteOfferingDiscount = ({
  isOpen,
  quoteOffering,
  quoteItems,
  errors,
  control,
  offeringId,
  rateId,
  discounts,
  discountIds,
  enableCustomDiscounts,
  isRemoved,
  disabled,
  isReadOnly,
  handleQuoteOfferingWithoutDirtyCheck,
  setEnableCustomDiscounts,
  setValue,
}: QuoteOfferingDiscountProps) => {
  const customSelectRef = useRef<MCustomMultiSelectRef>();
  const [searchTerm, setSearchTerm] = useState('');
  const [visibleItems, setVisibleItems] = useState(discounts || []);
  const [selectedItemCount, setSelectedItemCount] = useState(0);

  useEffect(() => {
    if (!discounts || !searchTerm) {
      setVisibleItems(discounts || []);
    }
    const searchTermLowercase = (searchTerm || '').toLowerCase();
    setVisibleItems(() =>
      (discounts || []).filter(({ name }) =>
        (name || '').toLowerCase().includes(searchTermLowercase),
      ),
    );
  }, [discounts, searchTerm]);

  useEffect(() => {
    // if discountIds changes, use that value
    // otherwise update based on user selection in real time
    setSelectedItemCount(discountIds?.length || 0);
  }, [discountIds]);

  if (
    !quoteOffering ||
    !quoteOffering?.items?.length ||
    !offeringId ||
    !rateId ||
    !discounts
  ) {
    return <MBox />;
  }

  const getDiscountPlaceholder = () => {
    if (enableCustomDiscounts) {
      return 'Custom Discount';
    }
    if (discountIds && discountIds?.length > 0) {
      if (discountIds?.length === 1) {
        // find is returning types other than IDiscount. Use any to stop it complaining about accessing name in return.
        const selected: any = find(discounts, {
          id: discountIds[0],
        });
        return selected
          ? selected?.name
          : `${discountIds.length} Discounts Selected`;
      }
      return `${discountIds.length} Discounts Selected`;
    }
    return 'Select';
  };

  const getCollapsedDiscount = () => {
    if (enableCustomDiscounts) {
      return 'Custom Discount';
    } else if (discountIds && discountIds?.length === 1) {
      return discounts.find(({ id }) => id === discountIds[0])?.name;
    } else if (discountIds && discountIds?.length > 1) {
      return `${discountIds.length} Discounts`;
    } else {
      return '';
    }
  };

  function handleDiscountFilterChange(value: string) {
    setSearchTerm(value);
  }

  function handleClear() {
    customSelectRef.current?.clearAll();
    setSelectedItemCount(0);
  }

  function handleAddOrRemoveItem(value: string, allValues?: string[]) {
    setSelectedItemCount(allValues?.length || 0);
  }

  function handleOpenStateChange(_isOpen: boolean) {
    if (!_isOpen) {
      setSearchTerm('');
      setSelectedItemCount(discountIds?.length || 0);
    }
  }

  /**
   * Closes the SelectBox and enables custom discount and sets PERCENTAGE as default custom discountType
   * and also sets customDiscountAmountOrPercent 0
   */
  function handleCustomDiscount({ onClose }: { onClose?: () => void }) {
    onClose?.();
    setValue('discountIds', []);
    setEnableCustomDiscounts(true);

    // Resets to default custom discount type and custom discount amount or percent if not set
    quoteItems
      ?.filter(
        ({ customDiscountAmountOrPercent, customDiscountType }) =>
          customDiscountAmountOrPercent === null || customDiscountType === null,
      )
      .map((_, index) => {
        setValue(
          `items.${index}.customDiscountType`,
          AmountUnitTypeEnum.PERCENTAGE,
        );
        setValue(`items.${index}.customDiscountAmountOrPercent`, 0);
      });
  }

  const discountPrependItems: CustomSelectItem[] = [
    {
      item: {
        id: 'CUSTOM_DISCOUNT',
        name: '+ Custom Discount',
      },
      onClick: handleCustomDiscount,
      hasDivider: false,
      isAction: true,
    },
  ];
  if (discounts?.length > 0) {
    discountPrependItems[0].hasDivider = true;
    discountPrependItems.push({
      item: {
        id: 'EXISTING_DISCOUNTS',
        name: 'EXISTING DISCOUNTS',
        [OPTION_TYPE_FIELD]: CUSTOM_SELECT_SUBTITLE_TYPE,
      },
      isHeading: true,
      render: () => (
        <MSearchInputWithClear
          heading="EXISTING DISCOUNTS"
          placeholder="Search Discounts"
          value={searchTerm}
          selectedItemCount={selectedItemCount}
          visibleItemCount={visibleItems.length || 0}
          onChange={handleDiscountFilterChange}
          onClearAll={handleClear}
        />
      ),
    });
  }

  return (
    <MFormField error={errors.discountIds} alignSelf="end">
      <MLockedTextOrContent
        text={getCollapsedDiscount()}
        h={8}
        textProps={{ noOfLines: 1 }}
        isLocked={isRemoved || isReadOnly || !isOpen}
        blankText=""
      >
        <Controller
          name="discountIds"
          control={control}
          defaultValue={discountIds}
          render={({ field: { onChange, ref, ...rest } }) => (
            <MCustomMultiSelect
              ref={(refEvent: any) => {
                ref(refEvent);
                customSelectRef.current = refEvent;
              }}
              isLazy
              isDisabled={isRemoved || disabled}
              items={visibleItems}
              itemValue="id"
              itemTitle="name"
              onChange={(e) => {
                // if selecting a discount disable custom discounts
                enableCustomDiscounts && setEnableCustomDiscounts(false);
                onChange(e);
                handleQuoteOfferingWithoutDirtyCheck();
                setSearchTerm('');

                // Resets custom discount type and custom discount amount or percent to null if set
                quoteItems
                  ?.filter(
                    ({ customDiscountAmountOrPercent, customDiscountType }) =>
                      customDiscountAmountOrPercent !== null ||
                      customDiscountType !== null,
                  )
                  .map((_, index) => {
                    setValue(`items.${index}.customDiscountType`, null);
                    setValue(
                      `items.${index}.customDiscountAmountOrPercent`,
                      null,
                    );
                  });
              }}
              onRemove={handleAddOrRemoveItem}
              onAdd={handleAddOrRemoveItem}
              onOpenStateChange={handleOpenStateChange}
              closeButtonText="Apply"
              placeholder={getDiscountPlaceholder()}
              popOverContentProps={{ minWidth: '300px' }}
              inputProps={{
                _placeholder: { color: 'tPurple.dark' },
                _disabled: {
                  // By default we hide placeholder when disabled, but here we want it visible
                  _placeholder: {
                    color: 'tGray.darkPurple',
                  },
                },
              }}
              prependItemsOutsideScrollArea
              prependItems={discountPrependItems}
              isReadOnly={isReadOnly}
              {...rest}
            />
          )}
        />
      </MLockedTextOrContent>
    </MFormField>
  );
};

export default QuoteOfferingDiscount;
