import { ToastId, useToast } from '@chakra-ui/react';
import { useEffect, useRef } from 'react';
import { useRecoilValue } from 'recoil';
import { activeToastState } from '~app/store/global.store';
import { MToast } from '~components/Monetize';

const AppToastWrapper = (props: any) => {
  const toastData = useRecoilValue(activeToastState);
  const toast = useToast();
  const toastIdRef = useRef<any>();

  function close(id?: ToastId) {
    if (id) {
      toast.close(id);
    }
  }

  useEffect(() => {
    if (!toastData) {
      return;
    }

    toastIdRef.current = toast({
      title: toastData?.summary,
      description: toastData?.detail,
      status: toastData?.severity,
      duration: toastData?.life,
      position: 'top-right',
      render: ({ id }) => <MToast id={id} onClose={close} {...toastData} />,
    });
  }, [toastData]);

  return <></>;
};

export default AppToastWrapper;
