import { MText } from '~app/components/Monetize';
import { StandardOptionRenderProps } from '~app/types/mCustomSelectTypes';
import { Highlighter } from '../../MPageSearchInput/Highlighter';

export const StandardOptionRender = ({
  content,
  isSubtitle,
  query = '',
  useHighlightedItemList = false,
}: StandardOptionRenderProps) => {
  if (useHighlightedItemList) {
    return (
      <Highlighter
        textToHighlight={content}
        searchWords={[query]}
        fontWeight="normal"
        color="tPurple.dark"
      />
    );
  }
  return (
    <MText
      color="inherit"
      display="block"
      fontSize={isSubtitle ? 'xs' : 'sm'}
      fontWeight="normal"
      mt="0"
      noOfLines={1}
    >
      {content}
    </MText>
  );
};
