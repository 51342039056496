export const ACCEPT_FILES: any = {
  pdf: {
    accepted: ['application/pdf'],
    label: 'PDF',
  },
  '*': {
    accepted: ['*'],
    label: 'Any type',
  },
  csv: {
    accepted: ['text/csv', 'text/plain'],
    label: 'CSV',
  },
  png: {
    accepted: ['image/png'],
    label: 'PNG',
  },
  xlsx: {
    accepted: [
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ],
    label: 'XLSX',
  },
};
