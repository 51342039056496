import { useQuery } from '@tanstack/react-query';
import { logger } from '../../services/logger';
import { ApiResponse, SearchResult } from './AppSupport.types';

const README_SEARCH_URL = 'https://docs-search.monetizeplatform.com/search';

export const useDocsSearch = (isOpen: boolean, query: string) => {
  return useQuery<SearchResult[]>(['app', 'docs', query], {
    queryFn: async () => {
      if (!query) {
        return [];
      }
      const url = new URL(README_SEARCH_URL);
      url.searchParams.append('search', query);

      const response = await fetch(url.toString());

      if (!response.ok) {
        logger.error(await response.text());
        throw new Error('Failed to fetch search results');
      }

      return response
        .json()
        .then(({ results }: ApiResponse) =>
          results.filter(
            (result) => !result.isReference && result.indexName !== 'Discuss',
          ),
        );
    },
    enabled: isOpen,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    placeholderData: [],
  });
};
