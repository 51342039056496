import { forwardRef, useState } from 'react';
import { composeGetQuery } from '../../../../api/utils';
import { MCustomSelect, MFlex, MText } from '../../../../components/Monetize';
import { MainSearchInputV2 } from '../../../../components/Monetize/MCustomSelect/components/MainSearchInputV2';
import { Highlighter } from '../../../../components/Monetize/MPageSearchInput/Highlighter';

const listApiConfig = composeGetQuery({
  first: 0,
  rows: 25,
  page: 0,
  sortField: 'name',
  sortOrder: 1,
});

interface OneTimeInvoiceOfferingDropdownProps {
  name?: string;
  value?: string;
  onBlur?: () => void;
  onChange: (offeringId: string) => void | Promise<void>;
}

export const OneTimeInvoiceOfferingDropdown = forwardRef<
  HTMLDivElement,
  OneTimeInvoiceOfferingDropdownProps
>(({ onChange, value, ...rest }: OneTimeInvoiceOfferingDropdownProps, ref) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = async (value: string) => {
    try {
      setIsLoading(true);
      await onChange(value);
    } catch (ex) {
      //
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <MCustomSelect
      ref={ref}
      useMainInputAsSearchInput
      MainInputComponent={MainSearchInputV2}
      useHighlightedItemList
      endpoint={`/api/offerings/summary`}
      additionalSearchParams={listApiConfig}
      getByIdEndpointFn={(recordId) => `/api/offerings/summary?id=${recordId}`}
      itemTitle="name"
      itemValue="id"
      loading={isLoading}
      renderItemContent={({ item, query }) => (
        <MFlex
          direction="column"
          overflow="hidden"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          w="100%"
        >
          <MFlex justifyContent="space-between" w="100%">
            <Highlighter
              title={item.name}
              color="tPurple.dark"
              fontSize="sm"
              fontWeight="medium"
              textToHighlight={item.name}
              searchWords={[query]}
              whiteSpace="normal"
            />
          </MFlex>
          <MText color="tGray.darkPurple" fontSize="xs">
            {item.id}
          </MText>
        </MFlex>
      )}
      value={value}
      onChange={(value) => handleChange(value as any)}
      isLazy
      {...rest}
    />
  );
});
