import * as z from 'zod';

export enum ApiKeyStatusEnum {
  ACTIVE = 'ACTIVE',
  DEACTIVATED = 'DEACTIVATED',
}

export interface IApiKey {
  id: string;
  createdAt: string;
  name: string;
  status: ApiKeyStatusEnum;
  roleIds: string[];
}

export const SettingApiKeyFormSchema = z.object({
  name: z.string().nonempty('Name cannot be empty'),
  roleIds: z.array(z.object({ id: z.string(), name: z.string() })),
});
export type ISettingApiKeyFormSchema = z.infer<typeof SettingApiKeyFormSchema>;
