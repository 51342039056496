import { ControllerRenderProps } from 'react-hook-form';
import { MCustomSelect } from '~app/components/Monetize';
import { GuidedQuotingVariables } from '~app/constants/guidedQuoting';
import {
  GuidedQuotingReq,
  ICustomFieldResSchema,
  Maybe,
  QuestionCompareFromEnum,
  QuestionFilterByEnum,
  QuestionReq,
  QuestionTypesEnum,
} from '~app/types';
import { getFirstValue } from '~app/utils';
import { objectToSelectOptions } from '~app/utils/misc';
import { GuidedQuotingCompareToConstant } from './GuidedQuotingCompareToConstant';
import { GuidedQuotingCompareToQuestion } from './GuidedQuotingCompareToQuestion';

interface CompareToProps {
  responseType: QuestionTypesEnum;
  filterBy: QuestionFilterByEnum;
  compareFrom: QuestionCompareFromEnum | null | undefined;
  field: ControllerRenderProps<GuidedQuotingReq, any>;
  questions: QuestionReq[];
  customFieldKey: Maybe<string>;
  customFieldsForSelectedEntity: ICustomFieldResSchema[];
  index: number;
}

export const GuidedQuotingCompareTo = ({
  responseType,
  filterBy,
  compareFrom,
  field,
  questions,
  index,
  customFieldKey,
  customFieldsForSelectedEntity,
}: CompareToProps) => {
  const firstValue = getFirstValue(field.value);
  if (filterBy === 'CONSTANT') {
    return (
      <GuidedQuotingCompareToConstant
        responseType={responseType}
        compareFrom={compareFrom}
        field={field}
        customFieldKey={customFieldKey}
        customFieldsForSelectedEntity={customFieldsForSelectedEntity}
        firstValue={firstValue}
      />
    );
  } else if (filterBy === 'QUESTION') {
    return (
      <GuidedQuotingCompareToQuestion
        responseType={responseType}
        compareFrom={compareFrom}
        field={field}
        questions={questions}
        index={index}
        firstValue={firstValue}
      />
    );
  } else if (filterBy === 'VARIABLE') {
    return (
      <MCustomSelect
        items={objectToSelectOptions(GuidedQuotingVariables)}
        {...field}
        value={firstValue}
      />
    );
  }

  return null;
};
