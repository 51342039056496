import { AlertProps } from '@chakra-ui/react';
import { MAlert } from '~app/components/Monetize';
import { AlertType } from '~app/components/Monetize/MAlert';

interface QuoteBannerItemProps extends AlertProps {
  children: React.ReactElement | any;
  onDismiss: () => void;
  type?: AlertType;
}

export const QuoteBannerItem = ({
  children,
  onDismiss,
  type,
}: QuoteBannerItemProps) => {
  return (
    <MAlert
      alertIconProps={{ boxSize: 4 }}
      alertDescriptionProps={{ fontSize: 'sm' }}
      alignSelf="stretch"
      type={type}
      dismissable
      onDismiss={onDismiss}
    >
      {children}
    </MAlert>
  );
};
