import { Suspense, forwardRef, lazy } from 'react';
import type { MEditorProps } from './MEditor_Lazy';
import { MBox, MSkeleton } from './chakra';

const MEditor_Lazy = lazy(() => import('./MEditor_Lazy'));

const MIN_HEIGHT = 200;
const HEIGHT_BUFFER = 100;

const LOADING_SKELETON = () => {
  return (
    <MBox pb={100} h={MIN_HEIGHT + HEIGHT_BUFFER} w="100%">
      <MSkeleton height="39px" width="100%" />
      <MSkeleton height="178px" width="100%" mt="1" />
      <MSkeleton height="25px" width="100%" mt="1" />
    </MBox>
  );
};

export const MEditor = forwardRef<any, MEditorProps>(
  (props: MEditorProps, ref: any) => {
    return (
      <Suspense fallback={<LOADING_SKELETON />}>
        <MEditor_Lazy {...props} ref={ref} />
      </Suspense>
    );
  },
);

export default MEditor;
