import { useEffect } from 'react';
import { LogoutMetabase } from '~app/components/Metabase/MetabaseLogout';
import { useAuth } from '~app/services/auth0';
import { onHeapLogout } from '~app/services/heap';
import { clearLocalStorage } from '~app/store/store.utils';
import AppLoading from '~components/App/AppLoading';

/**
 * this route will clean localstorage and logout auth0
 */
const Logout = () => {
  const { logoutAuth0 } = useAuth();

  useEffect(() => {
    clearLocalStorage();
    sessionStorage.clear();
    onHeapLogout();
    logoutAuth0();
  }, []);

  return (
    <>
      <AppLoading />
      <LogoutMetabase />
    </>
  );
};

export default Logout;
