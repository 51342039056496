import { Icon, Spinner } from '@chakra-ui/react';
import React, { FC } from 'react';
import { FiChevronDown } from 'react-icons/fi';
import { MdClose } from 'react-icons/md';
import { MCustomIconButton, MFlex, MText } from '~app/components/Monetize';
import { MTertiaryArrowButton } from '~app/components/Monetize/MTertiaryArrowButton';
import { CustomSelectStateProps } from '~app/types/mCustomSelectTypes';
import { truncateMiddle } from '~app/utils';
export interface QuoteSelectInputProps
  extends Pick<
    CustomSelectStateProps,
    | 'getTitleFromValue'
    | 'disabled'
    | 'isReadOnly'
    | 'onOpen'
    | 'onClose'
    | 'loading'
    | 'loadingRenderer'
    | 'value'
    | 'onToggleOpen'
    | 'externalLoading'
    | 'placeholder'
    | 'isOpen'
    | 'items'
    | 'cursor'
    | 'setCursor'
    | 'handleChange'
    | 'showContentInInput'
    | 'renderContentInInput'
    | 'inputProps'
    | 'renderRightElement'
    | 'clearable'
    | 'onClear'
    | 'onChange'
    | 'queryResultCount'
    | 'query'
    | 'onQueryKeyDown'
    | 'onQueryChange'
    | 'displayAvatar'
    | 'renderInputLeftElement'
    | 'inputLeftElementContent'
    | 'isSubtitleItem'
    | 'isTruncatedInputTitle'
  > {
  ref: any;
}
export const QuoteSelectInput: FC<QuoteSelectInputProps> = React.forwardRef<
  any,
  QuoteSelectInputProps
>(
  (
    {
      getTitleFromValue,
      disabled,
      isReadOnly,
      onOpen,
      onClose,
      loading,
      loadingRenderer,
      value,
      onToggleOpen,
      externalLoading,
      placeholder,
      isOpen,
      showContentInInput,
      renderContentInInput,
      inputProps,
      renderRightElement,
      clearable,
      onClear,
      onChange,
      inputLeftElementContent,
      isTruncatedInputTitle,
    }: QuoteSelectInputProps,
    ref,
  ) => {
    const rightElement = renderRightElement && renderRightElement({ value });

    const handleOnClear = (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      if (onClear) {
        onClear();
      } else {
        onChange && onChange('' as any);
      }
    };

    let rightMargin = rightElement ? rightElement.width : 6;
    if (clearable && value && !disabled) {
      rightMargin += 4;
    }

    const title = getTitleFromValue(value) || '';
    const inputTitle = isTruncatedInputTitle ? truncateMiddle(title) : title;

    if (disabled || isReadOnly) {
      return (
        <>
          {showContentInInput &&
            renderContentInInput &&
            renderContentInInput({ onToggleOpen, inputTitle })}
          {showContentInInput && !renderContentInInput && (
            <MFlex align="center" onClick={onToggleOpen}>
              <MText
                fontWeight="normal"
                isTruncated
                noOfLines={2}
                whiteSpace="initial"
              >
                {inputTitle}
              </MText>
            </MFlex>
          )}
        </>
      );
    }

    const onBlur = (ev: any) => {
      // Don't close popover if focus is within the popover content
      if (
        ev.relatedTarget?.hasAttribute('data-custom-select-item') ||
        ev.relatedTarget?.classList?.contains('chakra-popover__content')
      ) {
        return;
      }
      onClose();
    };

    // We user using ubtton instead of input, we want to use fields from inputProps that are relevant to button
    const buttonProps = {
      height: inputProps?.height || 'inherit',
      maxWidth: inputProps?.maxWidth || '100%',
    };

    return (
      <MTertiaryArrowButton
        ref={ref}
        isOpen={isOpen}
        onClick={onToggleOpen}
        showArrow={false}
        onBlur={onBlur}
      >
        <MFlex alignItems="center" onClick={onToggleOpen} mr="2">
          {showContentInInput &&
            renderContentInInput &&
            renderContentInInput({ onToggleOpen, inputTitle, isOpen })}
          {showContentInInput && !renderContentInInput && (
            <MText
              fontWeight="normal"
              isTruncated
              color="inherit"
              whiteSpace="initial"
              textAlign="left"
              wordBreak="break-all"
              noOfLines={2}
            >
              {inputTitle}
            </MText>
          )}
          {!inputTitle && <MText color="tGray.darkPurple">{placeholder}</MText>}
        </MFlex>

        {!isReadOnly && (
          <MFlex px="0" mx="0">
            {rightElement?.content}
            {clearable && value && !disabled && (
              <MCustomIconButton
                icon={MdClose}
                bg="none"
                color="tPurple.dark"
                _hover={{
                  bg: 'none',
                }}
                ml="2"
                btnSize={4}
                padding={0}
                containerSize={4}
                onClick={handleOnClear}
              />
            )}
            {loading || externalLoading ? (
              <>{loadingRenderer ? loadingRenderer : <Spinner size="xs" />}</>
            ) : (
              <Icon
                as={FiChevronDown}
                fontSize="20"
                onClick={!disabled ? onToggleOpen : () => {}}
                zIndex="1"
                color="inherit"
                opacity={disabled ? 0.25 : 'unset'}
              />
            )}
          </MFlex>
        )}
      </MTertiaryArrowButton>
    );
  },
);
