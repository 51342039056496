import { usePrintCreditNoteToHtml } from '../../../../api/accountsService';
import { HtmlIFrameContentRenderer } from '../../../../components/Monetize/HtmlIFrameContentRenderer';

export const ViewCreditNoteHtml = ({
  creditNoteId,
  modifyDate,
}: {
  creditNoteId: string;
  modifyDate: string | undefined;
}) => {
  const { data, isLoading, isRefetching, error, isInitialLoading } =
    usePrintCreditNoteToHtml({
      creditNoteId: creditNoteId,
      lastModifiedTimestamp: modifyDate,
    });

  if (error) {
    // TODO: add error state
    return null;
  }

  return (
    <HtmlIFrameContentRenderer
      id="credit-note-content"
      html={data}
      isInitialLoading={isInitialLoading}
      isReFetching={isLoading || isRefetching}
    ></HtmlIFrameContentRenderer>
  );
};
