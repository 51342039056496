import isNumber from 'lodash/isNumber';
import { boolean, never, number, object, string, z } from 'zod';
import { getRequiredMessage } from '../utils/messages';
import { IInvoiceSettings } from './InvoiceSettingsTypes';
import { AccountStatusEnum } from './accountTypes';
import { AddressSchema } from './addressTypes';
import { IPaymentCollectAutoConfigResSchema } from './billGroupTypes';
import { ContactRequestSchema, ContactRespSchema } from './contactTypes';
import { GetCreditSchema } from './creditTypes';
import { CustomFieldRecordSchema } from './customFieldsTypes';
import { DiscountTypeEnumZ } from './discountTypes';
import {
  LegalEntitySnapshot,
  LegalEntitySnapshotSlim,
} from './legalEntityTypes';
import {
  BaseResponseOptionalSchema,
  BaseResponseSchema,
  Maybe,
} from './miscTypes';
import { OfferingTypesEnumZ } from './offeringTypes';
import { PriceModelEnumZ } from './priceTypes';
import { ProductTypeEnumZ } from './productTypes';
import { QuoteSettingsDefaultAddressSourceEnumZ } from './quoteSettingsAddressTypes';
import { AggregatedTransactablesResponse } from './transactableTypes';

export enum InvoiceStatusEnum {
  DRAFT = 'DRAFT',
  CANCELED = 'CANCELED',
  UNPAID = 'UNPAID',
  PENDING = 'PENDING',
  PAID = 'PAID',
  REVERSED = 'REVERSED',
}
export const InvoiceStatusEnumZ = z.nativeEnum(InvoiceStatusEnum);

export enum InvoiceItemTypeEnum {
  SUBSCRIPTION = 'SUBSCRIPTION',
  ONETIME = 'ONETIME',
  USAGE = 'USAGE',
}

export enum TaxCalculationStatusEnum {
  NOT_APPLICABLE,
  CALCULATED,
  FAILED,
  FINALIZED_WITH_ERROR,
}
export const TaxCalculationStatusEnumZ = z.nativeEnum(TaxCalculationStatusEnum);

// Ended up not using these enums, but they are here for reference
// this list is not exhaustive
// export enum InvoiceTaxErrorEnum {
//   customerAddressCouldNotResolve = 'customerAddressCouldNotResolve',
//   transactionFrozenForFiling = 'transactionFrozenForFiling',
//   productExternalIdUnknown = 'productExternalIdUnknown',
//   taxDateTooFarInFuture = 'taxDateTooFarInFuture',
//   invalidToken = 'invalidToken',
//   currencyCodeNotSupported = 'currencyCodeNotSupported',
//   transactionIdNotFound = 'transactionIdNotFound',
// }

// export const InvoiceTaxErrorEnumZ = z.nativeEnum(InvoiceTaxErrorEnum);

export const PaymentInvoiceSchema = object({
  /** @deprecated - this is still here and derived from settledDate */
  collectionDate: string(),
  creditCardType: string(),
  paidAmount: string(),
  paymentDescription: string(),
  paymentId: string(),
  paymentMethodName: string(),
  settledDate: string().nullish(),
  createDate: string(),
});
export type IPaymentInvoice = z.infer<typeof PaymentInvoiceSchema>;

// from /api/invoices/${invoiceId}:
export const InvoiceItemRespSchema = BaseResponseSchema.extend({
  addonId: string().nullable(),
  amount: number(),
  amountCredited: number().nullable(),
  amountWithoutDiscount: number(),
  amountWithoutTax: number(),
  amountWrittenOff: number().nullable(),
  description: string().nullable(),
  discount: number(),
  discountId: string().nullable(),
  discountType: DiscountTypeEnumZ,
  isAddOn: boolean(),
  multiTier: boolean(),
  offeringDescription: string(),
  offeringId: string(),
  offeringName: string(),
  offeringType: OfferingTypesEnumZ,
  periodEndDate: string(),
  periodStartDate: string(),
  priceModel: PriceModelEnumZ,
  productId: string(),
  productName: string(),
  productType: ProductTypeEnumZ,
  prorationMultiplier: number(),
  quantity: number(),
  quoteItemId: string().nullable(),
  rateId: string(),
  rateName: string(),
  subscription: boolean(),
  subscriptionId: string(),
  subscriptionItemId: string().nullable(),
  writeOffDate: string().nullable(),
  minCommitAdditionalBilledAmount: number(),
  minCommitAvailableAmount: number(),
});
export type IInvoiceItemRespSchema = z.infer<typeof InvoiceItemRespSchema>;

// used in POST or PUT /api/subscriptions:
// on subscription preview, the record is not saved so base properties like id may be null
// but a referenceId is returned (if the endpoint was passed one) that can group items as if there were a subscriptionId
export const IInvoiceItemUnsavedRespSchema = InvoiceItemRespSchema.merge(
  BaseResponseOptionalSchema,
).extend({ referenceId: string().nullable(), subscriptionId: never() });

export const AccountSnapshotSchema = object({
  id: string(),
  accountName: string().optional(),
  customId: z.string().nullish(),
  currency: z.string(),
  status: z.nativeEnum(AccountStatusEnum),
  isTestAccount: z.boolean(),
  customFields: z.record(z.string()),
});

export type IAccountSnapshotSchema = z.infer<typeof AccountSnapshotSchema>;

export const InvoiceAddPoNumberSchema = z.object({
  poNumber: z
    .string()
    .max(45, 'PO Number can be no more than 45 characters long')
    .optional(),
});
export type IInvoiceAddPoNumberSchema = z.infer<
  typeof InvoiceAddPoNumberSchema
>;

export const PaymentInstructionSchema = z.object({
  accountNumber: z.string(),
  bankName: z.string(),
  routingNumber: z.string(),
  swiftCode: z.string(),
});

export const InvoiceContactUpdateSchema = object({
  contactId: string().optional(),
  shippingContactId: string().optional(),
});
export type IInvoiceContactUpdateSchema = z.infer<
  typeof InvoiceContactUpdateSchema
>;

export const InvoiceUpdateSchema = object({
  purchaseOrderNumber: string().nullable(),
  vatNumber: string().nullable(),
  registrationNumber: string().nullable(),
  customFields: CustomFieldRecordSchema,
  invoiceMemo: string().nullable(),
});
export type IInvoiceUpdateSchema = z.infer<typeof InvoiceUpdateSchema>;

// from /api/invoices/${invoiceId}:
export const InvoiceRespSchema = BaseResponseSchema.extend({
  originalQuoteId: string().nullable(),
  account: AccountSnapshotSchema,
  amount: number(),
  amountCredited: number(),
  amountDue: number(),
  amountPaid: number().nullable(),
  amountWithoutDiscount: number(),
  amountWithoutTax: number(),
  amountWrittenOff: number(),
  billDate: string(),
  billGroup: object({ id: string() }),
  billGroupId: string(),
  billingContact: ContactRespSchema,
  shippingContact: ContactRespSchema,
  collectionAttempts: number(),
  contractId: string(),
  credits: GetCreditSchema.array(),
  currency: string(),
  discount: number(),
  dueDate: string(),
  endDate: string().nullable(),
  lastSent: string().nullable(),
  exchangeRate: string().nullable(),
  fromCompany: LegalEntitySnapshot,
  invoiceItems: InvoiceItemRespSchema.array(),
  invoiceTerms: string().nullish(),
  invoicingDate: string(),
  locale: string(),
  netTerms: string().nullable(),
  netTermsDays: number(),
  orderId: string().nullable(),
  paid: boolean(),
  paidDate: string().nullable(),
  pastDue: boolean().nullable(),
  paymentInstruction: PaymentInstructionSchema.nullable(),
  payments: PaymentInvoiceSchema.array(),
  purchaseOrderNumber: string().nullable(),
  quoteId: string().nullish(),
  recreatedInvoice: boolean(),
  startDate: string().nullable(),
  status: InvoiceStatusEnumZ,
  tax: number().nullable(),
  testAccount: boolean(),
  usageStartDate: string().nullable(),
  usageEndDate: string().nullable(),
  customFields: CustomFieldRecordSchema.optional(),
  taxError: string().nullable(),
  taxStatus: TaxCalculationStatusEnumZ,
  billingAddress: AddressSchema.nullish(),
  shippingAddress: AddressSchema.nullish(),
  vatNumber: string().nullable(),
  registrationNumber: string().nullable(),
  invoiceNumber: string().nullish(),
  creditRebilledTargetInvoiceId: string().nullable(),
  creditRebilledTimestamp: string().nullable(),
  creditRebilledSourceInvoiceId: string().nullable(),
  invoiceMemo: string().nullable(),
});
export type IInvoiceRespSchema = z.infer<typeof InvoiceRespSchema>;

// from /api/invoices and /api/account{accountId}/invoices
export const InvoiceSummaryRespSchema = InvoiceRespSchema.pick({
  account: true,
  amount: true,
  amountCredited: true,
  amountDue: true,
  amountPaid: true,
  amountWithoutTax: true,
  amountWrittenOff: true,
  billDate: true,
  billGroupId: true,
  billingAddress: true,
  collectionAttempts: true,
  contractId: true,
  createDate: true,
  createdBy: true,
  currency: true,
  customFields: true,
  dueDate: true,
  endDate: true,
  id: true,
  invoiceNumber: true,
  invoicingDate: true,
  lastModifiedBy: true,
  lastSent: true,
  modifyDate: true,
  netTerms: true,
  paidDate: true,
  pastDue: true,
  purchaseOrderNumber: true,
  quoteId: true,
  shippingAddress: true,
  startDate: true,
  status: true,
  usageEndDate: true,
  usageStartDate: true,
  registrationNumber: true,
  taxError: true,
  taxStatus: true,
  vatNumber: true,
}).extend({
  fromCompany: LegalEntitySnapshotSlim,
  billingContact: ContactRequestSchema,
  shippingContact: ContactRequestSchema,
  billGroup: z.object({
    id: z.string(),
    name: z.string(),
    ccEmails: z.string().array(),
    customFields: z.record(z.any()),
  }),
});
export type InvoiceSummaryResp = z.infer<typeof InvoiceSummaryRespSchema>;

export interface UnpaidInvoiceShareLink {
  invoice: InvoiceSummaryResp;
  invoiceShareLink: string;
}

export type IShareInvoiceRespSchema = {
  invoice: IInvoiceRespSchema;
  logo?: string;
  invoiceSettings: IInvoiceSettings;
  billGroupSettings: IPaymentCollectAutoConfigResSchema;
  unpaidInvoices?: UnpaidInvoiceShareLink[];
};

// used in POST or PUT /api/subscriptions:
// note the usual save properties of id, createDate, createdBy, modifyDate, modifiedBy will be returned as null
// when previewing a new subscription, because the back-end does not save the record
export const InvoiceUnsavedRespSchema = InvoiceRespSchema.merge(
  BaseResponseOptionalSchema,
).extend({
  invoiceItems: IInvoiceItemUnsavedRespSchema.array(),
});

export const InvoiceEmailTimingSchema = z.object({
  automatic: z.boolean(),
  attachInvoicePdf: z.boolean(),
  showUnpaidLinksInPDF: z.boolean(),
  fromName: z.string().max(255).nullish(),
  replyTo: z
    .string()
    .max(255)
    .nullish()
    .refine(
      (replyTo) => !replyTo || z.string().email().safeParse(replyTo).success,
      { message: 'Must be a valid email address' },
    ),
});

export type IInvoiceEmailTiming = z.infer<typeof InvoiceEmailTimingSchema>;

// GET /api/revenueRecognition/invoice/{invoiceId}
export const RecognitionScheduleResponse = z.object({
  recognitionDate: z.string(),
  startDate: z.string(),
  endDate: z.string(),
  recognizedAmount: z.number(),
  previouslyRecognizedAmount: z.number(),
  deferredAmount: z.number(),
});
export type IRecognitionScheduleResponse = z.infer<
  typeof RecognitionScheduleResponse
>;

export const ProductRevRecognitionScheduleResponse = z.object({
  productId: z.string(),
  productName: z.string(),
  startDate: z.string(),
  endDate: z.string(),
  schedule: RecognitionScheduleResponse.array(),
});
export type IProductRevRecognitionScheduleResponse = z.infer<
  typeof ProductRevRecognitionScheduleResponse
>;

export const InvoiceRevRecognitionScheduleResponse = z.object({
  invoiceId: z.string(),
  invoiceDate: z.string(),
  invoiceAmount: z.number(),
  currency: z.string(),
  schedulesByProduct: ProductRevRecognitionScheduleResponse.array(),
});
export type IInvoiceRevRecognitionScheduleResponse = z.infer<
  typeof InvoiceRevRecognitionScheduleResponse
>;

export interface InvoiceDisplayProps {
  loadingInvoice: boolean;
  invoiceSettings?: IInvoiceSettings;
  isInReview?: boolean;
  canPayInvoice?: boolean;
  isInvoiceSharePage?: boolean;
  unpaidInvoices?: UnpaidInvoiceShareLink[];
  onPayInvoice?: () => void;
  companyLogoData?: { isLoading: boolean; data?: Maybe<string> };
  tenantId?: string;
  invoiceSecretId?: string;
}

export const InvoiceAddressDataSchema = z.object({
  addressSource: QuoteSettingsDefaultAddressSourceEnumZ,
  shippingAddressId: z.string().nullish(),
  billingAddressId: z.string().nullish(),
});

export type IInvoiceAddressDataSchema = z.infer<
  typeof InvoiceAddressDataSchema
>;

export const OneTimeInvoiceReqSchema = z.object({
  accountId: z.string({ required_error: getRequiredMessage('Account') }),
  legalEntityId: z.string({
    required_error: getRequiredMessage('Legal Entity'),
  }),
  currency: z.string({ required_error: 'Currency' }),
  billGroupId: z.string({ required_error: 'Bill Group' }),
});

export type IOneTimeInvoiceReqSchema = z.infer<typeof OneTimeInvoiceReqSchema>;

export const CreditAndRebillReqSchema = z.object({
  addressInformation: z.object({
    addressSource: QuoteSettingsDefaultAddressSourceEnumZ,
    billingAddressId: z.string().nullish(),
    shippingAddressId: z.string().nullish(),
  }),
  billingContactId: z.string({
    required_error: getRequiredMessage('Billing Contact'),
  }),
  shippingContactId: z.string({
    required_error: getRequiredMessage('Shipping Contact'),
  }),
  purchaseOrderNumber: z.string().nullish(),
  registrationNumber: z.string().nullish(),
  vatNumber: z.string().nullish(),
  applyCreditNote: z.boolean(),
  customFields: CustomFieldRecordSchema,
  invoiceMemo: z.string().nullish(),
});

export type ICreditAndRebillReqSchema = z.infer<
  typeof CreditAndRebillReqSchema
>;

export const CreditAndRebillRespSchema = z.object({
  creditNoteId: z.string(),
  invoice: InvoiceRespSchema,
});

export type ICreditAndRebillRespSchema = z.infer<
  typeof CreditAndRebillRespSchema
>;

export interface MassEmailCustomerInvoicesResults {
  progress: number;
  invoiceId: string;
  success: boolean;
  error?: string;
}

export interface MassCreditInvoicesResults {
  progress: number;
  invoiceId: string;
  allocations?: AggregatedTransactablesResponse;
  creditId?: string;
  success: boolean;
  error?: string;
}

export const MassCreditInvoiceRowSchema = z
  .object({
    invoiceId: z.string(),
    accountId: z.string(),
    billGroupId: z.string(),
    amountDue: z.number(),
    currency: z.string(),
    amount: z
      .union([z.number(), z.string()])
      .refine(
        (val) => val !== null && val !== undefined && val !== '',
        getRequiredMessage('Amount'),
      )
      .transform((val) => Number(val)),
  })
  .superRefine(({ amount, amountDue, currency }, ctx) => {
    if (isNumber(amount) && amount > amountDue) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `Amount must not exceed invoice amount due of invoice`,
        path: ['amount'],
      });
    } else if (isNumber(amount) && amount <= 0) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `Amount must be greater than 0`,
        path: ['amount'],
      });
    }
  });
export const MassCreditInvoiceSchema = z.object({
  name: z
    .string()
    .min(1, getRequiredMessage('Name'))
    .max(255, 'Name must be 255 or fewer characters.'),
  reason: z
    .string()
    .min(1, getRequiredMessage('Reason'))
    .max(255, 'Reason must be 255 or fewer characters.'),
  customFields: CustomFieldRecordSchema.nullable(),
  rows: z.array(MassCreditInvoiceRowSchema),
});
export type MassCreditInvoiceRow = z.infer<typeof MassCreditInvoiceRowSchema>;
export type MassCreditInvoice = z.infer<typeof MassCreditInvoiceSchema>;

export interface CreditAndRebillRequest {
  invoice: IInvoiceRespSchema;
  payload: ICreditAndRebillReqSchema;
  applyCreditToOldInvoice: boolean;
}

export interface CreditAndRebillWithApplicationResponse {
  applyCreditToInvoiceError: Error | null;
  creditAndRebillResponse: ICreditAndRebillRespSchema;
}
