import React, { FunctionComponent as FC } from 'react';

import { useGetTeamsById } from '~app/api/settingsService';
import { useGetTenantUserById } from '~app/api/usersService';
import { useAuth } from '~app/services/auth0';
import {
  ApprovalStatusEnum,
  IApprovalRespSchema,
  IApprovalSchemaUI,
  IQuoteRespSchema,
  ITeamResponseSchema,
  IUsersInfo,
  QuoteStatusEnum,
} from '~app/types';
import { sortTeamUsers } from '~app/utils/settings';
import {
  MAvatar,
  MBox,
  MButton,
  MFlex,
  MGrid,
  MGridItem,
  MSpinner,
  MText,
  MTooltip,
} from '../Monetize';

interface ApprovalStepPopoverProps {
  quote: IQuoteRespSchema;
  approval: IApprovalRespSchema | IApprovalSchemaUI;
  description?: string | React.ReactElement;
  reasonTitle?: string;
  reason?: string;
  canDoActions: boolean;
  onClose: () => void;
  onApprove: () => void;
  onReject: () => void;
  onRetract: () => void;
}

const CAN_APPROVE_STATUS = new Set([
  ApprovalStatusEnum.NO_ACTION,
  ApprovalStatusEnum.DECLINED,
]);
const CAN_REJECT_STATUS = new Set([ApprovalStatusEnum.NO_ACTION]);
const CAN_RETRACT_STATUS = new Set([ApprovalStatusEnum.APPROVED]);
const ApprovalStepPopover: FC<ApprovalStepPopoverProps> = React.forwardRef<
  any,
  ApprovalStepPopoverProps
>((props: ApprovalStepPopoverProps, ref) => {
  const {
    quote,
    approval,
    reasonTitle,
    reason,
    canDoActions,
    onClose,
    onApprove,
    onReject,
    onRetract,
  } = props;

  const { tenantId } = useAuth();
  const { teamId, userId } = approval;
  const { data: teamUsers, isLoading: teamLoading } = useGetTeamsById(teamId, {
    select: (team: ITeamResponseSchema) => {
      return sortTeamUsers(team?.users, team?.owner);
    },
  });
  const { data: user, isLoading: userLoading } = useGetTenantUserById({
    tenantId,
    userId,
    options: {
      enabled: !!userId,
    },
  });

  const canApprove =
    quote.status !== QuoteStatusEnum.DENIED &&
    CAN_APPROVE_STATUS.has(approval.status as ApprovalStatusEnum);
  const canReject =
    quote.status !== QuoteStatusEnum.DENIED &&
    CAN_REJECT_STATUS.has(approval.status as ApprovalStatusEnum);
  const canRetract =
    quote.status !== QuoteStatusEnum.DENIED &&
    CAN_RETRACT_STATUS.has(approval.status as ApprovalStatusEnum);

  return (
    <MBox p={4}>
      <MBox color="tPurple.dark" fontSize="sm" fontWeight={400}>
        {approval.name}
      </MBox>
      {!!reason && (
        <MBox mt="4">
          <MText color="tGray.darkPurple" fontSize={10} fontWeight={400}>
            {reasonTitle || 'Reason'}
          </MText>
          <MBox color="tPurple.dark" fontSize="sm" fontWeight={400}>
            {reason}
          </MBox>
        </MBox>
      )}

      {!reason && (
        <MBox mt={4}>
          <MText color="tGray.darkPurple" fontSize={10} fontWeight={400}>
            Approver
          </MText>
          {!!teamId && teamLoading && <MSpinner />}
          {!!userId && userLoading && <MSpinner />}
          {!!teamId && (teamUsers as IUsersInfo[])?.length && !teamLoading && (
            <MGrid templateColumns="repeat(12, 1fr)" gap={1} mt={2}>
              {(teamUsers as IUsersInfo[]).map(
                (member: IUsersInfo, index: number) => (
                  <MGridItem colSpan={2} key={index}>
                    <MTooltip
                      shouldWrapChildren
                      label={member.name}
                      placement="bottom-start"
                    >
                      <MAvatar name={member.name} mr="1" />
                    </MTooltip>
                  </MGridItem>
                ),
              )}
            </MGrid>
          )}
          {!!userId && !!user && !userLoading && (
            <MGrid templateColumns="repeat(12, 1fr)" gap={1} mt={2}>
              <MGridItem colSpan={2}>
                <MTooltip
                  shouldWrapChildren
                  label={user.name}
                  placement="bottom-start"
                >
                  <MAvatar name={user.name} mr="1" />
                </MTooltip>
              </MGridItem>
            </MGrid>
          )}
        </MBox>
      )}

      {canDoActions && (
        <MFlex mt={4} justifyContent="space-between">
          {canReject ? <></> : <MBox />}
          {canReject && (
            <MButton
              variant={'deleteOutline'}
              onClick={() => {
                onReject();
                onClose();
              }}
              minW="unset"
            >
              Reject
            </MButton>
          )}

          {canApprove && (
            <MButton
              ml={2}
              onClick={() => {
                onApprove();
                onClose();
              }}
              minW="unset"
            >
              Approve
            </MButton>
          )}

          {/* Retract is commented out for now */}
          {/* {canRetract && (
            <MButton
              variant={'deleteOutline'}
              ml={2}
              onClick={() => {
                onRetract();
                onClose();
              }}
              minW="unset"
            >
              Retract
            </MButton>
          )} */}
        </MFlex>
      )}
    </MBox>
  );
});

export default ApprovalStepPopover;
