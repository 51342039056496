import { Switch } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { handleApiErrorToast } from '~app/api/axios';
import {
  MAccordion,
  MAccordionCustomButton,
  MAccordionCustomButtonItem,
  MBox,
  MButton,
  MFlex,
  MFormField,
  MGrid,
  MGridItem,
  MStack,
  MText,
} from '~app/components/Monetize';
import MEditor from '~app/components/Monetize/MEditor';
import { MSettingAccordionItem } from '~app/components/Monetize/MSettingAccordionItem';
import {
  IInvoiceTermsSettingsSchema,
  InvoiceTermsSettingsSchema,
} from '~app/types/InvoiceSettingsTypes';

export type InvoiceTermsProps = {
  invoiceTerms: IInvoiceTermsSettingsSchema;
  onSubmit: (data: IInvoiceTermsSettingsSchema) => Promise<void>;
};

export const InvoiceTerms = ({ invoiceTerms, onSubmit }: InvoiceTermsProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty, isValid },
    reset,
    watch,
  } = useForm<IInvoiceTermsSettingsSchema>({
    resolver: zodResolver(InvoiceTermsSettingsSchema),
    mode: 'onChange',
    defaultValues: {
      invoiceTermsActive: true,
      invoiceTerms: '',
    },
  });

  useEffect(() => {
    if (invoiceTerms) {
      reset({
        ...invoiceTerms,
        invoiceTerms: invoiceTerms.invoiceTerms || '',
      });
    }
  }, [invoiceTerms, reset]);

  const handleSubmitTerms = async (data: IInvoiceTermsSettingsSchema) => {
    try {
      setIsLoading(true);
      await onSubmit(data);
    } catch (err: any) {
      handleApiErrorToast(err);
    } finally {
      setIsLoading(false);
    }
  };

  const isInvoiceTermsActive = watch('invoiceTermsActive');
  const isSaveBtnDisabled =
    !isDirty || !isValid || !isInvoiceTermsActive || isLoading;

  return (
    <MStack w="100%" data-testid="approval-notes-config">
      <form
        onSubmit={handleSubmit(handleSubmitTerms)}
        data-testid="quote-setting-form"
      >
        <MAccordion allowMultiple>
          <MSettingAccordionItem
            description="These terms will be added to a section at the bottom of
                  invoices."
            renderAccordionButton={({
              isExpanded,
            }: {
              isExpanded: boolean;
            }) => (
              <MAccordionCustomButton
                isExpanded={isExpanded}
                label="Invoice Terms"
              >
                {!isExpanded ? (
                  <MAccordionCustomButtonItem
                    label="Status:"
                    value={isInvoiceTermsActive ? 'Active' : 'Inactive'}
                    w="70px"
                  />
                ) : (
                  <MFormField
                    error={errors?.invoiceTermsActive}
                    onClick={(ev) => ev.stopPropagation()}
                  >
                    <Controller
                      name="invoiceTermsActive"
                      control={control}
                      render={({ field: { value, onChange, ...rest } }) => (
                        <Switch
                          isChecked={value}
                          onChange={(
                            ev: React.ChangeEvent<HTMLInputElement>,
                          ) => {
                            onChange(ev.target.checked);
                            handleSubmit(handleSubmitTerms)();
                          }}
                          {...rest}
                        />
                      )}
                    />
                  </MFormField>
                )}
              </MAccordionCustomButton>
            )}
          >
            <MBox mt={2}>
              <MGrid templateColumns="repeat(12, 1fr)" gap={2}>
                <MGridItem colSpan={12}>
                  <MFormField error={errors.invoiceTerms}>
                    <Controller
                      name="invoiceTerms"
                      control={control}
                      render={({ field: { onChange, value, ...rest } }) => (
                        <MEditor
                          autoScroll
                          handleEditorChange={onChange}
                          boxProps={{ pb: 0, minHeight: 125 }}
                          init={{ height: 125 }}
                          disabled={!isInvoiceTermsActive}
                          value={value || ''}
                          {...rest}
                        />
                      )}
                    />
                  </MFormField>
                </MGridItem>
              </MGrid>
              <MGridItem colSpan={12} mt={8}>
                <MFlex justify="end">
                  <MButton
                    variant="primary"
                    onClick={handleSubmit(handleSubmitTerms)}
                    isDisabled={isSaveBtnDisabled}
                    isLoading={isLoading}
                  >
                    Save
                  </MButton>
                </MFlex>
              </MGridItem>
            </MBox>
          </MSettingAccordionItem>
        </MAccordion>
      </form>
    </MStack>
  );
};
