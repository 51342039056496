import { Controller, UseFormReturn } from 'react-hook-form';
import {
  MCustomNumberInput,
  MFormField,
  MFormFieldProps,
  MLockedTextOrContent,
} from '~app/components/Monetize';
import { MTextProps } from '~app/components/Monetize/MText';
import { INewQuoteTypeReqSchema } from '~app/types';
import { formatCurrency } from '~app/utils';

export interface ManualRenewalFormProps {
  manualRenewalForm: UseFormReturn<INewQuoteTypeReqSchema>;
  isEditable?: boolean;
  formFieldProps?: MFormFieldProps;
  lockedTextProps?: MTextProps;
  onSubmit: (data: INewQuoteTypeReqSchema) => void;
}

export const ManualRenewalForm = ({
  onSubmit,
  isEditable = true,
  manualRenewalForm: {
    control,
    formState: { errors },
    getValues,
    handleSubmit,
  },
  formFieldProps,
  lockedTextProps,
}: ManualRenewalFormProps) => {
  const previousArr = getValues('previousArr');
  const formattedPreviousArr = formatCurrency(previousArr);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <MFormField
        error={errors.previousArr}
        label="Previous ARR"
        isRequired
        {...formFieldProps}
      >
        <MLockedTextOrContent
          text={formattedPreviousArr || ''}
          textProps={lockedTextProps}
          isLocked={!isEditable}
        >
          <Controller
            name="previousArr"
            control={control}
            render={({ field: { value, onChange, ...rest } }) => (
              <MCustomNumberInput
                value={String(value ?? '')}
                inputMode="decimal"
                placeholder="0"
                onChange={(valueAsString: string) => onChange(valueAsString)}
                {...rest}
              />
            )}
          />
        </MLockedTextOrContent>
      </MFormField>
    </form>
  );
};
