import React, { FunctionComponent as FC } from 'react';
import {
  MAccordionCustomButton,
  MAccordionCustomButtonItem,
  MAccordionItem,
  MAccordionPanel,
  MFlex,
  MText,
  MTooltip,
} from '~app/components/Monetize';
import {
  ISubscriptionScheduledChange,
  AmountUnitTypeEnum,
  SubscriptionScheduledChangeEnum,
} from '~app/types';
import { SubscriptionBillingStatusDisplayText } from '~app/constants/subscriptions';
import isNil from 'lodash/isNil';
import { toDateShort } from '~app/utils/dates';

interface ScheduledChangeItemProps {
  previousChange: { units: number; productId: string }[];
  item: ISubscriptionScheduledChange;
}
export const ScheduledChangeItem: FC<ScheduledChangeItemProps> = ({
  previousChange,
  item,
}: ScheduledChangeItemProps) => {
  const discountTooltip = (
    <MFlex flexDirection="column">
      <MText textColor="white" fontStyle="italic">
        Discounts Applied:
      </MText>
      {(item.discounts || []).map((discount, i) => {
        const { name, discountType, amount } = discount;
        return (
          <MText key={`${name}-${i}`} textColor="white" fontWeight="bold">
            {`${name} - ${
              discountType === AmountUnitTypeEnum.PERCENTAGE
                ? `${amount}%`
                : `$${amount}`
            }`}
          </MText>
        );
      })}
    </MFlex>
  );

  return (
    <MAccordionItem border="1px solid #C8C5D4" borderRadius={10} my={2}>
      {({ isExpanded }) => (
        <>
          <MAccordionCustomButton
            isExpanded={isExpanded}
            label={toDateShort(item.effectiveDate)}
          >
            {item.rateName && (
              <MAccordionCustomButtonItem
                label="Rate:"
                valueTextProps={{ color: 'tPurple.safety' }}
                value={item.rateName}
              />
            )}
            {item.discounts && item.discounts.length > 0 && (
              <MTooltip label={discountTooltip} placement="bottom-start">
                <MFlex>
                  <MAccordionCustomButtonItem
                    label="Discounts:"
                    valueTextProps={{ color: 'tPurple.safety' }}
                    value={
                      item.discounts.length === 1
                        ? item.discounts[0].name
                        : 'Multiple'
                    }
                  />
                </MFlex>
              </MTooltip>
            )}
            {item.billingStatus && (
              <MAccordionCustomButtonItem
                label="Billing Status:"
                valueTextProps={{ color: 'tPurple.safety' }}
                value={SubscriptionBillingStatusDisplayText[item.billingStatus]}
              />
            )}
            <MAccordionCustomButtonItem
              label="Invoiced:"
              valueTextProps={{ color: 'tPurple.safety' }}
              value={
                item.status === SubscriptionScheduledChangeEnum.PENDING
                  ? 'No'
                  : 'Yes'
              }
            />
          </MAccordionCustomButton>
          <MAccordionPanel px={6} pt={0} pr={0}>
            {!!item.itemChanges.length && (
              <MFlex w="100%" mt={4} justifyContent="space-between">
                <MFlex flexDirection="column">
                  <MText fontSize="md" fontWeight="bold">
                    Product
                  </MText>
                  {item.itemChanges.map((itemChange, index) => {
                    return (
                      <MText fontSize="sm" py={2} key={index}>
                        {itemChange.productName || ''}
                      </MText>
                    );
                  })}
                </MFlex>
                <MFlex>
                  <MFlex py={2} flexDirection="column" width={120}>
                    <MText fontSize="md" fontWeight="bold">
                      Quantity
                    </MText>
                    {item.itemChanges.map((itemChange, index) => {
                      const previousProduct = previousChange.find(
                        (change) => change.productId === itemChange.productId,
                      );
                      return (
                        <MFlex alignItems="center" key={index}>
                          <MText fontSize="sm" py={2} color="tPurple.safety">
                            {itemChange.units}
                          </MText>
                          {previousProduct &&
                            !isNil(previousProduct.units) &&
                            itemChange.units !== previousProduct.units && (
                              <MText fontSize="xs" ml="1">
                                (
                                {itemChange.units - previousProduct.units > 0
                                  ? '+'
                                  : '-'}
                                {itemChange.units - previousProduct.units})
                              </MText>
                            )}
                        </MFlex>
                      );
                    })}
                  </MFlex>
                </MFlex>
              </MFlex>
            )}
          </MAccordionPanel>
        </>
      )}
    </MAccordionItem>
  );
};
