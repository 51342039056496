import { useState } from 'react';
import {
  MBox,
  MDivider,
  MFlex,
  MGrid,
  MGridItem,
  MText,
} from '~app/components/Monetize';
import { MemberAvatar } from '~app/components/Teams/MemberAvatar';
import { MemberExpand } from '~app/components/Teams/MemberExpand';
import { TEAM_TYPE_ENUM_DISPLAY } from '~app/constants/teams';
import { ITeamResponseSchema, TeamTypeEnum } from '~app/types';
const MAX_INITIAL_TEAMS = 19;

export const UserTeamCard = ({ team }: { team: ITeamResponseSchema }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  let users = team.users || [];
  const totalUsers = team.users.length;
  const showExpand = users.length > MAX_INITIAL_TEAMS;
  users = isExpanded ? users : users.slice(0, MAX_INITIAL_TEAMS);

  return (
    <MBox
      key={team.id}
      w="354px"
      h={isExpanded ? 'fit-content' : '170px'}
      overflow="hidden"
      py={4}
      border="1px solid"
      borderColor="tGray.lightPurple"
      borderRadius={4}
    >
      <MGrid templateColumns="4fr 1.88fr .2fr" columnGap={4} px={4}>
        <MGridItem>
          <MFlex flexDir="column">
            <MText fontWeight="semibold">Name</MText>
            <MText noOfLines={1} color="tGray.darkPurple">
              {team.name}
            </MText>
          </MFlex>
        </MGridItem>
        <MGridItem>
          <MFlex flexDir="column">
            <MText fontWeight="semibold">Type</MText>
            <MText noOfLines={1} color="tGray.darkPurple">
              {TEAM_TYPE_ENUM_DISPLAY[team.type as TeamTypeEnum]}
            </MText>
          </MFlex>
        </MGridItem>
        <MGridItem>
          <MFlex flexDir="column">
            <MText fontWeight="semibold">Members</MText>
            <MText noOfLines={1} color="tGray.darkPurple">
              {users?.length}
            </MText>
          </MFlex>
        </MGridItem>
      </MGrid>

      <MBox py={4}>
        <MDivider borderColor="tGray.back" />
      </MBox>

      <MFlex pl={4} pr={1.5} flexDir="row" flexWrap="wrap" rowGap={4}>
        <MemberAvatar members={users} owner={team?.owner} />

        {showExpand && (
          <MemberExpand
            maxInitMember={MAX_INITIAL_TEAMS}
            totalLength={totalUsers}
            handleExpand={() => setIsExpanded(true)}
          />
        )}
      </MFlex>
    </MBox>
  );
};
