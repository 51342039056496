import {
  PlacementWithLogical,
  PopoverBodyProps,
  PopoverContentProps,
  PopoverProps,
  Portal,
} from '@chakra-ui/react';
import React, { FC } from 'react';
import {
  MPopover,
  MPopoverArrow,
  MPopoverBody,
  MPopoverCloseButton,
  MPopoverContent,
  MPopoverFooter,
  MPopoverHeader,
  MPopoverTrigger,
} from './chakra';

interface MPopoverWrapperProps {
  isOpen: boolean;
  showArrow?: boolean;
  placement: PlacementWithLogical;
  onOpen?: () => void;
  onClose: () => void;
  usePortal?: boolean;
  showCloseBtn?: boolean;
  trigger?: 'click' | 'hover';
  triggerContent: React.ReactNode;
  headerContent?: React.ReactNode;
  bodyContent: React.ReactNode;
  footerContent?: React.ReactNode;
  popoverProps?: PopoverProps;
  popoverContentProps?: PopoverContentProps;
  popoverBodyProps?: PopoverBodyProps;
}

const MPopoverWrapper: FC<MPopoverWrapperProps> = ({
  isOpen,
  onOpen,
  onClose,
  placement,
  showArrow,
  usePortal,
  showCloseBtn,
  trigger = 'click',
  triggerContent,
  headerContent,
  bodyContent,
  footerContent,
  popoverProps,
  popoverContentProps,
  popoverBodyProps,
}: MPopoverWrapperProps) => {
  const popoverContent = (
    <MPopoverContent {...popoverContentProps}>
      {showArrow && <MPopoverArrow />}
      {showCloseBtn && <MPopoverCloseButton />}
      {headerContent && <MPopoverHeader>{headerContent}</MPopoverHeader>}
      <MPopoverBody {...popoverBodyProps}>{bodyContent}</MPopoverBody>
      {footerContent && <MPopoverFooter>{footerContent}</MPopoverFooter>}
    </MPopoverContent>
  );

  return (
    <MPopover
      trigger={trigger}
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      placement={placement}
      returnFocusOnClose={false}
      {...popoverProps}
    >
      <MPopoverTrigger>{triggerContent}</MPopoverTrigger>
      {usePortal ? <Portal>{popoverContent}</Portal> : popoverContent}
    </MPopover>
  );
};

export default MPopoverWrapper;
