import { doGetUserById } from '~app/api/usersService';
import { useAuth } from '~app/services/auth0';
import {
  FilterTypeEqual,
  IUser,
  RenderTableFilterOptionProps,
} from '~app/types';
import { MainSearchInput } from '../../MCustomSelect/components/MainSearchInput';
import { UserAvatarAndName } from '../../MCustomSelect/components/UserAvatarAndName';
import { MCustomSelect } from '../../MCustomSelect/MCustomSelect';
import { useCustomSelectValue } from '../../MCustomSelect/useCustomSelectValue';

export const OwnerTableFilterOptionContent = ({
  filter,
  filterOption,
  handleFilterChange,
}: RenderTableFilterOptionProps) => {
  const internalFilter = filter as FilterTypeEqual;
  const { tenantId } = useAuth();

  const {
    internalValue,
    isLoading: isLoadingSavedValue,
    onInternalValueChange,
  } = useCustomSelectValue<IUser>({
    value: internalFilter?.value,
    setValue: (value: string) => {
      handleFilterChange(value, filterOption);
    },
    getOneById: doGetUserById,
  });

  return (
    <MCustomSelect
      clearable
      useMainInputAsSearchInput
      itemTitle="name"
      itemValue="id"
      displayAvatar
      endpoint={`/api/tenants/${tenantId}/users`}
      renderItemContent={({ title }) => (
        <UserAvatarAndName userName={title} textProps={{ fontSize: 'sm' }} />
      )}
      MainInputComponent={MainSearchInput}
      loading={isLoadingSavedValue}
      value={internalValue}
      returnItem
      onChange={(val) => {
        onInternalValueChange(val as any);
      }}
    />
  );
};
