import React, { FunctionComponent as FC } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useDocumentHead } from '~app/services/documentHead';

const DocumentHead: FC<any> = () => {
  const { headData } = useDocumentHead();
  return (
    <HelmetProvider>
      <Helmet>
        <title>{`MonetizeNow${headData.title ? ` - ${headData.title}` : ''}${
          headData.subtitle ? ` - ${headData.subtitle}` : ''
        }`}</title>
      </Helmet>
    </HelmetProvider>
  );
};

export default DocumentHead;
