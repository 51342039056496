import { useState } from 'react';
import {
  useGetInvoiceSettings,
  useUpdateInvoiceSettings,
} from '~app/api/settingsService';
import { IInvoiceSettings } from '~app/types/InvoiceSettingsTypes';

export interface InvoiceSettingsDataTypes {
  fetchLoading: boolean;
  invoiceSettings?: IInvoiceSettings;
  doUpdateInvoiceSettings: (payload: IInvoiceSettings) => Promise<void>;
}

const useInvoiceSettings = (): InvoiceSettingsDataTypes => {
  /**
   * Since we allow the user to make multiple API requests at the same time,
   * there is a chance that a race condition might send a stale payload to the server on a subsequent request.
   * We store the latest payload that was sent to the server and provide it to all components
   * so if they make an API request, the new request includes the changes from other in-flight requests.
   * On error, we reset back to the last known good response from server.
   */
  const { data: invoiceSettings, isLoading: fetchLoading } =
    useGetInvoiceSettings({
      onSuccess: (data) => {
        setLatestPayload(data);
      },
      onError: () => {
        setLatestPayload(invoiceSettings);
      },
    });

  const [latestPayload, setLatestPayload] = useState<
    IInvoiceSettings | undefined
  >(invoiceSettings);

  const { mutateAsync: doUpdateInvoiceSettings } = useUpdateInvoiceSettings();

  const updateInvoiceSettings = async (payload: IInvoiceSettings) => {
    setLatestPayload(payload);
    await doUpdateInvoiceSettings(payload);
  };

  return {
    fetchLoading,
    invoiceSettings: latestPayload || invoiceSettings,
    doUpdateInvoiceSettings: updateInvoiceSettings,
  };
};

export { useInvoiceSettings };
