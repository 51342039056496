import { nativeEnum, z } from 'zod';
import { roundNumberToDecimal } from '../utils/numberUtils';
import { InvoiceSummaryRespSchema } from './invoiceTypes';

export enum TransactableSourceType {
  /**
   * @deprecated THIS IS NOT YET IMPLEMENTED ON BACKEND
   * (Just marking as deprecated so it stands out)
   *
   * TransactionApplicationModal will use credit in it's place until we fix the implementation.
   *
   * All credit notes are being handled as credits, since credits are currently only thing that currently applies to invoices.
   */
  creditNote = 'creditNote',
  credit = 'credit',
  payment = 'payment',
}
export const TransactableSourceTypeZ = nativeEnum(TransactableSourceType);

export enum TransactableTargetType {
  invoice = 'invoice',
  refund = 'refund',
}
export const TransactableTargetTypeZ = nativeEnum(TransactableTargetType);

export type TransactableType = TransactableSourceType | TransactableTargetType;

export const TransactableTypeDisplay: Record<TransactableType, string> = {
  creditNote: 'Credit Note',
  credit: 'Credit',
  payment: 'Payment',
  invoice: 'Invoice',
  refund: 'Refund',
};

export enum AppTrxnType {
  APPLICATION = 'APPLICATION',
  UNAPPLICATION = 'UNAPPLICATION',
}
export const AppTrxnTypeZ = nativeEnum(AppTrxnType);

export const ApplicationTransactionResponseSchema = z.object({
  id: z.string(),
  targetId: z.string(),
  targetType: TransactableTargetTypeZ,
  amount: z.number(),
  type: AppTrxnTypeZ,
  createdAt: z.string(),
});

export type ApplicationTransactionResponse = z.infer<
  typeof ApplicationTransactionResponseSchema
>;

export const TransactablesResponseSchema = z.object({
  transactableId: z.string(),
  sourceId: z.string(),
  sourceType: TransactableSourceTypeZ,
  originalAmount: z.number(),
  appliedAmount: z.number(),
  remainingAmount: z.number(),
  applications: ApplicationTransactionResponseSchema.array(),
});

export type TransactablesResponse = z.infer<
  typeof ApplicationTransactionResponseSchema
>;

export const AggregatedApplicationTransactionResponseSchema = z.object({
  targetId: z.string(),
  targetType: TransactableTargetTypeZ,
  amount: z.number(),
  appliedAmount: z.number(),
  amountRemaining: z.number(),
  amountAppliedByTransactable: z.number(),
});

export type AggregatedApplicationTransactionResponse = z.infer<
  typeof AggregatedApplicationTransactionResponseSchema
>;

export const AggregatedTransactablesResponseSchema = z.object({
  transactableId: z.string(),
  sourceId: z.string(),
  sourceType: TransactableSourceTypeZ,
  amount: z.number(),
  appliedAmount: z.number(),
  remainingAmount: z.number(),
  aggregatedApplications:
    AggregatedApplicationTransactionResponseSchema.array(),
});

export type AggregatedTransactablesResponse = z.infer<
  typeof AggregatedTransactablesResponseSchema
>;

export const InvoiceApplicationDataSchema = z.object({
  invoiceId: z.string(),
  amount: z.number().min(0.01),
  type: AppTrxnTypeZ,
});
export type InvoiceApplicationData = z.infer<
  typeof InvoiceApplicationDataSchema
>;

export const ApplyUnapplyRequestSchema = z.object({
  applications: InvoiceApplicationDataSchema.array(),
});
export type ApplyUnapplyRequest = z.infer<typeof ApplyUnapplyRequestSchema>;

export enum SelectionStateEnum {
  NONE = 'NONE',
  ALL = 'ALL',
  SOME = 'SOME',
}

export const InvoiceApplicationFormDataRowSchema = z.object({
  checkedState: z.nativeEnum(SelectionStateEnum),
  appliedAmount: z.union([z.string(), z.number()]).transform((val) => {
    if (!val || isNaN(Number(val))) {
      return 0;
    }
    return roundNumberToDecimal(Number(val));
  }),
  /**
   * This is the amount due + the amount applied from this transactable
   */
  amountDueWithoutApplied: z.number(),
  amountAppliedFromOtherTransactables: z.number(),
  invoice: InvoiceSummaryRespSchema.pick({
    id: true,
    amount: true,
    amountDue: true,
    invoiceNumber: true,
    dueDate: true,
    currency: true,
    status: true,
  }).passthrough(),
  aggregateTransaction:
    AggregatedApplicationTransactionResponseSchema.nullish(),
});
export type InvoiceApplicationFormDataRow = z.infer<
  typeof InvoiceApplicationFormDataRowSchema
>;

export const InvoiceApplicationFormDataSchema = z.object({
  aggregateTransactable: AggregatedTransactablesResponseSchema.nullish(),
  checkedState: z.nativeEnum(SelectionStateEnum),
  rows: InvoiceApplicationFormDataRowSchema.array(),
});

export type InvoiceApplicationFormData = z.infer<
  typeof InvoiceApplicationFormDataSchema
>;
