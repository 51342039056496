import { FC, useRef, useState } from 'react';
import { AiOutlineLink } from 'react-icons/ai';
import {
  MBox,
  MButton,
  MFlex,
  MFormField,
  MInput,
  MSpinner,
  MText,
} from '~app/components/Monetize';
import { ActiveDocumentType } from '~app/types/quoteDocumentsType';
import { BoxProps, FormLabel } from '@chakra-ui/react';
import { MFileUploadButton } from '~app/components/Monetize/MFileUploadButton';
import { QuoteDocumentLinkV2 } from '~app/components/Quotes/QuoteDocumentLinkV2';

interface QuoteDocumentsModalV2ItemProps extends BoxProps {
  label: string;
  linkUrl: string;
  savedItemText?: string;
  onSetActiveItem?: (type: ActiveDocumentType) => void;
  onRemoveItem?: () => void;
  onGotoSavedItem: () => void;
}

export const QuoteDocumentsModalV2Item: FC<QuoteDocumentsModalV2ItemProps> = ({
  label,
  linkUrl,
  savedItemText,
  onRemoveItem,
  onGotoSavedItem,
  ...rest
}: QuoteDocumentsModalV2ItemProps) => {
  return (
    <MFlex
      flexDir={'row'}
      w="100%"
      alignItems="center"
      justifyContent="space-between"
      {...rest}
    >
      <MText fontWeight="bold" maxW="200px" mr="5">
        {label}
      </MText>
      <MBox minWidth={0}>
        {!savedItemText && (
          <MText fontStyle="italic" mb="2">
            No document added
          </MText>
        )}

        {savedItemText && (
          <QuoteDocumentLinkV2
            showDeleteIcon={false}
            fileName={savedItemText}
            onClick={onGotoSavedItem}
            onRemoveItem={onRemoveItem}
            showLinkIcon={!!linkUrl}
          />
        )}
      </MBox>
    </MFlex>
  );
};
