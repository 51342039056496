import isUndefined from 'lodash/isUndefined';
import { FC, ReactNode, useState } from 'react';
import { MAlert, MText } from '~app/components/Monetize';
import { SettingCardEdit } from '~app/components/Settings/SettingCardEdit';
import { SettingCardView } from '~app/components/Settings/SettingCardView';
import { useFlags } from '~app/services/launchDarkly';
import { SettingCardViewAction } from '~app/types';
import { IQuickBook } from '~app/types/accountingTypes';
import { QuickBooksForm } from './QuickBooksForm';
import { QuickBooksRefundSync } from './QuickBooksRefundSync';

interface QuickBooksConnectionProps {
  connection: IQuickBook;
  description?: ReactNode;
}

export const QuickBooksConnection: FC<QuickBooksConnectionProps> = ({
  connection,
  description,
}) => {
  const { allowRefunds } = useFlags();
  const [mode, setMode] = useState<'VIEW' | 'EDIT'>('VIEW');
  const [showWarning, setShowWarning] = useState(false);

  const handleAction = async (action: SettingCardViewAction) => {
    switch (action) {
      default:
        break;
    }
  };

  return (
    <>
      {mode === 'VIEW' && (
        <SettingCardView
          status={'ACTIVE'} // Once BE has error state, we will implement here
          actions={
            [
              // { action: 'RE_AUTHENTICATE', label: 'Re-Authenticate' },
              // { action: 'VERIFY', label: 'Verify Connection' },
              // {
              //   action: 'DELETE',
              //   label: 'Delete',
              //   isDanger: true,
              //   confirmProps: {
              //     title: `Are you sure you want to delete this connection?`,
              //     description:
              //       'Once removed, this configuration will not longer be available.',
              //   },
              // },
            ]
          }
          type="QuickBooks"
          tags={[
            {
              label: 'Company ID',
              value: connection.companyId,
            },
          ]}
          onEdit={() => {
            setMode('EDIT');
            setShowWarning(true);
          }}
          onAction={handleAction}
        />
      )}
      {mode === 'EDIT' && (
        <>
          {description}
          <SettingCardEdit type="QuickBooks">
            <QuickBooksForm
              connection={connection}
              onClose={() => setMode('VIEW')}
            />
          </SettingCardEdit>
          {!isUndefined(connection?.refundLineItemId) &&
            allowRefunds &&
            mode === 'EDIT' && (
              <MAlert type="warning" my={4}>
                <MText lineHeight="normal">
                  If you alter Quickbooks account information, you may need to
                  re-enter the Product ID below.
                </MText>
              </MAlert>
            )}
        </>
      )}
      {allowRefunds && connection.companyId && (
        <QuickBooksRefundSync connection={connection} />
      )}
    </>
  );
};
