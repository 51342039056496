import React, { FunctionComponent as FC } from 'react';
import {
  IApprovalRespSchema,
  IQuoteRespSchema,
  QuoteStatusEnum,
} from '~app/types';
import { QuoteProgressStep } from './QuoteProgressStep';
import { toDateShort } from '~app/utils/dates';

interface QuoteStepEditProps {
  quote: IQuoteRespSchema;
  approvals: IApprovalRespSchema[];
  reloadData: () => void;
}

export const QuoteStepEdit: FC<QuoteStepEditProps> = React.forwardRef<
  any,
  QuoteStepEditProps
>(({ quote }: QuoteStepEditProps, ref) => {
  let stepTitle = 'Edit Quote Details';
  let stepDate = '';
  const isCompleted = quote.status !== QuoteStatusEnum.DRAFT;
  const showInprogressStepNumber = false;
  const isDisabled = false;
  const isCurrent = quote.status === QuoteStatusEnum.DRAFT;

  if (isCompleted) {
    stepDate = toDateShort(quote.approvalSubmittedAt);
    stepTitle = 'Drafted Quote Details';
  }

  return (
    <QuoteProgressStep
      key="quote-progress-edit"
      stepTitle={stepTitle}
      stepDate={stepDate}
      stepNumber={1}
      isCompleted={isCompleted}
      showInprogressStepNumber={showInprogressStepNumber}
      isDisabled={isDisabled}
      isCurrent={isCurrent}
      containerProps={{ ml: -8 }}
    />
  );
});
