import { Flex, FlexProps, ListItem, SystemStyleObject } from '@chakra-ui/react';
import React, { FC } from 'react';
import { MDivider } from '../../chakra';
import MText from '../../MText';

interface MCustomSelectItemProps extends Omit<FlexProps, 'onClick'> {
  isHighlight?: boolean;
  isSubtitle?: boolean;
  rightLabel?: string;
  isSelected?: boolean;
  isDisabled?: boolean;
  /** Show divider beneath item */
  hasDivider?: boolean;
  children: any;
  onClick?: () => void;
  ref?: any;
}

export const MCustomSelectItem: FC<MCustomSelectItemProps> = React.forwardRef<
  any,
  MCustomSelectItemProps
>(
  (
    {
      isHighlight,
      isSubtitle,
      rightLabel, // displayed to the far right on same line as the option
      isSelected,
      isDisabled,
      hasDivider,
      onClick,
      children,
      ...rest
    }: MCustomSelectItemProps,
    ref,
  ) => {
    let color = 'tPurple.base';
    let bg = isHighlight ? 'tGray.support' : '';
    let hoverStyle: SystemStyleObject = { bg: 'tGray.support' };
    const cursor = isSubtitle || isDisabled ? 'not-allowed' : 'pointer';

    if (isSelected) {
      color = 'tPurple.base';
      bg = 'tBlue.hover';
      hoverStyle.bg = 'tBlue.hover';
    } else if (isHighlight) {
      color = 'tPurple.base';
    } else if (isSubtitle || isDisabled) {
      color = 'tGray.disabledText';
      bg = '';
      hoverStyle.bg = '';
    }

    // remove hover state if disabled
    if (isDisabled) {
      hoverStyle = {};
    }

    const handleClick = () => {
      !isDisabled && onClick && onClick();
    };

    return (
      <ListItem
        role="presentation"
        onClick={handleClick}
        /** Used for some edge-cases to detect when not to close on blur of search input */
        data-custom-select-item
        maxWidth="100%"
      >
        <Flex
          aria-disabled={isDisabled}
          role="option"
          aria-selected={isSelected}
          bg={bg}
          color={color}
          p="2"
          pb={isSubtitle ? 2.5 : 2}
          borderRadius="2"
          cursor={cursor}
          _hover={hoverStyle}
          alignItems="center"
          justifyContent="space-between"
          ref={ref}
          _focus={hoverStyle}
          maxWidth="100%"
          {...rest}
        >
          <Flex flex={1} alignItems="center" maxWidth="100%">
            {children}
          </Flex>

          {!!rightLabel && (
            <MText
              fontSize="10px"
              color="inherit"
              _groupHover={{ color: 'tPurple.base' }}
              ml="4"
              fontWeight="400"
            >
              {rightLabel}
            </MText>
          )}
        </Flex>
        {hasDivider && (
          <MDivider
            borderColor="tGray.lightPurple"
            onClick={(ev) => {
              ev.preventDefault();
              ev.stopPropagation();
            }}
          />
        )}
      </ListItem>
    );
  },
);
