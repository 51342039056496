import { TextProps } from '@chakra-ui/react';
import MText from './MText';

interface MReadOnlyInputProps extends TextProps {
  value: string | boolean | null | number;
}

export const MReadOnlyInput = ({ value, ...rest }: MReadOnlyInputProps) => {
  return (
    <MText mt={1} {...rest}>
      {value}
    </MText>
  );
};
