import { BoxProps, Input, InputProps } from '@chakra-ui/react';
import {
  CSSProperties,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { MBox } from './chakra';

const sizerStyle: CSSProperties = {
  position: 'absolute',
  top: 0,
  left: 0,
  visibility: 'hidden',
  height: 0,
  overflow: 'scroll',
  whiteSpace: 'pre',
};

const copyStyles = (styles: CSSStyleDeclaration, node: HTMLDivElement) => {
  node.style.fontSize = styles.fontSize;
  node.style.fontFamily = styles.fontFamily;
  node.style.fontWeight = styles.fontWeight;
  node.style.fontStyle = styles.fontStyle;
  node.style.letterSpacing = styles.letterSpacing;
  node.style.textTransform = styles.textTransform;
};

interface MAutoSizeInputProps extends InputProps {
  containerProps?: BoxProps;
  placeholderIsMinWidth?: boolean;
  extraWidth?: number;
  minWidth?: number;
  maxWidth?: number;
}
export const MAutoSizeInput = ({
  containerProps,
  placeholderIsMinWidth = false,
  extraWidth = 0,
  minWidth = 80,
  maxWidth = 390,
  ...rest
}: MAutoSizeInputProps) => {
  const isMounted = useRef(true);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const sizerRef = useRef<HTMLDivElement | null>(null);
  const placeHolderSizerRef = useRef<HTMLDivElement | null>(null);

  const [inputWidth, setInputWidth] = useState<number>(1);

  const sizerValue = [rest.defaultValue, rest.value, ''].reduce(
    (previousValue, currentValue) => {
      if (previousValue !== null && previousValue !== undefined) {
        return previousValue;
      }
      return currentValue;
    },
  );

  const copyInputStyles = () => {
    if (!isMounted || !window.getComputedStyle) {
      return;
    }
    const inputStyles =
      inputRef.current && window.getComputedStyle(inputRef.current);
    if (!inputStyles) {
      return;
    }
    if (sizerRef.current) {
      copyStyles(inputStyles, sizerRef.current);
    }
    if (placeHolderSizerRef.current) {
      copyStyles(inputStyles, placeHolderSizerRef.current);
    }
  };

  const updateInputWidth = () => {
    if (
      !isMounted ||
      !sizerRef.current ||
      typeof sizerRef.current?.scrollWidth === 'undefined'
    ) {
      return;
    }
    let newInputWidth;
    if (
      rest.placeholder &&
      placeHolderSizerRef.current &&
      (!rest.value || (rest.value && placeholderIsMinWidth))
    ) {
      newInputWidth =
        Math.max(
          sizerRef.current.scrollWidth,
          placeHolderSizerRef.current.scrollWidth,
        ) + 2;
    } else {
      newInputWidth = sizerRef.current.scrollWidth + 2;
    }

    newInputWidth += extraWidth;
    if (newInputWidth < minWidth) {
      newInputWidth = minWidth;
    }

    if (newInputWidth > maxWidth) {
      newInputWidth = maxWidth;
    }

    if (newInputWidth !== inputWidth) {
      setInputWidth(newInputWidth);
    }
  };

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    copyInputStyles();
  }, []);

  useLayoutEffect(() => {
    updateInputWidth();
  }, [rest.value, rest.placeholder]);

  return (
    <MBox display="inline-block" {...containerProps}>
      <Input
        ref={inputRef}
        boxSizing="content-box"
        width={`${inputWidth}px`}
        {...rest}
      />
      <MBox style={sizerStyle} ref={sizerRef}>
        {sizerValue}
      </MBox>
      {rest.placeholder ? (
        <MBox ref={placeHolderSizerRef} style={sizerStyle}>
          {rest.placeholder}
        </MBox>
      ) : null}
    </MBox>
  );
};
