import {
  MCheckbox,
  MCustomNumberInput,
  MCustomSelect,
  MInput,
} from '~app/components/Monetize';
import { DatePicker } from '~app/components/Monetize/DatePicker/DatePicker';
import { MainSearchInputV2 } from '~app/components/Monetize/MCustomSelect/components/MainSearchInputV2';
import { CustomFieldsAllData } from '~app/hooks/useCustomFieldsAll';
import { logger } from '~app/services/logger';
import { CustomFieldTypeEnum, IQuestion, Maybe } from '~app/types';

interface QuestionFormCustomFieldProps {
  question: IQuestion;
  filter?: Maybe<Record<string, any>>;
  customFieldsData: CustomFieldsAllData;
  value: any;
  onChange: (value: any) => void;
}

export const QuestionFormCustomField = ({
  question,
  filter,
  customFieldsData,
  value,
  onChange,
}: QuestionFormCustomFieldProps) => {
  const { customFieldEntity, customFieldType } = question;

  if (
    !question.customField ||
    !customFieldEntity ||
    !customFieldType ||
    !customFieldsData.fieldsByKey[
      `${customFieldEntity}.${question.customField}`
    ]
  ) {
    logger.warn(
      'Invalid custom field, custom field data points are missing',
      question,
    );
    return null;
  }

  const customField =
    customFieldsData.fieldsByKey[
      `${customFieldEntity}.${question.customField}`
    ];

  if (customField.type === CustomFieldTypeEnum.CHECKBOX) {
    return (
      <MCheckbox
        value={value ?? false}
        isChecked={!!value}
        onChange={(event) => onChange(event.target.checked)}
      />
    );
  }

  if (customField.type === CustomFieldTypeEnum.DATE) {
    return (
      <DatePicker
        value={value}
        onChange={onChange}
        minDate={filter?.min}
        maxDate={filter?.max}
        boxProps={{ mt: '0 !important', width: '100%' }}
      />
    );
  }

  if (customField.type === CustomFieldTypeEnum.DROPDOWN) {
    return (
      <MCustomSelect
        value={value || ''}
        useMainInputAsSearchInput
        MainInputComponent={MainSearchInputV2}
        itemTitle="label"
        itemValue="value"
        items={customField.values || []}
        placeholder={`Select...`}
        onChange={onChange}
      />
    );
  }

  if (customField.type === CustomFieldTypeEnum.NUMBER) {
    return (
      <MCustomNumberInput
        width="100%"
        value={value ?? ''}
        onChange={(valueAsString) => onChange(valueAsString)}
        min={filter?.min ?? 1}
        max={filter?.max ?? Number.MAX_SAFE_INTEGER}
      />
    );
  }

  if (customField.type === CustomFieldTypeEnum.SINGLE_LINE_TEXT) {
    return (
      <MInput
        mt="0 !important"
        value={value ?? ''}
        onChange={(e) => onChange(e.target.value)}
      />
    );
  }

  logger.warn('Unhandled custom field type', customField.type);

  return null;
};
