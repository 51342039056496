import React from 'react';
import {
  MBox,
  MFlex,
  MHStack,
  MPageHeader,
  MPageSearchInput,
  MSwitch,
  MText,
} from '~app/components/Monetize';
import {
  AmountTableFilterOptionContent,
  DateGlteTableFilterOptionContent,
  MDataTableFilter,
} from '~app/components/Monetize/DataTable';
import { ExportTableButton } from '~app/components/Monetize/ExportEntityButton';
import { InvoiceStatusEnumDisplay } from '~app/constants/invoice';
import { useInvoices } from '~app/hooks/AccountDetail';
import { InvoiceList } from '~app/routes/Invoices/InvoiceList';
import {
  FilterOptionType,
  FilterTypeEqual,
  FilterTypeOperator,
  IAccount,
  IAccountRespSchema,
  RenderTableFilterOptionProps,
} from '~app/types';
import { getIsTrulyEmptyList } from '~app/utils';
import { objectToObjArray } from '~app/utils/misc';

const renderSentInvoiceToggleOptionContent = ({
  filter,
  filterOption,
  handleFilterChange,
}: RenderTableFilterOptionProps) => {
  const internalFilter = filter as FilterTypeEqual;
  const { options } = filterOption;

  const falseValue = options?.falseValue ?? '';
  const trueValue = options?.trueValue ?? '';
  const isChecked = internalFilter?.value === trueValue;

  const handleToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.checked ? trueValue : falseValue;
    handleFilterChange(newValue, filterOption);
  };

  return (
    <MFlex alignItems="center" my="2" justifyContent="space-between">
      <MText mr="4">Hide Sent Invoices</MText>
      <MSwitch size="md" isChecked={isChecked} onChange={handleToggleChange} />
    </MFlex>
  );
};
const renderAmountOptionContent = (props: RenderTableFilterOptionProps) => {
  return <AmountTableFilterOptionContent {...props} />;
};
const renderDateGlteOptionContent = (props: RenderTableFilterOptionProps) => {
  return <DateGlteTableFilterOptionContent {...props} />;
};

const AccountInvoices = ({
  account,
  billGroupId,
}: {
  account: IAccount | IAccountRespSchema;
  billGroupId?: string;
}) => {
  const { id: accountId } = account;

  const {
    filters,
    setFilters,
    onResetFilter,
    setPager,
    pager,
    isLoading,
    invoices,
    searchTerm,
    setSearchTerm,
  } = useInvoices(accountId, billGroupId);

  const statusItems = objectToObjArray(
    InvoiceStatusEnumDisplay,
    'value',
    'label',
  );
  const filterOptions: FilterOptionType[] = [
    {
      title: '',
      key: 'sent',
      operator: FilterTypeOperator.TOGGLE,
      renderOptionContent: renderSentInvoiceToggleOptionContent,
      options: {
        falseValue: '',
        trueValue: 'false',
      },
    },
    {
      title: 'Amount',
      key: 'amount',
      operator: FilterTypeOperator.GLTE,
      renderOptionContent: renderAmountOptionContent,
    },
    {
      title: 'Invoice Date',
      key: 'invoicingDate',
      operator: FilterTypeOperator.GLTE,
      renderOptionContent: renderDateGlteOptionContent,
    },
    {
      title: 'Updated',
      key: 'modifyDate',
      operator: FilterTypeOperator.GLTE,
      renderOptionContent: (props) => (
        <DateGlteTableFilterOptionContent showTimePicker {...props} />
      ),
    },
    {
      title: 'Status',
      key: 'status',
      operator: FilterTypeOperator.IN,
      items: statusItems,
    },
  ];

  const isTrulyEmptyList = getIsTrulyEmptyList({
    loading: isLoading,
    totalElements: invoices?.totalElements || 0,
    filters,
    searchTerm,
    page: pager.page,
  });

  const filterComponentReset = React.useRef<any>(null);
  const searchComponentReset = React.useRef<any>(null);

  return (
    <MBox>
      <MPageHeader title="Invoices" hideContent={isTrulyEmptyList}>
        <MFlex>
          <MHStack spacing="2">
            <MPageSearchInput
              placeholderKey="ID"
              value={searchTerm || ''}
              onChange={(e: any) => setSearchTerm && setSearchTerm(e)}
              count={invoices?.totalElements}
              resetSearch={searchComponentReset}
            />

            <MDataTableFilter
              filters={filters}
              filterOptions={filterOptions}
              setFilters={setFilters}
              onResetFilter={onResetFilter}
              resetFilter={filterComponentReset}
            />
            <ExportTableButton
              entity="invoices"
              filters={filters}
              searchKey="id"
              searchTerm={searchTerm}
              sortField={pager.sortField}
              sortOrder={pager.sortOrder}
              endpointParams={[accountId]}
              internalFilters={[
                {
                  key: 'accountId',
                  operator: FilterTypeOperator.EQUAL,
                  value: accountId,
                },
              ]}
              applyInternalFiltersWithoutConfirmation
              getFilename={() => `invoices-${accountId}.csv`}
            />
          </MHStack>
        </MFlex>
      </MPageHeader>
      <InvoiceList
        filters={filters}
        setFilters={setFilters}
        onResetFilter={onResetFilter}
        setPager={setPager}
        pager={pager}
        isLoading={isLoading}
        // TODO: fix any type
        invoices={invoices as any}
        searchTerm={searchTerm}
        filterComponentReset={filterComponentReset}
      />
    </MBox>
  );
};

export default AccountInvoices;
