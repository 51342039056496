import { useMemo, useRef, useState } from 'react';
import { usePrompt } from '../../../hooks/usePrompt';
import {
  confirmModalPromiseProps,
  useConfirmModal,
} from '../../../services/confirmModal';
import { logger } from '../../../services/logger';
import { useToast } from '../../../services/toast';
import { MassEmailCustomerInvoicesResults } from '../../../types';
import { formatInteger, pluralize } from '../../../utils';

/**
 * Helper hook to handle mass invoice actions
 */
export const useMassInvoiceAction = <T extends { id: string }>({
  selectedRows: selectedRowsUnordered,
  visibleRows,
  isLoading,
  verbiage,
}: {
  selectedRows: T[];
  /**
   * Used to determine correct order of selected rows
   */
  visibleRows: T[];
  isLoading: boolean;
  verbiage: {
    successTitle?: string; // Success
    successSingularMessage: string; // invoice
    successSuffix: string; // invoice
    failureTitle?: string; // Sending Email Failed
    failureSingularMessage: string; // invoice
    failureSuffix: string; // invoice
    partialFailureTitle?: string; // Sending Email Partially Failed
    partialFailureSuffix: string; // invoice
  };
}) => {
  const abortControllerRef = useRef(new AbortController());
  const { addToast } = useToast();
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [progress, setProgress] = useState<{
    success: number;
    failure: number;
    unsent: number;
    resultsById: Record<string, MassEmailCustomerInvoicesResults>;
  } | null>(null);
  const { showConfirmModalPromise } = useConfirmModal();

  // Order selected rows based on table display
  const rows = useMemo(() => {
    const selectedRowIds = new Set(selectedRowsUnordered.map(({ id }) => id));
    return visibleRows.filter(({ id }) => selectedRowIds.has(id));
  }, [selectedRowsUnordered, visibleRows]);

  /**
   * Show warning if there is a navigation event while things are in progress
   */
  usePrompt(
    'Sending email is not done, would you like to cancel the process?',
    isLoading,
    () => {
      abortControllerRef.current.abort();
    },
  );

  /**
   * Setup state for submission, then call the callback
   * for the actual submission from the parent component
   */
  async function initiateSubmit({
    data,
    callback,
    confirm,
  }: {
    data: unknown;
    callback: (abortController: AbortController) => void;
    confirm?: confirmModalPromiseProps;
  }) {
    if (confirm && !(await showConfirmModalPromise(confirm))) {
      // user cancelled
      return;
    }

    logger.log('Submitted', data);
    const abortController = new AbortController();
    abortControllerRef.current = abortController;
    setProgress(null);
    setHasSubmitted(true);
    callback(abortController);
  }

  /**
   * Handle API progress events
   */
  function onProgress({
    result,
    results,
  }: {
    result: MassEmailCustomerInvoicesResults;
    results: MassEmailCustomerInvoicesResults[];
  }) {
    setProgress((prevResult) => {
      const resultsById = { ...(prevResult?.resultsById || {}) };
      resultsById[result.invoiceId] = result;
      return {
        success: results.filter((result) => result.success).length,
        failure: results.filter((result) => !result.success).length,
        unsent: rows.length - results.length,
        resultsById: resultsById,
      };
    });
  }

  /**
   * Handle toast response based on results
   */
  function showCompletionToast(results: MassEmailCustomerInvoicesResults[]) {
    const errorCount = results.filter((result) => !result.success).length;
    const allSuccess = errorCount === 0;
    const allFailed = errorCount === results.length;
    if (allSuccess) {
      addToast({
        summary: verbiage.successTitle || 'Success',
        detail:
          rows.length === 1
            ? verbiage.successSingularMessage
            : `All ${formatInteger(rows.length)} ${pluralize(
                'invoice',
                rows.length,
              )} ${verbiage.successSuffix}`,
        severity: 'success',
      });
    } else if (allFailed) {
      addToast({
        summary: verbiage.failureTitle || 'Failure',
        detail:
          errorCount === 1
            ? verbiage.failureSingularMessage
            : `All ${formatInteger(errorCount)} ${pluralize(
                'invoice',
                errorCount,
              )} ${verbiage.failureSuffix}`,
        severity: 'error',
      });
    } else {
      addToast({
        summary: verbiage.partialFailureTitle || 'Partial Failure',
        detail: `${formatInteger(errorCount)} ${pluralize(
          'invoice',
          errorCount,
        )} ${verbiage.partialFailureSuffix}`,
        severity: 'warning',
      });
    }
  }

  return {
    abortController: abortControllerRef.current,
    rows,
    hasSubmitted,
    onProgress,
    progress,
    showCompletionToast,
    initiateSubmit,
  };
};
