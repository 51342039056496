import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import {
  AggregationModelEnum,
  ApiListResponse,
  GetListApiConfig,
  HistoryTypes,
  IGetSubscriptionSchema,
  ISubscriptionHistory,
  ISubscriptionScheduledChange,
  ISubscriptionUsagePerDayResponse,
  IUpsertSubscriptionsRespSchema,
  SubscriptionUsagePerDayResponseSchema,
  UsagePeriodPointerEnum,
} from '~app/types';
import { apiGet, apiPost } from './axios';
import { subscriptionServiceQueryKeys } from './queryKeysService';
import { composeFiltersQuery, composeGetQuery } from './utils';

// NON-REACT-QUERY functions:

// TODO: replace with React Query, with useUpdateEntity calling queryKeysSubscriptions.update
// note that this handles arrays of subscriptions
export const upsertSubscriptionService = async (
  signal: AbortSignal,
  payload: any,
): Promise<IUpsertSubscriptionsRespSchema> => {
  // per API team, the POST and PUT calls for this endpoint behave the same -- each can take an array of
  // subscriptions that contains both new and existing dtos (the latter with id of course)
  // in the response, it returns an array of just one of the subscriptions submitted
  const res = await apiPost(`/api/subscriptions`, payload, { signal });
  return res.data;
};

export function useGetSubscriptionById(
  id: string,
  options: Partial<UseQueryOptions<IGetSubscriptionSchema>> = {},
) {
  return useQuery<IGetSubscriptionSchema>(
    [...subscriptionServiceQueryKeys.subscriptionDetail(id)],
    {
      queryFn: () =>
        apiGet<IGetSubscriptionSchema>(`/api/subscriptions/${id}`).then(
          (res) => res.data,
        ),
      ...options,
    },
  );
}

export function useSubscriptionScheduledChanges(
  subscriptionId: string,
  options: {
    enabled?: boolean;
    onSuccess?: (data: ISubscriptionScheduledChange[]) => void;
    onError?: (data: unknown) => void;
  } = {},
) {
  return useQuery(
    [
      ...subscriptionServiceQueryKeys.subscriptionScheduledChanges(
        subscriptionId,
      ),
    ],
    {
      queryFn: () =>
        apiGet<ISubscriptionScheduledChange[]>(
          `/api/subscriptions/${subscriptionId}/scheduledChanges`,
        ).then((res) => res.data),
      ...options,
    },
  );
}

export async function fetchUsageConsumption(
  subscriptionId: string,
  usageTypeIds: string[] | undefined,
  startTime: string,
  endTime: string,
  aggregationModel: AggregationModelEnum,
) {
  const payload = {
    subscriptionId,
    startTime,
    endTime,
    aggregationModel,
    usageTypeIds: usageTypeIds ? usageTypeIds.join(',') : '',
  };
  const res = await apiGet<{ unitsConsumed: number }>(`/usage/consumption`, {
    params: payload,
  });
  return res.data;
}

export function useGetUsageConsumption(
  filters: {
    subscriptionId: string;
    usageTypeIds: string[];
    startTime: string;
    endTime: string;
    aggregationModel: AggregationModelEnum;
  },
  options: {
    enabled?: boolean;
    onSuccess?: (data: { unitsConsumed: number }) => void;
    onError?: (data: unknown) => void;
  } = {},
) {
  const params = composeFiltersQuery({
    ...filters,
    usageTypeIds: filters.usageTypeIds ? filters.usageTypeIds.join(',') : '',
  });
  return useQuery(
    [...subscriptionServiceQueryKeys.subscriptionUsageConsumption(params)],
    () =>
      fetchUsageConsumption(
        filters.subscriptionId,
        filters.usageTypeIds,
        filters.startTime,
        filters.endTime,
        filters.aggregationModel,
      ),
    {
      ...options,
    },
  );
}

/**
 * Get aggregated usage data by day for multiple products on a subscription
 * convenience function for calling `doGetSubscriptionUsageRatedPerDay` multiple times
 */
export const doGetSubscriptionUsageRatedPerDayMultiple = async ({
  accountId,
  subscriptionId,
  period,
  productIds,
}: {
  accountId: string;
  subscriptionId: string;
  period: UsagePeriodPointerEnum;
  productIds: string[];
}): Promise<Record<string, ISubscriptionUsagePerDayResponse>> => {
  const output: Record<string, ISubscriptionUsagePerDayResponse> = {};
  for (const productId of productIds) {
    output[productId] = await doGetSubscriptionUsageRatedPerDay({
      accountId,
      subscriptionId,
      period,
      productId,
    });
  }
  return output;
};

/**
 * Get aggregated usage data by day for one product on a subscription
 */
export const doGetSubscriptionUsageRatedPerDay = async ({
  accountId,
  subscriptionId,
  ...params
}: {
  accountId: string;
  subscriptionId: string;
  period: UsagePeriodPointerEnum;
  productId: string;
}): Promise<ISubscriptionUsagePerDayResponse> => {
  return apiGet<ISubscriptionUsagePerDayResponse>(
    `/api/accounts/${accountId}/subscriptions/${subscriptionId}/usage/rated/perDay`,
    { params },
  ).then((res) => SubscriptionUsagePerDayResponseSchema.parse(res.data));
};

export function useGetSubscriptionHistory(
  config: GetListApiConfig,
  accountId: string,
  subscriptionId: string,
  options: Partial<UseQueryOptions<ApiListResponse<ISubscriptionHistory>>> = {},
) {
  const reqParams = {
    objectType: HistoryTypes.SUBSCRIPTION,
    objectId: subscriptionId,
  };
  const params = composeGetQuery(config, reqParams);

  return useQuery<ApiListResponse<ISubscriptionHistory>>(
    [
      ...subscriptionServiceQueryKeys.subscriptionHistory(
        accountId,
        subscriptionId,
      ),
      params,
    ],
    {
      queryFn: () =>
        apiGet<ApiListResponse<ISubscriptionHistory>>(
          `/api/account/${accountId}/history`,
          {
            params,
          },
        ).then((res) => res.data),
      ...options,
    },
  );
}

export function useGetSubscriptionMRR(
  accountId: string,
  subscriptionId: string,
  options: Partial<UseQueryOptions<{ total: number }>> = {},
) {
  return useQuery<{ total: number }>(
    [
      ...subscriptionServiceQueryKeys.subscriptionMRRAmount(
        accountId,
        subscriptionId,
      ),
    ],
    {
      queryFn: () =>
        apiGet<{ total: number }>(
          `/api/accounts/${accountId}/subscriptions/${subscriptionId}/overview/mrr`,
        ).then((res) => res.data),
      ...options,
    },
  );
}

export function useGetSubscriptionARR(
  accountId: string,
  subscriptionId: string,
  options: Partial<UseQueryOptions<{ total: number }>> = {},
) {
  return useQuery<{ total: number }>(
    [
      ...subscriptionServiceQueryKeys.subscriptionARRAmount(
        accountId,
        subscriptionId,
      ),
    ],
    {
      queryFn: () =>
        apiGet<{ total: number }>(
          `/api/accounts/${accountId}/subscriptions/${subscriptionId}/overview/arr`,
        ).then((res) => res.data),
      ...options,
    },
  );
}

export function useGetSubscriptionEstimate(
  accountId: string,
  subscriptionId: string,
  options: Partial<UseQueryOptions<{ total: number }>> = {},
) {
  return useQuery<{ total: number }>(
    [
      ...subscriptionServiceQueryKeys.subscriptionEstimate(
        accountId,
        subscriptionId,
      ),
    ],
    {
      queryFn: () =>
        apiGet<{ total: number }>(
          `/api/accounts/${accountId}/subscriptions/${subscriptionId}/overview/estimate`,
        ).then((res) => res.data),
      ...options,
    },
  );
}

export function useGetSubscriptionTotal(
  accountId: string,
  subscriptionId: string,
  options: Partial<UseQueryOptions<{ total: number }>> = {},
) {
  return useQuery<{ total: number }>(
    [
      ...subscriptionServiceQueryKeys.subscriptionTotalSpend(
        accountId,
        subscriptionId,
      ),
    ],
    {
      queryFn: () =>
        apiGet<{ total: number }>(
          `/api/accounts/${accountId}/subscriptions/${subscriptionId}/overview/totalSpend`,
        ).then((res) => res.data),
      ...options,
    },
  );
}
