import { FunctionComponent as FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { handleApiErrorToast } from '~app/api/axios';
import { upsertSubscriptionService } from '~app/api/subscriptionsService';
import { InvoiceContent } from '~app/components/Invoices/InvoiceContent';
import { ROUTES } from '~app/constants';
import Sentry from '~app/services/sentry';
import {
  IInvoiceItemRespSchema,
  IInvoiceRespSchema,
  ISubscriptionsFormReq,
  SubscriptionsFormReqSchema,
} from '~app/types';

interface ReviewInvoiceProps {
  formValues: ISubscriptionsFormReq;
  isInReview?: boolean;
}

export const ReviewInvoice: FC<ReviewInvoiceProps> = ({
  formValues,
  isInReview,
}: ReviewInvoiceProps) => {
  const xhrController: AbortController = new AbortController();
  const xhrSignal = xhrController.signal;
  const [loading, setLoading] = useState<boolean>(true);
  const [invoice, setInvoice] = useState<IInvoiceRespSchema | null>(null);
  const navigate = useNavigate();

  const fetchInvoice = async () => {
    try {
      setLoading(true);
      let preparedPayload: ISubscriptionsFormReq = {
        ...formValues,
        preview: true,
        collectNow: false,
      };

      const parsed = SubscriptionsFormReqSchema.safeParse(preparedPayload);
      if (parsed.success) {
        preparedPayload = parsed.data;
      } else {
        Sentry.captureException(parsed.error.issues, {
          tags: {
            type: 'SUBSCRIPTION_FORM',
          },
        });
      }

      const data = await upsertSubscriptionService(xhrSignal, preparedPayload);

      const invoice = data?.invoice; // API returns unsaved invoice (no subscriptionId, id, etc.)
      if (invoice) {
        const invoiceItems: IInvoiceItemRespSchema[] = invoice.invoiceItems.map(
          (invoiceItem) => {
            const stdInvoiceItem = invoiceItem as IInvoiceItemRespSchema;
            if (!stdInvoiceItem.subscriptionId && invoiceItem.referenceId) {
              // converting referenceId to subscriptionId to standarize the type for easier code-sharing with regular invoices
              stdInvoiceItem.subscriptionId = invoiceItem.referenceId;
            }
            return stdInvoiceItem;
          },
        );
        const invoiceWithSubId = invoice as IInvoiceRespSchema;
        invoiceWithSubId.invoiceItems = invoiceItems;
        setInvoice(invoiceWithSubId);
      }
    } catch (error) {
      handleApiErrorToast(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (formValues) {
      fetchInvoice();
    }
    return () => xhrController.abort();
  }, [formValues]);

  return (
    <InvoiceContent
      loadingInvoice={loading}
      invoice={invoice}
      isInReview={isInReview}
    />
  );
};
