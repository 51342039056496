import { TextProps } from '@chakra-ui/react';
import { MText } from '../Monetize';

const getAlignment = (cellName: string) => {
  if (cellName === 'Amount') {
    return 'right';
  }

  if (cellName === 'Quantity' || cellName === 'Price Per Unit') {
    return 'center';
  }
  return 'left';
};

interface HeaderCellProps extends TextProps {
  value: string;
  children: string | React.ReactNode;
  isRequired?: boolean;
}

const HeaderCell = ({
  value,
  children,
  isRequired = false,
  ...rest
}: HeaderCellProps): React.ReactElement => {
  const alignment = getAlignment(value);

  return (
    <MText
      key={value}
      fontWeight="bold"
      fontSize="md"
      textAlign={alignment}
      pr={value === 'Amount' ? '18px' : ''}
      {...rest}
    >
      {children}
      {isRequired && (
        <MText as="span" color="tRed.base" ml={1}>
          *
        </MText>
      )}
    </MText>
  );
};

export default HeaderCell;
