import { PaymentElement } from '@stripe/react-stripe-js';
import { StripePaymentElementChangeEvent } from '@stripe/stripe-js';
import { FunctionComponent as FC } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { formatCurrency } from '~app/utils';
import {
  MBox,
  MCustomNumberInput,
  MFormField,
  MGrid,
  MGridItem,
  MInput,
  MText,
} from '~components/Monetize';
import { IInvoiceRespSchema, ISharedInvoicePaymentUI } from '~types';
import { logger } from '../../../services/logger';

interface SharedInvoiceFormNewPaymentMethodProps {
  allowPartialPayment: boolean;
  formState: UseFormReturn<ISharedInvoicePaymentUI>;
  invoice: IInvoiceRespSchema;
  isDisabled?: boolean;
}

export const SharedInvoiceFormNewPaymentMethod: FC<
  SharedInvoiceFormNewPaymentMethodProps
> = ({
  allowPartialPayment,
  formState,
  invoice,
  isDisabled,
}: SharedInvoiceFormNewPaymentMethodProps) => {
  const {
    setValue,
    control,
    formState: { errors },
  } = formState;

  const handlePaymentFormChangeEvent = ({
    complete,
    empty,
    value,
  }: StripePaymentElementChangeEvent) => {
    logger.info('Stripe payment form change event', { complete, empty, value });
    setValue('stripeData', {
      empty,
      complete,
      type: value.type,
    });
  };

  return (
    <MBox>
      <PaymentElement
        onChange={handlePaymentFormChangeEvent}
        options={{
          fields: {
            billingDetails: {
              name: 'never',
              email: 'never',
              phone: 'auto',
              address: 'if_required',
            },
          },
        }}
      />
      <MGrid templateColumns="repeat(12, 1fr)" gap={4} my={3}>
        <MGridItem colSpan={12}>
          <MFormField
            isDisabled={isDisabled}
            error={errors?.amount}
            label="Amount"
            isRequired
          >
            <Controller
              name="amount"
              control={control}
              render={({ field: { onChange, ...field } }) =>
                allowPartialPayment ? (
                  <MCustomNumberInput
                    precision={2}
                    isDisabled={isDisabled}
                    onChange={(_, valueAsNumber) => onChange(valueAsNumber)}
                    {...field}
                  />
                ) : (
                  <MText>
                    {formatCurrency(field.value, {
                      currency: invoice.currency,
                    })}
                  </MText>
                )
              }
            />
          </MFormField>
        </MGridItem>
        <MGridItem colSpan={12}>
          <MFormField
            isDisabled={isDisabled}
            error={errors?.billingDetails?.paymentMethodName}
            label="Payment Method Name"
            isRequired
          >
            <Controller
              name="billingDetails.paymentMethodName"
              control={control}
              defaultValue=""
              render={({ field }) => <MInput {...field} />}
            />
          </MFormField>
        </MGridItem>
        <MGridItem colSpan={12}>
          <MFormField
            isDisabled={isDisabled}
            error={errors?.billingDetails?.fullName}
            label="Full Name"
            isRequired
          >
            <Controller
              name="billingDetails.fullName"
              control={control}
              defaultValue=""
              render={({ field }) => <MInput {...field} />}
            />
          </MFormField>
        </MGridItem>
        <MGridItem colSpan={12}>
          <MFormField
            isDisabled={isDisabled}
            error={errors.billingDetails?.email}
            label="Email"
            isRequired
          >
            <Controller
              name="billingDetails.email"
              control={control}
              defaultValue=""
              render={({ field }) => <MInput {...field} />}
            />
          </MFormField>
        </MGridItem>
      </MGrid>
    </MBox>
  );
};
