import { FunctionComponent as FC, useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { MBox, MFlex, MHStack, MText } from '~app/components/Monetize';
import { MCurrency } from '~app/components/Monetize/MCurrency';
import { MPercent } from '~app/components/Monetize/MPercent';
import { OFFERINGS } from '~app/constants';
import {
  CUSTOMIZABLE_RATE_PRODUCT_TYPES,
  PRODUCT_TYPE_DISPLAY_SHORT,
} from '~app/constants/products';
import RatePrice from '~app/routes/ProductCatalog/Offerings/components/Rate/RatePrice';
import { useFlags } from '~app/services/launchDarkly';
import {
  IAccount,
  IAccountRateCreateReqSchema,
  IAccountRateUpdateReqSchema,
  IOrderedObj,
  IPriceReqSchema,
  IPriceUnderProductSchemaUI,
  IQuoteItemRespSchema,
  IRateReqSchema,
  IRateResSchema,
  OfferingTypesEnum,
  PriceModelEnum,
  ProductTypeEnum,
} from '~app/types';
import { formatNumber } from '~app/utils';
import { getNextTierPrice } from '~app/utils/product';

interface PricesDrawerBodyEditProductProps {
  account: IAccount | Pick<IAccount, 'id' | 'accountName'>;
  offeringType: OfferingTypesEnum;
  offeringRate: IRateResSchema;
  providedOfferingRate: IRateResSchema;
  productsObj: IOrderedObj<IQuoteItemRespSchema>;
  productWithPrices: IPriceUnderProductSchemaUI;
  productsWithPrices: IPriceUnderProductSchemaUI[];
  latestPayload?: IAccountRateCreateReqSchema | IAccountRateUpdateReqSchema;
  isEditMode: boolean;
  setIsEditMode: (val: boolean) => void;
  setLatestPayload?: (
    val: IAccountRateCreateReqSchema | IAccountRateUpdateReqSchema,
  ) => void;
  onRateSaved?: (rate: IRateResSchema) => void;
  setOfferingRate?: (val: IRateResSchema | null) => void;
  createOfferingRate: (
    data: IRateReqSchema | IAccountRateCreateReqSchema,
    offeringId?: string | undefined,
  ) => Promise<IRateResSchema | null>;
  updateOfferingRate: (
    data: IRateReqSchema | IAccountRateUpdateReqSchema,
    rateId: string,
    offeringId?: string | undefined,
  ) => Promise<IRateResSchema | null>;
  setOrderedPricesUnderProduct?: (val: IPriceUnderProductSchemaUI[]) => void;
  cancelSaveCustomRates: () => void;
}

export const PricesDrawerBodyEditProduct: FC<
  PricesDrawerBodyEditProductProps
> = ({
  account,
  offeringRate,
  providedOfferingRate,
  productWithPrices,
  productsWithPrices,
  productsObj,
  latestPayload,
  isEditMode,
  offeringType,
  setIsEditMode,
  setLatestPayload,
  onRateSaved,
  setOfferingRate,
  createOfferingRate,
  updateOfferingRate,
  setOrderedPricesUnderProduct,
  cancelSaveCustomRates,
}: PricesDrawerBodyEditProductProps) => {
  const { lockPriceModelUpdateOnQuote } = useFlags();
  const isUsageTypeProduct =
    productWithPrices.productType === ProductTypeEnum.USAGE;
  const isPoTOfferingType =
    offeringType === OfferingTypesEnum.CUSTOM_PERCENT_OF_TOTAL;

  const {
    control,
    setValue,
    getValues,
    clearErrors,
    formState: { errors },
  } = useFormContext();
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: `products.${productWithPrices.id}.prices`,
    keyName: 'pId',
  });

  useEffect(() => {
    if (fields.length === 0) {
      append(
        getNextTierPrice({
          productId: productWithPrices.id,
          previousFrom: null,
          previousTo: null,
          previousAmount: null,
        }) as any,
      );
    }
  }, []);

  const handleRemovePrice = (index: number) => {
    // Make sure to clear any errors while removing a price tier
    if ((errors?.products as any)?.[productWithPrices.id].prices) {
      clearErrors(`products.${productWithPrices.id}.prices`);
    }
    const rowToRemove = getValues(
      `products.${productWithPrices.id}.prices.${index}`,
    ) as IPriceReqSchema;

    if (index !== fields.length - 1) {
      /** adjust next row from value with current row from value */
      setValue(
        `products.${productWithPrices.id}.prices.${index + 1}.from`,
        rowToRemove.from,
      );
    }

    remove(index);
  };

  return (
    <MBox pt="2">
      <MText mb="4" as="h5" fontSize="md" fontWeight="bold">
        {productWithPrices.name}{' '}
        <MText as="span" fontWeight="normal">
          ({PRODUCT_TYPE_DISPLAY_SHORT[productWithPrices.productType]})
        </MText>
      </MText>

      <MBox>
        <MHStack spacing="4" mb="2">
          <MBox minW="4" />
          <MText minW="40" fontWeight="bold">
            Pricing Model
          </MText>
          <MText minW="6.25rem" fontWeight="bold">
            From
          </MText>
          <MText minW="6.25rem" fontWeight="bold">
            Up to
          </MText>
          <MText minW="32" fontWeight="bold">
            {isPoTOfferingType ? 'Percentage' : 'Amount'}
          </MText>
          <MBox minW="8" />
        </MHStack>

        {isEditMode &&
          fields.map((field, index) => {
            const isLastRow = fields.length - 1 === index;
            const lastRowIndex = fields.length - 1;
            return (
              <RatePrice
                isPriceDrawer
                offeringType={offeringType}
                key={field.pId}
                index={index}
                id={field.pId}
                isOpen
                product={productWithPrices}
                isLastRow={isLastRow}
                lastRowIndex={lastRowIndex}
                handleRemovePrice={handleRemovePrice}
                generateNewPrice={(
                  previousFrom,
                  previousTo,
                  previousPriceModel,
                  previousAmount,
                ) =>
                  append(
                    getNextTierPrice({
                      productId: productWithPrices.id,
                      previousFrom,
                      previousTo,
                      previousPriceModel,
                      previousAmount,
                    }) as any,
                  )
                }
                isLocked={
                  !CUSTOMIZABLE_RATE_PRODUCT_TYPES.has(
                    productWithPrices.productType,
                  )
                }
                addPriceTierProps={{
                  pl: 0,
                  mb: 2,
                }}
                clearButtonProps={{ ml: '-2' }}
                currency={offeringRate.currency}
                isPriceModelDisabled={lockPriceModelUpdateOnQuote}
              />
            );
          })}

        {!isEditMode &&
          productWithPrices.prices.map(
            (
              { id, from, to, priceModel, amount, product },
              productIndex: number,
            ) => {
              const formattedFrom = formatNumber(from, {
                maximumFractionDigits: 0,
              });

              const formattedTo =
                formatNumber(to, {
                  maximumFractionDigits: 0,
                }) || '∞';

              const isLastRow =
                productWithPrices.prices.length - 1 === productIndex;

              return (
                <MHStack
                  align="flex-start"
                  spacing={4}
                  mb={!isLastRow ? 2 : 0}
                  key={`${productIndex}-range-${id}`}
                >
                  <MFlex align="center" minW={4} minH={8}>
                    <MText color="tPurple.base">{`${productIndex + 1}.`}</MText>
                  </MFlex>
                  <MFlex align="center" minH="8" minW="40">
                    <MText>{OFFERINGS.PRICE_MODEL_OPTIONS[priceModel]}</MText>
                  </MFlex>
                  <MFlex align="center" minH="8" minW="6.25rem">
                    <MText>{formattedFrom}</MText>
                  </MFlex>
                  <MFlex align="center" minH="8" minW="6.25rem">
                    <MText>{formattedTo}</MText>
                  </MFlex>
                  <MFlex align="center" minH="8" w="7.125rem">
                    {isPoTOfferingType && <MPercent amount={amount} />}
                    {!isPoTOfferingType && (
                      <MCurrency
                        amount={
                          priceModel === PriceModelEnum.CUSTOM
                            ? productsObj.byId[product.id]?.unitPrice
                            : amount
                        }
                        options={{
                          maximumFractionDigits: 'max',
                          currency: offeringRate.currency,
                        }}
                      />
                    )}
                  </MFlex>

                  <MBox minW="8" />
                </MHStack>
              );
            },
          )}
      </MBox>
    </MBox>
  );
};
