import { Link, StackProps } from '@chakra-ui/react';
import { UseFormReturn } from 'react-hook-form';
import { MDivider, MFlex, MLink, MText } from '~app/components/Monetize';
import { INewQuoteTypeReqSchema, IQuoteRespSchema } from '~app/types';
import { formatCurrency } from '~app/utils';
import { QuoteAnalyticsV2 } from './quoteAnalytics/QuoteAnalyticsV2';

interface QuoteHeaderSummaryLineProps extends StackProps {
  quote: IQuoteRespSchema | null | undefined;
  manualRenewalFormMethods?: UseFormReturn<INewQuoteTypeReqSchema, object>;
  setQuote: (quote: IQuoteRespSchema | null) => void;
}

const QuoteHeaderSummaryLineItem = ({
  label,
  children,
}: {
  label: string;
  children: React.ReactNode;
}) => {
  return (
    <MFlex alignItems="center">
      <MText fontWeight="bold" mr="2">
        {label}:{' '}
      </MText>
      {children}
    </MFlex>
  );
};

export const QuoteHeaderSummaryLine = ({
  quote,
  manualRenewalFormMethods,
  setQuote,
}: QuoteHeaderSummaryLineProps) => {
  if (!quote) {
    return null;
  }

  return (
    <MFlex alignItems="center">
      <QuoteHeaderSummaryLineItem label="ARR">
        <QuoteAnalyticsV2
          quote={quote as IQuoteRespSchema}
          setQuote={setQuote}
          manualRenewalFormMethods={manualRenewalFormMethods}
          key={'QuoteAnalytics'}
          renderTrigger={({ onOpen }) => (
            <MLink fontSize="sm" fontWeight="normal" as={Link} onClick={onOpen}>
              {formatCurrency(quote?.revenueMetrics.arr, {
                currency: quote?.currency,
              })}
            </MLink>
          )}
        />
      </QuoteHeaderSummaryLineItem>

      <MDivider
        orientation="vertical"
        mx="3"
        borderColor="tBlack.200"
        height="20px"
      />
      <QuoteHeaderSummaryLineItem label="TCV">
        <MText>
          {formatCurrency(quote?.amount, { currency: quote?.currency })}
        </MText>
      </QuoteHeaderSummaryLineItem>
    </MFlex>
  );
};
