import { UseQueryOptions } from '@tanstack/react-query';
import { useGetCurrencySettings } from '~app/api/settingsService';
import { ALL_CURRENCIES } from '~app/constants/currencies';
import { ApiListResponse, ICurrencyResp } from '~app/types';
import { formatCurrency } from '~app/utils';

/**
 * Simple wrapper for useCurrency to include the currencies and the default
 * Ensues that components do not need to worry about the default currency
 */
export const useCurrencies = (
  options?: Partial<
    UseQueryOptions<
      ApiListResponse<ICurrencyResp>,
      unknown,
      ApiListResponse<ICurrencyResp>
    >
  >,
) => {
  const { data, isLoading, isFetching, isInitialLoading } =
    useGetCurrencySettings(
      {},
      {
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        retry: false,
        staleTime: 1000 * 60 * 60, // 1 hour,
        select: (response) => {
          return (
            response?.content.map(
              (currency): ICurrencyResp & { description: string } => ({
                ...currency,
                description: ALL_CURRENCIES[currency.code].name,
              }),
            ) || []
          );
        },
      },
    );
  const currencies = data || [];

  const defaultCurrency =
    currencies?.find((item) => item.defaultCurrency)?.code || 'USD';

  const currencyPlaceholder = formatCurrency('0', {
    currency: defaultCurrency as string,
  });

  return {
    isLoading,
    isFetching,
    currencies,
    defaultCurrency,
    currencyPlaceholder,
    isInitialLoading,
  };
};
