import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { handleApiErrorToast } from '~app/api/axios';
import { useSaveNetsuiteConfiguration } from '~app/api/settingsService';
import {
  MButton,
  MCustomSelect,
  MDivider,
  MFormField,
  MGrid,
  MGridItem,
  MHStack,
  MInput,
} from '~app/components/Monetize';
import {
  INetsuite,
  NETSUITE_CUSTOMER_LOOKUP_OPTIONS,
  NETSUITE_PRODUCT_EXT_ID_OPTIONS,
  NetsuiteCustomerLookupEnum,
  NetsuiteProductExtIdEnum,
  NetsuiteSchema,
} from '~app/types/accountingTypes';
import { nullifyEmptyStrings } from '../../../../../utils/misc';

interface NetsuiteFormProps {
  connection?: INetsuite | null;
  onClose: () => void;
}

export const NetsuiteForm = ({ connection, onClose }: NetsuiteFormProps) => {
  const { mutate: saveConnection, isLoading } = useSaveNetsuiteConfiguration({
    onError: (err) => {
      handleApiErrorToast(err);
    },
    onSuccess: () => {
      onClose();
    },
  });
  const [connectionTestResult, setConnectionTestResult] = useState(null);

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors, isValid },
  } = useForm<INetsuite>({
    resolver: zodResolver(NetsuiteSchema),
    mode: 'onChange',
    defaultValues: {
      netsuiteProductExternalId: NetsuiteProductExtIdEnum.id,
      accountId: '',
      consumerKey: '',
      consumerSecret: '',
      tokenId: '',
      tokenSecret: '',
      ...connection,
      customerLookup: connection?.customerLookup
        ? connection.customerLookup
        : NetsuiteCustomerLookupEnum.NONE,
    },
  });

  // TODO: BE API needs to be implemented
  const onTestConnection = async () => {
    try {
      // setIsLoading(true);
      // const result = await doTestConnection();
      // setConnectionTestResult(result);
    } catch (error) {
      handleApiErrorToast(error);
    }
  };

  const onSubmitHandler = async (data: INetsuite) => {
    saveConnection(
      nullifyEmptyStrings({
        ...data,
        customerLookup:
          data.customerLookup === NetsuiteCustomerLookupEnum.NONE
            ? null
            : data.customerLookup,
      }),
    );
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmitHandler, (error) => console.log(error))}
      style={{ width: '100%' }}
    >
      <MGrid templateColumns="repeat(12, 1fr)" gap={4}>
        <MGridItem colSpan={12}>
          <MFormField error={errors.accountId} label="Account ID" isRequired>
            <Controller
              name="accountId"
              control={control}
              render={({ field }) => (
                <MInput {...field} isDisabled={isLoading} />
              )}
            />
          </MFormField>
        </MGridItem>
        <MGridItem colSpan={6}>
          <MFormField
            error={errors.consumerKey}
            label="Consumer Key"
            isRequired
          >
            <Controller
              name="consumerKey"
              control={control}
              render={({ field }) => (
                <MInput {...field} isDisabled={isLoading} />
              )}
            />
          </MFormField>
        </MGridItem>
        <MGridItem colSpan={6}>
          <MFormField
            error={errors.consumerSecret}
            label="Consumer Secret"
            isRequired
          >
            <Controller
              name="consumerSecret"
              control={control}
              render={({ field }) => (
                <MInput {...field} isDisabled={isLoading} />
              )}
            />
          </MFormField>
        </MGridItem>
        <MGridItem colSpan={6}>
          <MFormField error={errors.tokenId} label="Access Token ID" isRequired>
            <Controller
              name="tokenId"
              control={control}
              render={({ field }) => (
                <MInput {...field} isDisabled={isLoading} />
              )}
            />
          </MFormField>
        </MGridItem>
        <MGridItem colSpan={6}>
          <MFormField
            error={errors.tokenSecret}
            label="Access Token Secret"
            isRequired
          >
            <Controller
              name="tokenSecret"
              control={control}
              render={({ field }) => (
                <MInput {...field} isDisabled={isLoading} />
              )}
            />
          </MFormField>
        </MGridItem>
        <MGridItem colSpan={6}>
          <MFormField
            error={errors.customerLookup}
            label="Matching Field for Customer Lookup"
            tooltip="For Accounts in MonetizeNow that are not yet linked to a NetSuite Customer, select which field should be used to find an existing NetSuite Customer before creating a new customer."
            isRequired
          >
            <Controller
              name="customerLookup"
              control={control}
              render={({ field }) => (
                <MCustomSelect
                  items={NETSUITE_CUSTOMER_LOOKUP_OPTIONS}
                  {...field}
                  isDisabled={isLoading}
                />
              )}
            />
          </MFormField>
        </MGridItem>
        <MGridItem colSpan={6}>
          <MFormField
            error={errors.netsuiteProductExternalId}
            label="NetSuite Item External ID"
            tooltip="Field from the MonetizeNow product that will be used to match to an Item's external ID in NetSuite"
            isRequired
          >
            <Controller
              name="netsuiteProductExternalId"
              control={control}
              render={({ field: { value, ...rest } }) => (
                <MCustomSelect
                  items={NETSUITE_PRODUCT_EXT_ID_OPTIONS}
                  value={value || NetsuiteCustomerLookupEnum.NONE}
                  isDisabled={isLoading}
                  {...rest}
                />
              )}
            />
          </MFormField>
        </MGridItem>
        <MGridItem colSpan={6}>
          <MFormField
            error={errors.taxItemId}
            label="NetSuite Item Id for Tax Line"
            tooltip="NetSuite Item ID (i.e. Product) used on Invoices and Credit Notes for tax amounts."
          >
            <Controller
              name="taxItemId"
              control={control}
              render={({ field: { value, ...rest } }) => (
                <MInput value={value || ''} {...rest} isDisabled={isLoading} />
              )}
            />
          </MFormField>
        </MGridItem>
      </MGrid>
      <MDivider my={4} />
      <MHStack spacing="4" justify="end" align="center">
        <MButton variant="cancel" onClick={onClose} isDisabled={isLoading}>
          Cancel
        </MButton>
        <MButton type="submit" variant="primary" isLoading={isLoading}>
          {connection?.accountId ? 'Save' : 'Connect'}
        </MButton>
      </MHStack>
    </form>
  );
};
