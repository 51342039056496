import { Tab } from '@chakra-ui/react';
import { FC, useRef, useState } from 'react';
import {
  MBox,
  MButton,
  MDivider,
  MDrawer,
  MDrawerBody,
  MDrawerCloseButton,
  MDrawerContent,
  MDrawerFooter,
  MDrawerHeader,
  MDrawerOverlay,
  MFlex,
  MStack,
  MTabList,
  MTabPanel,
  MTabPanels,
  MTabs,
} from '~app/components/Monetize';
import { useACL } from '~app/services/acl/acl';
import {
  AddressSourceEnum,
  IContactAddressDataSchema,
  IContactRespSchema,
} from '~app/types';
import { ContactList } from './ContactList';

import { ContactsAddressList } from './ContactsAddressList';
import { useContacts } from './useContacts';

// Jest was constantly re-rendering when arrays were defined inline

interface ContactsDrawerProps {
  accountId: string;
  contacts: IContactRespSchema[];
  contactsById: Record<string, IContactRespSchema>;
  isReadOnly?: boolean;
  initialContactsWithAddressInformation: {
    billingContactId?: string;
    shippingContactId?: string;
    addressInformation: IContactAddressDataSchema;
  };
  onClose: (didChange?: boolean) => void;
  onSaveContacts: (payload: { primaryId?: string; billingId?: string }) => void;
  onSaveAddressData: (payload: IContactAddressDataSchema) => void;
}

export const ContactsDrawer: FC<ContactsDrawerProps> = ({
  accountId,
  contacts,
  contactsById,
  isReadOnly,
  initialContactsWithAddressInformation,
  onClose,
  onSaveAddressData,
  onSaveContacts,
}: ContactsDrawerProps) => {
  const cancelBtnRef = useRef(null);
  const [isDisabled] = useState(isReadOnly);
  const {
    isLoading,
    externalContacts,
    internalContacts,
    externalAccountContacts,
    internalAccountContacts,
    additionalRecipientSelectedContacts,
    didSave,
    handleToggle,
    handleRemove,
    handleUpdatedContact,
    handleNewContact,
    handleContactSelectionChange,
    handleSelectedAdditionalRecipients,
    handleCreatedNewAdditionalContact,
    handleAdditionalContactRemove,
    addressDataForm,
    onSaveAddress,
  } = useContacts({
    initialContactsWithAddressInformation,
    accountContacts: contacts,
    accountContactsById: contactsById,
    onSaveContacts,
    onSaveAddressData,
  });
  const addressSource = addressDataForm.watch('addressSource');
  const { canDo } = useACL();

  function handleClose() {
    onClose();
  }

  function handleTabChange() {}

  return (
    <MDrawer
      isOpen
      placement="right"
      onClose={handleClose}
      size="md"
      initialFocusRef={cancelBtnRef}
      onEsc={handleClose}
      onOverlayClick={handleClose}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      returnFocusOnClose={false}
      blockScrollOnMount={false}
    >
      <MDrawerOverlay />
      <MDrawerContent>
        <MDrawerCloseButton />
        <MDrawerHeader>Contacts</MDrawerHeader>
        <MDrawerBody
          p={0}
          bg="tGray.sidebarDark"
          data-testid="contact-drawer-body"
        >
          <MTabs
            variant="line"
            size="sm"
            backgroundColor="tWhite.base"
            defaultIndex={0}
            onChange={handleTabChange}
          >
            <MTabList px={6} pb={2}>
              <Tab data-testid="contacts-contact-tab">Contacts</Tab>
              {addressSource === AddressSourceEnum.ACCOUNT && (
                <Tab data-testid="contacts-address-tab">Addresses</Tab>
              )}
            </MTabList>
            <MTabPanels p={2} bg="tGray.sidebarDark">
              <MTabPanel p={0} data-testid="contacts-contact-content">
                <MStack>
                  <ContactList
                    isDisabled={isDisabled}
                    accountId={accountId}
                    accountContacts={externalAccountContacts}
                    contacts={externalContacts}
                    isInternal={false}
                    onToggle={handleToggle}
                    onRemove={handleRemove}
                    onAddContact={handleContactSelectionChange}
                    onUpdateContact={handleUpdatedContact}
                    onNewContact={handleNewContact}
                    canUpdateContact={canDo([['account_contacts', 'update']])}
                    canCreateContact={canDo([['account_contacts', 'create']])}
                    addressDataForm={addressDataForm}
                    onSaveAddress={onSaveAddress}
                  />
                </MStack>
              </MTabPanel>
              {addressSource === AddressSourceEnum.ACCOUNT && (
                <MTabPanel
                  p={0}
                  bg="tGray.sidebarDark"
                  data-testid="contacts-esign-content"
                >
                  <ContactsAddressList
                    isDisabled={isDisabled}
                    accountId={accountId}
                    addressDataForm={addressDataForm}
                  />
                </MTabPanel>
              )}
            </MTabPanels>
          </MTabs>
        </MDrawerBody>

        <MDivider />

        <MDrawerFooter>
          <MBox w="full">
            <MFlex justifyContent="center">
              <MButton
                ref={cancelBtnRef}
                data-testid="contact-drawer-close-btn"
                onClick={() => handleClose()}
                isLoading={isLoading}
                variant="cancel"
              >
                Close
              </MButton>
            </MFlex>
          </MBox>
        </MDrawerFooter>
      </MDrawerContent>
    </MDrawer>
  );
};
