import { FunctionComponent, PropsWithChildren } from 'react';
import { FooterRow, FooterRowProps } from './FooterRow';

export interface FooterRowAmendV2Props extends FooterRowProps {
  priorValue?: string;
  amendmentValue?: string;
}

export const FooterRowAmendV2: FunctionComponent<FooterRowAmendV2Props> = ({
  priorValue,
  amendmentValue,
  ...rest
}: PropsWithChildren<FooterRowAmendV2Props>) => {
  return (
    <FooterRow
      {...rest}
      extraValues={[
        { value: priorValue || '' },
        { value: amendmentValue || '', styles: { color: 'tPurple.safety' } },
      ]}
    />
  );
};
