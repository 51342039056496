import {
  FocusLock,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  useDisclosure,
} from '@chakra-ui/react';
import lodashGet from 'lodash/get';
import isString from 'lodash/isString';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { MdFilterAlt, MdFilterList } from 'react-icons/md';
import { useNonInitialEffect } from '../../../../hooks/useNonInitialEffect';
import MCustomIconButton from '../../MCustomIconButton';
import MCustomRadioGroup from '../../MCustomRadioGroup';
import MText from '../../MText';
import {
  BooleanOption,
  DataTableFilterRef,
  FilterProps,
} from '../DataTableTypes';

const ALL: BooleanOption = 'ALL';
const CHECKED: BooleanOption = 'CHECKED';
const UNCHECKED: BooleanOption = 'UNCHECKED';

const OPTIONS = [
  {
    title: 'All',
    value: ALL,
  },
  {
    title: 'Checked',
    value: CHECKED,
  },
  {
    title: 'Unchecked',
    value: UNCHECKED,
  },
];

export const DataTableHeaderBooleanFilter = forwardRef<
  DataTableFilterRef,
  FilterProps<any>
>(({ column, table }, ref) => {
  useImperativeHandle(
    ref,
    (): DataTableFilterRef => ({
      resetFilters: () => {
        setSelectedValue('ALL');
      },
      setFilterValue: ({ columnKey, value }) => {
        if (
          columnKey === column.id &&
          isString(value) &&
          [ALL, CHECKED, UNCHECKED].includes(value as BooleanOption)
        ) {
          setSelectedValue(value as BooleanOption);
        }
      },
    }),
  );

  const key = lodashGet(column, 'columnDef.accessorKey', column.id);

  const [selectedValue, setSelectedValue] = useState<BooleanOption>(ALL);
  const { isOpen, onOpen, onClose } = useDisclosure();

  useNonInitialEffect(() => {
    column.setFilterValue(selectedValue);
  }, [column, selectedValue]);

  const handleClose = () => {
    onClose();
  };

  const filtersApplied = selectedValue !== 'ALL';

  return (
    <Popover
      placement="auto-start"
      trigger="click"
      offset={[0, 0]}
      variant="responsive"
      isOpen={isOpen}
      onClose={handleClose}
      strategy="fixed"
      isLazy
    >
      <PopoverTrigger>
        <MCustomIconButton
          data-testid={`table-column-filter-${key}`}
          btnSize={5}
          onClick={onOpen}
          icon={filtersApplied ? MdFilterAlt : MdFilterList}
          variant="icon"
          ml="2"
          color={filtersApplied ? 'tIndigo.base' : 'tPurple.base'}
          iconColor={filtersApplied ? 'tIndigo.base' : 'tPurple.base'}
        />
      </PopoverTrigger>
      <PopoverContent>
        {isOpen && (
          <FocusLock>
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverHeader fontWeight="semibold">
              <MText pt={4} px={4}>
                Filter
              </MText>
            </PopoverHeader>
            <PopoverBody minW="304px">
              <MCustomRadioGroup
                value={selectedValue}
                my={1}
                itemTitle="title"
                itemValue="value"
                items={OPTIONS}
                onChange={(value) => setSelectedValue(value as BooleanOption)}
              />
            </PopoverBody>
          </FocusLock>
        )}
      </PopoverContent>
    </Popover>
  );
});
