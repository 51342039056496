import React, { FunctionComponent } from 'react';
import { MdClear } from 'react-icons/md';
import {
  MGrid,
  MIcon,
  MCircularProgress,
  MBox,
  MTooltip,
} from '~app/components/Monetize';

const ActionCell: FunctionComponent<any> = (
  props: any,
): React.ReactElement | null => {
  const { visible, onClick, isLoading, ...rest } = props;

  if (visible) {
    return (
      <MTooltip label="Delete" placement="bottom-start">
        <MGrid onClick={onClick} cursor="pointer" {...rest}>
          {isLoading ? (
            <MCircularProgress isIndeterminate size={4} />
          ) : (
            <MIcon as={MdClear} />
          )}
        </MGrid>
      </MTooltip>
    );
  }

  return (
    <MBox w={isLoading ? 4 : 7} pr={isLoading ? 0 : 3} justifySelf="center">
      {isLoading && <MCircularProgress isIndeterminate size={4} />}
    </MBox>
  );
};

export default ActionCell;
