import {
  CONTRACT_STATUS_AMEND_ALLOWED,
  CONTRACT_STATUS_CANCEL_ALLOWED,
  CONTRACT_STATUS_RENEWAL_ALLOWED,
} from '~app/constants/contracts';
import {
  ContractEndActionEnum,
  ContractStatusEnum,
  IContract,
} from '~app/types';

export function canAmendContract(contract: IContract) {
  if (!contract || contract.renewed) {
    return false;
  }
  return CONTRACT_STATUS_AMEND_ALLOWED.has(contract.status);
}

export function canRenewContract(contract: IContract) {
  if (!contract || contract.renewed) {
    return false;
  }
  return CONTRACT_STATUS_RENEWAL_ALLOWED.has(contract.status);
}

export function canTurnOffAutoRenew(contract: IContract) {
  if (!contract) {
    return false;
  }
  return (
    contract.endAction === ContractEndActionEnum.RENEW &&
    contract.status === ContractStatusEnum.ACTIVE &&
    !contract.renewed
  );
}

export function canCancelContract(contract: IContract) {
  if (!contract) {
    return false;
  }
  return (
    CONTRACT_STATUS_CANCEL_ALLOWED.has(contract.status) && !contract.renewed
  );
}
