import { parseISO } from 'date-fns/parseISO';
import { FC } from 'react';
import { Controller, useFormContext, useFormState } from 'react-hook-form';
import { handleApiErrorToast } from '~app/api/axios';
import {
  MCheckbox,
  MCustomSelect,
  MFlex,
  MFormField,
  MGrid,
  MText,
} from '~app/components/Monetize';
import { DatePicker } from '~app/components/Monetize/DatePicker/DatePicker';
import { useNetTerms } from '~app/hooks';
import { useIsTenantEsignConfigured } from '~app/hooks/useIsTenantEsignConfigured';
import { useFlags } from '~app/services/launchDarkly';
import {
  INetTerm,
  IQuoteRequestSchema,
  QuoteAmendmentVersionEnum,
} from '~app/types';
import { getQuoteType } from '~app/utils';
import { toDateShort } from '~app/utils/dates';
import { getQuoteFormHeaderGridTemplateColumns } from '~app/utils/quotes';
import { QuoteContractLengthPopover } from './components/QuoteContractLengthPopover';
import { QuoteContractRenewalTermsAction } from './components/QuoteContractRenewalTermsAction';
import QuoteContactInput from './components/contacts/QuoteContactInput';
import { useQuoteContext } from './quoteContext';

interface QuoteFormHeaderProps {
  onOpenContactDrawer: () => void;
}

const QuoteFormHeader: FC<QuoteFormHeaderProps> = ({
  onOpenContactDrawer,
}: QuoteFormHeaderProps) => {
  const {
    handleSubmitButtonWithoutDirtyCheck,
    quoteData: { quote, modifiedFields, updateQuote },
    quoteSettingsData,
    showPrimaryContactRequiredBg,
    isReadOnly,
    isLoading,
    useAmendmentV2,
  } = useQuoteContext();
  const { isNew, isAmendment, isRenewal } = getQuoteType(quote);
  const { activeNetTerms, netTermsById } = useNetTerms();
  const isTenantEsignConfigured = useIsTenantEsignConfigured();
  const { hideAutoRenewal } = useFlags();
  const { control, getValues, setValue } =
    useFormContext<IQuoteRequestSchema>();
  const { errors } = useFormState({
    control,
  });

  const selectedNetTerm = getValues('netTerms');
  const gridTemplateColumns = getQuoteFormHeaderGridTemplateColumns({
    isAmendment,
    isRenewal,
    useAmendmentV2,
  });

  // make sure that if inactive net term is selected, it is included in dropdown
  let netTermsOptions: INetTerm[] = activeNetTerms;
  if (
    selectedNetTerm &&
    netTermsById[selectedNetTerm] &&
    !netTermsById[selectedNetTerm].active
  ) {
    netTermsOptions = [...netTermsOptions, netTermsById[selectedNetTerm]];
  }

  const handleSaveQuoteContractRenewalTerms = async (
    payload: IQuoteRequestSchema,
  ): Promise<void> => {
    try {
      await updateQuote(quote?.id!, payload);
    } catch (ex) {
      handleApiErrorToast(ex);
    }
  };

  if (!quote) {
    return null;
  }

  return (
    <MGrid gap={4} gridTemplateColumns={gridTemplateColumns} alignItems="start">
      {isAmendment && !useAmendmentV2 && (
        <MFormField error={errors.contractAmendmentDate} label="Amendment Date">
          <Controller
            name="contractAmendmentDate"
            control={control}
            render={({ field: { onChange, ...rest } }) => (
              <DatePicker
                {...rest}
                triggerType="button"
                btnText={toDateShort(quote?.contractAmendmentDate || null)}
                minDate={
                  quote?.contractStartDate
                    ? parseISO(quote.contractStartDate)
                    : undefined
                }
                maxDate={
                  quote?.contractEndDate
                    ? parseISO(quote.contractEndDate)
                    : undefined
                }
                onChange={(date) => {
                  onChange(date);
                  handleSubmitButtonWithoutDirtyCheck();
                }}
                isDisabled={!quote}
                isReadOnly={isReadOnly}
                btnProps={{
                  my: 0,
                  ml: -3,
                  mr: 0,
                  'data-testid': 'contractStartDateBtn',
                }}
                readonlyContentProps={{ pb: 0, px: 3 }}
              />
            )}
          />
        </MFormField>
      )}

      <MFormField error={errors.contractStartDate} label="Contract Period">
        {quote?.contractStartDate && quote?.contractEndDate && (
          <MFlex minH={8} align="center">
            <MFlex align="center" ml="-3">
              {!(isAmendment || isRenewal) && (
                <Controller
                  name="contractStartDate"
                  control={control}
                  render={({ field: { onChange, ...rest } }) => (
                    <DatePicker
                      {...rest}
                      isReadOnly={isReadOnly}
                      triggerType="button"
                      btnText={toDateShort(quote?.contractStartDate || null)}
                      isLoading={modifiedFields?.has('contractStartDate')}
                      onChange={(date) => {
                        onChange(date);
                        handleSubmitButtonWithoutDirtyCheck();
                      }}
                      btnProps={{ m: 0, 'data-testid': 'contractStartDateBtn' }}
                      readonlyContentProps={{ pb: 0, px: 3 }}
                      isDisabled={!quote}
                    />
                  )}
                />
              )}

              {(isRenewal || isAmendment) && (
                <MText
                  px="3"
                  fontWeight="normal"
                  fontSize="sm"
                  color="tPurple.base"
                  whiteSpace="pre-wrap"
                >
                  {toDateShort(quote?.contractStartDate || null)}
                </MText>
              )}
              <MText as="span">-</MText>
              <MText
                pl="3"
                fontWeight="normal"
                fontSize="sm"
                color="tPurple.base"
                whiteSpace="pre-wrap"
              >
                {toDateShort(quote?.contractEndDate || null)}
              </MText>
            </MFlex>
          </MFlex>
        )}
      </MFormField>
      <QuoteContractLengthPopover
        isCustomContractLength={
          quoteSettingsData.quoteSettings?.customContractLength
            .allowCustomLength!
        }
        isNew={isNew}
        isAmendment={isAmendment}
        quote={quote}
        setValue={setValue}
        handleSubmitButton={handleSubmitButtonWithoutDirtyCheck}
        isDisabled={
          (isAmendment &&
            quote.amendmentVersion === QuoteAmendmentVersionEnum.v1) ||
          isReadOnly
        }
      />
      {!hideAutoRenewal && (
        <QuoteContractRenewalTermsAction
          contractRenewalTerms={quote?.contractRenewalTerms}
          defaultContractRenewalTerms={
            quoteSettingsData.quoteSettings?.contractRenewalTerms
          }
          onSubmit={async (contractRenewalTerms) => {
            try {
              await handleSaveQuoteContractRenewalTerms({
                ...getValues(),
                contractRenewalTerms,
              });
              setValue('contractRenewalTerms', contractRenewalTerms, {
                shouldDirty: false,
              });
            } catch (ex) {
              handleApiErrorToast(ex);
            }
          }}
          isReadOnly={isReadOnly}
        />
      )}
      <MFormField error={errors.expirationDate} label="Quote Expiration Date">
        <Controller
          name="expirationDate"
          control={control}
          render={({ field: { onChange, ...rest } }) => (
            <DatePicker
              data-testid="date-picker-expirationDate"
              {...rest}
              isDisabled={isReadOnly}
              isReadOnly={isReadOnly}
              minDate={new Date()}
              placeholder="Select End Date"
              onChange={(date) => {
                onChange(date);
                handleSubmitButtonWithoutDirtyCheck();
              }}
              readonlyContentProps={{ pb: 0, pt: 1.5 }}
            />
          )}
        />
      </MFormField>

      <MFormField error={errors.netTerms} label="Net Terms" isRequired>
        <Controller
          name="netTerms"
          control={control}
          render={({ field: { onChange, ...rest } }) => (
            <MCustomSelect
              isDisabled={isReadOnly}
              isReadOnly={isReadOnly}
              itemTitle="name"
              itemValue="value"
              items={netTermsOptions}
              placeholder="Select Net Terms"
              onChange={(e: any) => {
                onChange(e);
                handleSubmitButtonWithoutDirtyCheck();
              }}
              {...rest}
            />
          )}
        />
      </MFormField>
      <QuoteContactInput
        showPrimaryContactRequiredBg={showPrimaryContactRequiredBg}
        contact={quote?.contacts?.primary}
        onEditContacts={() => {
          onOpenContactDrawer();
        }}
      />

      {isTenantEsignConfigured && (
        <MFormField
          display="flex"
          error={errors.requiresEsign}
          alignSelf="flex-end"
          alignItems="center"
          minH={8}
          label=""
        >
          <Controller
            name="requiresEsign"
            control={control}
            render={({ field: { value, onChange, ...rest } }) => (
              <MCheckbox
                isReadOnly={isReadOnly}
                isDisabled={isReadOnly}
                isChecked={value as any}
                onChange={(e) => {
                  onChange(e.target.checked);
                  handleSubmitButtonWithoutDirtyCheck();
                }}
                {...rest}
              >
                eSign
              </MCheckbox>
            )}
          />
        </MFormField>
      )}
    </MGrid>
  );
};

export default QuoteFormHeader;
