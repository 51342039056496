import React from 'react';
import { MBox, MFlex } from '~app/components/Monetize';

export const OfferingRateSeparator = () => {
  return (
    <MFlex
      alignItems="center"
      justifyContent="center"
      borderTop="1px"
      borderBottom="1px"
      width="1px"
      borderColor="tGray.lightPurple"
    >
      <MBox height="4" width="1px" backgroundColor="tGray.lightPurple" />
    </MFlex>
  );
};
