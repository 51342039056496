import groupBy from 'lodash/groupBy';
import { SupportedCurrencyCodes } from '../constants/currencies';
import {
  CUSTOM_SELECT_SUBTITLE_TYPE,
  OPTION_TYPE_FIELD,
} from '../constants/customSelect';
import { PaymentMethodTypeEnumEndUserDisplay } from '../constants/paymentMethods';
import {
  IPaymentMethodResp,
  Maybe,
  PaymentGatewayConfigurations,
  PaymentMethodSummaryResponse,
  PaymentMethodTypeEnum,
} from '../types';

export function initDefaultPaymentGatewayOptionsIfNotExists(
  supportedCurrencies: SupportedCurrencyCodes[],
  configuration?: Maybe<PaymentGatewayConfigurations>,
): PaymentGatewayConfigurations {
  if (configuration?.configurations.length) {
    return configuration;
  }
  return {
    configurations: [
      {
        type: PaymentMethodTypeEnum.US_BANK_TRANSFER,
        amountConfigurations: [
          {
            currency: 'USD',
            minimum: 0,
            maximum: null,
          },
        ],
      },
    ],
  };
}

/**
 * Group payment methods by type and return a list of items for display in custom select
 * with subtitle of payment method type
 */
export const getGroupedAndSortedPaymentMethodOptions = <
  T extends IPaymentMethodResp | PaymentMethodSummaryResponse,
>(
  paymentMethods: Array<T>,
) => {
  const sortedPaymentMethods = paymentMethods.sort((a, b) => {
    const firstName = 'paymentMethodName' in a ? a.paymentMethodName : a.name;
    const secondName = 'paymentMethodName' in b ? b.paymentMethodName : b.name;
    return firstName.localeCompare(secondName);
  });
  const paymentMethodsByType = groupBy(
    sortedPaymentMethods,
    'paymentMethodType',
  );

  return Object.keys(paymentMethodsByType).flatMap((key) => {
    const paymentMethodType = key as PaymentMethodTypeEnum;
    return [
      {
        name: PaymentMethodTypeEnumEndUserDisplay[paymentMethodType],
        [OPTION_TYPE_FIELD]: CUSTOM_SELECT_SUBTITLE_TYPE,
      },
      ...paymentMethodsByType[key],
    ];
  });
};
