import { useQueryClient } from '@tanstack/react-query';
import { addDays } from 'date-fns/addDays';
import { isBefore } from 'date-fns/isBefore';
import { useEffect, useRef, useState } from 'react';
import { useGeneratePastInvoice } from '~app/api/accountsService';
import { handleApiErrorToast } from '~app/api/axios';
import { accountServiceQueryKeys } from '~app/api/queryKeysService';
import { useACL } from '~app/services/acl/acl';
import { useToast } from '~app/services/toast';
import { AccountStatusEnum, Maybe } from '~app/types';
import { parseDateAsUtc } from '~app/utils/dates';

const GEN_INVOICE_RETRIES = 3;
const GEN_INVOICE_INTERVAL = 1000 * 10; // 10 seconds

export const usePastInvoice = ({
  accountId,
  billGroupId,
  billGroupStatus,
  nextInvoiceDate,
  invoiceDelayDirty,
  invoiceDelay,
}: {
  accountId: string;
  billGroupId: string;
  billGroupStatus: string;
  nextInvoiceDate: string;
  invoiceDelayDirty?: Maybe<boolean>;
  invoiceDelay?: Maybe<number>;
}) => {
  const { canDo } = useACL();
  const queryClient = useQueryClient();
  const { addToast } = useToast();

  const [hasPastInvoices, setHasPastInvoices] = useState(() => false);

  useEffect(() => {
    setHasPastInvoices(
      !!nextInvoiceDate &&
        !invoiceDelayDirty &&
        isBefore(
          addDays(parseDateAsUtc(nextInvoiceDate), invoiceDelay || 0),
          new Date(),
        ),
    );
  }, [invoiceDelay, invoiceDelayDirty, nextInvoiceDate]);

  const genInvoiceInterval = useRef<{
    timeout: any;
    remainingChecks: number;
  }>({ timeout: null, remainingChecks: 5 });

  const { mutate: generatePreviousInvoice, isLoading } = useGeneratePastInvoice(
    {
      onSuccess: () => {
        addToast({
          summary: `Invoice Scheduled for Creation`,
          detail: `Creation may take a few minutes.  Clicking 'Generate' again may result in multiple invoices erroneously created.`,
          severity: 'success',
        });
        setHasPastInvoices(false);
        // Refetch the invoice {GEN_INVOICE_RETRIES} times, every {GEN_INVOICE_INTERVAL} seconds
        genInvoiceInterval.current.remainingChecks = GEN_INVOICE_RETRIES;
        genInvoiceInterval.current.timeout = setInterval(() => {
          queryClient.invalidateQueries(
            accountServiceQueryKeys.billGroups.billGroupByAccount(accountId),
          );
          queryClient.invalidateQueries(
            accountServiceQueryKeys.billGroups.billGroupById(billGroupId),
          );
          queryClient.invalidateQueries(
            accountServiceQueryKeys.accounts.accountDetailOverview(accountId),
          );
          genInvoiceInterval.current.remainingChecks--;
          if (genInvoiceInterval.current.remainingChecks <= 0) {
            clearInterval(genInvoiceInterval.current.timeout);
          }
        }, GEN_INVOICE_INTERVAL);
      },
      onError: (err) => {
        handleApiErrorToast(err);
      },
    },
  );

  const canUpdate =
    canDo([['billing', 'update']]) &&
    billGroupStatus === AccountStatusEnum.ACTIVE;

  return {
    hasPastInvoices,
    canUpdate,
    isLoading,
    generatePreviousInvoice,
  };
};
