import { differenceInDays } from 'date-fns/differenceInDays';
import { parseISO } from 'date-fns/parseISO';
import { MBox } from '~app/components/Monetize';
import {
  InactiveItemTypeEnumUI,
  IOfferingRes,
  IRateResBaseSchema,
  OfferingStatusEnum,
  QuoteTypeEnum,
  RateStatusEnum,
} from '~app/types';
import { InactiveItem } from './InactiveItem';

export function getIsRateEndDateIsBeforeContractStartDate(
  selectedRate?: IRateResBaseSchema,
  parsedContractStartDate?: Date,
) {
  const parsedInactiveRateEndDate = selectedRate?.endDate
    ? parseISO(selectedRate.endDate)
    : undefined;

  return parsedInactiveRateEndDate && parsedContractStartDate
    ? differenceInDays(parsedInactiveRateEndDate, parsedContractStartDate) <= 0
    : false;
}

export function getIsOfferingEndDateIsBeforeContractStartDate(
  selectedOffering?: IOfferingRes,
  parsedContractStartDate?: Date,
) {
  const parsedInactiveOfferingEndDate = selectedOffering?.endDate
    ? parseISO(selectedOffering.endDate)
    : undefined;

  return parsedInactiveOfferingEndDate && parsedContractStartDate
    ? differenceInDays(
        parsedInactiveOfferingEndDate,
        parsedContractStartDate,
      ) <= 0
    : false;
}

interface QuoteOfferingInactiveItemProps {
  offeringIndex: number;

  selectedOffering?: IOfferingRes;
  selectedRateId?: string | null;
  contractStartDate: string;
  isNewlyAddedOffering: boolean;
  quoteType: QuoteTypeEnum;
}
export const QuoteOfferingInactiveItem = ({
  offeringIndex,
  selectedOffering,
  selectedRateId,
  contractStartDate,
  isNewlyAddedOffering,
  quoteType,
}: QuoteOfferingInactiveItemProps) => {
  if (offeringIndex === -1 || !selectedOffering || !selectedRateId) {
    return null;
  }
  const parsedContractStartDate = parseISO(contractStartDate);
  const isOfferingEndDateIsBeforeContractStartDate =
    getIsOfferingEndDateIsBeforeContractStartDate(
      selectedOffering,
      parsedContractStartDate,
    );

  const showInactiveOffering =
    selectedOffering?.status !== OfferingStatusEnum.ACTIVE ||
    isOfferingEndDateIsBeforeContractStartDate;

  if (showInactiveOffering) {
    return (
      <InactiveItem
        type={InactiveItemTypeEnumUI.OFFERING}
        iconType={
          quoteType === QuoteTypeEnum.NEW || isNewlyAddedOffering
            ? 'warning'
            : 'info'
        }
      />
    );
  }

  return <MBox w="8" h="0" />;
};

interface QuoteOfferingInactiveRateItemProps {
  offeringIndex: number;
  selectedOffering?: IOfferingRes;
  selectedRateId?: string | null;
  contractStartDate: string;
  rates: IRateResBaseSchema[];
  isNewlyAddedOffering: boolean;
  quoteType: QuoteTypeEnum;
}

export const QuoteOfferingInactiveRateItem = ({
  offeringIndex,
  selectedOffering,
  selectedRateId,
  contractStartDate,
  rates,
  isNewlyAddedOffering,
  quoteType,
}: QuoteOfferingInactiveRateItemProps) => {
  if (offeringIndex === -1 || !selectedOffering || !selectedRateId) {
    return null;
  }

  const parsedContractStartDate = parseISO(contractStartDate);
  const selectedRate = rates.find(({ id }) => id === selectedRateId);
  const isRateEndDateIsBeforeContractStartDate =
    getIsRateEndDateIsBeforeContractStartDate(
      selectedRate,
      parsedContractStartDate,
    );

  const showInactiveRate =
    selectedRate?.status !== RateStatusEnum.ACTIVE ||
    !selectedRate?.quotable ||
    isRateEndDateIsBeforeContractStartDate;

  if (showInactiveRate) {
    return (
      <InactiveItem
        type={InactiveItemTypeEnumUI.RATE}
        iconType={
          quoteType === QuoteTypeEnum.NEW || isNewlyAddedOffering
            ? 'warning'
            : 'info'
        }
      />
    );
  }

  return null;
};
