import { FC } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import {
  MBox,
  MButton,
  MCard,
  MCustomIconButton,
  MFlex,
  MIcon,
  MPageLoader,
  MText,
} from '~app/components/Monetize';
import { QuoteCollaborationPopoverV2 } from '~app/components/Quotes/QuoteCollaborationPopoverV2';
import { IQuoteRequestSchema, CollaborationAccessEnum } from '~app/types';
import { useQuoteContext } from '../../Quote/quoteContext';
import MEditableInput from '~app/components/Monetize/MEditableInput';
import { QuoteBasicDetails } from './QuoteBasicDetails';
import { QuoteBasicContacts } from './QuoteBasicContacts';
import { MdSettings } from 'react-icons/md';
import { QuoteBasicContract } from './QuoteBasicContract';
import {
  QuoteStatusEnumDisplay,
  QuoteStatusTagStyle,
} from '~app/constants/quotes';

interface QuoteBasicProps {
  onOpenContactDrawer: () => void;
}
export const QuoteBasic: FC<QuoteBasicProps> = ({
  onOpenContactDrawer,
}: QuoteBasicProps) => {
  const {
    isReadOnly,
    quoteData,
    handleSubmitButton,
    handleSubmitButtonWithoutDirtyCheck,
  } = useQuoteContext();

  const { quote, setQuote } = quoteData;

  const { control, setValue } = useFormContext<IQuoteRequestSchema>();

  if (!quote) {
    return <MPageLoader />;
  }

  return (
    <MCard width={'100%'} p={4} borderColor="tGray.lightPurple" mb="8">
      <MFlex flexDir={['column', 'column', 'column', 'column', 'row']}>
        <MBox flex="1">
          <MFlex
            borderBottomWidth="1px"
            borderColor="tGray.support"
            alignItems="center"
            justifyContent="space-between"
          >
            <MText my="2" fontWeight="600" fontSize="md">
              Details
            </MText>

            <MBox mr="-1">
              <QuoteCollaborationPopoverV2
                quoteId={quote.id}
                quoteStatus={quote.status}
                collaborationAccess={quote.collaborationAccess}
                accessLevel={quote.accessLevel}
                onChange={(collaborationAccess: CollaborationAccessEnum) => {
                  setValue('collaborationAccess', collaborationAccess, {
                    shouldDirty: true,
                  });
                  handleSubmitButtonWithoutDirtyCheck();
                }}
              />
            </MBox>
          </MFlex>
          <QuoteBasicDetails />
        </MBox>
        <MBox mx="6" display={['hidden', 'hidden', 'visible']}>
          <MBox w="1px" background="tGray.support" height="100%"></MBox>
        </MBox>
        <MBox flex="1">
          <MFlex
            borderBottomWidth="1px"
            borderColor="tGray.support"
            alignItems="center"
            justifyContent="space-between"
          >
            <MText my="2" fontWeight="600" fontSize="md">
              Contract
            </MText>
          </MFlex>
          <QuoteBasicContract />
        </MBox>
        <MBox mx="6" display={['hidden', 'hidden', 'visible']}>
          <MBox w="1px" background="tGray.support" height="100%"></MBox>
        </MBox>
        <MBox flex="1">
          <MFlex
            borderBottomWidth="1px"
            borderColor="tGray.support"
            alignItems="center"
            justifyContent="space-between"
          >
            <MText my="2" fontWeight="600" fontSize="md">
              Contacts
            </MText>

            <MCustomIconButton
              btnSize={4}
              minW={6}
              minH={6}
              variant="icon"
              icon={MdSettings}
              iconColor="tIndigo.base"
              onClick={() => {
                onOpenContactDrawer();
              }}
            />
          </MFlex>
          <QuoteBasicContacts />
        </MBox>
      </MFlex>
    </MCard>
  );
};
