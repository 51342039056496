import { Link } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { IconType } from 'react-icons';
import { MdOpenInNew } from 'react-icons/md';
import { MFlex, MIcon } from './chakra';

export interface ItemWithLinkInNewWindowProps {
  url: string;
  icon?: IconType;
  children: ReactNode;
}

export const ItemWithLinkInNewWindow = ({
  url,
  icon = MdOpenInNew,
  children,
}: ItemWithLinkInNewWindowProps) => {
  return (
    <MFlex>
      {children}
      <Link href={url} target="_blank" onClick={(ev) => ev.stopPropagation()}>
        <MIcon as={icon} boxSize={3} ml={1} mt={1} />
      </Link>
    </MFlex>
  );
};
