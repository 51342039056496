import { nativeEnum, z } from 'zod';
import { getRequiredMessage } from '~app/utils/messages';

export enum EsignAccountBaseUrlEnum {
  DEMO = 'https://demo.docusign.net',
  PRODUCTION = 'https://www.docusign.net',
}
export const EsignAccountBaseUrlEnumZ = nativeEnum(EsignAccountBaseUrlEnum);

export const EsignSchema = z.object({
  userId: z.string().nonempty(getRequiredMessage('User ID')).uuid(),
  hmacKey: z.string().nonempty(getRequiredMessage('HMAC Key')),
  accountBaseURI: EsignAccountBaseUrlEnumZ,
});

export type IEsignSchema = z.infer<typeof EsignSchema>;

// this schema validates a record returned from the API -- it tells our code whether we have a valid saved eSign config
export const EsignSavedSchema = z.object({
  userId: z.string().nonempty(),
  hmacKey: z.string().nonempty(),
  accountBaseURI: EsignAccountBaseUrlEnumZ,
});

export interface IEsign {
  id: string;
  userId: string;
  hmacKey: string;
  accountBaseURI: EsignAccountBaseUrlEnum;
  locked: boolean;
}
