import { Icon, Link, Spinner, Stack } from '@chakra-ui/react';
import { IconType } from 'react-icons';
import { MdCheckCircle, MdError } from 'react-icons/md';
import { MBox, MFlex, MIcon, MText } from '../../../../components/Monetize';
import { ItemWithLinkInNewWindow } from '../../../../components/Monetize/ItemWithLinkInNewWindow';
import {
  getAccountDetailRoute,
  getInvoiceDetailRoute,
} from '../../../../constants/routes';
import {
  InvoiceSummaryResp,
  MassEmailCustomerInvoicesResults,
} from '../../../../types';

interface EmailListItemContentProps {
  row: InvoiceSummaryResp;
  result?: MassEmailCustomerInvoicesResults;
  isLoading: boolean;
}

export const EmailListItemContent = ({
  row,
  result,
  isLoading,
}: EmailListItemContentProps) => {
  return (
    <MFlex w="100%">
      <MBox pt={1} w="20px">
        {result && (
          <Icon
            as={result.success ? MdCheckCircle : MdError}
            color={result.success ? 'tGreen.approval' : 'tRed.base'}
            w="4"
            h="4"
            mr="1"
          />
        )}
        {!result && isLoading && <Spinner size="xs" />}
      </MBox>
      <Stack direction="column" spacing="1" width="100%">
        <ItemWithLinkInNewWindow url={getInvoiceDetailRoute(row.id)}>
          <MText fontWeight="bold">{row.invoiceNumber || row.id}</MText>
        </ItemWithLinkInNewWindow>
        <ItemWithLinkInNewWindow url={getAccountDetailRoute(row.account.id)}>
          <MText fontWeight="bold">{row.account.accountName}</MText>
        </ItemWithLinkInNewWindow>
        <MText>{row.billingContact.email}</MText>
        {Array.isArray(row.billGroup.ccEmails) &&
          row.billGroup.ccEmails.length > 0 && (
            <MText>CC: {row.billGroup.ccEmails.join(', ')}</MText>
          )}
        {result?.error && (
          <MText fontSize="xs" color={'tRed.base'}>
            {result.error}
          </MText>
        )}
      </Stack>
    </MFlex>
  );
};

const ItemLinkIcon = ({ url, as }: { url: string; as: IconType }) => {
  return (
    <Link href={url} target="_blank" onClick={(ev) => ev.stopPropagation()}>
      <MIcon as={as} boxSize={3} ml={1} mt={1} />
    </Link>
  );
};
