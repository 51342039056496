import { Icon, Spinner } from '@chakra-ui/react';
import { FC, forwardRef } from 'react';
import { MdArrowDropDown } from 'react-icons/md';
import { useAuth } from '~app/services/auth0';
import { MCustomSelectProps } from '~app/types/mCustomSelectTypes';
import { MAvatar, MCustomSelect, MFlex, MText } from '../Monetize';
import { MainInputProps } from '../Monetize/MCustomSelect/components/MainInput';
import { UserAvatarAndName } from '../Monetize/MCustomSelect/components/UserAvatarAndName';

const CHANGE_OWNER_WIDTH = '17.5625rem';

interface ChangeOwnerMainInputProps extends MainInputProps {
  ownerName: string;
}
export const MainChangeOwnerInput = ({
  getTitleFromValue,
  disabled,
  isReadOnly,
  loading,
  value,
  onToggleOpen,
  externalLoading,
  isOpen,
  ownerName,
}: ChangeOwnerMainInputProps) => {
  const getBgColor = () => {
    if (isOpen) {
      return 'tBlue.hover';
    }
    return 'tWhite.base';
  };
  const getHoverBgColor = () => {
    if (disabled || isReadOnly) {
      return 'transparent';
    }

    if (isOpen) {
      return 'tBlue.hover';
    }

    return 'tGray.support';
  };
  const getBorderColor = () => (isOpen ? 'tBlue.lightShade' : 'transparent');

  const userName = getTitleFromValue(value) || ownerName;
  const getCursor = () => {
    if (disabled) {
      return 'not-allowed';
    }

    if (isReadOnly) {
      return 'initial';
    }

    return 'pointer';
  };

  const onClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.persist();

    if (!(disabled || isReadOnly || disabled)) {
      onToggleOpen();
    }
  };

  return (
    <MFlex
      cursor={getCursor()}
      onMouseDown={onClick} // Since onBlur in quoteForm prevents onClick to fire first time, using onMouseDown instead of onClick
      bg={getBgColor()}
      borderColor={getBorderColor()}
      borderWidth={1}
      minWidth={CHANGE_OWNER_WIDTH}
      transition="background ease 0.3s"
      _hover={{
        bgColor: getHoverBgColor(),
      }}
      justify="space-between"
      align="center"
      px="2"
      py="1"
      borderRadius="3px"
    >
      <MFlex align="center">
        <MAvatar name={userName} mr="2" />
        <MText color="tGray.darkGrayPurple" fontWeight="normal">
          {userName}
        </MText>
      </MFlex>

      {isOpen && (loading || externalLoading) ? (
        <Spinner size="xs" />
      ) : (
        isOpen && <Icon as={MdArrowDropDown} fontSize="20" zIndex="1" />
      )}
    </MFlex>
  );
};

interface ChangeOwnerProps extends MCustomSelectProps {
  ownerName: string;
}

export const ChangeOwner: FC<ChangeOwnerProps> = ({
  ownerName,
  ...rest
}: ChangeOwnerProps) => {
  const { tenantId, currentTenantUserHasRole } = useAuth();
  return (
    <MCustomSelect
      ml="-2"
      itemTitle="name"
      itemValue="id"
      MainInputComponent={forwardRef<any, ChangeOwnerMainInputProps>(
        (mainInputProps: MainInputProps, ref: any) => (
          <MainChangeOwnerInput {...mainInputProps} ownerName={ownerName} />
        ),
      )}
      displayAvatar
      endpoint={`/api/tenants/${tenantId}/users`}
      popOverContentProps={{
        minWidth: CHANGE_OWNER_WIDTH,
      }}
      renderItemContent={({ title }) => (
        <UserAvatarAndName userName={title} textProps={{ fontSize: 'sm' }} />
      )}
      isReadOnly={!currentTenantUserHasRole}
      showQueryInput
      {...rest}
    />
  );
};
