import toString from 'lodash/toString';
import { FunctionComponent as FC, useEffect, useRef, useState } from 'react';
import {
  Control,
  Controller,
  UseFormSetError,
  UseFormSetValue,
  useFormState,
  useWatch,
} from 'react-hook-form';
import { MBox, MDivider, MFormField, MGrid } from '~app/components/Monetize';
import MEditableInput from '~app/components/Monetize/MEditableInput';
import { MProductDisplay } from '~app/components/Monetize/MProductDisplay';
import {
  AdditionalInfoCell,
  CostCell,
  QuantityCell,
} from '~app/components/Quotes';
import UnitPrice from '~app/components/Quotes/UnitPrice';
import {
  QUANTITY_EDITABLE_PRODUCT_TYPES,
  QUOTE_GRID_TEMPLATE_COLUMNS,
  QUOTE_GRID_TEMPLATE_COLUMNS_WITH_ADDITIONAL_INFO,
} from '~app/constants/quotes';
import {
  AmountUnitTypeEnum,
  IOfferingRes,
  IPriceResSchema,
  IQuoteItemReqSchema,
  IQuoteItemRespSchema,
  IQuoteOfferingReqSchema,
  IQuotePrice,
  IQuoteTemplateConfigSchema,
  IRateResSchema,
  ProductTypeEnum,
  QuoteTypeEnum,
} from '~app/types';
import { PriceModelEnum } from '~app/types/priceTypes';
import { getQuoteType } from '~app/utils';
import { getQuoteColumnAvailabilityReview } from '~app/utils/quotes';
import { useFlags } from '../../../../../services/launchDarkly';
import { useQuoteContext } from '../../quoteContext';
import { QuoteItemDiscountInputOrValue } from './QuoteItemDiscountOrValue';
import QuotePriceConfigPopover from './QuotePriceConfigPopover';

/**
 * Quote Edit Page
 */
interface QuoteItemProps {
  id: string;
  index: number;
  offeringId: string;
  parentQuoteOfferingId?: string;
  item: IQuoteItemReqSchema;
  itemFromServer: IQuoteItemRespSchema;
  priorScheduledQuoteItem?: IQuoteItemRespSchema;
  rateId?: string | null;
  orderable: IOfferingRes;
  offeringRate: IRateResSchema | null;
  control: Control<IQuoteOfferingReqSchema, object>;
  enableCustomDiscounts: boolean;
  watchRateId: string | null | undefined;
  isRemoved?: boolean;
  isReadOnly?: boolean;
  quotePrices: IQuotePrice[];
  setValue: UseFormSetValue<IQuoteOfferingReqSchema>;
  setError: UseFormSetError<IQuoteOfferingReqSchema>;
  handleQuoteOffering: () => void;
  handleQuoteOfferingWithoutDirtyCheck: () => void;
  displayConfig?: IQuoteTemplateConfigSchema | null;
  isQOAddedToAmendment?: boolean;
  isDisabled: boolean;
}

const QuoteItem: FC<QuoteItemProps> = ({
  index,
  item,
  itemFromServer,
  priorScheduledQuoteItem,
  orderable,
  offeringRate,
  control,
  enableCustomDiscounts,
  offeringId,
  parentQuoteOfferingId,
  watchRateId,
  isRemoved,
  isReadOnly: isReadOnlyProps = false,
  quotePrices,
  setValue,
  setError,
  handleQuoteOffering,
  handleQuoteOfferingWithoutDirtyCheck,
  displayConfig,
  isQOAddedToAmendment,
  isDisabled,
}: QuoteItemProps) => {
  const {
    teleportSalesDemoTempDependentPricingConfig,
    salesDemoMedinsightTempFormulaCustomPricing,
  } = useFlags();
  const [triggerVisibility, setTriggerVisibility] = useState<
    'hidden' | 'visible'
  >('hidden');
  const {
    isLoading,
    quoteData: { quote },
    quoteStateData: { offeringLoadingState },
    useAmendmentV2,
  } = useQuoteContext();

  const isOfferingLoading =
    offeringLoadingState[offeringId]?.isLoading || false;
  const isRateLoading =
    offeringLoadingState[offeringId]?.isLoading &&
    offeringLoadingState[offeringId]?.modifiedItems
      ?.get?.('header')
      ?.has('rateId');
  const isOfferingDeleting =
    offeringLoadingState[offeringId]?.isDeleting ||
    (parentQuoteOfferingId &&
      offeringLoadingState[parentQuoteOfferingId]?.isDeleting) ||
    false;
  const { isAmendment, isRenewal } = getQuoteType(quote);
  const { errors } = useFormState({ control });

  const customDiscountType = useWatch({
    control,
    name: `items.${index}.customDiscountType`,
  });

  const quantity = useWatch({
    control,
    name: `items.${index}.quantity`,
  });

  const currentProduct = orderable.products?.find(
    ({ id }) => id === item.productId,
  );

  const hasDerivedQuantityConfiguration =
    !!teleportSalesDemoTempDependentPricingConfig?.derivedQuantity?.[
      currentProduct?.id || ''
    ];

  const hasCustomFormulaPricing =
    salesDemoMedinsightTempFormulaCustomPricing?.formulaCustomQty
      ?.formulaProduct === currentProduct?.id;

  const isCustomPrice =
    offeringRate?.prices?.find(
      (price: IPriceResSchema) => price?.product?.id === item.productId,
    )?.priceModel === PriceModelEnum.CUSTOM;

  useEffect(() => {
    if (isCustomPrice && !quantity) {
      // set default quantity to 1 if the product has a custom price.
      // this is to prevent confusion when the price per unit is changed and the amount is 0 when the quantity is also zero.
      setValue(`items.${index}.quantity`, 1, {
        shouldDirty: true,
        shouldValidate: true,
      });
    }
  }, [isCustomPrice]);

  const discountAmountRef = useRef<any>(null);

  const onChangeDiscountType = (type: AmountUnitTypeEnum) => {
    setValue(`items.${index}.customDiscountAmountOrPercent`, 0, {
      shouldDirty: true,
      shouldValidate: true,
    });

    setValue(`items.${index}.customDiscountType`, type, {
      shouldDirty: true,
      shouldValidate: true,
    });

    handleQuoteOfferingWithoutDirtyCheck();
  };

  if (!quote) {
    return null;
  }

  if (!currentProduct) {
    return null;
  }

  const { isDiscountNotAvailable, isQuantityNotAvailable } =
    getQuoteColumnAvailabilityReview(currentProduct.productType);

  // Make it readonly is admendv2 and product is onetime
  const isReadOnly =
    isReadOnlyProps ||
    (useAmendmentV2 &&
      !isQOAddedToAmendment &&
      QUANTITY_EDITABLE_PRODUCT_TYPES.has(currentProduct?.productType));

  return (
    <>
      <MGrid
        templateColumns={
          quote.type === 'NEW' || useAmendmentV2
            ? QUOTE_GRID_TEMPLATE_COLUMNS
            : QUOTE_GRID_TEMPLATE_COLUMNS_WITH_ADDITIONAL_INFO
        }
        columnGap={6}
        py={2}
        pl="7"
        alignItems="center"
        onMouseEnter={() => {
          setTriggerVisibility('visible');
        }}
        onMouseLeave={() => {
          setTriggerVisibility('hidden');
        }}
      >
        <MBox
          data-testid="product-cell"
          pl={6}
          pr={12}
          alignSelf="center"
          overflow="hidden"
        >
          <MProductDisplay
            name={itemFromServer.productName}
            productType={currentProduct?.productType!}
            subscriptionTiming={offeringRate?.subscriptionTiming}
            disabled={isRemoved}
            color="tPurple.dark"
          />
          <MFormField error={errors.items?.[index]?.description}>
            <Controller
              control={control}
              name={`items.${index}.description`}
              render={({ field: { onChange, value, ...rest } }) => (
                <MEditableInput
                  isDisabled={
                    isOfferingDeleting ||
                    isRateLoading ||
                    isReadOnly ||
                    isRemoved
                  }
                  value={value || ''}
                  showCancel
                  showSave
                  onSubmit={(currValue) => {
                    currValue = (currValue || '').trim();
                    onChange(currValue);
                    handleQuoteOfferingWithoutDirtyCheck();
                  }}
                  onChange={onChange}
                  onCancel={onChange}
                  previewProps={{
                    fontSize: 'sm',
                    minH: '1rem',
                    px: 0,
                    py: 0,
                    isTruncated: true,
                    display: 'block',
                    _hover: !isReadOnly
                      ? {
                          background: 'tGray.support',
                          border: '1px solid',
                          borderColor: 'tGray.lightPurple',
                          borderRadius: '3px',
                          px: 2,
                        }
                      : {},
                  }}
                  inputProps={{
                    value: value || '',
                    py: 0,
                    height: '1.5rem',
                  }}
                  {...rest}
                />
              )}
            />
          </MFormField>
        </MBox>

        {/* Date placeholder */}
        <MBox data-testid="date-cell" />

        {!isQuantityNotAvailable ? (
          <MFormField
            data-testid="quantity-cell"
            error={errors?.items?.[index]?.quantity}
            alignSelf="center"
          >
            <Controller
              control={control}
              name={`items.${index}.quantity`}
              render={({ field: { onChange, onBlur, value, ...rest } }) => (
                <QuantityCell
                  index={index}
                  offeringRate={offeringRate}
                  isDisabled={
                    hasDerivedQuantityConfiguration ||
                    isOfferingDeleting ||
                    isRateLoading ||
                    isReadOnly ||
                    isDisabled
                  }
                  quote={quote}
                  item={itemFromServer}
                  placeholder="Quantity"
                  value={toString(value)}
                  isScheduledChange={!!priorScheduledQuoteItem}
                  scheduledChangePriorQty={priorScheduledQuoteItem?.quantity}
                  isReadOnly={
                    hasDerivedQuantityConfiguration ||
                    isReadOnly ||
                    (!!priorScheduledQuoteItem &&
                      currentProduct?.productType === ProductTypeEnum.ONETIME)
                  }
                  isAmendment={isAmendment}
                  isRenewal={isRenewal}
                  isRemoved={isRemoved}
                  onChange={(valueAsString: string, valueAsNumber: number) => {
                    onChange(valueAsString);
                  }}
                  handleBlur={(add: boolean) => {
                    onBlur();
                    // addition component does not trigger isDirty reliably
                    if (add) {
                      handleQuoteOfferingWithoutDirtyCheck();
                    } else {
                      handleQuoteOffering();
                    }
                  }}
                  handleQuoteOfferingWithoutDirtyCheck={
                    handleQuoteOfferingWithoutDirtyCheck
                  }
                  isLoading={isLoading}
                  isOfferingLoading={isOfferingLoading}
                  setError={setError}
                  setValue={setValue}
                  watchRateId={watchRateId}
                  {...rest}
                />
              )}
            />
          </MFormField>
        ) : (
          <MBox data-testid="quantity-cell" />
        )}

        {!isDiscountNotAvailable ? (
          <QuoteItemDiscountInputOrValue
            index={index}
            errors={errors}
            enableCustomDiscounts={enableCustomDiscounts}
            control={control}
            setValue={setValue}
            quote={quote}
            offeringId={offeringId}
            customDiscountType={customDiscountType}
            discountAmountRef={discountAmountRef}
            itemFromServer={itemFromServer}
            isDisabled={
              isRateLoading || isOfferingDeleting || isRemoved || isReadOnly
            }
            onChangeDiscountType={onChangeDiscountType}
            handleQuoteOffering={handleQuoteOfferingWithoutDirtyCheck}
            disableUnitTypeSelect={
              currentProduct?.productType === ProductTypeEnum.USAGE
            }
            disableUnitTypeMessage={
              currentProduct?.productType === ProductTypeEnum.USAGE
                ? 'Usage products can only have percentage discount.'
                : ''
            }
          />
        ) : (
          <MBox data-testid="discount-cell" />
        )}

        {quote && quote.type !== QuoteTypeEnum.NEW && !useAmendmentV2 && (
          <AdditionalInfoCell
            currency={quote.currency}
            item={itemFromServer}
            styles={{
              color: 'tPurple.dark',
            }}
          />
        )}
        <UnitPrice
          item={itemFromServer}
          quote={quote}
          errors={errors}
          control={control}
          index={index}
          isCustomPrice={!hasCustomFormulaPricing && isCustomPrice}
          setValue={setValue}
          handleQuoteOfferingWithoutDirtyCheck={
            handleQuoteOfferingWithoutDirtyCheck
          }
          isDisabled={isRemoved || isRateLoading}
          offeringRate={offeringRate}
          productType={currentProduct.productType}
          styles={{
            color: 'tPurple.dark',
          }}
          quotePrices={quotePrices}
          displayConfig={displayConfig}
        />
        <CostCell item={itemFromServer} currency={quote?.currency} />
        <QuotePriceConfigPopover
          index={index}
          item={itemFromServer}
          setValue={setValue}
          handleQuoteOfferingWithoutDirtyCheck={
            handleQuoteOfferingWithoutDirtyCheck
          }
          isRemoved={isRemoved}
          isLoading={isLoading || isOfferingLoading}
          billingFrequency={offeringRate?.billingFrequency}
          triggerVisibility={
            itemFromServer.options?.displayUnitPriceFrequency
              ? 'visible'
              : triggerVisibility
          }
          isReadOnly={isReadOnly}
        />
      </MGrid>
      <MDivider />
    </>
  );
};
export default QuoteItem;
