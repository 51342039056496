import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useGetTeamsById, useUpdateTeam } from '~app/api/settingsService';
import {
  MBox,
  MFlex,
  MHeading,
  MHStack,
  MPageContainer,
  MPageLoader,
} from '~app/components/Monetize';
import MEmptyDataPlaceholder from '~app/components/Monetize/MEmptyDataPlaceholder';
import { useHandleApiError } from '~app/hooks/useHandleApiError';
import { useConfirmModal } from '~app/services/confirmModal';
import { useDocumentHead } from '~app/services/documentHead';
import { ITeamResponseSchema } from '~app/types';
import { multiWordObjectFilter } from '~app/utils';
import { MemberSearchInput } from '../components/MemberSearchInput';
import { TeamDetailsHeader } from '../components/TeamDetailsHeader';
import { TeamMemberCard } from '../components/TeamMemberCard';

export const TeamDetails = () => {
  const { setDocTitle } = useDocumentHead();
  const [searchTerm, setSearchTerm] = useState('');
  const { id = '' } = useParams();

  const { handleApiError } = useHandleApiError();
  const { data: teamData, isLoading: isListLoading } = useGetTeamsById(id);
  const team = teamData as ITeamResponseSchema;
  const [visibleTeamMembers, setVisibleTeamMembers] = useState(
    team?.users || [],
  );

  const { mutate: doUpdateTeam, isLoading: updateLoading } = useUpdateTeam({
    teamId: id,
  });

  useEffect(() => {
    if (team) {
      setDocTitle('Settings', `Teams - ${team.name}`);
    }
  }, [team]);

  useEffect(() => {
    setVisibleTeamMembers(
      (team?.users || []).filter(
        multiWordObjectFilter(['name', 'email'], searchTerm),
      ),
    );
  }, [searchTerm, team?.users]);

  const {
    showConfirmModal: showDeleteModal,
    setModalLoading: setDeleteModalLoading,
    closeModal: closeDeleteModal,
  } = useConfirmModal();

  const handleRemoveMember = async (memberId: string, name: string) => {
    const onYes = async () => {
      if (!team) {
        return;
      }
      try {
        setDeleteModalLoading(true);
        const userIds = team?.users
          ?.filter(({ id: uId }) => uId !== memberId)
          .map(({ id: uId }) => uId);

        await doUpdateTeam({
          name: team.name,
          type: team.type,
          owner: team.owner,
          userIds: userIds,
        });
        setDeleteModalLoading(false);
        closeDeleteModal();
      } catch (err) {
        handleApiError(err);
      }
    };

    showDeleteModal({
      title: `Are you sure you want to remove ${name}?`,
      description:
        'Once removed, this user will no longer have access to this team.',
      onYes,
      yesButton: 'Remove',
      yesBtnProps: {
        variant: 'delete',
      },
      noBtnProps: {
        variant: 'cancel',
      },
    });
  };

  if (isListLoading) {
    return <MPageLoader />;
  }
  return (
    <MPageContainer alignItems="stretch" data-testid="team-details-page">
      <TeamDetailsHeader team={team || []} />

      <MBox pl={3} data-testid="team-members-details">
        <MHStack alignItems="center" justifyContent="space-between" mb={4}>
          <MHeading size="sm" fontWeight="semibold">
            Members
          </MHeading>
          <MFlex columnGap={3}>
            <MemberSearchInput
              value={searchTerm}
              onChangeValue={(value) => setSearchTerm(value)}
              onResetSearch={() => setSearchTerm('')}
            />
          </MFlex>
        </MHStack>
        {!!visibleTeamMembers.length && (
          <TeamMemberCard
            teamMembers={visibleTeamMembers}
            teamOwner={team.owner}
            handleRemoveMember={handleRemoveMember}
          />
        )}
        {!visibleTeamMembers.length && (
          <MEmptyDataPlaceholder
            mainMessage="Looks like there are no members here."
            btnLabel="Clear Filters"
            onClick={() => setSearchTerm('')}
          />
        )}
      </MBox>
    </MPageContainer>
  );
};
