import { MBox, MText } from '~app/components/Monetize';
import { useQuoteContext } from '../quoteContext';
import useFlags from 'launchdarkly-react-client-sdk/lib/useFlags';
import { QUOTE_TOTAL_EDIT_STYLE } from '~app/constants/quotes';
import { FooterRowAmendV2 } from '~app/components/Quotes/FooterRowAmendV2';
import { GridProps } from '@chakra-ui/react';
import { getVariableAmountIndicator } from '~app/utils/quotes';
import { getTotalValues } from './quoteOffering/quoteOfferingUtils';

export interface QuoteFormTotalRowAmendV2Props {
  anyVariableAmountProducts: boolean;
}

export const QuoteFormTotalRowAmendV2 = ({
  anyVariableAmountProducts,
}: QuoteFormTotalRowAmendV2Props) => {
  const {
    quoteData: { quote },
  } = useQuoteContext();

  const variableAmountIndicator = getVariableAmountIndicator(
    anyVariableAmountProducts,
  );
  const { totalExclVat } = useFlags();

  const totalCellStyles: GridProps = quote
    ? QUOTE_TOTAL_EDIT_STYLE[quote?.type]
    : {};

  const borderStyles: GridProps = totalCellStyles?.borderColor
    ? {
        borderWidth: '2px',
        borderRadius: 'md',
        borderColor: totalCellStyles.borderColor,
      }
    : { borderRadius: 'md' };
  if (!quote?.incrementalPrices) {
    return null;
  }
  const totalValues = getTotalValues(quote, variableAmountIndicator);
  if (!totalValues) {
    return null;
  }

  return (
    <MBox mt={2} minW="500px">
      <FooterRowAmendV2
        value="Updated Contract"
        priorValue="Prior Contract"
        amendmentValue="Amendment Changes"
        py="2"
      />
      <FooterRowAmendV2
        name="Subtotal"
        value={totalValues?.subtotal.value}
        priorValue={totalValues?.subtotal.priorValue}
        amendmentValue={totalValues?.subtotal.amendmentValue}
      />
      {(!!quote?.incrementalPrices?.absolute?.discountAmount ||
        !!quote?.incrementalPrices?.prior?.discountAmount ||
        !!quote?.incrementalPrices?.incremental?.discountAmount) && (
        <FooterRowAmendV2
          name="Discount"
          childrenProps={{ fontWeight: 'normal' }}
          value={totalValues?.discount.value}
          priorValue={totalValues?.discount.priorValue}
          amendmentValue={totalValues?.discount.amendmentValue}
        />
      )}
      <FooterRowAmendV2
        containerProps={{
          'data-testid': 'quoteTotalDue',
          backgroundColor: totalCellStyles.backgroundColor,
          borderColor: 'tBlue.tBlue',
          ...borderStyles,
        }}
        name={`Total${totalExclVat ? ' excl. VAT' : ''}`}
        nameProps={{
          fontSize: 'lg',
          lineHeight: 'none',
        }}
        childrenProps={{
          fontSize: 'lg',
          lineHeight: 'none',
        }}
        value={totalValues?.total.value}
        priorValue={totalValues?.total.priorValue}
        amendmentValue={totalValues?.total.amendmentValue}
        dividerProps={{ display: 'none' }}
      />

      {anyVariableAmountProducts && (
        <MText fontWeight="400" pt="2" textAlign="right">
          *Amount can increase due to usage products.
        </MText>
      )}
    </MBox>
  );
};
