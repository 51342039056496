import {
  BillGroupInvoiceFrequencyEnum,
  BillGroupStatusEnum,
  BillingScheduleRespStatusEnum,
} from '~app/types';
import { objectToSelectOptions } from '~app/utils/misc';

export const BILL_GROUP_STATUS_DISPLAY: {
  [key in BillGroupStatusEnum]: string;
} = {
  ACTIVE: 'Active',
  CANCELED: 'Canceled',
  INACTIVE: 'Inactive',
};
export const BILL_GROUP_STATUS_OPTIONS = objectToSelectOptions(
  BILL_GROUP_STATUS_DISPLAY,
);

export const BILL_GROUP_INVOICE_FREQUENCY_DISPLAY: {
  [key in BillGroupInvoiceFrequencyEnum]: string;
} = {
  DAILY: 'Daily',
  MONTHLY: 'Monthly',
  QUARTERLY: 'Quarterly',
  ANNUALLY: 'Annually',
  SEMIANNUALLY: 'Semi-annually',
  CUSTOM: 'Custom',
};

export const BILL_GROUP_INVOICE_FREQUENCY_OPTIONS = objectToSelectOptions(
  BILL_GROUP_INVOICE_FREQUENCY_DISPLAY,
);

export const BillingScheduleStatusEnumDiplay: {
  [key in BillingScheduleRespStatusEnum]: string;
} = {
  [BillingScheduleRespStatusEnum.INVOICED]: 'Invoiced',
  [BillingScheduleRespStatusEnum.NOT_INVOICED]: 'Not Invoiced',
};
