export const CustomRateIcon = (props: any) => (
  <svg
    width="19"
    height="20"
    viewBox="0 0 19 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18 1C18 0.448 17.552 0 17 0H1C0.448 0 0 0.448 0 1V19C0 19.552 0.448 20 1 20H13.5V18H2V2H16V9H18V1Z"
      fill="#3D3C65"
    />
    <path d="M12 15V13H6V15H12Z" fill="#3D3C65" />
    <path d="M13 7V5H6V7H13Z" fill="#3D3C65" />
    <path d="M13 11V9H6V11H13Z" fill="#3D3C65" />
    <path
      d="M15.9753 18.8426H17.0093V18.1019C17.6163 18.0401 18.0998 17.8524 18.4599 17.5386C18.82 17.2248 19 16.7798 19 16.2037C19 15.6687 18.8457 15.2238 18.537 14.8688C18.2284 14.5139 17.7088 14.1872 16.9784 13.8889C16.392 13.642 15.9727 13.4208 15.7207 13.2253C15.4686 13.0298 15.3426 12.7778 15.3426 12.4691C15.3426 12.2016 15.4455 11.9882 15.6512 11.8287C15.857 11.6692 16.1348 11.5895 16.4846 11.5895C16.7829 11.5895 17.0478 11.6615 17.2793 11.8056C17.5108 11.9496 17.7037 12.1759 17.858 12.4846L18.7685 12.0679C18.573 11.6358 18.3364 11.3169 18.0586 11.1111C17.7809 10.9054 17.4414 10.7716 17.0401 10.7099V10H16.0062V10.7099C15.4506 10.8025 15.0288 11.0134 14.7407 11.3426C14.4527 11.6718 14.3086 12.0679 14.3086 12.5309C14.3086 13.0658 14.4784 13.4954 14.8179 13.8194C15.1574 14.1435 15.6821 14.4444 16.392 14.7222C16.9784 14.9691 17.3873 15.2006 17.6188 15.4167C17.8503 15.6327 17.966 15.8951 17.966 16.2037C17.966 16.5123 17.8477 16.7567 17.6111 16.9367C17.3745 17.1168 17.0453 17.2068 16.6235 17.2068C16.2222 17.2068 15.8724 17.0936 15.5741 16.8673C15.2757 16.6409 15.07 16.322 14.9568 15.9105L14 16.2346C14.2058 16.7593 14.481 17.1708 14.8256 17.4691C15.1703 17.7675 15.5535 17.9681 15.9753 18.071V18.8426Z"
      fill="#3D3C65"
    />
    <path
      d="M5 6C5 6.55228 4.55228 7 4 7C3.44772 7 3 6.55228 3 6C3 5.44772 3.44772 5 4 5C4.55228 5 5 5.44772 5 6Z"
      fill="#3D3C65"
    />
    <path
      d="M5 10C5 10.5523 4.55228 11 4 11C3.44772 11 3 10.5523 3 10C3 9.44771 3.44772 9 4 9C4.55228 9 5 9.44771 5 10Z"
      fill="#3D3C65"
    />
    <path
      d="M5 14C5 14.5523 4.55228 15 4 15C3.44772 15 3 14.5523 3 14C3 13.4477 3.44772 13 4 13C4.55228 13 5 13.4477 5 14Z"
      fill="#3D3C65"
    />
  </svg>
);
