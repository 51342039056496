import { InputProps } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { MdClose, MdSearch } from 'react-icons/md';
import { useDebounce } from '~app/hooks/useDebounce';
import { useNonInitialEffect } from '~app/hooks/useNonInitialEffect';
import { pluralize } from '~app/utils';
import MText from './MText';
import {
  MCircularProgress,
  MIcon,
  MInput,
  MInputGroup,
  MInputLeftElement,
  MInputRightElement,
} from './chakra';

interface MSearchInputProps extends Omit<InputProps, 'onChange'> {
  value: string;
  count?: number;
  omitCount?: boolean;
  loading?: boolean;
  onChange: (val: string) => void;
}

export const MSearchInput = React.forwardRef<any, MSearchInputProps>(
  (
    {
      children,
      value,
      omitCount,
      count,
      loading,
      onChange,
      ...rest
    }: MSearchInputProps,
    ref,
  ) => {
    const [internalValue, setInternalValue] = useState(value);
    const debouncedFilters = useDebounce(internalValue);

    // don't notify parent on first render
    useNonInitialEffect(() => {
      onChange(debouncedFilters);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedFilters]);

    useEffect(() => {
      setInternalValue(value);
    }, [value]);

    const handleOnKeyDown = (ev: React.KeyboardEvent<HTMLInputElement>) => {
      if (ev.key === 'Enter') {
        onChange(internalValue);
      }
    };
    const handleOnChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
      setInternalValue(ev.target.value);
    };

    const onSetEmpty = () => {
      onChange('');
    };

    // Width of right element to ensure input text does not overlap
    let rightW = 0;

    if (!omitCount && internalValue && !loading) {
      rightW += 22;
    } else if (internalValue) {
      rightW += 8;
    }

    if (loading) {
      rightW += 6;
    }

    rightW = rightW * 4;

    return (
      <MInputGroup>
        <MInputLeftElement pointerEvents="none">
          <MIcon w="4" h="4" as={MdSearch} color="tPurple.base" />
        </MInputLeftElement>
        <MInput
          ref={ref}
          value={internalValue}
          onKeyDown={handleOnKeyDown}
          onChange={handleOnChange}
          _placeholder={{ color: 'tPurple.base', fontWeight: '400' }}
          pr={`${rightW}px`}
          {...rest}
        />

        <MInputRightElement width={`${rightW}px`}>
          {!omitCount && !!internalValue && !loading && (
            <MText fontSize="xs" whiteSpace="nowrap">
              {count ? `${count} ${pluralize('Result', count)}` : 'No Results'}
            </MText>
          )}

          {loading && <MCircularProgress mx={1} isIndeterminate size={4} />}
          {!!internalValue && (
            <MIcon
              as={MdClose}
              margin="2"
              cursor="pointer"
              onClick={onSetEmpty}
              _hover={{ color: 'tPurple.base' }}
            />
          )}
        </MInputRightElement>
      </MInputGroup>
    );
  },
);

export default MSearchInput;
