import { useMultiStyleConfig, useTab } from '@chakra-ui/react';
import { chakra } from '@chakra-ui/system';
import React, { FunctionComponent as FC } from 'react';
import { Link, LinkProps, useMatch, useResolvedPath } from 'react-router-dom';
import { useAuth } from '~app/services/auth0';
import './styles.scss';

type MCustomLinkTabProps = LinkProps;
const CLink = chakra(Link);
interface RoleProps {
  roles?: string[];
}

const MCustomLinkTab: FC<MCustomLinkTabProps & RoleProps> = React.forwardRef<
  any,
  MCustomLinkTabProps
>(({ roles, children, to, ...props }: MCustomLinkTabProps & RoleProps, ref) => {
  const tabProps = useTab({ ...props, ref });
  const styles = useMultiStyleConfig('Tabs', tabProps);

  return (
    <CLink
      __css={styles.tab}
      {...tabProps}
      type={undefined}
      to={to}
      data-content={children}
      className="boldContentHidden"
    >
      {children}
    </CLink>
  );
});

MCustomLinkTab.defaultProps = {
  roles: [],
};

export default MCustomLinkTab;
