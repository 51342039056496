import { TextProps, TooltipProps } from '@chakra-ui/react';
import isDate from 'lodash/isDate';
import isString from 'lodash/isString';
import { useGetTimezones } from '../../api/timezoneService';
import { Maybe } from '../../types';
import {
  TIMEZONE_CONFIG,
  TimezoneConfigKey,
  toDateShort,
  toDateTimeShort,
} from '../../utils/dates';
import MText from './MText';
import { MTooltip } from './chakra';

interface DateDisplayProps {
  /**
   * Display mode. If 'date', only show the date. If 'automatic', show the date and time if the value is a datetime.
   * default: automatic
   */
  mode?: 'automatic' | 'date';
  /**
   * The value to display.
   */
  date: Maybe<string | Date>;
  /**
   * Whether to omit the tooltip showing the timezone when the display is a datetime.
   * If a date is shown, a tooltip is never displayed.
   * default: false
   */
  omitTooltip?: Maybe<boolean>;
  /**
   * Override the tooltip label.
   * If not specified and the value is rendered as a datetime,
   * the default label will be 'Timezone: {timezone}'.
   */
  tooltipLabelOverride?: Maybe<string>;
  /**
   * Additional props for the MTooltip component.
   */
  tooltipProps?: Maybe<TooltipProps>;
  /**
   * Additional props for the MText component.
   */
  textProps?: Maybe<TextProps>;
  timezoneConfigKey?: TimezoneConfigKey;
}

/**
 * Display a date or datetime value with a tooltip showing the timezone.
 */
export const DateDisplay = ({
  mode = 'automatic',
  omitTooltip = false,
  date,
  tooltipLabelOverride,
  tooltipProps,
  textProps,
  timezoneConfigKey,
}: DateDisplayProps) => {
  const { data: timeZones } = useGetTimezones();

  if (!date || (!isString(date) && !isDate(date))) {
    return null;
  }
  const display =
    mode === 'date' || (isString(date) && date.length <= 10)
      ? ('date' as const)
      : ('datetime' as const);

  const value =
    display === 'date'
      ? toDateShort(date)
      : toDateTimeShort(date, timezoneConfigKey);

  // Short code such as "PT" for Pacific Time instead of America/Los_Angeles
  const timeZoneLabel =
    timeZones?.timezonesByValue[TIMEZONE_CONFIG.tenantTimezone]?.label;

  let tooltipLabel =
    display === 'date' || omitTooltip || !timeZoneLabel
      ? undefined
      : `Time zone: ${timeZoneLabel}`;
  if (tooltipLabelOverride) {
    tooltipLabel = tooltipLabelOverride;
  }

  return (
    <MTooltip openDelay={500} label={tooltipLabel} {...tooltipProps}>
      <MText noOfLines={1} {...textProps}>
        {value}
      </MText>
    </MTooltip>
  );
};
