/* eslint-disable @typescript-eslint/no-shadow */
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { handleApiErrorToast } from '~app/api/axios';

import { useNonInitialEffect } from '~app/hooks/useNonInitialEffect';
import {
  AddressSourceEnum,
  ContactAddressDataSchema,
  ContactWithoutEsignTypes,
  IContactAddressDataSchema,
  IContactRespSchema,
} from '~app/types';
import { filterInactiveContacts } from '~app/utils';

export interface UseContactsProps {
  initialContactsWithAddressInformation: {
    billingContactId?: string;
    shippingContactId?: string;
    addressInformation: IContactAddressDataSchema;
  };
  accountContacts: IContactRespSchema[];
  accountContactsById: Record<string, IContactRespSchema>;
  onSaveContacts: (payload: { primaryId?: string; billingId?: string }) => void;
  onSaveAddressData: (payload: IContactAddressDataSchema) => void;
}

/**
 * Logic to support Quote Contacts
 */
export function useContacts({
  initialContactsWithAddressInformation,
  accountContacts,
  accountContactsById,
  onSaveAddressData,
  onSaveContacts,
}: UseContactsProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [didSave, setDidSave] = useState(false);

  const [externalContacts, setExternalContacts] = useState<
    ContactWithoutEsignTypes[]
  >(() => {
    const { billingContactId, shippingContactId } =
      initialContactsWithAddressInformation;
    let selectedContacts: ContactWithoutEsignTypes[] = [];

    if (
      shippingContactId ||
      (billingContactId &&
        shippingContactId &&
        billingContactId === shippingContactId)
    ) {
      const shippingContact = accountContactsById[shippingContactId];
      selectedContacts = [
        {
          contact: shippingContact,
          billing: true,
          primary: true,
        },
      ];
    }

    if (
      billingContactId &&
      shippingContactId &&
      billingContactId !== shippingContactId
    ) {
      const billingContact = accountContactsById[billingContactId];
      const shippingContact = accountContactsById[shippingContactId];
      selectedContacts = [
        {
          contact: billingContact,
          billing: true,
          primary: false,
        },
        {
          contact: shippingContact,
          billing: false,
          primary: true,
        },
      ];
    }

    return selectedContacts;
  });
  const [internalContacts, setInternalContacts] = useState<
    ContactWithoutEsignTypes[]
  >([]);

  const addressDataForm = useForm<IContactAddressDataSchema>({
    mode: 'onChange',
    resolver: zodResolver(ContactAddressDataSchema),
    defaultValues: {
      addressSource: AddressSourceEnum.CONTACT,
    },
  });

  const addressSource = addressDataForm.watch('addressSource');

  /** Used to calculate which contacts cannot show up in selection list */
  const [selectedAccountContacts, setSelectedAccountContacts] = useState<
    string[]
  >([]);

  /** Used to render quote external contacts */
  const [externalAccountContacts, setExternalAccountContacts] = useState<
    IContactRespSchema[]
  >([]);
  /** Used to render quote internal contacts */
  const [internalAccountContacts, setInternalAccountContacts] = useState<
    IContactRespSchema[]
  >([]);

  const [
    additionalRecipientSelectedContacts,
    setAdditionalRecipientSelectedContacts,
  ] = useState<IContactRespSchema[]>([]);

  useEffect(() => {
    const activeExternal = filterInactiveContacts(accountContacts || []);
    setExternalAccountContacts(activeExternal);
  }, [accountContacts]);

  /**
   * Potentially save quote contacts whenever internal or external contacts are updated
   * If no changes were made, update is skipped
   *
   * useNonInitialEffect because we don't want to save on initial render
   */
  useNonInitialEffect(() => {
    handleSave();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [internalContacts, externalContacts, additionalRecipientSelectedContacts]);

  useEffect(() => {
    const { addressInformation } = initialContactsWithAddressInformation;

    if (addressInformation) {
      addressDataForm.reset(addressInformation);
    }
  }, [initialContactsWithAddressInformation, addressDataForm]);

  /**
   * Toggle the type of a contact (billing, primary, esign)
   */
  const handleToggle = (
    contact: ContactWithoutEsignTypes,
    type: 'primary' | 'billing',
    value: boolean,
    isInternal: boolean,
  ) => {
    const [contacts, setContacts] = isInternal
      ? [internalContacts, setInternalContacts]
      : [externalContacts, setExternalContacts];
    const updatedContacts = contacts.map((currContact) => ({ ...currContact }));

    // Only one contact can be selected for a given type
    // esign may allow multiple types in the future
    updatedContacts.forEach((currContact) => (currContact[type] = false));

    setContacts(
      updatedContacts.map((currContact) =>
        currContact.contact.id === contact.contact.id
          ? { ...currContact, [type]: value }
          : currContact,
      ),
    );
  };

  /**
   * Remove a contact from the quote
   */
  const handleRemove = (
    contact: ContactWithoutEsignTypes,
    isInternal: boolean,
  ) => {
    const [contacts, setContacts] = isInternal
      ? [internalContacts, setInternalContacts]
      : [externalContacts, setExternalContacts];
    // future improvement could be to update remaining contacts to try to make them as valid as possible

    const newContacts = contacts.filter(
      (currContact) => currContact !== contact,
    );

    // Auto-select primary or billing if a contact was removed
    if (!isInternal) {
      const primaryContact = newContacts.find(({ primary }) => primary);
      const billingContact = newContacts.find(({ billing }) => billing);

      if (primaryContact && !billingContact) {
        primaryContact.billing = true;
      } else if (billingContact && !primaryContact) {
        billingContact.primary = true;
      }
    }

    setContacts(newContacts);
    setSelectedAccountContacts(
      selectedAccountContacts.filter((item) => item !== contact.contact.id),
    );
  };

  /**
   * Remove a additionalContact from the quote
   */
  const handleAdditionalContactRemove = (contactId: string) => {
    const [contacts, setContacts] = [
      additionalRecipientSelectedContacts,
      setAdditionalRecipientSelectedContacts,
    ];
    const newContacts = contacts.filter(
      (currContact) => currContact.id !== contactId,
    );
    setContacts(newContacts);
  };

  /**
   * Save contacts - called automatically when things change
   */
  const handleSave = async () => {
    try {
      setIsLoading(true);
      const request = {
        primaryId: externalContacts.find((contact) => contact.primary)?.contact
          .id,
        billingId: externalContacts.find((contact) => contact.billing)?.contact
          .id,
      };
      onSaveContacts(request);
    } catch (ex) {
      handleApiErrorToast(ex);
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Called when a contact is edited (e.x. name, email, address etc..)
   * This contact has already been saved
   */
  const handleUpdatedContact = async (
    contact: IContactRespSchema,
    isInternal: boolean,
  ) => {
    const [contacts, setContacts] = isInternal
      ? [internalContacts, setInternalContacts]
      : [externalContacts, setExternalContacts];

    setContacts([
      ...contacts.map((currContact) =>
        currContact.contact.id === contact.id
          ? {
              ...currContact,
              contact,
            }
          : currContact,
      ),
    ]);

    if (!didSave) {
      setDidSave(true);
    }
  };

  /**
   * Called when a new contact is added to the quote
   */
  const handleNewContact = async (
    contact: IContactRespSchema,
    isInternal: boolean,
  ) => {
    const [contacts, setContacts] = isInternal
      ? [internalContacts, setInternalContacts]
      : [externalContacts, setExternalContacts];

    setContacts([
      ...contacts,
      {
        contact: contact,
        primary: !isInternal && !contacts.some(({ primary }) => primary),
        billing: !isInternal && !contacts.some(({ billing }) => billing),
      },
    ]);

    setSelectedAccountContacts([...selectedAccountContacts, contact.id]);

    if (!didSave) {
      setDidSave(true);
    }
  };

  /**
   * Called when a new contact is added to the quote
   */
  const handleContactSelectionChange = (
    contactId: string,
    isInternal: boolean,
  ) => {
    const contact = accountContactsById[contactId];
    const [contacts, setContacts] = isInternal
      ? [internalContacts, setInternalContacts]
      : [externalContacts, setExternalContacts];

    setContacts([
      ...contacts,
      {
        contact: contact,
        primary: !isInternal && !contacts.some(({ primary }) => primary),
        billing: !isInternal && !contacts.some(({ billing }) => billing),
      },
    ]);

    setSelectedAccountContacts([
      ...selectedAccountContacts.filter((id) => id !== contactId),
      contactId,
    ]);
  };

  /**
   * Called when a additional contact is selected for adding to the quote
   */
  const handleSelectedAdditionalRecipients = (contactId: string) => {
    const contact = accountContactsById[contactId];
    setAdditionalRecipientSelectedContacts([
      ...additionalRecipientSelectedContacts,
      contact,
    ]);
  };

  /**
   * Called when a brand new Additional Contact is Created and show as selected
   */
  const handleCreatedNewAdditionalContact = (contact: IContactRespSchema) => {
    setAdditionalRecipientSelectedContacts([
      ...additionalRecipientSelectedContacts,
      contact,
    ]);
  };

  const addressData = addressDataForm.getValues();
  const onSaveAddress = async () => {
    setIsLoading(true);
    try {
      onSaveAddressData(addressData);
      await setDidSave(true);
    } catch (err) {
      handleApiErrorToast(err);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    (async () => {
      if (addressDataForm.formState.isDirty) {
        await onSaveAddress();
        addressDataForm.reset({}, { keepValues: true });
      }
    })();
  }, [addressDataForm.formState.isDirty]);

  return {
    isLoading,
    externalContacts,
    internalContacts,
    externalAccountContacts,
    internalAccountContacts,
    additionalRecipientSelectedContacts,
    didSave,
    handleToggle,
    handleRemove,
    handleSave,
    handleUpdatedContact,
    handleNewContact,
    handleContactSelectionChange,
    handleSelectedAdditionalRecipients,
    handleCreatedNewAdditionalContact,
    handleAdditionalContactRemove,
    addressSource,
    addressDataForm,
    onSaveAddress,
  };
}
