import { z } from 'zod';
import { BaseResponseSchema } from './miscTypes';
import { IManualPayment, PaymentMethodTypeEnumZ } from './paymentMethodTypes';

export enum PaymentStatusEnum {
  PROCESSING = 'PROCESSING',
  SUCCESS = 'SUCCESS',
  PENDING = 'PENDING',
  FAILED = 'FAILED',
  CANCELED = 'CANCELED',
  REFUNDED = 'REFUNDED',
  PARTIAL_REFUND = 'PARTIAL_REFUND',
  SUCCEEDED = 'SUCCEEDED',
}
export const PaymentStatusEnumZ = z.nativeEnum(PaymentStatusEnum);

export const PAYMENT_STATUS_DISPLAY: {
  [key in PaymentStatusEnum]: string;
} = {
  PROCESSING: 'Processing',
  SUCCESS: 'Success',
  PENDING: 'Pending',
  FAILED: 'Failed',
  CANCELED: 'Canceled',
  REFUNDED: 'Refunded',
  PARTIAL_REFUND: 'Partial refund',
  SUCCEEDED: 'Succeeded',
};

export const PaymentSchema = BaseResponseSchema.extend({
  paymentDate: z.string(),
  collectionDate: z.string(),
  refundDate: z.string().nullish(),
  description: z.string(),
  amount: z.number(),
  appliedAmount: z.number().nullish(),
  fullyApplied: z.number().nullish(),
  gatewayCode: z.string().nullish(),
  gatewayId: z.string().nullish(),
  gatewayMessage: z.string().nullish(),
  gatewayReference: z.string().nullish(),
  paymentStatus: PaymentStatusEnumZ,
  paymentType: PaymentMethodTypeEnumZ,
  referenceNo: z.string().nullish(),
  unAppliedAmount: z.number().nullish(),
  voidDate: z.string().nullish(),
  currency: z.string(),
  settledDate: z.string(),
  paymentMethodId: z.string().nullish(),
});

export type IPayment = z.infer<typeof PaymentSchema>;

export type CreateInvoicePaymentRequest =
  | CreateInvoicePaymentRequestElectronic
  | CreateInvoicePaymentRequestManual
  | CreateInvoicePaymentRequestAchCredit
  | CreateInvoicePaymentRequestBankTransfer;

export interface CreateInvoicePaymentRequestElectronic {
  accountId: string;
  invoiceId: string;
  type: 'electronic';
  payload: { paymentMethodId: string; amount: number };
}
export interface CreateInvoicePaymentRequestAchCredit {
  accountId: string;
  invoiceId: string;
  type: 'achCredit';
  payload: { paymentMethodId: string; amount: number };
}
export interface CreateInvoicePaymentRequestBankTransfer {
  accountId: string;
  invoiceId: string;
  type: 'usBankTransfer';
  payload: { paymentMethodId: string; amount: number };
}
export interface CreateInvoicePaymentRequestManual {
  accountId: string;
  invoiceId: string;
  type: 'manual';
  payload: IManualPayment;
}
