/* eslint-disable prefer-destructuring */
import isEmpty from 'lodash/isEmpty';
import isString from 'lodash/isString';
import { nativeEnum, z } from 'zod';
import { CUSTOM_FIELDS_PREFIX } from '../constants/customFields';
import { getRequiredMessage } from '../utils/messages';
import {
  CustomFieldEntityEnumZ,
  CustomFieldTypeEnumZ,
} from './customFieldsTypes';
import { BaseResponseSchema } from './miscTypes';
import { IRateResBaseSchema } from './rateTypes';

// Should be constants, but wanted to avoid circular dependency
export const QUESTION_PREFIX = 'ques_' as const;
export const QUESTION_TEMP_PREFIX = 'TEMP_' as const;

// Questions
export type QuestionIdPlaceholder = `TEMP_${string}`;

export enum QuestionTypesEnum {
  BILLING_FREQUENCY = 'BILLING_FREQUENCY',
  BOOLEAN = 'BOOLEAN',
  CURRENCY = 'CURRENCY',
  CUSTOM_FIELD = 'CUSTOM_FIELD',
  DATE = 'DATE',
  LEGAL_ENTITY = 'LEGAL_ENTITY',
  NUMBER = 'NUMBER',
  OFFERING = 'OFFERING',
  PRODUCT = 'PRODUCT',
  RATE = 'RATE',
  TEXT = 'TEXT',
}

export const QuestionTypesEnumZ = nativeEnum(QuestionTypesEnum);

export enum QuestionFilterByEnum {
  NONE = 'NONE',
  CONSTANT = 'CONSTANT',
  QUESTION = 'QUESTION',
  VARIABLE = 'VARIABLE',
}

export const QuestionFilterByEnumZ = nativeEnum(QuestionFilterByEnum);

export enum QuestionCompareFromEnum {
  CREATE_DATE = 'CREATE_DATE',
  MODIFY_DATE = 'MODIFY_DATE',
  START_DATE = 'START_DATE',
  END_DATE = 'END_DATE',
  OFFERING_ID = 'OFFERING_ID',
  OFFERING_CUSTOM_FIELD_CHECKBOX = 'OFFERING_CUSTOM_FIELD_CHECKBOX',
  OFFERING_CUSTOM_FIELD_DATE = 'OFFERING_CUSTOM_FIELD_DATE',
  OFFERING_CUSTOM_FIELD_DROPDOWN = 'OFFERING_CUSTOM_FIELD_DROPDOWN',
  OFFERING_CUSTOM_FIELD_NUMBER = 'OFFERING_CUSTOM_FIELD_NUMBER',
  OFFERING_CUSTOM_FIELD_TEXT = 'OFFERING_CUSTOM_FIELD_TEXT',
  PRODUCT_ID = 'PRODUCT_ID',
  PRODUCT_CUSTOM_FIELD_CHECKBOX = 'PRODUCT_CUSTOM_FIELD_CHECKBOX',
  PRODUCT_CUSTOM_FIELD_DATE = 'PRODUCT_CUSTOM_FIELD_DATE',
  PRODUCT_CUSTOM_FIELD_DROPDOWN = 'PRODUCT_CUSTOM_FIELD_DROPDOWN',
  PRODUCT_CUSTOM_FIELD_NUMBER = 'PRODUCT_CUSTOM_FIELD_NUMBER',
  PRODUCT_CUSTOM_FIELD_TEXT = 'PRODUCT_CUSTOM_FIELD_TEXT',
  RATE_ID = 'RATE_ID',
  BILLING_FREQUENCY = 'BILLING_FREQUENCY',
}

export const QuestionCompareFromEnumZ = nativeEnum(QuestionCompareFromEnum);

export enum QuestionComparatorEnum {
  'EQUAL' = 'EQUAL',
  'GREATER_THAN' = 'GREATER_THAN',
  'GREATER_THAN_OR_EQUAL' = 'GREATER_THAN_OR_EQUAL',
  'LESS_THAN' = 'LESS_THAN',
  'LESS_THAN_OR_EQUAL' = 'LESS_THAN_OR_EQUAL',
}

export const QuestionComparatorEnumZ = nativeEnum(QuestionComparatorEnum);

const QuestionCompareFromFields = ['PRODUCT', 'OFFERING', 'RATE'];
// Should be in constants, but avoiding circular dependency
export const QuestionCompareFromCustomFields = new Set<QuestionCompareFromEnum>(
  [
    QuestionCompareFromEnum.PRODUCT_CUSTOM_FIELD_CHECKBOX,
    QuestionCompareFromEnum.OFFERING_CUSTOM_FIELD_CHECKBOX,
    QuestionCompareFromEnum.PRODUCT_CUSTOM_FIELD_DATE,
    QuestionCompareFromEnum.OFFERING_CUSTOM_FIELD_DATE,
    QuestionCompareFromEnum.PRODUCT_CUSTOM_FIELD_DROPDOWN,
    QuestionCompareFromEnum.OFFERING_CUSTOM_FIELD_DROPDOWN,
    QuestionCompareFromEnum.PRODUCT_CUSTOM_FIELD_NUMBER,
    QuestionCompareFromEnum.OFFERING_CUSTOM_FIELD_NUMBER,
    QuestionCompareFromEnum.PRODUCT_CUSTOM_FIELD_TEXT,
    QuestionCompareFromEnum.OFFERING_CUSTOM_FIELD_TEXT,
  ],
);

export const QuestionCompareFromCustomFieldsCheckbox =
  new Set<QuestionCompareFromEnum>([
    QuestionCompareFromEnum.PRODUCT_CUSTOM_FIELD_CHECKBOX,
    QuestionCompareFromEnum.OFFERING_CUSTOM_FIELD_CHECKBOX,
  ]);

export const QuestionCompareFromCustomFieldsDate =
  new Set<QuestionCompareFromEnum>([
    QuestionCompareFromEnum.PRODUCT_CUSTOM_FIELD_DATE,
    QuestionCompareFromEnum.OFFERING_CUSTOM_FIELD_DATE,
  ]);

export const QuestionCompareFromCustomFieldsDropdown =
  new Set<QuestionCompareFromEnum>([
    QuestionCompareFromEnum.PRODUCT_CUSTOM_FIELD_DROPDOWN,
    QuestionCompareFromEnum.OFFERING_CUSTOM_FIELD_DROPDOWN,
  ]);

export const QuestionCompareFromCustomFieldsNumber =
  new Set<QuestionCompareFromEnum>([
    QuestionCompareFromEnum.PRODUCT_CUSTOM_FIELD_NUMBER,
    QuestionCompareFromEnum.OFFERING_CUSTOM_FIELD_NUMBER,
  ]);

export const QuestionCompareFromCustomFieldsText =
  new Set<QuestionCompareFromEnum>([
    QuestionCompareFromEnum.PRODUCT_CUSTOM_FIELD_TEXT,
    QuestionCompareFromEnum.OFFERING_CUSTOM_FIELD_TEXT,
  ]);

export const QuestionReqSchema = z
  .object({
    id: z.string(),
    guidedQuotingId: z.string().nullish(),
    questionText: z.string().min(1, getRequiredMessage('Question Text')),
    questionNumber: z.number(),
    type: QuestionTypesEnumZ,
    filterBy: QuestionFilterByEnumZ,
    compareFrom: QuestionCompareFromEnumZ.nullish(),
    customFieldEntity: CustomFieldEntityEnumZ.nullish(),
    customFieldType: CustomFieldTypeEnumZ.nullish(),
    customField: z.string().nullish(),
    comparator: QuestionComparatorEnumZ.nullish(),
    compareTo: z.union([z.string(), z.string().array()]).nullish(),
  })
  // TODO: ensure that references are valid, non-circular, and in correct order
  .superRefine(
    (
      { type, filterBy, compareFrom, customField, comparator, compareTo },
      ctx,
    ) => {
      // TODO: add validation for type + filter by combination
      switch (filterBy) {
        case QuestionFilterByEnum.NONE: {
          if (type === QuestionTypesEnum.CUSTOM_FIELD) {
            break;
          }
          if (compareFrom || customField || comparator || !isEmpty(compareTo)) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: `Filter By is None, so other fields must not be set`,
              path: ['filterBy'],
            });
          }
          break;
        }
        case QuestionFilterByEnum.CONSTANT: {
          if (QuestionCompareFromFields.includes(type)) {
            if (!compareFrom) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: `Compare From Field is required`,
                path: ['compareFrom'],
              });
            }
          }
          if (!comparator) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: `Operator is required`,
              path: ['comparator'],
            });
          }
          if (!compareTo) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: `Filter To is required`,
              path: ['compareTo'],
            });
          }
          break;
        }
        case QuestionFilterByEnum.QUESTION:
        case QuestionFilterByEnum.VARIABLE: {
          if (!compareFrom) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: `Compare From Field is required`,
              path: ['compareFrom'],
            });
          } else {
            if (QuestionCompareFromCustomFields.has(compareFrom)) {
              if (!customField) {
                ctx.addIssue({
                  code: z.ZodIssueCode.custom,
                  message: `Custom Field is required`,
                  path: ['customField'],
                });
              }
            } else {
              if (!comparator) {
                ctx.addIssue({
                  code: z.ZodIssueCode.custom,
                  message: `Operator is required`,
                  path: ['comparator'],
                });
              }
              if (!compareTo) {
                ctx.addIssue({
                  code: z.ZodIssueCode.custom,
                  message: `Filter To is required`,
                  path: ['compareTo'],
                });
              }
            }
          }
        }
      }
    },
  );

export type QuestionReq = z.infer<typeof QuestionReqSchema>;

export const QuestionRespSchema = BaseResponseSchema.extend({
  guidedQuotingId: z.string(),
  questionText: z.string(),
  questionNumber: z.number(),
  type: QuestionTypesEnumZ,
  filterBy: QuestionFilterByEnumZ,
  compareFrom: QuestionCompareFromEnumZ.nullish(),
  // TODO: better type safety, all custom fields are required together or must all be null
  customFieldEntity: CustomFieldEntityEnumZ.nullish(),
  customFieldType: CustomFieldTypeEnumZ.nullish(),
  customField: z.string().nullish(),
  comparator: QuestionComparatorEnumZ.nullish(),
  compareTo: z
    .union([z.string(), z.string().array()])
    .nullish()
    .transform((value) => {
      if (isString(value)) {
        if (value.includes(',')) {
          return value.split(',');
        }
        return [value];
      }
      return value;
    }),
});

export type IQuestionRespSchema = z.infer<typeof QuestionRespSchema>;
export type IQuestion = IQuestionRespSchema;
// End of Questions

// Guided Quoting
export enum GuidedQuotingStatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export const GuidedQuotingStatusEnumZ = nativeEnum(GuidedQuotingStatusEnum);

export const QuoteSourceFieldsSchema = z
  .object({
    quoteField: z.union([
      z.literal('contractLength'),
      z.literal('contractStartDate'),
      z.literal('currency'),
      z.literal('expirationDate'),
      z.literal('legalEntity'),
      z.custom<`${typeof CUSTOM_FIELDS_PREFIX}${string}`>(
        (val) => isString(val) && /^customFields.[a-zA-Z0-9_]+$/.test(val),
      ),
    ]),
    type: z.nativeEnum(QuestionFilterByEnum),
    value: z.union([z.string(), z.boolean(), z.number()]).nullish(),
  })
  .superRefine(({ type, value }, ctx) => {
    switch (type) {
      case QuestionFilterByEnum.CONSTANT:
      case QuestionFilterByEnum.VARIABLE: {
        if (!value) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: `Value is required`,
            path: ['value'],
          });
        }
        break;
      }
      case QuestionFilterByEnum.NONE: {
        if (value) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: `Value must not be set if Populated By is None`,
            path: ['value'],
          });
        }
        break;
      }
      case QuestionFilterByEnum.QUESTION: {
        if (!value) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: `Value must be set for Question Response`,
            path: ['value'],
          });
        } else if (
          isString(value) &&
          !value.startsWith(QUESTION_PREFIX) &&
          !value.startsWith(QUESTION_TEMP_PREFIX)
        ) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: `Value must a valid Question Response`,
            path: ['value'],
          });
        }
        break;
      }
    }
  });

export type QuoteSourceField = z.infer<typeof QuoteSourceFieldsSchema>;
export type QuoteSourceFieldName = QuoteSourceField['quoteField'];

export const GuidedQuotingReqSchema = z
  .object({
    id: z.string().nullish(),
    name: z.string().min(1, { message: getRequiredMessage('Name') }),
    /** @deprecated use quoteSourceFields instead */
    expirationDateSource: z.string().nullish(),
    /** @deprecated use quoteSourceFields instead */
    contractStartDateSource: z.string().nullish(),
    /** @deprecated use quoteSourceFields instead */
    contractLengthSource: z.string().nullish(),
    /** @deprecated use quoteSourceFields instead */
    quoteOfferingSource: z.string().nullish(),
    quoteSourceFields: QuoteSourceFieldsSchema.array(),
    status: GuidedQuotingStatusEnumZ,
    questions: QuestionReqSchema.array(),
  })
  .superRefine(({ quoteSourceFields, questions }, ctx) => {
    quoteSourceFields?.forEach(({ type, value }, index) => {
      if (type === QuestionFilterByEnum.QUESTION && value) {
        const matchingQuestion = questions.find(({ id }) => id === value);
        if (!matchingQuestion) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: `Value must be set to a valid question`,
            path: ['quoteSourceFields', index, 'value'],
          });
        }
      }
    });
  })
  .superRefine(({ questions }, ctx) => {
    // Validate all required questions are accounted for and in the correct order
    const currencyQuestions = questions.filter(
      ({ type }) => type === QuestionTypesEnum.CURRENCY,
    );
    const currencyQuestion = currencyQuestions[0];

    const productQuestions = questions.filter(
      ({ type }) => type === QuestionTypesEnum.PRODUCT,
    );
    const productQuestion = productQuestions[0];

    const offeringQuestions = questions.filter(
      ({ type }) => type === QuestionTypesEnum.OFFERING,
    );
    const offeringQuestion = offeringQuestions[0];

    const rateQuestions = questions.filter(
      ({ type }) => type === QuestionTypesEnum.RATE,
    );
    const rateQuestion = rateQuestions[0];

    if (!offeringQuestion || !rateQuestion) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `Offering and Rate questions must both be present.`,
        path: ['questions-custom'],
      });
      return;
    }

    [currencyQuestions, productQuestions, offeringQuestions, rateQuestions]
      .filter((item) => item.length > 1)
      .flatMap((items) => items.map((item) => item.questionNumber))
      .forEach((number) => {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `This type of question can only be included once.`,
          path: ['questions', number - 1],
        });
      });

    if (
      currencyQuestion &&
      rateQuestion &&
      rateQuestion.questionNumber < currencyQuestion.questionNumber
    ) {
      [rateQuestion.questionNumber, currencyQuestion.questionNumber].forEach(
        (number) => {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: `Currency question must come before Rate question.`,
            path: ['questions', number - 1],
          });
        },
      );
    }

    if (
      productQuestion &&
      offeringQuestion &&
      offeringQuestion.questionNumber < productQuestion.questionNumber
    ) {
      [productQuestion.questionNumber, offeringQuestion.questionNumber].forEach(
        (number) => {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: `Product question must come before Offering and Rate questions.`,
            path: ['questions', number - 1],
          });
        },
      );
    } else if (
      productQuestion &&
      rateQuestion &&
      rateQuestion.questionNumber < productQuestion.questionNumber
    ) {
      [productQuestion.questionNumber, rateQuestion.questionNumber].forEach(
        (number) => {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: `Product question must come before Offering and Rate questions.`,
            path: ['questions', number - 1],
          });
        },
      );
    }

    if (
      rateQuestion &&
      offeringQuestion &&
      rateQuestion.questionNumber < offeringQuestion.questionNumber
    ) {
      [rateQuestion.questionNumber, offeringQuestion.questionNumber].forEach(
        (number) => {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: `Offering question must come before Rate question.`,
            path: ['questions', number - 1],
          });
        },
      );
    }
  });

export type GuidedQuotingReq = z.infer<typeof GuidedQuotingReqSchema>;

export const GuidedQuotingRespSchema = BaseResponseSchema.extend({
  name: z.string(),
  /** @deprecated use quoteSourceFields instead */
  expirationDateSource: z.string().nullish(),
  /** @deprecated use quoteSourceFields instead */
  contractStartDateSource: z.string().nullish(),
  /** @deprecated use quoteSourceFields instead */
  contractLengthSource: z.string().nullish(),
  /** @deprecated use quoteSourceFields instead */
  quoteOfferingSource: z.string().nullish(),
  quoteSourceFields: QuoteSourceFieldsSchema.array(),
  status: GuidedQuotingStatusEnumZ,
  questions: QuestionRespSchema.array(),
});

export type IGuidedQuotingRespSchema = z.infer<typeof GuidedQuotingRespSchema>;
export type IGuidedQuoting = IGuidedQuotingRespSchema;
// End of Guided Quoting

export const GuidedQuotingStatusTagStyle: {
  [key in GuidedQuotingStatusEnum]: {
    color: string;
    bgColor?: string;
    px?: number;
    borderRadius?: number;
    fontStyle?: string;
  };
} = {
  INACTIVE: {
    color: 'tGray.darkGrayPurple',
    bgColor: 'transparent',
  },
  ACTIVE: {
    color: 'tGreen.mDarkShade',
    bgColor: 'tGreen.light',
    px: 2,
    borderRadius: 4,
  },
};

export type QuestionId = string;
export type OfferingId = string;

export interface ContextProduct {
  id: string;
  name: string;
  type: string;
  isMandatory: boolean;
  isSelected: boolean;
  qty: string;
}

export interface GuidedQuotingContextBase {
  type:
    | QuestionTypesEnum.CURRENCY
    | QuestionTypesEnum.DATE
    | QuestionTypesEnum.LEGAL_ENTITY
    | QuestionTypesEnum.NUMBER
    | QuestionTypesEnum.PRODUCT
    | QuestionTypesEnum.TEXT;
  value: string;
}

export interface GuidedQuotingContextBillingFrequency {
  type: QuestionTypesEnum.BILLING_FREQUENCY;
  value: string[];
}

export interface GuidedQuotingContextOffering {
  type: QuestionTypesEnum.OFFERING;
  value: Record<
    OfferingId,
    {
      name: string;
      rate: string;
      products: ContextProduct[];
    }
  >;
}

export interface GuidedQuotingContextRate {
  type: QuestionTypesEnum.RATE;
  value: Record<OfferingId, IRateResBaseSchema>;
}

export type GuidedQuotingContext = Record<
  QuestionId,
  | GuidedQuotingContextBase
  | GuidedQuotingContextBillingFrequency
  | GuidedQuotingContextOffering
  | GuidedQuotingContextRate
>;
