import { MFlex } from '~app/components/Monetize';
interface CustomRateProductsWithPriceProps {
  productName: string;
  children: React.ReactElement;
}
const CustomRateProductsWithPrice = ({
  productName,
  children,
}: CustomRateProductsWithPriceProps) => {
  return (
    <MFlex flexDirection="column">
      <MFlex
        justifyContent="flex-start"
        alignItems="center"
        w="full"
        fontWeight="medium"
        color="tPurple.dark"
        fontSize="sm"
      >
        {productName}
      </MFlex>
      {children}
    </MFlex>
  );
};

export default CustomRateProductsWithPrice;
