import { Flex, ListItem, VStack } from '@chakra-ui/react';
import { FC } from 'react';
import { CustomSelectStateProps } from '~app/types/mCustomSelectTypes';
import { MBox, MCheckbox } from '../../chakra';
import MText from '../../MText';

interface CheckboxItemListProps
  extends Pick<
    CustomSelectStateProps,
    | 'isSelectedItem'
    | 'isDisabledItem'
    | 'isSubtitleItem'
    | 'itemRefs'
    | 'getTitle'
    | 'items'
    | 'cursor'
    | 'handleChange'
    | 'renderItemContent'
    | 'query'
  > {
  indexOffset: number;
  rightLabel?: boolean;
}

const CheckboxItemList: FC<CheckboxItemListProps> = ({
  indexOffset,
  isSelectedItem,
  isDisabledItem,
  isSubtitleItem,
  itemRefs,
  getTitle,
  cursor,
  items,
  handleChange,
  renderItemContent,
  query,
  rightLabel,
}: CheckboxItemListProps) => {
  const elements = items.map((item: any, i: number) => {
    const index = i + indexOffset;
    const isHighlight = cursor === index;
    const isSelected = isSelectedItem(item);
    const isDisabled = isDisabledItem(item);
    const isSubtitle = isSubtitleItem(item);

    let backgroundColor = '';
    if (isSelected) {
      backgroundColor = 'tBlue.hover';
    }
    if (isHighlight) {
      backgroundColor = 'tBlue.hover';
    }

    return (
      <ListItem
        key={`cb-${index}`}
        tabIndex={-1}
        _hover={{
          background: !isSubtitle ? 'tBlue.hover' : undefined,
        }}
        backgroundColor={backgroundColor}
        cursor="pointer"
        px={1}
        py={1}
        role="group"
        display={'flex'}
      >
        {isSubtitle ? (
          <MText
            disabled
            color={'tGray.disabledText'}
            noOfLines={1}
            cursor={'default'}
            pb={1}
            fontSize="sm"
            maxW="250px"
          >
            {getTitle(item)}
          </MText>
        ) : (
          <MCheckbox
            onChange={(event: any) => {
              handleChange(item, event);
            }}
            mt="0"
            py={2}
            w="100%"
            isChecked={isSelected}
            isDisabled={isDisabled}
            fontWeight={isSelected ? 'bold' : undefined}
            variant="custom-select"
            tabIndex={-1}
            ref={(element: HTMLInputElement) =>
              (itemRefs.current[index] = element)
            }
          >
            {renderItemContent ? (
              renderItemContent({
                title: getTitle(item),
                item,
                isSubtitle: false,
                isSelected,
                isHighlight,
                isDisabled,
                query,
              })
            ) : (
              <Flex
                aria-disabled={isDisabled}
                role="option"
                aria-selected={isSelected}
                borderRadius="2"
                alignItems="center"
                justifyContent="space-between"
                maxWidth="100%"
              >
                <MText
                  color={'tPurple.base'}
                  noOfLines={1}
                  fontSize="sm"
                  ml="1"
                  maxW="250px"
                >
                  {getTitle(item)}
                </MText>
                {!!rightLabel && (
                  <MText
                    fontSize="10px"
                    color="inherit"
                    _groupHover={{ color: 'tPurple.base' }}
                    ml="4"
                    fontWeight="400"
                  >
                    {item.rightLabel}
                  </MText>
                )}
              </Flex>
            )}
          </MCheckbox>
        )}
      </ListItem>
    );
  });
  if (items.length === 0) {
    return <MBox p="2">No Results</MBox>;
  }
  return (
    <VStack align="left" spacing="0">
      {elements}
    </VStack>
  );
};

export default CheckboxItemList;
