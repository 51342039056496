import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useForm } from 'react-hook-form';
import {
  useRevRecSettings,
  useSaveRevRecSettings,
} from '~app/api/revenueService';
import {
  MFlex,
  MFormField,
  MPageContainer,
  MPageLoader,
  MRadio,
  MRadioGroup,
  MSettingsPageHeader,
  MText,
} from '~app/components/Monetize';
import {
  IRevRecSettingsResponse,
  RevRecSettingsResponse,
  RevRecTriggerEnum,
} from '~app/types/revenueTypes';
import { SettingsToggle } from '../Quoting/QuoteSettings/SettingsToggle';

export const RevRecSettings = () => {
  const { data, isLoading: isLoadingRevRecSettings } = useRevRecSettings({
    onSuccess: (newData) => {
      reset(newData);
    },
  });
  const { mutate } = useSaveRevRecSettings();

  const {
    control,
    formState: { errors },
    reset,
    watch,
    getValues,
  } = useForm<IRevRecSettingsResponse>({
    resolver: zodResolver(RevRecSettingsResponse),
    mode: 'onChange',
    defaultValues: data || {
      enabled: false,
      trigger: RevRecTriggerEnum.INVOICE_CREATED,
    },
  });

  const enabled = watch('enabled');

  const onSubmit = () => {
    mutate(getValues());
  };

  if (isLoadingRevRecSettings) {
    return <MPageLoader />;
  }

  return (
    <MPageContainer alignItems="stretch">
      <MSettingsPageHeader title="Revenue" />
      <MFlex maxW="600px" flexDirection="column">
        <Controller
          name="enabled"
          control={control}
          render={({ field: { value, name, onChange } }) => (
            <SettingsToggle
              formControlProps={{ pt: 0 }}
              name={name}
              heading="Enable Revenue Recognition"
              subheading="Enable or disable creation of revenue recognition schedules"
              value={value}
              onChange={(value) => {
                onChange(value);
                onSubmit();
              }}
            />
          )}
        />

        <MFormField
          error={errors.trigger}
          label="Revenue Recognition Trigger"
          mt={6}
          labelProps={{ fontSize: 'md' }}
        >
          <Controller
            name="trigger"
            control={control}
            render={({ field: { onChange, ...rest } }) => (
              <MRadioGroup
                {...rest}
                isDisabled={!enabled}
                onChange={(value) => {
                  onChange(value);
                  onSubmit();
                }}
              >
                <MFlex flexDirection="column">
                  <MText mb={2}>Define when revenue is recognized</MText>
                  <MRadio value={RevRecTriggerEnum.INVOICE_CREATED} mb={2}>
                    Invoice Created
                  </MRadio>
                  <MRadio value={RevRecTriggerEnum.INVOICE_PAID} isDisabled>
                    Invoice Paid
                  </MRadio>
                </MFlex>
              </MRadioGroup>
            )}
          />
        </MFormField>
      </MFlex>
    </MPageContainer>
  );
};
