import React, { FunctionComponent as FC, useState, useEffect } from 'react';
import {
  chakra,
  useDisclosure,
  Text,
  Flex,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Box,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverContentProps,
  Tooltip,
} from '@chakra-ui/react';
import { MdArrowDropDown, MdSearch, MdClose, MdCheck } from 'react-icons/md';

interface MDropdownSearchItem {
  name: string;
  value: string;
}

interface MDropdownSearchProps {
  items: MDropdownSearchItem[];
  placeholder?: string;
  searchTooltip?: string;
  value?: MDropdownSearchItem | undefined;
  onChange: (val: MDropdownSearchItem | undefined) => void;
}

const DropDownMenuItem: FC<any> = ({ isSelected, children }: any) => {
  if (isSelected) {
    return (
      <Flex backgroundColor="#FAFAFF" align="center" p="2">
        <Icon as={MdCheck} color="tIndigo.base" mr={2.5} />
        <Text fontWeight="bold" fontSize="sm" color="tIndigo.base">
          {children}
        </Text>
      </Flex>
    );
  }
  return (
    <Text
      fontWeight="bold"
      fontSize="sm"
      color="inherit"
      _hover={{ color: 'white' }}
    >
      {children}
    </Text>
  );
};

const MDropdownSearch = React.forwardRef<MDropdownSearchProps, any>(
  (props: MDropdownSearchProps, ref: any) => {
    const { items, placeholder, value, onChange, searchTooltip } = props;
    const { onOpen, onClose, isOpen } = useDisclosure();
    const [query, setQuery] = useState<string>('');
    const [selected, selectItem] = useState<MDropdownSearchItem | undefined>();

    const handleQuery = (event: React.ChangeEvent<HTMLInputElement>): void => {
      setQuery(event.target.value);
    };

    const handleClear = (): void => {
      setQuery('');
    };

    const handleItem = (item: MDropdownSearchItem) => {
      selectItem(item);
      onChange(item as any);
      onClose();
    };

    const filteredItems: MDropdownSearchItem[] = items.filter(
      (item: MDropdownSearchItem) =>
        item.name?.toLowerCase()?.match(query?.toLowerCase()) ||
        item.value?.toLowerCase()?.match(query?.toLowerCase()),
    );

    useEffect(() => {
      if (value) {selectItem(value);}
    }, [value]);

    return (
      <MDropdownActions
        ref={ref}
        placement="bottom-start"
        onOpen={onOpen}
        onClose={onClose}
        isOpen={isOpen}
        popOverContentProps={{
          w: 56,
        }}
        renderTrigger={() => (
          <Tooltip
            isDisabled={isOpen}
            label={searchTooltip}
            placement="bottom-end"
          >
            <Flex
              justify="space-between"
              align="center"
              paddingY={2}
              pl={4}
              pr={2.5}
              h={8}
              backgroundColor={isOpen ? 'tBlue.hover' : 'white'}
              borderRadius={3}
              borderColor={isOpen ? 'tBlue.lightShade' : 'tGray.lightPurple'}
              borderWidth={1}
            >
              <Text fontSize={14} fontWeight="bold" color="tPurple.base">
                {selected != null ? selected.name : placeholder}
              </Text>
              <Icon
                as={MdArrowDropDown}
                color={isOpen ? 'tIndigo.base' : 'tPurple.base'}
                w={5}
                h={5}
              />
            </Flex>
          </Tooltip>
        )}
      >
        <InputGroup size="sm" bgColor="tWhite.titanWhite" mb={2}>
          <InputLeftElement pointerEvents="none">
            <Icon as={MdSearch} color="tIndigo.base" />
          </InputLeftElement>
          <Input
            value={query}
            onChange={handleQuery}
            border={0}
            placeholder="Search Currency"
            color="tIndigo.base"
          />
          <InputRightElement onClick={handleClear}>
            <Icon as={MdClose} color="tRed.base" />
          </InputRightElement>
        </InputGroup>
        <Box maxH={200} className="custom-scroll-bar-v1" overflow="auto">
          {filteredItems.map((item: MDropdownSearchItem) => {
            const isSelected: boolean = item.value === selected?.value;

            return (
              <MDropdownActionItem
                key={item.value}
                onClick={() => !isSelected && handleItem(item)}
                p={isSelected ? 0 : 2}
                _hover={{
                  color: 'white',
                  background: isSelected ? 'white' : 'tIndigo.base',
                }}
              >
                <DropDownMenuItem isSelected={isSelected}>
                  {item.name}
                </DropDownMenuItem>
              </MDropdownActionItem>
            );
          })}
          {!filteredItems.length && (
            <Text p={15} textAlign="center">
              No Items Found!
            </Text>
          )}
        </Box>
      </MDropdownActions>
    );
  },
);

MDropdownSearch.defaultProps = {
  placeholder: 'Select',
  searchTooltip: 'Search',
  value: undefined,
};

interface MDropdownActionsProps {
  children: any;
  renderTrigger: () => React.ReactElement;
  popOverContentProps?: PopoverContentProps;
}

const MDropdownActions = React.forwardRef<MDropdownActionsProps, any>(
  (props: MDropdownActionsProps, ref: any) => {
    const { renderTrigger, children, popOverContentProps, ...rest } = props;

    return (
      <Box position="relative" ref={ref}>
        <Popover
          placement="bottom"
          closeOnBlur
          trigger="click"
          offset={[0, 0]}
          {...rest}
        >
          <PopoverTrigger>{renderTrigger()}</PopoverTrigger>
          <PopoverContent
            border="1px solid"
            borderColor="tGray.back"
            borderRadius={0.5}
            p={2}
            w={120}
            boxShadow="t2"
            {...popOverContentProps}
          >
            {children}
          </PopoverContent>
        </Popover>
      </Box>
    );
  },
);

export const MIconAction = ({ icon }: { icon: any }) => (
  <Icon
    fontSize="lg"
    as={icon}
    minWidth="0"
    background="none"
    _hover={{
      color: 'tIndigo.base',
      background: 'none',
    }}
  />
);

export const MDropdownActionItem = ({ children, ...rest }: any) => (
  <Box
    color="tIndigo.base"
    p={0.5}
    borderRadius={0.5}
    cursor="pointer"
    _hover={{ color: 'white', background: 'tIndigo.base' }}
    {...rest}
  >
    {children}
  </Box>
);

MDropdownActions.defaultProps = {
  popOverContentProps: {},
};

export default chakra(MDropdownSearch);
