import { MdOutlineFileDownload } from 'react-icons/md';
import { MCheckbox, MFlex, MStack, MTooltip } from './chakra';
import MButton from './MButton';
import MCenterModal from './MCenterModal';
import MCustomIconButton from './MCustomIconButton';
import MFormField from './MFormField';
import MText from './MText';

export const ExportEntityButtonUI = ({
  ref,
  handleButtonClick,
  isDisabled,
  tooltipLabel,
  isLoading,
  isModalOpen,
  closeModal,
  handleDownload,
  customConfigElement,
  applyFilters,
  setApplyFilters,
}: {
  ref: React.ForwardedRef<unknown>;
  handleButtonClick: () => void;
  isDisabled: boolean;
  tooltipLabel: string;
  isLoading: boolean;
  isModalOpen: boolean;
  closeModal: () => void;
  handleDownload: () => void;
  customConfigElement: React.ReactNode;
  applyFilters: boolean;
  setApplyFilters: (apply: boolean) => void;
}) => {
  return (
    <MTooltip label={tooltipLabel} placement="bottom-end" shouldWrapChildren>
      <MCustomIconButton
        ref={ref}
        variant="icon"
        containerSize="8"
        btnSize={6}
        fontSize="lg"
        cursor="pointer"
        icon={MdOutlineFileDownload}
        onClick={handleButtonClick}
        isDisabled={isDisabled}
        iconColor="tPurple.base"
        isLoading={isLoading}
      />
      <MCenterModal
        size="md"
        isOpen={isModalOpen}
        onClose={closeModal}
        modalTitle="Export CSV"
        renderFooter={() => (
          <MStack
            spacing={4}
            direction="row"
            align="center"
            justify="right"
            flex={1}
          >
            <MButton onClick={closeModal} variant="cancel" minW="auto">
              Cancel
            </MButton>
            <MButton
              variant="primary"
              isLoading={isLoading}
              onClick={handleDownload}
              minW="auto"
            >
              Export
            </MButton>
          </MStack>
        )}
      >
        <MFlex flexDir="column">
          {customConfigElement}
          <MText>
            There are filters applied to this view. Do you want the export to
            use these filters?
          </MText>
          <MFormField>
            <MCheckbox
              mt={1}
              isChecked={applyFilters}
              isDisabled={isLoading}
              onChange={() => setApplyFilters(!applyFilters)}
            >
              Include filters on export
            </MCheckbox>
          </MFormField>
        </MFlex>
      </MCenterModal>
    </MTooltip>
  );
};
