import { Icon } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import pick from 'lodash/pick';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { MdLock, MdVisibility, MdVisibilityOff } from 'react-icons/md';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { handleApiErrorToast } from '~api/axios';
import {
  useCreateWebhook,
  useGetWebHookById,
  useUpdateWebhook,
} from '~app/api/webhooksService';
import {
  MAlert,
  MBox,
  MButton,
  MCustomSelect,
  MFlex,
  MFormField,
  MGrid,
  MGridItem,
  MIcon,
  MIDCopyBox,
  MInput,
  MPageContainer,
  MPageLoader,
  MSettingsPageHeader,
  MText,
  MTextarea,
  MTooltip,
} from '~app/components/Monetize';
import { ROUTES } from '~app/constants';
import { WEBHOOK_ENABLED_DISPLAY } from '~app/constants/webhooks';
import { usePrompt } from '~app/hooks/usePrompt';
import { useDocumentHead } from '~app/services/documentHead';
import { IWebhookReqSchema, WebhookReqSchema } from '~app/types';
import EventSelectModal from './components/EventSelectModal';

const WebhookCreate = () => {
  const { setDocTitle } = useDocumentHead();
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();

  const pathUrl = location.pathname;
  const webhookId = params.webhookId || '';
  const [isWebhookLocked, setIsWebhookLocked] = useState<boolean>(false);
  const [secretShow, setSecretShow] = useState(false);

  const defaultForm = { url: '', enabled: true };
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isValid, isSubmitted, isDirty },
  } = useForm<IWebhookReqSchema>({
    resolver: zodResolver(WebhookReqSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const { data: webhook, isLoading: isWebhookFetching } = useGetWebHookById({
    webhookId,
  });

  usePrompt(
    'There are unsaved changes, do you want to discard these changes?',
    isDirty && !isSubmitted,
  );

  const { mutate: createWebHookMutate, isLoading: createLoading } =
    useCreateWebhook({
      onSuccess: (newData) => {
        newData && navigate(ROUTES.getWebhookViewRoute(newData.webhookId!));
      },
      onError: (err: any) => handleApiErrorToast(err),
    });

  const { mutate: updateWebHookMutate, isLoading: updateLoading } =
    useUpdateWebhook({
      onSuccess: (newData) => {
        newData && navigate(ROUTES.SETTINGS_WEBHOOK_LIST, { replace: true });
      },
      onError: (err) => handleApiErrorToast(err),
    });

  useEffect(() => {
    if (webhook) {
      setDocTitle('Settings', `Webhooks - ${webhook.url}`);
      setIsWebhookLocked(webhook?.locked);
      const formData = pick(webhook, Object.keys(WebhookReqSchema.shape));
      reset(formData);
    } else {
      reset(defaultForm);
    }
  }, [webhook]);

  const onSubmit = async (payload: IWebhookReqSchema) => {
    if (webhookId) {
      updateWebHookMutate({ webhookId, payload });
    } else {
      createWebHookMutate(payload);
    }
  };

  const isInputDisabled = isWebhookLocked || createLoading || updateLoading;

  if (webhookId && isWebhookFetching) {
    return <MPageLoader />;
  }

  return (
    <MPageContainer alignItems="stretch">
      <MSettingsPageHeader
        divider={false}
        hasBackButton
        backButtonTitle="Back to Webhooks"
        backButtonLink={ROUTES.SETTINGS_WEBHOOK_LIST}
        title={
          !webhookId
            ? 'New Webhook'
            : pathUrl.endsWith('view')
            ? 'View Webhook'
            : 'Edit Webhook'
        }
        status={webhook?.enabled ? 'Active' : webhookId && 'Inactive'}
        tag={
          isWebhookLocked ? (
            <MTooltip
              label="This webhook is required for an integration and can't be updated."
              shouldWrapChildren
              placement="bottom-start"
            >
              <span>
                <MIcon
                  w={4}
                  h={6}
                  ml="3"
                  as={MdLock}
                  fontSize="xl"
                  alignSelf="center"
                  style={{
                    cursor: 'pointer',
                  }}
                />
              </span>
            </MTooltip>
          ) : null
        }
      >
        {!isSubmitted && isDirty && (
          <MAlert
            type="warning"
            alertProps={{ px: 2, py: 1 }}
            alertIconProps={{ width: 4, height: 4 }}
            alertDescriptionProps={{ fontSize: 'sm' }}
            mr="4"
          >
            Unsaved Changes
          </MAlert>
        )}
        <MButton
          variant="primary"
          isLoading={createLoading || updateLoading}
          isDisabled={!isValid || !isDirty || createLoading || updateLoading}
          onClick={handleSubmit(onSubmit)}
        >
          Save
        </MButton>
      </MSettingsPageHeader>

      <MBox maxW="650px" px={3.5}>
        <form onSubmit={handleSubmit(onSubmit)} data-testid="tenant-form">
          <MGrid templateColumns="repeat(12, 1fr)" gap={4}>
            <MGridItem colSpan={10}>
              <MFormField error={errors.url} label="Endpoint URL" isRequired>
                <Controller
                  name="url"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <MInput
                      {...field}
                      placeholder="https://"
                      isDisabled={isInputDisabled}
                    />
                  )}
                />
              </MFormField>
            </MGridItem>
            <MGridItem colSpan={2}>
              <MFormField error={errors.enabled} label="Status" isRequired>
                <Controller
                  name="enabled"
                  control={control}
                  render={({ field }) => (
                    <MCustomSelect
                      placeholder="Status"
                      items={WEBHOOK_ENABLED_DISPLAY}
                      {...field}
                      isDisabled={isInputDisabled}
                    />
                  )}
                />
              </MFormField>
            </MGridItem>
            {webhookId && (
              <MGridItem colSpan={12}>
                <MFormField label="Secret">
                  <MFlex alignItems="center">
                    {secretShow ? (
                      <MIDCopyBox
                        copyValue={webhook?.secret!}
                        color="tPurple.dark"
                      />
                    ) : (
                      <MText color="tPurple.dark">
                        {webhook?.secret.substring(0, 5)} *****
                      </MText>
                    )}

                    <Icon
                      ml={1}
                      cursor="pointer"
                      color="tGray.lightPurple"
                      as={secretShow ? MdVisibilityOff : MdVisibility}
                      onClick={() => setSecretShow(!secretShow)}
                    />
                  </MFlex>
                </MFormField>
              </MGridItem>
            )}
            <MGridItem colSpan={12}>
              <MFormField
                error={errors.description}
                label="Description"
                isRequired
              >
                <Controller
                  name="description"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <MTextarea
                      placeholder="An optional description of what this webhook endpoint is used for..."
                      {...field}
                      isDisabled={isInputDisabled}
                    />
                  )}
                />
              </MFormField>
            </MGridItem>
            <MGridItem colSpan={12}>
              <MFormField label="Select events to listen to">
                <Controller
                  name="events"
                  control={control}
                  render={({ field }) => (
                    <EventSelectModal {...field} disabled={isInputDisabled} />
                  )}
                />
              </MFormField>
              {errors?.events && <MFormField error={errors.events} />}
            </MGridItem>
          </MGrid>
        </form>
      </MBox>
    </MPageContainer>
  );
};

export default WebhookCreate;
