import {
  OnboardingOfferingPricingModels,
  OnboardingOfferingStatus,
  OnboardingOfferingTypes,
  OnboardingProductTypes,
} from '../types';

export const ProductTypeDisplayText: Record<OnboardingProductTypes, string> = {
  [OnboardingProductTypes.ADVANCE]: 'Subscription',
  [OnboardingProductTypes.SUBSCRIPTION]: 'Subscription',
  [OnboardingProductTypes.MIN_COMMIT]: 'Minimum Commit',
};

export const ProductStatusDisplayText: Record<string, string> = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
};

export const OfferingTypeDisplayText: Record<OnboardingOfferingTypes, string> =
  {
    [OnboardingOfferingTypes.SUBSCRIPTION]: 'Subscription',
    [OnboardingOfferingTypes.MIN_COMMIT]: 'Minimum Commit',
  };

export const OfferingPricingModelDisplayText: Record<
  OnboardingOfferingPricingModels,
  string
> = {
  [OnboardingOfferingPricingModels.FLAT]: 'Flat',
  [OnboardingOfferingPricingModels.TIERED]: 'Tiered',
  [OnboardingOfferingPricingModels.VOLUME]: 'Volume',
};

export const OfferingStatusDisplayText: Record<
  OnboardingOfferingStatus,
  string
> = {
  [OnboardingOfferingStatus.ACTIVE]: 'Active',
  [OnboardingOfferingStatus.INACTIVE]: 'Inactive',
};
