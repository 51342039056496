import { useMemo } from 'react';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import {
  MCustomNumberInput,
  MCustomSelect,
  MFormField,
  MGrid,
  MGridItem,
} from '~app/components/Monetize';
import { DatePicker } from '~app/components/Monetize/DatePicker/DatePicker';
import { PAYMENT_METHODS } from '~app/constants';
import { IPaymentMethodResp, MakePaymentForm } from '~types';
import { PaymentMethodItemRenderer } from '../../../components/PaymentMethods/PaymentMethodItemRenderer';
import { getGroupedAndSortedPaymentMethodOptions } from '../../../utils/payment.utils';

type CreatePaymentWithPaymentMethodProps = {
  allowPartialPayment: boolean;
  allPaymentMethods: IPaymentMethodResp[];
  control: Control<MakePaymentForm>;
  errors: FieldErrors<MakePaymentForm>;
  isLoading: boolean;
  isSaving: boolean;
  onOpenPaymentMethodModal: () => void;
};

export const CreatePaymentWithPaymentMethod = ({
  allowPartialPayment,
  allPaymentMethods,
  control,
  errors,
  isLoading,
  isSaving,
  onOpenPaymentMethodModal,
}: CreatePaymentWithPaymentMethodProps) => {
  const paymentMethodItems = useMemo(
    () => getGroupedAndSortedPaymentMethodOptions(allPaymentMethods),
    [allPaymentMethods],
  );

  return (
    <MGrid templateColumns="repeat(2, 1fr)" mt="4" gap={4}>
      <MGridItem colSpan={1}>
        <MFormField error={errors.amount} label="Amount" isRequired>
          <Controller
            name="amount"
            control={control}
            render={({ field: { onChange, ...field } }) => (
              <MCustomNumberInput
                precision={2}
                isDisabled={!allowPartialPayment || isSaving}
                inputMode="decimal"
                onChange={(value) => onChange(value)}
                {...field}
              />
            )}
          />
        </MFormField>
      </MGridItem>
      <MGridItem colSpan={1}>
        <MFormField error={errors.paymentDate} label="Payment Date" isRequired>
          <Controller
            name="paymentDate"
            control={control}
            render={({ field }) => (
              <DatePicker isReadOnly isDisabled {...field} />
            )}
          />
        </MFormField>
      </MGridItem>
      <MGridItem colSpan={2}>
        <MFormField error={errors.paymentMethodId} mt="2">
          <Controller
            name="paymentMethodId"
            control={control}
            render={({ field }) => (
              <MCustomSelect
                placeholder="Select Payment Method"
                itemValue="id"
                itemTitle="paymentMethodName"
                loading={isLoading}
                items={paymentMethodItems}
                isDisabled={isSaving}
                prependItems={[
                  {
                    item: PAYMENT_METHODS.NEW_PAYMENT_METHOD,
                    isAction: true,
                    hasDivider: true,
                    onClick: ({ onClose }) => {
                      onClose && onClose();
                      onOpenPaymentMethodModal();
                    },
                  },
                ]}
                renderItemContent={PaymentMethodItemRenderer}
                {...field}
              />
            )}
          />
        </MFormField>
      </MGridItem>
    </MGrid>
  );
};
