import { SelectionStateEnum } from '../../types';
import { MCenter, MCheckbox, MFlex, MFormLabel, MText } from '../Monetize';

interface TransactionApplicationTableSelectRowProps {
  checkedState: SelectionStateEnum;
  isDisabled?: boolean;
  onToggleSelectAll: () => void;
}

export const TransactionApplicationTableSelectRow = ({
  checkedState,
  isDisabled,
  onToggleSelectAll,
}: TransactionApplicationTableSelectRowProps) => {
  return (
    <>
      <MCenter>
        <MCheckbox
          isIndeterminate={checkedState === SelectionStateEnum.SOME}
          id={'applications-apply-all'}
          isChecked={checkedState === SelectionStateEnum.ALL}
          isDisabled={isDisabled}
          onChange={() => onToggleSelectAll()}
        />
      </MCenter>
      <MFlex align="center" gridColumn="span 6">
        <MFormLabel htmlFor={`applications-apply-all`} fontWeight="400" m="0">
          <MText>Apply All</MText>
        </MFormLabel>
      </MFlex>
    </>
  );
};
