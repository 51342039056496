import { MdInfo } from 'react-icons/md';
import { MFlex, MIcon, MText, MTooltip } from '~app/components/Monetize';
import { PRICE_MODEL_OPTIONS } from '~app/constants/offerings';
import {
  AdditionalFrequency,
  AggregationModelEnum,
  DEFAULT_PAGER,
  IPricesAndOptionsReqSchema,
  IProduct,
  IProductOptionSchema,
  IRateReqSchema,
  ProductTypeEnum,
  RateBillingFrequencyEnum,
  RateUsageBillingFrequencyEnum,
  TDataTablePager,
} from '~app/types';
import { IPriceReqSchema, PriceModelEnum } from '~app/types/priceTypes';
import { arrayToObject } from '~app/utils/misc';

export const renderPriceModelItemContent = ({
  title,
  item,
  isSubtitle,
  isSelected,
  isHighlight,
}: {
  title: string;
  item: any;
  isSubtitle: boolean;
  isSelected: boolean;
  isHighlight: boolean;
}) => (
  <PriceModelItemContent
    title={title}
    item={item}
    isSubtitle={isSubtitle}
    isSelected={isSelected}
    isHighlight={isHighlight}
  />
);

export const getPriceModelHelperText = (title: PriceModelEnum) => {
  switch (title) {
    case PriceModelEnum.FLAT:
      return 'If the units fall within the range, the price is the amount';

    case PriceModelEnum.TIERED:
      return 'If the units fall within a tier, the price is the sum of corresponding units times amount for that and all previous tiers';

    case PriceModelEnum.VOLUME:
      return 'If the units fall within a range, the price is units times the amount for that range';

    case PriceModelEnum.CUSTOM:
      return 'The price will be set by the user each time';

    default:
      return '';
  }
};

const PriceModelItemContent = ({
  title,
  item,
  isSubtitle,
  isSelected,
  isHighlight,
}: {
  title: string;
  item: any;
  isSubtitle: boolean;
  isSelected: boolean;
  isHighlight: boolean;
}) => {
  return (
    <MFlex
      alignItems="center"
      justifyContent="space-between"
      w="100%"
      position="relative"
      role="group"
      _hover={{
        div: {
          display: 'block',
        },
      }}
    >
      <MText
        color="inherit"
        isTruncated
        noOfLines={1}
        fontSize={isSubtitle ? '12px' : 'sm'}
        mb="-2px"
        mt="0px"
        fontWeight={isSelected || isHighlight ? 'bold' : 'light'}
      >
        {PRICE_MODEL_OPTIONS[title as PriceModelEnum] || title}
      </MText>

      <MTooltip
        label={getPriceModelHelperText(item.value)}
        placement="bottom-start"
      >
        <MFlex position="relative" alignItems="center" minH="5">
          <MIcon as={MdInfo} color="tPurple.base" />
        </MFlex>
      </MTooltip>
    </MFlex>
  );
};

/**
 * Convert an object of prices by productId into a combined array of prices
 */
export function getPricesFromProductObject(
  products: Record<string, IPricesAndOptionsReqSchema>,
): IPriceReqSchema[] {
  if (!products) {
    return [];
  }
  return Object.keys(products)
    .reduce(
      (acc: IPriceReqSchema[], curr: string) =>
        products ? [...acc, ...(products[curr].prices as any)] : acc,
      [],
    )
    .map(
      ({
        productId,
        from,
        to,
        amount,
        description,
        priceModel,
      }: IPriceReqSchema) => ({
        productId: productId,
        from,
        to,
        amount: amount,
        description: description,
        priceModel: priceModel,
      }),
    );
}
/**
 * Convert aggregationModel by productId into a combined array of options
 */
export function getOptionsFromProductObject(
  products: Record<string, IPricesAndOptionsReqSchema>,
): IProductOptionSchema[] {
  if (!products) {
    return [];
  }
  return Object.keys(products)
    .reduce((acc: IProductOptionSchema[], curr: string) => {
      return [
        ...acc,
        {
          productId: curr,
          aggregationModel: products[curr]
            .aggregationModel as AggregationModelEnum,
        },
      ];
    }, [])
    .filter(({ aggregationModel }) => !!aggregationModel);
}

export const PRODUCTS_ITEM_DEFAULT_PAGER: TDataTablePager = {
  ...DEFAULT_PAGER,
  rows: 50,
  sortField: 'name',
  sortOrder: 1,
};

/**
 *
 * @param selectedPriceModel - Currently selected price model in 0th index
 * @param disabledPriceModel - Disabled price model that user is trying to select
 * @returns - Constructs the message based on the params and returns it.
 */
export const getTooltipDisabledMessage = ({
  selectedPriceModel,
  disabledPriceModel,
}: {
  selectedPriceModel: string;
  disabledPriceModel: string;
}) => {
  return `${disabledPriceModel} pricing cannot be used with ${selectedPriceModel} pricing. To change to ${disabledPriceModel} pricing, change the first pricing model to ${disabledPriceModel}.`;
};

/**
 *
 * @param products - list of selected products
 * @returns - returns an object of each productTypes with true/false value based on there existence
 */
export const getProductTypeExistence = (products: IProduct[]) => {
  let isSubscriptionProductTypeExist = false;
  let isMinCommitProductTypeExist = false;
  let isOneTimeProductTypeExist = false;
  let isOneTimePrepaidCreditProductTypeExist = false;
  let isUsageProductTypeExist = false;

  for (const product of products) {
    switch (product.productType) {
      case ProductTypeEnum.ADVANCE:
        isSubscriptionProductTypeExist = true;
        break;
      case ProductTypeEnum.MIN_COMMIT:
        isMinCommitProductTypeExist = true;
        break;
      case ProductTypeEnum.ONETIME:
        isOneTimeProductTypeExist = true;
        break;
      case ProductTypeEnum.ONETIME_PREPAID_CREDIT:
        isOneTimePrepaidCreditProductTypeExist = true;
        break;
      case ProductTypeEnum.USAGE:
        isUsageProductTypeExist = true;
        break;
    }
  }

  return {
    isSubscriptionProductTypeExist,
    isMinCommitProductTypeExist,
    isOneTimeProductTypeExist,
    isOneTimePrepaidCreditProductTypeExist,
    isUsageProductTypeExist,
  };
};

/**
 *
 * @param isOneTime - is Offering type is one time
 * @param isMinCommit - is Offering type is min commit
 * @param isSubscription - is Offering type is subscription
 * @param isSubscriptionProductTypeExist - is subscription type product exists in selected list products
 * @returns - Billing Frequency or undefined based on the params
 */
export const getDefaultBillingFrequency = ({
  isOneTime,
  isMinCommit,
  isPercentOfTotal,
  isSubscription,
  isSubscriptionProductTypeExist,
}: {
  isOneTime: boolean;
  isMinCommit: boolean;
  isPercentOfTotal: boolean;
  isSubscription: boolean;
  isSubscriptionProductTypeExist: boolean;
}) => {
  if (isOneTime) {
    return RateBillingFrequencyEnum.ONETIME;
  }

  if (isMinCommit || isPercentOfTotal) {
    return RateBillingFrequencyEnum.MONTHLY;
  }

  /** Will make use of this logic in future */
  // if (isSubscription && isSubscriptionProductTypeExist) {
  //   return RateBillingFrequencyEnum.MONTHLY;
  // }

  // return undefined;

  return RateBillingFrequencyEnum.MONTHLY;
};

/**
 *
 * @param isMinCommit - is Offering type is min commit
 * @param isUsageProductTypeExist - is usage type product exists in the list of selected products
 * @returns - Usage Billing Frequency or undefined based on the params
 */
export const getDefaultUsageBillingFrequency = ({
  isMinCommit,
  isUsageProductTypeExist,
}: {
  isMinCommit: boolean;
  isUsageProductTypeExist: boolean;
}) => {
  if (isMinCommit || isUsageProductTypeExist) {
    return RateUsageBillingFrequencyEnum.MONTHLY;
  }

  return undefined;
};

/**
 *
 * @param products - array of products for the offering
 * @param frequency - additional frequency
 * @param finalData - main frequency final data
 * @returns - additional frequency final data
 */
export const getAdditionalFrequencyData = (
  products: IProduct[],
  frequency: AdditionalFrequency,
  finalData: IRateReqSchema,
) => {
  const productsObj = arrayToObject(products, 'id');
  const additionalFinalData = {
    ...finalData,
    name: frequency.name,
    description: frequency.description,
    billingFrequencyInMonths: frequency.billingFrequencyInMonths,
    billingFrequency: frequency.billingFrequency,
    prices: finalData.prices.map(({ amount, productId, ...rest }) => {
      const nextAmountPerMonth =
        finalData.billingFrequency === RateBillingFrequencyEnum.MONTHLY
          ? amount
          : amount / finalData.billingFrequencyInMonths!;

      return {
        ...rest,
        productId,
        amount:
          Math.round(
            productsObj[productId!].productType === ProductTypeEnum.ADVANCE
              ? nextAmountPerMonth * frequency.billingFrequencyInMonths * 100
              : nextAmountPerMonth * 100,
          ) / 100, // Ensures to 2 decimal places
      };
    }),
  };

  return additionalFinalData;
};
