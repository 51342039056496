import { z } from 'zod';

export enum RevRecTriggerEnum {
  INVOICE_CREATED = 'INVOICE_CREATED',
  INVOICE_PAID = 'INVOICE_PAID',
}

export const RevRecTriggerEnumZ = z.nativeEnum(RevRecTriggerEnum);

export const REV_REC_TRIGGER_DISPLAY: {
  [key in RevRecTriggerEnum]: string;
} = {
  INVOICE_CREATED: 'Invoice created',
  INVOICE_PAID: 'Invoice Paid',
};

export const RevRecSettingsResponse = z.object({
  enabled: z.boolean(),
  trigger: RevRecTriggerEnumZ,
});

export type IRevRecSettingsResponse = z.infer<typeof RevRecSettingsResponse>;

export const RevRecWaterfallRequest = z.object({
  startPeriod: z.string(),
  endPeriod: z.string(),
  recognizedAsOfPeriod: z.string(),
  currency: z.string().default('USD').optional(),
});

export type IRevRecWaterfallRequest = z.infer<typeof RevRecWaterfallRequest>;

export const RevenueRecognitionPeriod = z.object({
  period: z.string(),
  recognizedAmount: z.number(),
  deferredAmount: z.number(),
});
export type IRevenueRecognitionPeriod = z.infer<
  typeof RevenueRecognitionPeriod
>;

export const RevenueRecognitionSchedule = z.object({
  bookingPeriod: z.string(),
  totalAmount: z.number(),
  recognizedAsOf: RevenueRecognitionPeriod,
  periods: RevenueRecognitionPeriod.array(),
});
export type IRevenueRecognitionSchedule = z.infer<
  typeof RevenueRecognitionSchedule
>;

export const RevenueRecognitionTotals = z.object({
  amount: z.number(),
  recognizedAmount: z.number(),
  deferredAmount: z.number(),
  periods: RevenueRecognitionPeriod.array(),
});
export type IRevenueRecognitionTotals = z.infer<
  typeof RevenueRecognitionTotals
>;

export const RevenueRecognitionResponse = z.object({
  startPeriod: z.string(),
  endPeriod: z.string(),
  recognizedAsOfPeriod: z.string(),
  currency: z.string(),
  recognitionSchedule: RevenueRecognitionSchedule.array(),
  totals: RevenueRecognitionTotals,
});
export type IRevenueRecognitionResponse = z.infer<
  typeof RevenueRecognitionResponse
>;
