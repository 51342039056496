import { useQueryClient } from '@tanstack/react-query';
import { FunctionComponent as FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { handleApiErrorToast } from '~api/axios';
import {
  doPostConfigurationEsign,
  doPutConfigurationEsign,
  settingsQueryKeys,
} from '~app/api/settingsService';
import { ROUTES } from '~app/constants';
import { useQuery } from '~app/hooks';
import { useToast } from '~app/services/toast';
import { tempDocusignState } from '~app/store/global.store';
import AppLoading from '~components/App/AppLoading';

interface EsignCallbackProps {}

const EsignCallback: FC<EsignCallbackProps> = () => {
  const query = useQuery();

  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { addToast } = useToast();

  const queryClient = useQueryClient();

  // load saved docusign form in local state
  const [tempDocuSign, setTempDocuSign] = useRecoilState(tempDocusignState);
  const code = query.get('code');

  useEffect(() => {
    const load = async () => {
      setLoading(true);
      try {
        if (!code) {
          throw new Error('Sorry, docusign returned error.');
        }

        if (!tempDocuSign) {
          throw new Error('Sorry, something went wrong.');
        }

        const saveFunc =
          tempDocuSign.method === 'post'
            ? doPostConfigurationEsign
            : doPutConfigurationEsign;
        await saveFunc(tempDocuSign.data);
        addToast({
          summary: `DocuSign Connected`,
          detail: `DocuSign has successfully been connected.`,
          severity: 'info',
        });
        queryClient.invalidateQueries(settingsQueryKeys.esignConfig());
        setTempDocuSign(null);
      } catch (err) {
        handleApiErrorToast(err);
      }
      setLoading(false);

      navigate(ROUTES.SETTINGS_ESIGN, {});
    };

    load();
  }, []);

  if (loading) {
    return <AppLoading loadingText="Validating docusign connection..." />;
  }

  return null;
};

export default EsignCallback;
