import { NumberInputFieldProps, NumberInputProps } from '@chakra-ui/react';
import React, { FunctionComponent, useState } from 'react';

import {
  MAdditionNumberInput,
  MCustomNumberInput,
  MFlex,
  MGrid,
  MText,
} from '~app/components/Monetize';

export const getQuantityText = (
  value: string | number,
  isNewSubscription: boolean,
  color: string,
  previousQuantity: number = 0,
) => {
  const valueAsNum = Number(value);
  const quantityDifference = `(${
    valueAsNum > previousQuantity ? '+' : '-'
  }${Math.abs(valueAsNum - previousQuantity)})`;

  const showDifference =
    !isNewSubscription && !!previousQuantity && valueAsNum !== previousQuantity;

  return (
    <MFlex justifyContent="center">
      <MText fontWeight="600" color={showDifference ? color : 'tPurple.base'}>
        {value}
      </MText>
      {showDifference && (
        <MText ml={1} lineHeight={1.4}>
          {quantityDifference}
        </MText>
      )}
    </MFlex>
  );
};

interface SubscriptionQuantityCellProps extends NumberInputProps {
  value: string;
  inputName: string;
  dataTestId: string;
  isLoading: boolean;
  isDisabled: boolean;
  isNewSubscription: boolean;
  previousQuantity?: number;
  handleBlur: (add: boolean) => void;
}

export const SubscriptionQuantityCell: FunctionComponent<SubscriptionQuantityCellProps> =
  React.forwardRef<any, SubscriptionQuantityCellProps>(
    (
      props: SubscriptionQuantityCellProps,
      ref: any,
    ): React.ReactElement | null => {
      const {
        value,
        inputName,
        dataTestId,
        isLoading,
        isDisabled,
        isNewSubscription,
        previousQuantity,
        handleBlur,
        ...rest
      } = props;
      const [editMode, setEditMode] = useState<boolean>(isNewSubscription);
      const [focused, setFocused] = useState<boolean>(isNewSubscription);
      const isQuantityChanged =
        previousQuantity && Number(value) !== previousQuantity;
      const isHoverDisabled = !isNewSubscription && isDisabled;

      const quoteColors =
        !isNewSubscription && isQuantityChanged
          ? {
              color: 'tBlue.magenta',
              bgColor: 'tWhite.base',
              borderColor: 'tBlue.magenta',
            }
          : {
              color: 'tGray.darkGrayPurple',
              bgColor: 'transparent',
              borderColor: 'tBlue.lightShade',
            };

      const styleProps: NumberInputFieldProps = {
        maxWidth: 16,
        textAlign: 'center',
        ...quoteColors,
      };

      const handleInputBlur = (add?: boolean) => {
        if (!isNewSubscription) {
          setEditMode(false);
          setFocused(false);
        }
        handleBlur && handleBlur(add || false);
      };

      const renderNumberInput = () => {
        return isNewSubscription ? (
          <MCustomNumberInput
            styleProps={styleProps}
            value={value}
            isDisabled={isDisabled}
            onBlur={() => handleInputBlur()}
            variant="quantity"
            {...rest}
          />
        ) : (
          <MAdditionNumberInput
            styleProps={styleProps}
            quoteColors={isNewSubscription ? {} : quoteColors}
            value={value}
            isDisabled={isDisabled}
            handleInputBlur={handleInputBlur}
            variant="quantity"
            previousQuantity={previousQuantity}
            {...rest}
          />
        );
      };

      return (
        <MGrid
          onMouseEnter={() => !isHoverDisabled && setEditMode(true)}
          onMouseLeave={() => !isHoverDisabled && setEditMode(false)}
          onMouseDown={() => !isHoverDisabled && setFocused(true)}
          justifyContent="center"
          alignItems="center"
          data-testid={dataTestId}
        >
          {focused || (!focused && editMode)
            ? renderNumberInput()
            : getQuantityText(
                value,
                isNewSubscription,
                quoteColors.color,
                previousQuantity,
              )}
        </MGrid>
      );
    },
  );
