import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const baseStyle = defineStyle({
  color: 'tIndigo.base',
  _focus: {
    textDecoration: 'underline',
  },
  _active: {
    textDecoration: 'underline',
  },
});

export const linkTheme = defineStyleConfig({
  baseStyle,
});

export default linkTheme;
