import {
  Icon,
  Table,
  TableBodyProps,
  TableCellProps,
  TableColumnHeaderProps,
  TableContainer,
  TableContainerProps,
  TableHeadProps,
  TableProps,
  TableRowProps,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import {
  RowSelectionState,
  SortingState,
  TableOptions,
  TableState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useVirtualizer } from '@tanstack/react-virtual';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';
import { useNonInitialEffect } from '../../../hooks/useNonInitialEffect';
import { MBox, MFlex } from '../chakra';
import { DataTableFilterRef, DataTableRef } from './DataTableTypes';
import { DateTableHeaderFilter } from './dataTableFilters/DateTableHeaderFilter';
import { TableData, prepareTableData } from './tableUtilsNew';

// Default function to get row id
const getRowIdFn = <T extends object>(row: any) => row.id;

interface DataTableProps {
  table?: TableProps;
  head?: TableHeadProps;
  headRow?: TableRowProps;
  headCell?: TableColumnHeaderProps;
  body?: TableBodyProps;
  bodyRow?: TableRowProps;
  cell?: TableCellProps;
}

interface DataTableNewProps<T> {
  // TODO: should we break out just options we support instead of allowing full access
  options?: Omit<
    TableOptions<T>,
    'columns' | 'data' | 'getCoreRowModel' | 'getRowId' | 'state'
  >;
  /**
   * Lock selection if true
   * Can be used while processing data etc..
   */
  selectionDisabled?: boolean;
  /** Defaults to row.id */
  columns: TableOptions<T>['columns'];
  data: T[];
  /** Enum conversion maps for filters */
  filterDataDisplayMap?: Parameters<typeof prepareTableData>[1];
  /** Keys to allow global text search against */
  textSearchKeys?: Parameters<typeof prepareTableData>[2];
  pinnedLeftColumns?: string[];
  /**
   * Extra props to pass to table
   */
  tableProps?: DataTableProps;
  state?: Partial<TableState>;
  initialSortingState?: SortingState;
  /**
   * Set to true for tables that do not have server side pagination/sorting/filtering
   * @default false
   */
  clientControlled?: boolean;
  extraProps?: {
    containerProps?: TableContainerProps;
    tableProps?: TableProps;
    tableHeadProps?: TableHeadProps;
  };
  getRowId?: (row: T) => string;
  onRowSelectionChange?: (rows: T[]) => void;
  onVisibleRowChange?: (rows: T[]) => void;
}

// FIXME: figure out how to use generic props with forwardRef
export const DataTableNew = forwardRef<DataTableRef, DataTableNewProps<any>>(
  (
    {
      options = {},
      selectionDisabled,
      columns,
      data: initialData,
      pinnedLeftColumns,
      filterDataDisplayMap = {},
      textSearchKeys = [],
      tableProps = {},
      /**
       * Anything provided here overrides the internal state of the table
       */
      state,
      initialSortingState = [],
      clientControlled = false,
      extraProps = {},
      getRowId = getRowIdFn,
      onRowSelectionChange,
      onVisibleRowChange,
    },
    ref,
  ) => {
    const tableContainerRef = useRef<HTMLDivElement>(null);
    const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
    const [sorting, setSorting] = useState<SortingState>(initialSortingState);
    const filterRefs = useRef(new Map<number, DataTableFilterRef>());

    const { data, filterValues } = useMemo(() => {
      return prepareTableData(
        initialData,
        filterDataDisplayMap,
        textSearchKeys,
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialData]);

    const table = useReactTable<TableData<any>>({
      ...options,
      columns,
      // data: rows as any[],
      data,
      manualFiltering: !clientControlled,
      manualSorting: !clientControlled,
      manualPagination: !clientControlled,
      columnResizeMode: 'onEnd',
      getRowId,
      getCoreRowModel: getCoreRowModel(),
      // getExpandedRowModel: getExpandedRowModel(),
      // getFacetedMinMaxValues: getFacetedMinMaxValues(),
      // FIXME: we could use columnFaceting to have reactTable collect all available data for us instead of doing it by hand! https://tanstack.com/table/latest/docs/guide/column-faceting
      // FIXME: refactor to sue these and see if we can get away with removing `prepareTableData`
      // TODO: for server controlled, we can use these to set known values for all enums (could potentially use for clientControlled as well?)
      // getFacetedRowModel: getFacetedRowModel(),
      // getFacetedUniqueValues: getFacetedUniqueValues(),
      // getGroupedRowModel: getGroupedRowModel(),
      getFilteredRowModel: clientControlled ? getFilteredRowModel() : undefined,
      // getPaginationRowModel: clientControlled ? getPaginationRowModel() : undefined, // TODO: not needed for serverControlled and on client controlled we want to use virtual scrolling

      // SORT
      enableMultiSort: false,
      enableSorting: true,
      onRowSelectionChange: setRowSelection,
      getSortedRowModel: clientControlled ? getSortedRowModel() : undefined,
      onSortingChange: setSorting,
      /**
       * Global properties that can be access from anywhere using table.options.meta
       * Update declaration for any new properties: src/global.d.ts
       */
      meta: {
        selectionDisabled,
        filterValues,
        selectedFilters: new Map<string, Set<string>>(),
      },
      /**
       * Anything provided here overrides the internal state of the table
       */
      state: {
        sorting,
        rowSelection,
        columnPinning: {
          left: pinnedLeftColumns,
        },
        ...state,
      },
    });

    const { rows } = table.getRowModel();

    const rowVirtualizer = useVirtualizer({
      count: rows.length,
      // estimate row height for accurate scrollbar dragging
      estimateSize: () => 62,
      getScrollElement: () => tableContainerRef.current,
      // measure dynamic row height, except in firefox because it measures table border height incorrectly
      measureElement:
        typeof window !== 'undefined' &&
        navigator.userAgent.indexOf('Firefox') === -1
          ? (element) => element?.getBoundingClientRect().height
          : undefined,
      overscan: 15,
    });

    useImperativeHandle(
      ref,
      (): DataTableRef => ({
        resetFilters: () => {
          filterRefs.current.forEach((ref) => ref.resetFilters());
        },
        setFilterValue: (options) => {
          filterRefs.current.forEach((ref) => ref.setFilterValue(options));
        },
        table,
      }),
    );

    useNonInitialEffect(() => {
      if (onRowSelectionChange) {
        onRowSelectionChange(
          table.getSelectedRowModel().rows.map((row) => row.original),
        );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rowSelection, table]);

    useEffect(() => {
      if (onVisibleRowChange) {
        onVisibleRowChange(rows.map((row) => row.original));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rows]);

    /**
     * If data changes and selected rows are no longer included in dataset
     * then we need to ensure they are removed from the selection or else the
     * table state does not work as expected since items are selected that do not exist.
     */
    useNonInitialEffect(() => {
      const allRowIds = new Set(data.map(getRowId));
      setRowSelection((prev) => {
        const newSelection: RowSelectionState = {};
        Object.keys(prev).forEach((key) => {
          if (allRowIds.has(key)) {
            newSelection[key] = prev[key];
          }
        });
        return newSelection;
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    return (
      <TableContainer
        ref={tableContainerRef}
        w="100%"
        overflowY="auto"
        {...extraProps.containerProps}
      >
        <Table
          size="sm"
          variant="simple"
          display="grid"
          {...tableProps.table}
          {...extraProps.tableProps}
        >
          <Thead
            position="sticky"
            top="0"
            zIndex={4}
            bg="white"
            {...extraProps.tableHeadProps}
          >
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr
                key={headerGroup.id}
                border="none"
                {...tableProps.headRow}
                _hover={{
                  backgroundColor: 'inherit',
                }}
                display="flex"
                width="100%"
              >
                {headerGroup.headers.map((header, i) => {
                  const pinned = header.column.getIsPinned();
                  const sortable = header.column.getCanSort();
                  const sorted = header.column.getIsSorted();
                  const canSort = header.column.getCanSort();
                  const canResize = header.column.getCanResize();
                  const isResizing = header.column.getIsResizing();
                  const isGroup = header.subHeaders.length > 0;
                  const hasColSpan = header.colSpan && header.colSpan > 1;
                  const hasBorder = !isGroup || !header.isPlaceholder;
                  return (
                    <Th
                      key={header.id}
                      colSpan={header.colSpan}
                      display="flex"
                      width={header.getSize()}
                      border={
                        hasBorder
                          ? '1px solid var(--chakra-colors-tGray-back) !important'
                          : 'none'
                      }
                      backgroundColor={pinned ? 'white' : undefined}
                      zIndex={pinned ? 1 : undefined}
                      position={pinned ? 'sticky' : undefined}
                      left={pinned ? '0' : undefined}
                      pt={tableProps?.headCell?.pt ?? '0 !important'}
                      pb={tableProps?.headCell?.pb ?? '0 !important'}
                      pr={tableProps?.headCell?.pr ?? '0 !important'}
                      pl={tableProps?.headCell?.pl ?? '0 !important'}
                      {...tableProps.headCell}
                    >
                      <MBox position="relative" w="100%" h="100%">
                        {header.isPlaceholder ? null : (
                          <MFlex
                            p="0.4rem"
                            alignItems="center"
                            justifyContent="space-between"
                            w="100%"
                          >
                            <MFlex
                              fontSize={isGroup ? '' : ''}
                              cursor={sortable ? 'pointer' : 'default'}
                              justifyContent={hasColSpan ? 'center' : undefined}
                              _hover={{
                                textDecoration: sortable ? 'underline' : 'none',
                              }}
                              onClick={header.column.getToggleSortingHandler()}
                            >
                              <MBox as="span">
                                {flexRender(
                                  header.column.columnDef.header,
                                  header.getContext(),
                                )}
                              </MBox>
                              {canSort && (
                                <MBox minW={5}>
                                  {sorted === 'asc' && (
                                    <Icon
                                      as={FaCaretUp}
                                      mb="-3px"
                                      ml={1}
                                      color="tPurple.dark"
                                    />
                                  )}
                                  {sorted === 'desc' && (
                                    <Icon
                                      as={FaCaretDown}
                                      mb="-3px"
                                      ml={1}
                                      color="tPurple.dark"
                                    />
                                  )}
                                </MBox>
                              )}
                            </MFlex>
                            {header.column.getCanFilter() && (
                              <DateTableHeaderFilter
                                ref={(headerRed) =>
                                  headerRed &&
                                  filterRefs.current.set(i, headerRed)
                                }
                                column={header.column}
                                table={table}
                              />
                            )}
                          </MFlex>
                        )}
                        {hasBorder && canResize && (
                          <MBox
                            position="absolute"
                            top="0"
                            right="0"
                            height="100%"
                            width="4px"
                            bg="tIndigo.base"
                            opacity={isResizing ? 1 : 0}
                            cursor="col-resize"
                            userSelect="none"
                            _hover={{
                              opacity: 1,
                            }}
                            transform={
                              isResizing
                                ? `translateX(${
                                    table.getState().columnSizingInfo
                                      .deltaOffset ?? 0
                                  }px)`
                                : undefined
                            }
                            onDoubleClick={() => header.column.resetSize()}
                            onMouseDown={header.getResizeHandler()}
                            onTouchStart={header.getResizeHandler()}
                          ></MBox>
                        )}
                      </MBox>
                    </Th>
                  );
                })}
              </Tr>
            ))}
          </Thead>
          <Tbody
            display="grid"
            height={`${rowVirtualizer.getTotalSize()}px`}
            position="relative"
            {...tableProps.body}
          >
            {rows.length === 0 && (
              <>
                <Tr>
                  <Td colSpan={columns.length} textAlign="center">
                    No data
                  </Td>
                </Tr>
              </>
            )}
            {rowVirtualizer.getVirtualItems().map((virtualRow) => {
              const row = rows[virtualRow.index];
              const isRowSelected = row.getIsSelected();
              return (
                <Tr
                  key={row.id}
                  data-index={virtualRow.index}
                  ref={(node) => rowVirtualizer.measureElement(node)}
                  _hover={{
                    backgroundColor: 'tWhite.titanWhite',
                  }}
                  aria-selected={isRowSelected}
                  {...tableProps.bodyRow}
                  display="flex"
                  position="absolute"
                  transform={`translateY(${virtualRow.start}px)`}
                  width="100%"
                >
                  {row.getVisibleCells().map((cell) => {
                    const pinned = cell.column.getIsPinned();
                    let backgroundColor = pinned ? 'white' : undefined;
                    if (isRowSelected && pinned) {
                      backgroundColor = 'tWhite.titanWhite';
                    }
                    return (
                      <Td
                        key={cell.id}
                        position={pinned ? 'sticky' : undefined}
                        left={pinned ? '0' : undefined}
                        backgroundColor={backgroundColor}
                        display="flex"
                        width={cell.column.getSize()}
                        whiteSpace="normal"
                        {...tableProps.cell}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
    );
  },
);
