import {
  MAccordion,
  MAccordionCustomButton,
  MAccordionItem,
  MAccordionPanel,
  MStack,
} from '~app/components/Monetize';

import { IQuoteSettingsContractRenewalTerms } from '~app/types/quoteSettingsTypes';
import { ContractRenewalTermsForm } from './ContractRenewalTermsForm';

interface DefaultContractRenewalTermsAccordionProps {
  contractRenewalTerms: IQuoteSettingsContractRenewalTerms;
  onSubmit: (data: IQuoteSettingsContractRenewalTerms) => Promise<void>;
}

export const DefaultContractRenewalTermsAccordion = ({
  contractRenewalTerms,
  onSubmit,
}: DefaultContractRenewalTermsAccordionProps) => {
  return (
    <MStack w="full" data-testid="default-msa" mt={4}>
      <MAccordion allowMultiple reduceMotion w="full" mt={4}>
        <MAccordionItem
          borderWidth="1px"
          borderRadius="md"
          borderColor="tGray.back"
          data-testid="default-msa-accordion"
        >
          {({ isExpanded }) => (
            <>
              <MAccordionCustomButton
                isExpanded={isExpanded}
                label="Contract End Action"
              />
              <MAccordionPanel mx={5} mt="2">
                <ContractRenewalTermsForm
                  contractRenewalTerms={contractRenewalTerms}
                  onSubmit={onSubmit}
                />
              </MAccordionPanel>
            </>
          )}
        </MAccordionItem>
      </MAccordion>
    </MStack>
  );
};
