import { FunctionComponent as FC } from 'react';
import { Link } from 'react-router-dom';
import { useGetDashboardSalesCountQuotes } from '~app/api/dashboardsService';
import {
  MBox,
  MChart,
  MFlex,
  MLink,
  MSkeleton,
  MText,
} from '~app/components/Monetize';
import { ROUTES } from '~app/constants';
import { QuoteStatusEnumDisplay } from '~app/constants/quotes';
import { AccessDeniedSmall } from '~app/routes/AccessDeniedSmall';
import {
  ISalesCountQuotesCount,
  QuoteStatusEnum,
  SalesCreatedByFilter,
} from '~app/types';
import { BASE_RADICAL_CHART_OPTIONS } from '~app/utils/charts';
import './styles.scss';

export const QuoteStatusEnumDisplayCustom: {
  [key in QuoteStatusEnum]: string;
} = {
  ...QuoteStatusEnumDisplay,
  REVIEW: 'In Review',
};

const QuotesChart: FC<{
  createdByFilter: SalesCreatedByFilter;
  currency: string;
}> = ({ createdByFilter, currency }) => {
  const { isLoading, data, error } = useGetDashboardSalesCountQuotes(
    {
      currency,
      query: createdByFilter,
    },
    {
      select: (respData) => {
        // Select data for only these statuses
        const allowedQuoteStatus = [
          QuoteStatusEnum.REVIEW,
          QuoteStatusEnum.APPROVED,
          QuoteStatusEnum.SENT,
        ];
        const quoteCounts: ISalesCountQuotesCount[] = allowedQuoteStatus.map(
          (status) => {
            const quoteS = respData.quoteCounts.find(
              (qc) => qc.status === status,
            );
            return { status: status as string, count: quoteS?.count || 0 };
          },
        ) as ISalesCountQuotesCount[];
        return {
          total: quoteCounts.reduce((acc, qc) => acc + qc.count, 0),
          quoteCounts,
        };
      },
    },
  );

  const chartOptions = {
    ...BASE_RADICAL_CHART_OPTIONS,
    legend: {
      ...BASE_RADICAL_CHART_OPTIONS.legend,
      formatter: function (seriesName: string, opts: any) {
        return (
          QuoteStatusEnumDisplayCustom[seriesName as QuoteStatusEnum] +
          ':  ' +
          data?.quoteCounts[opts.seriesIndex].count
        );
      },
    },
    labels: data?.quoteCounts.map((quoteCount) => quoteCount.status),
  };
  const series = data?.quoteCounts.map((quoteCount) =>
    data.total > 0 ? (quoteCount.count / data.total) * 100 : 0,
  );

  return (
    <MBox h="400px">
      <MFlex alignItems="center">
        <MText fontWeight="bold" fontSize="2xl">
          Quotes
        </MText>
        <MLink
          fontSize="sm"
          as={Link}
          to={ROUTES.SALES_QUOTES_ROUTE}
          cursor="pointer"
          ml="3"
          mt="1"
        >
          View Quotes
        </MLink>
      </MFlex>

      <MFlex justify="center">
        <MBox w="400px">
          {isLoading && <MSkeleton w="100%" h="20px" />}
          {!error && !isLoading && (
            <MChart
              options={chartOptions}
              series={series}
              type="radialBar"
              height="350px"
              className="sales-quotes-chart"
            />
          )}
          {!isLoading && !!error && (
            <AccessDeniedSmall containerProps={{ mt: '4' }} />
          )}
        </MBox>
      </MFlex>
    </MBox>
  );
};

export { QuotesChart };
