import { QueryClient } from '@tanstack/react-query';
import clamp from 'lodash/clamp';
import { useState } from 'react';
import { handleApiErrorToast } from '~app/api/axios';
import { CPQ_SERVICE_API, doGetQuote } from '~app/api/cpqService';
import { doGetOfferingRate } from '~app/api/productCatalogService';
import { doGetTenantUser } from '~app/api/usersService';
import {
  CUSTOM_SELECT_SUBTITLE_TYPE,
  OPTION_TYPE_FIELD,
} from '~app/constants/customSelect';
import { OFFERING_TYPES_LABEL_DISPLAY } from '~app/constants/offerings';
import { DEFAULT_QUOTE_COLORS } from '~app/constants/quotes';
import { useAuth } from '~app/services/auth0';
import { logger } from '~app/services/logger';
import { groupBy } from '~app/utils/misc';
import {
  IOfferingRes,
  IQuoteOfferingReqSchema,
  IQuoteOfferingRespSchema,
  IQuoteRespSchema,
  IRateResSchema,
  IUser,
  Maybe,
  QuoteTypeEnum,
  RateTypeEnum,
} from '~types';

export interface CreatedByUserDataTypes {
  loading: boolean;
  createdByUser: IUser | null;
  fetchCreatedByUser: (userId: string) => Promise<IUser | null>;
}

/**
 * This is no longer used anywhere in the application.
 * Prefer to use quote.ownerName instead.
 */
export const useCreatedByUser = (): CreatedByUserDataTypes => {
  const [loading, setLoading] = useState<boolean>(false);
  const [createdByUser, setCreatedByUser] = useState<IUser | null>(null);
  const { tenantId } = useAuth();

  const fetchCreatedByUser = async (userId: string): Promise<IUser | null> => {
    setLoading(true);
    try {
      const user = await doGetTenantUser(tenantId, userId);

      setCreatedByUser(user);
      return user;
    } catch (error) {
      handleApiErrorToast(error);
      return null;
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    createdByUser,
    fetchCreatedByUser,
  };
};

const getTooltipLabel = (quoteType: string): string => {
  switch (quoteType) {
    case QuoteTypeEnum.AMENDMENT:
      return 'Amendments allow you to update quotes after a quote has been processed.';
    case QuoteTypeEnum.RENEWAL:
      return 'Renewals allow you to take an existing quote and create an entirely new quote.';
    default:
      return '';
  }
};

export const getQuoteTagColor = (quoteType: QuoteTypeEnum) => {
  switch (quoteType) {
    case 'AMENDMENT':
    case 'RENEWAL':
      return {
        color: 'tPurple.safety',
        bgColor: 'tPurple.inputBox',
      };
    default:
      return DEFAULT_QUOTE_COLORS;
  }
};

export const getQuoteDescription = (accountName?: string) => {
  if (accountName) {
    return `Quote for ${accountName}`;
  }

  return 'Quote ';
};

/**
 * Since quote has many places that do not use ReactQuery, this is a helper method
 * for updating the quote in the query cache.
 */
export const updateQuoteQueryCache = (
  queryClient: QueryClient,
  updatedQuote: IQuoteRespSchema,
) => {
  const queryKey = CPQ_SERVICE_API.cpqServiceQuotes.byId?.queryKey?.(
    updatedQuote.id,
  );
  if (queryKey) {
    queryClient.setQueryData(queryKey, updatedQuote);
  }
};

/**
 * Returns offeringList based on inactive offering
 */
export const getOfferingList = (
  allOfferings: IOfferingRes[],
  inactiveOffering?: IOfferingRes,
) => {
  if (inactiveOffering) {
    const isInactiveOfferingActuallyActive = allOfferings.find(
      ({ id }) => id === inactiveOffering.id,
    );
    if (!isInactiveOfferingActuallyActive) {
      return [
        ...allOfferings,
        {
          name: 'Inactive',
          [OPTION_TYPE_FIELD]: CUSTOM_SELECT_SUBTITLE_TYPE,
        },
        {
          ...inactiveOffering,
          rightLabel: OFFERING_TYPES_LABEL_DISPLAY[inactiveOffering.type],
        },
      ];
    }

    return allOfferings;
  }

  return allOfferings;
};

/**
 * Get all Quote Offerings from a quote and create a payload that can be used to create the same offerings for a new quote
 *
 * This wil ensure that any account specific rates that are from other accounts are replaced with the CATALOG rate
 * No discounts are carried over from the original QuoteItems
 * The quantity will default to the rate min bound
 */
export const getQuoteOfferingsToImport = async (
  quoteIdToImport: string,
  accountId: string,
): Promise<IQuoteOfferingReqSchema[]> => {
  const quoteToImport = await doGetQuote(quoteIdToImport);
  if (!quoteToImport) {
    throw new Error('Quote not found');
  }

  // Helper function to ensure that a failure to fetch a rate does not result in an exception
  async function fetchRate(rateId: string) {
    try {
      return await doGetOfferingRate(rateId);
    } catch (ex) {
      logger.warn('Failed to get rate', ex);
    }
  }

  // Check all rates and make sure that any account specific rates from different accounts are replaced with the CATALOG rate
  // Group by productId for easy lookup
  let ratesById = groupBy(
    (
      await Promise.all(
        Array.from(
          new Set(quoteToImport.quoteOfferings.map(({ rateId }) => rateId)),
        ).map((rateId) => fetchRate(rateId)),
      )
    ).filter(Boolean) as IRateResSchema[],
    'id',
  );

  // Fallback to CATALOG rate if the provided quote is using an account specific rate from a different account
  const { quoteOfferings, parentRates } = quoteToImport.quoteOfferings.reduce(
    (
      output: {
        quoteOfferings: IQuoteOfferingRespSchema[];
        parentRates: Set<string>;
      },
      quoteOffering,
    ) => {
      const rate = ratesById[quoteOffering.rateId];
      if (
        rate &&
        rate.rateType === RateTypeEnum.ACCOUNT &&
        rate.parentRateId &&
        rate.accountId !== accountId
      ) {
        output.quoteOfferings.push({
          ...quoteOffering,
          rateId: rate.parentRateId,
        });
        output.parentRates.add(rate.parentRateId);
      } else {
        output.quoteOfferings.push(quoteOffering);
      }
      return output;
    },
    { quoteOfferings: [], parentRates: new Set<string>() },
  );

  // If CATALOG rates were replaced with actual rates, fetch these rates so proper min/max can be set
  ratesById = {
    ...ratesById,
    ...groupBy(
      (
        await Promise.all(
          Array.from(parentRates).map((rateId) => fetchRate(rateId)),
        )
      ).filter(Boolean) as IRateResSchema[],
      'id',
    ),
  };

  // Get payload for new quoteOfferings - quoteItem quantity will be set to the minimum bound allowed by the rate
  return getQuoteOfferingCreatePayloadFromQuoteOffering(
    quoteOfferings,
    ratesById,
  );
};

/**
 * Convert existing QuoteOfferings into a request payload that can be used to create the same offerings for a new quote
 */
export const getQuoteOfferingCreatePayloadFromQuoteOffering = (
  existingOfferings: IQuoteOfferingRespSchema[],
  ratesById: Record<string, IRateResSchema>,
): IQuoteOfferingReqSchema[] => {
  if (!existingOfferings || !existingOfferings.length) {
    return [];
  }
  return existingOfferings
    .filter((offering) => !offering.parentQuoteOfferingId)
    .map((offering): IQuoteOfferingReqSchema => {
      const productPriceRanges =
        ratesById[offering.rateId]?.productPriceRanges || {};
      return {
        offeringId: offering.offeringId,
        rateId: offering.rateId,
        subscriptionId: offering.subscriptionId,
        description: offering.description,
        discountIds: null,
        schedule: null,
        items: offering.items.map((item) => ({
          customId: item.customId,
          productId: item.productId,
          sku: item.sku,
          description: item.description,
          // Ensure that the quoteOffering quantity is within the rate's min/max range
          quantity: productPriceRanges[item.productId]
            ? clamp(
                0,
                productPriceRanges[item.productId].min,
                productPriceRanges[item.productId].max ?? Infinity,
              )
            : item.quantity,
          customDiscountType: null,
          customDiscountAmountOrPercent: null,
          customDiscountName: null,
          customPrice: null,
          options: item.options,
          isSelected: item.isSelected,
          productName: item.productName,
          productType: item.productType,
        })),
      };
    });
};

/**
 * Based on quote, defaultContactId, esignConfiguration returns esignIds
 */
export const getDefaultEsignIds = ({
  quote,
  defaultContactId,
  internalEsignContactId,
  isTenantEsignConfigured,
}: {
  quote: IQuoteRespSchema;
  defaultContactId?: Maybe<string>;
  internalEsignContactId?: Maybe<string>;
  isTenantEsignConfigured: boolean;
}) => {
  if (isTenantEsignConfigured && !quote.contacts.esign.length) {
    if (defaultContactId && internalEsignContactId) {
      return [defaultContactId, internalEsignContactId];
    } else if (defaultContactId) {
      return [defaultContactId];
    } else if (internalEsignContactId) {
      return [internalEsignContactId];
    }
  }

  return quote.contacts.esign.map(({ id }) => id);
};

export const getOfferingNameById = (id: string, offerings: IOfferingRes[]) => {
  return offerings?.find((offering) => offering.id === id)?.name ?? '';
};
