import { Text, TextProps } from '@chakra-ui/react';
import { findAll } from './utils';

/**
 * Highlights all occurrences of search terms (searchText) within a string (textToHighlight).
 * This function returns an array of strings and <span>s (wrapping highlighted words).
 */

interface HighlighterProps extends TextProps {
  searchWords: string[];
  textToHighlight: string;
  activeIndex?: number;
  autoEscape?: boolean;
  caseSensitive?: boolean;
}

export function Highlighter({
  activeIndex = -1,
  autoEscape = true,
  caseSensitive = false,
  searchWords,
  textToHighlight,
  ...rest
}: HighlighterProps) {
  const chunks = findAll({
    autoEscape,
    caseSensitive,
    searchWords,
    textToHighlight,
  });

  if (!textToHighlight) return null;

  return (
    <Text color="tPurple.base" fontSize="sm" {...rest}>
      {chunks.map((chunk, index) => {
        const text = textToHighlight.substring(chunk.start, chunk.end);

        if (chunk.highlight) {
          return (
            <Text key={index} bg="tYellow.base" as="span">
              {text}
            </Text>
          );
        }
        return text;
      })}
    </Text>
  );
}
