import { TagProps } from '@chakra-ui/react';
import { MTag } from '~app/components/Monetize';
import { getVariantByStatus } from '~app/utils';

export const MEntityStatusBadge = ({
  status,
  ...rest
}: {
  status?: string;
} & TagProps) => {
  if (!status) {
    return null;
  }

  const variant = getVariantByStatus(status);

  return (
    <MTag ml=".5rem" variant={variant} {...rest}>
      {status}
    </MTag>
  );
};
