import { Container, useDisclosure } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import {
  MBox,
  MButton,
  MFlex,
  MPageHeader,
  MPageLoader,
  MText,
} from '../../../components/Monetize';
import { DataTableNew } from '../../../components/Monetize/DataTableNew/DataTableNew';
import { DataTableRef } from '../../../components/Monetize/DataTableNew/DataTableTypes';
import { SELECTION_COL_KEY } from '../../../components/Monetize/DataTableNew/dataTableNewRenderers';
import { BILL_GROUP_STATUS_DISPLAY } from '../../../constants/billGroups';
import { useFlags } from '../../../services/launchDarkly';
import {
  ADDRESS_SOURCE_DISPLAY,
  InvoiceSummaryResp,
  NET_TERMS_DISPLAY,
} from '../../../types';
import { formatInteger, pluralize } from '../../../utils';
import { arrayToObject } from '../../../utils/misc';
import { BillingPageLoadingBar } from '../shared/BillingPageLoadingBar';
import { MassCreditModal } from './MassCreditModal/MassCreditModal';
import { InvoiceManagementMassEmailModal } from './MassEmailModal/InvoiceManagementMassEmailModal';
import { useInvoiceManagement } from './useInvoiceManagement';

const INITIAL_SORTING_STATE = [{ id: 'dueDate', desc: false }];

const pinnedLeftColumns = [SELECTION_COL_KEY];

export const InvoiceManagement = () => {
  const {
    isOpen: isMassEmailModalOpen,
    onOpen: onMassEmailModalOpen,
    onClose: onMassEmailModalClose,
  } = useDisclosure();
  const {
    isOpen: isMassCreditModalOpen,
    onOpen: onMassCreditModalOpen,
    onClose: onMassCreditModalClose,
  } = useDisclosure();
  const { newApplicationOfPaymentCredit } = useFlags();
  const [selectedRows, setSelectedRows] = useState<InvoiceSummaryResp[]>([]);
  const [visibleRows, setVisibleRows] = useState<InvoiceSummaryResp[]>([]);
  const [isEveryVisibleRowSelected, setIsEveryVisibleRowSelected] =
    useState(false);
  const tableRef = useRef<DataTableRef>(null);
  const isAnyModalOpen = isMassEmailModalOpen || isMassCreditModalOpen;

  const { customFieldQueryResult, invoiceQueryResult, columns, progress } =
    useInvoiceManagement();

  const {
    data: billGroups,
    isLoading,
    isRefetching,
    refetch,
  } = invoiceQueryResult;

  useEffect(() => {
    if (
      selectedRows.length === 0 ||
      selectedRows.length !== visibleRows.length
    ) {
      setIsEveryVisibleRowSelected(false);
      return;
    }
    const selectedRowIds = new Set(
      Object.keys(arrayToObject(selectedRows, 'id')),
    );
    setIsEveryVisibleRowSelected(
      visibleRows.every((row) => selectedRowIds.has(row.id)),
    );
  }, [selectedRows, visibleRows]);

  const { isLoading: isLoadingCustomFields } = customFieldQueryResult;

  const visibleRowCount = visibleRows.length;
  const submitButtonText = isEveryVisibleRowSelected
    ? `${formatInteger(visibleRowCount)} ${pluralize(
        'Invoice',
        visibleRowCount,
      )} Selected`
    : 'Preview Impacted Invoices';

  function handlePreviewImpactedRows() {
    tableRef.current?.resetFilters();
    tableRef.current?.setFilterValue({
      columnKey: SELECTION_COL_KEY,
      value: 'SELECTED',
    });
  }

  return (
    <>
      {isMassEmailModalOpen && (
        <InvoiceManagementMassEmailModal
          selectedRows={selectedRows}
          visibleRows={visibleRows}
          onClose={() => {
            refetch();
            onMassEmailModalClose();
          }}
        />
      )}

      {isMassCreditModalOpen && (
        <MassCreditModal
          selectedRows={selectedRows}
          visibleRows={visibleRows}
          onClose={() => {
            refetch();
            onMassCreditModalClose();
          }}
        />
      )}

      <Container
        maxHeight="calc(100vh - 175px)"
        maxWidth="unset"
        p="0"
        position="relative"
        centerContent
        data-testid="bill-run-page"
      >
        <BillingPageLoadingBar
          isVisible={isLoading || isRefetching}
          progress={progress}
        />
        <MPageHeader
          title="Invoice Management"
          subTitle={<MText>Perform actions on unpaid invoices.</MText>}
        />

        {isLoadingCustomFields || isLoading ? (
          <MPageLoader height="50vh" />
        ) : (
          <>
            <MFlex mb={2} w="100%" justifyContent="space-between">
              <MBox>
                <MButton
                  variant="primary"
                  onClick={() => handlePreviewImpactedRows()}
                  isDisabled={
                    isRefetching ||
                    isEveryVisibleRowSelected ||
                    selectedRows.length === 0
                  }
                  type="button"
                >
                  {submitButtonText}
                </MButton>
                <MButton
                  variant="primary"
                  className="ml-2"
                  onClick={() => onMassEmailModalOpen()}
                  isDisabled={!isEveryVisibleRowSelected || isAnyModalOpen}
                  type="button"
                >
                  Email Selected Invoices
                </MButton>
                {newApplicationOfPaymentCredit && (
                  <MButton
                    variant="primary"
                    className="ml-2"
                    onClick={() => onMassCreditModalOpen()}
                    isDisabled={!isEveryVisibleRowSelected || isAnyModalOpen}
                    type="button"
                  >
                    Credit Selected Invoices
                  </MButton>
                )}
              </MBox>
              <MBox>
                <MButton
                  variant="tertiary"
                  size="sm"
                  onClick={() => tableRef.current?.resetFilters()}
                  type="button"
                >
                  Reset All Filters
                </MButton>
                <MButton
                  variant="tertiary"
                  size="sm"
                  isLoading={isRefetching}
                  onClick={() => refetch()}
                  type="button"
                >
                  Reload
                </MButton>
              </MBox>
            </MFlex>
            <DataTableNew
              ref={tableRef}
              columns={columns}
              data={billGroups || []}
              pinnedLeftColumns={pinnedLeftColumns}
              options={{
                enablePinning: true,
                enableColumnPinning: true,
              }}
              filterDataDisplayMap={{
                status: BILL_GROUP_STATUS_DISPLAY,
                netTerms: NET_TERMS_DISPLAY,
                addressSource: ADDRESS_SOURCE_DISPLAY,
              }}
              clientControlled
              initialSortingState={INITIAL_SORTING_STATE}
              onRowSelectionChange={(rows) => setSelectedRows(rows)}
              onVisibleRowChange={setVisibleRows}
            />
            {billGroups && (
              <MText mt={2} w="100%">
                Showing {formatInteger(visibleRowCount)} of{' '}
                {formatInteger(billGroups.length)} Invoices
              </MText>
            )}
          </>
        )}
      </Container>
    </>
  );
};
