import { SimpleGridProps } from '@chakra-ui/react';

export const HEADER_ROW_CELLS = [
  { label: 'Offering', value: 'Offering', isRequired: true },
  { label: 'Start Date', value: 'Start Date', isRequired: true },
  { label: 'End Date', value: 'End Date', isRequired: false },
  { label: 'Quantity', value: 'Quantity', isRequired: true },
  {
    label: 'Price Per Unit',
    value: 'Price Per Unit',
    isRequired: true,
  },
  { label: 'Amount', value: 'Amount', isRequired: false },
];

export const GRID_TEMPLATE_COLUMNS =
  'minmax(19rem, auto) repeat(2, 11rem) repeat(2, 7rem) minmax(11rem, auto)';

export const GRID_PROPS: SimpleGridProps = {
  columns: 6,
  py: 2,
  gridTemplateColumns: GRID_TEMPLATE_COLUMNS,
  gap: 4,
  alignItems: 'center',
};
