import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { tempRedirectInfoState } from '~app/store/global.store';
import AppLoading from '~components/App/AppLoading';
import { ROUTES } from '~constants';
import { useQuery } from '~hooks';
import { useAuth } from '~services/auth0';

/**
 * Redirect the user to the login/signup page or a specific route using a specific tenant.
 * This route reads from various query params to get the required information
 *
 * The login/signup page are normally initiated from an email.
 * Otherwise the redirect route can handle any links from external systems, such as Salesforce
 * where a specific tenant is required for the link to be valid.
 *
 * Supports the following use-cases:
 * 1. Redirect to the login page if user is not authenticated.
 * 2. Redirect to the sign-up page if the user is not authenticated.
 * 3. Redirect to any arbitrary application url in a specific tenant.
 *  - Example: /redirect?tenantId=tennt_5ryXNCjYjZ7qfRkj&redirectUrl=%2Fquotes%2Fquote_H1V6soldd7cO17J5%2Freview
 *
 */
const Redirector = () => {
  const {
    currentTenant,
    isAuthenticated,
    loginWithRedirect,
    userId: currentUserId,
    getTenantIdsFromAccessToken,
  } = useAuth();
  const setRedirectInfo = useSetRecoilState(tempRedirectInfoState);
  const query = useQuery();
  const navigate = useNavigate();
  const tenantId = query.get('tenantId') || '';
  const userId = query.get('userId') || '';
  const email = query.get('email') || '';
  const redirectUrl = query.get('redirectUrl') || '';
  const screenHint = query.get('screenHint') || '';

  const handleRedirect = async () => {
    if (
      !tenantId ||
      !redirectUrl ||
      // screenHint = login then userId required
      (screenHint === 'login' && !userId)
    ) {
      navigate(ROUTES.ROOT);
      return;
    }

    if (!isAuthenticated) {
      setRedirectInfo({
        tenantId,
        redirectUrl: `/redirect?tenantId=${tenantId}&redirectUrl=${encodeURIComponent(
          redirectUrl || '',
        )}&userId=${userId}`,
      });
      if (screenHint === 'signup') {
        await loginWithRedirect({
          login_hint: email,
          screen_hint: 'signup',
        });
        return;
      }

      await loginWithRedirect({
        login_hint: email,
        screen_hint: 'login',
      });
      return;
    }

    if (currentUserId) {
      if (userId && currentUserId !== userId) {
        navigate(ROUTES.ACCESS_DENIED);
        return;
      }

      if (currentTenant?.id === tenantId) {
        // If same tenant, just navigate
        navigate(redirectUrl);
      } else {
        // If different tenant, switch tenant
        navigate(ROUTES.getSwitchTenantRoute(tenantId!, redirectUrl));
      }
    } else {
      // If auth0 is authenticated but currentUserId is null, then continue onboarding
      navigate(ROUTES.ROOT);
    }
  };

  useEffect(() => {
    handleRedirect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTenant, currentUserId, redirectUrl, tenantId, userId]);

  return <AppLoading />;
};

export default Redirector;
