export const MonetizeNowLogo = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.87351 4.30317L9.8554 4.06875C9.67423 1.79159 7.53648 0 4.96393 0C2.39138 0 0.253632 1.79159 0.0905827 4.06875L0 5.24082V22.0349L9.87351 20.0759V4.30317Z"
      fill="white"
    />
    <path
      d="M11.2683 4.33608V19.8576L16.7938 18.7525V4.36957L16.7757 4.13516C16.6127 1.858 14.4568 0.0664062 11.9024 0.0664062C11.069 0.0664062 10.29 0.250588 9.60156 0.585465C10.5436 1.50638 11.1415 2.71193 11.2502 4.0347L11.2683 4.33608Z"
      fill="white"
    />
    <path
      d="M18.1511 4.33608V18.4846L23.6585 17.3963H23.6948V4.36957L23.6766 4.13516C23.4955 1.84125 21.3577 0.0664062 18.7852 0.0664062C17.9518 0.0664062 17.1728 0.250588 16.4844 0.585465C17.4264 1.50638 18.0243 2.71193 18.133 4.03469L18.1511 4.33608Z"
      fill="white"
    />
  </svg>
);
