import {
  MinCommitConfigTypeEnum,
  PrePaidCreditConfigTypeEnum,
  ProductStatusEnum,
  ProductTypeEnum,
  RecognitionMethodEnum,
} from '~app/types';
import { objectToSelectOptions } from '~app/utils/misc';

export const RECOGNITION_METHOD_DISPLAY: {
  [key in RecognitionMethodEnum]: string;
} = {
  IMMEDIATE: 'Immediate',
  EVENLY_MONTHLY_IN_ADVANCE: 'Evenly (monthly - advance)',
  // THESE DO NOT YET EXIST AND WILL PROBABLY CHANG
  EVENLY_MONTHLY_IN_ARREARS: 'Evenly (monthly - arrears)',
  EVENLY_MONTHLY_12_MONTHS: 'Evenly (monthly - 12 mos)',
  EVENLY_MONTHLY_3_MONTHS: 'Evenly (monthly - 3 mos)',
};

export const RecognitionMethodOptions = objectToSelectOptions(
  RECOGNITION_METHOD_DISPLAY,
);

export const PRODUCT_TYPE_DISPLAY: {
  [key in ProductTypeEnum]: string;
} = {
  ADVANCE: 'Subscription',
  // SUBSCRIPTION: 'Subscription',   // this will eventually replace ADVANCE, but we have to wait for BE
  USAGE: 'Usage',
  ONETIME: 'Onetime',
  MIN_COMMIT: 'Minimum Commit',
  ONETIME_PREPAID_CREDIT: 'Onetime Pre-Paid Credit',
};

export const PRODUCT_TYPE_ORDER: ProductTypeEnum[] = [
  ProductTypeEnum.ONETIME_PREPAID_CREDIT,
  ProductTypeEnum.ADVANCE,
  // ProductTypeEnum.ARREARS,   // not used on the FE, although still appears in BE code
  ProductTypeEnum.USAGE,
  ProductTypeEnum.ONETIME,
  ProductTypeEnum.MIN_COMMIT,
];

export const PRODUCT_TYPES_SUPPORTING_PRORATION: ProductTypeEnum[] = [
  ProductTypeEnum.ADVANCE,
];

export const MIN_COMMIT_CONFIG_TYPE_DISPLAY: {
  [key in MinCommitConfigTypeEnum]: string;
} = {
  USAGE_PRODUCTS: 'All Usage Products',
  SPECIFIC_USAGE_PRODUCTS: 'Specific Products',
};

export const PRE_PAID_CREDIT_CONFIG_TYPE_DISPLAY: {
  [key in PrePaidCreditConfigTypeEnum]: string;
} = {
  USAGE_PRODUCTS: 'Usage Products Only',
  ALL_PRODUCTS: 'All Products',
  SUBSCRIPTION_PRODUCTS_ONLY: 'Subscription Products Only',
  SPECIFIC_PRODUCTS: 'Specific Products',
};
// Used in the product search on offering
export const PRODUCT_TYPE_DISPLAY_SHORT: {
  [key in ProductTypeEnum]: string;
} = {
  /** @deprecated will be replaced with SUBSCRIPTION */
  ADVANCE: 'Subscription',
  // SUBSCRIPTION: 'Subscription',
  USAGE: 'Usage',
  ONETIME: 'Onetime',
  MIN_COMMIT: 'Minimum Commit',
  ONETIME_PREPAID_CREDIT: 'Onetime Pre-Paid Credit',
};

export const ProductTypeSelectOptions =
  objectToSelectOptions(PRODUCT_TYPE_DISPLAY);

export const MinCommitConfigTypeSelectOptions = objectToSelectOptions(
  MIN_COMMIT_CONFIG_TYPE_DISPLAY,
);

export const PrePaidCreditConfigTypeSelectOptions = objectToSelectOptions(
  PRE_PAID_CREDIT_CONFIG_TYPE_DISPLAY,
);

export const PRODUCT_STATUS_ENUM_DISPLAY: {
  [key in ProductStatusEnum]: string;
} = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
};

export const ProductStatusSelectOptions = objectToSelectOptions(
  PRODUCT_STATUS_ENUM_DISPLAY,
);

export const NEW_USAGE_TYPE_OPTION = {
  name: '+ New Usage Type',
  value: 'NEW_USAGE_TYPE',
};

export const ONE_TIME_PRODUCT_TYPES = new Set<ProductTypeEnum>([
  ProductTypeEnum.ONETIME,
  ProductTypeEnum.ONETIME_PREPAID_CREDIT,
]);

export const SUBSCRIPTION_PRODUCT_TYPES = new Set<ProductTypeEnum>([
  ProductTypeEnum.ADVANCE,
  ProductTypeEnum.USAGE,
]);

export const SUBSCRIPTION_OFFERING_TYPE_PRODUCT_TYPES =
  new Set<ProductTypeEnum>([
    ProductTypeEnum.ADVANCE,
    ProductTypeEnum.USAGE,
    ProductTypeEnum.ONETIME,
    ProductTypeEnum.ONETIME_PREPAID_CREDIT,
  ]);

export const VARIABLE_AMOUNT_PRODUCT_TYPES = new Set<ProductTypeEnum>([
  ProductTypeEnum.USAGE,
]);

// only these types of products can have rates customized in the Quote
export const CUSTOMIZABLE_RATE_PRODUCT_TYPES = new Set<ProductTypeEnum>([
  ProductTypeEnum.ADVANCE,
  ProductTypeEnum.USAGE,
  ProductTypeEnum.ONETIME,
]);

export const RECURRING_REVENUE_PRODUCT_TYPES = new Set<ProductTypeEnum>([
  ProductTypeEnum.ADVANCE,
  ProductTypeEnum.MIN_COMMIT,
]);
