import { FormControl, FormLabel, Switch } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { IProduct, IRateReqSchemaUI, PriceDisplayEnum } from '~app/types';
import { MBox, MButton, MDivider, MFlex, MText } from '../Monetize';
import { RateSettingsPopoverTable } from '../Rates/RateSettingsPopoverTable';

type PriceDisplaySettingsTableProps = {
  isReadOnly?: boolean;
  value?: PriceDisplayEnum | null;
  onChange: (value: PriceDisplayEnum) => void;

  quoteOfferingName: string;
  products: Pick<IProduct, 'productType' | 'name' | 'id'>[];
  rate?: IRateReqSchemaUI;
  isLoading: boolean;
};

export const PriceDisplaySettingsTable = ({
  isReadOnly,
  value,
  onChange,
  quoteOfferingName,
  products,
  rate,
  isLoading,
}: PriceDisplaySettingsTableProps) => {
  const [priceDisplayInternal, setPriceDisplayInternal] = useState(
    value || PriceDisplayEnum.PRODUCT,
  );

  useEffect(() => {
    setPriceDisplayInternal(value || PriceDisplayEnum.PRODUCT);
  }, [value]);

  const onSave = async () => {
    await onChange(priceDisplayInternal);
  };

  if (!rate) {
    return null;
  }

  return (
    <MBox bg="tWhite.base" p={4} borderRadius={2}>
      <MText fontWeight="bold" fontSize="md" mb="2">
        Price Display Settings
      </MText>
      <MDivider my="2" />
      <FormControl
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        mt="2"
      >
        <FormLabel htmlFor="set-price-product-level" mb="0">
          Show Price at Product Level
        </FormLabel>
        <Switch
          id="allow-collaboration-switch"
          isChecked={priceDisplayInternal === PriceDisplayEnum.PRODUCT}
          size="lg"
          onChange={() =>
            setPriceDisplayInternal(
              priceDisplayInternal === PriceDisplayEnum.PRODUCT
                ? PriceDisplayEnum.OFFERING
                : PriceDisplayEnum.PRODUCT,
            )
          }
          isDisabled={isReadOnly}
        />
      </FormControl>
      <MText my={1} fontSize="xs" color="tGray.acGray">
        On the Quote PDF, show the Price Per Unit and Amount for each product
        individually. On Invoice, Price details are always shown at the product
        level.
      </MText>

      <MBox my="3">
        <MText my={1} fontSize="xxs" color="tGray.acGray" fontWeight="600">
          {priceDisplayInternal === PriceDisplayEnum.PRODUCT
            ? 'Showing Prices at Product Level:'
            : 'Hiding Prices at Product Level:'}
        </MText>
        <MFlex>
          <RateSettingsPopoverTable
            rate={rate}
            quoteOfferingName={quoteOfferingName}
            priceDisplay={priceDisplayInternal}
            products={products}
          />
          <MBox
            w="70px"
            ml="2"
            pl="2"
            borderLeft="1px solid"
            borderColor="tIndigo.base"
            alignSelf="flex-end"
          >
            <MText fontSize="9px" color="tGray.acBlack">
              {priceDisplayInternal === PriceDisplayEnum.PRODUCT
                ? 'PPU & total product Amounts are shown here.'
                : 'Total per segment is shown. PPU & total product Amounts do not show here.'}
            </MText>
          </MBox>
        </MFlex>
      </MBox>
      {!isReadOnly && (
        <>
          <MDivider />
          <MFlex justifyContent="flex-end">
            <MButton
              variant="primary"
              minWidth="auto"
              mt="2"
              onClick={onSave}
              isDisabled={value === priceDisplayInternal}
              isLoading={isLoading}
            >
              Save
            </MButton>
          </MFlex>
        </>
      )}
    </MBox>
  );
};
