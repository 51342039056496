import { useEffect, useState } from 'react';

/**
 * Debounce a value (not a callback function)
 * This is useful for things like a search input, where the value is debounced
 * instead of the callback function which may not have a stable identity.
 *
 * @example
 * ```
 * const [value, setValue] = useState<string>(value);
 * const debouncedValue = useDebounce(value);
 *
 * // debouncedValue only gets updated based on the delay
 * // this is perfect for a useEffect to notify parent of a changed value
 *
 * useEffect(() => { onChange(debouncedValue); }, [debouncedValue]}
 * ```
 *
 * Reference: https://usehooks.com/useDebounce/
 *
 * @param value value to debounce
 * @param delay
 * @returns
 */
export function useDebounce<T>(value: T, delay = 300) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed
      // within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay], // Only re-call effect if value or delay changes
  );

  return debouncedValue;
}
