import {
  CustomFieldEntityEnum,
  CustomFieldPermissionsEnum,
  DEFAULT_PAGER,
  ICustomFieldResSchema,
} from '~app/types';
import { useGetListData } from '../api/queryUtils';
import { orderObjectsBy } from '../utils/misc';

export interface CustomFieldsAllData {
  allFields: ICustomFieldResSchema[];
  enabledFields: ICustomFieldResSchema[];
  fieldsByEntity: Record<CustomFieldEntityEnum, ICustomFieldResSchema[]>;
  fieldsByEntityByKey: Record<
    CustomFieldEntityEnum,
    Record<string, ICustomFieldResSchema>
  >;
  /**
   * Keyed by `${entity}.${key}`
   */
  fieldsByKey: Record<
    `${CustomFieldEntityEnum}.${string}`,
    ICustomFieldResSchema
  >;
}

/**
 * Returns all custom fields in the org and provides the data represented in various formats
 * to allow easy use when working with data in the UI.
 */
export const useCustomFieldsAll = () => {
  return useGetListData<ICustomFieldResSchema, CustomFieldsAllData>(
    'customFields',
    {
      config: DEFAULT_PAGER,
    },
    {
      isGetAll: true,
      refetchOnWindowFocus: false,
      select: ({ content }) => {
        return orderObjectsBy(content || [], ['displayLabel'])
          .map((field) => ({
            ...field,
            values: orderObjectsBy(field.values || [], ['order']).filter(
              ({ enabled }) => enabled,
            ),
          }))
          .reduce(
            (acc: CustomFieldsAllData, field) => {
              acc.allFields.push(field);
              if (field.permissions.includes(CustomFieldPermissionsEnum.READ)) {
                acc.enabledFields.push(field);
              }

              acc.fieldsByEntity[field.entity] =
                acc.fieldsByEntity[field.entity] || [];
              acc.fieldsByEntity[field.entity].push(field);

              acc.fieldsByEntityByKey[field.entity] =
                acc.fieldsByEntityByKey[field.entity] || {};
              acc.fieldsByEntityByKey[field.entity][field.key] = field;

              acc.fieldsByKey[`${field.entity}.${field.key}`] = field;
              return acc;
            },
            {
              allFields: [] as CustomFieldsAllData['allFields'],
              enabledFields: [] as CustomFieldsAllData['enabledFields'],
              fieldsByEntity: {} as CustomFieldsAllData['fieldsByEntity'],
              fieldsByEntityByKey:
                {} as CustomFieldsAllData['fieldsByEntityByKey'],
              fieldsByKey: {} as CustomFieldsAllData['fieldsByKey'],
            },
          );
      },
    },
  );
};
