import { Container } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import {
  MBox,
  MButton,
  MFlex,
  MPageHeader,
  MPageLoader,
  MText,
} from '../../../components/Monetize';
import { DataTableNew } from '../../../components/Monetize/DataTableNew/DataTableNew';
import { DataTableRef } from '../../../components/Monetize/DataTableNew/DataTableTypes';
import { SELECTION_COL_KEY } from '../../../components/Monetize/DataTableNew/dataTableNewRenderers';
import { BILL_GROUP_STATUS_DISPLAY } from '../../../constants/billGroups';
import {
  ADDRESS_SOURCE_DISPLAY,
  BillGroupListUi,
  NET_TERMS_DISPLAY,
} from '../../../types';
import { formatInteger, pluralize } from '../../../utils';
import { arrayToObject } from '../../../utils/misc';
import { BillingPageLoadingBar } from '../shared/BillingPageLoadingBar';
import { useManualBillRun } from './useManualBillRun';

const INITIAL_SORTING_STATE = [{ id: 'nextInvoiceDate', desc: false }];

const pinnedLeftColumns = [SELECTION_COL_KEY];

const EMPTY_ARR: BillGroupListUi[] = [];

export const ManualBillRun = () => {
  const [selectedRows, setSelectedRows] = useState<BillGroupListUi[]>([]);
  const [visibleRows, setVisibleRows] = useState<BillGroupListUi[]>([]);
  const [canFinalizeSubmission, setCanFinalizeSubmission] = useState(false);
  const tableRef = useRef<DataTableRef>(null);

  const {
    batchInitIsLoading,
    billGroupQueryResult,
    columns,
    customFieldQueryResult,
    handleInvokeManualBillRun,
    progress,
  } = useManualBillRun(selectedRows);

  const {
    data: billGroups,
    isLoading,
    isRefetching,
    refetch,
  } = billGroupQueryResult;

  useEffect(() => {
    if (
      selectedRows.length === 0 ||
      selectedRows.length !== visibleRows.length
    ) {
      setCanFinalizeSubmission(false);
      return;
    }
    const selectedRowIds = new Set(
      Object.keys(arrayToObject(selectedRows, 'id')),
    );
    setCanFinalizeSubmission(
      visibleRows.every((row) => selectedRowIds.has(row.id)),
    );
  }, [selectedRows, visibleRows]);

  const { isLoading: isLoadingCustomFields } = customFieldQueryResult;

  const visibleRowCount = visibleRows.length;
  const submitButtonText = canFinalizeSubmission
    ? `Submit ${formatInteger(visibleRowCount)} ${pluralize(
        'Bill Group',
        visibleRowCount,
      )}`
    : 'Preview Impacted Bill Groups';

  function handleSubmitClick() {
    if (canFinalizeSubmission) {
      handleInvokeManualBillRun();
    } else {
      tableRef.current?.resetFilters();
      tableRef.current?.setFilterValue({
        columnKey: SELECTION_COL_KEY,
        value: 'SELECTED',
      });
    }
  }

  return (
    <Container
      maxHeight="calc(100vh - 175px)"
      maxWidth="unset"
      p="0"
      position="relative"
      centerContent
      data-testid="bill-run-page"
    >
      <BillingPageLoadingBar
        isVisible={isLoading || isRefetching}
        progress={progress}
      />
      <MPageHeader
        title="Bill Run"
        subTitle={<MText>Progress billing for any eligible bill groups.</MText>}
      />

      {isLoadingCustomFields || isLoading ? (
        <MPageLoader height="50vh" />
      ) : (
        <>
          <MFlex mb={2} w="100%" justifyContent="space-between">
            <MBox>
              <MButton
                variant="primary"
                isLoading={batchInitIsLoading}
                onClick={() => handleSubmitClick()}
                isDisabled={
                  isRefetching ||
                  batchInitIsLoading ||
                  selectedRows.length === 0
                }
                type="button"
              >
                {submitButtonText}
              </MButton>
            </MBox>
            <MBox>
              <MButton
                variant="tertiary"
                size="sm"
                onClick={() => tableRef.current?.resetFilters()}
                type="button"
              >
                Reset All Filters
              </MButton>
              <MButton
                variant="tertiary"
                size="sm"
                isLoading={isRefetching}
                onClick={() => refetch()}
                type="button"
              >
                Reload
              </MButton>
            </MBox>
          </MFlex>
          <DataTableNew
            ref={tableRef}
            columns={columns}
            data={billGroups || EMPTY_ARR}
            pinnedLeftColumns={pinnedLeftColumns}
            options={{
              enablePinning: true,
              enableColumnPinning: true,
            }}
            filterDataDisplayMap={{
              status: BILL_GROUP_STATUS_DISPLAY,
              netTerms: NET_TERMS_DISPLAY,
              addressSource: ADDRESS_SOURCE_DISPLAY,
            }}
            clientControlled
            initialSortingState={INITIAL_SORTING_STATE}
            onRowSelectionChange={(rows) => setSelectedRows(rows)}
            onVisibleRowChange={setVisibleRows}
          />
          {billGroups && (
            <MText mt={2} w="100%">
              Showing {formatInteger(visibleRowCount)} of{' '}
              {formatInteger(billGroups.length)} Bill Groups
            </MText>
          )}
        </>
      )}
    </Container>
  );
};
