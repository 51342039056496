import { ACLActionType, ACLItemType } from '~app/types';
import * as ROLES from './roles';

export const ACL = {
  accounts: <ACLItemType>{
    read: ROLES.ACCOUNTS_READ_ROLES,
    create: ROLES.ACCOUNTS_EDIT_ROLES,
    delete: ROLES.ACCOUNTS_EDIT_ROLES,
    update: ROLES.ACCOUNTS_EDIT_ROLES,
  },
  account_contacts: <ACLItemType>{
    read: ROLES.ACCOUNT_CONTACTS_READ_ROLES,
    create: ROLES.ACCOUNT_CONTACTS_EDIT_ROLES,
    delete: ROLES.ACCOUNT_CONTACTS_EDIT_ROLES,
    update: ROLES.ACCOUNT_CONTACTS_EDIT_ROLES,
  },
  account_quotes: <ACLItemType>{
    read: ROLES.ACCOUNT_QUOTES_READ_ROLES,
    // create: ROLES.ACCOUNT_QUOTES_EDIT_ROLES,
    // delete: ROLES.ACCOUNT_QUOTES_EDIT_ROLES,
    // update: ROLES.ACCOUNT_QUOTES_EDIT_ROLES,
  },
  billing: <ACLItemType>{
    read: ROLES.BILLING_READ_ROLES,
    create: ROLES.BILLING_EDIT_ROLES,
    delete: ROLES.BILLING_EDIT_ROLES,
    update: ROLES.BILLING_EDIT_ROLES,
  },
  sales: <ACLItemType>{
    read: ROLES.SALES_READ_ROLES,
    create: ROLES.SALES_EDIT_ROLES,
    delete: ROLES.SALES_EDIT_ROLES,
    update: ROLES.SALES_EDIT_ROLES,
  },
  users: <ACLItemType>{
    read: ROLES.SETTINGS_USERS_ROLES,
    create: ROLES.SETTINGS_USERS_ROLES,
    delete: ROLES.SETTINGS_USERS_ROLES,
    update: ROLES.SETTINGS_USERS_ROLES,
  },
  apiKeys: <ACLItemType>{
    read: ROLES.SETTINGS_API_KEYS_ROLES,
    create: ROLES.SETTINGS_API_KEYS_ROLES,
    delete: ROLES.SETTINGS_API_KEYS_ROLES,
    update: ROLES.SETTINGS_API_KEYS_ROLES,
  },
  quote_owner: <ACLItemType>{
    read: ROLES.QUOTE_LIST_FILTER_BY_OWNER_ROLES,
  },
  settings: <ACLItemType>{
    read: ROLES.SETTINGS_READONLY_RULES,
    create: ROLES.SETTINGS_EDIT_RULES,
  },
  contracts: <ACLItemType>{
    read: ROLES.CONTRACTS_READ_ROLES,
    create: ROLES.SALES_EDIT_ROLES,
    delete: ROLES.SALES_EDIT_ROLES,
    update: ROLES.SALES_EDIT_ROLES,
  },
};

export type ACLActionItemType = [keyof typeof ACL, ACLActionType];
export type ACLAvailableAction = {
  [key in keyof typeof ACL]?: { [act in ACLActionType]?: boolean };
};
