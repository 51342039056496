import { UserRoleEnum } from '~app/types';

/**
 * https://docs.google.com/spreadsheets/d/16yjN9wvD6WFoM2On-yPO1ws-mqWwN7ePQR9L-IhFGuA/edit#gid=0
 * https://monetizenow.atlassian.net/wiki/spaces/PE/pages/edit-v2/978583553
 *
 * Quick notes on roles
 * - TENANT_ADMIN // Full permissions to take any action in this Tenant
 * - QUOTING_ADMIN, // Can access all Quoting and Quote Settings and Configuration
 * - BILLING_ADMIN, // Can access all Billing and Billing Settings and Configuration
 * - BILLING, // Can perform account management, support, a billing actions
 * - BILLING_READ_ONLY, // Can see accounts and their information but not make changes
 * - SALES_MANAGER, // Can manage sales teams and see other users quotes
 * - QUOTING, // Can create and update quotes
 * - QUOTING_READ_ONLY, // Can see all quotes, but take no actions
 * - APPROVER_ONLY // Can only see the approvals dashbaord and quotes they are a part of
 *
 *
 * Notes on Account pages
 * - Everything within accounts pages are CRUD-able by TENANT_ADMIN, BILLING_ADMIN, and BILLING
 * - Quote/Contacts tabs can be CRUD-able by SALES_ADMIN and SALES
 * - For the rest of tabs SALES_ADMIN and SALES are readonly users.
 *
 *
 * General rules
 * - All XXX_READ_ROLES will extend from XXX_EDIT_ROLES.
 * - We wont even allow user to access the page for roles without XXX_READ_ROLES.
 *    It can be done by acls option in PrivateRoute.
 *    ALCCheck also decides wheather to show the button/component depending on acls.
 * - We will show the page for all XXX_READ_ROLES assuming it includes XXX_EDIT_ROLES.
 * - We will add specific props readonly = who cannot update/edit/delete. readonly will make all forms readonly. Hide save/create buttons.
 * - Within shared components like AccountForm, ContactForm, ContactFormModal, do not use acls.
 *    Because those component is re-used in other places
 *    Visibility - should be controlled outside
 *    Should always have readonly props to identify readonly users and users with edit/update permission.
 */

// Accounts
export const ACCOUNTS_EDIT_ROLES = [
  UserRoleEnum.TENANT_ADMIN,
  UserRoleEnum.BILLING_ADMIN,
  UserRoleEnum.BILLING,
  UserRoleEnum.SALES_ADMIN,
];
export const ACCOUNTS_READ_ROLES = ACCOUNTS_EDIT_ROLES.concat([
  UserRoleEnum.BILLING_READ_ONLY,
  UserRoleEnum.SALES,
]);

// Account contacts - permission for contacts tab under account detail
export const ACCOUNT_CONTACTS_EDIT_ROLES = [
  UserRoleEnum.TENANT_ADMIN,
  UserRoleEnum.BILLING_ADMIN,
  UserRoleEnum.BILLING,
  UserRoleEnum.SALES_ADMIN,
  UserRoleEnum.SALES,
];
export const ACCOUNT_CONTACTS_READ_ROLES = ACCOUNT_CONTACTS_EDIT_ROLES.concat([
  UserRoleEnum.BILLING_READ_ONLY,
]);

// Account quote - permission for quotes and contracts tabs under account detail
export const ACCOUNT_QUOTES_EDIT_ROLES = [
  UserRoleEnum.TENANT_ADMIN,
  UserRoleEnum.BILLING_ADMIN,
  UserRoleEnum.BILLING,
  UserRoleEnum.SALES_ADMIN,
  UserRoleEnum.SALES,
];

export const ACCOUNT_QUOTES_READ_ROLES = ACCOUNT_QUOTES_EDIT_ROLES.concat([
  UserRoleEnum.BILLING_READ_ONLY,
]);

// Billing - permission for tabs (except contacts) under account details
export const BILLING_EDIT_ROLES = [
  UserRoleEnum.TENANT_ADMIN,
  UserRoleEnum.BILLING_ADMIN,
  UserRoleEnum.BILLING,
];

export const BILLING_READ_ROLES = BILLING_EDIT_ROLES.concat([
  UserRoleEnum.BILLING_READ_ONLY,
  UserRoleEnum.SALES_ADMIN,
  UserRoleEnum.SALES,
  UserRoleEnum.SALES_MANAGER,
  UserRoleEnum.SALES_FULL,
]);

// Sales - permission for tabs under quotes/contracts/opportunities, related to sales
export const SALES_EDIT_ROLES = [
  UserRoleEnum.TENANT_ADMIN,
  UserRoleEnum.SALES_ADMIN,
  UserRoleEnum.SALES,
  UserRoleEnum.SALES_FULL,
];

export const SALES_READ_ROLES = SALES_EDIT_ROLES.concat([
  UserRoleEnum.BILLING_READ_ONLY,
  UserRoleEnum.BILLING_ADMIN,
  UserRoleEnum.BILLING,
  UserRoleEnum.SALES_READ_ONLY,
  UserRoleEnum.APPROVER_ONLY,
]);

export const QUOTE_LIST_FILTER_BY_OWNER_ROLES = [
  UserRoleEnum.SALES_FULL,
  UserRoleEnum.TENANT_ADMIN,
  UserRoleEnum.SALES_ADMIN,
  UserRoleEnum.SALES_READ_ONLY,
];

// Users & Roles - permission for inviting/updating user roles
export const SETTINGS_USERS_ROLES = [UserRoleEnum.TENANT_ADMIN];

export const SETTINGS_API_KEYS_ROLES = [UserRoleEnum.TENANT_ADMIN];

export const SETTINGS_CUSTOM_FIELDS_ROLES = [UserRoleEnum.TENANT_ADMIN];

// settings & product catalog
export const SETTINGS_EDIT_RULES = [UserRoleEnum.TENANT_ADMIN];

export const SETTINGS_READONLY_RULES = SETTINGS_EDIT_RULES.concat([
  UserRoleEnum.SETTINGS_READ_ONLY,
]);

export const CONTRACTS_READ_ROLES = [UserRoleEnum.SALES_READ_ONLY];
