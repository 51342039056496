import { sortByAlphabetically } from '~app/utils';
import { SearchedItemType } from './MSearchInput';

export type Chunk = {
  highlight: boolean;
  start: number;
  end: number;
};

export type FindChunksProps = {
  autoEscape?: boolean;
  caseSensitive?: boolean;
  sanitizeFn?: (value: string) => string;
  searchWords: Array<string>;
  textToHighlight: string;
};

export type FindAllProps = FindChunksProps & {
  findChunks?: (value: FindChunksProps) => Array<Chunk>;
};

/**
 * @param autoEscape boolean - Escape special regular expression characters
 * @param caseSensitive boolean - Search should be case sensitive
 * @param findChunks - Custom findChunks function
 * @param sanitizeFn - Custom sanitize function
 * @param searchWords string[] - Array of words to search for
 * @param textToHighlight string - Text to search and highlight
 * Creates an array of chunk objects representing both higlightable and non highlightable pieces of text that match each search word.
 * @return - {start:number, end:number, highlight:boolean}[]
 */
export const findAll = ({
  autoEscape,
  caseSensitive = false,
  findChunks = defaultFindChunks,
  sanitizeFn,
  searchWords,
  textToHighlight,
}: FindAllProps): Array<Chunk> =>
  fillInChunks({
    chunksToHighlight: combineChunks({
      chunks: findChunks({
        autoEscape,
        caseSensitive,
        sanitizeFn,
        searchWords,
        textToHighlight,
      }),
    }),
    totalLength: textToHighlight ? textToHighlight.length : 0,
  });

/**
 * @param chunks - Takes an array of {start:number, end:number} objects and combines chunks that overlap into single chunks.
 * @return - {start:number, end:number}[]
 */
export const combineChunks = ({
  chunks,
}: {
  chunks: Array<Chunk>;
}): Array<Chunk> => {
  chunks = chunks
    .sort((first, second) => first.start - second.start)
    .reduce((processedChunks: Array<Chunk>, nextChunk: Chunk) => {
      // First chunk just goes straight in the array...
      if (processedChunks.length === 0) {
        return [nextChunk];
      } else {
        // ... subsequent chunks get checked to see if they overlap...
        const prevChunk = processedChunks.pop()!;
        if (nextChunk.start <= prevChunk.end) {
          // It may be the case that prevChunk completely surrounds nextChunk, so take the
          // largest of the end indexes.
          const endIndex = Math.max(prevChunk.end, nextChunk.end);
          processedChunks.push({
            highlight: false,
            start: prevChunk.start,
            end: endIndex,
          });
        } else {
          processedChunks.push(prevChunk, nextChunk);
        }
        return processedChunks;
      }
    }, []);

  return chunks;
};

/**
 * @param autoEscape boolean - Escape special regular expression characters
 * @param caseSensitive boolean - Search should be case sensitive
 * @param sanitizeFn - Custom sanitize function
 * @param searchWords Array<string> - Array of words to search for
 * @param textToHighlight string - Text to search and highlight
 * If we find matches, add them to the returned array as a "chunk" object ({start:number, end:number}).
 * @return - {start:number, end:number}[]
 */
const defaultFindChunks = ({
  autoEscape,
  caseSensitive,
  sanitizeFn = (value: string) => value,
  searchWords,
  textToHighlight,
}: FindChunksProps): Array<Chunk> => {
  textToHighlight = sanitizeFn(textToHighlight);

  return searchWords
    .filter((searchWord) => searchWord) // Remove empty words
    .reduce((chunks: Array<Chunk>, searchWord: string) => {
      searchWord = sanitizeFn(searchWord);

      if (autoEscape) {
        searchWord = escapeRegExpFn(searchWord);
      }

      const regex = new RegExp(searchWord, caseSensitive ? 'g' : 'gi');

      let match;
      while ((match = regex.exec(textToHighlight))) {
        const start = match.index;
        const end = regex.lastIndex;
        // We do not return zero-length matches
        if (end > start) {
          chunks.push({ highlight: false, start, end });
        }

        // Prevent browsers like Firefox from getting stuck in an infinite loop
        // See http://www.regexguru.com/2008/04/watch-out-for-zero-length-matches/
        if (match.index === regex.lastIndex) {
          regex.lastIndex++;
        }
      }

      return chunks;
    }, []);
};
// Allow the findChunks to be overridden in findAll,
// but for backwards compatibility we export as the old name
export { defaultFindChunks as findChunks };

/**
 * Given a set of chunks to highlight, create an additional set of chunks
 * to represent the bits of text between the highlighted text.
 * @param chunksToHighlight {start:number, end:number}[]
 * @param totalLength number
 * @return - {start:number, end:number, highlight:boolean}[]
 */
export const fillInChunks = ({
  chunksToHighlight,
  totalLength,
}: {
  chunksToHighlight: Array<Chunk>;
  totalLength: number;
}): Array<Chunk> => {
  const allChunks: Array<Chunk> = [];
  const append = (start: number, end: number, highlight: boolean) => {
    if (end - start > 0) {
      allChunks.push({
        start,
        end,
        highlight,
      });
    }
  };

  if (chunksToHighlight.length === 0) {
    append(0, totalLength, false);
  } else {
    let lastIndex = 0;
    chunksToHighlight.forEach((chunk) => {
      append(lastIndex, chunk.start, false);
      append(chunk.start, chunk.end, true);
      lastIndex = chunk.end;
    });
    append(lastIndex, totalLength, false);
  }
  return allChunks;
};

function escapeRegExpFn(value: string): string {
  return value.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
}

export const sortSearchRecordByStatus = (arr: SearchedItemType[]) => {
  if (!arr.length) {
    return [];
  }
  const statusOrder = ['ACTIVE', '', 'INACTIVE'];
  return arr.sort(
    (a, b) =>
      statusOrder.indexOf(a.status) - statusOrder.indexOf(b.status) ||
      sortByAlphabetically()(a, b),
  );
};
