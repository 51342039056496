import { useEffect } from 'react';
import { useAuth } from '~app/services/auth0';
import Sentry from '~app/services/sentry';

/**
 * Set context for Sentry so that no matter where events are logged,
 * we always know which user and which tenant the error originate from.
 */
export const useSentry = () => {
  const { userId, user, tenantId, currentTenant } = useAuth();

  useEffect(() => {
    if (userId) {
      Sentry.setUser({
        id: userId,
        email: user?.email,
      });
    }
  }, [userId, user]);

  useEffect(() => {
    if (tenantId || currentTenant) {
      Sentry.setContext('tenant', {
        id: tenantId,
        name: currentTenant?.name,
        testTenant: currentTenant?.testTenant,
      });
    }
  }, [tenantId, currentTenant]);
};
