import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';
import { apiGet, apiPost, apiPut } from '~api/axios';
import {
  ApiListResponse,
  GenericApiResponse,
  GetListApiConfig,
  GetListApiFilter,
  INoteReqSchema,
  INoteResSchema,
} from '~types';
import { notesServiceQueryKeys } from './queryKeysService';
import {
  updateListCacheWithRemovedItem,
  updateListCacheWithUpdatedItem,
} from './queryUtilsHelpers';
import { composeGetQuery } from './utils';

export function useGetNotes<SelectData = ApiListResponse<INoteResSchema>>(
  {
    entityPath,
    id,
    config = {},
    filters,
  }: {
    entityPath: 'accounts' | 'quotes';
    id: string;
    config?: GetListApiConfig;
    filters?: GetListApiFilter;
  },
  options: Partial<
    UseQueryOptions<ApiListResponse<INoteResSchema>, unknown, SelectData>
  > = {},
) {
  const { onSuccess, ...restOptions } = options;
  const params = composeGetQuery(config, filters);
  return useQuery<ApiListResponse<INoteResSchema>, unknown, SelectData>(
    [...notesServiceQueryKeys.noteListById(entityPath, id), params],
    {
      queryFn: () =>
        apiGet<ApiListResponse<INoteResSchema>>(
          `/api/${entityPath}/${id}/notes`,
          {
            params,
          },
        ).then((res) => res.data),
      refetchOnWindowFocus: false,
      // This is off so the quote sticky header does not need to refetch this as often
      refetchOnMount: false,
      ...restOptions,
    },
  );
}

export function useCreateNote(
  {
    entityPath,
    id,
  }: {
    entityPath: 'accounts' | 'quotes';
    id: string;
  },
  options: {
    onSuccess?: (data: INoteResSchema) => void;
    onError?: (err: unknown) => void;
  } = {},
) {
  const queryClient = useQueryClient();
  const { onSuccess, ...restOptions } = options;

  return useMutation<INoteResSchema, unknown, INoteReqSchema>(
    (payload) => {
      return apiPost<INoteResSchema>(
        `/api/${entityPath}/${id}/notes`,
        payload,
      ).then((res) => res.data);
    },
    {
      onSuccess: (newData) => {
        queryClient.invalidateQueries([
          ...notesServiceQueryKeys.noteListById(entityPath, id),
        ]);
        onSuccess && onSuccess(newData);
      },
      ...restOptions,
    },
  );
}

export function useUpdateNote(
  {
    entityPath,
    id,
  }: {
    entityPath: 'accounts' | 'quotes';
    id: string;
  },
  options: {
    onSuccess?: (data: INoteResSchema) => void;
    onError?: (err: unknown) => void;
  } = {},
) {
  const queryClient = useQueryClient();
  const { onSuccess, ...restOptions } = options;

  return useMutation<INoteResSchema, unknown, INoteReqSchema>(
    (payload) => {
      return apiPut<INoteResSchema>(`/api/notes/${payload?.id}`, payload).then(
        (res) => res.data,
      );
    },
    {
      onSuccess: (newData) => {
        updateListCacheWithUpdatedItem(
          queryClient,
          [...notesServiceQueryKeys.noteListById(entityPath, id)],
          newData,
        );
        onSuccess && onSuccess(newData);
      },
      ...restOptions,
    },
  );
}

export function useDeleteNote(
  {
    entityPath,
    id,
  }: {
    entityPath: 'accounts' | 'quotes';
    id: string;
  },
  options: {
    onSuccess?: (data: GenericApiResponse) => void;
    onError?: (err: unknown) => void;
  } = {},
) {
  const queryClient = useQueryClient();
  const { onSuccess, ...restOptions } = options;

  return useMutation<GenericApiResponse, unknown, string>(
    (noteId) => {
      return apiPut<GenericApiResponse>(`/api/notes/${noteId}/archive`).then(
        (res) => res.data,
      );
    },
    {
      onSuccess: (data, noteId) => {
        updateListCacheWithRemovedItem(
          queryClient,
          [...notesServiceQueryKeys.noteListById(entityPath, id)],
          noteId,
        );
        queryClient.invalidateQueries([
          ...notesServiceQueryKeys.noteListById(entityPath, id),
        ]);
        onSuccess && onSuccess(data);
      },
      ...restOptions,
    },
  );
}
