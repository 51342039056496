import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react';
import { FC } from 'react';
import {
  MAlertTag,
  MBox,
  MDivider,
  MFlex,
  MText,
} from '~app/components/Monetize';
import { BannerAlertType } from '~app/components/Monetize/MAlertTag';
import { IQuoteValidation, RuleValidationErrorLevelEnum } from '~app/types';

interface ValidationGroup {
  title: string;
  color: string;
  backColor: string;
  alertType: BannerAlertType;
  validations: IQuoteValidation[];
  errorLevel: RuleValidationErrorLevelEnum;
}

const ValidationPopover = ({ validation }: { validation: ValidationGroup }) => {
  return (
    <Popover placement="bottom" trigger="hover" arrowSize={15}>
      <PopoverTrigger>
        <MBox
          minW="5"
          minH="5"
          w="5"
          h="5"
          background={validation.color}
          borderRadius="50%"
          mr="1"
          display="flex"
          alignItems="center"
          justifyContent="center"
          cursor="pointer"
          data-testid="validation-popover-trigger"
        >
          <MText color="white" fontSize="xs">
            {validation.validations.length}
          </MText>
        </MBox>
      </PopoverTrigger>
      <PopoverContent
        border="0"
        boxShadow="popover"
        background={validation.backColor}
      >
        <PopoverArrow mt="1" bg={validation.backColor} />

        <PopoverBody padding="0">
          <MBox>
            <MAlertTag
              type={validation.alertType}
              padding="2"
              alignItems="flex-start"
            >
              <MBox ml="1">
                <MText color="tGray.acBlack" fontSize="sm" fontWeight="600">
                  {validation.title}
                </MText>
                <ul
                  style={{
                    listStyle:
                      validation.validations.length > 1 ? 'disc' : 'none',
                    marginLeft:
                      validation.validations.length > 1 ? '18px' : '0px',
                  }}
                >
                  {validation.validations.map((rule) => (
                    <li key={rule.ruleId}>
                      <MText
                        color="tGray.acBlack"
                        fontSize="xs"
                        data-testid="validation-message"
                      >
                        {rule.message}
                      </MText>
                    </li>
                  ))}
                </ul>
              </MBox>
            </MAlertTag>
          </MBox>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

interface QuoteAlertsProps {
  validations: IQuoteValidation[];
  showDivider?: boolean;
}

const QuoteAlerts: FC<QuoteAlertsProps> = ({
  validations,
  showDivider = true,
}: QuoteAlertsProps) => {
  if (!validations || validations.length === 0) {
    return null;
  }

  const alertValidationsByErrorLevel = {
    [RuleValidationErrorLevelEnum.ERROR]: {
      title: 'Error',
      color: 'tRed.base',
      backColor: 'tRed.paleRed',
      alertType: 'error' as BannerAlertType,
      validations: [] as IQuoteValidation[],
    },
  };
  const warningValidationsByErrorLevel = {
    [RuleValidationErrorLevelEnum.WARN]: {
      title: 'Warning',
      color: 'tOrange.tangerine',
      backColor: 'tOrange.input',
      alertType: 'warning' as BannerAlertType,
      validations: [] as IQuoteValidation[],
    },
    [RuleValidationErrorLevelEnum.INFO]: {
      title: 'Information',
      color: 'tIndigo.base',
      backColor: 'tBlue.hover',
      alertType: 'info' as BannerAlertType,
      validations: [] as IQuoteValidation[],
    },
  };
  validations.forEach((validation) => {
    if (validation.errorLevel === RuleValidationErrorLevelEnum.ERROR) {
      alertValidationsByErrorLevel[validation.errorLevel].validations.push(
        validation,
      );
    } else {
      warningValidationsByErrorLevel[validation.errorLevel].validations.push(
        validation,
      );
    }
  });

  const alertValidValidations: ValidationGroup[] = Object.values(
    alertValidationsByErrorLevel,
  )
    .filter((value) => value.validations.length > 0)
    .map((value) => ({
      errorLevel: value.validations[0].errorLevel,
      ...value,
    }));
  const warningValidValidations: ValidationGroup[] = Object.values(
    warningValidationsByErrorLevel,
  )
    .filter((value) => value.validations.length > 0)
    .map((value) => ({
      errorLevel: value.validations[0].errorLevel,
      ...value,
    }));

  return (
    <MFlex>
      <MText
        whiteSpace="nowrap"
        mr="1.5"
        color={alertValidValidations.length > 0 ? 'tRed.base' : 'tPurple.base'}
      >
        {alertValidValidations.length > 0 ? 'Action Required' : 'Quote Alerts'}
      </MText>

      {alertValidValidations.map((validation) => (
        <ValidationPopover
          key={validation.errorLevel}
          validation={validation}
        />
      ))}
      {warningValidValidations.map((validation) => (
        <ValidationPopover
          key={validation.errorLevel}
          validation={validation}
        />
      ))}
      {showDivider && (
        <MDivider
          borderColor="tPurple.base"
          height="5"
          w="1"
          mx="2"
          orientation="vertical"
        />
      )}
    </MFlex>
  );
};

export { QuoteAlerts };
