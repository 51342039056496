import React, { FunctionComponent as FC } from 'react';
import {
  MCustomNumberInput,
  MCustomNumberInputProps,
} from './MCustomNumberInput';

interface MCurrencyInputProps extends MCustomNumberInputProps {
  currency?: string;
}

export const MCurrencyInput: FC<MCurrencyInputProps> = React.forwardRef<
  any,
  MCurrencyInputProps
>(({ currency, ...rest }: MCurrencyInputProps, ref: any) => {
  return <MCustomNumberInput ref={ref} {...rest} />;
});
