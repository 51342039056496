import { BoxProps } from '@chakra-ui/react';
import { format as formatDate } from 'date-fns/format';
import { parse as parseDate } from 'date-fns/parse';
import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';
import { Column, ColumnBodyOptions, ColumnProps } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { ReactNode, useEffect, useState } from 'react';
import { MBox, MDataTable } from '~app/components/Monetize';
import { IRevenueRecognitionResponse } from '~app/types/revenueTypes';
import { formatCurrency } from '~app/utils';
import './RevRecTable.scss';
import {
  getRevRecPeriods,
  transformRevRecToTabularData,
} from './rev-rec.utils';

interface RevRecTableProps extends BoxProps {
  revRecData: IRevenueRecognitionResponse;
}

type RowData = Record<string, string | number | null>;

const monthBodyTemplate = (data: RowData, options: ColumnBodyOptions) => {
  if (data.type === 'TOTAL') {
    return (
      <MBox fontWeight={600} px="4" py="2">
        {data.period}
      </MBox>
    );
  }
  if (!isString(data.period)) {
    return '';
  }
  return (
    <MBox px="4" py="2">
      {formatDate(parseDate(data.period, 'yyyy-MM', new Date()), 'MMM yyyy')}
    </MBox>
  );
};
const amountBodyTemplate = (data: RowData, options: ColumnBodyOptions) => {
  if (!isNumber(data[options.field])) {
    return <MBox bg="tBlue.hover" w="100%" h="100%" />;
  }
  return (
    <MBox px="4" py="2" w="100%" h="100%">
      {formatCurrency(data[options.field] as string, {
        currency: isString(data?.currency) ? data.currency : 'USD',
        maximumFractionDigits: 0,
      })}
    </MBox>
  );
};

export const RevRecTable = ({ revRecData, ...rest }: RevRecTableProps) => {
  const [columns, setColumns] = useState<{
    headerGroup: ReactNode;
    // footerGroup: ReactNode;
    columns: ColumnProps[];
  } | null>(null);
  const [rows, setRows] = useState<RowData[]>();

  /**
   * Setup rows and columns from API response
   */
  useEffect(() => {
    const { recognizedAsOfPeriod, startPeriod } = revRecData;
    const periods = getRevRecPeriods(startPeriod, recognizedAsOfPeriod);
    const results = transformRevRecToTabularData(revRecData);
    setRows(results.rows.concat(results.footer));

    setColumns(() => {
      return {
        headerGroup: (
          <ColumnGroup>
            <Row
              style={{
                background:
                  'linear-gradient(0deg, #E3E8EC, #E3E8EC), linear-gradient(0deg, #FFFFFF, #FFFFFF)',
                border: '1px solid #E3E8EC',
              }}
            >
              <Column
                header="Sold"
                colSpan={2}
                frozen
                headerStyle={{
                  textAlign: 'center',
                  background: '#FCFCFC',
                  border: '1px solid #E3E8EC',
                }}
              />
              <Column
                header="Recognition Schedule"
                colSpan={periods.length}
                headerStyle={{
                  background: '#FCFCFC',
                  border: '1px solid #E3E8EC',
                }}
              />
              <Column
                header={`As Of ${formatDate(
                  parseDate(recognizedAsOfPeriod, 'yyyy-MM', new Date()),
                  'MMM yyyy',
                )}`}
                colSpan={2}
                frozen
                alignFrozen="right"
                headerStyle={{
                  textAlign: 'center',
                  background: '#FCFCFC',
                  border: '1px solid #E3E8EC',
                }}
              />
            </Row>
            <Row>
              <Column
                header="Month"
                frozen
                style={{ width: '120px', background: '#FCFCFC' }}
              />
              <Column
                header="Total"
                frozen
                style={{ width: '140px', background: '#FCFCFC' }}
              />
              {periods.map((period) => (
                <Column
                  key={period}
                  header={formatDate(
                    parseDate(period, 'yyyy-MM', new Date()),
                    'MMM yyyy',
                  )}
                  style={{ width: '140px' }}
                />
              ))}
              <Column
                header="Recognized"
                frozen
                alignFrozen="right"
                style={{ width: '140px', background: '#FCFCFC' }}
              />
              <Column
                header="Remaining"
                frozen
                alignFrozen="right"
                style={{ width: '140px', background: '#FCFCFC' }}
              />
            </Row>
          </ColumnGroup>
        ),
        columns: [
          {
            field: 'period',
            body: monthBodyTemplate,
            frozen: true,
            bodyStyle: {
              minWidth: '140px',
              maxWidth: '140px',
              background: '#FCFCFC',
            },
          },
          {
            field: 'total',
            body: amountBodyTemplate,
            frozen: true,
            bodyStyle: {
              minWidth: '140px',
              maxWidth: '140px',
              background: '#FCFCFC',
            },
          },
          // All months
          ...periods.map((period) => ({
            field: period,
            body: amountBodyTemplate,
            bodyStyle: {
              minWidth: '140px',
              maxWidth: '140px',
            },
          })),
          {
            field: 'recognized',
            body: amountBodyTemplate,
            frozen: true,
            alignFrozen: 'right',
            bodyStyle: {
              minWidth: '140px',
              maxWidth: '140px',
              background: '#FCFCFC',
            },
          },
          {
            field: 'deferred',
            body: amountBodyTemplate,
            frozen: true,
            alignFrozen: 'right',
            bodyStyle: {
              minWidth: '140px',
              maxWidth: '140px',
              background: '#FCFCFC',
            },
          },
        ],
      };
    });
  }, [revRecData]);

  if (!rows || !columns) {
    return null;
  }

  return (
    <MBox className="revenue-table" {...rest}>
      <MDataTable
        hoverable={false}
        value={rows}
        // This ensures that some base table styles are omitted
        cellClassName={(value, options) => 'no-style'}
        scrollable
        columns={columns.columns}
        headerColumnGroup={columns.headerGroup}
        rowClassName={(rowData: any, options) => {
          let classNames = 'disable-clickable-hover-style';
          if (rowData?.type === 'TOTAL') {
            classNames += ` total-row`;
          }
          return classNames;
        }}
      />
    </MBox>
  );
};
