import { ColumnProps } from 'primereact/column';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { useGetListData } from '~app/api/queryUtils';
import { MDataTableFilter } from '~app/components/Monetize/DataTable';
import DataTableActions, {
  ActionType,
} from '~app/components/Monetize/DataTable/MDataTableActions';
import { ROUTES } from '~app/constants';
import {
  CUSTOM_FIELD_ENTITY_DISPLAY,
  CUSTOM_FIELD_STATUS_DISPLAY,
  CUSTOM_FIELD_TYPE_DISPLAY,
} from '~app/constants/customFields';
import { useSettingsReadOnlyRule } from '~app/hooks/useSettingsReadOnlyRule';
import { useACL } from '~app/services/acl/acl';
import { useDocumentHead } from '~app/services/documentHead';
import { useToast } from '~app/services/toast';
import {
  tableFilterSelector,
  tablePagerSelector,
} from '~app/store/global.store';
import { FilterStateKeys } from '~app/store/store.types';
import { buildFilterParamsRequestObject, getFiltersApplied } from '~app/utils';
import { objectToObjArray } from '~app/utils/misc';
import { statusBodyTemplate, textBodyTemplate } from '~app/utils/tableUtils';
import {
  MBox,
  MButton,
  MDataTable,
  MFlex,
  MHStack,
  MPageContainer,
  MPageSearchInput,
  MSettingsPageHeader,
  shouldOpenInNewWindow,
} from '~components/Monetize';
import {
  CustomFieldEntityEnum,
  CustomFieldStatusEnum,
  CustomFieldTypeEnum,
  FilterType,
  FilterTypeOperator,
  GetListApiFilter,
  ICustomFieldResSchema,
} from '~types';

export const CustomFieldsPage = () => {
  const navigate = useNavigate();
  const { setDocTitle } = useDocumentHead();
  const { canDo } = useACL();

  const [initialFilters, persistTableFilter] = useRecoilState(
    tableFilterSelector(FilterStateKeys.CUSTOM_FIELDS_LIST),
  );

  const [pager, setPager] = useRecoilState(
    tablePagerSelector(FilterStateKeys.CUSTOM_FIELDS_LIST),
  );

  const [filters, setFilters] = useState<FilterType[]>(
    initialFilters as FilterType[],
  );
  const [globalFilter, setGlobalFilter] = useState<string>('');
  const [searchKey] = useState<string>('displayLabel');
  const [filterParams, setFilterParams] = useState<GetListApiFilter>(() =>
    buildFilterParamsRequestObject(filters, globalFilter, searchKey),
  );

  useEffect(() => {
    setFilterParams(
      buildFilterParamsRequestObject(filters, globalFilter, searchKey),
    );
  }, [pager, globalFilter, filters, searchKey]);
  const { addToast } = useToast();

  const {
    data: customFieldList,
    isLoading,
    isFetched,
    isRefetching,
    refetch,
    isError,
    error,
  } = useGetListData<ICustomFieldResSchema>('customFields', {
    config: pager,
    filters: filterParams,
  });

  const loading = isLoading || (isRefetching && !isFetched);

  const onResetFilter = () => {
    setFilters([]);
  };

  const { isReadOnly } = useSettingsReadOnlyRule();

  const onSelectEdit = (
    row: ICustomFieldResSchema,
    openInNewWindow = false,
  ) => {
    openInNewWindow
      ? window.open(
          ROUTES.getCustomFieldEditRoute(row.entity, row.key),
          '_blank',
        )
      : navigate(ROUTES.getCustomFieldEditRoute(row.entity, row.key));
  };

  const actionBodyTemplate = (rowData: ICustomFieldResSchema) => {
    if (isReadOnly) {
      return null;
    }

    const actions: ActionType[] = [];

    return <DataTableActions actions={actions} />;
  };

  useEffect(() => {
    persistTableFilter(filters);
  }, [filters, persistTableFilter]);

  const columns: ColumnProps[] = [
    {
      className: 'overflow-hidden',
      field: 'entity',
      header: 'Object',
      sortable: true,
      body: statusBodyTemplate<ICustomFieldResSchema, CustomFieldEntityEnum>(
        'entity',
        CUSTOM_FIELD_ENTITY_DISPLAY,
      ),
    },
    {
      className: 'overflow-hidden',
      field: 'displayLabel',
      header: 'Label',
      sortable: true,
      body: textBodyTemplate<ICustomFieldResSchema>('displayLabel'),
    },
    {
      className: 'overflow-hidden',
      field: 'key',
      header: 'API Name',
      sortable: true,
      body: textBodyTemplate<ICustomFieldResSchema>('key'),
    },
    {
      className: 'overflow-hidden',
      field: 'type',
      header: 'Type',
      sortable: true,
      body: statusBodyTemplate<ICustomFieldResSchema, CustomFieldTypeEnum>(
        'type',
        CUSTOM_FIELD_TYPE_DISPLAY,
      ),
    },
    {
      className: 'overflow-hidden',
      field: 'status',
      header: 'Status',
      sortable: true,
      body: statusBodyTemplate<ICustomFieldResSchema, CustomFieldStatusEnum>(
        'status',
        CUSTOM_FIELD_STATUS_DISPLAY,
      ),
    },
    {
      className: 'overflow-hidden table-cell-sm',
      field: 'action',
      header: '',
      body: actionBodyTemplate,
    },
  ];

  useEffect(() => {
    setDocTitle('Settings', 'Custom Fields');
  }, []);

  const filterOptions = [
    {
      title: 'Object',
      key: 'entity',
      operator: FilterTypeOperator.IN,
      items: objectToObjArray(CUSTOM_FIELD_ENTITY_DISPLAY, 'value', 'label'),
    },
    // {
    //   title: 'Type',
    //   key: 'type',
    //   operator: FilterTypeOperator.IN,
    //   items: objectToObjArray(CUSTOM_FIELD_TYPE_DISPLAY, 'value', 'label'),
    // },
  ];
  const hasFiltersOrSearchApplied =
    getFiltersApplied(filters) > 0 || !!globalFilter;

  const filterComponentReset = React.useRef<any>(null);
  const searchComponentReset = React.useRef<any>(null);

  return (
    <MPageContainer data-testid="customfields" alignItems="stretch">
      <MSettingsPageHeader title="Custom Fields">
        <MHStack spacing="2">
          <MPageSearchInput
            placeholderKey="Label"
            value={globalFilter}
            onChange={(e: any) => setGlobalFilter(e)}
            count={customFieldList?.totalElements}
            resetSearch={searchComponentReset}
          />
          <MDataTableFilter
            filters={filters}
            filterOptions={filterOptions}
            setFilters={(f) => setFilters && setFilters(f)}
            onResetFilter={onResetFilter}
            resetFilter={filterComponentReset}
          />
        </MHStack>
        <MFlex>
          <MButton
            variant="primary"
            ml="2"
            as={Link}
            to={ROUTES.CUSTOM_FIELDS_NEW}
          >
            New
          </MButton>
        </MFlex>
      </MSettingsPageHeader>
      <MBox width="100%">
        <MDataTable
          value={customFieldList?.content}
          totalRecords={customFieldList?.totalElements}
          totalPages={customFieldList?.totalPages}
          pager={pager}
          setPager={setPager}
          rowHover
          singleEmptyPlaceholder
          emptyFilterResultsProps={{
            mainMessage: 'Looks like there are no Custom Fields here.',
            smallMessage: null,
            btnLabel:
              !hasFiltersOrSearchApplied && canDo([['settings', 'create']])
                ? 'New Custom Field'
                : null,
            to: ROUTES.CUSTOM_FIELDS_NEW,
            secondaryBtnLabel: hasFiltersOrSearchApplied
              ? 'Clear Search and Filters'
              : undefined,
          }}
          className="p-datatable-responsive"
          loading={loading}
          columns={columns}
          filtersApplied={hasFiltersOrSearchApplied}
          resetFilter={() => {
            filterComponentReset.current && filterComponentReset.current();
            searchComponentReset.current && searchComponentReset.current();
          }}
          onRowClick={(e) =>
            onSelectEdit(
              e.data as ICustomFieldResSchema,
              shouldOpenInNewWindow(e),
            )
          }
          errorProps={{
            isFetchingError: isError,
            error: error,
            onRefetchData: refetch,
          }}
        />
      </MBox>
    </MPageContainer>
  );
};
