import { FormControlProps, TextareaProps } from '@chakra-ui/react';
import { Control, Controller, useController } from 'react-hook-form';
import { MTextarea } from './chakra';
import MFormField from './MFormField';

export interface MIEmailTextareaProps extends FormControlProps {
  tooltip: string;
  fieldName: string;
  placeholder: string;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  control: Control<any>;
  textAreaProps?: TextareaProps;
}

const MEmailTextarea = ({
  label,
  control,
  tooltip,
  fieldName,
  placeholder,
  isDisabled = false,
  isReadOnly = false,
  textAreaProps,
}: MIEmailTextareaProps) => {
  const variant = isReadOnly ? 'readonly' : 'primary';

  const {
    formState: { errors },
  } = useController({ name: fieldName, control });
  return (
    <MFormField
      error={errors?.[fieldName]}
      label={label}
      tooltip={tooltip}
      data-testid="email-text-area"
    >
      <Controller
        name={fieldName}
        control={control}
        render={({ field: { value, ...rest } }) => (
          <MTextarea
            isDisabled={isDisabled}
            isReadOnly={isReadOnly}
            variant={variant}
            value={value || ''}
            placeholder={placeholder}
            spellCheck={false}
            rows={2}
            minH={8}
            {...textAreaProps}
            {...rest}
          />
        )}
      />
    </MFormField>
  );
};

export default MEmailTextarea;
