import * as z from 'zod';
import { getRequiredMessage } from '~app/utils/messages';
import { UserStatusEnumZ } from './userTypes';

export enum TeamTypeEnum {
  SALES = 'SALES',
  APPROVAL = 'APPROVAL',
}
export const TeamTypeEnumZ = z.nativeEnum(TeamTypeEnum);
export interface ITeam {
  createdBy?: string;
  id?: string;
  name: string;
  type: TeamTypeEnum;
  userIds?: string[];
  users?: any[];
  owner: string;
}

export const UsersInfo = z.object({
  id: z.string(),
  name: z.string(),
  mobile: z.string().nullish(),
  email: z.string(),
  companyName: z.string().nullish(),
  status: UserStatusEnumZ,
});
export type IUsersInfo = z.infer<typeof UsersInfo>;

export const TeamSchema = z.object({
  id: z.string().optional(),
  name: z.string().min(1, { message: getRequiredMessage('Name') }),
  type: TeamTypeEnumZ,
  users: z.array(UsersInfo).optional(),
  owner: z.string().min(1, { message: getRequiredMessage('Owner') }),
});

export type ITeamSchema = z.infer<typeof TeamSchema>;

export const TeamResponseSchema = z.object({
  id: z.string(),
  name: z.string(),
  type: TeamTypeEnumZ,
  users: z.array(UsersInfo),
  owner: z.string(),
});
export type ITeamResponseSchema = z.infer<typeof TeamResponseSchema>;

export const TeamUpdateSchema = z.object({
  name: z.string(),
  type: TeamTypeEnumZ,
  owner: z.string(),
  userIds: z.array(z.string()),
});
export type ITeamUpdateSchema = z.infer<typeof TeamUpdateSchema>;

export const TeamOwnerUpdateSchema = z.object({
  id: z.string().optional(),
  userId: z.string().nonempty(getRequiredMessage('Owner')),
});

export type ITeamOwnerUpdateSchema = z.infer<typeof TeamOwnerUpdateSchema>;
