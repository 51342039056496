import { FunctionComponent as FC, useState } from 'react';
import {
  MBox,
  MButton,
  MCenterModal,
  MPageLoader,
  MStack,
} from '~app/components/Monetize';
import { IQuoteRequestSchema, IQuoteRespSchema } from '~app/types';
import { useQuoteDocuments } from './useQuoteDocuments';
import { Divider } from '@chakra-ui/react';
import { QuoteDocumentsModalV2Item } from './QuoteDocumentsModalV2Item';
import { useFlags } from '~app/services/launchDarkly';

interface QuoteDocumentsModalV2Props {
  quote: IQuoteRespSchema;
  disabled?: boolean;
  onUpdate: (data: IQuoteRequestSchema) => void;
  isOpen: boolean;
  onClose: (didSave?: boolean) => void;
}

export const QuoteDocumentsModalV2: FC<QuoteDocumentsModalV2Props> = ({
  quote,
  disabled,
  onUpdate,
  isOpen,
  onClose,
}: QuoteDocumentsModalV2Props) => {
  const [saving, setSaving] = useState(false);

  const {
    quoteDocument,
    msaData,
    sowData,
    pdfCoverData,
    pdfFooterData,

    isLoadingInitialData,

    handleRemoveItem,
    handleGoToItem,
  } = useQuoteDocuments({
    quote,
    setSaving,
    saving,
    onUpdate,
    handleClose,
  });

  const featureFlags = useFlags();
  function handleClose() {
    if (saving) {
      return;
    }
    onClose();
  }
  console.log(msaData, sowData);
  return (
    <MCenterModal
      isOpen={isOpen}
      onClose={handleClose}
      modalTitle="Documents"
      closeOnOverlayClick
      closeOnEsc
      modalHeaderProps={{ fontSize: 'lg', display: 'flex' }}
      renderFooter={() => (
        <MStack
          spacing={4}
          direction="row"
          align="center"
          justify="right"
          flex={1}
        >
          <MButton
            minW="auto"
            variant="cancel"
            isDisabled={saving}
            onClick={handleClose}
          >
            Close
          </MButton>
        </MStack>
      )}
    >
      <MBox>
        {isLoadingInitialData && <MPageLoader maxH={100} />}

        {!isLoadingInitialData && (
          <>
            {featureFlags.pdfCoverFooter && (
              <>
                <QuoteDocumentsModalV2Item
                  label="Cover"
                  linkUrl={pdfCoverData.linkUrl}
                  savedItemText={pdfCoverData.savedItemText}
                  onRemoveItem={() => handleRemoveItem('cover')}
                  onGotoSavedItem={() => handleGoToItem('cover')}
                />

                <Divider my={6} borderWidth="1px" borderColor="tBlue.hover" />

                <QuoteDocumentsModalV2Item
                  label="Footer"
                  linkUrl={pdfFooterData.linkUrl}
                  savedItemText={pdfFooterData.savedItemText}
                  onRemoveItem={() => handleRemoveItem('footer')}
                  onGotoSavedItem={() => handleGoToItem('footer')}
                />

                <Divider my={6} borderWidth="1px" borderColor="tBlue.hover" />
              </>
            )}

            <QuoteDocumentsModalV2Item
              label="Statement of Work (SOW)"
              linkUrl={sowData.linkUrl}
              savedItemText={sowData.savedItemText}
              onRemoveItem={() => handleRemoveItem('sow')}
              onGotoSavedItem={() => handleGoToItem('sow')}
            />

            <Divider my={6} borderWidth="1px" borderColor="tBlue.hover" />

            <QuoteDocumentsModalV2Item
              label="Master Service Agreement (MSA)"
              linkUrl={msaData.linkUrl}
              savedItemText={msaData.savedItemText}
              onRemoveItem={() => handleRemoveItem('msa')}
              onGotoSavedItem={() => handleGoToItem('msa')}
            />

            {quoteDocument.data && (
              <>
                <Divider my={6} borderWidth="1px" borderColor="tBlue.hover" />

                <QuoteDocumentsModalV2Item
                  label="Signed Quote"
                  linkUrl=""
                  savedItemText={quoteDocument.data.filename}
                  onGotoSavedItem={() => handleGoToItem('signedQuote')}
                />
              </>
            )}
          </>
        )}
      </MBox>
    </MCenterModal>
  );
};
