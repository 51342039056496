import { Card, CardBody } from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { MdOutlineImage } from 'react-icons/md';
import { useNavigate } from 'react-router';
import { handleApiErrorToast } from '~app/api/axios';
import { legalEntitiesServiceQueryKeys } from '~app/api/legalEntityService';
import { useGetListData, usePerformEntityAction } from '~app/api/queryUtils';
import { updateListCacheWithUpdatedItem } from '~app/api/queryUtilsHelpers';
import {
  MBox,
  MButton,
  MCenter,
  MFlex,
  MIcon,
  MImage,
  MPageContainer,
  MPageLoader,
  MSettingsPageHeader,
  MSkeleton,
  MTag,
  MText,
} from '~app/components/Monetize';
import { MDataTableActions } from '~app/components/Monetize/DataTable';
import { ROUTES } from '~app/constants';
import { useLegalEntityLogo } from '~app/hooks/useLegalEntityLogo';
import { DEFAULT_PAGER } from '~app/types';
import { ILegalEntityResponseSchema } from '~app/types/legalEntityTypes';
import { sortAlphabetically } from '~app/utils';

interface LegalEntityProps {
  id: string;
  name: string;
  isDefaultEntity: boolean;
}
const LegalEntity = ({ id, name, isDefaultEntity }: LegalEntityProps) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { isLoading, base64 } = useLegalEntityLogo({ id });

  const { mutateAsync: setToDefaultLegalEntity } =
    usePerformEntityAction<ILegalEntityResponseSchema>('legalEntities', {
      onSuccess: (legalEntityData) => {
        updateListCacheWithUpdatedItem(
          queryClient,
          [...legalEntitiesServiceQueryKeys.legalEntityList()],
          { ...legalEntityData, defaultEntity: false },
        );
        queryClient.invalidateQueries([
          ...legalEntitiesServiceQueryKeys.legalEntityList(),
        ]);
      },
      onError: (err) => handleApiErrorToast(err),
    });

  const actions = [
    {
      title: 'Set to Default Entity',
      enabled: !isDefaultEntity,
      action: () => {
        setToDefaultLegalEntity({ id, action: 'default' });
      },
    },
  ];

  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const shouldOpenInNewWindow =
      event.metaKey || event.ctrlKey || event.shiftKey || event.button === 1;
    if (shouldOpenInNewWindow) {
      // Prevent the event from propagating if the user is opening the link in a new tab
      event.preventDefault();
      window && window.open(ROUTES.getLegalEntityEditRoute(id), '_blank');
      return;
    } else {
      navigate(ROUTES.getLegalEntityEditRoute(id));
      return;
    }
  };
  return (
    <Card
      variant="entity"
      borderColor="tGray.support"
      width="200px"
      role="group"
      onClick={handleClick}
      onAuxClick={handleClick}
    >
      <CardBody>
        {isDefaultEntity && (
          <MTag position="absolute" top="4" left="4" variant="blue">
            Default Entity
          </MTag>
        )}
        {!isDefaultEntity && (
          <MDataTableActions
            boxProps={{
              position: 'absolute',
              top: 2,
              right: 2,
              display: 'none',
              _groupHover: {
                display: 'block',
              },
            }}
            actions={actions}
          />
        )}

        <MBox>
          {isLoading && <MSkeleton height={12} w="full" />}
          {!isLoading && (
            <MCenter my="6">
              {!base64 && (
                <MIcon
                  as={MdOutlineImage}
                  w={12}
                  h={12}
                  color={'tGray.darkPurple'}
                />
              )}
              {!!base64 && <MImage maxH={8} src={`${base64}`} />}
            </MCenter>
          )}
          <MText
            textAlign="center"
            fontSize="md"
            fontWeight="600"
            noOfLines={3}
          >
            {name}
          </MText>
        </MBox>
      </CardBody>
    </Card>
  );
};

const CompanySetting = () => {
  const navigate = useNavigate();
  const { data, isLoading } = useGetListData<ILegalEntityResponseSchema>(
    'legalEntities',
    { config: DEFAULT_PAGER },
    {
      select: (entityItems) => {
        const { content: entityContent } = entityItems;
        const defaultEntity = entityContent.filter(
          (entity) => entity.defaultEntity,
        );
        const alphabeticallySortedEntity = entityContent
          .filter((entityItem) => !entityItem.defaultEntity)
          .sort((a, b) => sortAlphabetically('name')(a, b));
        const allSortedEntity = [
          ...defaultEntity,
          ...alphabeticallySortedEntity,
        ];

        return { ...entityItems, content: allSortedEntity };
      },
    },
  );

  if (isLoading) {
    return <MPageLoader />;
  }
  const entityList = data?.content || [];

  return (
    <MPageContainer>
      <MSettingsPageHeader title="Company Settings">
        <MButton
          type="submit"
          variant="primary"
          onClick={() => navigate(ROUTES.LEGAL_ENTITY_NEW)}
        >
          New Entity
        </MButton>
      </MSettingsPageHeader>
      <MFlex flexWrap="wrap" gap={4} width="100%">
        {entityList.map(({ id, name, defaultEntity }) => {
          return (
            <LegalEntity
              key={id}
              id={id}
              name={name}
              isDefaultEntity={defaultEntity}
            />
          );
        })}
      </MFlex>
    </MPageContainer>
  );
};

export default CompanySetting;
