import { FunctionComponent as FC } from 'react';
import { FilterTypeGlte, RenderTableFilterOptionProps } from '~app/types';
import { MHStack } from '../../chakra';
import { MCurrencyInput } from '../../MCurrencyInput';
import MText from '../../MText';

interface AmountTableFilterOptionContentProps
  extends RenderTableFilterOptionProps {}

export const AmountTableFilterOptionContent: FC<
  AmountTableFilterOptionContentProps
> = ({
  filter,
  filterOption,
  handleFilterChange,
}: AmountTableFilterOptionContentProps) => {
  const internalFilter = filter as FilterTypeGlte;

  return (
    <MHStack>
      <MCurrencyInput
        value={internalFilter?.value?.min || ''}
        placeholder="Min"
        onChange={(val) =>
          handleFilterChange(
            {
              ...internalFilter?.value,
              min: val,
            },
            filterOption,
          )
        }
      />
      <MText>-</MText>
      <MCurrencyInput
        value={internalFilter?.value?.max || ''}
        placeholder="Max"
        onChange={(val) =>
          handleFilterChange(
            {
              ...internalFilter?.value,
              max: val,
            },
            filterOption,
          )
        }
      />
    </MHStack>
  );
};
