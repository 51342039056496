import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';
import { apiDelete, apiGet, apiGetAllList, apiPost, apiPut } from '~api/axios';
import {
  ApiListResponse,
  GetListApiFilter,
  IPaymentGateway,
  ITenant,
  Maybe,
  TDataTablePager,
  TenantRequest,
  TenantUserMap,
} from '~types';
import { TenantStatusEnum } from '../constants/tenants';
import { useAuth } from '../services/auth0';
import { nullifyEmptyStrings } from '../utils/misc';
import { composeGetQuery } from './utils';

export const tenantQueryKeys = {
  base: ['tenant'] as const,
  tenantList: () => [...tenantQueryKeys.base, 'list'] as const,
  tenantDetail: (id: string) => [...tenantQueryKeys.base, id] as const,
  paymentGateways: (tenantId: string) =>
    [...tenantQueryKeys.tenantDetail(tenantId), 'payment-gateways'] as const,
};

export function usePaymentGatewaysByTenant<
  SelectData = ApiListResponse<IPaymentGateway>,
>(
  tenantId: string,
  options?: Partial<
    UseQueryOptions<ApiListResponse<IPaymentGateway>, unknown, SelectData>
  >,
) {
  return useQuery<ApiListResponse<IPaymentGateway>, unknown, SelectData>(
    [...tenantQueryKeys.paymentGateways(tenantId)],
    {
      queryFn: () =>
        apiGet<ApiListResponse<IPaymentGateway>>(`/api/paymentGateways`).then(
          (res) => res.data,
        ),
      refetchOnWindowFocus: false,
      ...options,
    },
  );
}

export const useCreateTenant = (
  options: {
    onError?: (err: unknown) => void;
    onSuccess?: (data: ITenant) => void;
  } = {},
) => {
  const { getAndHandleAccessTokenSilently } = useAuth();
  const { onSuccess, ...restOptions } = options;
  const queryClient = useQueryClient();
  return useMutation<ITenant, unknown, TenantRequest>({
    mutationFn: (data) =>
      apiPost<ITenant>('/api/tenants', nullifyEmptyStrings(data), {
        axiosConfig: { excludeTenantIdFromHeader: true },
      }).then((res) => res.data),
    onSuccess: async (data) => {
      // clear all cache for all queries and update access token
      queryClient.clear();
      await getAndHandleAccessTokenSilently();
      onSuccess?.(data);
    },
  });
};

export const useUpdateTenant = (
  options: {
    onError?: (err: unknown) => void;
    onSuccess?: (data: ITenant) => void;
  } = {},
) => {
  const { getAndHandleAccessTokenSilently } = useAuth();
  const { onSuccess, ...restOptions } = options;
  const queryClient = useQueryClient();
  return useMutation<ITenant, unknown, { id: string; data: TenantRequest }>({
    mutationFn: ({ id, data }) =>
      apiPut<ITenant>(`/api/tenants/${id}`, nullifyEmptyStrings(data), {
        axiosConfig: { customXTenantId: id },
      }).then((res) => res.data),
    onSuccess: async (data) => {
      // invalidate query cache and update access token
      queryClient.invalidateQueries(tenantQueryKeys.tenantList());
      queryClient.setQueryData(tenantQueryKeys.tenantDetail(data.id), data);
      await getAndHandleAccessTokenSilently();
      onSuccess?.(data);
    },
  });
};

export const useDeleteTenant = (
  options: {
    onError?: (err: unknown) => void;
    onSuccess?: (data: ITenant) => void;
  } = {},
) => {
  const { getAndHandleAccessTokenSilently } = useAuth();
  const { onSuccess, ...restOptions } = options;
  const queryClient = useQueryClient();
  return useMutation<ITenant, unknown, { id: string }>({
    mutationFn: ({ id }) =>
      apiDelete<ITenant>(`/api/tenants/${id}`, {
        axiosConfig: { customXTenantId: id },
      }).then((res) => res.data),
    onSuccess: async (data) => {
      queryClient.invalidateQueries(tenantQueryKeys.tenantList());
      queryClient.removeQueries(tenantQueryKeys.tenantDetail(data.id));
      await getAndHandleAccessTokenSilently();
      onSuccess?.(data);
    },
  });
};

export const useAcceptInvite = (
  options: {
    onError?: (err: unknown) => void;
    onSuccess?: (data: TenantUserMap) => void;
  } = {},
) => {
  const { getAndHandleAccessTokenSilently } = useAuth();
  const { onSuccess, ...restOptions } = options;
  const queryClient = useQueryClient();
  return useMutation<TenantUserMap, unknown, { id: string }>({
    mutationFn: async ({ id }) => {
      await getAndHandleAccessTokenSilently();
      return apiPost<TenantUserMap>(
        `/api/invites/accept`,
        {},
        { axiosConfig: { customXTenantId: id } },
      ).then((res) => res.data);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(tenantQueryKeys.tenantList());
      queryClient.invalidateQueries(
        tenantQueryKeys.tenantDetail(data.tenant.id),
      );
      onSuccess?.(data);
    },
    ...restOptions,
  });
};

export const useGetTenantById = (
  tenantId: string,
  options: UseQueryOptions<ITenant, unknown, ITenant> = {},
) => {
  const { onSuccess, ...restOptions } = options;
  const queryClient = useQueryClient();
  return useQuery<ITenant, unknown, ITenant>(
    [...tenantQueryKeys.tenantDetail(tenantId)],
    {
      queryFn: () =>
        apiGet<ITenant>(`/api/tenants/${tenantId}`, {
          axiosConfig: { customXTenantId: tenantId },
        }).then((res) => res.data),
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        queryClient.invalidateQueries(tenantQueryKeys.tenantList());
        onSuccess?.(data);
      },
      ...restOptions,
    },
  );
};

export const useGetTenants = (
  {
    config,
    filters,
  }: {
    config: TDataTablePager;
    filters?: GetListApiFilter;
  },
  options: UseQueryOptions<ITenant[], unknown, ITenant[]> = {},
) => {
  const params = composeGetQuery(config, filters);
  return useQuery<ITenant[], unknown, ITenant[]>(
    [...tenantQueryKeys.tenantList(), params],
    {
      queryFn: () =>
        apiGetAllList<ITenant>('/api/tenants', {
          ...config,
          config: {
            axiosConfig: { excludeTenantIdFromHeader: true },
          },
        }).then((res) => res),
      refetchOnWindowFocus: false,
      ...options,
    },
  );
};

/**
 * Fetch tenant and auto-accept invite for the provided tenant, if required
 *
 * @param param0
 * @param options
 * @returns
 */
export const useGetTenantAndAutoAcceptInvite = (
  {
    onAcceptFailure,
  }: {
    onAcceptFailure: (ex: unknown) => void;
  },
  options: {
    onError?: (err: unknown) => void;
    onSuccess?: (data: Maybe<ITenant>) => void;
  } = {},
) => {
  const { getAndHandleAccessTokenSilently } = useAuth();
  const { onSuccess, ...restOptions } = options;
  const queryClient = useQueryClient();
  const { mutateAsync: doAcceptInvite } = useAcceptInvite();
  return useMutation<Maybe<ITenant>, unknown, { id: string }>({
    mutationFn: async ({ id }) => {
      await getAndHandleAccessTokenSilently();

      // Attempt to auto-accept invite if required
      const params = composeGetQuery({ first: 0, rows: 1, page: 0 }, { id });
      const tenantStatus = await apiGet<ApiListResponse<ITenant>>(
        '/api/tenants',
        {
          params,
          axiosConfig: { excludeTenantIdFromHeader: true },
        },
      ).then((res) => res.data.content[0]?.status);

      if (tenantStatus === TenantStatusEnum.INVITED) {
        try {
          await doAcceptInvite({ id });
          queryClient.invalidateQueries([...tenantQueryKeys.tenantList()]);
        } catch (ex) {
          onAcceptFailure(ex);
          return;
        }
      }

      // re-fetch tenant
      const tenant = await apiGet<ITenant>(`/api/tenants/${id}`, {
        axiosConfig: { customXTenantId: id },
      }).then((res) => res.data);

      queryClient.setQueriesData(
        tenantQueryKeys.tenantDetail(tenant.id),
        tenant,
      );

      return tenant;
    },
    onSuccess: async (data) => {
      await getAndHandleAccessTokenSilently();
      onSuccess?.(data);
    },
  });
};
