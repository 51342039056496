import { Box, BoxProps, Flex, TextProps } from '@chakra-ui/react';
import React, { FunctionComponent as FC } from 'react';
import { MPageHeaderLeftSection } from './MPageHeaderLeftSection';

interface MPageHeaderProps {
  title?: string | React.ReactNode;
  id?: string;
  size?: string;
  tag?: React.ReactNode; // TODO: Rename tag prop to something generic
  tagStyle?: TextProps;
  subTitle?: React.ReactNode;
  hasBackButton?: boolean;
  backButtonTitle?: string;
  backButtonLink?: string | object;
  backButtonCallback?: () => void;
  children?: React.ReactNode;
  hideContent?: boolean;
  status?: string;
  copyUrl?: string | boolean;
  containerProps?: BoxProps;
  parentLink?: string;
  parentLinkTitle?: string;
  /** Alternate react component for the id */
  customIdCopyBox?: React.ReactNode;
  /** Added after parent link and id with dividers between */
  extraSubtitleParts?: React.ReactNode[];
  renderLeftSection?: React.ReactNode;
  firstRowPlaceholder?: React.ReactNode;
  childrenProps?: Omit<BoxProps, 'alignItems' | 'as'>;
}

const MPageHeader: FC<MPageHeaderProps> = React.forwardRef<
  any,
  MPageHeaderProps
>(
  (
    {
      title = '',
      id = '',
      size = 'lg',
      tag = '',
      tagStyle,
      subTitle,
      hasBackButton = false,
      backButtonTitle = '',
      backButtonLink = '',
      backButtonCallback = () => {},
      children = null,
      hideContent = false,
      status,
      copyUrl,
      containerProps,
      parentLink,
      parentLinkTitle,
      customIdCopyBox,
      extraSubtitleParts,
      renderLeftSection,
      firstRowPlaceholder,
      childrenProps,
    }: MPageHeaderProps,
    ref,
  ) => {
    if (hideContent) {
      return null;
    }
    return (
      <Box position="relative" w="100%" mb={4} {...containerProps}>
        <Flex justifyContent="space-between" flexWrap="wrap">
          {renderLeftSection}
          {!renderLeftSection && (
            <MPageHeaderLeftSection
              hasBackButton={hasBackButton}
              backButtonLink={backButtonLink}
              backButtonTitle={backButtonTitle}
              size={size}
              title={title}
              tag={tag}
              tagStyle={tagStyle}
              parentLink={parentLink}
              parentLinkTitle={parentLinkTitle}
              id={id}
              copyUrl={copyUrl}
              customIdCopyBox={customIdCopyBox}
              extraSubtitleParts={extraSubtitleParts}
              subTitle={subTitle}
              status={status}
              backButtonCallback={backButtonCallback}
              firstRowPlaceholder={firstRowPlaceholder}
            />
          )}

          <Flex alignItems="center" {...childrenProps}>
            {children}
          </Flex>
        </Flex>
      </Box>
    );
  },
);

export default MPageHeader;
