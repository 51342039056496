import isNumber from 'lodash/isNumber';
import { v4 as uuidv4 } from 'uuid';
import {
  IPriceResSchema,
  IPriceUnderProductSchemaUI,
  IRateResSchema,
  PriceModelEnum,
} from '~app/types';
import { sortByProductType } from '.';

export function getOrderedPricesUnderProduct(rate: IRateResSchema) {
  const sortedPricesWithAccumulatedProducts = rate.prices?.reduce(
    (acc: any[], curr: IPriceResSchema, _: any, arr: IPriceResSchema[]) => {
      const isProductExist = acc.find(
        ({ id: productId }) => productId === curr.product.id,
      );

      if (isProductExist || !curr) {
        return acc;
      }

      return [
        ...acc,
        {
          ...curr.product,
          prices: arr
            .filter(({ product }) => product.id === curr.product.id)
            .sort((a, b) => a.from - b.from),
        },
      ];
    },
    [] as IPriceUnderProductSchemaUI[],
  );
  return (
    sortedPricesWithAccumulatedProducts
      ? sortByProductType(sortedPricesWithAccumulatedProducts)
      : []
  ) as IPriceUnderProductSchemaUI[];
}

export function getNextTierPrice({
  productId,
  previousFrom,
  previousTo,
  previousPriceModel,
  previousAmount,
}: {
  productId: string;
  previousFrom: null | number;
  previousTo: null | number;
  previousPriceModel?: PriceModelEnum;
  previousAmount: null | number;
}) {
  const uid = uuidv4();

  let newFromValue = 0;

  if (isNumber(previousTo) && !isNaN(previousTo)) {
    newFromValue = previousTo + 1;
  } else if (isNumber(previousFrom) && !isNaN(previousFrom)) {
    newFromValue = previousFrom + 1;
  }

  return {
    id: uid,
    productId,
    description: '',
    priceModel: previousPriceModel,
    from: newFromValue,
    to: null,
    amount: previousAmount,
  };
}
