import { Fragment } from 'react';
import { MBox, MFlex, MSkeleton, MText } from '~app/components/Monetize';
import { MCurrency } from '~app/components/Monetize/MCurrency';
import { MPercent } from '~app/components/Monetize/MPercent';
import MRowWithLineSeparator from '~app/components/Monetize/MRowWithLineSeparator';
import { PRODUCT_TYPE_DISPLAY_SHORT } from '~app/constants/products';
import {
  IOfferingSummaryResSchema,
  IQuotePrice,
  OfferingTypesEnum,
} from '~app/types';

const PricesSectionContent = ({
  quotePrices,
  loading,
  currency,
  offeringsById = {},
}: {
  quotePrices: IQuotePrice[];
  loading: boolean;
  currency: string;
  offeringsById?: Record<string, IOfferingSummaryResSchema>;
}) => {
  return (
    <>
      {loading ? (
        <>
          <MSkeleton height="5" width="30%" mb="2" />
          <MSkeleton height="3" width="20%" mb="2" />
          <MSkeleton height="3" width="100%" mb="2" />
        </>
      ) : (
        <>
          {quotePrices.map((quotePrice, index) => {
            const offering = offeringsById[quotePrice.offering.id];
            const isPoTOfferingType =
              offering?.type === OfferingTypesEnum.CUSTOM_PERCENT_OF_TOTAL;
            return (
              <Fragment key={index}>
                {quotePrice.schedule.map(
                  (quotePriceSchedule, quotePriceScheduleIndex) => (
                    <Fragment key={quotePriceScheduleIndex}>
                      <MFlex>
                        <MText fontWeight="bold" fontSize="md" my="2">
                          {quotePrice.offering.name}{' '}
                          {quotePriceSchedule.rate?.name
                            ? `- ${quotePriceSchedule.rate.name}`
                            : ''}
                        </MText>
                      </MFlex>

                      {quotePriceSchedule.productPrices.map(
                        (price, priceIndex) => (
                          <MBox key={priceIndex}>
                            <MFlex
                              flexDir="row"
                              align="center"
                              justify="space-between"
                            >
                              <MRowWithLineSeparator
                                my={0.5}
                                lineProps={{ background: 'transparent' }}
                                leftLabel={
                                  <MFlex width="100%">
                                    <MText fontWeight="bold" fontStyle="italic">
                                      {price.product.name}&nbsp;
                                    </MText>
                                    <MText
                                      fontWeight="medium"
                                      fontStyle="italic"
                                    >
                                      (
                                      {
                                        PRODUCT_TYPE_DISPLAY_SHORT[
                                          price.product.productType
                                        ]
                                      }
                                      )
                                    </MText>
                                  </MFlex>
                                }
                                rightLabel={
                                  index == 0 &&
                                  quotePriceScheduleIndex == 0 &&
                                  priceIndex === 0 && (
                                    <MFlex>
                                      <MText
                                        fontWeight="bold"
                                        textAlign="right"
                                        width={100}
                                        style={{ whiteSpace: 'nowrap' }}
                                      >
                                        Amount
                                      </MText>
                                    </MFlex>
                                  )
                                }
                              />
                            </MFlex>
                            {price.prices.map(
                              (productPrice, productPriceIndex) => (
                                <MFlex
                                  flexDir="row"
                                  align="center"
                                  justify="space-between"
                                  key={productPriceIndex}
                                >
                                  <MRowWithLineSeparator
                                    my={0.5}
                                    lineProps={{
                                      background: 'tBlue.lightShade',
                                    }}
                                    leftLabel={
                                      <MText>
                                        {productPrice.from} -{' '}
                                        {productPrice.to || '∞'}
                                      </MText>
                                    }
                                    rightLabel={
                                      <MFlex>
                                        {isPoTOfferingType && (
                                          <>
                                            <MPercent
                                              amount={productPrice.net}
                                            />
                                            <MText ml="1" as="span">
                                              of other products
                                            </MText>
                                          </>
                                        )}
                                        {!isPoTOfferingType && (
                                          <MCurrency
                                            amount={productPrice.net}
                                            width={100}
                                            textAlign="right"
                                            options={{
                                              maximumFractionDigits: 'max',
                                              currency,
                                            }}
                                          />
                                        )}
                                      </MFlex>
                                    }
                                  />
                                </MFlex>
                              ),
                            )}
                          </MBox>
                        ),
                      )}
                    </Fragment>
                  ),
                )}
              </Fragment>
            );
          })}
        </>
      )}
    </>
  );
};
export default PricesSectionContent;
