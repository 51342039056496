import { useDisclosure } from '@chakra-ui/react';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  useGetInvoiceByShareId,
  useSetupIntentForInvoiceShare,
} from '~app/api/shareService';
import AppLoading from '~app/components/App/AppLoading';
import InvoiceDisplay from '~app/components/Invoices/InvoiceDisplay';
import {
  MBox,
  MButton,
  MFlex,
  MPageContainer,
  MText,
} from '~app/components/Monetize';
import { MEntityStatusBadge } from '~app/components/Monetize/MEntityStatusBadge';
import { InvoiceStatusEnumDisplay } from '~app/constants/invoice';
import { PAPER_PAGE_MAX_WIDTH } from '~app/constants/quotes';
import { useFlags } from '~app/services/launchDarkly';
import { InvoiceStatusEnum } from '~types';
import { SharedInvoicePaymentModal } from './components/SharedInvoicePaymentModal';
import { ViewInvoiceActions } from './components/ViewInvoiceActions';

export const InvoiceShareDetails = () => {
  const { tenantId = '', invoiceSecretId = '' } = useParams();
  const { anonymousInvoiceAllowPaymentAmount } = useFlags();
  const paymentModalState = useDisclosure();
  const [publicKey, setPublicKey] = useState('');
  const [clientSecret, setClientSecret] = useState('');
  const [paymentMethodError, setPaymentMethodError] = useState<boolean>(false);
  const [displaySticky, setDisplaySticky] = useState<boolean>(false);

  const { mutateAsync: generateSetupIntent } = useSetupIntentForInvoiceShare();

  useEffect(() => {
    const run = async () => {
      try {
        const apiResp = await generateSetupIntent({
          invoiceSecretId,
          tenantId,
        });
        setClientSecret(apiResp.clientSecret);
        setPublicKey(apiResp.publicKey);
      } catch (err: any) {
        setPaymentMethodError(true);
      }
    };
    if (invoiceSecretId) {
      run();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceSecretId, generateSetupIntent]);

  const {
    isLoading,
    data: shareInvoiceData,
    error: shareInvoiceError,
  } = useGetInvoiceByShareId(invoiceSecretId, tenantId);
  const invoice = shareInvoiceData?.invoice;
  const unpaidInvoices = shareInvoiceData?.unpaidInvoices;

  const handlePaymentModalClose = () => paymentModalState.onClose();

  const canPayInvoice = useMemo(() => {
    try {
      const isUnpaid =
        invoice?.status === InvoiceStatusEnum.UNPAID &&
        !invoice?.paid &&
        invoice.amount > 0;

      const allowAnonymousPayment =
        isUnpaid &&
        (anonymousInvoiceAllowPaymentAmount === -1 ||
          invoice.amount < anonymousInvoiceAllowPaymentAmount);

      return isUnpaid && allowAnonymousPayment && !paymentMethodError;
    } catch (ex) {
      console.error(ex);
      return false;
    }
  }, [
    anonymousInvoiceAllowPaymentAmount,
    invoice?.amount,
    invoice?.paid,
    invoice?.status,
    paymentMethodError,
  ]);

  useEffect(() => {
    const handleStickyMenu = () => {
      window.scrollY > 50 ? setDisplaySticky(true) : setDisplaySticky(false);
    };

    window.addEventListener('scroll', handleStickyMenu);

    return () => {
      window.removeEventListener('scroll', handleStickyMenu);
    };
  }, []);

  if (isLoading) {
    return <AppLoading />;
  }

  if (shareInvoiceError) {
    return (
      <MBox textAlign="center" mt={'10%'}>
        <MText fontSize={24} fontWeight="700">
          Error
        </MText>
        <MText fontSize={18} fontWeight={400}>
          There was a problem processing your request.
        </MText>
      </MBox>
    );
  }

  return (
    <MPageContainer height="auto" p="6">
      <MBox
        width="100%"
        display="flex"
        flexDir="column"
        alignItems="center"
        pb="10"
      >
        <MFlex
          alignContent="center"
          alignItems="center"
          justifyContent="space-between"
          maxW={PAPER_PAGE_MAX_WIDTH}
          w="100%"
          pb={[4, 0, 7]}
          pt={[4, 0]}
        >
          {invoice?.status && (
            <MFlex alignItems="center">
              <MText
                fontWeight="bold"
                display={['none', 'none', 'inline']}
                mr=".5rem"
              >
                Status:
              </MText>
              <MEntityStatusBadge
                ml="0"
                status={
                  invoice?.status
                    ? InvoiceStatusEnumDisplay[invoice?.status]
                    : ''
                }
              />
            </MFlex>
          )}

          <MFlex alignItems="center">
            {canPayInvoice && (
              <MButton
                data-testid="pay-invoice-button"
                variant="primary"
                onClick={paymentModalState.onOpen}
                mr="2"
                display={['none', 'none', 'block']}
              >
                Pay Invoice
              </MButton>
            )}
            {!!invoice && (
              <ViewInvoiceActions
                invoice={invoice}
                invoiceSecretId={invoiceSecretId}
                tenantId={tenantId}
              />
            )}
          </MFlex>
        </MFlex>
        {canPayInvoice && (
          <MFlex
            w="100%"
            alignItems="center"
            justifyContent="center"
            position={displaySticky ? 'fixed' : 'relative'}
            bottom={displaySticky ? '0' : 'auto'}
            zIndex={1}
            bg="#FFF"
            boxShadow={displaySticky ? '0px -3px 14px 0px #18243C14' : 'none'}
            p="4"
          >
            <MButton
              data-testid="pay-invoice-button"
              variant="primary"
              onClick={paymentModalState.onOpen}
              display={['block', 'block', 'none']}
              size="lg"
              w="240px"
              minW="0"
            >
              Pay Invoice
            </MButton>
          </MFlex>
        )}
        <InvoiceDisplay
          isInvoiceSharePage
          loadingInvoice={isLoading}
          invoice={invoice}
          unpaidInvoices={unpaidInvoices}
          invoiceSettings={shareInvoiceData?.invoiceSettings}
          canPayInvoice={canPayInvoice}
          onPayInvoice={paymentModalState.onOpen}
          companyLogoData={{
            isLoading,
            data: shareInvoiceData?.logo
              ? `data:image/png;base64,${shareInvoiceData?.logo}`
              : '',
          }}
          tenantId={tenantId}
          invoiceSecretId={invoiceSecretId}
        />

        {/* {invoice && <InvoiceCreditNotes invoice={invoice} />} */}
      </MBox>

      {paymentModalState.isOpen && invoice && canPayInvoice && (
        <SharedInvoicePaymentModal
          allowPartialPayment={
            !!shareInvoiceData?.billGroupSettings?.allowPartialPayment
          }
          clientSecret={clientSecret}
          invoice={invoice}
          invoiceSecretId={invoiceSecretId}
          isOpen={paymentModalState.isOpen}
          publicKey={publicKey}
          tenantId={tenantId}
          onClose={handlePaymentModalClose}
        />
      )}
    </MPageContainer>
  );
};
