import { InvoiceItemTypeEnum, InvoiceStatusEnum } from '~app/types';
import { InvoiceCreationEnum } from '~app/types/InvoiceSettingsTypes';

export const InvoiceStatusEnumDisplay: {
  [key in InvoiceStatusEnum]: string;
} = {
  DRAFT: 'Draft',
  CANCELED: 'Canceled',
  UNPAID: 'Unpaid',
  PENDING: 'Pending',
  PAID: 'Paid',
  REVERSED: 'Reversed',
};

export const getInvoiceStatusStyle = (status?: InvoiceStatusEnum) => {
  switch (status) {
    case InvoiceStatusEnum.PAID:
      return { color: 'tGreen.mDarkShade', bg: 'tGreen.light' };
    default:
      return { color: 'tGray.darkGrayPurple', bg: 'transparent' };
  }
};

export const InvoiceItemTypeEnumDisplay: {
  [key in InvoiceItemTypeEnum]: string;
} = {
  SUBSCRIPTION: 'Subscription',
  ONETIME: 'Onetime',
  USAGE: 'Usage',
};

export const CHANGE_CONTACT_STATUSES = new Set([
  InvoiceStatusEnum.UNPAID,
  InvoiceStatusEnum.DRAFT,
]);

export const EMAIL_STATUSES = new Set([
  InvoiceStatusEnum.PAID,
  InvoiceStatusEnum.REVERSED,
]);

export const REVENUE_SCHEDULE_STATUSES = new Set([
  InvoiceStatusEnum.PAID,
  InvoiceStatusEnum.UNPAID,
]);

export const INVOICE_EMAIL_STATUSES = new Set([
  InvoiceStatusEnum.UNPAID,
  InvoiceStatusEnum.PAID,
]);

export const TAX_ERROR_CAN_RESOLVE_STATUSES = new Set([
  InvoiceStatusEnum.UNPAID,
  InvoiceStatusEnum.DRAFT,
]);

export const DEFAULT_INVOICE_SETTINGS = {
  invoiceTemplateConfig: {
    showUsageWithoutEvents: true,
    showOffering: true,
  },
  invoiceEmailingConfig: {
    automatic: false,
  },
  invoiceAutomation: {
    invoiceCreation: InvoiceCreationEnum.AUTOMATIC,
    invoiceDelay: 0,
  },
};
