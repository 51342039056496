import { CardBody } from '@chakra-ui/react';
import { MCard, MText } from '~app/components/Monetize';
import {
  AccountHistoryObjectTypeDisplayText,
  AccountHistoryOperationDisplayText,
} from '~app/constants/accounts';
import { IAccountHistoryResp } from '~app/types';
import { toDateTimeShort } from '~app/utils/dates';

const AccountHistoryDrawerItemCard = ({
  createDate,
  operation,
  objectType,
  message,
}: IAccountHistoryResp) => {
  return (
    <MCard variant="borderless">
      <CardBody flex="1" flexDirection="column">
        <MText fontWeight="normal" fontSize="sm" color="tGray.darkPurple">
          {toDateTimeShort(createDate)}
        </MText>
        <MText fontSize="md" fontWeight="bold" color="tPurple.base" mt={2}>
          {`${AccountHistoryOperationDisplayText[operation]} ${AccountHistoryObjectTypeDisplayText[objectType]}`}
        </MText>
        <MText fontSize="sm" fontWeight="normal" color="tPurple.base" mt={2}>
          {message}
        </MText>
      </CardBody>
    </MCard>
  );
};

export default AccountHistoryDrawerItemCard;
