import { useMutation, useQueryClient } from '@tanstack/react-query';
import { IRuleResSchema, RuleTypeEnum } from '~app/types';
import { apiGetAllList } from './axios';

const baseRulesKey = ['rules'] as const;

export const approvalServiceQueryKeys = {
  base: [...baseRulesKey, 'approvals'] as const,
  list: () => [...approvalServiceQueryKeys.base, 'list'] as const,
  allList: () => [...approvalServiceQueryKeys.base, 'allList'] as const,
  ruleById: (id: string) => [...approvalServiceQueryKeys.base, id] as const,
};

export const validationServiceQueryKeys = {
  base: [...baseRulesKey, 'validations'] as const,
  list: () => [...validationServiceQueryKeys.base, 'list'] as const,
  ruleById: (id: string) => [...validationServiceQueryKeys.base, id] as const,
};

export const conditionalTermServiceQueryKeys = {
  base: [...baseRulesKey, 'conditionalTerms'] as const,
  list: () => [...validationServiceQueryKeys.base, 'list'] as const,
  ruleById: (id: string) => [...validationServiceQueryKeys.base, id] as const,
};

export function useGetAllApprovalList(
  options: {
    onSuccess?: (data: IRuleResSchema[]) => void;
    onError?: (data: unknown) => void;
  } = {},
) {
  const { onSuccess, ...restOptions } = options;
  const queryClient = useQueryClient();
  return useMutation<IRuleResSchema[], unknown, { teamId?: string }>(
    ({ teamId }) =>
      apiGetAllList<IRuleResSchema>('/rules-v3/rules', {
        filters: { type: RuleTypeEnum.APPROVAL, teamId },
      }),
    {
      onSuccess: (data) => {
        queryClient.setQueryData(approvalServiceQueryKeys.allList(), data);
        onSuccess && onSuccess(data);
      },
      ...restOptions,
    },
  );
}
