import { drawerAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(drawerAnatomy.keys);

const baseStyle = definePartsStyle({
  overlay: {
    bg: 'tGray.overlay',
  },
  closeButton: defineStyle({
    position: 'absolute',
    top: 4,
    insetEnd: 4,
    fontSize: '12px',
  }),
  body: {
    bg: 'tGray.drawerSidebar',
    p: 4,
  },
  header: defineStyle({
    fontSize: 'sm',
    fontWeight: 'semibold',
    color: 'tPurple.base',
    borderBottom: 0,
  }),
});

const sizes = {
  xl: definePartsStyle({
    dialog: defineStyle({ maxW: `80vw` }),
  }),
};

export const drawerTheme = defineMultiStyleConfig({
  baseStyle,
  sizes,
  defaultProps: {
    variant: 'right',
  },
});

export default drawerTheme;
