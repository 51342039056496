import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { apiGet } from '~api/axios';
import {
  IFinanceCustomerChartData,
  IFinanceDashboardRes,
  IFinanceRevenueChartData,
  IFinanceTotal,
  IMonthlySales,
  IMonthlySalesPeriod,
  IQuoteAmountsByStatus,
  ISalesChartData,
  ISalesCountQuotes,
  RenewalPipeline,
} from '~types';

export const dashboardServiceQueryKeys = {
  base: ['dashboard'] as const,
  analytics: (type: string) =>
    [...dashboardServiceQueryKeys.base, type] as const,
  accounts: (type: string) =>
    [...dashboardServiceQueryKeys.base, 'accounts', type] as const,
  customers: (type: string) =>
    [...dashboardServiceQueryKeys.base, 'customers', type] as const,
  invoices: (type: string) =>
    [...dashboardServiceQueryKeys.base, 'invoices', type] as const,
  subscriptions: (type: string) =>
    [...dashboardServiceQueryKeys.base, 'subscriptions', type] as const,
  sales: (type: string) =>
    [...dashboardServiceQueryKeys.base, 'sales', type] as const,
};

// sales dashboard
export function useGetAnalyticsSalesActive({ query }: { query?: any } = {}) {
  return useQuery<number>(
    [...dashboardServiceQueryKeys.sales('active'), query],
    {
      queryFn: () =>
        apiGet<number>(`/api/dashboard/sales/active`, { params: query }).then(
          (res) => res.data,
        ),
      initialData: 0,
    },
  );
}

export function useGetAnalyticsSalesQuarter({
  quarter,
  currency,
  query = null,
}: {
  quarter: string;
  currency: string;
  query?: any;
}) {
  return useQuery<ISalesChartData>(
    [...dashboardServiceQueryKeys.sales('quarter'), currency, quarter, query],
    {
      queryFn: () =>
        apiGet<ISalesChartData>(`/api/dashboard/sales/quarter/${quarter}`, {
          params: { ...query, currency },
        }).then((res) => res.data),
      initialData: { amount: 0, currency },
    },
  );
}

export function useGetAnalyticsSalesNew({
  type = 'NEW',
  currency,
  query = null,
}: {
  type?: 'NEW' | 'AMENDMENT' | 'RENEWAL';
  currency: string;
  query?: any;
}) {
  return useQuery(
    [...dashboardServiceQueryKeys.sales('new'), type, currency, query],
    {
      queryFn: () =>
        apiGet<ISalesChartData>(`/api/dashboard/sales/new`, {
          params: { ...query, currency: 'USD', type },
        }).then((res) => res.data),
      initialData: { amount: 0, currency: 'USD' },
    },
  );
}

export function useGetAnalyticsSalesNewByStatus(
  {
    currency,
    query = null,
  }: {
    currency: string;
    query?: any;
  },
  options: {
    enabled?: boolean;
    onSuccess?: (data: IQuoteAmountsByStatus) => void;
    onError?: (data: unknown) => void;
  } = {},
) {
  return useQuery(
    [...dashboardServiceQueryKeys.sales('new-by-status'), currency, query],
    {
      queryFn: () =>
        apiGet<IQuoteAmountsByStatus>(`/api/dashboard/sales/newByStatus`, {
          params: { ...query, currency: 'USD' },
        }).then((res) => res.data),
      ...options,
    },
  );
}

export function useGetDashboardSalesCountQuotes(
  {
    currency,
    query = null,
  }: {
    currency: string;
    query?: any;
  },
  options: Partial<
    UseQueryOptions<ISalesCountQuotes, unknown, ISalesCountQuotes>
  > = {},
) {
  return useQuery<ISalesCountQuotes, unknown, ISalesCountQuotes>(
    [...dashboardServiceQueryKeys.sales('count-quotes'), currency, query],
    {
      queryFn: () =>
        apiGet<ISalesCountQuotes>(`/api/dashboard/sales/countQuotes`, {
          params: { ...query, currency: 'USD' },
        }).then((res) => res.data),
      ...options,
    },
  );
}

export function useGetAnalyticsSalesRenewalPipeline<
  SelectData = RenewalPipeline,
>(
  { query = null }: { query?: any } = {},
  options: { select?: (data: RenewalPipeline) => SelectData } = {},
) {
  return useQuery(
    [...dashboardServiceQueryKeys.sales('pipeline-renewal'), query],
    {
      queryFn: () =>
        apiGet<RenewalPipeline>(`/api/dashboard/sales/pipeline/renewal`, {
          params: query,
        }).then((res) => res.data),
      ...options,
    },
  );
}

// finance dashboard
export function useGetSalesMonthly<SelectData = IMonthlySales>(
  { query = null }: { query?: any } = {},
  options: { select?: (data: IMonthlySales) => SelectData } = {},
) {
  return useQuery([...dashboardServiceQueryKeys.sales('monthly'), query], {
    queryFn: () =>
      apiGet<IMonthlySales>(`/api/dashboard/sales/monthly`, {
        params: query,
      }).then((res) => res.data),

    ...options,
  });
}

export function useGetSalesMonthlyPeriod<SelectData = IMonthlySalesPeriod>(
  { period = 'YTD', query = null }: { period?: string; query?: any } = {},
  options: { select?: (data: IMonthlySalesPeriod) => SelectData } = {},
) {
  return useQuery(
    [...dashboardServiceQueryKeys.sales('monthly'), period, query],
    {
      queryFn: () =>
        apiGet<IMonthlySalesPeriod>(
          `/api/dashboard/sales/monthly/period/${period}`,
          {
            params: query,
          },
        ).then((res) => res.data),
      ...options,
    },
  );
}

export function useGetChurnedAccounts<SelectData = IFinanceDashboardRes>(
  options: { select?: (data: IFinanceDashboardRes) => SelectData } = {},
) {
  return useQuery([...dashboardServiceQueryKeys.accounts('churned')], {
    queryFn: () =>
      apiGet<IFinanceDashboardRes>(`/api/dashboard/accounts/churned`).then(
        (res) => res.data,
      ),
    ...options,
  });
}

export function useGetRevenueList<SelectData = IFinanceRevenueChartData[]>(
  { currency }: { currency: string },
  options: { select?: (data: IFinanceRevenueChartData[]) => SelectData } = {},
) {
  return useQuery(
    [...dashboardServiceQueryKeys.analytics('revenue'), currency],
    {
      queryFn: () =>
        apiGet<IFinanceRevenueChartData[]>(`/api/dashboard/revenue`, {
          params: { currency },
        }).then((res) => res.data),
      ...options,
    },
  );
}

export function useGetNewAccountsList() {
  return useQuery([...dashboardServiceQueryKeys.accounts('new')], {
    queryFn: () =>
      apiGet<IFinanceDashboardRes>(`/api/dashboard/accounts/new`).then(
        (res) => res.data,
      ),
  });
}
export function useGetActiveAccounts(currency: string = 'USD') {
  return useQuery([...dashboardServiceQueryKeys.accounts('active'), currency], {
    queryFn: () =>
      apiGet<IFinanceTotal>(`/api/dashboard/accounts/active`, {
        params: { currency },
      }).then((res) => res.data),
  });
}
export function useGetActiveSubscriptions(currency: string = 'USD') {
  return useQuery(
    [...dashboardServiceQueryKeys.subscriptions('active'), currency],
    {
      queryFn: () =>
        apiGet<IFinanceTotal>(`/api/dashboard/subscriptions/active`, {
          params: { currency },
        }).then((res) => res.data),
    },
  );
}

export function useGetUnpaidInvoices(currency: string = 'USD') {
  return useQuery([...dashboardServiceQueryKeys.invoices('unpaid'), currency], {
    queryFn: () =>
      apiGet<IFinanceTotal>(`/api/dashboard/invoices/unpaid`, {
        params: { currency },
      }).then((res) => res.data),
  });
}

export function useGetArr(currency: string = 'USD') {
  return useQuery([...dashboardServiceQueryKeys.analytics('arr'), currency], {
    queryFn: () =>
      apiGet<IFinanceTotal>(`/api/dashboard/arr`, {
        params: { currency },
      }).then((res) => res.data),
  });
}

export function useGetMrr(currency: string = 'USD') {
  return useQuery([...dashboardServiceQueryKeys.analytics('mrr'), currency], {
    queryFn: () =>
      apiGet<IFinanceTotal>(`/api/dashboard/mrr`, {
        params: {
          currency,
        },
      }).then((res) => res.data),
  });
}

export function useGetNewPayingCustomers<
  SelectData = IFinanceCustomerChartData[],
>(
  options: { select?: (data: IFinanceCustomerChartData[]) => SelectData } = {},
) {
  return useQuery([...dashboardServiceQueryKeys.customers('newPaying')], {
    queryFn: () =>
      apiGet<IFinanceCustomerChartData[]>(
        `/api/dashboard/customers/newPaying`,
      ).then((res) => res.data),
    ...options,
  });
}
// end finance dashboard
