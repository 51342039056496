import { CellContext, ColumnDef, useReactTable } from '@tanstack/react-table';
import { MCheckbox } from '../chakra';
import { getColHeaderFilters } from './tableUtilsNew';

export const SELECTION_COL_KEY = '_SELECT_';

interface CheckboxHeaderSelectCellProps<T> {
  table: ReturnType<typeof useReactTable<T>>;
}

export const CheckboxHeaderSelectCell = <T extends object>({
  table,
}: CheckboxHeaderSelectCellProps<T>) => {
  const selectionDisabled = table.options.meta?.selectionDisabled || false;
  return (
    <MCheckbox
      isChecked={table.getIsAllRowsSelected()}
      isDisabled={selectionDisabled}
      isIndeterminate={table.getIsSomeRowsSelected()}
      onChange={table.getToggleAllRowsSelectedHandler()}
    />
  );
};

export const CheckboxSelectCell = <TData, TValue>({
  row,
  table,
}: CellContext<TData, TValue>) => {
  const selectionDisabled = table.options.meta?.selectionDisabled || false;
  return (
    <MCheckbox
      isChecked={row.getIsSelected()}
      isDisabled={selectionDisabled || !row.getCanSelect()}
      onChange={row.getToggleSelectedHandler()}
    />
  );
};

export const SELECTION_COL: ColumnDef<any, any> = {
  id: SELECTION_COL_KEY,
  ...getColHeaderFilters('SELECTION'),
  header: CheckboxHeaderSelectCell,
  cell: CheckboxSelectCell,
  accessorFn: (originalRow) => originalRow.id,
  size: 55,
  enableSorting: false,
  enablePinning: true,
  enableResizing: false,
};
