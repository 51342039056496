import { Link } from 'react-router-dom';
import { useGetUsageConsumption } from '~app/api/subscriptionsService';
import {
  MBox,
  MDivider,
  MFlex,
  MGrid,
  MGridItem,
  MLink,
  MSkeleton,
  MText,
} from '~app/components/Monetize';
import { getSubscriptionUsageRoute } from '~app/constants/routes';
import {
  AggregationModelEnum,
  IGetSubscriptionItemSchema,
  IGetSubscriptionSchema,
} from '~app/types';
import { formatNumber } from '~app/utils';
import { UsageTypeWithDetails } from './UsageTypeWithDetails';

interface UsageDetailsItemProps {
  accountId: string;
  subscription: IGetSubscriptionSchema;
  subscriptionItem: IGetSubscriptionItemSchema;
}

export const UsageDetailsItem = ({
  accountId,
  subscription,
  subscriptionItem,
}: UsageDetailsItemProps) => {
  const { usagePeriodStart, usagePeriodEnd } = subscription;
  const {
    product: { id: productId, name, description, usageTypes },
  } = subscriptionItem;
  const usageType = usageTypes && usageTypes?.length > 0 ? usageTypes[0] : null;

  const { data: usageUnits, isLoading } = useGetUsageConsumption(
    {
      subscriptionId: subscription.id,
      usageTypeIds: usageTypes ? usageTypes.map(({ id }) => id) : [],
      startTime: `${usagePeriodStart}Z`,
      endTime: `${usagePeriodEnd}Z`,
      aggregationModel: AggregationModelEnum.SUM,
    },
    {
      enabled:
        !!usageType?.id &&
        !!subscription.usagePeriodStart &&
        !!subscription.usagePeriodEnd,
    },
  );

  return (
    <MBox data-testid={`${name}-${productId}`} width="full">
      <MGrid w="100%" templateColumns={'2fr 4fr 1.1fr 1.2fr'} columnGap={8}>
        <MGridItem pl={2} py={2}>
          <MLink
            as={Link}
            position="relative"
            _hover={{
              textDecoration: 'underline',
            }}
            to={getSubscriptionUsageRoute(
              accountId,
              subscription.id,
              productId,
            )}
          >
            <MText color="tIndigo.500">{name}</MText>
          </MLink>
        </MGridItem>
        <MGridItem py={2}>
          <MText>{description}</MText>
        </MGridItem>
        <MFlex py={2} mr={8} justifyContent="end">
          {isLoading ? (
            <MSkeleton height={5} w={7} />
          ) : (
            <MText textAlign="right">
              {formatNumber(usageUnits?.unitsConsumed || 0, {
                maximumFractionDigits: usageType?.decimalPlaces || 0,
              })}
            </MText>
          )}
        </MFlex>
        <MGridItem py={2}>
          {usageTypes && usageTypes?.length > 0 && (
            <UsageTypeWithDetails usageTypes={usageTypes} />
          )}
        </MGridItem>
        <MGridItem gridColumn="1/-1">
          <MDivider orientation="horizontal" color="red" />
        </MGridItem>
      </MGrid>
    </MBox>
  );
};
