import { Controller, useFormContext } from 'react-hook-form';
import { MdClear } from 'react-icons/md';
import {
  MBox,
  MButton,
  MCustomIconButton,
  MFlex,
  MFormField,
  MInput,
  MText,
} from '~app/components/Monetize';
import { RATE_BILLING_FREQUENCY_MAP } from '~app/constants/offerings';
import {
  IAccount,
  IAccountRateCreateReqSchema,
  IAccountRateUpdateReqSchema,
  IPriceUnderProductSchemaUI,
  IRateReqSchema,
  IRateResSchema,
} from '~app/types';

interface RateNameProps {
  account: IAccount | Pick<IAccount, 'id' | 'accountName'>;
  offeringRate: IRateResSchema;
  providedOfferingRate: IRateResSchema;
  productsWithPrices: IPriceUnderProductSchemaUI[];
  latestPayload?: IAccountRateCreateReqSchema | IAccountRateUpdateReqSchema;
  isEditMode: boolean;
  setIsEditMode: (val: boolean) => void;
  setLatestPayload?: (
    val: IAccountRateCreateReqSchema | IAccountRateUpdateReqSchema,
  ) => void;
  onRateSaved?: (rate: IRateResSchema) => void;
  setOfferingRate?: (val: IRateResSchema | null) => void;
  createOfferingRate: (
    data: IRateReqSchema | IAccountRateCreateReqSchema,
    offeringId?: string | undefined,
  ) => Promise<IRateResSchema | null>;
  updateOfferingRate: (
    data: IRateReqSchema | IAccountRateUpdateReqSchema,
    rateId: string,
    offeringId?: string | undefined,
  ) => Promise<IRateResSchema | null>;
  setOrderedPricesUnderProduct?: (val: IPriceUnderProductSchemaUI[]) => void;
}

export const RateName = ({
  offeringRate,
  isEditMode,
  setIsEditMode,
}: RateNameProps) => {
  const {
    control,
    reset,
    formState: { errors, isDirty },
  } = useFormContext();
  const isValid = Object.keys(errors).length === 0;

  return (
    <MFlex justify="space-between" gap="2" pb="2" align="flex-start">
      <MBox flex="1">
        {isEditMode ? (
          <MFormField
            isHorizontal
            labelProps={{ fontSize: 'md' }}
            label="Rate Name"
            error={errors.name}
          >
            <Controller
              name="name"
              control={control}
              render={({ field }) => <MInput {...field} />}
            />
          </MFormField>
        ) : (
          <MFlex h="8" align="center">
            <MText as="h5" fontSize="md" fontWeight="bold" noOfLines={1}>
              Rate Name{' '}
              <MText as="span" fontWeight="normal">
                {offeringRate.name}
              </MText>
            </MText>
          </MFlex>
        )}

        <MText mt="2" as="h5" fontSize="md" fontWeight="bold" noOfLines={1}>
          Subscription Frequency{' '}
          <MText as="span" fontWeight="normal">
            {offeringRate.billingFrequency
              ? RATE_BILLING_FREQUENCY_MAP[offeringRate.billingFrequency](
                  offeringRate.billingFrequencyInMonths,
                ).label
              : ''}
          </MText>
        </MText>
      </MBox>
      {isEditMode ? (
        <MCustomIconButton
          justifySelf="center"
          variant="icon"
          icon={MdClear}
          mr={-2}
          containerSize={8}
          onClick={() => {
            setIsEditMode(false);
            reset();
          }}
        />
      ) : (
        <MButton
          ml={2}
          isDisabled={isEditMode && (!isDirty || !isValid)}
          variant="tertiary"
          type="button"
          minW={16}
          mr={-4}
          fontWeight="normal"
          aria-label="edit rate"
          onClick={() => {
            if (!isEditMode) {
              setIsEditMode(true);
            }
          }}
        >
          Edit Rate
        </MButton>
      )}
    </MFlex>
  );
};
