import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useGetQuoteApprovalDashboard } from '~app/api/approvalService';
import {
  MBox,
  MPageContentContainer,
  MPageHeader,
  MSkeleton,
  MStack,
} from '~app/components/Monetize';
import {
  AmountTableFilterOptionContent,
  MDataTableFilter,
} from '~app/components/Monetize/DataTable';
import { DaysPendingApprovalFilterOptionContent } from '~app/components/Monetize/DataTable/FilterOptions/DaysPendingApprovalFilterOptionContent';
import MEmptyDataPlaceholder from '~app/components/Monetize/MEmptyDataPlaceholder';
import { tableFilterSelector } from '~app/store/global.store';
import { FilterStateKeys } from '~app/store/store.types';
import {
  buildFilterParamsRequestObject,
  getIsTrulyEmptyList,
} from '~app/utils';
import { ApprovalDashItem } from '~components/ApprovalSteps/ApprovalDashItem';
import {
  DEFAULT_PAGER,
  FilterOptionType,
  FilterType,
  FilterTypeOperator,
  GetListApiFilter,
  RenderTableFilterOptionProps,
} from '~types';

const generateFilterParams = ({
  filters,
  createdByFilter,
  currency,
}: {
  filters: FilterType[];
  createdByFilter?: { userId?: null | string; teamId?: null | string };
  currency?: string;
}) => {
  const newFilters = [...filters];
  if (currency) {
    newFilters.push({
      key: 'currency',
      operator: FilterTypeOperator.EQUAL,
      value: currency,
    });
  }

  if (createdByFilter?.userId) {
    newFilters.push({
      key: 'userId',
      operator: FilterTypeOperator.EQUAL,
      value: createdByFilter.userId,
    });
  }

  if (createdByFilter?.teamId) {
    newFilters.push({
      key: 'teamId',
      operator: FilterTypeOperator.EQUAL,
      value: createdByFilter.teamId,
    });
  }

  newFilters.push({
    // UI need only Pending approvals
    key: 'notApproved',
    operator: FilterTypeOperator.EQUAL,
    value: 'true',
  });

  return buildFilterParamsRequestObject(newFilters);
};
export interface ApprovalDashboardProps {
  createdByFilter: { userId?: null | string; teamId?: null | string };
  resetAllFilters: () => void;
}

export const ApprovalDashboard = ({
  createdByFilter,
  resetAllFilters,
}: ApprovalDashboardProps) => {
  const [initialFilters, persistTableFilter] = useRecoilState(
    tableFilterSelector(FilterStateKeys.DASHBOARD_APPROVAL_LIST),
  );
  const [filters, setFilters] = useState<FilterType[]>(initialFilters);
  const [filterParams, setFilterParams] = useState<GetListApiFilter>(() =>
    generateFilterParams({
      filters,
      createdByFilter,
    }),
  );

  useEffect(() => {
    setFilterParams(() =>
      generateFilterParams({
        filters,
        createdByFilter,
      }),
    );
  }, [filters, DEFAULT_PAGER, createdByFilter]);

  useEffect(() => {
    persistTableFilter(filters);
  }, [filters, persistTableFilter]);

  const filterOptions: FilterOptionType[] = [
    {
      title: 'Amount',
      key: 'amount',
      operator: FilterTypeOperator.GLTE,
      renderOptionContent: (props: RenderTableFilterOptionProps) => {
        return <AmountTableFilterOptionContent {...props} />;
      },
    },
    {
      title: 'Days in Approval',
      key: 'daysPendingApproval',
      operator: FilterTypeOperator.EQUAL,
      renderOptionContent: (props: RenderTableFilterOptionProps) => {
        return <DaysPendingApprovalFilterOptionContent {...props} />;
      },
    },
  ];

  const onResetApprovalFilter = () => {
    setFilters([]);
  };
  const onResetAllFilter = () => {
    setFilters([]);
    resetAllFilters();
  };

  const {
    isLoading,
    isRefetching,
    isFetched,
    data: quoteList,
  } = useGetQuoteApprovalDashboard({
    config: DEFAULT_PAGER,
    filters: filterParams,
  });

  const loading = isLoading || (isRefetching && !isFetched);
  const isTrulyEmptyList = getIsTrulyEmptyList({
    loading,
    filters,
    page: DEFAULT_PAGER.page,
    totalElements: quoteList?.length || 0,
  });

  if (loading) {
    return (
      <MBox w="100%">
        <MStack spacing="2" w="100%">
          <MSkeleton height="200" w="100%" />
          <MSkeleton height="200" w="100%" />
          <MSkeleton height="200" w="100%" />
        </MStack>
      </MBox>
    );
  }

  return (
    <MPageContentContainer w="100%">
      <MPageHeader title="Pending Approvals">
        {!isTrulyEmptyList && (
          <MDataTableFilter
            filters={filters}
            filterOptions={filterOptions}
            setFilters={setFilters}
            onResetFilter={onResetApprovalFilter}
          />
        )}
      </MPageHeader>

      <MStack spacing="4" w="100%">
        {quoteList?.map((quote) => (
          <ApprovalDashItem
            key={quote.id}
            quote={quote}
            approvals={(quote as any).approvals}
          />
        ))}
        {quoteList?.length === 0 && (
          <MEmptyDataPlaceholder
            mainMessage="No pending approvals."
            smallMessage="Approvals needing review will appear here."
            btnLabel="Clear Filters"
            onClick={onResetAllFilter}
          />
        )}
      </MStack>
    </MPageContentContainer>
  );
};
