import { useNavigate, useParams } from 'react-router-dom';
import { USAGE_TYPE_LIST } from '~app/constants/routes';
import { UsageTypeForm } from './UsageTypeForm';

export const UsageTypeFormPage = () => {
  const navigate = useNavigate();
  const { usageId: usageTypeId = '' } = useParams();

  return (
    <UsageTypeForm
      key={usageTypeId}
      isOpen
      onClose={() => navigate(USAGE_TYPE_LIST)}
      usageTypeId={usageTypeId}
    />
  );
};
