import React, { FunctionComponent as FC } from 'react';
import { FormatNumberOptions } from '~app/types';
import { formatPercentNumerator } from '~app/utils';
import MText, { MTextProps } from './MText';

interface MPercentProps extends MTextProps {
  amount: number | string | null | undefined;
  options?: FormatNumberOptions;
}
export const MPercent: FC<MPercentProps> = (props: MPercentProps) => {
  const { amount, options, ...rest } = props;
  const formatted = formatPercentNumerator(amount, options);
  return <MText {...rest}>{formatted}</MText>;
};
