import { FunctionComponent as FC } from 'react';
import { useParams } from 'react-router-dom';

import { useGetById } from '~app/api/queryUtils';
import { MEntityStatusBadge } from '~app/components/Monetize/MEntityStatusBadge';
import { ROUTES } from '~app/constants';
import { PaymentMethodStatusEnumDisplay } from '~app/constants/paymentMethods';
import { useHandleApiError } from '~app/hooks/useHandleApiError';
import { MBox, MCenterModal, MPageLoader } from '~components/Monetize';
import { IPaymentMethodResp, PaymentMethodTypeEnum } from '~types';
import { PaymentMethodAchCredit } from './PaymentMethodAchCredit';
import { PaymentMethodCreditCard } from './PaymentMethodCreditCard';
import { PaymentMethodDirectDebit } from './PaymentMethodDirectDebit';

interface PaymentMethodModalProps {
  isOpen: boolean;
  onClose: () => void;
  isReadOnly?: boolean;
}

export const PaymentMethodModal: FC<PaymentMethodModalProps> = ({
  isOpen,
  onClose,
}: PaymentMethodModalProps) => {
  const params = useParams();
  const paymentMethodId = params?.paymentMethodId || '';
  const { handleApiError } = useHandleApiError();

  const { data: paymentMethod, isLoading } = useGetById<IPaymentMethodResp>(
    'accountPaymentMethods',
    paymentMethodId!,
    {
      enabled: !!paymentMethodId,
      onError: (err) =>
        handleApiError(
          err,
          ROUTES.getAccountPaymentMethodRoute(params?.accountId!),
        ),
    },
  );

  return (
    <MCenterModal
      isOpen={isOpen}
      onClose={onClose}
      modalTitle={'Payment Method'}
      size="sm"
      modalHeaderProps={{ display: 'flex', alignItems: 'center' }}
      renderModalTitleActions={() =>
        paymentMethod?.status && (
          <MEntityStatusBadge
            status={PaymentMethodStatusEnumDisplay[paymentMethod.status]}
          />
        )
      }
    >
      <MBox>
        {isLoading && <MPageLoader height="350px" />}
        {!!paymentMethod && (
          <>
            {paymentMethod.paymentMethodType ===
              PaymentMethodTypeEnum.CREDIT_CARD && (
              <PaymentMethodCreditCard paymentMethod={paymentMethod} />
            )}
            {paymentMethod.paymentMethodType ===
              PaymentMethodTypeEnum.DIRECT_DEBIT && (
              <PaymentMethodDirectDebit paymentMethod={paymentMethod} />
            )}
            {(paymentMethod.paymentMethodType ===
              PaymentMethodTypeEnum.ACH_CREDIT ||
              paymentMethod.paymentMethodType ===
                PaymentMethodTypeEnum.US_BANK_TRANSFER) && (
              <PaymentMethodAchCredit paymentMethod={paymentMethod} />
            )}
          </>
        )}
      </MBox>
    </MCenterModal>
  );
};
