import { Tfoot } from '@chakra-ui/react';
import { SubscriptionTotalCell } from './SubscriptionTotalCell';
import { formatCurrency } from '~app/utils';
import { SubscriptionFooterRow } from './SubscriptionFooterRow';
import { ISubscriptionListPricingResponseSchema } from '~app/types';

interface SubscriptionFormFooterProps {
  pricingData?: ISubscriptionListPricingResponseSchema | null;
  currency: string;
}

export const SubscriptionFormFooter = ({
  pricingData,
  currency,
}: SubscriptionFormFooterProps) => {
  const discountDisplay = pricingData ? pricingData.discount * -1 : 0;
  return (
    <Tfoot>
      <SubscriptionFooterRow
        name="Subtotal"
        amount={formatCurrency(pricingData?.amountWithoutDiscount || 0, {
          currency,
          maximumFractionDigits: 2,
        })}
      />
      {!!pricingData?.discount && (
        <SubscriptionFooterRow
          name={'Discount'}
          amount={`${formatCurrency(discountDisplay, {
            currency,
            maximumFractionDigits: 2,
          })}`}
          discountInfo={discountDisplay > 0}
        />
      )}
      <SubscriptionTotalCell
        name="Total"
        amount={pricingData?.amount || 0}
        amountProps={{
          currency,
          maximumFractionDigits: 2,
        }}
      />
    </Tfoot>
  );
};
