import { useState } from 'react';
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { ROUTES } from '../../constants';
import { MSearchInput } from '../Monetize/MPageSearchInput/MSearchInput';

interface AccountGlobalSearchProps {
  containerClicked: boolean;
}

export const AccountGlobalSearch = ({
  containerClicked,
}: AccountGlobalSearchProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [searchTerm, setSearchTerm] = useState<string>(
    searchParams.get('search') || '',
  );
  const borderTopColor = 'var(--chakra-colors-tPurple-dark)';

  function handleSubmit(search: string, isSubmitted?: boolean) {
    setSearchTerm(search);
    if (isSubmitted) {
      navigate({
        pathname: ROUTES.ACCOUNT_LIST,
        search: createSearchParams({ search }).toString(),
      });
    }
  }

  return (
    <MSearchInput
      key={location.pathname}
      apiKey="accountSearch"
      searchKey="query"
      searchOperator="equals"
      nameField="accountName"
      sortField="accountName"
      value={searchTerm || ''}
      onChange={handleSubmit}
      generateRoute={ROUTES.getAccountDetailRoute}
      alwaysExpanded
      borderRadius={0}
      size="md"
      border="none"
      backgroundColor="tGray.sidebarDark"
      focusBackgroundColor="tGray.sidebarDark"
      fontSize="md"
      focusColor="tPurple.dark"
      borderTop={`1px solid ${borderTopColor}`}
      containerClicked={containerClicked}
    />
  );
};
