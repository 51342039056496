import { PaymentGatewayTypeEnum } from '~app/types';

export const PAYMENT_GATEWAY_STATUS = [
  { name: 'Active', value: 'ACTIVE' },
  { name: 'Inactive', value: 'INACTIVE' },
];

export const PAYMENT_GATEWAY_TYPE_DISPLAY: {
  [key in PaymentGatewayTypeEnum]: string;
} = {
  STRIPEV2: 'Stripe',
  BRAINTREE: 'Braintree',
  WORLDPAY: 'Worldpay',
  AUTHNET: 'Authnet',
  ADYEN: 'Adyen',
  VANTIV: 'Vantiv',
  PAYPAL_PRO: 'Paypal Pro',
  PAYMENT_TECH: 'Payment Tech',
  TEST_PASS: 'TEST_PASS',
  TEST_FAIL: 'TEST_FAIL',
};
