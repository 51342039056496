import React, { FunctionComponent as FC } from 'react';
import { useTenantUsers } from '~app/hooks';
import {
  INoteReqSchema,
  INoteResSchema,
  isMilestone,
  NoteMilestone,
} from '~app/types';
import { MStack } from '../Monetize';
import { NoteCard } from './NoteCard';
import { NoteMilestoneDisplay } from './NoteMilestone';

interface NoteListProps {
  notes: (INoteResSchema | NoteMilestone)[];
  unsavedNotes: Map<string, INoteResSchema>;
  handleAction: (
    action: 'CREATE' | 'UPDATE' | 'DELETE',
    note: INoteReqSchema,
  ) => void;
  handleNoteModified: (action: 'ADD' | 'CANCEL', note: INoteResSchema) => void;
  isReadOnly?: boolean;
  milestones?: NoteMilestone[];
  // ref: React.ForwardedRef<HTMLElement | null>;
  // nuddaRef: React.ForwardedRef<HTMLElement | null>;
}

export const NoteList: FC<NoteListProps> = React.forwardRef<any, NoteListProps>(
  (
    {
      notes,
      unsavedNotes,
      isReadOnly,
      handleNoteModified,
      handleAction,
    }: NoteListProps,
    ref,
  ) => {
    const { usersById } = useTenantUsers();

    return (
      <MStack boxShadow="initial">
        {notes.map((item) =>
          isMilestone(item) ? (
            <NoteMilestoneDisplay key={item.label} milestone={item} />
          ) : (
            <NoteCard
              key={item.id}
              note={item}
              unsavedNotes={unsavedNotes}
              handleNoteModified={handleNoteModified}
              createNote={(createNote) => handleAction('CREATE', createNote)}
              updateNote={(updateNote) => handleAction('UPDATE', updateNote)}
              deleteNote={(deleteNote) => handleAction('DELETE', deleteNote)}
              isReadOnly={isReadOnly}
              usersById={usersById}
            />
          ),
        )}
      </MStack>
    );
  },
);
