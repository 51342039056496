import DataTableActions from '~app/components/Monetize/DataTable/MDataTableActions';
import { IRefundSchema } from '~types';
import { doPrintRefundToPdf } from '../../../api/refundService';
import { downloadAndSaveToPDF } from '../../../utils';

interface RefundActionsProps {
  refund: IRefundSchema;
}

export const RefundActions = ({ refund }: RefundActionsProps) => {
  const actions = [
    {
      title: 'Print to PDF',
      enabled: true,
      action: () => downloadAndSaveToPDF(() => doPrintRefundToPdf(refund.id)),
    },
  ];

  if (!refund) {
    return null;
  }

  return <DataTableActions actions={actions} />;
};
