import { addDays } from 'date-fns/addDays';
import { addMonths } from 'date-fns/addMonths';
import { parseISO } from 'date-fns/parseISO';
import { useEffect, useState } from 'react';
import { Control, Controller } from 'react-hook-form';
import {
  DatePicker,
  DatePickerProps,
} from '~app/components/Monetize/DatePicker/DatePicker';
import {
  IOfferingRes,
  IQuoteOfferingReqSchema,
  IQuoteOfferingRespSchema,
  IQuoteRespSchema,
  OfferingTypesEnum,
  ProductTypeEnum,
  RateBillingFrequencyEnum,
} from '~app/types';

interface QuoteOfferingScheduledEffectiveDateProps {
  isOfferingLoading: boolean;
  quote: IQuoteRespSchema;
  control: Control<IQuoteOfferingReqSchema>;
  priorScheduledQuoteOffering?: IQuoteOfferingRespSchema | null;
  nextScheduledQuoteOffering?: IQuoteOfferingRespSchema | null;
  isReadOnly?: boolean;
  isDisabled?: boolean;
  isChildOffering?: boolean;
  onChange?: (date: string) => void;
  offering: IOfferingRes;
  monthInterval: number;
  parentQuoteOffering?: IQuoteOfferingRespSchema | null;
}

export const QuoteOfferingScheduledEffectiveDate = ({
  isOfferingLoading,
  control,
  quote,
  priorScheduledQuoteOffering,
  nextScheduledQuoteOffering,
  isReadOnly,
  isDisabled = false,
  isChildOffering = false,
  onChange: onChangeProp,
  offering,
  monthInterval,
  parentQuoteOffering,
}: QuoteOfferingScheduledEffectiveDateProps) => {
  const [minDate, setMinDate] = useState<Date>();
  const [maxDate, setMaxDate] = useState<Date>();
  const isMinCommit = offering.type === OfferingTypesEnum.MIN_COMMIT;
  const hasUsageProduct =
    offering.products?.some(
      (product) => product.productType === ProductTypeEnum.USAGE,
    ) ?? false;

  const datepickerOptions: Partial<DatePickerProps> = {
    minDate,
    maxDate,
  };
  if (isMinCommit || hasUsageProduct) {
    datepickerOptions.mode =
      parentQuoteOffering?.billingFrequency ===
      RateBillingFrequencyEnum.ANNUALLY
        ? 'YEAR'
        : 'MONTH';
    datepickerOptions.interval =
      parentQuoteOffering?.billingFrequency ===
      RateBillingFrequencyEnum.ANNUALLY
        ? 1
        : (monthInterval as 1 | 3 | 6); // This will be only 1 or 3 or 6

    // Last frequency period cannot be selected
    datepickerOptions.minDate = priorScheduledQuoteOffering?.startDate
      ? addMonths(
          parseISO(priorScheduledQuoteOffering?.startDate),
          monthInterval,
        )
      : parseISO(quote.contractStartDate);
    datepickerOptions.maxDate = addDays(
      addMonths(
        parseISO(
          nextScheduledQuoteOffering?.startDate || quote.contractEndDate,
        ),
        -1 * monthInterval,
      ),
      1,
    );

    datepickerOptions.baseDate = parseISO(quote.contractStartDate);
    datepickerOptions.anchorMonth =
      parseISO(quote.contractStartDate).getMonth() + 1;
  }

  useEffect(() => {
    const isOneTimeOffering =
      parentQuoteOffering?.billingFrequency ===
      RateBillingFrequencyEnum.ONETIME;

    if (isOneTimeOffering) {
      setMinDate(parseISO(quote.contractStartDate));
      setMaxDate(parseISO(quote.contractEndDate));
    } else {
      let minDateStr = quote.contractStartDate;
      const maxDateStr = parentQuoteOffering?.endDate || quote.contractEndDate;

      if (priorScheduledQuoteOffering?.startDate) {
        minDateStr = priorScheduledQuoteOffering.startDate;
      }

      setMinDate(
        isChildOffering
          ? addDays(parseISO(minDateStr), 1)
          : parseISO(minDateStr),
      );
      setMaxDate(parseISO(maxDateStr));
    }
  }, [
    quote?.type,
    priorScheduledQuoteOffering?.startDate,
    nextScheduledQuoteOffering?.endDate,
    quote.contractStartDate,
    quote.contractEndDate,
    isChildOffering,
    parentQuoteOffering,
  ]);

  return (
    <>
      <Controller
        control={control}
        name={isChildOffering ? 'schedule.startDate' : 'startDate'}
        render={({ field: { onChange, value, ...rest } }) => (
          <DatePicker
            {...rest}
            value={!isChildOffering && !value ? quote.contractStartDate : value}
            {...datepickerOptions}
            onChange={(date) => {
              onChange(date);
              onChangeProp && onChangeProp(date!);
            }}
            isReadOnly={isReadOnly}
            isDisabled={isOfferingLoading || isDisabled}
            variant={isReadOnly ? 'readonly' : 'primary'}
          />
        )}
      />
    </>
  );
};
