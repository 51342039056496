import * as z from 'zod';
import { getRequiredMessage } from '~app/utils/messages';

export const CompanySettingsSchema = z.object({
  imageUrl: z.string().nullish(),
  name: z.string().nonempty(getRequiredMessage('Name')),
  line1: z.string().nonempty(getRequiredMessage('Line1')),
  line2: z.string().nullish(),
  country: z.string().nonempty(getRequiredMessage('Country')),
  city: z.string().nonempty(getRequiredMessage('City')),
  state: z.string().nonempty(getRequiredMessage('State')),
  postalCode: z.string().nonempty(getRequiredMessage('Postal Code')),
});

export type ICompanySettingsSchema = z.infer<typeof CompanySettingsSchema>;
