import { User } from '@auth0/auth0-spa-js';
import { Tooltip } from '@chakra-ui/react';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { MBox, MFlex, MImage, MLink, MText } from '~app/components/Monetize';
import { ROUTES } from '~app/constants';
import { IUser } from '~app/types';

interface ProfileNavProps {
  isOverlay: boolean;
  user?: User;
  currentTenantUser: IUser;
}
export const ProfileNav: FC<ProfileNavProps> = ({
  isOverlay,
  user,
  currentTenantUser,
}: ProfileNavProps) => {
  return (
    <MLink as={Link} to={ROUTES.PROFILE}>
      <MFlex
        position="relative"
        cursor="pointer"
        marginBottom={4}
        p={4}
        role="group"
        alignItems="center"
        justifyItems="center"
      >
        {user?.picture && (
          <Tooltip
            label="Profile"
            placement="bottom-end"
            isDisabled={!isOverlay}
          >
            <MImage
              w="8"
              src={user.picture}
              backgroundColor="tGray.darkPurple"
              borderRadius={2}
              _groupHover={{
                backgroundColor: 'tIndigo.base',
              }}
            />
          </Tooltip>
        )}
        {!isOverlay && (
          <MBox overflow="hidden" ml="4" maxW="8rem">
            <MText
              title={currentTenantUser.name || user?.name}
              fontSize="xs"
              isTruncated
              _groupHover={{
                color: 'tIndigo.base',
              }}
            >
              {currentTenantUser.name || user?.name}
            </MText>
            <MText
              title={currentTenantUser.email}
              fontSize="xs"
              fontWeight={700}
              isTruncated
              _groupHover={{
                color: 'tIndigo.base',
              }}
            >
              {currentTenantUser.email}
            </MText>
          </MBox>
        )}
      </MFlex>
    </MLink>
  );
};
