import { PopoverTrigger, useDisclosure } from '@chakra-ui/react';

import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { MdSettings } from 'react-icons/md';
import { MButton } from '~app/components/Monetize';
import MPopoverWrapper from '~app/components/Monetize/MPopoverWrapper';
import {
  IOfferingRes,
  IProduct,
  IRateReqSchemaUI,
  PriceDisplayEnum,
} from '~app/types';
import { PriceDisplaySettingsTable } from '~app/components/Quotes/PriceDisplaySettingsTable';

interface RateSettingsPopoverProps {
  offering?: IOfferingRes;
  products: IProduct[];
  handleSave: () => void;
}

export const RateSettingsPopover: FC<RateSettingsPopoverProps> = ({
  offering,
  products,
  handleSave,
}: RateSettingsPopoverProps) => {
  const { getValues, setValue } = useFormContext<IRateReqSchemaUI>();
  const rate = getValues();

  const { onOpen, onClose, isOpen } = useDisclosure();

  const onSave = async (value: PriceDisplayEnum) => {
    await setValue(
      'options',
      {
        priceDisplay: value,
        productOptions: rate.options?.productOptions || [],
      },
      { shouldDirty: true, shouldValidate: true },
    );
    onClose();
    handleSave();
  };

  function handleOnOpen() {
    onOpen();
  }
  return (
    <MPopoverWrapper
      usePortal
      placement="bottom-start"
      isOpen={isOpen}
      onOpen={handleOnOpen}
      onClose={onClose}
      triggerContent={
        <PopoverTrigger>
          <MButton variant="tertiary" minWidth="auto" leftIcon={<MdSettings />}>
            Settings
          </MButton>
        </PopoverTrigger>
      }
      popoverContentProps={{
        display: 'flex',
        alignItems: 'center',
        border: 'none',
        bg: 'transparent',
        zIndex: 200,
        minW: '510px',
      }}
      popoverBodyProps={{
        boxShadow: '0px 4px 25px 0px #B4BCEF61',
        bg: '#FFF',
      }}
      showArrow
      bodyContent={
        <>
          <PriceDisplaySettingsTable
            value={rate?.options?.priceDisplay}
            onChange={onSave}
            rate={rate}
            quoteOfferingName={offering?.name || ''}
            products={products}
            isLoading={false}
          />
        </>
      }
    />
  );
};
