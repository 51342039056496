import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { apiDelete, apiGet, apiPost, apiPut } from '~api/axios';
import {
  IOfferingReqSchema,
  IOfferingRes,
  IOfferingSummaryResSchema,
  IOrderablesRes,
} from '~app/types/offeringTypes';
import { orderObjectsBy } from '~app/utils/misc';
import {
  ApiListResponse,
  GetListApiConfig,
  GetListApiFilter,
  IAccountRateCreateReqSchema,
  IAccountRateUpdateReqSchema,
  IDiscount,
  IPrice,
  IProduct,
  IProductResSchema,
  IRateReqSchema,
  IRateResSchema,
  IUsageType,
  IUsageTypeResSchema,
} from '~types';

import { ApiQueryItem } from './queryUtils';
import { asQueryUtil, composeGetQuery } from './utils';

export const productCatalogServiceQueryKeys = {
  base: ['product-catalog'] as const,
  // PRODUCTS
  products: () => [...productCatalogServiceQueryKeys.base, 'products'] as const,
  productsList: () =>
    [...productCatalogServiceQueryKeys.products(), 'list'] as const,
  productById: (id: string) =>
    [...productCatalogServiceQueryKeys.products(), id] as const,
  // USAGE TYPES
  usageTypes: () =>
    [...productCatalogServiceQueryKeys.base, 'usage-types'] as const,
  usageTypesList: () =>
    [...productCatalogServiceQueryKeys.usageTypes(), 'list'] as const,
  usageTypesById: (id: string) =>
    [...productCatalogServiceQueryKeys.usageTypes(), id] as const,
  // DISCOUNTS
  discounts: () =>
    [...productCatalogServiceQueryKeys.base, 'discounts'] as const,
  discountsList: () =>
    [...productCatalogServiceQueryKeys.discounts(), 'list'] as const,
  discountsById: (id: string) =>
    [...productCatalogServiceQueryKeys.discounts(), id] as const,
  discountsByOfferingId: (id: string) =>
    [...productCatalogServiceQueryKeys.discounts(), 'by-offering', id] as const,
  // OFFERINGS
  offerings: () =>
    [...productCatalogServiceQueryKeys.base, 'offerings'] as const,
  offeringsList: () =>
    [...productCatalogServiceQueryKeys.offerings(), 'list'] as const,
  offeringsById: (id: string) =>
    [...productCatalogServiceQueryKeys.offerings(), id] as const,
  //RATES
  rates: () => [...productCatalogServiceQueryKeys.base, 'rates'] as const,
  ratesList: () => [...productCatalogServiceQueryKeys.rates(), 'list'] as const,
  ratesById: (id: string) =>
    [...productCatalogServiceQueryKeys.rates(), id] as const,
  orderables: () =>
    [...productCatalogServiceQueryKeys.base, 'orderables'] as const,
};

const queryKeysProducts: Required<Omit<ApiQueryItem, 'delete' | 'upload'>> = {
  list: {
    endpoint: `/api/products`,
    queryKey: productCatalogServiceQueryKeys.productsList(),
    byIdQueryKey: (id: string) => queryKeysProducts.byId.queryKey(id),
  },
  byId: {
    endpoint: (id: string) => `/api/products/${id}`,
    queryKey: (id: string) => productCatalogServiceQueryKeys.productById(id),
  },
  create: {
    endpoint: () => `/api/products`,
    invalidateKeys: [productCatalogServiceQueryKeys.productsList()],
    setDataKey: (id: string) => productCatalogServiceQueryKeys.productById(id),
  },
  update: {
    endpoint: (id: string) => `/api/products/${id}`,
    invalidateKeys: [productCatalogServiceQueryKeys.productsList()],
    setDataKey: (id: string) => productCatalogServiceQueryKeys.productById(id),
  },
};

const queryKeysUsageTypes: Required<Omit<ApiQueryItem, 'delete' | 'upload'>> = {
  list: {
    endpoint: `/api/usageTypes`,
    queryKey: productCatalogServiceQueryKeys.usageTypesList(),
    byIdQueryKey: (id: string) => queryKeysUsageTypes.byId.queryKey(id),
  },
  byId: {
    endpoint: (id: string) => `/api/usageTypes/${id}`,
    queryKey: (id: string) => productCatalogServiceQueryKeys.usageTypesById(id),
  },
  create: {
    endpoint: () => `/api/usageTypes`,
    invalidateKeys: [productCatalogServiceQueryKeys.usageTypes()],
    setDataKey: (id: string) =>
      productCatalogServiceQueryKeys.usageTypesById(id),
  },
  update: {
    endpoint: (id: string) => `/api/usageTypes/${id}`,
    invalidateKeys: [productCatalogServiceQueryKeys.usageTypes()],
    setDataKey: (id: string) =>
      productCatalogServiceQueryKeys.usageTypesById(id),
  },
};

const queryKeysDiscounts: Required<Omit<ApiQueryItem, 'delete' | 'upload'>> = {
  list: {
    endpoint: `/api/discounts`,
    queryKey: productCatalogServiceQueryKeys.discountsList(),
    byIdQueryKey: (id: string) => queryKeysDiscounts.byId.queryKey(id),
  },
  byId: {
    endpoint: (id: string) => `/api/discounts/${id}`,
    queryKey: (id: string) => productCatalogServiceQueryKeys.discountsById(id),
  },
  create: {
    endpoint: () => `/api/discounts`,
    invalidateKeys: [productCatalogServiceQueryKeys.discounts()],
    setDataKey: (id: string) =>
      productCatalogServiceQueryKeys.discountsById(id),
  },
  update: {
    endpoint: (id: string) => `/api/discounts/${id}`,
    invalidateKeys: [productCatalogServiceQueryKeys.discounts()],
    setDataKey: (id: string) =>
      productCatalogServiceQueryKeys.discountsById(id),
  },
};

const queryKeysOfferings: Required<Omit<ApiQueryItem, 'delete' | 'upload'>> = {
  list: {
    endpoint: `/api/offerings/summary`,
    queryKey: productCatalogServiceQueryKeys.offeringsList(),
    // list API returns a summary object
    // byIdQueryKey: (id: string) => queryKeysOfferings.byId.queryKey(id),
  },
  byId: {
    endpoint: (id: string) => `/api/offerings/${id}`,
    queryKey: (id: string) => productCatalogServiceQueryKeys.offeringsById(id),
  },
  create: {
    endpoint: () => `/api/offerings`,
    invalidateKeys: [
      productCatalogServiceQueryKeys.offerings(),
      productCatalogServiceQueryKeys.orderables(),
    ],
    setDataKey: (id: string) =>
      productCatalogServiceQueryKeys.offeringsById(id),
  },
  update: {
    endpoint: (id: string) => `/api/offerings/${id}`,
    invalidateKeys: [
      productCatalogServiceQueryKeys.offerings(),
      productCatalogServiceQueryKeys.orderables(),
    ],
    setDataKey: (id: string) =>
      productCatalogServiceQueryKeys.offeringsById(id),
    skipListUpdate: true,
  },
};

const queryKeysOfferingsV2: Required<Omit<ApiQueryItem, 'delete' | 'upload'>> =
  {
    list: {
      endpoint: `/api/offerings/summary`,
      queryKey: productCatalogServiceQueryKeys.offeringsList(),
      // list API returns a summary object
      // byIdQueryKey: (id: string) => queryKeysOfferings.byId.queryKey(id),
    },
    byId: {
      endpoint: (id: string) => `/api/offerings/${id}`,
      queryKey: (id: string) =>
        productCatalogServiceQueryKeys.offeringsById(id),
    },
    create: {
      endpoint: () => `/api/v2/offerings`,
      invalidateKeys: [
        productCatalogServiceQueryKeys.offerings(),
        productCatalogServiceQueryKeys.orderables(),
      ],
      setDataKey: (id: string) =>
        productCatalogServiceQueryKeys.offeringsById(id),
    },
    update: {
      endpoint: (id: string) => `/api/v2/offerings/${id}`,
      invalidateKeys: [
        productCatalogServiceQueryKeys.offerings(),
        productCatalogServiceQueryKeys.orderables(),
      ],
      setDataKey: (id: string) =>
        productCatalogServiceQueryKeys.offeringsById(id),
      skipListUpdate: true,
    },
  };

const queryKeysRates: Required<
  Omit<ApiQueryItem, 'create' | 'update' | 'delete' | 'upload'>
> = {
  list: {
    endpoint: `/api/rates`,
    queryKey: productCatalogServiceQueryKeys.ratesList(),
    byIdQueryKey: (id: string) => queryKeysRates.byId.queryKey(id),
  },
  byId: {
    endpoint: (id: string) => `/api/rates/${id}`,
    queryKey: (id: string) => productCatalogServiceQueryKeys.ratesById(id),
  },
};

export const PRODUCT_SERVICE_API = asQueryUtil({
  productCatalogProducts: queryKeysProducts,
  productCatalogUsageTypes: queryKeysUsageTypes,
  productCatalogDiscounts: queryKeysDiscounts,
  productCatalogOfferings: queryKeysOfferings,
  productCatalogOfferingsV2: queryKeysOfferingsV2,
  productCatalogRates: queryKeysRates,
});

// Products
export const createProduct = async (data: any): Promise<any> => {
  const res = await apiPost<any>('/api/products', data);
  return res.data;
};

export const updateProduct = async (id: string, data: any): Promise<any> => {
  const res = await apiPut<any>(`/api/products/${id}`, data);
  return res.data;
};

export const doDeactivateProduct = async (id: string): Promise<IProduct> => {
  const res = await apiPut<IProduct>(`/api/products/${id}/deactivate`);
  return res.data;
};

export const doActivateProduct = async (id: string): Promise<IProduct> => {
  const res = await apiPut<IProduct>(`/api/products/${id}/activate`);
  return res.data;
};

export const doGetProducts = async (
  config: GetListApiConfig,
  filters?: GetListApiFilter,
): Promise<ApiListResponse<IProductResSchema>> => {
  const params = composeGetQuery(config, filters);
  const res = await apiGet<ApiListResponse<IProductResSchema>>(
    '/api/products',
    {
      params,
    },
  );
  return res.data;
};

export const doGetProductById = async (
  id: string,
): Promise<IProductResSchema> => {
  const res = await apiGet<IProductResSchema>(`/api/products/${id}`);
  return res.data;
};
// End Product

// Usage Type
export const createUsageType = async (data: any): Promise<any> => {
  const res = await apiPost<any>('/api/usageTypes', data);
  return res.data;
};

export const updateUsageType = async (id: string, data: any): Promise<any> => {
  const res = await apiPut<any>(`/api/usageTypes/${id}`, data);
  return res.data;
};

export const getUsageTypes = async (
  config: GetListApiConfig,
  filters?: GetListApiFilter,
): Promise<ApiListResponse<IUsageType>> => {
  const params = composeGetQuery(config, filters);
  const res = await apiGet<ApiListResponse<IUsageType>>('/api/usageTypes', {
    params,
  });
  return res.data;
};

export const getUsageTypeById = async (
  id: string,
): Promise<IUsageTypeResSchema> => {
  const res = await apiGet<IUsageTypeResSchema>(`/api/usageTypes/${id}`);
  return res.data;
};

export const doDeactivateUsageType = async (
  id: string,
): Promise<IUsageType> => {
  const res = await apiPut<IUsageType>(`/api/usageTypes/${id}/deactivate`);
  return res.data;
};

export const doActivateUsageType = async (id: string): Promise<IUsageType> => {
  const res = await apiPut<IUsageType>(`/api/usageTypes/${id}/activate`);
  return res.data;
};

// End Usage Type

export const createPrice = async (data: IPrice): Promise<any> => {
  const res = await apiPost<any>('/api/prices', data as any);
  return res.data;
};

export const updatePrice = async (data: IPrice, id: string): Promise<any> => {
  const res = await apiPut<any>(`/api/prices/${id}`, data as any);
  return res.data;
};

export const doDeletePrice = async (id: string): Promise<any> => {
  const res = await apiDelete<any>(`/api/prices/${id}`);
  return res.data;
};

export const getPricesByRateId = async (rateId: string): Promise<any> => {
  const res = await apiGet<any>(`/api/rates/${rateId}/prices`, {
    params: { sort: 'from', pageSize: 20 },
  });
  return res.data;
};

// Rate
export const doGetOfferingRate = async (
  id: string,
): Promise<IRateResSchema> => {
  const res = await apiGet<IRateResSchema>(`/api/rates/${id}`);
  return res.data;
};

export function useGetRateById(
  id: string,
  options: Partial<UseQueryOptions<IRateResSchema>> = {},
) {
  return useQuery<IRateResSchema>(
    productCatalogServiceQueryKeys.ratesById(id),
    {
      queryFn: () =>
        apiGet<IRateResSchema>(`/api/rates/${id}`).then((res) => res.data),
      ...options,
    },
  );
}

export const useGetRates = <SelectData = ApiListResponse<IRateResSchema>>(
  config: GetListApiConfig,
  filters?: GetListApiFilter,
  options: Partial<
    UseQueryOptions<ApiListResponse<IRateResSchema>, unknown, SelectData>
  > = {},
) => {
  const params = composeGetQuery(config, filters);
  return useQuery<ApiListResponse<IRateResSchema>, unknown, SelectData>(
    [...productCatalogServiceQueryKeys.ratesList(), params],
    {
      queryFn: () =>
        apiGet<ApiListResponse<IRateResSchema>>('/api/rates', {
          params,
        }).then((res) => res.data),
      ...options,
    },
  );
};

export const useGetRate = (
  rateId: string,
  options?: {
    enabled?: boolean;
  },
) => {
  return useQuery(productCatalogServiceQueryKeys.ratesById(rateId), {
    queryFn: () =>
      apiGet<IRateResSchema>(`/api/rates/${rateId}`).then((res) => res.data),
    ...options,
  });
};

export const doCreateOfferingRate = async (
  data: IRateReqSchema | IAccountRateCreateReqSchema,
  offeringId: string,
): Promise<IRateResSchema> => {
  const res = await apiPost<IRateResSchema>(
    `/api/offerings/${offeringId}/rates`,
    data as any,
  );
  return res.data;
};

export const doUpdateOfferingRate = async (
  data: IRateReqSchema | IAccountRateUpdateReqSchema,
  offeringId: string,
  id: string,
): Promise<IRateResSchema> => {
  const res = await apiPut<IRateResSchema>(
    `/api/offerings/${offeringId}/rates/${id}`,
    data as any,
  );
  return res.data;
};

export function useGetDiscountsByOfferingId({
  offeringId,
  config = {},
  filters,
  options = { refetchOnWindowFocus: false },
}: {
  offeringId: string;
  config?: GetListApiConfig;
  filters?: GetListApiFilter;
  options?: {
    enabled?: boolean;
    staleTime?: number;
    refetchOnWindowFocus?: boolean;
  };
}) {
  const params = composeGetQuery(config, filters);
  return useQuery(
    [
      ...productCatalogServiceQueryKeys.discountsByOfferingId(offeringId),
      params,
    ],
    {
      queryFn: () =>
        apiGet<IDiscount[]>(`/api/offerings/${offeringId}/discounts`, {
          params,
        }).then((res) => orderObjectsBy(res.data, ['name'])),
      ...options,
    },
  );
}

/** @deprecated, prefer `useGetDiscountsByOfferingId()` */
export const doGetDiscountsByOfferingId = async (
  signal: AbortSignal,
  offeringId: string,
  config: GetListApiConfig,
  filters: GetListApiFilter,
): Promise<IDiscount[]> => {
  const params = composeGetQuery(config, filters);
  const res = await apiGet<any>(`/api/offerings/${offeringId}/discounts`, {
    params,
    signal,
  });
  return res.data;
};

export const doDeleteRate = async (id: string): Promise<any> => {
  const res = await apiDelete<any>(`/api/rates/${id}`);
  return res.data;
};

export const getProductRate = async (
  config: GetListApiConfig,
  id: string,
  filters?: GetListApiFilter,
): Promise<any> => {
  const params = composeGetQuery(config, filters);
  const res = await apiGet<any>(`/api/products/${id}/rates`, { params });
  return res.data;
};

// Offering
export const doCreateOffering = async (
  data: IOfferingReqSchema,
): Promise<IOfferingRes> => {
  const res = await apiPost<IOfferingRes>(`/api/offerings`, data);
  return res.data;
};

export const doUpdateOffering = async (
  id: string,
  data: IOfferingReqSchema,
): Promise<IOfferingRes> => {
  const res = await apiPut<IOfferingRes>(`/api/offerings/${id}`, data);
  return res.data;
};

export const doCancelOffering = async (id: string): Promise<IOfferingRes> => {
  const res = await apiPut<IOfferingRes>(`/api/offerings/${id}/cancel`);
  return res.data;
};

export const doDeactivateOffering = async (
  id: string,
): Promise<IOfferingRes> => {
  const res = await apiPut<IOfferingRes>(`/api/offerings/${id}/deactivate`);
  return res.data;
};

export const doActivateOffering = async (id: string): Promise<IOfferingRes> => {
  const res = await apiPut<IOfferingRes>(`/api/offerings/${id}/activate`);
  return res.data;
};

export const doGetOfferings = async (
  config: GetListApiConfig,
  filters?: GetListApiFilter,
) => {
  const params = composeGetQuery(config, filters);
  const res = await apiGet<ApiListResponse<IOfferingSummaryResSchema>>(
    '/api/offerings/summary',
    {
      params,
    },
  );
  return res.data;
};

export const doGetOfferingById = async (id: string): Promise<IOfferingRes> => {
  const res = await apiGet<IOfferingRes>(`/api/offerings/${id}`);
  return res.data;
};

// discount

export const doCreateDiscount = async (data: IDiscount): Promise<IDiscount> => {
  const res = await apiPost<IDiscount>('/api/discounts', data);
  return res.data;
};

export const doUpdateDiscount = async (
  id: string,
  data: any,
): Promise<IDiscount> => {
  const res = await apiPut<IDiscount>(`/api/discounts/${id}`, data);
  return res.data;
};

export const doDeactivateDiscount = async (id: string): Promise<IDiscount> => {
  const res = await apiPut<IDiscount>(`/api/discounts/${id}/deactivate`);
  return res.data;
};

export const doActivateDiscount = async (id: string): Promise<IDiscount> => {
  const res = await apiPut<IDiscount>(`/api/discounts/${id}/activate`);
  return res.data;
};

export const doGetDiscounts = async (
  config: GetListApiConfig,
  filters?: GetListApiFilter,
): Promise<ApiListResponse<IDiscount>> => {
  const params = composeGetQuery(config, filters);
  const res = await apiGet<ApiListResponse<IDiscount>>('/api/discounts', {
    params,
  });
  return res.data;
};

export const doGetDiscountsById = async (id: string): Promise<any> => {
  const res = await apiGet<IProduct>(`/api/discounts/${id}`);
  return res.data;
};

// Orderables

/**
 * Get params used for useOrderables
 * This is a stand-alone function as it is used to update the queryCache
 */
export function getOrderablesParams({
  currency = 'USD',
  accountId,
  quotableOnly = true,
  orderableStartDate,
}: {
  currency?: string;
  accountId?: string;
  quotableOnly?: boolean;
  orderableStartDate?: string | null;
}) {
  // "api/orders/orderable?quotable=true" = returns only quotable rates
  // "api/orders/orderable?quotable=false" = returns only non-quotable rates
  // "api/orders/orderable" = returns all rates
  const params: {
    currency?: string;
    accountId?: string;
    quotable?: boolean;
    date?: string | null;
  } = { currency };
  if (accountId) {
    params.accountId = accountId;
  }
  if (quotableOnly !== false) {
    params.quotable = true;
  }
  if (orderableStartDate) {
    params.date = orderableStartDate;
  }
  return params;
}

export function useOrderables<SelectData = IOrderablesRes>(
  params: {
    currency?: string;
    accountId?: string;
    quotable?: boolean;
    date?: string | null;
  } = {},
  options: Partial<UseQueryOptions<IOrderablesRes, unknown, SelectData>> = {},
) {
  const { onSuccess, ...restOptions } = options;
  return useQuery<IOrderablesRes, unknown, SelectData>(
    [...productCatalogServiceQueryKeys.orderables(), params],
    {
      queryFn: () =>
        apiGet<IOrderablesRes>(`/api/orders/orderable`, {
          params,
        }).then((res) => res.data),
      refetchOnReconnect: false,
      keepPreviousData: true,
      staleTime: 1000 * 60 * 60, // 1 hour
      cacheTime: Infinity,
      ...restOptions,
    },
  );
}
