import { FC } from 'react';
import {
  QuoteStatusEnumDisplay,
  QuoteStatusTagStyle,
} from '~app/constants/quotes';
import { QuoteStatusEnum } from '~app/types';
import { MBox, MText } from '../Monetize';

interface QuoteStatusProps {
  status: QuoteStatusEnum;
}
const QuoteStatus: FC<QuoteStatusProps> = (props: QuoteStatusProps) => {
  const { status } = props;

  const style = status
    ? QuoteStatusTagStyle[status as QuoteStatusEnum]
    : {
        color: 'tGray.darkGrayPurple',
        bgColor: 'transparent',
      };
  return (
    <MBox maxWidth="200px">
      <MText
        {...style}
        fontWeight="normal"
        width="fit-content"
        data-testid="quote-status"
        h="18px"
        lineHeight="18px"
      >
        {QuoteStatusEnumDisplay[status as QuoteStatusEnum]}
      </MText>
    </MBox>
  );
};

export default QuoteStatus;
