import { FC } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useGetQuoteStorageById } from '~app/api/cpqService';
import { MButton, MTooltip } from '~app/components/Monetize';
import { MTooltipContentList } from '~app/components/Monetize/MTooltipContentList';
import { QUOTE_READONLY_STATUSES } from '~app/constants/quotes';
import { useConfirmModal } from '~app/services/confirmModal';
import {
  IQuoteRequestSchema,
  IQuoteRespSchema,
  OpportunityStatusEnum,
  QuoteTypeEnum,
  RuleValidationErrorLevelEnum,
  UserRoleEnum,
} from '~app/types';
import { getQuoteStatus } from '~app/utils';
import { useAuth } from '../../../../../services/auth0';
import { useFlags } from '../../../../../services/launchDarkly';
import { QuoteModalDataTypes } from '../../QuoteReviewModal';
import { QuoteStateDataTypes } from '../../quoteContext';
import {
  getEsignContactValidity,
  getStandardContactsValidity,
} from '../contacts/quoteContactUtils';
import { SendQuoteManuallyContent } from './SendQuoteManuallyContent';
import { SendQuoteThroughEsignContent } from './SendQuoteThroughEsignContent';

interface ReviewQuoteActionButtonProps {
  isReadOnly: boolean;
  hasValidRole: boolean;
  isActionLoading: boolean;
  isEsignEnabled: boolean;
  quote: IQuoteRespSchema;
  quoteStateData: QuoteStateDataTypes;
  quoteFormMethods: UseFormReturn<IQuoteRequestSchema>;
  reviewQuoteModalData: QuoteModalDataTypes;
  anyOfferingLoading: boolean;
  onAcceptQuote: () => void;
  onRecreateQuote: () => void;
  onSendQuote: () => void;
}

export const ReviewQuoteActionButton: FC<ReviewQuoteActionButtonProps> = ({
  isReadOnly,
  hasValidRole,
  isActionLoading,
  isEsignEnabled,
  quote,
  quoteStateData,
  quoteFormMethods,
  reviewQuoteModalData,
  anyOfferingLoading,
  onAcceptQuote,
  onRecreateQuote,
  onSendQuote,
}: ReviewQuoteActionButtonProps) => {
  const { quoteAcceptanceAllowedRoles = ['ALL'] } = useFlags();
  const { currentTenantUserHasRole } = useAuth();

  const canAcceptQuote =
    !quoteAcceptanceAllowedRoles?.length ||
    quoteAcceptanceAllowedRoles[0] === 'ALL' ||
    currentTenantUserHasRole(quoteAcceptanceAllowedRoles as UserRoleEnum[]);

  const { offeringLoadingState, isReviewBtnPressed } = quoteStateData;
  const {
    formState: { isValid: isFormValid },
  } = quoteFormMethods;

  const { showConfirmModal, closeModal } = useConfirmModal();

  const sowDocument = useGetQuoteStorageById(
    {
      quoteId: quote.id,
      storageId: quote.sowDocumentStorageId!,
      params: { attachContent: true },
    },
    { enabled: !!quote.sowDocumentStorageId },
  );

  const msaDocument = useGetQuoteStorageById(
    {
      quoteId: quote.id,
      storageId: quote.documentLocation!,
      params: { attachContent: true },
    },
    { enabled: !!quote.documentLocation },
  );

  if (!quote || !hasValidRole) {
    return null;
  }

  const { isCanceled, isSent, isApproved, isDraft } = getQuoteStatus(quote);
  const isQuoteReadOnly = QUOTE_READONLY_STATUSES.has(quote.status);
  const isDocumentsLoading =
    msaDocument.isInitialLoading || sowDocument.isInitialLoading;

  const onClickSendQuote = () => {
    if (isEsignEnabled && quote.contacts.esign.length > 0) {
      const internalContact = quote.contacts.esign.find(
        (contact) => contact.internal,
      );
      const externalContact = quote.contacts.esign.find(
        (contact) => !contact.internal,
      );
      showConfirmModal({
        title: `Would you like to send this quote through eSign?`,
        content: (
          <SendQuoteThroughEsignContent
            quote={quote}
            internalContact={internalContact}
            externalContact={externalContact}
            msaDocument={msaDocument}
            sowDocument={sowDocument}
          />
        ),
        onYes: () => {
          onSendQuote();
          closeModal();
        },
        yesButton: 'Send',
        yesBtnProps: {
          variant: 'primary',
        },
        noBtnProps: {
          variant: 'cancel',
        },
        modalBodyProps: {
          pb: 1,
        },
      });

      return;
    }

    showConfirmModal({
      title: 'Mark this Quote as Sent?',
      content: <SendQuoteManuallyContent quote={quote} />,
      onYes: () => {
        onSendQuote();
        closeModal();
      },
      yesButton: 'Confirm',
      yesBtnProps: {
        variant: 'primary',
      },
      noBtnProps: {
        variant: 'cancel',
      },
    });
  };

  if (canAcceptQuote && isSent && !quote.contacts.esign.length) {
    return (
      <MButton
        mr={1.5}
        variant="primary"
        data-testid="quote-accept-btn"
        isLoading={isActionLoading}
        isDisabled={isActionLoading}
        onClick={onAcceptQuote}
      >
        Accept Quote
      </MButton>
    );
  }

  if (
    isCanceled &&
    quote.type === QuoteTypeEnum.NEW &&
    (!quote.opportunity?.status ||
      quote.opportunity?.status === OpportunityStatusEnum.ACTIVE)
  ) {
    return (
      <MButton
        mr={1.5}
        variant="secondary"
        data-testid="quote-recreate-btn"
        isLoading={isActionLoading}
        isDisabled={isActionLoading}
        onClick={onRecreateQuote}
      >
        Recreate Quote
      </MButton>
    );
  }

  if (!isQuoteReadOnly) {
    const {
      isValid: isStandardContactsValid,
      errorMsgs: standardContactsErrorMsgs,
    } = getStandardContactsValidity(quote);

    const { isValid: isEsignSetupValid, errorMsgs: eSignContactsErrorMsgs } =
      getEsignContactValidity(quote);

    const isSubmitButtonDisabled =
      isActionLoading ||
      !(isApproved && isStandardContactsValid && isEsignSetupValid) ||
      isDocumentsLoading;

    const errorValidations =
      quote?.validations?.filter(
        (validation) =>
          validation.errorLevel === RuleValidationErrorLevelEnum.ERROR,
      ) || [];

    let disabledSubmitMessages: string[] = [];
    if (isSubmitButtonDisabled) {
      /** Common Error messages for All the quote status */
      if (!isStandardContactsValid) {
        disabledSubmitMessages = disabledSubmitMessages.concat(
          standardContactsErrorMsgs,
        );
      }
      if (!isEsignSetupValid) {
        disabledSubmitMessages = disabledSubmitMessages.concat(
          eSignContactsErrorMsgs,
        );
      }

      if (!isDraft) {
        /** Error messages if quote not in draft status */
        if (isActionLoading) {
          disabledSubmitMessages.push('Wait for action to load');
        }
        if (!isApproved) {
          disabledSubmitMessages.push('Quote must first be approved');
        }
      } else {
        /** Error messages if quote in draft status */
        if (errorValidations.length > 0) {
          disabledSubmitMessages.push('Resolve validation error');
        }
        if (!(quote && quote.quoteOfferings?.length > 0)) {
          disabledSubmitMessages.push('At least one offering is required');
        }
      }
    }

    const sendTooltipContent = (
      <MTooltipContentList
        title="To send, resolve required actions below"
        listItems={disabledSubmitMessages}
      />
    );

    if (isDraft) {
      const isDraftQuoteSubmitButtonDisabled =
        !(
          isStandardContactsValid &&
          quote?.quoteOfferings?.length &&
          isEsignSetupValid
        ) ||
        !isFormValid ||
        errorValidations.length > 0 ||
        anyOfferingLoading;

      return (
        <MTooltip
          shouldWrapChildren
          isDisabled={!isDraftQuoteSubmitButtonDisabled}
          bg="tPurple.base"
          label={sendTooltipContent}
        >
          <MButton
            mr={1.5}
            type="button"
            variant="primary"
            isLoading={isReviewBtnPressed}
            isDisabled={
              isDraftQuoteSubmitButtonDisabled ||
              isReviewBtnPressed ||
              isReadOnly
            }
            onClick={reviewQuoteModalData.handleReviewOrOpenModal}
          >
            Submit
          </MButton>
        </MTooltip>
      );
    }

    return (
      <MTooltip
        shouldWrapChildren
        isDisabled={!isSubmitButtonDisabled}
        bg="tPurple.base"
        label={sendTooltipContent}
      >
        <MButton
          mr={1.5}
          variant="primary"
          data-testid="quote-send-btn"
          isLoading={isActionLoading}
          isDisabled={isSubmitButtonDisabled}
          onClick={onClickSendQuote}
        >
          {isDraft ? 'Submit' : 'Send'}
        </MButton>
      </MTooltip>
    );
  }

  return null;
};
