import { useEffect } from 'react';
import { ApiListResponse, DEFAULT_PAGER, TDataTablePager } from '~app/types';

/**
 * Detect if the user is on an invalid page for pagination and, if so, reset back to the first page.
 *
 * An invalid page could happen from applying filters or from deleting all records on the last page.
 *
 * @param isLoading
 * @param apiListResponse
 * @param pager
 * @param setPager
 * @param defaultPager
 */
export function useRestPaginationIfInvalid<T>(
  isLoading: boolean,
  apiListResponse: ApiListResponse<T> | undefined,
  pager: TDataTablePager,
  setPager: (pager: TDataTablePager) => void,
  defaultPager: TDataTablePager = DEFAULT_PAGER,
) {
  const content = apiListResponse?.content;
  const page = pager?.page;

  useEffect(() => {
    if (!isLoading && content && !content.length && pager.page !== 0) {
      setPager(defaultPager);
    }
  }, [content, defaultPager, isLoading, page, pager.page, setPager]);
}
