import { FC } from 'react';
import { Link } from 'react-router-dom';
import { MEmptyPlaceholderProps } from '~app/types';
import { MBox, MButton, MHStack, MText } from '.';

// pass either 'to' or 'onClick' prop for the button
const MEmptyDataPlaceholder: FC<MEmptyPlaceholderProps> = ({
  mainMessage,
  smallMessage,
  btnLabel,
  alternateBtnLabel,
  to,
  alternateTo,
  mainMessageProps,
  boxProps,
  secondaryBtnLabel,
  onClickSecondaryBtn,
  btnProps,
  alternateBtnProps,
  ...rest
}: MEmptyPlaceholderProps) => {
  return (
    <MBox
      height="50vh"
      display="flex"
      flexDir="column"
      margin="0 auto"
      alignItems="center"
      justifyContent="center"
      {...boxProps}
    >
      <MText fontWeight="bold" fontSize="2xl" {...mainMessageProps}>
        {mainMessage}
      </MText>
      <MText color="tGray.darkGrayPurple">{smallMessage}</MText>
      <MHStack mt={5}>
        {!!btnLabel && (
          <MButton
            to={to || ''}
            as={to ? Link : MButton}
            variant="secondary"
            {...btnProps}
            {...rest}
          >
            {btnLabel}
          </MButton>
        )}
        {!!alternateBtnLabel && (
          <MButton
            to={alternateTo || ''}
            as={alternateTo ? Link : MButton}
            variant="secondary"
            {...alternateBtnProps}
            {...rest}
          >
            {alternateBtnLabel}
          </MButton>
        )}
      </MHStack>
      {!!secondaryBtnLabel && (
        <MButton
          mt={2}
          variant="underline"
          {...btnProps}
          {...rest}
          onClick={onClickSecondaryBtn}
        >
          {secondaryBtnLabel}
        </MButton>
      )}
    </MBox>
  );
};

export default MEmptyDataPlaceholder;
