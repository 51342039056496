import { useEffect } from 'react';
import { ControllerRenderProps } from 'react-hook-form';
import {
  BillGroupStatusEnum,
  DEFAULT_PAGER,
  IBillGroupResp,
  TDataTablePager,
} from '~app/types';
import { useGetListData } from '../../../api/queryUtils';
import { BILL_GROUP_STATUS_DISPLAY } from '../../../constants/billGroups';
import { MCustomSelectProps } from '../../../types/mCustomSelectTypes';
import { MFlex } from '../chakra';
import { MainSearchInputV2 } from '../MCustomSelect/components/MainSearchInputV2';
import MCustomSelect from '../MCustomSelect/MCustomSelect';
import { Highlighter } from '../MPageSearchInput/Highlighter';
import MText from '../MText';

interface BillGroupDropdownProps {
  accountId: string;
  statusFilter?: BillGroupStatusEnum[];
  field: ControllerRenderProps<any, any>;
  customSelectProps?: MCustomSelectProps;
}

const PAGER: TDataTablePager<keyof IBillGroupResp> = {
  ...DEFAULT_PAGER,
  sortField: 'name',
};

const EMPTY_LIST: IBillGroupResp[] = [];

export const BillGroupDropdown = ({
  accountId,
  statusFilter,
  field,
  customSelectProps,
}: BillGroupDropdownProps) => {
  const { data: billGroups, isLoading } = useGetListData<
    IBillGroupResp,
    IBillGroupResp[]
  >(
    'billGroups',
    { config: PAGER },
    {
      isGetAll: true,
      endpointArgs: { accountId },
      select: (data) =>
        data.content.filter(
          (billGroup) =>
            !statusFilter || statusFilter.includes(billGroup.status),
        ),
    },
  );

  useEffect(() => {
    if (billGroups?.length === 1) {
      field.onChange(billGroups[0].id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billGroups]);

  return (
    <MCustomSelect
      placeholder="Select a Bill Group"
      useMainInputAsSearchInput
      MainInputComponent={MainSearchInputV2}
      useHighlightedItemList
      hideHighlightSearchResultsCount
      items={billGroups || EMPTY_LIST}
      itemTitle="name"
      itemValue="id"
      loading={isLoading}
      renderItemContent={BillGroupItemRenderer}
      {...field}
      {...customSelectProps}
    />
  );
};

export const BillGroupItemRenderer = ({
  item,
  query,
}: {
  item: IBillGroupResp;
  query: string;
}) => {
  return (
    <MFlex
      direction="column"
      overflow="hidden"
      whiteSpace="nowrap"
      textOverflow="ellipsis"
      w="100%"
    >
      <MFlex justifyContent="space-between" w="100%">
        <Highlighter
          title={item.name}
          color="tPurple.dark"
          fontSize="sm"
          fontWeight="medium"
          textToHighlight={item.name}
          searchWords={[query]}
          whiteSpace="normal"
        />
      </MFlex>
      <MFlex justifyContent="space-between" w="100%">
        <MText color="tGray.darkPurple" fontSize="xs">
          {item.id}
        </MText>
        <MText color="tGray.darkPurple" fontSize="xs">
          {BILL_GROUP_STATUS_DISPLAY[item.status]}
        </MText>
      </MFlex>
    </MFlex>
  );
};
