import { zodResolver } from '@hookform/resolvers/zod';
import pick from 'lodash/pick';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useCreateAccount, useUpdateAccount } from '~api/accountsService';
import { handleApiErrorToast } from '~app/api/axios';
import { useGetListData } from '~app/api/queryUtils';
import {
  ILegalEntitiesSelector,
  selectLegalEntities,
} from '~app/api/selectors';
import { useCurrencies } from '~app/hooks/useCurrencies';
import { ILegalEntityResponseSchema } from '~app/types/legalEntityTypes';
import { MBox, MButton, MCenterModal, MStack } from '~components/Monetize';
import { ROUTES } from '~constants';
import {
  AccountSchema,
  AccountStatusEnum,
  DEFAULT_PAGER,
  IAccountSchema,
} from '~types';
import { nullifyEmptyStrings } from '../../utils/misc';
import AccountForm from './AccountForm';

interface AccountFormModalProps {
  isOpen: boolean;
  onClose: () => void;
  account?: any | null;
  isReadOnly?: boolean;
}

const AccountFormModal: FC<AccountFormModalProps> = ({
  isOpen,
  onClose,
  account: baseData,
  isReadOnly,
}: AccountFormModalProps) => {
  const { defaultCurrency } = useCurrencies();
  const { data: legalEntitiesData, isLoading } = useGetListData<
    ILegalEntityResponseSchema,
    ILegalEntitiesSelector
  >(
    'legalEntities',
    { config: DEFAULT_PAGER },
    {
      select: selectLegalEntities,
    },
  );

  const handleClose = () => {
    reset();
    onClose();
  };

  const { mutateAsync: doCreateAccount, isLoading: isCreatingAccount } =
    useCreateAccount({
      onSuccess: (newAccount) => {
        handleClose();
        navigate(ROUTES.getAccountDetailRoute(newAccount.id));
      },
      onError: (error) => {
        handleApiErrorToast(error);
      },
    });

  const { mutateAsync: doUpdateAccount, isLoading: isUpdatingAccount } =
    useUpdateAccount({
      onSuccess: (updatedAccount) => {
        handleClose();
      },
      onError: (error) => {
        handleApiErrorToast(error);
      },
    });

  const navigate = useNavigate();
  const formData = pick<IAccountSchema>(
    baseData,
    Object.keys(AccountSchema.shape),
  );
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    reset,
    watch,
  } = useForm<IAccountSchema>({
    resolver: zodResolver(AccountSchema),
    mode: 'onChange',
    defaultValues: {
      status: AccountStatusEnum.ACTIVE,
      defaultCurrency,
      isTestAccount: false,
      defaultLegalEntityId: legalEntitiesData?.defaultLegalEntity?.id,
      customFields: {},
      taxExemptionNumber: null,
      taxEntityUseCode: null,
      defaultDunningProcessId: null,
    },
    values: {
      defaultLegalEntityId: formData.defaultLegalEntityId
        ? formData.defaultLegalEntityId
        : legalEntitiesData?.defaultLegalEntity?.id!,
      defaultCurrency: formData.defaultCurrency
        ? formData.defaultCurrency
        : defaultCurrency,
      isTestAccount: formData.isTestAccount ? formData.isTestAccount : false,
      accountName: formData.accountName ? formData.accountName : '',
      status: formData.status ? formData.status : AccountStatusEnum.ACTIVE,
      customId: formData.customId ? formData.customId : null,
      customFields: formData.customFields ?? {},
      taxExemptionNumber: formData.taxExemptionNumber || null,
      taxEntityUseCode: formData.taxEntityUseCode || null,
      billingAddress: formData.billingAddress,
      shippingAddress: formData.shippingAddress,
      defaultDunningProcessId: formData.defaultDunningProcessId || null,
      ccEmails: formData.ccEmails || null,
    },
    resetOptions: {
      keepDirtyValues: true,
      keepErrors: true,
    },
  });

  const isValid = Object.keys(errors).length === 0;
  const onSubmit = async (data: IAccountSchema) => {
    data = nullifyEmptyStrings(data);
    if (baseData) {
      doUpdateAccount({
        id: baseData.id,
        data,
      });
    } else {
      doCreateAccount(data);
    }
  };

  return (
    <MCenterModal
      size="xl"
      isOpen={isOpen}
      onClose={handleClose}
      modalTitle={
        !baseData ? 'New Account' : `${!isReadOnly ? 'Edit' : 'View'} Account`
      }
      modalContentProps={{ 'data-testid': 'account-modal' } as any}
      renderFooter={
        isReadOnly
          ? null
          : () => (
              <MStack
                spacing={4}
                direction="row"
                align="center"
                justify="end"
                flex={1}
              >
                <MButton onClick={handleClose} variant="cancel" minW="auto">
                  Cancel
                </MButton>
                <MButton
                  variant="primary"
                  form="account-form"
                  isLoading={isCreatingAccount || isUpdatingAccount}
                  onClick={handleSubmit(onSubmit)}
                  isDisabled={!isValid}
                  type="submit"
                  minW="auto"
                >
                  Save
                </MButton>
              </MStack>
            )
      }
    >
      <MBox>
        <form id="account-form" onSubmit={handleSubmit(onSubmit)}>
          <AccountForm
            control={control}
            errors={errors}
            isReadOnly={isReadOnly}
            setValue={setValue}
            isExisting={!!baseData?.id}
            watch={watch}
            legalEntities={legalEntitiesData?.legalEntities || []}
          />
        </form>
      </MBox>
    </MCenterModal>
  );
};

export default AccountFormModal;
