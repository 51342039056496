export interface IHistory {
  objectType: string;
  objectId: string;
  operation: string;
  message: string;
  createDate: string;
}
export enum HistoryTypes {
  ACCOUNT = 'ACCOUNT',
  BILL_GROUP = 'BILL_GROUP',
  CONTACT = 'CONTACT',
  SUBSCRIPTION = 'SUBSCRIPTION',
  TEAM = 'TEAM',
}
