import { zodResolver } from '@hookform/resolvers/zod';
import React, { FunctionComponent as FC, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { MdInfo } from 'react-icons/md';

import { handleApiErrorToast } from '~app/api/axios';
import { useInviteUser } from '~app/api/usersService';
import { ROLES } from '~app/constants';
import { USER_ROLES_DISPLAY } from '~app/constants/roles';
import { logger } from '~app/services/logger';
import { getEmailsFromString, inflect } from '~app/utils';
import {
  MBox,
  MButton,
  MCenterModal,
  MCustomTagSelect,
  MFormField,
  MGrid,
  MGridItem,
  MIcon,
  MStack,
  MText,
  MTextarea,
  MTooltip,
} from '~components/Monetize';
import { useToast } from '~services/toast';
import {
  IUserInviteSchema,
  IUserRole,
  UserInviteSchema,
  UserRoleEnum,
} from '~types';

const RoleItemContent = ({
  title,
  item,
  isSubtitle,
  isSelected,
  isHighlight,
}: {
  title: string;
  item: any;
  isSubtitle: boolean;
  isSelected: boolean;
  isHighlight: boolean;
}) => {
  return (
    <MBox
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      // Prevent jitter when hovering and icon becomes visible
      minW="160px"
      w="100%"
      h="22px"
      position="relative"
      role="group"
    >
      <MText
        color="inherit"
        noOfLines={1}
        fontSize={isSubtitle ? '12px' : 'sm'}
        width="90%"
      >
        {USER_ROLES_DISPLAY[title as UserRoleEnum] || title}
      </MText>
      <MTooltip label={item.description} placement="bottom-start">
        <MBox position="relative" ml="4">
          <MIcon
            as={MdInfo}
            color="tPurple.base"
            display="none"
            sx={{
              '[role=group]:not(.chakra-form-control):hover &': {
                display: 'block',
              },
            }}
          />
        </MBox>
      </MTooltip>
    </MBox>
  );
};

export const renderRoleItemContent = ({
  title,
  item,
  isSubtitle,
  isSelected,
  isHighlight,
}: {
  title: string;
  item: any;
  isSubtitle: boolean;
  isSelected: boolean;
  isHighlight: boolean;
}) => (
  <RoleItemContent
    title={title}
    item={item}
    isSubtitle={isSubtitle}
    isSelected={isSelected}
    isHighlight={isHighlight}
  />
);

export const handleTransformRoleLabel = (title: string) =>
  USER_ROLES_DISPLAY[title as UserRoleEnum] || title;
interface InviteFormProps {
  children?: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
  tenantId: string;
  userRoles?: IUserRole[];
}

const InviteForm: FC<InviteFormProps> = (props: InviteFormProps) => {
  const { isOpen, onClose, tenantId, userRoles } = props;
  const { addToast } = useToast();
  const formDefault: IUserInviteSchema = {
    email: '',
    roleIds: [],
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    watch,
  } = useForm<IUserInviteSchema>({
    resolver: zodResolver(UserInviteSchema.nonstrict()),
    mode: 'onSubmit',
    defaultValues: formDefault,
  });

  const formRoleIds = watch('roleIds');

  useEffect(() => {
    if (isOpen) {
      // pass
    } else {
      reset(formDefault);
    }
  }, [reset, isOpen]);

  const doInviteUser = useInviteUser(tenantId, {
    onSuccess: (
      _,
      {
        invitees,
      }: {
        invitees: string[];
        roleIds: string[];
      },
    ) => {
      addToast({
        summary: 'Invite Sent Successfully',
        detail: `You have sent invitations to ${invitees.length} ${inflect({
          singular: 'person',
          plural: 'people',
          count: invitees.length,
        })} to join this tenant.`,
        severity: 'success',
      });
      onClose();
    },
    onError: (error) => {
      handleApiErrorToast(error);
    },
  });

  const onSubmit = async (requestData: IUserInviteSchema) => {
    const emails = getEmailsFromString(requestData.email);
    const body = {
      invitees: emails,
      roleIds: requestData.roleIds.map((role) => role.id),
    };
    doInviteUser.mutate(body);
  };

  const saveLoading = doInviteUser.isLoading;
  const onError = (errs: any, event: any) => {
    logger.error(errs);
  };

  return (
    <MCenterModal
      isOpen={isOpen}
      onClose={onClose}
      modalTitle="Invite User"
      modalBodyProps={{ pt: 0 }}
      renderFooter={() => (
        <MStack
          spacing={4}
          direction="row"
          align="center"
          justify="right"
          flex={1}
        >
          <MButton onClick={onClose} variant="cancel" minW="auto">
            Cancel
          </MButton>
          <MButton
            variant="primary"
            isLoading={saveLoading}
            onClick={handleSubmit(onSubmit, onError)}
            data-testid="send-invite-btn"
            minW="auto"
          >
            Send
          </MButton>
        </MStack>
      )}
    >
      <MBox>
        <form
          onSubmit={handleSubmit(onSubmit, onError)}
          data-testid="invite-user-form"
        >
          <MText maxW="23rem" mb="4">
            Invite one or more users by email address.
          </MText>
          <MGrid templateColumns="repeat(12, 1fr)" gap={4}>
            <MGridItem colSpan={12}>
              <MFormField
                error={errors.email}
                label="Email"
                tooltip="Use commas or new lines to add multiple email addresses."
                isRequired
              >
                <Controller
                  name="email"
                  control={control}
                  defaultValue=""
                  render={({ field: { onBlur, ...rest } }) => (
                    <MTextarea
                      placeholder="Use commas or new lines to add multiple email addresses"
                      spellCheck={false}
                      rows={1}
                      minH="8"
                      maxW="30rem"
                      {...rest}
                    />
                  )}
                />
              </MFormField>
            </MGridItem>
            {/* <MGridItem colSpan={12}>
              <MFormField error={errors.roleIds} label="Role">
                <Controller
                  name="roleIds"
                  control={control}
                  render={({ field }) => (
                    <MCustomTagSelect
                      placeholder="Select role"
                      items={userRoles}
                      itemTitle="name"
                      itemValue="id"
                      returnItem
                      inputWidth="200px"
                      renderItemContent={renderRoleItemContent}
                      transformLabel={handleTransformRoleLabel}
                      {...field}
                    />
                  )}
                />
              </MFormField>
            </MGridItem> */}
            <MGridItem colSpan={12}>
              <MFormField error={errors.roleIds} label="Role" isRequired>
                <Controller
                  name="roleIds"
                  control={control}
                  render={({ field }) => (
                    <MCustomTagSelect
                      showTags={false}
                      items={userRoles}
                      itemTitle="roleName"
                      itemValue="id"
                      returnItem
                      inputWidth="200px"
                      closeButtonText="Apply"
                      renderItemContent={renderRoleItemContent}
                      placeholder={
                        formRoleIds.length === 0
                          ? 'Select Role'
                          : formRoleIds.length === 1
                          ? ROLES.USER_ROLES_DISPLAY[
                              formRoleIds[0].name as UserRoleEnum
                            ]
                          : `${formRoleIds.length} Roles Selected`
                      }
                      inputProps={{
                        _placeholder: {
                          color:
                            formRoleIds.length === 0
                              ? 'inherit'
                              : 'tPurple.dark',
                        },
                      }}
                      {...field}
                    />
                  )}
                />
              </MFormField>
            </MGridItem>
          </MGrid>
        </form>
      </MBox>
    </MCenterModal>
  );
};

InviteForm.defaultProps = {
  children: null,
  userRoles: [],
};

export default InviteForm;
