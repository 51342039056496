import { FormControlProps } from '@chakra-ui/react';
import {
  MFormControl,
  MFormLabel,
  MRadio,
  MRadioGroup,
  MSkeleton,
} from '~app/components/Monetize';

interface CustomContractLengthProps {
  name: string;
  fetchLoading?: boolean;
  value: boolean;
  formControlProps?: FormControlProps;
  onChange: (value: boolean) => void | Promise<void>;
}

export const CustomContractLength = ({
  name,
  fetchLoading = false,
  value,
  formControlProps,
  onChange,
}: CustomContractLengthProps) => {
  return (
    <MFormControl
      display="flex"
      flexDir="column"
      columnGap={2}
      width="full"
      pt={4}
      {...formControlProps}
    >
      <MFormLabel htmlFor={name} fontSize="md" fontWeight="600">
        Custom Contract Length
      </MFormLabel>

      {fetchLoading ? (
        <MSkeleton height={50} width="100%" />
      ) : (
        <MRadioGroup
          onChange={(val: string) => onChange(val === 'true')}
          value={typeof value === 'undefined' ? 'true' : String(value)}
          display="flex"
          flexDirection="column"
          rowGap={2}
          ml={2}
        >
          <MRadio value="true">Any number of months (e.g. 15 months)</MRadio>
          <MRadio value="false">Whole years only (1 year, 2 year, etc.)</MRadio>
        </MRadioGroup>
      )}
    </MFormControl>
  );
};
