import { FC, useEffect, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { MFlex, MHeading } from '~app/components/Monetize';
import { MBackButton } from '~app/components/Monetize/MBackButton';
import { NotesDrawer } from '~app/components/Notes/NotesDrawer';
import { useFlags } from '~app/services/launchDarkly';
import { appLayoutState } from '~app/store/global.store';
import {
  INewQuoteTypeReqSchema,
  IQuoteRequestSchema,
  IQuoteRespSchema,
  QuoteStatusEnum,
} from '~app/types';
import OpportunityLink, { OpportunityLinkDataTypes } from '../OpportunityLink';
import { QuoteActions } from '../QuoteActions';
import { QuoteAlerts } from '../QuoteAlerts';
import { QuoteModalDataTypes } from '../QuoteReviewModal';
import ViewOriginalLink from '../ViewOriginalLink';
import { QuoteStateDataTypes } from '../quoteContext';
import { PreviewQuotePdfDrawer } from './PreviewQuotePdfDrawer';
import { QuoteInternalExternalViewToggle } from './QuoteInternalExternalViewToggle';
import { QuoteTags } from './QuoteTags';
import QuoteContactIcon from './contacts/QuoteContactIcon';
import CustomizeQuoteDrawer from './customizeQuoteDrawer/CustomizeQuoteDrawer';
import QuoteAnalytics from '../../../../components/Quotes/quoteAnalytics/QuoteAnalytics';
import { ReviewQuoteActionButton } from './reviewQuote/ReviewQuoteActionButton';

interface QuoteStickyMenuProps {
  title: string;
  isReadOnly: boolean;
  quote?: IQuoteRespSchema | null;
  manualRenewalFormMethods: UseFormReturn<INewQuoteTypeReqSchema, object>;
  showPrimaryContactRequiredBg: boolean;
  opportunityLinkData: OpportunityLinkDataTypes;
  handleBackButton: () => void;
  fetchQuote: (quoteId: string) => Promise<IQuoteRespSchema | null>;
  setContactDrawerOpen: (val: boolean) => void;
  setQuote: (quote: IQuoteRespSchema | null) => void;
  isInternalView: boolean;
  setIsInternalView: (val: boolean) => void;
  isEsignEnabled?: boolean;
  hasValidRole: boolean;
  isActionLoading?: boolean;
  onOpenManualAcceptanceModal: () => void;
  handleRecreateQuote: () => void;
  handleSendQuote: () => void;
  quoteFormMethods: UseFormReturn<IQuoteRequestSchema>;
  reviewQuoteModalData: QuoteModalDataTypes;
  quoteStateData: QuoteStateDataTypes;
}

export const QuoteStickyMenu: FC<QuoteStickyMenuProps> = ({
  title,
  quote,
  manualRenewalFormMethods,
  showPrimaryContactRequiredBg,
  opportunityLinkData,
  handleBackButton,
  fetchQuote,
  setContactDrawerOpen,
  setQuote,
  isReadOnly,
  isInternalView,
  setIsInternalView,
  isEsignEnabled = false,
  hasValidRole,
  isActionLoading = false,
  onOpenManualAcceptanceModal,
  handleRecreateQuote,
  handleSendQuote,
  quoteFormMethods,
  reviewQuoteModalData,
  quoteStateData,
}: QuoteStickyMenuProps) => {
  const { quoteInternalExternalView } = useFlags();
  const navigate = useNavigate();
  const layoutMode = useRecoilValue(appLayoutState);
  const isOverlay = layoutMode === 'overlay';
  const left = isOverlay ? '4rem' : '13rem';
  const isDraft = quote?.status === QuoteStatusEnum.DRAFT;
  const { offeringLoadingState } = quoteStateData;
  const anyOfferingLoading = Object.values(offeringLoadingState).some(
    (item) => item.isLoading,
  );

  const [displaySticky, setDisplaySticky] = useState<boolean>(false);

  useEffect(() => {
    window.addEventListener('scroll', handleStickyMenu);

    return () => {
      window.removeEventListener('scroll', handleStickyMenu);
    };
  }, []);

  const handleStickyMenu = () => {
    if (window !== undefined) {
      window.scrollY > 240 ? setDisplaySticky(true) : setDisplaySticky(false);
    }
  };

  if (!displaySticky || !quote) {
    return null;
  }

  return (
    <MFlex
      h="16"
      bg="tWhite.base"
      justify="space-between"
      position="fixed"
      top="0"
      left={left}
      width={`calc(100% - ${left})`}
      align="center"
      zIndex="11"
      px="8"
      boxShadow="22px 0px 56px 0px rgba(0, 0, 0, 0.13)"
    >
      <MFlex align="center">
        <MBackButton onClick={handleBackButton} backButtonTitle="Go Back" />
        <MFlex ml="1.5">
          <MHeading size="lg" color="tPurple.base">
            {title}
          </MHeading>
          <QuoteTags
            quote={quote}
            setQuote={setQuote}
            manualRenewalFormMethods={manualRenewalFormMethods}
          />
        </MFlex>
      </MFlex>
      <MFlex align="center" gap="2">
        <QuoteAlerts showDivider={false} validations={quote.validations} />
        {quoteInternalExternalView && (
          <QuoteInternalExternalViewToggle
            isInternalView={isInternalView}
            setIsInternalView={setIsInternalView}
          />
        )}
        <ViewOriginalLink quote={quote} />

        {quote.contacts && (
          <QuoteContactIcon
            showPrimaryContactRequiredBg={showPrimaryContactRequiredBg}
            contacts={quote.contacts}
            onOpen={() => setContactDrawerOpen(true)}
          />
        )}

        <QuoteAnalytics
          quote={quote}
          setQuote={setQuote}
          manualRenewalFormMethods={manualRenewalFormMethods}
          iconButtonStyles={{ m: 0 }}
        />

        {opportunityLinkData && (
          <OpportunityLink
            {...opportunityLinkData}
            iconButtonStyles={{ m: 0 }}
          />
        )}

        {quote.id && (
          <CustomizeQuoteDrawer quote={quote} iconButtonStyles={{ m: 0 }} />
        )}

        {quote.id && (
          <NotesDrawer
            id={quote.id}
            entity="quotes"
            disabled={!quote}
            iconButtonStyles={{ m: 0 }}
          />
        )}

        {quote.id && (
          <PreviewQuotePdfDrawer quote={quote} iconButtonStyles={{ m: 0 }} />
        )}

        <ReviewQuoteActionButton
          isReadOnly={isReadOnly}
          hasValidRole={hasValidRole}
          isActionLoading={isActionLoading}
          isEsignEnabled={isEsignEnabled}
          quote={quote}
          quoteFormMethods={quoteFormMethods}
          reviewQuoteModalData={reviewQuoteModalData}
          quoteStateData={quoteStateData}
          anyOfferingLoading={anyOfferingLoading}
          onAcceptQuote={onOpenManualAcceptanceModal}
          onRecreateQuote={handleRecreateQuote}
          onSendQuote={handleSendQuote}
        />

        {quote.id && (
          <QuoteActions
            quote={quote}
            setQuote={setQuote}
            loadData={() => quote.id && fetchQuote(quote.id)}
            manualRenewalFormMethods={manualRenewalFormMethods}
          />
        )}
      </MFlex>
    </MFlex>
  );
};
