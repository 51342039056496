import { FC } from 'react';
import { toDateShort } from '~app/utils/dates';
import { MAlert, MLink, MSpinner, MText } from '~components/Monetize';
import { BillGroupErrorStatusEnum, Maybe } from '~types';
import { usePastInvoice } from './hooks/usePastInvoice';

/**
 * Bill group generate invoice banner
 * After generation, the banner goes away for 15 seconds, then may reappear if the next invoice date is still in the past
 */
export interface BillGroupGenerateInvoiceProps {
  billGroupId: string;
  nextInvoiceDate: string;
  billGroupStatus: string;
  accountId: string;
  invoiceDelayDirty?: Maybe<boolean>;
  invoiceDelay?: Maybe<number>;
  errorStatus?: BillGroupErrorStatusEnum;
}

export const BillGroupGenerateInvoice: FC<BillGroupGenerateInvoiceProps> = ({
  billGroupId,
  nextInvoiceDate,
  billGroupStatus,
  accountId,
  invoiceDelayDirty = false,
  invoiceDelay = 0,
  errorStatus,
}: BillGroupGenerateInvoiceProps) => {
  const { canUpdate, hasPastInvoices, isLoading, generatePreviousInvoice } =
    usePastInvoice({
      accountId,
      billGroupId,
      billGroupStatus,
      nextInvoiceDate,
      invoiceDelay,
      invoiceDelayDirty,
    });

  if (!canUpdate || !hasPastInvoices) {
    return null;
  }

  if (errorStatus === BillGroupErrorStatusEnum.TAX_CALCULATION_ERROR) {
    return (
      <MAlert type="error">
        <MText>
          There is a tax calculation error on your most recent invoice.
        </MText>
      </MAlert>
    );
  }

  return (
    <MAlert type="info" fontWeight="500" fontSize="md" mb="4">
      This Bill Group has pending invoice(s). Next Invoice Date is{' '}
      {toDateShort(nextInvoiceDate)}.{' '}
      <MLink
        color="tIndigo.base"
        fontWeight="bold"
        onClick={() => {
          if (!isLoading) {
            generatePreviousInvoice({ accountId, billGroupId: billGroupId });
          }
        }}
      >
        Generate
      </MLink>
      {isLoading && <MSpinner size="sm" ml={2} />}
    </MAlert>
  );
};
