import { ReminderTypeEnum } from '~app/types';

export const EXPIRATION_REMINDER_TYPE_DISPLAY: {
  [key in ReminderTypeEnum]: string;
} = {
  FIRST_EMAIL: 'First Expiration Email',
  SECOND_EMAIL: 'Second Expiration Email',
  LAST_EMAIL: 'Last Expiration Email',
};

export const defaultQuoteExpirationReminder = [
  {
    type: ReminderTypeEnum.FIRST_EMAIL,
    tempId: 0,
    days: '',
  },
  {
    type: ReminderTypeEnum.SECOND_EMAIL,
    tempId: 1,
    days: '',
  },
  {
    type: ReminderTypeEnum.LAST_EMAIL,
    tempId: 2,
    days: '',
  },
];
