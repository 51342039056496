import { Tooltip, useDisclosure } from '@chakra-ui/react';
import { FC, useRef, useState } from 'react';
import { MdOutlineHistory } from 'react-icons/md';
import {
  MBox,
  MButton,
  MCircularProgress,
  MCustomIconButton,
  MDrawer,
  MDrawerBody,
  MDrawerCloseButton,
  MDrawerContent,
  MDrawerFooter,
  MDrawerHeader,
  MDrawerOverlay,
  MFlex,
  MStack,
  MText,
} from '~app/components/Monetize';
import MEmptyDataPlaceholder from '~app/components/Monetize/MEmptyDataPlaceholder';
import { useAccountHistory } from '~app/hooks';
import { DEFAULT_PAGER, TDataTablePager } from '~app/types';
import { formatInteger } from '~app/utils';
import AccountHistoryDrawerItemCard from './AccountHistoryDrawerItemCard';

const AccountHistoryDrawer: FC<{
  accountId?: string;
  disabled?: boolean;
}> = ({ accountId = null, disabled }: any) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const historyBtnRef = useRef<any>();
  const historyBodyTopRef = useRef<HTMLDivElement | null>(null);
  const historyBodyRef = useRef<HTMLDivElement | null>(null);
  const [pager, setPager] = useState<TDataTablePager>(DEFAULT_PAGER);
  const {
    isInitialLoading,
    isFetching,
    data: historyRecords,
  } = useAccountHistory(accountId, pager, {
    enabled: isOpen,
    keepPreviousData: true,
  });

  const scrollToTop = () => {
    historyBodyTopRef?.current &&
      historyBodyTopRef.current.scrollIntoView({
        behavior: 'smooth',
      });
  };
  const shouldRenderLoadMoreBtn = (historyRecords?.totalPages ?? 0) > 1;
  const hasHistoryRecords = (historyRecords?.totalPages ?? 0) > 0;
  const hasHistoryRecordContent = (historyRecords?.content.length ?? 0) > 0;
  return (
    <MBox position="relative">
      <Tooltip isDisabled={isOpen} label="View History" placement="bottom-end">
        <MCustomIconButton
          icon={MdOutlineHistory}
          btnSize={6}
          onClick={onOpen}
          isDisabled={disabled}
          aria-label="Account History"
          p={4}
          variant="icon"
        />
      </Tooltip>
      <MDrawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        size="sm"
        finalFocusRef={historyBtnRef}
        blockScrollOnMount={false}
      >
        <MDrawerOverlay />
        <MDrawerContent>
          <MDrawerCloseButton />
          <MDrawerHeader>Recent Account History</MDrawerHeader>
          <MDrawerBody px={2} bgColor="tGray.sidebarDark" ref={historyBodyRef}>
            <MBox ref={historyBodyTopRef} />
            {isInitialLoading && (
              <MFlex w="full" h="full" justify="center" align="center">
                <MCircularProgress isIndeterminate size={6} />
              </MFlex>
            )}
            {!isInitialLoading && !hasHistoryRecords && (
              <MFlex flexDirection="column" bgColor="tWhite.base">
                <MEmptyDataPlaceholder
                  mainMessage="Looks like there are no recent account history here."
                  boxProps={{ h: '3.25rem' }}
                  mainMessageProps={{
                    fontSize: 'md',
                    fontWeight: 'semibold',
                  }}
                />
              </MFlex>
            )}
            {hasHistoryRecordContent && (
              <>
                <MStack boxShadow="initial" spacing={2}>
                  {historyRecords?.content.map((historyRecord) => {
                    return (
                      <AccountHistoryDrawerItemCard
                        key={historyRecord.id}
                        {...historyRecord}
                      />
                    );
                  })}
                </MStack>
                <MFlex align="center" justify="center" flexDir="column" mt={4}>
                  <MText
                    align="center"
                    fontSize="xs"
                    color="tPurple.base"
                  >{`Showing ${formatInteger(historyRecords?.content.length)}
              of ${formatInteger(historyRecords?.totalElements)}`}</MText>
                  {shouldRenderLoadMoreBtn && (
                    <MButton
                      isLoading={!isInitialLoading && isFetching}
                      variant="tertiary"
                      onClick={() =>
                        setPager((currentPager) => ({
                          ...currentPager,
                          rows: currentPager.rows + 20,
                        }))
                      }
                    >
                      Load More
                    </MButton>
                  )}
                  {historyBodyRef.current &&
                    historyBodyRef.current.scrollHeight >
                      historyBodyRef.current.clientHeight && (
                      <MButton variant="tertiary" onClick={scrollToTop} mt={2}>
                        Back to Top
                      </MButton>
                    )}
                </MFlex>
              </>
            )}
          </MDrawerBody>
          <MDrawerFooter>
            <MBox w="full">
              <MFlex justify="center">
                <MButton
                  mt={5}
                  variant="cancel"
                  justifySelf="center"
                  onClick={onClose}
                >
                  Close
                </MButton>
              </MFlex>
            </MBox>
          </MDrawerFooter>
        </MDrawerContent>
      </MDrawer>
    </MBox>
  );
};

export default AccountHistoryDrawer;
