import { atom, DefaultValue, selectorFamily } from 'recoil';
import * as z from 'zod';
import {
  FilterType,
  IPaymentMethodReqUI,
  ISharedInvoicePaymentUI,
  ITenant,
  PaymentMethodReqSchemaUI,
  SharedInvoicePaymentSchemaUI,
  TDataTablePager,
  ToastItem,
} from '~app/types';
import { AppLayoutMode } from '~app/types/appTypes';
import { TIMEZONE_CONFIG } from '../utils/dates';
import {
  FilterStateKeys,
  getDefaultFilter,
  getDefaultPager,
  PersistKeysEnum,
  QuoteLocalPreferences,
  quoteLocalPreferencesSchema,
  RedirectInfo,
  RedirectInfoSchema,
  StorageOptions,
  stringValueSchema,
  TableFilterSchema,
  TablePagerSchema,
  TempDocuSignInfo,
  TempDocuSignInfoSchema,
  TenantStorageSchema,
} from './store.types';
import {
  clearBrowserStorage,
  getFromBrowserStorage,
  removeFalseValuesFromAdvancedQuoteOptions,
  saveToBrowserStorage,
} from './store.utils';

/**
 *
 * *****GLOBAL STORE*****
 *
 * The global store is used to store data that is used across the entire application
 * or data that should be persisted to localstorage
 *
 * The store is implemented using RecoilJS {@link https://recoiljs.org/}
 *
 * global.store -> This includes all atoms and selectors
 * store-migrations -> This includes any migrations that need to be run on localstorage
 * store.types -> This includes types BUT also includes the default table filters and pagers used across the application
 * store.utils -> This includes all helper functions used in the store, mostly used for storage mechanisms
 *
 * **** Storing data in localstorage or sessionStorage ****
 * * You probable want to set the default from existing values, so use `getFromBrowserStorage` on the default
 * * You will persist values to localstorage using the `saveToBrowserStorage` function, usually called from an effect
 * * For more complex use-cases, like storing a lot of values by key
 *    then look at these examples: tableFiltersState, tableFilterSelector, tablePagerState, tablePagerSelector
 *    And look at components using tableFilterSelector and tablePagerSelector
 * * You will need to add ZOD schemas to validate incoming and outgoing data and add the schema to SchemaValidators
 *    this ensures that we never have data in an unexpected shape coming into the application
 * * You will need to add a migration to store.migrations.ts if you change the shape of the data
 *
 */

// This stores the function provided from Auth0 useAuth hook so that it can be accessed in Axios interceptor
export const refreshAccessTokenState = atom<(() => Promise<string>) | null>({
  key: 'global-refreshAccessTokenState',
  default: null,
});

export const currentUserIdState = atom<string>({
  key: 'global-currentUserState',
  default: getFromBrowserStorage({
    persistKey: PersistKeysEnum.USER_ID,
    schemaValidator: stringValueSchema,
  }),
  effects: [
    // Playwright relies on this
    ({ onSet }) =>
      onSet((newValue) => {
        if (newValue) {
          saveToBrowserStorage({
            persistKey: PersistKeysEnum.USER_ID,
            schemaValidator: stringValueSchema,
            value: newValue as any,
          });
        } else {
          clearBrowserStorage({ persistKey: PersistKeysEnum.USER_ID });
        }
      }),
  ],
});

const DEFAULT_TENANT_STATE = getFromBrowserStorage({
  persistKey: PersistKeysEnum.CURRENT_TENANT,
  schemaValidator: TenantStorageSchema,
  defaultValue: null,
  type: StorageOptions.ALL,
});

// Initialize default timezone
if (DEFAULT_TENANT_STATE) {
  TIMEZONE_CONFIG.tenantTimezone = DEFAULT_TENANT_STATE.timezone;
}

export const currentTenantState = atom<ITenant | null>({
  key: 'global-currentTenantState',
  default: DEFAULT_TENANT_STATE,
  effects: [
    ({ onSet }) =>
      onSet((newValue) => {
        if (newValue) {
          saveToBrowserStorage({
            persistKey: PersistKeysEnum.CURRENT_TENANT,
            schemaValidator: TenantStorageSchema,
            value: newValue as any,
            type: StorageOptions.ALL,
          });
          TIMEZONE_CONFIG.tenantTimezone = newValue.timezone;
        } else {
          clearBrowserStorage({
            persistKey: PersistKeysEnum.CURRENT_TENANT,
            type: StorageOptions.ALL,
          });
          TIMEZONE_CONFIG.tenantTimezone = TIMEZONE_CONFIG.userTimezone;
        }
      }),
  ],
});

export const accessTokenState = atom<string>({
  key: 'global-accessTokenState',
  default: getFromBrowserStorage({
    persistKey: PersistKeysEnum.ACCESS_TOKEN,
    schemaValidator: stringValueSchema,
  }),
  effects: [
    ({ onSet }) =>
      onSet((newValue) =>
        saveToBrowserStorage({
          persistKey: PersistKeysEnum.ACCESS_TOKEN,
          schemaValidator: stringValueSchema,
          value: newValue,
        }),
      ),
  ],
});

export const activeToastState = atom<ToastItem | null>({
  key: 'global-activeToastState',
  default: null,
});

export const switchTenantVisibleState = atom<boolean>({
  key: 'global-switchTenantVisibleState',
  default: false,
});

export const navigationState = atom<{
  route: string;
  config?: any;
} | null>({
  key: 'global-navigationState',
  default: null,
});

export const quoteLocalPreferencesState = atom<QuoteLocalPreferences>({
  key: 'global-quoteLocalPreferencesState',
  default: getFromBrowserStorage({
    persistKey: PersistKeysEnum.QUOTE_PREFERENCES,
    schemaValidator: quoteLocalPreferencesSchema,
    defaultValue: {},
  }),
  effects: [
    ({ onSet }) =>
      onSet((newValue) =>
        saveToBrowserStorage({
          persistKey: PersistKeysEnum.QUOTE_PREFERENCES,
          schemaValidator: quoteLocalPreferencesSchema,
          value: newValue,
          transformFn: removeFalseValuesFromAdvancedQuoteOptions,
        }),
      ),
  ],
});

export const tempPaymentMethodState = atom<IPaymentMethodReqUI | null>({
  key: 'global-tempPaymentMethodState',
  default: getFromBrowserStorage({
    persistKey: PersistKeysEnum.PAYMENT_METHOD,
    schemaValidator: PaymentMethodReqSchemaUI,
    defaultValue: null,
  }),
  effects: [
    ({ onSet }) =>
      onSet((newValue) => {
        if (newValue) {
          saveToBrowserStorage({
            persistKey: PersistKeysEnum.PAYMENT_METHOD,
            schemaValidator: PaymentMethodReqSchemaUI,
            value: newValue as any,
          });
        } else {
          clearBrowserStorage({ persistKey: PersistKeysEnum.PAYMENT_METHOD });
        }
      }),
  ],
});

export const tempSharedInvoicePaymentState =
  atom<ISharedInvoicePaymentUI | null>({
    key: 'global-tempSharedInvoicePaymentState',
    default: getFromBrowserStorage({
      persistKey: PersistKeysEnum.SHARED_INVOICE_KEY,
      schemaValidator: SharedInvoicePaymentSchemaUI,
      defaultValue: null,
    }),
    effects: [
      ({ onSet }) =>
        onSet((newValue) => {
          if (newValue) {
            saveToBrowserStorage({
              persistKey: PersistKeysEnum.SHARED_INVOICE_KEY,
              schemaValidator: SharedInvoicePaymentSchemaUI,
              value: newValue as any,
            });
          } else {
            clearBrowserStorage({
              persistKey: PersistKeysEnum.SHARED_INVOICE_KEY,
            });
          }
        }),
    ],
  });

export const tempDocusignState = atom<TempDocuSignInfo | null>({
  key: 'global-tempDocusignState',
  default: getFromBrowserStorage({
    persistKey: PersistKeysEnum.DOCUSIGN,
    schemaValidator: TempDocuSignInfoSchema,
    defaultValue: null,
  }),
  effects: [
    ({ onSet }) =>
      onSet((newValue) => {
        if (newValue) {
          saveToBrowserStorage({
            persistKey: PersistKeysEnum.DOCUSIGN,
            schemaValidator: TempDocuSignInfoSchema,
            value: newValue as any,
          });
        } else {
          clearBrowserStorage({ persistKey: PersistKeysEnum.DOCUSIGN });
        }
      }),
  ],
});

export const tempRedirectInfoState = atom<RedirectInfo | null>({
  key: 'global-tempRedirectInfoState',
  default: getFromBrowserStorage({
    persistKey: PersistKeysEnum.REDIRECT_INFO,
    schemaValidator: RedirectInfoSchema,
    defaultValue: null,
  }),
  effects: [
    ({ onSet }) =>
      onSet((newValue) => {
        if (newValue) {
          saveToBrowserStorage({
            persistKey: PersistKeysEnum.REDIRECT_INFO,
            schemaValidator: RedirectInfoSchema,
            value: newValue as any,
          });
        } else {
          clearBrowserStorage({ persistKey: PersistKeysEnum.REDIRECT_INFO });
        }
      }),
  ],
});

/**
 * This filter includes the entire store
 * use the tableFilterSelector selector to get a slice
 */
export const tableFiltersState = atom({
  key: 'global-tableFiltersState',
  default: getFromBrowserStorage({
    persistKey: PersistKeysEnum.TABLE_FILTERS,
    schemaValidator: TableFilterSchema,
    defaultValue: {} as Record<string, FilterType[]>,
    type: StorageOptions.SESSION,
  }),
  effects: [
    ({ onSet }) =>
      onSet((newValue) => {
        if (newValue) {
          saveToBrowserStorage({
            persistKey: PersistKeysEnum.TABLE_FILTERS,
            schemaValidator: TableFilterSchema,
            value: newValue,
            type: StorageOptions.SESSION,
          });
        }
      }),
  ],
});

export const tableFilterSelector = selectorFamily<
  FilterType[],
  FilterStateKeys
>({
  key: 'global-tableFilterSelector',
  get:
    (filterKey) =>
    ({ get }) => {
      return (get(tableFiltersState)[filterKey] ||
        getDefaultFilter(filterKey)) as FilterType[];
    },
  set:
    (filterKey) =>
    ({ get, set }, newValue) => {
      if (!(newValue instanceof DefaultValue)) {
        set(tableFiltersState, {
          ...get(tableFiltersState),
          [filterKey]: newValue,
        });
      }
    },
});

export const tablePagerState = atom({
  key: 'global-tablePagerState',
  default: getFromBrowserStorage({
    persistKey: PersistKeysEnum.TABLE_PAGERS,
    schemaValidator: TablePagerSchema,
    defaultValue: {} as Record<string, TDataTablePager>,
    type: StorageOptions.SESSION,
  }),
  effects: [
    ({ onSet }) =>
      onSet((newValue) => {
        if (newValue) {
          saveToBrowserStorage({
            persistKey: PersistKeysEnum.TABLE_PAGERS,
            schemaValidator: TablePagerSchema,
            value: newValue,
            type: StorageOptions.SESSION,
          });
        }
      }),
  ],
});

export const tablePagerSelector = selectorFamily<
  TDataTablePager,
  FilterStateKeys
>({
  key: 'global-tablePagerSelector',
  get:
    (filterKey) =>
    ({ get }) => {
      return (get(tablePagerState)[filterKey] ||
        getDefaultPager(filterKey)) as TDataTablePager;
    },
  set:
    (filterKey) =>
    ({ get, set }, newValue) => {
      if (!(newValue instanceof DefaultValue)) {
        set(tablePagerState, {
          ...get(tablePagerState),
          [filterKey]: newValue,
        });
      }
    },
});

/**
 * This keeps track of the page layout state
 * Keeping here since we probably need to access it in the child component
 */
export const appLayoutState = atom<AppLayoutMode>({
  key: 'appLayout',
  default: 'overlay',
});

export const appGlobalDataState = atom({
  key: 'global-appDataState',
  default: {
    hasMultipleCurrency: false,
    hasMultipleLegalEntity: false,
    hasCrmConfigured: false,
  },
});

export const currencyFilterState = atom<string>({
  key: 'dashboard-currency',
  default: getFromBrowserStorage({
    persistKey: PersistKeysEnum.DASHBOARD_CURRENCY_FILTER,
    schemaValidator: z.string(),
    type: StorageOptions.SESSION,
  }),
  effects: [
    ({ onSet }) =>
      onSet((newValue) => {
        if (newValue) {
          saveToBrowserStorage({
            persistKey: PersistKeysEnum.DASHBOARD_CURRENCY_FILTER,
            schemaValidator: z.string(),
            value: newValue,
            type: StorageOptions.SESSION,
          });
        }
      }),
  ],
});
