import { useEffect } from 'react';
import { handleApiErrorToast } from '~app/api/axios';
import {
  MBox,
  MPageContainer,
  MSettingsPageHeader,
  MSkeleton,
  MVStack,
} from '~app/components/Monetize';
import { useQuoteSettings } from '~app/hooks';
import { useDocumentHead } from '~app/services/documentHead';
import {
  IQuoteSettingsConfigSchema,
  QuoteSettingsDefaultAddressSourceEnum,
} from '~app/types';
import ApprovalNotes from './ApprovalNotes';
import DefaultQuoteDocuments from './DefaultQuoteDocuments';
import { DefaultSigningContacts } from './DefaultSigningContacts';
import NetTerms from './NetTerms';
import { SettingsToggle } from './SettingsToggle';
import { DefaultContractRenewalTermsAccordion } from './DefaultContractRenewalTermsAccordion';
import { CustomContractLength } from './CustomContractLength';
import { AddressSourceSetting } from './AddressSourceSetting';
import { useFlags } from '~app/services/launchDarkly';

const QuoteSettings = () => {
  const { enableAccountBasedAddresses } = useFlags();
  const { setDocTitle } = useDocumentHead();
  const { fetchLoading, quoteSettings, doSaveQuoteSettings } =
    useQuoteSettings();
  useEffect(() => {
    setDocTitle('Settings', 'Quote Settings');
  }, []);

  /**
   * Handle save and show error message if there is an error
   * Returns boolean if the save was unsuccessful
   */
  const handleSave = async (
    payload: IQuoteSettingsConfigSchema,
  ): Promise<void> => {
    try {
      await doSaveQuoteSettings({
        ...payload,
        defaultAddressSource:
          payload.defaultAddressSource ||
          QuoteSettingsDefaultAddressSourceEnum.CONTACT,
      });
    } catch (ex) {
      handleApiErrorToast(ex);
    }
  };

  return (
    <MPageContainer data-testid="quote-settings-list">
      <MSettingsPageHeader title="Quote Settings" />

      <MBox w={'100%'} mt={0} overflow="auto">
        <MVStack maxW="600px" spacing={0}>
          {fetchLoading ? (
            <MSkeleton height={200} width="100%" />
          ) : (
            <>
              <ApprovalNotes
                approvalMessage={quoteSettings?.quoteApprovalNotes!}
                onSubmit={(quoteApprovalNotes) =>
                  handleSave({ ...quoteSettings!, quoteApprovalNotes })
                }
              />

              <NetTerms
                netTerms={quoteSettings?.netTerms!}
                onSave={(netTerms) =>
                  handleSave({ ...quoteSettings!, netTerms })
                }
              />
              <DefaultSigningContacts />
              <DefaultQuoteDocuments
                isLoading={fetchLoading}
                defaultQuoteDocuments={
                  quoteSettings?.defaultQuoteDocuments ?? []
                }
              />
            </>
          )}

          {enableAccountBasedAddresses && (
            <AddressSourceSetting
              isLoading={fetchLoading}
              value={quoteSettings?.defaultAddressSource}
              onChange={(defaultAddressSource) =>
                handleSave({
                  ...quoteSettings!,
                  defaultAddressSource,
                })
              }
            />
          )}
          <CustomContractLength
            name="allowCustomLength"
            fetchLoading={fetchLoading}
            value={!!quoteSettings?.customContractLength.allowCustomLength}
            onChange={(allowCustomLength) =>
              handleSave({
                ...quoteSettings!,
                customContractLength: { allowCustomLength },
              })
            }
          />
          <SettingsToggle
            name="allowPONumberOnQuotes"
            heading="Purchase Order (PO) Number"
            subheading="Allow users to add a PO number on quote."
            fetchLoading={fetchLoading}
            value={quoteSettings?.allowPONumberOnQuotes!}
            onChange={(allowPONumberOnQuotes) =>
              handleSave({
                ...quoteSettings!,
                allowPONumberOnQuotes,
              })
            }
          />

          {fetchLoading ? (
            <MSkeleton height={50} width="100%" />
          ) : (
            <DefaultContractRenewalTermsAccordion
              contractRenewalTerms={quoteSettings?.contractRenewalTerms!}
              onSubmit={(contractRenewalTerms) =>
                handleSave({ ...quoteSettings!, contractRenewalTerms })
              }
            />
          )}
        </MVStack>
      </MBox>
    </MPageContainer>
  );
};
export default QuoteSettings;
