import { z } from 'zod';

export enum QuoteSettingsDefaultAddressSourceEnum {
  CONTACT = 'CONTACT',
  ACCOUNT = 'ACCOUNT',
}

export const QuoteSettingsDefaultAddressSourceEnumZ = z.nativeEnum(
  QuoteSettingsDefaultAddressSourceEnum,
);
