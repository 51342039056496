import { FormLabel, Heading } from '@chakra-ui/react';
import { ReactNode, useEffect } from 'react';
import {
  Control,
  FieldErrors,
  useFieldArray,
  UseFormSetValue,
} from 'react-hook-form';
import { CustomFieldValue, ICustomFieldReqUiSchema } from '~app/types';
import {
  MButton,
  MGrid,
  MGridItem,
  MText,
} from '../../../../components/Monetize';
import { CustomFieldsFormDropdownValueRow } from './CustomFieldsFormDropdownValueRow';

function getNewRow(order: number): CustomFieldValue {
  return {
    enabled: true,
    order,
    label: '',
    value: '',
  };
}

interface CustomFieldsFormDropdownValuesProps {
  control: Control<ICustomFieldReqUiSchema>;
  errors: FieldErrors<ICustomFieldReqUiSchema>;
  existingFieldValues: Set<string>;
  setValue: UseFormSetValue<ICustomFieldReqUiSchema>;
}

export const CustomFieldsFormDropdownValues = ({
  control,
  errors,
  setValue,
  existingFieldValues,
}: CustomFieldsFormDropdownValuesProps) => {
  const { append, fields, move, remove } = useFieldArray({
    name: 'values',
    control,
  });

  useEffect(() => {
    if (fields.length === 0) {
      append(getNewRow(0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields]);

  return (
    <>
      <Heading as="h2" fontWeight={600} fontSize="md" mt={3} mb={2}>
        Dropdown Values
      </Heading>

      <MText color="tRed.base">{errors.values?.message}</MText>

      <MGrid
        maxWidth="512px"
        templateColumns="20px 1fr 1fr 50px 20px"
        gap={2}
        mt={2}
      >
        <MGridItem />
        <MGridItem>
          <FieldHeading>Label</FieldHeading>
        </MGridItem>
        <MGridItem>
          <FieldHeading>Value</FieldHeading>
        </MGridItem>
        <MGridItem>
          <FieldHeading>Enabled</FieldHeading>
        </MGridItem>
      </MGrid>

      {fields.map((field, index) => (
        <CustomFieldsFormDropdownValueRow
          key={field.id}
          index={index}
          showDragHandle={fields.length > 1}
          control={control}
          errors={errors}
          field={field}
          setValue={setValue}
          onMove={move}
          fieldExists={existingFieldValues.has(field.value)}
          preventDelete={
            fields.length === 1 || existingFieldValues.has(field.value)
          }
          onRemove={() => remove(index)}
        />
      ))}
      <MButton
        variant="tertiary"
        size="sm"
        ml={4}
        mt={2}
        onClick={() => {
          append(getNewRow(fields.length - 1));
        }}
      >
        + Add Value
      </MButton>
      <MGridItem />
    </>
  );
};

const FieldHeading = ({ children }: { children: ReactNode }) => (
  <MGridItem>
    <FormLabel mr="0" mb={0} display="inline-block">
      {children}
    </FormLabel>
  </MGridItem>
);
