import { Accordion } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { handleApiErrorToast } from '~app/api/axios';
import {
  useConfigNotificationEmailOrDomain,
  useConfigureNotification,
  useGetNotificationSettings,
} from '~app/api/settingsService';
import {
  MAccordionCustomButton,
  MAccordionCustomButtonItem,
  MBox,
  MButton,
  MFormField,
  MHStack,
  MInput,
  MPageContainer,
  MPageLoader,
  MRadio,
  MRadioGroup,
  MSettingsPageHeader,
  MStack,
  MTag,
  MTagCloseButton,
  MTagLabel,
  MText,
  MWrap,
  MWrapItem,
} from '~app/components/Monetize';
import { MSettingAccordionItem } from '~app/components/Monetize/MSettingAccordionItem';
import {
  NotificationTypeEnumDisplay,
  NotificationTypePlaceholder,
} from '~app/constants/notifications';
import { useAuth } from '~app/services/auth0';
import { useDocumentHead } from '~app/services/documentHead';
import {
  INotificationDomainSchema,
  INotificationEmailSchema,
  INotificationSchema,
  NotificationDomainSchema,
  NotificationEmailSchema,
  NotificationSchema,
  NotificationTypeEnum,
} from '~app/types/notificationTypes';

export const Notifications = () => {
  const { setDocTitle } = useDocumentHead();
  const { currentTenant } = useAuth();
  const [submitDomainLoading, setSubmitDomainLoading] =
    useState<boolean>(false);
  const [submitEmailLoading, setSubmitEmailLoading] = useState<boolean>(false);
  const [domainList, setDomainList] = useState<string[]>([]);
  const [emailList, setEmailList] = useState<string[]>([]);
  const [settings, setSettings] = useState<INotificationSchema>({
    restrict: currentTenant?.testTenant ? true : false,
    notifications: [],
  });

  const { data: notificationSettings, isLoading: isGetLoading } =
    useGetNotificationSettings();
  const { mutate: doConfigureNotification } = useConfigureNotification();
  const { mutateAsync: doConfigNotificationEmailOrDomain } =
    useConfigNotificationEmailOrDomain();

  const {
    control: restrictControl,
    formState: { errors: restrictErrors, isSubmitting: isRestrictSubmitting },
    reset: resetRestrict,
    handleSubmit: handleRestrictSubmit,
  } = useForm<INotificationSchema>({
    resolver: zodResolver(NotificationSchema),
    mode: 'onChange',
    defaultValues: {
      restrict: currentTenant?.testTenant ? true : false,
      notifications: [],
    },
  });

  const {
    control: domainControl,
    formState: { errors: domainErrors, isValid: isDomainValid },
    reset: resetDomain,
    getValues: getDomainValue,
    handleSubmit: handleDomainSubmit,
  } = useForm<INotificationDomainSchema>({
    resolver: zodResolver(NotificationDomainSchema),
    mode: 'onChange',
    defaultValues: { domain: '' },
  });

  const {
    control: emailControl,
    formState: { errors: emailErrors, isValid: isEmailValid },
    reset: resetEmail,
    getValues: getEmailValue,
    handleSubmit: handleEmailSubmit,
  } = useForm<INotificationEmailSchema>({
    resolver: zodResolver(NotificationEmailSchema),
    mode: 'onChange',
    defaultValues: { email: '' },
  });

  useEffect(() => {
    setDocTitle('Settings', 'Notifications');
  }, []);

  useEffect(() => {
    if (notificationSettings) {
      setSettings({ ...notificationSettings });
      resetRestrict({ ...notificationSettings });
    }
  }, [notificationSettings]);

  useEffect(() => {
    if (settings) {
      setDomainList(
        settings.notifications.find(
          ({ type: notificationType }) =>
            notificationType === NotificationTypeEnum.DOMAIN,
        )?.emailOrDomains || [],
      );
      setEmailList(
        settings.notifications.find(
          ({ type: notificationType }) =>
            notificationType === NotificationTypeEnum.EMAIL,
        )?.emailOrDomains || [],
      );
    }
  }, [settings]);

  const onRestrictSubmit = async (data: INotificationSchema) => {
    try {
      await doConfigureNotification(data);
    } catch (error) {
      return error;
    }
  };

  const onRestrictError = (error: any) => {
    handleApiErrorToast(error);
  };

  const onSubmitDomain = async () => {
    const value = getDomainValue('domain');
    const updatedList = domainList
      .filter((domain) => domain !== value)
      .concat(value); // don't add same domain  twice
    const domainPayload = {
      type: NotificationTypeEnum.DOMAIN,
      emailOrDomains: updatedList,
    };
    try {
      setSubmitDomainLoading(true);
      await doConfigNotificationEmailOrDomain(domainPayload);
      resetDomain();
    } catch (err) {
      handleApiErrorToast(err);
    } finally {
      setSubmitDomainLoading(false);
    }
  };

  const onSubmitEmail = async () => {
    const value = getEmailValue('email');
    const updatedList = emailList
      .filter((email) => email !== value)
      .concat(value); // don't add same domain  twice
    const emailPayload = {
      type: NotificationTypeEnum.EMAIL,
      emailOrDomains: updatedList,
    };

    try {
      setSubmitEmailLoading(true);
      await doConfigNotificationEmailOrDomain(emailPayload);
      resetEmail();
    } catch (err) {
      handleApiErrorToast(err);
    } finally {
      setSubmitEmailLoading(false);
    }
  };

  const handleRemove = async (
    item: string,
    notificationType: NotificationTypeEnum,
  ) => {
    let updatedList: string[] = [];
    if (notificationType === NotificationTypeEnum.DOMAIN) {
      updatedList = domainList.filter((domain) => domain !== item);
    } else if (notificationType === NotificationTypeEnum.EMAIL) {
      updatedList = emailList.filter((email) => email !== item);
    }

    try {
      await doConfigNotificationEmailOrDomain({
        type: notificationType,
        emailOrDomains: updatedList,
      });
    } catch (err) {
      handleApiErrorToast(err);
    }
  };

  const renderTagList = (notificationType: NotificationTypeEnum) => {
    const emailOrDomainsList =
      notificationType === NotificationTypeEnum.DOMAIN ? domainList : emailList;
    return emailOrDomainsList.map((item, index) => {
      return (
        <MWrapItem key={index}>
          <MTag size="md" borderRadius="full" variant="outline" my={1}>
            <MTagLabel>{item}</MTagLabel>
            <MTagCloseButton
              data-testid="remove-email-or-domain"
              onClick={() => handleRemove(item, notificationType)}
            />
          </MTag>
        </MWrapItem>
      );
    });
  };

  return (
    <MPageContainer data-testid="email-notifications-page" alignItems="stretch">
      <MBox>
        <MSettingsPageHeader title="Notifications" />
      </MBox>

      {isGetLoading && <MPageLoader />}
      <MBox maxW="600px" mb={4}>
        {!isGetLoading && (
          <>
            <MText mb={6} color="tGray.darkGrayPurple">
              Select email domains or addresses to allow sending emails while
              testing. We will not send emails in a Test tenant unless you
              explicitly set them here
            </MText>

            <form>
              <MFormField
                error={restrictErrors.restrict}
                label="Email Send Behavior"
              >
                <Controller
                  name="restrict"
                  control={restrictControl}
                  render={({ field: { value, onChange, ...rest } }) => {
                    return (
                      <MRadioGroup
                        {...rest}
                        value={
                          typeof value === 'undefined' ? 'true' : String(value)
                        }
                        onChange={(val: string) => {
                          onChange(val === 'true');
                          handleRestrictSubmit(
                            onRestrictSubmit,
                            onRestrictError,
                          )();
                        }}
                      >
                        <MStack direction="column" columnGap={4}>
                          <MRadio
                            value="false"
                            isDisabled={
                              currentTenant?.testTenant || isRestrictSubmitting
                            }
                          >
                            Allow All Email
                          </MRadio>
                          <MRadio value="true">
                            <MText fontWeight="normal" as="span">
                              Restrict Email to Configured Destinations
                            </MText>
                          </MRadio>
                          );
                        </MStack>
                      </MRadioGroup>
                    );
                  }}
                />
              </MFormField>
            </form>

            <MStack w="100%" data-testid="netTermsConfig" mt={4}>
              <Accordion allowMultiple w="full">
                <MSettingAccordionItem
                  isDisabled={!settings.restrict}
                  description="Allow emails to be sent to specific email domains."
                  renderAccordionButton={({
                    isExpanded,
                  }: {
                    isExpanded: boolean;
                  }) => (
                    <MAccordionCustomButton
                      isExpanded={isExpanded}
                      label="Allow By Domain"
                    >
                      {domainList.length > 0 && (
                        <MAccordionCustomButtonItem
                          align="left"
                          label="Total:"
                          value={domainList.length || ''}
                          isExpanded={isExpanded}
                          hideWhenExpanded
                          w="70px"
                        />
                      )}
                    </MAccordionCustomButton>
                  )}
                >
                  <MText fontWeight="bold" mb={1}>
                    Domain
                  </MText>

                  <form onSubmit={handleDomainSubmit(onSubmitDomain)}>
                    <MHStack alignItems="flex-start" mb={3} maxW="392">
                      <MFormField error={domainErrors.domain} isRequired>
                        <Controller
                          name="domain"
                          control={domainControl}
                          defaultValue=""
                          render={({ field }) => (
                            <MInput
                              data-testid="email-or-domain-input"
                              placeholder={
                                NotificationTypePlaceholder[
                                  NotificationTypeEnum.DOMAIN
                                ]
                              }
                              {...field}
                            />
                          )}
                        />
                      </MFormField>

                      <MButton
                        size="sm"
                        data-testid="add-email-or-domain"
                        variant="primary"
                        type="submit"
                        onClick={handleDomainSubmit(onSubmitDomain)}
                        isDisabled={!isDomainValid || submitDomainLoading}
                        isLoading={submitDomainLoading}
                      >
                        Add
                      </MButton>
                    </MHStack>
                  </form>

                  <MWrap>{renderTagList(NotificationTypeEnum.DOMAIN)}</MWrap>
                </MSettingAccordionItem>
                <MSettingAccordionItem
                  isDisabled={!settings.restrict}
                  mt={4}
                  description="Allow emails to be sent to specific email address."
                  renderAccordionButton={({
                    isExpanded,
                  }: {
                    isExpanded: boolean;
                  }) => (
                    <MAccordionCustomButton
                      isExpanded={isExpanded}
                      label="Allow By Email"
                    >
                      {emailList.length > 0 && (
                        <MAccordionCustomButtonItem
                          align="left"
                          label="Total:"
                          value={emailList.length || ''}
                          isExpanded={isExpanded}
                          hideWhenExpanded
                          w="70px"
                        />
                      )}
                    </MAccordionCustomButton>
                  )}
                >
                  <MText fontWeight="bold" mb={1}>
                    {`${
                      NotificationTypeEnumDisplay[NotificationTypeEnum.EMAIL]
                    }`}
                  </MText>
                  <form onSubmit={handleEmailSubmit(onSubmitEmail)}>
                    <MHStack alignItems="flex-start" mb={3} maxW="392">
                      <MFormField error={emailErrors.email} isRequired>
                        <Controller
                          name="email"
                          control={emailControl}
                          defaultValue=""
                          render={({ field }) => (
                            <MInput
                              data-testid="email-or-domain-input"
                              placeholder={
                                NotificationTypePlaceholder[
                                  NotificationTypeEnum.EMAIL
                                ]
                              }
                              {...field}
                            />
                          )}
                        />
                      </MFormField>

                      <MButton
                        size="sm"
                        data-testid="add-email-or-domain"
                        variant="primary"
                        type="submit"
                        onClick={handleEmailSubmit(onSubmitEmail)}
                        isDisabled={!isEmailValid || submitEmailLoading}
                        isLoading={submitEmailLoading}
                      >
                        Add
                      </MButton>
                    </MHStack>
                  </form>
                  <MWrap>{renderTagList(NotificationTypeEnum.EMAIL)}</MWrap>
                </MSettingAccordionItem>
              </Accordion>
            </MStack>
          </>
        )}
      </MBox>
    </MPageContainer>
  );
};
