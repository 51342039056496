import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  MBox,
  MButton,
  MHeading,
  MPageContainer,
  MText,
} from '~app/components/Monetize';
import { AUTH0_ERROR_MAPPING } from '~app/constants/auth0';
import { useQuery } from '~app/hooks';
import { useAuth } from '~app/services/auth0';
import { toTitleCase } from '~app/utils';
import AppLoading from '~components/App/AppLoading';
import { ROUTES } from '~constants';

const CallbackError = ({
  description,
  error,
}: {
  description: string;
  error: string;
}) => {
  const desc = (AUTH0_ERROR_MAPPING as any)[description] || description;

  return (
    <MPageContainer>
      <MBox
        display="flex"
        alignItems="center"
        flexDirection="column"
        justifyContent="center"
        flex={1}
      >
        <MHeading fontSize="3xl" mb="4" color="tPurple.base">
          {/* turns access_denied into Access Denied */}
          {error.split('_').map(toTitleCase).join(' ')}
        </MHeading>
        <MText size="lg" textAlign="center">
          {desc}
        </MText>

        <MButton variant="secondary" my="4" as={Link} to={ROUTES.AUTH_LOGOUT}>
          Back to Login
        </MButton>
      </MBox>
    </MPageContainer>
  );
};

const Callback = () => {
  const { getAndHandleAccessTokenSilently } = useAuth();
  const { isAuthenticated, isLoading } = useAuth();
  const navigate = useNavigate();
  const query = useQuery();
  const error = query.get('error') || '';
  const errorDescription = query.get('error_description') || '';

  const getUserMetadata = async () => {
    await getAndHandleAccessTokenSilently();
    navigate(ROUTES.ROOT);
  };

  useEffect(() => {
    if (isAuthenticated && !isLoading) {
      getUserMetadata();
    }
  }, [isAuthenticated, isLoading]);

  /**
   * Handle error when error is valid string
   * example: https://dev.monetizeplatform.com/auth/callback?error=unauthorized&error_description=Your%20company%20admin%20is%20preventing%20login%20with%20this%20method%20of%20authentication%2C%20please%20try%20another%20method%20such%20as%20Google%20Login.&state=MkNkUWczdmhWMFBTX0ZJaDlxSWgtWWJWRXlpT21kVEVZMVpWZUJQSzVENw%3D%3D
   */
  if (error) {
    return <CallbackError error={error} description={errorDescription} />;
  }

  return <AppLoading loadingText="Logging You in..." />;
};

export default Callback;
