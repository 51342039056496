import { BoxProps, TextProps } from '@chakra-ui/react';
import { MouseEvent, ReactNode } from 'react';
import { MBox, MFlex, MHeading, MText } from '~app/components/Monetize';

const disabledStyle = {
  color: 'tPurple.lightGrayPurple',
};

export interface ButtonCardProps extends Omit<BoxProps, 'title'> {
  titleLeftContent?: ReactNode;
  title: ReactNode;
  titleProps?: TextProps;
  /** Shown to the right of the title area */
  titleRightContent?: ReactNode;
  descriptionProps?: TextProps;
  description: ReactNode;
  isDisabled?: boolean;
  comingSoon?: boolean;
  onClick?: (event?: MouseEvent<HTMLDivElement>) => void;
}

export const ButtonCard = ({
  title,
  titleProps,
  titleLeftContent,
  titleRightContent,
  description,
  descriptionProps,
  isDisabled,
  onClick,
  ...rest
}: ButtonCardProps) => {
  return (
    <MBox
      w="310px"
      p={4}
      borderRadius="4"
      bgColor="tWhite.base"
      boxShadow="card"
      borderWidth="1px"
      borderColor="tGray.support"
      _hover={
        !isDisabled
          ? {
              bgColor: 'tBlue.hover',
              boxShadow: 't2',
              cursor: 'pointer',
            }
          : {}
      }
      role="group"
      onClick={onClick}
      {...rest}
    >
      <MFlex alignItems="center" justifyContent="space-between">
        <MFlex columnGap={1.5} alignItems="center">
          {titleLeftContent}
          <MHeading
            fontWeight="medium"
            size="sm"
            color={isDisabled ? disabledStyle.color : 'tPurple.dark'}
            {...titleProps}
          >
            {title}
          </MHeading>
        </MFlex>
        {titleRightContent}
      </MFlex>

      <MText
        color={isDisabled ? disabledStyle.color : 'tGray.darkPurple'}
        _groupHover={!isDisabled ? { color: 'tPurple.dark' } : {}}
        mt={2}
        lineHeight={'18px'}
        {...descriptionProps}
      >
        {description}
      </MText>
    </MBox>
  );
};
