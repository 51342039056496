import { AggregatedApplicationTransactionResponseWithInvoices } from '../../api/transactableService';
import {
  AggregatedApplicationTransactionResponse,
  AggregatedTransactablesResponse,
  AppTrxnType,
  InvoiceApplicationData,
  InvoiceApplicationFormData,
  InvoiceApplicationFormDataRow,
  Maybe,
  SelectionStateEnum,
  TransactableTargetType,
} from '../../types';
import { roundNumberToDecimal } from '../../utils/numberUtils';

/**
 * Calculate initial state for application form
 *
 * Transform data from useGetAggregatedTransactionApplicationsWithInvoices
 * into the form representation
 */
export const calculateApplicationFromData = ({
  aggregatedApplicationsById,
  aggregateTransactable,
  allInvoices,
}: AggregatedApplicationTransactionResponseWithInvoices) => {
  const data = {
    aggregateTransactable,
    checkedState: SelectionStateEnum.NONE, // will potentially get updated
    rows: allInvoices.map((invoice) => {
      const aggregateTransaction = aggregatedApplicationsById[invoice.id];

      const appliedAmount =
        aggregateTransaction?.amountAppliedByTransactable || 0;

      const amountAppliedFromOtherTransactables =
        invoice.amount -
        invoice.amountDue +
        (aggregateTransaction?.amountAppliedByTransactable || 0);

      const amountDueWithoutApplied =
        invoice.amountDue +
        (aggregateTransaction?.amountAppliedByTransactable || 0);

      let checkedState = SelectionStateEnum.NONE;
      if (appliedAmount > 0 && appliedAmount < invoice.amountDue) {
        checkedState = SelectionStateEnum.SOME;
      } else if (appliedAmount >= invoice.amountDue) {
        checkedState = SelectionStateEnum.ALL;
      }

      return {
        checkedState,
        aggregateTransaction,
        appliedAmount,
        amountAppliedFromOtherTransactables,
        amountDueWithoutApplied,
        invoice,
      };
    }),
  };
  if (data.rows.every((row) => row.checkedState === SelectionStateEnum.ALL)) {
    data.checkedState = SelectionStateEnum.ALL;
  } else if (
    data.rows.some(
      (row) =>
        row.checkedState === SelectionStateEnum.ALL ||
        row.checkedState === SelectionStateEnum.SOME,
    )
  ) {
    data.checkedState = SelectionStateEnum.SOME;
  }
  return data;
};

export const calculateAmounts = (
  sourceOriginalAmount: number,
  rows: InvoiceApplicationFormDataRow[],
  aggregateTransactable?: Maybe<AggregatedTransactablesResponse>,
) => {
  const refundedAmount =
    aggregateTransactable?.aggregatedApplications
      .filter(({ targetType }) => targetType === TransactableTargetType.refund)
      .reduce((acc, row) => acc + Number(row.appliedAmount), 0) || 0;
  const totalAmount = aggregateTransactable?.amount ?? sourceOriginalAmount;
  const availableAmount =
    sourceOriginalAmount -
    refundedAmount -
    rows.reduce((acc, row) => acc + Number(row.appliedAmount), 0);
  const appliedAmount = totalAmount - availableAmount - refundedAmount;
  return {
    totalAmount: roundNumberToDecimal(totalAmount),
    availableAmount: roundNumberToDecimal(availableAmount),
    appliedAmount: roundNumberToDecimal(appliedAmount),
    refundedAmount: roundNumberToDecimal(refundedAmount),
  };
};

/**
 * Based on the form and the new applications, calculate the applications to achieve desired outcome.
 */
export const calculateApplications = (
  { rows }: InvoiceApplicationFormData,
  aggregatedApplicationsById: Record<
    string,
    AggregatedApplicationTransactionResponse
  >,
) => {
  const applications = rows
    .reduce((acc: InvoiceApplicationData[], row) => {
      const existingAggregation = aggregatedApplicationsById[row.invoice.id];
      if (!existingAggregation && row.appliedAmount > 0) {
        acc.push({
          amount: row.appliedAmount,
          invoiceId: row.invoice.id,
          type: AppTrxnType.APPLICATION,
        });
      } else if (
        existingAggregation &&
        row.appliedAmount !== existingAggregation.amountAppliedByTransactable
      ) {
        const amount =
          row.appliedAmount - existingAggregation.amountAppliedByTransactable;
        acc.push({
          amount: Math.abs(amount),
          invoiceId: row.invoice.id,
          type:
            amount > 0 ? AppTrxnType.APPLICATION : AppTrxnType.UNAPPLICATION,
        });
      }
      return acc;
    }, [])
    .map((item) => ({
      ...item,
      amount: roundNumberToDecimal(item.amount),
    }));
  return applications;
};
