import {
  Alert,
  AlertDescription,
  AlertDescriptionProps,
  AlertIcon,
  AlertIconProps,
  AlertProps,
  AlertTitle,
  CloseButton,
  HTMLChakraProps,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { MdCheck, MdInfo } from 'react-icons/md';
import { MBox, MFlex } from '~components/Monetize/chakra';

export type AlertType = 'success' | 'error' | 'warning' | 'info';

const getValues = (type: AlertType = 'info') => {
  switch (type) {
    case 'success':
      return {
        bgColor: 'tGreen.light',
        iconColor: 'tGreen.mDarkShade',
        icon: MdCheck,
      };
    case 'error':
      return {
        bgColor: 'tRed.cosmos',
        iconColor: 'tRed.base',
        icon: MdInfo,
      };
    case 'warning':
      return {
        bgColor: 'tCreamsicle.base',
        iconColor: 'tOrange.tangerine',
        icon: MdInfo,
      };
    case 'info':
    default:
      return {
        bgColor: 'tBlue.hover',
        iconColor: 'tIndigo.base',
        icon: MdInfo,
      };
  }
};

interface MAlertProps extends HTMLChakraProps<'div'> {
  type?: AlertType;
  title?: string;
  dismissable?: boolean;
  alertProps?: AlertProps;
  alertIconProps?: AlertIconProps;
  alertDescriptionProps?: AlertDescriptionProps;
  onDismiss?: () => void;
  children: React.ReactNode;
}

export const MAlert = ({
  type = 'info',
  title,
  dismissable = false,
  alertProps,
  alertIconProps,
  alertDescriptionProps,
  onDismiss,
  children,
  ...rest
}: MAlertProps) => {
  const [dismissed, setDismissed] = useState(false);
  const { bgColor, iconColor } = getValues(type);

  if (dismissed) {
    return null;
  }

  return (
    <MFlex alignItems="flex-end" {...rest}>
      <Alert status={type} bgColor={bgColor} borderRadius={3} {...alertProps}>
        <MFlex width={'100%'}>
          <MBox>
            <AlertIcon color={iconColor} {...alertIconProps} />
          </MBox>
          <MBox width={'100%'}>
            {title && (
              <AlertTitle fontSize={14} fontWeight={600}>
                {title}
              </AlertTitle>
            )}
            <AlertDescription
              fontSize={title ? 14 : 12}
              color="#333333"
              {...alertDescriptionProps}
            >
              {children}
            </AlertDescription>
          </MBox>
          {dismissable && (
            <CloseButton
              h="6"
              w="6"
              onClick={() => {
                setDismissed(false);
                onDismiss?.();
              }}
            />
          )}
        </MFlex>
      </Alert>
    </MFlex>
  );
};

export default MAlert;
