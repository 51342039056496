import { Control, Controller, FormState } from 'react-hook-form';
import { MCustomMultiSelect, MFormField } from '~app/components/Monetize';
import { IBulkQuoteOfferingReqSchema, IOfferingRes } from '~app/types';

interface BulkOfferingSelectionProps {
  errors: FormState<IBulkQuoteOfferingReqSchema>['errors'];
  control: Control<IBulkQuoteOfferingReqSchema>;
  items: IOfferingRes[];
  disabled?: boolean;
}

export const BulkOfferingSelection = ({
  errors,
  control,
  items,
  disabled,
}: BulkOfferingSelectionProps) => {
  return (
    <MFormField error={errors.offeringIds} isRequired isDisabled={disabled}>
      <Controller
        control={control}
        name={'offeringIds'}
        render={({ field: { onChange, value, ...rest } }) => {
          return (
            <MCustomMultiSelect
              isDisabled={disabled}
              value={value}
              onChange={(val) => {
                onChange(val);
              }}
              onAdd={(val, allItems) => {
                onChange(allItems);
              }}
              onRemove={(val, allItems) => {
                onChange(allItems);
              }}
              items={items}
              itemTitle="name"
              itemValue="id"
              multiple
              checkboxDisplay
              showAllSelect
              returnItem
              rightLabel
              showQueryInput
            />
          );
        }}
      ></Controller>
    </MFormField>
  );
};
