import { zodResolver } from '@hookform/resolvers/zod';
import isNil from 'lodash/isNil';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import {
  BulkQuoteOfferingReqSchema,
  IBulkQuoteOfferingReqSchema,
  IOfferingRes,
  IQuoteOffering,
  IRateResBaseSchema,
  OfferingTypesEnum,
} from '~app/types';
import { BillingFrequency } from './BillingFrequency';
import { BulkRates } from './BulkRates';
import { BulkOfferingSelection } from './OfferingSelection';
import { StepperComponent } from './StepperComponent';

export const BulkOfferingFlow = ({
  items,
  disabled,
  offeringCreationLoading,
  onBulkOfferingSubmit,
}: {
  items: IOfferingRes[];
  disabled?: boolean;
  offeringCreationLoading: boolean;
  onBulkOfferingSubmit: (data: IBulkQuoteOfferingReqSchema) => void;
}) => {
  const bulkOfferingCreationState = useForm<IBulkQuoteOfferingReqSchema>({
    resolver: zodResolver(BulkQuoteOfferingReqSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      offeringIds: [],
      billingFrequency: '',
      customBillingFrequency: null,
      bulkRate: [],
    },
  });

  const {
    control,
    trigger,
    getValues,
    setValue,
    watch,
    handleSubmit,
    reset,
    setError,
    clearErrors,
    formState: { errors },
  } = bulkOfferingCreationState;

  const watchOfferings = watch('offeringIds');
  const watchBillingFrequency = watch('billingFrequency');
  const watchCustomBillingFreq = watch('customBillingFrequency');
  const bulkRateWatch = watch('bulkRate');

  useEffect(() => {
    const isCustomFreqValid =
      watchCustomBillingFreq && watchCustomBillingFreq > 0;
    if (isCustomFreqValid && watchBillingFrequency) {
      clearErrors('billingFrequency');
    }
  }, [watchCustomBillingFreq, watchBillingFrequency]);

  const handleBulkRates = (billingFreq: string) => {
    if (watchOfferings?.length) {
      const bulkRateValues = watchOfferings.map((offering: IOfferingRes) => {
        const matchingRate = offering.rates.find(
          (rate: IRateResBaseSchema) =>
            String(rate.billingFrequencyInMonths) === billingFreq,
        );

        if (offering.type === OfferingTypesEnum.ONETIME) {
          return {
            offeringId: offering.id,
            rateId: offering.rates[0].id,
          };
        }

        return {
          offeringId: offering.id,
          rateId: matchingRate?.id ?? '', // Safely handle the case where no rate is found
        };
      });

      setValue('bulkRate', bulkRateValues);
    }
  };

  const handleBillingFrequencyNext = (moveNext: Function) => {
    const customBillingFreq = getValues('customBillingFrequency');
    let billingFreq = getValues('billingFrequency');

    const isBillingFrequencyEmpty = billingFreq === '';

    if (isBillingFrequencyEmpty) {
      setError('billingFrequency', {
        type: 'custom',
        message: 'Billing frequency is required',
      });
      return;
    }

    // Move to next step if billingFreq is greater than 1
    if (Number(billingFreq) >= 1) {
      clearErrors('billingFrequency');
      handleBulkRates(billingFreq);
      moveNext();
      return;
    }

    const isCustomBillingFreqMissing =
      isNil(customBillingFreq) && Number(billingFreq) === 0;
    const isCustomBillingFreqInvalid =
      customBillingFreq !== null &&
      customBillingFreq !== undefined &&
      customBillingFreq < 1;

    if (isCustomBillingFreqMissing) {
      setError('billingFrequency', {
        type: 'custom',
        message: 'Custom value is required',
      });
    } else if (isCustomBillingFreqInvalid) {
      setError('billingFrequency', {
        type: 'custom',
        message: 'Custom value cannot be less than 1',
      });
    } else {
      // Move to next step if customBillingFreq is valid
      handleBulkRates(billingFreq);
      moveNext();
    }
  };

  const handleRatesNextAndSubmit = (_: Function, resetStepper?: Function) => {
    trigger('bulkRate').then((res) => {
      if (res) {
        handleSubmit(onBulkOfferingSubmit)();
        if (!offeringCreationLoading) {
          resetStepper?.();
          reset();
        }
      }
    });
  };

  const isOfferingCreateable = useMemo(() => {
    const data = getValues();

    const offeringIdsValid =
      Array.isArray(data.offeringIds) && data.offeringIds.length > 0;

    const bulkRatesValid =
      Array.isArray(data.bulkRate) &&
      data.bulkRate.every((rate) => rate.rateId && rate.rateId.trim() !== '');

    return !(offeringIdsValid && bulkRatesValid); // reverse this as we are controlling the disable state
  }, [JSON.stringify(bulkRateWatch)]);

  const checkIfAllOfferingIsOnetime = (listOfOfferings: IQuoteOffering[]) => {
    return listOfOfferings.every(
      (offering: IQuoteOffering) => offering.type === OfferingTypesEnum.ONETIME,
    );
  };

  const handleSkipBillingFrequency = () => {
    setValue('billingFrequency', '');
    clearErrors('billingFrequency');
    handleBulkRates('');
  };

  const handleOnetimeOfferingNext = (moveNext: Function) => {
    handleSkipBillingFrequency();
    // to move to the last index which for now is 2
    moveNext(1);
  };

  const handleBulkRatesBack = (movePrev: Function) => {
    if (checkIfAllOfferingIsOnetime(watchOfferings)) {
      // to move back to the first index
      movePrev(-2);
      return;
    }

    movePrev();
  };

  return (
    <StepperComponent
      content={[
        {
          label: 'Select Offerings',
          item: (
            <BulkOfferingSelection
              disabled={disabled}
              control={control}
              errors={errors}
              items={items}
            />
          ),
          footer: {
            next: {
              label: 'Next',
              onClick: (moveNext: Function) => {
                trigger('offeringIds').then((res) => {
                  if (res) {
                    if (checkIfAllOfferingIsOnetime(watchOfferings)) {
                      handleOnetimeOfferingNext(moveNext);
                    }
                    moveNext();
                  }
                });
              },
              disable: watchOfferings.length < 1,
            },
          },
        },
        {
          label: 'Select Billing Frequency for all Offerings:',
          item: (
            <BillingFrequency
              disabled={disabled}
              control={control}
              errors={errors}
              setValue={setValue}
              watch={watch}
            />
          ),
          footer: {
            prev: {
              label: 'Back',
              onClick: (movePrev: Function) => {
                movePrev();
              },
              disable: false,
            },
            next: {
              label: 'Next',
              onClick: handleBillingFrequencyNext,
              disable: false,
            },
            skip: {
              label: 'Skip',
              onClick: (moveNext: Function) => {
                handleSkipBillingFrequency();
                moveNext();
              },
            },
          },
        },
        {
          label: 'Select Pricing for Offerings:',
          item: (
            <BulkRates watch={watch} control={control} disabled={disabled} />
          ),
          footer: {
            prev: {
              label: 'Back',
              onClick: handleBulkRatesBack,
              disable: false,
            },
            next: {
              label: 'Create',
              loading: offeringCreationLoading,
              onClick: handleRatesNextAndSubmit,
              disable: isOfferingCreateable, // need to work on this
            },
          },
        },
      ]}
      defaultIndex={0}
    />
  );
};
