import { Switch, Tooltip } from '@chakra-ui/react';
import React from 'react';
import { Control, Controller, FormState } from 'react-hook-form';
import {
  MCustomSelect,
  MFlex,
  MFormField,
  MFormFieldProps,
} from '~app/components/Monetize';
import { IInvoiceTemplateConfigRes } from '~app/types/InvoiceSettingsTypes';

interface IInvoiceTemplateFormProps {
  control: Control<IInvoiceTemplateConfigRes>;
  errors: FormState<IInvoiceTemplateConfigRes>['errors'];
  isDisabled?: boolean;
  handleSubmit: React.FormEventHandler<HTMLFormElement>;
}
export const InvoiceTemplateForm = ({
  control,
  errors,
  isDisabled,
  handleSubmit,
}: IInvoiceTemplateFormProps) => {
  const baseFieldProps: MFormFieldProps = {
    as: MFlex,
    alignItems: 'center',
    justifyContent: 'space-between',
    labelProps: { m: 0, fontWeight: 'normal' },
  };

  const fieldProps: MFormFieldProps = {
    ...baseFieldProps,
    py: 3,
    borderBottom: '1px',
    borderBottomColor: 'tGray.back',
  };

  return (
    <>
      <form
        id="customize-invoiceTemplate-form"
        data-testid="customize-invoiceTemplate-form"
        style={{ marginTop: '0' }}
        onSubmit={handleSubmit}
      >
        <>
          <MFlex
            flexDir="column"
            justifyContent="space-between"
            alignItems="center"
            w="100%"
          >
            <MFormField
              label="Show usage products without any usage events"
              {...fieldProps}
              error={errors?.showUsageWithoutEvents}
            >
              <Controller
                name="showUsageWithoutEvents"
                control={control}
                render={({ field: { value, ...rest } }) => (
                  <Switch isChecked={value} {...rest} isDisabled={isDisabled} />
                )}
              />
            </MFormField>

            <MFormField
              label="Group invoice line items by offering"
              {...fieldProps}
              error={errors?.showOffering}
              tooltip="When enabled, invoice line items will be grouped by their offering just like the quote."
            >
              <Controller
                name="showOffering"
                control={control}
                render={({ field: { value, ...rest } }) => (
                  <Switch isChecked={value} {...rest} isDisabled={isDisabled} />
                )}
              />
            </MFormField>
            {/* <Tooltip
              label={
                requireSigningPage
                  ? 'Signing Sheet will always show because eSign is configured.'
                  : undefined
              }
              size="sm"
            >
              <MFormField {...fieldProps} label="Show Signing Page">
                <Controller
                  name="showSigningPage"
                  control={control}
                  render={({ field: { value, ...rest } }) => (
                    <Switch
                      isChecked={requireSigningPage ? true : value}
                      {...rest}
                      isDisabled={isDisabled || requireSigningPage}
                    />
                  )}
                />
              </MFormField>
            </Tooltip>
             */}
          </MFlex>
        </>
      </form>
    </>
  );
};
