import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  MPageContainer,
  MText,
  MHeading,
  MButton,
  MBox,
} from '~app/components/Monetize';

const NotFound = () => {
  const navigate = useNavigate();

  const goDashboard = () => {
    navigate('/');
  };

  return (
    <MPageContainer>
      <MBox
        display="flex"
        alignItems="center"
        flexDirection="column"
        justifyContent="center"
        flex={1}
      >
        <MHeading fontSize="3xl" mb="3">
          Page Not Found
        </MHeading>
        <MText size="lg">Please contact the system administrator</MText>

        <MButton onClick={goDashboard} variant="primary" my="4">
          Go to dashboard
        </MButton>
      </MBox>
    </MPageContainer>
  );
};

export default NotFound;
