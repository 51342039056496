import {
  InputGroup,
  NumberInput,
  NumberInputField,
  NumberInputFieldProps,
  NumberInputProps,
} from '@chakra-ui/react';
import isNil from 'lodash/isNil';
import toString from 'lodash/toString';
import React, { FunctionComponent as FC, useEffect, useState } from 'react';
import {
  decimalRegex,
  decimalWithNegativeRegex,
  intNumRegex,
  intNumWithNegativeRegex,
} from '~app/constants/common';

const integerPattern = /[0-9]*(.[0-9]+)?/;

export interface MCustomNumberInputProps extends NumberInputProps {
  styleProps?: NumberInputFieldProps;
  handleChange?: (val: string) => true | false;
  LeftElement?: null | React.ReactElement;
  ref?: any;
  inputMode?: React.InputHTMLAttributes<any>['inputMode'];
  precision?: number;
}

export const MCustomNumberInput: FC<MCustomNumberInputProps> = React.forwardRef<
  any,
  MCustomNumberInputProps
>(
  (
    {
      // non-standard props
      styleProps = {},
      handleChange = () => true,
      // standard number props
      onChange,
      onBlur,
      inputMode,
      placeholder,
      value,
      min = 0,
      max = Infinity,
      LeftElement = null,
      precision,
      ...rest
    }: MCustomNumberInputProps,
    reference: any,
  ) => {
    const [internalValue, setInternalValue] = useState<string>(toString(value));
    const isDecimal = () => inputMode === 'decimal';

    useEffect(() => {
      if (toString(value) !== internalValue && internalValue !== '-') {
        setInternalValue(toString(value));
      }
    }, [value, internalValue]);

    const handleInternalChange = (val: string) => {
      val = val.trim();
      // this is for external onChange overwrites. handleChange returns true/false
      if (handleChange && handleChange(val)) {
        let regex = isDecimal() ? decimalRegex : intNumRegex;
        if (isNil(min) || min < 0) {
          regex = isDecimal()
            ? decimalWithNegativeRegex
            : intNumWithNegativeRegex;
          // Allow user to enter negative sign, but don't emit to parent yet since it is not a valid number at this point
          if (val === '-') {
            setInternalValue(val);
            return;
          }
        }
        // test new input value against regex. use old value if it does not pass.
        let newStringValue = regex.test(String(val))
          ? String(val)
          : internalValue;
        // allow user to clear input
        if (val === '') {
          newStringValue = '';
        } else if (val === '.' && isDecimal()) {
          newStringValue = '0.';
        }
        // keep internal as a string. NumberInput handles decimals better when casting to a string.
        setInternalValue(newStringValue);
        // form validation is expecting an integer
        onChange && onChange(newStringValue, Number(newStringValue));
      }
    };
    return (
      <NumberInput
        ref={reference}
        precision={precision}
        onChange={handleInternalChange}
        onBlur={onBlur}
        value={internalValue}
        min={min}
        max={max}
        {...rest}
      >
        <InputGroup>
          {LeftElement}
          <NumberInputField
            data-testid="custom-number-input"
            {...styleProps}
            pattern="(-)?[0-9]+(.[0-9]+)?"
            // overwrite default NumberInputField padding
            paddingInlineEnd={2}
            paddingInlineStart={2}
            placeholder={placeholder}
          />
        </InputGroup>
      </NumberInput>
    );
  },
);
