import React, { FunctionComponent as FC } from 'react';
import { MFlex } from '../../Monetize';
import { IApprovalRespSchema, IQuoteRespSchema } from '~app/types';
import { QuoteStepEdit } from './QuoteStepEdit';
import { QuoteStepApproval } from './QuoteStepApproval';
import { QuoteStepCustomer } from './QuoteStepCustomer';
import { QuoteStepSign } from './QuoteStepSign';

interface QuoteProgressBarProps {
  quote: IQuoteRespSchema;
  approvals: IApprovalRespSchema[];
  reloadData: () => void;
}

export const QuoteProgressBar: FC<QuoteProgressBarProps> = React.forwardRef<
  any,
  QuoteProgressBarProps
>((props: QuoteProgressBarProps, ref) => {
  return (
    <MFlex w="100%" flexWrap="wrap" pl="7">
      <QuoteStepEdit {...props} />
      <QuoteStepApproval {...props} />
      <QuoteStepCustomer {...props} />
      <QuoteStepSign {...props} />
    </MFlex>
  );
});
