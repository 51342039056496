const QuoteTemplateIcon = (props: any) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.80247 13.5742C9.11671 13.5742 8.53366 13.3582 8.05333 12.9262C7.57333 12.4939 7.33333 11.9692 7.33333 11.352C7.33333 10.7348 7.57333 10.2101 8.05333 9.77778C8.53366 9.34578 9.11671 9.12978 9.80247 9.12978H13.7531C14.4388 9.12978 15.0219 9.34578 15.5022 9.77778C15.9822 10.2101 16.2222 10.7348 16.2222 11.352C16.2222 11.9692 15.9822 12.4939 15.5022 12.9262C15.0219 13.3582 14.4388 13.5742 13.7531 13.5742H9.80247ZM9.80247 12.9076H13.7531C14.2331 12.9076 14.6412 12.7563 14.9773 12.4538C15.3134 12.1513 15.4815 11.784 15.4815 11.352C15.4815 10.92 15.3134 10.5527 14.9773 10.2502C14.6412 9.9477 14.2331 9.79644 13.7531 9.79644H9.80247C9.32247 9.79644 8.9144 9.9477 8.57827 10.2502C8.24214 10.5527 8.07407 10.92 8.07407 11.352C8.07407 11.784 8.24214 12.1513 8.57827 12.4538C8.9144 12.7563 9.32247 12.9076 9.80247 12.9076ZM13.7531 12.4631C14.0961 12.4631 14.3877 12.3551 14.6277 12.1391C14.8677 11.9231 14.9877 11.6607 14.9877 11.352C14.9877 11.0433 14.8677 10.7809 14.6277 10.5649C14.3877 10.3489 14.0961 10.2409 13.7531 10.2409C13.41 10.2409 13.1185 10.3489 12.8785 10.5649C12.6385 10.7809 12.5185 11.0433 12.5185 11.352C12.5185 11.6607 12.6385 11.9231 12.8785 12.1391C13.1185 12.3551 13.41 12.4631 13.7531 12.4631Z"
        fill="currentColor"
      />
      <path
        d="M9.80247 19.1111C9.11671 19.1111 8.53366 18.8951 8.05333 18.4631C7.57333 18.0308 7.33333 17.5061 7.33333 16.8889C7.33333 16.2717 7.57333 15.747 8.05333 15.3147C8.53366 14.8827 9.11671 14.6667 9.80247 14.6667H13.7531C14.4388 14.6667 15.0219 14.8827 15.5022 15.3147C15.9822 15.747 16.2222 16.2717 16.2222 16.8889C16.2222 17.5061 15.9822 18.0308 15.5022 18.4631C15.0219 18.8951 14.4388 19.1111 13.7531 19.1111H9.80247ZM9.80247 18.4444H13.7531C14.2331 18.4444 14.6412 18.2932 14.9773 17.9907C15.3134 17.6881 15.4815 17.3209 15.4815 16.8889C15.4815 16.4569 15.3134 16.0896 14.9773 15.7871C14.6412 15.4846 14.2331 15.3333 13.7531 15.3333H9.80247C9.32247 15.3333 8.9144 15.4846 8.57827 15.7871C8.24214 16.0896 8.07407 16.4569 8.07407 16.8889C8.07407 17.3209 8.24214 17.6881 8.57827 17.9907C8.9144 18.2932 9.32247 18.4444 9.80247 18.4444ZM13.7531 18C14.0961 18 14.3877 17.892 14.6277 17.676C14.8677 17.46 14.9877 17.1976 14.9877 16.8889C14.9877 16.5801 14.8677 16.3178 14.6277 16.1018C14.3877 15.8858 14.0961 15.7778 13.7531 15.7778C13.41 15.7778 13.1185 15.8858 12.8785 16.1018C12.6385 16.3178 12.5185 16.5801 12.5185 16.8889C12.5185 17.1976 12.6385 17.46 12.8785 17.676C13.1185 17.892 13.41 18 13.7531 18Z"
        fill="currentColor"
      />
      <path
        d="M5.5 22C5.1 22 4.75 21.85 4.45 21.55C4.15 21.25 4 20.9 4 20.5V3.5C4 3.1 4.15 2.75 4.45 2.45C4.75 2.15 5.1 2 5.5 2H14.525L20 7.475V20.5C20 20.9 19.85 21.25 19.55 21.55C19.25 21.85 18.9 22 18.5 22H5.5ZM13.775 8.15V3.5H5.5V20.5H18.5V8.15H13.775Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default QuoteTemplateIcon;
