import * as z from 'zod';
import { getRequiredMessage } from '~app/utils/messages';
import { ITeamResponseSchema } from './teamTypes';

// "id": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
// "name": "string",
// "email": "string",
// "mobile": "string",
// "active": true,
// "roles": [
//   {
//     "id": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
//     "name": "string",
//     "description": "string"
//   }
// ]

export const UserInviteSchema = z.object({
  // email: z.array(z.string().email().nonempty("Email can't be empty.")),
  email: z.string().refine(
    (v: string) => {
      // converting strings to emails manually instead of using getEmailsFromString()
      // which is causing all jest tests to fail if used inside refine()
      const emails = v
        .trim()
        .replace(/[,\r\n\s]/g, ',')
        .split(',')
        .filter(Boolean);

      const isAllEmailValid = emails.every(
        (email: string) => z.string().email().safeParse(email).success,
      );

      return isAllEmailValid;
    },
    (val) => ({
      message: !val
        ? getRequiredMessage('Email(s)')
        : 'Contains invalid email address',
    }),
  ),
  roleIds: z
    .array(z.object({ id: z.string(), name: z.string() }))
    .min(1, getRequiredMessage('Role')),
});
export type IUserInviteSchema = z.infer<typeof UserInviteSchema>;

export enum UserRoleEnum {
  TENANT_ADMIN = 'TENANT_ADMIN',
  BILLING = 'BILLING',
  SALES = 'SALES',
  SALES_ADMIN = 'SALES_ADMIN',
  SALES_MANAGER = 'SALES_MANAGER',
  SALES_FULL = 'SALES_FULL',
  SALES_READ_ONLY = 'SALES_READ_ONLY',
  BILLING_ADMIN = 'BILLING_ADMIN',
  BILLING_READ_ONLY = 'BILLING_READ_ONLY',
  APPROVER_ONLY = 'APPROVER_ONLY',
  SETTINGS_READ_ONLY = 'SETTINGS_READ_ONLY',
  REPORTING = 'REPORTING',
}

export const UserRoleSchema = z.object({
  id: z.string().nonempty(getRequiredMessage('id')),
  name: z.string().nonempty(getRequiredMessage('name')),
  description: z.string().nullish(),
});

export type IUserRoleSchema = z.infer<typeof UserRoleSchema>;
export interface IUserRole {
  id: string;
  name: UserRoleEnum;
  description: string;
}

export enum UserStatusEnum {
  ACTIVE = 'ACTIVE',
  INVITED = 'INVITED',
  DEACTIVATED = 'DEACTIVATED',
}
export const UserStatusEnumZ = z.nativeEnum(UserStatusEnum);
export interface IUser {
  id: string;
  name: string;
  email: string;
  companyName?: string | null;
  mobile?: string | null;
  active: boolean;
  status: UserStatusEnum;
  roles: IUserRole[];
  teams: ITeamResponseSchema[];
}

export interface IUserInviteLink {
  url: string;
}

export interface IUserPayLoadSchema {
  tenantId: string;
  userId: string;
}

export interface IUserRolePayLoadSchema {
  tenantId: string;
  userId: string;
  roleId: string;
}
export interface IUserRolesModifySchema {
  tenantId: string;
  userId: string;
  idsToAdd: string[];
  idsToRemove: string[];
  /** Used so that cache can be optimistically updated */
  userRoles?: IUserRole[];
}
