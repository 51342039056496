import type { ColumnProps } from 'primereact/column';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  MBox,
  MDataTable,
  MFlex,
  MHStack,
  MPageHeader,
  MText,
} from '~app/components/Monetize';
import { MDataTableFilter } from '~app/components/Monetize/DataTable';
import { ExportTableButton } from '~app/components/Monetize/ExportEntityButton';
import { PaymentMethodTypeEnumDisplay } from '~app/constants/paymentMethods';
import { getAccountPaymentView } from '~app/constants/routes';
import { useAccountPayments } from '~app/hooks/AccountDetail';
import { getFiltersApplied } from '~app/utils';
import { objectToObjArray } from '~app/utils/misc';
import {
  currencyBodyTemplate,
  idBodyTemplate,
  statusBodyTemplate,
  textBodyTemplate,
} from '~app/utils/tableUtils';
import {
  FilterTypeOperator,
  IAccountRespSchema,
  IPayment,
  PAYMENT_STATUS_DISPLAY,
  PaymentMethodTypeEnum,
  PaymentStatusEnum,
} from '~types';
import { logger } from '../../../services/logger';
import { toDateShort } from '../../../utils/dates';
import { PaymentActions } from './PaymentActions';

interface PaymentListProps {
  account: IAccountRespSchema;
  billGroupId?: string;
}

export const PaymentList = ({ account, billGroupId }: PaymentListProps) => {
  const navigate = useNavigate();
  const { id: accountId } = account;

  const {
    filters,
    setFilters,
    onResetFilter,
    totalRecords,
    setPager,
    pager,
    loading,
    listData,
    refetch,
  } = useAccountPayments({ accountId, billGroupId });

  const onSelectRow = (rowData: IPayment) => {
    navigate(getAccountPaymentView(accountId, rowData.id));
  };

  const columns: ColumnProps[] = [
    {
      field: 'id',
      header: 'ID',
      body: idBodyTemplate<IPayment>('id'),
      className: 'table-cell-md',
    },
    {
      field: 'paymentDate',
      header: 'Payment Date',
      sortable: true,
      body: (payment) => {
        try {
          return (
            <MText noOfLines={1}>
              {payment.paymentType === PaymentMethodTypeEnum.MANUAL
                ? toDateShort(payment.paymentDate)
                : toDateShort(payment.createDate)}
            </MText>
          );
        } catch (ex) {
          logger.warn('Could not generate template', {
            payment,
            property: 'paymentDate',
            ex,
          });
          return null;
        }
      },
      style: { whiteSpace: 'nowrap' },
    },
    {
      field: 'description',
      header: 'Description',
      body: textBodyTemplate<IPayment>('description'),
      className: 'table-cell-lg',
    },
    {
      field: 'paymentStatus',
      header: 'Status',
      body: statusBodyTemplate<IPayment, PaymentStatusEnum>(
        'paymentStatus',
        PAYMENT_STATUS_DISPLAY,
      ),
      sortable: true,
    },
    {
      field: 'paymentType',
      header: 'Type',
      body: statusBodyTemplate<IPayment, PaymentMethodTypeEnum>(
        'paymentType',
        PaymentMethodTypeEnumDisplay,
      ),
      sortable: true,
    },
    {
      field: 'amount',
      header: 'Amount',
      sortable: true,
      body: currencyBodyTemplate<IPayment>(
        'amount',
        'currency',
        {},
        account?.defaultCurrency,
      ),
      style: { textAlign: 'right' },
    },
    {
      field: 'unAppliedAmount',
      header: 'Remaining',
      sortable: true,
      body: currencyBodyTemplate<IPayment>(
        'unAppliedAmount',
        'currency',
        {},
        account?.defaultCurrency,
      ),
      style: { textAlign: 'right' },
    },
    {
      field: 'actions',
      header: '',
      style: { width: '4em' },
      body: (payment) => (
        <PaymentActions
          payment={payment}
          accountId={account.id}
          refetch={refetch}
        />
      ),
    },
  ];

  const statusItems = objectToObjArray(
    PAYMENT_STATUS_DISPLAY,
    'value',
    'label',
  );

  const typeItems = objectToObjArray(
    PaymentMethodTypeEnumDisplay,
    'value',
    'label',
  );

  const filterOptions = [
    {
      title: 'Status',
      key: 'paymentStatus',
      operator: FilterTypeOperator.IN,
      items: statusItems,
    },
    {
      title: 'Type',
      key: 'paymentType',
      operator: FilterTypeOperator.IN,
      items: typeItems,
    },
  ];

  const filterComponentReset = React.useRef<any>(null);

  const hasFilerApplied = filters && filters?.length > 0;
  const hasContent = listData?.content?.length > 0;
  const shouldShowHeaderContent = hasContent || hasFilerApplied;

  return (
    <MBox>
      <MPageHeader title="Payments" hideContent={!shouldShowHeaderContent}>
        <MFlex>
          <MHStack spacing="2">
            <MDataTableFilter
              filters={filters}
              filterOptions={filterOptions}
              setFilters={setFilters}
              onResetFilter={onResetFilter}
              resetFilter={filterComponentReset}
            />
            <ExportTableButton
              entity="payments"
              filters={filters}
              sortField={pager.sortField}
              sortOrder={pager.sortOrder}
              endpointParams={[accountId]}
              getFilename={() => `payments-${accountId}.csv`}
            />
          </MHStack>
        </MFlex>
      </MPageHeader>

      <MDataTable
        value={listData?.content}
        totalRecords={totalRecords}
        totalPages={listData?.totalPages}
        pager={pager}
        setPager={setPager}
        rowHover
        className="p-datatable-responsive"
        emptyProps={{
          mainMessage: 'Looks like there are no payments here.',
          smallMessage: 'When a payment is made it will appear here.',
        }}
        filtersApplied={getFiltersApplied(filters) > 0}
        resetFilter={() => {
          filterComponentReset.current && filterComponentReset.current();
        }}
        loading={loading}
        columns={columns}
        onSelectionChange={(e) => onSelectRow(e.value as IPayment)}
      />
    </MBox>
  );
};
