import MFormField, { MFormFieldProps } from './MFormField';
import MText, { MTextProps } from './MText';

interface MFormFieldReadOnlyProps {
  /**
   * The size of the input text.
   * COMPACT = 21px for forms that do not have any normal input fields
   * REGULAR (default) = 24px for forms that have text mixed with input fields
   */
  label: string;
  mode?: 'COMPACT' | 'REGULAR';
  formFieldProps?: MFormFieldProps;
  textProps?: MTextProps;
  children?: React.ReactNode;
}

/**
 * Text component that can be used for readonly input fields.
 *
 * If the field is editable sometimes, use MInput with disabled+readonly
 *
 * If a field is always readonly, use this component which renders as text
 */
export const MFormFieldReadOnly = ({
  label,
  mode = 'REGULAR',
  formFieldProps,
  textProps,
  children,
}: MFormFieldReadOnlyProps) => {
  const minH = mode === 'COMPACT' ? '21px' : '24px';
  const pt = mode === 'REGULAR' ? 3 : undefined;
  return (
    <MFormField label={label} isReadOnly {...formFieldProps}>
      <MText minH={minH} pt={pt} {...textProps}>
        {children}
      </MText>
    </MFormField>
  );
};
