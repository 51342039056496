import { format as formatDate } from 'date-fns/format';
import { parseISO } from 'date-fns/parseISO';
import { useGetSalesMonthly } from '~app/api/dashboardsService';
import { MBox, MChart, MSkeleton, MText } from '~app/components/Monetize';
import { sortDateByAscendingOrder } from '~app/utils';
import { salesChartOptions } from './chartOptions';

interface SalesChartProps {
  currency: string;
}

export const SalesChart = ({ currency }: SalesChartProps) => {
  const { isLoading, data: sales } = useGetSalesMonthly(
    { query: { currency } },
    {
      select: (data) => ({
        series: [
          {
            name: 'sales-series',
            data: sortDateByAscendingOrder(data.monthlySales, 'yearMonth')?.map(
              ({ yearMonth, amount }: any) => ({
                x: formatDate(parseISO(yearMonth), 'MMM'),
                y: amount,
              }),
            ),
          },
        ],
      }),
    },
  );

  const chartOptions = salesChartOptions(currency);

  return (
    <MBox>
      <MText fontSize="lg" fontWeight="bold">
        Sales
      </MText>
      {isLoading ? (
        <MSkeleton height={300} />
      ) : (
        <MChart
          key={currency}
          options={chartOptions}
          series={sales?.series || []}
          height={300}
        />
      )}
    </MBox>
  );
};
