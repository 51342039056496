import React, { FunctionComponent as FC } from 'react';
import {
  IApprovalRespSchema,
  IQuoteRespSchema,
  QuoteCancelationReasonEnum,
  QuoteStatusEnum,
} from '~app/types';
import { QuoteProgressStep } from './QuoteProgressStep';
import { toDateShort } from '~app/utils/dates';
import { QUOTE_ACCEPTED_STATUSES } from '~app/constants/quotes';

interface QuoteStepSignProps {
  quote: IQuoteRespSchema;
  approvals: IApprovalRespSchema[];
  reloadData: () => void;
}

const CANCEL_MESSAGE = {
  [QuoteCancelationReasonEnum.BY_USER]: 'Cancelled by User',
  [QuoteCancelationReasonEnum.CUSTOMER_DECLINED]: 'Cancelled by Customer',
  [QuoteCancelationReasonEnum.DUPLICATE]: 'Cancelled due to duplication',
  [QuoteCancelationReasonEnum.EXPIRED]: 'Expired due to Inactivity',
};

export const QuoteStepSign: FC<QuoteStepSignProps> = React.forwardRef<
  any,
  QuoteStepSignProps
>(({ quote }: QuoteStepSignProps, ref) => {
  let stepTitle = 'Sign Deal with Customer';
  let stepDate = '';
  const isCompleted =
    QUOTE_ACCEPTED_STATUSES.has(quote.status) && !!quote.acceptedAt;
  const showInprogressStepNumber = false;
  const isCurrent = quote.status === QuoteStatusEnum.SENT;
  const isDisabled = !isCurrent && !QUOTE_ACCEPTED_STATUSES.has(quote.status);
  const isCanceled = quote.status === QuoteStatusEnum.CANCELED;
  const isExpired = quote.status === QuoteStatusEnum.EXPIRED;

  if (isCompleted) {
    stepDate = toDateShort(quote.acceptedAt);
    if (quote.autoRenewContract) {
      stepTitle = 'Automatically Renewed';
    } else if (!quote.requiresEsign) {
      stepTitle = 'Automatically Processed';
    } else {
      stepTitle = 'Signed by Customer';
    }
  }
  if (isCanceled) {
    stepDate = toDateShort(quote.modifyDate);
    stepTitle = quote.cancellationReason
      ? CANCEL_MESSAGE[quote.cancellationReason]
      : 'Cancelled';
  }
  if (isExpired) {
    stepDate = toDateShort(quote.expirationDate);
    stepTitle = 'Expired due to Inactivity';
  }

  return (
    <QuoteProgressStep
      key="quote-progress-sign"
      stepTitle={stepTitle}
      stepDate={stepDate}
      stepNumber={4}
      isCompleted={isCompleted}
      showInprogressStepNumber={showInprogressStepNumber}
      isDisabled={isDisabled}
      isCurrent={isCurrent}
      containerProps={{ ml: -8 }}
      isError={isCanceled || isExpired}
      showLightBackground={
        (isCanceled &&
          quote.cancellationReason === QuoteCancelationReasonEnum.EXPIRED) ||
        isExpired
      }
    />
  );
});
