import React, { FunctionComponent as FC, useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import {
  MBox,
  MButton,
  MCenterModal,
  MStack,
  MGrid,
  MGridItem,
  MTextarea,
  MFormField,
} from '~components/Monetize';
import {
  IApproval,
  IQuoteRespSchema,
  RejectSchema,
  IRejectSchema,
} from '~app/types';
import { approvalsQueryKeys, doQuoteReject } from '~app/api/approvalService';
import { handleApiErrorToast } from '~app/api/axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';

interface ApprovalRejectModalProps {
  isOpen: boolean;
  quote: IQuoteRespSchema;
  approval: IApproval;
  onClose: () => void;
  // this prop is being made optional for the interim in order to support consumers
  // of this component's only parent, ApprovalSteps, that are not yet on React Query, it will eventually be removed
  reloadData?: () => void;
}

const formDefault: IRejectSchema = {
  declineReason: '',
};

export const ApprovalRejectModal: FC<ApprovalRejectModalProps> = (
  props: ApprovalRejectModalProps,
) => {
  const { isOpen, onClose, reloadData, quote, approval } = props;
  const [saveLoading, setSaveLoading] = useState(false);
  const queryClient = useQueryClient();

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty, isValid },
    reset,
  } = useForm<IRejectSchema>({
    resolver: zodResolver(RejectSchema),
    mode: 'onChange',
    defaultValues: formDefault,
  });

  useEffect(() => {
    reset(formDefault);
  }, [reset, isOpen]);

  const { mutate: rejectQuote, isLoading: isSaveLoading } = useMutation(
    ({ approvalId, data }: { approvalId: string; data: IRejectSchema }) =>
      doQuoteReject(approvalId, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([...approvalsQueryKeys.dashboard()]);
        onClose && onClose();
      },
      onError: (error) => {
        handleApiErrorToast(error);
      },
    },
  );

  useEffect(() => {
    setSaveLoading(isSaveLoading);
  }, [isSaveLoading]);

  const onSubmit = async (data: IRejectSchema) => {
    if (reloadData) {
      // use of reloadData to be removed once all consumers of this component are on React Query
      setSaveLoading(true);
      try {
        await doQuoteReject(approval.id, data);
        onClose();
      } catch (err) {
        handleApiErrorToast(err);
      }
      setSaveLoading(false);
      reloadData && reloadData();
    } else {
      // parent component is using React Query
      rejectQuote({ approvalId: approval.id, data });
    }
  };

  return (
    <MCenterModal
      isOpen={isOpen}
      onClose={onClose}
      modalTitle={`Are you sure you want to reject this Quote??`}
      modalHeaderProps={{
        mr: 10,
      }}
      size="lg"
      renderFooter={() => (
        <MStack
          spacing={4}
          direction="row"
          align="center"
          justify="end"
          flex={1}
        >
          <MButton onClick={onClose} variant="cancel" minW="auto">
            Cancel
          </MButton>
          <MButton
            variant="delete"
            isLoading={saveLoading}
            onClick={handleSubmit(onSubmit)}
            isDisabled={!isDirty || !isValid}
            type="submit"
            minW="auto"
          >
            Reject
          </MButton>
        </MStack>
      )}
    >
      <MBox>
        <form onSubmit={handleSubmit(onSubmit)}>
          <MGrid templateColumns="repeat(12, 1fr)" gap={1}>
            <MGridItem colSpan={12}>
              <MFormField
                error={errors.declineReason}
                label="Reason"
                isRequired
              >
                <Controller
                  name="declineReason"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <MTextarea
                      placeholder="Provide a reason for rejecting this Quote"
                      {...field}
                    />
                  )}
                />
              </MFormField>
            </MGridItem>
          </MGrid>
        </form>
      </MBox>
    </MCenterModal>
  );
};

ApprovalRejectModal.defaultProps = {};
