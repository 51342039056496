import { useState } from 'react';
import { FiExternalLink } from 'react-icons/fi';
import { MdCheck, MdClose } from 'react-icons/md';
import { handleApiErrorToast } from '~app/api/axios';
import {
  doEvaluateQuoteRules,
  useLinkOpportunityToCrm,
} from '~app/api/cpqService';
import { OpportunitySearchInput } from '~app/routes/Quotes/Quote/components/NewQuoteForm/OpportunitySearchInput';
import {
  CrmOpportunityForInput,
  CrmType,
  IOpportunityWithQuotes,
  IOpportunityQuote,
} from '~app/types';
import {
  MButton,
  MCustomIconButton,
  MFlex,
  MIcon,
  MLink,
  MText,
} from '~components/Monetize';
import { SalesforceIcon } from '../../assets/icons/SalesforceIcon';

interface OpportunityModalProps {
  crmType: CrmType;
  opportunity: IOpportunityWithQuotes;
  isDisabled?: boolean;
  quotes: IOpportunityQuote[];
}

export const OpportunityLinkCrmOpportunityInput = ({
  crmType,
  opportunity,
  isDisabled,
  quotes,
}: OpportunityModalProps) => {
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [crmOpportunity, setCrmOpportunity] =
    useState<CrmOpportunityForInput | null>(null);
  const { mutateAsync: doLinkOpportunityToCrm, isLoading } =
    useLinkOpportunityToCrm();

  const handleSubmit = async () => {
    try {
      if (crmOpportunity?.id) {
        await doLinkOpportunityToCrm({
          opportunityId: opportunity.id,
          customId: crmOpportunity.id,
        });

        await Promise.all(
          quotes.map((quote) => doEvaluateQuoteRules(quote.id)),
        );
      }
      setIsSearchActive(false);
      setCrmOpportunity(null);
    } catch (err) {
      handleApiErrorToast(err);
    }
  };

  function handleCancel() {
    setIsSearchActive(false);
    setCrmOpportunity(null);
  }

  return (
    <>
      {!isDisabled && !opportunity.customId && !isSearchActive && (
        <>
          <MButton
            variant="tertiary"
            ml={-3}
            onClick={() => setIsSearchActive(true)}
          >
            Link to Salesforce
          </MButton>
        </>
      )}
      {!isDisabled && isSearchActive && (
        <MFlex>
          <OpportunitySearchInput
            label=""
            accountId={opportunity.accountId}
            crmOpportunityId={crmOpportunity?.id}
            onChange={setCrmOpportunity}
          />
          <MCustomIconButton
            variant="icon"
            btnSize={4}
            mt={2}
            ml={2}
            iconColor="tPurple.base"
            icon={MdClose}
            onClick={handleCancel}
            isDisabled={isLoading}
          />
          <MCustomIconButton
            variant="icon"
            btnSize={4}
            mt={2}
            ml={2}
            iconColor="tIndigo.base"
            icon={MdCheck}
            isLoading={isLoading}
            isDisabled={!crmOpportunity?.id || isLoading}
            onClick={handleSubmit}
          />
        </MFlex>
      )}
      {opportunity.customId && (
        <MFlex columnGap={1} alignItems="center">
          {crmType === 'salesforce' && <SalesforceIcon />}
          {/* FIXME: The SFDC opportunity might have a different name - we don't store that data */}
          {opportunity.url ? (
            <MLink
              href={opportunity.url}
              isExternal
              fontWeight="bold"
              color="tIndigo.base"
              noOfLines={1}
            >
              {opportunity.name}
              <MIcon as={FiExternalLink} ml="1" color="tIndigo.base" />
            </MLink>
          ) : (
            <MText noOfLines={1}>{opportunity?.name}</MText>
          )}
        </MFlex>
      )}
    </>
  );
};
