// https://github.com/chakra-ui/chakra-ui/blob/main/packages/theme/src/components/switch.ts
// Escape SVG for use in CSS
// https://yoksel.github.io/url-encoder/
import { switchAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';
import { calc, cssVar } from '@chakra-ui/theme-tools';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(switchAnatomy.keys);

const $width = cssVar('switch-track-width');
const $height = cssVar('switch-track-height');

const $diff = cssVar('switch-track-diff');
const diffValue = calc.subtract($width, $height);

const $translateX = cssVar('switch-thumb-x');

const baseStyle = definePartsStyle({
  container: defineStyle({
    [$diff.variable]: diffValue,
    [$translateX.variable]: $diff.reference,
    _rtl: {
      [$translateX.variable]: calc($diff).negate().toString(),
    },
  }),
  track: defineStyle({
    borderRadius: 'full',
    borderColor: 'tGray.lightPurple',
    borderWidth: '1px',
    p: '1px',
    width: [$width.reference],
    height: [$height.reference],
    transitionProperty: 'common',
    transitionDuration: 'fast',
    bg: 'tGray.lightPurple',
    _focusVisible: {
      boxShadow: 'outline',
    },
    _disabled: {
      opacity: 0.4,
      cursor: 'not-allowed',
    },
    _checked: {
      bg: 'white',
      borderColor: 'tIndigo.base',
      _before: {
        content: `url("data:image/svg+xml,%3Csvg stroke='currentColor' fill='%234659DA' stroke-width='0' viewBox='0 0 24 24' height='1em' width='1em' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='none' d='M0 0h24v24H0z'%3E%3C/path%3E%3Cpath d='M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z'%3E%3C/path%3E%3C/svg%3E")`,
        position: 'absolute',
        top: '0.25rem',
        left: '0.3rem',
      },
      _after: { content: `""` },
    },
    _after: {
      content: `url("data:image/svg+xml,%3Csvg stroke='currentColor' fill='%233D3C65' stroke-width='0' viewBox='0 0 24 24' height='1em' width='1em' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='none' d='M0 0h24v24H0z'%3E%3C/path%3E%3Cpath d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'%3E%3C/path%3E%3C/svg%3E")`,
      position: 'absolute',
      top: '0.25rem',
      right: '0.4rem',
    },
  }),
  thumb: defineStyle({
    bg: 'white',
    transitionProperty: 'transform',
    transitionDuration: 'normal',
    borderRadius: 'inherit',
    width: [$height.reference],
    height: [$height.reference],
    _checked: {
      transform: `translateX(${$translateX.reference})`,
      bg: 'tIndigo.base',
    },
  }),
});

const sizes = {
  sm: definePartsStyle({
    container: defineStyle({
      [$width.variable]: '1.375rem',
      [$height.variable]: '0.75rem',
    }),
    track: defineStyle({
      _checked: {
        _before: { content: `""` },
        _after: { content: `""` },
      },
      _after: { content: `""` },
    }),
  }),
  md: definePartsStyle({
    container: defineStyle({
      [$width.variable]: '2.075rem',
      [$height.variable]: '1rem',
    }),
    track: defineStyle({
      _checked: {
        _before: {
          content: `url("data:image/svg+xml,%3Csvg stroke='currentColor' fill='%234659DA' stroke-width='0' viewBox='0 0 28 28' height='1em' width='1em' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='none' d='M0 0h24v24H0z'%3E%3C/path%3E%3Cpath d='M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z'%3E%3C/path%3E%3C/svg%3E")`,
          position: 'absolute',
          top: '0.25rem',
          left: '0.3rem',
        },
        _after: { content: `""` },
      },
      _after: {
        content: `url("data:image/svg+xml,%3Csvg stroke='currentColor' fill='%233D3C65' stroke-width='0' viewBox='0 0 28 28' height='1em' width='1em' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='none' d='M0 0h24v24H0z'%3E%3C/path%3E%3Cpath d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'%3E%3C/path%3E%3C/svg%3E")`,
        position: 'absolute',
        top: '0.25rem',
        right: '0.1rem',
      },
    }),
  }),
  lg: definePartsStyle({
    container: defineStyle({
      [$width.variable]: '2.75rem',
      [$height.variable]: '1.25rem',
    }),
  }),
};

const variantDualLabel = definePartsStyle({
  ...baseStyle,
  track: {
    ...baseStyle.track,
    borderColor: 'tIndigo.base',
    bg: 'tIndigo.base',
    _checked: {
      bg: 'tIndigo.base',
      borderColor: 'tIndigo.base',
    },
    _after: null,
  },
  thumb: {
    ...baseStyle.thumb,
    bg: 'white',
    _checked: {
      transform: `translateX(${$translateX.reference})`,
      bg: 'white',
    },
  },
});

const variantUnsaved = definePartsStyle({
  track: {
    ...baseStyle.track,
    borderColor: 'tOrange.base',
    _checked: {
      borderColor: 'tOrange.base',
    },
  },
});

export const switchTheme = defineMultiStyleConfig({
  variants: {
    dualLabel: variantDualLabel,
    unsaved: variantUnsaved,
  },
  baseStyle,
  sizes,
  defaultProps: {
    size: 'lg',
    colorScheme: 'tIndigo',
  },
});

export default switchTheme;
