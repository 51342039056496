import { FC, PropsWithChildren } from 'react';
import { MBox, MText, MTooltip } from '~app/components/Monetize';
import { QUOTES } from '~app/constants';
import { RateBillingFrequencyEnum } from '~app/types';

interface RateSymbolProps {
  billingFrequency: RateBillingFrequencyEnum;
  disabled?: boolean;
}

const RateSymbol: FC<RateSymbolProps> = ({
  billingFrequency,
  disabled,
}: PropsWithChildren<RateSymbolProps>) => {
  const toolTipLabel =
    QUOTES.RATE_BILLING_FREQUENCY_DISPLAY[billingFrequency].toLowerCase();

  return (
    <MTooltip label={`Billed ${toolTipLabel}`} placement="bottom-start">
      <MBox
        borderWidth="1px"
        p="2px"
        rounded="full"
        borderStyle="dashed"
        borderColor="tGray.lightPurple"
        display="flex"
        alignItems="center"
        justifyContent="center"
        w="5"
        h="5"
        cursor="pointer"
      >
        <MText
          fontSize="xs"
          fontWeight="bold"
          color={disabled ? 'tGray.darkPurple' : 'tPurple.base'}
          mt="1px"
          textTransform="uppercase"
        >
          {billingFrequency.slice(0, 1)}
        </MText>
      </MBox>
    </MTooltip>
  );
};

export default RateSymbol;
