import { useQuery } from '@tanstack/react-query';
import { useSetRecoilState } from 'recoil';
import { apiGetAllList } from '~app/api/axios';
import { useGetListData } from '~app/api/queryUtils';
import {
  settingsQueryKeys,
  useGetCrmConfiguration,
  useGetCurrencySettings,
} from '~app/api/settingsService';
import Sentry from '~app/services/sentry';
import { appGlobalDataState } from '~app/store/global.store';
import {
  CustomFieldEntityEnum,
  DEFAULT_PAGER,
  ICustomFieldResSchema,
} from '~app/types';
import { ILegalEntityResponseSchema } from '~app/types/legalEntityTypes';

/**
 * used to prefetch some data that solve the screen jump issue and
 * the queryKey of prefetches need to be exactly same of the respective components useQuery for using RQ cache
 *
 * Loading of this page blocks the entire loading of the application, so be mindful of what things happen here.
 * Anything added here should not retry failed queries and should also not show the user any error messages
 * because the user did not take action so we would never need to notify the user that something they were not aware of went wrong.
 *
 *
 * TICKET: https://monetizenow.atlassian.net/browse/BP-8260
 */
export const useConfigDataFetcher = ({ enabled }: { enabled: boolean }) => {
  const options = {
    enabled,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    retry: false,
    staleTime: 1000 * 60 * 60, // 1 hour,
  };

  const setGlobalState = useSetRecoilState(appGlobalDataState);

  const { isInitialLoading: isCurrencyLoading } = useGetCurrencySettings(
    {},
    {
      onSuccess: (data) => {
        setGlobalState((currVal) => ({
          ...currVal,
          hasMultipleCurrency: data.totalElements > 1,
        }));
      },
      onError: (err) => {
        Sentry.captureException(err, {
          tags: {
            type: 'CURRENCY_CONFIG_FETCHER',
          },
        });
      },
      ...options,
    },
  );

  const { isInitialLoading: isLegalEntityLoading } =
    useGetListData<ILegalEntityResponseSchema>(
      'legalEntities',
      { config: DEFAULT_PAGER },
      {
        onSuccess: (data) => {
          setGlobalState((currVal) => ({
            ...currVal,
            hasMultipleLegalEntity: data.totalElements > 1,
          }));
        },
        onError: (err) => {
          Sentry.captureException(err, {
            tags: {
              type: 'LEGAL_ENTITY_CONFIG_FETCHER',
            },
          });
        },
        ...options,
      },
    );

  const { isInitialLoading: isCrmConfigurationLoading } =
    useGetCrmConfiguration({
      onSuccess: (data) => {
        setGlobalState((currVal) => ({
          ...currVal,
          hasCrmConfigured: !!data.salesforce || !!data.hubspot,
        }));
      },
      onError: (err) => {
        Sentry.captureException(err, {
          tags: {
            type: 'CRM_CONFIGURATION_CONFIG_FETCHER',
          },
        });
      },
      ...options,
    });

  const { isInitialLoading: isAccountCustomFieldLoading } = useQuery({
    queryKey: [
      ...settingsQueryKeys.customFieldsListByEntity(
        CustomFieldEntityEnum.ACCOUNT,
      ),
    ],
    queryFn: async () => {
      try {
        const response = await apiGetAllList<ICustomFieldResSchema>(
          '/api/configurations/customFields',
          {
            filters: { entity: CustomFieldEntityEnum.ACCOUNT },
          },
        );
        return response;
      } catch (err) {
        Sentry.captureException(err, {
          tags: {
            type: 'ACCOUNT_CUSTOM_FIELD_CONFIG_FETCHER',
          },
        });
        return [];
      }
    },
    ...options,
  });

  const { isInitialLoading: isQuoteCustomFieldLoading } = useQuery({
    queryKey: [
      ...settingsQueryKeys.customFieldsListByEntity(
        CustomFieldEntityEnum.QUOTE,
      ),
    ],
    queryFn: async () => {
      try {
        const response = await apiGetAllList<ICustomFieldResSchema>(
          '/api/configurations/customFields',
          {
            filters: { entity: CustomFieldEntityEnum.QUOTE },
          },
        );
        return response;
      } catch (err) {
        Sentry.captureException(err, {
          tags: {
            type: 'QUOTE_CUSTOM_FIELD_CONFIG_FETCHER',
          },
        });
        return [];
      }
    },
    ...options,
  });

  return {
    isConfigLoading:
      isCurrencyLoading ||
      isLegalEntityLoading ||
      isCrmConfigurationLoading ||
      isAccountCustomFieldLoading ||
      isQuoteCustomFieldLoading,
  };
};
