import { useDisclosure } from '@chakra-ui/react';
import { isBefore } from 'date-fns/isBefore';
import { parseISO } from 'date-fns/parseISO';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { handleApiErrorToast } from '~app/api/axios';
import { useExtendExpirationDate } from '~app/api/cpqService';
import ExpiringIcon from '~app/assets/icons/ExpiringIcon';
import {
  MButton,
  MCustomIconButton,
  MFlex,
  MFormField,
  MText,
} from '~app/components/Monetize';
import { DatePicker } from '~app/components/Monetize/DatePicker/DatePicker';
import MPopoverWrapper from '~app/components/Monetize/MPopoverWrapper';
import { IExtendedExpirationDateReqSchema, IQuoteRespSchema } from '~app/types';
import { pluralize } from '~app/utils';
import { getRequiredMessage } from '~app/utils/messages';

interface ExtendQuoteExpirationDateProps {
  quote: IQuoteRespSchema;
  isQuoteExpiresInDaysVisible: boolean;
  quoteExpiresInDays: number;
  setQuote: (quote: IQuoteRespSchema | null) => void;
}
export const ExtendQuoteExpirationDate = ({
  quote,
  quoteExpiresInDays,
  isQuoteExpiresInDaysVisible,
  setQuote,
}: ExtendQuoteExpirationDateProps) => {
  const {
    handleSubmit,
    reset,
    setError,
    control,
    formState: { errors, isValid, isDirty },
  } = useForm<IExtendedExpirationDateReqSchema>({
    mode: 'onChange',
  });
  const { mutate: updateNewQuoteType, isLoading } = useExtendExpirationDate({
    onSuccess: (data) => {
      setQuote(data);
    },
    onError: (error) => {
      // If any error occurs it should reset the date
      reset();
      handleApiErrorToast(error);
    },
  });

  const [minDate, setMinDate] = useState<Date>(new Date());

  const onSubmit = async (payload: IExtendedExpirationDateReqSchema) => {
    // If user manually input extended expiration date before min date then it should trigger
    // setError and exit before calling api and show error message.
    const parsedDate = parseISO(payload.extendedDate);
    const isMinDateInvalid = isBefore(parsedDate, minDate);
    if (!payload.extendedDate) {
      setError('extendedDate', {
        type: 'custom',
        message: getRequiredMessage('Date'),
      });
      return;
    }

    if (isMinDateInvalid) {
      setError('extendedDate', {
        type: 'custom',
        message: 'Date cannot be before expiration date.',
      });
      return;
    }
    updateNewQuoteType({
      quoteId: quote.id,
      payload,
    });
  };

  useEffect(() => {
    if (quote.expirationDate) {
      const parsedDate = parseISO(quote.expirationDate);
      setMinDate(parsedDate);
      reset({ extendedDate: quote.expirationDate });
    }
  }, [quote, reset]);

  const { onOpen, onClose, isOpen } = useDisclosure();

  if (!isQuoteExpiresInDaysVisible) {
    return null;
  }

  const isQuoteExpiresToday = quoteExpiresInDays <= 0;
  return (
    <MPopoverWrapper
      isOpen={isOpen}
      placement="bottom-start"
      showArrow
      onOpen={onOpen}
      onClose={() => {
        reset();
        onClose();
      }}
      triggerContent={
        <MCustomIconButton
          p={4}
          minH="8"
          variant="icon"
          aria-label="expiringBtn"
          btnSize={7}
          icon={ExpiringIcon}
        />
      }
      bodyContent={
        <MFlex direction="column" gap={4}>
          <MFlex direction="column">
            <MText fontWeight="bold" color="tPurple.dark">
              This quote expires{' '}
              {isQuoteExpiresToday
                ? 'today'
                : `in ${quoteExpiresInDays} ${pluralize(
                    'day',
                    quoteExpiresInDays,
                  )}`}
            </MText>
            <MText fontWeight="normal" color="tPurple.dark">
              Update expiration date to extend Quote.
            </MText>
          </MFlex>
          <form onSubmit={handleSubmit(onSubmit)}>
            <MFormField error={errors.extendedDate} isRequired w="full">
              <Controller
                name="extendedDate"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    {...field}
                    minDate={minDate}
                    isDisabled={isLoading}
                  />
                )}
              />
            </MFormField>
          </form>
        </MFlex>
      }
      footerContent={
        <MFlex direction="row" align="center" justify="right" gap={4}>
          <MButton variant="cancel" minW="auto" onClick={onClose}>
            Cancel
          </MButton>
          <MButton
            isLoading={isLoading}
            variant="primary"
            onClick={handleSubmit(onSubmit)}
            isDisabled={!isValid || !isDirty || isLoading}
            type="submit"
            minW="auto"
          >
            Update
          </MButton>
        </MFlex>
      }
    />
  );
};
