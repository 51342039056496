import { z } from 'zod';

export enum StorageTypeEnum {
  MSA = 'MSA',
  SOW = 'SOW',
  COVER = 'COVER',
  FOOTER = 'FOOTER',
}
export const StorageTypeEnumZ = z.nativeEnum(StorageTypeEnum);

export enum StorageReferenceTypeEnum {
  QUOTE = 'QUOTE',
  QUOTE_CONFIG = 'QUOTE_CONFIG',
}
export const StorageReferenceTypeEnumZ = z.nativeEnum(StorageReferenceTypeEnum);

export const StorageSchema = z.object({
  id: z.string(),
  filename: z.string(),
  mediaType: z.string(),
  referenceFilename: z.string(),
  referenceId: z.string(),
  contentBase64Encoded: z.string().nullish(),
  referenceType: StorageReferenceTypeEnumZ,
  type: StorageTypeEnumZ,
});
export type IStorage = z.infer<typeof StorageSchema>;
