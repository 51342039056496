import { ModalOverlayProps } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useCreateTenant, useUpdateTenant } from '~api/tenantService';
import { handleApiErrorToast } from '~app/api/axios';
import { useAuth } from '~app/services/auth0';
import { useFlags } from '~app/services/launchDarkly';
import {
  MBox,
  MButton,
  MCenterModal,
  MCustomRadioGroup,
  MCustomSelect,
  MFormField,
  MGrid,
  MGridItem,
  MInput,
  MLink,
  MStack,
  MTextarea,
} from '~components/Monetize';
import { ROUTES } from '~constants';
import { ITenant, TenantRequest, TenantRequestSchema } from '~types';
import { useGetTimezones } from '../../api/timezoneService';
import { USER_TIMEZONE } from '../../constants/dates';
import { TIMEZONE_CONFIG } from '../../utils/dates';
import { MainSearchInputV2 } from '../Monetize/MCustomSelect/components/MainSearchInputV2';

interface TenantFormBaseProps {
  existingTenant?: ITenant | null;
  isOpen: boolean;
  overlayProps?: ModalOverlayProps;
  showLogoutBtn?: boolean;
  onSaved?: (data: ITenant) => void;
}

interface TenantFormWithoutCloseProps extends TenantFormBaseProps {
  showCloseButton?: true;
  onClose: () => void;
}

interface TenantFormWithCloseProps extends TenantFormBaseProps {
  showCloseButton: false;
  onClose?: never;
}

/**
 * After making any changes here, make sure to test the new user signup flow
 * where a tenant is created for the user.
 */
export const TenantForm = ({
  existingTenant,
  isOpen,
  showCloseButton = true,
  showLogoutBtn,
  onSaved,
  onClose,
}: TenantFormWithoutCloseProps | TenantFormWithCloseProps) => {
  const initialRef = React.useRef(null);
  const { createProductionTenant, allowTimezoneModification } = useFlags();
  const TENANT_OPTIONS = [
    {
      title: 'Test',
      value: true,
      description:
        'This is a test tenant, play around, make mistakes, learn how MonetizeNow works.',
    },
    {
      title: 'Live',
      disabled: !createProductionTenant,
      value: false,
      description: (
        <>
          If you are ready for a live tenant, please contact{' '}
          <MLink href="mailto:support@monetizenow.io" color="tIndigo.base">
            support@monetizenow.io
          </MLink>
        </>
      ),
    },
  ];

  const { currentTenant, setCurrentTenant } = useAuth();
  const { data: TimeZoneResponse } = useGetTimezones();
  const { mutateAsync: doCreateTenant, isLoading: createLoading } =
    useCreateTenant();
  const { mutateAsync: doUpdateTenant, isLoading: updateLoading } =
    useUpdateTenant();
  const timeZones = TimeZoneResponse?.timezones || [];
  const timeZonesByValue = TimeZoneResponse?.timezonesByValue || {};
  const isLoading = createLoading || updateLoading;

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors, isDirty, isValid },
  } = useForm<TenantRequest>({
    resolver: zodResolver(TenantRequestSchema),
    mode: 'onChange',
    defaultValues: {
      description: existingTenant?.description || '',
      testTenant: existingTenant?.testTenant ?? true,
      name: existingTenant?.name || '',
      timezone: existingTenant?.timezone ?? 'UTC',
    },
  });
  const watchTimezone = watch('timezone');

  const utcTimeZoneLabel = timeZonesByValue.UTC?.value;
  const userTimeZoneLabel =
    timeZonesByValue[TIMEZONE_CONFIG.userTimezone]?.label;

  const onSubmit = async (data: TenantRequest) => {
    try {
      let tenant;
      if (!existingTenant) {
        tenant = await doCreateTenant(data);
      } else {
        tenant = await doUpdateTenant({ id: existingTenant.id!, data });
        if (existingTenant.id === currentTenant?.id) {
          setCurrentTenant({ ...tenant });
        }
      }
      onSaved?.(tenant);
      onClose?.();
    } catch (err: any) {
      handleApiErrorToast(err);
    }
  };

  return (
    <MCenterModal
      initialFocusRef={initialRef}
      size="md"
      isOpen={isOpen}
      showCloseButton={showCloseButton}
      onClose={onClose || (() => {})}
      modalTitle={existingTenant?.id ? 'Edit Tenant' : 'New Tenant'}
      renderFooter={() => (
        <MStack
          spacing={8}
          direction="row"
          align="center"
          justify="center"
          flex={1}
        >
          {showLogoutBtn && (
            <MButton variant="tertiary" as={Link} to={ROUTES.AUTH_LOGOUT}>
              Logout
            </MButton>
          )}
          <MButton
            variant="primary"
            type="submit"
            isLoading={isLoading}
            onClick={handleSubmit(onSubmit)}
            isDisabled={!isValid || !isDirty || isLoading}
          >
            Save
          </MButton>
        </MStack>
      )}
    >
      <MBox>
        <form onSubmit={handleSubmit(onSubmit)} data-testid="tenant-form">
          <MGrid templateColumns="repeat(12, 1fr)" gap={4}>
            <MGridItem colSpan={12}>
              <MFormField error={errors.name} label="Tenant Name" isRequired>
                <Controller
                  name="name"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <MInput
                      {...field}
                      placeholder="Ex: Tenant 1"
                      ref={initialRef}
                    />
                  )}
                />
              </MFormField>
            </MGridItem>
            <MGridItem colSpan={12}>
              <MFormField label="Description">
                <Controller
                  name="description"
                  control={control}
                  defaultValue=""
                  render={({ field: { value, ...rest } }) => (
                    <MTextarea value={value || ''} {...rest} />
                  )}
                />
              </MFormField>
            </MGridItem>
            {allowTimezoneModification && (
              <MGridItem colSpan={12}>
                <MFormField
                  label="Time Zone"
                  tooltip="This controls the time zone used for displaying date-time fields in MonetizeNow, emails, and PDF documents."
                >
                  <Controller
                    name="timezone"
                    control={control}
                    render={({ field }) => (
                      <MCustomSelect
                        useMainInputAsSearchInput
                        MainInputComponent={MainSearchInputV2}
                        items={timeZones || []}
                        itemTitle="displayLabel"
                        itemValue="value"
                        isLazy
                        {...field}
                      />
                    )}
                  />
                </MFormField>
                <MButton
                  variant="link"
                  pl="1"
                  size="sm"
                  onClick={() =>
                    setValue(
                      'timezone',
                      watchTimezone === USER_TIMEZONE ? 'UTC' : USER_TIMEZONE,
                      { shouldDirty: true, shouldValidate: true },
                    )
                  }
                >
                  {watchTimezone === USER_TIMEZONE
                    ? `Set to default time zone (${utcTimeZoneLabel})`
                    : `Set to your time zone (${userTimeZoneLabel})`}
                </MButton>
              </MGridItem>
            )}
            <MGridItem colSpan={12}>
              <MFormField error={errors.testTenant} label="Select Tenant Type">
                <Controller
                  name="testTenant"
                  control={control}
                  defaultValue
                  render={({ field: { value, ...rest } }) => (
                    <MCustomRadioGroup
                      value={value as any}
                      my={1}
                      {...rest}
                      itemDescription="description"
                      items={TENANT_OPTIONS}
                      isDisabled={!!existingTenant?.id}
                    />
                  )}
                />
              </MFormField>
            </MGridItem>
          </MGrid>
        </form>
      </MBox>
    </MCenterModal>
  );
};
