import { ApexOptions } from 'apexcharts';
import { colors } from '~app/styles/theme';
import { IQuoteAmountsByStatus } from '~app/types';
import { formatCurrency, toTitleCase } from '~app/utils';
import {
  BASE_BAR_CHART_OPTIONS,
  BASE_PIE_CHART_OPTIONS,
  getBaseLineOptionsCurrencyYAxis,
} from '~app/utils/charts';

export const salesTooltipStyles = `
  background: #FFFFFF !important;
  padding: 6px;
  color: var(--chakra-colors-tGray-darkGray);
  font-size: 12px;
  box-shadow: 0px 4px 25px rgba(180, 188, 239, 0.38);
  border-radius: 4px;
`;

const salesCustomTooltip: ApexOptions['tooltip'] = {
  custom: ({ series, seriesIndex, dataPointIndex, w }: any) =>
    `<div class="custom-tooltip" style="${salesTooltipStyles}">${formatCurrency(
      series[seriesIndex][dataPointIndex],
    )}</div>`,
};

export const salesChartOptions = (currency = 'USD') => ({
  ...getBaseLineOptionsCurrencyYAxis(currency),
  tooltip: salesCustomTooltip,
});

export const upcomingRenewalChartOptions = (currency = 'USD') => ({
  ...BASE_BAR_CHART_OPTIONS,
  yaxis: {
    labels: {
      style: {
        fontSize: '13px',
        colors: colors.tGray.lightPurple,
      },
      formatter: (value: any) => `${formatCurrency(value, { currency })}`,
    },
  },
});

export const getQuoteValueByStatusChartOptions = (
  data?: IQuoteAmountsByStatus,
) => {
  const options: ApexOptions = {
    ...BASE_PIE_CHART_OPTIONS,
    labels: (data?.amountsByStatus || []).map((ele: any) =>
      toTitleCase(ele.status),
    ),
    tooltip: {
      custom: ({ series, seriesIndex, w }: any) =>
        `<div class="custom-tooltip"> <span> ${
          w.globals.labels[seriesIndex]
        }: ${formatCurrency(series[seriesIndex], {
          currency: data?.amountsByStatus[seriesIndex].currency,
        })}</span></div>`,
      followCursor: true,
    },
    colors: [colors.tPurple.safety, colors.tRed.magenta, colors.tGreen.bayside],
  };

  return options;
};
