import { GridItem, GridItemProps, Heading } from '@chakra-ui/react';
import partition from 'lodash/partition';
import { ReactNode, useMemo } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import {
  MCustomSelect,
  MFormField,
  MGrid,
  MText,
} from '~app/components/Monetize';
import { CUSTOM_FIELDS_PREFIX } from '~app/constants/customFields';
import {
  GuidedQuotingFieldDataTypeMapping,
  GuidedQuotingFieldDisplayText,
  GuidedQuotingPopulateByToAvailableTypes,
} from '~app/constants/guidedQuoting';
import {
  GuidedQuotingReq,
  ICustomFieldResSchema,
  QuestionFilterByEnum,
  QuestionReq,
  QuestionTypesEnum,
  QuoteSourceField,
  QuoteSourceFieldName,
} from '~app/types';
import { arrayToObject, objectToSelectOptions } from '~app/utils/misc';
import { GuidedQuotingQuoteFieldsValues } from './GuidedQuotingQuoteFieldsValues';

interface GuidedQuotingQuoteFieldsProps {
  formValues: UseFormReturn<GuidedQuotingReq>;
  quoteSourceFields: QuoteSourceField[];
  questions: QuestionReq[];
  quoteCustomFields: ICustomFieldResSchema[];
}

export const GuidedQuotingQuoteFields = ({
  formValues,
  quoteSourceFields,
  questions,
  quoteCustomFields,
}: GuidedQuotingQuoteFieldsProps) => {
  const customFieldsByKey = useMemo(
    () => arrayToObject(quoteCustomFields, 'key'),
    [quoteCustomFields],
  );

  const [standardQuoteFields, customQuoteFields] = useMemo(
    () =>
      partition(
        quoteSourceFields,
        (value) => !value.quoteField.startsWith(CUSTOM_FIELDS_PREFIX),
      ),
    [quoteSourceFields],
  );

  return (
    <MGrid templateColumns="1fr 1fr 1fr" overflow="hidden" alignItems="center">
      {/* Header */}
      <GridHeaderCell>Quote Field</GridHeaderCell>
      <GridHeaderCell>Populated By</GridHeaderCell>
      <GridHeaderCell>Value</GridHeaderCell>
      {/* Rows */}
      {standardQuoteFields.map(({ quoteField, type }, index) => (
        <GridBodyRow
          key={quoteField}
          index={index}
          formValues={formValues}
          name={quoteField}
          type={type}
          questions={questions}
          quoteCustomFields={customFieldsByKey}
        />
      ))}
      {customQuoteFields.length > 0 && (
        <GridItem
          colSpan={3}
          px={2}
          pt={2}
          borderBottom="1px"
          borderBottomColor="tGray.back"
        >
          <MText fontSize="md" fontWeight={600}>
            Quote Custom Fields
          </MText>
        </GridItem>
      )}
      {customQuoteFields.map(({ quoteField, type }, index) => (
        <GridBodyRow
          key={quoteField}
          index={index + standardQuoteFields.length}
          formValues={formValues}
          name={quoteField}
          type={type}
          questions={questions}
          quoteCustomFields={customFieldsByKey}
        />
      ))}
    </MGrid>
  );
};

const GridHeaderCell = ({
  children,
  ...rest
}: { children: ReactNode } & GridItemProps) => {
  return (
    <GridItem
      px={2}
      pb={2}
      borderBottom="1px"
      borderBottomColor="tGray.back"
      {...rest}
    >
      <Heading as="h3" fontWeight={600} fontSize="md">
        {children}
      </Heading>
    </GridItem>
  );
};

const GridBodyCell = ({
  children,
  ...rest
}: { children: ReactNode } & GridItemProps) => {
  return (
    <GridItem
      columnGap={0}
      px={2}
      py={2}
      minH="50px"
      borderBottom="1px"
      borderBottomColor="tGray.back"
      alignContent="center"
      {...rest}
    >
      {children}
    </GridItem>
  );
};

const GridBodyRow = ({
  index,
  formValues,
  name,
  type,
  questions,
  quoteCustomFields,
}: {
  index: number;
  formValues: UseFormReturn<GuidedQuotingReq>;
  name: QuoteSourceFieldName;
  type: QuestionFilterByEnum;
  questions: QuestionReq[];
  quoteCustomFields: Record<string, ICustomFieldResSchema>;
}) => {
  const {
    control,
    setValue,
    clearErrors,
    formState: { errors: errors },
  } = formValues;

  const customField = useMemo(() => {
    if (name.startsWith(CUSTOM_FIELDS_PREFIX)) {
      return quoteCustomFields[name.split('.')[1]];
    }
  }, [name, quoteCustomFields]);

  const typeOptions = useMemo(() => {
    if (name.startsWith(CUSTOM_FIELDS_PREFIX)) {
      return objectToSelectOptions(
        GuidedQuotingPopulateByToAvailableTypes[
          QuestionTypesEnum.CUSTOM_FIELD
        ] || {},
      );
    }
    return objectToSelectOptions(
      GuidedQuotingPopulateByToAvailableTypes[
        GuidedQuotingFieldDataTypeMapping[name]
      ] || {},
    );
  }, [name]);

  const label = customField
    ? customField.displayLabel
    : GuidedQuotingFieldDisplayText[name];

  return (
    <>
      <GridBodyCell>
        <MText>{label}</MText>
      </GridBodyCell>
      <GridBodyCell>
        <MFormField error={errors.quoteSourceFields?.[index]?.type} isRequired>
          <Controller
            name={`quoteSourceFields.${index}.type`}
            control={control}
            render={({ field: { onChange, ...rest } }) => (
              <MFormField isRequired>
                <MCustomSelect
                  items={typeOptions}
                  {...rest}
                  onChange={(newValue: any) => {
                    onChange(newValue);
                    setValue(`quoteSourceFields.${index}.value`, null);
                  }}
                />
              </MFormField>
            )}
          />
        </MFormField>
      </GridBodyCell>
      <GridBodyCell>
        <MFormField error={errors.quoteSourceFields?.[index]?.value} isRequired>
          <Controller
            name={`quoteSourceFields.${index}.value`}
            control={control}
            render={({ field }) => (
              <GuidedQuotingQuoteFieldsValues
                name={name}
                type={type}
                field={field}
                questions={questions}
                customField={customField}
                clearErrors={() =>
                  clearErrors(`quoteSourceFields.${index}.value`)
                }
              />
            )}
          />
        </MFormField>
      </GridBodyCell>
    </>
  );
};
