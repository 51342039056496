import { Fragment } from 'react';
import {
  MAccordionButton,
  MAccordionIcon,
  MAccordionItem,
  MAccordionPanel,
  MFlex,
  MGrid,
  MGridItem,
  MText,
} from '~app/components/Monetize';
import { formatCurrency, formatNumber, pluralize } from '~app/utils';
import { toDateShort } from '~app/utils/dates';
import { IProductRevRecognitionScheduleResponse } from '~types';

type InvoiceRevenueScheduleAccordionItemProps = {
  productRevRecSchedule: IProductRevRecognitionScheduleResponse;
  currency: string;
};

export const InvoiceRevenueScheduleAccordionItem = ({
  productRevRecSchedule,
  currency,
}: InvoiceRevenueScheduleAccordionItemProps) => {
  const { productName, schedule } = productRevRecSchedule;
  return (
    <MAccordionItem
      borderTop="none"
      borderLeft="none"
      borderRight="none"
      borderWidth="1px"
      borderColor="tGray.back"
    >
      <MAccordionButton px={0} _active={{ bg: 'none' }} _hover={{ bg: 'none' }}>
        <MFlex justifyContent="space-between" alignItems="center" w="full">
          <MText noOfLines={1} fontWeight={600} fontSize="md">
            {productName}
          </MText>

          <MFlex alignContent="center" justifyContent="center" gap={2}>
            <MText>
              {formatNumber(schedule.length, { maximumFractionDigits: 0 })}{' '}
              Revenue {pluralize('Segment', schedule.length)}
            </MText>
            <MAccordionIcon />
          </MFlex>
        </MFlex>
      </MAccordionButton>

      <MAccordionPanel pr={0} pt={0} pb={4}>
        <MGrid templateColumns="2fr repeat(4, 1fr)" columnGap={2} rowGap={3}>
          <MGridItem alignSelf="end">
            <MText fontWeight={700} lineHeight="18px">
              Date
            </MText>
          </MGridItem>
          <MGridItem alignSelf="end">
            <MText fontWeight={700} lineHeight="18px">
              Recognition Date
            </MText>
          </MGridItem>
          <MGridItem alignSelf="end" textAlign="right">
            <MText fontWeight={700} lineHeight="18px">
              Amount Recognized
            </MText>
          </MGridItem>
          <MGridItem alignSelf="end" textAlign="right">
            <MText fontWeight={700} lineHeight="18px">
              Previously Recognized
            </MText>
          </MGridItem>
          <MGridItem alignSelf="end" textAlign="right">
            <MText fontWeight={700} lineHeight="18px">
              Amount Deferred
            </MText>
          </MGridItem>
          {schedule.map(
            ({
              startDate,
              endDate,
              recognitionDate,
              recognizedAmount,
              previouslyRecognizedAmount,
              deferredAmount,
            }) => (
              <Fragment key={startDate}>
                <MGridItem>
                  <MText lineHeight="18px">
                    {toDateShort(startDate)} - {toDateShort(endDate)}
                  </MText>
                </MGridItem>
                <MGridItem>
                  <MText lineHeight="18px">
                    {toDateShort(recognitionDate)}
                  </MText>
                </MGridItem>
                <MGridItem textAlign="right">
                  <MText lineHeight="18px">
                    {formatCurrency(recognizedAmount, { currency })}
                  </MText>
                </MGridItem>
                <MGridItem textAlign="right">
                  <MText lineHeight="18px">
                    {formatCurrency(previouslyRecognizedAmount, { currency })}
                  </MText>
                </MGridItem>
                <MGridItem textAlign="right">
                  <MText lineHeight="18px">
                    {formatCurrency(deferredAmount, { currency })}
                  </MText>
                </MGridItem>
              </Fragment>
            ),
          )}
        </MGrid>
      </MAccordionPanel>
    </MAccordionItem>
  );
};
