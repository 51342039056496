import { MdInfo, MdOutlineError } from 'react-icons/md';
import { MFlex, MIcon, MTooltip } from '~app/components/Monetize';
import { InactiveItemTypeEnumUI } from '~app/types';
interface InactiveItemProps {
  type: InactiveItemTypeEnumUI;
  iconType: 'info' | 'warning';
}
const getLabel = (type: InactiveItemTypeEnumUI) => {
  switch (type) {
    case InactiveItemTypeEnumUI.OFFERING:
      return 'This offering is no longer active.';
    case InactiveItemTypeEnumUI.RATE:
      return 'This rate is no longer active.';
    default:
      return '';
  }
};
export function InactiveItem({
  type,
  iconType = 'info',
  ...rest
}: InactiveItemProps) {
  const label = getLabel(type);
  return (
    <MTooltip label={label} placement="bottom-start">
      <MFlex
        data-testid={`inactiveInfoIcon-${type}`}
        h="8"
        w="8"
        align="center"
        justify="center"
        cursor="pointer"
        {...rest}
      >
        <MIcon
          as={iconType === 'warning' ? MdOutlineError : MdInfo}
          size="4"
          color={iconType === 'warning' ? 'tOrange.tangerine' : 'tIndigo.base'}
          alignSelf="center"
        />
      </MFlex>
    </MTooltip>
  );
}
