import { FC } from 'react';
import {
  MCustomSelect,
  MText,
  QuoteSelectInput,
} from '~app/components/Monetize';
import { UserAvatarAndName } from '~app/components/Monetize/MCustomSelect/components/UserAvatarAndName';
import { useAuth } from '~app/services/auth0';
import { MCustomSelectProps } from '~app/types/mCustomSelectTypes';

const CHANGE_OWNER_WIDTH = '17.5625rem';

interface ChangeOwnerV2Props extends MCustomSelectProps {
  ownerName: string;
  inputId?: string;
}

export const ChangeOwnerV2: FC<ChangeOwnerV2Props> = ({
  ownerName,
  inputId,
  isReadOnly,
  ...rest
}: ChangeOwnerV2Props) => {
  const { tenantId, currentTenantUserHasRole } = useAuth();

  if (isReadOnly) {
    return <MText>{ownerName}</MText>;
  }

  return (
    <MCustomSelect
      itemTitle="name"
      itemValue="id"
      MainInputComponent={QuoteSelectInput}
      showContentInInput
      displayAvatar
      endpoint={`/api/tenants/${tenantId}/users`}
      popOverContentProps={{
        minWidth: CHANGE_OWNER_WIDTH,
      }}
      renderItemContent={({ title }) => (
        <UserAvatarAndName userName={title} textProps={{ fontSize: 'sm' }} />
      )}
      isReadOnly={!currentTenantUserHasRole || isReadOnly}
      showQueryInput
      popOverProps={{ placement: 'bottom-end', matchWidth: false }}
      {...rest}
    />
  );
};
