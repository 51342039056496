import React, { SVGProps } from 'react';

export const BriefcaseIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="30"
    height="28"
    viewBox="0 0 30 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M25.0334 9.5987H21.8334V7.46536C21.8334 6.28883 20.8766 5.33203 19.7 5.33203H13.3C12.1235 5.33203 11.1667 6.28883 11.1667 7.46536V9.5987H7.96671C6.79017 9.5987 5.83337 10.5555 5.83337 11.732V23.4654C5.83337 24.6419 6.79017 25.5987 7.96671 25.5987H25.0334C26.2099 25.5987 27.1667 24.6419 27.1667 23.4654V11.732C27.1667 10.5555 26.2099 9.5987 25.0334 9.5987ZM19.7 7.46536V9.5987H13.3V7.46536H19.7ZM7.96671 11.732H25.0334V15.9987H21.8334V13.8654H19.7V15.9987H13.3V13.8654H11.1667V15.9987H7.96671V11.732ZM7.96671 23.4654V18.132H11.1667V20.2654H13.3V18.132H19.7V20.2654H21.8334V18.132H25.0344V23.4654H7.96671Z"
      fill="#3D3C65"
    />
  </svg>
);
