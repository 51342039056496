import { Maybe } from '../types';
import { logger } from './logger';

export const updateMiddlewareAttributes = (userProps: {
  name: string;
  email: string;
  tenantId: Maybe<string>;
  tenantName: Maybe<string>;
}) => {
  try {
    if (window.Middleware) {
      window.Middleware.setAttributes(userProps);
    }
  } catch (ex) {
    logger.warn('Error updating middleware attributes', ex);
  }
};
