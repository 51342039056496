import { defineStyle, defineStyleConfig } from '@chakra-ui/react';
import { inputTheme } from './Input';

const baseStyle = defineStyle({
  ...inputTheme.baseStyle?.field,
  borderRadius: 'md',
  paddingY: '8px',
  minHeight: '80px',
  lineHeight: 'short',
  verticalAlign: 'top',
});

const variantReadonly = defineStyle({
  ...inputTheme.variants?.readonly.field,
});

const variantUnsaved = defineStyle({
  ...inputTheme.variants?.unsaved.field,
});

export const textareaTheme = defineStyleConfig({
  baseStyle,
  variants: {
    readonly: variantReadonly,
    unsaved: variantUnsaved,
    // this internal variant is the default, and it overrides many of our baseStyles
    outline: baseStyle,
  },
  defaultProps: {
    size: 'sm',
  },
});

export default textareaTheme;
