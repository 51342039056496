import { chakra } from '@chakra-ui/react';
import { Fragment } from 'react';
import { Link, useLocation } from 'react-router-dom';
import MComingSoonWrapper from '~app/components/Monetize/MComingSoon';
import { MBox, MFlex, MHeading, MText } from '~components/Monetize';
import { useSettingsNavRoutes } from './settingsNav';
import { ISettingsSideNav, ISettingsSideNavItem } from './settingsNavType';

const CLink = chakra(Link);

const SettingNavLink = ({
  settingNav,
}: {
  settingNav: ISettingsSideNavItem;
}) => {
  const location = useLocation();

  if (settingNav?.isComingSoon) {
    return (
      <MComingSoonWrapper px="1" py="1" ml="1.5" justifyContent="space-between">
        <MText color="tGray.lightPurple" fontWeight="light">
          {settingNav.title}
        </MText>
      </MComingSoonWrapper>
    );
  }

  const isActive =
    !!settingNav.route && location.pathname.startsWith(settingNav.route);

  let color = 'tPurple.dark'; // enabled but not active
  const hoverColor = 'tPurple.dark';
  let fontWeight = 'normal';
  if (isActive) {
    color = 'tIndigo.base';
    fontWeight = 'bold';
  }

  return (
    <CLink
      to={settingNav.route || ''}
      role="menuitem"
      data-testid={`settings-route-${settingNav.title.split(' ').join('-')}`}
      cursor="pointer"
      _hover={{ color: hoverColor, fontWeight: 'bold' }}
      borderWidth="1px"
      borderColor="transparent"
      borderRadius="md"
      px="1"
      py="1"
      ml="1.5"
      display="block"
    >
      <MText
        color={color}
        fontWeight={fontWeight}
        _hover={{ color: hoverColor, fontWeight: 'bold' }}
      >
        {settingNav.title}
      </MText>
    </CLink>
  );
};

const SettingsSideNavTitle = ({
  parentNavItem,
}: {
  parentNavItem: ISettingsSideNav;
}) => {
  const { title } = parentNavItem;

  return (
    <>
      <MText fontSize="md" pt="4" fontWeight="bold" color="tPurple.dark" pb="1">
        {title}
      </MText>
    </>
  );
};

export const SettingsSideNav = ({ children }: any) => {
  const SETTINGS_ROUTES = useSettingsNavRoutes();

  return (
    <MFlex flexDir="row" h="100vh">
      <MBox
        minW="200px"
        w="200px"
        backgroundColor="white"
        borderRight="1px solid"
        borderLeft="1px solid"
        borderColor="tPurple.linkWater"
        p="6"
        maxH="100vh"
        overflowY="auto"
      >
        <MHeading size="lg">Settings</MHeading>
        <MBox>
          {SETTINGS_ROUTES.map((navItem) => (
            <Fragment key={navItem.title}>
              <SettingsSideNavTitle parentNavItem={navItem} />
              {navItem.children.map((childNavItem) => (
                <SettingNavLink
                  key={childNavItem.title}
                  settingNav={childNavItem}
                />
              ))}
            </Fragment>
          ))}
        </MBox>
      </MBox>

      <MBox
        flex="1"
        overflowY="auto"
        maxH="100vh"
        className="custom-scroll-bar-v1"
      >
        {children}
      </MBox>
    </MFlex>
  );
};
