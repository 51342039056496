import { z } from 'zod';

// avoids circular dependencies by allowing multiple consumers to import this
// enum, which otherwise should not be referencing the full offeringTypes.ts file

export enum OfferingStatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  CANCELED = 'CANCELED',
  PENDING = 'PENDING',
  EXPIRED = 'EXPIRED',
  ARCHIVED = 'ARCHIVED',
}

export const OfferingStatusEnumZ = z.nativeEnum(OfferingStatusEnum);
