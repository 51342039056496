import { Divider, useDisclosure } from '@chakra-ui/react';
import { useState } from 'react';
import {
  MBox,
  MButton,
  MCenterModal,
  MFlex,
  MStack,
  MText,
} from '~app/components/Monetize';
import { IQuoteRespSchema, QuoteStatusEnum } from '~app/types';

interface QuoteReviewEsignProps {
  quote: IQuoteRespSchema;
}

const QuoteReviewInfoEsignItem = ({
  title,
  name,
  email,
  subTitle,
}: {
  title?: string;
  name?: string;
  email?: string;
  subTitle?: string;
}) => {
  return (
    <MFlex justifyContent="space-between" alignItems="flex-start" w="100%">
      <MBox>
        <MText fontWeight="bold">{title} </MText>
        {!!subTitle && (
          <MText color="tGray.darkPurple" fontSize="xs">
            {subTitle}
          </MText>
        )}
      </MBox>
      <MBox>
        {!!name && (
          <MText color="tGray.darkPurple" textAlign="right">
            {name}
          </MText>
        )}
        {!!email && (
          <MText color="tGray.darkPurple" fontSize="xs" textAlign="right">
            {email}
          </MText>
        )}
      </MBox>
    </MFlex>
  );
};

export const QuoteReviewInfoEsign = ({ quote }: QuoteReviewEsignProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  let esignText = '';
  // Determines esign text based on quote status, requiresEsign and quote sent date
  if (quote.requiresEsign) {
    switch (quote.status) {
      case QuoteStatusEnum.DRAFT:
      case QuoteStatusEnum.REVIEW:
      case QuoteStatusEnum.APPROVED:
      case QuoteStatusEnum.DENIED:
      case QuoteStatusEnum.CANCELED:
        esignText = 'This Quote will be sent through eSign';
        break;
      case QuoteStatusEnum.SENT:
      case QuoteStatusEnum.ACCEPTED:
      case QuoteStatusEnum.PROCESSED:
        esignText = 'This Quote was sent through eSign';
        break;
      case QuoteStatusEnum.EXPIRED: {
        if (!quote.sentDate) {
          esignText = 'This Quote will be sent through eSign';
        } else {
          esignText = 'This Quote was sent through eSign';
        }
        break;
      }
      default:
        break;
    }
  }

  function handleClose() {
    onClose();
  }

  const internalEsign = quote.contacts?.esign.find(({ internal }) => internal);
  const externalEsign = quote.contacts?.esign.find(({ internal }) => !internal);
  return (
    <>
      <MButton
        variant="underline"
        onClick={onOpen}
        fontSize="sm"
        fontWeight="normal"
        w="initial"
      >
        View Signer Details
      </MButton>
      <MCenterModal
        isOpen={isOpen}
        onClose={handleClose}
        modalTitle={esignText}
        closeOnOverlayClick
        closeOnEsc
        modalHeaderProps={{ fontSize: 'lg', display: 'flex' }}
        renderFooter={() => (
          <MStack
            spacing={4}
            direction="row"
            align="center"
            justify="right"
            flex={1}
          >
            <MButton minW="auto" variant="cancel" onClick={handleClose}>
              Close
            </MButton>
          </MStack>
        )}
      >
        <MBox>
          <MFlex flexDirection="column">
            {internalEsign && (
              <QuoteReviewInfoEsignItem
                title="Internal Signature: "
                name={internalEsign.fullName}
                email={internalEsign.email}
                subTitle="Receives the Quote first"
              />
            )}

            <Divider my={6} borderWidth="1px" borderColor="tBlue.hover" />
            {externalEsign && (
              <QuoteReviewInfoEsignItem
                title="Customer Signature: "
                name={externalEsign.fullName}
                email={externalEsign.email}
              />
            )}
          </MFlex>
        </MBox>
      </MCenterModal>
    </>
  );
};
