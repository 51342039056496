import {
  Modal,
  ModalBody,
  ModalBodyProps,
  ModalCloseButton,
  ModalContent,
  ModalContentProps,
  ModalFooter,
  ModalFooterProps,
  ModalHeader,
  ModalHeaderProps,
  ModalOverlay,
  ModalOverlayProps,
  ModalProps,
  Spinner,
} from '@chakra-ui/react';
import React, { FunctionComponent as FC, ReactNode } from 'react';
import { MCenter, MFlex, MStack } from './chakra';

interface MCenterModalProps extends ModalProps {
  renderFooter?: ((props: { onClose: () => any }) => React.ReactNode) | null;
  modalTitle?: ReactNode;
  modalOverlayProps?: ModalOverlayProps;
  modalHeaderProps?: ModalHeaderProps;
  modalContentProps?: ModalContentProps;
  modalBodyProps?: ModalBodyProps;
  modalFooterProps?: ModalFooterProps;
  renderModalTitleActions?: () => any;
  showCloseButton?: boolean;
  subTitle?: React.ReactNode;
  isLoading?: boolean;
}

const MCenterModal: FC<MCenterModalProps> = ({
  blockScrollOnMount,
  closeOnOverlayClick,
  isCentered,
  isOpen,
  modalOverlayProps,
  modalBodyProps,
  modalContentProps,
  modalFooterProps,
  modalHeaderProps,
  modalTitle = '',
  motionPreset,
  showCloseButton = true,
  size,
  subTitle,
  variant,
  onClose,
  renderFooter,
  renderModalTitleActions,
  children,
  isLoading = false,
  ...rest
}: MCenterModalProps) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      blockScrollOnMount={
        typeof blockScrollOnMount !== 'undefined' ? blockScrollOnMount : true
      }
      closeOnOverlayClick={
        typeof closeOnOverlayClick !== 'undefined' ? closeOnOverlayClick : true
      }
      isCentered={typeof isCentered !== 'undefined' ? isCentered : true}
      motionPreset={
        typeof motionPreset !== 'undefined' ? motionPreset : 'slideInBottom'
      }
      size={typeof size !== 'undefined' ? size : 'lg'}
      variant={typeof variant !== 'undefined' ? variant : 'primary'}
      scrollBehavior="inside"
      {...rest}
    >
      <ModalOverlay style={{ opacity: 0.5 }} {...modalOverlayProps} />

      <ModalContent {...modalContentProps}>
        <ModalHeader fontSize="lg" fontWeight="600" {...modalHeaderProps}>
          <MStack spacing={0} w="100%">
            <MFlex alignItems="center">
              {modalTitle}
              {renderModalTitleActions && renderModalTitleActions()}
            </MFlex>
            {subTitle}
          </MStack>
        </ModalHeader>
        {showCloseButton && (
          <ModalCloseButton _hover={{ bg: 'tGray.lightPurple' }} />
        )}
        <ModalBody {...modalBodyProps}>
          {isLoading ? (
            <MCenter>
              <Spinner />
            </MCenter>
          ) : (
            children
          )}
        </ModalBody>
        {!!renderFooter && (
          <ModalFooter px="0" mx="4" {...modalFooterProps}>
            {renderFooter({ onClose })}
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
};

export default MCenterModal;
