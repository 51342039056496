import { zodResolver } from '@hookform/resolvers/zod';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useGetAccountById } from '~api/accountsService';
import { handleApiErrorToast } from '~app/api/axios';
import { useCreateContact, useUpdateContact } from '~app/api/contactsService';
import { useGetById } from '~app/api/queryUtils';
import {
  CONTACT_STATUS_DISPLAY,
  DEFAULT_COUNTRY_US,
} from '~app/constants/contacts';
import { getAccountContactsRoute } from '~app/constants/routes';
import { useHandleApiError } from '~app/hooks/useHandleApiError';
import { useCtrlEnterHotkey } from '~app/hooks/useHotkeys';
import { logger } from '~app/services/logger';
import { nullifyEmptyStrings } from '~app/utils/misc';
import {
  MBox,
  MButton,
  MCenterModal,
  MPageHeader,
  MPageLoader,
  MStack,
  MTag,
} from '~components/Monetize';
import {
  ContactRequestSchema,
  ContactStatusEnum,
  IContactRequestSchema,
  IContactRespSchema,
} from '~types';
import { ContactForm } from './ContactForm';

const formDefault: Partial<IContactRequestSchema> = {
  fullName: '',
  email: '',
  phone: null,
  address: {
    city: null,
    country: DEFAULT_COUNTRY_US,
    line1: null,
    line2: null,
    state: null,
    postalCode: null,
  },
  status: ContactStatusEnum.ACTIVE,
  primary: false,
  customFields: {},
};

interface ContactFormModalProps {
  isOpen: boolean;
  isLoading?: boolean;
  isDisabled?: boolean;
  accountId?: string | null;
  contactId?: string | null;
  onClose: (contact?: IContactRespSchema) => void;
  onUpdatedContact?: null | ((contact: IContactRespSchema) => void);
  onCreatedContact?: null | ((contact: IContactRespSchema) => void);
  isReadOnly?: boolean;
  defaultValues?: Partial<IContactRequestSchema>;
}

export const ContactFormModal = React.forwardRef(
  (
    {
      isOpen,
      contactId,
      accountId,
      isLoading,
      isDisabled,
      onClose,
      onUpdatedContact,
      onCreatedContact,
      isReadOnly,
      defaultValues,
    }: ContactFormModalProps,
    ref,
  ) => {
    const params = useParams();
    const { handleApiError } = useHandleApiError();
    const [editContactId, setEditContactId] = useState<string>(() => {
      if (contactId) {
        return contactId;
      } else if (params?.contactId) {
        return params?.contactId;
      }
      return '';
    });

    useEffect(() => {
      if (contactId) {
        setEditContactId(contactId);
      } else if (params?.contactId) {
        setEditContactId(params?.contactId);
      }
    }, [isOpen, params, contactId]);

    const { isLoading: isContactFetchLoading, data: contact } =
      useGetById<IContactRespSchema>('contacts', editContactId!, {
        enabled: !!editContactId,
        refetchOnWindowFocus: false,
        select: (data) => {
          if (data.address && !data.address.country) {
            data.address.country = DEFAULT_COUNTRY_US;
          }
          return data;
        },
        onSuccess: (data) => {
          setIsPrimary(!!data?.primary);
          reset(Object.assign({}, formDefault, data));
        },
        onError: (err) =>
          handleApiError(
            err,
            accountId ? getAccountContactsRoute(accountId!) : undefined,
          ),
      });

    const [isPrimary, setIsPrimary] = useState<boolean>(!!contact?.primary);

    const {
      handleSubmit,
      control,
      formState: { errors },
      trigger,
      reset,
      setValue,
      watch,
    } = useForm<IContactRequestSchema>({
      resolver: zodResolver(ContactRequestSchema),
      mode: 'onBlur',
      defaultValues: contact || { ...formDefault, ...defaultValues },
    });

    useEffect(() => {
      if (!isOpen) {
        reset();
      }
    }, [isOpen, reset]);

    const { mutate: doCreateContact, isLoading: createContactLoading } =
      useCreateContact({
        onSuccess: (data) => {
          onClose(data);
          onCreatedContact && onCreatedContact(data);
        },
        onError: (err) => handleApiErrorToast(err),
      });

    const { mutate: doUpdateContact, isLoading: updateContactLoading } =
      useUpdateContact({
        onSuccess: (data) => {
          onClose();
          onUpdatedContact && onUpdatedContact(data);
        },
        onError: (err) => handleApiErrorToast(err),
      });

    const isContactLoading =
      !!isOpen && !!editContactId && isContactFetchLoading;

    const isContactSaving = createContactLoading || updateContactLoading;

    const isValid = Object.keys(errors).length === 0;

    // TODO: reconcile this with the other useEffect using the same dependencies
    // Note that when this modal is used with a unique route, isOpen is always true, and the component is unmounted without
    // isOpen first being set to false
    useEffect(() => {
      if (!isOpen) {
        reset({ ...formDefault });
      }
    }, [isOpen, reset]);

    useCtrlEnterHotkey(() => {
      if (isOpen) {
        handleSubmit(onSubmit, onError)();
      }
    });

    const onSubmit = async (requestData: IContactRequestSchema) => {
      try {
        requestData = nullifyEmptyStrings(requestData);
        if (!editContactId) {
          if (accountId) {
            doCreateContact({
              accountId,
              payload: requestData,
            });
          }
        } else {
          doUpdateContact({
            contactId: editContactId,
            payload: requestData,
          });
        }
      } catch (err) {
        handleApiErrorToast(err);
      }
    };
    const onError = (errs: any, event: any) => {
      logger.warn(`ERRORS:`, errs);
    };

    const { data: accountDetails } = useGetAccountById(accountId as string, {
      enabled: !!accountId,
    });

    const title = (
      <MPageHeader
        title={
          contactId || params?.contactId
            ? `${!isReadOnly ? 'Edit' : 'View'} Contact`
            : 'New Contact'
        }
        status={
          contact?.status ? CONTACT_STATUS_DISPLAY[contact?.status] : undefined
        }
        tag={
          <MBox display="flex">
            {isPrimary && <MTag variant="blue">Primary</MTag>}
          </MBox>
        }
        parentLink={
          accountId || contact?.accountId
            ? getAccountContactsRoute(accountId || contact!.accountId)
            : undefined
        }
        parentLinkTitle={accountDetails?.accountName}
        copyUrl
        id={contact?.id}
        containerProps={{ mb: 0 }}
      ></MPageHeader>
    );

    return (
      <MCenterModal
        isOpen={isOpen}
        onClose={onClose}
        renderModalTitleActions={() => title}
        renderFooter={
          isContactLoading || isReadOnly
            ? null
            : () => (
                <MStack
                  spacing={4}
                  direction="row"
                  align="center"
                  justify="right"
                  flex={1}
                >
                  <MButton
                    onClick={() => onClose()}
                    variant="cancel"
                    minW="auto"
                  >
                    Cancel
                  </MButton>
                  <MButton
                    data-testid="contact-form-submit"
                    variant="primary"
                    isLoading={
                      isContactLoading ||
                      isContactSaving ||
                      isLoading ||
                      createContactLoading ||
                      updateContactLoading
                    }
                    onClick={handleSubmit(onSubmit, onError)}
                    isDisabled={!isValid || isDisabled}
                    type="submit"
                    minW="auto"
                  >
                    Save
                  </MButton>
                </MStack>
              )
        }
        modalContentProps={{ 'data-testid': 'contact-form' } as any}
      >
        <MBox>
          {isContactLoading ? (
            <MPageLoader height="450px" />
          ) : (
            <ContactForm
              formId="contact-form"
              isDisabled={isDisabled}
              control={control}
              errors={errors}
              watch={watch}
              trigger={trigger}
              setValue={setValue}
              handleSubmit={handleSubmit(onSubmit, onError)}
              isReadOnly={isReadOnly}
              contactId={contactId || params?.contactId}
              omitPrimary={contact?.status !== ContactStatusEnum.ACTIVE}
            />
          )}
        </MBox>
      </MCenterModal>
    );
  },
);
