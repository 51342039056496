import { zodResolver } from '@hookform/resolvers/zod';
import { FC, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useChangeTeamOwner } from '~app/api/settingsService';
import {
  MButton,
  MCenterModal,
  MCustomSelect,
  MFormField,
  MGrid,
  MGridItem,
  MStack,
} from '~app/components/Monetize';
import { UserAvatarAndName } from '~app/components/Monetize/MCustomSelect/components/UserAvatarAndName';

import {
  ITeamOwnerUpdateSchema,
  IUsersInfo,
  TeamOwnerUpdateSchema,
} from '~app/types';

interface OwnerChangeModalProps {
  isOpen: boolean;
  onClose: () => void;
  teamId: string;
  teamOwnerId: string;
  teamUsers: IUsersInfo[];
}

export const OwnerChange: FC<OwnerChangeModalProps> = ({
  isOpen,
  onClose,
  teamId,
  teamOwnerId,
  teamUsers,
}) => {
  const changeOwnerMutate = useChangeTeamOwner();
  const loading = changeOwnerMutate.isLoading;

  const {
    handleSubmit,
    reset,
    control,
    formState: { isValid, isDirty },
  } = useForm<ITeamOwnerUpdateSchema>({
    resolver: zodResolver(TeamOwnerUpdateSchema),
    mode: 'onSubmit',
    defaultValues: {
      userId: teamOwnerId,
    },
  });

  useEffect(() => {
    reset({ userId: teamOwnerId });
  }, [teamOwnerId, reset]);

  const onCloseModal = () => {
    reset({ userId: teamOwnerId });
    onClose();
  };

  const onSubmit = async (data: any) => {
    if (teamId) {
      await changeOwnerMutate.mutate({ id: teamId, userId: data.userId });
      onClose();
    }
  };

  return (
    <MCenterModal
      size="xl"
      isOpen={isOpen}
      onClose={onCloseModal}
      modalTitle="Change Owner"
      modalContentProps={{ 'data-testid': 'change-owner-modal' } as any}
      renderFooter={() => (
        <MStack
          spacing={4}
          direction="row"
          align="center"
          justify="right"
          flex={1}
        >
          <MButton onClick={onCloseModal} variant="cancel" minW="auto">
            Cancel
          </MButton>
          <MButton
            variant="primary"
            isDisabled={!isValid || !isDirty || loading}
            onClick={handleSubmit(onSubmit)}
            isLoading={loading}
            type="submit"
            minW="auto"
          >
            Save
          </MButton>
        </MStack>
      )}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <MGrid gap={4} mb={4}>
          <MGridItem>
            <MFormField label="Choose Owner" isRequired>
              <Controller
                name="userId"
                control={control}
                render={({ field }) => (
                  <MCustomSelect
                    defaultValue={teamOwnerId}
                    items={teamUsers}
                    itemTitle="name"
                    itemValue="id"
                    displayAvatar
                    renderItemContent={({ title }) => (
                      <UserAvatarAndName userName={title} />
                    )}
                    {...field}
                  />
                )}
              />
            </MFormField>
          </MGridItem>
        </MGrid>
      </form>
    </MCenterModal>
  );
};
