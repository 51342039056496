import {
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from '@chakra-ui/react';
import { MdSearch } from 'react-icons/md';
import { MCloseButton } from '~app/components/Monetize';

type MemberSearchInputProps = {
  value: string;
  onChangeValue: (value: string) => void;
  onResetSearch: () => void;
};
const RIGHT_ELEMENT_W = 5;

export const MemberSearchInput = ({
  value,
  onChangeValue,
  onResetSearch,
}: MemberSearchInputProps) => {
  return (
    <InputGroup>
      <InputLeftElement pointerEvents="none" w="8" textAlign="center">
        <Icon w="5" h="5" as={MdSearch} color="tPurple.base" />
      </InputLeftElement>
      <Input
        data-testid="search-input"
        w={278}
        type="text"
        placeholder="Search"
        value={value}
        onChange={(e) => onChangeValue(e.target.value)}
        tabIndex={-1}
        _placeholder={{
          color: 'tGray.darkGrayPurple',
        }}
        pr={RIGHT_ELEMENT_W + 3}
      />

      <InputRightElement w={RIGHT_ELEMENT_W}>
        {value && <MCloseButton onClick={() => onResetSearch()} mr={1} />}
      </InputRightElement>
    </InputGroup>
  );
};
