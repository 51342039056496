import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { apiGet, apiPatch, apiPost } from '~api/axios';
import { IRefundReqSchema, IRefundSchema } from '../types/refundTypes';
import { accountServiceQueryKeys } from './queryKeysService';
import { settingsQueryKeys } from './settingsService';

export function useCreateRefund() {
  const queryClient = useQueryClient();
  return useMutation<
    IRefundSchema,
    unknown,
    {
      paymentId: string;
      payload: IRefundReqSchema;
      paymentType?: string;
    }
  >(({ paymentId, payload, paymentType }) => {
    const refund = apiPost<IRefundSchema>(
      `/api/refunds/payment/${paymentId}${
        paymentType === 'manual' ? '/manual' : ''
      }`,
      payload,
    )
      .then((res) => res.data)
      .catch((error) => {
        throw error.response?.data;
      });

    queryClient.invalidateQueries(
      accountServiceQueryKeys.payments.paymentById(paymentId),
    );
    queryClient.invalidateQueries(
      accountServiceQueryKeys.payments.htmlTemplate(paymentId),
    );

    return refund;
  });
}

export function useGetRefundById(
  refundId: string,
  options: {
    enabled?: boolean;
    onSuccess?: (data: IRefundSchema) => void;
    onError?: (data: unknown) => void;
    refetchOnWindowFocus?: boolean;
  } = {},
) {
  return useQuery([...accountServiceQueryKeys.refunds.refundById(refundId)], {
    queryFn: () =>
      apiGet<IRefundSchema>(`/api/refunds/${refundId}`).then((res) => res.data),
    ...options,
  });
}

export const usePrintRefundToHtml = (
  {
    refundId,
    lastModifiedTimestamp,
  }: {
    refundId: string;
    /** Used for caching response */
    lastModifiedTimestamp?: string;
  },
  options: {
    enabled?: boolean;
    onSuccess?: (data: string) => void;
    onError?: (error: unknown) => void;
  } = {},
) => {
  return useQuery(
    [
      ...accountServiceQueryKeys.refunds.htmlTemplate(refundId),
      lastModifiedTimestamp,
    ],
    {
      queryFn: () =>
        apiGet<string>(`/api/refunds/${refundId}/print`, {
          responseType: 'text',
          headers: {
            accept: 'text/html',
          },
        }).then((res) => res.data),
      refetchOnWindowFocus: false,
      retry: false,
      keepPreviousData: true,
      ...options,
    },
  );
};

export const doPrintRefundToPdf = async (refundId: string) => {
  const res = await apiGet<ArrayBuffer>(`/api/refunds/${refundId}/print`, {
    responseType: 'arraybuffer',
    headers: {
      accept: 'application/pdf',
    },
  });
  return {
    data: res.data,
    fileName: `refund-${refundId}.pdf`,
  };
};

export const useUpdateLineItem = (
  options: Partial<
    UseMutationOptions<boolean, unknown, Record<string, string>>
  > = {},
) => {
  const queryClient = useQueryClient();

  return useMutation<any, unknown, Record<string, string>>(
    (payload) =>
      apiPatch<Record<string, string>>(
        `/connector/accounting/quickbooks/connection`,
        payload,
      )
        .then((res) => res)
        .catch((error) => {
          throw error.response?.data;
        }),
    {
      onSuccess: (data) => {
        if (data) {
          queryClient.invalidateQueries([...settingsQueryKeys.accounting()]);
        }
      },
      ...options,
    },
  );
};
