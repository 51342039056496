import { Box } from '@chakra-ui/react';
type MemberExpandProps = {
  maxInitMember: number;
  totalLength: number;
  handleExpand: () => void;
};

export const MemberExpand = ({
  maxInitMember,
  totalLength,
  handleExpand,
}: MemberExpandProps) => {
  return (
    <Box
      w="25px"
      h="25px"
      mr={2}
      lineHeight="25px"
      borderRadius="full"
      bg="tBlue.hover"
      fontSize="13px"
      fontWeight="500"
      textAlign="center"
      position="relative"
      cursor="pointer"
      onClick={handleExpand}
    >
      +{totalLength - maxInitMember}
    </Box>
  );
};
