import { FlexProps, GridItemProps, GridProps } from '@chakra-ui/react';
import { FC } from 'react';
import { IconType } from 'react-icons/lib';
import { AnrokLogo, AvalaraLogo } from '~app/assets/images';
import {
  MButton,
  MDivider,
  MFlex,
  MFormField,
  MGrid,
  MGridItem,
  MImage,
  MText,
} from '~app/components/Monetize';
import {
  ActionType,
  DataTableActions,
} from '~app/components/Monetize/DataTable/MDataTableActions';
import { MEntityStatusBadge } from '~app/components/Monetize/MEntityStatusBadge';
import { TAX_PROVIDER_DISPLAY, TAX_STATUS_DISPLAY } from '~app/constants/taxes';
import { IConfirmModalProps } from '~app/types';
import { TaxProviderTypeEnum, TaxStatusEnum } from '~app/types/taxTypes';

export type TaxCardAction = 'STATUS_UPDATE' | 'VERIFY' | 'DELETE' | 'OPEN';

interface TaxCardViewProps extends FlexProps {
  type: TaxProviderTypeEnum;
  status: TaxStatusEnum;
  omitEditButton?: boolean;
  tags: Array<{ label: string; value: string } & GridItemProps>;
  actions: {
    action: TaxCardAction;
    label: string;
    isDanger?: boolean;
    confirmProps?: Partial<IConfirmModalProps> | null;
    icon?: IconType;
  }[];
  onEdit: () => void;
  onAction: (action: TaxCardAction) => void;
  tagsContainer?: GridProps;
}

export const TaxCardView: FC<TaxCardViewProps> = ({
  type,
  tags,
  actions,
  status,
  omitEditButton,
  onEdit,
  onAction,
  tagsContainer,
  ...rest
}) => {
  const logo = type === TaxProviderTypeEnum.ANROK ? AnrokLogo : AvalaraLogo;

  const tableActions: ActionType[] = actions.map(
    ({ action, isDanger, label, confirmProps, icon }) => ({
      title: label,
      action: () => onAction(action),
      color: isDanger ? 'danger' : undefined,
      enabled: true,
      confirmProps,
      icon,
    }),
  );

  return (
    <MFlex
      bgColor="tWhite.base"
      alignItems="flex-start"
      flexDirection="column"
      p={4}
      border="1px solid"
      borderColor="tGray.back"
      borderRadius="3px"
      w="600px"
      {...rest}
    >
      <MFlex justifyContent="space-between" w="100%" minH={8}>
        <MFlex alignItems="center">
          <MImage src={logo} />
          <MText ml={2} fontSize="14px">
            {TAX_PROVIDER_DISPLAY[type]}
          </MText>
          <MEntityStatusBadge status={TAX_STATUS_DISPLAY[status]} />
        </MFlex>
        <MFlex alignItems="center">
          {!omitEditButton && (
            <MButton variant="tertiary" size="sm" onClick={onEdit}>
              Edit
            </MButton>
          )}
          <DataTableActions actions={tableActions} />
        </MFlex>
      </MFlex>
      <MDivider my={4} />
      <MGrid w="full" {...tagsContainer}>
        {tags.map(({ label, value, ...rest }, index) => (
          <MGridItem
            key={label}
            mb={index !== tags.length - 1 ? 4 : 0}
            {...rest}
          >
            <MFormField label={label} overflow="hidden">
              <MText title={value}>{value}</MText>
            </MFormField>
          </MGridItem>
        ))}
      </MGrid>
    </MFlex>
  );
};
