import { useDisclosure } from '@chakra-ui/react';
import { MdEdit } from 'react-icons/md';
import {
  MBox,
  MCheckbox,
  MCustomIconButton,
  MDivider,
  MFlex,
  MSimpleGrid,
  MSpinner,
  MText,
} from '~app/components/Monetize';
import { useCustomFields } from '~app/hooks/useCustomFields';
import {
  CustomFieldEntityEnum,
  CustomFieldTypeEnum,
  ICustomFieldRecordSchema,
  ICustomFieldResSchema,
} from '~app/types';
import { EditCustomFieldsModal } from './EditCustomFieldsModal';

type EditCustomFieldsSectionProps = {
  isLoading: boolean;
  invoiceId: string;
  customFiledValues?: ICustomFieldRecordSchema;
  refreshData: () => void;
};

export const EditCustomFieldsSection = ({
  isLoading,
  invoiceId,
  customFiledValues,
  refreshData,
}: EditCustomFieldsSectionProps) => {
  const {
    customFieldList,
    zodSchemasByEntity,
    isLoading: isCustomFieldsLoading,
  } = useCustomFields(CustomFieldEntityEnum.INVOICE);

  const {
    isOpen: isOPenEditCustomFieldsModal,
    onOpen: onOpenEditCustomFieldsModal,
    onClose: onCloseEditCustomFieldsModal,
  } = useDisclosure();

  if (customFieldList.length === 0) {
    return null;
  }

  if (isLoading || isCustomFieldsLoading) {
    return (
      <MBox w="100%" display="flex" alignItems="center" justifyContent="center">
        <MSpinner size="sm" />
      </MBox>
    );
  }

  return (
    <MBox
      width="100%"
      border="1px"
      borderColor="tGray.lightPurple"
      borderRadius={8}
      p={6}
    >
      <MFlex justifyContent="space-between">
        <MText fontSize={16} fontWeight={600} mb={4}>
          Additional Fields
        </MText>
        <MCustomIconButton
          variant="icon"
          btnSize={4}
          iconColor="tPurple.base"
          icon={MdEdit}
          data-testid="edit-invoice-btn"
          onClick={onOpenEditCustomFieldsModal}
        />
      </MFlex>
      <MDivider mb={6} />
      <MSimpleGrid columns={4} gap={4} mb={6} alignItems="flex-end">
        {customFiledValues &&
          customFieldList?.map((customField: ICustomFieldResSchema) => {
            const { key, displayLabel, type } = customField;
            const fieldValue = customFiledValues[key];

            switch (type) {
              case CustomFieldTypeEnum.CHECKBOX: {
                return (
                  <MCheckbox key={key} isChecked={!!fieldValue}>
                    {displayLabel}
                  </MCheckbox>
                );
              }
              default:
                return (
                  <MBox key={key}>
                    <MText color="tGray.darkPurple" fontWeight={400}>
                      {displayLabel}
                    </MText>
                    <MText color="tPurple.200">{fieldValue ?? 'Blank'}</MText>
                  </MBox>
                );
            }
          })}
      </MSimpleGrid>
      {isOPenEditCustomFieldsModal && (
        <EditCustomFieldsModal
          invoiceId={invoiceId}
          isOpen={isOPenEditCustomFieldsModal}
          customFieldList={customFieldList}
          customFiledValues={customFiledValues}
          zodSchemasByEntity={zodSchemasByEntity}
          onClose={onCloseEditCustomFieldsModal}
          refreshData={refreshData}
        />
      )}
    </MBox>
  );
};
