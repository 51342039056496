import { CardBody } from '@chakra-ui/react';
import { FunctionComponent as FC, Fragment } from 'react';
import {
  MBox,
  MCard,
  MDivider,
  MFlex,
  MHStack,
  MText,
} from '~app/components/Monetize';
import { MCurrency } from '~app/components/Monetize/MCurrency';
import { MPercent } from '~app/components/Monetize/MPercent';
import { OFFERINGS } from '~app/constants';
import { RATE_BILLING_FREQUENCY_MAP } from '~app/constants/offerings';

import { PRODUCT_TYPE_DISPLAY_SHORT } from '~app/constants/products';
import {
  IOrderedObj,
  IPriceUnderProductSchemaUI,
  IQuoteItemRespSchema,
  OfferingTypesEnum,
  RateBillingFrequencyEnum,
} from '~app/types';
import { formatNumber } from '~app/utils';
import { PriceModelEnum } from '~types/priceTypes';

interface PricesDrawerBodyViewProps {
  rateName: string;
  offeringType: OfferingTypesEnum;
  productsWithPrices: IPriceUnderProductSchemaUI[];
  productsObj: IOrderedObj<IQuoteItemRespSchema>;
  currency: string;
  billingFrequency?: RateBillingFrequencyEnum;
  billingFrequencyInMonths?: number | null;
}

export const PricesDrawerBodyView: FC<PricesDrawerBodyViewProps> = ({
  offeringType,
  rateName,
  productsWithPrices,
  productsObj,
  currency,
  billingFrequency,
  billingFrequencyInMonths,
}: PricesDrawerBodyViewProps) => {
  const isPoTOfferingType =
    offeringType === OfferingTypesEnum.CUSTOM_PERCENT_OF_TOTAL;

  return (
    <>
      <MCard variant="borderless" mb="2">
        <CardBody justifyContent="space-between" alignItems={'center'}>
          <MText as="h5" fontSize="md" fontWeight="bold" isTruncated>
            {rateName}
          </MText>
          <MText mt="2" as="h5" fontSize="md" fontWeight="bold" noOfLines={1}>
            Subscription Frequency{' '}
            <MText as="span" fontWeight="normal">
              {billingFrequency
                ? RATE_BILLING_FREQUENCY_MAP[billingFrequency](
                    billingFrequencyInMonths,
                  ).label
                : ''}
            </MText>
          </MText>
        </CardBody>
      </MCard>
      <MCard variant="borderless" mt="2">
        <CardBody>
          {productsWithPrices.map(
            ({ id, prices, name, productType }, index) => {
              const isLastRow = productsWithPrices.length - 1 === index;
              return (
                <Fragment key={id}>
                  <MBox pt="2">
                    <MText mb="4" as="h5" fontSize="md" fontWeight="bold">
                      {name}{' '}
                      <MText as="span" fontWeight="normal">
                        ({PRODUCT_TYPE_DISPLAY_SHORT[productType]})
                      </MText>
                    </MText>

                    <MHStack spacing={4} mb="2">
                      <MBox minW="4" />
                      <MText minW="40" fontWeight="bold">
                        Pricing Model
                      </MText>
                      <MText minW="6.25rem" fontWeight="bold">
                        From
                      </MText>
                      <MText minW="6.25rem" fontWeight="bold">
                        Up to
                      </MText>
                      <MText minW="32" fontWeight="bold">
                        {isPoTOfferingType ? 'Percentage' : 'Amount'}
                      </MText>
                      <MBox minW="8" />
                    </MHStack>
                    {prices.map(
                      (
                        { from, to, priceModel, amount, product },
                        productIndex: number,
                      ) => {
                        const formattedFrom = formatNumber(from, {
                          maximumFractionDigits: 0,
                        });

                        const formattedTo =
                          formatNumber(to, {
                            maximumFractionDigits: 0,
                          }) || '∞';

                        const isLastRow = prices.length - 1 === productIndex;

                        return (
                          <MHStack
                            key={`${productIndex}-range-${id}`}
                            align="flex-start"
                            spacing={4}
                            mb={!isLastRow ? 2 : 0}
                          >
                            <MFlex align="center" minW={4} minH={8}>
                              <MText color="tPurple.base">{`${
                                productIndex + 1
                              }.`}</MText>
                            </MFlex>
                            <MFlex align="center" minH="8" minW="40">
                              <MText>
                                {OFFERINGS.PRICE_MODEL_OPTIONS[priceModel]}
                              </MText>
                            </MFlex>
                            <MFlex align="center" minH="8" minW="6.25rem">
                              <MText>{formattedFrom}</MText>
                            </MFlex>
                            <MFlex align="center" minH="8" minW="6.25rem">
                              <MText>{formattedTo}</MText>
                            </MFlex>
                            <MFlex align="center" minH="8" w="7.125rem">
                              {isPoTOfferingType && (
                                <MPercent amount={amount} />
                              )}
                              {!isPoTOfferingType && (
                                <MCurrency
                                  amount={
                                    priceModel === PriceModelEnum.CUSTOM
                                      ? productsObj.byId[product.id]?.unitPrice
                                      : amount
                                  }
                                  options={{
                                    maximumFractionDigits: 'max',
                                    currency,
                                  }}
                                />
                              )}
                            </MFlex>

                            <MBox minW="8" />
                          </MHStack>
                        );
                      },
                    )}
                  </MBox>
                  {!isLastRow && <MDivider my="2" />}
                </Fragment>
              );
            },
          )}
        </CardBody>
      </MCard>
    </>
  );
};
