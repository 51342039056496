import React, { FunctionComponent as FC } from 'react';
import { MDivider, MGridItem, MText } from '~app/components/Monetize';
interface ExtraConfigurationTitleProps {
  title: string;
}
const ExtraConfigurationTitle: FC<ExtraConfigurationTitleProps> = ({
  title,
}: ExtraConfigurationTitleProps) => {
  return (
    <MGridItem colSpan={12}>
      <MDivider mt="3.5" borderColor="tGray.gray89" />
      <MText fontSize="lg" fontWeight="semibold" mt="5">
        {title}
      </MText>
    </MGridItem>
  );
};

export default ExtraConfigurationTitle;
