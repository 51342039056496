import { NotificationTypeEnum } from '~app/types';

export const NotificationTypeEnumDisplay: {
  [key in NotificationTypeEnum]: string;
} = {
  DOMAIN: 'Domain',
  EMAIL: 'Email',
};

export const NotificationTypePlaceholder: {
  [key in NotificationTypeEnum]: string;
} = {
  DOMAIN: 'Enter Domain Name',
  EMAIL: 'Enter Email',
};
