import { ReactNode, useRef } from 'react';
import { MdEdit } from 'react-icons/md';
import { colors } from '~styles/theme';
import { MCard, MCustomIconButton, MFlex, MHeading, MText } from '../Monetize';

interface KeyValueDetailProps {
  label: string;
  value: ReactNode;
}

const KeyValueDetail = ({ label, value }: KeyValueDetailProps) => {
  const id = `label_${label}`;
  return (
    <MFlex direction="column" gap={2} pt={2}>
      <MText id={id} color={colors.tGray.darkPurple}>
        {label}
      </MText>
      {value === 'Blank' ? (
        <MText aria-labelledby={id} fontStyle="italic">
          Blank
        </MText>
      ) : (
        <MText aria-labelledby={id}>{value}</MText>
      )}
    </MFlex>
  );
};

interface DynamicLabelValueBannerProps {
  title: string;
  data: {
    label: string;
    value: ReactNode;
  }[];
  isEditable?: boolean;
  onEditClick?: () => void;
}

// TODO: use this component to replace the the payment details component as this is more dynamic
export const DynamicLabelValueBanner = ({
  title,
  data,
  isEditable = false,
  onEditClick,
}: DynamicLabelValueBannerProps) => {
  const buttonRef = useRef<typeof MCustomIconButton>(null);

  //check if the data is empty or the only value is null
  if (data.length === 0) {
    return null;
  }

  return (
    <MCard
      width={'100%'}
      p={6}
      borderRadius="lg"
      borderColor={colors.tGray.lightPurple}
    >
      <MFlex
        borderBottom="2px solid"
        borderColor={colors.tWhite.titanWhite}
        py={2}
        justifyContent="space-between"
      >
        <MHeading color="tPurple.dark" fontSize={16} fontWeight={600}>
          {title}
        </MHeading>

        {isEditable && (
          <MCustomIconButton
            ref={buttonRef}
            btnSize={5}
            variant="icon"
            p={3}
            icon={MdEdit}
            iconColor="tPurple.base"
            onClick={onEditClick}
          />
        )}
      </MFlex>

      <MFlex flexWrap="wrap" justifyContent="left" gap={4} pt="4">
        {data.map((item, index) => (
          <KeyValueDetail key={index} label={item.label} value={item.value} />
        ))}
      </MFlex>
    </MCard>
  );
};
