import { FC, useMemo } from 'react';
import { BriefcaseIcon } from '~app/assets/icons';
import { QuoteBodyAccordion } from '~app/components/Quotes/QuoteBodyAccordion';
import { QuoteCustomFields } from '../../Quote/components/QuoteCustomFields';
import { QuoteTerms } from './QuoteTerms';
import { QuoteFormDiscounts } from '../../Quote/components/QuoteFormDiscounts';
import { QuoteFormPricesSection } from '../../Quote/components/QuoteFormPricesSection';
import { useQuoteContext } from '../../Quote/quoteContext';
import { QuoteUploadDocuments } from './QuoteUploadDocuments';
import { QuoteStatusEnum, QuoteTypeEnum } from '~app/types';

interface QuoteInternalExtraInfoProps {}

export const QuoteInternalExtraInfo: FC<
  QuoteInternalExtraInfoProps
> = ({}: QuoteInternalExtraInfoProps) => {
  const { quoteData } = useQuoteContext();
  const { quote } = quoteData;

  const title = useMemo(() => {
    if (quote?.status !== QuoteStatusEnum.DRAFT) {
      return 'Extra Information';
    } else {
      return 'Review Extra Information';
    }
  }, [quote?.status]);

  return (
    <QuoteBodyAccordion title={title} icon={BriefcaseIcon}>
      <QuoteUploadDocuments />

      <QuoteCustomFields showDivider={false} mt="6" />

      {quote && (
        <QuoteFormDiscounts
          quote={quote}
          mt={8}
          borderWidth="1px"
          borderRadius="md"
          borderColor="tGray.lightPurple"
          itemProps={{ border: 'none' }}
        />
      )}
      {quote?.id && (
        <QuoteFormPricesSection
          quoteId={quote?.id}
          currency={quote?.currency}
          mt={8}
          borderWidth="1px"
          borderRadius="md"
          borderColor="tGray.lightPurple"
          itemProps={{ border: 'none' }}
        />
      )}
      <QuoteTerms mt={6} />
    </QuoteBodyAccordion>
  );
};
