import {
  BoxProps,
  ButtonProps,
  Icon,
  IconProps,
  Input,
  InputGroup,
  InputLeftElement,
  InputProps,
  InputRightElement,
  Popover,
  PopoverBody,
  PopoverBodyProps,
  PopoverContent,
  PopoverContentProps,
  PopoverTrigger,
  Portal,
  TextProps,
  useDisclosure,
} from '@chakra-ui/react';
import { format as formatDate } from 'date-fns/format';
import { formatISO } from 'date-fns/formatISO';
import { isSameDay } from 'date-fns/isSameDay';
import { isSameMinute } from 'date-fns/isSameMinute';
import { parse as parseDate } from 'date-fns/parse';
import { parseISO } from 'date-fns/parseISO';
import isString from 'lodash/isString';
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import FocusLock from 'react-focus-lock';
import { IoMdCalendar } from 'react-icons/io';
import { MdArrowDropDown, MdClose } from 'react-icons/md';
import { DATE_PLACEHOLDER } from '~app/constants/placeholders';
import { toDateShort, toDateTimeShort } from '~app/utils/dates';
import MButton from '../MButton';
import MCustomIconButton from '../MCustomIconButton';
import MText from '../MText';
import { MBox, MCircularProgress } from '../chakra';
import { DatePickerBody } from './DatePickerBody';
import { TimePickerInput } from './TimePickerInput';

/**
 * TODO:
 * keyboard navigation (arrow keys, prev month, next month, )
 */

function formatDateInput(selectedDate?: Date | null, includeTime = false) {
  if (!selectedDate) {
    return '';
  }
  if (includeTime) {
    return toDateTimeShort(selectedDate);
  }
  return toDateShort(selectedDate, 'userTimezone');
}

export interface DatePickerProps
  extends Omit<InputProps, 'value' | 'onChange'> {
  value?: Date | string | null;
  popoverContentProps?: PopoverContentProps;
  popoverBodyProps?: PopoverBodyProps;
  readonlyContentProps?: TextProps;
  usePortal?: boolean;
  clearable?: boolean;
  isReadOnly?: boolean;
  isLoading?: boolean;
  includeTime?: boolean;
  minDate?: Date;
  maxDate?: Date;
  triggerType?: 'input' | 'button';
  btnText?: string;
  btnProps?: ButtonProps & { 'data-testid'?: string };
  /**
   * DATE = normal date picker (default) (can also include time picker)
   * MONTH = month picker
   * YEAR = year picker
   */
  mode?: 'DATE' | 'MONTH' | 'YEAR';
  /**
   * Only applies when mode is MONTH
   * Determines what months a user can choose
   * Default is 1, which means all months are available
   * if 3, user can only select every 3 months (quarter)
   * if 6, user can only select every 6 months (semi-annual)
   */
  interval?: 1 | 3 | 6;
  /**
   * Only applies when mode is MONTH
   * Determines what month the interval starts at
   * Only matters is interval is not 1
   */
  anchorMonth?: number;
  /** Only applies for MONTH YEAR mode
   * addMonths from baseDate
   */
  baseDate?: Date;
  onChange: (date: string | null) => void;
  onClose?: () => void;
  onOpen?: () => void;
  boxProps?: BoxProps;
}

export const DatePicker = forwardRef(
  (
    {
      popoverContentProps,
      popoverBodyProps,
      readonlyContentProps,
      usePortal = false,
      clearable = false,
      isReadOnly = false,
      isDisabled = false,
      isLoading = false,
      includeTime = false,
      minDate,
      maxDate,
      value,
      btnText = 'Select',
      triggerType = 'input',
      mode = 'DATE',
      interval,
      anchorMonth,
      onChange,
      onClose: onCloseProp,
      onOpen: onOpenProp,
      btnProps = {},
      baseDate,
      boxProps = {},
      ...inputProps
    }: DatePickerProps,
    ref,
  ) => {
    const isInputTypeTrigger = triggerType === 'input';
    const isButtonTypeTrigger = triggerType === 'button';
    const triggerRef = useRef<HTMLButtonElement | HTMLInputElement>();

    const { onOpen, onClose, isOpen } = useDisclosure({
      onClose: onCloseProp,
      onOpen: onOpenProp,
    });

    const [selectedDates, onDatesChange] = useState<Date[]>(() => {
      if (!value) {
        return [];
      }
      if (typeof value === 'string') {
        return [parseISO(value)];
      }
      return [value];
    });

    const [selectedDate] = selectedDates;

    const [selectedTime, setSelectedTime] = useState<{
      hour: number;
      minute: number;
      amPm: 'AM' | 'PM';
    }>(() => {
      if (!selectedDate) {
        return {
          hour: 1,
          minute: 0,
          amPm: 'AM',
        };
      }
      const [hour, minute, amPm] = formatDate(selectedDate, 'h mm a').split(
        ' ',
      );
      return {
        hour: Number(hour),
        minute: Number(minute),
        amPm: amPm as 'AM' | 'PM',
      };
    });

    useEffect(() => {
      if (!value) {
        onDatesChange([]);
      } else if (typeof value === 'string') {
        onDatesChange([parseISO(value)]);
      } else {
        onDatesChange([value]);
      }
    }, [value]);

    const onPopoverOpen = () => {
      if (!isDisabled) {
        onOpen();
      }
    };

    const onPopoverClose = (date: Date | null = selectedDate) => {
      onClose();
      // emit value only when closed - which is on selection for date, or manually for datetime
      if (!date) {
        // only emit if value changed
        value && onChange(null);
        return;
      }
      if (includeTime) {
        const clonedDate = parseDate(
          `${selectedTime.hour}:${selectedTime.minute} ${selectedTime.amPm}`,
          'h:mm a',
          date || new Date(),
        );
        const tempPriorValue = isString(value) ? parseISO(value) : value;
        // only emit if value changed
        if (!tempPriorValue || !isSameMinute(tempPriorValue, clonedDate)) {
          onChange(formatISO(clonedDate, { representation: 'complete' }));
        }
      } else {
        const tempPriorValue = isString(value) ? parseISO(value) : value;
        // only emit if value changed
        if (!tempPriorValue || !isSameDay(tempPriorValue, date)) {
          onChange(formatISO(date, { representation: 'date' }));
        }
      }
    };

    const handleKeypress = (event: React.KeyboardEvent) => {
      if (event.code === 'Space' && !isOpen) {
        event.preventDefault();
        onPopoverOpen();
      }
    };

    const handleClear = (event: React.MouseEvent) => {
      onDatesChange([]);
      onPopoverClose(null);
      event.preventDefault();
      onChange(null);
    };

    const handleDateChange = (dates: Date[]) => {
      const [date] = dates;
      onDatesChange(dates);
      !includeTime && onPopoverClose(date);
    };

    const handleTimeChange = (
      hour: number,
      minute: number,
      amPm: 'AM' | 'PM',
    ) => {
      setSelectedTime({ hour, minute, amPm });
    };

    let inputRightWidth = 8;
    if (clearable && selectedDate) {
      inputRightWidth = 12;
    }

    const PopoverContentWrapper = usePortal ? Portal : React.Fragment;

    if (isReadOnly) {
      return (
        <MText pb="2" {...readonlyContentProps}>
          {formatDateInput(selectedDate || value, includeTime)}
        </MText>
      );
    }

    const activeProps: InputProps = isOpen
      ? {
          color: 'tIndigo.base',
          borderColor: 'tBlue.lightShade',
          background: 'tBlue.hover',
        }
      : {};
    const activeIconProps: IconProps = isOpen
      ? {
          color: 'tIndigo.base',
        }
      : {};

    return (
      <Popover
        trigger="click"
        offset={[0, 0]}
        variant="responsive"
        isOpen={isOpen}
        onOpen={onPopoverOpen}
        onClose={onPopoverClose}
        strategy="fixed"
        isLazy
        placement="bottom-end"
        // Since the trigger is a div, we need to manage this manually
        returnFocusOnClose={false}
      >
        <PopoverTrigger>
          <MBox {...boxProps}>
            {isInputTypeTrigger && (
              <InputGroup>
                <InputLeftElement>
                  <Icon
                    as={IoMdCalendar}
                    w="5"
                    h="5"
                    tabIndex={-1}
                    color={isDisabled ? 'tGray.acGray' : 'tPurple.dark'}
                    cursor={isDisabled ? 'not-allowed' : 'pointer'}
                    opacity={isDisabled ? 0.25 : 'unset'}
                    {...activeIconProps}
                  />
                </InputLeftElement>
                <Input
                  ref={triggerRef}
                  onKeyPress={handleKeypress}
                  autoComplete="off"
                  readOnly
                  cursor="pointer"
                  placeholder={DATE_PLACEHOLDER}
                  value={formatDateInput(selectedDate, includeTime)}
                  pr={inputRightWidth}
                  isDisabled={isDisabled}
                  {...activeProps}
                  {...inputProps}
                />
                <InputRightElement w={inputRightWidth}>
                  {clearable && !isDisabled && selectedDate && (
                    <MCustomIconButton
                      icon={MdClose}
                      onClick={handleClear}
                      bg="none"
                      color="tPurple.dark"
                      _hover={{ bg: 'none' }}
                      _focus={{ color: 'tIndigo.base' }}
                      iconProps={{ ...activeIconProps }}
                    />
                  )}
                  {isLoading ? (
                    <MCircularProgress isIndeterminate size={4} />
                  ) : (
                    <Icon
                      as={MdArrowDropDown}
                      tabIndex={-1}
                      fontSize="20"
                      color={isDisabled ? 'tGray.acGray' : 'tPurple.dark'}
                      cursor={isDisabled ? 'not-allowed' : 'pointer'}
                      opacity={isDisabled ? 0.25 : 'unset'}
                      {...activeIconProps}
                    />
                  )}
                </InputRightElement>
              </InputGroup>
            )}
            {isButtonTypeTrigger && (
              <MButton
                ref={triggerRef}
                variant="tertiary"
                size="sm"
                my={2}
                {...btnProps}
              >
                {btnText}
              </MButton>
            )}
          </MBox>
        </PopoverTrigger>
        <PopoverContentWrapper>
          <PopoverContent w="100%" {...popoverContentProps}>
            <PopoverBody {...popoverBodyProps} minW="304px">
              <FocusLock>
                <DatePickerBody
                  isDisabled={isDisabled}
                  minDate={minDate}
                  maxDate={maxDate}
                  selectedDates={selectedDates}
                  mode={mode}
                  interval={interval}
                  anchorMonth={anchorMonth}
                  onDatesChange={handleDateChange}
                  baseDate={baseDate}
                />
                {includeTime && mode === 'DATE' && (
                  <TimePickerInput
                    initialValue={selectedDate}
                    isDisabled={isDisabled}
                    onChange={handleTimeChange}
                    onEnter={onPopoverClose}
                  />
                )}
              </FocusLock>
            </PopoverBody>
          </PopoverContent>
        </PopoverContentWrapper>
      </Popover>
    );
  },
);
