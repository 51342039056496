import { useQueryClient } from '@tanstack/react-query';
import { useState, useEffect } from 'react';
import {
  useGetQuoteStorageById,
  doDeleteQuoteStorageById,
  doUploadQuoteDocument,
  cpqServiceQueryKeys,
} from '~app/api/cpqService';
import { logger } from '~app/services/logger';
import {
  IQuoteRespSchema,
  IQuoteRequestSchema,
  StorageTypeEnum,
  IStorage,
} from '~app/types';
import {
  DocumentItemState,
  ActiveDocumentType,
} from '~app/types/quoteDocumentsType';
import { showPdfInNewTab, convertToBase64 } from '~app/utils/download';
import { getQuoteRequestFromQuoteResponse } from '~app/utils/quotes';

export const useQuoteDocumentsV2 = ({
  quote,
  onUpdate,
}: {
  quote: IQuoteRespSchema;
  onUpdate: (data: IQuoteRequestSchema) => void;
  handleClose?: () => void;
}) => {
  const queryClient = useQueryClient();

  const [msaData, setMsaData] = useState<DocumentItemState>({
    savedData: {
      storage: undefined,
      link: quote.documentUrl || undefined,
    },
    activeItem: 'none',
    linkUrl: '',
    savedItemText: '',
  });

  const [sowData, setSowData] = useState<DocumentItemState>({
    savedData: {
      storage: undefined,
    },
    activeItem: 'none',
    linkUrl: '',
    savedItemText: '',
  });

  const [pdfCoverData, setPdfCoverData] = useState<DocumentItemState>({
    savedData: {
      storage: undefined,
    },
    activeItem: 'none',
    linkUrl: '',
    savedItemText: '',
  });

  const [pdfFooterData, setPdfFooterData] = useState<DocumentItemState>({
    savedData: {
      storage: undefined,
    },
    activeItem: 'none',
    linkUrl: '',
    savedItemText: '',
  });

  const msaDocument = useGetQuoteStorageById(
    {
      quoteId: quote.id,
      storageId: quote.documentLocation!,
      params: { attachContent: true },
    },
    { enabled: !!quote.documentLocation && !msaData.saving },
  );

  const sowDocument = useGetQuoteStorageById(
    {
      quoteId: quote.id,
      storageId: quote.sowDocumentStorageId!,
      params: { attachContent: true },
    },
    { enabled: !!quote.sowDocumentStorageId && !sowData.saving },
  );

  const quoteDocument = useGetQuoteStorageById(
    {
      quoteId: quote.id,
      storageId: quote.signedDocumentStorageId!,
      params: { attachContent: true },
    },
    { enabled: !!quote.signedDocumentStorageId },
  );
  const pdfCoverDocument = useGetQuoteStorageById(
    {
      quoteId: quote.id,
      storageId: quote.coverDocumentStorageId!,
      params: { attachContent: true },
    },
    { enabled: !!quote.coverDocumentStorageId && !pdfCoverData.saving },
  );
  const pdfFooterDocument = useGetQuoteStorageById(
    {
      quoteId: quote.id,
      storageId: quote.footerDocumentStorageId!,
      params: { attachContent: true },
    },
    { enabled: !!quote.footerDocumentStorageId && !pdfFooterData.saving },
  );

  const isLoadingInitialData =
    (msaDocument.isLoading && msaDocument.isFetching) ||
    (sowDocument.isLoading && sowDocument.isFetching) ||
    (quoteDocument.isLoading && quoteDocument.isFetching) ||
    (pdfCoverDocument.isLoading && pdfCoverDocument.isFetching) ||
    (pdfFooterDocument.isLoading && pdfFooterDocument.isFetching);

  const handlerMap = {
    setter: {
      msa: setMsaData,
      sow: setSowData,
      cover: setPdfCoverData,
      footer: setPdfFooterData,
    },
    getter: {
      msa: msaData,
      sow: sowData,
      cover: pdfCoverData,
      footer: pdfFooterData,
    },
  };

  // Set initial state id msaDocument changes
  useEffect(() => {
    if (msaDocument.data || quote.documentUrl) {
      setMsaData((priorData) => {
        let activeItem: ActiveDocumentType = 'none';
        if (msaDocument.data) {
          activeItem = 'file';
        } else if (quote.documentUrl) {
          activeItem = 'link';
        }
        return {
          ...priorData,
          savedData: {
            link: quote.documentUrl || undefined,
            storage: msaDocument.data,
          },
          linkUrl: quote.documentUrl || '',
          activeItem,
          savedItemText: msaDocument.data?.filename || quote.documentUrl || '',
        };
      });
    }
  }, [msaDocument.data, quote.documentUrl]);

  useEffect(() => {
    if (sowDocument.data) {
      setSowData((priorData) => {
        let activeItem: ActiveDocumentType = 'none';
        if (sowDocument.data) {
          activeItem = 'file';
        }
        return {
          ...priorData,
          savedData: {
            link: undefined,
            storage: sowDocument.data,
          },
          activeItem,
          savedItemText: sowDocument.data?.filename || '',
        };
      });
    }
  }, [sowDocument.data?.filename, sowDocument.data]);

  useEffect(() => {
    if (pdfCoverDocument.data) {
      setPdfCoverData((priorData) => {
        let activeItem: ActiveDocumentType = 'none';
        if (pdfCoverDocument.data) {
          activeItem = 'file';
        }
        return {
          ...priorData,
          savedData: {
            link: undefined,
            storage: pdfCoverDocument.data,
          },
          activeItem,
          savedItemText: pdfCoverDocument.data?.filename || '',
        };
      });
    }
  }, [pdfCoverDocument.data?.filename, pdfCoverDocument.data]);

  useEffect(() => {
    if (pdfFooterDocument.data) {
      setPdfFooterData((priorData) => {
        let activeItem: ActiveDocumentType = 'none';
        if (pdfFooterDocument.data) {
          activeItem = 'file';
        }
        return {
          ...priorData,
          savedData: {
            link: undefined,
            storage: pdfFooterDocument.data,
          },
          activeItem,
          savedItemText: pdfFooterDocument.data?.filename || '',
        };
      });
    }
  }, [pdfFooterDocument.data?.filename, pdfFooterDocument.data]);

  function handleActiveItemChange(
    type: 'msa' | 'sow' | 'cover' | 'footer',
    item: ActiveDocumentType,
  ) {
    handlerMap.setter[type]((priorData) => ({
      ...priorData,
      activeItem: item,
    }));
  }

  async function handleAddItem(
    type: 'msa' | 'sow' | 'cover' | 'footer',
    item: { type: 'file'; value: File } | { type: 'link'; value: string },
  ) {
    const updatedQuote = getQuoteRequestFromQuoteResponse(quote);

    if (item.type === 'file') {
      handlerMap.setter[type]((priorData) => ({
        ...priorData,
        fileToUpload: {
          file: item.value,
          fileName: item.value.name,
        },
        savedItemText: item.value.name,
        saving: true,
      }));
    } else {
      handlerMap.setter[type]((priorData) => ({
        ...priorData,
        linkUrl: item.value,
        savedItemText: '', // for links, this is not set until save
        saving: true,
      }));
    }

    try {
      if (type === 'msa') {
        if (item.type === 'link' && item.value) {
          updatedQuote.documentUrl = item.value;
          updatedQuote.documentLocation = null;
          setMsaData((priorData) => ({
            ...priorData,
            savedItemText: item.value,
          }));
        }

        if (item.type === 'file') {
          const { id } = await doUploadQuoteDocument(
            quote.id,
            item.value,
            StorageTypeEnum.MSA,
          );
          updatedQuote.documentLocation = id;
        }
      }
      if (type === 'sow' && item.type === 'file') {
        const { id } = await doUploadQuoteDocument(
          quote.id,
          item.value,
          StorageTypeEnum.SOW,
        );
        updatedQuote.sowDocumentStorageId = id;
      }

      if (type === 'cover' && item.type === 'file') {
        const { id } = await doUploadQuoteDocument(
          quote.id,
          item.value,
          StorageTypeEnum.COVER,
        );
        updatedQuote.coverDocumentStorageId = id;
      }

      if (type === 'footer' && item.type === 'file') {
        const { id } = await doUploadQuoteDocument(
          quote.id,
          item.value,
          StorageTypeEnum.FOOTER,
        );
        updatedQuote.footerDocumentStorageId = id;
      }

      await onUpdate(updatedQuote);
      queryClient.invalidateQueries([
        [...cpqServiceQueryKeys.quoteStorage(quote.id)],
      ]);
    } catch (ex) {
      logger.warn('Error creating storage', ex);
    }

    handlerMap.setter[type]((priorData) => ({
      ...priorData,
      saving: false,
    }));
  }

  async function handleRemoveItem(type: 'msa' | 'sow' | 'cover' | 'footer') {
    handlerMap.setter[type]((priorData) => ({
      ...priorData,
      fileToUpload: undefined,
      linkUrl: '',
      activeItem: 'none',
      savedItemText: '',
      saving: true,
    }));
    const updatedQuote = getQuoteRequestFromQuoteResponse(quote);

    try {
      if (
        type === 'msa' &&
        msaData.activeItem === 'file' &&
        msaData.savedData.storage
      ) {
        await doDeleteQuoteStorageById(quote.id, msaData.savedData.storage.id, {
          type: msaData.savedData.storage.type,
        });

        updatedQuote.documentLocation = null;
      }

      if (
        type === 'msa' &&
        msaData.activeItem === 'link' &&
        msaData.savedData.link
      ) {
        updatedQuote.documentUrl = null;
      }

      if (type === 'sow' && sowData.savedData.storage) {
        await doDeleteQuoteStorageById(quote.id, sowData.savedData.storage.id, {
          type: sowData.savedData.storage.type,
        });

        updatedQuote.sowDocumentStorageId = null;
      }

      if (type === 'cover' && pdfCoverData.savedData.storage) {
        await doDeleteQuoteStorageById(
          quote.id,
          pdfCoverData.savedData.storage.id,
          {
            type: pdfCoverData.savedData.storage.type,
          },
        );

        updatedQuote.coverDocumentStorageId = null;
      }
      if (type === 'footer' && pdfFooterData.savedData.storage) {
        await doDeleteQuoteStorageById(
          quote.id,
          pdfFooterData.savedData.storage.id,
          {
            type: pdfFooterData.savedData.storage.type,
          },
        );

        updatedQuote.coverDocumentStorageId = null;
      }

      await onUpdate(updatedQuote);
      queryClient.invalidateQueries([
        [...cpqServiceQueryKeys.quoteStorage(quote.id)],
      ]);
    } catch (ex) {
      logger.warn('Error creating storage', ex);
    }

    handlerMap.setter[type]((priorData) => ({
      ...priorData,
      saving: false,
    }));
  }

  /**
   * Open PDF in new tab or go to link URL
   */
  async function handleGoToItem(
    type: 'msa' | 'sow' | 'signedQuote' | 'cover' | 'footer',
  ) {
    if (type === 'signedQuote') {
      if (quoteDocument.data?.contentBase64Encoded) {
        const base64Data = quoteDocument.data.contentBase64Encoded;
        const fileName = quoteDocument.data.filename;
        showPdfInNewTab(base64Data, fileName);
      }
    } else {
      if (handlerMap.getter[type].activeItem === 'file') {
        let base64Data = '';
        let fileName = '';
        if (handlerMap.getter[type].fileToUpload) {
          base64Data = await convertToBase64(
            handlerMap.getter[type].fileToUpload!.file,
          );
          // eslint-disable-next-line prefer-destructuring
          fileName = handlerMap.getter[type].fileToUpload!.fileName;
        } else if (
          handlerMap.getter[type].savedData.storage?.contentBase64Encoded
        ) {
          base64Data =
            handlerMap.getter[type].savedData.storage!.contentBase64Encoded!;
          fileName = handlerMap.getter[type].savedData.storage!.filename;
        }
        showPdfInNewTab(base64Data, fileName);
      } else {
        const link =
          handlerMap.getter[type].linkUrl ||
          handlerMap.getter[type].savedData.link;
        if (link) {
          window.open(link, '_blank');
        }
      }
    }
  }

  return {
    quoteDocument,
    msaData,
    sowData,
    pdfCoverData,
    pdfFooterData,

    msaDocument,
    sowDocument,
    pdfCoverDocument,
    pdfFooterDocument,

    isLoadingInitialData,

    handleActiveItemChange,
    handleAddItem,
    handleRemoveItem,
    handleGoToItem,
  };
};
