import React, { FunctionComponent, PropsWithChildren } from 'react';
import { MdInfo } from 'react-icons/md';
import { MFlex, MIcon, MText, MTooltip } from '~app/components/Monetize';

const HeaderCellText: FunctionComponent<any> = ({
  children,
  isRequired,
  textProps = {},
  tooltipLabel,
  additionalNode,
  ...rest
}: PropsWithChildren<any>): React.ReactElement => {
  return (
    <>
      <MFlex fontWeight="600" alignItems={'center'} {...rest}>
        <MText {...textProps}>{children}</MText>
        {isRequired && (
          <MText color="tRed.cinnabar" ml="1" {...textProps}>
            *
          </MText>
        )}
        {tooltipLabel && (
          <MTooltip
            label={tooltipLabel}
            placement="bottom-start"
            shouldWrapChildren
            alignSelf="center"
            alignItems="center"
          >
            <MIcon
              display={'flex'}
              as={MdInfo}
              w="4"
              h="4"
              ml={2}
              cursor="pointer"
            />
          </MTooltip>
        )}
      </MFlex>
      {additionalNode && <MFlex mt={2}>{additionalNode}</MFlex>}
    </>
  );
};

export default HeaderCellText;
