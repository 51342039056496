import { LegalEntityAddressFormatEnum } from '~app/types/legalEntityTypes';
import { objectToSelectOptions } from '~app/utils/misc';

export const LEGAL_ENTITY_ADDRESS_FORMAT_DISPLAY_TEXT: {
  [key in LegalEntityAddressFormatEnum]: string;
} = {
  US_FORMAT: 'US Format',
  EUROPEAN_FORMAT: 'European Format',
};

export const LEGAL_ENTITY_ADDRESS_FORMATS = objectToSelectOptions(
  LEGAL_ENTITY_ADDRESS_FORMAT_DISPLAY_TEXT,
);
