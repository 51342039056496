import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { apiDelete, apiGet, apiPost, apiPut } from '~api/axios';
import { sortAlphabetically } from '~app/utils';
import { IEventCategory, IWebhookReqSchema, IWebhookResSchema } from '~types';
import {
  setByIdCacheFromReturnedList,
  updateListCacheWithRemovedItem,
  updateListCacheWithUpdatedItem,
} from './queryUtilsHelpers';

export const webhookQueryKeys = {
  base: ['webhook'] as const,
  webhookList: () => [...webhookQueryKeys.base, 'list'] as const,
  webhookDetail: (id: string) => [...webhookQueryKeys.base, id] as const,
  webhookEvents: () => [...webhookQueryKeys.base, 'events'] as const,
};

export function useGetWebHooks(
  options: {
    onError?: (err: unknown) => void;
    onSuccess?: (data: IWebhookResSchema) => void;
  } = {},
) {
  const queryClient = useQueryClient();
  const { onSuccess, ...restOptions } = options;
  return useQuery<IWebhookResSchema[]>([...webhookQueryKeys.webhookList()], {
    queryFn: () =>
      apiGet<IWebhookResSchema[]>('/webhooks/webhooks').then((res) => res.data),
    onSuccess: ([newData]) => {
      setByIdCacheFromReturnedList(
        queryClient,
        (id) => [...webhookQueryKeys.webhookDetail(id)],
        newData as any,
      );
      onSuccess && onSuccess(newData);
    },
    ...restOptions,
  });
}

export function useGetWebHookById({ webhookId }: { webhookId: string }) {
  return useQuery<IWebhookResSchema>(
    [...webhookQueryKeys.webhookDetail(webhookId!)],
    {
      queryFn: () =>
        apiGet<IWebhookResSchema>(`/webhooks/webhooks/${webhookId}`).then(
          (res) => res.data,
        ),
      enabled: !!webhookId,
    },
  );
}

export function useCreateWebhook(
  options: {
    onSuccess?: (data: IWebhookResSchema) => void;
    onError?: (err: unknown) => void;
  } = {},
) {
  const queryClient = useQueryClient();
  const { onSuccess, ...restOptions } = options;

  return useMutation<IWebhookResSchema, unknown, IWebhookReqSchema>(
    (payload) => {
      return apiPost<IWebhookResSchema>(`/webhooks/webhooks`, payload).then(
        (res) => res.data,
      );
    },
    {
      onSuccess: (newData) => {
        queryClient.invalidateQueries([...webhookQueryKeys.webhookList()]);
        onSuccess && onSuccess(newData);
      },
      ...restOptions,
    },
  );
}

export function useUpdateWebhook(
  options: {
    onSuccess?: (data: IWebhookResSchema) => void;
    onError?: (err: unknown) => void;
  } = {},
) {
  const queryClient = useQueryClient();
  const { onSuccess, ...restOptions } = options;

  return useMutation<
    IWebhookResSchema,
    unknown,
    { webhookId: string; payload: IWebhookReqSchema }
  >(
    ({ webhookId, payload }) => {
      return apiPut<IWebhookResSchema>(
        `/webhooks/webhooks/${webhookId}`,
        payload,
      ).then((res) => res.data);
    },
    {
      onSuccess: (newData, { webhookId }) => {
        queryClient.invalidateQueries([...webhookQueryKeys.webhookList()]);
        queryClient.invalidateQueries([
          ...webhookQueryKeys.webhookDetail(webhookId),
        ]);
        updateListCacheWithUpdatedItem(
          queryClient,
          [...webhookQueryKeys.webhookDetail(webhookId)],
          newData,
        );
        onSuccess && onSuccess(newData);
      },
      ...restOptions,
    },
  );
}

export function useDeleteWebHook(
  options: {
    onSuccess?: (data: IWebhookResSchema) => void;
    onError?: (err: unknown) => void;
  } = {},
) {
  const queryClient = useQueryClient();
  const { onSuccess, ...restOptions } = options;
  return useMutation<IWebhookResSchema, unknown, IWebhookResSchema>(
    (payload) => {
      return apiDelete<IWebhookResSchema>(
        `/webhooks/webhooks/${payload.webhookId}`,
      ).then((res) => res.data);
    },
    {
      onSuccess: (newData) => {
        queryClient.invalidateQueries([...webhookQueryKeys.webhookList()]);
        updateListCacheWithRemovedItem(
          queryClient,
          [...webhookQueryKeys.webhookDetail(newData.webhookId!)],
          newData.webhookId,
        );
        onSuccess && onSuccess(newData);
      },
    },
  );
}

export function useGetWebHookEvents() {
  const transformEvents = (events: IEventCategory[]) =>
    events
      .map(({ types, ...rest }) => ({
        types: types.sort(sortAlphabetically('key')),
        ...rest,
      }))
      .sort(sortAlphabetically('category'));

  return useQuery<IEventCategory[]>([...webhookQueryKeys.webhookEvents()], {
    queryFn: () =>
      apiGet<{ events: IEventCategory[] }>(
        `/webhooks/webhooks/webhookEvents`,
      ).then(({ data: { events } }) => transformEvents(events)),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24, // 24 hours
  });
}
