import { VStack } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { MdCheckCircle } from 'react-icons/md';
import {
  useSearchForImportablePaymentMethods,
  useImportPaymentMethods,
} from '~app/api/accountsService';
import { PaymentMethodTypeEnumDisplay } from '~app/constants/paymentMethods';
import useCurrentPaymentGateways from '~app/hooks/useCurrentPaymentGateways';

import {
  MBox,
  MButton,
  MCenterModal,
  MStack,
  MText,
  MFormField,
  MInput,
  MFlex,
  MDivider,
  MAlertTag,
  MCheckbox,
  MIcon,
  MFormLabel,
} from '~components/Monetize';

import {
  ImportPaymentMethodReqSchema,
  IImportPaymentMethodReqSchema,
  PaymentMethodTypeEnum,
  ImportablePaymentMethodItem,
  ImportStep,
} from '~types';
import { ImportPaymentMethodList } from './ImportPaymentMethodList';

const formDefault: Partial<IImportPaymentMethodReqSchema> = {
  gatewayAccountId: '',
  gatewayId: '',
};

interface ImportPaymentMethodModalProps {
  isOpen: boolean;
  onClose: () => void;
  accountId: string;
}

export const ImportPaymentMethodModal = React.forwardRef(
  ({ isOpen, onClose, accountId }: ImportPaymentMethodModalProps, ref) => {
    const [step, setStep] = useState<ImportStep>(
      ImportStep.SELECT_GATEWAY_ACCOUNT_ID,
    );
    const [selectedPMs, setSelectedPMs] = useState<string[]>([]);
    const {
      mutateAsync: doGetImportablePaymentMethod,
      isLoading: isLoadingImportablePaymentMethod,
      error: errorImportablePaymentMethod,
      data: dataImportablePaymentMethod,
    } = useSearchForImportablePaymentMethods({
      onSuccess: (data) => {
        setStep(ImportStep.SELECT_PAYMENT_METHOD);
        setSelectedPMs(data.paymentMethods.map((pm) => pm.externalId));
      },
    });
    const {
      mutateAsync: doSaveImportPaymentMethod,
      isLoading: isLoadingSaveImportPaymentMethod,
      error: errorSaveImportPaymentMethod,
      data: dataSaveImportPaymentMethod,
    } = useImportPaymentMethods({
      onSuccess: (data) => {
        setStep(ImportStep.SUBMITTED_PAYMENT_METHOD);
      },
      onError: () => {
        setStep(ImportStep.SUBMITTED_PAYMENT_METHOD);
      },
    });

    const {
      handleSubmit,
      control,
      formState: { errors, isValid },
      watch,
      setValue,
    } = useForm<IImportPaymentMethodReqSchema>({
      resolver: zodResolver(ImportPaymentMethodReqSchema),
      mode: 'onBlur',
      defaultValues: formDefault,
    });
    const watchGatewayId = watch('gatewayId');
    const watchGatewayAcccountId = watch('gatewayAccountId');

    const { currentPaymentGateway } = useCurrentPaymentGateways({
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    });
    useEffect(() => {
      currentPaymentGateway && setValue('gatewayId', currentPaymentGateway?.id);
    }, [currentPaymentGateway]);

    const onHandleSelectGatewayId = () => {
      setStep(ImportStep.SELECT_GATEWAY_ACCOUNT_ID);
    };
    const onHandleImport = () => {
      doSaveImportPaymentMethod({
        accountId,
        gatewayId: watchGatewayId,
        gatewayAccountId: watchGatewayAcccountId,
        externalPaymentMethodIds: selectedPMs,
      });
    };
    const onHandleGoBack = () => {
      setStep(ImportStep.SELECT_PAYMENT_METHOD);
    };

    const onSubmit = async (requestData: IImportPaymentMethodReqSchema) => {
      doGetImportablePaymentMethod({ ...requestData, accountId });
    };

    let errorImportablePaymentMethodMessage =
      errorImportablePaymentMethod?.response?.data?.message;
    if (errorImportablePaymentMethod) {
      errorImportablePaymentMethodMessage =
        errorImportablePaymentMethod.response?.data?.message;
    } else if (dataImportablePaymentMethod?.paymentMethods.length === 0) {
      errorImportablePaymentMethodMessage =
        'There is no account associated with this Account ID.';
    }

    let modalTitle = 'Import Payment Method';
    const isSubmitSuccess =
      dataSaveImportPaymentMethod?.failedImports.length === 0 &&
      !errorSaveImportPaymentMethod;
    if (step == ImportStep.SUBMITTED_PAYMENT_METHOD) {
      modalTitle = isSubmitSuccess
        ? 'Successfully Imported Payment Method'
        : 'Error Importing Payment Method';
    }

    return (
      <MCenterModal
        isOpen={isOpen}
        onClose={onClose}
        modalTitle={modalTitle}
        renderFooter={() => (
          <MStack
            spacing={4}
            direction="row"
            align="center"
            justify="right"
            flex={1}
          >
            <MButton
              onClick={onClose}
              variant="cancel"
              minW="auto"
              isDisabled={isLoadingSaveImportPaymentMethod}
            >
              Cancel
            </MButton>
            {step === ImportStep.SELECT_GATEWAY_ACCOUNT_ID &&
              !errorImportablePaymentMethodMessage && (
                <MButton
                  variant="primary"
                  isLoading={isLoadingImportablePaymentMethod}
                  onClick={handleSubmit(onSubmit)}
                  isDisabled={!isValid}
                  type="submit"
                  minW="auto"
                >
                  Continue
                </MButton>
              )}
            {step === ImportStep.SELECT_GATEWAY_ACCOUNT_ID &&
              errorImportablePaymentMethodMessage && (
                <MButton variant="primary" onClick={onClose} minW="auto">
                  Ok
                </MButton>
              )}
            {step === ImportStep.SELECT_PAYMENT_METHOD && (
              <MButton
                variant="primary"
                onClick={onHandleImport}
                type="submit"
                minW="auto"
                isLoading={isLoadingSaveImportPaymentMethod}
                isDisabled={selectedPMs.length === 0}
              >
                Import
              </MButton>
            )}
            {step === ImportStep.SUBMITTED_PAYMENT_METHOD &&
              isSubmitSuccess && (
                <MButton variant="primary" onClick={onClose} minW="auto">
                  Ok
                </MButton>
              )}
            {step === ImportStep.SUBMITTED_PAYMENT_METHOD &&
              !isSubmitSuccess && (
                <MButton variant="primary" onClick={onHandleGoBack} minW="auto">
                  Go Back
                </MButton>
              )}
          </MStack>
        )}
        modalContentProps={
          { 'data-testid': 'import-payment-method-form' } as any
        }
      >
        <MBox>
          {step === ImportStep.SELECT_GATEWAY_ACCOUNT_ID && (
            <MBox>
              <MFormField
                error={errors.gatewayAccountId}
                label="Payment Account ID"
                isRequired
              >
                <Controller
                  name="gatewayAccountId"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <MInput
                      {...field}
                      disabled={isLoadingImportablePaymentMethod}
                    />
                  )}
                />
                {!!errorImportablePaymentMethodMessage && (
                  <MAlertTag
                    type={'error'}
                    padding="4"
                    alignItems="flex-start"
                    mt="4"
                  >
                    <MBox ml="1">
                      <MText color="tGray.acBlack" fontSize="sm">
                        {errorImportablePaymentMethodMessage}
                      </MText>
                    </MBox>
                  </MAlertTag>
                )}
              </MFormField>
            </MBox>
          )}
          {step >= ImportStep.SELECT_PAYMENT_METHOD && (
            <>
              <MFlex alignItems={'center'} mb="6">
                <MText>
                  {dataImportablePaymentMethod?.gatewayAccount.name}
                </MText>
                <MBox px="2">
                  <MDivider
                    orientation="vertical"
                    borderColor="tPurple.base"
                    height="16px"
                  />
                </MBox>
                <MText>{dataImportablePaymentMethod?.gatewayAccount.id}</MText>
              </MFlex>
              <MBox mt={2}>
                <MText fontWeight="600" fontSize="md">
                  Linked Payment Methods
                </MText>
                <MBox my="4">
                  <VStack spacing={4}>
                    <ImportPaymentMethodList
                      step={step}
                      selectedPMs={selectedPMs}
                      setSelectedPMs={setSelectedPMs}
                      dataImportablePaymentMethod={dataImportablePaymentMethod}
                      errorSaveImportPaymentMethod={
                        errorSaveImportPaymentMethod
                      }
                      dataSaveImportPaymentMethod={dataSaveImportPaymentMethod}
                    />
                  </VStack>
                </MBox>
              </MBox>
            </>
          )}
        </MBox>
      </MCenterModal>
    );
  },
);
