import React, { FunctionComponent as FC } from 'react';
import { Link } from 'react-router-dom';
import { getQuoteReviewRoute } from '~app/constants/routes';
import {
  IApprovalRespSchema,
  IQuoteRespSchema,
  NET_TERMS_DISPLAY,
  NetTermsEnum,
} from '~app/types';
import { formatCurrency } from '~app/utils';

import { differenceInDays } from 'date-fns/differenceInDays';
import { startOfDay } from 'date-fns/startOfDay';
import { getAccountDetailRoute } from '~app/constants/routes';
import { QuoteAlerts } from '~app/routes/Quotes/Quote/QuoteAlerts';
import { QuoteTags } from '~app/routes/Quotes/Quote/components/QuoteTags';
import { toDateShort } from '~utils/dates';
import {
  MAvatar,
  MBox,
  MCenter,
  MDivider,
  MFlex,
  MHStack,
  MLink,
  MStatusIDBox,
  MText,
} from '../Monetize';
import { ApprovalSteps } from './ApprovalSteps';

interface ApprovalDashItemProps {
  quote: IQuoteRespSchema;
  approvals: IApprovalRespSchema[];
}

export const ApprovalDashItem: FC<ApprovalDashItemProps> = React.forwardRef<
  any,
  ApprovalDashItemProps
>((props: ApprovalDashItemProps, ref) => {
  const { quote, approvals } = props;

  const values = [
    {
      label: 'Amount',
      value: formatCurrency(quote.amount, { currency: quote.currency }),
    },
    {
      label: 'Contract Length',
      value: quote.contractLength + ' Months',
    },
    {
      label: 'Start Date',
      value: toDateShort(quote.contractStartDate),
    },
    {
      label: 'Discount',
      value: formatCurrency(quote.discountAmount, {
        currency: quote.currency,
      }),
    },
    {
      label: 'Net Terms',
      value: NET_TERMS_DISPLAY[quote.netTerms as NetTermsEnum] || '',
    },
    {
      label: 'Expiration Date',
      value: toDateShort(quote.expirationDate),
    },
  ];

  return (
    <MBox
      boxShadow={'-2px 0px 16px rgba(200, 197, 212, 0.15)'}
      borderRadius="sm"
      p="6"
    >
      <MBox>
        <MFlex>
          <MBox>
            <MBox display="flex">
              <MLink to={getQuoteReviewRoute(quote.id)} as={Link}>
                <MText
                  color="tIndigo.base"
                  fontSize={18}
                  fontWeight="600"
                  cursor="pointer"
                >
                  {quote.description}
                </MText>
              </MLink>
              <QuoteTags quote={quote} isReadOnly />
            </MBox>
            <MBox marginTop={1}>
              <MHStack ml={0} gap={0}>
                <MLink
                  fontSize="sm"
                  fontWeight="normal"
                  to={getAccountDetailRoute(quote.accountId)}
                  as={Link}
                  _hover={{
                    textDecoration: 'underline',
                  }}
                >
                  {quote.accountName}
                </MLink>
                <MCenter m={0} w={0} height={4}>
                  <MDivider
                    m={0}
                    orientation="vertical"
                    borderColor="tGray.darkGrayPurple"
                  />
                </MCenter>
                <MStatusIDBox id={quote.id} copyUrl mb="0.5" />
              </MHStack>
            </MBox>
            <MBox display="flex" marginTop={1}>
              <MAvatar name={quote.ownerName} mr="1" />
              <MText alignSelf="center">{quote.ownerName}</MText>
            </MBox>
          </MBox>
          <MBox marginLeft="auto">
            <QuoteAlerts validations={quote.validations} showDivider={false} />
            {!!quote.approvalSubmittedAt && (
              <MText color="tGray.darkPurple">
                Days awaiting in approval:{' '}
                {differenceInDays(
                  startOfDay(new Date()),
                  startOfDay(new Date(quote.approvalSubmittedAt)),
                )}
              </MText>
            )}
          </MBox>
        </MFlex>

        <MFlex marginTop={5}>
          <MHStack spacing={4} shouldWrapChildren flexWrap="wrap" flex="1">
            {values.map((val, index) => (
              <MFlex key={index}>
                <MText alignSelf={'center'} color="tGray.darkPurple">
                  {val.label}:
                </MText>
                <MBox alignSelf={'center'} marginLeft={2}>
                  <MText fontWeight="semibold" fontSize="sm">
                    {val.value}
                  </MText>
                </MBox>
              </MFlex>
            ))}
          </MHStack>
        </MFlex>
      </MBox>
      <MDivider borderColor="tGray.back" width="full" my="4" />

      <ApprovalSteps quote={quote} approvals={approvals} />
    </MBox>
  );
});

ApprovalDashItem.defaultProps = {};
