import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  MButton,
  MDropdownActionItem,
  MDropdownActions,
  MFlex,
  MPageHeader,
  MText,
} from '~app/components/Monetize';
import { ROUTES } from '~app/constants';
import { useSettingsReadOnlyRule } from '~app/hooks/useSettingsReadOnlyRule';
import { useDocumentHead } from '~services/documentHead';
import CatalogTabs from './CatalogTabs';

const PageHeader = () => {
  const navigate = useNavigate();
  const { setDocTitle } = useDocumentHead();
  useEffect(() => {
    setDocTitle('Product Catalog');
  }, []);

  const DropDownMenuItem = ({ children }: any) => (
    <MText color="inherit">{children}</MText>
  );

  const redirectToNewProduct = () => {
    navigate(ROUTES.PRODUCT_NEW);
  };

  const redirectToNewOffering = () => {
    navigate(ROUTES.OFFERING_NEW);
  };

  const redirectToDiscount = () => {
    navigate(ROUTES.DISCOUNT_NEW);
  };

  const redirectToNewUsage = () => {
    navigate(ROUTES.USAGE_TYPE_NEW);
  };

  const { canCreateSettings } = useSettingsReadOnlyRule();

  return (
    <>
      {canCreateSettings && (
        <MPageHeader
          title="Product Catalog"
          data-testid="product-catalog-title"
        >
          <MFlex>
            <MDropdownActions
              popOverContentProps={{ minW: '120px' }}
              renderTrigger={() => (
                <MButton
                  variant="primary"
                  ml="3.5"
                  data-testid="new-product-catalog-btn"
                >
                  New
                </MButton>
              )}
            >
              <MDropdownActionItem onClick={redirectToNewProduct}>
                <DropDownMenuItem>Product</DropDownMenuItem>
              </MDropdownActionItem>
              <MDropdownActionItem onClick={redirectToNewOffering}>
                <DropDownMenuItem>Offering</DropDownMenuItem>
              </MDropdownActionItem>
              <MDropdownActionItem onClick={redirectToDiscount}>
                <DropDownMenuItem>Discount</DropDownMenuItem>
              </MDropdownActionItem>
              <MDropdownActionItem onClick={redirectToNewUsage}>
                <DropDownMenuItem>Usage Type</DropDownMenuItem>
              </MDropdownActionItem>
            </MDropdownActions>
          </MFlex>
        </MPageHeader>
      )}
      <CatalogTabs />
    </>
  );
};

export default PageHeader;
