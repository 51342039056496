import { ComponentType, createElement, forwardRef, lazy } from 'react';

export type PreloadableComponent<T extends ComponentType<unknown>> = T & {
  preload: () => Promise<void>;
};

export default function lazyWithPreload<T extends ComponentType<any>>(
  componentImport: () => Promise<{ default: T }>,
  moduleName = 'any',
): PreloadableComponent<T> {
  const LazyComponent = lazy(() => lazyRetry(componentImport, moduleName));
  let factoryPromise: Promise<void> | undefined;
  let LoadedComponent: T | undefined;

  const Component = forwardRef(function LazyWithPreload(props, ref) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return createElement(
      (LoadedComponent as any) ?? (LazyComponent as any),
      Object.assign(ref ? { ref } : {}, props) as any,
    );
  }) as unknown as PreloadableComponent<T>;

  Component.preload = () => {
    if (!factoryPromise) {
      factoryPromise = componentImport()
        .then((module) => {
          LoadedComponent = module.default;
        })
        .catch((ex) => {
          console.error('Failed to preload component', ex);
        });
    }

    return factoryPromise;
  };

  // Auto-pre-load after a delay
  setTimeout(() => {
    Component.preload();
  }, 500);

  return Component;
}

const lazyRetry = <T extends ComponentType<unknown>>(
  componentImport: () => Promise<{ default: T }>,
  name = 'any',
): Promise<{ default: T }> => {
  return new Promise((resolve, reject) => {
    // check if the window has already been refreshed
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem(`retry-${name}-refreshed`) || 'false',
    );
    // try to import the component
    componentImport()
      .then((component) => {
        window.sessionStorage.setItem(`retry-${name}-refreshed`, 'false'); // success so reset the refresh
        resolve(component);
      })
      .catch((error) => {
        if (!hasRefreshed) {
          // not been refreshed yet
          window.sessionStorage.setItem(`retry-${name}-refreshed`, 'true');
          return window.location.reload();
        }
        // Default error behavior as already tried refreshing
        reject(error);
      });
  });
};
