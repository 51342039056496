import { Td, Tr } from '@chakra-ui/react';
import { Control, Controller } from 'react-hook-form';
import { MCurrency } from '~app/components/Monetize/MCurrency';
import { MProductDisplay } from '~app/components/Monetize/MProductDisplay';
import { SubscriptionQuantityCell } from '~app/routes/AccountDetails/Subscriptions/components/SubscriptionQuantityCell';
import {
  formatCurrency,
  formatNumber,
  isProrationDisplayable,
  prorationLabel,
} from '~app/utils';
import {
  MBox,
  MFlex,
  MFormField,
  MGrid,
  MText,
  MVStack,
} from '~components/Monetize';
import {
  IDiscount,
  ISubscriptionItemPricingResponseSchema,
  ISubscriptionProductReqUI,
  ISubscriptionsFormReqUI,
} from '~types';

interface SubscriptionProductProps {
  subscriptionProduct: ISubscriptionProductReqUI;
  subscriptionProductIdx: number;
  pricedProduct?: ISubscriptionItemPricingResponseSchema;
  selectedDiscountObjs: IDiscount[];
  subscriptionOfferingIdx: number;
  errors: any;
  control: Control<ISubscriptionsFormReqUI>;
  subscriptionId: string;
  isLocked: boolean;
  isCanceled: boolean;
  savedQuantity?: number;
  loadingForm: boolean;
  getPrices: () => void;
  currency: string;
}

export const SubscriptionProduct = ({
  subscriptionProduct,
  subscriptionProductIdx,
  pricedProduct,
  subscriptionOfferingIdx,
  errors,
  control,
  subscriptionId,
  isLocked,
  isCanceled,
  savedQuantity,
  loadingForm,
  getPrices,
  currency,
}: SubscriptionProductProps) => {
  const { amount, amountWithoutDiscount, discount, creditAmount } =
    pricedProduct || {};
  const showAmountWithoutDiscount = !!(
    amountWithoutDiscount &&
    amount !== amountWithoutDiscount &&
    amountWithoutDiscount > 0
  );
  const { product } = subscriptionProduct;

  return (
    <Tr
      aria-label={`Subscription ${subscriptionOfferingIdx} Item ${subscriptionProductIdx}`}
      bg={subscriptionProductIdx % 2 ? 'transparent' : 'tWhite.titanWhite'}
    >
      <Td pl="16px !important">
        <MBox px={4} alignSelf="center">
          <MFlex
            flexDir="row"
            data-testid={`Subscription ${subscriptionOfferingIdx} Item ${subscriptionProductIdx} Name`}
          >
            {product && (
              <MProductDisplay
                name={product.name}
                productType={product.productType}
              />
            )}
          </MFlex>
          <MText isTruncated maxW={350}>
            {product?.description || ''}
          </MText>
        </MBox>
      </Td>
      <Td />
      <Td data-testid={`units-input${subscriptionProductIdx}`} minW={140}>
        {subscriptionProduct.product?.productType !== 'USAGE' && (
          <MFormField error={errors?.units}>
            <Controller
              name={`subscriptions.${subscriptionOfferingIdx}.subscriptionItems.${subscriptionProductIdx}.units`}
              control={control}
              render={({ field: { onChange, onBlur, value, ...rest } }) => (
                <SubscriptionQuantityCell
                  inputMode="numeric"
                  isDisabled={isLocked || isCanceled}
                  isReadOnly={isLocked || isCanceled}
                  isNewSubscription={!subscriptionId}
                  isLoading={loadingForm}
                  previousQuantity={savedQuantity}
                  inputName={`subscriptions.${subscriptionOfferingIdx}.subscriptionItems.${subscriptionProductIdx}.units`}
                  dataTestId={`Subscription ${subscriptionOfferingIdx} Item ${subscriptionProductIdx} Units ${
                    subscriptionId && `Addition `
                  }Input`}
                  value={value.toString()}
                  onChange={onChange}
                  handleBlur={() => {
                    onBlur();
                    getPrices();
                  }}
                  {...rest}
                />
              )}
            />
          </MFormField>
        )}
      </Td>
      <Td>
        {!!discount && discount > 0 && (
          <MCurrency
            amount={discount}
            textAlign="right"
            fontSize="sm"
            pr=".5rem"
            data-testid={`Subscription ${subscriptionOfferingIdx} Item ${subscriptionProductIdx} Discount`}
            options={{ currency }}
          />
        )}
      </Td>
      <Td>
        <MVStack alignSelf="center">
          {product?.productType &&
            isProrationDisplayable(
              pricedProduct?.creditProrationMultiplier,
              product.productType,
            ) && (
              <MBox width="100%">
                <MText as="span">
                  {prorationLabel(pricedProduct!.creditProrationMultiplier!)}:
                </MText>
                <MText
                  as="span"
                  fontWeight="bold"
                  ml="0.5rem"
                  data-testid={`Subscription ${subscriptionOfferingIdx} Item ${subscriptionProductIdx} Credit Proration Multiplier`}
                >
                  {formatNumber(pricedProduct!.creditProrationMultiplier)}
                </MText>
              </MBox>
            )}
          {creditAmount && (
            <MBox style={{ marginTop: '0' }} width="100%">
              <MText as="span">Credit:</MText>
              <MText
                as="span"
                fontWeight="bold"
                ml="0.5rem"
                data-testid={`Subscription ${subscriptionOfferingIdx} Item ${subscriptionProductIdx} Credit`}
              >
                {formatCurrency(creditAmount, { currency })}
              </MText>
            </MBox>
          )}
        </MVStack>
      </Td>
      <Td pr="16px !important">
        <MGrid gridTemplateColumns="1fr" style={{ minHeight: '40px' }}>
          {showAmountWithoutDiscount && (
            <MCurrency
              amount={amountWithoutDiscount}
              gridColumn="1"
              gridRow="1"
              textDecoration="line-through"
              fontWeight="bold"
              color="tGray.darkPurple"
              textAlign="right"
              fontSize="sm"
              alignSelf="start"
              data-testid={`Subscription ${subscriptionOfferingIdx} Item ${subscriptionProductIdx} AmountWithoutDiscount`}
              options={{ currency }}
            />
          )}
          <MCurrency
            amount={amount}
            gridColumn="1"
            gridRow="1"
            textAlign="right"
            alignSelf={showAmountWithoutDiscount ? 'end' : 'center'}
            fontWeight="bold"
            fontSize="sm"
            data-testid={`Subscription ${subscriptionOfferingIdx} Item ${subscriptionProductIdx} Amount`}
            options={{ currency }}
          />
        </MGrid>
      </Td>
    </Tr>
  );
};
