import { MdLock, MdMoreVert } from 'react-icons/md';
import {
  MBox,
  MDropdownActionItem,
  MDropdownActions,
  MFlex,
  MIcon,
  MIconAction,
  MText,
  MTooltip,
} from '~app/components/Monetize';
import { ALL_CURRENCIES } from '~app/constants/currencies';
import { useDashboardCurrencyFilter } from '~app/routes/Dashboard/components/useDashboardCurrencyFilter';
import { ICurrencyResp } from '~app/types';

interface CurrencyItemProps {
  currency: ICurrencyResp;
  onSetDefault: (currency: ICurrencyResp) => void;
  onDelete: (currency: ICurrencyResp) => void;
}

const CurrencyItem = ({
  currency,
  onSetDefault,
  onDelete,
}: CurrencyItemProps) => {
  const { setCurrency: setDashboardCurrencyFilter } =
    useDashboardCurrencyFilter();
  const { code: currCode } = currency;
  return (
    <MBox
      key={currCode}
      fontSize="sm"
      fontWeight="bold"
      px="1rem"
      py=".6rem"
      minWidth={440}
      border="1px solid"
      borderRadius={2}
      borderColor="tGray.back"
    >
      <MFlex alignItems="center" justifyContent="space-between">
        <MFlex alignItems="center">
          <MText mr="2">{currCode}</MText>
          <MText
            fontWeight="normal"
            data-testid={
              currency.defaultCurrency ? `${currCode}-default` : `${currCode}`
            }
          >
            {ALL_CURRENCIES[currCode].name}
          </MText>
        </MFlex>
        <MFlex alignItems="center">
          {currency.locked && (
            <MTooltip
              label="You can't update locked currencies as there are products/invoices/transactions associated with them."
              placement="bottom-end"
            >
              <MBox height={4}>
                <MIcon color="tPurple.dark" as={MdLock} w={4} h={4} />
              </MBox>
            </MTooltip>
          )}
          {/* users should be able to set a locked currency as the default -- a ticket has been filed, but until the API supports this, we disable the option -- see https://monetizenow.atlassian.net/browse/BP-3356 */}
          {!currency.defaultCurrency && (
            <MDropdownActions
              popOverContentProps={{ minW: '100px' }}
              renderTrigger={() => (
                <MBox data-testid="currency-action" height="1.125rem">
                  <MIconAction icon={MdMoreVert} />
                </MBox>
              )}
            >
              <MDropdownActionItem
                onClick={() => {
                  onSetDefault(currency);
                  setDashboardCurrencyFilter(currency.code);
                }}
              >
                <MText color="inherit">Set as Default</MText>
              </MDropdownActionItem>
              {!currency.locked && (
                <MDropdownActionItem
                  onClick={() => onDelete(currency)}
                  colorScheme="tRed.base"
                >
                  <MText color="inherit">Delete</MText>
                </MDropdownActionItem>
              )}
            </MDropdownActions>
          )}
        </MFlex>
      </MFlex>
    </MBox>
  );
};

export default CurrencyItem;
