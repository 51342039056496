import { TagProps } from '@chakra-ui/react';
import { MTag } from '~app/components/Monetize';
import { QuoteTypeEnumDisplay } from '~app/constants/quotes';
import { IQuoteRespSchema } from '~app/types';
import { getVariantByStatus } from '~app/utils';

interface IQuoteTypeTagProps extends TagProps {
  quote: IQuoteRespSchema;
}

export const QuoteTypeTag = ({ quote, ...rest }: IQuoteTypeTagProps) => {
  const variant = getVariantByStatus(quote.type);
  return (
    <MTag variant={variant} {...rest}>
      {QuoteTypeEnumDisplay[quote.type]}
    </MTag>
  );
};
