import { FlexProps, IconProps } from '@chakra-ui/react';
import { MdLock } from 'react-icons/md';
import { MFlex, MIcon, MTooltip } from '~app/components/Monetize';

const LockedStatus = ({
  containerProps,
  iconProps,
}: {
  containerProps?: FlexProps;
  iconProps?: IconProps;
}) => (
  <MTooltip
    label="Some of the information in this rate cannot be edited because this rate is currently being used in a quote."
    placement="left"
  >
    <MFlex
      alignItems="center"
      justifyContent="center"
      cursor="pointer"
      {...containerProps}
    >
      <MIcon
        title="lock-icon"
        as={MdLock}
        boxSize={5}
        color="tGray.darkGrayPurple"
        {...iconProps}
      />
    </MFlex>
  </MTooltip>
);

export default LockedStatus;
