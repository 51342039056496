import { MSkeleton, MText } from '~app/components/Monetize';
import { IUsageTypeResSchema } from '~app/types';
import { formatNumber } from '~app/utils';

export const UsageUnit = ({
  consumptionLoading,
  hasError,
  usageTypes,
  unitsConsumed,
}: {
  consumptionLoading: boolean;
  hasError: boolean;
  usageTypes: IUsageTypeResSchema[];
  unitsConsumed: number | undefined | null;
}) => {
  if (consumptionLoading) {
    return <MSkeleton height={5} w={7} />;
  }

  if (hasError) {
    return <MText color="red">Failed!</MText>;
  }

  const usageType = usageTypes && usageTypes?.length > 0 ? usageTypes[0] : null;

  return (
    <MText>
      {formatNumber(unitsConsumed ?? 0, {
        maximumFractionDigits: usageType?.decimalPlaces ?? 0,
      })}
    </MText>
  );
};
