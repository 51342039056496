import { formatISO } from 'date-fns/formatISO';
import { useState } from 'react';
import { doGetDefaultLegalEntity } from '~app/api/legalEntityService';
import { INITIAL_FORM_VALUES } from '~app/constants/quotes';
import { getQuoteEditRoute } from '~app/constants/routes';
import { useQuote } from '~app/hooks';
import { useBackNavigate } from '~app/hooks/useBackNavigate';
import { IAccountRespSchema } from '~app/types';
import { NewGuidedQuoteAccountSelectionModal } from './NewGuidedQuoteAccountSelectionModal';
import { NewGuidedQuoteModal } from './NewGuidedQuoteModal';

const NewGuidedQuoteFormPage = () => {
  const { navigate, navigateBack } = useBackNavigate(1);
  const { createQuote } = useQuote();

  const [account, setAccount] = useState<IAccountRespSchema>();

  const createManualQuote = async () => {
    const newQuote = await createQuote({
      ...INITIAL_FORM_VALUES,
      currency: account?.defaultCurrency ?? 'USD',
      contractStartDate: formatISO(new Date(), { representation: 'date' }),
      legalEntityId: (await doGetDefaultLegalEntity()).content[0].id ?? '',
      accountId: account?.id,
      description: `Quote for ${account?.accountName}`,
    });

    if (newQuote) {
      navigate(getQuoteEditRoute(newQuote.id), { replace: true });
    }
  };

  // Account Creation Part
  if (!account) {
    return (
      <NewGuidedQuoteAccountSelectionModal
        onClose={navigateBack}
        onFinished={(account) => setAccount(account)}
      />
    );
  }

  return (
    <NewGuidedQuoteModal
      account={account}
      onCancel={navigateBack}
      onFinished={(newQuote) =>
        navigate(getQuoteEditRoute(newQuote.id), { replace: true })
      }
      onManualQuote={createManualQuote}
    />
  );
};

export default NewGuidedQuoteFormPage;
