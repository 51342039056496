import { useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useGetQuoteStorageById } from '~app/api/cpqService';
import {
  MBox,
  MButton,
  MDivider,
  MFlex,
  MIcon,
  MPageLoader,
} from '~app/components/Monetize';
import { ensureBoolean } from '~app/utils';
import { useDocumentHead } from '~services/documentHead';
import { ReviewQuoteHtml } from '../../Quote/ReviewQuoteHtml';
import { QuoteConfetti } from '../../Quote/components/QuoteConfetti';
import { useQuoteContext } from '../../Quote/quoteContext';
import { QuoteReviewInfo } from './QuoteReviewInfo';
import { PreviewQuotePdfDrawerV2 } from '~app/components/Quotes/PreviewQuotePdfDrawerV2';
import { useFormContext } from 'react-hook-form';
import { QuoteCollaborationPopoverV2 } from '~app/components/Quotes/QuoteCollaborationPopoverV2';
import { IQuoteRequestSchema, CollaborationAccessEnum } from '~app/types';

interface QuotePageCustomerProps {
  setContactDrawerOpen: (val: boolean) => void;
}
export const QuotePageCustomer = ({
  setContactDrawerOpen,
}: QuotePageCustomerProps) => {
  const {
    quoteData: { quote, displayConfig, setQuote },
    setReviewQuoteDisplayWidth,
    handleSubmitButtonWithoutDirtyCheck,
  } = useQuoteContext();
  const reviewQuoteRef = useRef<any>();
  const [searchParams] = useSearchParams();
  const isCelebrate = searchParams.get('celebrate');
  const { data: document } = useGetQuoteStorageById(
    {
      quoteId: quote?.id!,
      storageId: quote?.documentLocation!,
      params: { attachContent: true },
    },
    { enabled: !!quote?.id && !!quote?.documentLocation },
  );

  const { setValue } = useFormContext<IQuoteRequestSchema>();
  // NOTE: Leave these setDocTitle within the index.tsx and QuotePageCustomer.tsx
  const { setDocTitle } = useDocumentHead();
  useEffect(() => {
    setDocTitle(
      `Review Quote${quote?.description ? ` - ${quote.description}` : ''}`,
    );
  }, [quote?.description]);

  useEffect(() => {
    if (reviewQuoteRef.current) {
      // Calculating review quote display width and removing padding
      setReviewQuoteDisplayWidth(reviewQuoteRef.current.clientWidth - 64);
    }
  }, [reviewQuoteRef, document?.contentBase64Encoded]);

  if (!quote || !displayConfig) {
    return <MPageLoader />;
  }

  return (
    <>
      <QuoteConfetti active={ensureBoolean(isCelebrate)} />
      <QuoteReviewInfo
        onOpenContactDrawer={() => {
          setContactDrawerOpen(true);
        }}
      />
      <MFlex alignItems="center" mb="4" alignSelf="flex-end">
        <PreviewQuotePdfDrawerV2
          quote={quote}
          renderTrigger={({ handleOpen }) => (
            <MButton variant="tertiary" onClick={handleOpen} minW="auto">
              Preview PDF
            </MButton>
          )}
        />
        <MDivider
          orientation="vertical"
          mx="1"
          borderColor="tBlack.200"
          height="6"
        />
        <QuoteCollaborationPopoverV2
          quoteId={quote.id}
          quoteStatus={quote.status}
          collaborationAccess={quote.collaborationAccess}
          accessLevel={quote.accessLevel}
          onChange={(collaborationAccess: CollaborationAccessEnum) => {
            setValue('collaborationAccess', collaborationAccess, {
              shouldDirty: true,
            });
            handleSubmitButtonWithoutDirtyCheck();
          }}
          renderTrigger={({ handleOpen, icon }) => (
            <MButton
              variant="tertiary"
              minW="auto"
              aria-label="Share Quote"
              onClick={handleOpen}
            >
              {icon && <MIcon as={icon} mr="1" />}
              Share
            </MButton>
          )}
        />
      </MFlex>
      <MBox
        w="full"
        borderWidth={0.5}
        borderColor="tBlue.hover"
        background="tWhite.titanWhite"
        ref={reviewQuoteRef}
      >
        {!!quote && !!displayConfig && (
          <ReviewQuoteHtml quote={quote} displayConfig={displayConfig} />
        )}
      </MBox>
    </>
  );
};
