export const UpcomingRenewalIcon = (props: any) => {
  return (
    <svg
      width="14"
      height="16"
      viewBox="0 0 14 16"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      fill="currentColor"
    >
      <path
        d="M4.43613 1.46256V0H9.47578V1.46256H4.43613ZM6.23349 9.70925H7.67842V5.62115H6.23349V9.70925ZM6.95185 16C6.00931 16 5.12725 15.8212 4.30568 15.4635C3.4841 15.1058 2.76594 14.6212 2.1512 14.0097C1.53645 13.3982 1.05287 12.6822 0.700448 11.8616C0.348025 11.0409 0.171814 10.1599 0.171814 9.21838C0.171814 8.2769 0.348025 7.39501 0.700448 6.57269C1.05287 5.75037 1.53668 5.03053 2.15187 4.41318C2.76706 3.79584 3.48573 3.30832 4.3079 2.95063C5.13007 2.59294 6.01275 2.4141 6.95595 2.4141C7.76653 2.4141 8.53011 2.54332 9.2467 2.80176C9.9633 3.06021 10.5977 3.43025 11.1498 3.91189L12.2071 2.85463L13.2819 3.91189L12.2247 4.96916C12.6593 5.46255 13.0235 6.0558 13.3172 6.7489C13.6109 7.442 13.7577 8.26432 13.7577 9.21586C13.7577 10.1598 13.5791 11.0432 13.222 11.8661C12.8649 12.6889 12.3781 13.4053 11.7617 14.0151C11.1453 14.6249 10.4251 15.1082 9.60135 15.4649C8.77755 15.8216 7.89438 16 6.95185 16Z"
        fill="currentColor"
      />
    </svg>
  );
};
