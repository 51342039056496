import { useDisclosure } from '@chakra-ui/hooks';
import { ColumnProps } from 'primereact/column';
import React, { FC, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useGetPaymentMethodsByAccount } from '~app/api/accountsService';
import {
  MBox,
  MButton,
  MDataTable,
  MFlex,
  MHStack,
  MPageHeader,
  MPageSearchInput,
  MText,
} from '~app/components/Monetize';
import {
  MDataTableActions,
  MDataTableFilter,
} from '~app/components/Monetize/DataTable';
import { ExportTableButton } from '~app/components/Monetize/ExportEntityButton';
import {
  ACH_OR_BANK_TRANSFER,
  PaymentMethodStatusEnumDisplay,
  PaymentMethodTypeEnumDisplay,
} from '~app/constants/paymentMethods';
import {
  getAccountPaymentMethodCreate,
  getAccountPaymentMethodView,
} from '~app/constants/routes';
import useCurrentPaymentGateways from '~app/hooks/useCurrentPaymentGateways';
import { useACL } from '~app/services/acl/acl';
import { ACLCheck } from '~app/services/acl/ACLCheck';
import {
  buildFilterParamsRequestObject,
  formatAccountNumber,
  formatExpirationDate,
  getFiltersApplied,
  getIsTrulyEmptyList,
} from '~app/utils';
import { objectToObjArray } from '~app/utils/misc';
import {
  dateTimeBodyTemplate,
  statusBodyTemplate,
  textBodyTemplate,
} from '~app/utils/tableUtils';
import {
  DEFAULT_PAGER,
  FilterType,
  FilterTypeOperator,
  GetListApiFilter,
  IPaymentMethodResp,
  PaymentMethodStatusEnum,
  PaymentMethodTypeEnum,
  TDataTablePager,
} from '~types';

export const usePaymentMethodsData = (accountId: string) => {
  const [filters, setFilters] = useState<FilterType[]>([]);
  const [searchKey] = useState<string>('paymentMethodName');
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [pager, setPager] = useState<TDataTablePager>(DEFAULT_PAGER);
  const [filterParams, setFilterParams] = useState<GetListApiFilter>(() =>
    buildFilterParamsRequestObject(filters!, searchTerm, searchKey),
  );

  const {
    isOpen: isModalOpen,
    onOpen: onOpenModal,
    onClose: onCloseModal,
  } = useDisclosure();

  const {
    isLoading,
    isRefetching,
    isFetched,
    data: listData,
  } = useGetPaymentMethodsByAccount(
    {
      accountId: accountId,
      config: pager,
      filters: filterParams,
    },
    { enabled: !!accountId },
  );

  const [selected, setSelected] = useState<null | IPaymentMethodResp>(null);

  useEffect(() => {
    setFilterParams(
      buildFilterParamsRequestObject(filters!, searchTerm, searchKey),
    );
  }, [pager, accountId, filters, searchTerm, searchKey]);

  const openCreateModal = () => {
    onOpenModal();
  };

  const openEditModal = (row: IPaymentMethodResp) => {
    setSelected(row);
    onOpenModal();
  };

  const onResetFilter = () => {
    setFilters([]);
  };

  return {
    pager,
    setPager,
    filters,
    setFilters,
    searchKey,
    searchTerm,
    setSearchTerm,
    onResetFilter,
    totalRecords: listData?.totalElements || 0,
    loading: isLoading || (isRefetching && !isFetched),
    isModalOpen,
    onOpenModal,
    onCloseModal: () => {
      setSelected(null);
      onCloseModal();
    },
    openCreateModal,
    openEditModal,
    listData,
    selected,
    setSelected,
  };
};

export const PaymentMethodList: FC = () => {
  const navigate = useNavigate();
  const params = useParams();
  const accountId = params?.accountId || '';
  const {
    filters,
    setFilters,
    searchKey,
    searchTerm,
    setSearchTerm,
    onResetFilter,
    listData,
    pager,
    setPager,
    loading,
  } = usePaymentMethodsData(accountId);
  const { canDo } = useACL();
  const { currentPaymentGateway: paymentGateway } = useCurrentPaymentGateways();

  const onSelectRow = (rowData: IPaymentMethodResp) => {
    if (rowData?.id) {
      navigate(getAccountPaymentMethodView(accountId, rowData.id));
    }
  };

  const numberBodyTemplate = (rowData: IPaymentMethodResp) => (
    <MText isTruncated noOfLines={1}>
      {ACH_OR_BANK_TRANSFER.has(rowData.paymentMethodType)
        ? rowData.accountNumber
        : formatAccountNumber(rowData.lastFour)}
    </MText>
  );

  const expiryDateBodyTemplate = (rowData: IPaymentMethodResp) => (
    <MText isTruncated noOfLines={1}>
      {formatExpirationDate(rowData.expirationMonth, rowData.expirationYear)}
    </MText>
  );

  const actionBodyTemplate = (rowData: IPaymentMethodResp) => {
    return <MDataTableActions actions={[]} />;
  };

  const columns: ColumnProps[] = [
    {
      header: 'Gateway ID',
      body: textBodyTemplate<IPaymentMethodResp>('paymentGateway.id'),
      className: 'table-cell-md',
    },
    {
      header: 'Name',
      body: textBodyTemplate<IPaymentMethodResp>('paymentMethodName'),
      className: 'table-cell-md',
    },
    {
      header: 'Type',
      body: statusBodyTemplate<IPaymentMethodResp, PaymentMethodTypeEnum>(
        'paymentMethodType',
        PaymentMethodTypeEnumDisplay,
      ),
      sortable: true,
      className: 'table-cell-md',
      style: { textAlign: 'left' },
    },
    {
      header: 'Number',
      body: numberBodyTemplate,
      className: 'table-cell-md',
    },
    {
      header: 'Expiration Date',
      body: expiryDateBodyTemplate,
      className: 'table-cell-md',
    },
    {
      header: 'Status',
      body: statusBodyTemplate<IPaymentMethodResp, PaymentMethodStatusEnum>(
        'status',
        PaymentMethodStatusEnumDisplay,
      ),
      style: { width: '10em' },
    },
    {
      header: 'Creation Date',
      body: dateTimeBodyTemplate<IPaymentMethodResp>('createDate'),
      style: { width: '10em' },
    },
    { field: 'action', header: '', sortable: false, body: actionBodyTemplate },
  ];

  const statusItems = objectToObjArray(
    PaymentMethodStatusEnumDisplay,
    'value',
    'label',
  );
  const typeItems = objectToObjArray(
    PaymentMethodTypeEnumDisplay,
    'value',
    'label',
  );

  const filterOptions = [
    {
      title: 'Status',
      key: 'status',
      operator: FilterTypeOperator.IN,
      items: statusItems,
    },
    {
      title: 'Type',
      key: 'paymentMethodType',
      operator: FilterTypeOperator.IN,
      items: typeItems,
    },
  ];

  const isTrulyEmptyList = getIsTrulyEmptyList({
    loading,
    totalElements: listData?.totalElements || 0,
    filters,
    searchTerm,
    page: pager.page,
  });
  const filterComponentReset = React.useRef<any>(null);
  const searchComponentReset = React.useRef<any>(null);

  return (
    <MBox>
      <MPageHeader title="Payment Methods" hideContent={isTrulyEmptyList}>
        <MFlex>
          <MHStack spacing="2">
            <MPageSearchInput
              placeholderKey="Name"
              value={searchTerm || ''}
              onChange={(e: any) => setSearchTerm && setSearchTerm(e)}
              count={listData?.totalElements}
              resetSearch={searchComponentReset}
            />
            <MDataTableFilter
              filters={filters}
              filterOptions={filterOptions}
              setFilters={setFilters}
              onResetFilter={onResetFilter}
              resetFilter={filterComponentReset}
            />
            <ExportTableButton
              entity="paymentMethods"
              filters={filters}
              searchKey={searchKey}
              searchTerm={searchTerm}
              sortField={pager.sortField}
              sortOrder={pager.sortOrder}
              endpointParams={[accountId]}
              getFilename={() => `payment-methods-${accountId}.csv`}
            />
            {paymentGateway && (
              <ACLCheck acls={[['billing', 'create']]}>
                <MButton
                  variant="secondary"
                  as={Link}
                  to={getAccountPaymentMethodCreate(accountId)}
                >
                  New Payment Method
                </MButton>
              </ACLCheck>
            )}
          </MHStack>
        </MFlex>
      </MPageHeader>

      <MDataTable
        value={listData?.content}
        totalRecords={listData?.totalElements}
        totalPages={listData?.totalPages}
        pager={pager}
        setPager={setPager}
        rowHover
        className="p-datatable-responsive"
        onSelectionChange={(e) => onSelectRow(e.value as IPaymentMethodResp)}
        emptyProps={{
          mainMessage: 'Looks like there are no payment methods here.',
          smallMessage:
            'You can automate your payments by adding a payment method.',
          btnLabel:
            canDo([['billing', 'create']]) && paymentGateway
              ? 'New Payment Method'
              : '',
          to: getAccountPaymentMethodCreate(accountId),
        }}
        filtersApplied={getFiltersApplied(filters) > 0 || !!searchTerm}
        resetFilter={() => {
          filterComponentReset.current && filterComponentReset.current();
          searchComponentReset.current && searchComponentReset.current();
        }}
        loading={loading}
        columns={columns}
      />
    </MBox>
  );
};
