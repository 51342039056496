import { useDisclosure } from '@chakra-ui/react';
import { useEffect } from 'react';
import {
  MdAccountTree,
  MdChecklist,
  MdConstruction,
  MdDomain,
  MdEuroSymbol,
  MdNotificationsNone,
  MdOutlineCalculate,
  MdOutlineMailOutline,
  MdOutlinePaid,
  MdOutlineSupervisorAccount,
  MdPayment,
  MdSettings,
} from 'react-icons/md';
import { ConditionalTermIcon, CustomFieldIcon } from '~app/assets/icons';
import AccountingIcon from '~app/assets/icons/AccountingIcon';
import ApiKeysIcon from '~app/assets/icons/ApiKeysIcon';
import ApprovalRuleIcon from '~app/assets/icons/ApprovalRuleIcon';
import CompanySettingsIcon from '~app/assets/icons/CompanySettingsIcon';
import CrmIcon from '~app/assets/icons/CrmIcon';
import DunningIcon from '~app/assets/icons/DunningIcon';
import InvoiceTemplateIcon from '~app/assets/icons/InvoiceTemplateIcon';
import InvoicesIcon from '~app/assets/icons/InvoicesIcon';
import OrgManagementIcon from '~app/assets/icons/OrgManagementIcon';
import PaymentGatewayIcon from '~app/assets/icons/PaymentGatewayIcon';
import QuoteSettingIcon from '~app/assets/icons/QuoteSettingIcon';
import QuoteTemplateIcon from '~app/assets/icons/QuoteTemplateIcon';
import { RevenueIcon } from '~app/assets/icons/RevenueIcon';
import SubscriptionsIcon from '~app/assets/icons/SubscriptionsIcon';
import TeamIcon from '~app/assets/icons/TeamIcon';
import ValidationRulesIcon from '~app/assets/icons/ValidationRulesIcon';
import WebhookIcon from '~app/assets/icons/WebhookIcon';
import eSignIcon from '~app/assets/icons/eSignIcon';
import {
  MBox,
  MFlex,
  MIDCopyBox,
  MIcon,
  MPageContainer,
  MPageHeader,
  MTag,
  MText,
} from '~app/components/Monetize';
import { ButtonCard } from '~app/components/Monetize/Cards/ButtonCard';
import { LinkCard } from '~app/components/Monetize/Cards/LinkCard';
import { useDocumentHead } from '~app/services/documentHead';
import { TenantForm } from '../../../components/Tenants/TenantForm';
import { useAuth } from '../../../services/auth0';
import { useSettingsNavRoutes } from '../SideNavBar/settingsNav';
import { SettingSideNavItemKey } from '../SideNavBar/settingsNavType';

const titleLeftContentMapping: Record<
  SettingSideNavItemKey,
  (props?: any) => JSX.Element
> = {
  apikeys: ApiKeysIcon,
  companysettings: MdSettings,
  customfields: CustomFieldIcon,
  currencies: MdEuroSymbol,
  domains: MdDomain,
  notifications: MdNotificationsNone,
  usersroles: MdOutlineSupervisorAccount,
  approvalrules: ApprovalRuleIcon,
  quotingemails: MdOutlineMailOutline,
  orgmanagement: OrgManagementIcon,
  quotesettings: QuoteSettingIcon,
  quotetemplates: QuoteTemplateIcon,
  teams: TeamIcon,
  validationrules: ValidationRulesIcon,
  pricing: MdOutlinePaid,
  dunning: DunningIcon,
  invoices: InvoicesIcon,
  onboarding: MdChecklist,
  invoicetemplates: InvoiceTemplateIcon,
  subscriptions: SubscriptionsIcon,
  billingpayments: MdPayment,
  tax: MdOutlineCalculate,
  revenuerecognition: RevenueIcon,
  crm: CrmIcon,
  esign: eSignIcon,
  paymentgateway: PaymentGatewayIcon,
  webhooks: WebhookIcon,
  accounting: AccountingIcon,
  conditionalterms: ConditionalTermIcon,
  guidedquoting: MdAccountTree,
};

export const SettingsLanding = () => {
  const SETTINGS_ROUTES = useSettingsNavRoutes();

  const { setDocTitle } = useDocumentHead();

  const { currentTenant } = useAuth();

  const {
    isOpen: isEditTenantModalOpen,
    onOpen: onOpenEditTenantModal,
    onClose: onCloseEditTenantModal,
  } = useDisclosure();

  useEffect(() => {
    setDocTitle('Settings');
  }, []);

  return (
    <>
      {isEditTenantModalOpen && currentTenant && (
        <TenantForm
          isOpen
          onClose={onCloseEditTenantModal}
          existingTenant={currentTenant}
        />
      )}
      <MPageContainer>
        <MPageHeader
          title="Settings"
          subTitle={
            <MText color="tGray.darkPurple">
              Set up how you manage subscriptions, customers, billing and more.
            </MText>
          }
        ></MPageHeader>

        <MBox w="100%" pb={6}>
          {currentTenant && (
            <ButtonCard
              minH="100px"
              title="Current Tenant"
              titleProps={{ noOfLines: 2 }}
              onClick={onOpenEditTenantModal}
              titleLeftContent={
                <MIcon
                  as={CompanySettingsIcon}
                  w={6}
                  h={6}
                  color="tPurple.base"
                />
              }
              titleRightContent={
                <MTag
                  size="xs"
                  variant={currentTenant.testTenant ? 'orange' : 'purple'}
                  ml="2"
                >
                  {currentTenant.testTenant ? 'Test' : 'Live'}
                </MTag>
              }
              descriptionProps={{ as: 'div' }}
              description={
                <>
                  <MText mb={1} fontSize="sm" fontWeight="500" noOfLines={2}>
                    {currentTenant.name}
                  </MText>
                  <MIDCopyBox
                    copyValue={currentTenant.id}
                    displayIcon
                    fontWeight="normal"
                  />
                </>
              }
            />
          )}
        </MBox>

        {SETTINGS_ROUTES.map((parentNavRoute) => (
          <MBox w="100%" pb={6} key={parentNavRoute.title}>
            <MText fontWeight="semibold" fontSize="lg" pb={2}>
              {parentNavRoute.title}
            </MText>

            <MFlex gap={6} flexWrap="wrap">
              {parentNavRoute.children.map(
                ({ key, description, route, title, isComingSoon }) =>
                  isComingSoon ? (
                    <ButtonCard
                      h="100px"
                      key={title}
                      title={title}
                      description={description}
                      isDisabled
                      titleRightContent={
                        <MFlex
                          alignItems="center"
                          columnGap={1}
                          color="tGray.acGray"
                        >
                          <MIcon as={MdConstruction} color="inherit" />
                          <MText color="inherit">Coming soon</MText>
                        </MFlex>
                      }
                      titleLeftContent={
                        titleLeftContentMapping[key] ? (
                          <MIcon
                            as={titleLeftContentMapping[key]}
                            w={6}
                            h={6}
                            color={
                              isComingSoon ? 'tGray.acGray' : 'tPurple.base'
                            }
                          />
                        ) : (
                          <></>
                        )
                      }
                    />
                  ) : (
                    <LinkCard
                      h="100px"
                      to={route}
                      key={title}
                      title={title}
                      description={description}
                      titleLeftContent={
                        titleLeftContentMapping[key] ? (
                          <MIcon
                            as={titleLeftContentMapping[key]}
                            w={6}
                            h={6}
                            color="tPurple.base"
                          />
                        ) : (
                          <></>
                        )
                      }
                    />
                  ),
              )}
            </MFlex>
          </MBox>
        ))}
      </MPageContainer>
    </>
  );
};
