import { DiscountDurationEnum, DiscountStatusEnum } from '~app/types';
import { objectToSelectOptions } from '~app/utils/misc';

export const DISCOUNT_STATUS = [
  { name: 'Active', value: 'ACTIVE' },
  { name: 'Inactive', value: 'INACTIVE' },
  { name: 'Pending', value: 'PENDING' },
  { name: 'Expired', value: 'EXPIRED' },
];

export const DISCOUNT_STATUS_ENUM_DISPLAY: {
  [key in DiscountStatusEnum]: string;
} = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
  PENDING: 'Pending',
  EXPIRED: 'Expired',
};

export const DISCOUNT_APPLY_ON = [
  // { name: 'Total Amount', value: 'Total Amount' },
  { name: 'Invoice', value: 'Invoice' },
  { name: 'Invoice Lines', value: 'Invoice Lines' },
];

export const DISCOUNT_DURATION_DISPLAY_TEXT: {
  [key in DiscountDurationEnum]: string;
} = {
  LIMITED: 'Limited',
  UNLIMITED: 'Unlimited',
  ONE_TIME: 'Onetime',
};

export const DISCOUNT_DURATION_TYPES = objectToSelectOptions(
  DISCOUNT_DURATION_DISPLAY_TEXT,
);
