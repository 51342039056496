import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetAccountById, useGetPaymentById } from '~app/api/accountsService';
import { usePrintPaymentToHtml } from '~app/api/paymentsService';
import {
  MBox,
  MFlex,
  MPageContainer,
  MPageHeader,
} from '~app/components/Monetize';
import { HtmlIFrameContentRenderer } from '~app/components/Monetize/HtmlIFrameContentRenderer';
import { PaymentDetails } from '~app/components/Payments/PaymentDetails';
import { ROUTES } from '~app/constants';
import { useHandleApiError } from '~app/hooks/useHandleApiError';
import { PAYMENT_STATUS_DISPLAY } from '~app/types';
import { PaymentActions } from './PaymentActions';

export const PaymentPage: FC = () => {
  const params = useParams();
  const navigate = useNavigate();

  const paymentId = params?.paymentId || '';
  const accountId = params?.accountId || '';

  const { handleApiError } = useHandleApiError();

  const {
    data: payment,
    isLoading: isPaymentIdLoading,
    refetch,
  } = useGetPaymentById(accountId, paymentId, {
    enabled: !!accountId && !!paymentId,
    onError: (err) =>
      handleApiError(err, ROUTES.getAccountPaymentsRoute(accountId)),
  });

  const { data: accountDetails } = useGetAccountById(accountId, {
    enabled: !!accountId,
    refetchOnWindowFocus: false,
  });

  const handleBackNavigation = () => {
    navigate(-1);
  };

  const { data, isLoading, isRefetching, error, isInitialLoading } =
    usePrintPaymentToHtml({
      paymentId,
    });

  if (error) {
    // TODO: add error state
    return null;
  }

  return (
    <MPageContainer height="auto">
      <MPageHeader
        hasBackButton
        title="View Payment"
        status={
          payment?.paymentStatus
            ? PAYMENT_STATUS_DISPLAY[payment?.paymentStatus]
            : ''
        }
        parentLink={ROUTES.getAccountPaymentsRoute(accountId)}
        parentLinkTitle={accountId && accountDetails?.accountName}
        copyUrl
        backButtonCallback={handleBackNavigation}
        id={payment?.id}
      >
        <MFlex
          alignContent="center"
          alignItems="center"
          columnGap={3}
          display={['none', 'none', 'flex']}
        >
          {!!payment?.id && (
            <PaymentActions
              payment={payment}
              accountId={accountId}
              refetch={refetch}
            />
          )}
        </MFlex>
      </MPageHeader>

      <MBox
        width="100%"
        px={[1, 1, 8]}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        {!isPaymentIdLoading && accountDetails && payment && (
          <PaymentDetails payment={payment} />
        )}
        <MBox
          width="100%"
          mt={6}
          borderWidth={0.5}
          borderColor="tBlue.hover"
          background="tWhite.titanWhite"
        >
          <HtmlIFrameContentRenderer
            id="payment-content"
            html={data}
            isInitialLoading={isInitialLoading}
            isReFetching={isLoading || isRefetching}
          ></HtmlIFrameContentRenderer>
        </MBox>
      </MBox>
    </MPageContainer>
  );
};
