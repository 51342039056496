import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useSetRecoilState } from 'recoil';
import { settingsQueryKeys } from '~app/api/settingsService';
import { useGetTenantAndAutoAcceptInvite } from '~app/api/tenantService';
import { useQuery } from '~app/hooks';
import { useDashboardCurrencyFilter } from '~app/routes/Dashboard/components/useDashboardCurrencyFilter';
import { useNavigateToErrorPage } from '~app/routes/ErrorPages/ErrroPage';
import Sentry from '~app/services/sentry';
import { useToast } from '~app/services/toast';
import {
  switchTenantVisibleState,
  tableFiltersState,
  tablePagerState,
} from '~app/store/global.store';
import { PersistKeysEnum, StorageOptions } from '~app/store/store.types';
import { clearBrowserStorage } from '~app/store/store.utils';
import AppLoading from '~components/App/AppLoading';
import { MLink } from '~components/Monetize';
import { ROUTES } from '~constants';
import { handleApiErrorToast } from '../../../api/axios';
import { useAuth } from '../../../services/auth0';

const SwitchTenant = () => {
  const { addToast } = useToast();
  const setSwitchTenantVisible = useSetRecoilState(switchTenantVisibleState);
  const { selectTenant } = useAuth();
  const query = useQuery();
  const navigate = useNavigate();
  const tenantId = query.get('tenantId') || '';
  const redirectUrl = query.get('redirectUrl');
  const navigateToErrorPage = useNavigateToErrorPage();

  const onOpenSwitch = () => {
    setSwitchTenantVisible(true);
  };
  const resetTableFilters = useSetRecoilState(tableFiltersState);
  const resetTablePagers = useSetRecoilState(tablePagerState);
  const { setCurrency: resetDashboardCurrencyFilter } =
    useDashboardCurrencyFilter();
  const queryClient = useQueryClient();
  const { mutateAsync: getTenantAndAutoAcceptInvite } =
    useGetTenantAndAutoAcceptInvite(
      {
        onAcceptFailure: (ex) => {
          Sentry.captureException(ex, { tags: { type: 'TENANT_INVITE' } });
          navigateToErrorPage(
            'There was an error accepting your invite.',
            true,
          );
        },
      },
      {
        onError: (ex) => {
          handleApiErrorToast(ex);
          navigate(ROUTES.ROOT);
        },
        onSuccess: (tenant) => {
          if (!tenant) {
            return;
          }
          selectTenant(tenant);
          addToast({
            summary: `You are in ${tenant.name}`,
            summaryProps: { fontWeight: 'normal' },
            severity: 'info',
            customContent: (
              <MLink textDecor="underline" fontSize="xs" onClick={onOpenSwitch}>
                Change
              </MLink>
            ),
          });
          clearBrowserStorage({
            persistKey: PersistKeysEnum.TABLE_FILTERS,
            type: StorageOptions.SESSION,
          });
          clearBrowserStorage({
            persistKey: PersistKeysEnum.TABLE_PAGERS,
            type: StorageOptions.SESSION,
          });
          clearBrowserStorage({
            persistKey: PersistKeysEnum.DASHBOARD_CURRENCY_FILTER,
            type: StorageOptions.SESSION,
          });
          resetTableFilters({});
          resetTablePagers({});
          resetDashboardCurrencyFilter('');
          queryClient.invalidateQueries([...settingsQueryKeys.currencyList()]);

          // Redirect to dashboard or included redirectUrl
          if (redirectUrl && !redirectUrl.includes('auth/')) {
            navigate(redirectUrl);
          } else {
            navigate(ROUTES.DASHBOARD);
          }
        },
      },
    );

  useEffect(() => {
    if (!tenantId) {
      // select tenant route if tenantId is empty
      navigate(ROUTES.ONBOARD_SELECT_TENANT);
    } else {
      getTenantAndAutoAcceptInvite({ id: tenantId });
    }
  }, [tenantId]);

  return <AppLoading loadingText="Validating the tenant..." />;
};

export default SwitchTenant;
