import { ColumnProps } from 'primereact/column';
import React, { useEffect, useState } from 'react';
import { MdInfo } from 'react-icons/md';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useGetAccountContacts } from '~app/api/contactsService';
import { useGetListData } from '~app/api/queryUtils';
import {
  MBox,
  MButton,
  MDataTable,
  MFlex,
  MHStack,
  MIcon,
  MPageHeader,
  MPageSearchInput,
  MTooltip,
} from '~app/components/Monetize';
import MDataTableFilter from '~app/components/Monetize/DataTable/MDataTableFilter';
import { ExportTableButton } from '~app/components/Monetize/ExportEntityButton';
import { ROUTES } from '~app/constants';
import { BILL_GROUP_STATUS_DISPLAY } from '~app/constants/billGroups';
import { getBillGroupNewRoute } from '~app/constants/routes';
import { useAccountPastDueInformation } from '~app/hooks/AccountDetail/useAccountPastDueInformation';
import { ACLCheck } from '~app/services/acl/ACLCheck';
import { useACL } from '~app/services/acl/acl';
import { useFlags } from '~app/services/launchDarkly';
import {
  buildFilterParamsRequestObject,
  getFiltersApplied,
  getIsTrulyEmptyList,
} from '~app/utils';
import { addContactsAndPaymentsToBillGroupList } from '~app/utils/billGroups';
import { objectToObjArray } from '~app/utils/misc';
import {
  dateBodyTemplate,
  enumFuncBodyTemplate,
  idBodyTemplate,
  nestedTextBodyTemplate,
  statusBodyTemplate,
  textBodyTemplate,
} from '~app/utils/tableUtils';
import {
  BILL_GROUP_INVOICE_FREQUENCY_MAP,
  BillGroupInvoiceFrequencyEnum,
  BillGroupStatusEnum,
  DEFAULT_PAGER,
  FilterType,
  FilterTypeOperator,
  GetListApiFilter,
  IBillGroupResp,
  IBillGroupRespUI,
  IContactRequestSchema,
  TDataTablePager,
} from '~types';
import { usePaymentMethodsData } from '../PaymentMethods/PaymentMethodList';
import { BillGroupActions } from './BillGroupActions';

export const BillGroupListPage = ({
  billGroupId,
}: {
  billGroupId?: string;
}) => {
  const params = useParams();
  const navigate = useNavigate();
  const accountId = params?.accountId || '';
  const { listData: paymentMethods } = usePaymentMethodsData(accountId);
  const { data: contacts, isFetched } = useGetAccountContacts({
    accountId,
    config: { ...DEFAULT_PAGER, rows: 100 },
  });
  const [joinedData, setJoinedData] = useState<IBillGroupRespUI[]>([]);
  const { allowBillGroupCreation } = useFlags();

  const { canDo } = useACL();
  const [filters, setFilters] = useState<FilterType[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [pager, setPager] = useState<TDataTablePager>(DEFAULT_PAGER);

  const [filterParams, setFilterParams] = useState<GetListApiFilter>(() =>
    buildFilterParamsRequestObject(filters, searchTerm, 'name'),
  );

  const { data: billGroups, isLoading: loading } =
    useGetListData<IBillGroupResp>(
      'billGroups',
      { config: pager, filters: filterParams },
      {
        endpointArgs: { accountId },
        select: (data) => {
          if (billGroupId) {
            return {
              ...data,
              content: data.content?.filter(({ id }) => id === billGroupId),
            };
          }
          return data;
        },
      },
    );
  const { getDueAmountByBillGroupId } = useAccountPastDueInformation(accountId);

  useEffect(() => {
    setFilterParams(
      buildFilterParamsRequestObject(filters, searchTerm, 'name'),
    );
  }, [filters, searchTerm, pager]);

  useEffect(() => {
    setJoinedData(
      addContactsAndPaymentsToBillGroupList(
        billGroups?.content,
        contacts?.content,
        paymentMethods?.content,
      ),
    );
  }, [billGroups, contacts, paymentMethods]);

  const onSelectRow = (rowData: IBillGroupRespUI) => {
    navigate(ROUTES.getBillGroupPageRoute(rowData.id!, 'details'));
  };

  const columns: ColumnProps[] = [
    {
      field: '',
      header: '',
      style: {
        width: '0.5rem',
        paddingTop: '0.5rem',
        paddingLeft: '0.13rem',
        paddingRight: '0',
      },
      body: (data: IBillGroupRespUI) => {
        if (getDueAmountByBillGroupId(data.id)) {
          return (
            <MTooltip
              label="This invoice has a balance past due"
              placement="bottom-start"
            >
              <MBox display="inline">
                <MIcon
                  as={MdInfo}
                  w={4}
                  h={4}
                  color="tRed.base"
                  transform="rotate(180deg)"
                />
              </MBox>
            </MTooltip>
          );
        }
        return null;
      },
    },
    {
      className: 'table-cell-md',
      field: 'id',
      header: 'ID',
      body: idBodyTemplate<IBillGroupRespUI>('id'),
    },
    {
      className: 'table-cell-lg',
      field: 'name',
      header: 'Name',
      sortable: true,
      body: textBodyTemplate<IBillGroupRespUI>('name'),
    },
    {
      field: 'billingContact',
      header: 'Billing Contact',
      body: nestedTextBodyTemplate<IBillGroupRespUI, IContactRequestSchema>(
        'billingContact',
        'fullName',
      ),
      className: 'table-cell-lg',
    },
    {
      field: 'nextInvoiceDate',
      header: 'Next Invoice Date',
      body: dateBodyTemplate<IBillGroupRespUI>('nextInvoiceDate'),
      style: { width: '12em' },
    },
    {
      field: 'invoicingFrequency',
      header: 'Invoicing Frequency',
      body: enumFuncBodyTemplate<
        IBillGroupRespUI,
        BillGroupInvoiceFrequencyEnum
      >(
        'invoicingFrequency',
        'invoicingFrequencyInMonths',
        BILL_GROUP_INVOICE_FREQUENCY_MAP,
      ),
      style: { width: '12em' },
    },
    {
      field: 'status',
      header: 'Status',
      body: statusBodyTemplate<IBillGroupRespUI, BillGroupStatusEnum>(
        'status',
        BILL_GROUP_STATUS_DISPLAY,
      ),
      className: 'table-cell-sm',
      sortable: true,
    },
    {
      body: (rowData: IBillGroupRespUI) => (
        <BillGroupActions billGroup={rowData} />
      ),
      style: { width: '4em' },
    },
  ];

  const statusItems = objectToObjArray(
    BILL_GROUP_STATUS_DISPLAY,
    'value',
    'label',
  );

  const filterOptions = [
    {
      title: 'Status',
      key: 'status',
      operator: FilterTypeOperator.IN,
      items: statusItems,
    },
  ];

  const isTrulyEmptyList = getIsTrulyEmptyList({
    loading,
    totalElements: billGroups?.totalElements || 0,
    filters,
    searchTerm,
    page: pager.page,
  });

  const filterComponentReset = React.useRef<any>(null);
  const searchComponentReset = React.useRef<any>(null);

  const displayNewBillGroupButton =
    canDo([['billing', 'create']]) && allowBillGroupCreation
      ? 'New Bill Group'
      : null;

  return (
    <MBox>
      <MPageHeader title="Bill Groups" hideContent={isTrulyEmptyList}>
        <MFlex>
          <MHStack spacing="2">
            <MPageSearchInput
              value={searchTerm || ''}
              onChange={(e: any) => setSearchTerm && setSearchTerm(e)}
              count={billGroups?.totalElements}
              resetSearch={searchComponentReset}
              placeholderKey="Name"
            />
            <MDataTableFilter
              filters={filters}
              filterOptions={filterOptions}
              setFilters={setFilters}
              onResetFilter={() => setFilters([])}
              resetFilter={filterComponentReset}
            />
            <ExportTableButton
              entity="billGroups"
              filters={filters}
              searchKey="name"
              searchTerm={searchTerm}
              sortField={pager.sortField}
              sortOrder={pager.sortOrder}
              endpointParams={[accountId]}
              getFilename={() => `bill-groups-${accountId}.csv`}
            />
            {allowBillGroupCreation && (
              <ACLCheck acls={[['billing', 'create']]}>
                <MButton
                  variant="secondary"
                  as={Link}
                  to={getBillGroupNewRoute(accountId)}
                >
                  New Bill Group
                </MButton>
              </ACLCheck>
            )}
          </MHStack>
        </MFlex>
      </MPageHeader>
      <MDataTable
        value={joinedData}
        totalRecords={billGroups?.totalElements}
        totalPages={billGroups?.totalPages}
        pager={pager}
        setPager={setPager}
        rowHover
        onSelectionChange={(e) => onSelectRow(e.value as IBillGroupRespUI)}
        className="p-datatable-responsive"
        emptyProps={{
          mainMessage: 'Looks like there are no bill groups here.',
          btnLabel: displayNewBillGroupButton,
          to: getBillGroupNewRoute(accountId),
        }}
        filtersApplied={getFiltersApplied(filters) > 0 || !!searchTerm}
        resetFilter={() => {
          filterComponentReset.current && filterComponentReset.current();
          searchComponentReset.current && searchComponentReset.current();
        }}
        loading={loading || !isFetched}
        columns={columns}
      />
    </MBox>
  );
};
