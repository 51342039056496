import { nativeEnum, z } from 'zod';
import { getRequiredMessage } from '~app/utils/messages';

export enum DunningStatusEnum {
  ACTIVE = 'ACTIVE',
  CANCELED = 'CANCELED',
}
export const DunningStatusEnumZ = nativeEnum(DunningStatusEnum);

export enum DunningInvoiceActionEnum {
  VOID = 'VOID',
  WRITE_OFF = 'WRITE_OFF',
}

export enum CustomerCCEnum {
  emailBillGroupCCEmails = 'emailBillGroupCCEmails',
  emailBillGroupShippingContact = 'emailBillGroupShippingContact',
  emailAccountCCEmails = 'emailAccountCCEmails',
}

export const DunningStepCreateSchema = z.object({
  id: z.string().nullish(),
  stepName: z
    .string({ required_error: getRequiredMessage('Name') })
    .min(1, getRequiredMessage('Name')),
  customId: z.string().nullable(),
  stepSequence: z.number(),
  daysSinceDueDate: z
    .union([z.string(), z.number()])
    .refine((val) => val !== null && val !== undefined && val !== '', {
      message: 'Day must be a valid number',
    })
    .transform((val) => Number(val)),
  retryCollection: z.boolean().default(false),
  sendEmail: z.boolean().default(false),
  emailTemplateId: z.string().nullable(),
  emailBillGroupShippingContact: z.boolean().nullable(),
  emailBillGroupCCEmails: z.boolean().nullable(),
  emailAccountCCEmails: z.boolean().nullable(),
  internalCCEmails: z.union([z.string(), z.array(z.string())]).nullable(),
});

export type IDunningStepCreateSchema = z.infer<typeof DunningStepCreateSchema>;

export const DunningCreateSchema = z.object({
  name: z
    .string({ required_error: getRequiredMessage('Dunning Name') })
    .min(1, getRequiredMessage('Dunning Name')),
  dunningSteps: z
    .array(DunningStepCreateSchema)
    .superRefine((data, { addIssue }) => {
      data.forEach((step, index) => {
        const customIds = data.map(({ customId }) => customId);
        const duplicateIndex = customIds.findIndex(
          (id, i) => id && id.trim() === step.customId && i !== index,
        );

        if (duplicateIndex !== -1) {
          addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Custom ID should be unique',
            path: [`${index}.customId`],
          });
        }

        if (index > 0) {
          const prevDay = data[index - 1].daysSinceDueDate;
          const currDay = data[index].daysSinceDueDate;
          if (currDay <= prevDay) {
            addIssue({
              code: z.ZodIssueCode.custom,
              message: 'The day should be greater than previous step day',
              path: [`${index}.daysSinceDueDate`],
            });
          }
        }

        if (
          step.internalCCEmails !== null &&
          (step.internalCCEmails as string).trim().length > 0
        ) {
          const emails = (step.internalCCEmails as string)
            .trim()
            .replace(/[,\r\n\s]/g, ',')
            .split(',')
            .filter(Boolean);

          const isAllEmailValid = emails.every(
            (email: string) => z.string().email().safeParse(email).success,
          );

          if (!isAllEmailValid) {
            addIssue({
              code: z.ZodIssueCode.custom,
              message: 'Contains invalid email',
              path: [`${index}.internalCCEmails`],
            });
          }
        }

        if (step.sendEmail && !step.emailTemplateId) {
          addIssue({
            code: z.ZodIssueCode.custom,
            message: getRequiredMessage('Email Template Id'),
            path: [`${index}.emailTemplateId`],
          });
        }
      });
    }),
});

export type IDunningCreateSchema = z.infer<typeof DunningCreateSchema>;

export enum DunningBillGroupStatusEnum {
  NO_CHANGE = 'NO_CHANGE',
  SUSPENDED = 'SUSPENDED',
  CANCELED = 'CANCELED',
}

export const DunningBillGroupStatusEnumZ = nativeEnum(
  DunningBillGroupStatusEnum,
);

export enum DunningSubscriptionStatusEnum {
  NO_CHANGE = 'NO_CHANGE',
  SUSPENDED = 'SUSPENDED',
  CANCELED = 'CANCELED',
}

export const DunningSubscriptionStatusEnumZ = nativeEnum(
  DunningSubscriptionStatusEnum,
);

export const DunningSchema = z.object({
  id: z.string(),
  name: z.string(),
  status: z.string(),
  invoiceEndAction: z.string(),
  dunningSteps: DunningStepCreateSchema.array(),
  totalDunningStep: z.number(),
  dunningProcessDays: z.string(),
  defaultProcess: z.boolean(),
});

export type IDunnings = z.infer<typeof DunningSchema>;
