import { NumberInputFieldProps, NumberInputProps } from '@chakra-ui/react';
import React, { FC, useEffect, useState } from 'react';
import { UseFormSetError, UseFormSetValue } from 'react-hook-form';
import {
  MAdditionNumberInput,
  MCustomNumberInput,
  MGrid,
} from '~app/components/Monetize';
import { DEFAULT_QUOTE_COLORS } from '~app/constants/quotes';
import { QuantityText } from '~app/routes/Quotes/Quote/components/QuantityText';
import { useQuoteContext } from '~app/routes/Quotes/Quote/quoteContext';
import {
  IQuoteItem,
  IQuoteOfferingReqSchema,
  IQuoteRespSchema,
  IRateResSchema,
  QuoteAmendmentVersionEnum,
  QuoteStatusEnum,
  QuoteTypeEnum,
} from '~app/types';
import { getQuoteColors, isQuoteQtyChanged } from '~app/utils';

interface QuantityCellProps extends NumberInputProps {
  index: number;
  quote: IQuoteRespSchema | null;
  item: IQuoteItem;
  value: string;
  isScheduledChange: boolean;
  scheduledChangePriorQty?: number;
  isAmendment: boolean;
  isRenewal: boolean;
  placeholder: string;
  offeringRate: IRateResSchema | null;
  watchRateId: string | null | undefined;
  isLoading: boolean;
  isOfferingLoading: boolean;
  isRemoved?: boolean;
  setValue: UseFormSetValue<IQuoteOfferingReqSchema>;
  setError: UseFormSetError<IQuoteOfferingReqSchema>;
  handleBlur: (add: boolean) => void;
  handleQuoteOfferingWithoutDirtyCheck: () => void;
}

const QuantityCell: FC<QuantityCellProps> = React.forwardRef<
  any,
  QuantityCellProps
>(
  (
    {
      index,
      item,
      quote,
      isScheduledChange,
      scheduledChangePriorQty,
      isAmendment,
      isRenewal,
      value,
      offeringRate,
      watchRateId,
      isLoading,
      isOfferingLoading,
      isRemoved,
      handleBlur,
      setValue,
      setError,
      handleQuoteOfferingWithoutDirtyCheck,
      isDisabled,
      ...rest
    }: QuantityCellProps,
    ref: any,
  ): React.ReactElement | null => {
    const isNew = !isAmendment && !isRenewal;
    const isDraft = quote?.status === QuoteStatusEnum.DRAFT;
    // If it's amend v2 and editable, just make input editable by default
    const shouldEditByDefault =
      (!isDisabled &&
        quote?.amendmentVersion === QuoteAmendmentVersionEnum.v2) ||
      isNew;
    const [editMode, setEditMode] = useState<boolean>(shouldEditByDefault);
    const [focused, setFocused] = useState<boolean>(shouldEditByDefault);

    useEffect(() => {
      if (shouldEditByDefault) {
        setEditMode(true);
        setFocused(true);
      } else {
        setEditMode(false);
        setFocused(false);
      }
    }, [shouldEditByDefault]);

    const checkQtyChanged = isQuoteQtyChanged(
      item,
      Number(value),
      isScheduledChange ? scheduledChangePriorQty : item.previousQuantity!,
    );
    const quoteType = quote?.type || QuoteTypeEnum.NEW;
    const { useAmendmentV2 } = useQuoteContext();
    const quoteColors =
      checkQtyChanged && !useAmendmentV2
        ? getQuoteColors(quoteType)
        : DEFAULT_QUOTE_COLORS;

    const styleProps: NumberInputFieldProps = {
      width: 'auto',
      maxWidth: 16,
      textAlign: 'center',
      fontWeight: '400',
      /** For very large numbers, alow the input size to grow */
      minWidth: `min(calc(${String(value || '').length}ch + 1.2rem), 7rem)`,
      onClick: (ev: React.MouseEvent<HTMLInputElement>) => {
        const inputElement = ev.currentTarget;
        if (inputElement.value === '0') {
          inputElement.select();
        }
      },
      ...quoteColors,
    };

    const handleInputBlur = (add?: boolean) => {
      if (!shouldEditByDefault) {
        setEditMode(false);
        setFocused(false);
      }
      handleQuoteOfferingWithoutDirtyCheck();
    };

    const renderNumberInput = () => {
      if (isScheduledChange) {
        return (
          <MAdditionNumberInput
            styleProps={styleProps}
            quoteColors={quoteColors}
            iconColor="tBlue.magenta"
            value={value}
            handleInputBlur={handleInputBlur}
            variant="quantity"
            previousQuantity={scheduledChangePriorQty}
            aria-label={rest.name}
            {...rest}
            isDisabled={isRemoved || isDisabled}
          />
        );
      }

      if (isAmendment) {
        return (
          <MAdditionNumberInput
            styleProps={styleProps}
            quoteColors={isNew || useAmendmentV2 ? {} : quoteColors}
            iconColor="tBlue.magenta"
            value={value}
            handleInputBlur={handleInputBlur}
            variant="quantity"
            previousQuantity={item?.previousQuantity}
            aria-label={rest.name}
            {...rest}
            isDisabled={isRemoved || isDisabled}
          />
        );
      }
      return (
        <MCustomNumberInput
          styleProps={styleProps}
          value={value}
          onBlur={() => handleInputBlur()}
          variant="quantity"
          aria-label={rest.name}
          {...rest}
          isDisabled={isRemoved || isDisabled}
        />
      );
    };

    return (
      <MGrid
        onMouseEnter={() =>
          !isRemoved && !isNew && isDraft && !isDisabled && setEditMode(true)
        }
        onMouseLeave={() =>
          !isRemoved && !isNew && isDraft && !isDisabled && setEditMode(false)
        }
        onMouseDown={() =>
          !isRemoved && !isNew && isDraft && !isDisabled && setFocused(true)
        }
        justifyContent="center"
        alignItems="center"
        data-testid={`quantity-wrap-${index}`}
      >
        {focused || (!focused && editMode) ? (
          renderNumberInput()
        ) : (
          <QuantityText
            value={value}
            quote={quote}
            item={item}
            scheduledChangePriorQty={scheduledChangePriorQty}
            type={quoteType}
            isScheduledChange={isScheduledChange}
            isRemoved={isRemoved}
            hideDifference={useAmendmentV2}
          />
        )}
      </MGrid>
    );
  },
);

export default QuantityCell;
