import { useDisclosure } from '@chakra-ui/react';
import { format as formatDate } from 'date-fns/format';
import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { MdInfo, MdOutlineError } from 'react-icons/md';
import { useRecoilValue } from 'recoil';
import { useGetPaymentMethodsByAccountWithGroupedItems } from '~api/accountsService';
import { doGetContactById } from '~app/api/contactsService';
import { useGetListData } from '~app/api/queryUtils';
import {
  ILegalEntitiesSelector,
  selectLegalEntities,
} from '~app/api/selectors';
import { useGetDunningProcesses } from '~app/api/settingsService';
import { AccountAddressSelect } from '~app/components/Account/AccountAddressSelect';
import { ContactSelect } from '~app/components/Contacts/ContactSelect';
import { CustomFieldDataForm } from '~app/components/CustomFields/CustomFieldDataForm';
import { useCustomSelectValue } from '~app/components/Monetize/MCustomSelect/useCustomSelectValue';
import MEmailTextarea from '~app/components/Monetize/MEmailTextarea';
import { AddPaymentMethodForm } from '~app/components/PaymentMethods/Form/AddPaymentMethodForm';
import { BILL_GROUPS, PAYMENT_METHODS } from '~app/constants';
import { STATUS_PLACEHOLDER } from '~app/constants/placeholders';
import {
  SETTING_INVOICE_CREATION_OPTIONS,
  SETTING_INVOICE_DELAY_OPTIONS_IN_FUTURE,
  SETTING_INVOICE_DELAY_OPTIONS_PAST_AND_FUTURE,
} from '~app/constants/settings';
import useCurrentPaymentGateways from '~app/hooks/useCurrentPaymentGateways';
import { useCtrlEnterHotkey } from '~app/hooks/useHotkeys';
import { useACL } from '~app/services/acl/acl';
import { useFlags } from '~app/services/launchDarkly';
import { appGlobalDataState } from '~app/store/global.store';
import {
  FirstInvoiceCreationStrategyEnum,
  InvoiceCreationEnum,
} from '~app/types/InvoiceSettingsTypes';
import { ILegalEntityResponseSchema } from '~app/types/legalEntityTypes';
import { buildFilterParamsRequestObject } from '~app/utils';
import { getAddressFromContact } from '~app/utils/address';
import { arrayToObject } from '~app/utils/misc';
import {
  MBox,
  MCheckbox,
  MCurrencySelect,
  MCustomSelect,
  MDivider,
  MFormField,
  MGrid,
  MGridItem,
  MIcon,
  MInput,
  MRadio,
  MRadioGroup,
  MSkeleton,
  MStack,
  MText,
  MTooltip,
} from '~components/Monetize';
import {
  ApiListResponse,
  BILL_GROUP_INVOICE_FREQUENCY_MAP,
  BillGroupErrorStatusEnum,
  BillGroupStatusEnum,
  CustomFieldEntityEnum,
  DEFAULT_PAGER,
  FilterType,
  FilterTypeOperator,
  GetListApiFilter,
  IAccountRespSchema,
  IBillGroupReqUI,
  IContactRespSchema,
  IDunnings,
  INetTerm,
  IPaymentMethodResp,
  PaymentMethodStatusEnum,
  PaymentMethodTypeEnum,
  QuoteSettingsDefaultAddressSourceEnum,
} from '~types';
import { PaymentMethodItemRenderer } from '../../../components/PaymentMethods/PaymentMethodItemRenderer';
import { ACH_OR_BANK_TRANSFER } from '../../../constants/paymentMethods';
import { BillGroupGenerateInvoice } from './BillGroupGenerateInvoice';

export interface IBillGroupFormProps {
  isSaveLoading: boolean;
  loading: boolean;
  isReadOnly?: boolean;
  accountId: string;
  activeNetTerms: INetTerm[];
  billGroupId?: string;
  handleSave?: () => void;
  errorStatus?: BillGroupErrorStatusEnum;
  accountDetails?: IAccountRespSchema;
}

export const BillGroupForm: FC<IBillGroupFormProps> = ({
  accountId,
  loading,
  isReadOnly,
  billGroupId,
  activeNetTerms,
  handleSave,
  errorStatus,
  accountDetails,
}: IBillGroupFormProps) => {
  const {
    allowInvoiceDelayInPast,
    enableAccountBasedAddresses,
    showSettingFirstInvoiceCheckbox,
  } = useFlags();
  const { hasCrmConfigured } = useRecoilValue(appGlobalDataState);

  const {
    control,
    setValue,
    watch,
    formState: { errors, dirtyFields },
  } = useFormContext<IBillGroupReqUI>();
  const { canDo } = useACL();

  const {
    name,
    contactId,
    billDay,
    defaultPaymentGatewayId,
    invoiceCreation,
    locked,
    activeContract,
    nextInvoiceDate,
    invoiceDelay,
    status,
    invoicingFrequency,
    invoicingFrequencyInMonths,
    netTerms,
    addressData,
    legalEntityId,
    shippingContactId,
  } = watch();

  const contactIdCustomValue = useCustomSelectValue<IContactRespSchema>({
    value: contactId,
    setValue: (val) => setValue('contactId', val, { shouldDirty: true }),
    getOneById: doGetContactById,
  });
  const shippingContactIdCustomValue = useCustomSelectValue<IContactRespSchema>(
    {
      value: shippingContactId,
      setValue: (val) =>
        setValue('shippingContactId', val, { shouldDirty: true }),
      getOneById: doGetContactById,
    },
  );

  const {
    isOpen: isPaymentMethodModalOpen,
    onOpen: onOpenPaymentMethodModal,
    onClose: closePaymentMethodModal,
  } = useDisclosure();

  const filters: FilterType[] = [
    {
      key: 'status',
      operator: FilterTypeOperator.EQUAL,
      value: PaymentMethodStatusEnum.ACTIVE,
    },
    {
      key: 'paymentMethodType',
      operator: FilterTypeOperator.NOT_EQUAL,
      value: PaymentMethodTypeEnum.ACH_CREDIT,
    },
  ];
  const paymentMethodsFilterParams: GetListApiFilter =
    buildFilterParamsRequestObject(filters);

  const { data: paymentMethods } =
    useGetPaymentMethodsByAccountWithGroupedItems({
      accountId: accountId!,
      filters: paymentMethodsFilterParams,
      filterFn: ({ paymentMethodType }) =>
        !ACH_OR_BANK_TRANSFER.has(paymentMethodType),
    });

  const { activeGateways, currentPaymentGateway } = useCurrentPaymentGateways({
    refetchOnWindowFocus: false, // If not, the modal flicks whenever window has focus
  });

  const { data: dunningData } = useGetDunningProcesses<
    ApiListResponse<IDunnings> & { contentById: Record<string, IDunnings> }
  >({
    config: DEFAULT_PAGER,
    options: {
      select: (data) => {
        return {
          ...data,
          contentById: arrayToObject(data.content, 'id'),
        };
      },
    },
  });

  const onSuccessContactLoad = (data: IContactRespSchema[]) => {
    if (data.length && !billGroupId && !contactId) {
      const primaryContact = data.find((c: IContactRespSchema) => c.primary);
      if (primaryContact) {
        setValue('contactId', primaryContact.id);
      }
    }
  };

  const { data: legalEntities } = useGetListData<
    ILegalEntityResponseSchema,
    ILegalEntitiesSelector
  >(
    'legalEntities',
    {
      config: DEFAULT_PAGER,
    },
    {
      select: selectLegalEntities,
    },
  );
  const selectedLegalEntity = legalEntities?.legalEntities.find(
    (legalEntity) => legalEntity.id === legalEntityId,
  );

  const invoicingFrequencyDisplay =
    invoicingFrequency &&
    invoicingFrequencyInMonths &&
    BILL_GROUP_INVOICE_FREQUENCY_MAP[invoicingFrequency](
      invoicingFrequencyInMonths,
    );

  const onClosePaymentMethodModal = (newPaymentMethod?: IPaymentMethodResp) => {
    closePaymentMethodModal();
    if (newPaymentMethod) {
      setValue('paymentMethodId', newPaymentMethod?.id, {
        shouldDirty: true,
        shouldValidate: true,
      });
    }
  };

  useCtrlEnterHotkey(() => {
    handleSave?.();
  });

  const canOnlyUpdatePONumber =
    !canDo([['billing', 'update']]) && canDo([['sales', 'update']]);
  const isLockedAndContracted =
    canOnlyUpdatePONumber || (!!locked && !!activeContract);
  const isReadOnlyProps = {
    isDisabled: isReadOnly,
    disabled: isReadOnly,
    isReadOnly: isReadOnly,
    variant: isReadOnly ? 'readonly' : 'primary',
  };

  const isReadOnlyOrLockedProps = {
    isDisabled: isLockedAndContracted || isReadOnly,
    disabled: isLockedAndContracted || isReadOnly,
    isReadOnly: isLockedAndContracted || isReadOnly,
    variant: isLockedAndContracted || isReadOnly ? 'readonly' : 'primary',
  };

  return (
    <>
      {loading && (
        <>
          <MSkeleton height={100} minH={220} width="full" mt={4} />
          <MDivider
            orientation="horizontal"
            borderColor="tBlue.hover"
            opacity="1"
            alignSelf="center"
            mt={6}
          />
          <MSkeleton height={100} minH={100} width="full" mt={6} />
        </>
      )}
      {!loading && (
        <form data-testid="bill-group-form">
          {billGroupId && nextInvoiceDate && (
            <MBox w="100%">
              <BillGroupGenerateInvoice
                billGroupId={billGroupId}
                nextInvoiceDate={nextInvoiceDate}
                accountId={accountId}
                billGroupStatus={status}
                invoiceDelayDirty={dirtyFields.invoiceDelay}
                invoiceDelay={invoiceDelay}
                errorStatus={errorStatus}
              />
            </MBox>
          )}
          <MGrid templateColumns="repeat(12, 1fr)" columnGap={4} rowGap={5}>
            <MGridItem colSpan={6}>
              <MFormField
                error={errors.name}
                label="Bill Group Name"
                isRequired
              >
                <Controller
                  name="name"
                  defaultValue=""
                  control={control}
                  render={({ field }) => (
                    <MInput
                      {...field}
                      {...isReadOnlyOrLockedProps}
                      maxLength={60}
                      isDisabled={
                        isReadOnlyOrLockedProps.disabled ||
                        canOnlyUpdatePONumber
                      }
                    />
                  )}
                />
              </MFormField>
            </MGridItem>
            <MGridItem colSpan={6}>
              <MFormField error={errors.status} label="Status">
                <Controller
                  name="status"
                  control={control}
                  defaultValue={BillGroupStatusEnum.ACTIVE}
                  render={({ field }) => (
                    <MCustomSelect
                      {...field}
                      placeholder={STATUS_PLACEHOLDER}
                      items={BILL_GROUPS.BILL_GROUP_STATUS_OPTIONS}
                      {...isReadOnlyOrLockedProps}
                      isDisabled={
                        !billGroupId ||
                        isReadOnlyOrLockedProps.disabled ||
                        canOnlyUpdatePONumber
                      }
                      inputProps={{
                        variant: isReadOnlyOrLockedProps.variant,
                        isReadOnly: isReadOnlyOrLockedProps.isReadOnly,
                        isDisabled: isReadOnlyOrLockedProps.isDisabled,
                      }}
                    />
                  )}
                />
              </MFormField>
            </MGridItem>
            <MGridItem colSpan={6}>
              <MFormField
                error={errors.legalEntityId}
                label="Legal Entity"
                isRequired
              >
                <Controller
                  name="legalEntityId"
                  control={control}
                  render={({ field }) => (
                    <MCustomSelect
                      itemTitle="name"
                      itemValue="id"
                      items={legalEntities?.legalEntities || []}
                      {...field}
                      {...isReadOnlyOrLockedProps}
                      isDisabled={
                        isReadOnlyOrLockedProps.disabled ||
                        canOnlyUpdatePONumber
                      }
                    />
                  )}
                />
              </MFormField>
            </MGridItem>
            <MGridItem colSpan={6}>
              <MFormField error={errors.currency} label="Currency" isRequired>
                <Controller
                  name="currency"
                  control={control}
                  render={({ field }) => (
                    <MCurrencySelect
                      {...field}
                      {...isReadOnlyOrLockedProps}
                      isDisabled={
                        isReadOnlyOrLockedProps.disabled ||
                        canOnlyUpdatePONumber
                      }
                    />
                  )}
                />
              </MFormField>
            </MGridItem>

            {enableAccountBasedAddresses && (
              <MGridItem colSpan={12}>
                <MFormField
                  error={errors?.addressData?.addressSource}
                  label="Addresses Sourced From"
                  tooltip="Set source of Shipping and Billing Addresses for any future Invoices"
                  isHorizontal
                  labelProps={{ mb: 0 }}
                >
                  <Controller
                    control={control}
                    name="addressData.addressSource"
                    render={({ field: { value, onChange, ...rest } }) => (
                      <MRadioGroup
                        onChange={onChange}
                        value={value}
                        {...rest}
                        isDisabled={
                          isReadOnlyProps.disabled || canOnlyUpdatePONumber
                        }
                      >
                        <MStack
                          spacing={4}
                          direction="row"
                          color="tGray.darkPurple"
                        >
                          <MRadio
                            value={
                              QuoteSettingsDefaultAddressSourceEnum.ACCOUNT
                            }
                            isDisabled={
                              !hasCrmConfigured ||
                              isReadOnlyProps.disabled ||
                              canOnlyUpdatePONumber
                            }
                            onChange={() => {}}
                          >
                            <MTooltip
                              label="CRM must be connected to use Account Addresses"
                              placement="bottom-start"
                              shouldWrapChildren
                              isDisabled={hasCrmConfigured}
                            >
                              <MText
                                display="inline-flex"
                                alignItems="center"
                                color="currentColor"
                              >
                                Account Addresses
                              </MText>
                            </MTooltip>
                          </MRadio>
                          <MRadio
                            value={
                              QuoteSettingsDefaultAddressSourceEnum.CONTACT
                            }
                            onChange={() => {
                              setValue('addressData.billingAddressId', null);
                              setValue('addressData.shippingAddressId', null);
                            }}
                            isDisabled={
                              isReadOnlyProps.disabled || canOnlyUpdatePONumber
                            }
                          >
                            Contacts
                          </MRadio>
                        </MStack>
                      </MRadioGroup>
                    )}
                  />
                </MFormField>
              </MGridItem>
            )}
            <MGridItem colSpan={6}>
              <MFormField
                error={errors.contactId}
                label="Billing Contact"
                isRequired
              >
                <Controller
                  name="contactId"
                  control={control}
                  render={({ field }) => (
                    <ContactSelect
                      {...field}
                      {...isReadOnlyProps}
                      isDisabled={
                        isReadOnlyProps.disabled || canOnlyUpdatePONumber
                      }
                      onSuccess={onSuccessContactLoad}
                      showAddContacts={canDo([['account_contacts', 'create']])}
                      accountId={accountId}
                      loading={contactIdCustomValue.isLoading}
                      value={contactIdCustomValue.internalValue}
                      returnItem
                      onChange={(val) => {
                        contactIdCustomValue.onInternalValueChange(val as any);
                      }}
                      clearable={false}
                    />
                  )}
                />
              </MFormField>
            </MGridItem>
            <MGridItem colSpan={6}>
              <MFormField
                error={errors?.addressData?.billingAddressId}
                label="Billing Address"
              >
                <Controller
                  name="addressData.billingAddressId"
                  control={control}
                  render={({ field }) => {
                    if (
                      addressData.addressSource ===
                      QuoteSettingsDefaultAddressSourceEnum.CONTACT
                    ) {
                      return contactIdCustomValue.internalValue ? (
                        <MText>
                          {
                            getAddressFromContact(
                              contactIdCustomValue.internalValue,
                              {
                                addressFormat:
                                  selectedLegalEntity?.addressFormat,
                              },
                            ).fullAddress
                          }
                        </MText>
                      ) : (
                        <MText fontStyle="italic">
                          Address populates from Billing Contact
                        </MText>
                      );
                    } else {
                      return (
                        <AccountAddressSelect
                          {...field}
                          {...isReadOnlyProps}
                          isDisabled={
                            isReadOnlyProps.disabled || canOnlyUpdatePONumber
                          }
                          addressFormat={selectedLegalEntity?.addressFormat}
                          accountId={accountId}
                        />
                      );
                    }
                  }}
                />
              </MFormField>
            </MGridItem>
            <MGridItem colSpan={6}>
              <MFormField
                error={errors.shippingContactId}
                label="Shipping Contact"
                isRequired
              >
                <Controller
                  name="shippingContactId"
                  control={control}
                  render={({ field }) => (
                    <ContactSelect
                      {...field}
                      {...isReadOnlyProps}
                      isDisabled={
                        isReadOnlyProps.disabled || canOnlyUpdatePONumber
                      }
                      onSuccess={onSuccessContactLoad}
                      showAddContacts={canDo([['account_contacts', 'create']])}
                      accountId={accountId}
                      loading={shippingContactIdCustomValue.isLoading}
                      value={shippingContactIdCustomValue.internalValue}
                      returnItem
                      onChange={(val) => {
                        shippingContactIdCustomValue.onInternalValueChange(
                          val as any,
                        );
                      }}
                      clearable={false}
                    />
                  )}
                />
              </MFormField>
            </MGridItem>
            <MGridItem colSpan={6}>
              <MFormField
                error={errors?.addressData?.shippingAddressId}
                label="Shipping Address"
              >
                <Controller
                  name="addressData.shippingAddressId"
                  control={control}
                  render={({ field }) => {
                    if (
                      addressData.addressSource ===
                      QuoteSettingsDefaultAddressSourceEnum.CONTACT
                    ) {
                      return shippingContactIdCustomValue.internalValue ? (
                        <MText>
                          {
                            getAddressFromContact(
                              shippingContactIdCustomValue.internalValue,
                              {
                                addressFormat:
                                  selectedLegalEntity?.addressFormat,
                              },
                            ).fullAddress
                          }
                        </MText>
                      ) : (
                        <MText fontStyle="italic">
                          Address populates from Shipping Contact
                        </MText>
                      );
                    } else {
                      return (
                        <AccountAddressSelect
                          {...field}
                          {...isReadOnlyProps}
                          isDisabled={
                            isReadOnlyProps.disabled || canOnlyUpdatePONumber
                          }
                          addressFormat={selectedLegalEntity?.addressFormat}
                          accountId={accountId}
                        />
                      );
                    }
                  }}
                />
              </MFormField>
            </MGridItem>
            <MGridItem colSpan={12}>
              <MEmailTextarea
                label="CC Emails"
                tooltip="Invoices will be sent to these emails. Use commas to add multiple emails."
                fieldName="strCcEmails"
                placeholder="Use commas to add multiple emails"
                control={control}
                isReadOnly={isReadOnly || canOnlyUpdatePONumber}
                textAreaProps={{ rows: 1 }}
              />
            </MGridItem>
            <MGridItem colSpan={12}>
              <MDivider
                orientation="horizontal"
                borderColor="tBlue.hover"
                opacity="1"
              />
            </MGridItem>
            <MGridItem colSpan={6}>
              <MFormField
                error={errors.invoiceTemplateId}
                label="Invoice Template"
              >
                <Controller
                  name="invoiceTemplateId"
                  control={control}
                  render={({ field }) => (
                    <MCustomSelect
                      placeholder="Select"
                      items={['Default', 'Template 1', 'Template 2']}
                      clearable
                      {...field}
                      {...isReadOnlyOrLockedProps}
                      isDisabled
                      value={'Default'}
                      inputProps={{
                        variant: isReadOnlyOrLockedProps.variant,
                        isReadOnly: isReadOnlyOrLockedProps.isReadOnly,
                        isDisabled: isReadOnlyOrLockedProps.isDisabled,
                      }}
                    />
                  )}
                />
              </MFormField>
            </MGridItem>
            <MGridItem colSpan={6}>
              {invoicingFrequencyDisplay && (
                <MFormField label="Invoice Frequency">
                  <Controller
                    name="invoicingFrequency"
                    control={control}
                    render={({ field }) => (
                      <MText>{invoicingFrequencyDisplay.label}</MText>
                    )}
                  />
                </MFormField>
              )}
            </MGridItem>
            <MGridItem colSpan={12}>
              <MFormField
                error={errors.invoiceCreation}
                label="Create Invoices"
              >
                <Controller
                  name="invoiceCreation"
                  control={control}
                  render={({ field }) => (
                    <MCustomSelect
                      items={SETTING_INVOICE_CREATION_OPTIONS}
                      {...field}
                      {...isReadOnlyProps}
                      isDisabled={
                        isReadOnlyProps.disabled || canOnlyUpdatePONumber
                      }
                      inputProps={{
                        variant: isReadOnlyProps.variant,
                        isReadOnly: isReadOnlyProps.isReadOnly,
                        isDisabled: isReadOnlyProps.isDisabled,
                      }}
                    />
                  )}
                />
              </MFormField>
              {showSettingFirstInvoiceCheckbox && (
                <MFormField
                  error={errors?.firstInvoiceCreationStrategy}
                  onClick={(ev) => ev.stopPropagation()}
                  mt="2"
                >
                  <Controller
                    name="firstInvoiceCreationStrategy"
                    control={control}
                    render={({ field: { onChange, value, ...rest } }) => (
                      <MCheckbox
                        isChecked={
                          value ===
                          FirstInvoiceCreationStrategyEnum.GENERATE_ON_QUOTE_PROCESSED
                        }
                        {...rest}
                        onChange={(ev) => {
                          if (ev.target.checked) {
                            onChange(
                              FirstInvoiceCreationStrategyEnum.GENERATE_ON_QUOTE_PROCESSED,
                            );
                          } else {
                            onChange(
                              FirstInvoiceCreationStrategyEnum.FOLLOW_EXISTING_SETTINGS,
                            );
                          }
                        }}
                        {...isReadOnlyProps}
                        isDisabled={
                          isReadOnlyProps.disabled || canOnlyUpdatePONumber
                        }
                      >
                        <MTooltip
                          label="Applies to New and Renewal Quotes with Offerings billed in Advance"
                          placement="bottom-end"
                          shouldWrapChildren
                        >
                          <MText>
                            Only for first invoices, create automatically upon
                            quote processing.
                            <MIcon
                              as={MdInfo}
                              color="tPurple.base"
                              ml="1"
                              w="3"
                              h="3"
                              mb="-0.5"
                            />
                          </MText>
                        </MTooltip>
                      </MCheckbox>
                    )}
                  />
                </MFormField>
              )}
            </MGridItem>
            {invoiceCreation !== InvoiceCreationEnum.MANUAL && (
              <MGridItem colSpan={6}>
                <MFormField
                  error={errors.invoiceDelay}
                  label="Offset from Billing Schedule"
                >
                  <Controller
                    name="invoiceDelay"
                    control={control}
                    render={({ field: { value, onChange, ...rest } }) => (
                      <MCustomSelect
                        items={
                          allowInvoiceDelayInPast
                            ? SETTING_INVOICE_DELAY_OPTIONS_PAST_AND_FUTURE
                            : SETTING_INVOICE_DELAY_OPTIONS_IN_FUTURE
                        }
                        value={String(value)}
                        onChange={(ev) => {
                          onChange(Number(ev));
                        }}
                        {...rest}
                        {...isReadOnlyProps}
                        isDisabled={
                          isReadOnlyProps.disabled || canOnlyUpdatePONumber
                        }
                        inputProps={{
                          variant: isReadOnlyProps.variant,
                          isReadOnly: isReadOnlyProps.isReadOnly,
                          isDisabled: isReadOnlyProps.isDisabled,
                        }}
                      />
                    )}
                  />
                </MFormField>
              </MGridItem>
            )}
            <MGridItem colSpan={6} alignSelf="flex-end" mb={2}>
              <MFormField
                error={errors.autoEmailInvoice}
                label="Email Customer Invoices"
              >
                <Controller
                  name="autoEmailInvoice"
                  control={control}
                  render={({ field: { value, onChange, ...rest } }) => {
                    return (
                      <MRadioGroup
                        {...rest}
                        value={
                          typeof value === 'undefined' ? 'true' : String(value)
                        }
                        onChange={(val: string) => {
                          onChange(val === 'true');
                        }}
                        variant={isReadOnly ? 'readonly' : 'primary'}
                        isDisabled={
                          isReadOnlyProps.disabled || canOnlyUpdatePONumber
                        }
                      >
                        <MStack direction="row" columnGap={5}>
                          <MRadio value="true">
                            Automatically
                            {!!value && (
                              <MTooltip
                                shouldWrapChildren
                                label={
                                  'MonetizeNow will email your customers automatically when invoices are created.'
                                }
                                placement="bottom-end"
                              >
                                <MIcon
                                  as={MdOutlineError}
                                  w={3}
                                  h={3}
                                  ml={1}
                                  color={'tOrange.tangerine'}
                                />
                              </MTooltip>
                            )}
                          </MRadio>
                          <MRadio value="false">Manually</MRadio>
                          );
                        </MStack>
                      </MRadioGroup>
                    );
                  }}
                />
              </MFormField>
            </MGridItem>

            <MGridItem colSpan={12}>
              <MDivider
                orientation="horizontal"
                borderColor="tBlue.hover"
                opacity="1"
              />
            </MGridItem>
            <MGridItem colSpan={6}>
              <MFormField
                error={errors.defaultPaymentGatewayId}
                label="Payment Gateway"
              >
                <Controller
                  name="defaultPaymentGatewayId"
                  control={control}
                  render={({ field: { onChange, ...rest } }) => (
                    <MCustomSelect
                      placeholder="Select"
                      items={activeGateways.map(({ id, description }: any) => ({
                        title: description,
                        value: id,
                      }))}
                      onChange={(value) => {
                        onChange(value);
                        const firstPaymentMethod = paymentMethods?.content.find(
                          ({ paymentGateway }) =>
                            paymentGateway.id === (value as unknown as string),
                        );
                        if (!billGroupId && value && firstPaymentMethod) {
                          setValue('paymentMethodId', firstPaymentMethod.id, {
                            shouldDirty: true,
                            shouldValidate: true,
                          });
                        }
                      }}
                      {...rest}
                      {...isReadOnlyOrLockedProps}
                      isDisabled={
                        isReadOnlyOrLockedProps.disabled ||
                        canOnlyUpdatePONumber
                      }
                      inputProps={{
                        isReadOnly: isReadOnlyOrLockedProps.isReadOnly,
                        variant: isReadOnlyOrLockedProps.variant,
                        isDisabled: isReadOnlyOrLockedProps.isDisabled,
                      }}
                    />
                  )}
                />
              </MFormField>
            </MGridItem>
            <MGridItem colSpan={6}>
              <MFormField error={errors.paymentMethodId} label="Payment Method">
                <Controller
                  name="paymentMethodId"
                  control={control}
                  render={({ field }) => (
                    <MCustomSelect
                      placeholder="Select"
                      itemValue="id"
                      itemTitle="paymentMethodName"
                      items={
                        defaultPaymentGatewayId
                          ? paymentMethods?.paymentMethodsByGatewayId?.[
                              defaultPaymentGatewayId
                            ] || []
                          : []
                      }
                      prependItems={
                        (defaultPaymentGatewayId &&
                          currentPaymentGateway && [
                            {
                              item: PAYMENT_METHODS.NEW_PAYMENT_METHOD,
                              isAction: true,
                              hasDivider: true,
                              onClick: ({ onClose }) => {
                                onClose && onClose();
                                onOpenPaymentMethodModal();
                              },
                            },
                          ]) ||
                        []
                      }
                      renderItemContent={PaymentMethodItemRenderer}
                      clearable
                      {...field}
                      {...isReadOnlyProps}
                      isDisabled={
                        isReadOnlyProps.disabled || canOnlyUpdatePONumber
                      }
                    />
                  )}
                />
              </MFormField>
            </MGridItem>
            <MGridItem colSpan={12} alignSelf="flex-end">
              <MFormField error={errors.collectInvoiceBalanceAutomatically}>
                <Controller
                  name="collectInvoiceBalanceAutomatically"
                  defaultValue={false}
                  control={control}
                  render={({ field: { value, ...rest } }) => (
                    <MCheckbox
                      pt={2}
                      pb={1}
                      isChecked={value}
                      {...rest}
                      {...isReadOnlyProps}
                      isDisabled={
                        isReadOnlyProps.disabled || canOnlyUpdatePONumber
                      }
                    >
                      Collect Payment Automatically
                    </MCheckbox>
                  )}
                />
              </MFormField>
            </MGridItem>
            <MGridItem colSpan={12}>
              <MDivider
                orientation="horizontal"
                borderColor="tBlue.hover"
                opacity="1"
              />
            </MGridItem>
            <MGridItem colSpan={6}>
              <MFormField
                error={errors.billDay}
                label="Bill Cycle Day"
                isRequired
              >
                <Controller
                  name="billDay"
                  defaultValue={Number(formatDate(new Date(), 'd'))}
                  control={control}
                  render={({ field }) => (
                    <MCustomSelect
                      placeholder="Select"
                      items={[...Array(31).keys()].map((k) => ({
                        title: (k + 1).toString(),
                        value: k + 1,
                      }))}
                      {...field}
                      {...isReadOnlyOrLockedProps}
                      isDisabled={
                        isReadOnlyOrLockedProps.disabled ||
                        canOnlyUpdatePONumber
                      }
                      inputProps={{
                        variant: isReadOnlyOrLockedProps.variant,
                        isReadOnly: isReadOnlyOrLockedProps.isReadOnly,
                        isDisabled: isReadOnlyOrLockedProps.isDisabled,
                      }}
                    />
                  )}
                />
              </MFormField>
            </MGridItem>
            <MGridItem colSpan={6}>
              <MFormField error={errors.purchaseOrderNumber} label="PO Number">
                <Controller
                  name="purchaseOrderNumber"
                  defaultValue=""
                  control={control}
                  render={({ field: { value, ...rest } }) => (
                    <MInput
                      placeholder="PO Number"
                      value={value || ''}
                      {...rest}
                      {...isReadOnlyProps}
                    />
                  )}
                />
              </MFormField>
            </MGridItem>
            <MGridItem colSpan={6}>
              <MFormField
                error={errors.registrationNumber}
                label="Registration Number"
              >
                <Controller
                  name="registrationNumber"
                  defaultValue=""
                  control={control}
                  render={({ field: { value, ...rest } }) => (
                    <MInput
                      placeholder="Registration Number"
                      value={value || ''}
                      {...rest}
                      {...isReadOnlyProps}
                    />
                  )}
                />
              </MFormField>
            </MGridItem>
            <MGridItem colSpan={6}>
              <MFormField error={errors.vatNumber} label="VAT Number">
                <Controller
                  name="vatNumber"
                  defaultValue=""
                  control={control}
                  render={({ field: { value, ...rest } }) => (
                    <MInput
                      placeholder="VAT Number"
                      value={value || ''}
                      {...rest}
                      {...isReadOnlyProps}
                    />
                  )}
                />
              </MFormField>
            </MGridItem>

            <MGridItem colSpan={12}>
              <MDivider
                orientation="horizontal"
                borderColor="tBlue.hover"
                opacity="1"
              />
            </MGridItem>
            <MGridItem colSpan={6}>
              <MFormField
                error={errors.dunningProcessId}
                label="Dunning Process"
              >
                <Controller
                  name="dunningProcessId"
                  control={control}
                  render={({ field }) => (
                    <MCustomSelect
                      placeholder="Select"
                      items={(dunningData?.content || []).map(
                        (x: IDunnings) => ({
                          title: x.name,
                          value: x.id,
                        }),
                      )}
                      clearable
                      onClear={() => {
                        setValue('dunningProcessId', null, {
                          shouldValidate: true,
                          shouldDirty: true,
                        });
                      }}
                      {...field}
                      {...isReadOnlyProps}
                      isDisabled={
                        isReadOnlyProps.disabled || canOnlyUpdatePONumber
                      }
                    />
                  )}
                />
              </MFormField>
            </MGridItem>
            <MGridItem colSpan={6}>
              <MFormField error={errors.netTerms} label="Net Terms" isRequired>
                <Controller
                  name="netTerms"
                  control={control}
                  render={({ field }) => (
                    <MCustomSelect
                      placeholder="Select Net Terms"
                      items={activeNetTerms}
                      itemTitle="name"
                      itemValue="value"
                      {...field}
                      {...isReadOnlyOrLockedProps}
                      isDisabled={
                        isReadOnlyOrLockedProps.disabled ||
                        canOnlyUpdatePONumber
                      }
                      inputProps={{
                        variant: isReadOnlyOrLockedProps.variant,
                        isReadOnly: isReadOnlyOrLockedProps.isReadOnly,
                        isDisabled: isReadOnlyOrLockedProps.isDisabled,
                      }}
                    />
                  )}
                />
              </MFormField>
            </MGridItem>
            <MGridItem colSpan={12}>
              <Controller
                name="customFields"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <CustomFieldDataForm
                    entity={CustomFieldEntityEnum.BILL_GROUP}
                    value={value}
                    setValue={(val) => {
                      onChange(val);
                    }}
                    fieldColSpan={6}
                    mode="modal"
                    {...isReadOnlyProps}
                    isDisabled={
                      isReadOnlyProps.disabled || canOnlyUpdatePONumber
                    }
                  />
                )}
              />
            </MGridItem>
          </MGrid>
        </form>
      )}
      <AddPaymentMethodForm
        isOpen={isPaymentMethodModalOpen}
        accountId={accountId}
        onClose={(newPaymentMethod) =>
          onClosePaymentMethodModal(newPaymentMethod)
        }
      />
    </>
  );
};
