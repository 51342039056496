import { Td, Tr } from '@chakra-ui/react';
import hasValue from 'lodash/has';
import React, { useMemo } from 'react';
import {
  Control,
  Controller,
  FieldErrors,
  UseFormGetValues,
  UseFormSetValue,
} from 'react-hook-form';
import { MdMoreVert } from 'react-icons/md';
import { useGetDiscountsByOfferingId } from '~app/api/productCatalogService';
import MEditableInput from '~app/components/Monetize/MEditableInput';
import { PricesDrawer } from '~app/components/Quotes';
import useProductOfferings from '~app/hooks/useProductOfferings';
import RateSymbol from '~app/routes/ProductCatalog/Offerings/components/Rate/RateSymbol';
import { RateItemContent } from '~app/routes/Quotes/Quote/components/quoteOffering/RateItemContent';
import { pluralize } from '~app/utils';
import { groupBy } from '~app/utils/misc';
import {
  MBox,
  MCircularProgress,
  MCustomMultiSelect,
  MCustomSelect,
  MDropdownActionItem,
  MDropdownActions,
  MFlex,
  MFormField,
  MIconAction,
  MText,
} from '~components/Monetize';
import { SUBSCRIPTIONS } from '~constants';
import {
  IDiscount,
  IRateResBaseSchema,
  ISubscriptionOfferingReqUI,
  ISubscriptionPricingResponseSchema,
  ISubscriptionProductReqUI,
  ISubscriptionsFormReqUI,
  RateStatusEnum,
  SubscriptionBillingStatusEnum,
} from '~types';
import { OfferingRateSeparator } from './OfferingRateSeparator';
import { SubscriptionProduct } from './SubscriptionProduct';

interface SubscriptionOfferingProps {
  accountId: string;
  offeringRow: ISubscriptionOfferingReqUI;
  subscriptionOfferingIdx: number;
  errors: FieldErrors<any>;
  control: Control<ISubscriptionsFormReqUI>;
  subscriptionId: string;
  orderablesObj: ReturnType<typeof useProductOfferings>;
  isLocked: boolean;
  isCanceled: boolean;
  savedSubscriptionOffering: ISubscriptionOfferingReqUI | null;
  pricedOffering?: ISubscriptionPricingResponseSchema | null;
  loadingForm: boolean;
  loadingPrices: boolean;
  getValues: UseFormGetValues<ISubscriptionsFormReqUI>;
  setValue: UseFormSetValue<ISubscriptionsFormReqUI>;
  handleRateChange: (
    offeringRow: ISubscriptionOfferingReqUI,
    subscriptionOfferingIdx: number,
    rateId: string,
  ) => void;
  handleOfferingChange: (
    offeringRow: ISubscriptionOfferingReqUI,
    subscriptionOfferingIdx: number,
    offeringId: string,
  ) => void;
  removeUnsavedSubscription: (subscriptionOfferingIdx: number) => void;
  getPrices: () => void;
  watchBillGroup: string;
  currency: string;
}

export const SubscriptionOffering = ({
  accountId,
  offeringRow,
  subscriptionOfferingIdx,
  errors,
  control,
  subscriptionId,
  orderablesObj,
  isLocked,
  isCanceled,
  savedSubscriptionOffering,
  pricedOffering,
  loadingForm,
  loadingPrices,
  watchBillGroup,
  currency,
  getValues,
  handleRateChange,
  handleOfferingChange,
  removeUnsavedSubscription,
  getPrices,
}: SubscriptionOfferingProps) => {
  // allow editing of an offering if a Bill Group is selected and offering is not selected
  const offeringEditable =
    !!watchBillGroup && offeringRow && !offeringRow.offeringId;
  const watchOfferingId = getValues(
    `subscriptions.${subscriptionOfferingIdx}.offeringId`,
  );
  const watchRateId = getValues(
    `subscriptions.${subscriptionOfferingIdx}.rateId`,
  );

  const { rateOptions, selectedRateObj } = useMemo(() => {
    if (orderablesObj.loading || !offeringRow?.offeringId) {
      return { rateOptions: [], selectedRateObj: null };
    }
    // NOTE: https://monetizenow.sentry.io/issues/4172046050/events/d2adc60bd3054bab8de7960e830067cb/
    const hasProductOfferingById = hasValue(
      orderablesObj.productOfferingsObj.byId,
      offeringRow.offeringId,
    );

    const rateObjs = (
      hasProductOfferingById
        ? orderablesObj.productOfferingsObj.byId[offeringRow.offeringId].rates
        : []
    ) as IRateResBaseSchema[];

    const unselectedRateObjs = rateObjs.filter(
      (rate) =>
        rate.status === RateStatusEnum.ACTIVE && rate.id !== watchRateId,
    );
    const selectedRateObj = rateObjs.find(({ id }) => id === watchRateId);
    const rateOptions = selectedRateObj
      ? [selectedRateObj, ...unselectedRateObjs]
      : [...unselectedRateObjs];
    return {
      rateOptions,
      selectedRateObj,
    };
  }, [
    watchRateId,
    offeringRow.offeringId,
    orderablesObj.loading,
    orderablesObj.productOfferingsObj,
  ]);

  const onCloseDiscountSelect = async () => {
    getPrices();
  };

  const selectedDiscountIds: string[] =
    getValues(`subscriptions.${subscriptionOfferingIdx}.discountIds`) || [];

  const offeringId =
    savedSubscriptionOffering?.offeringId ||
    getValues(`subscriptions.${subscriptionOfferingIdx}.offeringId`);

  const {
    data: discounts,
    isLoading: isLoadingDiscounts,
    isFetching: isFetchingDiscounts,
  } = useGetDiscountsByOfferingId({
    offeringId: offeringId || '',
    filters: accountId ? { accountId } : undefined,
    options: {
      enabled: !!offeringId,
      staleTime: 1000 * 60 * 5, // 5 minutes
    },
  });

  const selectedDiscountObjs = useMemo((): IDiscount[] => {
    if (!discounts) {
      return [];
    }
    const foundDiscountObjects = selectedDiscountIds.reduce(
      (foundObjs: IDiscount[], discId) => {
        const found = discounts.find(({ id }) => id === discId);
        if (found) {
          foundObjs.push(found);
        }
        return foundObjs;
      },
      [],
    );
    return foundDiscountObjects;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offeringRow.offeringId, selectedDiscountIds]);

  // show subscription item row form inputs only when rate and offering are selected
  const showOfferingRow = !!watchOfferingId && !!watchRateId;
  const backgroundColor = !offeringEditable ? 'tGray.back' : 'tWhite.base';
  const readOnlyOfferingRateTitle = (
    offeringId?: string | null,
    selectedRateObj?: IRateResBaseSchema | null,
  ) => {
    if (!(offeringId && selectedRateObj)) {
      return '';
    }
    return `${
      orderablesObj.productOfferingsObj.byId[offeringId]?.name || ''
    } - ${selectedRateObj.name || ''}`;
  };

  const pricedProductRecords = pricedOffering
    ? groupBy(pricedOffering.items, 'productId')
    : {};

  return (
    <>
      <Tr
        aria-label={`Subscription Row ${subscriptionOfferingIdx}`}
        verticalAlign="top"
      >
        <Td pl="16px !important" w={450}>
          <MFlex direction="column" w={350}>
            <MFlex>
              {(isLocked || isCanceled) && (
                <MText fontWeight="bold" width="250px">
                  {readOnlyOfferingRateTitle(
                    offeringRow.offeringId,
                    selectedRateObj,
                  )}
                </MText>
              )}
              {!isLocked && !isCanceled && (
                <>
                  <MFormField error={errors?.offeringId}>
                    <Controller
                      name={`subscriptions.${subscriptionOfferingIdx}.offeringId`}
                      defaultValue={offeringRow.offeringId}
                      control={control}
                      render={({ field: { onChange, ...rest } }) => (
                        <MCustomSelect
                          popOverProps={{ matchWidth: false }}
                          placeholder="Select Offering"
                          items={orderablesObj.productOfferings}
                          itemTitle="name"
                          itemValue="id"
                          isDisabled={!offeringEditable || isLocked}
                          data-testid={`Select Item ${subscriptionOfferingIdx}`}
                          onChange={(e: any) => {
                            onChange(e);
                            handleOfferingChange(
                              offeringRow,
                              subscriptionOfferingIdx,
                              e as any,
                            );
                          }}
                          bg={isLocked ? 'transparent' : backgroundColor}
                          w={watchOfferingId ? '100%' : '50%'}
                          minW={175}
                          inputProps={
                            watchOfferingId
                              ? {
                                  borderTopRightRadius: 0,
                                  borderBottomRightRadius: 0,
                                  borderRight: 0,
                                }
                              : {}
                          }
                          popOverContentProps={{ minW: '200px' }}
                          {...rest}
                        />
                      )}
                    />
                  </MFormField>

                  {watchOfferingId && (
                    <>
                      <OfferingRateSeparator />
                      <MFormField error={errors?.rateId}>
                        <Controller
                          name={`subscriptions.${subscriptionOfferingIdx}.rateId`}
                          defaultValue={offeringRow.rateId}
                          control={control}
                          render={({ field: { onChange, ...rest } }) => (
                            <MCustomSelect
                              placeholder="Select Rate"
                              items={rateOptions}
                              itemTitle="name"
                              itemValue="id"
                              isDisabled={isLocked}
                              data-testid={`Select Rate ${subscriptionOfferingIdx}`}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>,
                              ) => {
                                onChange(e);
                                handleRateChange(
                                  offeringRow,
                                  subscriptionOfferingIdx,
                                  e as any,
                                );
                              }}
                              renderRightElement={
                                selectedRateObj &&
                                (() => {
                                  const rateSymbol = (
                                    <RateSymbol
                                      billingFrequency={
                                        selectedRateObj.billingFrequency
                                      }
                                      disabled={isLocked}
                                    />
                                  );
                                  return {
                                    width: 12,
                                    content: rateSymbol,
                                  };
                                })
                              }
                              inputProps={{
                                borderTopLeftRadius: 0,
                                borderBottomLeftRadius: 0,
                                borderLeft: 0,
                                _placeholder: {
                                  color: 'tPurple.dark',
                                },
                              }}
                              renderItemContent={RateItemContent}
                              showQueryInput
                              {...rest}
                            />
                          )}
                        />
                      </MFormField>
                    </>
                  )}
                </>
              )}
            </MFlex>
            {watchRateId && (
              <MFlex mt={1}>
                <MFormField>
                  <Controller
                    control={control}
                    name={`subscriptions.${subscriptionOfferingIdx}.description`}
                    defaultValue={selectedRateObj?.description}
                    render={({ field: { onChange, value, ...rest } }) => (
                      <MEditableInput
                        value={value || ''}
                        showCancel
                        showSave
                        onSubmit={(currValue) => {
                          currValue = (currValue || '').trim();
                          onChange(currValue);
                        }}
                        onChange={onChange}
                        onCancel={onChange}
                        isDisabled={isCanceled}
                        data-testid={`subscription-${subscriptionOfferingIdx}-description`}
                        previewProps={{
                          fontSize: 'sm',
                          minH: '27px',
                        }}
                        inputProps={{
                          value: value || '',
                          maxLength: 80,
                          ...rest,
                        }}
                      />
                    )}
                  />
                </MFormField>
              </MFlex>
            )}
          </MFlex>
        </Td>
        <Td>
          {showOfferingRow && (
            <MFormField error={errors?.billingStatus}>
              <Controller
                name={`subscriptions.${subscriptionOfferingIdx}.billingStatus`}
                defaultValue={offeringRow.billingStatus}
                control={control}
                render={({ field }) => (
                  <MCustomSelect
                    width="106px"
                    placeholder="Select"
                    isDisabled={isLocked || isCanceled}
                    isReadOnly={isLocked}
                    variant={isLocked ? 'readonly' : 'primary'}
                    items={
                      isCanceled
                        ? SUBSCRIPTIONS.STATUS_OPTIONS.filter(
                            (status) =>
                              status.value ===
                              SubscriptionBillingStatusEnum.CANCELED,
                          )
                        : SUBSCRIPTIONS.STATUS_OPTIONS.filter(
                            (status) =>
                              status.value !==
                              SubscriptionBillingStatusEnum.CANCELED,
                          )
                    }
                    w={16}
                    {...field}
                  />
                )}
              />
            </MFormField>
          )}
        </Td>
        <Td>
          {showOfferingRow && (
            <MFlex justifyContent="center" alignItems="center">
              {offeringRow.offeringId && offeringRow.rateId && (
                <PricesDrawer
                  // FIXME: we should allow this in the future,
                  // but saving back to subscription is complicated - this component needs to be refactored and simplified
                  allowPriceCustomization={false}
                  offeringId={offeringRow.offeringId}
                  rateId={offeringRow.rateId}
                  isOfferingOpen
                  offeringType={
                    orderablesObj.productOfferingsObj.byId?.[
                      offeringRow.offeringId
                    ]?.type
                  }
                />
              )}
            </MFlex>
          )}
        </Td>
        <Td>
          {(!selectedDiscountIds?.length && isLocked) || !showOfferingRow ? (
            <MFlex />
          ) : (
            <MFormField error={errors?.discountIds}>
              <Controller
                name={`subscriptions.${subscriptionOfferingIdx}.discountIds`}
                defaultValue={selectedDiscountIds}
                control={control}
                render={({ field }) => (
                  <MCustomMultiSelect
                    isReadOnly={isLocked}
                    variant={isLocked ? 'readonly' : 'primary'}
                    isDisabled={!offeringRow.rateId || isLocked || isCanceled}
                    items={discounts}
                    itemValue="id"
                    itemTitle="name"
                    closeButtonText="Apply"
                    placeholder={
                      selectedDiscountIds.length === 0
                        ? 'Select'
                        : `${selectedDiscountIds.length} ${pluralize(
                            'Discount',
                            selectedDiscountIds.length,
                          )}`
                    }
                    inputProps={{
                      _placeholder: {
                        color: 'tPurple.dark',
                        textAlign: 'left',
                      },
                    }}
                    externalOnClose={onCloseDiscountSelect}
                    loading={isLoadingDiscounts && isFetchingDiscounts}
                    pr=".5rem"
                    {...field}
                  />
                )}
              />
            </MFormField>
          )}
        </Td>
        <Td></Td>
        <Td pr="16px !important" textAlign="end">
          {loadingPrices && (
            <MFlex justifyContent="flex-end" alignItems="center">
              <MCircularProgress isIndeterminate size={4} />
            </MFlex>
          )}
          {!subscriptionId &&
            !!watchOfferingId &&
            !!watchRateId &&
            !loadingPrices && (
              <MDropdownActions
                popOverContentProps={{ minW: '100px' }}
                renderTrigger={() => (
                  <MBox mr={-2} py={1}>
                    <MIconAction icon={MdMoreVert} />
                  </MBox>
                )}
              >
                <MDropdownActionItem
                  colorScheme="tRed.base"
                  textAlign="left"
                  data-testid={`Subscription ${subscriptionOfferingIdx} Remove`}
                  onClick={() => {
                    removeUnsavedSubscription(subscriptionOfferingIdx);
                  }}
                >
                  <MText color="inherit">Delete</MText>
                </MDropdownActionItem>
              </MDropdownActions>
            )}
        </Td>
      </Tr>
      {!!watchRateId &&
        Array.isArray(offeringRow?.subscriptionItems) &&
        offeringRow.subscriptionItems.map(
          (
            subscriptionProduct: ISubscriptionProductReqUI,
            subscriptionProductIdx: number,
          ) => (
            <SubscriptionProduct
              key={`${subscriptionOfferingIdx}-${subscriptionProduct.productId}`}
              subscriptionProduct={subscriptionProduct}
              subscriptionProductIdx={subscriptionProductIdx}
              pricedProduct={
                pricedProductRecords[subscriptionProduct.productId]
              }
              selectedDiscountObjs={selectedDiscountObjs}
              subscriptionOfferingIdx={subscriptionOfferingIdx}
              errors={errors}
              control={control}
              subscriptionId={subscriptionId}
              isLocked={isLocked}
              isCanceled={isCanceled}
              savedQuantity={Number(
                savedSubscriptionOffering?.subscriptionItems.find(
                  ({ id }) => id === subscriptionProduct.id,
                )?.units,
              )}
              loadingForm={loadingForm}
              getPrices={getPrices}
              currency={currency}
            />
          ),
        )}
    </>
  );
};
