const DocumentIcon = (props: any) => {
  return (
    <svg
      width="17"
      height="21"
      viewBox="0 0 17 21"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.11489 15.8608H12.1451V14.3147H4.11489V15.8608ZM4.11489 11.6213H12.1451V10.0751H4.11489V11.6213ZM1.69586 20.25C1.24697 20.25 0.852109 20.0796 0.511284 19.7388C0.170459 19.3979 4.57764e-05 19.0031 4.57764e-05 18.5542V1.69581C4.57764e-05 1.2303 0.170459 0.831281 0.511284 0.498768C0.852109 0.166256 1.24697 0 1.69586 0H10.7236L16.2599 5.53633V18.5542C16.2599 19.0031 16.0936 19.3979 15.7611 19.7388C15.4286 20.0796 15.0296 20.25 14.5641 20.25H1.69586ZM9.85072 6.33436V1.69581H1.69586V18.5542H14.5641V6.33436H9.85072Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default DocumentIcon;
