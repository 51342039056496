import {
  Box,
  BoxProps,
  FormLabelProps,
  RadioProps,
  SimpleGrid,
  SimpleGridProps,
  useRadio,
  useRadioGroup,
} from '@chakra-ui/react';
import { ReactNode, forwardRef } from 'react';

interface RadioStyleButtonGroupProps {
  name: string;
  options: { label: string | ReactNode; value: string }[];
  isDisabled?: boolean;
  value?: string;
  defaultValue?: string;
  containerProps?: SimpleGridProps;
  buttonContainerProps?: FormLabelProps;
  buttonProps?: BoxProps;
  onChange?: (value: string) => void;
}

/**
 * This component is a grid of radio buttons that are styled as buttons.
 *
 * Reference https://v2.chakra-ui.com/docs/components/radio/usage#custom-radio-buttons
 */
export const RadioStyleButtonGroup = forwardRef<
  any,
  RadioStyleButtonGroupProps
>(
  (
    {
      name,
      options,
      defaultValue,
      isDisabled,
      value,
      buttonProps,
      buttonContainerProps,
      containerProps,
      onChange,
    },
    ref,
  ) => {
    const { getRootProps, getRadioProps } = useRadioGroup({
      name,
      isDisabled,
      value,
      defaultValue,
      onChange,
    });

    const group = getRootProps();

    return (
      <SimpleGrid
        w="100%"
        gap={0}
        columns={options.length}
        {...group}
        {...containerProps}
      >
        {options.map(({ label, value }, i) => {
          const radio = getRadioProps({ value });
          return (
            <RadioStyleButton
              key={value}
              isDisabled={isDisabled}
              {...radio}
              buttonProps={buttonProps}
              buttonContainerProps={buttonContainerProps}
              columns={(containerProps?.columns as number) || options.length}
              index={i}
              itemCount={options.length}
            >
              {label}
            </RadioStyleButton>
          );
        })}
      </SimpleGrid>
    );
  },
);

type RadioStyleButtonProps = RadioProps &
  Pick<RadioStyleButtonGroupProps, 'buttonProps' | 'buttonContainerProps'> & {
    columns: number;
    index: number;
    itemCount: number;
  };

export const RadioStyleButton = forwardRef<any, RadioStyleButtonProps>(
  (props, ref) => {
    const {
      buttonProps,
      buttonContainerProps,
      columns,
      index,
      itemCount,
      children,
      ...radioProps
    } = props;
    const { getInputProps, getRadioProps } = useRadio(radioProps);

    const numberOfRows = Math.ceil(itemCount / columns);
    const isFirstItem = index === 0;
    const isLastItemInFirstRow = index === columns - 1;
    const isFirstItemInFinalRow = index === itemCount - columns;
    const isLastItem = index === itemCount - 1;
    const isFirstItemInRow = index % columns === 0;
    const isFinalRow = index >= itemCount - columns;

    const topLeftBorderRadius = isFirstItem ? '2px' : '0px';
    const topRightBorderRadius = isLastItemInFirstRow ? '2px' : '0px';
    const bottomLeftBorderRadius = isFirstItemInFinalRow ? '2px' : '0px';
    const bottomRightBorderRadius = isLastItem ? '2px' : '0px';

    const showLeftBorder = isFirstItem || isFirstItemInRow;
    const showBottomBorder = isFinalRow;

    const input = getInputProps();
    const checkbox = getRadioProps();

    return (
      <Box as="label" flexGrow={1} textAlign="center" {...buttonContainerProps}>
        <input {...input} />
        <Box
          {...checkbox}
          position="relative"
          cursor="pointer"
          bg="tWhite.base"
          color="tRoyalBlue.base"
          border="1px solid"
          borderLeftColor={showLeftBorder ? 'tRoyalBlue.base' : 'transparent'}
          borderTopColor="tRoyalBlue.base"
          borderRightColor="tRoyalBlue.base"
          borderBottomColor={
            showBottomBorder ? 'tRoyalBlue.base' : 'transparent'
          }
          lineHeight="0"
          borderRadius={`${topLeftBorderRadius} ${topRightBorderRadius} ${bottomRightBorderRadius} ${bottomLeftBorderRadius}`}
          fontWeight="bold"
          h="32px"
          fontSize="xs"
          justifyContent="center"
          alignItems="center"
          display="flex"
          _checked={{
            bg: 'tBlue.hover',
            // Ensure no transparent borders when checked otherwise outline looks weird
            borderColor: 'tRoyalBlue.base',
            outline: '2.5px solid',
            outlineColor: 'tIndigo.base',
            zIndex: 1000,
          }}
          _hover={{
            bg: 'tBlue.hover',
            _disabled: {
              bg: 'tGray.lightPurple',
            },
          }}
          _disabled={{
            bg: 'tGray.lightPurple',
            color: 'tGray.darkPurple',
            border: '1px solid',
            borderColor: 'tWhite.base',
            outline: 'none',
            cursor: 'not-allowed',
          }}
          _focus={{
            boxShadow: '0px 2px 8px 0px #18243C66',
          }}
          {...buttonProps}
        >
          {children}
        </Box>
      </Box>
    );
  },
);
