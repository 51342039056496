import { SVGProps } from 'react';

export const QuoteDocumentIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="18"
    height="22"
    viewBox="0 0 18 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.83498 17.0453H13.2947V15.4164H4.83498V17.0453ZM4.83498 12.579H13.2947V10.9501H4.83498V12.579ZM2.28654 21.6693C1.81363 21.6693 1.39765 21.4897 1.03859 21.1307C0.67953 20.7716 0.5 20.3556 0.5 19.8827V2.12247C0.5 1.63205 0.67953 1.21169 1.03859 0.861389C1.39765 0.511088 1.81363 0.335938 2.28654 0.335938H11.7972L17.6297 6.16845V19.8827C17.6297 20.3556 17.4546 20.7716 17.1043 21.1307C16.754 21.4897 16.3336 21.6693 15.8432 21.6693H2.28654ZM10.8777 7.00917V2.12247H2.28654V19.8827H15.8432V7.00917H10.8777Z"
      fill="#3D3C65"
    />
  </svg>
);
