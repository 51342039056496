import { Divider } from '@chakra-ui/react';
import isString from 'lodash/isString';
import { FunctionComponent as FC, useState } from 'react';
import { handleApiErrorToast, isAxiosError } from '~app/api/axios';
import { useSearchCrmOpportunities } from '~app/api/crmService';
import { useGetCrmConfiguration } from '~app/api/settingsService';
import { MCustomSelect, MFormField } from '~app/components/Monetize';
import { MainSearchInput } from '~app/components/Monetize/MCustomSelect/components/MainSearchInput';
import { useDebounce } from '~app/hooks/useDebounce';
import { CrmOpportunityForInput, Maybe } from '~app/types';

interface OpportunitySearchInputProps {
  label?: string;
  accountId: string;
  crmOpportunityId?: Maybe<string>;
  onChange: (crmOpportunity: CrmOpportunityForInput) => void;
  showDivider?: boolean;
}

/**
 * Search for an Opportunity in the CRM
 * This is a typeahead search that will search for opportunities in the CRM
 */
export const OpportunitySearchInput: FC<OpportunitySearchInputProps> = ({
  label = 'Link a Salesforce Opportunity',
  accountId,
  crmOpportunityId,
  onChange,
  showDivider = false,
}: OpportunitySearchInputProps) => {
  const [errorMessage, setErrorMessage] = useState<{ message: string } | null>(
    null,
  );
  const [searchTerm, setSearchTerm] = useState<string>('');
  const searchTermDebounced = useDebounce(searchTerm);

  // Hubspot is not yet supported
  const { data: crmConnections, isLoading: crmConfigLoading } =
    useGetCrmConfiguration({
      onError: (err) => handleApiErrorToast(err),
    });

  const { data: items, isFetching: searchLoading } = useSearchCrmOpportunities(
    accountId,
    searchTermDebounced,
    {
      enabled:
        !crmConfigLoading &&
        !!accountId &&
        !!(crmConnections?.salesforce /** || crmConnections?.hubspot */),
      select: (values) =>
        values.map((item) => ({ ...item, rightLabel: item.stage })),
      onError: (error) => {
        setErrorMessage(
          isAxiosError(error) && isString(error.response?.data?.details)
            ? {
                message:
                  error.response?.data?.details ||
                  'There was a problem searching for opportunities.',
              }
            : { message: 'There was a problem searching for opportunities.' },
        );
      },
      onSuccess: () => {
        setErrorMessage(null);
      },
    },
  );

  const onChangeQuery = (q: string): void => {
    setSearchTerm(q);
  };

  if (
    crmConfigLoading ||
    !accountId ||
    !crmConnections?.salesforce /** && !crmConnections?.hubspot */
  ) {
    return null;
  }

  return (
    <>
      {showDivider && crmConnections?.salesforce && <Divider />}

      <MFormField label={label} error={errorMessage}>
        <MCustomSelect
          clearable
          items={items}
          itemTitle="name"
          itemValue="id"
          useMainInputAsSearchInput
          useExternalQuery
          externalQuery={searchTerm}
          onChangeQuery={onChangeQuery}
          itemSearch="q"
          MainInputComponent={MainSearchInput}
          loading={searchLoading}
          placeholder="Search"
          value={crmOpportunityId || ''}
          onChange={(val: any) => {
            onChange({
              id: val,
              name: items?.find((item) => item.id === val)?.name || '',
            });
          }}
        />
      </MFormField>
    </>
  );
};
